import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, IconButton, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { getVacations } from "api/vacations/getVacations";
import { Vacation, VacationStatus } from "api/vacations/_types/Vacation";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { Pagination } from "components/Pagination/Pagination";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { FaExchangeAlt, FaFileDownload, FaFileExport } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { vacationStatusMap } from "utils/vacationMappers";
import { VacationsTableRow } from "./components/VacationsTableRow";
import { VacationsTableFilters } from "./components/VacationTableFilters";

const headers = [
  { label: 'COLABORADOR', key: 'collaborator_name' },
  { label: 'E-MAIL', key: 'collaborator_email' },
  { label: 'DATA LIMITE PARA GOZO', key: 'deadline' },
  { label: 'INÍCIO AQUISITIVO', key: 'acquisition_start_date' },
  { label: 'FIM AQUISITIVO', key: 'acquisition_end_date' },
  { label: 'DATA INICIAL PARA PLANEJAMENTO DAS FÉRIAS', key: 'planning_start_date' },
  { label: 'REGIONAL', key: 'regional' },
  { label: 'STATUS', key: 'status' },
]

function formatValuesToReport(values: Vacation[]) {
  return values?.map((vacation) => ({
    ...vacation,
    regional: vacation?.regional === 'other' ? 'Outra' : vacation?.regional.toUpperCase(),
    deadline: vacation.deadline
      ? format(new Date(vacation.deadline), 'dd/MM/yyyy')
      : '-',
    acquisition_start_date: vacation.acquisition_start_date
      ? format(new Date(vacation.acquisition_start_date), 'dd/MM/yyyy')
      : '-',
    acquisition_end_date: vacation.acquisition_end_date
      ? format(new Date(vacation.acquisition_end_date), 'dd/MM/yyyy')
      : '-',
    planning_start_date: vacation.planning_start_date
      ? format(new Date(vacation.planning_start_date), 'dd/MM/yyyy')
      : '-',
    status: vacationStatusMap[vacation?.status]
  }))
}



export function Vacations() {
  const searchParams = useSearchParams()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanViewVacations = userLogged?.permissions.includes(
    'view-vacations'
  )

  useEffect(() => {
    if (!userCanViewVacations) history.push('/')
  }, [userCanViewVacations, history])

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status')
  const collaboratorName = searchParams.get('collaboratorName')

  const {
    data: vacationsResult
  } = useQuery({
    queryKey: [
      'vacations',
      page,
      status,
      collaboratorName,
    ],
    queryFn: () => getVacations({
      currentPage: Number(page),
      pageSize: 10,
      collaboratorName,
      status: status as VacationStatus | 'all'
    })
  })

  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }


  const {
    data: vacationsDataReportResult,
    isLoading: isLoadingVacationsDataReportResult
  } = useQuery({
    queryKey: [
      'vacations-report',
      page,
      status,
      collaboratorName,
      vacationsResult?.meta?.count
    ],
    queryFn: () => getVacations({
      currentPage: 1,
      pageSize: vacationsResult?.meta?.count,
      collaboratorName,
      status: ["active", "planning", "planned", "paying", "waiting", "on-vacation", "done", "dismissed"].includes(status) ? status as VacationStatus : 'all'
    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: vacationsResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: vacationsResult?.meta?.pageSize
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const csvReportProps = {
    data: vacationsDataReportResult ? formatValuesToReport(vacationsDataReportResult?.vacations) : [],
    headers,
    filename: `ferias.csv`,
  }

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Férias</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar kanban"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => history.push('/ferias/kanban')}
          />
        </Flex>
      </Flex>

      <VacationsTableFilters />

      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Nome</Th>
              <Th color="white">Data limite para gozo</Th>
              <Th color="white">Status</Th>
              <Th></Th>

            </Tr>
          </Thead>
          <Tbody>
            {vacationsResult?.vacations?.map((vacation) => {
              return <VacationsTableRow key={vacation.id} vacation={vacation} />
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {!vacationsDataReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingVacationsDataReportResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
