import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface ValidateTestOnboardingBody{
  llmTrainingThemes: {
    theme: string
    status: string
  }[]
}

interface ValidateTestOnboardingParams {
  onboardingId: string
  body: ValidateTestOnboardingBody
}

export async function validateTestOnboarding({
  onboardingId,
  body
}: ValidateTestOnboardingParams) {
  try {
    await api.patch(`/onboardings/${onboardingId}/validate-test`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
