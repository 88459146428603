export function validateHasFile(file: FileList[]) {
  if (!file?.length) return false

  return true
}

export function validateFileType(files: FileList, supportedFilesFormats: string[]) {
  const arrayFile = []
  if (files?.length) {
    Object.entries(files).forEach(([key, file])=> {
      if(!supportedFilesFormats.includes(file.type)){
        arrayFile.push(file)
      }
    })
  }
  if(arrayFile.length){
    return false
  }

  return true
}

export function validateFileSize(files: FileList) {
  const arrayFile = []

  if (files?.length) {
    Object.entries(files).forEach(([key, file])=> {
      return file.size / 1024 / 1024 <= 15
    })
  }
  if(arrayFile.length){
    return false
  }
  return true
}
