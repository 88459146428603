import { differenceInMinutes } from "date-fns"

export function compareComunicationDeadlineWithDateNow(value?: string) {
  if (value) {
    const differenceInMinutesToNow = differenceInMinutes(
      new Date(value),
      new Date()
    )

    if (differenceInMinutesToNow < 15 || (differenceInMinutesToNow / 60) > 168) {
      return false
    }

    return true
  }

  return false
}
