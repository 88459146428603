import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Radio, RadioGroup, Stack, Text, Textarea } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { set } from "date-fns";
import { useToastify } from "hooks/toastify/useToastify";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { completeVisaQualityAudit } from "api/audits/completeVisaQualityAudit";


interface AuditQualityVisaProps {
  auditId: string
}

interface AuditQualityVisaSchema {
  realizedDate: string
  hasTrainingSchedule: string
  hasAuditsSchedule: string
  hasUpdatedDocumentThatStandardizesCriticalDocuments: string
  hasUpdatedOrganizationalChart: string
  hasCargoAndFunctionsDetail: string
  hasAuditProcessDocument: string
  hasTrainingProcessDocument: string
  hasCleaningDesinfectionDocument: string
  hasBiossecurityRulesDocument: string
  hasCustomerPopPatternDocument: string
  nonCompliance: string
}

const auditQualityVisaSchema = yup.object({
  realizedDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  hasTrainingSchedule: yup.string().required(),
  hasAuditsSchedule: yup.string().required(),
  hasUpdatedDocumentThatStandardizesCriticalDocuments: yup.string().required(),
  hasUpdatedOrganizationalChart: yup.string().required(),
  hasCargoAndFunctionsDetail: yup.string().required(),
  hasAuditProcessDocument: yup.string().required(),
  hasTrainingProcessDocument: yup.string().required(),
  hasCleaningDesinfectionDocument: yup.string().required(),
  hasBiossecurityRulesDocument: yup.string().required(),
  hasCustomerPopPatternDocument: yup.string().required(),
  nonCompliance: yup.string(),
})
export function AuditQualityVisa({
  auditId
}: AuditQualityVisaProps) {
  const { promiseMessage } = useToastify()

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<AuditQualityVisaSchema>({
    resolver: yupResolver(auditQualityVisaSchema)
  })

  const queryClient = useQueryClient()

  const { mutateAsync: completeVisaQualityAuditFn } = useMutation({
    mutationFn: completeVisaQualityAudit,
    onSuccess() {
      queryClient.invalidateQueries(['audits'])
      queryClient.invalidateQueries({ queryKey: 'pending-audits' })
      queryClient.invalidateQueries({ queryKey: 'approved-audits' })
      queryClient.invalidateQueries({ queryKey: 'reproved-audits' })
      queryClient.invalidateQueries({ queryKey: ['audit', auditId] })
    }
  })

  async function handleAuditQualityVisa(values: AuditQualityVisaSchema) {

    const convertValuesAuditQualityVisa = {
      hasTrainingSchedule: values.hasTrainingSchedule === 'yes',
      hasAuditsSchedule: values.hasAuditsSchedule === 'yes',
      hasUpdatedDocumentThatStandardizesCriticalDocuments: values.hasUpdatedDocumentThatStandardizesCriticalDocuments === 'yes',
      hasUpdatedOrganizationalChart: values.hasUpdatedOrganizationalChart === 'yes',
      hasCargoAndFunctionsDetail: values.hasCargoAndFunctionsDetail === 'yes',
      hasAuditProcessDocument: values.hasAuditProcessDocument === 'yes',
      hasTrainingProcessDocument: values.hasTrainingProcessDocument === 'yes',
      hasCleaningDesinfectionDocument: values.hasCleaningDesinfectionDocument === 'yes',
      hasBiossecurityRulesDocument: values.hasBiossecurityRulesDocument === 'yes',
      hasCustomerPopPatternDocument: values.hasCustomerPopPatternDocument === 'yes',
    };

    await promiseMessage(completeVisaQualityAuditFn({
      body: {
        realizedDate: values.realizedDate,
        nonCompliance: values.nonCompliance,
        ...convertValuesAuditQualityVisa,
      },
      auditId
    }), 'Auditoria realizada!')
  }

  return (
    <Box
      w="full"
      as="form"
      maxH='500px'
      overflowY='scroll'
      onSubmit={handleSubmit(handleAuditQualityVisa)}
    >
      <Heading letterSpacing="tight" size='sm'>Auditoria Qualidade VISA</Heading>
      <FormControl isInvalid={!!errors?.realizedDate} mt={3}>
        <FormLabel fontSize="sm">
          Data realizada
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('realizedDate')}
          name="realizedDate"
          type="date"
          size="sm"
          rounded="md"
          w={["full", "full", "sm"]}
        />
      </FormControl>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasTrainingSchedule}>
            <FormLabel>
              Existe cronograma de treinamentos?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasTrainingSchedule"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasAuditsSchedule}>
            <FormLabel>
              Existe cronograma de auditorias?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasAuditsSchedule"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasUpdatedDocumentThatStandardizesCriticalDocuments}>
            <FormLabel>
              Existe documento atualizado que padronize os documentos críticos?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasUpdatedDocumentThatStandardizesCriticalDocuments"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasUpdatedOrganizationalChart}>
            <FormLabel>
              Existe organograma atualizado?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasUpdatedOrganizationalChart"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasCargoAndFunctionsDetail}>
            <FormLabel>
              Existe descrição de cargos e funções?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasCargoAndFunctionsDetail"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasAuditProcessDocument}>
            <FormLabel>
              Existe documento que defina o processo de auditoria?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasAuditProcessDocument"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasTrainingProcessDocument}>
            <FormLabel>
              Existe documento que defina o processo de treinamento?

              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasTrainingProcessDocument"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasCleaningDesinfectionDocument}>
            <FormLabel>
              Existe documento que descreve a limpeza e desinfecção?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasCleaningDesinfectionDocument"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasBiossecurityRulesDocument}>
            <FormLabel>
              Existe documento que defina as regras de biossegurança?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasBiossecurityRulesDocument"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasCustomerPopPatternDocument}>
            <FormLabel>
              Existe documento padrão de POP do Cliente?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasCustomerPopPatternDocument"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <FormControl isInvalid={!!errors.nonCompliance} mt={3}>
        <FormLabel>
          Não conformidade
        </FormLabel>
        <Textarea
          {...register('nonCompliance')}
          placeholder="Descreva a não conformidade..."
          size='sm'
          rounded='md'
        />
      </FormControl>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Auditar
        </Button>
      </Flex>

    </Box>
  )
}
