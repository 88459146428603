import { createContext, ReactNode } from 'react'
import { api } from '../services/api'

import 'react-toastify/dist/ReactToastify.css'
import swal from 'sweetalert'

interface AllocatedServiceProviderProps {
  children: ReactNode
  pathname?: string
}

export interface AllocatedServiceProps {
  id: string
  collector_id: string | null
  service_id: string
  allocate_flight: string | null
  allocate_availability_date: string
  allocate_availability_hour: string
  airline_information: string
  observation: string
}

type AllocatedServiceInput = Omit<AllocatedServiceProps, 'id'>

interface AllocatedServiceContextProps {
  createAllocatedService: (
    allocatedServiceInput: AllocatedServiceInput,
  ) => Promise<boolean | undefined>
  editCurrentAllocatedService: (
    serviceId: string,
    allocatedServiceInput: AllocatedServiceInput,
  ) => Promise<boolean | undefined>
}

export const AllocatedServiceContext =
  createContext<AllocatedServiceContextProps>(
    {} as AllocatedServiceContextProps,
  )

export function AllocatedServiceProvider({
  children,
  pathname,
}: AllocatedServiceProviderProps) {
  async function createAllocatedService(
    allocatedServiceInput: AllocatedServiceInput,
  ) {
    try {
      const response = await api.post(
        '/allocate-service',
        allocatedServiceInput,
      )
      if (response) {
        swal('Poof! O serviço foi alocado com sucesso!', {
          icon: 'success',
        })
        return true
      }
    } catch (error: any) {
      swal('Erro!', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function editCurrentAllocatedService(
    serviceId: string,
    allocatedServiceInput: AllocatedServiceInput,
  ) {
    try {
      await api.put(
        `in-progress/allocate-service/${serviceId}`,
        allocatedServiceInput,
      )
      await swal('Poof! O serviço alocado foi atualizado com sucesso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro!', `${error.response.data.message}`, 'error')
      return false
    }
  }

  return (
    <AllocatedServiceContext.Provider
      value={{ createAllocatedService, editCurrentAllocatedService }}
    >
      {children}
    </AllocatedServiceContext.Provider>
  )
}
