import axios from "axios";
import { useMutation } from "react-query";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface ICustomerContacts {
  name: string
  email: string
  phone: string
  description: string
  type: string[]
}

interface CreateCustomerReqFunctionProps {
  type: string
  situation: string
  trading_firstname: string
  company_lastname: string
  attendance_responsible: string
  cnpj_cpf: string
  cost_center: string
  deadline_delivery: string
  operational_email: string
  financial_email: string
  cellphone: string
  telephone: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  state: string
  city: string
  municipal_register: string
  state_register: string
  icms: string
  iss: string
  payment_conditional: string
  day_expiration_1: string
  day_expiration_2: string
  payment_type: string
  emission_type: string
  billing_type: string
  custom_billing_day: number
  lifebox_payment_term: string
  has_waiting_time: boolean
  observation: string
  contacts: ICustomerContacts[]
}

async function createCustomerReqFunction(input: CreateCustomerReqFunctionProps) {
  try {
    const { data } = await api.post(`/customer`, input)


    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

interface UpdateCustomerAttachmentsReqFunctionProps {
  customerId: string
  input: FormData
}

async function updateCustomerAttachmentsReqFunction({
  customerId,
  input
}: UpdateCustomerAttachmentsReqFunctionProps) {
  try {
    await api.patch(`/customer/${customerId}/attachment`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

interface DeleteCustomerAttachmentReqFunctionProps {
  customerId: string
  filename: string
}

async function deleteCustomerAttachmentReqFunction({
  customerId,
  filename
}: DeleteCustomerAttachmentReqFunctionProps) {
  try {
    await api.patch(`/customer/${customerId}/attachment/${filename}/delete`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

export function useCustomerFunctions() {

  const createCustomer = useMutation(
    'createCustomer', createCustomerReqFunction
  )
  const updateCustomerAttachments = useMutation(
    'updateCustomerAttachments', updateCustomerAttachmentsReqFunction
  )
  const deleteCustomerAttachment = useMutation(
    'deleteCustomerAttachment', deleteCustomerAttachmentReqFunction
  )

  return { createCustomer, updateCustomerAttachments, deleteCustomerAttachment }
}
