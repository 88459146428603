import { GeneralProviderResponse } from './dtos/GeneralProvider';
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";

type QueryOptions = UseQueryOptions<GeneralProviderResponse, unknown, GeneralProviderResponse, QueryKey>

interface GeneralProviderQueryParams {
  q?: string
  situation?: string
  page_size?: number
  current_page?: number
}

interface UseGeneralProviderOptions {
  queryKey?: string
  queryOptions?: QueryOptions
  queryParams?: GeneralProviderQueryParams
}

export function useGeneralProviders(options?: UseGeneralProviderOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getGeneralProviders(queryParams?: GeneralProviderQueryParams) {
    try {
      const { data } = await api.get('/general-providers', {
        params: {
          ...queryParams,
        }
      })

      return data

    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new Error("Erro inesperado!")
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([options?.queryKey || 'generalProviders', {...options?.queryParams}], ()=> getGeneralProviders(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
