import { useMutation } from 'react-query'
import {
  generateServiceWithdrawAuthorizationReqFunction,
  GenerateWithdrawAuthorizationProps
} from '../../../utils/RequestFunctions/Service/WithdrawAuthorization/generateServiceWithdrawAuthorizationReqFunctions'
import { useSwal } from '../../swal/useSwal'

export const useGenerateWithdrawAuthorizationFunctions = () => {
  const { errorMessage } = useSwal()

  const generateWithdrawAuthorization = useMutation(
    'generateWithdrawAuthorization',
    ({ input, service_id }: GenerateWithdrawAuthorizationProps) => generateServiceWithdrawAuthorizationReqFunction({ input, service_id }),
    {
      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  return {
    generateWithdrawAuthorization,
  }
}
