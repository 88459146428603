import { Fade, List, ListItem, ListItemProps, useMediaQuery } from "@chakra-ui/react"
import { useAuth } from "hooks/auth/useAuth"
import { ReactNode, useEffect, useState } from "react"
import { normalizeText, SubMenuOptionProps } from "../Sidebar"
import { SubmenuContent } from "./SubmenuContent"

interface SubMenuProps extends ListItemProps, SubMenuOptionProps {
  children?: ReactNode
  isExpanded?: boolean
  isTrigger?: boolean
  filter?: string
  onSetIsExpanded: () => void
}

export const Submenu = ({
  onSetIsExpanded,
  isExpanded,
  icon,
  name,
  subMenus,
  children,
  type,
  href,
  allowedPermissions,
  isTrigger,
  filter,
  ...rest
}: SubMenuProps) => {
  const [isWideVersion] = useMediaQuery('(min-width: 1024px)')

  const [isOpen, setIsOpen] = useState(false)

  const hasSubmenu = subMenus?.length > 0

  useEffect(() => {
    if (filter && filter.trim() !== '') {
      const normalizedFilter = normalizeText(filter);
      const normalizedName = normalizeText(name);

      const nameMatch = normalizedName
        .includes(normalizedFilter);

      const subMenuMatch = subMenus?.some(subMenu =>
        normalizeText(subMenu.name)
          .includes(normalizedFilter)
      );

      if (nameMatch || subMenuMatch) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    } else {
      setIsOpen(false)
    }
  }, [filter, isExpanded, name, subMenus]);

  const handleMenuAction = () => {
    if (hasSubmenu) {
      setIsOpen(!isOpen)
      if (!isExpanded) onSetIsExpanded()
    }



    if (!hasSubmenu && !isWideVersion && ['internal', 'external'].includes(type)) {
      onSetIsExpanded()
    }
  }

  const { userLogged } = useAuth()

  const hasPermission = (userPermissions: string[], requiredPermissions: string[]): boolean => {
    return requiredPermissions?.some(permission => userPermissions.includes(permission));
  };

  const canAccessSubMenu = (subMenu: SubMenuOptionProps, userPermissions: string[]): boolean => {
    if (!subMenu.allowedPermissions && subMenu.subMenus) {
      return subMenu.subMenus.some(subMenu => canAccessSubMenu(subMenu, userPermissions));
    }

    if (subMenu?.allowedPermissions) {
      return hasPermission(userPermissions, subMenu?.allowedPermissions);
    }

    return true;
  };

  const canAccessMenu = (menu: SubMenuOptionProps, userPermissions: string[]): boolean => {
    return canAccessSubMenu(menu, userPermissions) || menu.subMenus?.some(subMenu => canAccessSubMenu(subMenu, userPermissions));
  };

  const userCanAccessMenu = canAccessMenu(
    {
      name,
      allowedPermissions,
      icon,
      subMenus,
      children,
      href,
      type
    },
    userLogged?.permissions ?? []);


  if (!userCanAccessMenu) {
    return <></>
  }

  return (
    <>
      <ListItem
        w="full"
        display="flex"
        align="center"
        justifyContent="space-between"
        onClick={handleMenuAction}
        position="relative"
        rounded="md"
        pb="4"
        pl="4"
        cursor="pointer"
        lineHeight="1"
        color='blackAlpha.900'
        transition="all 0.2s"
        opacity={isOpen ? '1' : '0.9'}
        _hover={{
          opacity: "1"
        }}
        role="group"
        {...rest}
      >
        <SubmenuContent
          hasSubmenu={hasSubmenu}
          isExpanded={isExpanded}
          isOpen={isOpen}
          name={name}
          href={href}
          icon={icon}
          type={type}
        >
          {children}
        </SubmenuContent>
      </ListItem>

      {hasSubmenu && isOpen && (
        <Fade in={isOpen}>
          <List
            ml="4"
            spacing="2"
          >
            {subMenus.map((option) => {
              return (
                <Submenu
                  key={option.name}
                  onSetIsExpanded={onSetIsExpanded}
                  isExpanded={isExpanded}
                  position="relative"
                  _last={{
                    _after: {
                      top: '-50%',
                      height: '80%'
                    },
                  }}
                  _notLast={{
                    _before: {
                      content: '""',
                      top: '-20px',
                      bottom: '0',
                      width: '0',
                      bg: 'gray.300',
                      borderLeft: '2px solid',
                      borderColor: "gray.200",
                      left: '0',
                      position: 'absolute',
                      display: "block"
                    }
                  }}
                  _after={{
                    content: "''",
                    position: 'absolute',
                    left: '0',
                    top: '-20%',
                    height: '50%',
                    width: '8px',
                    borderBottom: '2px solid',
                    borderLeft: '2px solid',
                    borderColor: "gray.200",
                    borderRadius: '0 0 0 .4em'
                  }}
                  filter={filter}
                  {...option}
                >
                  {option?.children}
                </Submenu>
              )
            })}
          </List>
        </Fade>
      )}
    </>
  )
}
