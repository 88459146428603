import { usePagination } from "@ajna/pagination"
import { Box, Button, Divider, Flex, Heading, Icon, IconButton, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";

import { Pagination } from "components/Pagination/Pagination"
import { FiPlus } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";

import { useEffect, useReducer, useState } from "react";
import { useAuth } from "hooks/auth/useAuth";
import { useAwaitingQuotePurchaseOrders } from "hooks/purchaseOrder/useAwaitingQuotePurchaseOrders";
import { formatDate } from "utils/DateFunctions/formatDate";
import { FaEllipsisH, FaEye, FaFileInvoiceDollar, FaTimes, FaTrash } from "react-icons/fa";
import { usePurchaseOrderFunctions } from "hooks/purchaseOrder/usePurchaseOrderFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { useQueryClient } from "react-query";
import { ViewPurchaseOrderModal } from "./components/ViewPurchaseOrderModal";
import { PurchaseOrder } from "hooks/purchaseOrder/dtos/PurchaseOrder";
import { RefusePurchaseOrderModal } from "./components/RefusePurchaseOrderModal";

interface ActionPayload {
  currentPage?: number
}

interface Action {
  type: 'set-awaiting-quote-purchase-order-current-page'
  payload: ActionPayload
}

function reducer(state: ActionPayload, action: Action) {
  if (action.type === 'set-awaiting-quote-purchase-order-current-page') {
    return {
      currentPage: action.payload.currentPage
    }
  }
  return {
    ...state,
    ...action.payload
  }
}

export function PurchaseOrdersAwaitingQuote() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()
  const { promiseMessage } = useToastify()

  const queryClient = useQueryClient()

  const [purchaseOrder, setPurchaseOrder] = useState({} as PurchaseOrder)

  const [listAwaitingQuoteDataState, dispatch] = useReducer(
    reducer,
    {} as ActionPayload
  )

  const userHasPermissionToViewPurchaseOrdersAwaitingQuote =
    userLogged.permissions.includes('view-purchase-orders-awaiting-quote')

  useEffect(() => {
    if (!userHasPermissionToViewPurchaseOrdersAwaitingQuote) {
      redirect('/')
    }
  }, [userHasPermissionToViewPurchaseOrdersAwaitingQuote, redirect])

  const { data: purchaseOrdersAwaitingQuoteResponseData, isFetching: isFetchingpurchaseOrdersData } = useAwaitingQuotePurchaseOrders({
    queryParams: {
      currentPage: listAwaitingQuoteDataState.currentPage,
      pageSize: Number(rowsPerPage)
    },
    queryOptions: {
      enabled: !!listAwaitingQuoteDataState.currentPage
    }
  })

  const { deletePurchaseOrder: { mutateAsync: deletePurchaseOrder } } = usePurchaseOrderFunctions()

  async function handleDeletePurchaseOrder(purchaseOrder: PurchaseOrder) {
    await promiseMessage(deletePurchaseOrder(purchaseOrder.id, {
      onSuccess: () => {
        queryClient.invalidateQueries(['purchaseOrdersAwaitingQuote'])
      }
    }), 'Pedido de compra excluído com sucesso!')
  }

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: purchaseOrdersAwaitingQuoteResponseData?.totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  useEffect(() => {
    dispatch({
      type: 'set-awaiting-quote-purchase-order-current-page',
      payload: {
        currentPage
      }
    })
  }, [currentPage])

  const {
    isOpen: isViewPurchaseOrderModalOpen,
    onOpen: onOpenViewPurchaseOrderModal,
    onClose: onCloseViewPurchaseOrderModal,
  } = useDisclosure()

  const {
    isOpen: isRefusePurchaseOrderModalOpen,
    onOpen: onOpenRefusePurchaseOrderModal,
    onClose: onCloseRefusePurchaseOrderModal,
  } = useDisclosure()

  const handleOpenViewPurchaseOrderModal = (purchaseOrder: PurchaseOrder) => {
    setPurchaseOrder(purchaseOrder)
    onOpenViewPurchaseOrderModal()
  }

  const handleRefusePurchaseOrderModal = (purchaseOrder: PurchaseOrder) => {
    setPurchaseOrder(purchaseOrder)
    onOpenRefusePurchaseOrderModal()
  }

  const handleQuotePurchaseOrder = (purchaseOrder: PurchaseOrder) => {
    redirect(`/pedido-compra/${purchaseOrder.id}/cotar`)
  }

  const handleChangePage = (page: number) => setCurrentPage(page)

  const purchaseOrderActionsOptions = [
    {
      type: 'Visualizar',
      permission: 'view-purchase-orders-awaiting-quote',
      icon: FaEye,
      handle: handleOpenViewPurchaseOrderModal,
    },
    {
      type: 'Cotar',
      permission: 'quote-purchase-order',
      icon: FaFileInvoiceDollar,
      handle: handleQuotePurchaseOrder
    },
    {
      type: 'Recusar',
      permission: 'refuse-purchase-order',
      icon: FaTimes,
      handle: handleRefusePurchaseOrderModal
    },
    {
      type: 'Excluir',
      permission: 'delete-purchase-order',
      icon: FaTrash,
      handle: handleDeletePurchaseOrder
    },
  ]

  return (
    <>
      <ViewPurchaseOrderModal
        onClose={onCloseViewPurchaseOrderModal}
        isOpen={isViewPurchaseOrderModalOpen}
        purchaseOrder={purchaseOrder}
      />
      <RefusePurchaseOrderModal
        onClose={onCloseRefusePurchaseOrderModal}
        isOpen={isRefusePurchaseOrderModalOpen}
        purchaseOrderId={purchaseOrder.id}
      />
      <Box
        borderRadius='8px'
        p={4}
        bg='white'
      >
        {isFetchingpurchaseOrdersData ? <Spinner /> :
          <Flex
            w="full"
            direction="column"
            gap={6}
          >
            <Flex mb="8" justify="space-between" align="center">
              <Heading fontSize="xl">Pedidos de Compra Aguardando Cotação</Heading>
              <Link to="/pedido-compra/criar">
                <Button
                  colorScheme="gray"
                  rightIcon={<Icon as={FiPlus} />}
                  size="sm"
                >
                  Adicionar Pedido de Cotação
                </Button>

              </Link>
            </Flex>
            <Divider />
            <TableContainer>
              <Table variant="striped" size="sm">
                <Thead>
                  <Tr>
                    <Th>PROTOCOLO</Th>
                    <Th>SOLICITANTE</Th>
                    <Th>DATA SOLICITAÇÃO</Th>
                    <Th>DATA LIMITE</Th>
                    <Th>TIPO</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {purchaseOrdersAwaitingQuoteResponseData?.purchaseOrders && purchaseOrdersAwaitingQuoteResponseData?.purchaseOrders.map(purchaseOrder => {
                    return (
                      <Tr key={purchaseOrder.id}>
                        <Td>{purchaseOrder.protocol}</Td>
                        <Td>{`${purchaseOrder.owner.firstname} ${purchaseOrder.owner.lastname}`}</Td>
                        <Td>{formatDate.handle(purchaseOrder.created_at, 'DateWithoutHourToShow')}</Td>
                        <Td>{formatDate.handle(purchaseOrder.arrival_limit_date, 'DateWithoutHourToShow')}</Td>
                        <Td>{purchaseOrder.type}</Td>
                        <Td isNumeric>
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              variant='ghost'
                              aria-label='Options'
                              icon={<FaEllipsisH />}
                            />
                            <MenuList>
                              <MenuGroup title="Ações">
                                {purchaseOrderActionsOptions.filter((action) => userLogged?.permissions.includes(action.permission)).map((action) => {
                                  return (
                                    <MenuItem
                                      key={action.type}
                                      onClick={() => action.handle(purchaseOrder)}
                                      display="flex"
                                      alignItems="center"
                                      gap="2"
                                    >
                                      <Icon as={action.icon} />
                                      {action.type}
                                    </MenuItem>

                                  )
                                })}
                              </MenuGroup>
                            </MenuList>
                          </Menu>

                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <Pagination
              currentPage={currentPage}
              pages={pages}
              pagesQuantity={pagesCount}
              handlePageChange={handleChangePage}
            />
          </Flex>
        }
      </Box>
    </>
  )
}
