import { Button, Flex, FormControl, FormLabel, Input, Stack } from "@chakra-ui/react";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { validateHasFile } from "utils/fileValidation";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { useForm, } from "react-hook-form";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";

import { FaCloudUploadAlt } from "react-icons/fa";
import { addAttachmentInContingency } from "api/contingencies/addAttachmentInContingency";

interface AddAttachmentProps {
  contingencyId: string
}


export interface AddAttachmentContingencySchema {
  attachmentId: FileList
}

const addAttachmentContingencySchema = yup.object({
  attachmentId: yup.mixed()
    .test(value => validateHasFile(value)).required()
    .test(function (value) {
      const { hasAttachmentId } = this.options.context;
      return value && hasAttachmentId;
    }),

})

export function AddAttachmentContingency({ contingencyId }: AddAttachmentProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const { mutation: uploadAttachmentFn } = useAttachmentMutation()


  const {
    register,
    unregister,
    formState: {
      errors
    }
  } = useForm<AddAttachmentContingencySchema>({
    resolver: yupResolver(addAttachmentContingencySchema),
    context: {
      hasAttachmentId: !!uploadAttachmentFn?.data?.attachment?.id
    }
  })

  const { mutateAsync: addAttachmentInContingencyFn } = useMutation({
    mutationFn: addAttachmentInContingency,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['contingency', contingencyId]
      })

      queryClient.invalidateQueries({ queryKey: 'contingencies' })

    }
  })


  const [uploadedAttachmentId, setUploadedAttachmentId] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAddAttachment = useCallback(async () => {
    setIsSubmitting(true)
    await promiseMessage(addAttachmentInContingencyFn({
      attachmentId: uploadAttachmentFn?.data?.attachment?.id,
      contingencyId
    }, {
      onSuccess() {
        setIsSubmitting(false)
      },
      onError() {
        setIsSubmitting(false)
      }
    }), 'Anexo adicionado na contigência!');
  }, [addAttachmentInContingencyFn, contingencyId, promiseMessage, uploadAttachmentFn?.data?.attachment?.id]);


  useEffect(() => {
    if (uploadAttachmentFn?.data?.attachment?.id && uploadedAttachmentId !== uploadAttachmentFn?.data?.attachment.id) {
      handleAddAttachment()
      setUploadedAttachmentId(uploadAttachmentFn.data.attachment.id);
      unregister('attachmentId');
    }
  }, [uploadAttachmentFn, unregister, uploadedAttachmentId, handleAddAttachment]);

  async function handleUploadAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadAttachmentFn.mutateAsync(formData)

  }

  return (
    <Flex
      w="full"
      justify="flex-end"
    >
      <Stack direction="column">
        <Button
          as={FormLabel}
          htmlFor="attachmentId"
          lineHeight="1"
          leftIcon={<FaCloudUploadAlt />}
          size="sm"
          w="min"
          cursor="pointer"
          border={!!errors?.attachmentId && '2px solid'}
          borderColor={!!errors?.attachmentId && 'red.500'}
          colorScheme="blue"
        >
          {isSubmitting ? 'Carregando...' : 'Carregar'}
        </Button>
        <FormControl isInvalid={!!errors?.attachmentId}>
          <Input
            {...register('attachmentId')}
            name="attachmentId"
            id="attachmentId"
            type="file"
            hidden
            onChangeCapture={handleUploadAttachment}
          />
        </FormControl>
      </Stack>
    </Flex>
  )
}
