import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface ProceedVacationBody {
  isSignedReceipt: 'yes' | 'no'
  receiptAttachmentId?: string
}

interface ProceedVacationProps {
  vacationId: string
  body: ProceedVacationBody
}

export async function proceedVacation({
  body,
  vacationId
}: ProceedVacationProps) {
  try {
    const response = await api.put(`/vacations/${vacationId}/proceed`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
