import { Box, Button, Flex, FormControl, FormLabel, Heading, Text, Textarea, VStack } from "@chakra-ui/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";

import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useReducer } from "react";

import { AttachmentTableRowField } from "./AttachmentTableRowField";
import { addActivityRefund } from "api/refunds/addActivityRefund";

interface AddActivityRefundProps {
  refundId: string
}

export interface AddActivityRefundSchema {
  observations: string
  attachments: {
    file: FileList
  }[]
}

const addActivitySchema = yup.object({
  observations: yup.string().required(),
  attachments: yup.array().min(1),
})
interface SetAttachmentIdActionPayload {
  attachmentId: string
}

export interface SetAttachmentIdAction {
  type: 'ADD' | 'DELETE'
  payload: SetAttachmentIdActionPayload
}

function reducer(state: string[], action: SetAttachmentIdAction) {
  if (action.type === 'ADD') {
    state.push(action.payload.attachmentId)
  }

  if (action.type === 'DELETE') {
    const attachmentIndex = state.findIndex(attachmentId => attachmentId === action.payload.attachmentId)

    state.splice(attachmentIndex, 1)
  }


  return state
}



export function AddActivityRefund({ refundId }: AddActivityRefundProps) {
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const [attachmentsIds, setAttachmentId] = useReducer(reducer, [])

  const formMethods = useForm<AddActivityRefundSchema>({
    resolver: yupResolver(addActivitySchema),
    defaultValues: {
      attachments: [{ file: undefined }]
    }
  })

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: {
      errors,
      isSubmitting
    }
  } = formMethods


  const {
    fields: attachmentsFields,
    append: appendAttachmentField,
    remove: removeAttachmentField,
  } = useFieldArray({
    control,
    name: 'attachments'
  })

  const attachments = useWatch({
    control,
    name: 'attachments'
  })

  const { mutateAsync: addActivityRefundFn } = useMutation({
    mutationFn: addActivityRefund,
    onSuccess() {

      queryClient.invalidateQueries({ queryKey: 'pending-refunds' })
      queryClient.invalidateQueries({ queryKey: 'approved-refunds' })
      queryClient.invalidateQueries({ queryKey: 'reproved-refunds' })
      queryClient.invalidateQueries({ queryKey: ['refund', refundId] })

      reset({
        observations: "",
        attachments: [{ file: undefined }],
      });

    }
  })

  async function handleAddActivityRefund(values: AddActivityRefundSchema) {
    await promiseMessage(addActivityRefundFn({
      body: {
        observations: values.observations ? values.observations : null,
        attachmentsIds
      }, refundId
    }), 'Tratativa realizada!')
  }


  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleAddActivityRefund)}
    >
      <Heading size='sm' letterSpacing="tight">Tratativas</Heading>
      <FormLabel fontSize='sm' mt={3}>
        Anexos
        <Text as="sup" color="red.500">*</Text>
      </FormLabel>
      <VStack spacing={3}>
        {attachmentsFields.map((field, index) => {

          const isFilledField = Boolean(attachments[index]?.file?.length)

          return (

            <FormProvider {...formMethods}>
              <AttachmentTableRowField
                key={field.id}
                index={index}
                onSetAttachmentId={setAttachmentId}
                isFilledField={isFilledField}
                onAppendField={() => appendAttachmentField({})}
                onRemoveField={() => removeAttachmentField(index)}
              />
            </FormProvider>
          )
        })}
      </VStack>
      <FormControl isInvalid={!!errors?.observations} mt={3}>
        <FormLabel fontSize="sm">
          Observações
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register('observations')}
          name="observations"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
