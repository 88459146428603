import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { getInvoices } from "api/invoices/getInvoices";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { FaExchangeAlt, FaPlus } from "react-icons/fa";
import { useInfiniteQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreateInvoice } from "./components/CreateInvoice";
import { KanbanInvoiceColumn } from "./components/KanbanInvoiceColumn";

export function InvoicesKanban() {

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanViewInvoices = userLogged?.permissions?.includes(
    'view-invoices'
  )

  const userCanCreateInvoice = userLogged?.permissions?.includes(
    'create-invoice'
  )

  useEffect(() => {
    if (!userCanViewInvoices) history.push('/')
  }, [history, userCanViewInvoices])

  const {
    isOpen: isCreateInvoiceOpen,
    onOpen: onOpenCreateInvoice,
    onClose: onCloseCreateInvoice
  } = useDisclosure()

  const {
    data: analyzingInvoicesResult,
    isLoading: isAnalyzingInvoicesResultLoading,
    hasNextPage: hasNextPageAnalyzingInvoices,
    fetchNextPage: fetchNextPageAnalyzingInvoices,
    isFetchingNextPage: isFetchingNextPageAnalyzingInvoices,
  } = useInfiniteQuery({
    queryKey: ['invoices-analyzing'],
    queryFn: ({ pageParam = 1 }) => getInvoices({
      currentPage: pageParam,
      pageSize: 10,
      status: 'analyzing',
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined;

      return nextPage;
    },
    keepPreviousData: true,
  });

  const {
    data: checkingInvoiceInvoicesResult,
    isLoading: isCheckingInvoiceInvoicesResultLoading,
    hasNextPage: hasNextPageCheckingInvoiceInvoices,
    fetchNextPage: fetchNextPageCheckingInvoiceInvoices,
    isFetchingNextPage: isFetchingNextPageCheckingInvoiceInvoices,
  } = useInfiniteQuery({
    queryKey: ['invoices-checking-invoice'],
    queryFn: ({ pageParam = 1 }) => getInvoices({
      currentPage: pageParam,
      pageSize: 10,
      status: 'checking-invoice',
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined;

      return nextPage;
    },
    keepPreviousData: true,
  });

  const {
    data: operationalInconsistencyInvoicesResult,
    isLoading: isOperationalInconsistencyInvoicesResultLoading,
    hasNextPage: hasNextPageOperationalInconsistencyInvoices,
    fetchNextPage: fetchNextPageOperationalInconsistencyInvoices,
    isFetchingNextPage: isFetchingNextPageOperationalInconsistencyInvoices,
  } = useInfiniteQuery({
    queryKey: ['invoices-operational-inconsistency'],
    queryFn: ({ pageParam = 1 }) => getInvoices({
      currentPage: pageParam,
      pageSize: 10,
      status: 'operational-inconsistency',
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined;

      return nextPage;
    },
    keepPreviousData: true,
  });

  const {
    data: collectorInconsistencyInvoicesResult,
    isLoading: isCollectorInconsistencyInvoicesResultLoading,
    hasNextPage: hasNextPageCollectorInconsistencyInvoices,
    fetchNextPage: fetchNextPageCollectorInconsistencyInvoices,
    isFetchingNextPage: isFetchingNextPageCollectorInconsistencyInvoices,
  } = useInfiniteQuery({
    queryKey: ['invoices-collector-inconsistency'],
    queryFn: ({ pageParam = 1 }) => getInvoices({
      currentPage: pageParam,
      pageSize: 10,
      status: 'collector-inconsistency',
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined;

      return nextPage;
    },
    keepPreviousData: true,
  });

  const {
    data: approvedInvoiceInvoicesResult,
    isLoading: isApprovedInvoiceInvoicesResultLoading,
    hasNextPage: hasNextPageApprovedInvoiceInvoices,
    fetchNextPage: fetchNextPageApprovedInvoiceInvoices,
    isFetchingNextPage: isFetchingNextPageApprovedInvoiceInvoices,
  } = useInfiniteQuery({
    queryKey: ['invoices-approved-invoice'],
    queryFn: ({ pageParam = 1 }) => getInvoices({
      currentPage: pageParam,
      pageSize: 10,
      status: 'approved-invoice',
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined;

      return nextPage;
    },
    keepPreviousData: true,
  });

  const {
    data: checkingValuesInvoicesResult,
    isLoading: isCheckingValuesInvoicesResultLoading,
    hasNextPage: hasNextPageCheckingValuesInvoices,
    fetchNextPage: fetchNextPageCheckingValuesInvoices,
    isFetchingNextPage: isFetchingNextPageCheckingValuesInvoices,
  } = useInfiniteQuery({
    queryKey: ['invoices-checking-values'],
    queryFn: ({ pageParam = 1 }) => getInvoices({
      currentPage: pageParam,
      pageSize: 10,
      status: 'checking-values',
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined;

      return nextPage;
    },
    keepPreviousData: true,
  });

  const {
    data: checkingCpValueInvoicesResult,
    isLoading: isCheckingCpValueInvoicesResultLoading,
    hasNextPage: hasNextPageCheckingCpValueInvoices,
    fetchNextPage: fetchNextPageCheckingCpValueInvoices,
    isFetchingNextPage: isFetchingNextPageCheckingCpValueInvoices,
  } = useInfiniteQuery({
    queryKey: ['invoices-checking-cp-value'],
    queryFn: ({ pageParam = 1 }) => getInvoices({
      currentPage: pageParam,
      pageSize: 10,
      status: 'checking-cp-value',
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined;

      return nextPage;
    },
    keepPreviousData: true,
  });

  const {
    data: approvedInvoicesResult,
    isLoading: isApprovedInvoicesResultLoading,
    hasNextPage: hasNextPageApprovedInvoices,
    fetchNextPage: fetchNextPageApprovedInvoices,
    isFetchingNextPage: isFetchingNextPageApprovedInvoices,
  } = useInfiniteQuery({
    queryKey: ['invoices-approved'],
    queryFn: ({ pageParam = 1 }) => getInvoices({
      currentPage: pageParam,
      pageSize: 10,
      status: 'approved',
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined;

      return nextPage;
    },
    keepPreviousData: true,
  });

  const {
    data: repprovedInvoicesResult,
    isLoading: isRepprovedInvoicesResultLoading,
    hasNextPage: hasNextPageRepprovedInvoices,
    fetchNextPage: fetchNextPageRepprovedInvoices,
    isFetchingNextPage: isFetchingNextPageRepprovedInvoices,
  } = useInfiniteQuery({
    queryKey: ['invoices-repproved'],
    queryFn: ({ pageParam = 1 }) => getInvoices({
      currentPage: pageParam,
      pageSize: 10,
      status: 'repproved',
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined;

      return nextPage;
    },
    keepPreviousData: true,
  });

  const columnsInvoices = [
    {
      title: 'Aguardando análise',
      invoices: analyzingInvoicesResult?.pages.flatMap((page) => page.invoices) || [],
      invoicesCount: analyzingInvoicesResult?.pages[0]?.meta?.count,
      color: 'blue.300',
      hasNextPage: hasNextPageAnalyzingInvoices,
      fetchNextPage: fetchNextPageAnalyzingInvoices,
      isFetchingNextPage: isFetchingNextPageAnalyzingInvoices,
      isLoading: isAnalyzingInvoicesResultLoading,
    },
    {
      title: 'Conferência de fatura',
      invoices: checkingInvoiceInvoicesResult?.pages.flatMap((page) => page.invoices) || [],
      invoicesCount: checkingInvoiceInvoicesResult?.pages[0]?.meta?.count,
      color: 'yellow.300',
      hasNextPage: hasNextPageCheckingInvoiceInvoices,
      fetchNextPage: fetchNextPageCheckingInvoiceInvoices,
      isFetchingNextPage: isFetchingNextPageCheckingInvoiceInvoices,
      isLoading: isCheckingInvoiceInvoicesResultLoading,
    },
    {
      title: 'Inconsistência operacional',
      invoices: operationalInconsistencyInvoicesResult?.pages.flatMap((page) => page.invoices) || [],
      invoicesCount: operationalInconsistencyInvoicesResult?.pages[0]?.meta?.count,
      color: 'red.300',
      hasNextPage: hasNextPageOperationalInconsistencyInvoices,
      fetchNextPage: fetchNextPageOperationalInconsistencyInvoices,
      isFetchingNextPage: isFetchingNextPageOperationalInconsistencyInvoices,
      isLoading: isOperationalInconsistencyInvoicesResultLoading,
    },
    {
      title: 'Inconsistência do coletador',
      invoices: collectorInconsistencyInvoicesResult?.pages.flatMap((page) => page.invoices) || [],
      invoicesCount: collectorInconsistencyInvoicesResult?.pages[0]?.meta?.count,
      color: 'purple.300',
      hasNextPage: hasNextPageCollectorInconsistencyInvoices,
      fetchNextPage: fetchNextPageCollectorInconsistencyInvoices,
      isFetchingNextPage: isFetchingNextPageCollectorInconsistencyInvoices,
      isLoading: isCollectorInconsistencyInvoicesResultLoading,
    },
    {
      title: 'Fatura aprovada',
      invoices: approvedInvoiceInvoicesResult?.pages.flatMap((page) => page.invoices) || [],
      invoicesCount: approvedInvoiceInvoicesResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageApprovedInvoiceInvoices,
      fetchNextPage: fetchNextPageApprovedInvoiceInvoices,
      isFetchingNextPage: isFetchingNextPageApprovedInvoiceInvoices,
      isLoading: isApprovedInvoiceInvoicesResultLoading,
    },
    {
      title: 'Conferência de valores',
      invoices: checkingValuesInvoicesResult?.pages.flatMap((page) => page.invoices) || [],
      invoicesCount: checkingValuesInvoicesResult?.pages[0]?.meta?.count,
      color: 'cyan.300',
      hasNextPage: hasNextPageCheckingValuesInvoices,
      fetchNextPage: fetchNextPageCheckingValuesInvoices,
      isFetchingNextPage: isFetchingNextPageCheckingValuesInvoices,
      isLoading: isCheckingValuesInvoicesResultLoading,
    },
    {
      title: 'Conferência de valor',
      invoices: checkingCpValueInvoicesResult?.pages.flatMap((page) => page.invoices) || [],
      invoicesCount: checkingCpValueInvoicesResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageCheckingCpValueInvoices,
      fetchNextPage: fetchNextPageCheckingCpValueInvoices,
      isFetchingNextPage: isFetchingNextPageCheckingCpValueInvoices,
      isLoading: isCheckingCpValueInvoicesResultLoading,
    },
    {
      title: 'Aprovado',
      invoices: approvedInvoicesResult?.pages.flatMap((page) => page.invoices) || [],
      invoicesCount: approvedInvoicesResult?.pages[0]?.meta?.count,
      color: 'green.500',
      hasNextPage: hasNextPageApprovedInvoices,
      fetchNextPage: fetchNextPageApprovedInvoices,
      isFetchingNextPage: isFetchingNextPageApprovedInvoices,
      isLoading: isApprovedInvoicesResultLoading,
    },
    {
      title: 'Reprovado',
      invoices: repprovedInvoicesResult?.pages.flatMap((page) => page.invoices) || [],
      invoicesCount: repprovedInvoicesResult?.pages[0]?.meta?.count,
      color: 'red.500',
      hasNextPage: hasNextPageRepprovedInvoices,
      fetchNextPage: fetchNextPageRepprovedInvoices,
      isFetchingNextPage: isFetchingNextPageRepprovedInvoices,
      isLoading: isRepprovedInvoicesResultLoading,
    },
  ];


  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Controle de fatura de coletador e agregado</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar tabela"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => history.push('/controle/fatura/coletadores-e-agregados')}
          />
          {userCanCreateInvoice && (
            <IconButton
              aria-label="Criar fatura"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateInvoice}
            />
          )}
        </Flex>
        <Modal

          isOpen={isCreateInvoiceOpen}
          onClose={onCloseCreateInvoice}
          isCentered
          size="4xl"
        >
          <ModalOverlay />
          <CreateInvoice
            onCloseModal={onCloseCreateInvoice}
          />
        </Modal>
      </Flex>
      <Flex gap={4} overflowX="auto" mt={4}>
        {columnsInvoices?.map((invoice, index) => {
          return (
            <KanbanInvoiceColumn
              key={index}
              invoice={invoice}
            />
          )
        })}
      </Flex>
    </Box>
  )
}
