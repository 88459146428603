import { useToastify } from '../toastify/useToastify'
import { useQuery } from 'react-query'
import { useAuth } from '../auth/useAuth'
import { getAllRncs, getOneRnc } from '../../services/getFunctions/rnc/getRnc'

export function useRnc(
  rncID: string | null,
  isEnable?: boolean,
  refetchOnFocus?: boolean,
) {
  const { handleLogout, userLogged } = useAuth()
  const { errorMessage } = useToastify()

  const rncs = useQuery('rncs', getAllRncs, {
    enabled: !!isEnable && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (e: any) => {
      errorMessage(e.message || 'Erro Inesperado!')
      handleLogout()
    },
  })

  const rnc = useQuery(
    ['rnc', rncID],
    () => getOneRnc(rncID !== null ? rncID : ''),
    {
      enabled: !!rncID && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
        handleLogout()
      },
    },
  )

  return {
    rncs,
    rnc,
  }
}
