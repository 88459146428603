import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Radio, RadioGroup, Stack, Text, Textarea } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { completeLlmHumanResourcesAudit } from "api/audits/completeLlmHumanResourcesAudit";
import { set } from "date-fns";
import { useToastify } from "hooks/toastify/useToastify";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup"

interface AuditRhLlmProps {
  auditId: string
}

interface AuditRhLlmSchema {
  realizedDate: string
  isTimeCardRecordedCorrectly: string
  isFinishedOnboarding: string
  isWithAso: string
  isOpenedSalaryAccount: string
  nonCompliance?: string
}

const auditRhLlmSchema = yup.object({
  realizedDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  isTimeCardRecordedCorrectly: yup.string().required(),
  isFinishedOnboarding: yup.string().required(),
  isWithAso: yup.string().required(),
  isOpenedSalaryAccount: yup.string().required(),
  nonCompliance: yup.string()
})


export function AuditRhLlm({
  auditId
}: AuditRhLlmProps) {

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<AuditRhLlmSchema>({
    resolver: yupResolver(auditRhLlmSchema)
  })

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const { mutateAsync: completeLlmHumanResourcesAuditFn } = useMutation({
    mutationFn: completeLlmHumanResourcesAudit,
    onSuccess() {
      queryClient.invalidateQueries(['audits'])
      queryClient.invalidateQueries({ queryKey: 'pending-audits' })
      queryClient.invalidateQueries({ queryKey: 'approved-audits' })
      queryClient.invalidateQueries({ queryKey: 'reproved-audits' })
      queryClient.invalidateQueries({ queryKey: ['audit', auditId] })
    }
  })
  async function handleAuditRhLlm(values: AuditRhLlmSchema) {
    const convertValuesAuditRhLlm = {
      isTimeCardRecordedCorrectly: values.isTimeCardRecordedCorrectly === 'yes',
      isFinishedOnboarding: values.isFinishedOnboarding === 'yes',
      isWithAso: values.isWithAso === 'yes',
      isOpenedSalaryAccount: values.isOpenedSalaryAccount === 'yes'
    };

    await promiseMessage(completeLlmHumanResourcesAuditFn({
      body: {
        realizedDate: values.realizedDate,
        nonCompliance: values.nonCompliance,
        ...convertValuesAuditRhLlm,
      },
      auditId
    }), 'Auditoria realizada!')
  }

  return (
    <Box
      w="full"
      as="form"
      maxH='500px'
      overflowY='scroll'
      onSubmit={handleSubmit(handleAuditRhLlm)}
    >
      <Heading letterSpacing="tight" size='sm'>Auditoria RH LLM</Heading>

      <FormControl isInvalid={!!errors?.realizedDate} mt={3}>
        <FormLabel fontSize="sm">
          Data realizada
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('realizedDate')}
          name="realizedDate"
          type="date"
          size="sm"
          rounded="md"
          w={["full", "full", "sm"]}
        />
      </FormControl>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isTimeCardRecordedCorrectly}>
            <FormLabel>
              Cartão de ponto batido corretamente?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isTimeCardRecordedCorrectly"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="column">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isFinishedOnboarding}>
            <FormLabel>
              Onboarding finalizado?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isFinishedOnboarding"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="column">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isWithAso}>
            <FormLabel>
              ASO vigente?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isWithAso"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="column">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isOpenedSalaryAccount}>
            <FormLabel>
              Conta salário aberta?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isOpenedSalaryAccount"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="column">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <FormControl isInvalid={!!errors.nonCompliance} mt={3}>
        <FormLabel>
          Não conformidade
        </FormLabel>
        <Textarea
          {...register('nonCompliance')}
          placeholder="Descreva a não conformidade..."
          size='sm'
          rounded='md'
        />
      </FormControl>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Auditar
        </Button>
      </Flex>
    </Box>
  )
}
