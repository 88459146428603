import { Popover, PopoverContent, PopoverArrow, PopoverHeader, PopoverCloseButton, PopoverBody, Flex, PopoverFooter, Button, } from "@chakra-ui/react";
import { Select } from "components/Inputs/SelectInput";
import { ComunicationTypes, InternComunication } from "hooks/internComunication/dtos/InternComunication";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup"
import { useUsers } from "hooks/user/useUsers";
import { useHub } from "hooks/hub/useHub";
import { useInternComunicationsFunctions } from "hooks/internComunication/useInternComunicationsFunctions";
import { useToastify } from "hooks/toastify/useToastify";

interface InternComunicationTransferResponsibleInputs {
  comunicationType: ComunicationTypes
  comunicationResponsible: string
}

const comunicationTypes = [
  { key: 'logisticsDirectionLastMile', value: 'DIRETORIA DE LOGÍSTICA - LAST MILE', showOption: 'DIRETORIA DE LOGÍSTICA - LAST MILE' },
  { key: 'logisticsDirectionPlanning', value: 'DIRETORIA DE LOGÍSTICA - PLANEJAMENTO', showOption: 'DIRETORIA DE LOGÍSTICA - PLANEJAMENTO' },
  { key: "commercialDirection", value: 'DIRETORIA COMERCIAL', showOption: 'DIRETORIA COMERCIAL' },
  { key: "financialDirection", value: 'DIRETORIA FINANCEIRA', showOption: 'DIRETORIA FINANCEIRA' },
  { key: "qualityAndRegulationDirection", value: 'DIRETORIA DE REGULAÇÃO E QUALIDADE', showOption: 'DIRETORIA DE REGULAÇÃO E QUALIDADE' },
  { key: "techDirection", value: 'DIRETORIA DE TECNOLOGIA', showOption: 'DIRETORIA DE TECNOLOGIA' },
  { key: "peopleDirection", value: 'DIRETORIA DE GENTE', showOption: 'DIRETORIA DE GENTE' },
]

const internComunicationTransferResponsibleFormSchema = yup.object().shape({
  comunicationType: yup.string().required("Campo obrigatório"),
  comunicationResponsible: yup.string().required("Campo obrigatório"),
})


interface InternComunicationTransferResponsibleModalProps {
  isOpen: boolean
  onClose: () => void
  initialFocusRef: React.RefObject<HTMLElement> | null;
  onOpen: () => void;
  closeOnBlur: boolean;
  internComunication: InternComunication
}

export function InternComunicationTransferResponsibleModal({
  isOpen,
  onClose,
  onOpen,
  closeOnBlur,
  initialFocusRef,
  internComunication
}: InternComunicationTransferResponsibleModalProps) {

  const budgetHubs: string[] = []

  if (internComunication?.serviceIDComunication?.serviceIDRequested) {
    if (internComunication?.serviceIDComunication?.serviceIDRequested?.budgetIDService.crossdocking_hub_id) {
      budgetHubs.push(
        internComunication?.serviceIDComunication?.serviceIDRequested?.budgetIDService.source_hub_id,
        internComunication?.serviceIDComunication?.serviceIDRequested?.budgetIDService.crossdocking_hub_id,
        internComunication?.serviceIDComunication?.serviceIDRequested?.budgetIDService.destination_hub_id
      )
    } else {
      budgetHubs.push(
        internComunication?.serviceIDComunication?.serviceIDRequested?.budgetIDService.source_hub_id,
        internComunication?.serviceIDComunication?.serviceIDRequested?.budgetIDService.destination_hub_id
      )
    }
  }

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<InternComunicationTransferResponsibleInputs>({
    resolver: yupResolver(internComunicationTransferResponsibleFormSchema)
  })

  const { promiseMessage } = useToastify()

  const {
    transferResponsibleInternComunication: { mutateAsync: transferResponsibleInternComunication }
  } = useInternComunicationsFunctions()

  const [comunicationTypeSelected] = watch([
    "comunicationType",
  ])

  const isComunicationTypeSelected = !!comunicationTypeSelected
  const isOperationalLastMileComunicationType = comunicationTypeSelected === "DIRETORIA DE LOGÍSTICA - LAST MILE"

  const { data: users, isFetching: isFetchingUsers } = useUsers({
    queryParms: { situation: 'ATIVO' }
  })

  const {
    hubs: { data: hubs, isFetching: isHubsFetching }
  } = useHub(null, isOperationalLastMileComunicationType)

  const hubsFilteredByBudget = hubs?.filter(hub => budgetHubs.includes(hub.id))

  const hubsResponsibles = hubsFilteredByBudget?.map(hub => hub.hub_responsible)

  const usersFilteredByComunicationTypeOptions = users?.filter(user => {

    if (comunicationTypeSelected === 'DIRETORIA COMERCIAL') {
      const comercialUserTypes = [
        "SUCESSO DO CLIENTE - COMERCIAL",
        "COMERCIAL - DIRETORIA",
      ]

      return comercialUserTypes.includes(user.user_type)
    }

    if (comunicationTypeSelected === 'DIRETORIA FINANCEIRA') {
      const financeDirectionUserTypes = [
        "ADMINISTRATIVO FINANCEIRO - DIRETORIA",
        "FINANCEIRO - GERAL",
        "ADMINISTRATIVO FINANCEIRO - GERÊNCIA",
        "ADMINISTRATIVO FINANCEIRO - JOVEM APRENDIZ",
        "ADMINISTRATIVO FINANCEIRO - ALMOXARIFADO",
        "ADMINISTRATIVO FINANCEIRO - COMPRAS",
        "ADMINISTRATIVO FINANCEIRO - FINANCEIRO",
      ]

      return financeDirectionUserTypes.includes(user.user_type)
    }

    if (comunicationTypeSelected === 'DIRETORIA DE REGULAÇÃO E QUALIDADE') {
      const qualityAndRegulationDirectionUserTypes = [
        "REGULAÇÃO E QUALIDADE - ANALISTA",
        "REGULAÇÃO E QUALIDADE - DIRETORIA",
        "REGULAÇÃO E QUALIDADE - JOVEM APRENDIZ",
      ]

      return qualityAndRegulationDirectionUserTypes.includes(user.user_type)
    }

    if (comunicationTypeSelected === 'DIRETORIA DE LOGÍSTICA - PLANEJAMENTO') {
      const operationUserTypes = [
        "SUCESSO DO CLIENTE - TORRE DE CONTROLE",
        "OPERAÇÃO - GERÊNCIA",
        "SUCESSO DO CLIENTE - DIRETORIA",
        "SUCESSO DO CLIENTE - TORRE DE CONTROLE",
        "SUCESSO DO CLIENTE - GERÊNCIA",
        "SUCESSO DO CLIENTE - SUPERVISÃO",
        "SUCESSO DO CLIENTE - JOVEM APRENDIZ",
      ]

      return operationUserTypes.includes(user.user_type)
    }

    if (comunicationTypeSelected === "DIRETORIA DE TECNOLOGIA") {
      return user.user_type === "ADMIN"
    }

    if (comunicationTypeSelected === "DIRETORIA DE LOGÍSTICA - LAST MILE") {
      return hubsResponsibles?.includes(user.id)
    }

    if (comunicationTypeSelected === "DIRETORIA DE GENTE") {
      return user.user_type.includes('GENTE')
    }

    return user.user_type.includes(comunicationTypeSelected)
  })
    .map(user => {
      return {
        key: user.id,
        value: user.id,
        showOption: `${user.firstname} ${user.lastname}`
      }
    })

  async function handleTransferResponsibleInternComunication(values: InternComunicationTransferResponsibleInputs) {
    await promiseMessage(transferResponsibleInternComunication({
      internComunicationId: internComunication.id,
      input: values
    }, {
      onSuccess: () => {
        reset()
        onClose()
      }
    }), 'Comunicação interna transferida com sucesso!')
  }

  return (
    <Flex position='absolute' w='64'>
      <Popover
        isOpen={isOpen}
        initialFocusRef={initialFocusRef}
        onOpen={onOpen} onClose={onClose}
        placement="left-start"
        closeOnBlur={closeOnBlur}
        preventOverflow
      >
        <PopoverContent
          mt='10'
        >
          <PopoverArrow />
          <PopoverHeader
            fontSize="md"
            fontWeight="bold"
            border="0"
            letterSpacing="tight"
          >
            Transferir Comunicação
          </PopoverHeader>
          <PopoverCloseButton onClick={onClose} />
          <PopoverBody
            maxH='300px'
            h='full'
            overflow='auto'
          >
            <Flex gap={6} direction="column" p={4}>
              <Select
                {...register("comunicationType")}
                name="comunicationType"
                options={comunicationTypes}
                label="Tipo de comunicação"
                placeholder="Selecione uma opção..."
                error={errors.comunicationType}
                required
              />

              {isComunicationTypeSelected && (
                <Select
                  {...register("comunicationResponsible")}
                  name="comunicationResponsible"
                  options={usersFilteredByComunicationTypeOptions}
                  label="Responsável"
                  placeholder={isFetchingUsers ? "Carregando..." : "Selecione uma opção..."}
                  isDisabled={isFetchingUsers || isHubsFetching}
                  error={errors.comunicationResponsible}
                  required
                />
              )}


            </Flex>
          </PopoverBody>
          <PopoverFooter>
            <Flex gap={2} w='full' justifyContent='flex-end'>
              <Button variant='ghost' onClick={onClose}>
                Fechar
              </Button>
              <Button
                isLoading={isSubmitting}
                type="submit"
                colorScheme='blue'
                onClick={handleSubmit(handleTransferResponsibleInternComunication)}
              >
                Trasferir
              </Button>
            </Flex>

          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </Flex>
  )
}
