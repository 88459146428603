import axios from "axios"
import { api } from "services/api"
import { IDriverProps } from "services/getFunctions/driver/getDrivers"
import { RequestError } from "utils/errors/RequestErrors"
import { Delivery } from "./@types/delivery"


export interface GetDeliveryResponse extends Delivery {
  driverIDDelivery: IDriverProps
  serviceIDDelivery: {
    protocol: number
  }
}


interface GetDeliveryParams {
  deliveryId: string
}

export async function getDelivery({
  deliveryId
}: GetDeliveryParams) {
  try {
    const { data } = await api.get<GetDeliveryResponse>(`/delivery-service/${deliveryId}`)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
