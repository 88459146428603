import { GridItem, GridItemProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface BugCardRootProps extends GridItemProps {
  children: ReactNode
}

export function BugCardRoot({
  children,
  ...rest
}: BugCardRootProps) {
  return (
    <GridItem
      p="2"
      rounded="sm"
      boxShadow="base"
      {...rest}
    >
      {children}
    </GridItem>
  )
}
