import { Button, Flex, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Stack, Text } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup";
import { createOnboarding } from "api/onboardings/createOnboarding";
import { OnboardingType } from "api/onboardings/onboarding";
import { useToastify } from "hooks/toastify/useToastify";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup"
import ReactInputMask from 'react-input-mask'
import { handleSwitchMask } from "utils/handleChangeMask";
import { isCpfValid } from "utils/cpfCnpjValidateFunctions";
import { useHistory } from "react-router-dom";
import { getRegionals } from "api/regionals/getRegionals";
import AsyncSelect from "react-select/async"

interface CreateOnboardingProps {
  onCloseModal: () => void
  isRequired?: boolean
  redirectPath?: string
}

interface CreateOnboardingSchema {
  trainingType: OnboardingType
  name: string
  collector: string
  cpf: string
  email: string
  regional: { value: string, label: string }
}

const createOnboardingSchema = yup.object({
  trainingType: yup.string().required(),
  name: yup.string().required(),
  collector: yup.mixed().when('trainingType', {
    is: 'COLETADOR',
    then: yup.string().required("")
  }),
  cpf: yup.string().transform(isCpfValid).required(),
  email: yup.string().email().required()
})

const trainingTypes = [
  'LLM - COMPRAS',
  'LLM - RH',
  'LLM - OPERAÇÃO MATRIZ',
  'LLM - COMERCIAL',
  'LLM - QUALIDADE',
  'LLM - TI',
  'LLM - FINANCEIRO',
  'LLM - CULTURA ORGANIZACIONAL',
  'LLM - OPERAÇÃO REGIONAIS',
  'MOTOCICLISTA - MOTORISTA',
  'AGREGADOS',
  'COLETADOR',
  'PORTADOR'
]


export function CreateOnboarding({ onCloseModal, isRequired = false, redirectPath, }: CreateOnboardingProps) {
  const queryClient = useQueryClient()

  const history = useHistory()

  const { mutateAsync: createOnboardingFn } = useMutation({
    mutationFn: createOnboarding,
    onSuccess() {
      queryClient.invalidateQueries(['onboardings'])
      onCloseModal()

      if (redirectPath) history.push(redirectPath)
    }
  })

  const { promiseMessage } = useToastify()
  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CreateOnboardingSchema>({
    resolver: yupResolver(createOnboardingSchema)
  })

  const [trainingType] = useWatch({
    control,
    name: ['trainingType'],
  })

  const isCollectorTraining = trainingType === 'collector'


  async function handleCreateOnboarding({
    trainingType,
    name,
    collector,
    cpf,
    email
  }: CreateOnboardingSchema) {
    await promiseMessage(createOnboardingFn({
      body: {
        trainingType,
        name,
        collector,
        cpf,
        email
      }
    }), 'Onboarging criado! 🎉')
  }

  async function regionalPromiseOptions(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getRegionals({ currentPage: 1, pageSize: 10 })

    return response.regionals.filter(regional => regional.code.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())).map(regional => {
      return { value: regional.id, label: regional.code.toUpperCase() }
    })
  }

  return (
    <ModalContent>
      <ModalHeader>Criar Onboarding</ModalHeader>
      {!isRequired && <ModalCloseButton />}
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleCreateOnboarding)}
        maxH='600px'
        overflowY='scroll'
      >

        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Tipo de treinamento
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.trainingType}>
              <Select
                {...register('trainingType')}
                name="trainingType"
                placeholder="Selecione..."
                size="sm"
                rounded="md"
              >
                {trainingTypes.map((training) => {
                  return (
                    <option key={training} value={training}>{training}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors?.name}>
              <FormLabel fontSize="sm">
                Nome Completo
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('name')}
                name="name"
                size="sm"
                rounded="md"
              />
            </FormControl>
          </Stack>
        </Stack>
        {isCollectorTraining && (
          <Stack
            spacing="6"
            direction={["column", "column", "row"]}
            mt="3"
          >
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors?.collector}>
                <FormLabel fontSize="sm">
                  Coletador
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('collector')}
                  name="collector"
                  size="sm"
                  rounded="md"
                />
              </FormControl>
            </Stack>
          </Stack>
        )}
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors?.cpf}>
              <FormLabel fontSize="sm">
                CPF
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('cpf')}
                name="cpf"
                as={ReactInputMask as any}
                mask={handleSwitchMask('cpf')}
                size="sm"
                rounded="md"

              />
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors?.email}>
              <FormLabel fontSize="sm">
                E-mail
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('email')}
                name="email"
                size="sm"
                rounded="md"
                type='email'
              />
            </FormControl>
          </Stack>
        </Stack>

        <FormControl isInvalid={!!errors.regional}>
          <FormLabel fontSize="sm">
            Regional
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            control={control}
            name="regional"
            render={({ field }) => (
              <AsyncSelect
                {...register('regional')}
                name={field.name}
                onChange={field.onChange}
                value={field.value}
                isMulti={false}
                cacheOptions
                defaultOptions
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: '1px',
                    borderRadius: '6px',
                    border: state.isFocused ? 'none' : '',
                    boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                  })
                }}
                noOptionsMessage={() => 'Não há regionais cadastradas ou encontradas!'}
                placeholder="Selecione uma regional..."
                loadOptions={regionalPromiseOptions}
                isClearable={true}
              />
            )}
          />
        </FormControl>
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
