import { Regional } from "api/regionals/_types/Regional";
import axios from "axios";
import { User } from "hooks/user/dtos/User";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { IHubsProps } from "utils/RequestFunctions/Hubs/requestHubFunctions";
import { Training, TrainingStatus } from "./training";

interface GetTrainingsParams {
  pageSize: string
  currentPage: string
  status?: TrainingStatus | 'all' | null
  collaborator?: string
  hub?: string
}

export interface GetTrainingsResponse {
  trainings: Array<Training & { regional: Regional | null }>
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
    count: number
  }
}

export async function getTrainings({
  pageSize,
  currentPage,
  status,
  hub,
  collaborator
}: GetTrainingsParams) {
  try {
    const { data } = await api.get<GetTrainingsResponse>('/trainings', {
      params: {
        pageSize,
        currentPage,
        status,
        hub,
        collaborator
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

