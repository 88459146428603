import { Button, Flex } from "@chakra-ui/react";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";

import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { FinancialClassificationForm, FinancialClassificationFormInputs } from "./components/FinancialClassificationForm"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";

import { useToastify } from "hooks/toastify/useToastify";
import { useSwal } from "hooks/swal/useSwal";
import { useAuth } from "hooks/auth/useAuth";

import { useFinancialClassificationFunctions } from "hooks/financialClassification/useFinancialClassificationFunctions"
import { useFinancialClassification } from "hooks/financialClassification/useFinancialClassification";

interface Params {
  id: string
}

const editFinancialClassificationSchema = yup.object().shape({
  type: yup.string().required('Campo obrigatório')
})

export function EditFinancialClassificationPage() {

  const { id } = useParams<Params>()
  const { push: redirectTo } = useHistory()
  const { userLogged } = useAuth()

  useEffect(() => {
      if (!userLogged.permissions.includes('edit-financial-classification')) {
      redirectTo("/")
    }
  }, [userLogged, redirectTo])

  const {data: financialClassification, isFetching: isFinancialClassificationFetching, isFetched: isFinancialClassificationFetched} = useFinancialClassification({typeId: id, queryOptions:{enabled: !!id}})


  const {updateFinancialClassification: {mutateAsync: updateFinancialClassification}} = useFinancialClassificationFunctions()
  const { promiseMessage } = useToastify()
  const { confirmMessage, standardMessage } = useSwal()

  const formMethods = useForm<FinancialClassificationFormInputs>({
    resolver: yupResolver(editFinancialClassificationSchema)
  })

  const { handleSubmit, setValue, formState: { isSubmitting } } = formMethods

  async function handleEditFinancialClassification({ type }: FinancialClassificationFormInputs) {
    const hasEditFinancialClassification = await confirmMessage({ title: "Deseja editar uma classificação?" })

    if (hasEditFinancialClassification) {
      const successMessage = 'Classificação Financeira editado com sucesso!'

      await promiseMessage(updateFinancialClassification({ type_id: id, type }, {
        onSuccess: () => redirectTo("/classificacao-financeira")
      }), successMessage)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  useEffect(() => {
    if (isFinancialClassificationFetched) {
      setValue("type", financialClassification.type)
    }
  }, [ setValue, isFinancialClassificationFetched, financialClassification])

  if (isFinancialClassificationFetching) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm
      title="Editar Classificação Financeira"
      onSubmit={handleSubmit(handleEditFinancialClassification)}
    >
      <FormProvider {...formMethods}>
        <FinancialClassificationForm />
      </FormProvider>

      <Flex
        mt={6}
        direction={["column", "column", "row"]}
        align="center"
        justify={["center", "center", "flex-end"]}
      >
        <Button
          w={["full", "full", "min"]}
          colorScheme="blue"
          type="submit"
          isLoading={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}
