import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import request from "axios"
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import {  ExtraAndDiscountResponse } from "./dtos/ExtrasAndDiscounts";


type QueryOptions = UseQueryOptions<ExtraAndDiscountResponse, unknown, ExtraAndDiscountResponse, QueryKey>

interface UseExtrasAndDiscountsOptions {
  extraAndDiscountId: string
  queryKey?: string
  queryOptions?: QueryOptions
}

export function useExtraAndDiscount(options?: UseExtrasAndDiscountsOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getExtraAndDiscount() {
    try {
      const { data } = await api.get(`extras-and-discounts/${options.extraAndDiscountId}`)

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new RequestError("Erro inesperado!")
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([
    options?.queryKey || 'extraAndDiscount',
    options.extraAndDiscountId
  ], getExtraAndDiscount, {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })

}
