import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Radio, RadioGroup, Select, Stack, Text } from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { sendCltDriverApproveDismiss } from "api/cltDrivers/sendCltDriverApproveDismiss";
import { format, parseISO, set } from "date-fns";
import { useEffect } from "react";
import { CltDriver } from "api/cltDrivers/_types/CltDriver";

interface SendCltDriverApproveDismissProps {
  cltDriver: CltDriver
}

export interface SendCltDriverApproveDismissSchema {
  dismissDate: string
  dismissionType: string
  isApprovedDismission: string
  isLegalRiskControlled: string
  noticeType: string
  shouldAdvanceStatus: string
}

const sendCltDriverApproveDismissSchema = yup.object({
  dismissionType: yup.string(),
  isApprovedDismission: yup.string(),
  isLegalRiskControlled: yup.string(),
  noticeType: yup.string(),
})

const dismissionOptions = [
  { name: 'Término contrato de experiência' },
  { name: 'Sem justa causa' },
  { name: 'Com justa causa' },
  { name: 'Pedido de demissão pelo colaborador' },
  { name: 'Acordo entre partes' },
]

const noticeTypeOptions = [
  { name: 'Trabalhado' },
  { name: 'Indenizado' },
  { name: 'Descontado' }
]


export function SendCltDriverApproveDismiss({ cltDriver }: SendCltDriverApproveDismissProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<SendCltDriverApproveDismissSchema>({
    resolver: yupResolver(sendCltDriverApproveDismissSchema)
  })

  useEffect(() => {
    if (cltDriver) {
      setValue("dismissDate", cltDriver?.dismissDate ? format(parseISO(cltDriver?.dismissDate), 'yyyy-MM-dd') : null)
      setValue("dismissionType", cltDriver.dismissionType)
      setValue("isApprovedDismission", cltDriver?.isApprovedDismission ? 'yes' : 'no')
      setValue("isLegalRiskControlled", cltDriver?.isLegalRiskControlled ? 'yes' : 'no')
      setValue("noticeType", cltDriver.noticeType)
    }
  }, [cltDriver, setValue])

  const { mutateAsync: sendCltDriverApproveDismissFn } = useMutation({
    mutationFn: sendCltDriverApproveDismiss,
    onSuccess(_data, { body, cltDriverId }) {
      queryClient.invalidateQueries({ queryKey: 'clts-drivers' })
      queryClient.invalidateQueries({ queryKey: 'dismissing-approvation' })
      queryClient.invalidateQueries({ queryKey: 'dismissing-comunication' })
      queryClient.invalidateQueries({ queryKey: ['clt-driver', cltDriverId] })
    }
  })


  async function handleSendCltDriverApproveDismiss(values: SendCltDriverApproveDismissSchema) {
    let dismissDate = null
    if (Boolean(values?.dismissDate)) {
      const [year, month, day] = values?.dismissDate?.split('-').map(Number)
      dismissDate = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()
    }
    await promiseMessage(sendCltDriverApproveDismissFn({
      body: {
        ...values,
        isApprovedDismission: values.isApprovedDismission === 'yes',
        isLegalRiskControlled: values.isLegalRiskControlled === 'yes',
        shouldAdvanceStatus: values.shouldAdvanceStatus ? values.shouldAdvanceStatus === 'yes' : false,
        dismissDate: dismissDate
      }, cltDriverId: cltDriver?.id
    }), 'Aprovação de demissão')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleSendCltDriverApproveDismiss)}
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'>Demissão - Aprovação</Heading>
      <FormControl isInvalid={!!errors.isLegalRiskControlled} mt={3}>
        <FormLabel fontSize='sm'>
          Risco jurídico controlado ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel >
        <Controller
          name="isLegalRiskControlled"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.dismissionType} mt={3}>
        <FormLabel fontSize="sm">
          Tipo de demissão
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register('dismissionType')}
          name="dismissionType"
          placeholder="Selecione..."
          rounded="md"
          size='sm'
        >
          {dismissionOptions.map((dismiss) => {
            return (
              <option key={dismiss.name} value={dismiss.name}>{dismiss.name}</option>
            )
          })}

        </Select>

      </FormControl>
      <FormControl isInvalid={!!errors.noticeType} mt={3}>
        <FormLabel fontSize="sm">
          Tipo de aviso prévio
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register('noticeType')}
          name="noticeType"
          placeholder="Selecione..."
          rounded="md"
          size='sm'
        >
          {noticeTypeOptions.map((notice) => {
            return (
              <option key={notice.name} value={notice.name}>{notice.name}</option>
            )
          })}

        </Select>

      </FormControl>

      <FormControl isInvalid={!!errors.isApprovedDismission} mt={3}>
        <FormLabel fontSize='sm'>
          Demissão aprovada ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isApprovedDismission"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl isInvalid={!!errors?.dismissDate} mt={3}>
        <FormLabel fontSize="sm">
          Data da demissão:
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('dismissDate')}
          name="dismissDate"
          type="date"
          rounded="md"
          size='sm'
        />
      </FormControl>
      <FormControl isInvalid={!!errors.shouldAdvanceStatus} mt={3}>
        <FormLabel fontSize="sm">
          Passar para o próximo status?
        </FormLabel>
        <Select
          {...register('shouldAdvanceStatus')}
          name="shouldAdvanceStatus"
          placeholder="Selecione..."
          size="sm"
          rounded="md"
        >
          <option value='yes'>Sim</option>
          <option value='no'>Não</option>
        </Select>
      </FormControl>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
