import { Box, Divider, Grid, GridItem, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { getInvoice } from "api/invoices/getInvoice";
import { useAuth } from "hooks/auth/useAuth";
import { useQuery } from "react-query";
import { ApproveInvoice } from "./ApproveInvoice";
import { CheckInvoice } from "./CheckInvoice";
import { CheckInvoiceCPValue } from "./CheckInvoiceCPValue";
import { CheckInvoiceValues } from "./CheckInvoiceValues";
import { EditInvoiceStatus } from "./EditInvoiceStatus";
import { InvoiceDetailTable } from "./InvoiceDetailTable";
import { OperationalInconsistency } from "./OperationalInconsistency";


interface InvoiceDetailsProps {
  invoiceId: string
}

export function InvoiceDetails({ invoiceId }: InvoiceDetailsProps) {
  const { userLogged } = useAuth()

  const {
    data: invoiceResult,
    isLoading: isLoadingInvoiceResult
  } = useQuery({
    queryKey: ['invoice', invoiceId],
    queryFn: () => getInvoice({ invoiceId })
  })

  const userCanEditInvoiceStatus = userLogged?.permissions?.includes(
    'edit-invoice-status'
  )
  const userCanCheckInvoice = userLogged?.permissions?.includes(
    'check-invoice'
  )
  const userCanRealizeOperationalInconsistency = userLogged?.permissions?.includes(
    'realize-operational-inconsistency'
  )
  const userCanApproveInvoice = userLogged?.permissions?.includes(
    'approve-invoice'
  )
  const userCanCheckInvoiceValues = userLogged?.permissions?.includes(
    'check-values'
  )
  const userCanCheckInvoiceCPValues = userLogged?.permissions?.includes(
    'check-cp-value'
  )

  return (
    <ModalContent>
      <ModalHeader>
        Detalhes da Fatura
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        maxH='570px'
        overflowY='scroll'
      >

        {isLoadingInvoiceResult ? (
          <Table size="sm">
            <Tbody>
              {Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr>
                    <Td>
                      <Skeleton w="full" h="16px" />
                    </Td>
                    <Td isNumeric>
                      <Skeleton w="full" h="16px" />
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>

        ) : (
          <Grid
            templateColumns={['collector-inconsistency', 'analyzing'].includes(invoiceResult?.invoice?.status) ? "1fr auto 1fr" : "1.5fr auto 1fr auto 0.5fr"}
            gap={6}
          >
            <GridItem>
              <InvoiceDetailTable
                invoiceResult={invoiceResult}
              />
            </GridItem>
            {['approved', 'reproved'].includes(invoiceResult?.invoice?.status) ? "1fr" : ['checking-invoice', 'operational-inconsistency', 'approved-invoice', 'checking-values', 'checking-cp-value'].includes(invoiceResult?.invoice?.status) && (
              <>
                <Box>
                  <Divider orientation="vertical" />
                </Box>
                <GridItem>
                  {invoiceResult?.invoice?.status === 'checking-invoice' && userCanCheckInvoice && (
                    <CheckInvoice
                      invoiceId={invoiceId}
                    />
                  )}
                  {invoiceResult?.invoice?.status === 'operational-inconsistency' && userCanRealizeOperationalInconsistency && (
                    <OperationalInconsistency />
                  )}
                  {invoiceResult?.invoice?.status === 'approved-invoice' && userCanApproveInvoice && (
                    <ApproveInvoice
                      invoiceId={invoiceId}
                    />
                  )}
                  {invoiceResult?.invoice?.status === 'checking-values' && userCanCheckInvoiceValues && (
                    <CheckInvoiceValues
                      invoiceId={invoiceId}
                    />
                  )}
                  {invoiceResult?.invoice?.status === 'checking-cp-value' && userCanCheckInvoiceCPValues && (
                    <CheckInvoiceCPValue
                      invoiceId={invoiceId}
                    />
                  )}
                </GridItem>
              </>
            )}
            {userCanEditInvoiceStatus && (
              <>
                <Box>
                  <Divider orientation="vertical" />
                </Box>
                <GridItem>
                  <EditInvoiceStatus
                    invoiceId={invoiceId}
                    status={invoiceResult?.invoice?.status}
                  />
                </GridItem>
              </>
            )}
          </Grid>
        )}
      </ModalBody>
    </ModalContent>
  )
}
