import { Button, ButtonGroup, Divider, Flex, Heading, IconButton, Spinner, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { TextArea } from "components/Inputs/TextInput";
import { useAuth } from "hooks/auth/useAuth";
import { useMaterialRequestsFunction } from "hooks/materialRequests/useMaterialRequestsFunctions";
import { useProductsByWarehouse } from "hooks/product/useProductsByWarehouse";
import { useToastify } from "hooks/toastify/useToastify";
import { useWarehouses } from "hooks/warehouse/useWarehouses";
import { Fragment, useEffect } from "react";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { FaPlus, FaTrash } from "react-icons/fa";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import * as yup from "yup"

interface CreateRSMFormInputs {
  warehouseId: string
  products: {
    productId: string
    quantity: number
    measure: string
  }[]
  deliveryLimitDate: string
  observations: string
}

const createRsmSchema = yup.object().shape({
  warehouseId: yup.string().required('Selecione um almoxarifado válido'),
  products: yup.array().min(1).of(yup.object({
    productId: yup.string().required('Selecione um produto válido'),
    quantity: yup.number().typeError('A quantidade é obrigatória')
  })),
  deliveryLimitDate: yup.string().required('A Data limite é obrigatória.'),
})

export function CreateRSM() {
  const { userLogged } = useAuth()

  const { push: redirect } = useHistory()

  const { createMaterialRequest } = useMaterialRequestsFunction()

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  useEffect(() => {
    if (!userLogged?.permissions.includes("create-rsm")) {
      redirect("/")
    }
  }, [redirect, userLogged])

  async function handleCreateMaterialRequest(values: CreateRSMFormInputs) {

    const {
      warehouseId,
      ...input
    } = values

    await promiseMessage(createMaterialRequest.mutateAsync({
      warehouseId: values.warehouseId,
      input,
    }, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('materialRequests')

        redirect('/rsms')
      }
    }), 'Requisição de material criada com sucesso!')
  }

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CreateRSMFormInputs>({
    resolver: yupResolver(createRsmSchema)
  })

  const {
    fields: productsFields,
    append: appendProductField,
    remove: removeProductField,
  } = useFieldArray({
    control,
    name: 'products'
  })

  function handleAppendProductField() {
    appendProductField({
      productId: '',
      quantity: 0
    })
  }

  const [selectedWarehouse] = useWatch({
    control,
    name: ['warehouseId']
  })

  const {
    data: warehousesData,
    isFetching: isFetchingWarehousesData,
  } = useWarehouses({
    queryParams: {
      situation: 'ATIVO'
    }
  })

  const warehousesSelectOptions = warehousesData?.warehouses.map((warehouse) => {
    return {
      key: warehouse.id,
      value: warehouse.id,
      showOption: warehouse.name
    }
  })

  const {
    data: productsData,
    isFetching: isFetchingProductsData,
  } = useProductsByWarehouse({
    warehouseId: selectedWarehouse,
    queryOptions: {
      enabled: !!selectedWarehouse
    }
  })

  const productsSelectOptions = productsData?.products.map((product) => {
    return {
      key: product.id,
      value: product.id,
      showOption: product.material.material_name
    }
  }).sort((a, b) => a.showOption.localeCompare(b.showOption))

  return (
    <StandardBackgroundForm
      onSubmit={handleSubmit(handleCreateMaterialRequest)}
      title="Solicitar RSM"
    >
      {isFetchingWarehousesData ? (
        <Spinner />
      ) : (
        <Flex
          gap="4"
          direction="column"
        >
          <Select
            {...register("warehouseId")}
            name="warehouseId"
            label="Almoxarifado"
            placeholder="Selecione um almoxarifado..."
            options={warehousesSelectOptions}
            error={errors.warehouseId}
            required
          />

          {!!selectedWarehouse && (
            <>
              {isFetchingProductsData ? (
                <Spinner />
              ) : (
                <Stack
                  spacing="4"
                  direction={{
                    base:
                      'column'
                  }}
                  border="1px solid"
                  borderColor={errors?.products ? "red.500" : "gray.200"}
                  rounded="xl"
                  py="3"
                  px="4"
                >
                  <Heading fontSize="lg" letterSpacing="tight">Produtos</Heading>

                  {productsFields.map((field, index) => {

                    return (
                      <Fragment key={field.id}>

                        <Divider borderColor="gray.200" />
                        <Stack spacing="4" direction={{ base: 'column', lg: 'row' }}>
                          <Controller
                            control={control}
                            name={`products.${index}.productId`}
                            render={({ field: { name, onChange } }) => {
                              return (
                                <Select
                                  {...register(`products.${index}.productId`)}
                                  name={name}
                                  label="Produto"
                                  onChange={(event) => onChange(() => {
                                    const currentProductInfo = productsData?.products.find(
                                      (product) => product.id === event.target.value
                                    )

                                    setValue(`products.${index}.measure`, currentProductInfo?.measure)

                                    return event.target.value
                                  })}
                                  placeholder="Selecione um produto..."
                                  options={productsSelectOptions}
                                  error={
                                    errors.products
                                      ? errors.products[index]?.productId
                                      : undefined
                                  }
                                  required
                                />
                              )
                            }}

                          />

                          <Stack w="full" spacing="4" direction={{ base: 'column', lg: 'row' }}>
                            <Input
                              {...register(`products.${index}.quantity`)}
                              name={`products.${index}.quantity`}
                              type="number"
                              label="Quantidade"
                              error={errors?.products
                              ? errors?.products[index].quantity
                              : undefined
                              }
                              required
                            />

                            <Input
                              {...register(`products.${index}.measure`)}
                              name={`products.${index}.measure`}
                              label="Medida"
                              required
                              isDisabled
                            />
                          </Stack>

                          <IconButton
                            aria-label="Remover produto"
                            variant="outline"
                            icon={<FaTrash />}
                            h="10"
                            mt={{ base: "inherit", lg: "8"}}
                            onClick={() =>  removeProductField(index)}
                            w="min"
                          />

                        </Stack>
                      </Fragment>
                    )
                  })}

                  <Button
                    colorScheme="blue"
                    size="sm"
                    w="min"
                    alignSelf="end"
                    leftIcon={<FaPlus />}
                    lineHeight="1"
                    onClick={handleAppendProductField}
                  >
                    Adicionar produto
                  </Button>

                </Stack>
              )}

            </>
          )}

          <Input
            {...register("deliveryLimitDate")}
            name="deliveryLimitDate"
            label="Data limite de entrega"
            type="date"
            error={errors.deliveryLimitDate}
            required
          />

          <TextArea
            {...register("observations")}
            name="observations"
            label="Observações"
          />

          <ButtonGroup
            flex="1"
            alignSelf="flex-end"
          >
            <Button
              type="submit"
              isLoading={isSubmitting}
              colorScheme="blue"
            >
              Solicitar
            </Button>
          </ButtonGroup>
        </Flex>
      )}

    </StandardBackgroundForm>
  )
}
