import { useEffect, useState } from "react";
import { Box, Button, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { useAuth } from "hooks/auth/useAuth";
import { FaExchangeAlt, FaFileDownload, FaFileExport, FaPlus } from "react-icons/fa";
import { CreateOnboarding } from "./components/CreateOnboarding";
import { useHistory } from "react-router-dom";
import { useSearchParams } from "hooks/useSearchParams";
import { getOnboardings } from "api/onboardings/getOnboardings";
import { useQuery } from "react-query";
import { Onboarding, OnboardingStatus, OnboardingType } from "api/onboardings/onboarding";
import { usePagination } from "@ajna/pagination";
import { Pagination } from "components/Pagination/Pagination";
import { OnboardingTableRow } from "./components/OnboardingTableRow";
import { OnboardingsTableFilters } from "./components/OnboardingsTableFilters";
import { onboardingStatusMap } from "utils/onboardingMappers";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";

const headers = [
  { label: 'COLABORADOR', key: 'name' },
  { label: 'TIPO DE TREINAMENTO', key: 'training_type' },
  { label: 'CPF', key: 'cpf' },
  { label: 'E-MAIL', key: 'email' },
  { label: 'STATUS', key: 'status' },
  { label: 'CARGO', key: 'cargo' },
  { label: 'COLETADOR', key: 'collector' },
]

function formatValuesToReport(values: Onboarding[]) {
  return values?.map((onboarding) => ({
    ...onboarding,
    status: onboardingStatusMap[onboarding?.status],
    collector: onboarding?.collector ? onboarding?.collector : '-',
    cargo: onboarding?.cargo ? onboarding?.cargo : '-'
  }))
}


export function Onboardings() {
  const { userLogged } = useAuth()
  const { replace, push: redirect } = useHistory()
  const searchParams = useSearchParams()

  const userHasViewOnboardingsPermission = userLogged?.permissions.includes('view-onboardings')
  const userHasCreateOnboardingPermission = userLogged?.permissions.includes('create-onboarding')

  useEffect(() => {
    if (!userHasViewOnboardingsPermission) {
      redirect('/')
    }
  }, [userHasViewOnboardingsPermission, redirect])

  const page = searchParams.get('page') ?? '1'
  const name = searchParams.get('name')
  const status = searchParams.get('status') ?? 'all'
  const trainingType = searchParams.get('trainingType') ?? 'all'


  const { data: onboardingsData } = useQuery({
    queryKey: ['onboardings',
      page,
      name,
      status,
      trainingType
    ],
    queryFn: () => getOnboardings({
      currentPage: page,
      pageSize: '10',
      name,
      status: status as OnboardingStatus | 'all',
      trainingType: trainingType as OnboardingType | 'all'
    }),
    keepPreviousData: true
  })

  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }

  const {
    data: onboardingsDataReportResult,
    isLoading: isLoadingOnboardingsDataReportResult
  } = useQuery({
    queryKey: ['onboardings-report',
      page,
      name,
      status,
      trainingType
    ],
    queryFn: () => getOnboardings({
      currentPage: '1',
      pageSize: String(onboardingsData?.meta?.count),
      name,
      status: ["inactive", "training", "inviting", "testing", "finished"].includes(status) ? status as OnboardingStatus : 'all',
      trainingType: trainingType as OnboardingType | 'all'
    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: onboardingsData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: onboardingsData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }


  const {
    isOpen: isCreateOnboardingModalOpen,
    onOpen: onOpenCreateOnboardingModal,
    onClose: onCloseCreateOnboardingModal
  } = useDisclosure()

  const csvReportProps = {
    data: onboardingsDataReportResult ? formatValuesToReport(onboardingsDataReportResult?.onboardings) : [],
    headers,
    filename: `onboardings.csv`,
  }



  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Onboardings</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar kanban"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => redirect('/onboardings/kanban')}
          />
        </Flex>
        {/* {userHasCreateOnboardingPermission && (
          <IconButton
            aria-label="Criar Onboarding"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onOpenCreateOnboardingModal}
          />
        )} */}
        <Modal
          isOpen={isCreateOnboardingModalOpen}
          onClose={onCloseCreateOnboardingModal}
          isCentered
        >
          <ModalOverlay />
          <CreateOnboarding onCloseModal={onCloseCreateOnboardingModal} />
        </Modal>
      </Flex>
      <OnboardingsTableFilters />
      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Colaborador</Th>
              <Th color="white">Tipo de treinamento</Th>
              <Th color="white">CPF</Th>
              <Th color="white">E-mail</Th>
              <Th color="white">Status</Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {onboardingsData?.onboardings?.map((onboarding) => {
              return (
                <OnboardingTableRow
                  key={onboarding.id}
                  onboarding={onboarding}
                />
              )
            })}

          </Tbody>
        </Table>
      </TableContainer>
      {!onboardingsDataReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingOnboardingsDataReportResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>

  )
}
