import { Flex, Box, Heading, Button, Icon } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { BudgetTable } from '../../components/Tables/Budget/BudgetTable'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useBudget } from '../../hooks/budget/useBudget'
import { useCity } from '../../hooks/city/useCity'

export function BudgetList() {
  const [isLoading, setIsLoading] = useState(true)

  const {
    budgets: { data: budgets, isLoading: isBudgetLoading, refetch },
  } = useBudget(null, true, false)
  const {
    cities: { data: cities, isLoading: isCitiesLoading },
  } = useCity(null, true, false)
  const { userLogged } = useContext(AuthContext)
  const permissions = userLogged?.permissions

  useEffect(() => {
    function run() {
      if (!isBudgetLoading && !isCitiesLoading) {
        setIsLoading(false)
      }
    }
    run()
  }, [isBudgetLoading, isCitiesLoading])

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex
            mb="8"
            justify="space-between"
            align="center"
            direction={['column', 'column', 'row']}
          >
            <Heading size="lg" fontFamily="poppins">
              Lista de Orçamentos
            </Heading>
            {permissions !== undefined && (
              <Link to="/orcamento/adicionar">
                {userLogged?.permissions.includes('add-budget') && (
                  <Button
                    colorScheme="gray"
                    rightIcon={<Icon as={FiPlus} />}
                    size="sm"
                    mt={['4', '4', '0']}
                  >
                    Adicionar Orçamento
                  </Button>
                )}
              </Link>
            )}
          </Flex>
          {!!budgets && !!cities && (
            <BudgetTable budgets={budgets} cities={cities} refetch={refetch} />
          )}
        </Box>
      </Flex>

  )
}
