import { createContext, ReactNode } from 'react'
import { api } from '../services/api'

import swal from 'sweetalert'

import 'react-toastify/dist/ReactToastify.css'

interface ClientsProviderProps {
  children: ReactNode
  pathname?: string
}

export interface InputProps {
  id: string
  name: string
  minimum_stock: number
  standard_stock: number
  measure: string
  general_provider_id: string
  unit_cost: number
  unit_price: number
  situation: string
}

type InputInput = Omit<InputProps, 'id'>

interface InputContextProps {
  createInput: (inputInput: InputInput) => Promise<boolean>
  deleteInput: (inputId: string) => Promise<boolean | undefined>
  editInput: (
    inputId: string,
    inputInput: InputInput,
  ) => Promise<boolean | undefined>
}

export const InputContext = createContext<InputContextProps>(
  {} as InputContextProps,
)

export function InputProvider({ children, pathname }: ClientsProviderProps) {
  async function createInput(inputInput: InputInput) {
    try {
      await api.post('/input', inputInput)

      setTimeout(() => {
        swal('Poof! O Insumo foi criado com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function deleteInput(inputId: string) {
    try {
      await api.delete(`/input/${inputId}`)
      swal('Poof! O Insumo foi excluído com suceso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro!', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function editInput(inputId: string, inputInput: InputInput) {
    try {
      await api.put(`/input/${inputId}`, inputInput)
      setTimeout(() => {
        swal('Poof! O Insumo foi atualizado com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  return (
    <InputContext.Provider value={{ createInput, deleteInput, editInput }}>
      {children}
    </InputContext.Provider>
  )
}
