import { useHistory, useParams } from 'react-router'
import { DriverForm } from './DriverForm'
import { useState, useEffect } from 'react'
import { driverFormatDataToBack } from '../../utils/DriverFunctions/driverFormatDataToBack'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useAuth } from '../../hooks/auth/useAuth'
import { useSwal } from '../../hooks/swal/useSwal'
import { useDriverFunctions } from '../../hooks/driver/useDriverFunctions'

interface QueryParams {
  id: string
  slug: string
}

interface FormInputs {
  situation: string
  collector_id: string
  firstname: string
  lastname: string
  cpf: string
  email: string
  observation: string
  has_interval: 'yes' | 'no'
  intervals: {
    startTime: string
    endTime: string
    weekDay: number
    enabled: boolean
  }[]
}

export function DriverAddDetailEdit() {
  const [isLoading, setIsLoading] = useState(false)

  const {
    createDriver: { mutateAsync: createDriver },
    editDriver: { mutateAsync: editDriver },
  } = useDriverFunctions()
  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useAuth()

  const { slug, id }: QueryParams = useParams()
  const { push: redirectTo } = useHistory()

  useEffect(() => {
    const checkPermissions = () => {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (slug === 'adicionar') {
            if (!permissions?.includes('add-driver')) {
              redirectTo('/')
            }
          }
          if (slug === 'editar') {
            if (!permissions?.includes('edit-driver')) {
              redirectTo('/')
            }
          }
          if (slug === 'visualizar') {
            if (!permissions?.includes('view-driver')) {
              redirectTo('/')
            }
          }
        }
      } catch {
        redirectTo('/')
      }
    }
    checkPermissions()
  }, [slug, redirectTo, userLogged])

  const handleCreateDriver = async (values: FormInputs) => {
    const hasConfirmed = await confirmMessage({
      title: 'Deseja adicionar um motorista?',
    })

    if (hasConfirmed) {
      setIsLoading(true)
      const formatedDriverData = await driverFormatDataToBack(values)
      const response = await createDriver(formatedDriverData)
      if (response) {
        redirectTo('/motoristas')
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const handleEditMotorista = async (values: FormInputs) => {
    const hasConfirmed = await confirmMessage({
      title: 'Deseja editar um motorista?',
    })

    if (hasConfirmed) {
      setIsLoading(true)
      try {
        const formatedDriverData = await driverFormatDataToBack(values)
        const editDriverReqData = {
          driverId: id,
          editDriverInput: formatedDriverData,
        }
        const response = await editDriver({ ...editDriverReqData })
        if (response) redirectTo('/motoristas')
      } catch {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (isLoading) {
    return <GeneralContentLoading />
  }

  if (slug === 'adicionar') {
    return (
      <DriverForm
        slug={slug}
        href=""
        submit={handleCreateDriver}
        title="Adicionar motorista"
        action="Salvar"
      />
    )
  } else if (slug === 'editar') {
    return (
      <DriverForm
        slug={slug}
        id={id}
        href=""
        submit={handleEditMotorista}
        title="Editar motorista"
        action="Salvar"
      />
    )
  } else {
    return (
      <DriverForm
        slug={slug}
        id={id}
        href={`/motorista/editar/${id}`}
        submit={handleEditMotorista}
        title="Visualizar motorista"
        action="Editar"
        isDisabled={true}
      />
    )
  }
}
