import { Button, Flex, Input } from "@chakra-ui/react";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface DeliveriesWithoutQRCodeSchema {
  destinationCollector: string
}

export function DeliveriesWithoutQRCodePendingValidationFilters() {
  const searchParams = useSearchParams()

  const history = useHistory()

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<DeliveriesWithoutQRCodeSchema>()

  function handleSearchDeliveriesWithoutQRCode(
    values: DeliveriesWithoutQRCodeSchema
  ) {
    if (values.destinationCollector) {
      searchParams.set('destinationCollector', values.destinationCollector)
    } else {
      searchParams.delete('destinationCollector')
    }

    searchParams.set('page', '1')

    history.replace({ search: searchParams.toString() })
  }

  function handleClearFilters() {
    searchParams.delete('destinationCollector')

    searchParams.set('page', '1')

    reset()

    history.replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(handleSearchDeliveriesWithoutQRCode)}
      mt="6"
      gap="2"
      direction={{ base: 'column', lg: 'row' }}
    >

      <Input
        {...register('destinationCollector')}
        placeholder="Coletador destino"
        size="sm"
        rounded="md"
        type="text"
        w={{ base: 'full', lg: 'fit-content' }}
      />

      <Button
        leftIcon={<FaSearch />}
        size="sm"
        lineHeight="1"
        type="submit"
        w={{ base: 'full', lg: 'fit-content' }}
      >
        Filtrar resultados
      </Button>

      <Button
        leftIcon={<FaTimes />}
        size="sm"
        lineHeight="1"
        onClick={handleClearFilters}
        w={{ base: 'full', lg: 'fit-content' }}
      >
        Remover filtros
      </Button>
    </Flex>
  )
}
