import { createContext, ReactNode } from 'react'
import { api } from '../services/api'

import 'react-toastify/dist/ReactToastify.css'
import swal from 'sweetalert'

interface ShippingProviderProps {
  children: ReactNode
  pathname?: string
}

export interface ShippingProps {
  id: string
  company_name: string
  trading_name: string
  cnpj: string
  email: string
  modal: string
  cellphone: string
  telephone: string
  state_register: string | null
  observation: string
  has_board_autofill: boolean
  cte_transfer_cost_in_cents?: number
  board_weight?: number
  real_weight?: number
  taxed_weight?: number
  is_loglife_payer?: boolean
  is_cte_destination_branch_equal_to_planned?: boolean
}

type ShippingInput = Omit<ShippingProps, 'id'>

interface ShippingContextProps {
  createShipping: (shippingInput: ShippingInput) => Promise<boolean>
  deleteShipping: (shippingId: string) => Promise<boolean | undefined>
  editShipping: (
    shippingId: string,
    shippingInput: ShippingInput,
  ) => Promise<boolean | undefined>
}

export const ShippingContext = createContext<ShippingContextProps>(
  {} as ShippingContextProps,
)

export function ShippingProvider({
  children,
  pathname,
}: ShippingProviderProps) {
  async function createShipping(shippingInput: ShippingInput) {
    try {
      await api.post('/shipping', shippingInput)
      setTimeout(() => {
        swal('Poof! A transportadora foi criada com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function deleteShipping(shippingId: string) {
    try {
      await api.delete(`/shipping/${shippingId}`)
      swal('Poof! A transportadora foi excluída com sucesso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function editShipping(
    shippingId: string,
    shippingInput: ShippingInput,
  ) {
    try {
      await api.put(`/shipping/${shippingId}`, shippingInput)
      setTimeout(() => {
        swal('Poof! A transportadora foi atualizada com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  return (
    <ShippingContext.Provider
      value={{ createShipping, deleteShipping, editShipping }}
    >
      {children}
    </ShippingContext.Provider>
  )
}
