import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface BulkValidateBoardsBody {
  boardWeight: number
  cte: string
  operationalNumber: string
  realWeight: number
  taxedWeight: number
  realBoardDate: Date
  realBoardHour: Date
  trackerLink?: string
  modal: string
  validateObservation: string
  ratings: {
    boardVolume: number
    taxedWeight: number
    transferBudget: number
    cteTransferCost: number
    boardServiceId: string
  }[]
}

interface BulkValidateBoardsProps {
  body: BulkValidateBoardsBody
}

export async function bulkValidateBoards({
  body
}: BulkValidateBoardsProps) {
  try {
    const { data } = await api.put('/boards/multi-validations', body)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
