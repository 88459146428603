import { api } from '../../../services/api'

export type CreateHolidayInputProps = {
  name: string
  date: string
  national: boolean
  city_id: string | null
}

export type EditHolidayInputProps = CreateHolidayInputProps

export type EditHolidayReqProps = {
  holidayId: string
  editHolidayInput: EditHolidayInputProps
}

const createHolidayReqFunction = (
  createHolidayInput: CreateHolidayInputProps,
) =>
  api
    .post('/holiday', createHolidayInput)
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })

const editHolidayReqFunction = ({
  holidayId,
  editHolidayInput,
}: EditHolidayReqProps) =>
  api
    .put(`/holiday/${holidayId}`, editHolidayInput)
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })

const deleteHolidayReqFunction = (holidayId: string) =>
  api
    .delete(`/holiday/${holidayId}`)
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })

export {
  createHolidayReqFunction,
  editHolidayReqFunction,
  deleteHolidayReqFunction,
}
