import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import request from "axios"
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { LoadRatioService } from "./dtos/LoadRatioService";


interface LoadRatioServicesResponse{
  services: LoadRatioService[]
  totalPages: number
}

interface LoadRatioServicesQueryParams {
  currentPage?: number
  pageSize?: number
  collectorId?: string
  protocol?: string
  customer?: string
  timezoneOffset?: number
  collectDate?: string
  deliveryDatetime?: string
  sourceCity?: string
  destinationCity?: string
  tracker?: string
  cte?: string
  vehicle?: string
  volume?: string
  shipping?: string
  sourceBranch?: string
  destinationBranch?: string
  destinationCollector?: string
  step?: string
}

type QueryOptions = UseQueryOptions<LoadRatioServicesResponse, unknown, LoadRatioServicesResponse, QueryKey>

interface UseLoadRatioServicesOptions {
  queryKey?: string
  queryOptions?: QueryOptions
  queryParams?: LoadRatioServicesQueryParams
}

async function getLoadRatioServices(queryParams?: LoadRatioServicesQueryParams) {
  try {
    const { data } = await api.get(`/services/load-ratio`, {
      params: {
        ...queryParams
      }
    })

    return data
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}

export function useLoadRatioServices(options?: UseLoadRatioServicesOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([
    options?.queryKey || 'loadRatioServices',
    { ...options?.queryParams }
  ], () => getLoadRatioServices(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
