import { OccurrenceProps } from '../../../contexts/OccurrenceContext'
import { api } from '../../api'

const getAllOccurrences = (step?: string) =>
  api.get<OccurrenceProps[]>('/occurrence', {
    params: {
      step
    }
  })
    .then((res) => res.data)
// .catch(err => {
//   throw new Error(err.response.data.message)
// })

const getOneOccurrence = (occurrenceId: string) =>
  api
    .get<OccurrenceProps>(`/occurrence/${occurrenceId}`)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err.response.data.message)
    })

export { getAllOccurrences, getOneOccurrence }
