import axios from "axios"
import { ShippingProps } from "contexts/ShippingContext"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"


interface GetShippingsQueryParams {
  pageSize: number
  currentPage: number
  tradingName?: string
}

export interface GetShippingsResponse {
  shippings: ShippingProps[]
  meta: {
    totalPages: number
    pageSize: number
    currentPage: number
  }
}

export async function getShippings({
  currentPage,
  pageSize,
  tradingName
}: GetShippingsQueryParams) {
  try {
    const response = await api.get<GetShippingsResponse>('/shippings', {
      params: {
        currentPage,
        pageSize,
        tradingName
      }
    })

    return response.data

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
