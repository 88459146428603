import { Stack } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { useFormContext } from "react-hook-form";

interface CrossdockingHoursInputsGroupProps {
  isDisabled?: boolean
}

export function CrossdockingHoursInputsGroup({
  isDisabled = false
}: CrossdockingHoursInputsGroupProps) {

  const { register, formState: { errors } } = useFormContext()

  return (
    <Stack w="full" spacing={6}>
      <Stack direction={["column", "column", "row"]} spacing={6}>
        <Input
          {...register('crossdocking_board_date')}
          name="crossdocking_board_date"
          type="date"
          label="Data limite para embarque crossdocking"
          error={errors.crossdocking_board_date}
          isDisabled={isDisabled}
          required
        />
        <Input
          {...register('crossdocking_board_hour')}
          name="crossdocking_board_hour"
          type="time"
          label="Horário limite para embarque crossdocking"
          error={errors.crossdocking_board_hour}
          isDisabled={isDisabled}
          required
        />
      </Stack>

      <Stack direction={["column", "column", "row"]} spacing={6}>
        <Input
          {...register(
            'crossdocking_availability_forecast_day',
          )}
          name="crossdocking_availability_forecast_day"
          type="date"
          label="Data previsão de disponibilidade crossdocking"
          error={
            errors.crossdocking_availability_forecast_day
          }
          isDisabled={isDisabled}
          required
        />
        <Input
          {...register(
            'crossdocking_availability_forecast_time',
          )}
          name="crossdocking_availability_forecast_time"
          type="time"
          label="Hora previsão de disponibilidade crossdocking"
          error={
            errors.crossdocking_availability_forecast_time
          }
          isDisabled={isDisabled}
          required
        />
      </Stack>
    </Stack>
  )
}
