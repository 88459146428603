export function handlePermissions(userType: string) {
  switch (userType) {
    case 'ADMIN':
      return [
        'view-config',
        'add-permission',
        'edit-permission',
        'remove-permission',
        'add-user',
        'view-user',
        'edit-user',
        'remove-user',
        'reset-password',
        'view-log',
        'update-users-permissions',
        'add-customer',
        'view-customer',
        'edit-customer',
        'remove-customer',
        'add-collector',
        'view-collector',
        'edit-collector',
        'remove-collector',
        'add-driver',
        'view-driver',
        'edit-driver',
        'remove-driver',
        'add-stock',
        'view-stock',
        'edit-stock',
        'remove-stock',
        'edit-current-stock',
        'add-dispatch-stock',
        'add-confirmation-dispatch-stock',
        'add-materials-dispatch-stock',
        'confirm-dispatch-stock',
        'view-dispatched-materials',
        'edit-dispatch-stock',
        'remove-dispatch-stock',
        'view-dispatch-stock',
        'view-dispatch-stock-confirm-list',
        'view-dispatch-stock-add-confirmation-list',
        'view-dispatch-stock-dispatched-list',
        'add-input',
        'view-input',
        'edit-input',
        'remove-input',
        'add-hub',
        'view-hub',
        'edit-hub',
        'remove-hub',
        'add-city',
        'view-city',
        'edit-city',
        'remove-city',
        'add-address',
        'view-address',
        'edit-address',
        'remove-address',
        'add-shipping',
        'view-shipping',
        'edit-shipping',
        'remove-shipping',
        'add-branch',
        'view-branch',
        'edit-branch',
        'remove-branch',
        'add-provider',
        'view-provider',
        'edit-provider',
        'remove-provider',
        'add-collector-cost',
        'view-collector-cost',
        'edit-collector-cost',
        'remove-collector-cost',
        'add-budget',
        'view-budget',
        'edit-budget',
        'remove-budget',
        'remove-service',
        'view-service-list',
        'view-service-consult',
        'add-single-service',
        'add-recurrent-service',
        'view-requested-service',
        'view-recurrent-service',
        'edit-requested-service',
        'edit-progress-service',
        'valid-requested-service',
        'valid-boarding-service',
        'add-allocated-service',
        'add-available-service',
        'view-cost-report',
        'view-stock-report',
        'view-billing-report',
        'view-sla-report',
        'view-cargo-in-transit-report',
        'view-schedule-report',
        'view-cte-report',
        'view-stock-control-report',
        'view-occurrence-report',
        'view-dispatch-stock-report',
        'view-rnc-report',
        'view-collector-report',
        'add-occurrence',
        'remove-occurrence',
        'validate-occurrence',
        'view-occurrence-list',
        'add-route-network',
        'remove-route-network',
        'view-route-network',
        'view-rnc-list',
        'add-rnc',
        'validate-rnc',
        'remove-rnc',
        'view-holiday',
        'edit-holiday',
        'add-holiday',
        'remove-holiday',
        'validate-new-service-addresses',
      ]

    case 'GERÊNCIA':
      return [
        'view-config',
        'add-user',
        'view-user',
        'edit-user',
        'remove-user',
        'add-customer',
        'view-customer',
        'edit-customer',
        'remove-customer',
        'add-collector',
        'view-collector',
        'edit-collector',
        'remove-collector',
        'add-driver',
        'view-driver',
        'edit-driver',
        'remove-driver',
        'add-stock',
        'view-stock',
        'edit-stock',
        'remove-stock',
        'add-dispatch-stock',
        'add-confirmation-dispatch-stock',
        'add-materials-dispatch-stock',
        'confirm-dispatch-stock',
        'view-dispatched-materials',
        'edit-dispatch-stock',
        'remove-dispatch-stock',
        'view-dispatch-stock',
        'view-dispatch-stock-confirm-list',
        'view-dispatch-stock-add-confirmation-list',
        'view-dispatch-stock-dispatched-list',
        'add-input',
        'view-input',
        'edit-input',
        'remove-input',
        'add-hub',
        'view-hub',
        'edit-hub',
        'remove-hub',
        'add-city',
        'view-city',
        'edit-city',
        'remove-city',
        'add-address',
        'view-address',
        'edit-address',
        'remove-address',
        'add-shipping',
        'view-shipping',
        'edit-shipping',
        'remove-shipping',
        'add-branch',
        'view-branch',
        'edit-branch',
        'remove-branch',
        'add-provider',
        'view-provider',
        'edit-provider',
        'remove-provider',
        'add-collector-cost',
        'view-collector-cost',
        'edit-collector-cost',
        'remove-collector-cost',
        'add-budget',
        'view-budget',
        'edit-budget',
        'remove-budget',
        'view-service-list',
        'view-service-consult',
        'add-single-service',
        'add-recurrent-service',
        'view-requested-service',
        'view-recurrent-service',
        'edit-requested-service',
        'edit-progress-service',
        'valid-requested-service',
        'valid-boarding-service',
        'add-allocated-service',
        'add-available-service',
        'view-cost-report',
        'view-stock-report',
        'view-billing-report',
        'view-sla-report',
        'view-cargo-in-transit-report',
        'view-schedule-report',
        'view-cte-report',
        'view-stock-control-report',
        'view-occurrence-report',
        'view-dispatch-stock-report',
        'view-rnc-report',
        'view-collector-report',
        'add-occurrence',
        'remove-occurrence',
        'validate-occurrence',
        'view-occurrence-list',
        'add-route-network',
        'remove-route-network',
        'view-route-network',
        'view-rnc-list',
        'add-rnc',
        'validate-rnc',
        'remove-rnc',
        'view-holiday',
        'edit-holiday',
        'add-holiday',
        'remove-holiday',
        'validate-new-service-addresses',
      ]

    case 'ANALISTA':
      return [
        'view-config',
        'add-customer',
        'view-customer',
        'edit-customer',
        'add-collector',
        'view-collector',
        'edit-collector',
        'add-driver',
        'view-driver',
        'edit-driver',
        'remove-driver',
        'view-stock',
        'edit-stock',
        'view-input',
        'edit-input',
        'add-address',
        'view-address',
        'edit-address',
        'remove-address',
        'view-hub',
        'view-city',
        'add-collector-cost',
        'view-collector-cost',
        'edit-collector-cost',
        'view-budget',
        'add-shipping',
        'view-shipping',
        'edit-shipping',
        'add-branch',
        'view-branch',
        'edit-branch',
        'add-provider',
        'view-provider',
        'edit-provider',
        'view-service-list',
        'view-service-consult',
        'add-single-service',
        'add-recurrent-service',
        'view-requested-service',
        'view-recurrent-service',
        'edit-requested-service',
        'valid-requested-service',
        'valid-boarding-service',
        'add-allocated-service',
        'add-available-service',
        'view-cost-report',
        'view-stock-report',
        'view-billing-report',
        'view-sla-report',
        'view-cargo-in-transit-report',
        'view-schedule-report',
        'view-cte-report',
        'view-stock-control-report',
        'view-occurrence-report',
        'view-dispatch-stock-report',
        'view-rnc-report',
        'view-collector-report',
        'add-occurrence',
        'remove-occurrence',
        'validate-occurrence',
        'view-occurrence-list',
        'view-rnc-list',
        'add-rnc',
        'validate-rnc',
        'view-holiday',
        'validate-new-service-addresses',
      ]

    case 'AUXILIAR':
      return [
        'view-config',
        'view-customer',
        'view-collector',
        'view-stock',
        'view-input',
        'add-driver',
        'view-driver',
        'edit-driver',
        'add-address',
        'view-address',
        'edit-address',
        'remove-address',
        'view-hub',
        'view-city',
        'view-collector-cost',
        'view-budget',
        'view-shipping',
        'view-branch',
        'view-provider',
        'view-service-list',
        'view-service-consult',
        'add-single-service',
        'add-recurrent-service',
        'view-requested-service',
        'view-recurrent-service',
        'edit-requested-service',
        'valid-requested-service',
        'valid-boarding-service',
        'add-allocated-service',
        'add-available-service',
        'view-cost-report',
        'view-stock-report',
        'view-billing-report',
        'view-sla-report',
        'view-cargo-in-transit-report',
        'view-cte-report',
        'view-stock-control-report',
        'view-occurrence-report',
        'view-dispatch-stock-report',
        'view-rnc-report',
        'view-collector-report',
        'add-occurrence',
        'remove-occurrence',
        'validate-occurrence',
        'view-occurrence-list',
        'view-rnc-list',
        'add-rnc',
        'validate-rnc',
        'view-holiday',
        'validate-new-service-addresses',
      ]

    case 'CLIENTE':
      return [
        'view-config',
        'add-address',
        'view-address',
        'edit-address',
        'view-budget',
        'view-billing-report',
        'view-sla-report',
        'view-occurrence-report',
        'view-service-list',
        'view-service-consult',
        'add-single-service',
        'view-occurrence-report',
        'view-requested-service',
        'edit-requested-service',
        'add-occurrence',
        'remove-occurrence',
        'view-occurrence-list',
        'view-holiday',
      ]

    case 'COLETADOR':
      return [
        'view-config',
        'add-driver',
        'view-driver',
        'edit-driver',
        'view-service-list',
        'view-service-consult',
        'define-collect',
        'define-boarding',
        'define-landing',
        'define-delivery',
        'add-collect-service',
        'add-boarding-service',
        'add-landing-service',
        'add-delivery-service',
        'add-occurrence',
        'remove-occurrence',
        'view-occurrence-list',
        'view-schedule-report',
        'view-cargo-in-transit-report',
        'view-collector-report',
        'view-occurrence-report',
        'view-holiday',
      ]

    case 'MOTORISTA':
      return [
        'view-config',
        'add-collect-service',
        'add-boarding-service',
        'add-landing-service',
        'add-delivery-service',
        'add-occurrence',
        'view-occurrence-list',
        'view-holiday',
      ]

    default:
      return null
  }
}
