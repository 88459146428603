import { Button, Flex } from "@chakra-ui/react";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";
import { useMaterial } from "hooks/material/useMaterial";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { MaterialForm, MaterialFormInputs } from "./components/MaterialForm";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { useMaterialFunctions } from "hooks/material/useMaterialFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { useSwal } from "hooks/swal/useSwal";
import { useAuth } from "hooks/auth/useAuth";

interface Params {
  id: string
}

const editMaterialSchema = yup.object().shape({
  material_name: yup.string().required('Campo obrigatório')
})

export function EditMaterialPage() {

  const { id } = useParams<Params>()
  const { push: redirectTo } = useHistory()
  const { userLogged } = useAuth()

  useEffect(() => {
    if (!userLogged.permissions.includes('update-material')) {
      redirectTo("/")
    }
  }, [userLogged, redirectTo])

  const { data: material, isFetching: isMaterialFetching, isFetched: isMaterialFetched } = useMaterial(id)

  const { updateMaterial: { mutateAsync: updateMaterial } } = useMaterialFunctions()
  const { promiseMessage } = useToastify()
  const { confirmMessage, standardMessage } = useSwal()

  const formMethods = useForm<MaterialFormInputs>({
    resolver: yupResolver(editMaterialSchema)
  })

  const { handleSubmit, setValue, formState: { isSubmitting } } = formMethods

  async function handleEditMaterial({ material_name }: MaterialFormInputs) {
    const hasEditMaterial = await confirmMessage({ title: "Deseja editar um material?" })

    if (hasEditMaterial) {
      const successMessage = 'Material editado com sucesso!'

      await promiseMessage(updateMaterial({ material_id: id, material_name }, {
        onSuccess: () => redirectTo("/materiais")
      }), successMessage)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  useEffect(() => {
    if (isMaterialFetched) {
      setValue("material_name", material.material_name)
    }
  }, [material, setValue, isMaterialFetched])

  if (isMaterialFetching) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm
      title="Editar material"
      onSubmit={handleSubmit(handleEditMaterial)}
    >
      <FormProvider {...formMethods}>
        <MaterialForm />
      </FormProvider>

      <Flex
        mt={6}
        direction={["column", "column", "row"]}
        align="center"
        justify={["center", "center", "flex-end"]}
      >
        <Button
          w={["full", "full", "min"]}
          colorScheme="blue"
          type="submit"
          isLoading={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}
