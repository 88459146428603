import { useMutation } from 'react-query'
import {
  GenerateContentDeclarationPdfInput,
  generateContentDeclarationPdfReqFunction,
} from '../../../utils/RequestFunctions/Service/ContentDeclaration/requestGenerateContentDeclarationPdfFunctions'
import { useSwal } from '../../swal/useSwal'

export const useGenerateContentDeclarationFunctions = () => {
  const { errorMessage } = useSwal()

  const generateContentDelcaration = useMutation(
    'generateContentDeclaration',
    (input: GenerateContentDeclarationPdfInput) =>
      generateContentDeclarationPdfReqFunction(input),
    {
      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  return {
    generateContentDelcaration,
  }
}
