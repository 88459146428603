import { api } from "services/api"
import request from "axios"
import { QueryKey, useQuery, UseQueryOptions } from "react-query"
import { RequestError } from "utils/errors/RequestErrors"
import { useToastify } from "hooks/toastify/useToastify"
import { useAuth } from "hooks/auth/useAuth"
import { User, UserSituation } from "./dtos/User"

interface UserQueryParams {
  situation?: UserSituation
}

interface GetLLMUsersResponse {
  users: User[]
}

export async function getLLMUsers(queryParams?: UserQueryParams) {
  try {
    const { data } = await api.get<GetLLMUsersResponse>(`/users/llm`, {
      params: queryParams && {
        ...queryParams,
      }
    })

    return data
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

type QueryOptions = UseQueryOptions<GetLLMUsersResponse, unknown, GetLLMUsersResponse, QueryKey>

interface UseLLMUsersOptions {
  queryKey?: string
  queryOptions?: QueryOptions
  queryParams?: UserQueryParams
}

export function useLLMUsers(options?: UseLLMUsersOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([
    options?.queryKey ?? "llmUsers",
    {...options?.queryParams}
  ], () => getLLMUsers(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
