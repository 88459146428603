import { Button, Flex, Spinner } from "@chakra-ui/react";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { useToastify } from "hooks/toastify/useToastify";
import { useLLMUsers } from "hooks/user/useLLMUsers";
import { useMonitoringUsers } from "hooks/user/useMonitoringUsers";
import { useUserFunctions } from "hooks/user/useUserFunctions";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { MonitoringUsersForm, MonitoringUsersFormInputs } from "./components/MonitoringUsersForm";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";

const editViewMonitoringUsersSchema = yup.object().shape({
  monitoringDays: yup.array().min(1, 'Campo obrigatório').of(yup.object({
    day: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
    periods: yup.array().min(1, 'Campo obrigatório').of(yup.object({
      startHour: yup.string().required('Horário inicial obrigatório'),
      endHour: yup.string().required('Horário final obrigatório'),
      responsibles: yup.array().min(1, 'Campo obrigatório').of(yup.object({
        userId: yup.string().required('Campo obrigatório'),
      }))
    }))
  }))
})

export interface MonitoringUsersReducedData {
  [day: number]: {
    [period: string]: {
      id: string
      monitoringId: string
    }[]
  }
}

interface Params {
  slug: 'editar' | 'visualizar'
}


export function EditViewMonitoringUsers() {
  const [deletedMonitoringIds, setDeletedMonitoringIds] = useState<string[]>([])

  const { slug } = useParams<Params>()

  const handleSetDeletedMonitoringUsersIds = (monitoringId: string | string[]) => {
    if (Array.isArray(monitoringId)) {
      setDeletedMonitoringIds(prevMonitoringIds => [...prevMonitoringIds, ...monitoringId])
    } else {
      setDeletedMonitoringIds(prevMonitoringIds => [...prevMonitoringIds, monitoringId])
    }

  }

  const formMethods = useForm<MonitoringUsersFormInputs>({
    resolver: yupResolver(editViewMonitoringUsersSchema)
  })

  const {
    data: llmUsersData,
    isFetching: isFetchingLlmUsersData
  } = useLLMUsers()

  const {
    data: monitoringUsersData,
    isFetching: isFetchingMonitoringUsersData,
  } = useMonitoringUsers()

  const monitoringUsersReducedData = useMemo(() => {
    return monitoringUsersData?.monitoringUsers?.reduce<MonitoringUsersReducedData>((acc, curr) => {
      if (!acc[curr.day]) {
        acc[curr.day] = {}
      }

      if (!acc[curr.day][`${curr.start_hour} - ${curr.end_hour}`]) {
        acc[curr.day][`${curr.start_hour} - ${curr.end_hour}`] = []
      }

      acc[curr.day][`${curr.start_hour} - ${curr.end_hour}`].push({
        ...curr.user,
        monitoringId: curr.id
      })


      return acc
    }, {})
  }, [monitoringUsersData])

  const {
    editMonitoringUsers,
  } = useUserFunctions()

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  async function handleEditViewMonitoringUsers(values: MonitoringUsersFormInputs) {
    await promiseMessage(editMonitoringUsers.mutateAsync({
      data: {
        deletedMonitoringIds,
        monitoringDays: values.monitoringDays,
      }
    }, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('monitoringUsers')
      }
    }), 'Responsáveis pelo monitoramento editados com sucesso.')
  }

  const title = slug === 'editar'
  ? 'Editar Responsáveis por Monitoramento'
  : 'Visualizar Responsáveis por Monitoramento'

  return (
    <StandardBackgroundForm
      title={title}
      onSubmit={formMethods.handleSubmit(handleEditViewMonitoringUsers)}
    >
      {(isFetchingLlmUsersData || isFetchingMonitoringUsersData) ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <MonitoringUsersForm
            users={llmUsersData?.users}
            monitoringUsersReducedData={monitoringUsersReducedData}
            onSetDeletedMonitoringUsersIds={handleSetDeletedMonitoringUsersIds}
            isViewPage={slug === 'visualizar'}
          />
        </FormProvider>
      )}

      <Flex
        w="full"
        justify="flex-end"
        align="center"
        mt="4"
      >

        <Button
          type="submit"
          colorScheme="blue"
        >
          Salvar
        </Button>
      </Flex>

    </StandardBackgroundForm>
  )
}
