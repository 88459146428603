import swal from 'sweetalert'
import { useHistory, useParams } from 'react-router'
import { useState, useContext, useEffect } from 'react'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { AuthContext } from '../../contexts/AuthContext'
import { InputForm } from './InputForm'
import { InputContext } from '../../contexts/InputContext'
import { transformStringToNumber } from '../../utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber'

interface QueryParams {
  id: string
  slug: string
}

interface FormInputs {
  name: string
  minimum_stock: number
  standard_stock: number
  measure: string
  general_provider_id: string
  unit_cost: number
  unit_price: number
  situation: string
}

export function InputAddDetailEdit() {
  const { slug, id }: QueryParams = useParams()

  const { createInput, editInput } = useContext(InputContext)
  const [isLoading, setIsLoading] = useState(false)
  const { userLogged } = useContext(AuthContext)

  const history = useHistory()

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (slug === 'adicionar') {
            if (!permissions?.includes('add-input')) {
              history.push('/')
            }
          }
          if (slug === 'editar') {
            if (!permissions?.includes('edit-input')) {
              history.push('/')
            }
          }
          if (slug === 'visualizar') {
            if (!permissions?.includes('view-input')) {
              history.push('/')
            }
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [slug, history, userLogged])

  async function handleCreateInput(values: FormInputs) {
    const newValues = {
      ...values,
      unit_cost: transformStringToNumber(String(values.unit_cost)),
      unit_price: transformStringToNumber(String(values.unit_price)),
    }

    await swal({
      title: 'Deseja adicionar um insumo?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willCreate) => {
      if (willCreate) {
        const response = await createInput(newValues)
        if (response) {
          setIsLoading(true)
          setTimeout(() => {
            history.push('/insumos')
          }, 1500)
        }
      } else {
        return swal('Ação cancelada com êxito!')
      }
    })
  }

  async function handleEditInput(values: FormInputs) {
    const newValues = {
      ...values,
      unit_cost: transformStringToNumber(String(values.unit_cost)),
      unit_price: transformStringToNumber(String(values.unit_price)),
    }

    await swal({
      title: 'Deseja editar um insumo?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willEdit) => {
      if (willEdit) {
        const response = await editInput(id, newValues)
        if (response) {
          setIsLoading(true)
          setTimeout(() => {
            history.push('/insumos')
          }, 1500)
        }
      } else {
        swal('Ação cancelada com êxito!')
      }
    })
  }

  if (isLoading) {
    return <GeneralContentLoading />
  }

  if (slug === 'adicionar') {
    return (
      <InputForm
        slug={slug}
        href=""
        submit={handleCreateInput}
        title="Adicionar Insumo de Transporte"
        action="Salvar"
      />
    )
  } else if (slug === 'editar') {
    return (
      <InputForm
        slug={slug}
        id={id}
        href=""
        submit={handleEditInput}
        title="Editar Insumo de Transporte"
        action="Salvar"
      />
    )
  } else {
    return (
      <InputForm
        slug={slug}
        id={id}
        href={`/insumo/editar/${id}`}
        submit={async () => {}}
        title="Visualizar Insumo de Transporte"
        action="Editar"
        isDisabled={true}
      />
    )
  }
}
