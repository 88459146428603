import {
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  Icon,
  Stack,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextArea } from 'components/Inputs/TextInput'
import { Collector } from 'hooks/collector/dtos/Collector'
import { Fragment, useEffect } from 'react'
import { useCurrency } from 'react-hook-currency'
import { Controller, NestedValue, useFieldArray, useForm } from 'react-hook-form'
import { FaTimes, FaPlus } from 'react-icons/fa'
import { IDriverProps } from 'services/getFunctions/driver/getDrivers'
import { CustomerProps } from '../../../../contexts/CustomerContext'
import { CitiesProps } from '../../../../services/getFunctions/city/getCity'
import { vehicle_types } from '../../../../utils/customLists'
import { IHubsProps } from '../../../../utils/RequestFunctions/Hubs/requestHubFunctions'
import { schema } from '../../../../validations/businessBudgetSchema'
import { ListButton } from '../../../Buttons/ListButton'
import { SubmitButton } from '../../../Buttons/SubmitButton'
import { CheckboxBudget } from '../../../Inputs/CheckboxBudget'
import { Input } from '../../../Inputs/Input'
import { Select } from '../../../Inputs/SelectInput'
import { StandardBackgroundForm } from '../../StandardBackgroundForm'

interface IFormInputProps {
  customer_id: string
  source_hub_id: string
  destination_hub_id: string
  source_collector_id: string
  source_cities: NestedValue<string[]>
  destination_cities: NestedValue<string[]>
  route_nickname: string
  vehicle: string
  start_date: string
  end_date: string | null
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  monthly_price: string
  budget: string
  monthly_km_franchising: string
  week_time_franchising: string
  km_extra_price: string
  extra_hour_price: string
  weekday_daily_budget_backup: string
  weekend_daily_budget_backup: string
  drivers: {
    driver: string
    attendance_days: string[]
    start_hour: string
    end_hour: string
    value_in_cents: string
  }[]
  last_readjustment: string
  observation: string
}

interface ICreateBusinessBudgetFormProps {
  hubs?: IHubsProps[]
  cities?: CitiesProps[]
  customers?: CustomerProps[]
  collectors?: Collector[]
  drivers?: IDriverProps[]
  submit: (values: IFormInputProps) => Promise<void>
}


export function CreateBusinessBudgetForm({
  hubs,
  cities,
  customers,
  collectors,
  drivers,
  submit,
}: ICreateBusinessBudgetFormProps) {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      customer_id: '',
      source_hub_id: '',
      destination_hub_id: '',
      source_cities: [],
      destination_cities: [],
      route_nickname: '',
      vehicle: '',
      start_date: '',
      end_date: '',
      caixa_termica: 0,
      embalagem_secundaria: 0,
      gelo_seco: 0,
      gelox: 0,
      isopor3l: 0,
      isopor7l: 0,
      terciaria3l: 0,
      terciaria8l: 0,
      monthly_price: '',
      budget: '',
      monthly_km_franchising: '',
      week_time_franchising: '',
      km_extra_price: '',
      extra_hour_price: '',
      weekday_daily_budget_backup: '',
      weekend_daily_budget_backup: '',
      drivers: [],
      last_readjustment: '',
      observation: '',
    },
  })

  const { onChange, format: currencyFormat } = useCurrency({
    style: 'decimal',
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'drivers',
  })

  useEffect(() => {
    append({ driver: '', attendance_days: [], start_hour: '', end_hour: '', value_in_cents: '' })
  }, [append])


  const customerId = watch('customer_id')
  const sourceHubId = watch('source_hub_id')
  const destinationHubId = watch('destination_hub_id')

  const collectorsSelectOptions = collectors
    ?.map((collector) => ({
      key: collector.id,
      value: collector.id,
      showOption: collector.trading_name
    }))
    .sort((a, b) => a.showOption.localeCompare(b.showOption));

  return (
    <StandardBackgroundForm
      onSubmit={handleSubmit(submit)}
      title="Criar Orçamento Business"
    >
      <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
        <Select
          {...register('customer_id')}
          name="customer_id"
          label="Cliente"
          customers={customers}
          placeholder="Selecione uma opção..."
          error={errors.customer_id}
          required
        />
      </Stack>
      {customerId && (
        <>
          <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
            <Select
              {...register('source_hub_id')}
              name="source_hub_id"
              label="Hub Origem"
              hubs={hubs}
              placeholder="Selecione uma opção..."
              error={errors.source_hub_id}
              required
            />
            <Select
              {...register('destination_hub_id')}
              name="destination_hub_id"
              label="Hub Destino"
              hubs={hubs}
              placeholder="Selecione uma opção..."
              error={errors.destination_hub_id}
              required
            />
          </Stack>

          {sourceHubId && destinationHubId && (
            <Stack
              mt="4"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <CheckboxBudget
                {...register('source_cities')}
                name="source_cities"
                label="Cidade(s) de origem"
                citySource={cities?.filter(
                  (city) => city.hub_id === sourceHubId,
                )}
                error={errors.source_cities}
                required
              />
              <CheckboxBudget
                {...register('destination_cities')}
                name="destination_cities"
                label="Cidade(s) de destino"
                cityDestination={cities?.filter(
                  (city) => city.hub_id === destinationHubId,
                )}
                error={errors.destination_cities}
                required
              />
            </Stack>
          )}

          <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
            <Input
              {...register('route_nickname')}
              name="route_nickname"
              label="Apelido da rota"
              error={errors.route_nickname}
              required
            />
            <Select
              {...register('vehicle')}
              name="vehicle"
              label="Veículo"
              vehicle_types={vehicle_types.filter(
                (vehicle) => vehicle.name !== 'CAMINHÃO',
              )}
              placeholder="Selecione uma opção..."
              error={errors.vehicle}
              required
            />
          </Stack>
          <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
            <Input
              {...register("start_date")}
              name="start_date"
              label="Data da Início"
              error={errors.start_date}
              type="date"
              required
            />
            <Input
              {...register("last_readjustment")}
              name="last_readjustment"
              label="Data do último reajuste"
              error={errors.last_readjustment}
              type="date"
              required
            />
          </Stack>

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Input
              {...register('caixa_termica')}
              name="caixa_termica"
              label="Caixa Térmica"
              type="number"
              error={errors.caixa_termica}
              required
            />
            <Stack
              w="full"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Input
                {...register('gelo_seco')}
                name="gelo_seco"
                label="Gelo Seco"
                type="number"
                error={errors.gelo_seco}
                required
              />

              <Input
                {...register('gelox')}
                name="gelox"
                label="Gelox"
                type="number"
                error={errors.gelox}
                required
              />
            </Stack>
          </Stack>

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Input
              {...register('isopor3l')}
              name="isopor3l"
              label="Isopor 3L"
              type="number"
              error={errors.isopor3l}
              required
            />
            <Input
              {...register('isopor7l')}
              name="isopor7l"
              label="Isopor 7L"
              type="number"
              error={errors.isopor7l}
              required
            />
            <Input
              {...register('terciaria3l')}
              name="terciaria3l"
              label="Terciária 3L"
              type="number"
              error={errors.terciaria3l}
              required
            />

            <Input
              {...register('terciaria8l')}
              name="terciaria8l"
              label="Terciária 8L"
              type="number"
              error={errors.gelox}
              required
            />
          </Stack>

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Input
              {...register('embalagem_secundaria')}
              name="embalagem_secundaria"
              label="Embalagem secundária"
              type="number"
              error={errors.embalagem_secundaria}
              required
            />
          </Stack>

          <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
            <Stack
              w="full"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Input
                {...register('monthly_price')}
                name="monthly_price"
                label="Preço mensal"
                onChange={onChange}
                defaultValue={currencyFormat('000')}
                error={errors.monthly_price}
                required
              />
              <Input
                {...register('budget')}
                name="budget"
                label="Budget"
                onChange={onChange}
                defaultValue={currencyFormat('000')}
                error={errors.budget}
                required
              />
            </Stack>

            <Input
              {...register('monthly_km_franchising')}
              name="monthly_km_franchising"
              label="Franquia mensal de KM"
              onChange={onChange}
              defaultValue={currencyFormat('000')}
              error={errors.monthly_km_franchising}
              required
            />
          </Stack>

          <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
            <Input
              {...register('week_time_franchising')}
              name="week_time_franchising"
              onChange={onChange}
              defaultValue={currencyFormat('000')}
              label="Franquia de tempo semanal"
              error={errors.monthly_price}
              required
            />
            <Input
              {...register('km_extra_price')}
              name="km_extra_price"
              onChange={onChange}
              defaultValue={currencyFormat('000')}
              label="Preço do KM adicional"
              error={errors.km_extra_price}
              required
            />
          </Stack>

          <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
            <Input
              {...register('weekday_daily_budget_backup')}
              name="weekday_daily_budget_backup"
              onChange={onChange}
              defaultValue={currencyFormat('000')}
              label="Budget diária backup (Dias úteis)"
              error={errors.weekday_daily_budget_backup}
              required
            />
            <Input
              {...register('weekend_daily_budget_backup')}
              name="weekend_daily_budget_backup"
              onChange={onChange}
              defaultValue={currencyFormat('000')}
              label="Budget diária backup (Finais de semana) "
              error={errors.weekend_daily_budget_backup}
              required
            />
          </Stack>

          <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
            <Input
              {...register('extra_hour_price')}
              name="extra_hour_price"
              label="Preço da HE"
              onChange={onChange}
              defaultValue={currencyFormat('000')}
              error={errors.extra_hour_price}
              required
            />
            <Select
              {...register('source_collector_id')}
              name="source_collector_id"
              label="Coletador Origem"
              options={collectorsSelectOptions}
              placeholder="Selecione uma opção..."
              error={errors.source_collector_id}
              required
            />
          </Stack>

          {fields.map((item, index) => (
            <Fragment key={index}>
              <Flex
                w="full"
                align="center"
                direction={['column', 'column', 'row']}
              >
                <VStack w="full">
                  <Stack
                    w="full"
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Stack
                      w="full"
                      spacing={6}
                      direction={['column', 'column', 'row']}
                    >

                      <Select
                        {...register(`drivers.${index}.driver`)}
                        name={`drivers.${index}.driver`}
                        label="Motorista Executor"
                        options={drivers.map(driver => {
                          return {
                            showOption: `${driver.firstname} ${driver.lastname}`,
                            value: `${driver.firstname} ${driver.lastname}`,
                            key: driver.id
                          }
                        })}
                        placeholder="Selecione uma opção..."
                        error={
                          errors.drivers &&
                          errors?.drivers[index]?.driver
                        }
                        required
                      />
                    </Stack>

                    <Stack
                      w="full"
                      spacing="24px"
                      direction={['column', 'column', 'row']}
                    >
                      <Input
                        {...register(`drivers.${index}.start_hour`)}
                        name={`drivers.${index}.start_hour`}
                        label="Horário Inicial"
                        type="time"
                        error={
                          errors.drivers
                            ? errors?.drivers[index]?.start_hour
                            : undefined
                        }
                        required
                      />
                      <Input
                        {...register(`drivers.${index}.end_hour`)}
                        name={`drivers.${index}.end_hour`}
                        label="Horário Final"
                        type="time"
                        error={
                          errors.drivers
                            ? errors?.drivers[index]?.end_hour
                            : undefined
                        }
                        required
                      />
                      <Stack>
                        {isWideVersion ? (
                          <Button
                            type="button"
                            onClick={() => remove(index)}
                            rightIcon={<Icon as={FaTimes} />}
                            mt={7}
                            h={12}
                            // ml="2"
                            w="full"
                          >
                            Remover
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            onClick={() => remove(index)}
                            w="full"
                            mt={7}
                            h={12}
                          >
                            <Icon as={FaTimes} />
                          </Button>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                  <Controller
                    name={`drivers.${index}.attendance_days`}
                    control={control}
                    render={({ field }) => {
                      return (
                        <CheckboxGroup
                          onChange={field.onChange}
                          value={field.value}
                        >

                          <Stack direction="column" w="full" border="1px" borderColor="gray.200" p={3} rounded="md">
                            <Checkbox value="0">Domingo</Checkbox>
                            <Checkbox value="1">Segunda-feira</Checkbox>
                            <Checkbox value="2">Terça-feira</Checkbox>
                            <Checkbox value="3">Quarta-feira</Checkbox>
                            <Checkbox value="4">Quinta-feira</Checkbox>
                            <Checkbox value="5">Sexta-feira</Checkbox>
                            <Checkbox value="6">Sábado</Checkbox>
                          </Stack>
                        </CheckboxGroup>
                      )
                    }}

                  />
                  <Input
                    {...register(`drivers.${index}.value_in_cents`)}
                    name={`drivers.${index}.value_in_cents`}
                    label="Valor contratado mensal/diario"
                    onChange={onChange}
                    defaultValue={currencyFormat('000')}
                    error={
                      errors.drivers
                        ? errors?.drivers[index]?.value_in_cents
                        : undefined
                    }
                    required
                  />
                </VStack>
              </Flex>
              <Divider my="6" borderColor="gray.700" />
            </Fragment>
          ))}

          <Stack
            mt="8"
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            {isWideVersion ? (
              <Button
                type="button"
                onClick={() => append({ attendance_days: [], start_hour: '', end_hour: '' })}
                rightIcon={<Icon as={FaPlus} />}
              >
                Adicionar
              </Button>
            ) : (
              <Button
                type="button"
                onClick={() => append({ attendance_days: [], start_hour: '', end_hour: '' })}
              >
                <Icon as={FaPlus} />
              </Button>
            )}
          </Stack>

          <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
            <TextArea
              {...register('observation')}
              name="observation"
              label="Observações"
            />
          </Stack>
        </>
      )}
      <Flex mt="4" justifyContent="flex-end">
        <Stack spacing="24px" direction="row">
          <SubmitButton action="Salvar" isSubmitting={isSubmitting} />
          <ListButton name="Orçamentos Business" href="/orcamentos-business" />
        </Stack>
      </Flex>
    </StandardBackgroundForm>
  )
}
