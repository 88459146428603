import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Flex,
} from '@chakra-ui/react'
import { Link, useHistory } from 'react-router-dom'
import { FiEdit3, FiEye, FiTrash2 } from 'react-icons/fi'
import { useContext, useState, useEffect, Fragment } from 'react'
import { Pagination } from '../../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../../utils/searchBoxFilter'
import { AuthContext } from '../../../../contexts/AuthContext'
import { RequestedServiceContext } from '../../../../contexts/ServiceContext'
import swal from 'sweetalert'
import { days_of_week } from '../../../../utils/customLists'
import { recurrentServiceSortByDate } from '../../../../utils/ServiceFunctions/recurrentServiceSortByDate'
import { TableFilterButton } from '../../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../../hooks/filterOptions/useFilterOptions'
import { EmptyContentTableAlert } from '../../../Alerts/EmptyContentTableAlert'
import { RecurrentServiceProps } from '../../../../services/getFunctions/recurrentService/getRecurrentService'
import { formatDate } from '../../../../utils/DateFunctions/formatDate'
import { GenerateExcelReportButton } from '../../../Buttons/GenerateExcelReportButton'
import { Address } from 'hooks/address/dtos/Address'

interface RecurrentTableProps {
  addresses: Address[]
  recurrentServices: RecurrentServiceProps[]
  refetch: () => void
}

interface IDestinationAddressesInputProps {
  address: string;
  quantity: number;
}

const recurrentServiceOptions = [
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'situation', value: 'Situação', checked: false },
  { key: 'daysOfWeek', value: 'Dias da Semana', checked: false },
  { key: 'sourceCity', value: 'Cidade de Origem', checked: false },
  { key: 'destinationCity', value: 'Cidade de Destino', checked: false },
].sort((a, b) => a.value.localeCompare(b.value))

const recurrentServiceOrderOptions = [
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'situation', value: 'Situação', checked: false },
].sort((a, b) => a.value.localeCompare(b.value))

const recurrentServiceCsvHeaders = [
  { label: 'ORÇAMENTO', key: 'budget_id' },
  { label: 'CLIENTE', key: 'customer_id' },
  { label: 'DIAS DA SEMANA', key: 'days_of_week' },
  { label: 'ENDEREÇOS DE ORIGEM', key: 'source_address_id' },
  { label: 'ENDEREÇOS DE DESTINO', key: 'destination_address_id' },
  { label: 'PRAZO', key: 'deadline' },
  { label: 'TIPO DE SERVIÇO', key: 'service_type' },
  { label: 'FRANQUIA', key: 'franchising' },
  { label: 'MODAL', key: 'modal' },
  { label: 'VEÍCULO', key: 'vehicle' },
  { label: 'CAIXA TÉRMICA', key: 'caixa_termica' },
  { label: 'EMBALAGEM SECUNDÁRIA', key: 'embalagem_secundaria' },
  { label: 'GELO SECO', key: 'gelo_seco' },
  { label: 'GELOX', key: 'gelox' },
  { label: 'ISOPOR 3L', key: 'isopor3l' },
  { label: 'ISOPOR 7L', key: 'isopor7l' },
  { label: 'TERCIARIA 3L', key: 'terciaria3l' },
  { label: 'TERCIARIA 8L', key: 'terciaria8l' },
  { label: 'HORÁRIO INICIAL DA COLETA', key: 'collect_hour_start' },
  { label: 'HORÁRIO FINAL DA COLETA', key: 'collect_hour_end' },
  { label: 'HORÁRIO DA ENTREGA', key: 'delivery_hour' },
  { label: 'SITUAÇÃO', key: 'situation' },
  { label: 'COLETADOR DE ORIGEM', key: 'sourceCollectorIDRecurrent.trading_name' },
  { label: 'COLETADOR DE DESTINO', key: 'destinationCollectorIDRecurrent.trading_name' },
  { label: 'BASE DE ORIGEM', key: 'sourceBranchIDRecurrent.nickname' },
  { label: 'BASE DE DESTINO', key: 'destinationBranchIDRecurrent.nickname' },
  { label: 'FORNECEDOR DE GELO SECO', key: 'provider_id' },
  { label: 'HORÁRIO PREVISTO PARA DISPONIBILIZAÇÃO', key: 'availability_forecast_time' },
  { label: 'PRAZO PREVISTO PARA DISPONIBILIZAÇÃO', key: 'availability_forecast_deadline' },
  { label: 'HORÁRIO PREVISTO PARA EMBARQUE', key: 'board_hour' },
  { label: 'PRAZO PREVISTO PARA EMBARQUE', key: 'board_date' },
  { label: 'VOO PLANEJADO', key: 'planned_flight' },
  { label: 'TIPO DE MATERIAL', key: 'material_type' },
  { label: 'MATERIAIS PARA ENDEREÇO DE DESTINO', key: 'destination_addresses_input' }
]

export function RecurrentServiceTable({
  addresses,
  recurrentServices,
  refetch,
}: RecurrentTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE
  const [searchedServices, setSearchedServices] = useState<
    RecurrentServiceProps[]
  >([])
  const [slicedServices, setSlicedServices] = useState<RecurrentServiceProps[]>(
    [],
  )
  const [customerFiltered, setCustomerFiltered] = useState('')
  const [situationFiltered, setSituationFiltered] = useState('')
  const [programDaysFiltered, setProgramDaysFiltered] = useState('')
  const [sourceCityFiltered, setSourceCityFiltered] = useState('')
  const [destinationCityFiltered, setDestinationCityFiltered] = useState('')

  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { deleteRecurrentService } = useContext(RequestedServiceContext)
  const { userLogged } = useContext(AuthContext)

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedServices?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  function formatRecurrentServiceDataToReport(
    recurrentServices: RecurrentServiceProps[]
  ) {
    return recurrentServices.map(recurrent => {
      const budget = recurrent.budgetIDRecurrent

      const sourceAddresses = addresses.filter(
        (address) =>
          recurrent.source_address_id.includes(address.id)
      ).map(address => `${address.trading_name} | ${address.branch} | ${address.street
        } | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name
        } | ${address.cep}`)

      const destinationAddresses = addresses.filter(
        (address) =>
          recurrent.destination_address_id.includes(address.id)
      ).map(address => `${address.trading_name} | ${address.branch} | ${address.street
        } | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name
        } | ${address.cep}`)

      return {
        ...recurrent,
        customer_id: recurrent.customerIDRecurrent.trading_firstname,
        budget_id: `${budget.vehicle} | ${budget.modal} | ${budget.franchising
          }KG | GELO SECO: ${budget.gelo_seco} | GELOX: ${budget.gelox
          } | ${budget.observation}`,
        days_of_week: days_of_week.reduce((days, day) => {
          recurrent.days_of_week.map(recurrentDay => {
            if (recurrentDay === day.value) {
              return days.push(day.name)
            }
            return []
          })

          return days
        }, [] as Array<string>),
        source_address_id: sourceAddresses,
        destination_address_id: destinationAddresses,
        collect_hour_start: formatDate.handle(recurrent.collect_hour_start, "DateOnlyWithHourMinute"),
        collect_hour_end: formatDate.handle(recurrent.collect_hour_end, "DateOnlyWithHourMinute"),
        delivery_hour: formatDate.handle(recurrent.delivery_hour, "DateOnlyWithHourMinute"),
        source_collector_id: recurrent.source_collector_id ?? '-',
        destination_collector_id: recurrent.destination_collector_id ?? '-',
        source_branch_id: recurrent.source_branch_id ?? '-',
        destination_branch_id: recurrent.destination_branch_id ?? '-',
        provider_id: recurrent.provider_id ?? '-',
        availability_forecast_time: recurrent.availability_forecast_time ? formatDate.handle(recurrent.availability_forecast_time, "DateOnlyWithHourMinute") : '-',
        availability_forecast_deadline: recurrent.availability_forecast_deadline ?? '-',
        board_hour: recurrent.board_hour ? formatDate.handle(recurrent.board_hour, "DateOnlyWithHourMinute") : '-',
        board_date: recurrent.board_date ?? '-',
        planned_flight: recurrent.planned_flight ?? '-',
        material_type: recurrent.material_type ?? '-',
        destination_addresses_input: recurrent.destination_addresses_input
          ? addresses.reduce((addresses, address) => {
            recurrent.destination_addresses_input?.map(destinationAddress => {
              if (destinationAddress.address_id === address.id) {
                return addresses.push({
                  address: `${address.trading_name} | ${address.branch} | ${address.street
                    } | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name
                    } | ${address.cep}`, quantity: destinationAddress.quantity
                })
              }
              return []
            })

            return addresses
          }, [] as Array<IDestinationAddressesInputProps>)
            .map(address => `${address.address}: ${address.quantity}`).join('\n')
          : '-'
      }

    })
  }

  const csvReportProps = {
    data: formatRecurrentServiceDataToReport(recurrentServices),
    filename: 'relatório-servicos-recorrentes',
    headers: recurrentServiceCsvHeaders,
  }

  const history = useHistory()

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(recurrentServiceOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(recurrentServiceOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    async function run() {
      if (userLogged !== null) {
        if (!userLogged?.permissions.includes('view-recurrent-service')) {
          history.push('/')
        }
        if (userLogged.user_type === 'CLIENTE') {
          const servicesPerPage = recurrentServices.filter(
            (service) =>
              service.budgetIDRecurrent.customer_id === userLogged.customer_id,
          )
          setSlicedServices(servicesPerPage)
        } else {
          setSlicedServices(recurrentServices)
        }
      }
    }

    run()
  }, [itemLimit, currentPage, offset, userLogged, history, recurrentServices])

  useEffect(() => {
    function run() {
      const newRecurrentSlicedServices = slicedServices?.filter((service) => {
        const customerFilter = searchBoxFilter(
          service.customerIDRecurrent.trading_firstname,
          customerFiltered,
        )
        const situationFilter = service.situation.toLowerCase().startsWith(
          situationFiltered.toLowerCase()
        )
        const daysWeekFilter = searchBoxFilter(
          days_of_week
            .filter((day) => service.days_of_week.includes(day.value))
            .map((day) => day.name)
            .join(', '),
          programDaysFiltered,
        )
        const sourceCityFilter = searchBoxFilter(
          addresses
            .filter((address) => service.source_address_id.includes(address.id))
            .map((address) => address.cityIDAddress.name)
            .join(', '),
          sourceCityFiltered,
        )
        const destinationCityFilter = searchBoxFilter(
          addresses
            .filter((address) =>
              service.destination_address_id.includes(address.id),
            )
            .map((address) => address.cityIDAddress.name)
            .join(', '),
          destinationCityFiltered,
        )

        if (
          customerFiltered === '' &&
          sourceCityFiltered === '' &&
          destinationCityFiltered === '' &&
          situationFiltered === '' &&
          programDaysFiltered === ''
        ) {
          return service
        }
        return (
          customerFilter &&
          situationFilter &&
          daysWeekFilter &&
          sourceCityFilter &&
          destinationCityFilter
        )
      })

      if (orderOptionSelected.length && newRecurrentSlicedServices) {
        const recurrentSlicedWithOrder = newRecurrentSlicedServices.sort(
          (a, b) =>
            (orderOptionSelected.includes('customer') &&
              a.budgetIDRecurrent.customer_id.localeCompare(
                b.budgetIDRecurrent.customer_id,
              )) ||
            (orderOptionSelected.includes('situation') &&
              a.situation.localeCompare(b.situation)) ||
            0,
        )

        return setSearchedServices(recurrentSlicedWithOrder)
      }

      if (newRecurrentSlicedServices && orderOptionSelected.length === 0) {
        return setSearchedServices(
          newRecurrentSlicedServices.sort((a, b) =>
            recurrentServiceSortByDate(a.created_at, b.created_at),
          ),
        )
      }
    }
    run()
  }, [
    customerFiltered,
    situationFiltered,
    programDaysFiltered,
    sourceCityFiltered,
    destinationCityFiltered,
    orderOptionSelected,
    addresses,
    slicedServices,
  ])

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  async function handleDeleteRecurrentService(serviceId: string) {
    await swal({
      title: 'Deseja excluir um serviço recorrente?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willDelete) => {
      if (willDelete) {
        const response = await deleteRecurrentService(serviceId)
        if (response) {
          refetch()
        }
      } else {
        swal('Remoção cancelada com êxito!')
      }
    })
  }

  return (
    <>
      {slicedServices.length <= 0 ? (
        <EmptyContentTableAlert
          title="Oops!"
          description="Não há dados para mostrar aqui!"
        />
      ) : (
        <>
          <TableFilterButton />
          <Stack mt="4" direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Fragment key={option.key}>
                  <Flex>
                    {option.key === 'customer' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Cliente..."
                        handleSearch={(e) =>
                          setCustomerFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'situation' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Situação..."
                        handleSearch={(e) =>
                          setSituationFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'daysOfWeek' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Dias da Semana.."
                        handleSearch={(e) =>
                          setProgramDaysFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'sourceCity' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Cidade Origem..."
                        handleSearch={(e) =>
                          setSourceCityFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'destinationCity' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Cidade Destino..."
                        handleSearch={(e) =>
                          setDestinationCityFiltered(e.target.value)
                        }
                      />
                    )}
                  </Flex>
                </Fragment>
              ))}
          </Stack>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th fontSize="12" color="blue.900">
                    CLIENTE
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    SITUAÇÃO
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    DIAS PROGRAMADOS
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    CIDADE DE ORIGEM
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    CIDADE DESTINO
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchedServices
                  .slice(offset, offset + Number(itemLimit))
                  .map((service, index) => {
                    return (
                      <Tr key={index}>
                        <Td>
                          {userLogged !== null && (
                            <>
                              {userLogged.permissions.includes(
                                'view-requested-service',
                              ) && (
                                  <Link
                                    to={`/servico/recorrente/visualizar/${service.id}`}
                                  >
                                    <Icon
                                      cursor="pointer"
                                      as={FiEye}
                                      ml="4"
                                      fontSize="20"
                                    />
                                  </Link>
                                )}
                              {userLogged.permissions.includes(
                                'edit-recurrent-service',
                              ) && (
                                  <Link
                                    to={`/servico/recorrente/editar/${service.id}`}
                                  >
                                    <Icon
                                      cursor="pointer"
                                      as={FiEdit3}
                                      fontSize="20"
                                      ml="4"
                                      mt={['2', '2', '0']}
                                    />
                                  </Link>
                                )}
                              {userLogged.permissions.includes(
                                'remove-service',
                              ) && (
                                  <Icon
                                    cursor="pointer"
                                    as={FiTrash2}
                                    fontSize="20"
                                    ml="4"
                                    mt={['2', '2', '0']}
                                    onClick={() =>
                                      handleDeleteRecurrentService(service.id)
                                    }
                                  />
                                )}
                            </>
                          )}
                        </Td>
                        <Td fontSize="12">
                          {service.customerIDRecurrent.trading_firstname.toUpperCase()}
                        </Td>
                        <Td fontSize="12">{service.situation.toUpperCase()}</Td>
                        <Td fontSize="12">
                          {Array.from(
                            new Set(
                              days_of_week
                                .filter((day) =>
                                  service.days_of_week.includes(day.value),
                                )
                                .map((day) => day.name),
                            ),
                          )
                            .join(', ')
                            .toUpperCase()}
                        </Td>
                        <Td fontSize="12">
                          {Array.from(
                            new Set(
                              addresses
                                .filter((address) =>
                                  service.source_address_id.includes(
                                    address.id,
                                  ),
                                )
                                .map((address) => address.cityIDAddress.name),
                            ),
                          )
                            .join(', ')
                            .toUpperCase()}
                        </Td>
                        <Td fontSize="12">
                          {Array.from(
                            new Set(
                              addresses
                                .filter((address) =>
                                  service.destination_address_id.includes(
                                    address.id,
                                  ),
                                )
                                .map((address) => address.cityIDAddress.name),
                            ),
                          )
                            .join(', ')
                            .toUpperCase()}
                        </Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </Box>
          <GenerateExcelReportButton csvReportProps={csvReportProps} />
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}
