import { useMutation, useQueryClient } from 'react-query'
import {
  CreateAvailableServiceInputProps,
  createAvailableServiceReqFunction,
  updateAvailableServiceReqFunction,
  UpdateAvailableServiceReqProps,
  updateAvailableServicesToLandingServiceReqFunction,
} from '../../../utils/RequestFunctions/Service/Available/requestAvailableServiceFunctions'
import { useSwal } from '../../swal/useSwal'

export const useAvailableServiceFunctions = () => {
  const queryClient = useQueryClient()
  const { successMessage, errorMessage } = useSwal()

  const createAvailableService = useMutation(
    'createAvailableService',
    (createAvailableServiceInput: CreateAvailableServiceInputProps) =>
      createAvailableServiceReqFunction(createAvailableServiceInput),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['service'])
        queryClient.invalidateQueries(['services'])
        successMessage('Serviço disponibilizado para desembarque com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const updateAvailableService = useMutation(
    'updateAvailableService',
    ({
      serviceId,
      updateAvailableServiceInput,
    }: UpdateAvailableServiceReqProps) =>
      updateAvailableServiceReqFunction({
        serviceId,
        updateAvailableServiceInput,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['service'])
        queryClient.invalidateQueries(['services'])
        successMessage(
          'Informações de disponibilização do serviço atualizadas com sucesso!',
        )
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const updateAllocateServicesToLandingService = useMutation(
    'updateAllocateServiceToLandingService',
    () => updateAvailableServicesToLandingServiceReqFunction(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['services'])
        successMessage(
          'Serviços alocados foram disponibilizados para desembarque com sucesso!',
        )
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  return {
    updateAllocateServicesToLandingService,
    createAvailableService,
    updateAvailableService,
  }
}
