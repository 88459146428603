import { FormProvider, useForm } from "react-hook-form";
import { StandardBackgroundForm } from "../../../../components/Form/StandardBackgroundForm";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { useService } from "../../../../hooks/services/service";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDriver } from "../../../../hooks/driver/useDriver";
import { useEffect } from "react";
import { Accordion, Flex, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { FinishCollectServiceForm } from "../components/FinishCollectServiceForm";
import { GeneralContentLoading } from "../../../../components/Loading/GeneralContentLoading";
import { RequestedServiceInfoAccordion } from "../../../../components/Accordions/Service/RequestedServiceInfoAccordion";
import { useCollectServiceFunctions } from "../../../../hooks/collectService/useCollectServiceFunctions";
import { useSwal } from "../../../../hooks/swal/useSwal";
import { useGeolocation } from "../../../../hooks/useGeolocation";
import { toast } from "react-toastify";
import { useAddresses } from "../../../../hooks/address/useAddresses";
import { useProviders } from "hooks/provider/useProviders";
import { ExternalComunications } from "components/ExternalComunications";
import { useAuth } from "hooks/auth/useAuth";
import { formatDate } from "utils/DateFunctions/formatDate";
import { isAfter, isSameDay, set } from "date-fns";
import { ConfirmCollectsTotalVolumesAndInputs } from "../components/ConfirmCollectsTotalVolumesAndInputs";

interface IFormProps {
  provider_id: string
  driver_id: string
  responsible_name: string
  collect_volume: number
  collect_sample: number
  is_unsuccess: string
  box_photos: {
    attachment: FileList
  }
  content_declarations: {
    attachment: FileList
  }[]
  open_box_photo: FileList
  unsuccess_reason: 'RESPONSÁVEL NÃO LOCALIZADO' | 'LOCAL FECHADO' | 'PACIENTE NÃO COMPARECEU, SEM AVISO.' | 'CANCELAMENTO FORA DO PRAZO' | 'SEM MATERIAL PARA ENVIO' | 'DOCUMENTAÇÃO INCORRETA OU FALTANTE'
  real_arrival_date: string
  real_arrival_hour: string
  observation: string
  temp: number
}

interface IParams {
  service_id: string
  provider_id: string
}

const supportedFileFormats = [
  "image/jpeg",
  "image/pjpeg",
  "image/jpg",
  "image/png"
]
function validateHasFile(file: FileList) {
  if (!file?.length) return false

  return true
}

function validateFileType(file: FileList) {
  if (file?.length) {
    return supportedFileFormats.includes(file[0].type)
  }

  return true
}

function validateFileSize(file: FileList) {
  if (file?.length) {
    return file[0].size / 1024 / 1024 <= 15
  }

  return true
}

interface ValidateDateInputs {
  real_arrival_date: string,
  real_arrival_hour: string,
  collectDate: Date
}

function validateDate({
  real_arrival_date,
  real_arrival_hour,
  collectDate
}: ValidateDateInputs): boolean {

  const [year, month, date] = real_arrival_date.split('-')?.map(value => Number(value))
  const [hours, minutes] = real_arrival_hour.split(':')?.map(value => Number(value))

  const realArrivalTimestamp = set(new Date(), {
    date,
    month: month - 1,
    year: year,
    hours: hours,
    minutes: minutes
  })

  const currentDate = new Date();

  const isRealDepartureDateSameNow = isSameDay(realArrivalTimestamp, currentDate)

  if (!isSameDay(realArrivalTimestamp, collectDate) && isAfter(collectDate, realArrivalTimestamp)) {
    throw new yup.ValidationError(
      'A nova data de coleta não pode ser menor que a data esperada para coleta',
      real_arrival_date,
      'real_arrival_date'
    );
  }

  if (!isRealDepartureDateSameNow && isAfter(realArrivalTimestamp, currentDate)) {
    throw new yup.ValidationError(
      'A nova data de coleta não pode ser maior que a data atual',
      real_arrival_date,
      'real_arrival_date'
    );
  }

  if (isRealDepartureDateSameNow && isAfter(realArrivalTimestamp, currentDate)) {
    throw new yup.ValidationError(
      'O novo horário de coleta não pode ser maior que a data atual',
      real_arrival_hour,
      'real_arrival_hour'
    );
  }

  return true;
}



async function alertGeolocationError(error: GeolocationPositionError) {
  if (error.code === 1) {
    toast.error("Para iniciar a coleta, é necessário que a localização esteja habilitada!")
  }
  if (error.code === 2) {
    toast.error("Localização não está disponível! Tente recarregar a página ou verifique as configurações do dispositivo.")
  }
}

const schema = yup.object().shape({
  provider_id: yup.string().required("Campo Obrigatório"),
  driver_id: yup.string().required("Campo Obrigatório"),
  responsible_name: yup.string().required("Campo Obrigatório"),
  collect_volume: yup.number().when("is_unsuccess", {
    is: "NÃO",
    then: yup.number().typeError("Campo Obrigatório").required("Campo Obrigatório")
  }),
  collect_sample: yup.number().when("is_unsuccess", {
    is: "NÃO",
    then: yup.number().typeError("Campo Obrigatório").required("Campo Obrigatório")
  }),
  is_unsuccess: yup.string().required("Campo Obrigatório"),
  unsuccess_reason: yup.string().when('is_unsuccess', {
    is: 'SIM',
    then: yup.string().required('Campo obrigatório'),
  }),
  box_photos: yup.array().when('is_unsuccess', {
    is: 'NÃO',
    then: yup.array().min(1, 'Campo obrigatório').of(yup.object({
      attachment: yup.mixed()
        .test("length", "Campo obrigatório", value => validateHasFile(value))
        .test("type", "São suportados apenas os formatos de imagem: .jpeg, .pjpeg, jpg, png", value => validateFileType(value))
        .test("fileSize", "É suportado o upload de imagem somente até 10Mb", value => validateFileSize(value)),
    })).required('Campo Obrigatório'),
  }),
  content_declarations: yup.array().min(1, 'Campo obrigatório').of(yup.object({
    attachment: yup.mixed()
      .test("length", "Campo obrigatório", value => validateHasFile(value))
      .test("type", "São suportados apenas os formatos de imagem: .jpeg, .pjpeg, jpg, png", value => validateFileType(value))
      .test("fileSize", "É suportado o upload de imagem somente até 10Mb", value => validateFileSize(value)),
  })).required('Campo Obrigatório'),
  open_box_photo: yup.mixed()
    .test("type", "São suportados apenas os formatos de imagem: .jpeg, .pjpeg, jpg, png", value => validateFileType(value))
    .test("fileSize", "É suportado o upload de imagem somente até 15Mb", value => validateFileSize(value)),
  real_arrival_date: yup.string().when('$isEditRealArrivalTime', {
    is: true,
    then: yup.string().required('Campo obrigatório')
      .test('real_arrival_date', 'A nova data de coleta não pode ser menor que a data de entrega', async function (value) {
        return validateDate({
          real_arrival_date: value,
          real_arrival_hour: this.parent.real_arrival_hour,
          collectDate: new Date(this.options.context.collectDate)
        });
      })
  }),
  real_arrival_hour: yup.string().when('$isEditRealArrivalTime', {
    is: true,
    then: yup.string().required('Campo Obrigatório')
      .test('real_arrival_hour', 'O horário não pode ser maior que o horário atual', function (value) {
        return validateDate({
          real_arrival_hour: value,
          real_arrival_date: this.parent.real_arrival_date,
          collectDate: new Date(this.options.context.collectDate)
        });
      })
  }),
  observation: yup.string(),
  temp: yup.string().when('is_unsuccess', {
    is: 'NÃO',
    then: yup.string().when('$hasTempCheck', {
      is: true,
      then: yup.string().matches(/^-?\d+(\.\d+)?$/, 'Apenas números (positivos ou negativos) com ponto (.) são permitidos').required('Campo obrigatório')
    }),
  })
})

export function FinishProviderCollectService() {
  const { service_id, provider_id } = useParams<IParams>()
  const { push: redirectTo } = useHistory()
  const location = useLocation()
  const state = location.state as { path?: string }
  const hasPathRedirect = state?.path !== undefined && state?.path !== null

  const { userLogged } = useAuth()

  const {
    service: { data: service, isLoading: isServiceLoading }
  } = useService(service_id)
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()
  const { data: providers, isLoading: isProvidersLoading } = useProviders();
  const {
    drivers: { data: drivers, isLoading: isDriversLoading }
  } = useDriver(null, true)

  const {
    finishCollectService: {
      mutateAsync: finishCollectService, isLoading: isFinishCollectServiceLoading, isSuccess: isFinishCollectServiceSuccess
    },
    finishUnsuccessCollectService: {
      mutateAsync: finishUnsuccessCollectService, isLoading: isFinishUnsuccessCollectServiceLoading
    }
  } = useCollectServiceFunctions()

  const { confirmMessage, standardMessage } = useSwal()
  const { error: geolocationError, position } = useGeolocation()
  const collectServiceFiltered = service?.serviceIDCollect
    .find(collect => collect.provider_id === provider_id)

  const formMethods = useForm<IFormProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      is_unsuccess: "NÃO",
    },
    context: {
      collectDate: service?.serviceIDRequested.collect_date,
      isEditRealArrivalTime: collectServiceFiltered.real_arrival_timestamp && collectServiceFiltered.real_arrival_timestamp_validated,
      hasTempCheck: service?.customerIDService?.has_temp_check
    }
  })

  const { setValue, handleSubmit, watch } = formMethods

  const isUnsuccessCollect = watch("is_unsuccess") === "SIM"



  useEffect(() => {
    if (collectServiceFiltered) {
      setValue("provider_id", collectServiceFiltered?.provider_id)
      setValue("driver_id", collectServiceFiltered?.driver_id)
      setValue(
        "real_arrival_date",
        collectServiceFiltered.arrival_timestamp
          ? formatDate.handle(
            collectServiceFiltered.arrival_timestamp,
            'DateWithoutHourToInput',
          )
          : '',
      )
      setValue(
        'real_arrival_hour',
        collectServiceFiltered.arrival_timestamp
          ? formatDate.handle(
            collectServiceFiltered.arrival_timestamp,
            'DateOnlyWithHourMinute',
          )
          : '',
      )
    }
  }, [setValue, collectServiceFiltered])

  const providerCollectCount = service?.serviceIDRequested?.provider_id
    ? 1
    : 0

  const serviceFinishedCollectsCount = service?.serviceIDCollect?.filter(
    (collect) => collect.step !== 'GOING'
  )?.length

  const isLastCollect =
    (service?.serviceIDRequested?.source_address_id?.length + providerCollectCount) - serviceFinishedCollectsCount === 1 ||
    service?.serviceIDRequested?.source_address_id?.length + providerCollectCount === serviceFinishedCollectsCount

  const {
    isOpen: isConfirmCollectsTotalVolumesAndInputsModalOpen,
    onOpen: onOpenConfirmCollectsTotalVolumesAndInputsModal,
    onClose: onCloseConfirmCollectsTotalVolumesAndInputsModal
  } = useDisclosure()

  useEffect(() => {
    if (isFinishCollectServiceSuccess) {
      if (isLastCollect) {
        onOpenConfirmCollectsTotalVolumesAndInputsModal()
      } else {
        if(hasPathRedirect){
          redirectTo(state?.path)
          return
        }else{
          redirectTo(`/servicos/coletas/${service_id}`)
        }
      }
    }
  }, [redirectTo, service_id, isFinishCollectServiceSuccess, isLastCollect, onOpenConfirmCollectsTotalVolumesAndInputsModal, hasPathRedirect, state?.path])

  async function handleFinishCollectService(values: IFormProps) {
    const formData = new FormData()

    const [year, month, date] = values.real_arrival_date.split('-').map(Number)
    const [hours, minutes] = values.real_arrival_hour.split(':').map(Number)

    const realArrivalTimestamp = set(new Date(), {
      date,
      month: month - 1,
      year,
      hours,
      minutes
    })

    const collectTemperature =
    String(values.temp).replace(',', '.')

    Object.entries(values).forEach(([key, value]) => {
      if (["box_photos", "content_declarations", "open_box_photo"].includes(key)) {
        if (key === 'box_photos' || key === 'content_declarations') {
          value.forEach((v) => {
            formData.append(key, v.attachment[0])
          })
        } else {
          formData.append(key, value[0])
        }
      } else {
        formData.append(key, value)
      }
    })

    if (geolocationError) return await alertGeolocationError(geolocationError)

    formData.append("departure_latitude", String(position?.coords.latitude))
    formData.append("departure_longitude", String(position?.coords.longitude))
    formData.append("arrival_latitude", collectServiceFiltered?.arrival_latitude!)
    formData.append("arrival_longitude", collectServiceFiltered?.arrival_longitude!)
    formData.append("arrival_timestamp", collectServiceFiltered?.arrival_timestamp!)
    formData.append("departure_timestamp", String(new Date()))
    formData.append('real_arrival_timestamp', String(realArrivalTimestamp))
    formData.append("service_id", service_id)
    formData.delete("address_id")
    formData.append("temp", collectTemperature)

    const hasFinishAddressCollect = await confirmMessage({ title: "Deseja finalizar uma coleta?" })

    if (hasFinishAddressCollect) {
      await finishCollectService({ input: formData, id: collectServiceFiltered.id })
    } else {
      await standardMessage("Ação cancelada!")
    }

  }

  async function handleFinishCollectServiceAsUnsuccess(values: IFormProps) {
    const formData = new FormData()

    Object.entries(values).forEach(([key, value]) => {
      if (key === "content_declarations") {
        value.forEach((v) => {
          formData.append(key, v.attachment[0])
        })
      } else {
        formData.append(key, value)
      }
    })

    if (geolocationError) return await alertGeolocationError(geolocationError)

    formData.append("unsuccess_latitude", String(position?.coords.latitude))
    formData.append("unsuccess_longitude", String(position?.coords.longitude))
    formData.append("unsuccess_timestamp", String(new Date()))
    formData.delete("address_id")

    const hasFinishAddressUnsuccessCollect = await confirmMessage({ title: "Deseja finalizar a coleta como sem sucesso?" })

    if (hasFinishAddressUnsuccessCollect) {
      await finishUnsuccessCollectService({ input: formData, id: collectServiceFiltered.id })
    } else {
      await standardMessage("Ação cancelada!")
    }
  }

  const {
    isOpen: isExternalComunicationModalOpen,
    onOpen: onOpenExternalComunicationModal,
    onClose: onCloseExternalComunicationModal,
  } = useDisclosure()

  if (
    isServiceLoading ||
    isAddressesLoading ||
    isDriversLoading ||
    isFinishCollectServiceLoading ||
    isProvidersLoading ||
    isFinishUnsuccessCollectServiceLoading
  ) {
    return <GeneralContentLoading />
  }

  return (
    <>
      <Modal
        isOpen={isConfirmCollectsTotalVolumesAndInputsModalOpen}
        onClose={onCloseConfirmCollectsTotalVolumesAndInputsModal}
        size="2xl"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />

        <ConfirmCollectsTotalVolumesAndInputs
          inputs={[
            { name: 'CAIXA TÉRMICA', quantity: service?.serviceIDRequested?.caixa_termica },
            { name: 'ALMOFADA ABSORVENTE', quantity: service?.serviceIDRequested?.embalagem_secundaria },
            { name: 'EMBALAGEM SECUNDÁRIA ZIPLOCK', quantity: service?.serviceIDRequested?.embalagem_secundaria },
            { name: 'LACRE', quantity: service?.serviceIDRequested?.embalagem_secundaria },
            { name: 'GELO SECO', quantity: service?.serviceIDRequested?.gelo_seco },
            { name: 'GELOX', quantity: service?.serviceIDRequested?.gelox },
            { name: 'ISOPOR 3L', quantity: service?.serviceIDRequested?.isopor3l },
            { name: 'ISOPOR 7L', quantity: service?.serviceIDRequested?.isopor7l },
            { name: 'TERCIÁRIA 3L', quantity: service?.serviceIDRequested?.terciaria3l },
            { name: 'TERCIÁRIA 8L', quantity: service?.serviceIDRequested?.terciaria8l }
          ]}
          serviceId={service?.id}
          path={state?.path}
        />
      </Modal>

      {(userLogged?.user_type === 'COLETADOR' || userLogged?.user_type === 'MOTORISTA') && (
        <ExternalComunications.TriggerButton
          aria-label="help-me"
          onClick={onOpenExternalComunicationModal}
        />
      )}

      <ExternalComunications.Root
        isOpen={isExternalComunicationModalOpen}
        onClose={onCloseExternalComunicationModal}
      >
        <ExternalComunications.Content
          serviceId={service.id}
          onClose={onCloseExternalComunicationModal}
        />
      </ExternalComunications.Root>

      <StandardBackgroundForm
        onSubmit={
          handleSubmit(isUnsuccessCollect
            ? handleFinishCollectServiceAsUnsuccess
            : handleFinishCollectService
          )}
        title="Finalizar coleta"
      >
        <Flex direction="column" gap="4">
          <Accordion allowMultiple>
            <RequestedServiceInfoAccordion
              requestedServiceId={service?.serviceIDRequested.id!}
              addresses={addresses!}
            />
          </Accordion>
          <FormProvider {...formMethods}>
            <FinishCollectServiceForm
              providers={providers}
              drivers={drivers!}
              isProviderForm
              hasTempCheck={service.customerIDService.has_temp_check}
            />
          </FormProvider>
        </Flex>
      </StandardBackgroundForm>
    </>
  )
}
