import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface DeletePatrimonyParams {
  patrimonyId: string
}
export async function deletePatrimony({
  patrimonyId,
}: DeletePatrimonyParams) {
  try {
    await api.delete(`patrimonies/${patrimonyId}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
