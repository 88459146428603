import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { CltDriver, CltDriverStatus } from "./_types/CltDriver";

interface GetCltDriversParams {
  search?: string
  pageSize?: number
  currentPage?: number
  status?: CltDriverStatus
}

export interface GetCltDriversResponse {
  cltDrivers: CltDriver[]
  meta: {
    currentPage: number
    pageSize: number
  }
  count: number
  totalPages: number
}

export async function getCltDrivers({
  pageSize,
  currentPage,
  search,
  status
}: GetCltDriversParams) {
  try {
    const { data } = await api.get<GetCltDriversResponse>('/clt-drivers', {
      params: {
        pageSize,
        currentPage,
        search,
        status
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

