import { Tr, Td, IconButton, Modal, ModalOverlay, Button, useDisclosure, Icon } from "@chakra-ui/react";
import { Regional } from "api/regionals/_types/Regional";
import { GetVaccinesResponse } from "api/vaccines/getVaccines";
import { inactivateVaccine } from "api/vaccines/inactivateVaccine";
import { Vaccine } from "api/vaccines/vaccine";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaPen, FaSearch, FaTimes } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { vaccineCollaboratorTypeMap, vaccineTypesMap } from "utils/vaccineMappers";
import { EditVaccine } from "./EditVaccine";
import { VaccineDetail } from "./VaccineDetail";
import { VaccineStatus } from "./VaccineStatus";

interface VaccineTableRowProps {
  vaccine: Vaccine & {
    regional: Regional
  }
}

export function VaccinesTableRow({ vaccine }: VaccineTableRowProps) {
  const { userLogged } = useAuth()

  const userHasInactivateVaccinePermission = userLogged?.permissions.includes('inactivate-vaccine')
  const userHasEditVaccinePermission = userLogged?.permissions.includes('edit-vaccine')

  const {
    isOpen: isDetailModalOpen,
    onOpen: onOpenDetailModal,
    onClose: onCloseDetailModal,
  } = useDisclosure()

  const {
    isOpen: isEditVaccineModalOpen,
    onOpen: onOpenEditVaccineModal,
    onClose: onCloseEditVaccineModal
  } = useDisclosure()

  const queryClient = useQueryClient()

  const { mutateAsync: inactivateVaccineFn } = useMutation({
    mutationFn: inactivateVaccine,
    onSuccess(_data, { vaccineId }) {
      const cachedVaccines = queryClient.getQueriesData<GetVaccinesResponse>({
        queryKey: ['vaccine']
      })

      cachedVaccines.forEach(([cachedKey, cachedData]) => {
        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          vaccines: cachedData.vaccines.map((vaccine) => {
            if (vaccine.id === vaccineId) {
              return {
                ...vaccine,
                status: 'inactive',
                inactivated_at: new Date().toISOString(),
              }
            }

            return vaccine
          })
        })
      })
    },
  })

  const { promiseMessage } = useToastify()

  async function handleInactivateVaccine() {
    await promiseMessage(inactivateVaccineFn({
      vaccineId: vaccine.id
    }), 'Vacina inativada!')
  }

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Vacina"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onOpenDetailModal}
        />

        <Modal
          isOpen={isDetailModalOpen}
          onClose={onCloseDetailModal}
          isCentered
        >
          <ModalOverlay />

          <VaccineDetail vaccineId={vaccine.id} />

        </Modal>
      </Td>
      <Td>
        {userHasEditVaccinePermission && (
          <>
            <IconButton
              aria-label="Editar vacina"
              icon={<Icon as={FaPen} />}
              size="sm"
              variant="ghost"
              onClick={onOpenEditVaccineModal}
            />

            <Modal
              isOpen={isEditVaccineModalOpen}
              onClose={onCloseEditVaccineModal}
              isCentered
            >
              <ModalOverlay />
              <EditVaccine onCloseModal={onCloseEditVaccineModal} vaccineId={vaccine?.id} />
            </Modal>
          </>
        )}
      </Td>
      <Td>{vaccine.collaborator_name}</Td>
      <Td>{vaccineCollaboratorTypeMap[vaccine.collaborator_type]}</Td>
      <Td>{vaccine.regional ? vaccine.regional.code.toUpperCase() : '-'}</Td>
      <Td>{vaccineTypesMap[vaccine.type]}</Td>
      <Td>
        <VaccineStatus status={vaccine.status} />
      </Td>
      <Td>
        {
          vaccine.next_dose_date
            ? format(new Date(vaccine.next_dose_date), 'dd/MM/yyyy')
            : '-'
        }
      </Td>

      <Td>
        {(vaccine.status !== 'inactive' && userHasInactivateVaccinePermission) && (
          <Button
            leftIcon={<FaTimes />}
            lineHeight="1"
            size="xs"
            variant="ghost"
            onClick={handleInactivateVaccine}
          >
            Inativar
          </Button>
        )}
      </Td>
    </Tr>
  )
}
