import { Button, Flex, Icon, IconButton, Stack } from "@chakra-ui/react"
import { Select } from "components/Inputs/SelectInput"
import { UserProps } from "contexts/UserContext"
import { Control, DeepMap, FieldError, useFieldArray, UseFormRegister } from "react-hook-form"
import { FaPlus, FaTrash } from "react-icons/fa"
import { MonitoringUsersFormInputs } from "./MonitoringUsersForm"

interface MonitoringUsersPeriodResponsibleProps {
  index: number
  periodIndex: number
  control: Control<MonitoringUsersFormInputs>
  register: UseFormRegister<MonitoringUsersFormInputs>
  users: UserProps[]
  errors: DeepMap<MonitoringUsersFormInputs, FieldError>
  onSetDeletedMonitoringUsersIds?: (monitoringId: string) => void
  isViewPage?: boolean
}

export function MonitoringUsersPeriodResponsibleField({
  control,
  index,
  periodIndex,
  register,
  users,
  onSetDeletedMonitoringUsersIds,
  errors,
  isViewPage
}: MonitoringUsersPeriodResponsibleProps) {

  const {
    fields,
    append,
    remove
  } = useFieldArray({
    name: `monitoringDays.${index}.periods.${periodIndex}.responsibles`,
    control
  })

  const handleRemovePeriodResponsibleField = (index: number, monitoringId?: string) => {
    if (monitoringId) {
      onSetDeletedMonitoringUsersIds(monitoringId)
    }
    remove(index)
  }

  const handleAppendPeriodResponsibleField = () => append({
    userId: undefined
  })

  const responsiblesOptions = users?.map((user) => {
    return {
      key: user.id,
      value: user.id,
      showOption: `${user.firstname} ${user.lastname}`
    }
  })

  return (
    <Flex
      direction="column"
      gap="6"
    >
      {fields.map((field, fieldIndex) => {
        return (
          <Stack key={field.id} direction={["column", "column", "row"]} spacing="6">
            <Select
              {...register(`monitoringDays.${index}.periods.${periodIndex}.responsibles.${fieldIndex}.userId`)}
              name={`monitoringDays.${index}.periods.${periodIndex}.responsibles.${fieldIndex}.userId`}
              label="Usuário"
              error={
                errors?.monitoringDays
                  ? errors?.monitoringDays[index]?.periods
                    ? errors?.monitoringDays[index]?.periods[periodIndex]
                      ? errors?.monitoringDays[index]?.periods[periodIndex]?.responsibles[fieldIndex]?.userId
                      : undefined
                    : undefined
                  : undefined
              }
              placeholder="Selecione uma opção..."
              options={responsiblesOptions}
              isDisabled={isViewPage}
              required
            />

            <Button
              mt={["2", "2", "8"]}
              colorScheme="red"
              as={IconButton}
              w="10"
              onClick={() => handleRemovePeriodResponsibleField(fieldIndex, field?.monitoringId)}
              icon={<Icon as={FaTrash} />}
              hidden={isViewPage}
            />
          </Stack>
        )
      })}

      {!isViewPage && (
        <Button
          leftIcon={<Icon as={FaPlus} />}
          onClick={handleAppendPeriodResponsibleField}
          alignSelf="flex-end"
          w={["full", "full", "min"]}
          colorScheme="blue"
        >
          Novo responsável
        </Button>
      )}
    </Flex>
  )

}
