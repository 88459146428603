import {
  getAllStocks,
  getOneStock,
} from '../../services/getFunctions/stock/getStock'
import { useQuery } from 'react-query'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'

export function useStock(
  stockId: string | null,
  isEnable?: boolean,
  refetchOnFocus?: boolean,
) {
  const { handleLogout, userLogged } = useContext(AuthContext)

  const stocks = useQuery('stocks', getAllStocks, {
    enabled: !!isEnable && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (error: any) => {
      toast.error('Erro ao consultar informações! Realize o login novamente.')
      handleLogout()
    },
  })
  const stock = useQuery(
    ['stock', stockId],
    () => getOneStock(stockId !== null ? stockId : ''),
    {
      enabled: !!stockId && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )

  return {
    stocks,
    stock,
  }
}
