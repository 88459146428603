import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetDelayedLandingsResponse {
  services: {
    id: string
    protocol: number
    step: string
    customer: string
    shipping: string
    source_hub: string
    source_tz: string
    destination_hub: string
    destination_tz: string
  }[]
}

interface GetDelayedLandingsProps {
  queryParams?: { collectorId?: string }
}

export async function getDelayedLandings({ queryParams }: GetDelayedLandingsProps) {
  try {
    const { data } = await api.get<GetDelayedLandingsResponse>('/services/dashboard/landings/delayed', {
      params: {
        ...queryParams
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
