import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface SolveSupportBody {
  status: 'done' | 'waiting-return'
  content: string
  attachmentsIds?: string[]
}

interface SolveSupportProps {
  body: SolveSupportBody
  supportId: string

}
export async function solveSupport({
  body,
  supportId
}: SolveSupportProps) {
  try {
    const response = await api.put(`/supports/${supportId}/solve`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
