import { Box, Flex, Text } from "@chakra-ui/react";
import { useHub } from "hooks/hub/useHub";
import { useEffect } from "react";
import { GeneralContentLoading } from "../../../components/Loading/GeneralContentLoading";

import { useBranch } from "../../../hooks/branch/useBranch";
import { useBudget } from "../../../hooks/budget/useBudget";
import { useCity } from "../../../hooks/city/useCity";
import { useService } from "../../../hooks/services/service";
import { KanbanBoardedServices } from "./components/KanbanStepsContent/KanbanBoardedServices";
import { KanbanToBoardAndBoardingServices } from "./components/KanbanStepsContent/KanbanToBoardAndBoardingServices";
import { KanbanToCollectAndCollectingServices } from "./components/KanbanStepsContent/KanbanToCollectAndCollectingServices";
import { KanbanToDeliveryAndDeliveringServices } from "./components/KanbanStepsContent/KanbanToDeliveryAndDeliveringServices";

const serviceSteps = ["A coletar", "A embarcar", "Embarcados", "A entregar"]

export function KanbanViewServicesPage() {

  const { services: {
    data: services,
    isLoading: isServicesLoading,
    refetch: refetchServices
  } } = useService(null, true, false)

  const { budgets: {
    data: budgets,
    isLoading: isBudgetsLoading
  } } = useBudget(null, true, false)

  const { cities: {
    data: cities,
    isLoading: isCitiesLoading
  } } = useCity(null, true, false)

  const { branches: {
    data: branches,
    isLoading: isBranchesLoading
  } } = useBranch(null, true, false)

  const {
    hubs: { data: hubs, isFetching: isHubsFetching }
  } = useHub(null, true, false)

  useEffect(() => {
    const subscribeInterval = setInterval(() => {
      refetchServices()
    }, 30000)

    return () => {
      clearInterval(subscribeInterval)
    }
  }, [refetchServices])

  if (isServicesLoading || isCitiesLoading || isBudgetsLoading || isBranchesLoading || isHubsFetching) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="full" gap="8" p={['6', '8']}>
        {serviceSteps.map(step => {
          return (
            <Box w="25%" key={step}>
              <Text fontSize="xl" fontWeight="bold">{step}</Text>
              {step === "A coletar" && (
                <KanbanToCollectAndCollectingServices
                  services={services}
                  budgets={budgets}
                  cities={cities}
                  hubs={hubs}
                />
              )}
              {step === "A embarcar" && (
                <KanbanToBoardAndBoardingServices
                  services={services}
                  budgets={budgets}
                  cities={cities}
                  branches={branches}
                  hubs={hubs}
                />
              )}
              {step === "Embarcados" && (
                <KanbanBoardedServices
                  services={services}
                  budgets={budgets}
                  cities={cities}
                  branches={branches}
                  hubs={hubs}
                />
              )}
              {step === "A entregar" && (
                <KanbanToDeliveryAndDeliveringServices
                  services={services}
                  budgets={budgets}
                  cities={cities}
                  hubs={hubs}
                />
              )}
            </Box>
          )
        })}
      </Flex>

  )
}
