import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Stack,
  Link as ChakraLink,
  Icon,
  Spinner,
  Heading,
} from '@chakra-ui/react'
import { Fragment, useEffect, useState, memo } from 'react'
import { FiExternalLink } from 'react-icons/fi'

import { AllocatedServiceProps } from '../../../../contexts/AllocateServiceContext'
import {
  RequestedServiceProps,
  ServiceProps,
} from '../../../../contexts/ServiceContext'

import { handleChangeUrl } from '../../../../utils/handleChangeUrl'
import { serviceFormatDateToFront } from '../../../../utils/ServiceFunctions/serviceFormatDateToFront'
import { serviceFormatHourToFront } from '../../../../utils/ServiceFunctions/serviceFormatHourToFront'

import { TextArea } from '../../../Inputs/TextInput'
import { Input } from '../../../Inputs/Input'

import { useShipping } from '../../../../hooks/shipping/useShipping'
import { useCustomer } from '../../../../hooks/customer/useCustomer'
import { useCity } from '../../../../hooks/city/useCity'
import { useHub } from '../../../../hooks/hub/useHub'
import { useRequestedService } from '../../../../hooks/services/requestedService'
import { BoardServiceProps } from '../../../../services/getFunctions/getBoardServiceFunctions'
import { AvailableServiceProps } from '../../../../services/getFunctions/getAvailableServiceFunctions'
import { useAddresses } from '../../../../hooks/address/useAddresses'

interface BranchAccordionDeliveryProps {
  serviceByID: ServiceProps
}

function InfoAccordion({ serviceByID }: BranchAccordionDeliveryProps) {
  const [isLoading, setIsLoading] = useState(true)

  const [destinationHubId, setDestinationHubId] = useState('')
  const [sourceHubId, setSourceHubId] = useState('')
  const [shippingId, setShippingId] = useState('')
  const [customerId, setCustomerId] = useState('')

  const [requestedServiceById, setRequestedServiceById] = useState<
    RequestedServiceProps[]
  >([])
  const [boardServiceById, setBoardServiceById] = useState<BoardServiceProps[]>(
    [],
  )
  const [allocatedServiceById, setAllocatedServiceById] = useState<
    AllocatedServiceProps[]
  >([])
  const [availableServiceById, setAvailableServiceById] = useState<
    AvailableServiceProps[]
  >([])

  const {
    hub: { data: destinationHubById, isLoading: isDestinationHubLoading },
  } = useHub(destinationHubId, false, false)
  const {
    customer: { data: customerById, isLoading: isCustomerLoading },
  } = useCustomer(customerId, false, false)
  const {
    requestedService: { data: requestedById, isLoading: isReqServiceLoading },
  } = useRequestedService(
    serviceByID ? serviceByID.serviceIDRequested.id : null,
    false,
    false,
  )
  const {
    hub: { data: sourceHubById, isLoading: isSourceHubLoading },
  } = useHub(sourceHubId, false, false)
  const {
    shipp: { data: shippById, isLoading: isShipLoading },
  } = useShipping(shippingId, false, false)
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()
  const {
    cities: { data: cities, isLoading: isCitiesLoading },
  } = useCity(null, true, false)

  useEffect(() => {
    function run() {
      if (serviceByID !== undefined) {
        if (serviceByID.serviceIDBoard !== null) {
          setBoardServiceById(serviceByID.serviceIDBoard)
        }
        if (serviceByID.serviceIDAllocate !== null) {
          setAllocatedServiceById(serviceByID.serviceIDAllocate)
        }
        if (serviceByID.serviceIDAvailable !== null) {
          setAvailableServiceById(serviceByID.serviceIDAvailable)
        }
      }
    }
    run()
  }, [serviceByID])

  useEffect(() => {
    if (requestedById !== undefined) {
      const requestedServiceById = []
      requestedServiceById.push(requestedById)
      setRequestedServiceById(requestedServiceById)
      setShippingId(requestedById.sourceBranchIDService.shipping_id)
      setSourceHubId(requestedById.budgetIDService.source_hub_id)
      setDestinationHubId(requestedById.budgetIDService.destination_hub_id)
      setCustomerId(requestedById.serviceIDRequested.customer_id)
    }
  }, [requestedById])

  useEffect(() => {
    function run() {
      if (
        !isDestinationHubLoading &&
        !isCustomerLoading &&
        !isSourceHubLoading &&
        !isShipLoading &&
        !isAddressesLoading &&
        !isReqServiceLoading &&
        !isCitiesLoading
      ) {
        setIsLoading(false)
      }
    }
    run()
  }, [
    isDestinationHubLoading,
    isReqServiceLoading,
    isCustomerLoading,
    isSourceHubLoading,
    isShipLoading,
    isAddressesLoading,
    isCitiesLoading,
  ])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      {requestedServiceById.map((service) => {
        const isAllocateStep = service.serviceIDRequested.step === "toAllocateService"
        const isCrossdockingService = service.crossdocking_collector_id !== null

        let plannedFlight: string;

        if (isCrossdockingService) {
          const isCrossdockingBoardFinished = serviceByID.serviceIDBoard
            .find(board =>
              board.collector_id === service.crossdocking_collector_id
            )?.step === "DONE"

          if (isCrossdockingBoardFinished) {
            plannedFlight = service.planned_flight ?? ''
          } else {
            plannedFlight = service.crossdocking_planned_flight ?? ''
          }
        } else {
          plannedFlight = service.planned_flight ?? ''
        }

        return (
          <Fragment key={service.id}>
            {service.service_type === 'FRACIONADO' && (
              <AccordionItem>
                <h2>
                  <AccordionButton p="4">
                    <Box flex="1" textAlign="left">
                      Informações do serviço
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    {isAllocateStep && (
                      <Input
                        name="planned_flight"
                        value={plannedFlight}
                        label="Viagem planejada"
                        isDisabled={true}
                      />
                    )}
                    <Input
                      name="protocol"
                      value={service.serviceIDRequested.protocol}
                      label="Protocolo"
                      isDisabled={true}
                    />
                    <Input
                      name="customer"
                      value={customerById ? customerById.trading_firstname : ''}
                      label="Cliente"
                      isDisabled={true}
                    />
                  </Stack>
                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      name="source_cities"
                      value={
                        cities
                          ? cities
                            .filter((city) =>
                              service.budgetIDService.source_cities.includes(
                                city.id,
                              ),
                            )
                            .map((city) => city.name)
                            .join(',')
                          : ''
                      }
                      label="Cidades Remetentes"
                      isDisabled={true}
                    />
                  </Stack>

                  <Divider my="6" borderColor="gray.700" />

                  <Heading size="md" mt="4" fontFamily="poppins">
                    Remetente(s)
                  </Heading>
                  {!!addresses &&
                    addresses
                      .filter((address) =>
                        service.source_address_id.includes(address.id),
                      )
                      .map((address) => (
                        <Stack
                          key={address.id}
                          spacing="24px"
                          mt="4"
                          direction={['column', 'column', 'row']}
                        >
                          <Input
                            name="sender"
                            value={`${address.trading_name} - ${address.cep} | ${address.street}, ${address.number}, ${address.neighborhood}`}
                            label="Remetente"
                            isDisabled={true}
                          />
                        </Stack>
                      ))}

                  <Divider my="6" borderColor="gray.700" />

                  <Heading size="md" mt="4" fontFamily="poppins">
                    Destinatário(s)
                  </Heading>

                  {!!addresses &&
                    addresses
                      .filter((address) =>
                        service.destination_address_id.includes(address.id),
                      )
                      .map((address) => (
                        <Stack
                          key={address.id}
                          spacing="24px"
                          mt="4"
                          direction={['column', 'column', 'row']}
                        >
                          <Input
                            name="recipient"
                            value={`${address.trading_name} - ${address.cep} | ${address.street}, ${address.number}, ${address.neighborhood}`}
                            label="Destinatário"
                            isDisabled={true}
                          />
                        </Stack>
                      ))}

                  <Divider my="6" borderColor="gray.700" />

                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      name="collect_date"
                      value={serviceFormatDateToFront(service.collect_date)}
                      label="Data da Coleta"
                      type="date"
                      isDisabled={true}
                    />
                    <Input
                      name="collect_hour_start"
                      value={serviceFormatHourToFront(service.collect_hour_start)}
                      label="Horário da Coleta"
                      isDisabled={true}
                    />
                  </Stack>
                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      name="delivery_date"
                      value={serviceFormatDateToFront(service.delivery_date)}
                      label="Data da Entrega"
                      type="date"
                      isDisabled={true}
                    />
                    <Input
                      name="delivery_hour"
                      value={serviceFormatHourToFront(service.delivery_date)}
                      label="Horário da Entrega"
                      isDisabled={true}
                    />
                  </Stack>
                  {boardServiceById.map((bService) => (
                    <Fragment key={bService.id}>
                      <Stack
                        spacing="24px"
                        mt="4"
                        direction={['column', 'column', 'row']}
                      >
                        <Input name="link_box" hidden>
                          <ChakraLink
                            fontSize="lg"
                            href={handleChangeUrl(bService.cte_photo as any)}
                            isExternal
                          >
                            Foto do Cte <Icon as={FiExternalLink} mx="2px" />
                          </ChakraLink>
                        </Input>
                      </Stack>
                      <Stack
                        spacing="24px"
                        mt="4"
                        direction={['column', 'column', 'row']}
                      >
                        <Input
                          name="operational_number"
                          value={bService.operational_number}
                          label="Rastreador"
                          isDisabled={true}
                        />
                      </Stack>
                      <Stack
                        spacing="24px"
                        mt="4"
                        direction={['column', 'column', 'row']}
                      >
                        <Input
                          name="cte"
                          value={bService.cte !== null ? bService.cte : ''}
                          label="CTE"
                          isDisabled={true}
                        />

                        <Input
                          name="cte_loglife"
                          value={
                            service.serviceIDRequested.cte_loglife !== null
                              ? service.serviceIDRequested.cte_loglife
                              : ''
                          }
                          label="CTE Loglife"
                          isDisabled={true}
                        />
                      </Stack>

                      <Stack
                        spacing="24px"
                        mt="4"
                        direction={['column', 'column', 'row']}
                      >
                        <Input
                          name="board_volume"
                          value={
                            bService.board_volume !== null
                              ? bService.board_volume
                              : ''
                          }
                          type="number"
                          label="Volume Embarcado"
                          isDisabled={true}
                        />

                        <Input
                          name="taxed_weight"
                          value={
                            bService.taxed_weight !== null
                              ? bService.taxed_weight
                              : ''
                          }
                          label="Peso Taxado"
                          isDisabled={true}
                        />
                      </Stack>
                    </Fragment>
                  ))}

                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      name="shipping"
                      value={shippById ? shippById.company_name : ''}
                      label="Transportadora"
                      isDisabled={true}
                    />
                  </Stack>

                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      name="source_branch"
                      value={service.sourceBranchIDService.nickname}
                      label="Base Origem"
                      isDisabled={true}
                    />

                    <Input
                      name="destination_branch"
                      value={service.destinationBranchIDService.nickname}
                      label="Base Destino"
                      isDisabled={true}
                    />
                  </Stack>

                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      name="source_hub"
                      value={sourceHubById ? sourceHubById.name : ''}
                      label="HUB Origem"
                      isDisabled={true}
                    />

                    <Input
                      name="destination_hub"
                      value={destinationHubById ? destinationHubById.name : ''}
                      label="HUB Destino"
                      isDisabled={true}
                    />
                  </Stack>
                  {service.serviceIDRequested.step === 'toAllocateService' &&
                    boardServiceById.map((bService) => (
                      <TextArea
                        name="board_validate_observation"
                        label="Observações do Embarque Validado"
                        isDisabled={true}
                        value={
                          bService.validate_observation !== null
                            ? bService.validate_observation
                            : ''
                        }
                        key={bService.id}
                      />
                    ))}

                  {service.serviceIDRequested.step === 'toAvailableService' &&
                    allocatedServiceById.map((alService) => (
                      <TextArea
                        name="allocated_observation"
                        label="Observações do Serviço Alocado"
                        isDisabled={true}
                        value={
                          alService.observation !== null
                            ? alService.observation
                            : ''
                        }
                        key={alService.id}
                      />
                    ))}

                  {(service.serviceIDRequested.step === 'toLandingService' ||
                    service.serviceIDRequested.step === 'landingService') &&
                    availableServiceById.map((avService) => (
                      <TextArea
                        name="available_observation"
                        label="Observações do Serviço Disponibilizado"
                        isDisabled={true}
                        value={
                          avService.observation !== null
                            ? avService.observation
                            : ''
                        }
                        key={avService.id}
                      />
                    ))}
                </AccordionPanel>
              </AccordionItem>
            )}
            <Divider my="6" borderColor="gray.700" />
          </Fragment>
        )
      })}
    </>
  )
}

export const InformationAccordion = memo(InfoAccordion)
