import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";


interface ReviewDocumentBody {
  isDocumentChanged: boolean
  documentAttachmentId: string
  nextReviewDate: string
  version: string
}

interface ReviewDocumentProps {
  body: ReviewDocumentBody
  routeParams: {
    documentId: string
  }
}

export async function reviewDocument({
  body,
  routeParams
}: ReviewDocumentProps) {
  try {
    const response = await api.put(`/documents/${routeParams.documentId}/review`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
