import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Socket } from "socket.io-client";
import { socketApi } from "../services/socket/socket";

interface BugSocketContextProps {
  bugSocketConnection: Socket | null
}

interface BugSocketProviderProps {
  children: ReactNode
}

interface BugNotificationData {
  message: string
  assignedUsers: string[]
}

export const BugSocketContext = createContext({} as BugSocketContextProps)

export function BugSocketProvider({ children }: BugSocketProviderProps) {
  const [bugSocketConnection, setBugSocketConnection] = useState<Socket | null>(null)

  const { infoMessage } = useToastify()
  const { userLogged } = useAuth()

  const queryClient = useQueryClient()

  useEffect(() => {
    setBugSocketConnection(socketApi.connect("bugs"))
  }, [])

  useEffect(() => {
    bugSocketConnection?.on("getBugSubjectUpdatedNotification", async ({
      assignedUsers,
      message,
    }: BugNotificationData) => {
      const isUserLoggedNotification = assignedUsers.includes(userLogged?.id)

      if (isUserLoggedNotification) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications']})

        infoMessage(message)
      }
    })

    bugSocketConnection?.on("getBugDescriptionUpdatedNotification", async ({
      assignedUsers,
      message,
    }: BugNotificationData) => {
      const isUserLoggedNotification = assignedUsers.includes(userLogged?.id)

      if (isUserLoggedNotification) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications']})

        infoMessage(message)
      }
    })

    bugSocketConnection?.on("getBugValidatedNotification", async ({
      assignedUsers,
      message,
    }: BugNotificationData) => {
      const isUserLoggedNotification = assignedUsers.includes(userLogged?.id)

      if (isUserLoggedNotification) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications']})

        infoMessage(message)
      }
    })

    bugSocketConnection?.on("getBugValidationRemovedNotification", async ({
      assignedUsers,
      message,
    }: BugNotificationData) => {
      const isUserLoggedNotification = assignedUsers.includes(userLogged?.id)

      if (isUserLoggedNotification) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications']})

        infoMessage(message)
      }
    })

    bugSocketConnection?.on("getBugCommentCreatedNotification", async ({
      assignedUsers,
      message,
    }: BugNotificationData) => {
      const isUserLoggedNotification = assignedUsers.includes(userLogged?.id)

      if (isUserLoggedNotification) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications']})

        infoMessage(message)
      }
    })

    return () => {
      bugSocketConnection?.off("getBugSubjectUpdatedNotification")
      bugSocketConnection?.off("getBugDescriptionUpdatedNotification")
      bugSocketConnection?.off("getBugValidatedNotification")
      bugSocketConnection?.off("getBugValidationRemovedNotification")
      bugSocketConnection?.off("getBugCommentCreatedNotification")
    }
  }, [bugSocketConnection, queryClient, infoMessage, userLogged])

  return (
    <BugSocketContext.Provider value={{
      bugSocketConnection,
    }}>
      {children}
    </BugSocketContext.Provider>
  )
}
