import { RequestedServiceProps } from '../../contexts/ServiceContext'
import { api } from '../api'

export const getAllRequestedServices = () =>
  api.get<RequestedServiceProps[]>('/requested-service').then((res) => res.data)
export const getOneRequestedService = (requestedServiceId: string) =>
  api
    .get<RequestedServiceProps>(`/requested-service/${requestedServiceId}`)
    .then((res) => res.data)

export const getOneRequestedServiceByServiceId = (serviceId: string) =>
  api
    .get<RequestedServiceProps>(`/requested-service/service/${serviceId}`)
    .then((res) => res.data)
