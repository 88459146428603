import { createContext, ReactNode, useCallback, useState } from "react";

export type MaterialRequestsTableButtonStatus = 'requested' | 'awaiting-delivery' | 'delivered' | 'reproved'

interface MaterialRequestsStatusTableButtonContextProps {
  status: MaterialRequestsTableButtonStatus
  handleChangeStatus: (status: MaterialRequestsTableButtonStatus) => void
}

interface MaterialRequestsStatusTableButtonContextProviderProps {
  children: ReactNode
}

export const MaterialRequestsStatusTableButtonContext = createContext(
  {} as MaterialRequestsStatusTableButtonContextProps
)

export function MaterialRequestsStatusTableButtonProvider({
  children
}: MaterialRequestsStatusTableButtonContextProviderProps) {
  const [status, setStatus] = useState<MaterialRequestsTableButtonStatus>('requested')

  const handleChangeStatus = useCallback((status: MaterialRequestsTableButtonStatus) => {
    setStatus(status)
  }, [])

  return (
    <MaterialRequestsStatusTableButtonContext.Provider value={{
      status,
      handleChangeStatus
    }}>
      {children}
    </MaterialRequestsStatusTableButtonContext.Provider>
  )
}
