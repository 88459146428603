import { Button, Flex, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Stack, Text, Textarea } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createCltIntercurrence } from "api/cltIntercurrences/createCltIntercurrence";
import { set } from "date-fns";
import { useToastify } from "hooks/toastify/useToastify";
import { ExtraHoursCltIntercurrenceForm } from "pages/CltIntercurrences/components/ExtraHoursCltIntercurrenceForm";
import { MissWorkCltIntercurrenceForm } from "pages/CltIntercurrences/components/MissWorkCltIntercurrenceForm";
import { PatrimonyWithoutReturnCltIntercurrenceForm } from "pages/CltIntercurrences/components/PatrimonyWithoutReturnCltIntercurrenceForm";
import { TicketLogAdditionalValueCltIntercurrenceForm } from "pages/CltIntercurrences/components/TicketLogAdditionalValueCltIntercurrenceForm";
import { WorkAccidentCltIntercurrenceForm } from "pages/CltIntercurrences/components/WorkAccidentCltIntercurrenceForm";
import { useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useMutation } from "react-query";
import { validateHasFile } from "utils/fileValidation";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import * as yup from "yup"

interface CreateCltIntercurrenceSchema {
  type: string
  date: string
  hour: string
  test: string
  description: string
  regional: string
  otherRegional: string
  partnerType: string
  collaboratorName: string

  isTrajectoryOrOccupationalAccident: string
  accidentAddress: string
  bodyPart: string
  medicalCareTime: string
  medicalCertificateCid: string
  medicalName: string
  medicalCrm: string

  missWorkType: string
  attachment: FileList

  discountTotalValue: string

  minutesQuantity: number

  employeeCarLicensePlate: string
}

const createCltIntercurrenceSchema = yup.object({
  type: yup.string().required(),
  date: yup.string().required(),
  hour: yup.mixed().when('type', {
    is: 'work-accident',
    then: yup.string().required()
  }),
  description: yup.string().required(),
  regional: yup.string().required(),
  otherRegional: yup.mixed().when('regional', {
    is: 'other',
    then: yup.string().required()
  }),
  partnerType: yup.string().required(),
  collaboratorName: yup.string().required(),

  isTrajectoryOrOccupationalAccident: yup.mixed().when('type', {
    is: 'work-accident',
    then: yup.string().required()
  }),
  accidentAddress: yup.mixed().when('type', {
    is: 'work-accident',
    then: yup.string().required()
  }),
  bodyPart: yup.mixed().when('type', {
    is: 'work-accident',
    then: yup.string().required()
  }),
  medicalCareTime: yup.mixed().when('type', {
    is: 'work-accident',
    then: yup.string().required()
  }),
  medicalCertificateCid: yup.mixed().when('type', {
    is: 'work-accident',
    then: yup.string().required()
  }),
  medicalName: yup.mixed().when('type', {
    is: 'work-accident',
    then: yup.string().required()
  }),
  medicalCrm: yup.mixed().when('type', {
    is: 'work-accident',
    then: yup.string().required()
  }),

  missWorkType: yup.mixed().when('type', {
    is: 'miss-work',
    then: yup.string().required()
  }),
  attachment: yup.mixed().when('missWorkType', {
    is: 'justified',
    then: yup.mixed().test(validateHasFile)
  }),

  discountTotalValue: yup.mixed().when('type', {
    is: 'patrimony-without-return',
    then: yup.string().required()
  }),

  minutesQuantity: yup.mixed().when('type', {
    is: (value: string) => ['late-or-early-departure', 'extra-hours'].includes(value),
    then: yup.number().required().typeError('')
  }),

  employeeCarLicensePlate: yup.mixed().when('type', {
    is: 'ticket-log-additional-value',
    then: yup.string().required()
  }),
})

interface CreateCltDriverCltIntercurrenceCardProps {
  driverName: string
  driverRegional: string
  onCloseModal: () => void
  onSetIsCltIntercurrenceCreatedOrNotNecessary: (value: boolean) => void
}

export function CreateCltDriverCltIntercurrenceModal({
  driverName,
  driverRegional,
  onCloseModal,
  onSetIsCltIntercurrenceCreatedOrNotNecessary,
}: CreateCltDriverCltIntercurrenceCardProps) {
  const [attachmentId, setAttachmentId] = useState<string | null>(null)

  const formProps = useForm<CreateCltIntercurrenceSchema>({
    resolver: yupResolver(createCltIntercurrenceSchema),
    defaultValues: {
      collaboratorName: driverName,
      regional: driverRegional.toLowerCase(),
      partnerType: 'clt-driver'
    }
  })

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    }
  } = formProps

  const intercurrrenceType = useWatch({
    control,
    name: 'type'
  })

  const regional = useWatch({
    control,
    name: 'regional'
  })

  const isWorkAccidentIntercurrence = intercurrrenceType === 'work-accident'
  const isMissWorkdIntercurrence = intercurrrenceType === 'miss-work'
  const isPatrimonyWithoutReturnIntercurrence = intercurrrenceType === 'patrimony-without-return'
  const isExtraHoursIntercurrence = intercurrrenceType === 'extra-hours'
  const isLateOrEarlyDeparture = intercurrrenceType === 'late-or-early-departure'
  const isTicketLogAdditionalValueIntercurrence = intercurrrenceType === 'ticket-log-additional-value'

  const isOtherRegional = regional === 'other'

  const { mutateAsync: createCltIntercurrenceFn } = useMutation({
    mutationFn: createCltIntercurrence,
    onSuccess: () => {
      onSetIsCltIntercurrenceCreatedOrNotNecessary(true)

      onCloseModal()

    }
  })

  const { promiseMessage } = useToastify()

  async function handleCreateCltIntercurrenceFn(values: CreateCltIntercurrenceSchema) {
    const [year, month, day] = values.date.split('-').map(Number)

    let intercurrenceDate = set(new Date(), {
      year: year,
      month: month - 1,
      date: day,
    })

    if (values.hour) {
      const [hours, minutes] = values.hour.split(':').map(Number)

      set(intercurrenceDate, {
        hours,
        minutes
      })
    }

    const body = {
      type: values.type,
      date: intercurrenceDate,
      collaboratorName: values.collaboratorName,
      description: values.description,
      partnerType: values.partnerType,
      regional: values.regional === 'other' ? values.otherRegional : values.regional,
    }

    if (values.type === 'work-accident') {
      const [hours, minutes] = values.medicalCareTime.split(':').map(Number)

      body['isTrajectoryOrOccupationalAccident'] = values.isTrajectoryOrOccupationalAccident === 'yes'
      body['accidentAddress'] = values.accidentAddress
      body['bodyPart'] = values.bodyPart
      body['medicalCareTime'] = set(new Date(), { hours, minutes })
      body['medicalCertificateCid'] = values.medicalCertificateCid
      body['medicalCrm'] = values.medicalCrm
      body['medicalName'] = values.medicalName
    }

    if (values.type === 'miss-work') {
      body['attachmentId'] = attachmentId
    }

    if (values.type === 'patrimony-without-return') {
      const discountTotalValueInCents = Math.ceil(transformStringToNumber(values.discountTotalValue) * 100)

      body['discountTotalValueInCents'] = discountTotalValueInCents
    }

    if (values.type === 'extra-hours' || values.type === 'late-or-early-departure') {
      body['minutesQuantity'] = values.minutesQuantity
    }

    if (values.type === 'ticket-log-additional-value') {
      body['employeeCarLicensePlate'] = values.employeeCarLicensePlate
    }

    await promiseMessage(createCltIntercurrenceFn({ body }), 'Intercorrência clt criada!')
  }

  function handleSetAttachmentId(attachmentId: string) {
    setAttachmentId(attachmentId)
  }

  return (
    <ModalContent
      p="4"
      rounded="md"
      bg="white"
      border="1px solid"
      borderColor="gray.100"
    >
      <ModalHeader fontSize="lg" letterSpacing="tight">
        Criar Intercorrência CLT
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <Flex
          direction="column"
          gap="4"
          as="form"
          onSubmit={handleSubmit(handleCreateCltIntercurrenceFn)}
          mt="4"
        >
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing="3"
          >
            <FormControl isInvalid={!!errors.type}>
              <FormLabel>
                Tipo de intercorrência
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register('type')}
                placeholder="Selecione um tipo..."
                size="sm"
                rounded="md"
              >
                <option value="work-accident">Acidente de trabalho</option>
                <option value="miss-work">Falta</option>
                <option value="vacation">Férias</option>
                <option value="home-office">Home office</option>
                <option value="patrimony-without-return">Patrimônio sem devolução</option>
                <option value="extra-hours">HE autorizada</option>
                <option value="turn-change">Troca de turno</option>
                <option value="late-or-early-departure">Atraso ou saída antecipada</option>
                <option value="ticket-log-additional-value">Valor adicional ticket log</option>
                <option value="other">Outra</option>
              </Select>
            </FormControl>

            <Stack
              w="full"
              direction={{ base: 'column', lg: 'row' }}
              spacing="3"
            >
              <FormControl isInvalid={!!errors.type}>
                <FormLabel>
                  Data da intercorrência
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('date')}
                  type="date"
                  size="sm"
                  rounded="md"
                />
              </FormControl>

              {isWorkAccidentIntercurrence && (
                <FormControl isInvalid={!!errors.type}>
                  <FormLabel>
                    Horário da intercorrência
                    <Text as="sup" color="red.500">*</Text>
                  </FormLabel>
                  <Input
                    {...register('hour')}
                    type="time"
                    size="sm"
                    rounded="md"
                  />
                </FormControl>
              )}
            </Stack>
          </Stack>


          <FormControl isInvalid={!!errors.description}>
            <FormLabel>
              Descrição
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Textarea
              {...register('description')}
              placeholder="Descreva a intercorrência..."
              size="sm"
              rounded="md"
            />
          </FormControl>

          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing="3"
          >
            <Stack
              w="full"
              direction={{ base: 'column', lg: 'row' }}
              spacing="3"
            >
              <FormControl isInvalid={!!errors.type}>
                <FormLabel>
                  Regional
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Select
                  {...register('regional')}
                  placeholder="Selecione uma regional..."
                  size="sm"
                  rounded="md"
                  isDisabled
                >
                  <option value="mg">MG</option>
                  <option value="rj">RJ</option>
                  <option value="df">DF</option>
                  <option value="sp">SP</option>
                  <option value="other">Outro</option>
                </Select>
              </FormControl>
              {isOtherRegional && (
                <FormControl isInvalid={!!errors.type}>
                  <FormLabel>
                    Outra regional
                    <Text as="sup" color="red.500">*</Text>
                  </FormLabel>
                  <Input
                    {...register('otherRegional')}
                    size="sm"
                    rounded="md"
                  />
                </FormControl>
              )}
            </Stack>

            <Stack
              w="full"
              direction={{ base: 'column', lg: 'row' }}
              spacing="3"
            >
              <FormControl isInvalid={!!errors.type}>
                <FormLabel>
                  Tipo de parceiro
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Select
                  {...register('partnerType')}
                  placeholder="Selecione um tipo de parceiro..."
                  size="sm"
                  rounded="md"
                  isDisabled
                >
                  <option value="rh-managment">RH Gerencial</option>
                  <option value="clt-driver">Motorista CLT</option>
                </Select>
              </FormControl>


              <FormControl isInvalid={!!errors.type}>
                <FormLabel>
                  Nome do colaborador
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('collaboratorName')}
                  rounded="md"
                  size="sm"
                  isDisabled
                />
              </FormControl>
            </Stack>
          </Stack>

          {isWorkAccidentIntercurrence && (
            <FormProvider {...formProps}>
              <WorkAccidentCltIntercurrenceForm />
            </FormProvider>
          )}

          {isMissWorkdIntercurrence && (
            <FormProvider {...formProps}>
              <MissWorkCltIntercurrenceForm
                onSetAttachmentId={handleSetAttachmentId}
              />
            </FormProvider>
          )}

          {isPatrimonyWithoutReturnIntercurrence && (
            <FormProvider {...formProps}>
              <PatrimonyWithoutReturnCltIntercurrenceForm />
            </FormProvider>
          )}

          {(isExtraHoursIntercurrence || isLateOrEarlyDeparture) && (
            <FormProvider {...formProps}>
              <ExtraHoursCltIntercurrenceForm />
            </FormProvider>
          )}

          {isTicketLogAdditionalValueIntercurrence && (
            <FormProvider {...formProps}>
              <TicketLogAdditionalValueCltIntercurrenceForm />
            </FormProvider>
          )}

          <Button
            type="submit"
            colorScheme="blue"
            w="min"
            alignSelf="end"
            isLoading={isSubmitting}
            disabled={isSubmitting}
            size="sm"
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>

    </ModalContent>
  )
}
