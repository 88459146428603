import axios from 'axios'
import { useMutation } from 'react-query'
import { api } from 'services/api'
import { RequestError } from 'utils/errors/RequestErrors'
import {
  generateServiceLabelReqFunction,
  GenerateLabelInputProps,
} from '../../../utils/RequestFunctions/Service/Label/requestGenerateServiceLabelFunction'
import { useSwal } from '../../swal/useSwal'

export interface LabelInfo {
  id: string
  service_id: string
  source_address_id: string
  destination_address_id: string
  quantity: number
}

interface GenerateServicesLabelsInput {
  labelsInfo: LabelInfo[]
}

async function generateServicesLabelsReqFunction(input: GenerateServicesLabelsInput) {
  try {
    const { data } = await api.post("/service/label", input, {
      responseType: 'arraybuffer'
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

export const useGenerateLabelFunctions = () => {
  const { errorMessage } = useSwal()

  const generateServiceLabel = useMutation(
    'generateServiceLabel',
    (input: GenerateLabelInputProps) => generateServiceLabelReqFunction(input),
    {
      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const generateServicesLabels = useMutation(
    'generateServicesLabel', generateServicesLabelsReqFunction
  )

  return {
    generateServiceLabel,
    generateServicesLabels
  }
}
