import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Contingency } from "./_types/Contingency";


export interface GetContingenciesParams {
  pageSize: number
  currentPage: number
}

export interface GetContingenciesResponse {
  contingencies: Contingency[]
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
    count: number
  }
}

export async function getContingencies({
  currentPage,
  pageSize
}: GetContingenciesParams) {
  try {
    const response = await api.get<GetContingenciesResponse>('/contingencies', {
      params: {
        pageSize,
        currentPage
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
