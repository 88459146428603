import axios from 'axios'
import { useAuth } from 'hooks/auth/useAuth'
import { useMutation, useQueryClient } from 'react-query'
import { api } from 'services/api'
import { HttpErrorStatusCodeEnum } from 'utils/errors/HttpStatusCodeEnum'
import { RequestError } from 'utils/errors/RequestErrors'
import {
  CreateGeloSecoMaintenanceProps,
  createGeloSecoMaintenanceReqFunction
} from '../../utils/RequestFunctions/Service/serviceRequestFunctions'
import { useSwal } from '../swal/useSwal'

export type ServiceCancelReasons = 'CLIENTE SOLICITOU CANCELAMENTO' |
  'PACIENTE NÃO COMPARECEU' | 'PACIENTE NÃO COMPARECEU' |
  'SERVIÇO ABERTO INCORRETAMENTE'

interface RequestCancelServiceInputs {
  cancel_reason: ServiceCancelReasons
  cancel_observation?: string
}

interface RequestCancelServiceReqProps {
  serviceId: string
  input: RequestCancelServiceInputs
}

async function requestCancelServiceReqFunction({
  serviceId,
  input
}: RequestCancelServiceReqProps) {
  try {
    await api.put(`/service/request-cancel/${serviceId}`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

interface ValidateCancelServiceRequestProps {
  serviceId: string
  hasValidate: boolean
}

async function validateCancelServiceRequestReqFunction({
  serviceId,
  hasValidate
}: ValidateCancelServiceRequestProps) {
  try {
    await api.patch(`/service/validate-cancel-request/${serviceId}`, {
      has_validate: hasValidate
    })

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

async function auditServiceRequestReqFunction(serviceId: string) {
  try {
    await api.patch(`/services/${serviceId}/audit`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

export const useServiceFunctions = () => {
  const { successMessage, errorMessage } = useSwal()
  const { handleLogout } = useAuth()

  const queryClient = useQueryClient()

  const createGeloSecoMaintenance = useMutation(
    'createGeloSecoMaintenance', (
      { service_id, input }: CreateGeloSecoMaintenanceProps) => createGeloSecoMaintenanceReqFunction({
        service_id,
        input
      }),
    {
      onSuccess: () => {
        successMessage('Manutenção de gelo seco realizada com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  const onRequestSuccess = () => {
    queryClient.invalidateQueries(['service'])
    queryClient.invalidateQueries(['services'])
    queryClient.invalidateQueries(['servicesBySteps'])
  }

  const onRequestError = async (error: RequestError) => {
    if (error.httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  const requestCancelService = useMutation(
    'requestCancelService', requestCancelServiceReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  const validateCancelServiceRequest = useMutation(
    'validateCancelServiceRequest', validateCancelServiceRequestReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  const auditServiceRequest = useMutation(
    'auditServiceRequest', auditServiceRequestReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  return {
    createGeloSecoMaintenance,
    requestCancelService,
    validateCancelServiceRequest,
    auditServiceRequest
  }
}
