import { Box, Divider, Grid, GridItem, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, Tbody, Td, Tr } from "@chakra-ui/react"
import { getPassiveFeedback } from "api/feedbacks/getPassiveFeedback"
import { useAuth } from "hooks/auth/useAuth"
import { useQuery } from "react-query"
import { PassiveFeedbackDetailTable } from "./PassiveFeedbackDetailTable"
import { RealizeCltDriverPassiveFeedback } from "./RealizeCltDriverPassiveFeedback"
import { RealizeInternCltPassiveFeedback } from "./RealizeInternCltPassiveFeedback"
import { RealizeRecurrentCltDriverPassiveFeedback } from "./RealizeRecurrentCltDriverPassiveFeedback"
import { RealizeRecurrentInternCltPassiveFeedback } from "./RealizeRecurrentInternCltPassiveFeedback"

interface PassiveFeedbackDetailsProps {
  passiveFeedbackId: string
  onClose: () => void
}

export function PassiveFeedbackDetails({
  passiveFeedbackId,
  onClose
}: PassiveFeedbackDetailsProps) {

  const { userLogged } = useAuth()
  const userCanRealizePassiveFeedback = userLogged?.permissions.includes('realize-passive-feedback')

  const {
    data: passiveFeedbackResult,
    isLoading: isLoadingPassiveFeedbackResult
  } = useQuery({
    queryKey: ['passive-feedback', passiveFeedbackId],
    queryFn: () => getPassiveFeedback({ passiveFeedbackId })
  })


  return (
    <ModalContent >
      <ModalHeader>
        Detalhes Feedback
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        {isLoadingPassiveFeedbackResult ? (
          <Table size="sm">
            <Tbody>
              {Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr key={index}>
                    <Td>
                      <Skeleton w="full" h="16px" />
                    </Td>
                    <Td isNumeric>
                      <Skeleton w="full" h="16px" />
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>

        ) : (
          <Grid
            templateColumns={
              userCanRealizePassiveFeedback && passiveFeedbackResult?.passiveFeedback.status === 'scheduled'
                ? "1fr auto 1fr"
                : "1fr"
            }
            gap={6}
          >
            <GridItem>
              <PassiveFeedbackDetailTable
                passiveFeedback={passiveFeedbackResult}
              />
            </GridItem>
            {userCanRealizePassiveFeedback && passiveFeedbackResult?.passiveFeedback.status === 'scheduled' && (
              <>
                <Box>
                  <Divider orientation="vertical" />
                </Box>
                <GridItem>
                  {passiveFeedbackResult?.passiveFeedback?.type === 'initial' && passiveFeedbackResult?.passiveFeedback?.internClt && (
                    <RealizeInternCltPassiveFeedback
                      passiveFeedbackId={passiveFeedbackId}
                    />
                  )}

                  {passiveFeedbackResult?.passiveFeedback?.type === 'initial' && passiveFeedbackResult?.passiveFeedback?.cltDriverId && (
                    <RealizeCltDriverPassiveFeedback
                      passiveFeedbackId={passiveFeedbackId}
                    />
                  )}

                  {passiveFeedbackResult?.passiveFeedback?.type === 'recurrent' && passiveFeedbackResult?.passiveFeedback?.cltDriverId && (
                    <RealizeRecurrentCltDriverPassiveFeedback
                      passiveFeedbackId={passiveFeedbackId}
                    />
                  )}

                  {passiveFeedbackResult?.passiveFeedback?.type === 'recurrent' && passiveFeedbackResult?.passiveFeedback?.internCltId && (
                    <RealizeRecurrentInternCltPassiveFeedback
                      passiveFeedbackId={passiveFeedbackId}
                    />
                  )}

                </GridItem>
              </>
            )}

          </Grid>
        )}
      </ModalBody>
    </ModalContent>
  )
}
