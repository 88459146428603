import { api } from '../../../../services/api'

export type CreateDeliveryServiceInputProps = {
  service_id: string
  address_id: string
  driver_id: string
  arrival_latitude: string
  arrival_longitude: string
  arrival_timestamp: string
  is_business?: boolean
}

export type FinishDeliveryServiceInputProps = {
  service_id: string
  collector_id: string
  responsible_name: string
  delivery_volume: number
  box_photo: any | null
  content_declaration: any
  departure_latitude: string
  departure_longitude: string
  departure_timestamp: string
  observation: string
}

export type UpdateDeliveryServiceInputProps = {
  service_id: string
  collector_id: string
  responsible_name: string
  delivery_volume: number
  box_photo: any | null
  content_declaration: any
  departure_latitude: string
  departure_longitude: string
  departure_timestamp: string
  observation: string
}

export type FinishDeliveryServiceReqProps = {
  serviceID: string
  finishDeliveryServiceInput: FormData
}

export type UpdateDeliveryServiceReqProps = {
  serviceID: string
  updateDeliveryServiceInput: UpdateDeliveryServiceInputProps
}

const createDeliveryServiceReqFunction = (
  createDeliveryServiceInputProps: CreateDeliveryServiceInputProps,
) =>
  api
    .post('/delivery-service', createDeliveryServiceInputProps)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

const finishDeliveryServiceReqFunction = ({
  serviceID,
  finishDeliveryServiceInput,
}: FinishDeliveryServiceReqProps) =>
  api
    .put(`/delivery-service/${serviceID}`, finishDeliveryServiceInput)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

const updateDeliveryServiceReqFunction = ({
  serviceID,
  updateDeliveryServiceInput,
}: UpdateDeliveryServiceReqProps) =>
  api
    .put(
      `in-progress/delivery-service/${serviceID}`,
      updateDeliveryServiceInput,
    )
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

export {
  createDeliveryServiceReqFunction,
  finishDeliveryServiceReqFunction,
  updateDeliveryServiceReqFunction,
}
