import { api } from '../../../services/api'

export type CreateUserInputProps = {
  user_type: string
  situation: string
  email: string
  firstname: string
  lastname: string
  loglife_employee: boolean
  permissions: string[]
  customers: string[]
  collector_id: string | null
  driver_id: string | null
  substitute_id: string
  collectors_ids: string[]
}

export type EditUserInputProps = CreateUserInputProps

export type EditUserReqProps = {
  id: string
  input: EditUserInputProps
}

async function createUserReqFunction(input: CreateUserInputProps) {
  try {
    const { data } = await api.post('/user', input)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function editUserReqFunction({ id, input }: EditUserReqProps) {
  try {
    const { data } = await api.put(`/user/${id}`, input)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function deleteUserReqFunction(id: string) {
  try {
    const { data } = await api.delete(`/user/${id}`)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

export { createUserReqFunction, editUserReqFunction, deleteUserReqFunction }
