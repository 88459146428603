import { Button, Flex, FormLabel, Icon, Image, Stack, Link as ChakraLink, Text } from "@chakra-ui/react";
import { Address } from "hooks/address/dtos/Address";
import { useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { FaFile, FaLink, FaPlus, FaTimes } from "react-icons/fa";
import { FiFile } from "react-icons/fi";
import { Link } from "react-router-dom";
import { unsuccessReasonOptions } from "utils/CustomLists/unsuccessCollectReasonOptions";
import { Input } from "../../../../components/Inputs/Input";
import { Select } from "../../../../components/Inputs/SelectInput";
import { TextArea } from "../../../../components/Inputs/TextInput";
import { CollectedServiceProps } from "../../../../contexts/CollectedServiceContext";
import { ProviderProps } from "../../../../contexts/ProviderContext";
import { IDriverProps } from "../../../../services/getFunctions/driver/getDrivers";
import { negative_positive } from "../../../../utils/customLists";
import { handleChangeUrl } from "../../../../utils/handleChangeUrl";
import { PreviewFilesCarousel } from "./PreviewFilesCarousel";

type ContentDeclaration = {
  file: FileList
}

type BoxPhoto = ContentDeclaration

interface IFormProps {
  step: 'DONE' | 'UNSUCCESS'
  address_id?: string
  provider_id?: string
  driver_id: string
  responsible_name: string
  collect_volume: number
  collect_sample: number
  unsuccess_reason: string
  has_edit_box_photo: string
  box_photos?: BoxPhoto[]
  has_edit_content_declaration: string
  content_declarations?: ContentDeclaration[]
  has_edit_open_box_photo: string
  open_box_photo?: FileList | string
  real_arrival_date: string
  real_arrival_hour: string
  collects_total_volumes: number
  observation: string
}

interface IEditCollectServiceForm {
  isProviderForm?: boolean;
  providers?: ProviderProps[]
  addresses?: Address[]
  drivers: IDriverProps[]
  collectService: CollectedServiceProps
}

const supportedFileFormats = [
  "image/jpeg",
  "image/pjpeg",
  "image/jpg",
  "image/png"
]

const collectServiceStepOptions = [
  { key: '0', value: 'DONE', showOption: 'SUCESSO' },
  { key: '1', value: 'UNSUCCESS', showOption: 'SEM SUCESSO' },
]

export function EditCollectServiceForm({
  isProviderForm = false,
  providers,
  addresses,
  drivers,
  collectService
}: IEditCollectServiceForm) {

  const {
    register,
    control,
    watch,
    formState: { errors }
  } = useFormContext<IFormProps>()

  const step = watch("step")
  const openBoxPhoto = watch("open_box_photo")

  const hasEditBoxPhoto = watch("has_edit_box_photo") === "SIM"
  const hasEditContentDeclaration = watch("has_edit_content_declaration") === "SIM"
  const hasEditOpenBoxPhoto = watch("has_edit_open_box_photo") === "SIM"

  const openBoxPhotoPreview = openBoxPhoto
    ? Object.entries(openBoxPhoto).map(([key, value]) => URL.createObjectURL(value))[0]
    : null

  const isUnsuccessStep = step === 'UNSUCCESS'

  const {
    fields: contentDeclarationsFields,
    append: appendContentDeclarationField,
    remove: removeContentDeclarationField
  } = useFieldArray({
    control,
    name: 'content_declarations'
  })

  function handleRemoveContentDeclarationAttachmentField(index: number) {
    removeContentDeclarationField(index)
  }

  const handleAppendContentDeclarationAttachmentField = () => appendContentDeclarationField({ file: undefined })

  const contentDeclarations = useWatch({
    control,
    name: 'content_declarations'
  })

  const {
    fields: boxPhotosFields,
    append: appendBoxPhotoField,
    remove: removeBoxPhotoField
  } = useFieldArray({
    control,
    name: 'box_photos'
  })

  function handleRemoveBoxPhotoField(index: number) {
    removeBoxPhotoField(index)
  }

  const handleAppendBoxPhotoField = () => appendBoxPhotoField({ file: undefined })

  const boxPhotos = useWatch({
    control,
    name: 'box_photos'
  })

  const [currentExistentContentDeclarationIndex, setCurrentExistentContentDeclarationIndex] = useState(0)

  const existentContentDeclarations = collectService.content_declarations
    ? typeof collectService.content_declarations === 'string'
      ? JSON.parse(collectService.content_declarations)
      : collectService.content_declarations
    : null

  const nextExistentContentDeclaration = () => {

  }


  const existentBoxPhotos = collectService.box_photos
    ? typeof collectService.box_photos === 'string'
      ? JSON.parse(collectService.box_photos)
      : collectService.box_photos
    : null


  return (
    <Flex direction="column" gap="4">
      <Select
        {...register("step")}
        name="step"
        label="Status"
        options={collectServiceStepOptions}
        error={errors.step}
        required
      />
      <Stack direction={['column', 'column', 'row']} spacing="24px">
        {isProviderForm ? (
          <Select
            {...register("provider_id")}
            name="provider_id"
            label="Endereço remetente"
            providerAddress={providers}
            error={errors.provider_id}
            isDisabled
            required
          />
        ) : (
          <Select
            {...register("address_id")}
            name="address_id"
            label="Endereço remetente"
            addresses={addresses}
            error={errors.address_id}
            isDisabled
            required
          />
        )}

        <Select
          {...register("driver_id")}
          name="driver_id"
          label="Pertence ao motorista"
          drivers={drivers}
          error={errors.driver_id}
          required
        />
      </Stack>
      {collectService?.real_arrival_timestamp && collectService?.real_arrival_timestamp_validated && (
        <Stack direction={["column", "column", "row"]} spacing={6}>
          <Input
            {...register("real_arrival_date")}
            name="real_arrival_date"
            label="Data real da coleta"
            error={errors.real_arrival_date}
            type="date"
            required
          />
          <Input
            {...register("real_arrival_hour")}
            name="real_arrival_hour"
            label="Horário real da coleta"
            error={errors.real_arrival_hour}
            type="time"
            required
          />
        </Stack>
      )}

      <Input
        {...register("responsible_name")}
        name="responsible_name"
        label="Nome do responsável"
        error={errors.responsible_name}
        required
      />
      {isUnsuccessStep && (
        <Select
          {...register('unsuccess_reason')}
          name='unsuccess_reason'
          label='Motivo do sem sucesso'
          options={unsuccessReasonOptions}
          placeholder='Selecione uma opção...'
          error={errors.unsuccess_reason}
          required
        />
      )}

      {!isUnsuccessStep && (

        <Stack direction={['column', 'column', 'row']} spacing="24px">
          <Input
            {...register("collect_volume")}
            name="collect_volume"
            label="Volume"
            error={errors.collect_volume}
            required
          />
          {isProviderForm ? (
            <Input
              {...register("collect_sample")}
              name="collect_sample"
              label="Quantidade de gelo seco"
              error={errors.collect_sample}
              required
            />
          ) : (
            <Input
              {...register("collect_sample")}
              name="collect_sample"
              label="Quantidade de amostras"
              error={errors.collect_sample}
              required
            />
          )}
        </Stack>
      )}

      <Stack direction={['column', 'column', 'row']} spacing="24px">
        {!isUnsuccessStep && (
          <Stack w="full" spacing="24px">
            {existentBoxPhotos && (
              <PreviewFilesCarousel
                files={existentBoxPhotos}
              />
            )}
            <Select
              {...register("has_edit_box_photo")}
              name="has_edit_box_photo"
              label="Editar foto da caixa?"
              placeholder="Selecione uma opção..."
              negative_positive={negative_positive}
              error={errors.has_edit_box_photo}
              required
            />
            {hasEditBoxPhoto && (
              <>
                {boxPhotosFields.map((field, index) => {
                  return (
                    <Stack key={field.id} w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
                      <Input
                        {...register(`box_photos.${index}.file`)}
                        name={`box_photos.${index}.file`}
                        label="Foto da caixa"
                        type="file"
                        accept={supportedFileFormats.join(", ")}
                        error={errors.box_photos ? errors?.box_photos[index]?.file : undefined}
                        hidden
                        required
                      >
                        <Stack
                          w="full"
                          spacing="24px"
                          mt="4"
                          justifyContent='space-between'
                          direction={['column', 'column', 'row']}
                        >
                          <Button
                            as={FormLabel}
                            htmlFor={`box_photos.${index}.file`}
                            _hover={{
                              cursor: 'pointer'
                            }}
                            mr="2"
                            leftIcon={<Icon as={FaFile} />}
                          >
                            Upload
                          </Button>
                          {boxPhotos && boxPhotos[index]?.file?.length > 0 && (
                            <ChakraLink
                              href={URL.createObjectURL(boxPhotos[index].file[0])}
                            >
                              {boxPhotos[index].file[0].name} (Visualizar)
                            </ChakraLink>
                          )}
                          <Button
                            leftIcon={<Icon as={FaTimes} />}
                            variant="ghost"
                            onClick={() => handleRemoveBoxPhotoField(index)}
                            h="48px"
                          >
                            Remover
                          </Button>
                        </Stack>
                      </Input>
                    </Stack>
                  )
                })}

                <Stack
                  w="full"
                  mt="4"
                >
                  <Button
                    _hover={{
                      cursor: 'pointer'
                    }}
                    mr="2"
                    leftIcon={<Icon as={FaPlus} />}
                    onClick={handleAppendBoxPhotoField}
                  >
                    Foto da caixa
                  </Button>
                  <Text
                    fontSize="sm"
                    color="red.500"
                  >
                    {errors?.box_photos?.message}
                  </Text>
                </Stack>
              </>
            )}
          </Stack>
        )}

        <Stack w="full" spacing="24px">
          {existentContentDeclarations && (
            <PreviewFilesCarousel
              files={existentContentDeclarations}
            />
          )}

          <Select
            {...register("has_edit_content_declaration")}
            name="has_edit_content_declaration"
            label="Editar declaração de conteúdo?"
            placeholder="Selecione uma opção..."
            negative_positive={negative_positive}
            error={errors.has_edit_content_declaration}
            required
          />
          {hasEditContentDeclaration && (
            <>
              {contentDeclarationsFields.map((field, index) => {
                return (
                  <Stack key={field.id} w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
                    <Input
                      {...register(`content_declarations.${index}.file`)}
                      name={`content_declarations.${index}.file`}
                      label="Declaração de Conteúdo"
                      type="file"
                      accept={supportedFileFormats.join(", ")}
                      error={errors.content_declarations ? errors?.content_declarations[index]?.file : undefined}
                      hidden
                      required
                    >
                      <Stack
                        w="full"
                        spacing="24px"
                        mt="4"
                        justifyContent='space-between'
                        direction={['column', 'column', 'row']}
                      >
                        <Button
                          as={FormLabel}
                          htmlFor={`content_declarations.${index}.file`}
                          _hover={{
                            cursor: 'pointer'
                          }}
                          mr="2"
                          leftIcon={<Icon as={FaFile} />}
                        >
                          Upload
                        </Button>
                        {contentDeclarations && contentDeclarations[index]?.file?.length > 0 && (
                          <ChakraLink
                            href={URL.createObjectURL(contentDeclarations[index].file[0])}
                          >
                            {contentDeclarations[index].file[0].name} (Visualizar)
                          </ChakraLink>
                        )}
                        <Button
                          leftIcon={<Icon as={FaTimes} />}
                          variant="ghost"
                          onClick={() => handleRemoveContentDeclarationAttachmentField(index)}
                          h="48px"
                        >
                          Remover
                        </Button>
                      </Stack>
                    </Input>
                  </Stack>
                )
              })}

              <Stack
                w="full"
                mt="4"
              >
                <Button
                  _hover={{
                    cursor: 'pointer'
                  }}
                  mr="2"
                  leftIcon={<Icon as={FaPlus} />}
                  onClick={handleAppendContentDeclarationAttachmentField}
                >
                  Declaração de Conteúdo
                </Button>
                <Text
                  fontSize="sm"
                  color="red.500"
                >
                  {errors?.content_declarations?.message}
                </Text>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      {!isUnsuccessStep && (
        <Stack spacing="24px">
          {collectService.open_box_photo && (
            <Button
              as={ChakraLink}
              href={handleChangeUrl(collectService.open_box_photo as string)}
              leftIcon={<Icon as={FaLink} />}
              variant="outline"
              isExternal
            >
              Foto da caixa aberta
            </Button>
          )}
          <Select
            {...register("has_edit_open_box_photo")}
            name="has_edit_open_box_photo"
            label="Editar foto da caixa aberta?"
            placeholder="Selecione uma opção..."
            negative_positive={negative_positive}
            error={errors.has_edit_open_box_photo}
            required
          />
          {hasEditOpenBoxPhoto && (
            <>
              <Input
                {...register("open_box_photo")}
                name="open_box_photo"
                type="file"
                accept={supportedFileFormats.join(", ")}
                hidden
                label="Foto da caixa aberta"
                error={errors.open_box_photo}
                required
              >
                <Button
                  leftIcon={<Icon as={FiFile} />}
                  as={FormLabel}
                  htmlFor="open_box_photo"
                  _hover={{
                    cursor: "pointer"
                  }}
                >Escolha uma imagem</Button>
              </Input>
              {openBoxPhotoPreview && (
                <Image
                  objectFit="cover"
                  width="300px"
                  src={openBoxPhotoPreview}
                />
              )}
            </>
          )}
        </Stack>
      )}

      <Input
        {...register("collects_total_volumes")}
        name="collects_total_volumes"
        label="Volume total de coletas"
        error={errors.collects_total_volumes}
        required
      />


      <TextArea
        {...register("observation")}
        name="observation"
        label="Observações"
      />

      <Stack w={["full", "full", "min", "min"]} alignSelf={["center", "center", "flex-end"]} direction={['column', 'column', 'row']}>
        <Button colorScheme="blue" type="submit">Editar</Button>
        <Button as={Link} to="/servicos/coletas" type="submit">Coletas</Button>
      </Stack>
    </Flex>
  )
}
