

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface EditTrainingFieldProps {
  routeParams: {
    trainingId: string
  }
  body: {
    field: string
    value: string
  }
}

export async function editTrainingField({
  body,
  routeParams
}: EditTrainingFieldProps) {
  try {
    await api.patch(`/trainings/${routeParams.trainingId}/edit-field`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
