import { Accordion } from '@chakra-ui/react'
import { memo } from 'react'
import { ServiceProps } from '../../../../contexts/ServiceContext'
import { InformationAccordion } from '../InformationAccordion/InformationAccordion'

interface AvailableAccordionProps {
  serviceByID: ServiceProps
}

function AvailableAccordion({ serviceByID }: AvailableAccordionProps) {
  return (
    <Accordion allowMultiple>
      <InformationAccordion serviceByID={serviceByID} />
    </Accordion>
  )
}

export default memo(AvailableAccordion)
