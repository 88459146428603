import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface SendCltDriverInProgressNoticeDetailsBody {
  doesInNoticeReturnedPatrimonies: boolean
  hasDiscountedMeetAndFoodAndFuelVouchers: boolean
  shouldAdvanceStatus: boolean
}

interface SendCltDriverInProgressNoticeDetailsProps {
  body: SendCltDriverInProgressNoticeDetailsBody
  cltDriverId: string
}

export async function sendCltDriverInProgressNoticeDetails({
  body,
  cltDriverId
}: SendCltDriverInProgressNoticeDetailsProps) {
  try {
    const response = await api.put(`/clt-drivers/${cltDriverId}/dismiss/in-notice-details`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
