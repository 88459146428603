import { CltIntercurrenceBooleanWithNAType, CltIntercurrencePartnerTypeType, CltIntercurrenceStatus, CltIntercurrenceType } from "api/cltIntercurrences/types/CltIntercurrence";

export const cltIntercurrenceTypeMap: Record<CltIntercurrenceType, string> = {
  "work-accident": "Acidente de trabalho",
  "miss-work": "Falta",
  "vacation": "Férias",
  "home-office": "Home office",
  "patrimony-without-return": "Patrimônio sem devolução",
  "extra-hours": "HE autorizada",
  "turn-change": "Troca de turno",
  "late-or-early-departure": "Atraso ou saída antecipada",
  "ticket-log-additional-value": "Valor adicional ticket log",
  "other": "Outra",
}

export const cltIntercurrenceStatusMap: Record<CltIntercurrenceStatus, string> = {
  approved: "Aprovado",
  requested: "Solicitado",
  rejected: "Rejeitado"
}

export const cltIntercurrencePartnerTypeMap: Record<CltIntercurrencePartnerTypeType, string> = {
  "clt-driver": "Motorista CLT",
  "rh-managment": "RH Gerencial",
}

export const cltIntercurrenceBooleanWithNAType: Record<CltIntercurrenceBooleanWithNAType, string> = {
  "n/a": "N/A",
  no: 'Não',
  yes: 'Sim'
}
