import { useContext } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'
import {
  getAllRequestedServices,
  getOneRequestedService,
  getOneRequestedServiceByServiceId,
} from '../../services/getFunctions/getRequestedServiceFunctions'

export function useRequestedService(
  requestedServiceId: string | null,
  isEnable?: boolean,
  refetchOnFocus?: boolean,
  serviceId?: string
) {
  const { handleLogout, userLogged } = useContext(AuthContext)

  const requestedServices = useQuery(
    'requestedServices',
    getAllRequestedServices,
    {
      enabled: !!isEnable && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )
  const requestedService = useQuery(
    ['requestedService', requestedServiceId],
    () => getOneRequestedService(requestedServiceId !== null ? requestedServiceId : ''),
    {
      enabled: !!requestedServiceId && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )

  const requestedServiceByServiceId = useQuery(
    ['requestedServiceByServiceId', serviceId],
    () => getOneRequestedServiceByServiceId(serviceId ? serviceId : ''),
    {
      enabled: !!serviceId && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )

  return {
    requestedServices,
    requestedService,
    requestedServiceByServiceId
  }
}
