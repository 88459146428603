const statesWithUfList = {
  Acre: {
    uf: 'AC',
  },
  Alagoas: {
    uf: 'AL',
  },
  Amapá: {
    uf: 'AP',
  },
  Amazonas: {
    uf: 'AM',
  },
  Bahia: {
    uf: 'BA',
  },
  Ceará: {
    uf: 'CE',
  },
  'Distrito Federal': {
    uf: 'DF',
  },
  'Espírito Santo': {
    uf: 'ES',
  },
  Goiás: {
    uf: 'GO',
  },
  Maranhão: {
    uf: 'MA',
  },
  'Mato Grosso': {
    uf: 'MT',
  },
  'Mato Grosso do Sul': {
    uf: 'MS',
  },
  'Minas Gerais': {
    uf: 'MG',
  },
  Pará: {
    uf: 'PA',
  },
  Paraíba: {
    uf: 'PB',
  },
  Paraná: {
    uf: 'PR',
  },
  Pernambuco: {
    uf: 'PE',
  },
  Piauí: {
    uf: 'PI',
  },
  'Rio de Janeiro': {
    uf: 'RJ',
  },
  'Rio Grande do Norte': {
    uf: 'RN',
  },
  'Rio Grande do Sul': {
    uf: 'RS',
  },
  Rondônia: {
    uf: 'RO',
  },
  Roraima: {
    uf: 'RR',
  },
  'Santa Catarina': {
    uf: 'SC',
  },
  'São Paulo': {
    uf: 'SP',
  },
  Sergipe: {
    uf: 'SE',
  },
  Tocantins: {
    uf: 'TO',
  },
}

export type StatesWithUfListProps = typeof statesWithUfList

export { statesWithUfList }
