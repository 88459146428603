import { Box, Divider, Grid, GridItem, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner } from "@chakra-ui/react"
import { getStandardization } from "api/standardizations/getStandardization"
import { useAuth } from "hooks/auth/useAuth"
import { useQuery } from "react-query"
import { StandardizationDetailTable } from "./StandardizationDetailTable"
import { ValidateStandardization } from "./ValidateStandardization"

interface StandardizationDetailProps {
  standardizationId: string
}

export function StandardizationDetail({ standardizationId }: StandardizationDetailProps) {

  const { userLogged } = useAuth()

  const userCanValidateStandardization = userLogged?.permissions.includes('validate-standardization')

  const { data: standardizationData, isLoading: isStandardizationDataLoading } = useQuery({
    queryKey: ['standardization', standardizationId],
    queryFn: () => getStandardization({ standardizationId })
  })


  return (
    <ModalContent>
      {isStandardizationDataLoading ? (
        <ModalBody
          p="6"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
        </ModalBody>
      ) : (
        <>
          <ModalHeader letterSpacing="tight">Detalhes da Padronização</ModalHeader>
          <ModalCloseButton />
          <ModalBody >
            <Grid
              templateColumns={
                userCanValidateStandardization && standardizationData?.standardization.status === 'pending'
                  ? "1fr auto 1fr"
                  : "1fr"
              }
              gap={6}
            >
              <GridItem>
                <StandardizationDetailTable
                  standardization={standardizationData?.standardization}
                />
              </GridItem>
              {userCanValidateStandardization && standardizationData?.standardization?.status === 'pending' && (
                <>
                  <Box>
                    <Divider orientation="vertical" />
                  </Box>
                  <GridItem>
                    <ValidateStandardization
                      standardizationId={standardizationData?.standardization?.id}
                    />
                  </GridItem>
                </>
              )}
            </Grid>

          </ModalBody>
        </>
      )}
    </ModalContent>
  )
}
