import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, Icon, Spinner, Table, TableContainer, Tbody, Thead, useDisclosure } from "@chakra-ui/react";
import { getCollectsRelation } from "api/service/getCollectsRelation";
import { Pagination } from "components/Pagination/Pagination";
import { useSearchParams } from "hooks/useSearchParams";
import { FormProvider, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CollectsRelationsTableHead } from "./CollectsRelationsTableHead";
import { CollectsRelationsTableRow } from "./CollectsRelationsTableRow";
import { CollectsRelationsFilters } from "./CollectsRelationsFilters";
import { FaFilter } from "react-icons/fa";
import { useAuth } from "hooks/auth/useAuth";


export interface CollectsRelationsFilterSchema {
  protocols: { value: string }[]
}

export function CollectsRelations() {
  const searchParams = useSearchParams()
  const { userLogged } = useAuth()

  const page = searchParams.get('page') ?? 1

  const history = useHistory()

  const ROWS_PER_PAGE = 10

  const formMethods = useForm<CollectsRelationsFilterSchema>()

  const customers = searchParams.get('customers')
  const protocols = searchParams.get('protocols')
  const destinationAddresses = searchParams.get('destinationAddresses')
  const sourceCollectors = searchParams.get('sourceCollectors')
  const vehicles = searchParams.get('vehicles')
  const destinationCollectors = searchParams.get('destinationCollectors')
  const collectDates = searchParams.get('collectDates')
  const collectHours = searchParams.get('collectHours')

  const {
    data: collectsRelationsResult,
    isLoading: isLoadingCollectsRelationsResult
  } = useQuery({
    queryKey: ['collects-relations',
      page,
      customers,
      protocols,
      destinationAddresses,
      sourceCollectors,
      vehicles,
      destinationCollectors,
      collectDates,
      collectHours
    ],
    queryFn: () => getCollectsRelation({
      currentPage: Number(page),
      pageSize: ROWS_PER_PAGE,
      timezoneOffset: new Date().getTimezoneOffset() / 60,
      customers,
      destinationAddresses,
      destinationCollectors,
      sourceCollectors,
      protocols,
      vehicles,
      collectDates,
      collectHours,
      collectorId: userLogged?.collector_id ?? null
    }),
    refetchOnWindowFocus: false
  })

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: collectsRelationsResult?.meta?.totalPages,
      initialState: {
        pageSize: ROWS_PER_PAGE,
        isDisabled: false,
        currentPage: Number(page),
      },
    });


  const handleChangePage = (page: number) => {
    setCurrentPage(page)
    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <Flex w='full' justifyContent='space-between' >
        <Heading letterSpacing="tight">Coletas</Heading>
        <Button
          variant="ghost"
          onClick={onToggle}
          boxShadow="md"
          borderRadius="md"
          size="sm"
          leftIcon={
            <Icon as={FaFilter} />
          }
        >
          Filtros
        </Button>
      </Flex>

      <CollectsRelationsFilters
        onToggle={onToggle}
        isOpen={isOpen}
      />


      <TableContainer rounded="md" mt={6}>
        <Table size="sm">
          <Thead>
            <FormProvider {...formMethods}>
              <CollectsRelationsTableHead />
            </FormProvider>
          </Thead>
          <Tbody>
            {isLoadingCollectsRelationsResult && (
              <Spinner />
            )}
            {collectsRelationsResult?.services?.map(service => {
              return (
                <CollectsRelationsTableRow
                  key={String(service.protocol)}
                  service={service}
                />
              )
            })}

          </Tbody>
        </Table>
      </TableContainer>

      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
