import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getCustomersExtrasDiscounts } from "api/extrasAndDiscounts/getCustomersExtrasDiscounts";
import { ExtraAndDiscount, ExtraAndDiscountStatus } from "api/extrasAndDiscounts/_types/ExtraAndDiscount";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { Pagination } from "components/Pagination/Pagination";
import { format, parseISO } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { FaFileDownload, FaFileExport, FaPlus } from "react-icons/fa";
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom";
import { handleChangeUrl } from "utils/handleChangeUrl";
import { CreateCustomerExtraDiscount } from "./components/CreateCustomerExtraDiscount";
import { CustomerExtraDiscountTableRow } from "./components/CustomerExtraDiscountTableRow";
import { CustomersExtrasDiscountsTableFilter } from "./components/CustomersExtrasDiscountsTableFilter";


const headers = [
  { label: 'CLIENTE', key: 'customer' },
  { label: 'DATA DE CRIAÇÃO', key: 'created_at' },
  { label: 'DATA DA OCORRÊNCIA', key: 'occurrence_date' },
  { label: 'TIPO', key: 'type' },
  { label: 'TIPO DE SERVIÇO', key: 'service_type' },
  { label: 'ROTA', key: 'route' },
  { label: 'IDENTIFICADOR', key: 'protocol' },
  { label: 'DESCRIÇÃO', key: 'description' },
  { label: 'VALOR', key: 'value' },
  { label: 'FINALIZADO EM', key: 'closed_at' },
  { label: 'ANEXO', key: 'attachment' },
]

function formatValuesToReport(values: ExtraAndDiscount[]) {
  return values?.map((extraAndDiscount) => ({
    ...extraAndDiscount,
    customer: extraAndDiscount?.customer.trading_firstname,
    created_at: format(parseISO(extraAndDiscount?.created_at), 'dd/MM/yyyy'),
    occurrence_date: extraAndDiscount?.occurrence_date ? format(parseISO(extraAndDiscount?.occurrence_date), 'dd/MM/yyyy') : '-',
    value: extraAndDiscount.value / 100,
    protocol: extraAndDiscount.service_type === 'EXPRESS'
      ? extraAndDiscount.protocol
      : extraAndDiscount.route,
    closed_at: extraAndDiscount.closed_at ? format(parseISO(extraAndDiscount?.closed_at), 'dd/MM/yyyy') : '-',
    attachment: extraAndDiscount.attachment ? handleChangeUrl(extraAndDiscount.attachment) : '-'
  }))
}


export function CustomersExtrasDiscounts() {

  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  const { userLogged } = useAuth()
  const searchParams = useSearchParams()
  const { replace, push: redirect } = useHistory()

  const userCanViewExtrasAndDiscounts =
    userLogged?.permissions.includes('view-extras-and-discounts')

  const userCanCreateExtrasAndDiscounts =
    userLogged?.permissions.includes('create-extras-and-discounts')

  useEffect(() => {
    if (!userCanViewExtrasAndDiscounts) {
      redirect('/')
    }
  }, [redirect, userCanViewExtrasAndDiscounts])

  const status = searchParams.get('status')
  const page = searchParams.get('page') ?? '1'
  const customer = searchParams.get('customer')
  const date = searchParams.get('date')
  const protocol = searchParams.get('protocol')

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }

  const {
    data: customersExtrasDiscountsResult
  } = useQuery({
    queryKey: [
      'customers-extras-discounts',
      page,
      status,
      customer,
      date,
      protocol,

    ],
    queryFn: () => getCustomersExtrasDiscounts({
      currentPage: Number(page),
      pageSize: 10,
      customer,
      date,
      protocol,
      status: ['requested', 'closed'].includes(status) ? status as ExtraAndDiscountStatus : null,
    }),
    keepPreviousData: false,
  })

  const {
    data: customersExtrasDiscountsReportResult,
    isLoading: isLoadingCustomersExtrasDiscountsReportResult
  } = useQuery({
    queryKey: [
      'customers-extras-discounts-report',
      status,
      customer,
      date,
      protocol,
      customersExtrasDiscountsResult?.meta.count,
    ],
    queryFn: () => getCustomersExtrasDiscounts({
      currentPage: 1,
      pageSize: customersExtrasDiscountsResult?.meta.count,
      customer,
      date,
      protocol,
      status: ['requested', 'closed'].includes(status) ? status as ExtraAndDiscountStatus : null,
    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })


  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: customersExtrasDiscountsResult?.meta.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: 10
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  const csvReportProps = {
    data: customersExtrasDiscountsReportResult ? formatValuesToReport(customersExtrasDiscountsReportResult?.extrasDiscounts) : [],
    headers,
    filename: `extras-e-descontos-.csv`,
  }

  const {
    isOpen: isCreateExtraAndDiscountOpen,
    onToggle: onToggleExtraAndDiscountPrice,
  } = useDisclosure()


  return (
    <Box
      rounded="md"
      p="6"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Extras e Descontos</Heading>

        {userCanCreateExtrasAndDiscounts && (
          <IconButton
            aria-label="Criar extra/desconto"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onToggleExtraAndDiscountPrice}
          />
        )}
        <Modal
          isOpen={isCreateExtraAndDiscountOpen}
          onClose={onToggleExtraAndDiscountPrice}
          isCentered
          size="4xl"
        >
          <ModalOverlay />
          <CreateCustomerExtraDiscount onClose={onToggleExtraAndDiscountPrice} />
        </Modal>
      </Flex>
      <CustomersExtrasDiscountsTableFilter />
      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Cliente</Th>
              <Th color="white">Data Criação</Th>
              <Th color="white">Tipo</Th>
              <Th color="white">Tipo de Serviço</Th>
              <Th color="white">Identificador</Th>
              <Th color="white">Valor do Extra/Desconto</Th>
              <Th color="white">Status</Th>
              <Th color="white">Criado Por</Th>
              <Th color="white">Finalizado em</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {customersExtrasDiscountsResult?.extrasDiscounts?.map((extraDiscount) => {
              return (
                <CustomerExtraDiscountTableRow
                  key={extraDiscount?.id}
                  extraDiscount={extraDiscount}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {!customersExtrasDiscountsReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingCustomersExtrasDiscountsReportResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
