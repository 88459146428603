import { useContext } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'
import { getRecurrentServiceByDayOfWeek } from '../../services/getFunctions/recurrentService/getRecurrentService'

export function useRecurrentServiceByDayOfWeek(
  dayOfWeek: number | null,
  refetchOnFocus?: boolean,
) {
  const { handleLogout, userLogged } = useContext(AuthContext)

  const recurrentServiceByDayOfWeek = useQuery(
    ['recurrentServiceByDayOfWeek', dayOfWeek],
    () => getRecurrentServiceByDayOfWeek(dayOfWeek || null),
    {
      enabled: !!dayOfWeek && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )

  return {
    recurrentServiceByDayOfWeek,
  }
}
