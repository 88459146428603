import { Box, Flex, Grid, GridItem, Heading, Image, Text } from "@chakra-ui/react"
import { getGollogTracking } from "api/tracking/getGollogTracking"

import { addHours, format } from "date-fns"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import gollogLogo from "assets/images/gollog-logo.png"
import { Empty } from "components/Empty"

interface Params {
  tracker: string
}

export function GollogTracking() {
  const { tracker } = useParams<Params>()

  const {
    data,
    isError,
  } = useQuery({
    queryKey: ['gollog-tracker', tracker],
    queryFn: () => getGollogTracking({
      queryParams: {
        values: tracker
      }
    })
  })

  const handleReloadPage = () => window.location.reload()

  if (isError) {
    return (
      <Empty.Root>
        <Empty.ActionButton onClick={handleReloadPage}>Recarregar a página</Empty.ActionButton>
      </Empty.Root>
    )
  }

  const userTimezone = new Date().getTimezoneOffset() / 60

  return (

      <Box
        as={Flex}
        p="6"
        bg="white"
        rounded="md"
        direction="column"
        gap="6"
      >
        <Flex
          align="center"
          justifyContent="space-between"
        >
          <Heading letterSpacing="tight">Rastreamento Gollog</Heading>

          <Image
            w="100px"
            h="50px"
            src={gollogLogo}
          />
        </Flex>


        <Flex
          direction='column'
          gap="3"
        >
          <Box w="full" bg="#38c3fa" p="3" rounded="lg">
            <Text color="white" as="b">Status - {data?.result[0]?.lastStatus.description}</Text>
          </Box>

          <Flex
            direction="column"
            gap="1"
          >
            {data?.result[0]?.events
              .sort((a, b) => {
                return new Date(a.date).getTime() - new Date(b.date).getTime()
              })
              .map((event, index) => {
                return (
                  <Grid
                    key={index.toString()}
                    gap="6"
                    bg="blackAlpha.50"
                    p="2"
                    // rounded="md"
                    fontSize="sm"
                    alignItems="center"
                    templateColumns="repeat(10, 1fr)"
                    overflowX="scroll"
                    _first={{ borderTopRadius: 'xl'}}
                    _last={{ borderBottomRadius: 'xl'}}

                  >
                    <GridItem>
                      <Text>{format(addHours(new Date(event.date), userTimezone), 'dd/MM')}</Text>
                    </GridItem>

                    <GridItem>
                      <Text>{format(addHours(new Date(event.date), userTimezone), 'HH:mm')}</Text>
                    </GridItem>

                    <GridItem>
                      <Text>{event.station}</Text>
                    </GridItem>

                    <GridItem colSpan={3} >
                      <Text>{event.codeDescription}</Text>
                    </GridItem>
                    <GridItem colSpan={4} >
                      <Text>{event.message}</Text>
                    </GridItem>
                  </Grid>
                )
              })}
          </Flex>
        </Flex>
      </Box>

  )
}
