import { Menu, MenuButton, Button, MenuList, MenuOptionGroup, MenuItemOption } from "@chakra-ui/react";
import { CltDriversExtrasDiscountsStatus, CltDriversExtrasDiscountsStatusEnum } from "contexts/CltDriversExtrasDiscountsStatusContext";
import { useCltDriversExtrasDiscountsStatus } from "hooks/cltDriver/useCltDriversExtrasDiscountsStatus";
import { FaChevronDown } from "react-icons/fa";

interface CltDriversExtrasDiscountsStatusOptions {
  key: keyof typeof CltDriversExtrasDiscountsStatusEnum
  value: keyof typeof CltDriversExtrasDiscountsStatusEnum
  showOption: string
}

const cltDriversExtrasDiscountsStatusOptions: CltDriversExtrasDiscountsStatusOptions[] = [
  { key: 'requested', value: 'requested', showOption: CltDriversExtrasDiscountsStatusEnum['requested'] },
  { key: 'approved', value: 'approved', showOption: CltDriversExtrasDiscountsStatusEnum['approved'] },
  { key: 'effectivated', value: 'effectivated', showOption: CltDriversExtrasDiscountsStatusEnum['effectivated'] },
  { key: 'rejected', value: 'rejected', showOption: CltDriversExtrasDiscountsStatusEnum['rejected'] }
]

export function CltDriversExtrasDiscountsTableStatusButton() {
  const { status, handleChangeStatus } = useCltDriversExtrasDiscountsStatus()

  return (
    <Menu>
      <MenuButton fontSize="12px" variant="ghost" size="sm" as={Button} rightIcon={<FaChevronDown />}>
        STATUS
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          defaultValue="requested"
          value={status}
          type="radio"
          onChange={
            (status: CltDriversExtrasDiscountsStatus) => handleChangeStatus(status)
          }
        >
          {cltDriversExtrasDiscountsStatusOptions.map((option) => {
            return (
              <MenuItemOption value={option.value} key={option.key}>{option.showOption}</MenuItemOption>
            )
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
