import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface PayVacationBody {
  paymentDate: string
  paymentValueInCents: number
  costCenter: string
  description: string
  paymentAttachmentsIds: string[]
}

interface PayVacationProps {
  vacationId: string
  body: PayVacationBody
}

export async function payVacation({
  body,
  vacationId
}: PayVacationProps) {
  try {
    const response = await api.put(`/vacations/${vacationId}/pay`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
