import { ChakraProvider } from '@chakra-ui/react'
import { theme } from './styles/theme'
import { Routes } from './routes'
import { ToastContainer } from 'react-toastify'
import { AuthProvider } from './contexts/AuthContext'
import { QueryClient, QueryClientProvider } from 'react-query'
import { FilterOptionsProvider } from './contexts/FilterOptionsContext'
import { SocketProvider } from './contexts/SocketContext'
import { ProfileSocketProvider } from 'contexts/ProfileSocketContext'
import { InternComunicationSocketProvider } from 'contexts/InternComunicationSocketContext'
import { ServiceSocketProvider } from 'contexts/ServiceSocketContext'
import { PmcSocketProvider } from 'contexts/PmcSocketContext'
import { AggregatesStatusTableButtonProvider } from 'contexts/AggregatesStatusTableButtonContext'
import { PurchaseOrdersSocketProvider } from 'contexts/PurchaseOrderSocketContext'
import { ExternalComunicationsSocketProvider } from 'contexts/ExternalComunicationsSocketContext'
import { PublicRoutes } from 'publicRoutes'
import { BugSocketProvider } from 'contexts/BugSocketContext'
import { BrowserRouter as Router } from 'react-router-dom'
import { PageRelatedDocument } from 'components/PageRelatedDocument'

export function App() {
  const queryClient = new QueryClient()

  return (
    <Router>
      <AuthProvider>
        <ChakraProvider theme={theme} portalZIndex={40}>
          <QueryClientProvider client={queryClient}>
            <PageRelatedDocument />
            <BugSocketProvider>
              <ServiceSocketProvider>
                <InternComunicationSocketProvider>
                  <ProfileSocketProvider>
                    <PmcSocketProvider>
                      <SocketProvider>
                        <PurchaseOrdersSocketProvider>
                          <FilterOptionsProvider>
                            <AggregatesStatusTableButtonProvider>
                              <ExternalComunicationsSocketProvider>
                                <Routes />
                              </ExternalComunicationsSocketProvider>
                            </AggregatesStatusTableButtonProvider>
                          </FilterOptionsProvider>
                        </PurchaseOrdersSocketProvider>
                      </SocketProvider>
                    </PmcSocketProvider>
                  </ProfileSocketProvider>
                </InternComunicationSocketProvider>
              </ServiceSocketProvider>
            </BugSocketProvider>
            <PublicRoutes />
          </QueryClientProvider>
          <ToastContainer />
        </ChakraProvider>
      </AuthProvider>
    </Router>
  )
}
