import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Heading,
  Spinner,
  Stack,
} from '@chakra-ui/react'
import { memo } from 'react'

import { RequestedAccordion } from '../RequestedAccordion/RequestedAccordion'

import { Input } from '../../../Inputs/Input'
import { useCustomer } from '../../../../hooks/customer/useCustomer'
import { ServiceProps } from '../../../../contexts/ServiceContext'
import { BranchProps } from '../../../../contexts/BranchContext'
import { useAddresses } from '../../../../hooks/address/useAddresses'
import { useProviders } from 'hooks/provider/useProviders'

interface DeliveryAccordionProps {
  serviceByID: ServiceProps
  branches: BranchProps[]
}

function DeliveryAccordion({ serviceByID, branches }: DeliveryAccordionProps) {
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()
  const { data: providers, isLoading: isProviderLoading } = useProviders()
  const {
    customer: { data: customer, isLoading: isCustomerLoading },
  } = useCustomer(
    serviceByID?.customer_id ? serviceByID?.customer_id : null,
    true,
    false,
  )

  return (
    <Accordion allowMultiple>
      {addresses && (
        <RequestedAccordion
          serviceByID={serviceByID}
          addresses={addresses}
          branches={branches}
        />
      )}
      {isAddressesLoading || isProviderLoading || isCustomerLoading ? (
        <Spinner />
      ) : (
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Informações adicionais
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Stack mt="4">
              <Input
                name="customer"
                label="Cliente"
                value={customer?.trading_firstname}
                isDisabled={true}
              />
            </Stack>
            <Heading size="md" mt="4" fontFamily="poppins">
              Endereço(s) Remetente(s)
            </Heading>
            {addresses
              ?.filter((address) =>
                serviceByID?.serviceIDRequested.source_address_id.includes(
                  address.id,
                ),
              )
              .map((address) => (
                <Stack key={address.id} mt="4">
                  <Input
                    isDisabled={true}
                    value={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep}
         `.toUpperCase()}
                    name="source_address"
                    label="Endereço"
                  />
                </Stack>
              ))}
            {providers
              ?.filter(
                (provider) =>
                  serviceByID?.serviceIDRequested.provider_id === provider.id,
              )
              .map((provider) => (
                <Stack key={provider.id} mt="4">
                  <Input
                    value={`| ${provider.trading_name} | ${provider.street} | ${provider.number} | ${provider.neighborhood} | ${provider.city} | ${provider.cep}`.toUpperCase()}
                    name="source_provider"
                    label="Endereço Fornecedor"
                    isDisabled={true}
                  />
                </Stack>
              ))}
          </AccordionPanel>
        </AccordionItem>
      )}
      <Divider my="6" borderColor="gray.700" />
    </Accordion>
  )
}

export default memo(DeliveryAccordion)
