import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface UpdateInvoicePurchaseOrderReqFunctionProps {
  purchaseOrderId: string
  input: FormData
}

export async function updateInvoicePurchaseOrder({
  purchaseOrderId,
  input
}: UpdateInvoicePurchaseOrderReqFunctionProps) {
  try {

    await api.patch(`/purchase-orders/${purchaseOrderId}/invoice`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
