import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CreateRequestedBusinessServiceForm } from '../../../components/Form/Service/RequestedBusiness/CreateRequestedBusinessServiceForm'
import { EditDetailRequestedBusinessServiceForm } from '../../../components/Form/Service/RequestedBusiness/EditDetailRequestedBusinessServiceForm'
import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { useAuth } from '../../../hooks/auth/useAuth'
import { useBusinessBudget } from '../../../hooks/businessBudget/useBusinessBudget'
import { useCustomer } from '../../../hooks/customer/useCustomer'
import { useDriver } from '../../../hooks/driver/useDriver'
import { useRequestedBusinessService } from '../../../hooks/services/requestedBusiness/useRequestedBusinessService'
import { useRequestedBusinessServiceFunctions } from '../../../hooks/services/requestedBusiness/useRequestedBusinessServiceFunctions'
import { useSwal } from '../../../hooks/swal/useSwal'
import { useAddresses } from '../../../hooks/address/useAddresses'
import { days_of_week } from '../../../utils/customLists'
import { setDate } from '../../../utils/DateFunctions/setDate'
import { RequestedBusinessServiceInputProps } from '../../../utils/RequestFunctions/Service/RequestedBusiness/requestRequestedBusinessServiceFunctions'
import { useCollectors } from 'hooks/collector/useCollectors'

interface IAddressesScheduleProps {
  address_id: string;
  planned_hour: string;
}

interface IFormInputProps {
  customer_id: string
  business_budget_id: string
  situation: string
  source_collector_id: string
  provider_id: string
  driver_id: string
  source_address_id: string[] | string
  destination_address_id: string[] | string
  vehicle: string
  service_type: string
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  route_start_date: string | Date
  route_start_hour: string
  route_end_hour: string
  deadline: number
  is_recurrent: string;
  recurrence_type: string
  recurrence: 'EVERY OTHER DAY' | 'ALTERNATE WEEKLY'
  days_of_week: string[]
  observation: string
  source_addresses_schedule: Array<IAddressesScheduleProps>
  destination_addresses_schedule: Array<IAddressesScheduleProps>
  automation_start_date: string
}

interface IQueryParams {
  id?: string
  slug: string
}

async function formatValuesToBack(
  values: IFormInputProps,
): Promise<RequestedBusinessServiceInputProps> {
  const [routeStartHourHours, routeStartHourMinutes] =
    values.route_start_hour.split(':')
  const [routeEndHourHours, routeEndHourMinutes] =
    values.route_end_hour.split(':')
  const sourceAddresses = Array.isArray(values.source_address_id)
    ? values.source_address_id
    : Array.from(values.source_address_id)
  const destinationAddresses = Array.isArray(values.destination_address_id)
    ? values.destination_address_id
    : Array.from(values.destination_address_id)
  const daysOfWeek = values.is_recurrent === "SIM"
    ? days_of_week.reduce((acc, curr) => {
      values?.days_of_week?.map(day => {
        if (day === curr.name) {
          acc.push(curr.value)
        }
        return []
      })

      return acc
    }, [] as number[])
    : null

  const sourceAddressesSchedule = values.source_addresses_schedule.map(address => {
    const [hours, minutes] = address.planned_hour.split(":")

    return {
      address_id: address.address_id,
      planned_hour: setDate.handleSetHourWithFormat(Number(hours), Number(minutes), "FullDate")
    }
  })

  const destinationAddressesSchedule = values.destination_addresses_schedule.map(address => {
    const [hours, minutes] = address.planned_hour.split(":")

    return {
      address_id: address.address_id,
      planned_hour: setDate.handleSetHourWithFormat(Number(hours), Number(minutes), "FullDate")
    }
  })

  return {
    ...values,
    destination_collector_id: values.source_collector_id,
    route_start_date: values.route_start_date as Date,
    route_start_hour: setDate.handleSetHourWithoutFormat(
      Number(routeStartHourHours),
      Number(routeStartHourMinutes)
    ),
    route_end_hour: setDate.handleSetHourWithoutFormat(
      Number(routeEndHourHours),
      Number(routeEndHourMinutes),
    ),
    provider_id: values.provider_id ? values.provider_id : null,
    source_address_id: sourceAddresses as string[],
    destination_address_id: destinationAddresses as string[],
    is_recurrent: values.is_recurrent === "NÃO" ? false : true,
    days_of_week: daysOfWeek,
    source_addresses_schedule: sourceAddressesSchedule,
    destination_addresses_schedule: destinationAddressesSchedule,
  }
}

export function CreateDetailEditRequestedBusinessService() {
  const [isRecurrent, setIsRecurrent] = useState(false)

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()
  const { id, slug }: IQueryParams = useParams()
  const { confirmMessage, standardMessage } = useSwal()

  const {
    drivers: { data: drivers, isLoading: isDriversLoading },
  } = useDriver(null, true, false)
  const { data: addresses, isLoading: isAddressesLoading} = useAddresses()
  const {
    customers: { data: customers, isLoading: isCustomersLoading },
  } = useCustomer(null, true, false)
  const {
    data: collectors, isLoading: isCollectorsLoading,
  } = useCollectors()
  const {
    businessBudgets: {
      data: businessBudgets,
      isLoading: isBusinessBudgetsLoading,
    },
  } = useBusinessBudget()
  const {
    requestedBusinessService: {
      data: requestedBusinessService,
      isLoading: isRequestedBusinessServiceLoading,
    },
  } = useRequestedBusinessService(id)

  const {
    createRequestedBusinessService: {
      mutateAsync: createRequestedBusinessService,
      isLoading: isCreateRequestedBusinessServiceLoading,
      isSuccess: isCreateRequestedBusinessServiceSuccess,
    },
    editRequestedBusinessService: {
      mutateAsync: editRequestedBusinessService,
      isLoading: isEditRequestedBusinessServiceLoading,
      isSuccess: isEditRequestedBusinessServiceSuccess,
    },
  } = useRequestedBusinessServiceFunctions()

  useEffect(() => {
    if (slug === 'solicitar') {
      if (!userLogged?.permissions.includes('add-requested-business-service')) {
        redirectTo('/')
      }
    }
    if (slug === 'editar') {
      if (
        !userLogged?.permissions.includes('edit-requested-business-service')
      ) {
        redirectTo('/')
      }
    }
    if (slug === 'visualizar') {
      if (
        !userLogged?.permissions.includes('view-requested-business-service')
      ) {
        redirectTo('/')
      }
    }
  }, [slug, redirectTo, userLogged])

  useEffect(() => {
    if (
      isCreateRequestedBusinessServiceSuccess ||
      isEditRequestedBusinessServiceSuccess
    ) {
      if (isRecurrent === true) {
        return redirectTo('/servicos-solicitados-business/recorrentes')
      }
      redirectTo('/servicos-solicitados-business')
    }
  }, [
    redirectTo,
    isRecurrent,
    isCreateRequestedBusinessServiceSuccess,
    isEditRequestedBusinessServiceSuccess,
  ])

  async function handleCreateRequestedBusinessService(values: IFormInputProps) {
    if (values.is_recurrent === "SIM") {
      setIsRecurrent(true)
    }

    const hasCreateRequestedBusinessService = await confirmMessage({
      title: 'Deseja solicitar um serviço business?',
    })

    if (hasCreateRequestedBusinessService) {
      const formatedValuesToBack = await formatValuesToBack(values)
      await createRequestedBusinessService(formatedValuesToBack)
      return
    }

    standardMessage('Ação cancelada com êxito!')
  }

  async function handleEditRequestedBusinessService(values: IFormInputProps) {
    if (values.is_recurrent === "SIM") {
      setIsRecurrent(true)
    }
    const hasEditRequestedBusinessService = await confirmMessage({
      title: 'Deseja editar um serviço business?',
    })

    if (hasEditRequestedBusinessService) {
      const formatedValuesToBack = await formatValuesToBack(values)
      const requestedBusinessServiceReqObj = {
        id: id!,
        input: formatedValuesToBack,
      }
      await editRequestedBusinessService(requestedBusinessServiceReqObj)
      return
    }

    standardMessage('Ação cancelada com êxito!')
  }

  if (
    isCreateRequestedBusinessServiceLoading ||
    isEditRequestedBusinessServiceLoading ||
    isDriversLoading ||
    isCustomersLoading ||
    isCollectorsLoading ||
    isBusinessBudgetsLoading ||
    isAddressesLoading ||
    isRequestedBusinessServiceLoading
  ) {
    return <GeneralContentLoading />
  }

  return (
    <>
      {slug === 'solicitar' &&
        drivers &&
        customers &&
        collectors &&
        businessBudgets &&
        addresses && (
          <CreateRequestedBusinessServiceForm
            drivers={drivers}
            customers={customers}
            collectors={collectors}
            businessBudgets={businessBudgets}
            submit={handleCreateRequestedBusinessService}
            addresses={addresses}
          />
        )}

      {(slug === 'editar' || slug === 'visualizar') &&
        drivers &&
        customers &&
        collectors &&
        businessBudgets &&
        addresses &&
        requestedBusinessService && (
          <EditDetailRequestedBusinessServiceForm
            slug={slug}
            drivers={drivers}
            customers={customers}
            collectors={collectors}
            businessBudgets={businessBudgets}
            requestedBusinessService={requestedBusinessService}
            submit={handleEditRequestedBusinessService}
            addresses={addresses}
          />
        )}
    </>
  )
}
