import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure, } from "@chakra-ui/react";
import { getActivesFeedbacks } from "api/feedbacks/getActivesFeedbacks";
import { ActiveFeedback, ActiveFeedbackStatus } from "api/feedbacks/_types/ActiveFeedback";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { FaExchangeAlt, FaFileDownload, FaFileExport, FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreateActiveFeedback } from "./components/CreateActiveFeedback";
import { ActiveFeedbackTableRow } from "./components/ActiveFeedbackTableRow";
import { ActivesFeedbacksTableFilter } from "./components/ActivesFeedbacksTableFilter";
import { format } from "date-fns";
import { activeFeedbackStatusMap } from "utils/feedbackMappers";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { TableSkeleton } from "components/Loading/TableSkeleton";

const headers = [
  { label: 'COLABORADOR', key: 'collaborator_name' },
  { label: 'CPF', key: 'cpf' },
  { label: 'RG', key: 'rg' },
  { label: 'E-MAIL', key: 'email' },
  { label: 'STATUS', key: 'status' },
  { label: 'DATA FEEDBACK', key: 'deadline' },
  { label: 'REGIONAL', key: 'regional' },

]

function formatValuesToReport(values: ActiveFeedback[]) {
  return values?.map((activeFeedback) => ({
    ...activeFeedback,
    collaborator_name: activeFeedback?.cltDriver ? `${activeFeedback?.cltDriver?.driver?.firstname} ${activeFeedback?.cltDriver?.driver?.lastname}` : activeFeedback?.internClt ? activeFeedback?.internClt.name : '-',
    cpf: activeFeedback?.cltDriver ? `${activeFeedback?.cltDriver?.driver?.cpf} ` : activeFeedback?.internClt ? activeFeedback?.internClt.cpf : '-',
    email: activeFeedback?.cltDriver ? `${activeFeedback?.cltDriver?.driver?.email} ` : activeFeedback?.internClt ? activeFeedback?.internClt.email : '-',
    rg: activeFeedback?.cltDriver ? `${activeFeedback?.cltDriver?.rg} ` : activeFeedback?.internClt ? activeFeedback?.internClt.rg : '-',
    regional: activeFeedback?.internClt.regional === 'other' ? 'Outra' : activeFeedback?.internClt.regional.toUpperCase(),
    deadline: activeFeedback.deadline
      ? format(new Date(activeFeedback.deadline), 'dd/MM/yyyy')
      : '-',
    status: activeFeedbackStatusMap[activeFeedback?.status]
  }))
}


export function ActiveFeedbacksTable() {

  const { replace, push: redirect } = useHistory()
  const { userLogged } = useAuth()

  const userCanViewActivesFeedbacks = userLogged?.permissions?.includes(
    'view-actives-feedbacks'
  )

  const userCanCreateActiveFeedback = userLogged?.permissions?.includes(
    'create-active-feedback'
  )

  useEffect(() => {
    if (!userCanViewActivesFeedbacks) redirect('/')
  }, [redirect, userCanViewActivesFeedbacks])

  const searchParams = useSearchParams()

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status') ?? 'all'
  const name = searchParams.get('name')
  const dueDate = searchParams.get('dueDate')

  const {
    data: activeFeedbacksResult,
    isLoading: isLoadingActiveFeedbacksResult
  } = useQuery({
    queryKey: [
      'actives-feedbacks',
      page,
      status,
      name,
      dueDate
    ],
    queryFn: () => getActivesFeedbacks({
      currentPage: Number(page),
      pageSize: 10,
      status: status as ActiveFeedbackStatus | 'all',
      name,
      dueDate
    }),
    keepPreviousData: true
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: activeFeedbacksResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: activeFeedbacksResult?.meta?.pageSize
    },
  })

  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }

  const {
    data: activeFeedbacksDataReportResult,
    isLoading: isLoadingActiveFeedbacksDataReportResult
  } = useQuery({
    queryKey: [
      'actives-feedbacks-report',
      page,
      status,
      activeFeedbacksResult?.meta?.count
    ],
    queryFn: () => getActivesFeedbacks({
      currentPage: 1,
      pageSize: activeFeedbacksResult?.meta?.count,
      status: ["scheduled", "done"].includes(status) ? status as ActiveFeedbackStatus : 'all'
    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreateActiveFeedbackOpen,
    onOpen: onOpenCreateActiveFeedback,
    onClose: onCloseCreateActiveFeedback
  } = useDisclosure()

  const csvReportProps = {
    data: activeFeedbacksDataReportResult ? formatValuesToReport(activeFeedbacksDataReportResult?.activesFeedbacks) : [],
    headers,
    filename: `feedbacks-ativos.csv`,
  }

  const sortedActiveFeedbacks = activeFeedbacksResult?.activesFeedbacks
    ?.slice()
    .sort((a, b) => {
      const deadlineA = new Date(a.deadline).getTime();
      const deadlineB = new Date(b.deadline).getTime();
      return deadlineA - deadlineB;
    });



  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Feedbacks Ativos</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar kanban"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => redirect('/feedbacks-ativos/kanban')}
          />
          {userCanCreateActiveFeedback && (
            <IconButton
              aria-label="Criar feedback"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateActiveFeedback}
            />
          )}
        </Flex>
        <Modal
          isOpen={isCreateActiveFeedbackOpen}
          onClose={onCloseCreateActiveFeedback}
          isCentered
          size="4xl"
        >
          <ModalOverlay />
          <CreateActiveFeedback onCloseModal={onCloseCreateActiveFeedback} />
        </Modal>
      </Flex>

      <ActivesFeedbacksTableFilter />

      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Colaborador</Th>
              <Th color="white">Status</Th>
              <Th color="white">Data Feedback</Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoadingActiveFeedbacksResult ? (
              <TableSkeleton />
            ) : (
              sortedActiveFeedbacks?.map((activeFeedback) => {
                return (
                  <ActiveFeedbackTableRow
                    key={activeFeedback.id}
                    activeFeedback={activeFeedback}
                  />
                )
              })
            )
            }

          </Tbody>
        </Table>
      </TableContainer>
      {!activeFeedbacksDataReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingActiveFeedbacksDataReportResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
