import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getSporadics } from "api/sporadics/getSporadics";
import { SporadicStatus } from "api/sporadics/_types/Sporadic";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaExchangeAlt, FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreateSporadic } from "./components/CreateSporadic";
import { SporadicsTableFilter } from "./components/SporadicsTableFilter";
import { SporadicTableRow } from "./components/SporadicTableRow";


export function SporadicsTable() {

  const { userLogged } = useAuth()
  const { replace, push: redirect } = useHistory()

  const userCanViewSporadics = userLogged?.permissions?.includes(
    'view-sporadics'
  )

  const userCanCreateSporadic = userLogged?.permissions?.includes(
    'create-sporadic'
  )

  useEffect(() => {
    if (!userCanViewSporadics) redirect('/')
  }, [redirect, userCanViewSporadics])

  const {
    isOpen: isCreateSporadicOpen,
    onOpen: onOpenCreateSporadic,
    onClose: onCloseCreateSporadic
  } = useDisclosure()



  const searchParams = useSearchParams()

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status') ?? 'all'
  const protocol = searchParams.get('protocol')

  const { data: sporadicsResult } = useQuery({
    queryKey: [
      'sporadics',
      page,
      status,
      protocol
    ],
    queryFn: () => getSporadics({
      currentPage: Number(page),
      pageSize: 10,
      status: status as SporadicStatus | 'all',
      protocol
    }),
    keepPreviousData: true
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: sporadicsResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: sporadicsResult?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }


  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Esporádicos</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar kanban"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => redirect('/esporadicos/kanban')}
          />
          {userCanCreateSporadic && (
            <IconButton
              aria-label="Criar esporadico"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateSporadic}
            />

          )}

        </Flex>

        <Modal
          isOpen={isCreateSporadicOpen}
          onClose={onCloseCreateSporadic}
          isCentered
          size="4xl"
        >
          <ModalOverlay />
          <CreateSporadic onClose={onCloseCreateSporadic} />
        </Modal>
      </Flex>

      <SporadicsTableFilter />
      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th></Th>
              <Th color="white">Protocolo</Th>
              <Th color="white">Data Inicial Realização</Th>
              <Th color="white">Data Final Realização</Th>
              <Th color="white">Regional</Th>
              <Th color="white">Status</Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {sporadicsResult?.sporadics?.map((sporadic) => {
              return (
                <SporadicTableRow sporadic={sporadic} />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
