import { PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { GetServiceCostsResponse } from "api/service/getServiceCosts";

interface ServiceCostsProps {
  serviceCosts: GetServiceCostsResponse
}

export function ServiceCosts({ serviceCosts }: ServiceCostsProps) {
  const totalCollectsCost = !serviceCosts
    ? 'R$0,00'
    : Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(serviceCosts.collectsCost)

  const totalDeliveriesCost = !serviceCosts
    ? 'R$0,00'
    : Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(serviceCosts.deliveriesCost)

  const totalExtrasDiscountsCost = !serviceCosts
    ? 'R$0,00'
    : Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(serviceCosts.extrasDiscountsCost)

  const totalMaterialsTotalCost = !serviceCosts
    ? 'R$0,00'
    : Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(serviceCosts.materialsTotalCost)

  const totalTransferCost = !serviceCosts
    ? 'R$0,00'
    : Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(serviceCosts.transferCost)
  return (
    <PopoverContent zIndex="popover" bg="white" borderColor="gray.200">
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverHeader
        fontSize="md"
        fontWeight="bold"
        border="0"
        letterSpacing="tight"
      >
        Custos do Serviço
      </PopoverHeader>
      <PopoverBody
        maxH='300px'
        h='full'
        overflow='auto'

      >
        <Table size="sm" w="full">
          <Tbody>
            <Tr>
              <Td>Custo total de coletas</Td>
              <Td>{totalCollectsCost}</Td>
            </Tr>
            <Tr>
              <Td>Custo total de entregas</Td>
              <Td>{totalDeliveriesCost}</Td>
            </Tr>
            <Tr>
              <Td>Custo de transferência</Td>
              <Td>{totalTransferCost}</Td>
            </Tr>
            <Tr>
              <Td>Custo dos insumos</Td>
              <Td>{totalMaterialsTotalCost}</Td>
            </Tr>
            <Tr>
              <Td>Extras/Descontos</Td>
              <Td>{totalExtrasDiscountsCost}</Td>
            </Tr>

          </Tbody>
        </Table>
      </PopoverBody>
    </PopoverContent>
  )
}
