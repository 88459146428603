import { formatInTimeZone } from 'date-fns-tz';
import { subHours, addHours, set } from 'date-fns';
import { timezonesUsed } from './DateFunctions/timezones';

interface ServiceDelayProps {
  timezoneOffset: string
  serviceDate: string
  serviceHour: string
}
export const serviceDelay = ({ timezoneOffset, serviceDate, serviceHour }: ServiceDelayProps) => {

  const userTimezone = new Date().getTimezoneOffset() / 60
  const currentDate = subHours(addHours(new Date(), userTimezone), parseInt(timezoneOffset.replace('UTC-', '')))

  const [
    day,
    month,
    year
  ] = formatInTimeZone(serviceDate, timezonesUsed[timezoneOffset].timezone, 'dd/MM/yyyy').split('/').map(Number)

  const [
    hours,
    minutes
  ] = formatInTimeZone(serviceHour, timezonesUsed[timezoneOffset].timezone, 'HH:mm').split(':').map(Number)

  const serviceDateTime = set(new Date(), {
    date: day,
    month: month - 1,
    year,
    hours,
    minutes
  })

  return currentDate > serviceDateTime
}
