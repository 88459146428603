import { Pmc } from "api/pmcs/pmc";
import { PmcProduct } from "api/pmcs/pmcProduct";
import axios from "axios";
import { FinancialClassification } from "hooks/financialClassification/dtos/FinancialClassification";
import { GeneralProvider } from "hooks/generalProvider/dtos/GeneralProvider";
import { User } from "hooks/user/dtos/User";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { PurchaseOrder } from "./purchaseOrder";

interface GetPurchaseOrderParams {
  purchaseOrderId: string

}

interface Attachment {
  key: string
  filename: string
}

export interface PurchaseOrderQuoteProviders {
  providerName: string
  budgetAttachment: string
}

export type PurchaseOrderInvoices = Attachment
export type PurchaseOrderApprovedAttachments = Attachment

export interface GetPurchaseOrderResponse {
  purchaseOrder: PurchaseOrder & {
    purchase_description: string
    quantity: number
    purchase_reason: string
    sugested_provider: string
    arrival_limit_date: string
    quote_providers: string,
    quoted_at: string
    approved_provider: string
    approved_at: string
    not_approved_at: string
    provider_cost_center: string
    approved_quote_attachments: string
    purchased_at: string | null
    is_finished_correctly: boolean
    has_requested_financial_payment: boolean
    purchase_type_attachment: string
    has_cfop: boolean
    has_conferred_equity: boolean
    arrived_at: string
    owner: User
    productResponsible: User
    provider: {
      id: string
      trading_name: string
    }
    provider_id: string
    financialClassification: FinancialClassification
    pmc: Pmc & {
      pmcProducts: Array<PmcProduct & {
        generalProvider: GeneralProvider
      }>
    }
    created_at: string
    attachment: string | null
    purchase_due_date: string | null
    purchase_has_invoice: boolean | null
    purchase_invoice: string | null
    purchase_invoice_sended_at: string | null
    refused_at: string | null
    refuse_description: string | null
    payment_type: string | null
    installments_quantity: number | null
    purchase_approved_value: number | null
  }
}

export async function getPurchaseOrder({
 purchaseOrderId
}: GetPurchaseOrderParams) {
  try {
    const { data } = await api.get<GetPurchaseOrderResponse>(`/purchase-orders/${purchaseOrderId}`)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

