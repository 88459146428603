import { Button, Flex, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner } from "@chakra-ui/react";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { CustomerExtraAndDiscountForm, ExtraAndDiscountShema } from "./CustomerExtraAndDiscountForm";
import { useBusinessBudgetRoutes } from "hooks/businessBudget/useBusinessBudgetsRoutes";
import { useNonInactiveCustomers } from "hooks/customer/useNonInactiveCustomers";
import { useMutation, useQueryClient } from "react-query";
import { createCustomerExtraDiscount } from "api/extrasAndDiscounts/createCustomerExtraDiscount";
import { useToastify } from "hooks/toastify/useToastify";


interface CreateCustomerExtraDiscountProps {
  onClose: () => void
}

const createExtrasAndDiscountsFormSchema = yup.object().shape({
  customer_id: yup.string().required(),
  type: yup.string().required(),
  service_type: yup.string().required(),
  protocol: yup.string().when('service_type', {
    is: 'EXPRESS',
    then: yup.string().required()
  }),
  route: yup.string().when('service_type', {
    is: 'BUSINESS',
    then: yup.string().required()
  }),
  occurrence_date: yup.string().when('service_type', {
    is: 'BUSINESS',
    then: yup.string().required()
  }),
  description: yup.string().required(),
  value: yup.string().required(),
})


export function CreateCustomerExtraDiscount({ onClose }: CreateCustomerExtraDiscountProps) {

  const formMethods = useForm<ExtraAndDiscountShema>({
    resolver: yupResolver(createExtrasAndDiscountsFormSchema)
  })

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const { control, handleSubmit, formState: { isSubmitting } } = formMethods

  const {
    data: customersData,
    isFetching: isCustomersDataFetching
  } = useNonInactiveCustomers()

  const [customerSelected, serviceType] = useWatch({
    control,
    name: ['customer_id', 'service_type']
  })

  const isBusinessServiceType = serviceType === 'BUSINESS'

  const {
    data: businessBudgetRoutesData
  } = useBusinessBudgetRoutes({
    customer_id: customerSelected,
    queryOptions: {
      enabled: !!customerSelected && isBusinessServiceType
    }
  })

  const { mutateAsync: createCustomerExtraDiscountFn } = useMutation({
    mutationFn: createCustomerExtraDiscount,
    onSuccess(_data, _variables, _context) {

      queryClient.invalidateQueries({ queryKey: 'customers-extras-discounts' })
      onClose()
    }
  })


  async function handleCreateExtrasAndDiscounts(
    values: ExtraAndDiscountShema
  ) {
    const successMessage = values.type === 'DESCONTO'
      ? 'Desconto criado com sucesso!'
      : 'Extra criado com sucesso!'

    const formData = new FormData()

    formData.append('type', values.type)
    formData.append('service_type', values.service_type)
    if (values.protocol) {
      formData.append('protocol', values.protocol.toString())
    }
    if (values.route) {
      formData.append('route', values.route)
    }
    if (values.occurrence_date) {
      formData.append('occurrence_date', values.occurrence_date)
    }
    formData.append('description', values.description)
    formData.append('value', values.value)
    formData.append('attachment', values.attachment[0])

    await promiseMessage(createCustomerExtraDiscountFn({
      customerId: values.customer_id,
      input: formData
    }), successMessage)
  }


  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Criar Extra/Desconto
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        maxH='600px'
        overflowY='scroll'
        onSubmit={handleSubmit(handleCreateExtrasAndDiscounts)}
      >
        {isCustomersDataFetching ? (
          <Spinner />
        ) : (
          <FormProvider {...formMethods}>
            <CustomerExtraAndDiscountForm
              customers={customersData.customers}
              businessBudgetRoutesData={businessBudgetRoutesData}
              slug='create'
            />

          </FormProvider>
        )}
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>

      </ModalBody>
    </ModalContent>
  )
}
