import { Flex, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { InternClt } from "api/internClts/_types/internClt";
import { useAuth } from "hooks/auth/useAuth";
import { FaSearch } from "react-icons/fa";
import { FaPen } from "react-icons/fa6";
import { useHistory } from "react-router-dom";
import { InternCltDetail } from "./InternCltDetail";
import { InternCltStatus } from "./InternCltStatus";

interface InternCltTableRowProps {
  internClt: InternClt
}

export function InternCltTableRow({ internClt }: InternCltTableRowProps) {

  const history = useHistory()

  const { userLogged } = useAuth()

  const userCanEditInternClt = userLogged?.permissions.includes('create-intern-clt')

  const {
    isOpen: isInternCltDetailOpen,
    onOpen: onOpenInternCltDetail,
    onClose: onCloseInternCltDetail
  } = useDisclosure()

  return (
    <Tr>
      <Td>
        <Flex
          align="center"
          w="full"
          gap={3}
        >
          <Modal
            isOpen={isInternCltDetailOpen}
            onClose={onCloseInternCltDetail}
            size="6xl"
          >
            <ModalOverlay />

            <InternCltDetail internCltId={internClt.id} />

          </Modal>
          <IconButton
            aria-label="Abrir detalhes de interno CLT"
            icon={<FaSearch />}
            variant="ghost"
            size="sm"
            onClick={onOpenInternCltDetail}
          />

          {userCanEditInternClt && (
            <IconButton
              aria-label="Editar interno CLT"
              icon={<FaPen />}
              size="sm"
              variant="ghost"
              onClick={() => history.push(`/interno-clt/${internClt.id}/editar`)}
            />
          )}
        </Flex>
      </Td>

      <Td>{internClt.name}</Td>
      <Td>{internClt.office.name}</Td>
      <Td>
        <InternCltStatus
          status={internClt.status}
        />
      </Td>
    </Tr>
  )
}
