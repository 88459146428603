import swal from 'sweetalert'
import { useHistory, useParams } from 'react-router'
import { useState, useContext, useEffect } from 'react'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { AuthContext } from '../../contexts/AuthContext'
import { StockForm } from './StockForm'
import { StockContext } from '../../contexts/StockContext'

interface QueryParams {
  id: string
  slug: string
}

interface FormInputs {
  collector_id: string
  input_id: string
  minimum_stock: number
  standard_stock: number
  observation: string
}

export function StockAddDetailEdit() {
  const { slug, id }: QueryParams = useParams()

  const { createStock, editStock } = useContext(StockContext)
  const [isLoading, setIsLoading] = useState(false)
  const { userLogged } = useContext(AuthContext)

  const history = useHistory()

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (slug === 'adicionar') {
            if (!permissions?.includes('add-stock')) {
              history.push('/')
            }
          }
          if (slug === 'editar') {
            if (!permissions?.includes('edit-stock')) {
              history.push('/')
            }
          }
          if (slug === 'visualizar') {
            if (!permissions?.includes('view-stock')) {
              history.push('/')
            }
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [slug, history, userLogged])

  async function handleCreateStock(values: FormInputs) {
    await swal({
      title: 'Deseja adicionar um estoque?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willCreate) => {
      if (willCreate) {
        const response = await createStock(values)
        if (response) {
          setIsLoading(true)
          setTimeout(() => {
            history.push('/estoques')
          }, 1500)
        }
      } else {
        return swal('Ação cancelada com êxito!')
      }
    })
  }

  async function handleEditStock(values: FormInputs) {
    await swal({
      title: 'Deseja editar um estoque?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willEdit) => {
      if (willEdit) {
        const response = await editStock(id, values)
        if (response) {
          setIsLoading(true)
          setTimeout(() => {
            history.push('/estoques')
          }, 1500)
        }
      } else {
        swal('Ação cancelada com êxito!')
      }
    })
  }

  if (isLoading) {
    return <GeneralContentLoading />
  }

  if (slug === 'adicionar') {
    return (
      <StockForm
        slug={slug}
        href=""
        submit={handleCreateStock}
        title="Adicionar estoque"
        action="Salvar"
      />
    )
  } else if (slug === 'editar') {
    return (
      <StockForm
        slug={slug}
        id={id}
        href=""
        submit={handleEditStock}
        title="Editar estoque"
        action="Salvar"
      />
    )
  } else {
    return (
      <StockForm
        slug={slug}
        id={id}
        href={`/estoque/editar/${id}`}
        submit={async () => { }}
        title="Visualizar estoque"
        action="Editar"
        isDisabled={true}
      />
    )
  }
}
