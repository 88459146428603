import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ExternalComunicationsRootProps {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
}


export function ExternalComunicationsRoot({
  isOpen,
  onClose,
  children
}: ExternalComunicationsRootProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        {children}
      </ModalContent>
    </Modal>

  )
}
