import { Fragment, useEffect, useMemo, useState } from "react";

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Divider, Flex, Spinner, Stack, Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { NestedValue, useFormContext, useWatch } from "react-hook-form";
import { add, getDay, isAfter, isEqual, parseISO, set } from "date-fns";

import { useCity } from "hooks/city/useCity";
import { Input } from "components/Inputs/Input";
import { useBudget } from "hooks/budget/useBudget";
import { Address } from "hooks/address/dtos/Address";
import { Select } from "components/Inputs/SelectInput";
import { useBranches } from "hooks/branch/useBranches";
import { useCustomer } from "hooks/customer/useCustomer";
import { useShipping } from "hooks/shipping/useShipping";
import { usePlannedRoutes } from "hooks/usePlannedRoutes";
import { useProviders } from "hooks/provider/useProviders";
import { formatDate } from "utils/DateFunctions/formatDate";
import { useCollectors } from "hooks/collector/useCollectors";
import { BranchesInputsGroups } from "./inputGroups/BranchesInputsGroups";
import { CollectorsInputsGroup } from "./inputGroups/CollectorsInputsGroup";
import { PlannedRoutesInputsGroup } from "./inputGroups/PlannedRoutesInputsGroup";
import { AddressesCheckboxInputsGroup } from "./inputGroups/AddressesCheckboxInputsGroup";
import { CrossdockingHoursInputsGroup } from "./inputGroups/CrossdockingHoursInputsGroup";
import { ConnectionBranchesInputsGroup } from "./inputGroups/ConnectionBranchesInputsGroup";
import { deadline_delivery, modal_types, service_types, vehicle_types } from "utils/customLists";
import { FractionedServiceHoursInputsGroup } from "./inputGroups/FractionedServiceHoursInputsGroup";
import { switchRequestedServiceMateryalTypes } from "utils/CustomLists/switchRequestedServiceMateryalTypes";
import { TextArea } from "components/Inputs/TextInput";
import { useServiceRouteNetworks } from "hooks/useRouteNetwork/useServiceRouteNetworks";
import { comparePlannedRouteArrivalHourIsBiggerThanDestinationBranchOpenHour } from "utils/ServiceFunctions/comparePlannedRouteArrivalHourIsBiggerThanDestinationBranchOpenHour";
import { comparePlannedRouteDepartureHourIsLessThanSourceBranchCloseHour } from "utils/ServiceFunctions/comparePlannedRouteDepartureHourIsLessThanSourceBranchCloseHour";
import { comparePlannedRouteDepartureHourIsLessThanSourceBranchOpenHour } from "utils/ServiceFunctions/comparePlannedRouteDepartureHourIsLessThanSourceBranchOpenHour";
import { findBranchOpenAndCloseHoursBasedOnDayOfWeek } from "utils/ServiceFunctions/findBranchOpenAndCloseHoursBasedOnDayOfWeek";
import { findPlannedRouteInfoAndFormatAccordinglyWithModal } from "utils/ServiceFunctions/findPlannedRouteInfoAndFormatAccordinglyWithModal";
import { useAuth } from "hooks/auth/useAuth";
import { useCollectorCosts } from "hooks/cost/useCollectorCosts";
import { getTransferCostEstimate } from "api/service/getTransferCostEstimate";
import { useQuery } from "react-query";
import { getServicesValidatedWithSameModal } from "api/service/getServicesValidatedWithSameModal";
import { ServicesValidatedWithSameModalTableRow } from "./ServicesValidatedWithSameModalTableRow";

interface BudgetInfo {
  source_cities?: string[]
  destination_cities?: string[]
  source_hub_id?: string
  crossdocking_hub_id?: string | null
  destination_hub_id?: string
}

interface RequestedServiceInfo {
  source_addresses?: string[]
  destination_addresses?: string[]
  customer_id?: string
  budget_id?: string
  isFetching: boolean
  destination_addresses_input?: string[]
  serviceStep: string
}

export interface DestinationAddressInput {
  address_id: string
  quantity: number
}

interface ValidateRequestedServiceFormProps {
  budgetInfo: BudgetInfo
  isEditPage?: boolean
  addresses?: Address[]
  isAddressesFetching: boolean
  requestedServiceInfo: RequestedServiceInfo
  sourceAddresses?: Address[]
  defaultSelectedSourceAddresses?: string[]
  destinationAddresses?: Address[]
  defaultSelectedDestinationAddresses?: string[]
  destinationAddressesInputFields: DestinationAddressInput[]
  appendDestinationAddressesInputFields: (value: Partial<DestinationAddressInput>) => void
}

export interface ValidateRequestedServiceFormInputs {
  customer_id: string
  budget_id: string
  source_address_id: NestedValue<string[]>
  destination_address_id: NestedValue<string[]>
  has_crossdocking: string
  source_collector_id: string
  crossdocking_collector_id: string | null
  destination_collector_id: string
  shipping_id: string

  source_branch_id: string
  destination_crossdocking_branch_id: string
  source_crossdocking_branch_id: string
  destination_branch_id: string


  has_crossdocking_connection: string
  has_connection: string
  connection_branch_id: string
  crossdocking_connection_branch_id: string

  deadline: number
  service_type: string

  crossdocking_modal: string
  modal: string

  crossdocking_planned_flight: string
  planned_flight: string
  planned_connection_flight: string
  crossdocking_planned_connection_flight: string

  vehicle: string
  franchising: number
  transfer_budget: number
  caixa_termica: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  embalagem_secundaria: number

  collect_date: string
  collect_hour_start: string
  collect_hour_end: string

  delivery_date: string
  delivery_hour: string

  crossdocking_board_date: string
  crossdocking_board_hour: string
  crossdocking_availability_forecast_day: string
  crossdocking_availability_forecast_time: string

  board_date: string
  board_hour: string
  availability_forecast_day: string
  availability_forecast_time: string

  provider_id: string
  material_type: string
  destination_addresses_input: DestinationAddressInput[]

  observation: string
  transfer_cost_estimate_crossdocking: string
  transfer_cost_estimate: string
  protocol: string
}

const positiveNegativeOptions = [
  { key: "yes", value: "SIM", showOption: "SIM" },
  { key: "no", value: "NÃO", showOption: "NÃO" },
]

function sortServicesValidatedWithSameModal(
  curr: { boardHour: string, boardDate: string },
  next: { boardHour: string, boardDate: string }
) {
  const [currHours, currMinutes] = curr.boardHour.split(':').map(Number)
  const [nextHours, nextMinutes] = curr.boardHour.split(':').map(Number)

  const dateNowWithCurrHours = set(new Date(), { hours: currHours, minutes: currMinutes })
  const dateNowWithNextHours = set(new Date(), { hours: nextHours, minutes: nextMinutes })

  return new Date(curr.boardDate).getTime() - new Date(next.boardDate).getTime() || dateNowWithCurrHours.getMilliseconds() - dateNowWithNextHours.getMilliseconds()
}

interface ServiceWithSameModal {
  id: string
  protocol: number
  customer: {
    id: string
    tradingFirstname: string
  }
  boardDate: string
  boardHour: string
  sourceBranch: {
    id: string
    nickname: string
  }
  destinationBranch: {
    id: string
    nickname: string
  }
  shipping: {
    id: string
    tradingName: string
  }
}


const removeDuplicatesServices = (services: ServiceWithSameModal[]): ServiceWithSameModal[] => {
  return services.filter((service, index, self) =>
    index === self.findIndex((s) => s.id === service.id && s.protocol === service.protocol)
  );
};

const filterServicesByCollectDate = (services: ServiceWithSameModal[], collectDate: string | null): ServiceWithSameModal[] => {
  if (!Boolean(collectDate)) {
    return services
  }

  const collectDateISO = parseISO(collectDate)

  return services.filter((service) => {
    const boardDate = parseISO(service.boardDate);
    return isAfter(boardDate, collectDateISO) || isEqual(boardDate, collectDateISO);
  });
};


export function ValidateRequestedServiceForm({
  addresses,
  isEditPage = false,
  budgetInfo,
  isAddressesFetching,
  requestedServiceInfo,
  destinationAddressesInputFields,
  appendDestinationAddressesInputFields
}: ValidateRequestedServiceFormProps) {

  const { userLogged } = useAuth()

  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors }
  } = useFormContext<ValidateRequestedServiceFormInputs>()

  const isUserAuthorizedToEditServiceCollectors = isEditPage
    ? userLogged?.permissions.includes('edit-validated-service-collectors')
    : true

  const isUserAuthorizedToEditServiceBranches = isEditPage
    ? userLogged?.permissions.includes('edit-validated-service-branches')
    : true

  const isUserAuthorizedToEditServiceSchedule = isEditPage
    ? userLogged?.permissions.includes('edit-validated-service-schedules')
    : true

  const [isRouteNetworkFetchEnabled, setIsRouteNetworkFetchEnabled] = useState(true)

  const {
    customer: { data: customer, isFetching: isFetchingCustomer }
  } = useCustomer(requestedServiceInfo.customer_id, false)

  const {
    budget: { data: budget, isFetching: isFetchingBudget }
  } = useBudget(requestedServiceInfo.budget_id)

  const {
    cities: {
      data: cities,
      isFetching: isFetchingCities
    }
  } = useCity(null, true)

  const { source_cities, destination_cities } = budgetInfo


  const budgetSourceCitiesNames = useMemo(() => {
    return Array.from(new Set(cities
      ?.filter(city => source_cities?.includes(city.id))
      ?.map(city => city.name)
    ))
  }, [source_cities, cities])

  const budgetDestinationCitiesNames = useMemo(() => {
    return Array.from(new Set(cities
      ?.filter(city => destination_cities?.includes(city.id))
      ?.map(city => city.name)
    ))
  }, [destination_cities, cities])

  useEffect(() => {
    if (customer) {
      setValue('customer_id', customer.trading_firstname)
    }
  }, [customer, setValue])

  useEffect(() => {
    if (budget) {
      setValue('budget_id', `${budgetSourceCitiesNames?.join(
        ', ',
      )} > ${budgetDestinationCitiesNames?.join(', ')} | ${budget.vehicle
        } | GELO SECO: ${budget.gelo_seco} | GELOX: ${budget.gelox
        } | ${budget.observation}
`.toUpperCase())
    }
  }, [budget, setValue, budgetSourceCitiesNames, budgetDestinationCitiesNames])

  const [sourceAddressesSelected, destinationAddressesSelected] = watch([
    "source_address_id",
    "destination_address_id"
  ])

  const isCrossdockingService = watch("has_crossdocking") === "SIM"
  const isFractionedService = watch("service_type") === "FRACIONADO"
  const isFinishedService = requestedServiceInfo?.serviceStep === 'finishedService'
  const userHasPermissionEditFinishedService =
    userLogged?.permissions.includes("edit-finished-service");

  const editFinishedService = isFinishedService ?
    userHasPermissionEditFinishedService ?
      false :
      true :
    false

  const {
    source_addresses: defaultSelectedSourceAddresses,
    destination_addresses: defaultSelectedDestinationAddresses
  } = requestedServiceInfo

  const sourceAddresses = addresses?.filter(address => {
    const sourceCitiesFilter = source_cities?.includes(address.city_id)
    const differentFromDestinationAddressesSelectedFilter =
      !destinationAddressesSelected?.includes(address.id)

    return sourceCitiesFilter && differentFromDestinationAddressesSelectedFilter
  })

  const destinationAddresses = addresses?.filter(address => {
    const destinationCitiesFilter = destination_cities?.includes(address.city_id)
    const differentFromSourceAddressesSelectedFilter =
      !sourceAddressesSelected?.includes(address.id)

    return destinationCitiesFilter && differentFromSourceAddressesSelectedFilter
  })

  const { source_hub_id, crossdocking_hub_id, destination_hub_id } = budgetInfo

  const geloSecoQuantity = watch("gelo_seco")

  const { data: providers, isFetching: isFetchingProviders } = useProviders({
    queryOptions: { enabled: !!source_hub_id && geloSecoQuantity > 0 },
    queryParams: {
      hub_id: source_hub_id,
      material: 'GELO SECO',
      situation: 'ATIVO'
    }
  })

  const providersOptions = providers?.map(provider => {
    return {
      key: provider.id,
      showOption: provider.trading_name,
      value: provider.id
    }
  })

  const { isFetching: isRequestedServiceFetching } = requestedServiceInfo

  const budgetHubs: string[] = []

  if (crossdocking_hub_id) {
    budgetHubs.push(source_hub_id!, crossdocking_hub_id, destination_hub_id!)
  } else {
    budgetHubs.push(source_hub_id!, destination_hub_id!)
  }

  const { data: collectors, isFetching: isCollectorsFetching } = useCollectors({
    queryParams: { hubs: budgetHubs, situation: 'ATIVO' },
    queryOptions: { enabled: !!budgetHubs.length && !isRequestedServiceFetching }
  })

  const {
    data: primaryCollectorCosts,
    isLoading: isLoadingPrimaryCollectorCosts
  } = useCollectorCosts({
    queryParams: {
      isPrimary: true
    }
  })



  const sourceCollectorsOptions = collectors?.filter(collector => {

    const sourceHubFilter = source_hub_id
      ? collector.hub_list.includes(source_hub_id)
      : false

    const differentFromLoglifeMatrizFilter = collector.trading_name !== 'LOGLIFE MATRIZ'

    return sourceHubFilter && differentFromLoglifeMatrizFilter
  })
    ?.map(collector => {
      const sourceAddressesCitiesIds = sourceAddresses?.map((address) => address.city_id)

      const collectorHasSomePrimaryCostToSourceCities =
        primaryCollectorCosts?.find(primaryCost => primaryCost.collector_id === collector.id && sourceAddressesCitiesIds?.includes(primaryCost.city_id))



      if (collectorHasSomePrimaryCostToSourceCities) {
        return {
          key: collector.id,
          value: collector.id,
          showOption: `${collector.trading_name} - (${collector.company_name})`,
          bold: true
        }
      }

      return {
        key: collector.id,
        value: collector.id,
        showOption: `${collector.trading_name} - (${collector.company_name})`
      }
    })

  const crossdockingCollectorsOptions = collectors
    ?.filter(collector => {

      const crossdockingHubFilter = crossdocking_hub_id
        ? collector.hub_list.includes(crossdocking_hub_id)
        : false

      const differentFromLoglifeMatrizFilter = collector.trading_name !== 'LOGLIFE MATRIZ'

      return crossdockingHubFilter && differentFromLoglifeMatrizFilter
    })
    ?.map(collector => {
      return {
        key: collector.id,
        value: collector.id,
        showOption: `${collector.trading_name} - (${collector.company_name})`
      }
    })

  const destinationCollectorsOptions = collectors?.filter(collector => {

    const destinationHubFilter = destination_hub_id
      ? collector.hub_list.includes(destination_hub_id)
      : false

    const differentFromLoglifeMatrizFilter = collector.trading_name !== 'LOGLIFE MATRIZ'

    return destinationHubFilter && differentFromLoglifeMatrizFilter
  })
    ?.map(collector => {
      const destinationAddressesCitiesIds = destinationAddresses?.map((address) => address.city_id)

      const collectorHasSomePrimaryCostToDestinationCities =
        primaryCollectorCosts?.find(primaryCost => primaryCost.collector_id === collector.id && destinationAddressesCitiesIds?.includes(primaryCost.city_id))


      if (collectorHasSomePrimaryCostToDestinationCities) {
        return {
          key: collector.id,
          value: collector.id,
          showOption: `${collector.trading_name} - (${collector.company_name})`,
          bold: true
        }
      }

      return {
        key: collector.id,
        value: collector.id,
        showOption: `${collector.trading_name} - (${collector.company_name})`
      }
    })

  const { data: branches, isFetching: isBranchesFetching } = useBranches({
    queryOptions: { enabled: !isRequestedServiceFetching }
  })

  const crossdockingModal = watch("crossdocking_modal")
  const modal = watch("modal")

  const sourceBranchesOptions = branches?.filter(branch => {
    const isActiveBranch = branch.situation === 'active'
    const hubFilter = branch.hub_id === source_hub_id
    const modalFilter = isCrossdockingService
      ? branch.shippingIDBranch.modal === crossdockingModal
      : branch.shippingIDBranch.modal === modal

    return hubFilter && modalFilter && isActiveBranch
  })
    .map(branch => {
      const shippingBudget = budget?.shippings?.find(shipping => shipping.shipping?.id === branch.shipping_id)

      if (shippingBudget) {
        return {
          key: branch.id,
          value: branch.id,
          showOption: `${branch.nickname} - ${branch.shippingIDBranch.company_name}`,
          bold: true
        }
      }
      return {
        key: branch.id,
        value: branch.id,
        showOption: `${branch.nickname} - ${branch.shippingIDBranch.company_name}`,
      }
    })

  const shippingId = watch('shipping_id')
  const sourceBranchSelected = watch("source_branch_id")
  const destinationBranchSelected = watch("destination_branch_id")
  const sourceShippingId = branches?.find(branch => branch.id === sourceBranchSelected)?.shipping_id
  const destinationShippingId = branches?.find(branch => branch.id === destinationBranchSelected)?.shipping_id

  const destinationCrossdockingBranchesOptions = branches?.filter(branch => {
    const sourceBranchShipping = branches
      ?.find(branch => branch.id === sourceBranchSelected)?.shipping_id

    const branchesDifferentFromSourceBranchFilter =
      branch.id !== sourceBranchSelected
    const shippingFilter = branch.shipping_id === sourceBranchShipping
    const modalFilter = branch.shippingIDBranch.modal === crossdockingModal
    const hubFilter = branch.hub_id === crossdocking_hub_id
    const isActiveBranch = branch.situation === 'active'

    return branchesDifferentFromSourceBranchFilter && shippingFilter &&
      modalFilter && hubFilter && isActiveBranch
  })
    .map(branch => {

      const shippingBudget = budget?.shippings?.find(shipping => shipping.shipping?.id === branch.shipping_id)
      if (shippingBudget) {

        return {
          key: branch.id,
          value: branch.id,
          showOption: `${branch.nickname} - ${branch.shippingIDBranch.company_name}`,
          bold: true
        }
      }
      return {
        key: branch.id,
        value: branch.id,
        showOption: `${branch.nickname}- ${branch.shippingIDBranch.company_name}`,
      }
    })

  const sourceCrossdockingBranchesOptions = branches?.filter(branch => {
    const hubFilter = branch.hub_id === crossdocking_hub_id
    const shippingFilter = branch.shipping_id === shippingId
    const modalFilter = branch.shippingIDBranch.modal === modal
    const isActiveBranch = branch.situation === 'active'

    return hubFilter && shippingFilter && modalFilter && isActiveBranch
  })
    .map(branch => {
      const shippingBudget = budget?.shippings?.find(shipping => shipping.shipping?.id === branch.shipping_id)

      if (shippingBudget) {
        return {
          key: branch.id,
          value: branch.id,
          showOption: `${branch.nickname} - ${branch.shippingIDBranch.company_name}`,
          bold: true
        }
      }

      return {
        key: branch.id,
        value: branch.id,
        showOption: `${branch.nickname}- ${branch.shippingIDBranch.company_name}`,
      }
    })

  const sourceCrossdockingBranchSelected = watch("source_crossdocking_branch_id")
  const destinationCrossdockingBranchSelected = watch("destination_crossdocking_branch_id")

  const destinationBranchesOptions = branches?.filter(branch => {
    if (isCrossdockingService) {
      const sourceCrossdockingBranchShipping = branches
        ?.find(branch => branch.id === sourceCrossdockingBranchSelected)?.shipping_id

      const branchesDifferentFromSourceCrossdockingBranchFilter =
        branch.id !== sourceCrossdockingBranchSelected
      const shippingFilter = branch.shipping_id === sourceCrossdockingBranchShipping
      const modalFilter = branch.shippingIDBranch.modal === modal
      const hubFilter = branch.hub_id === destination_hub_id
      const isActiveBranch = branch.situation === 'active'

      return branchesDifferentFromSourceCrossdockingBranchFilter && shippingFilter
        && modalFilter && hubFilter && isActiveBranch
    }
    const sourceBranchShipping = branches
      ?.find(branch => branch.id === sourceBranchSelected)?.shipping_id

    const branchesDifferentFromSourceBranchFilter =
      branch.id !== sourceBranchSelected
    const shippingFilter = branch.shipping_id === sourceBranchShipping
    const modalFilter = branch.shippingIDBranch.modal === modal
    const hubFilter = branch.hub_id === destination_hub_id
    const isActiveBranch = branch.situation === 'active'

    return branchesDifferentFromSourceBranchFilter && shippingFilter
      && modalFilter && hubFilter && isActiveBranch
  }).map(branch => {
    const shippingBudget = budget?.shippings?.find(shipping => shipping.shipping?.id === branch.shipping_id)

    if (shippingBudget) {
      return {
        key: branch.id,
        value: branch.id,
        showOption: `${branch.nickname} - ${branch.shippingIDBranch.company_name}`,
        bold: true
      }
    }

    return {
      key: branch.id,
      value: branch.id,
      showOption: `${branch.nickname} - ${branch.shippingIDBranch.company_name}`,
    }
  })

  const isAirModal = modal === "AÉREO"
  const isAirCrossdockingModal = crossdockingModal === "AÉREO"

  const crossdockingConnectionBranchesOptions = isCrossdockingService && isAirCrossdockingModal
    ? branches?.filter(branch => {
      return branch.shipping_id === branches.find(branch => branch.id === sourceBranchSelected)?.shipping_id
    }).map(branch => {

      const shippingBudget = budget?.shippings?.find(shipping => shipping.shipping?.id === branch.shipping_id)

      if (shippingBudget) {
        return {
          key: branch.id,
          value: branch.id,
          showOption: `${branch.nickname} - ${branch.shippingIDBranch.company_name}`,
          bold: true
        }
      }

      return {
        key: branch.id,
        value: branch.id,
        showOption: `${branch.nickname} - ${branch.shippingIDBranch.company_name}`,
      }
    })
    : []

  const connectionBranchesOptions = isAirModal && branches
    ? isCrossdockingService
      ? branches?.filter(branch => {
        return branch.shipping_id === branches.find(branch => {
          return branch.id === sourceCrossdockingBranchSelected
        })?.shipping_id
      }).map(branch => {
        return {
          key: branch.id,
          value: branch.id,
          showOption: `${branch.nickname} - ${branch.shippingIDBranch.company_name}`,
        }
      })
      : branches.filter(branch => {
        return branch.shipping_id === branches.find(branch => {
          return branch.id === sourceBranchSelected
        })?.shipping_id
      }).map(branch => {
        const shippingBudget = budget?.shippings?.find(shipping => shipping.shipping?.id === branch.shipping_id)

        if (shippingBudget) {
          return {
            key: branch.id,
            value: branch.id,
            showOption: `${branch.nickname} - ${branch.shippingIDBranch.company_name}`,
            bold: true
          }
        }

        return {
          key: branch.id,
          value: branch.id,
          showOption: `${branch.nickname} - ${branch.shippingIDBranch.company_name}`,
        }
      })
    : []

  const {
    shipping: {
      data: shipping, isFetching: isShippingFetching
    }
  } = useShipping(null, true)

  const shippingOptions = shipping
    ? shipping.map(ship => {
      return {
        key: ship.id,
        value: ship.id,
        showOption: ship.company_name
      }
    })
    : []

  const hasConnection = watch('has_connection') === "SIM"
  const connectionBranchSelected = watch('connection_branch_id')
  const hasCrossdockingConnection = watch('has_crossdocking_connection') === "SIM"
  const crossdockingConnectionBranchSelected = watch('crossdocking_connection_branch_id')

  const collectDate = useWatch({
    control,
    name: "collect_date"
  })
  const collectHourEnd = useWatch({
    control,
    name: "collect_hour_end"
  })

  const deliveryDate = useWatch({
    control,
    name: "delivery_date"
  })
  const deliveryHour = useWatch({
    control,
    name: "delivery_hour"
  })

  const isValidCollectDate = new Date(collectDate) instanceof Date && !isNaN(new Date(collectDate).getMilliseconds())
  const isValidDeliveryDate = new Date(deliveryDate) instanceof Date && !isNaN(new Date(deliveryDate).getMilliseconds())

  useEffect(() => {
    if (isValidDeliveryDate) {
      const debounce = setTimeout(() => {
        const dayOfWeekOfdeliveryDate = getDay(add(new Date(deliveryDate), { days: 1 }))

        if (dayOfWeekOfdeliveryDate === 6) {
          const someDestinationAddressHasSaturdayAttendance = addresses
            ?.find(address => {
              return destinationAddressesSelected.includes(address.id) && address.saturday_open !== null
            })

          if (!someDestinationAddressHasSaturdayAttendance) {
            const someDestinationAddressHasSundayAttendance = addresses?.find(address => {
              return destinationAddressesSelected.includes(address.id) && address.sunday_open !== null
            })

            if (someDestinationAddressHasSundayAttendance) {
              const newDeliveryDate = formatDate
                .handle(add(new Date(deliveryDate), { days: 2 }), 'DateWithoutHourToInput')

              setValue('delivery_date', newDeliveryDate)
            } else {
              const newDeliveryDate = formatDate
                .handle(add(new Date(deliveryDate), { days: 3 }), 'DateWithoutHourToInput')

              setValue('delivery_date', newDeliveryDate)
            }
          }
        }

        if (dayOfWeekOfdeliveryDate === 0) {
          const someDestinationAddressHasSundayAttendance = addresses
            ?.find(address => {
              return destinationAddressesSelected.includes(address.id) && address.sunday_open !== null
            })

          if (!someDestinationAddressHasSundayAttendance) {
            const newDeliveryDate = formatDate
              .handle(add(new Date(deliveryDate), { days: 2 }), 'DateWithoutHourToInput')

            setValue('delivery_date', newDeliveryDate)
          }
        }
      }, 1000)

      return () => clearTimeout(debounce)
    }
  }, [
    setValue,
    addresses,
    deliveryDate,
    isValidDeliveryDate,
    destinationAddressesSelected
  ])

  useEffect(() => {
    setIsRouteNetworkFetchEnabled(false)
    const routeNetworkTimeout = setTimeout(() => {
      setIsRouteNetworkFetchEnabled(true)
    }, 1500)

    return () => clearTimeout(routeNetworkTimeout)
  }, [
    collectDate,
    collectHourEnd,
    deliveryDate,
    deliveryHour,
    sourceBranchSelected,
    sourceCrossdockingBranchSelected
  ])

  const sourceBranchShippingId = branches?.find(branch => {
    return branch.id === sourceBranchSelected
  })?.shipping_id

  const sourceCrossdockingBranchShippingId = branches?.find(branch => {
    return branch.id === sourceCrossdockingBranchSelected
  })?.shipping_id

  const {
    data: serviceRouteNetworksData,
    isFetching: isFetchingServiceRouteNetworksData
  } = useServiceRouteNetworks({
    queryOptions: {
      enabled: isFractionedService &&
        isValidCollectDate &&
        isValidDeliveryDate &&
        (isCrossdockingService
          ? (!!sourceBranchShippingId && !!sourceCrossdockingBranchShippingId)
          : !!sourceBranchShippingId) &&
        isRouteNetworkFetchEnabled
    },
    queryParams: {
      collect_date: collectDate,
      collect_hour_end: collectHourEnd,
      delivery_date: deliveryDate,
      delivery_hour: deliveryHour,
      shipping_id: sourceBranchShippingId,
      crossdocking_shipping_id: sourceCrossdockingBranchShippingId,
      modal: modal as 'AÉREO' | 'RODOVIÁRIO'
    }
  })

  const {
    plannedRoutesWithoutCrossdockingAndConnection,
    plannedRoutesFromSourceBranchToConnectionCrossdockingBranch,
    plannedRoutesFromSourceBranchToConnectionBranch,
    plannedRoutesFromConnectionBranchToDestinationBranch,
    plannedRoutesFromConnectionCrossdockingBranchToDestinationCrossdockingBranch,
    plannedRoutesFromSourceBranchToDestinationCrossdockingBranch,
    plannedRoutesFromSourceCrossdockingBranchToConnectionBranch,
    plannedRoutesFromSourceCrossdockingBranchToDestinationBranch
  } = usePlannedRoutes({
    destinationBranchSelected,
    hasConnection,
    isCrossdockingService,
    isFractionedService,
    routeNetworks: serviceRouteNetworksData?.routeNetworks,
    sourceBranchSelected,
    connectionBranchSelected,
    crossdockingConnectionBranchSelected,
    hasCrossdockingConnection,
    sourceCrossdockingBranchSelected,
    destinationCrossdockingBranchSelected
  })


  const crossdockingPlannedFlightConnection = useWatch({
    control,
    name: "crossdocking_planned_connection_flight"
  })
  const crossdockingPlannedFlight = useWatch({
    control,
    name: "crossdocking_planned_flight"
  })
  const plannedConnectionFlight = useWatch({
    control,
    name: "planned_connection_flight"
  })
  const plannedFligth = useWatch({
    control,
    name: "planned_flight"
  })

  const dayOfWeekFromCollectDate = getDay(add(new Date(collectDate), { days: 1 }))

  const sourceCollectorSelected = useWatch({
    control,
    name: 'source_collector_id'
  })
  const sourceCrossdockingCollectorSelected = useWatch({
    control,
    name: 'crossdocking_collector_id'
  })

  const deadline = useWatch({
    control,
    name: 'deadline'
  })

  useEffect(() => {
    if (isValidCollectDate) {
      const debounce = setTimeout(() => {
        const deliveryDateSummedUpWithDeadline =
          formatDate.handle(add(new Date(collectDate), { days: 1 + Number(deadline) }), "DateWithoutHourToInput")

        setValue('delivery_date', deliveryDateSummedUpWithDeadline)
      }, 1000)
      return () => clearTimeout(debounce)
    }

  }, [deadline, collectDate, setValue, isValidCollectDate])

  useEffect(() => {
    if (!isFractionedService) {
      setValue('source_crossdocking_branch_id', undefined)
      setValue('destination_crossdocking_branch_id', undefined)
      setValue('crossdocking_connection_branch_id', undefined)
      setValue('planned_connection_flight', undefined)
      setValue('crossdocking_collector_id', undefined)
      setValue('destination_collector_id', sourceCollectorSelected)
      setValue('board_date', undefined)
      setValue('board_hour', undefined)
      setValue('availability_forecast_day', undefined)
      setValue('availability_forecast_time', undefined)
    }
  }, [
    setValue,
    isFractionedService,
    sourceCollectorSelected
  ])

  useEffect(() => {
    if (isCrossdockingService) {
      if (hasCrossdockingConnection) {
        const debounceCrossdockingWithCrossdockingConnectionDelayTimeout = setTimeout(() => {
          const existsSourceBranchToCrossdockingConnectionBranchPlannedFlight =
            findPlannedRouteInfoAndFormatAccordinglyWithModal(
              plannedRoutesFromSourceBranchToConnectionCrossdockingBranch,
              crossdockingPlannedFlightConnection,
              crossdockingModal as 'AÉREO' | 'RODOVIÁRIO',
            )
          const existsCrossdockingConnectionBranchToDestinationCrossdockingBranchPlannedFlight =
            findPlannedRouteInfoAndFormatAccordinglyWithModal(
              plannedRoutesFromConnectionCrossdockingBranchToDestinationCrossdockingBranch,
              crossdockingPlannedFlight,
              crossdockingModal as 'AÉREO' | 'RODOVIÁRIO',
            )

          const existsSourceBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
            dayOfWeekFromCollectDate,
            branches,
            sourceBranchSelected
          )

          const existsDestinationCrossdockingBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
            dayOfWeekFromCollectDate,
            branches,
            destinationCrossdockingBranchSelected
          )

          if (
            !existsSourceBranchToCrossdockingConnectionBranchPlannedFlight ||
            !existsCrossdockingConnectionBranchToDestinationCrossdockingBranchPlannedFlight
          ) {
            if (existsSourceBranchAttendanceHourOnCollectDate) {
              const {
                branchCloseHour: sourceBranchCloseHour
              } = existsSourceBranchAttendanceHourOnCollectDate

              setValue('crossdocking_board_hour', sourceBranchCloseHour)
            }

            if (existsDestinationCrossdockingBranchAttendanceHourOnCollectDate) {
              const {
                branchOpenHour: destinationCrossdockingBranchOpenHour
              } = existsDestinationCrossdockingBranchAttendanceHourOnCollectDate

              setValue(
                "crossdocking_availability_forecast_time",
                destinationCrossdockingBranchOpenHour
              )
            }
          } else {
            const {
              departureDate,
              departureHour
            } = existsSourceBranchToCrossdockingConnectionBranchPlannedFlight

            const {
              arrivalHour,
              arrivalDate
            } = existsCrossdockingConnectionBranchToDestinationCrossdockingBranchPlannedFlight

            setValue('crossdocking_board_date', departureDate)
            setValue('crossdocking_availability_forecast_day', arrivalDate)

            if (existsSourceBranchAttendanceHourOnCollectDate) {
              const {
                branchOpenHour: sourceBranchOpenHour,
                branchCloseHour: sourceBranchCloseHour
              } = existsSourceBranchAttendanceHourOnCollectDate


              const isPlannedRouteDepartureHourLessThanSourceBranchCloseHour =
                comparePlannedRouteDepartureHourIsLessThanSourceBranchCloseHour(
                  departureHour,
                  sourceBranchCloseHour,
                )

              const isPlannedRouteDepartureHourLessThanSourceBranchOpenHour =
                comparePlannedRouteDepartureHourIsLessThanSourceBranchOpenHour(
                  departureHour,
                  sourceBranchOpenHour
                )

              if (isPlannedRouteDepartureHourLessThanSourceBranchOpenHour) {
                setValue(
                  'crossdocking_board_date',
                  formatDate.handle(departureDate, 'DateWithoutHourToInput')
                )
                setValue('crossdocking_board_hour', sourceBranchCloseHour)
              } else if (isPlannedRouteDepartureHourLessThanSourceBranchCloseHour) {
                setValue('crossdocking_board_hour', departureHour)
              } else {
                setValue('crossdocking_board_hour', sourceBranchCloseHour)
              }
            } else {
              setValue('crossdocking_board_hour', departureHour)
            }

            if (existsDestinationCrossdockingBranchAttendanceHourOnCollectDate) {

              const {
                branchOpenHour: destinationCrossdockingBranchCloseHour
              } = existsDestinationCrossdockingBranchAttendanceHourOnCollectDate

              const isPlannedRouteArrivalHourBiggerThanDestinationCrossdockingBranchOpenHour =
                comparePlannedRouteArrivalHourIsBiggerThanDestinationBranchOpenHour(
                  arrivalHour,
                  destinationCrossdockingBranchCloseHour,
                )

              if (isPlannedRouteArrivalHourBiggerThanDestinationCrossdockingBranchOpenHour) {
                setValue('crossdocking_availability_forecast_time', arrivalHour)
              } else {
                setValue('crossdocking_availability_forecast_time', destinationCrossdockingBranchCloseHour)
              }
            } else {
              setValue('crossdocking_availability_forecast_time', arrivalHour)
            }
          }
        }, 1500)

        return () => clearTimeout(debounceCrossdockingWithCrossdockingConnectionDelayTimeout)
      }

      const debounceCrossdockingWithoutCrossdockingConnectionDelayTimeout = setTimeout(() => {
        setValue('crossdocking_planned_connection_flight', undefined)

        const existsSourceBranchToDestinationCrossdockingBranchPlannedFlight =
          findPlannedRouteInfoAndFormatAccordinglyWithModal(
            plannedRoutesFromSourceBranchToDestinationCrossdockingBranch,
            crossdockingPlannedFlight,
            crossdockingModal as 'AÉREO' | 'RODOVIÁRIO',
          )

        const existsSourceBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
          dayOfWeekFromCollectDate,
          branches,
          sourceBranchSelected
        )
        const existsDestinationCrossdockingBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
          dayOfWeekFromCollectDate,
          branches,
          destinationCrossdockingBranchSelected
        )

        if (!existsSourceBranchToDestinationCrossdockingBranchPlannedFlight) {
          if (existsSourceBranchAttendanceHourOnCollectDate) {
            const {
              branchCloseHour: sourceBranchCloseHour
            } = existsSourceBranchAttendanceHourOnCollectDate

            setValue('crossdocking_board_hour', sourceBranchCloseHour)
          }

          if (existsDestinationCrossdockingBranchAttendanceHourOnCollectDate) {
            const {
              branchOpenHour: destinationCrossdockingBranchOpenHour
            } = existsDestinationCrossdockingBranchAttendanceHourOnCollectDate

            setValue('availability_forecast_time', destinationCrossdockingBranchOpenHour)
          }
        } else {

          const {
            arrivalDate,
            arrivalHour,
            departureDate,
            departureHour
          } = existsSourceBranchToDestinationCrossdockingBranchPlannedFlight

          setValue('crossdocking_board_date', departureDate)
          setValue('crossdocking_availability_forecast_day', arrivalDate)

          if (existsSourceBranchAttendanceHourOnCollectDate) {
            const {
              branchOpenHour: sourceBranchOpenHour,
              branchCloseHour: sourceBranchCloseHour
            } = existsSourceBranchAttendanceHourOnCollectDate

            const isPlannedRouteDepartureHourLessThanSourceBranchCloseHour =
              comparePlannedRouteDepartureHourIsLessThanSourceBranchCloseHour(
                departureHour,
                sourceBranchCloseHour,
              )

            const isPlannedRouteDepartureHourLessThanSourceBranchOpenHour =
              comparePlannedRouteDepartureHourIsLessThanSourceBranchOpenHour(
                departureHour,
                sourceBranchOpenHour
              )

            if (isPlannedRouteDepartureHourLessThanSourceBranchOpenHour) {
              setValue(
                'crossdocking_board_date',
                formatDate.handle(departureDate, 'DateWithoutHourToInput')
              )
              setValue('crossdocking_board_hour', sourceBranchCloseHour)
            } else if (isPlannedRouteDepartureHourLessThanSourceBranchCloseHour) {
              setValue('crossdocking_board_hour', departureHour)
            } else {
              setValue('crossdocking_board_hour', sourceBranchCloseHour)
            }
          } else {
            setValue('crossdocking_board_hour', departureHour)
          }


          if (existsDestinationCrossdockingBranchAttendanceHourOnCollectDate) {
            const {
              branchOpenHour: destinationCrossdockingBranchOpenHour
            } = existsDestinationCrossdockingBranchAttendanceHourOnCollectDate

            const isPlannedRouteArrivalHourBiggerThanDestinationCrossdockingBranchOpenHour =
              comparePlannedRouteArrivalHourIsBiggerThanDestinationBranchOpenHour(
                arrivalHour,
                destinationCrossdockingBranchOpenHour,
              )

            if (isPlannedRouteArrivalHourBiggerThanDestinationCrossdockingBranchOpenHour) {
              setValue('crossdocking_availability_forecast_time', arrivalHour)
            } else {
              setValue('crossdocking_availability_forecast_time', destinationCrossdockingBranchOpenHour)
            }
          } else {
            setValue('crossdocking_availability_forecast_time', arrivalHour)
          }
        }
      }, 1500)

      return () => clearTimeout(debounceCrossdockingWithoutCrossdockingConnectionDelayTimeout)
    }
  }, [
    plannedRoutesFromSourceBranchToDestinationCrossdockingBranch,
    plannedRoutesFromSourceBranchToConnectionCrossdockingBranch,
    plannedRoutesFromConnectionCrossdockingBranchToDestinationCrossdockingBranch,
    sourceBranchSelected,
    isCrossdockingService,
    hasCrossdockingConnection,
    branches,
    crossdockingModal,
    crossdockingPlannedFlight,
    dayOfWeekFromCollectDate,
    destinationCrossdockingBranchSelected,
    crossdockingPlannedFlightConnection,
    setValue
  ])

  useEffect(() => {
    if (isCrossdockingService) {
      if (hasConnection) {
        const debounceCrossdockingWithConnectionDelayTimeout = setTimeout(() => {
          const existsSourceCrossdockingBranchToConnectionBranchPlannedFlight =
            findPlannedRouteInfoAndFormatAccordinglyWithModal(
              plannedRoutesFromSourceCrossdockingBranchToConnectionBranch,
              plannedConnectionFlight,
              modal as 'AÉREO' | 'RODOVIÁRIO',
            )

          const existsConnectionBranchToDestinationBranchPlannedFlight =
            findPlannedRouteInfoAndFormatAccordinglyWithModal(
              plannedRoutesFromConnectionBranchToDestinationBranch,
              plannedFligth,
              modal as 'AÉREO' | 'RODOVIÁRIO',
            )

          const existsSourceCrossdockingBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
            dayOfWeekFromCollectDate,
            branches,
            sourceCrossdockingBranchSelected
          )

          const existsDestinationBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
            dayOfWeekFromCollectDate,
            branches,
            destinationBranchSelected
          )

          if (
            !existsSourceCrossdockingBranchToConnectionBranchPlannedFlight ||
            !existsConnectionBranchToDestinationBranchPlannedFlight
          ) {

            if (existsSourceCrossdockingBranchAttendanceHourOnCollectDate) {
              const {
                branchCloseHour: sourceCrossdockingBranchCloseHour
              } = existsSourceCrossdockingBranchAttendanceHourOnCollectDate

              setValue('board_hour', sourceCrossdockingBranchCloseHour)
            }

            if (existsDestinationBranchAttendanceHourOnCollectDate) {
              const {
                branchOpenHour: destinationBranchCloseHour
              } = existsDestinationBranchAttendanceHourOnCollectDate

              setValue('availability_forecast_time', destinationBranchCloseHour)
            }
          } else {
            const {
              departureDate,
              departureHour
            } = existsConnectionBranchToDestinationBranchPlannedFlight

            const {
              arrivalDate,
              arrivalHour
            } = existsSourceCrossdockingBranchToConnectionBranchPlannedFlight


            setValue('board_date', departureDate)
            setValue('availability_forecast_day', arrivalDate)

            if (existsSourceCrossdockingBranchAttendanceHourOnCollectDate) {
              const {
                branchOpenHour: sourceCrossdockingBranchOpenHour,
                branchCloseHour: sourceCrossdockingBranchCloseHour
              } = existsSourceCrossdockingBranchAttendanceHourOnCollectDate

              const isPlannedConnectionRouteDepartureHourLessThanSourceCrossdockingBranchCloseHour =
                comparePlannedRouteDepartureHourIsLessThanSourceBranchCloseHour(
                  departureHour,
                  sourceCrossdockingBranchCloseHour,
                )

              const isPlannedConnectionRouteDepartureHourLessThanSourceCrossdockingBranchOpenHour =
                comparePlannedRouteDepartureHourIsLessThanSourceBranchOpenHour(
                  departureHour,
                  sourceCrossdockingBranchOpenHour
                )

              if (isPlannedConnectionRouteDepartureHourLessThanSourceCrossdockingBranchOpenHour) {
                setValue(
                  'board_date',
                  formatDate.handle(departureDate, 'DateWithoutHourToInput')
                )
                setValue('board_hour', sourceCrossdockingBranchCloseHour)
              } else if (isPlannedConnectionRouteDepartureHourLessThanSourceCrossdockingBranchCloseHour) {
                setValue('board_hour', departureHour)
              } else {
                setValue('board_hour', sourceCrossdockingBranchOpenHour)
              }
            } else {
              setValue('board_hour', departureHour)
            }

            if (existsDestinationBranchAttendanceHourOnCollectDate) {

              const {
                branchOpenHour: destinationBranchOpenHour
              } = existsDestinationBranchAttendanceHourOnCollectDate


              const isPlannedConnectionRouteArrivalHourBiggerThanDestinationBranchCloseHour =
                comparePlannedRouteArrivalHourIsBiggerThanDestinationBranchOpenHour(
                  arrivalHour,
                  destinationBranchOpenHour,
                )

              if (isPlannedConnectionRouteArrivalHourBiggerThanDestinationBranchCloseHour) {
                setValue('availability_forecast_time', arrivalHour)
              } else {
                setValue('availability_forecast_time', destinationBranchOpenHour)
              }

            } else {
              setValue('availability_forecast_time', arrivalHour)
            }
          }
        }, 1500)

        return () => clearTimeout(debounceCrossdockingWithConnectionDelayTimeout)
      }

      const debounceCrossdockingWithoutConnectionDelayTimeout = setTimeout(() => {
        setValue('planned_connection_flight', undefined)
        const existsSourceCrossdockingBranchToDestinationBranchPlannedFlight =
          findPlannedRouteInfoAndFormatAccordinglyWithModal(
            plannedRoutesFromSourceCrossdockingBranchToDestinationBranch,
            plannedFligth,
            modal as 'AÉREO' | 'RODOVIÁRIO',
          )

        const existsSourceCrossdockingBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
          dayOfWeekFromCollectDate,
          branches,
          sourceCrossdockingBranchSelected
        )

        const existsDestinationBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
          dayOfWeekFromCollectDate,
          branches,
          destinationBranchSelected
        )

        if (!existsSourceCrossdockingBranchToDestinationBranchPlannedFlight) {
          if (existsSourceCrossdockingBranchAttendanceHourOnCollectDate) {
            const {
              branchCloseHour: sourceCrossdockingBranchCloseHour
            } = existsSourceCrossdockingBranchAttendanceHourOnCollectDate

            setValue('board_hour', sourceCrossdockingBranchCloseHour)
          }

          if (existsDestinationBranchAttendanceHourOnCollectDate) {
            const {
              branchOpenHour: destinationBranchOpenHour
            } = existsDestinationBranchAttendanceHourOnCollectDate

            setValue('availability_forecast_time', destinationBranchOpenHour)
          }
        } else {
          const {
            arrivalDate,
            arrivalHour,
            departureDate,
            departureHour
          } = existsSourceCrossdockingBranchToDestinationBranchPlannedFlight

          setValue('board_date', departureDate)
          setValue('availability_forecast_day', arrivalDate)

          if (existsSourceCrossdockingBranchAttendanceHourOnCollectDate) {
            const {
              branchOpenHour: sourceCrossdockingBranchOpenHour,
              branchCloseHour: sourceCrossdockingBranchCloseHour
            } = existsSourceCrossdockingBranchAttendanceHourOnCollectDate

            const isPlannedRouteDepartureHourLessThanSourceCrossdockingBranchCloseHour =
              comparePlannedRouteDepartureHourIsLessThanSourceBranchCloseHour(
                departureHour,
                sourceCrossdockingBranchCloseHour,
              )

            const isPlannedRouteDepartureHourLessThanSourceCrossdockingBranchOpenHour =
              comparePlannedRouteDepartureHourIsLessThanSourceBranchOpenHour(
                departureHour,
                sourceCrossdockingBranchOpenHour
              )

            if (isPlannedRouteDepartureHourLessThanSourceCrossdockingBranchOpenHour) {
              setValue(
                'board_date',
                formatDate.handle(departureDate, 'DateWithoutHourToInput')
              )
              setValue('board_hour', sourceCrossdockingBranchCloseHour)
            } else if (isPlannedRouteDepartureHourLessThanSourceCrossdockingBranchCloseHour) {
              setValue('board_hour', departureHour)
            } else {
              setValue('board_hour', sourceCrossdockingBranchCloseHour)
            }
          } else {
            setValue('board_hour', departureHour)
          }
          if (existsDestinationBranchAttendanceHourOnCollectDate) {

            const {
              branchOpenHour: destinationBranchOpenHour
            } = existsDestinationBranchAttendanceHourOnCollectDate


            const isPlannedConnectionRouteArrivalHourBiggerThanDestinationBranchCloseHour =
              comparePlannedRouteArrivalHourIsBiggerThanDestinationBranchOpenHour(
                arrivalHour,
                destinationBranchOpenHour,
              )

            if (isPlannedConnectionRouteArrivalHourBiggerThanDestinationBranchCloseHour) {
              setValue('availability_forecast_time', arrivalHour)
            } else {
              setValue('availability_forecast_time', destinationBranchOpenHour)
            }

          } else {
            setValue('availability_forecast_time', arrivalHour)
          }
        }
      }, 1500)

      return () => clearTimeout(debounceCrossdockingWithoutConnectionDelayTimeout)
    }
  }, [
    plannedRoutesFromSourceCrossdockingBranchToDestinationBranch,
    plannedRoutesFromConnectionBranchToDestinationBranch,
    plannedRoutesFromSourceCrossdockingBranchToConnectionBranch,
    branches,
    sourceCrossdockingBranchSelected,
    dayOfWeekFromCollectDate,
    destinationBranchSelected,
    isCrossdockingService,
    hasConnection,
    modal,
    plannedFligth,
    plannedConnectionFlight,
    setValue
  ])

  useEffect(() => {
    if (!isCrossdockingService) {
      setValue('source_crossdocking_branch_id', undefined)
      setValue('destination_crossdocking_branch_id', undefined)
      setValue('crossdocking_connection_branch_id', undefined)
      setValue('crossdocking_collector_id', undefined)
      setValue('crossdocking_board_date', undefined)
      setValue('crossdocking_board_hour', undefined)
      setValue('crossdocking_availability_forecast_day', undefined)
      setValue('crossdocking_availability_forecast_time', undefined)

      if (hasConnection) {
        const debounceWithConnectionDelayTimeout = setTimeout(() => {
          const existsSourceBranchToConnectionBranchPlannedFlight =
            findPlannedRouteInfoAndFormatAccordinglyWithModal(
              plannedRoutesFromSourceBranchToConnectionBranch,
              plannedConnectionFlight,
              modal as 'AÉREO' | 'RODOVIÁRIO',
            )

          const existsConnectionBranchToDestinationBranchPlannedFlight =
            findPlannedRouteInfoAndFormatAccordinglyWithModal(
              plannedRoutesFromConnectionBranchToDestinationBranch,
              plannedFligth,
              modal as 'AÉREO' | 'RODOVIÁRIO',
            )

          const existsSourceBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
            dayOfWeekFromCollectDate,
            branches,
            sourceBranchSelected
          )

          const existsDestinationBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
            dayOfWeekFromCollectDate,
            branches,
            destinationBranchSelected
          )

          if (
            !existsSourceBranchToConnectionBranchPlannedFlight ||
            !existsConnectionBranchToDestinationBranchPlannedFlight
          ) {
            if (existsSourceBranchAttendanceHourOnCollectDate) {
              const {
                branchCloseHour: sourceBranchCloseHour,
              } = existsSourceBranchAttendanceHourOnCollectDate

              setValue('board_hour', sourceBranchCloseHour)
            }

            if (existsDestinationBranchAttendanceHourOnCollectDate) {
              const {
                branchOpenHour: destinationBranchOpenHour
              } = existsDestinationBranchAttendanceHourOnCollectDate

              setValue('availability_forecast_time', destinationBranchOpenHour)
            }
          } else {

            const {
              departureDate,
              departureHour
            } = existsSourceBranchToConnectionBranchPlannedFlight

            const {
              arrivalDate,
              arrivalHour
            } = existsConnectionBranchToDestinationBranchPlannedFlight

            setValue('board_date', departureDate)
            setValue('availability_forecast_day', arrivalDate)

            if (existsSourceBranchAttendanceHourOnCollectDate) {
              const {
                branchOpenHour: sourceBranchOpenHour,
                branchCloseHour: sourceBranchCloseHour
              } = existsSourceBranchAttendanceHourOnCollectDate

              const isPlannedConnectionRouteDepartureHourLessThanSourceBranchCloseHour =
                comparePlannedRouteDepartureHourIsLessThanSourceBranchCloseHour(
                  departureHour,
                  sourceBranchCloseHour,
                )

              const isPlannedConnectionRouteDepartureHourLessThanSourceBranchOpenHour =
                comparePlannedRouteDepartureHourIsLessThanSourceBranchOpenHour(
                  departureHour,
                  sourceBranchOpenHour
                )

              if (isPlannedConnectionRouteDepartureHourLessThanSourceBranchOpenHour) {
                setValue(
                  'board_date',
                  formatDate.handle(departureDate, 'DateWithoutHourToInput')
                )
                setValue('board_hour', sourceBranchCloseHour)
              } else if (isPlannedConnectionRouteDepartureHourLessThanSourceBranchCloseHour) {
                setValue('board_hour', departureHour)
              } else {
                setValue('board_hour', sourceBranchCloseHour)
              }
            } else {
              setValue('board_hour', departureHour)
            }

            if (existsDestinationBranchAttendanceHourOnCollectDate) {
              const {
                branchOpenHour: destinationBranchOpenHour
              } = existsDestinationBranchAttendanceHourOnCollectDate


              const isPlannedConnectionRouteArrivalHourBiggerThanDestinationBranchOpenHour =
                comparePlannedRouteArrivalHourIsBiggerThanDestinationBranchOpenHour(
                  arrivalHour,
                  destinationBranchOpenHour,
                )

              if (isPlannedConnectionRouteArrivalHourBiggerThanDestinationBranchOpenHour) {
                setValue('availability_forecast_time', arrivalHour)
              } else {
                setValue('availability_forecast_time', destinationBranchOpenHour)
              }

            } else {
              setValue('availability_forecast_time', arrivalHour)
            }

          }
        }, 1500)
        return () => clearTimeout(debounceWithConnectionDelayTimeout)
      }

      const debounceWithoutConnectionDelayTimeout = setTimeout(() => {
        setValue('planned_connection_flight', undefined)

        const existsSourceBranchToDestinationBranchPlannedFlight =
          findPlannedRouteInfoAndFormatAccordinglyWithModal(
            plannedRoutesWithoutCrossdockingAndConnection,
            plannedFligth,
            modal as 'AÉREO' | 'RODOVIÁRIO',
          )

        const existsSourceBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
          dayOfWeekFromCollectDate,
          branches,
          sourceBranchSelected
        )

        const existsDestinationBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
          dayOfWeekFromCollectDate,
          branches,
          destinationBranchSelected
        )

        if (!existsSourceBranchToDestinationBranchPlannedFlight) {
          if (existsSourceBranchAttendanceHourOnCollectDate) {
            const {
              branchCloseHour: sourceBranchCloseHour
            } = existsSourceBranchAttendanceHourOnCollectDate

            setValue('board_hour', sourceBranchCloseHour)
          }

          if (existsDestinationBranchAttendanceHourOnCollectDate) {
            const {
              branchOpenHour: destinationBranchOpenHour,
            } = existsDestinationBranchAttendanceHourOnCollectDate

            setValue('availability_forecast_time', destinationBranchOpenHour)
          }
        } else {

          const {
            arrivalDate,
            arrivalHour,
            departureDate,
            departureHour
          } = existsSourceBranchToDestinationBranchPlannedFlight

          setValue('board_date', departureDate)
          setValue('availability_forecast_day', arrivalDate)

          if (existsSourceBranchAttendanceHourOnCollectDate) {
            const {
              branchOpenHour: sourceBranchOpenHour,
              branchCloseHour: sourceBranchCloseHour
            } = existsSourceBranchAttendanceHourOnCollectDate

            const isPlannedRouteDepartureHourLessThanSourceBranchCloseHour =
              comparePlannedRouteDepartureHourIsLessThanSourceBranchCloseHour(
                departureHour,
                sourceBranchCloseHour,
              )

            const isPlannedRouteDepartureHourLessThanSourceBranchOpenHour =
              comparePlannedRouteDepartureHourIsLessThanSourceBranchOpenHour(
                departureHour,
                sourceBranchOpenHour
              )

            if (isPlannedRouteDepartureHourLessThanSourceBranchOpenHour) {
              setValue(
                'board_date',
                formatDate.handle(departureDate, 'DateWithoutHourToInput')
              )
              setValue('board_hour', sourceBranchCloseHour)
            } else if (isPlannedRouteDepartureHourLessThanSourceBranchCloseHour) {
              setValue('board_hour', departureHour)
            } else {
              setValue('board_hour', sourceBranchCloseHour)
            }
          } else {
            setValue('board_hour', departureHour)
          }

          if (existsDestinationBranchAttendanceHourOnCollectDate) {
            const {
              branchOpenHour: destinationBranchOpenHour
            } = existsDestinationBranchAttendanceHourOnCollectDate

            const isPlannedRouteArrivalHourBiggerThanDestinationBranchOpenHour =
              comparePlannedRouteArrivalHourIsBiggerThanDestinationBranchOpenHour(
                arrivalHour,
                destinationBranchOpenHour,
              )

            if (isPlannedRouteArrivalHourBiggerThanDestinationBranchOpenHour) {
              setValue('availability_forecast_time', arrivalHour)
            } else {
              setValue('availability_forecast_time', destinationBranchOpenHour)
            }
          } else {
            setValue('availability_forecast_time', arrivalHour)
          }
        }
      }, 1500)

      return () => clearTimeout(debounceWithoutConnectionDelayTimeout)
    }
  }, [
    plannedRoutesWithoutCrossdockingAndConnection,
    plannedRoutesFromConnectionBranchToDestinationBranch,
    plannedRoutesFromSourceBranchToConnectionBranch,
    branches,
    sourceBranchSelected,
    dayOfWeekFromCollectDate,
    connectionBranchSelected,
    destinationCrossdockingBranchSelected,
    hasConnection,
    isCrossdockingService,
    plannedConnectionFlight,
    plannedFligth,
    setValue,
    destinationBranchSelected,
    modal
  ])

  const materialType = useWatch({
    control,
    name: 'material_type',
  })

  const destinationAddressesInputs = useWatch({
    control,
    name: 'destination_addresses_input'
  })

  useEffect(() => {
    if (materialType === 'CARGA GERAL' || materialType === 'CORRELATOS') {
      if (!destinationAddressesInputs) {
        if (requestedServiceInfo.destination_addresses_input) {
          requestedServiceInfo.destination_addresses_input
            .map(destinationAddressInput => JSON.parse(destinationAddressInput) as DestinationAddressInput)
            .forEach((destinationAddressInput) => {
              appendDestinationAddressesInputFields({
                address_id: destinationAddressInput.address_id,
                quantity: destinationAddressInput.quantity
              })
            })
        } else {
          destinationAddressesSelected?.forEach((destinationAddress, index) => {
            appendDestinationAddressesInputFields({
              address_id: destinationAddress,
              quantity: 0
            })
          })
        }
      }
    } else {
      setValue('destination_addresses_input', undefined)
    }
  }, [
    appendDestinationAddressesInputFields,
    requestedServiceInfo,
    destinationAddressesSelected,
    materialType,
    destinationAddressesInputs,
    setValue
  ])

  const franchising = watch("franchising")

  const {
    data: transferCostEstimateResult
  } = useQuery({
    queryKey: [
      'transfer-cost-estimate',
      sourceBranchSelected,
      destinationBranchSelected,
      sourceShippingId,
      franchising,
      isFractionedService
    ],
    queryFn: () => getTransferCostEstimate({
      sourceBranchId: sourceBranchSelected,
      destinationBranchId: destinationBranchSelected,
      shippingId: sourceShippingId,
      weight: franchising
    }),
    enabled: !!sourceBranchSelected && !!destinationBranchSelected && !!sourceShippingId && !!franchising && isFractionedService
  })

  const {
    data: transferCostCrossdockingEstimateResult
  } = useQuery({
    queryKey: [
      'transfer-cost-estimate-crossdocking',
      sourceCrossdockingBranchSelected,
      destinationBranchSelected,
      destinationShippingId,
      franchising,
      isFractionedService,
      isCrossdockingService
    ],
    queryFn: () => getTransferCostEstimate({
      sourceBranchId: sourceCrossdockingBranchSelected,
      destinationBranchId: destinationCrossdockingBranchSelected,
      shippingId: destinationShippingId,
      weight: franchising
    }),
    enabled: !!sourceCrossdockingBranchSelected && !!destinationBranchSelected && !!destinationShippingId && !!franchising && isFractionedService && isCrossdockingService
  })


  const {
    data: servicesValidatedWithSameModalResult,
    isLoading: isLoadingServicesValidatedWithSameModalResult
  } = useQuery({
    queryKey: [
      'services-validated-with-same-modal',
      budgetInfo,
      sourceCollectorSelected,
      modal,
      isFractionedService
    ],
    queryFn: () => getServicesValidatedWithSameModal({
      sourceHubId: budgetInfo?.source_hub_id,
      collectorId: sourceCollectorSelected,
      destinationHubId: budgetInfo?.destination_hub_id,
      modal: modal
    }),
    enabled: !!budgetInfo && !!sourceCollectorSelected && !!modal && !!isFractionedService
  })

  const {
    data: servicesCrossdockingValidatedWithSameModalFirstBoardResult,
    isLoading: isLoadingServicesCrossdockingValidatedWithSameModalFirstBoardResult
  } = useQuery({
    queryKey: [
      'services-crossdocking-validated-with-same-modal-first-board',
      budgetInfo,
      sourceCollectorSelected,
      modal,
      isFractionedService,
      isCrossdockingService
    ],
    queryFn: () => getServicesValidatedWithSameModal({
      sourceHubId: budgetInfo?.source_hub_id,
      collectorId: sourceCollectorSelected,
      destinationHubId: budgetInfo?.crossdocking_hub_id,
      modal: modal
    }),
    enabled: !!budgetInfo && !!sourceCollectorSelected && !!modal && isFractionedService && isCrossdockingService
  })

  const {
    data: servicesCrossdockingValidatedWithSameModalSecondBoardResult,
    isLoading: isLoadingServicesCrossdockingValidatedWithSameModalSecondBoardResult
  } = useQuery({
    queryKey: [
      'services-crossdocking-validated-with-same-modal-second-board',
      budgetInfo,
      sourceCrossdockingCollectorSelected,
      crossdockingModal,
      isFractionedService,
      isCrossdockingService
    ],
    queryFn: () => getServicesValidatedWithSameModal({
      sourceHubId: budgetInfo?.crossdocking_hub_id,
      collectorId: sourceCrossdockingCollectorSelected,
      destinationHubId: budgetInfo?.destination_hub_id,
      modal: crossdockingModal
    }),
    enabled: !!budgetInfo && !!sourceCrossdockingCollectorSelected && !!crossdockingModal && isFractionedService && isCrossdockingService
  })

  const servicesValidatedWithSameModal = servicesValidatedWithSameModalResult && removeDuplicatesServices(servicesValidatedWithSameModalResult?.services)

  const servicesValidatedWithSameModalFiltered = filterServicesByCollectDate(servicesValidatedWithSameModal || [], collectDate)

  const servicesCrossdockingValidatedWithSameModalFirstBoard = servicesCrossdockingValidatedWithSameModalFirstBoardResult && removeDuplicatesServices(servicesCrossdockingValidatedWithSameModalFirstBoardResult?.services)

  const servicesCrossdockingValidatedWithSameModalFirstBoardFiltered = filterServicesByCollectDate(servicesCrossdockingValidatedWithSameModalFirstBoard || [], collectDate)

  const servicesCrossdockingValidatedWithSameModalSecondBoard = servicesCrossdockingValidatedWithSameModalSecondBoardResult && removeDuplicatesServices(servicesCrossdockingValidatedWithSameModalSecondBoardResult?.services)

  const servicesCrossdockingValidatedWithSameModalSecondBoardFiltered = filterServicesByCollectDate(servicesCrossdockingValidatedWithSameModalSecondBoard || [], collectDate)


  if (isFetchingCustomer || isFetchingBudget || isFetchingCities || isCollectorsFetching || isLoadingPrimaryCollectorCosts || isBranchesFetching || requestedServiceInfo.isFetching) {
    return <Spinner />
  }

  return (
    <Flex
      gap={6}
      direction="column"
      align="center"
      justify="center"
    >
      <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
        <Input
          {...register("protocol")}
          name="protocol"
          label="Protocolo"
          isDisabled={true}
        />
        <Input
          {...register("customer_id")}
          name="customer_id"
          label="Pertence ao cliente"
          isDisabled={true}
          error={errors.customer_id}
          required
        />
      </Stack>
      <Input
        {...register("budget_id")}
        error={errors.budget_id}
        name="budget_id"
        label="Orçamento"
        isDisabled={true}
        required
      />

      <AddressesCheckboxInputsGroup
        isEditPage={isEditPage}
        sourceAddresses={sourceAddresses}
        destinationAddresses={destinationAddresses}
        defaultSelectedSourceAddresses={defaultSelectedSourceAddresses}
        defaultSelectedDestinationAddresses={defaultSelectedDestinationAddresses}
        isAddressesFetching={isAddressesFetching}
      />

      <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
        <Input
          {...register("has_crossdocking")}
          name="has_crossdocking"
          label="Possui crossdocking?"
          error={errors.has_crossdocking}
          isDisabled={true}
        />

        {isCrossdockingService && (
          <Select
            {...register('shipping_id')}
            name='shipping_id'
            label='Transportadora crossdocking'
            placeholder="Selecione uma opção..."
            error={errors.shipping_id}
            isDisabled={isShippingFetching}
            options={shippingOptions}
            required
          />
        )}
      </Stack>

      <CollectorsInputsGroup
        sourceCollectorsOptions={sourceCollectorsOptions}
        crossdockingCollectorsOptions={crossdockingCollectorsOptions}
        destinationCollectorsOptions={destinationCollectorsOptions}
        isCrossdockingService={isCrossdockingService}
        isFractionedService={isFractionedService}
        isLoading={isCollectorsFetching}
        isDisabled={!isUserAuthorizedToEditServiceCollectors}
      />

      {isFractionedService && (
        <BranchesInputsGroups
          isBranchesFetching={isBranchesFetching}
          sourceBranchesOptions={sourceBranchesOptions}
          isCrossdockingService={isCrossdockingService}
          destinationBranchesOptions={destinationBranchesOptions}
          sourceCrossdockingBranchesOptions={sourceCrossdockingBranchesOptions}
          destinationCrossdockingBranchesOptions={destinationCrossdockingBranchesOptions}
          isDisabled={!isUserAuthorizedToEditServiceBranches}
        />
      )}

      {isFractionedService && (
        <Stack
          direction={["column", "column", "row"]}
          spacing={6}
          w="full"
        >
          {isCrossdockingService && isAirCrossdockingModal && (
            <Select
              {...register("has_crossdocking_connection")}
              name="has_crossdocking_connection"
              options={positiveNegativeOptions}
              label="Possui conexão crossdocking?"
              placeholder="Selecione uma opção..."
              isDisabled={!isUserAuthorizedToEditServiceBranches}
              required
            />
          )}

          {isAirModal && (
            <Select
              {...register("has_connection")}
              name="has_connection"
              options={positiveNegativeOptions}
              label="Possui conexão?"
              placeholder="Selecione uma opção..."
              isDisabled={!isUserAuthorizedToEditServiceBranches}
              required
            />
          )}
        </Stack>
      )}

      {(hasConnection || hasCrossdockingConnection) && (
        <ConnectionBranchesInputsGroup
          isAirModal={isAirModal}
          hasConnection={hasConnection}
          isBranchesFetching={isBranchesFetching}
          isCrossdockingService={isCrossdockingService}
          isCrossdockingAirModal={isAirCrossdockingModal}
          hasCrossdockingConnection={hasCrossdockingConnection}
          connectionBranchesOptions={connectionBranchesOptions}
          crossdockingConnectionBranchesOptions={crossdockingConnectionBranchesOptions}
          isDisabled={!isUserAuthorizedToEditServiceBranches}
        />
      )}

      <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
        <Select
          {...register('deadline')}
          name='deadline'
          placeholder="Selecione uma opção..."
          deadline_delivery={deadline_delivery}
          label="Prazo de entrega"
          error={errors.deadline}
          required
        />
        <Select
          {...register('service_type')}
          name='service_type'
          placeholder="Selecione uma opção..."
          service_types={service_types}
          label="Tipo de serviço"
          error={errors.service_type}
          required
        />
      </Stack>

      <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
        {isCrossdockingService && (
          <Select
            {...register('crossdocking_modal')}
            name='crossdocking_modal'
            placeholder="Selecione uma opção..."
            modal_types={modal_types}
            label="Modal crossdocking"
            error={errors.crossdocking_modal}
            required
          />
        )}
        <Select
          {...register('modal')}
          name='modal'
          placeholder="Selecione uma opção..."
          modal_types={modal_types}
          label={isCrossdockingService ? "Modal (segundo embarque)" : "Modal"}
          error={errors.modal}
          required
        />
      </Stack>

      {isFractionedService && (
        <PlannedRoutesInputsGroup
          isDisabled={!isUserAuthorizedToEditServiceBranches}
          hasConnection={hasConnection}
          isCrossdockingService={isCrossdockingService}
          isFetchingRouteNetworks={isFetchingServiceRouteNetworksData}
          hasCrossdockingConnection={hasCrossdockingConnection}
          plannedRoutesWithoutCrossdockingAndConnection={plannedRoutesWithoutCrossdockingAndConnection}
          plannedRoutesFromSourceBranchToConnectionBranch={plannedRoutesFromSourceBranchToConnectionBranch}
          plannedRoutesFromConnectionBranchToDestinationBranch={plannedRoutesFromConnectionBranchToDestinationBranch}
          plannedRoutesFromSourceBranchToConnectionCrossdockingBranch={plannedRoutesFromSourceBranchToConnectionCrossdockingBranch}
          plannedRoutesFromSourceCrossdockingBranchToConnectionBranch={plannedRoutesFromSourceCrossdockingBranchToConnectionBranch}
          plannedRoutesFromSourceBranchToDestinationCrossdockingBranch={plannedRoutesFromSourceBranchToDestinationCrossdockingBranch}
          plannedRoutesFromSourceCrossdockingBranchToDestinationBranch={plannedRoutesFromSourceCrossdockingBranchToDestinationBranch}
          plannedRoutesFromConnectionCrossdockingBranchToDestinationCrossdockingBranch={plannedRoutesFromConnectionCrossdockingBranchToDestinationCrossdockingBranch}
        />
      )}
      {isFractionedService && (
        <Flex w='full' >
          <Accordion allowMultiple w='full'>
            <AccordionItem>
              <h2>
                <AccordionButton>

                  <Box flex="1" textAlign="left">
                    Embarques
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {(isLoadingServicesValidatedWithSameModalResult || isLoadingServicesCrossdockingValidatedWithSameModalFirstBoardResult || isLoadingServicesCrossdockingValidatedWithSameModalSecondBoardResult) && (
                  <Spinner />
                )}

                <Table size="sm" w="full" >

                  <Thead>
                    <Tr
                      h="40px"
                    >
                      <Th></Th>
                      <Th color="blue.500">Protocolo</Th>
                      <Th color="blue.500">Cliente</Th>
                      <Th color="blue.500">Data limite de embarque</Th>
                      <Th color="blue.500">Hora limite de embarque</Th>
                      <Th color="blue.500">Transportadora</Th>
                      <Th color="blue.500">Base de Origem</Th>
                      <Th color="blue.500">Base destino</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {servicesValidatedWithSameModalResult && servicesValidatedWithSameModalFiltered?.sort(sortServicesValidatedWithSameModal)?.map(service => {
                      return (
                        <ServicesValidatedWithSameModalTableRow
                          key={service.id}
                          service={service}
                        />
                      )
                    })}
                    {servicesCrossdockingValidatedWithSameModalFirstBoardResult && servicesCrossdockingValidatedWithSameModalFirstBoardFiltered?.sort(sortServicesValidatedWithSameModal)?.map(service => {
                      return (
                        <ServicesValidatedWithSameModalTableRow
                          key={service.id}
                          service={service}
                        />
                      )
                    })}
                    {servicesCrossdockingValidatedWithSameModalSecondBoardResult && servicesCrossdockingValidatedWithSameModalSecondBoardFiltered?.sort(sortServicesValidatedWithSameModal)?.map(service => {
                      return (
                        <ServicesValidatedWithSameModalTableRow
                          key={service.id}
                          service={service}
                        />
                      )
                    })}
                  </Tbody>
                </Table>


              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Flex>
      )}

      <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
        <Select
          {...register("vehicle")}
          name="vehicle"
          label="Veículo"
          vehicle_types={vehicle_types}
          placeholder="Selecione uma opção..."
          error={errors.vehicle}
          required
        />
      </Stack>

      <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
        <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
          <Input
            {...register("franchising")}
            name="franchising"
            label="Franquia"
            type="number"
            addChildren="KG"
            error={errors.vehicle}
            required
            isDisabled={true}
          />
          <Input
            {...register("transfer_budget")}
            name="transfer_budget"
            label="Budget de transferência de orçamento"
            type="number"
            error={errors.transfer_budget}
            required
            isDisabled={true}
          />
        </Stack>
        {isFractionedService && (
          <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
            <Input
              name="transfer_cost_estimate"
              label="Estimativa do valor de trasnferência"
              placeholder={transferCostEstimateResult?.shippingCostWeight ? String(transferCostEstimateResult?.shippingCostWeight.price_in_cents / 100) : 'N/a'}
              type="number"
              required
              isDisabled={true}
            />
            {isCrossdockingService && (
              <Input
                name="transfer_cost_estimate_crossdocking"
                label="Estimativa do valor de trasnferência crossdocking"
                placeholder={transferCostCrossdockingEstimateResult?.shippingCostWeight ? String(transferCostCrossdockingEstimateResult?.shippingCostWeight.price_in_cents / 100) : 'N/a'}
                type="number"
                required
                isDisabled={true}
              />
            )}
          </Stack>
        )}
      </Stack>

      <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
        <Input
          {...register("caixa_termica")}
          name="caixa_termica"
          label="Caixa térmica"
          type="number"
          error={errors.caixa_termica}
          required
          isDisabled={editFinishedService}
        />
        <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
          <Input
            {...register("gelo_seco")}
            name="gelo_seco"
            label="Gelo seco"
            type="number"
            error={errors.gelo_seco}
            required
            isDisabled={editFinishedService}
          />
          <Input
            {...register("gelox")}
            name="gelox"
            label="Gelox"
            type="number"
            error={errors.gelox}
            required
            isDisabled={editFinishedService}
          />
        </Stack>
      </Stack>
      <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
        <Input
          {...register("isopor3l")}
          name="isopor3l"
          label="Isopor 3l"
          type="number"
          error={errors.isopor3l}
          required
          isDisabled={editFinishedService}
        />
        <Input
          {...register("isopor7l")}
          name="isopor7l"
          label="Isopor 7l"
          type="number"
          error={errors.isopor7l}
          required
          isDisabled={editFinishedService}
        />
        <Input
          {...register("terciaria3l")}
          name="terciaria3l"
          label="Terciária 3l"
          type="number"
          error={errors.terciaria3l}
          required
          isDisabled={editFinishedService}
        />
        <Input
          {...register("terciaria8l")}
          name="terciaria8l"
          label="Terciária 8l"
          type="number"
          error={errors.terciaria8l}
          required
          isDisabled={editFinishedService}
        />
      </Stack>
      <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
        <Input
          {...register("embalagem_secundaria")}
          name="embalagem_secundaria"
          label="Embalagem secundária"
          type="number"
          error={errors.embalagem_secundaria}
          required
          isDisabled={editFinishedService}
        />
      </Stack>

      <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
        <Input
          {...register('collect_date')}
          name="collect_date"
          type="date"
          label="Data da coleta"
          isDisabled={!isUserAuthorizedToEditServiceSchedule}
          required
        />
        <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
          <Input
            {...register('collect_hour_start')}
            name="collect_hour_start"
            type="time"
            label="Horário inicial da coleta"
            error={errors.collect_hour_start}
            isDisabled={!isUserAuthorizedToEditServiceSchedule}
            required
          />
          <Input
            {...register('collect_hour_end')}
            name="collect_hour_end"
            type="time"
            label="Horário final da coleta"
            error={errors.collect_hour_end}
            isDisabled={!isUserAuthorizedToEditServiceSchedule}
            required
          />
        </Stack>
      </Stack>

      {geloSecoQuantity > 0 && (
        <Select
          {...register("provider_id")}
          name="provider_id"
          placeholder="Selecione uma opção..."
          isDisabled={isFetchingProviders}
          options={providersOptions}
          label="Fornecedor de gelo seco"
          required
        />
      )}

      <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
        <Input
          {...register('delivery_date')}
          name="delivery_date"
          type="date"
          label="Data da entrega"
          error={errors.delivery_date}
          isDisabled={!isUserAuthorizedToEditServiceSchedule}
          required
        />
        <Input
          {...register('delivery_hour')}
          name="delivery_hour"
          type="time"
          label="Horário da entrega"
          error={errors.delivery_hour}
          isDisabled={!isUserAuthorizedToEditServiceSchedule}
          required
        />
      </Stack>

      {isCrossdockingService && (
        <CrossdockingHoursInputsGroup
          isDisabled={!isUserAuthorizedToEditServiceSchedule}
        />
      )}

      {isFractionedService && (
        <FractionedServiceHoursInputsGroup
          isDisabled={!isUserAuthorizedToEditServiceSchedule}
        />
      )}

      <Select
        {...register("material_type")}
        name="material_type"
        label="Tipo de material"
        placeholder="Selecione uma opção..."
        requestedServiceMaterialTypes={switchRequestedServiceMateryalTypes.handle()}
        required
      />

      {destinationAddressesInputFields.map((destinationAddressInputField, index) => {
        return (
          <Fragment key={destinationAddressInputField.address_id}>
            <Flex
              w="full"
              align="center"
              direction={['column', 'column', 'row']}
            >
              <Stack
                w="full"
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Select
                  {...register(
                    `destination_addresses_input.${index}.address_id`,
                  )}
                  name={`destination_addresses_input.${index}.address_id`}
                  label="Endereço"
                  addresses={addresses}
                  error={
                    errors.destination_addresses_input
                      ? errors?.destination_addresses_input[
                        index
                      ]?.address_id
                      : undefined
                  }
                  isDisabled={true}
                  required
                />
                <Input
                  {...register(
                    `destination_addresses_input.${index}.quantity`,
                  )}
                  name={`destination_addresses_input.${index}.quantity`}
                  label="Quantidade"
                  type="number"
                  error={
                    errors.destination_addresses_input
                      ? errors?.destination_addresses_input[
                        index
                      ]?.quantity
                      : undefined
                  }
                  required
                />
              </Stack>
            </Flex>
            <Divider my="6" borderColor="gray.700" />
          </Fragment>
        )
      })}

      <TextArea
        {...register('observation')}
        name='observation'
        label='Observações'
      />
    </Flex>
  )
}
