import { ActiveFeedbackStatus } from "api/feedbacks/_types/ActiveFeedback";
import { PassiveFeedbackStatus } from "api/feedbacks/_types/PassiveFeedback";

export const passiveFeedbackStatusMap: Record<PassiveFeedbackStatus, string> = {
  scheduled: 'Agendado',
  done: 'Realizado'
}

export const activeFeedbackStatusMap: Record<ActiveFeedbackStatus, string> = {
  scheduled: 'Avaliação programada',
  'direct-leader-approving': 'Avaliação do líder direto',
  'executive-leader-approving': 'Aprovação do diretor executivo',
  'awaiting-leader-feedback': 'Aguardando feedback do líder',
  done: 'Concluído'
}
