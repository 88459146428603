import { format, set } from 'date-fns'
import { setDate } from '../DateFunctions/setDate'

interface DataProps {
  name: string
  state: string
  schedule_deadline: string
  opening_deadline: number
  start_attendance_block: string
  final_attendance_block: string
  has_saturday_attendance: string
  saturday_start_attendance_block: string
  saturday_final_attendance_block: string
  has_sunday_attendance: string
  sunday_start_attendance_block: string
  sunday_final_attendance_block: string
  timezone: string
  observation: string
  days_service: number[]
  situation: string
  hub_id: string
}

export function cityFormatDataToBack(data: DataProps) {
  const [scheduleDeadlineHour, scheduleDeadlineMinute] =
    data.schedule_deadline.split(':')
  const scheduleDeadlineHourWithDateNow = format(
    set(new Date(), {
      hours: Number(scheduleDeadlineHour),
      minutes: Number(scheduleDeadlineMinute),
    }),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )

  const [startAttendanceBlockHour, startAttendanceBlockMinute] =
    data.start_attendance_block.split(':')
  const startAttendanceHourWithDateNow = format(
    set(new Date(), {
      hours: Number(startAttendanceBlockHour),
      minutes: Number(startAttendanceBlockMinute),
    }),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )

  const [finalAttendanceBlockHour, finalAttendanceBlockMinute] =
    data.final_attendance_block.split(':')
  const finalAttendanceHourWithDateNow = format(
    set(new Date(), {
      hours: Number(finalAttendanceBlockHour),
      minutes: Number(finalAttendanceBlockMinute),
    }),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )

  let saturdayStartAttendanceBlock: Date | null = null
  let saturdayFinalAttendanceBlock: Date | null = null

  if (data.has_saturday_attendance === "SIM") {
    const [saturdayStartAttendanceHours, saturdayStartAttendanceMinutes] = data.saturday_start_attendance_block.split(":")
    saturdayStartAttendanceBlock = setDate.handleSetHourWithoutFormat(
      Number(saturdayStartAttendanceHours),
      Number(saturdayStartAttendanceMinutes)
    )
    const [saturdayFinalAttendanceHours, saturdayFinalAttendanceMinutes] = data.saturday_final_attendance_block.split(":")
    saturdayFinalAttendanceBlock = setDate.handleSetHourWithoutFormat(
      Number(saturdayFinalAttendanceHours),
      Number(saturdayFinalAttendanceMinutes)
    )
  }

  let sundayStartAttendanceBlock: Date | null = null
  let sundayFinalAttendanceBlock: Date | null = null

  if (data.has_sunday_attendance === "SIM") {
    const [sundayStartAttendanceHours, sundayStartAttendanceMinutes] = data.sunday_start_attendance_block.split(":")
    sundayStartAttendanceBlock = setDate.handleSetHourWithoutFormat(
      Number(sundayStartAttendanceHours),
      Number(sundayStartAttendanceMinutes)
    )
    const [sundayFinalAttendanceHours, sundayFinalAttendanceMinutes] = data.sunday_final_attendance_block.split(":")
    sundayFinalAttendanceBlock = setDate.handleSetHourWithoutFormat(
      Number(sundayFinalAttendanceHours),
      Number(sundayFinalAttendanceMinutes)
    )
  }

  const dataDaysOfWeek =
    data.days_service.toString().split(',').length > 1
      ? data.days_service
      : data.days_service.toString().split('  ')
  const daysOfWeekByNumber: number[] = []
  dataDaysOfWeek.forEach((day) => {
    if (day === 'DOMINGO') {
      daysOfWeekByNumber.push(0)
    }
    if (day === 'SEGUNDA') {
      daysOfWeekByNumber.push(1)
    }
    if (day === 'TERÇA') {
      daysOfWeekByNumber.push(2)
    }
    if (day === 'QUARTA') {
      daysOfWeekByNumber.push(3)
    }
    if (day === 'QUINTA') {
      daysOfWeekByNumber.push(4)
    }
    if (day === 'SEXTA') {
      daysOfWeekByNumber.push(5)
    }
    if (day === 'SÁBADO') {
      daysOfWeekByNumber.push(6)
    }
  })

  const newCityFormatedData = {
    ...data,
    name: data.name,
    state: data.state,
    hub_id: data.hub_id,
    schedule_deadline: scheduleDeadlineHourWithDateNow,
    opening_deadline: data.opening_deadline,
    start_attendance_block: startAttendanceHourWithDateNow,
    final_attendance_block: finalAttendanceHourWithDateNow,
    saturday_start_attendance_block: saturdayStartAttendanceBlock,
    saturday_final_attendance_block: saturdayFinalAttendanceBlock,
    sunday_start_attendance_block: sundayStartAttendanceBlock,
    sunday_final_attendance_block: sundayFinalAttendanceBlock,
    days_service: daysOfWeekByNumber,
    situation: data.situation,
    observation: data.observation,
  }

  return newCityFormatedData
}
