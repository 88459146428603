import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CheckInvoiceCPValueBody {
  isCorrectValue: boolean
}

interface CheckInvoiceCPValueProps {
  invoiceId: string
  body: CheckInvoiceCPValueBody
}

export async function checkInvoiceCPValue({
  invoiceId,
  body
}: CheckInvoiceCPValueProps) {
  try {
    await api.patch(`invoices/${invoiceId}/check-cp-value`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
