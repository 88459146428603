import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getStocks } from "api/stocks/getStocks";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useHub } from "hooks/hub/useHub";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreateStock } from "./components/CreateStock";
import { StocksTableFilters } from "./components/StocksTableFilters";
import { StocksTableRow } from "./components/StocksTableRow";

export function Stocks() {

  const { replace, push: redirect } = useHistory()
  const searchParams = useSearchParams()

  const { userLogged } = useAuth()

  const userHasViewStocksPermission = userLogged?.permissions.includes('view-stock')

  const userHasCreateStockPermission = userLogged?.permissions.includes('add-stock')

  useEffect(() => {
    if (!userHasViewStocksPermission) {
      redirect('/')
    }
  }, [userHasViewStocksPermission, redirect])

  const page = searchParams.get('page') ?? '1'
  const collector = searchParams.get('collector')
  const input = searchParams.get('input')
  const hubsFilter = searchParams.get('hubs')

  const { data: stocksResult } = useQuery({
    queryKey: ['stocks', page,
      collector,
      input,
      hubsFilter
    ],
    queryFn: () => getStocks({
      currentPage: page,
      pageSize: '10',
      collector,
      input,
      hubs: hubsFilter,
      collectorId: userLogged?.collector_id
    }),
    keepPreviousData: true
  })

  const {
    hubs: { data: hubs, isLoading: isHubsLoading },
  } = useHub(null, true, false)

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: stocksResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: stocksResult?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreateStockModalOpen,
    onOpen: onOpenCreateStockModal,
    onClose: onCloseCreateStockModal
  } = useDisclosure()

  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Estoque de Insumos de Transporte</Heading>
        {userHasCreateStockPermission && (
          <IconButton
            aria-label="Criar Estoque"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onOpenCreateStockModal}
          />
        )}
        <Modal
          isOpen={isCreateStockModalOpen}
          onClose={onCloseCreateStockModal}
          isCentered
        >
          <ModalOverlay />
          <CreateStock onCloseModal={onCloseCreateStockModal} />
        </Modal>
      </Flex>
      {!isHubsLoading && (
        <StocksTableFilters
          hubs={hubs}
        />
      )}
      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>

              <Th color="white">Coletador</Th>
              <Th color="white">Hub</Th>
              <Th color="white">Insumo</Th>
              <Th color="white">Estoque atual</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {hubs && stocksResult?.stocks?.map((stock) => {
              return (
                <StocksTableRow
                  key={stock.id}
                  stock={stock}
                  hubs={hubs}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
