import { createContext, ReactNode, useCallback, useState } from 'react'

interface FilterOptionsProviderProps {
  children: ReactNode
  pathname?: string
}

export interface IOptionsProps {
  key: string
  value: string
  checked: boolean
}

interface IOrderOptionsProps {
  key: string
  value: string
}

interface FilterOptionsContextProps {
  handleRegisterOptions: (key: string) => void
  handleChangeOrderOptions: (value: string) => void
  onLoadSetFilterOptions: (options: IOptionsProps[]) => void
  onLoadSetOrderOptions: (options: IOrderOptionsProps[]) => void
  filterOptions: IOptionsProps[]
  orderOptions: IOrderOptionsProps[]
  orderOptionSelected: string[]
}

export const FilterOptionsContext = createContext<FilterOptionsContextProps>(
  {} as FilterOptionsContextProps,
)

export function FilterOptionsProvider({
  children,
  pathname,
}: FilterOptionsProviderProps) {
  const [filterOptions, setFilterOptions] = useState<IOptionsProps[]>([])
  const [orderOptions, setOrderOptions] = useState<IOrderOptionsProps[]>([])
  const [orderOptionSelected, setOrderOptionSelected] = useState<string[]>([])

  const onLoadSetOrderOptions = useCallback((options: IOrderOptionsProps[]) => {
    setOrderOptions(options)
  }, [])

  const onLoadSetFilterOptions = useCallback((options: IOptionsProps[]) => {
    setFilterOptions(options)
  }, [])

  const handleChangeOrderOptions = useCallback(
    (value: string) => {
      const newOrderOptionSelected = [...orderOptionSelected]
      const indexOfOptionSelected = orderOptionSelected.findIndex(
        (option) => option === value,
      )

      if (indexOfOptionSelected >= 0) {
        newOrderOptionSelected.splice(indexOfOptionSelected, 1)
        setOrderOptionSelected(newOrderOptionSelected)
      } else {
        setOrderOptionSelected((prevOrderOptionSelected) => [
          ...prevOrderOptionSelected,
          value,
        ])
      }
    },
    [orderOptionSelected],
  )

  const handleRegisterOptions = useCallback(
    (key: string) => {
      const newOptions = filterOptions.map((option) => {
        return option.key === key
          ? { ...option, checked: !option.checked }
          : option
      })

      setFilterOptions(newOptions)
    },
    [filterOptions],
  )

  return (
    <FilterOptionsContext.Provider
      value={{
        handleRegisterOptions,
        handleChangeOrderOptions,
        onLoadSetFilterOptions,
        onLoadSetOrderOptions,
        filterOptions,
        orderOptionSelected,
        orderOptions,
      }}
    >
      {children}
    </FilterOptionsContext.Provider>
  )
}
