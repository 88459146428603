import { Box, Flex, Heading, IconButton } from "@chakra-ui/react"
import { getOnboardings } from "api/onboardings/getOnboardings"
import { useAuth } from "hooks/auth/useAuth"
import { useEffect } from "react"
import { FaExchangeAlt } from "react-icons/fa"
import { useInfiniteQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { OnboardingsKanbanColumn } from "./components/OnboardingsKanbanColumn"

export function OnboardingsKanban(){
  const { userLogged } = useAuth()
  const {  push: redirect } = useHistory()


  const userHasViewOnboardingsPermission = userLogged?.permissions.includes('view-onboardings')


  useEffect(() => {
    if (!userHasViewOnboardingsPermission) {
      redirect('/')
    }
  }, [userHasViewOnboardingsPermission, redirect])


  const {
    data: invitingOnboardingsResult,
    isLoading: isInvitingOnboardingsResultLoading,
    hasNextPage: hasNextPageInvitingOnboardings,
    fetchNextPage: fetchNextPageInvitingOnboardings,
    isFetchingNextPage: isFetchingNextPageInvitingOnboardings,
  } = useInfiniteQuery({
    queryKey: ['inviting-onboardings'],
    queryFn: ({ pageParam = 1 }) => getOnboardings({
      currentPage: pageParam,
      pageSize: '10',
      status: 'inviting',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })


  const {
    data: trainingOnboardingsResult,
    isLoading: isTrainingOnboardingsResultLoading,
    hasNextPage: hasNextPageTrainingOnboardings,
    fetchNextPage: fetchNextPageTrainingOnboardings,
    isFetchingNextPage: isFetchingNextPageTrainingOnboardings,
  } = useInfiniteQuery({
    queryKey: ['training-onboardings'],
    queryFn: ({ pageParam = 1 }) => getOnboardings({
      currentPage: pageParam,
      pageSize: '10',
      status: 'training',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })
  const {
    data: testingOnboardingsResult,
    isLoading: isTestingOnboardingsResultLoading,
    hasNextPage: hasNextPageTestingOnboardings,
    fetchNextPage: fetchNextPageTestingOnboardings,
    isFetchingNextPage: isFetchingNextPageTestingOnboardings,
  } = useInfiniteQuery({
    queryKey: ['testing-onboardings'],
    queryFn: ({ pageParam = 1 }) => getOnboardings({
      currentPage: pageParam,
      pageSize: '10',
      status: 'testing',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })
  const {
    data: finishedOnboardingsResult,
    isLoading: isFinishedOnboardingsResultLoading,
    hasNextPage: hasNextPageFinishedOnboardings,
    fetchNextPage: fetchNextPageFinishedOnboardings,
    isFetchingNextPage: isFetchingNextPageFinishedOnboardings,
  } = useInfiniteQuery({
    queryKey: ['finished-onboardings'],
    queryFn: ({ pageParam = 1 }) => getOnboardings({
      currentPage: pageParam,
      pageSize: '10',
      status: 'finished',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: inactiveOnboardingsResult,
    isLoading: isInactiveOnboardingsResultLoading,
    hasNextPage: hasNextPageInactiveOnboardings,
    fetchNextPage: fetchNextPageInactiveOnboardings,
    isFetchingNextPage: isFetchingNextPageInactiveOnboardings,
  } = useInfiniteQuery({
    queryKey: ['inactive-onboardings'],
    queryFn: ({ pageParam = 1 }) => getOnboardings({
      currentPage: pageParam,
      pageSize: '10',
      status: 'inactive',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })


  const columnsOnboardings = [
    {
      title: 'Aguardando convite',
      onboardings: invitingOnboardingsResult?.pages.flatMap((page) => page.onboardings) || [],
      onboardingsCount: invitingOnboardingsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageInvitingOnboardings,
      fetchNextPage: fetchNextPageInvitingOnboardings,
      isFetchingNextPage: isFetchingNextPageInvitingOnboardings,
      isLoading: isInvitingOnboardingsResultLoading,
    },
    {
      title: 'Em Treinamento',
      onboardings: trainingOnboardingsResult?.pages.flatMap((page) => page.onboardings) || [],
      onboardingsCount: trainingOnboardingsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageTrainingOnboardings,
      fetchNextPage: fetchNextPageTrainingOnboardings,
      isFetchingNextPage: isFetchingNextPageTrainingOnboardings,
      isLoading: isTrainingOnboardingsResultLoading,
    },
    {
      title: 'Teste da cumbuca',
      onboardings: testingOnboardingsResult?.pages.flatMap((page) => page.onboardings) || [],
      onboardingsCount: testingOnboardingsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageTestingOnboardings,
      fetchNextPage: fetchNextPageTestingOnboardings,
      isFetchingNextPage: isFetchingNextPageTestingOnboardings,
      isLoading: isTestingOnboardingsResultLoading,
    },
    {
      title: 'Finalizados',
      onboardings: finishedOnboardingsResult?.pages.flatMap((page) => page.onboardings) || [],
      onboardingsCount: finishedOnboardingsResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageFinishedOnboardings,
      fetchNextPage: fetchNextPageFinishedOnboardings,
      isFetchingNextPage: isFetchingNextPageFinishedOnboardings,
      isLoading: isFinishedOnboardingsResultLoading,
    },
    {
      title: 'Inativados',
      onboardings: inactiveOnboardingsResult?.pages.flatMap((page) => page.onboardings) || [],
      onboardingsCount: inactiveOnboardingsResult?.pages[0]?.meta?.count,
      color: 'red.300',
      hasNextPage: hasNextPageInactiveOnboardings,
      fetchNextPage: fetchNextPageInactiveOnboardings,
      isFetchingNextPage: isFetchingNextPageInactiveOnboardings,
      isLoading: isInactiveOnboardingsResultLoading,
    },

  ]

  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Onboardings</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar tabela"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => redirect('/onboardings')}
          />

        </Flex>

      </Flex>
      <Flex gap={4} overflowX="auto" mt={4}>
        {columnsOnboardings?.map((onboarding, index) => {
          return (
            <OnboardingsKanbanColumn
              key={index}
              onboarding={onboarding}
            />
          )
        })}
      </Flex>
    </Box>
  )

}
