import { Button, Flex, Select } from "@chakra-ui/react";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { SupportType } from "utils/supportTypes";

interface SupportsSubjectsTableFiltersSchema {
  supportType: SupportType | 'all'
}

export function SupportsSubjectsTableFilters() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()


  const supportType = searchParams.get('supportType') ?? 'all'

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<SupportsSubjectsTableFiltersSchema>({
    defaultValues: {
      supportType: supportType as SupportType | 'all'
    }
  })

  async function handleFilterSupportsSubjects(data: SupportsSubjectsTableFiltersSchema) {
    if (data.supportType) {
      searchParams.set('supportType', data.supportType)
    } else {
      searchParams.delete('supportType')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    reset({
      supportType: 'all'
    })
    searchParams.delete('supportType')

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }


  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterSupportsSubjects)}
    >

      <Select
        {...register('supportType')}
        name="supportType"
        size="sm"
        rounded="md"
        w={{ base: 'full', lg: '250px'}}
      >
        <option value="all">Todos</option>
        {Object.keys(SupportType).filter((key) => isNaN(Number(key))).map((key) => {
          return <option value={key} key={key}>{key}</option>
        })}
      </Select>

      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={["full", "full", "min"]}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleRemoveFilters}
        w={["full", "full", "min"]}
      >
        Remover filtros
      </Button>

    </Flex>
  )
}
