import { Button, Flex, FormControl, FormLabel, Input, InputGroup, InputLeftAddon, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Spinner, Stack, Text } from "@chakra-ui/react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { getPatrimonyTypes } from "api/patrimonies/getPatrimonyTypes"
import { useToastify } from "hooks/toastify/useToastify"
import { editPatrimony } from "api/patrimonies/editPatrimony"
import { getPatrimony } from "api/patrimonies/getPatrimony"
import { useEffect } from "react"
import { useCurrency } from "react-hook-currency"

interface EditPatrimonyProps {
  onCloseModal: () => void
  patrimonyId: string
}

interface EditPatrimonySchema {
  brand: string
  identifier: number
  valueInCents: string
  patrimonyTypeId: string
}

const editPatrimonySchema = yup.object({
  brand: yup.string().required(),
  identifier: yup.number().required(),
  valueInCents: yup.number().required().transform((value, originalValue) => Math.ceil(transformStringToNumber(originalValue) * 100)),
  patrimonyTypeId: yup.string().required()
})
export function EditPatrimony({ onCloseModal, patrimonyId }: EditPatrimonyProps) {
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<EditPatrimonySchema>({
    resolver: yupResolver(editPatrimonySchema)
  })

  const { data: patrimonyData, isLoading: isPatrimonyDataLoading } = useQuery({
    queryKey: ['patrimony', patrimonyId],
    queryFn: () => getPatrimony({ patrimonyId })
  })

  useEffect(() => {
    if (patrimonyData) {
      setValue("brand", patrimonyData?.patrimony.brand)
      setValue("identifier", patrimonyData?.patrimony.identifier)
      setValue("valueInCents", String(patrimonyData?.patrimony.value_in_cents / 100))
      setValue("patrimonyTypeId", patrimonyData?.patrimony.patrimony_type_id)
    }
  }, [patrimonyData, setValue])


  const { data: patrimonyTypesData, isLoading: isPatrimonyTypesDataLoading } = useQuery({
    queryKey: ['patrimonyTypes'],
    queryFn: () => getPatrimonyTypes({}),
    keepPreviousData: true
  })

  const { mutateAsync: editPatrimonyFn } = useMutation({
    mutationFn: editPatrimony,
    onSuccess() {
      queryClient.invalidateQueries(['patrimonies'])
      onCloseModal()
    }
  })

  async function handleEditPatrimony({
    brand,
    identifier,
    valueInCents,
    patrimonyTypeId
  }: EditPatrimonySchema) {
    await promiseMessage(editPatrimonyFn({
      body: {
        brand,
        identifier,
        valueInCents: Number(valueInCents)
      },
      patrimonyTypeId: patrimonyTypeId,
      patrimonyId: patrimonyId
    }), 'Patrimônio editado! 🎉')
  }

  const {
    format: currencyFormat, onChange: onCurrencyChange,
  } = useCurrency({
    style: 'decimal'
  })



  return (
    <ModalContent>
      <ModalHeader>Editar Patrimônio</ModalHeader>
      <ModalCloseButton />
      {isPatrimonyDataLoading || isPatrimonyTypesDataLoading ? (
        <ModalBody
          p="6"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
        </ModalBody>
      ) : (
        <ModalBody
          as="form"
          onSubmit={handleSubmit(handleEditPatrimony)}
          maxH='600px'
          overflowY='scroll'
        >
          <Stack
            spacing="6"
            direction={["column", "column", "column"]}
            mt="3"
          >
            <Flex
              flexDirection='column'>

              <FormControl>
                <FormLabel fontSize="sm">
                  Tipo de Patrimônio
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>


                <Select
                  {...register('patrimonyTypeId')}
                  name="patrimonyTypeId"
                  placeholder="Selecione..."
                  size='sm'
                  rounded='md'
                >

                  {patrimonyTypesData?.patrimonyTypes.map((patrimonyType) => (
                    <option key={patrimonyType.id} value={patrimonyType.id}>
                      {patrimonyType.type}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Flex>
          </Stack>
          <Stack
            spacing="6"
            direction={["column", "column", "row"]}
            mt="3"
          >
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors?.brand}>
                <FormLabel fontSize="sm">
                  Marca
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('brand')}
                  name="brand"
                  size="sm"
                  rounded="md"
                />
              </FormControl>
            </Stack>
          </Stack>
          <Stack
            spacing="6"
            direction={["column", "column", "row"]}
            mt="3"
          >
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors?.identifier}>
                <FormLabel fontSize="sm">
                  Número de Patrimônio
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('identifier')}
                  name="identifier"
                  size="sm"
                  rounded="md"
                />
              </FormControl>
            </Stack>
          </Stack>
          <Stack
            spacing="6"
            direction={["column", "column", "row"]}
            mt="3"
          >
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors?.valueInCents}>
                <FormLabel fontSize="sm">
                  Valor do patrimônio
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Controller
                  name="valueInCents"
                  control={control}
                  render={({ field }) => {
                    return (
                      <InputGroup
                        size="sm"
                      >
                        <InputLeftAddon
                          borderTopLeftRadius="md"
                          borderBottomLeftRadius="md"
                        >
                          R$
                        </InputLeftAddon>
                        <Input
                          {...register("valueInCents")}
                          size="sm"
                          rounded="md"
                          w="full"
                          name={field.name}
                          defaultValue={currencyFormat('000')}
                          onChange={e => field.onChange(onCurrencyChange(e))}
                        />
                      </InputGroup>
                    )
                  }}
                />
              </FormControl>
            </Stack>
          </Stack>
          <Flex
            mt="6"
            w="full"
            justify="flex-end"
          >
            <Button
              type="submit"
              size="sm"
              colorScheme="blue"
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
            >
              Editar
            </Button>
          </Flex>
        </ModalBody>
      )}
    </ModalContent>
  )
}
