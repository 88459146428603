import { usePagination } from "@ajna/pagination";
import { Box, Heading, IconButton, Link, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { VaccineStatus as VaccineStatusTypes } from "api/vaccines/vaccine";
import { getVaccinesReport } from "api/vaccines/getVaccinesReport";
import { Pagination } from "components/Pagination/Pagination";

import { format } from "date-fns";
import { useSearchParams } from "hooks/useSearchParams";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { vaccineCollaboratorTypeMap, vaccineStatusMap, vaccineTypesMap } from "utils/vaccineMappers";
import { VaccineStatus } from "./components/VaccineStatus";
import { VaccinesReportFilters } from "./components/VaccinesReportFilters";

const headers = [
  { label: 'Colaborador', key: 'collaborator_name' },
  { label: 'Tipo de colaborador', key: 'collaborator_type' },
  { label: 'Regional', key: 'regional.code' },
  { label: 'Vacina', key: 'type' },
  { label: 'Data próxima dose', key: 'next_dose_date' },
  { label: 'Status', key: 'status' },
  { label: 'Anexo cartão vacina frente', key: 'cardFrontPhotoAttachment.link' },
  { label: 'Anexo cartão de vacina verso', key: 'cardBackPhotoAttachment.link' },
]

export function VaccinesReport() {
  const searchParams = useSearchParams()

  const status = searchParams.get('status')
  const collaborator = searchParams.get('collaborator')

  const {
    data: vaccinesReportData
  } = useQuery({
    queryKey: ['vaccines-report', status, collaborator],
    queryFn: () => getVaccinesReport({
      collaborator,
      status: status as VaccineStatusTypes | 'all',
    }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!status || !!collaborator
  })

  const csvProps = {
    data: vaccinesReportData ? vaccinesReportData?.vaccines.map(vaccine => {


      return {
        ...vaccine,
        collaborator_type: vaccineCollaboratorTypeMap[vaccine.collaborator_type],
        type: vaccineTypesMap[vaccine.type],
        next_dose_date: vaccine.next_dose_date ? format(new Date(vaccine.next_dose_date), 'dd/MM/yyyy') : '',
        status: vaccineStatusMap[vaccine.status],
      }
    })
      : [],
    headers,
    filename: `relatório-vacinas-${new Date().toISOString()}.csv`,
  }

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
    offset,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  })

  const handleChangePage = (page: number) => setCurrentPage(page)

  return (

    <Box
      p="6"
      bg="white"
      rounded="md"
    >
      <Heading letterSpacing="tight">Relatório de Vacinas</Heading>

      <VaccinesReportFilters csvProps={csvProps} />

      {vaccinesReportData && (
        <TableContainer mt="6">
          <Table
            size="sm"
          >
            <Thead>
              <Tr>
                <Th>Colaborador</Th>
                <Th>Tipo de colaborador</Th>
                <Th>Regional</Th>
                <Th>Vacina</Th>
                <Th>Data de próxima dose</Th>
                <Th>Status</Th>
                <Th>Anexo cartão de vacina frente</Th>
                <Th>Anexo cartão de vacina verso</Th>
              </Tr>
            </Thead>

            <Tbody>
              {vaccinesReportData?.vaccines.slice(offset, offset + 10).map((vaccine) => {

                return (
                  <Tr key={vaccine.id}>
                    <Td>{vaccine.collaborator_name}</Td>
                    <Td>{vaccineCollaboratorTypeMap[vaccine.collaborator_type]}</Td>
                    <Td>{vaccine.regional ? vaccine.regional.code : '-'}</Td>
                    <Td>
                      {vaccineTypesMap[vaccine.type]}
                    </Td>
                    <Td>{vaccine.next_dose_date ? format(new Date(vaccine.next_dose_date), 'dd/MM/yyyy') : '-'}</Td>
                    <Td>
                      <VaccineStatus status={vaccine.status} />
                    </Td>
                    <Td>
                      {vaccine.cardFrontPhotoAttachment ? (
                        <IconButton
                          aria-label="Abrir anexo do cartão de vacina frente"
                          as={Link}
                          href={vaccine.cardFrontPhotoAttachment.link}
                          isExternal
                          icon={<FaExternalLinkAlt />}
                          size="sm"
                        />
                      ) : '-'}
                    </Td>
                    <Td>
                      {vaccine.cardBackPhotoAttachment ? (
                        <IconButton
                          aria-label="Abrir anexo do cartão de vacina verso"
                          as={Link}
                          href={vaccine.cardBackPhotoAttachment.link}
                          isExternal
                          icon={<FaExternalLinkAlt />}
                          size="sm"
                        />
                      ) : '-'}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      <Pagination
        handlePageChange={handleChangePage}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </Box>

  )
}
