import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface ApprovePurchaseOrderBody {
  approvedProvider: string | null
  purchaseApprovedValue: string
}

interface ApprovePurchaseOrderReqFunctionProps {
  purchaseOrderId: string
  input: ApprovePurchaseOrderBody
}

export async function approvePurchaseOrder({
  purchaseOrderId,
  input
}: ApprovePurchaseOrderReqFunctionProps) {
  try {

    const { data } = await api.patch(`/purchase-orders/${purchaseOrderId}/approve`, input)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
