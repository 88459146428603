import { ExternalComunicationsMessageAvatar } from "./ExternalComunicationsMessageAvatar";
import { ExternalComunicationsMessageContentBody } from "./ExternalComunicationsMessageContentBody";
import { ExternalComunicationsMessageContentContainer } from "./ExternalComunicationsMessageContentContainer";
import { ExternalComunicationsMessageContentHeader } from "./ExternalComunicationsMessageContentHeader";
import { ExternalComunicationsMessageRoot } from "./ExternalComunicationsMessageRoot";

export const ExternalComunicationsMessage = {
  Root: ExternalComunicationsMessageRoot,
  Avatar: ExternalComunicationsMessageAvatar,
  ContentContainer: ExternalComunicationsMessageContentContainer,
  ContentHeader: ExternalComunicationsMessageContentHeader,
  ContentBody: ExternalComunicationsMessageContentBody
}
