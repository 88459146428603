import { TrainingTypes } from './training';
import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface CreateTrainingBody {
  collaboratorType: string
  collaboratorName: string
  collaboratorEmail: string
  regionalId: string
  type: TrainingTypes
}

interface CreateTrainingParams {
  body: CreateTrainingBody
}

export async function createTraining({
  body
}: CreateTrainingParams) {
  try {
    await api.post('/trainings', {
      collaboratorType: body.collaboratorType,
      collaboratorName: body.collaboratorName,
      collaboratorEmail: body.collaboratorEmail,
      regionalId: body.regionalId,
      type: body.type,
    })

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

