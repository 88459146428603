import { api } from '../../../services/api'

export type CreateCityInputProps = {
  name: string
  state: string
  hub_id: string
  schedule_deadline: string
  start_attendance_block: string
  final_attendance_block: string
  saturday_start_attendance_block: Date | null
  saturday_final_attendance_block: Date | null
  sunday_start_attendance_block: Date | null
  sunday_final_attendance_block: Date | null
  days_service: number[]
  timezone: string
  situation: string
  observation: string
}

export type EditCityInputProps = CreateCityInputProps

export type EditCityReqProps = {
  cityId: string
  editCityInput: EditCityInputProps
}

const createCityReqFunction = (cityInput: CreateCityInputProps) =>
  api
    .post('/city', cityInput)
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })
const editCityReqFunction = ({ cityId, editCityInput }: EditCityReqProps) =>
  api
    .put(`/city/${cityId}`, editCityInput)
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })
const deleteCityReqFunction = (cityId: string) =>
  api
    .delete(`/city/${cityId}`)
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })

export { createCityReqFunction, editCityReqFunction, deleteCityReqFunction }
