import { Regional } from "api/regionals/_types/Regional";
import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { User } from "hooks/user/dtos/User";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Aso } from "./aso";

interface GetAsoParams {
  asoId: string
}
interface GetAsoResponse {
  aso: Aso & {
    regional: Regional & {
      responsible: User
    } | null
    attachment: Attachment | null
  }
}

export async function getAso({ asoId }: GetAsoParams) {
  try {
    const response = await api.get<GetAsoResponse>(`/asos/${asoId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
