import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface EditProductBody {
  situation: string
  material_id: string
  general_provider_id: string
  warehouse_id: string,
  measure: string
  unit_cost: number
  min_stock: number
  standard_stock: number
  current_stock: number
}

interface EditProductParams {
  body: EditProductBody
  productId: string
}

export async function editProduct({
  body,
  productId
}: EditProductParams) {
  try {

    await api.put(`/products/${productId}`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

