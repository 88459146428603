
import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Stock } from "./types/Stock";

interface GetAdvertenceProps {
  routeParams: {
    stockId: string
  }
}

export async function getStock({
  routeParams
}: GetAdvertenceProps) {
  try {
    const response = await api.get<Stock>(`/stock/${routeParams.stockId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
