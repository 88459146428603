import { format, set } from 'date-fns'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CreateHolidayForm } from '../../components/Form/Holiday/CreateHolidayForm'
import { EditDetailHolidayForm } from '../../components/Form/Holiday/EditDetailHolidayForm'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useHolidayFunctions } from '../../hooks/holiday/useHolidayFunctions'
import { useSwal } from '../../hooks/swal/useSwal'
import { ICreateHolidayDTO } from './IHolidayFormDTO'

interface IQueryParams {
  id: string
  slug: string
}

async function holidayFormatValuesToBack(values: ICreateHolidayDTO) {
  return {
    name: values.name,
    national: values.national === 'SIM',
    date: format(
      set(new Date(), { date: values.day, month: values.month }),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
    ),
    city_id: values.city_id ?? null,
  }
}

export function CreateDetailEditHoliday() {
  const [isLoading, setIsLoading] = useState(false)
  const {
    createHoliday: {
      mutateAsync: createHoliday,
      isError: isCreateHolidayError,
      isSuccess: isCreateHolidaySuccess,
      isLoading: isCreateHolidayLoading,
    },
    editHoliday: {
      mutateAsync: updateHoliday,
      isError: isEditHolidayError,
      isSuccess: isEditHolidaySuccess,
      isLoading: isEditHolidayLoading,
    },
  } = useHolidayFunctions()

  const { confirmMessage, standardMessage } = useSwal()
  const { id, slug }: IQueryParams = useParams()
  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (isCreateHolidayLoading || isEditHolidayLoading) {
      setIsLoading(true)
      return
    }

    if (isCreateHolidayError || isEditHolidayError) {
      setIsLoading(false)
      return
    }

    if (isCreateHolidaySuccess || isEditHolidaySuccess) {
      redirectTo('/feriados')
    }
  }, [
    isCreateHolidayLoading,
    isEditHolidayLoading,
    isCreateHolidayError,
    isEditHolidayError,
    isCreateHolidaySuccess,
    isEditHolidaySuccess,
    redirectTo,
  ])

  const handleCreateHoliday = async (values: ICreateHolidayDTO) => {
    const hasCreateHoliday = await confirmMessage({
      title: 'Deseja criar um feriado?',
    })

    if (hasCreateHoliday) {
      setIsLoading(true)
      const createHolidayValuesFormatedToBack = await holidayFormatValuesToBack(
        values,
      )
      await createHoliday(createHolidayValuesFormatedToBack)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const handleEditHoliday = async (values: ICreateHolidayDTO) => {
    const hasEditHoliday = await confirmMessage({
      title: 'Deseja editar um feriado?',
    })

    if (hasEditHoliday) {
      setIsLoading(true)
      const editHolidayValuesFormatedToBack = await holidayFormatValuesToBack(
        values,
      )
      const updateHolidayRequestResponse = {
        holidayId: id,
        editHolidayInput: editHolidayValuesFormatedToBack,
      }
      await updateHoliday({ ...updateHolidayRequestResponse })
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (
    <>
      {slug === 'adicionar' && (
        <CreateHolidayForm submit={handleCreateHoliday} />
      )}
      {slug === 'editar' && (
        <EditDetailHolidayForm
          holidayId={id}
          slug={slug}
          submit={handleEditHoliday}
        />
      )}
      {slug === 'visualizar' && (
        <EditDetailHolidayForm
          holidayId={id}
          slug={slug}
          submit={async () => {}}
        />
      )}
    </>
  )
}
