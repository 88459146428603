import { Tr, Td, HStack, Skeleton, SkeletonText } from '@chakra-ui/react';

export function TableSkeleton() {
  return (
    <>
      {Array.from({ length: 10 }).map((_, index) => (
        <Tr key={String(index)}>
          <Td>
            <HStack spacing={3}>
              <Skeleton w={8} h={8} />
              <Skeleton w={8} h={8} />
            </HStack>
          </Td>
          <Td>
            <SkeletonText skeletonHeight={3} noOfLines={1} w="180px" />
          </Td>
          <Td>
            <SkeletonText skeletonHeight={3} noOfLines={1} w="30px" />
          </Td>
          <Td>
            <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
          </Td>
          <Td>
            <Skeleton w={8} h={8} />
          </Td>
        </Tr>
      ))}
    </>
  );
};


