import { IconButton, Td, Tr } from "@chakra-ui/react";
import { format } from "date-fns";
import { FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface ServicesValidatedWithSameModalTableRowProps {
  service: {
    id: string
    protocol: number
    customer: {
      id: string
      tradingFirstname: string
    },
    boardDate: string
    boardHour: string
    sourceBranch: {
      id: string
      nickname: string
    },
    destinationBranch: {
      id: string
      nickname: string
    },
    shipping: {
      id: string
      tradingName: string
    }
  }
}

export function ServicesValidatedWithSameModalTableRow({ service }: ServicesValidatedWithSameModalTableRowProps) {
  const { push: redirect } = useHistory()

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Feedback"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={() => redirect(`/servico/detalhes/${service.id}`)}
        />
      </Td>
      <Td>{service.protocol}</Td>
      <Td>{service?.customer?.tradingFirstname}</Td>
      <Td>{service?.boardDate ? format(new Date(service?.boardDate), 'dd/MM/yyyy') : '-'}</Td>
      <Td>{service?.boardHour ? format(new Date(service?.boardHour), 'HH:mm') : '-'}</Td>
      <Td>{service?.shipping?.tradingName}</Td>
      <Td>{service?.sourceBranch?.nickname}</Td>
      <Td>{service?.destinationBranch?.nickname}</Td>
    </Tr>
  )
}
