import { usePagination } from "@ajna/pagination"
import { Box, Button, Flex, Heading, Icon, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";

import { useAuth } from "hooks/auth/useAuth";

import { useFinancialClassificationFunctions } from "hooks/financialClassification/useFinancialClassificationFunctions";
import { useFinancialClassifications } from "hooks/financialClassification/useFinancialClassifications";
import { Pagination } from "components/Pagination/Pagination"
import { useSwal } from "hooks/swal/useSwal";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect, useReducer } from "react";
import { FaPen, FaTrash } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";

interface ActionPayload {
  currentPage?: number
}

interface Action {
  type: 'set-financial-classification-current-page'
  payload: ActionPayload
}

function reducer(state: ActionPayload, action: Action) {
  if (action.type === 'set-financial-classification-current-page') {
    return {
      currentPage: action.payload.currentPage
    }
  }

  return {
    ...state,
    ...action.payload
  }
}

export function FinancialClassificationList() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  const [listFinancialClassificationDataState, dispatch] = useReducer(
    reducer,
    {} as ActionPayload
  )

  const { confirmMessage, standardMessage } = useSwal()

  const { data: financialClassifications, isFetching: isFetchingFinancialClassifications } = useFinancialClassifications({
    queryParams: {
      current_page: listFinancialClassificationDataState.currentPage,
      page_size: Number(rowsPerPage)
    },
    queryOptions:{
      enabled: !!listFinancialClassificationDataState.currentPage
    }
  })

  useEffect(() => {
    if (!userLogged.permissions.includes('view-financial-classification')) {
      redirectTo("/")
    }
  }, [userLogged, redirectTo])

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: financialClassifications?.total_pages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  useEffect(() => {
    dispatch({
      type: 'set-financial-classification-current-page',
      payload: {
        currentPage
      }
    })
  }, [currentPage])


  const { deleteFinancialClassification: { mutateAsync: deleteFinancialClassification } } = useFinancialClassificationFunctions()
  const { promiseMessage } = useToastify()

  async function handleDeleteFinancialClassification(type_id: string) {
    const hasDeleteFinancialClassification = await confirmMessage({ title: "Deseja excluir uma classificação financeira?" })

    if (hasDeleteFinancialClassification) {
      await promiseMessage(deleteFinancialClassification(type_id), "Classificação financeira excluída com sucesso!")
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }
  const handleChangePage = (page: number) => setCurrentPage(page)

  if (isFetchingFinancialClassifications) {
    return <GeneralContentLoading />
  }

  return (

      <Box
        p={4}
        bg="white"
        borderRadius="8px"
      >
        <Flex
          w="full"
          direction="column"
          gap={6}
        >
          <Heading fontSize="xl">Lista de Classificação Financeira</Heading>

          <TableContainer>
            <Table variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th>NOME</Th>
                  <Th></Th>
                </Tr>
              </Thead>

              <Tbody>
                {financialClassifications?.financial_classifications.map(financialClassification => {
                  return (
                    <Tr key={financialClassification.id}>
                      <Td>{financialClassification.type}</Td>
                      <Td isNumeric>
                        {userLogged.permissions.includes('delete-financial-classification') && (
                          <Button as={Link} to={`/classificacao-financeira/editar/${financialClassification.id}`} size="xs" variant="unstyled">
                            <Icon
                              cursor="pointer"
                              as={FaPen}
                              transition="all 0.2s"
                              _hover={{ opacity: 0.8 }}
                            />
                          </Button>
                        )}
                        {userLogged.permissions.includes('edit-financial-classification') && (
                          <Button size="xs" variant="unstyled" ml={2}>
                            <Icon
                              onClick={async () => await handleDeleteFinancialClassification(financialClassification.id)}
                              cursor="pointer"
                              as={FaTrash}
                              transition="all 0.2s"
                              _hover={{ opacity: 0.8 }}
                            />
                          </Button>
                        )}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>


            </Table>
          </TableContainer>
          <Pagination
            currentPage={currentPage}
            pages={pages}
            pagesQuantity={pagesCount}
            handlePageChange={handleChangePage}
          />
        </Flex>
      </Box>

  )
}
