import { Button, Flex, FormControl, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Table, Tbody, Td, Text, Th, Thead, Tr, Select, Stack, ModalFooter } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { Address } from "hooks/address/dtos/Address"
import { useGenerateLabelFunctions } from "hooks/services/label/useGenerateLabelFunctions"
import { useToastify } from "hooks/toastify/useToastify"
import { useFieldArray, useForm } from "react-hook-form"
import { FaDownload } from "react-icons/fa"
import * as yup from "yup"

interface GenerateServiceLabelsProps {
  serviceId: string
  sourceAddresses: Address[]
  destinationAddresses: Address[]
}


interface GenerateServiceLabelsSchema {
  labelsInfo: {
    source_address_id: string
    destination_address_id: string
    quantity: number
  }[]
}

const generateServiceLabelsSchema = yup.object({
  labelsInfo: yup.array().min(1).of(yup.object({
    quantity: yup.number().required()
  }))
})


export function GenerateServiceLabels({
  destinationAddresses,
  serviceId,
  sourceAddresses
}: GenerateServiceLabelsProps) {

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    }
  } = useForm<GenerateServiceLabelsSchema>({
    defaultValues: {
      labelsInfo: sourceAddresses.map((sourceAddress) => {
        return {
          source_address_id: sourceAddress.id,
          destination_address_id: destinationAddresses[0].id,
          quantity: 1
        }
      })
    },
    resolver: yupResolver(generateServiceLabelsSchema)
  })

  const {
    fields,
  } = useFieldArray({
    control,
    name: 'labelsInfo'
  })

  const {
    generateServicesLabels: {
      data: servicesLabelsData,
      mutateAsync: generateServicesLabels,
    },
  } = useGenerateLabelFunctions()

  const { promiseMessage } = useToastify()

  async function handleGenerateServiceLabels(data: GenerateServiceLabelsSchema) {
    await promiseMessage(generateServicesLabels({
      labelsInfo: data.labelsInfo.map((labelInfo) => {
        return {
          ...labelInfo,
          id: labelInfo.source_address_id,
          service_id: serviceId
        }
      })
    }), 'Etiquetas geradas com sucesso')
  }

  const bytes = servicesLabelsData
    ? new Uint8Array(servicesLabelsData)
    : null

  const url = bytes
    ? URL.createObjectURL(
      new Blob([bytes], { type: 'application/pdf' }),
    )
    : null

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Etiquetas</ModalHeader>
      <ModalCloseButton />

      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleGenerateServiceLabels)}
        maxH="600px"
        overflow='auto'
      >
        <Table size="sm">
          <Thead>
            <Th>Origem</Th>
            <Th>Destino</Th>
            <Th>Quantidade</Th>
          </Thead>
          <Tbody>
            {fields.map((field, index) => {
              const currentSourceAddress = sourceAddresses.find(
                (sourceAddress) => sourceAddress.id === field.source_address_id
              )

              return (
                <Tr key={field.id}>
                  <Td fontSize="xs">{`${currentSourceAddress.street} | ${currentSourceAddress.branch ?? ''}, ${currentSourceAddress.number} ${''}
                  ${currentSourceAddress.neighborhood} - ${currentSourceAddress.cityIDAddress.name}, ${''}
                  ${currentSourceAddress.state} - ${currentSourceAddress.cep} | ${currentSourceAddress.responsible_name}
                  `}</Td>
                  <Td fontSize="xs">
                    <Stack
                      spacing="6"
                      direction={["column", "column", "row"]}
                    >
                      <FormControl >
                        <Select
                          {...register(`labelsInfo.${index}.destination_address_id`)}
                          name={`labelsInfo.${index}.destination_address_id`}
                          defaultValue={destinationAddresses[0].id}
                          placeholder="Selecione..."
                          fontSize='xs'
                          size="lg"
                          rounded="md"
                          style={{ whiteSpace: 'pre-wrap' }}
                          h='40'
                          w='200px'
                        >
                          {destinationAddresses.map(destinationAddress => {
                            return (
                              <option
                                key={destinationAddress.id}
                                value={destinationAddress.id}
                              >
                                {`${destinationAddress.street} | ${destinationAddress.branch ?? ''},
                                     ${destinationAddress.number} ${''}
                                   ${destinationAddress.neighborhood} - ${destinationAddress.cityIDAddress.name}, ${''}
                                   ${destinationAddress.state} - ${destinationAddress.cep} | ${destinationAddress.responsible_name}`}

                              </option>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Stack>

                  </Td>

                  <Td>
                    <FormControl isInvalid={!!errors?.labelsInfo && !!errors?.labelsInfo[index]?.quantity}>
                      <Input
                        {...register(`labelsInfo.${index}.quantity`)}
                        name={`labelsInfo.${index}.quantity`}
                        size="sm"
                        rounded="md"
                        type="number"
                      />
                    </FormControl>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>

        {errors.labelsInfo && (
          <Text textAlign="right" margin="0 auto" fontSize="xs" color="red.500">Todos os campos devem estar preenchidos!</Text>
        )}

        <Flex mt="6" justify="flex-end" gap="2">
          {url && (
            <Button
              colorScheme="green"
              as={Link}
              size="sm"
              leftIcon={<FaDownload />}
              href={url}
              download={`etiquetas-${serviceId}`}
            >
              Download
            </Button>
          )}
          <Button
            type="submit"
            colorScheme="blue"
            size="sm"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Gerar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )

}
