import { Box, FormControl, FormLabel, Input, Select, Stack, Text } from "@chakra-ui/react";
import { GeneralProviderResponse } from "hooks/generalProvider/dtos/GeneralProvider";
import { useFormContext, useWatch } from "react-hook-form";

import { captalize } from "utils/captalize";
import { CreateRefundSchema } from "./CreateRefund";


export const providerTypesOptions = [
  'Insumos/Serviços',
  'Produtos',
  'Outro'
]

interface ProviderRefundFormProps {
  generalProviders: GeneralProviderResponse
}

export function ProviderRefundForm({ generalProviders }: ProviderRefundFormProps) {

  const { register, control, formState: { errors } } = useFormContext<CreateRefundSchema>()

  const providerType = useWatch({
    control,
    name: 'providerType'
  })

  const isInputOrServiceProviderType = providerType === 'Insumos/Serviços'

  return (
    <Box w="full">
      <Stack
        w="full"
        spacing={3}
      >
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors?.occurrenceDate}>
              <FormLabel fontSize="sm">
                Data da Ocorrência
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('occurrenceDate')}
                name="occurrenceDate"
                type="date"
                rounded="md"
                size='sm'
              />
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.providerId}>
              <FormLabel fontSize="sm">
                Fornecedor
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register('providerId')}
                name="providerId"
                placeholder="Selecione um usuário"
                size="sm"
                rounded='md'
              >
                {generalProviders?.generalProviders?.map((provider) => {
                  return <option key={provider.id} value={provider.id}>{captalize(provider.trading_name)}</option>
                })}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.providerType}>
              <FormLabel fontSize="sm">
                Tipo de fornecedor
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Select
                {...register('providerType')}
                name="providerType"
                placeholder="Selecione..."
                rounded="md"
                size='sm'
              >
                {Object.entries(providerTypesOptions).map(([key, value]) => (
                  <option key={key} value={value}>{value}</option>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            {isInputOrServiceProviderType && (
              <FormControl isInvalid={!!errors.invoiceNumber}>
                <FormLabel fontSize="sm">
                  Número da Fatura
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('invoiceNumber')}
                  name="invoiceNumber"
                  rounded="md"
                  size="sm"
                />
              </FormControl>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
