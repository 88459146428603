import { useContext } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'
import {
  getAllCustomers,
  getOneCustomer,
} from '../../services/getFunctions/customer/getCustomer'

export function useCustomer(
  customerId: string | null,
  isEnable?: boolean,
  refetchOnFocus?: boolean,
) {
  const { handleLogout, userLogged } = useContext(AuthContext)

  const customers = useQuery('customers', getAllCustomers, {
    enabled: !!isEnable && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (error: any) => {
      toast.error('Erro ao consultar informações! Realize o login novamente.')
      handleLogout()
    },
  })
  const customer = useQuery(
    ['customer', customerId],
    () => getOneCustomer(customerId !== null ? customerId : ''),
    {
      enabled: !!customerId && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )

  return {
    customers,
    customer,
  }
}
