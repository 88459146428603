import { Button, Flex, Spinner } from "@chakra-ui/react";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { useToastify } from "hooks/toastify/useToastify";
import { useLLMUsers } from "hooks/user/useLLMUsers";
import { useUserFunctions } from "hooks/user/useUserFunctions";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { MonitoringUsersForm, MonitoringUsersFormInputs } from "./components/MonitoringUsersForm";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";

const createMonitoringUsersSchema = yup.object().shape({
  monitoringDays: yup.array().min(1, 'Campo obrigatório').of(yup.object({
    day: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
    periods: yup.array().min(1, 'Campo obrigatório').of(yup.object({
      startHour: yup.string().required('Horário inicial obrigatório'),
      endHour: yup.string().required('Horário final obrigatório'),
      responsibles: yup.array().min(1, 'Campo obrigatório').of(yup.object({
        userId: yup.string().required('Campo obrigatório'),
      }))
    }))
  }))
})

export function CreateMonitoringUsers() {

  const formMethods = useForm<MonitoringUsersFormInputs>({
    resolver: yupResolver(createMonitoringUsersSchema)
  })

  const {
    data: llmUsersData,
    isFetching: isFetchingLlmUsersData
  } = useLLMUsers()

  const {
    createMonitoringUsers,
  } = useUserFunctions()

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  async function handleCreateMonitoringUsers(values: MonitoringUsersFormInputs) {
    await promiseMessage(createMonitoringUsers.mutateAsync({
      data: values
    }, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('monitoringUsers')
      }
    }), 'Responsáveis pelo monitoramento criados com sucesso.')
  }

  return (
    <StandardBackgroundForm
      title="Adicionar Responsáveis por Monitoramento"
      onSubmit={formMethods.handleSubmit(handleCreateMonitoringUsers)}
    >
      {isFetchingLlmUsersData ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <MonitoringUsersForm
            users={llmUsersData?.users}
          />
        </FormProvider>
      )}

      <Flex
        w="full"
        justify="flex-end"
        align="center"
        mt="4"
      >

        <Button
          type="submit"
          colorScheme="blue"
        >
          Salvar
        </Button>
      </Flex>

    </StandardBackgroundForm>
  )
}
