import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { DispatchStock } from "./dispatchStock";

interface GetDispatchStockParams {
  dispatchStockId: string
}

interface GetDispatchStockResponse {
  dispatchStock: DispatchStock
}

export async function getDispatchStock({ dispatchStockId }: GetDispatchStockParams) {
  try {
    const response = await api.get<GetDispatchStockResponse>(`/dispatch-stocks/${dispatchStockId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
