import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Divider, Flex, Link, Spinner, Stack, Text } from "@chakra-ui/react"
import { Input } from "../../../../../../components/Inputs/Input"
import { TextArea } from "../../../../../../components/Inputs/TextInput"
import { ICollectionsInfoProps } from "../../../DetailBusinessService"

interface ICollectInfoProps {
  successAddressesCollected: ICollectionsInfoProps[]
  unsuccessAddressesCollected: ICollectionsInfoProps[]
  isLoading: boolean
}

export function CollectInfo({ successAddressesCollected, unsuccessAddressesCollected, isLoading }: ICollectInfoProps) {
  return (
    <AccordionItem isDisabled={isLoading}>
      <AccordionButton p="4">
        <Box flex='1' textAlign='left'>
          Informações das coletas do serviço
        </Box>
        {isLoading ? (
          <Spinner />
        ) : (
          <AccordionIcon />
        )}
      </AccordionButton>
      <AccordionPanel>
        <Flex direction="column" gap="4">
          <Box>
            <Stack spacing="24px" bg="blue.100" p="4" borderRadius="6">
              <Text fontSize="xl" fontWeight="bold" as="em">Coletas com sucesso</Text>
              {successAddressesCollected.map(address => {
                return (
                  <Flex direction="column" gap="4" key={address.id}>
                    <Flex flex="1" gap="6" justify="space-between" direction={["column", "column", "row"]}>
                      <Button
                        as={Link}
                        rel="noreferrer"
                        flex="1"
                        isExternal
                        href={`https://www.google.com/maps/place/${address.arrival_location}`}
                      >
                        Localização inicial da coleta
                      </Button>
                      <Button
                        as={Link}
                        rel="noreferrer"
                        flex="1"
                        isExternal
                        href={`https://www.google.com/maps/place/${address.departure_location}`}
                      >
                        Localização final da coleta
                      </Button>
                    </Flex>
                    {!address.is_provider ? (
                      <Input
                        name={`address_${address.id}`}
                        label="Endereço"
                        isDisabled
                        value={`${address.trading_name} | ${address.branch} | ${address.responsible_name} | ${address.street}, ${address.number} - ${address.neighborhood} | ${address.cep} | ${address.city} | ${address.state} | ${address.complement}`}
                      />
                    ) : (
                      <Input
                        name={`provider_${address.id}`}
                        label="Endereço fornecedor"
                        isDisabled
                        value={`${address.trading_name} | ${address.street}, ${address.number} - ${address.neighborhood} | ${address.cep} | ${address.city} | ${address.state} | ${address.complement}`}
                      />
                    )}
                    <Stack direction={["column", "column", "row"]} spacing="24px">
                      <Input
                        name={`address_schedule_${address.id}`}
                        label="Horário planejado"
                        type="time"
                        isDisabled
                        value={address.planned_hour}
                      />
                      <Input
                        name={`address_departure_hour_${address.id}`}
                        label="Horário real"
                        type="time"
                        isDisabled
                        value={address.real_hour}
                      />
                    </Stack>
                    <TextArea
                      name={`address_observation_${address.id}`}
                      label="Observações"
                      value={address.observation}
                      isDisabled
                    />

                    <Divider my="4" />
                  </Flex>
                )
              })}
            </Stack>
            <Divider my="4" />
            <Stack spacing="24px" bg="red.200" p="4" borderRadius="6">
              <Text fontSize="xl" fontWeight="bold" as="em">Coletas sem sucesso</Text>
              {unsuccessAddressesCollected.map(address => {
                return (
                  <Flex direction="column" key={address.id} gap="4">
                    <Flex flex="1" gap="6" justify="space-between" direction={["column", "column", "row"]}>
                      <Button
                        as={Link}
                        rel="noreferrer"
                        flex="1"
                        isExternal
                        href={`https://www.google.com/maps/place/${address.arrival_location}`}
                      >
                        Localização inicial da coleta
                      </Button>
                      <Button
                        as={Link}
                        rel="noreferrer"
                        flex="1"
                        isExternal
                        href={`https://www.google.com/maps/place/${address.unsuccess_location}`}
                      >
                        Localização final da coleta
                      </Button>
                    </Flex>
                    {!address.is_provider ? (
                      <Input
                        name={`address_${address.id}`}
                        label="Endereço"
                        isDisabled
                        value={`${address.trading_name} | ${address.branch} | ${address.responsible_name} | ${address.street}, ${address.number} - ${address.neighborhood} | ${address.cep} | ${address.city} | ${address.state} | ${address.complement}`}
                      />
                    ) : (
                      <Input
                        name={`provider_${address.id}`}
                        label="Endereço fornecedor"
                        isDisabled
                        value={`${address.trading_name} | ${address.street}, ${address.number} - ${address.neighborhood} | ${address.cep} | ${address.city} | ${address.state} | ${address.complement}`}
                      />
                    )}
                    <Stack direction={["column", "column", "row"]} spacing="24px">
                      <Input
                        name={`address_schedule_${address.id}`}
                        label="Horário planejado"
                        type="time"
                        isDisabled
                        value={address.planned_hour}
                      />
                      <Input
                        name={`address_unsuccess_hour_${address.id}`}
                        label="Horário real"
                        type="time"
                        isDisabled
                        value={address.real_hour}
                      />
                    </Stack>
                    <TextArea
                      name={`address_${address.id}`}
                      label="Observações"
                      value={address.observation}
                      isDisabled
                    />

                    <Divider my="4" />
                  </Flex>
                )
              })}
            </Stack>
          </Box>
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  )
}
