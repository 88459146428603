import { Box, Flex, Heading, Icon, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { TableFilterButton } from "components/Filters/TableFilterButton"
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading"
import { SearchBox } from "components/SearchBox/SearchBox"

import { useAuth } from "hooks/auth/useAuth"
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions"
import { useServices } from "hooks/services/useServices"
import { useEffect } from "react"
import { useForm, useWatch } from "react-hook-form"
import { FaCheck } from "react-icons/fa"
import { Link, useHistory } from "react-router-dom"
import { searchBoxFilter } from "utils/searchBoxFilter"

interface MaintenanceServiceFilterOptionsInputs {
  protocol: string;
  customer: string;
  gelo_seco: string;
  gelox: string;
}

const maintenanceServiceFilterOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'gelo_seco', value: 'Gelo seco', checked: false },
  { key: 'gelox', value: 'Gelox', checked: false },
]

export function MaintenanceServiceList() {

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-maintenance-service')) {
      redirectTo('/')
    }
  }, [redirectTo, userLogged])

  const { data: services, isFetching: isFetchingServices } = useServices({
    queryParams: { steps: ['toMaintenanceService', 'ongoingMaintenanceService'] }
  })

  const {
    filterOptions,
    onLoadSetFilterOptions,
  } = useFilterOptions()

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(maintenanceServiceFilterOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  const {
    control,
    register,
  } = useForm<MaintenanceServiceFilterOptionsInputs>()

  const [
    protocolFiltered,
    customerFiltered,
    geloSecoFiltered,
    geloxFiltered
  ] = useWatch({
    control,
    name: ['protocol', 'customer', 'gelo_seco', 'gelox']
  })

  const servicesFilteredByUserInput = services?.filter(service => {
    const protocolFilter = protocolFiltered
      ? searchBoxFilter(String(service.protocol), protocolFiltered)
      : service.protocol

    const customerFilter = customerFiltered
      ? searchBoxFilter(service.customerIDService.trading_firstname, customerFiltered)
      : service.customerIDService.trading_firstname

    const geloSecoFilter = geloSecoFiltered
      ? searchBoxFilter(String(service.serviceIDRequested.gelo_seco), geloSecoFiltered)
      : service.serviceIDRequested.gelo_seco

    const geloxFilter = customerFiltered
      ? searchBoxFilter(String(service.serviceIDRequested.gelox), geloxFiltered)
      : service.customerIDService.trading_firstname

    return protocolFilter || customerFilter || geloSecoFilter || geloxFilter

  })

  if (isFetchingServices) {
    return <GeneralContentLoading />
  }

  return (

      <Box
        p={['2', '4']}
        bg="white"
        borderRadius="8px"
      >
        <Flex
          direction="column"
          gap={2}
        >
          <Heading size="lg">Serviços em manutenção</Heading>

          <Flex
            gap={4}
            justifyContent="space-between"
            direction={["column", "column", "row"]}
          >
            <TableFilterButton />
            <Flex direction="column" gap={2}>
              {filterOptions
                .filter(option => option.checked)
                .map(option => {
                  return (
                    <SearchBox
                      {...register(option.key as keyof MaintenanceServiceFilterOptionsInputs)}
                      key={option.key}
                      size="sm"
                      placeholder={'Buscar ' + option.value}
                      handleSearch={() => { }}
                    />
                  )
                })}
            </Flex>
          </Flex>

          <TableContainer>
            <Table variant="striped">
              <Thead>
                <Tr>
                  {maintenanceServiceFilterOptions.map(option => {
                    return (
                      <Th key={option.key}>{option.value.toLocaleUpperCase()}</Th>
                    )
                  })}
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {servicesFilteredByUserInput?.map(service => {
                  return (
                    <Tr key={service.id}>
                      <Td>{service.protocol}</Td>
                      <Td>{service.customerIDService.trading_firstname}</Td>
                      <Td>{service.serviceIDRequested.gelo_seco}</Td>
                      <Td>{service.serviceIDRequested.gelox}</Td>
                      <Td>
                        <Box
                          as={Link}
                          to={`/servico/manutencao/${service.id}`}
                        >
                          <Icon
                            cursor="pointer"
                            fontSize="20"
                            as={FaCheck}
                          />
                        </Box>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>

        </Flex>
      </Box>

  )
}
