import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface CreateStandardizationBody {
  anttPhotoId: string
  cleaningRegistersPhotosIds: string[]
  cnhPhotoId: string
  crlvPhotoId: string
  email: string
  frontPhotoWithUniformAndBadgePhotoId: string
  hubId: string
  name: string
  observations: string
  sendedAt: string
  tiresPhotosIds: string[],
  trunkLidOrCarWithEmergencyFormPhotoId: string
  vehicle: string
  vehicleFrontPhotoId: string
  vehicleInternPhotoId: string
  vehicleLeftSidePhotoId: string
  vehicleRearPhotoId: string
  vehicleRightSidePhotoId: string
  loglifeCustomer: string
}

interface CreateStantardizationParams {
  body: CreateStandardizationBody
}

export async function createStandardization({
  body
}: CreateStantardizationParams) {
  try {
    await api.post('/standardizations', body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

