import { User } from 'hooks/user/dtos/User'
import { api } from '../../../services/api'

interface AlignmentAttachment {
  key: string
  filename: string
}

export interface RncAlignments {
  id: string
  aligned_by: string
  date: string
  description: string
  attachments: string | AlignmentAttachment[]
  alignedBy: User
}

export type RncProps = {
  id: string
  status: string
  protocol: string
  direction: string
  date: string
  intercurrence_description: string
  loss_value: number | null
  opened_by: string
  cause: string | null
  corrective: string | null
  preventive: string | null
  why_not_proceed: string | null
  reasons_why: {
    content: string
  }[] | null
  risk_rating: string | null
  rnc_type: string | null
  alignment_date: string | null
  alignment_observation: string | null
  business_meeting: string | null
  UserOpenedIDRNC: {
    firstname: string
    lastname: string
  }
  UserResponsibleIDRNC: {
    firstname: string
    lastname: string
  }
  createdAt: string
  attachment_photo: string | null
  alignments: RncAlignments[]
  intercurrence_responsible: string
}

export type CreateRncInput = {
  direction: string
  date: string
  is_date_full: boolean
  intercurrence_description: string
  loss_value: number | null
  opened_by: string
  intercurrence_responsible: string
}

export type AnswerRncInput = {
  cause: string | null
  corrective: string | null
  preventive: string | null
  why_not_proceed: string | null
  reasons_why: {
    content: string
  }[]
}

export type ValidateRncInput = {
  risk_rating: string
  rnc_type: string | null
  alignment_observation: string | null
  business_meeting: string | null
}

export type AlingRncInput = {
  alignment_observation: string
  alignment_date: string
}

export type ReqAnswerProps = {
  rncId: string
  answerInput: AnswerRncInput
}

export type ReqValidateProps = {
  rncId: string
  hasAlignment: boolean
  validateRncInput: ValidateRncInput
}

export type ReqAlignProps = {
  rncId: string
  hasUpdateAlignment: boolean
  alignRncInput: AlingRncInput
}

const createRncReqFunction = (rncInput: FormData) =>
  api
    .post('/rnc', rncInput)
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })

const answerRncReqFunction = ({ rncId, answerInput }: ReqAnswerProps) =>
  api
    .put(`/rnc/${rncId}`, answerInput)
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })

const validateRncReqFunction = ({
  rncId,
  validateRncInput,
  hasAlignment,
}: ReqValidateProps) =>
  api
    .put(`/rnc/${rncId}`, {
      ...validateRncInput,
      hasValidate: true,
      hasAlignment,
    })
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })

const alignRncReqFunction = ({
  rncId,
  alignRncInput,
  hasUpdateAlignment,
}: ReqAlignProps) =>
  api
    .put(`/rnc/${rncId}`, {
      ...alignRncInput,
      hasUpdateAlignment,
    })
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })

const deleteRncReqFunction = (rncId: string) =>
  api
    .delete(`/rnc/${rncId}`)
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })

export {
  createRncReqFunction,
  answerRncReqFunction,
  validateRncReqFunction,
  alignRncReqFunction,
  deleteRncReqFunction,
}
