import * as yup from "yup"
import { Button, Flex, Spinner } from "@chakra-ui/react";
import { ExtrasDiscountsForm, ExtrasDiscountsFormInputs } from "components/Form/ExtrasDiscountsForm";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { Select } from "components/Inputs/SelectInput";
import { useToastify } from "hooks/toastify/useToastify";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect, useState } from "react";
import { useAggregates } from "hooks/aggregate/useAggregates";
import { useAggregateFunctions } from "hooks/aggregate/useAggregateFunctions";

interface CreateAggregateExtraDiscountInputs extends Omit<ExtrasDiscountsFormInputs, 'extraDiscountAttachments'> {
  aggregateId: string,
  attachmentsIds: string[]
}

const createAggregateExtraDiscountSchema = yup.object().shape({
  aggregateId: yup.string().required('Campo obrigatório'),
  type: yup.string().required('Campo obrigatório'),
  serviceDate: yup.string().required('Campo obrigatório'),
  value: yup.string().required('Campo obrigatório'),
  description: yup.string().required('Campo obrigatório'),
  serviceType: yup.string().required('Campo obrigatório'),
  customerId: yup.mixed().when('serviceType', {
    is: 'BUSINESS',
    then: yup.string().required('Campo obrigatório'),
  }),
  serviceRoute: yup.mixed().when('serviceType', {
    is: 'BUSINESS',
    then: yup.string().required('Campo obrigatório'),
  }),
  servicesProtocols: yup.mixed().when('serviceType', {
    is: 'EXPRESS',
    then: yup.array()
      .of(
        yup.object().shape({
          protocol: yup.number()
            .typeError('Campo obrigatório')
            .required('Campo obrigatório')
            .positive('O protocolo deve ser um número positivo')
            .integer('O protocolo deve ser um número inteiro'),
        })
      )
      .min(1, 'Você deve adicionar pelo menos um serviço'),
  })
})

export function CreateAggregateExtraDiscount() {

  const { userLogged } = useAuth()

  const { push: redirect } = useHistory()
  const [attachmentsIds, setAttachmentsIds] = useState<string[]>([])

  const userHasPermissionToCreateAggregateExtraDiscount =
    userLogged?.permissions.includes('create-aggregate-extra-discount')

  useEffect(() => {
    if (!userHasPermissionToCreateAggregateExtraDiscount) {
      redirect('/')
    }
  }, [redirect, userHasPermissionToCreateAggregateExtraDiscount])

  const {
    data: aggregates,
    isFetching: isFetchingAggregates
  } = useAggregates()

  const aggregatesSelectOptions = aggregates?.map((cltDriver) => {
    return {
      key: cltDriver.id,
      value: cltDriver.id,
      showOption: `${cltDriver.driver.firstname} ${cltDriver.driver.lastname}`
    }
  })

  const formMethods = useForm<CreateAggregateExtraDiscountInputs>({
    resolver: yupResolver(createAggregateExtraDiscountSchema),
    defaultValues: {
      servicesProtocols: [{ protocol: 0 }]
    }
  })

  const { register, handleSubmit, formState: { errors, isSubmitting } } = formMethods

  const {
    createAggregateExtraDiscount: {
      mutateAsync: createAggregateExtraDiscount
    }
  } = useAggregateFunctions()

  const { promiseMessage } = useToastify()


  async function handleCreateAggregateExtraDiscount(
    input: CreateAggregateExtraDiscountInputs
  ) {
    await promiseMessage(createAggregateExtraDiscount({
      aggregateId: input.aggregateId,
      input: {
        ...input,
        value: transformStringToNumber(input.value),
        attachmentsIds: attachmentsIds,
        servicesProtocols: input.serviceType === 'EXPRESS' ? input.servicesProtocols.map(serviceProtocol => serviceProtocol.protocol) : null
      }
    }, {
      onSuccess: () => {
        redirect('/extras-descontos/agregados')
      }
    }), 'Extra/Desconto de agregado criado com sucesso!')
  }

  const handleSetAttachmentsIds = (attachmentsIds: string[]) => setAttachmentsIds(attachmentsIds)

  return (
    <StandardBackgroundForm
      title='Criar Extra/Desconto de Agregado'
      onSubmit={handleSubmit(handleCreateAggregateExtraDiscount)}
    >
      {isFetchingAggregates ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <ExtrasDiscountsForm onSetAttachmentsIds={handleSetAttachmentsIds}>
            <Select
              {...register('aggregateId')}
              name='aggregateId'
              options={aggregatesSelectOptions}
              label='Agregado'
              placeholder='Selecione uma opção...'
              error={errors.aggregateId}
              required
            />
          </ExtrasDiscountsForm>
        </FormProvider>
      )}

      <Flex
        mt={4}
        gap={4}
        w='full'
        align='center'
        justifyContent='flex-end'
      >
        <Button
          type='submit'
          colorScheme='blue'
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}


