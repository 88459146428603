import { Button, Flex, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Stack, Text, Textarea } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { editStock } from "api/stocks/editStock";
import { getStock } from "api/stocks/getStock";
import { useCollectors } from "hooks/collector/useCollectors";
import { useInput } from "hooks/input/useInput";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as yup from "yup"

interface EditStockProps {
  onCloseModal: () => void,
  stockId: string
}

interface EditStockSchema {
  collector_id: string
  input_id: string
  minimum_stock: number
  standard_stock: number
  observation: string
  current_stock: number
}

const editStockSchema = yup.object().shape({
  collector_id: yup.string().required('Campo Obrigatório'),
  input_id: yup.string().required('Campo Obrigatório'),
  minimum_stock: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório!'),
  standard_stock: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório!'),
  observation: yup.string(),
})

export function EditStock({ onCloseModal, stockId }: EditStockProps) {
  const {
    data: stockResult
  } = useQuery({
    queryKey: ['stock', stockId],
    queryFn: () => getStock({ routeParams: { stockId } })
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<EditStockSchema>({
    resolver: yupResolver(editStockSchema)
  })

  useEffect(() => {
    if (stockResult) {
      setValue('collector_id', stockResult.collector_id)
      setValue('input_id', stockResult.input_id)
      setValue('minimum_stock', stockResult.minimum_stock)
      setValue('standard_stock', stockResult.standard_stock)
      setValue('current_stock', stockResult.current_stock)
      setValue('observation', stockResult.observation)
    }
  }, [setValue, stockResult])

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const { mutateAsync: editStockFn } = useMutation({
    mutationFn: editStock,
    onSuccess: () => {
      queryClient.invalidateQueries(['stocks'])
      onCloseModal()
    }
  })

  const {
    data: collectors
  } = useCollectors()
  const {
    inputs: { data: inputs },
  } = useInput(null, true, false)

  async function handleEditStock(values: EditStockSchema) {
    await promiseMessage(editStockFn({
      body: values,
      stockId
    }), 'Estoque editado!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Editar Estoque</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleEditStock)}
        maxH='600px'
        overflowY='scroll'
      >
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Pertence ao Coletador
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.collector_id}>
              <Select
                {...register('collector_id')}
                name="collector_id"
                placeholder="Selecione..."
                size="sm"
                rounded="md"
              >
                {collectors?.map((collector) => {
                  return (
                    <option key={collector.id} value={collector.id}>{collector.trading_name}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Insumo
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.input_id}>
              <Select
                {...register('input_id')}
                name="input_id"
                placeholder="Selecione..."
                size="sm"
                rounded="md"
              >
                {inputs?.map((input) => {
                  return (
                    <option key={input.id} value={input.id}>{input.name}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            spacing="0.25"
            w="full"
          >
            <FormControl isInvalid={!!errors.minimum_stock}>
              <FormLabel fontSize="sm">
                Estoque Mínimo
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('minimum_stock')}
                name="minimum_stock"
                size="sm"
                rounded="md"

              />
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            spacing="0.25"
            w="full"
          >
            <FormControl isInvalid={!!errors.standard_stock}>
              <FormLabel fontSize="sm">
                Estoque Padrão
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('standard_stock')}
                name="standard_stock"
                size="sm"
                rounded="md"

              />
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            spacing="0.25"
            w="full"
          >
            <FormControl isInvalid={!!errors.current_stock}>
              <FormLabel fontSize="sm">
                Estoque Atual
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('current_stock')}
                name="current_stock"
                size="sm"
                rounded="md"

              />
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors?.observation}>
              <FormLabel fontSize="sm">
                Observações
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Textarea
                {...register('observation')}
                name="observation"
                size="sm"
                rounded="md"
              />
            </FormControl>
          </Stack>
        </Stack>
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Editar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
