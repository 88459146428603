import { Box, Flex, Spinner, Step, StepIcon, StepIndicator, StepNumber, Stepper, StepSeparator, StepStatus, StepTitle, Text, useMediaQuery, useSteps } from "@chakra-ui/react"
import { format } from "date-fns"
import { useServiceStatus } from "hooks/services/useServiceStatus"
import { useEffect } from "react"
import { FaTimes } from "react-icons/fa"
import { useParams } from "react-router-dom"

interface Params {
  serviceId: string
}

const steps = {
  'FRACIONADO': [
    {
      title: 'Coletado',
      serviceSteps: ['toBoardService', 'boardingService']
    },
    {
      title: 'Embarcado',
      serviceSteps: ['toAllocateService']
    },
    {
      title: 'Alocado',
      serviceSteps: ['toAvailableService', 'availableService']
    },
    {
      title: 'Disponibilizado',
      serviceSteps: ['toLandingService', 'landingService']
    },
    {
      title: 'Desembarcado',
      serviceSteps: ['toDeliveryService', 'deliveringService']
    },
    {
      title: 'Entregue',
      serviceSteps: ['finishedService']
    },
  ],
  'DEDICADO': [
    {
      title: 'Coletado',
      serviceSteps: ['toDeliveryService', 'deliveringService']
    },
    {
      title: 'Entregue',
      serviceSteps: ['finishedService']
    },
  ]
}

export function ServiceStatus() {
  const { serviceId } = useParams<Params>()

  const {
    data: serviceStatusData,
    isFetching: isFetchingServiceStatusData,
  } = useServiceStatus({
    serviceId
  })

  const stepsFilteredByServiceType = Object.entries(steps).find(
    ([key, _]) => key === serviceStatusData?.service?.service_type
  )

  const stepsCount = stepsFilteredByServiceType?.[1]?.length

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: stepsCount,
  })

  useEffect(() => {

    if (serviceStatusData?.service?.step === 'unsuccessService') {
      setActiveStep(1)
    } else {
      const activeStepIndex = stepsFilteredByServiceType?.[1].findIndex(
        (step) => step.serviceSteps.includes(serviceStatusData?.service?.step)
      )

      setActiveStep(activeStepIndex + 1)

    }

  }, [setActiveStep, serviceStatusData, stepsFilteredByServiceType])

  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  return (
    <Box
      h="calc(100vh)"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >

      <Box
        bgColor="white"
        rounded="lg"
        boxShadow="md"
        p="4"
        m="4"
        gap="4"
        maxH={["600px", "600px", "400px"]}
        h="full"
        maxW="1440px"
        w="full"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {isFetchingServiceStatusData ? (
          <Spinner />
        ) : (
          <>
            <Flex
              alignSelf="flex-start"
              flex="1"
              align="flex-start"
              justify="space-between"
              gap="2"
              p="2"
              fontSize="lg"
              w="full"
            >
              <Flex
                gap="2"
                direction="column"
                align={["center", "center", "flex-start"]}
                w="full"
                textAlign="center"
                h="full"
              >
                <Text
                  fontSize={["sm", "sm", "md"]}
                >
                  RASTREIO DO SEU SERVIÇO
                </Text>
                <Text
                  color="black"
                  fontWeight="bold"
                  fontSize={["sm", "sm", "lg"]}
                >
                  #{serviceStatusData?.service?.protocol}
                </Text>
              </Flex>

              <Flex
                gap="2"
                direction="column"
                justify={["center", "center", "flex-end"]}
                align={["center", "center", "flex-end"]}
                w="full"
                textAlign="center"
                h="full"
              >
                <Text
                  fontSize={["sm", "sm", "md"]}
                >
                  PREVISÃO DE ENTREGA
                </Text>
                {serviceStatusData?.service?.delivery_date && (
                  <Text
                    fontWeight="bold"
                    color="black"
                    fontSize={["sm", "sm", "lg"]}
                  >
                    {format(new Date(serviceStatusData?.service?.delivery_date), 'dd/MM/yyyy')}
                  </Text>
                )}
              </Flex>
            </Flex>
            <Stepper
              index={activeStep}
              orientation={isWideVersion ? "horizontal" : "vertical"}
              gap={isWideVersion ? "2" : "0"}
              size="lg"
              h="full"
              w={["min", "min", "full"]}
              colorScheme={serviceStatusData?.service.step === 'unsuccessService' ? 'red' : 'blue'}
              justifySelf="center"
            >
              {serviceStatusData?.service?.step === 'unsuccessService' && (
                <Step>
                  <StepIndicator>
                    <StepStatus
                      complete={<FaTimes />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink='0'>
                    <StepTitle>Sem Sucesso</StepTitle>
                  </Box>

                  <StepSeparator />
                </Step>
              )}

              {stepsFilteredByServiceType?.[1]?.map((step, index) => {
                return (
                  <Step key={index}>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box flexShrink='0'>
                      <StepTitle>{step.title}</StepTitle>
                    </Box>

                    <StepSeparator />
                  </Step>
                )
              })}
            </Stepper>
          </>
        )}

      </Box>

    </Box>
  )
}
