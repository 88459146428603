import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { Aso, AsoStatus } from "api/asos/aso";
import { getAsos } from "api/asos/getAsos";
import { Regional } from "api/regionals/_types/Regional";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { Pagination } from "components/Pagination/Pagination";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { FaExchangeAlt, FaFileDownload, FaFileExport, FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { asoStatusMap, asoTypeMap } from "utils/asoMappers";
import { AsosTableFilters } from "./components/AsosTableFilters";
import { AsosTableRow } from "./components/AsosTableRow";
import { CreateAso } from "./components/CreateAso";

const headers = [
  { label: 'COLABORADOR', key: 'collaborator_name' },
  { label: 'E-MAIL', key: 'collaborator_email' },
  { label: 'REGIONAL', key: 'regional' },
  { label: 'STATUS', key: 'status' },
  { label: 'DATA DA SOLICITAÇÃO', key: 'created_at' },
  { label: 'TIPO', key: 'type' },
  { label: 'DATA DA REALIZAÇÃO', key: 'due_date' },
]

function formatValuesToReport(values: Array<Aso & { regional: Regional }>) {
  return values?.map((aso) => ({
    ...aso,
    type: asoTypeMap[aso.type],
    created_at: format(new Date(aso.created_at), 'dd/MM/yyyy'),
    status: asoStatusMap[aso.status],
    regional: aso.regional ? aso.regional.code.toUpperCase() : '-',
    due_date: aso.status === 'active' ? (
      format(new Date(aso.due_date), 'dd/MM/yyyy')
    ) : (
      '-'
    )
  }))
}


export function Asos() {

  const { replace, push: redirect } = useHistory()
  const searchParams = useSearchParams()

  const { userLogged } = useAuth()

  const userHasViewAsosPermission = userLogged?.permissions.includes('view-asos')

  const userHasCreateAsoPermission = userLogged?.permissions.includes('create-aso')

  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }


  useEffect(() => {
    if (!userHasViewAsosPermission) {
      redirect('/')
    }
  }, [userHasViewAsosPermission, redirect])

  const hub = searchParams.get('hub')
  const collaborator = searchParams.get('collaborator')
  const status = searchParams.get('status') ?? 'all'
  const page = searchParams.get('page') ?? '1'

  const {
    data: asosData,
  } = useQuery({
    queryKey: ['asos', hub, collaborator, status, page],
    queryFn: () => getAsos({
      collaborator,
      status: status as AsoStatus | 'all',
      currentPage: page,
      pageSize: '10'
    }),
    keepPreviousData: true,
  })

  const {
    data: asosDataReportResult,
    isLoading: isLoadingAsosDataReportResult
  } = useQuery({
    queryKey: [
      'asos-report',
      status,
      collaborator,
      asosData?.meta?.count,
    ],
    queryFn: () => getAsos({
      currentPage: '1',
      pageSize: String(asosData?.meta?.count),
      status: ["pending", "active", "inactive"].includes(status) ? status as AsoStatus : 'all',
      collaborator,
    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: asosData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: asosData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreateAsoModalOpen,
    onOpen: onOpenCreateAsoModal,
    onClose: onCloseCreateAsoModal
  } = useDisclosure()

  const csvReportProps = {
    data: asosDataReportResult ? formatValuesToReport(asosDataReportResult?.asos) : [],
    headers,
    filename: `asos.csv`,
  }


  return (

    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Asos</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar kanban"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => redirect('/asos/kanban')}
          />
          {userHasCreateAsoPermission && (
            <IconButton
              aria-label="Criar Aso"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateAsoModal}
            />
          )}
        </Flex>

        <Modal
          isOpen={isCreateAsoModalOpen}
          onClose={onCloseCreateAsoModal}
          size="2xl"
          isCentered
        >
          <ModalOverlay />

          <CreateAso onCloseModal={onCloseCreateAsoModal} />
        </Modal>
      </Flex>

      <AsosTableFilters />

      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Regional</Th>
              <Th color="white">Status</Th>
              <Th color="white">Data da Solicitação</Th>
              <Th color="white">Tipo</Th>
              <Th color="white">Colaborador</Th>
              <Th color="white">Data da Realização</Th>
              <Th color="white">Data de Vencimento</Th>
            </Tr>
          </Thead>
          <Tbody>
            {asosData?.asos?.map((aso) => {
              return (
                <AsosTableRow
                  key={aso.id}
                  aso={aso}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {!asosDataReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingAsosDataReportResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}

      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />

    </Box>

  )
}
