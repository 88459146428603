import * as yup from 'yup'
import { isCpfValid } from '../utils/cpfCnpjValidateFunctions'

const driverSchema = yup.object().shape({
  collector_id: yup.string().required('Campo Obrigatório'),
  situation: yup.string().required('Campo Obrigatório'),
  firstname: yup.string().required('Campo Obrigatório'),
  lastname: yup.string().required('Campo Obrigatório'),
  cpf: yup.string().transform(isCpfValid).required('CPF inválido'),
  email: yup.string(),
  observation: yup.string(),
})

export { driverSchema }
