import { useQuery } from "react-query";
import { api } from "../../services/api";
import { useSwal } from "../swal/useSwal";

interface CollectService {
  id: string;
  service_id: string;
  address_id: string | null;
  provider_id: string | null;
  driver_id: string;
  step: 'GOING' | 'DONE' | 'UNSUCCESS' | 'VALIDATEUNSUCCESS';
  arrival_latitude: string;
  arrival_longitude: string;
  arrival_timestamp: string;
  responsible_name: string | null;
  collect_volume: number | null;
  collect_sample: number | null;
  box_photo: string | null;
  open_box_photo: string | null;
  content_declaration: string | null;
  departure_latitude: string | null;
  departure_longitude: string | null;
  departure_timestamp: string | null;
  unsuccess_latitude: string | null;
  unsuccess_longitude: string | null;
  unsuccess_timestamp: string | null;
  observation: string;
  addressIDCollect: {
    street: string;
    neighborhood: string;
    number: string;
    cep: string;
  }
  providerIDCollect: {
    street: string;
    neighborhood: string;
    number: string;
    cep: string;
  }
  serviceIDCollect: {
    protocol: number;
    customer_id: string;
    customerIDService: {
      trading_firstname: string;
    }
  }
  requestedService: {
    source_collector_id: string;
    collect_date: Date;
    sourceCollectorIDService: {
      trading_name: string;
    }
  }
}

async function getAllCollectServices(step: string) {
  try {
    const { data } = await api.get<CollectService[]>(`/collect-service`, {
      params: {
        step: step
      }
    })

    return data
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    }
  }
}

export function useCollectServices(step: string) {
  const { errorMessage } = useSwal()

  const collectServices = useQuery("collectServices", () => getAllCollectServices(step), {
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (error instanceof Error) {
        errorMessage(error.message)
      }
    }
  })

  return { collectServices }
}
