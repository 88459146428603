import { Flex, FlexProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface BugCardContentProps extends FlexProps {
  children: ReactNode
}

export function BugCardContent({
  children,
  ...rest
}: BugCardContentProps) {
  return (
    <Flex
      direction="column"
      gap="2"
      w="full"
      h="full"
      {...rest}
    >
      {children}
    </Flex>
  )
}
