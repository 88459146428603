import { Box, Flex, Spinner, Stack, Link as ChakraLink, Divider, Heading, Button, Icon, Grid, } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { Radio } from "components/Inputs/RadioInput";

import { PurchaseOrderQuoteProviders, PurchaseOrderInvoices, PurchaseOrderApprovedAttachments } from "hooks/purchaseOrder/dtos/PurchaseOrder";
import { usePurchaseOrder } from "hooks/purchaseOrder/usePurchaseOrder";
import { PmcCardPurchaseOrder } from "./components/PmcCardPurchaseOrder";
import { PmcCardPurchaseOrderItem } from "./components/PmcCardPurchaseOrderItem";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import { positiveNegativeOptions } from "utils/CustomLists/positiveNegativeOptions";
import { formatDate } from "utils/DateFunctions/formatDate";
import { handleChangeUrl } from "utils/handleChangeUrl";
import { TextArea } from "components/Inputs/TextInput";
import { paymentTypeOptions } from "./components/BuyPurchaseOrder";

interface ParamsProps {
  purchaseOrderId: string
}

export function ViewPurchaseOrder() {

  const { goBack } = useHistory()

  const { purchaseOrderId } = useParams<ParamsProps>()

  const {
    data: purchaseOrderData,
    isFetching: isFetchingPurchaseOrderData
  } = usePurchaseOrder({
    purchaseOrderId
  })

  const purchaseOrder = purchaseOrderData?.purchaseOrder

  const purchaseOrderQuoteProviders: PurchaseOrderQuoteProviders[] =
    purchaseOrder?.quoted_at
      ? typeof purchaseOrderData?.purchaseOrder.quote_providers === 'string'
        ? JSON.parse(purchaseOrderData?.purchaseOrder.quote_providers)
        : purchaseOrderData?.purchaseOrder.quote_providers
      : []

  const isProductPurchaseOrder = purchaseOrder?.type === 'PRODUTO'
  const isPmcPurchaseOrder = purchaseOrder?.purchase_description === 'PMC'

  const purchaseOrderInvoices: PurchaseOrderInvoices[] =
    purchaseOrder?.purchase_invoice
      ? typeof purchaseOrderData?.purchaseOrder.purchase_invoice === 'string'
        ? JSON.parse(purchaseOrderData?.purchaseOrder.purchase_invoice)
        : purchaseOrderData?.purchaseOrder.purchase_invoice
      : []


  const purchaseOrderApprovedAttachments: PurchaseOrderApprovedAttachments[] =
    purchaseOrder?.approved_quote_attachments
      ? typeof purchaseOrderData?.purchaseOrder.approved_quote_attachments === 'string'
        ? JSON.parse(purchaseOrderData?.purchaseOrder.approved_quote_attachments)
        : purchaseOrderData?.purchaseOrder.approved_quote_attachments
      : []

  return (

      <Box
        p={8}
        bg='white'
        borderRadius='8px'
      >

        {isFetchingPurchaseOrderData ? (
          <Spinner />
        ) : (
          <Flex
            gap={4}
            direction='column'
          >
            <Heading size='lg'>Visualizar Pedido de Compra</Heading>

            <Divider />

            <Stack
              w='full'
              spacing={4}
              direction={['column', 'column', 'row']}
            >
              <Input
                name='type'
                defaultValue={purchaseOrder?.type}
                label='Tipo de pedido'
                isDisabled
              />
              {isProductPurchaseOrder && !isPmcPurchaseOrder && (
                <Stack
                  w='full'
                  spacing={4}
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    name='quantity'
                    defaultValue={purchaseOrder?.quantity}
                    label='Quantidade'
                    isDisabled
                  />
                  <Input
                    name='productResponsible'
                    defaultValue={`${purchaseOrder?.productResponsible?.firstname} ${purchaseOrder?.productResponsible?.lastname}`}
                    label='Responsável pelo produto'
                    isDisabled
                  />
                </Stack>
              )}
            </Stack>

            <Input
              name='purchaseDescription'
              defaultValue={purchaseOrder?.purchase_description}
              label='Descrição da compra'
              isDisabled
            />

            {!isPmcPurchaseOrder && (
              <>
                <Stack
                  w='full'
                  spacing={4}
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    name='purchaseReason'
                    defaultValue={purchaseOrder?.purchase_reason}
                    label='Motivo da compra'
                    isDisabled
                  />
                  <Input
                    name='arrivalLimitDate'
                    type='date'
                    defaultValue={purchaseOrder?.arrival_limit_date}
                    label='Data limite'
                    isDisabled
                  />
                </Stack>
              </>
            )}
            {purchaseOrder?.attachment && (
              <Button
                w='full'
                as={ChakraLink}
                rightIcon={<Icon as={FaExternalLinkAlt} />}
                href={handleChangeUrl(purchaseOrder?.attachment)}
                variant='ghost'
                isExternal
              >
                Visualizar anexo
              </Button>
            )}
            {purchaseOrder?.pmc?.pmcProducts && (
              <>
                <Stack
                  w='full'
                  spacing={4}
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    name='sugestedProvider'
                    defaultValue={purchaseOrder?.sugested_provider}
                    label='Fornecedor Sugerido'
                    isDisabled />
                </Stack>
                <Grid
                  p={2}
                  templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(3, 1fr)"]}
                  gap={4}
                  overflow='auto'
                >
                  {purchaseOrder?.pmc?.pmcProducts.filter(product => product?.product?.general_provider_id === purchaseOrder.provider.id).map(product => {
                    return (
                      <PmcCardPurchaseOrder title={product?.product?.material?.material_name}>
                        <Flex w='full' direction="column" align="flex-start" p={4} gap={2} overflow='auto' maxH={'350px'}>
                          <PmcCardPurchaseOrderItem title='Medida' value={product?.product.measure} />
                          <PmcCardPurchaseOrderItem title='Estoque Atual' value={String(product.product.current_stock)} />
                          <PmcCardPurchaseOrderItem title='Estoque Padrão' value={String(product.product.standard_stock)} />
                          <PmcCardPurchaseOrderItem title='Estoque Minimo' value={String(product.product.min_stock)} />
                          <PmcCardPurchaseOrderItem title='Custo Unitário' value={new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                          }).format(product.product.unit_cost / 100)} />
                          <PmcCardPurchaseOrderItem title='Quantidade Comprada' value={String(product.purchase_quantity)} />
                          <PmcCardPurchaseOrderItem title='Custo Estimado' value={String(
                            new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(product.purchase_quantity * (product.product.unit_cost / 100)))} />
                          {product.approved_quantity && (
                            <PmcCardPurchaseOrderItem title='Quantidade Aprovada' value={String(product.approved_quantity)} />
                          )}
                          {product.delivered_at && (
                            <PmcCardPurchaseOrderItem title='Entregue em' value={formatDate.handle(product.delivered_at, 'DateWithDateAndHourMinute')} />
                          )}
                          {product.delivered_quantity && (
                            <PmcCardPurchaseOrderItem title='Quantidade Entregue' value={String(product.approved_quantity)} />
                          )}
                          <Divider my="6" borderColor="gray.700" />
                        </Flex>
                      </PmcCardPurchaseOrder>
                    );
                  })}
                </Grid>
              </>
            )}
            {purchaseOrder?.quoted_at !== null && (
              <>
                <Divider />
                <Input
                  name='quoteType'
                  defaultValue={purchaseOrder?.quote_type}
                  label='Tipo de cotação'
                  isDisabled
                />
                <Heading
                  size='md'
                >
                  Fornecedores cotados
                </Heading>

                <Divider />

                {purchaseOrderQuoteProviders.map((quoteProvider, index) => {
                  return (
                    <Stack
                      key={String(index)}
                      w='full'
                      spacing={4}
                      direction={['column', 'column', 'row']}
                    >
                      <Flex
                        w='full'
                        gap={4}
                        align='center'
                      >
                        <Input
                          name={`quoteProvider.${index}.providerName`}
                          defaultValue={quoteProvider.providerName.toUpperCase()}
                          label='Nome do Fornecedor'
                          isDisabled
                        />

                        <Stack w='full' mt='8'>
                          <Button
                            w='full'
                            as={ChakraLink}
                            rightIcon={<Icon as={FaExternalLinkAlt} />}
                            href={handleChangeUrl(quoteProvider.budgetAttachment)}
                            variant='ghost'
                            isExternal
                          >
                            Visualizar Orçamento
                          </Button>
                        </Stack>
                      </Flex>
                    </Stack>
                  )
                })}

                <Divider />

                <Input
                  name='quotedAt'
                  defaultValue={formatDate.handle(purchaseOrder?.quoted_at, 'DateWithDateAndHourMinute')}
                  label='Cotação realizada em'
                  isDisabled
                />
              </>
            )}


            {purchaseOrder?.approved_at !== null && (
              <>
                <Divider />
                <Input
                  name='approvedProvider'
                  defaultValue={purchaseOrder?.approved_provider}
                  label='Fornecedor aprovado'
                  isDisabled
                />

                <Input
                  name='approvedAt'
                  defaultValue={formatDate.handle(purchaseOrder?.approved_at, 'DateWithDateAndHourMinute')}
                  label='Aprovação realizada em'
                  isDisabled
                />
              </>
            )}


            {purchaseOrder?.purchased_at !== null && (
              <>
                <Divider />
                <Input
                  name='provider'
                  defaultValue={purchaseOrder?.provider.trading_name}
                  label='Fornecedor'
                  isDisabled
                />
                <Input
                  name='providerFinancialClassification'
                  defaultValue={purchaseOrder?.financialClassification.type}
                  label='Classificação financeira'
                  isDisabled
                />
                <Input
                  name='providerCostCenter'
                  defaultValue={purchaseOrder?.provider_cost_center}
                  label='Centro de custo'
                  isDisabled
                />
                {purchaseOrder.purchase_due_date && (
                  <Input
                    name="purchaseDueDate"
                    label="Data de vencimento"
                    defaultValue={formatDate.handle(purchaseOrder?.purchase_due_date, 'DateWithoutHourToShow')}
                    isDisabled
                  />
                )}
                {purchaseOrder?.purchase_has_invoice && (
                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Radio
                      name='purchase_has_invoice'
                      radioOptions={positiveNegativeOptions}
                      defaultCheckedOption={purchaseOrder.purchase_has_invoice ? 'yes' : 'no'}
                      label='Possui Nota Fiscal?'
                      isDisabled
                    />
                  </Stack>
                )}
                {purchaseOrder?.payment_type && (
                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Radio
                      name='payment_type'
                      radioOptions={paymentTypeOptions}
                      defaultCheckedOption={paymentTypeOptions.some(option => option.value === purchaseOrder.payment_type) ? purchaseOrder.payment_type : ''}
                      label='Forma de pagamento?'
                      isDisabled
                    />
                  </Stack>
                )}
                {purchaseOrderInvoices.map((invoice, index) => {
                  return (
                    <Stack
                      key={String(index)}
                      w='full'
                      spacing={4}
                      direction={['column', 'column', 'row']}
                    >
                      <Button
                        w='full'
                        as={ChakraLink}
                        rightIcon={<Icon as={FaExternalLinkAlt} />}
                        href={handleChangeUrl(invoice.key)}
                        variant='ghost'
                        isExternal
                      >
                        Visualizar Nota Fiscal
                      </Button>
                    </Stack>
                  )
                })}
                {purchaseOrderApprovedAttachments && (
                  purchaseOrderApprovedAttachments.map((quoteAttachment) => {
                    return (
                      <Button
                        w='full'
                        key={quoteAttachment.key}
                        as={ChakraLink}
                        rightIcon={<Icon as={FaExternalLinkAlt} />}
                        href={handleChangeUrl(quoteAttachment?.key)}
                        variant='ghost'
                        isExternal
                      >
                        {quoteAttachment.filename} (Visualizar)
                      </Button>
                    )
                  })
                )}


              </>
            )}

            <Divider />

            {purchaseOrder?.refused_at !== null && (
              <>
                <Stack
                  w='full'
                  spacing={4}
                  direction={['column', 'column', 'row']}
                >
                  <TextArea
                    name='refuseDescription'
                    defaultValue={purchaseOrder?.refuse_description}
                    label='Motivo da Recusa'
                    isDisabled />

                </Stack>
                <Input
                  name='approvedAt'
                  defaultValue={formatDate.handle(purchaseOrder?.refused_at, 'DateWithDateAndHourMinute')}
                  label='Recusa realizada em'
                  isDisabled
                />
              </>
            )}

            <Divider />

            {purchaseOrder?.arrived_at !== null && (
              <>
                <Stack
                  w='full'
                  spacing={4}
                  direction={['column', 'column', 'row']}
                >
                  <Radio
                    name='isFinishCorrectly'
                    radioOptions={positiveNegativeOptions}
                    defaultCheckedOption={
                      purchaseOrder?.is_finished_correctly
                        ? 'yes'
                        : 'no'
                    }
                    label={
                      isProductPurchaseOrder
                        ? 'Produto entregue corretamente?'
                        : 'Serviço finalizado corretamente?'
                    }
                    isDisabled
                  />
                  <Radio
                    name='hasRequestedFinancialPayment'
                    radioOptions={positiveNegativeOptions}
                    defaultCheckedOption={
                      purchaseOrder?.has_requested_financial_payment
                        ? 'yes'
                        : 'no'
                    }
                    label='Solicitado pagamento ao financeiro?'
                    isDisabled
                  />
                </Stack>
                <Button
                  w='full'
                  as={ChakraLink}
                  rightIcon={<Icon as={FaExternalLinkAlt} />}
                  href={handleChangeUrl(purchaseOrder?.purchase_type_attachment)}
                  variant='ghost'
                  isExternal
                >
                  {
                    isProductPurchaseOrder
                      ? 'Visualizar NF'
                      : 'Visualizar RPA'
                  }
                </Button>

                <Stack
                  w='full'
                  spacing={4}
                  direction={['column', 'column', 'row']}
                >
                  <Radio
                    name='hasCfop'
                    radioOptions={positiveNegativeOptions}
                    defaultCheckedOption={
                      purchaseOrder?.has_cfop
                        ? 'yes'
                        : 'no'
                    }
                    label='Conferiu se a nota está com CFOP de venda a contribuinte do ICMS ? Não pode ser CFOPs 6.107/6.108'
                    isDisabled
                  />
                  <Radio
                    name='hasConferredEquity'
                    radioOptions={positiveNegativeOptions}
                    defaultCheckedOption={
                      purchaseOrder?.has_conferred_equity
                        ? 'yes'
                        : 'no'
                    }
                    label='Conferiu se patrimônio deu entrada no controle de patrimonio?'
                    isDisabled
                  />
                </Stack>

                <Input
                  name='arrivedAt'
                  defaultValue={formatDate.handle(purchaseOrder?.arrived_at, 'DateWithDateAndHourMinute')}
                  label='Chegou em'
                  isDisabled
                />
              </>
            )}

            <Button w='min' alignSelf='flex-end' variant='outline' onClick={goBack}>Voltar</Button>
          </Flex>
        )}
      </Box>

  )
}
