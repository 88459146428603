import { usePagination } from "@ajna/pagination";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { EmptyContentTableAlert } from "../../../../components/Alerts/EmptyContentTableAlert";
import { Pagination } from "../../../../components/Pagination/Pagination";

export interface ISpotReportData {
  protocol: number
  successCollectedAddresses: string
  unsuccessCollectedAddresses: string

}

interface ISpotReportTableProps {
  reportData: ISpotReportData[]
}

export function SpotReportTable({ reportData }: ISpotReportTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: reportData?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  function handleChangePage(page: number) {
    setCurrentPage(page)
  }


  return (
    <Box mt="4">
      <Box overflow="auto">
        {reportData?.length ? (
          <Table size="sm" variant="striped">
            <Thead>
              <Tr>
                <Th>PROTOCOLO</Th>
                <Th>COLETAS COM SUCESSO</Th>
                <Th>COLETAS SEM SUCESSO</Th>
              </Tr>
            </Thead>
            <Tbody>
              {reportData.slice(offset, offset + Number(itemLimit)).map(data => {
                return (
                  <Tr key={data.protocol}>
                    <Td>{data.protocol}</Td>
                    <Td>{data.successCollectedAddresses}</Td>
                    <Td>{data.unsuccessCollectedAddresses}</Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        ) : (
          <EmptyContentTableAlert
            title="Oops!"
            description={`Não foram encontrados dados com os filtros informados. Tente ajustá-los e realize a busca novamente.`}
          />
        )}
      </Box>
      <Pagination
        handlePageChange={handleChangePage}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </Box>
  )
}
