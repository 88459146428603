import { Box, Button, Circle, Flex, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { FaTag, FaTimes, FaTrash } from "react-icons/fa";

import { DocumentDetail } from "./DocumentDetail";

import { useAuth } from "hooks/auth/useAuth";
import { Document } from "api/documents/types/Document";
import { inactivateDocument } from "api/documents/inactivateDocument";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { GetDocumentsResponse } from "api/documents/getDocuments";
import { deleteDocument } from "api/documents/deleteDocument";


interface DocumentsKanbanCardProps {
  document: Document
}

export function DocumentsKanbanCard({ document }: DocumentsKanbanCardProps) {
  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const userHasDeleteDocumentPermission = userLogged?.permissions.includes('delete-document')

  const userCanInactiveDocument = userLogged?.permissions?.includes('inactive-document')



  const { mutateAsync: inactivateDocumentFn } = useMutation({
    mutationFn: inactivateDocument,
    onSuccess: (_data, { routeParams }) => {
      const cached = queryClient.getQueriesData<GetDocumentsResponse>({
        queryKey: ['documents']
      })
      queryClient.invalidateQueries({ queryKey: 'non-evaluated-documents' })
      queryClient.invalidateQueries({ queryKey: 'expired-documents' })
      queryClient.invalidateQueries({ queryKey: 'active-documents' })
      queryClient.invalidateQueries({ queryKey: 'inactive-documents' })
      queryClient.invalidateQueries({ queryKey: ['document', routeParams.documentId] })


      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          documents: cachedValue.documents.map((document) => {
            if (document.id === routeParams.documentId) {
              return { ...document, status: 'inactive' }
            }

            return document
          })
        })
      })
    }
  })

  async function handleInactivateDocument() {
    await promiseMessage(inactivateDocumentFn({
      routeParams: {
        documentId: document.id
      }
    }), 'Documento inativado!')
  }

  const {
    isOpen: isDocumentDetailModalOpen,
    onToggle: onToggleDocumentDetailModal,
  } = useDisclosure()

  const { mutateAsync: deleteDocumentFn } = useMutation({
    mutationFn: deleteDocument,
    onSuccess(_data, { documentId }) {
      const cachedDocuments = queryClient.getQueriesData<GetDocumentsResponse>({
        queryKey: ['documents']
      })

      queryClient.invalidateQueries({ queryKey: 'non-evaluated-documents' })
      queryClient.invalidateQueries({ queryKey: 'expired-documents' })
      queryClient.invalidateQueries({ queryKey: 'active-documents' })
      queryClient.invalidateQueries({ queryKey: 'inactive-documents' })
      queryClient.invalidateQueries({ queryKey: ['document', documentId] })

      cachedDocuments.forEach(([cachedKey, cachedData]) => {
        if (!cachedData) {
          return null
        }

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          documents: cachedData.documents.filter((cachedDocument) => {
            return cachedDocument.id !== documentId
          })
        })

      })
    },
  })

  async function handleDeleteDocument() {
    await promiseMessage(deleteDocumentFn({
      documentId: document?.id
    }), 'Documento excluido!')
  }



  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleDocumentDetailModal}
      cursor='pointer'
    >
      <HStack justify="space-between" w="full">
        <Text fontWeight="bold" mb={2}>
          {document.name}
        </Text>

        {userHasDeleteDocumentPermission && (
          <Circle
            size="5"
            color="white"
            onClick={(e) => {
              e.stopPropagation()
              handleDeleteDocument()
            }}
          >
            <Icon as={FaTrash} boxSize={"3.5"} color='blue.900' />
          </Circle>
        )}

      </HStack>


      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">
        <Box>
          <HStack spacing={2}>
            <Icon as={FaTag} />
            <Text fontSize="xs" fontWeight="bold">TIPO</Text>
          </HStack>
          <Text ml={5}>{document.document_type}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaTag} />
            <Text fontSize="xs" fontWeight="bold">CLASSIFICAÇÃO</Text>
          </HStack>
          <Text ml={5}>{document.classification ?? '-'}</Text>
        </Box>
      </VStack>
      <Flex align="center" gap="2" alignItems="flex-end" justifyContent="flex-end">
        {(userCanInactiveDocument && document.status !== 'inactive') && (
          <Button
            leftIcon={<FaTimes />}
            lineHeight="1"
            size="xs"
            variant="outline"
            onClick={(e) => {
              e.stopPropagation()
              handleInactivateDocument()
            }}
          >
            Inativar
          </Button>
        )}
      </Flex>
      <Modal
        isOpen={isDocumentDetailModalOpen}
        onClose={onToggleDocumentDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <DocumentDetail
          documentId={document.id}
        />
      </Modal>
    </Box>
  )
}
