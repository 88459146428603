import { Flex, IconButton, Menu, MenuButton, MenuGroup, MenuItem, MenuList } from "@chakra-ui/react";
import { TextArea } from "components/Inputs/TextInput";
import { useForm, useWatch } from "react-hook-form";
import { FaCheckDouble, FaEllipsisV, FaPaperPlane } from "react-icons/fa";

export interface ExternalComunicationsChatFormInputs {
  content: string
}

interface ExternalComunicationsChatFormProps {
  isQuestionForm: boolean
  isEnabledToDigitSomethingInChat: boolean
  onSubmitChat: (values: ExternalComunicationsChatFormInputs) => Promise<void>
  onCloseExternalComunication?: () => Promise<void>
}

export function ExternalComunicationsChatForm({
  isEnabledToDigitSomethingInChat,
  isQuestionForm = false,
  onSubmitChat,
  onCloseExternalComunication,
}: ExternalComunicationsChatFormProps) {

  const {
    control,
    register,
    handleSubmit,
  } = useForm<ExternalComunicationsChatFormInputs>({
    defaultValues: {
      content: ''
    }
  })

  const content = useWatch({
    control,
    name: 'content'
  })

  return (
    <Flex
      justifySelf="flex-end"
      align="center"
      justify="space-between"
      bgColor="white"
      bottom="0"
      left="0"
      w="full"
      gap="2"
      p="2"
      borderTop="4px solid"
      borderColor="gray.50"
      as="form"
      onSubmit={handleSubmit(onSubmitChat)}
    >
      <TextArea
        {...register("content")}
        size="sm"
        fontSize="xs"
        rounded="lg"
        resize="none"
        bgColor="white"
        name="content"
        w="full"
        rows={1}
        focusBorderColor="none"
        placeholder="Digite aqui..."
        isDisabled={!isEnabledToDigitSomethingInChat}
      />

      {content.length > 0 && (
        <IconButton
          size="sm"
          colorScheme="blue"
          rounded="full"
          aria-label="Send message"
          icon={<FaPaperPlane />}
          type="submit"
          disabled={!isEnabledToDigitSomethingInChat}
        />
      )}
      {isQuestionForm && (
        <Menu
          placement="top"
        >
          <MenuButton
            size="sm"
            as={IconButton}
            colorScheme="blue"
            rounded="full"
            aria-label="Send message"
            icon={<FaEllipsisV />}
            disabled={!isEnabledToDigitSomethingInChat}
          />
          <MenuList>
            <MenuGroup title="Ações">
              <MenuItem
                icon={<FaCheckDouble />}
                onClick={onCloseExternalComunication}
              >
                Finalizar
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      )}
    </Flex>
  )
}
