import { useState } from "react"

export function useKanbanSearchBox() {
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  const [searchedWords, setSearchedWords] = useState('')

  function handleSearchServices(event: { target: HTMLInputElement }) {
    setIsLoadingSearch(true)
    setTimeout(() => {
      setSearchedWords(event.target.value)
      setIsLoadingSearch(false)
    }, 3000)
  }

  return {
    handleSearchServices,
    isLoadingSearch,
    searchedWords
  }
}
