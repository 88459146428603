export const dateFormatsMoreUsed = {
  FullDate: {
    format: "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  },
  DateWithoutHourToInput: {
    format: 'yyyy-MM-dd',
  },
  DateWithoutHourToShow: {
    format: 'dd/MM/yyyy',
  },
  DateOnlyWithHourMinute: {
    format: 'HH:mm',
  },
  DateWithDateAndHourMinute: {
    format: "dd/MM/yyyy '-' HH:mm",
  },
  DateOnlyWithDayAndMonth: {
    format: 'dd/MM'
  },
  OnlyMonthOfTheDate: {
    format: 'MM'
  }
}

export type DateFormats = keyof typeof dateFormatsMoreUsed
