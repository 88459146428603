import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getCarriers } from "api/carriers/getCarriers";
import { CarrierStatus } from "api/carriers/_types/Carrier";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa6";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CarriersTableFilters } from "./components/CarriersTableFilters";
import { CarriersTableRow } from "./components/CarriersTableRow";
import { CreateCarrier } from "./components/CreateCarrier";


export function Carriers() {
  const searchParams = useSearchParams()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanViewCarriers = userLogged?.permissions?.includes(
    'view-carriers'
  )

  const userCanCreateCarrier = userLogged?.permissions?.includes(
    'create-carrier'
  )

  useEffect(() => {
    if (!userCanViewCarriers) history.push('/')
  }, [history, userCanViewCarriers])

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status') ?? 'all'
  const name = searchParams.get('name')

  const {
    data: carriersResult
  } = useQuery({
    queryKey: ['carriers', page, status, name],
    queryFn: () => getCarriers({
      currentPage: Number(page),
      pageSize: 10,
      status: status as CarrierStatus | 'all',
      name
    })
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: carriersResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: carriersResult?.meta?.pageSize
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreateCarrierOpen,
    onOpen: onOpenCreateCarrier,
    onClose: onCloseCreateCarrier
  } = useDisclosure()

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Portadores</Heading>
        {userCanCreateCarrier && (
          <IconButton
            aria-label="Criar portador"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onOpenCreateCarrier}
          />
        )}

        <Modal
          isOpen={isCreateCarrierOpen}
          onClose={onCloseCreateCarrier}
          isCentered
          size="2xl"
        >
          <ModalOverlay />
          <CreateCarrier onClose={onCloseCreateCarrier} />
        </Modal>
      </Flex>

      <CarriersTableFilters />

      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Nome</Th>
              <Th color="white">Parceiro Loglife</Th>
              <Th color="white">Cpf</Th>
              <Th color="white">Email</Th>
              <Th color="white">Status</Th>
              <Th></Th>

            </Tr>
          </Thead>
          <Tbody>
            {carriersResult?.carriers?.map((carrier) => {
              return (
                <CarriersTableRow
                  key={carrier.id}
                  carrier={carrier}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
