import { Box, Text, Input, IconButton, Button, Stack, Select } from "@chakra-ui/react";
import { useState } from "react";
import AsyncSelect from "react-select/async";
import { FaEdit } from "react-icons/fa";
import { asoCollaboratorTypeMap, asoStatusMap, asoTypeMap } from "utils/asoMappers";
import { format } from "date-fns";

interface EditableFieldProps {
  label: string
  fieldName: string
  initialValue: string
  handleEditAsoFieldFn({ fieldName, value }: {
    fieldName: string
    value: string
  }): Promise<void>
  options?: { value: string; label: string }[]
  type?: "text" | "select" | "async-select" | "date"
  loadAsyncOptions?: (inputValue: string) => Promise<any[]>
  isLoading: boolean
}

const formatFieldValue = (fieldName: string, value: string | null): string => {
  if (!value) return "N/A"

  if (fieldName === "collaborator_type") return asoCollaboratorTypeMap[value];
  if (fieldName === "type") return asoTypeMap[value];
  if (["due_date", "realized_at"].includes(fieldName)) {
    try {
      return format(new Date(value), "dd/MM/yyyy");
    } catch {
      return
    }
  }
  if (fieldName === "status") return asoStatusMap[value];
  return value
};


export function EditableFieldForm({
  label,
  fieldName,
  initialValue,
  handleEditAsoFieldFn,
  options,
  type = "text",
  loadAsyncOptions,
  isLoading
}: EditableFieldProps) {
  const [isEditing, setIsEditing] = useState(false)
  const [tempValue, setTempValue] = useState(initialValue)

  return (
    <Box>
      <Text fontWeight="bold">{label}</Text>
      {isEditing ? (
        <Stack direction="row" spacing={2}>
          {type === "text" && (
            <Input
              value={tempValue}
              onChange={(e) => setTempValue(e.target.value)}
              size="sm"
              borderColor="blue.300"
              rounded='md'
            />
          )}

          {type === "date" && (
            <Input
              value={tempValue ? format(new Date(tempValue), 'yyyy-MM-dd') : ''}
              onChange={(e) => setTempValue(e.target.value)}
              size="sm"
              type="date"
              borderColor="blue.300"
              rounded='md'
            />
          )}


          {type === "select" && options && (
            <Select
              value={tempValue}
              onChange={(e) => setTempValue(e.target.value)}
              size="sm"
              rounded='md'
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          )}


          {type === "async-select" && loadAsyncOptions && (
            <AsyncSelect
              defaultOptions
              loadOptions={loadAsyncOptions}
              onChange={(option) => setTempValue(option?.value || "")}
              placeholder="Selecione..."
              cacheOptions
              styles={{
                control: (base) => ({ ...base, minHeight: "30px", fontSize: "14px" }),
              }}
            />
          )}

          <Button
            colorScheme="blue"
            size="sm"
            onClick={async () => {
              await handleEditAsoFieldFn({ fieldName, value: tempValue });
              setIsEditing(false);
            }}
            isLoading={isLoading}
          >
            Salvar
          </Button>
          <Button
            size="sm"
            rounded='md'
            onClick={() => setIsEditing(false)}
            isDisabled={isLoading}
          >
            Cancelar
          </Button>
        </Stack>
      ) : (
        <Stack direction="row" spacing={2} align="center">
          <Text>{formatFieldValue(fieldName, initialValue)}</Text>
          <IconButton
            aria-label="Editar"
            icon={<FaEdit />}
            size="sm"
            onClick={() => setIsEditing(true)}
          />
        </Stack>
      )}
    </Box>
  );
}
