import { Flex, IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Popover, PopoverTrigger, Spinner, Table, TableContainer, Tbody, Td, Text, Textarea, Tr } from "@chakra-ui/react";
import { getBilling } from "api/billings/getBilling";
import { format } from "date-fns";
import { FaExternalLinkAlt, FaInfo } from "react-icons/fa";
import { useQuery } from "react-query";
import { handleChangeUrl } from "utils/handleChangeUrl";
import { BillingServiceBudgetDetail } from "./BillingServiceBudgetDetail";
import { BillingStatus } from "./BillingStatus";

interface BillingDetailProps {
  billingId: string
}

export function BillingDetail({ billingId }: BillingDetailProps) {

  const { data: billingData, isLoading: isBillingDataLoading } = useQuery({
    queryKey: ['billing', billingId],
    queryFn: () => getBilling({ billingId })
  })


  return (
    <ModalContent>
      {isBillingDataLoading ? (
        <ModalBody p="14">
          <Spinner />
        </ModalBody>
      ) : (
        <>
          <ModalHeader letterSpacing="tight">Faturamento</ModalHeader>
          <ModalCloseButton />

          <ModalBody mb="4">
            <TableContainer>
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>Cliente</Td>
                    <Td isNumeric>
                      {billingData.billing.customer.trading_firstname}
                    </Td>
                  </Tr>

                  <Tr>
                    <Td>Status</Td>
                    <Td display="flex" justifyContent="flex-end">
                      <BillingStatus status={billingData.billing.status} />
                    </Td>
                  </Tr>

                  {billingData.billing.service && (
                    <>
                      <Tr>
                        <Td>Serviço</Td>
                        <Td isNumeric>{billingData.billing.service.protocol}</Td>
                      </Tr>
                      <Tr>
                        <Td>Orçamento</Td>
                        <Td isNumeric>
                          <Popover>
                            <PopoverTrigger>
                              <IconButton
                                aria-label="Visualizar informações do orçamento"
                                icon={<FaInfo />}
                                size="xs"
                                colorScheme="linkedin"
                              />
                            </PopoverTrigger>
                            <BillingServiceBudgetDetail budget={billingData.billing.service.serviceIDRequested.budgetIDService} />
                          </Popover>
                        </Td>
                      </Tr>
                    </>
                  )}

                  {billingData.billing.status !== 'awaiting-billing' && (
                    <>
                      <Tr>
                        <Td>Período inicial</Td>
                        <Td isNumeric>{format(new Date(billingData.billing.start_date), 'dd/MM/yyyy')}</Td>
                      </Tr>
                      <Tr>
                        <Td>Período final</Td>
                        <Td isNumeric>{format(new Date(billingData.billing.end_date), 'dd/MM/yyyy')}</Td>
                      </Tr>
                      <Tr>
                        <Td>Data de vencimento</Td>
                        <Td isNumeric>{format(new Date(billingData.billing.due_date), 'dd/MM/yyyy')}</Td>
                      </Tr>
                      <Tr>
                        <Td>Valor da fatura</Td>
                        <Td isNumeric>{(billingData.billing.value / 100).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        })}</Td>
                      </Tr>
                      <Tr>
                        <Td>Envio de email</Td>
                        <Td isNumeric>{billingData.billing.auto_send_email ? 'Sim' : 'Não'}</Td>
                      </Tr>
                    </>
                  )}
                </Tbody>
              </Table>
            </TableContainer>


            {billingData.billing.customer.billing_observation && (
              <Flex
                direction="column"
                mt="6"
                gap="2"
              >
                <Text fontSize="sm">Observações</Text>
                <Textarea
                  borderRadius="md"
                  size="sm"
                  h="150px"
                  isReadOnly
                  defaultValue={billingData.billing.customer.billing_observation}
                />
              </Flex>
            )}

            <TableContainer>
              <Table size="sm">
                <Tbody>

                  {billingData?.billing.payment_slips && (
                    billingData.billing.payment_slips.map((paymentSlip) => {
                      return (
                        <Tr key={paymentSlip}>
                          <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">
                            {paymentSlip.slice(33, paymentSlip.length)}
                          </Td>
                          <Td isNumeric>
                            <IconButton
                              aria-label="Ir para anexo"
                              as={Link}
                              icon={<FaExternalLinkAlt />}
                              isExternal
                              href={handleChangeUrl(paymentSlip)}
                              size="sm"
                              variant="ghost"
                            />
                          </Td>
                        </Tr>
                      )
                    })
                  )}


                  {billingData?.billing.invoices && (
                    billingData.billing.invoices.map((invoice) => {
                      return (
                        <Tr key={invoice}>
                          <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">
                            {invoice.slice(33, invoice.length)}
                          </Td>
                          <Td isNumeric>
                            <IconButton
                              aria-label="Ir para anexo"
                              as={Link}
                              icon={<FaExternalLinkAlt />}
                              isExternal
                              href={handleChangeUrl(invoice)}
                              size="sm"
                              variant="ghost"
                            />
                          </Td>
                        </Tr>
                      )
                    })
                  )}

                  {billingData?.billing.attachments && (
                    billingData.billing.attachments.map((attachment) => {
                      return (
                        <Tr key={attachment}>
                          <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">
                            {attachment.slice(33, attachment.length)}
                          </Td>
                          <Td isNumeric>
                            <IconButton
                              aria-label="Ir para anexo"
                              as={Link}
                              icon={<FaExternalLinkAlt />}
                              isExternal
                              href={handleChangeUrl(attachment)}
                              size="sm"
                              variant="ghost"
                            />
                          </Td>
                        </Tr>
                      )
                    })
                  )}
                </Tbody>
              </Table>
            </TableContainer>

          </ModalBody>
        </>
      )}

    </ModalContent>
  )
}
