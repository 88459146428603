class SwitchUserTypes {
  handle() {
    const userTypes = [
      { id: '2', name: 'SUCESSO DO CLIENTE - LAST MILE' },
      { id: '4', name: 'COMERCIAL - GERÊNCIA' },
      { id: '5', name: 'TECNOLOGIA - GERÊNCIA' },
      { id: '6', name: 'SUCESSO DO CLIENTE - COMERCIAL' },
      { id: '7', name: 'COMERCIAL - DIRETORIA' },
      { id: '8', name: 'REGULAÇÃO E QUALIDADE - ANALISTA' },
      { id: '9', name: 'REGULAÇÃO E QUALIDADE - DIRETORIA' },
      { id: '10', name: 'ADMIN' },
      { id: '11', name: 'CLIENTE' },
      { id: '12', name: 'MOTORISTA' },
      { id: '13', name: 'COLETADOR' },
      { id: '14', name: 'REMETENTE' },
      { id: '15', name: 'APRENDIZ - COMPRAS' },
      { id: '16', name: 'ADMINISTRATIVO FINANCEIRO - DIRETORIA' },
      { id: '17', name: 'FINANCEIRO - GERAL' },
      { id: '18', name: 'ADMINISTRATIVO FINANCEIRO - GERÊNCIA' },
      { id: '19', name: 'SUCESSO DO CLIENTE - TORRE DE CONTROLE' },
      { id: '20', name: 'SUCESSO DO CLIENTE - GERÊNCIA' },
      { id: '21', name: 'SUCESSO DO CLIENTE - SUPERVISÃO' },
      { id: '22', name: 'SUCESSO DO CLIENTE - JOVEM APRENDIZ' },
      { id: '23', name: 'ADMINISTRATIVO FINANCEIRO - FINANCEIRO' },
      { id: '24', name: 'ADMINISTRATIVO FINANCEIRO - RH' },
      { id: '25', name: 'ADMINISTRATIVO FINANCEIRO - COMPRAS' },
      { id: '26', name: 'ADMINISTRATIVO FINANCEIRO - JOVEM APRENDIZ' },
      { id: '27', name: 'ADMINISTRATIVO FINANCEIRO - ALMOXARIFADO' },
      { id: '28', name: 'DIRETORIA EXECUTIVA' },
      { id: '29', name: 'GENTE - GERAL' },
      { id: '30', name: 'GENTE - JOVEM APRENDIZ' },
    ]

    return userTypes
  }
}

const switchUserTypes = new SwitchUserTypes()

export { switchUserTypes }
