import { Flex, Link, Text } from "@chakra-ui/react";
import { Radio, RadioOption } from "components/Inputs/RadioInput";
import { useFormContext } from "react-hook-form";

interface PurchaseOrderProviderOptions extends RadioOption {
  url?: string
}

interface ApprovePurchaseOrderProps {
  purchaseOrderProvidersOptions: PurchaseOrderProviderOptions[]
}

export interface ApprovePurchaseOrderFormInputs {
  approvedProvider: string
}


export function ApprovePurchaseOrderForm({
  purchaseOrderProvidersOptions
}: ApprovePurchaseOrderProps) {

  const {
    register,
    formState: {
      errors
    }
  } = useFormContext<ApprovePurchaseOrderFormInputs>()

  return (
    <Flex direction="column" gap={6} align="center">
      <Radio
        {...register('approvedProvider')}
        name='approvedProvider'
        label='Cotação aprovada'
        error={errors.approvedProvider}
        radioOptions={purchaseOrderProvidersOptions}
        required
      />
      {purchaseOrderProvidersOptions.map(purchaseOrderAttachment => {
        return (
          <Link
            key={purchaseOrderAttachment.url}
            href={purchaseOrderAttachment.url}
            isExternal
            alignSelf='flex-start'
          >
            {purchaseOrderAttachment.url && (
              <Text as='b'>{purchaseOrderAttachment.showOption} (Visualizar cotação)</Text>
            )}
          </Link>
        )
      })}
    </Flex>
  )
}
