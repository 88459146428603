import { Button, Flex, Icon, Td, Tr, Link as ChakraLink, IconButton, useDisclosure, Modal, ModalOverlay } from "@chakra-ui/react"
import { Interactions } from "components/Interactions/Interactions"
import { differenceInMinutes, format, isBefore, isSameDay, set } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { Occurrence } from "hooks/occurrences/dtos/Occurrence"
import { Services } from "hooks/services/dtos/InLandingService"
import { interactionStatusColors } from "pages/Dashboard/Llm/components/DelayedCollects"
import { FaClock, FaExclamation, FaEye, FaPen, FaPeopleArrows } from "react-icons/fa"
import { Link } from "react-router-dom"
import { formatDate } from "utils/DateFunctions/formatDate"


interface InLandingServiceTableRowProps {
  service: Services
}

function compareDeliveryForecastDateWithDateNow(
  availableForecastDate: string,
  availableForecastHour: string
) {
  const [availableForecastEndHours, availableForecastEndMinutes] = formatDate
    .handle(availableForecastHour, 'DateOnlyWithHourMinute').split(':')


  const differenceToNow = differenceInMinutes(
    new Date(),
    set(new Date(availableForecastDate), {
      hours: Number(availableForecastEndHours),
      minutes: Number(availableForecastEndMinutes)
    })
  )

  return differenceToNow > 0
}

export function InLandingServiceTableRow({ service }: InLandingServiceTableRowProps) {
  const { userLogged } = useAuth()

  const userHasStartLandingServicePermission =
    userLogged?.permissions.includes('add-landing-service')

  const isLLMUser = userLogged.loglife_employee

  const { isOpen, onOpen, onClose } = useDisclosure();

  const deliveryDelayOccurrences = [
    'ATRASO NA ENTREGA',
    'CANCELAMENTO DE VOO',
    'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)',
    'ATRASO NA TRANSFERÊNCIA',
    'ATRASO NA LIBERAÇÃO'
  ]

  const serviceDeliveryDelayOccurrence =
    service.occurrenceIDService
      .filter(occurrence => deliveryDelayOccurrences.includes(occurrence.intercurrence))
      .reduce((acc, curr) => {
        return new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr
      }, {} as Occurrence)

  const deliveryDate = serviceDeliveryDelayOccurrence?.occurrence_date
    ? formatDate.handle(serviceDeliveryDelayOccurrence.occurrence_date, "DateWithoutHourToShow")
    : formatDate.handle(service.serviceIDRequested.delivery_date, "DateWithoutHourToShow")

  const deliveryHour = serviceDeliveryDelayOccurrence?.occurrence_hour
    ? formatDate.handle(serviceDeliveryDelayOccurrence.occurrence_hour, "DateOnlyWithHourMinute")
    : formatDate.handle(service.serviceIDRequested.delivery_hour, "DateOnlyWithHourMinute")

  let tracker = '-'
  let validatedBoardVolume = '-'
  let shipping = '-'
  let sourceBranch = '-'
  let destinationBranch = '-'
  let destinationCollector = '-'

  let trackerLink = null

  const serviceHasCrossdocking = service.serviceIDRequested.crossdocking_collector_id !== null

  let isServiceDelayed = false

  if (serviceHasCrossdocking) {
    const crossdockingBoardAlreadyFinished = service.serviceIDLanding.find(landing => {
      return ['DONE', 'VALIDATING'].includes(landing.step) && landing.collector_id === service.serviceIDRequested.crossdocking_collector_id
    })

    const isBoardDateBeforeToToday = isBefore(
      new Date(service.serviceIDRequested.board_date),
      new Date()
    )

    const isBoardDateSameToToday = isSameDay(
      new Date(service.serviceIDRequested.board_date),
      new Date()
    )

    if (!isBoardDateSameToToday && isBoardDateBeforeToToday) {
      isServiceDelayed = true
    } else if (isBoardDateSameToToday) {
      const [boardHours, boardMinutes] = format(new Date(service.serviceIDRequested.board_hour), "HH:mm").split(':').map(Number)

      isServiceDelayed = differenceInMinutes(
        set(new Date(), {
          hours: boardHours,
          minutes: boardMinutes
        }), new Date()
      ) < 240
    }

    if (crossdockingBoardAlreadyFinished) {
      const currentBoardInfo = service.serviceIDBoard.find(board => {
        return board.collector_id === service.serviceIDRequested.source_collector_id
      })

      tracker = String(currentBoardInfo?.operational_number)
      validatedBoardVolume = String(currentBoardInfo?.board_volume)
      shipping = service.shippings.find(ship => {
        return ship.id === service.serviceIDRequested.sourceCrossdockingBranchIDService.shipping_id
      })?.company_name
      trackerLink = currentBoardInfo?.tracker_link
      sourceBranch = service.serviceIDRequested.sourceCrossdockingBranchIDService.nickname
      destinationBranch = service.serviceIDRequested.destinationBranchIDService.nickname
      destinationCollector = service.serviceIDRequested.destinationCollectorIDService.trading_name
    } else {
      const currentBoardInfo = service.serviceIDBoard.find(board => {
        return board.collector_id === service.serviceIDRequested.crossdocking_collector_id
      })

      tracker = String(currentBoardInfo?.operational_number)
      validatedBoardVolume = String(currentBoardInfo?.board_volume)
      shipping = service.shippings.find(ship => {
        return ship.id === service.serviceIDRequested.sourceBranchIDService.shipping_id
      })?.company_name
      trackerLink = currentBoardInfo?.tracker_link
      sourceBranch = service.serviceIDRequested.sourceBranchIDService.nickname
      destinationBranch = service.serviceIDRequested.destinationCrossdockingBranchIDService.nickname
      destinationCollector = service.serviceIDRequested.crossdockingCollectorIDService.trading_name
    }
  } else {
    const currentBoardInfo = service.serviceIDBoard.find(board => {
      return board.collector_id === service.serviceIDRequested.source_collector_id
    })
    tracker = String(currentBoardInfo?.operational_number)
    validatedBoardVolume = String(currentBoardInfo?.board_volume)
    shipping = service.shippings.find(ship => {
      return ship.id === service.serviceIDRequested.sourceBranchIDService.shipping_id
    })?.company_name
    trackerLink = currentBoardInfo?.tracker_link
    sourceBranch = service.serviceIDRequested.sourceBranchIDService.nickname
    destinationBranch = service.serviceIDRequested.destinationBranchIDService.nickname
    destinationCollector = service.serviceIDRequested.destinationCollectorIDService.trading_name
  }

  let tableLineColor = ''

  const isCurrentDateBiggerThanDeliveryForecastDate =
    compareDeliveryForecastDateWithDateNow(
      service.serviceIDRequested.delivery_date,
      service.serviceIDRequested.delivery_hour
    )

  if (
    serviceDeliveryDelayOccurrence.id
  ) {
    tableLineColor = 'yellow.300'
  } else if (isCurrentDateBiggerThanDeliveryForecastDate) {
    tableLineColor = 'red.300'
  }

  return (
    <Tr
      color={tableLineColor}
    >
      <Td>
        <Flex align='baseline' gap={2}>
          {service?.serviceIDRequested?.budgetIDService?.priority_budget && (
            <Icon as={FaExclamation} color={'red.500'} />
          )}
          {isServiceDelayed && (
            <Icon as={FaClock} color={'red.500'} />
          )}
          {isLLMUser && (
            <Flex h="full" justify="flex-end" align="flex-end">
              <IconButton
                aria-label="Abrir Interações"
                icon={<FaPeopleArrows />}
                colorScheme={interactionStatusColors[service.last_interaction_status] || interactionStatusColors.default}
                size="sm"
                onClick={onOpen}
                variant='ghost'

              />
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />

                <Interactions isOpen={isOpen} serviceId={service.id} />
              </Modal>

            </Flex>

          )}
        </Flex>
      </Td>
      <Td>
        <Flex>
          {(userHasStartLandingServicePermission) && (
            <Button
              as={Link}
              to={`/servicos/desembarques/${service.id}`}
              variant='unstyled'
            >
              <Flex w='full' h='full' align='center' justify='center'>
                <Icon as={FaPen} />
              </Flex>
            </Button>
          )}
          <Button
            as={Link}
            to={`/servico/detalhes/${service.id}`}
            variant='unstyled'
          >
            <Flex w='full' h='full' align='center' justify='center'>
              <Icon as={FaEye} />
            </Flex>
          </Button>
        </Flex>
      </Td>
      <Td>{service.protocol}</Td>
      <Td>{deliveryDate}</Td>
      <Td>{deliveryHour}</Td>
      <Td>{shipping}</Td>
      <Td>{
        trackerLink ?
          <ChakraLink href={trackerLink} isExternal>
            {tracker}
          </ChakraLink>
          : tracker}</Td>
      <Td>{validatedBoardVolume}</Td>
      <Td>{sourceBranch}</Td>
      <Td>{destinationBranch}</Td>
      <Td>{service.customerIDService.trading_firstname}</Td>
      <Td>{Array.from(new Set(service.source_cities.map(city => city.name))).join(', ')}</Td>
      <Td>{Array.from(new Set(service.destination_cities.map(city => city.name))).join(', ')}</Td>
      <Td>{destinationCollector}</Td>
    </Tr>
  )
}
