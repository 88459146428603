import { Regional } from "api/regionals/_types/Regional";
import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Onboarding } from "./onboarding";

interface GetOnboardingParams {
  onboardingId: string
}

interface GetOnboardingResponse {
  onboarding: Onboarding & {
    certificate: {
      id: string
      title: string
      link: string
      createdAt: string
      updatedAt: string
    }
    regional: Regional | null
  }
}

export async function getOnboarding({ onboardingId }: GetOnboardingParams) {
  try {
    const response = await api.get<GetOnboardingResponse>(`/onboardings/${onboardingId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
