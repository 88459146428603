import { Button, Flex, Input, Select } from "@chakra-ui/react";
import { DispatchStockProposalStatusTypes } from "api/dispachStocks/dispatchStockProposal";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { dispatchStockProposalStatusMap } from "utils/dispatchStockMappers";

interface DispatchStockProposalsTableFiltersSchema {
  protocol: number
  collector: string
  status: DispatchStockProposalStatusTypes | 'all'
}

export function DispatchStockProposalsTableFilters() {
  const searchParams = useSearchParams()

  const protocol = searchParams.get('protocol')
  const collector = searchParams.get('collector')
  const status = searchParams.get('status') ?? 'all'

  const history = useHistory()

  const {
    reset,
    register,
    handleSubmit,
  } = useForm<DispatchStockProposalsTableFiltersSchema>({
    defaultValues: {
      protocol: protocol ? Number(protocol) : undefined,
      collector: collector ? collector : undefined,
      status: status as DispatchStockProposalStatusTypes | 'all'
    }
  })

  function handleSearchDispatchStockProposals(
    values: DispatchStockProposalsTableFiltersSchema
  ) {
    if (values.protocol) {
      searchParams.set('protocol', values.protocol.toString())
    } else {
      searchParams.delete('protocol')
    }

    if (values.collector) {
      searchParams.set('collector', values.collector.toString())
    } else {
      searchParams.delete('collector')
    }

    if (values.status) {
      searchParams.set('status', values.status.toString())
    } else {
      searchParams.delete('status')
    }

    searchParams.set('page', '1')

    history.replace({ search: searchParams.toString() })
  }

  function handleClearFilters() {
    searchParams.delete('protocol')
    searchParams.delete('collector')
    searchParams.set('status', 'all')
    searchParams.set('page', '1')

    reset()

    history.replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      mt="6"
      direction={{ base: 'column', lg: 'row' }}
      w="full"
      gap="2"
      as="form"
      onSubmit={handleSubmit(handleSearchDispatchStockProposals)}
    >
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        w="full"
        gap="2"
      >

        <Input
          {...register('protocol')}
          size="sm"
          rounded="md"
          w={{ base: 'full', lg: 'fit-content' }}
          placeholder="Protocolo"
        />

        <Input
          {...register('collector')}
          size="sm"
          rounded="md"
          w={{ base: 'full', lg: 'fit-content' }}
          placeholder="Coletador"
        />

        <Select
          {...register('status')}
          size="sm"
          rounded="md"
          w={{ base: 'full', lg: 'fit-content' }}
        >
          {Object.entries(dispatchStockProposalStatusMap).map(([key, value]) => {
            return (
              <option key={key} value={key}>{value}</option>
            )
          })}

          <option value="all">Todos Status</option>
        </Select>
      </Flex>

      <Flex
        flex="1"
        gap="2"
        direction={{ base: 'column', lg: 'row' }}
      >
        <Button
          leftIcon={<FaSearch />}
          size="sm"
          w={{ base: 'full', lg: 'fit-content' }}
          type="submit"
        >
          Filtrar resultados
        </Button>

        <Button
          leftIcon={<FaTimes />}
          size="sm"
          w={{ base: 'full', lg: 'fit-content' }}
          type="button"
          onClick={handleClearFilters}
          variant="ghost"
        >
          Remover filtros
        </Button>

      </Flex>

    </Flex>
  )
}
