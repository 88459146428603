import { Th, Tr } from "@chakra-ui/react";

export function DeliveriesRelationsTableHead() {
  return (
    <Tr
      h="40px"
      bg="#38c3fa"
    >
      <Th></Th>
      <Th color="white">Protocolo</Th>
      <Th color="white">Cliente</Th>
      <Th color="white">Remetentes</Th>
      <Th color="white">Retirada</Th>
      <Th color="white">Data limite de entrega</Th>
      <Th color="white">Horário limite de entrega</Th>
      <Th color="white">Destinatários</Th>
      <Th color="white">Etapa</Th>
      <Th color="white">Coletador de origem</Th>
      <Th color="white">Coletador de destino</Th>
    </Tr >
  )
}
