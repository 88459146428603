import { BranchProps } from "contexts/BranchContext"
import { formatDate } from "utils/DateFunctions/formatDate"
import { DaysOfWeekEnum } from "utils/enums/DaysOfWeekEnum"

export function findBranchOpenAndCloseHoursBasedOnDayOfWeek(
  dayOfWeek: number,
  branches: BranchProps[],
  selectedBranch: string
) {
  const branchExists = branches?.find(branch => branch.id === selectedBranch)

  if (branchExists) {
    if (dayOfWeek >= DaysOfWeekEnum['MONDAY'] && dayOfWeek <= DaysOfWeekEnum['FRIDAY']) {
      const branchHasWeekOperation = branchExists.business_open !== null
      if (branchHasWeekOperation) {
        return {
          branchOpenHour: formatDate.handle(branchExists.business_open, "DateOnlyWithHourMinute"),
          branchCloseHour: formatDate.handle(branchExists.business_close, "DateOnlyWithHourMinute"),
        }
      }

      return null
    }

    if (dayOfWeek === DaysOfWeekEnum['SATURDAY']) {
      const branchHasSaturdayOperation = branchExists.saturday_open !== null
      if (branchHasSaturdayOperation) {
        return {
          branchOpenHour: formatDate.handle(branchExists.saturday_open, "DateOnlyWithHourMinute"),
          branchCloseHour: formatDate.handle(branchExists.saturday_close, "DateOnlyWithHourMinute"),
        }
      }

      return null
    }

    if (dayOfWeek === DaysOfWeekEnum['SUNDAY']) {
      const branchHasSundayOperation = branchExists.sunday_open !== null
      if (branchHasSundayOperation) {
        return {
          branchOpenHour: formatDate.handle(branchExists.sunday_open, "DateOnlyWithHourMinute"),
          branchCloseHour: formatDate.handle(branchExists.sunday_close, "DateOnlyWithHourMinute"),
        }
      }

      return null
    }
  }
  return null
}
