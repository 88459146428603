import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { api } from 'services/api';
import { useAuth } from 'hooks/auth/useAuth';
import { RequestError } from 'utils/errors/RequestErrors';
import { HttpErrorStatusCodeEnum } from 'utils/errors/HttpStatusCodeEnum';

async function validateFiscalRetentionReqFunction(input: FormData) {
  try {
    await api.post("/service/fiscal-retention", input)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

export function useFiscalRetentionFunctions() {
  const { handleLogout } = useAuth()
  const queryClient = useQueryClient()

  async function onRequestSuccess() {
    await queryClient.invalidateQueries(["service", "services", "servicesBySteps"])
  }

  async function onRequestError({ httpCode }: RequestError) {
    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  const validateFiscalRetention = useMutation("validateFiscalRetention", validateFiscalRetentionReqFunction, {
    onSuccess: onRequestSuccess,
    onError: (error: RequestError) => onRequestError(error)
  })

  return { validateFiscalRetention }
}
