import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CreateInvoiceBody {
  aggregateId?: string | null
  approvedReportAttachmentId: string
  billingEmail: string
  cityId: string
  collectorId?: string | null
  regionalId: string
  reportFinalDate: string
  reportStartDate: string
}

interface CreateInvoiceProps {
  body: CreateInvoiceBody
}

export async function createInvoice({
  body
}: CreateInvoiceProps) {
  try {
    const response = await api.post(`/invoices`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
