import { Button, Flex, Input } from "@chakra-ui/react";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface InternCltsOfficesTableFiltersSchema {
  name: string
}

export function InternCltsOfficesTableFilters() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const name = searchParams.get('name')
  const {
    register,
    handleSubmit,
    reset,
  } = useForm<InternCltsOfficesTableFiltersSchema>({
    defaultValues: {
      name,
    }
  })

  async function handleFilterInternCltsOffices(data: InternCltsOfficesTableFiltersSchema) {
    if (data.name) {
      searchParams.set('name', data.name)
    } else {
      searchParams.delete('name')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleClearFilters() {
    searchParams.delete('name')

    searchParams.set('page', '1')

    reset({
      name: '',
    })

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterInternCltsOffices)}
    >

      <Input
        {...register("name")}
        name="name"
        placeholder="Nome"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />

      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={{ base: "full", lg: 'min' }}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        w={{ base: "full", lg: 'min' }}
        onClick={handleClearFilters}
      >
        Remover filtros
      </Button>

    </Flex>
  )
}
