import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface EvaluateDocumentBody {
  updatedById: string
  updateDate: string
  nextReviewDate: string
  classification: string
}

interface EvaluateDocumentProps {
  body: EvaluateDocumentBody
  routeParams: {
    documentId: string
  }
}

export async function evaluateDocument({
  body,
  routeParams
}: EvaluateDocumentProps) {
  try {
    const response = await api.put(`/documents/${routeParams.documentId}/evaluate`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
