import { Divider, Flex, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { TextArea } from "components/Inputs/TextInput";
import { ThirdPartyRnc } from "hooks/rnc/dtos/ThirdPartyRnc";
import { FaExclamationCircle, FaInfo, FaMoneyBillAlt, FaRegCalendarAlt, FaUserAlt } from "react-icons/fa";
import { formatDate } from "utils/DateFunctions/formatDate";

interface ViewThirdPartyRncInfoModalProps {
  isOpen: boolean
  onClose: () => void
  thirdPartyRnc: ThirdPartyRnc
}

export function ViewThirdPartyRncInfoModal({
  isOpen,
  onClose,
  thirdPartyRnc
}: ViewThirdPartyRncInfoModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      {thirdPartyRnc && (
        <ModalContent maxH='800px' overflow='auto'>
          <ModalHeader>Informações da rnc - {thirdPartyRnc.type.toLocaleLowerCase()}</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Flex direction='column' gap={2}>
              <Flex align='center' gap={2}>
                <Icon h='full' as={FaUserAlt} />
                {thirdPartyRnc.collector && (
                  <Text as='b'>{thirdPartyRnc.collector.trading_name.split(' ').map(word => {
                    return word[0] + word.substring(1, word.length).toLowerCase();
                  }).join(' ')}</Text>
                )}
                {thirdPartyRnc.provider && (
                  <Text as='b'>{thirdPartyRnc.provider.trading_name}</Text>
                )}
              </Flex>
              <Text>Informações de abertura</Text>
              <Divider />

              <Flex justify='space-between'>
                <Flex align='center' gap={2}>
                  <Icon fontSize='sm' as={FaRegCalendarAlt} />
                  <Text fontSize='sm'>{formatDate.handle(thirdPartyRnc.created_at, 'DateWithoutHourToShow')}</Text>
                </Flex>
                <Flex align='center' gap={2}>
                  <Icon fontSize='sm' as={FaMoneyBillAlt} />
                  <Text fontSize='sm'>
                    {new Intl
                      .NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      })
                      .format(thirdPartyRnc.loss_value)
                    }
                  </Text>
                </Flex>
              </Flex>

              <TextArea
                minH='200px'
                name='description'
                defaultValue={thirdPartyRnc.description}
                label='Descrição'
                size='sm'
                isReadOnly
              />

              {(thirdPartyRnc.status === 'awaiting-validation' || thirdPartyRnc.status === 'finalized') && (
                <>
                  <Text>Informações de resposta</Text>
                  <Divider />

                  <RadioGroup size='sm'>
                    <Stack direction='row'>
                      <Radio
                        isDisabled
                        isChecked={thirdPartyRnc.proceed}
                      >
                        Procede
                      </Radio>
                      <Radio
                        isDisabled
                        isChecked={!thirdPartyRnc.proceed}
                      >
                        Não procede
                      </Radio>
                    </Stack>
                  </RadioGroup>

                  {thirdPartyRnc.proceed ? (
                    <Stack direction='column' spacing={2}>
                      <TextArea
                        name='cause'
                        value={thirdPartyRnc.cause}
                        label='Causa'
                        size='sm'
                        isReadOnly
                      />
                      <TextArea
                        name='corrective'
                        value={thirdPartyRnc.corrective}
                        label='Corretiva'
                        size='sm'
                        isReadOnly
                      />
                      <TextArea
                        name='preventive'
                        value={thirdPartyRnc.preventive}
                        label='Preventiva'
                        size='sm'
                        isReadOnly
                      />
                    </Stack>
                  ) : (
                    <TextArea
                      name='why_not_proceed'
                      label='Por que não procede?'
                      size='sm'
                      value={thirdPartyRnc.why_not_proceed}
                      isReadOnly
                    />
                  )}
                </>
              )}

              {thirdPartyRnc.status === 'finalized' && (
                <>
                  <Text>Informações de validação</Text>
                  <Divider />

                  <Flex gap={2} align='center'>
                    <Icon fontSize='sm' as={FaExclamationCircle} />
                    <Text fontSize='sm'>{thirdPartyRnc.risk_rating}</Text>
                  </Flex>

                  <Flex gap={2} align='center'>
                    <Icon fontSize='sm' as={FaInfo} />
                    <Text fontSize='sm'>{thirdPartyRnc.validation_cause}</Text>
                  </Flex>
                </>
              )}

            </Flex>

          </ModalBody>

        </ModalContent>
      )}

    </Modal>
  )
}
