import { useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { Occurrence } from "./dtos/Occurrence";

interface OccurrencesQueryParams {
  step?: 'AWAITING-PROCEDURE' | 'AWAITING-ALIGNMENT' | 'AWAITING-ATTACHMENT' | 'OCCURRENCE-FINALIZED'
  type?: 'CLIENTE' | 'COLETADOR' | 'MOTORISTA' | 'TRANSPORTADORA'
  steps?: Array<'AWAITING-PROCEDURE' | 'AWAITING-ALIGNMENT' | 'AWAITING-ATTACHMENT' | 'OCCURRENCE-FINALIZED'>
  customer_id?: string
}

type QueryOptions = UseQueryOptions<Occurrence[], unknown, Occurrence[], "occurrences">

interface UseOccurrencesOptions {
  queryOptions?: QueryOptions
  queryParams?: OccurrencesQueryParams
}

async function getOccurrences(
  queryParams?: OccurrencesQueryParams
) {
  try {
    const { data } = await api.get(`/occurrence`, {
      params: {
        steps: queryParams?.steps?.join(','),
        ...queryParams
       }
    })

    return data
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

export function useOccurrences(options?: UseOccurrencesOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    errorMessage(message)

    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) await handleLogout()

  }

  return useQuery(
    "occurrences", () => getOccurrences(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )
}
