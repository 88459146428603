import { Box, Button, Flex, Heading, IconButton, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { createInternClt } from "api/internClts/createInternClt";
import { set } from "date-fns";
import { useToastify } from "hooks/toastify/useToastify";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import { getInputDateYearsMonthAndDateSplited } from "utils/getInputDateYearsMonthAndDateSplited";
import { getInputTimeHoursAndMinutesSplited } from "utils/getInputTimeHoursAndMinutesSplited";
import { CreateInternCltOfficeModal } from "./components/CreateInternCltOfficeModal";
import { InternCltForm, InternCltFormSchema } from "./components/InternCltForm";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { FaArrowLeft } from "react-icons/fa6";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "hooks/auth/useAuth";
import { InternCltBenefitName } from "api/internClts/_types/internClt";

const createInternCltSchema = yup.object().shape({
  name: yup.string().required(),
  cpf: yup.string().required(),
  rg: yup.string().required(),
  bornDate: yup.string().required(),
  addressStreet: yup.string().required(),
  addressNumber: yup.string().required(),
  addressNeighborhood: yup.string().required(),
  addressComplement: yup.mixed(),
  addressCity: yup.string().required(),
  addressCep: yup.string().required(),
  addressState: yup.string().required(),
  email: yup.string().required(),
  regional: yup.string().required(),
  admissionDate: yup.string().required(),
  salary: yup.string().required(),
  office: yup.object().required(),
  hasLeadershipGratification: yup.string().required(),
  leadershipGratificationValue: yup.mixed().when('hasLeadershipGratification', {
    is: 'yes',
    then: yup.string().required()
  }),
  hasCommission: yup.string().required(),
  commissionDescription: yup.mixed().when('hasCommission', {
    is: 'yes',
    then: yup.string().required()
  }),
  journeyType: yup.string().required(),
  workStartHour: yup.string().required(),
  workEndHour: yup.string().required(),
  workIntervalStartHour: yup.string().required(),
  workIntervalEndHour: yup.string().required(),
  saturdayWorkStartHour: yup.mixed().when('workWeekDays', {
    is: (value: string[]) => !!value && value.includes('6'),
    then: yup.string().required()
  }),
  saturdayWorkEndHour: yup.mixed().when('workWeekDays', {
    is: (value: string[]) =>!!value && value.includes('6'),
    then: yup.string().required()
  }),
  saturdayWorkIntervalStartHour: yup.mixed().when('workWeekDays', {
    is: (value: string[]) =>!!value && value.includes('6'),
    then: yup.string().required()
  }),
  saturdayWorkIntervalEndHour: yup.mixed().when('workWeekDays', {
    is: (value: string[]) =>!!value && value.includes('6'),
    then: yup.string().required()
  }),
  sundayWorkStartHour: yup.mixed().when('workWeekDays', {
    is: (value: string[]) =>!!value && value.includes('0'),
    then: yup.string().required()
  }),
  sundayWorkEndHour: yup.mixed().when('workWeekDays', {
    is: (value: string[]) =>!!value && value.includes('0'),
    then: yup.string().required()
  }),
  sundayWorkIntervalStartHour: yup.mixed().when('workWeekDays', {
    is: (value: string[]) =>!!value && value.includes('0'),
    then: yup.string().required()
  }),
  sundayWorkIntervalEndHour: yup.mixed().when('workWeekDays', {
    is: (value: string[]) =>!!value && value.includes('0'),
    then: yup.string().required()
  }),
  workWeekDays: yup.mixed().when('journeyType', {
    is: 'days',
    then: yup.array().min(1)
  }),
  benefits: yup.array().of(yup.string()),
  hasHealthInsuranceDependents: yup.mixed().when('benefits', {
    is: (value: InternCltBenefitName[]) => !!value?.find(v => v === 'health-insurance'),
    then: yup.string().required(),
  }),
  vrDailyValue: yup.mixed().when('benefits', {
    is: (value: InternCltBenefitName[]) => !!value?.find(v => v === 'vr'),
    then: yup.string().required()
  }),
  vaDailyValue: yup.mixed().when('benefits', {
    is: (value: InternCltBenefitName[]) => !!value?.find(v => v === 'va'),
    then: yup.string().required()
  }),
  vtDailyValue: yup.mixed().when('benefits', {
    is: (value: InternCltBenefitName[]) => !!value?.find(v => v === 'vt'),
    then: yup.string().required()
  }),
  fuelMonthlyValue: yup.mixed().when('benefits', {
    is: (value: InternCltBenefitName[]) => !!value?.find(v => v === 'fuel-aid'),
    then: yup.string().required()
  }),
  phoneAssistanceValue: yup.mixed().when('benefits', {
    is: (value: InternCltBenefitName[]) => !!value?.find(v => v === 'phone-assistance'),
    then: yup.string().required()
  }),
  vtTypes: yup.mixed().when('benefits', {
    is: (value: InternCltBenefitName[]) => !!value?.find(v => v === 'vt'),
    then: yup.array().min(1)
  }),
  phone: yup.string().required(),
})

export function CreateInternClt() {

  const formMethods = useForm<InternCltFormSchema>({
    resolver: yupResolver(createInternCltSchema),
  })

  const { userLogged } = useAuth()

  const userCanCreateInternClt = userLogged?.permissions.includes('create-intern-clt')

  const history = useHistory()

  useEffect(() => {
    if (!userCanCreateInternClt) history.push('/')
  }, [history, userCanCreateInternClt])

  const { mutateAsync: createInternCltFn } = useMutation({
    mutationFn: createInternClt,
    onSuccess: () => history.push('/internos-clt')
  })

  const { handleSubmit } = formMethods
  const { promiseMessage } = useToastify()

  async function handleCreateInternClt(values: InternCltFormSchema) {

    await promiseMessage(createInternCltFn({
      body: {
        ...values,
        officeId: values.office.value,
        salaryInCents: values.salary ? Math.ceil(transformStringToNumber(values.salary) * 100) : null,
        benefits: values.benefits,
        hasCommission: values.hasCommission === 'yes',
        hasHealthInsuranceDependents: values.hasHealthInsuranceDependents === 'yes',
        hasLeadershipGratification: values.hasLeadershipGratification === 'yes',
        leadershipGratificationValueInCents: values.hasLeadershipGratification === 'yes'
          ? Math.ceil(transformStringToNumber(values.leadershipGratificationValue) * 100)
          : null,
        workStartHour: set(new Date(), {
          ...getInputTimeHoursAndMinutesSplited(values.workStartHour)
        }),
        workEndHour: set(new Date(), {
          ...getInputTimeHoursAndMinutesSplited(values.workEndHour)
        }),
        workIntervalStartHour: set(new Date(), {
          ...getInputTimeHoursAndMinutesSplited(values.workIntervalStartHour)
        }),
        workIntervalEndHour: set(new Date(), {
          ...getInputTimeHoursAndMinutesSplited(values.workIntervalEndHour)
        }),
        saturdayWorkStartHour: values.saturdayWorkStartHour
          ? set(new Date(), {
            ...getInputTimeHoursAndMinutesSplited(values.saturdayWorkStartHour)
          })
          : null,
        saturdayWorkEndHour: values.saturdayWorkEndHour
          ? set(new Date(), {
            ...getInputTimeHoursAndMinutesSplited(values.saturdayWorkEndHour)
          })
          : null,
        saturdayWorkIntervalStartHour: values.saturdayWorkIntervalStartHour
          ? set(new Date(), {
            ...getInputTimeHoursAndMinutesSplited(values.saturdayWorkIntervalStartHour)
          })
          : null,
        saturdayWorkIntervalEndHour: values.saturdayWorkIntervalEndHour
          ? set(new Date(), {
            ...getInputTimeHoursAndMinutesSplited(values.saturdayWorkIntervalEndHour)
          })
          : null,
        sundayWorkStartHour: values.sundayWorkStartHour
          ? set(new Date(), {
            ...getInputTimeHoursAndMinutesSplited(values.sundayWorkStartHour)
          })
          : null,
        sundayWorkEndHour: values.sundayWorkEndHour
          ? set(new Date(), {
            ...getInputTimeHoursAndMinutesSplited(values.sundayWorkEndHour)
          })
          : null,
        sundayWorkIntervalStartHour: values.sundayWorkIntervalStartHour
          ? set(new Date(), {
            ...getInputTimeHoursAndMinutesSplited(values.sundayWorkIntervalStartHour)
          })
          : null,
        sundayWorkIntervalEndHour: values.sundayWorkIntervalEndHour
          ? set(new Date(), {
            ...getInputTimeHoursAndMinutesSplited(values.sundayWorkIntervalEndHour)
          })
          : null,
        admissionDate: set(new Date(), {
          ...getInputDateYearsMonthAndDateSplited(values.admissionDate)
        }),
        bornDate: set(new Date(), {
          ...getInputDateYearsMonthAndDateSplited(values.bornDate)
        }),
        vrDailyValueInCents: values.vrDailyValue ? Math.ceil(transformStringToNumber(values.vrDailyValue) * 100) : null,
        vaDailyValueInCents: values.vaDailyValue ? Math.ceil(transformStringToNumber(values.vaDailyValue) * 100) : null,
        fuelMonthlyValueInCents: values.fuelMonthlyValue ? Math.ceil(transformStringToNumber(values.fuelMonthlyValue) * 100) : null,
        phoneAssistanceValueInCents: values.phoneAssistanceValue ? Math.ceil(transformStringToNumber(values.phoneAssistanceValue) * 100) : null,
        vtDailyValueInCents: values.vtDailyValue
          ? Math.ceil(transformStringToNumber(values.vtDailyValue) * 100)
          : null,
      }
    }), 'Clt interno criado com sucesso!')
  }

  const {
    isOpen: isCreateInternCltOfficeModalOpen,
    onOpen: onOpenCreateInternCltOfficeModal,
    onClose: onCloseCreateInternCltOfficeModal
  } = useDisclosure()

  return (
    <Box
      rounded="md"
      p={4}
      bg="white"
    >
      <Flex gap={3} align="center">
        <IconButton
          aria-label="Voltar à lista de internos clt"
          icon={<FaArrowLeft />}
          variant="ghost"
          size="sm"
          onClick={() => history.goBack()}
        />
        <Heading letterSpacing="tight">Criar Interno CLT</Heading>
      </Flex>

      <Modal
        isOpen={isCreateInternCltOfficeModalOpen}
        onClose={onCloseCreateInternCltOfficeModal}
        isCentered
      >
        <ModalOverlay />

        <CreateInternCltOfficeModal
          onClose={onCloseCreateInternCltOfficeModal}
        />

      </Modal>

      <FormProvider {...formMethods}>
        <Flex
          direction="column"
          w="full"
          gap={3}
          as="form"
          onSubmit={handleSubmit(handleCreateInternClt)}
        >
          <InternCltForm
            onOpenCreateInternCltOfficeModal={onOpenCreateInternCltOfficeModal}
            slug='create'
          />

          <Button
            alignSelf="end"
            type="submit"
            colorScheme="blue"
            isLoading={formMethods.formState.isSubmitting}
          >
            Salvar
          </Button>
        </Flex>

      </FormProvider>

    </Box>
  )
}
