import { usePagination } from "@ajna/pagination"
import { IconButton, ModalCloseButton, ModalContent, ModalHeader, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { getServicesRecurrentsByBudget } from "api/budgets/getServicesRecurrentsByBudget"
import { format, parseISO } from "date-fns"
import { useSearchParams } from "hooks/useSearchParams"
import { FaExternalLinkAlt } from "react-icons/fa"
import { useQuery } from "react-query"
import { Link, useHistory } from "react-router-dom"
import { Pagination } from "components/Pagination/Pagination";

interface BudgetServicesRecurrentsProps {
  budgetId: string
}
const daysOfWeek = {
  0: 'DOMINGO',
  1: 'SEGUNDA',
  2: 'TERÇA',
  3: 'QUARTA',
  4: 'QUINTA',
  5: 'SEXTA',
  6: 'SÁBADO',
}
export function BudgetServicesRecurrents({ budgetId }: BudgetServicesRecurrentsProps) {
  const { push: redirect, replace } = useHistory()
  const searchParams = useSearchParams()

  const page = searchParams.get('page') ?? '1'

  const { data: services, isLoading: isServicesLoading } =
    useQuery({
      queryKey: ['budget-services-recurrents', budgetId, page],
      queryFn: () => getServicesRecurrentsByBudget({
        budgetId,
        currentPage: page,
        pageSize: '10',
      })
    })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: services?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: services?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }


  return (
    <ModalContent maxW={'880px'} p='4'>
      <ModalHeader letterSpacing="tight">Serviços Recorrentes</ModalHeader>
      <ModalCloseButton />
      {isServicesLoading ? (
        <Spinner />
      ) : (
        <TableContainer
          border="1px solid"
          borderColor="gray.200"
          rounded="md"
          mt="4"
        >
          <Table size="sm">
            <Thead>
              <Tr
                h="40px"
                bg="#38c3fa"
              >
                <Th color="white">Serviço</Th>
                <Th color="white">Horário Inicial da Coleta</Th>
                <Th color="white">Horário Final da Coleta</Th>
                <Th color="white">Horário da Entrega</Th>
                <Th color="white">Dias da Semana</Th>
              </Tr>
            </Thead>
            <Tbody>
              {services?.recurrentServices?.map((service) => {
                return (
                  <Tr>
                    <Td >
                      <IconButton
                        aria-label="Ir para o serviço"
                        as={Link}
                        icon={<FaExternalLinkAlt />}
                        size="sm"
                        onClick={() => redirect(`/servico/recorrente/visualizar/${service.id}`)}
                      />
                    </Td>
                    <Td>{format(parseISO(service.collect_hour_start), 'HH:mm')}</Td>
                    <Td>{format(parseISO(service.collect_hour_end), 'HH:mm')}</Td>
                    <Td>{format(parseISO(service.delivery_hour), 'HH:mm')}</Td>
                    <Td>{
                      service.days_of_week.map(day => {
                        return daysOfWeek[day]
                      }).join(', ')
                    }</Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </ModalContent>
  )
}
