import { DispatchStockStepTypes } from "api/dispachStocks/dispatchStock";
import { DispatchStockProposalStatusTypes } from "api/dispachStocks/dispatchStockProposal";

export const dispatchStockStepMap: Record<DispatchStockStepTypes, string> = {
  pending: 'Aprovando materiais',
  'head-confirming': 'Aguardando aprovação da matriz',
  'collector-confirming': 'Aguardando aprovação do coletador',
  'dispatched': 'Despachado'
}


export const dispatchStockProposalStatusMap: Record<DispatchStockProposalStatusTypes, string> = {
  'approving': 'Aguardando Aprovação',
  'reproved': 'Reprovada',
  'sending': 'Aguardando Envio',
  'sended': 'Enviada'
}
