import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface SendCltDriverPontoMaisRegisterDetailsBody {
  hasRegisteredOnPontoMais: boolean
  hasRegisteredEmail: boolean
  hasRegisteredPhoto: boolean
  shouldAdvanceStatus: boolean
}

interface SendCltDriverPontoMaisRegisterDetailsProps {
  body: SendCltDriverPontoMaisRegisterDetailsBody
  cltDriverId: string
}

export async function sendCltDriverPontoMaisRegisterDetails({
  body,
  cltDriverId
}: SendCltDriverPontoMaisRegisterDetailsProps) {
  try {
    const response = await api.put(`/clt-drivers/${cltDriverId}/hire/pontomais-details`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
