import { usePagination } from "@ajna/pagination";
import { Box, Button, Checkbox, CheckboxGroup, Flex, Heading, IconButton, Input, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Spinner, Stack, Table, TableContainer, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { getDeliveryServicesRelation } from "api/service/getDeliveryServicesRelation";
import { Pagination } from "components/Pagination/Pagination";

import { useAuth } from "hooks/auth/useAuth";
import { useAvailableServiceFunctions } from "hooks/services/available/useAvailableServiceFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaFilter, FaRedo, FaSearch, FaTimes } from "react-icons/fa";
import { FaArrowsRotate } from "react-icons/fa6";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { DeliveriesTableRow } from "./components/DeliveriesTableRow";

interface DeliveriesFiltersSchema {
  protocol: string
  customer: string
  deliveryDate: string
  deliveryHour: string
  withdrawInfo: string
  sourceAddress: string
  destinationAddress: string
  steps: string[]
  sourceCollector: string
  destinationCollector: string
}

export function Deliveries() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE
  const { push: redirect, replace } = useHistory()
  const { userLogged } = useAuth()
  const searchParams = useSearchParams()
  const queryClient = useQueryClient()

  const page = searchParams.get('page') ?? '1'
  const userLoggedHasViewDeliveriesPermission = userLogged?.permissions.includes("view-deliveries")

  useEffect(() => {
    if (!userLoggedHasViewDeliveriesPermission) {
      redirect('/')
    }
  }, [userLoggedHasViewDeliveriesPermission, redirect])

  const protocol = searchParams.get('protocol')
  const customer = searchParams.get('customer')
  const deliveryDate = searchParams.get('deliveryDate')
  const deliveryHour = searchParams.get('deliveryHour')
  const withdrawInfo = searchParams.get('withdrawInfo')
  const sourceAddress = searchParams.get('sourceAddress')
  const destinationAddress = searchParams.get('destinationAddress')
  const steps = searchParams.get('steps')
  const sourceCollector = searchParams.get('sourceCollector')
  const destinationCollector = searchParams.get('destinationCollector')

  const {
    control,
    register,
    handleSubmit,
    reset,
  } = useForm<DeliveriesFiltersSchema>({
    defaultValues: {
      protocol,
      customer,
      deliveryDate,
      deliveryHour,
      withdrawInfo,
      sourceAddress,
      destinationAddress,
      sourceCollector,
      destinationCollector,
      steps: steps ? decodeURI(steps).split(',') : [
        'toBoardValidate',
        'toAllocateService',
        'toAvailableService',
        'availableService',
        'toLandingService',
        'landingService',
        'toMaintenanceService',
        'ongoingMaintenanceService',
        'toValidateFiscalRetention',
        'toDeliveryService',
        'deliveringService',
      ]
    }
  })

  const {
    data: deliveryServicesRelationResult,
    isLoading: isLoadingDeliveryServicesRelationResult
  } = useQuery({
    queryKey: [
      'delivery-services-relation',
      page,
      protocol,
      customer,
      deliveryDate,
      deliveryHour,
      withdrawInfo,
      sourceAddress,
      destinationAddress,
      steps,
      sourceCollector,
      destinationCollector,
      userLogged?.collector_id,
    ],
    queryFn: () => getDeliveryServicesRelation({
      currentPage: page,
      pageSize: rowsPerPage,
      protocol,
      customer,
      sourceAddress,
      destinationAddress,
      deliveryDate,
      deliveryHour,
      withdrawInfo,
      sourceCollector,
      destinationCollector,
      timezoneOffset: String(new Date().getTimezoneOffset() / 60),
      collectorId: userLogged?.collector_id,
      steps: steps ?? [
        'toBoardValidate',
        'toAllocateService',
        'toAvailableService',
        'availableService',
        'toLandingService',
        'landingService',
        'toMaintenanceService',
        'ongoingMaintenanceService',
        'toValidateFiscalRetention',
        'toDeliveryService',
        'deliveringService',
      ].join(','),
    }),
  })

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: deliveryServicesRelationResult?.meta.totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: page ? Number(page) : 1,
      },
    });

  const handleChangePage = (page: number) => {
    setCurrentPage(page)
    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  function handleFilterDeliveries(data: DeliveriesFiltersSchema) {
    if (data.deliveryDate) {
      searchParams.set('deliveryDate', data.deliveryDate)
    } else {
      searchParams.delete('deliveryDate')
    }

    if (data.deliveryHour) {
      searchParams.set('deliveryHour', data.deliveryHour)
    } else {
      searchParams.delete('deliveryHour')
    }

    if (data.withdrawInfo) {
      searchParams.set('withdrawInfo', data.withdrawInfo)
    } else {
      searchParams.delete('withdrawInfo')
    }

    if (data.customer) {
      searchParams.set('customer', data.customer)
    } else {
      searchParams.delete('customer')
    }

    if (data.destinationAddress) {
      searchParams.set('destinationAddress', data.destinationAddress)
    } else {
      searchParams.delete('destinationAddress')
    }

    if (data.protocol) {
      searchParams.set('protocol', data.protocol)
    } else {
      searchParams.delete('protocol')
    }

    if (data.sourceAddress) {
      searchParams.set('sourceAddress', data.sourceAddress)
    } else {
      searchParams.delete('sourceAddress')
    }


    if (data.sourceCollector) {
      searchParams.set('sourceCollector', data.sourceCollector)
    } else {
      searchParams.delete('sourceCollector')
    }

    if (data.destinationCollector) {
      searchParams.set('destinationCollector', data.destinationCollector)
    } else {
      searchParams.delete('destinationCollector')
    }


    if (data.steps) {
      searchParams.set('steps', data.steps.join(','))
    } else {
      searchParams.delete('steps')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    searchParams.delete('deliveryDate')
    searchParams.delete('deliveryHour')
    searchParams.delete('withdrawInfo')
    searchParams.delete('customer')
    searchParams.delete('destinationAddress')
    searchParams.delete('protocol')
    searchParams.delete('sourceAddress')
    searchParams.delete('sourceCollector')
    searchParams.delete('destinationCollector')

    searchParams.set('page', '1')

    reset({
      protocol: '',
      customer: '',
      deliveryDate: '',
      deliveryHour: '',
      withdrawInfo: '',
      sourceAddress: '',
      destinationAddress: '',
      sourceCollector: '',
      destinationCollector: ''
    })

    replace({ search: searchParams.toString() })

  }

  function handleUpdateTable() {
    queryClient.invalidateQueries(['in-delivery-services'])
  }

  const {
    updateAllocateServicesToLandingService: {
      mutateAsync: updateAllocateServicesToLandingService,
    },
  } = useAvailableServiceFunctions()

  const { promiseMessage } = useToastify()

  async function handleUpdateAvailableServices() {
    await promiseMessage(updateAllocateServicesToLandingService(), 'Os serviços disponíveis foram disponibilizados com sucesso!')
  }

  return (

    <Box
      borderRadius='8px'
      p={4}
      bg='white'
    >
      <Flex
        w="full"
        direction="column"
        gap={6}
      >
        <Heading letterSpacing="tight">Entregas</Heading>

        {isLoadingDeliveryServicesRelationResult ? (
          <Spinner />
        ) : (
          <Box
            as="form"
            onSubmit={handleSubmit(handleFilterDeliveries)}
          >
            <Flex
              align="center"
              gap="2"
              mb="6"
            >
              <Button
                type="submit"
                size="sm"
                letterSpacing="1"
                leftIcon={<FaSearch />}
              >
                Filtrar resultados
              </Button>

              <Button
                type="button"
                size="sm"
                letterSpacing="1"
                leftIcon={<FaTimes />}
                variant="ghost"
                onClick={handleRemoveFilters}
              >
                Remover filtros
              </Button>

              <Button
                type="button"
                size="sm"
                letterSpacing="1"
                leftIcon={<FaArrowsRotate />}
                variant="ghost"
                onClick={handleUpdateAvailableServices}
              >
                Disponibilizar serviços
              </Button>
              <Button
                type="button"
                size="sm"
                letterSpacing="1"
                leftIcon={<FaRedo />}
                variant="ghost"
                onClick={handleUpdateTable}
              >
                Atualizar página
              </Button>

            </Flex>

            <TableContainer
              rounded="md"
              border="1px solid"
              borderColor="gray.100"
            >
              <Table
                size="sm"
              >
                <Thead
                  h="40px"
                  bg="#38c3fa"
                >
                  <Tr>
                    <Th></Th>
                    <Th w="200px" textColor="white">
                      <Flex align="center" gap="2">
                        Protocolo
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de protocolo"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="200px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              <Input
                                {...register('protocol')}
                                name="protocol"
                                placeholder="Protocolo"
                                size="sm"
                                rounded="md"
                                color="gray.900"
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th w="200px" textColor="white">
                      <Flex align="center" gap="2">
                        Cliente
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de cliente"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="400px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              <Input
                                {...register('customer')}
                                name="customer"
                                placeholder="Cliente"
                                size="sm"
                                rounded="md"
                                color="gray.900"
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th w="400px" textColor="white">
                      <Flex align="center" gap="2">
                        Remetentes
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de remetentes"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="400px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              <Input
                                {...register('sourceAddress')}
                                name="sourceAddress"
                                placeholder="Rua, Responsável, Bairro, etc."
                                size="sm"
                                rounded="md"
                                color="gray.900"
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th w="400px" textColor="white">
                      <Flex align="center" gap="2">
                        Retirada
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de remetentes"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="400px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              <Input
                                {...register('withdrawInfo')}
                                name="withdrawInfo"
                                placeholder="Base, Transportadora, etc."
                                size="sm"
                                rounded="md"
                                color="gray.900"
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th w="200px" textColor="white">
                      <Flex align="center" gap="2">
                        Data limite de entrega
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de data limite de entrega"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="200px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              <Input
                                {...register('deliveryDate')}
                                name="deliveryDate"
                                placeholder="Data limite de embaque"
                                size="sm"
                                type="date"
                                rounded="md"
                                color="gray.900"
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th w="200px" textColor="white">
                      <Flex align="center" gap="2">
                        Horário limite de entrega
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de horário limite de entrega"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="200px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              <Input
                                {...register('deliveryHour')}
                                name="deliveryHour"
                                size="sm"
                                type="time"
                                rounded="md"
                                color="gray.900"
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th w="400px" textColor="white">
                      <Flex align="center" gap="2">
                        Destinatários
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de destinatários"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="400px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              <Input
                                {...register('destinationAddress')}
                                name="destinationAddress"
                                placeholder="Rua, Responsável, Bairro, etc."
                                size="sm"
                                rounded="md"
                                color="gray.900"
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th textColor="white">
                      <Flex align="center" gap="2">
                        Etapa
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de etapa(s)"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent>
                            <PopoverArrow />
                            <PopoverBody>
                              <Controller
                                control={control}
                                name="steps"
                                render={({ field }) => {
                                  return (
                                    <CheckboxGroup
                                      colorScheme="blue"
                                      onChange={field.onChange}
                                      value={field.value}
                                    >
                                      <Stack
                                        spacing="4"
                                      >
                                        <Checkbox color="gray.900" value="toBoardValidate">
                                          <Text fontSize="xs">Embarcado (Validação)</Text>
                                        </Checkbox>
                                        <Checkbox color="gray.900" value="toAllocateService">
                                          <Text fontSize="xs">Embarcado (Alocação)</Text>
                                        </Checkbox>
                                        <Checkbox color="gray.900" value="toAvailableService">
                                          <Text fontSize="xs">Alocado</Text>
                                        </Checkbox>
                                        <Checkbox color="gray.900" value="availableService">
                                          <Text fontSize="xs">Aguardando disponibilização</Text>
                                        </Checkbox>
                                        <Checkbox color="gray.900" value="toLandingService">
                                          <Text fontSize="xs">Disponível</Text>
                                        </Checkbox>
                                        <Checkbox color="gray.900" value="landingService">
                                          <Text fontSize="xs">Desembarcando</Text>
                                        </Checkbox>
                                        <Checkbox color="gray.900" value="toMaintenanceService">
                                          <Text fontSize="xs">Em manutenção</Text>
                                        </Checkbox>
                                        <Checkbox color="gray.900" value="ongoingMaintenanceService">
                                          <Text fontSize="xs">Manutenção em andamento</Text>
                                        </Checkbox>
                                        <Checkbox color="gray.900" value="toValidateFiscalRetention">
                                          <Text fontSize="xs">Em retenção fiscal</Text>
                                        </Checkbox>
                                        <Checkbox color="gray.900" value="toDeliveryService">
                                          <Text fontSize="xs">Em rota de entrega</Text>
                                        </Checkbox>
                                        <Checkbox color="gray.900" value="deliveringService">
                                          <Text fontSize="xs">Entregando</Text>
                                        </Checkbox>
                                      </Stack>
                                    </CheckboxGroup>
                                  )
                                }}
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th textColor="white">
                      <Flex align="center" gap="2">
                        Coletador origem
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de coletador de origem"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent>
                            <PopoverArrow />
                            <PopoverBody>
                              <Input
                                {...register('sourceCollector')}
                                name="sourceCollector"
                                placeholder="Coletador"
                                size="sm"
                                rounded="md"
                                color="gray.900"
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th textColor="white">
                      <Flex align="center" gap="2">
                        Coletador destino
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de coletador de destino"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent>
                            <PopoverArrow />
                            <PopoverBody>
                              <Input
                                {...register('destinationCollector')}
                                name="destinationCollector"
                                placeholder="Coletador."
                                size="sm"
                                rounded="md"
                                color="gray.900"
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {deliveryServicesRelationResult?.services.map(service => {
                    return (
                      <DeliveriesTableRow key={service.id} service={service} />
                    )
                  })}
                </Tbody>
              </Table>

            </TableContainer>
          </Box>
        )}

        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />
      </Flex>
    </Box>

  )
}
