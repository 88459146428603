import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface FinishVacationPeriodBody {
  withPontomaisAllowance: boolean
  isFuelBenefitBlocked: boolean
}

interface FinishVacationPeriodProps {
  vacationId: string
  body: FinishVacationPeriodBody
}

export async function finishVacationPeriod({
  body,
  vacationId
}: FinishVacationPeriodProps) {
  try {
    const response = await api.put(`/vacations/${vacationId}/finish-period`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
