import * as yup from 'yup'

const serviceEditValidateSchema = yup.object().shape({
  customer_id: yup.string().required('Campo obrigatório'),
  situation: yup.string().when('is_recurrent_page', {
    is: true,
    then: yup.string().required('Campo Obrigatório'),
  }),
  budget_id: yup.string().required('Campo obrigatório'),
  is_unique_collect_address_service: yup.boolean(),
  source_address_id: yup
    .array()
    .min(1, "Campo obrigatório")
    .required("Campo obrigatório")
    .when("is_edit_page", {
      is: true,
      then: yup.array()
        .min(1, "Campo obrigatório")
        .required("Campo obrigatório")
        .when("is_unique_collect_address_service", {
          is: true,
          then: yup.array()
            .min(1, "Campo obrigatório")
            .max(1, "O orçamento não possui cobrança para endereços adicionais, selecione apenas 1 endereço")
            .required("Campo obrigatório")
        }),
    }),

  destination_address_id: yup.array().min(1, "Campo obrigatório").required("Campo obrigatório"),

  is_edit_page: yup.boolean(),

  has_crossdocking: yup.string().when('is_edit_page', {
    is: false,
    then: yup.string().when('service_type', {
      is: 'FRACIONADO',
      then: yup.string().required('Campo Obrigatório'),
    }),
  }),

  shipping_id: yup.string().when('has_crossdocking', {
    is: 'SIM',
    then: yup.string().required('Campo Obrigatório'),
  }),

  source_collector_id: yup.string().when('is_edit_page', {
    is: false,
    then: yup.string().required('Campo Obrigatório'),
  }),

  crossdocking_collector_id: yup.string().when('is_edit_page', {
    is: false,
    then: yup.string().when('has_crossdocking', {
      is: 'SIM',
      then: yup
        .string()
        .required('Campo Obrigatório')
        .notOneOf(
          [yup.ref('source_collector_id'), null],
          'O coletador (crossdocking) não pode ser o mesmo que o de origem!',
        ),
    }),
  }),

  destination_collector_id: yup.string().when('is_edit_page', {
    is: false,
    then: yup.string().required('Campo Obrigatório'),
  }),

  source_branch_id: yup.string().when('is_edit_page', {
    is: false,
    then: yup.string().when('service_type', {
      is: 'FRACIONADO',
      then: yup.string().required('Campo Obrigatório'),
    }),
  }),

  source_crossdocking_branch_id: yup.string().when('is_edit_page', {
    is: false,
    then: yup.string().when('has_crossdocking', {
      is: 'SIM',
      then: yup.string().required('Campo Obrigatório'),
    }),
  }),

  destination_crossdocking_branch_id: yup.string().when('is_edit_page', {
    is: false,
    then: yup.string().when('has_crossdocking', {
      is: 'SIM',
      then: yup.string().required('Campo Obrigatório'),
    }),
  }),

  destination_branch_id: yup.string().when('is_edit_page', {
    is: false,
    then: yup.string().when('service_type', {
      is: 'FRACIONADO',
      then: yup.string().required('Campo Obrigatório'),
    }),
  }),

  provider_id: yup.string().when('is_edit_page', {
    is: false,
    then: yup.string().when('gelo_seco', {
      is: (value: number) => value > 0,
      then: yup.string().required('Campo Obrigatório'),
    }),
  }),

  deadline: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  service_type: yup.string().required('Campo Obrigatório'),

  modal: yup.string().required('Campo Obrigatório'),
  crossdocking_modal: yup.string().when('is_edit_page', {
    is: false,
    then: yup.string().when('has_crossdocking', {
      is: 'SIM',
      then: yup
        .string()
        .required('Campo Obrigatório')
        .notOneOf(
          [yup.ref('source_collector_id'), null],
          'O coletador (crossdocking) não pode ser o mesmo que o de origem!',
        ),
    }),
  }),

  crossdocking_planned_flight: yup.string(),

  planned_flight: yup.string().when('is_edit_page', {
    is: false,
    then: yup.string().when('service_type', {
      is: 'FRACIONADO',
      then: yup.string().required('Campo Obrigatório'),
    }),
  }),

  has_connection: yup
    .string()
    .nullable()
    .when('is_edit_page', {
      is: false,
      then: yup
        .string()
        .nullable()
        .when('modal', {
          is: 'AÉREO',
          then: yup.string().required('Campo Obrigatório'),
        }),
    }),

  has_connection_crossdocking: yup
    .string()
    .nullable()
    .when('is_edit_page', {
      is: false,
      then: yup
        .string()
        .nullable()
        .when('crossdocking_modal', {
          is: 'AÉREO',
          then: yup.string().required('Campo Obrigatório'),
        }),
    }),

  connection_branch_id: yup
    .string()
    .nullable()
    .when('has_connection', {
      is: 'SIM',
      then: yup.string().required('Campo Obrigatório'),
    }),

  crossdocking_connection_branch_id: yup
    .string()
    .nullable()
    .when('has_connection_crossdocking', {
      is: 'SIM',
      then: yup.string().required('Campo Obrigatório'),
    }),

  planned_connection_flight: yup
    .string()
    .nullable()
    .when('has_connection', {
      is: 'SIM',
      then: yup.string().required('Campo Obrigatório'),
    }),

  crossdocking_planned_connection_flight: yup
    .string()
    .nullable()
    .when('has_connection_crossdocking', {
      is: 'SIM',
      then: yup.string().required('Campo Obrigatório'),
    }),

  vehicle: yup.string().required('Campo Obrigatório'),
  franchising: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  caixa_termica: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  embalagem_secundaria: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  gelo_seco: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  gelox: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  isopor3l: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  isopor7l: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  terciaria3l: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  terciaria8l: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),

  collect_date: yup.string().required('Campo obrigatório'),
  collect_hour_start: yup.string().required('Campo obrigatório'),
  collect_hour_end: yup.string().required('Campo obrigatório'),
  delivery_date: yup.string().required('Campo obrigatório'),
  delivery_hour: yup.string().required('Campo obrigatório'),

  crossdocking_board_date: yup.date().when('is_edit_page', {
    is: false,
    then: yup.date().when('has_crossdocking', {
      is: 'SIM',
      then: yup.date().when('service_type', {
        is: 'FRACIONADO',
        then: yup
          .date()
          .typeError('Campo Obrigatório')
          .required('Campo Obrigatório'),
      }),
    }),
  }),

  crossdocking_board_hour: yup.string().when('is_edit_page', {
    is: false,
    then: yup.string().when('has_crossdocking', {
      is: 'SIM',
      then: yup.string().when('service_type', {
        is: 'FRACIONADO',
        then: yup.string().required('Campo Obrigatório'),
      }),
    }),
  }),

  board_date: yup.date().when('is_edit_page', {
    is: false,
    then: yup.date().when('service_type', {
      is: 'FRACIONADO',
      then: yup
        .date()
        .typeError('Campo Obrigatório')
        .required('Campo Obrigatório'),
    }),
  }),

  board_hour: yup.string().when('is_edit_page', {
    is: false,
    then: yup.string().when('service_type', {
      is: 'FRACIONADO',
      then: yup.string().required('Campo Obrigatório'),
    }),
  }),

  crossdocking_availability_forecast_day: yup.string().when('is_edit_page', {
    is: false,
    then: yup.string().when('service_type', {
      is: 'FRACIONADO',
      then: yup.string().when('has_crossdocking', {
        is: 'SIM',
        then: yup.string().required('Campo Obrigatório'),
      }),
    }),
  }),

  crossdocking_availability_forecast_time: yup.string().when('is_edit_page', {
    is: false,
    then: yup.string().when('service_type', {
      is: 'FRACIONADO',
      then: yup.string().when('has_crossdocking', {
        is: 'SIM',
        then: yup.string().required('Campo Obrigatório'),
      }),
    }),
  }),

  availability_forecast_day: yup.string().when('is_edit_page', {
    is: false,
    then: yup.string().when('service_type', {
      is: 'FRACIONADO',
      then: yup.string().required('Campo Obrigatório'),
    }),
  }),

  availability_forecast_time: yup.string().when('is_edit_page', {
    is: false,
    then: yup.string().when('service_type', {
      is: 'FRACIONADO',
      then: yup.string().required('Campo Obrigatório'),
    }),
  }),

  material_type: yup.string().required('Campo Obrigatório'),
  observation: yup.string(),
})

export { serviceEditValidateSchema }
