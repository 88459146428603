import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Stack,
} from '@chakra-ui/react'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useEffect, useState, useContext } from 'react'

import { CSVLink } from 'react-csv'
import { handleChangeHeaders } from '../../utils/ReportFunctions/handleChangeHeaders'

import { ReportContext } from '../../contexts/ReportContext'
import { reportFormatDataToBack } from '../../utils/ReportFunctions/reportFormatDateToBack'
import { StockTable } from '../../components/Tables/Report/Stock/Stocktable'
import {
  reportBillingFormatDataToFront,
  reportCargoFormatDataToFront,
  reportCteFormatDataToFront,
  reportOccurrenceFormatDataToFront,
  reportSlaFormatDataToFront,
  reportStockFormatDataToFront,
} from '../../utils/ReportFunctions/reportFormatDatatoFront'
import { CustomerProps } from '../../contexts/CustomerContext'
import { AuthContext } from '../../contexts/AuthContext'

import { CteReportTable } from '../../components/Tables/Report/Cte/CteReportTable'
import { BillingTable } from '../../components/Tables/Report/Billing/BillingTable'
import { CargoTable } from '../../components/Tables/Report/Cargo/CargoTable'

import { SlaTable } from '../../components/Tables/Report/sla/SlaTable'
import { Select } from '../../components/Inputs/SelectInput'
import { Input } from '../../components/Inputs/Input'

import { useCustomer } from '../../hooks/customer/useCustomer'
import { handleChangeReportFormTilte } from '../../utils/ReportFunctions/handleChangeTitle'
import { StockControlTable } from '../../components/Tables/Report/StockControl/StockControlTable'
import { OccurrenceReportTable } from '../../components/Tables/Report/Occurrence/OccurrenceReportTable'
import { RncReportTable } from '../../components/Tables/Report/Rnc/RncReportTable'
import { useContentLoading } from '../../hooks/loading/useContentLoading'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { EmptyContentTableAlert } from '../../components/Alerts/EmptyContentTableAlert'
import { switchReportFilters } from '../../utils/CustomLists/switchReportFilters'
import { useShipping } from '../../hooks/shipping/useShipping'
import { useCollectors } from 'hooks/collector/useCollectors'
import { Collector } from 'hooks/collector/dtos/Collector'
import { add } from 'date-fns'
import { formatDate } from 'utils/DateFunctions/formatDate'

interface FormInputProps {
  find_by: string
  service_id: string
  driver_id: string
  shipping_id: string
  customer_id: string
  collector_id: string
  startFilter: string
  endFilter: string
}

interface ReportProps {
  isDisabled?: boolean
}

interface QueryParams {
  slug: string
}

const schema = yup.object().shape({
  find_by: yup.string().required('Campo Obrigatório'),
  has_start_and_end_filter: yup.boolean(),
  customer_id: yup.string().when('find_by', {
    is: 'CLIENTE',
    then: yup.string().required('Campo Obrigatório'),
  }),
  collector_id: yup.string().when('find_by', {
    is: 'COLETADOR',
    then: yup.string().required('Campo Obrigatório'),
  }),
  shipping_id: yup.string().when('find_by', {
    is: 'TRANSPORTADORA',
    then: yup.string().required('Campo Obrigatório'),
  }),
  startFilter: yup.string().required('Campo Obrigatório'),
  endFilter: yup.string().required('Campo Obrigatório'),
  // endFilter: yup.string().when("has_start_and_end_filter", {
  //   is: true,
  //   then: yup.string().required('Campo Obrigatório'),
  // })
})

export function Report({ isDisabled = false }: ReportProps) {
  const [showTable, setShowTable] = useState<boolean>(false)
  const [customers, setCustomers] = useState<CustomerProps[]>([])
  const [collectors, setCollectors] = useState<Collector[]>([])

  const { userLogged } = useContext(AuthContext)

  const {
    slaReport,
    stockReport,
    billingReport,
    cargoReport,
    cteReport,
    stockControllerReport,
    occurrenceReport,
    sendSlaDate,
    rncReport,
    customerManagmentReport,
    sendStockData,
    sendBillingData,
    sendCargoData,
    sendCteData,
    sendStockControllerData,
    sendOccurrenceData,
    sendRncData,
    sendCustomerManagmentReportData,
  } = useContext(ReportContext)

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    resolver: yupResolver(schema),
  })

  const { slug }: QueryParams = useParams()
  const { handleSetIsContentLoadingFalse, isContentLoading } =
    useContentLoading()

  const history = useHistory()

  const findBySelected = watch('find_by')
  const startDate = watch('startFilter')
  const finalDate = watch('endFilter')

  const isValidStartDate = new Date(startDate) instanceof Date &&
    !isNaN(new Date(startDate).getMilliseconds())

  const maxEndFilter = isValidStartDate
    ? formatDate.handle(add(new Date(startDate), {
      days: 31
    }), 'DateWithoutHourToInput')
    : ''

  const {
    customers: { data: customersFiltered, isLoading: isCustomersLoading },
  } = useCustomer(null, true, false)
  const {
    data: collectorsFiltered, isLoading: isCollectorsLoading,
  } = useCollectors()
  const {
    shipping: { data: shipping, isLoading: isShippingLoading },
  } = useShipping(null, true, false)

  useEffect(() => {
    if (!isCustomersLoading && !isCollectorsLoading && !isShippingLoading) {
      handleSetIsContentLoadingFalse()
    }
  }, [
    isCustomersLoading,
    isCollectorsLoading,
    handleSetIsContentLoadingFalse,
    isShippingLoading,
  ])

  const switchDataCsv = () => {
    switch (slug) {
      case 'sla':
        return reportSlaFormatDataToFront(slaReport)
      case 'estoque':
        return reportStockFormatDataToFront(stockReport)
      case 'faturamento':
        return reportBillingFormatDataToFront(billingReport)
      case 'cargas':
        return reportCargoFormatDataToFront(cargoReport)
      case 'cte':
        return reportCteFormatDataToFront(cteReport)
      case 'controle-estoque':
        return stockControllerReport
      case 'ocorrencias':
        return reportOccurrenceFormatDataToFront(occurrenceReport)
      case 'gerencial-cliente':
        return customerManagmentReport
      default:
        return []
    }
  }

  useEffect(() => {
    function run() {
      if (!!customersFiltered && !!collectorsFiltered) {
        setCustomers(customersFiltered)
        setCollectors(collectorsFiltered)
      }
    }
    run()
  }, [customersFiltered, collectorsFiltered])

  useEffect(() => {
    function run() {
      if (slug === 'controle-estoque') {
        setValue('startFilter', ' ')
        setValue('endFilter', ' ')
      }

      if (slug === 'coletador') {
        setValue('find_by', 'COLETADOR')
      }
    }
    run()
  }, [setValue, slug])

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (slug === 'sla') {
            if (!permissions?.includes('view-sla-report')) {
              history.push('/')
            }
          }
          if (slug === 'estoque') {
            if (!permissions?.includes('view-stock-report')) {
              history.push('/')
            }
          }
          if (slug === 'faturamento') {
            if (!permissions?.includes('view-billing-report')) {
              history.push('/')
            }
          }
          if (slug === 'cargas') {
            if (
              !permissions?.includes('view-cargo-in-transit-report') &&
              userLogged?.user_type !== 'COLETADOR'
            ) {
              history.push('/')
            }
          }
          if (slug === 'cte') {
            if (!permissions?.includes('view-cte-report')) {
              history.push('/')
            }
          }
          if (slug === 'controle-estoque') {
            if (!permissions?.includes('view-stock-control-report')) {
              history.push('/')
            }
          }
          if (slug === 'ocorrencias') {
            if (
              !permissions?.includes('view-occurrence-report') &&
              userLogged?.user_type !== 'COLETADOR'
            ) {
              history.push('/')
            }
          }
          if (slug === 'rnc') {
            if (!permissions?.includes('view-rnc-report')) {
              history.push('/')
            }
          }
          if (slug === 'coletador') {
            if (!permissions?.includes('view-collector-report')) {
              history.push('/')
            }
          }
          if (slug === 'gerencial-cliente') {
            if (!permissions?.includes('view-customer-managment-report')) {
              history.push('/')
            }
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [slug, history, userLogged])

  useEffect(() => {
    function run() {
      if (userLogged?.user_type === 'CLIENTE') {
        setValue('find_by', 'CLIENTE')
        setValue('customer_id', userLogged?.customer_id)
      }
      if (userLogged?.user_type === 'COLETADOR') {
        setValue('find_by', 'COLETADOR')
        setValue('collector_id', userLogged?.collector_id)
      }
    }
    run()
  }, [setValue, userLogged, slug])

  const csvReport = {
    data: switchDataCsv(),
    headers: handleChangeHeaders(slug, findBySelected),
    filename:
      slug !== 'ocorrencias' && slug !== 'controle-estoque'
        ? `relatorio-${slug}-${startDate}_${finalDate}.csv`
        : `relatorio-${slug}.csv`,
  }

  useEffect(() => {
    function run() {
      history.listen((location) => {
        if (location.pathname) {
          reset()
        }
      })
    }
    run()
  }, [history, reset])

  async function handleGenerateReportInformation(values: FormInputProps) {
    const formatedData = reportFormatDataToBack(values, userLogged)

    if (slug === 'sla') {
      await sendSlaDate(formatedData)
    }
    if (slug === 'estoque') {
      await sendStockData(formatedData)
    }
    if (slug === 'faturamento') {
      await sendBillingData(formatedData)
    }
    if (slug === 'cargas') {
      await sendCargoData(formatedData)
    }
    if (slug === 'cte') {
      await sendCteData(formatedData)
    }
    if (slug === 'controle-estoque') {
      await sendStockControllerData(formatedData)
    }
    if (slug === 'ocorrencias') {
      await sendOccurrenceData(formatedData)
    }
    if (slug === 'rnc') {
      await sendRncData(formatedData)
    }
    if (slug === 'gerencial-cliente') {
      await sendCustomerManagmentReportData(formatedData)
    }
  }

  function handleResetValues() {
    setValue('collector_id', '')
    setValue('customer_id', '')
    setValue('shipping_id', '')
    if (slug !== 'controle-estoque') {
      setValue('startFilter', '')
      setValue('endFilter', '')
    }
  }

  if (isContentLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          overflowX="auto"
          p={['6', '8']}
          onSubmit={handleSubmit(handleGenerateReportInformation)}
          noValidate
        >
          <Heading size="lg" fontFamily="poppins">
            {handleChangeReportFormTilte(slug)}
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          <Stack>
            <Select
              {...register('find_by')}
              name="find_by"
              label="Pesquisar por"
              find_by_options={switchReportFilters.handle(slug)}
              isDisabled={
                userLogged?.user_type === 'CLIENTE' ||
                userLogged?.user_type === 'COLETADOR' ||
                slug === 'coletador'
              }
              onClick={() => handleResetValues()}
              placeholder="Selecione uma opção"
              required
            />
          </Stack>

          {findBySelected === 'CLIENTE' && (
            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              <Select
                {...register('customer_id')}
                name="customer_id"
                label="Pertence ao Cliente"
                customers={customers}
                error={errors.customer_id}
                isDisabled={userLogged?.user_type === 'CLIENTE'}
                placeholder="Selecione uma opção"
                required
              />

              <Stack
                w="full"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  {...register('startFilter')}
                  isDisabled={isDisabled}
                  name="startFilter"
                  type="date"
                  label="Início do Período"
                  error={errors.startFilter}
                  required
                />

                <Input
                  {...register('endFilter')}
                  isDisabled={isDisabled}
                  name="endFilter"
                  type="date"
                  label="Fim do Período"
                  error={errors.endFilter}
                  max={maxEndFilter}
                  required
                />
              </Stack>
            </Stack>
          )}

          {findBySelected === 'GERAL' && slug !== 'controle-estoque' && (
            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              <Input
                {...register('startFilter')}
                isDisabled={isDisabled}
                name="startFilter"
                type="date"
                label="Início do Período"
                error={errors.startFilter}
                required
              />

              <Input
                {...register('endFilter')}
                isDisabled={isDisabled}
                name="endFilter"
                type="date"
                label="Fim do Período"
                error={errors.endFilter}
                max={maxEndFilter}
                required
              />
            </Stack>
          )}

          {findBySelected === 'COLETADOR' && (
            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              <Select
                {...register('collector_id')}
                name="collector_id"
                label="Pertence ao Coletador"
                collectors={collectors}
                error={errors.collector_id}
                isDisabled={userLogged?.user_type === 'COLETADOR'}
                placeholder="Selecione uma opção"
                required
              />
              {slug !== 'controle-estoque' && (
                <Stack
                  w="full"
                  spacing="24px"
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    {...register('startFilter')}
                    isDisabled={isDisabled}
                    name="startFilter"
                    type="date"
                    label="Início do Período"
                    error={errors.startFilter}
                    required
                  />

                  <Input
                    {...register('endFilter')}
                    isDisabled={isDisabled}
                    name="endFilter"
                    type="date"
                    label="Fim do Período"
                    error={errors.endFilter}
                    max={maxEndFilter}
                    required
                  />
                </Stack>
              )}
            </Stack>
          )}
          {findBySelected === 'MOTORISTA' && (
            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              <Input
                {...register('startFilter')}
                isDisabled={isDisabled}
                name="startFilter"
                type="date"
                label="Início do Período"
                error={errors.startFilter}
                required
              />

              <Input
                {...register('endFilter')}
                isDisabled={isDisabled}
                name="endFilter"
                type="date"
                label="Fim do Período"
                error={errors.endFilter}
                max={maxEndFilter}
                required
              />
            </Stack>
          )}
          {findBySelected === 'TRANSPORTADORA' && (
            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              <Select
                {...register('shipping_id')}
                name="shipping_id"
                label="Transportadora"
                shipping={shipping}
                error={errors.shipping_id}
                isDisabled={userLogged?.user_type === 'TRANSPORTADORA'}
                placeholder="Selecione uma opção"
                required
              />
              <Stack
                w="full"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  {...register('startFilter')}
                  isDisabled={isDisabled}
                  name="startFilter"
                  type="date"
                  label="Início do Período"
                  error={errors.startFilter}
                  required
                />

                <Input
                  {...register('endFilter')}
                  isDisabled={isDisabled}
                  name="endFilter"
                  type="date"
                  label="Fim do Período"
                  error={errors.endFilter}
                  max={maxEndFilter}
                  required
                />
              </Stack>
            </Stack>
          )}

          {startDate &&
            finalDate &&
            slug === 'sla' &&
            showTable &&
            (slaReport.length > 0 ? (
              <Stack mt="4">
                <SlaTable />
              </Stack>
            ) : (
              <Stack mt="8">
                <EmptyContentTableAlert
                  title="Oops!"
                  description="Não há dados para mostrar aqui!"
                />
              </Stack>
            ))}

          {startDate &&
            finalDate &&
            slug === 'estoque' &&
            showTable &&
            (stockReport.length > 0 ? (
              <Stack mt="4">
                <StockTable />
              </Stack>
            ) : (
              <Stack mt="8">
                <EmptyContentTableAlert
                  title="Oops!"
                  description="Não há dados para mostrar aqui!"
                />
              </Stack>
            ))}

          {startDate &&
            finalDate &&
            slug === 'faturamento' &&
            showTable &&
            (billingReport.length > 0 ? (
              <Stack mt="4">
                <BillingTable />
              </Stack>
            ) : (
              <Stack mt="8">
                <EmptyContentTableAlert
                  title="Oops!"
                  description="Não há dados para mostrar aqui!"
                />
              </Stack>
            ))}

          {startDate &&
            finalDate &&
            slug === 'cargas' &&
            showTable &&
            (cargoReport.length > 0 ? (
              <Stack mt="4">
                <CargoTable />
              </Stack>
            ) : (
              <Stack mt="8">
                <EmptyContentTableAlert
                  title="Oops!"
                  description="Não há dados para mostrar aqui!"
                />
              </Stack>
            ))}

          {startDate &&
            finalDate &&
            slug === 'cte' &&
            showTable &&
            (cteReport.length > 0 ? (
              <Stack mt="4">
                <CteReportTable />
              </Stack>
            ) : (
              <Stack mt="8">
                <EmptyContentTableAlert
                  title="Oops!"
                  description="Não há dados para mostrar aqui!"
                />
              </Stack>
            ))}

          {slug === 'controle-estoque' &&
            showTable &&
            (stockControllerReport.length > 0 ? (
              <Stack mt="4">
                <StockControlTable />
              </Stack>
            ) : (
              <Stack mt="8">
                <EmptyContentTableAlert
                  title="Oops!"
                  description="Não há dados para mostrar aqui!"
                />
              </Stack>
            ))}

          {slug === 'ocorrencias' &&
            showTable &&
            (occurrenceReport.length > 0 ? (
              <Stack mt="4">
                <OccurrenceReportTable />
              </Stack>
            ) : (
              <Stack mt="8">
                <EmptyContentTableAlert
                  title="Oops!"
                  description="Não há dados para mostrar aqui!"
                />
              </Stack>
            ))}

          {slug === 'rnc' &&
            showTable &&
            (rncReport.length > 0 ? (
              <Stack mt="4">
                <RncReportTable />
              </Stack>
            ) : (
              <Stack mt="8">
                <EmptyContentTableAlert
                  title="Oops!"
                  description="Não há dados para mostrar aqui!"
                />
              </Stack>
            ))}


          <Flex mt="8" justify="flex-end">
            <HStack>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
                loadingText="Carregando"
                onClick={() => setShowTable(true)}
              >
                Buscar Informações
              </Button>

              {startDate &&
                finalDate &&
                (slaReport.length > 0 ||
                  stockReport.length > 0 ||
                  billingReport.length > 0 ||
                  cargoReport.length > 0 ||
                  cteReport.length > 0 ||
                  stockControllerReport.length > 0 ||
                  occurrenceReport.length > 0 ||
                  rncReport.length > 0
                  ) && (
                  <CSVLink {...csvReport} separator={`;`} enclosingCharacter={`"`}>
                    <Button
                      type="button"
                      colorScheme="green"
                      isLoading={isSubmitting}
                      loadingText="Carregando"
                    >
                      Exportar CSV
                    </Button>
                  </CSVLink>
                )}
            </HStack>
          </Flex>
        </Box>
      </Flex>

  )
}
