import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface ReproveTrainingLlmParams {
  trainingLlmId: string
}

export async function reproveTrainingLlm({
  trainingLlmId,
}: ReproveTrainingLlmParams) {
  try {
    await api.patch(`/llm/trainings/${trainingLlmId}/reprove`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
