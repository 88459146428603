import { useEffect, useMemo, useState } from "react";

import * as yup from "yup";
import { Button, Flex } from "@chakra-ui/react";
import { addDays, differenceInDays, set } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useHistory, useParams } from "react-router-dom";
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form";

import { useSwal } from "hooks/swal/useSwal";
import { useToastify } from "hooks/toastify/useToastify";
import { formatDate } from "utils/DateFunctions/formatDate";
import { useRequestedService } from "hooks/services/requestedService";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { ValidateRequestedServiceForm, ValidateRequestedServiceFormInputs } from "./components/ValidateRequestedServiceForm";
import { useRequestServiceFunctions, ValidateRequestedServiceInputProps } from "hooks/services/request/useRequestServiceFunctions";
import { useHolidays } from "hooks/holiday/useHolidays";
import { useAddresses } from "hooks/address/useAddresses";
import { validateIsHolidayOnServiceCollectDeliveryDate } from "utils/ServiceFunctions/Validations/holiday/validateIsHolidayOnServiceCollectDeliveryDate";
import { splitPlannedRouteValue } from "utils/ServiceFunctions/splitPlannedRouteValue";
import { useQueryClient } from "react-query";

interface Params {
  serviceId: string
}

function testIfCollectDateIsMoreThan15DaysToNow(collectDate: string | undefined, context: yup.TestContext) {
  if (collectDate && context) {


    const [year, month, date] = collectDate?.split('-').map(collectDateArgs => Number(collectDateArgs))

    const difference = differenceInDays(
      set(new Date(), { date, month: month - 1, year }),
      new Date()
    )

    if (difference > 15) return context?.createError({ message: 'A data do agendamento da coleta deve ser de no máximo 15 dias a partir de hoje.' })


    return true
  }
  return false
}

function generateTimestampWithDateAndHourInput(
  dateInput: string,
  dateHours: string
) {
  const [hours, minutes] = dateHours.split(':').map(value => Number(value))
  const [year, month, date] = dateInput.split('-').map(value => Number(value))

  const timestamp = set(new Date(), {
    hours,
    minutes,
    date,
    month: month - 1,
    year
  })

  return timestamp
}

const editValidatedRequestedServiceSchema = yup.object().shape({
  customer_id: yup.string().required('Campo obrigatório'),
  budget_id: yup.string().required('Campo obrigatório'),
  source_address_id: yup.array().of(yup.string()).min(1, "Deve ser selecionado ao menos um endereço").required('Campo obrigatório'),
  destination_address_id: yup.array().of(yup.string()).min(1, "Deve ser selecionado ao menos um endereço").required('Campo obrigatório'),

  source_collector_id: yup.string().required('Campo obrigatório'),
  crossdocking_collector_id: yup.string().when('has_crossdocking', {
    is: 'SIM',
    then: yup.string().required('Campo obrigatório')
  }),
  destination_collector_id: yup.string().required('Campo obrigatório'),

  has_crossdocking: yup.string(),
  has_connection: yup.string().when(['modal', 'service_type', 'gelo_seco'], {
    is: (modal: string, service_type: string, gelo_seco: number) => modal === 'AÉREO' && service_type === 'FRACIONADO' && gelo_seco === 0,
    then: yup.string().required('Campo obrigatório')
  }),
  shipping_id: yup.string().when('has_crossdocking', {
    is: 'SIM',
    then: yup.string().required('Campo obrigatório')
  }),
  source_branch_id: yup.string().when('service_type', {
    is: 'FRACIONADO',
    then: yup.string().required('Campo obrigatório')
  }),
  destination_branch_id: yup.string().when('service_type', {
    is: 'FRACIONADO',
    then: yup.string().required('Campo obrigatório')
  }),
  has_crossdocking_connection: yup.string().when(['crossdocking_modal', 'has_crossdocking', 'gelo_seco'], {
    is: (crossdocking_modal: string, has_crossdocking: string, gelo_seco: number) => crossdocking_modal === 'AÉREO' && has_crossdocking === 'SIM' && gelo_seco === 0,
    then: yup.string().required('Campo obrigatório')
  }),
  crossdocking_connection_branch_id: yup.string().when(["has_crossdocking", "has_crossdocking_connection"], {
    is: 'SIM',
    then: yup.string().required('Campo obrigatório')
  }),
  destination_crossdocking_branch_id: yup.string().when('has_crossdocking', {
    is: 'SIM',
    then: yup.string().required('Campo obrigatório')
  }),
  source_crossdocking_branch_id: yup.string().when('has_crossdocking', {
    is: 'SIM',
    then: yup.string().required('Campo obrigatório')
  }),
  connection_branch_id: yup.string().when('has_connection', {
    is: 'SIM',
    then: yup.string().required('Campo obrigatório')
  }),
  modal: yup.string().required('Campo obrigatório'),
  crossdocking_modal: yup.string().when('has_crossdocking', {
    is: 'SIM',
    then: yup.string().required('Campo obrigatório')
  }),
  crossdocking_planned_connection_flight: yup.string().when('has_crossdocking_connection', {
    is: 'SIM',
    then: yup.string().required('Campo obrigatório')
  }),
  crossdocking_planned_flight: yup.string().when('has_crossdocking', {
    is: 'SIM',
    then: yup.string().required('Campo obrigatório')
  }),
  planned_connection_flight: yup.string().when('has_connection', {
    is: 'SIM',
    then: yup.string().required('Campo obrigatório')
  }),
  planned_flight: yup.string().when('service_type', {
    is: 'FRACIONADO',
    then: yup.string().required('Campo obrigatório')
  }),
  deadline: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  service_type: yup.string().required('Campo obrigatório'),
  vehicle: yup.string().required('Campo obrigatório'),
  franchising: yup.string().required('Campo obrigatório'),
  caixa_termica: yup.number().min(0, "O valor mínimo deve ser 0").typeError("Campo obrigatório"),
  gelo_seco: yup.number().min(0, "O valor mínimo deve ser 0").typeError("Campo obrigatório"),
  gelox: yup.number().min(0, "O valor mínimo deve ser 0").typeError("Campo obrigatório"),
  isopor3l: yup.number().min(0, "O valor mínimo deve ser 0").typeError("Campo obrigatório"),
  isopor7l: yup.number().min(0, "O valor mínimo deve ser 0").typeError("Campo obrigatório"),
  terciaria3l: yup.number().min(0, "O valor mínimo deve ser 0").typeError("Campo obrigatório"),
  terciaria8l: yup.number().min(0, "O valor mínimo deve ser 0").typeError("Campo obrigatório"),
  embalagem_secundaria: yup.number().min(0, "O valor mínimo deve ser 0").typeError("Campo obrigatório"),
  provider_id: yup.string().when('gelo_seco', {
    is: (gelo_seco: number) => gelo_seco > 0,
    then: yup.string().required('Campo obrigatório')
  }),

  collect_date: yup.string().required('Campo obrigatório'),
  collect_hour_start: yup.string().required('Campo obrigatório'),
  collect_hour_end: yup.string().required('Campo obrigatório'),
  delivery_date: yup.string().required('Campo obrigatório'),
  delivery_hour: yup.string().required('Campo obrigatório'),

  board_date: yup.string().when('service_type', {
    is: 'FRACIONADO',
    then: yup.string().required('Campo obrigatório')
  }),
  board_hour: yup.string().when('service_type', {
    is: 'FRACIONADO',
    then: yup.string().required('Campo obrigatório')
  }),
  availability_forecast_day: yup.string().when('service_type', {
    is: 'FRACIONADO',
    then: yup.string().required('Campo obrigatório')
  }),
  availability_forecast_time: yup.string().when('service_type', {
    is: 'FRACIONADO',
    then: yup.string().required('Campo obrigatório')
  }),
  crossdocking_board_date: yup.string().when('has_crossdocking', {
    is: 'SIM',
    then: yup.string().required('Campo obrigatório'),
  }),
  crossdocking_board_hour: yup.string().when('has_crossdocking', {
    is: 'SIM',
    then: yup.string().required('Campo obrigatório'),
  }),
  crossdocking_availability_forecast_day: yup.string().when('has_crossdocking', {
    is: 'SIM',
    then: yup.string().required('Campo obrigatório'),
  }),
  crossdocking_availability_forecast_time: yup.string().when('has_crossdocking', {
    is: 'SIM',
    then: yup.string().required('Campo obrigatório'),
  }),

  material_type: yup.string().required('Campo obrigatório'),
  destination_addresses_input: yup.array().when('material_type', {
    is: (material_type: string) => ['CARGA GERAL', 'CORRELATOS']?.includes(material_type),
    then: yup.array().of(yup.object({
      address_id: yup.string().required('Campo obrigatório'),
      quantity: yup.number().required('Campo obrigatório').typeError('O valor precisa ser um número')
    }))
  })

})

export function EditValidatedRequestedService() {
  const [collectDateTimestamp, setCollectDateTimestamp] = useState<Date | null>(null)
  const [deliveryDateTimestamp, setDeliveryDateTimestamp] = useState<Date | null>()

  const { serviceId } = useParams<Params>()

  const { promiseMessage } = useToastify()
  const { standardMessage, confirmMessage } = useSwal()

  const { goBack } = useHistory()

  const {
    updateValidatedRequestedService: { mutateAsync: updateValidatedRequestedService }
  } = useRequestServiceFunctions()

  const {
    requestedServiceByServiceId: {
      data: requestedService,
      isFetching: isRequestedServiceFetching
    }
  } = useRequestedService(null, false, false, serviceId)

  const formMethods = useForm<ValidateRequestedServiceFormInputs>({
    defaultValues: {
      budget_id: requestedService?.budget_id ?? '',
      source_address_id: [],
      destination_address_id: [],
      has_crossdocking: "",
      has_connection: "NÃO",
    },
    resolver: yupResolver(editValidatedRequestedServiceSchema),
    shouldUnregister: true
  })

  const {
    control,
    setValue,
    handleSubmit,
    formState: {
      isSubmitting,
    }
  } = formMethods

  const {
    fields: destinationAddressesInputFields,
    update: updateDestinationAddressesInputFields,
    append: appendDestinationAddressesInputFields
  } = useFieldArray({
    name: 'destination_addresses_input',
    control,
  })

  useEffect(() => {
    if (requestedService) {
      setValue("source_address_id", requestedService.source_address_id)
      setValue("destination_address_id", requestedService.destination_address_id)

      const isCrossdockingService =
        requestedService.budgetIDService.crossdocking_hub_id !== null

      if (isCrossdockingService) {
        setValue("has_crossdocking", "SIM")
      } else {
        setValue("has_crossdocking", "NÃO")
      }
      setValue('protocol', String(requestedService?.serviceIDRequested?.protocol))
      setValue("service_type", requestedService.service_type)
      setValue("modal", requestedService.modal)
      setValue("deadline", requestedService.deadline)
      setValue("vehicle", requestedService.vehicle)
      setValue("franchising", requestedService.franchising)
      if (requestedService.budgetIDService.transfer_budget) {
        setValue("transfer_budget", requestedService.budgetIDService.transfer_budget)
      }
      setValue("caixa_termica", requestedService.caixa_termica)
      setValue("gelo_seco", requestedService.gelo_seco)
      setValue("gelox", requestedService.gelox)
      setValue("isopor3l", requestedService.isopor3l)
      setValue("isopor7l", requestedService.isopor7l)
      setValue("terciaria3l", requestedService.terciaria3l)
      setValue("terciaria8l", requestedService.terciaria8l)
      setValue("embalagem_secundaria", requestedService.embalagem_secundaria)

      setValue("collect_date", formatDate.handle(requestedService.collect_date, "DateWithoutHourToInput"))
      setValue("collect_hour_start", formatDate.handle(requestedService.collect_hour_start, "DateOnlyWithHourMinute"))
      setValue("collect_hour_end", formatDate.handle(requestedService.collect_hour_end, "DateOnlyWithHourMinute"))

      setValue("delivery_date", formatDate.handle(requestedService.delivery_date, "DateWithoutHourToInput"))
      setValue("delivery_hour", formatDate.handle(requestedService.delivery_hour, "DateOnlyWithHourMinute"))

      if (requestedService.board_date) {
        setValue("board_date", formatDate.handle(requestedService.board_date, "DateWithoutHourToInput"))
        setValue("board_hour", formatDate.handle(requestedService.board_hour, "DateOnlyWithHourMinute"))
        setValue("availability_forecast_day", formatDate.handle(requestedService.availability_forecast_day, "DateWithoutHourToInput"))
        setValue("availability_forecast_time", formatDate.handle(requestedService.availability_forecast_time, "DateOnlyWithHourMinute"))
      }

      if (requestedService.crossdocking_board_date) {
        setValue("crossdocking_board_date", formatDate.handle(requestedService.crossdocking_board_date, "DateWithoutHourToInput"))
        setValue("crossdocking_board_hour", formatDate.handle(requestedService.crossdocking_board_hour, "DateOnlyWithHourMinute"))
        setValue("crossdocking_availability_forecast_day", formatDate.handle(requestedService.crossdocking_availability_forecast_day, "DateWithoutHourToInput"))
        setValue("crossdocking_availability_forecast_time", formatDate.handle(requestedService.crossdocking_availability_forecast_time, "DateOnlyWithHourMinute"))
      }

      if (requestedService.planned_flight) {
        setValue('planned_flight', requestedService.planned_flight)
      }

      if (requestedService.crossdocking_planned_flight) {
        setValue('crossdocking_planned_flight', requestedService.crossdocking_planned_flight)
      }

      if (requestedService.material_type) {
        setValue("material_type", requestedService.material_type)
      }

      setValue("observation", requestedService.observation)

      if (requestedService.source_collector_id !== null) {
        setValue('source_collector_id', requestedService.source_collector_id)
      }

      if (requestedService.destination_collector_id !== null) {
        setValue('destination_collector_id', requestedService.destination_collector_id)
      }

      if (requestedService.crossdocking_collector_id) {
        setValue('crossdocking_modal', requestedService.crossdocking_modal!)
        setValue('crossdocking_collector_id', requestedService.crossdocking_collector_id)
      }

      if (requestedService.source_branch_id) {
        setValue('source_branch_id', requestedService.source_branch_id)
      }

      if (requestedService.destination_branch_id) {
        setValue('destination_branch_id', requestedService.destination_branch_id)
      }

      if (requestedService.source_crossdocking_branch_id) {
        setValue('shipping_id', requestedService.sourceCrossdockingBranchIDService.shipping_id)
        setValue('source_crossdocking_branch_id', requestedService.source_crossdocking_branch_id)
      }

      if (requestedService.destination_crossdocking_branch_id) {
        setValue('destination_crossdocking_branch_id', requestedService.destination_crossdocking_branch_id)
      }

    }
  }, [requestedService, updateDestinationAddressesInputFields, setValue])

  const collectDate = useWatch({ control, name: 'collect_date' })
  const deliveryDate = useWatch({ control, name: 'delivery_date' })

  useEffect(() => {
    const timeout = setTimeout(() => {
      const isValidCollectDate = new Date(collectDate) instanceof Date &&
        !isNaN(new Date(collectDate).getMilliseconds())

      if (isValidCollectDate) {
        setCollectDateTimestamp(() => set(addDays(new Date(collectDate), 1), {
          hours: 0,
          minutes: 0,
          seconds: 0
        }))
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [collectDate])

  useEffect(() => {
    const timeout = setTimeout(() => {
      const isValidDeliveryDate = new Date(deliveryDate) instanceof Date &&
        !isNaN(new Date(deliveryDate).getMilliseconds())

      if (isValidDeliveryDate) {
        setDeliveryDateTimestamp(() => set(addDays(new Date(deliveryDate), 1), {
          hours: 23,
          minutes: 59,
          seconds: 59
        }))
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [deliveryDate])

  const budgetInfo = useMemo(() => {
    return {
      source_cities: requestedService?.budgetIDService.source_cities,
      destination_cities: requestedService?.budgetIDService.destination_cities,
      source_hub_id: requestedService?.budgetIDService.source_hub_id,
      crossdocking_hub_id: requestedService?.budgetIDService.crossdocking_hub_id,
      destination_hub_id: requestedService?.budgetIDService.destination_hub_id,
    }
  }, [requestedService])

  const addressesCities = useMemo(() => {
    return Array.from(new Set(budgetInfo.source_cities?.concat(budgetInfo.destination_cities ?? [])))
  }, [budgetInfo])

  const isAddressQueryEnabled = requestedService?.serviceIDRequested.customer_id !== undefined

  const {
    data: addresses,
    isFetching: isAddressesFetching
  } = useAddresses({
    queryParams: {
      customer_id: requestedService?.serviceIDRequested.customer_id,
      cities: addressesCities,
      situation: "ATIVO"
    },
    queryOptions: { enabled: isAddressQueryEnabled }
  })

  const sourceAddressesSelected = useWatch({
    control,
    name: 'source_address_id'
  })

  const sourceAddressesSelectedCities = addresses
    ? addresses
      .filter(address => sourceAddressesSelected?.includes(address.id))
      .map(address => address.city_id)
    : []

  const {
    data: holidays,
    isFetching: isHolidaysFetching
  } = useHolidays({
    queryOptions: {
      enabled: collectDateTimestamp !== null && deliveryDateTimestamp !== null
    },
    queryParams: {
      start_date: collectDateTimestamp ?? undefined,
      end_date: deliveryDateTimestamp ?? undefined,
    }
  })

  const destinationAddressesSelected = useWatch({
    control,
    name: 'destination_address_id'
  })

  const destinationAddressesSelectedCities = addresses
    ? addresses
      .filter(address => destinationAddressesSelected?.includes(address.id))
      .map(address => address.city_id)
    : []

  const requestedServiceInfo = useMemo(() => {
    return {
      isFetching: isRequestedServiceFetching,
      source_addresses: requestedService?.source_address_id,
      customer_id: requestedService?.serviceIDRequested.customer_id,
      budget_id: requestedService?.budget_id,
      destination_addresses: requestedService?.destination_address_id,
      destination_addresses_input: requestedService?.destination_addresses_input as string[],
      serviceStep: requestedService?.serviceIDRequested?.step
    }
  }, [isRequestedServiceFetching, requestedService])

  const queryClient = useQueryClient()

  async function handleEditRequestedService(
    formValues: ValidateRequestedServiceFormInputs
  ) {

    let hasValidateRequestedServiceWithDifferentDeadline = true

    if (requestedService?.budgetIDService.deadline !== formValues.deadline) {
      hasValidateRequestedServiceWithDifferentDeadline = await confirmMessage({
        text: '',
        title: 'O prazo de entrega é diferente do orçamento, deseja continuar?',
        buttons: ['Não', 'Sim'],
      })
    }

    if (!hasValidateRequestedServiceWithDifferentDeadline) {
      return standardMessage('Ação cancelada com êxito!')
    }

    const holidayOnCollectDateMessageExists = validateIsHolidayOnServiceCollectDeliveryDate(
      'COLLECT',
      holidays ?? [],
      sourceAddressesSelectedCities,
      collectDateTimestamp as Date
    )

    let hasValidateRequestedServiceWithHolidayOnCollectDate = true

    if (holidayOnCollectDateMessageExists) {
      hasValidateRequestedServiceWithHolidayOnCollectDate = await confirmMessage({
        title: holidayOnCollectDateMessageExists,
        buttons: ['Não', 'Sim'],
        text: ''
      })
    }

    if (!hasValidateRequestedServiceWithHolidayOnCollectDate) {
      return standardMessage('Ação cancelada com êxito')
    }

    const holidayOnDeliveryDateMessageExists = validateIsHolidayOnServiceCollectDeliveryDate(
      'DELIVERY',
      holidays ?? [],
      destinationAddressesSelectedCities,
      deliveryDateTimestamp as Date
    )

    let hasValidateRequestedServiceWithHolidayOnDeliveryDate = true

    if (holidayOnDeliveryDateMessageExists) {
      hasValidateRequestedServiceWithHolidayOnDeliveryDate = await confirmMessage({
        title: holidayOnDeliveryDateMessageExists,
        buttons: ['Não', 'Sim'],
        text: ''
      })
    }

    if (!hasValidateRequestedServiceWithHolidayOnDeliveryDate) {
      return standardMessage('Ação cancelada com êxito')
    }

    const hasValidateRequestedService = await confirmMessage({
      title: 'Deseja validar um serviço solicitado?'
    })

    if (hasValidateRequestedService && requestedService) {

      const {
        collect_date,
        collect_hour_start,
        collect_hour_end,
        delivery_date,
        delivery_hour,
        board_date,
        board_hour,
        crossdocking_board_date,
        crossdocking_board_hour,
        availability_forecast_day,
        availability_forecast_time,
        crossdocking_availability_forecast_day,
        crossdocking_availability_forecast_time,
        crossdocking_planned_connection_flight,
        crossdocking_planned_flight,
        planned_connection_flight,
        planned_flight,
      } = formValues

      const collectDateWithCollectStartHour =
        generateTimestampWithDateAndHourInput(collect_date, collect_hour_start)

      const collectDateWithCollectEndHour =
        generateTimestampWithDateAndHourInput(collect_date, collect_hour_end)

      const deliveryDateWithDeliveryHour =
        generateTimestampWithDateAndHourInput(delivery_date, delivery_hour)

      const boardDateWithBoardHours = board_date
        ? generateTimestampWithDateAndHourInput(board_date, board_hour)
        : undefined

      const crossdockingBoardDateWithCrossdockingBoardHours =
        crossdocking_board_date
          ? generateTimestampWithDateAndHourInput(
            crossdocking_board_date,
            crossdocking_board_hour
          )
          : undefined

      const availabilityDateWithAvailabilityHours =
        availability_forecast_day
          ? generateTimestampWithDateAndHourInput(
            availability_forecast_day,
            availability_forecast_time
          )
          : undefined

      const availabilityCrossdockingDateWithAvailabilityCrossdockingHours =
        crossdocking_board_date
          ? generateTimestampWithDateAndHourInput(
            crossdocking_availability_forecast_day,
            crossdocking_availability_forecast_time
          )
          : undefined

      const plannedCrossdockingFlight = crossdocking_planned_connection_flight
        ? `${splitPlannedRouteValue(crossdocking_planned_connection_flight)} -> ${splitPlannedRouteValue(crossdocking_planned_flight)}`
        : crossdocking_planned_flight
          ? splitPlannedRouteValue(crossdocking_planned_flight)
          : undefined

      const plannedFlight = planned_connection_flight
        ? `${splitPlannedRouteValue(planned_connection_flight)} -> ${splitPlannedRouteValue(planned_flight)}`
        : planned_flight
          ? splitPlannedRouteValue(planned_flight)
          : undefined

      const validateRequestedServiceInput: ValidateRequestedServiceInputProps = {
        ...formValues,
        customer_id: requestedService.customer_id,
        budget_id: requestedService.budget_id,
        collect_date: collectDateWithCollectEndHour,
        collect_hour_start: collectDateWithCollectStartHour,
        collect_hour_end: collectDateWithCollectEndHour,
        delivery_date: deliveryDateWithDeliveryHour,
        delivery_hour: deliveryDateWithDeliveryHour,
        board_date: boardDateWithBoardHours,
        board_hour: boardDateWithBoardHours,
        crossdocking_board_date: crossdockingBoardDateWithCrossdockingBoardHours,
        crossdocking_board_hour: crossdockingBoardDateWithCrossdockingBoardHours,
        availability_forecast_day: availabilityDateWithAvailabilityHours,
        availability_forecast_time: availabilityDateWithAvailabilityHours,
        crossdocking_availability_forecast_day:
          availabilityCrossdockingDateWithAvailabilityCrossdockingHours,
        crossdocking_availability_forecast_time:
          availabilityCrossdockingDateWithAvailabilityCrossdockingHours,
        crossdocking_planned_flight: plannedCrossdockingFlight,
        planned_flight: plannedFlight
      }

      return await promiseMessage(updateValidatedRequestedService({
        serviceId: serviceId,
        input: validateRequestedServiceInput
      }, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['service'])
          goBack()
        }
      }), "Serviço editado com sucesso!")
    }

    return standardMessage('Ação cancelada com êxito!')
  }

  return (
    <StandardBackgroundForm
      title="Editar Serviço Solicitado"
      onSubmit={handleSubmit(handleEditRequestedService)}
    >
      <FormProvider {...formMethods}>
        <ValidateRequestedServiceForm
          addresses={addresses}
          budgetInfo={budgetInfo}
          isEditPage={true}
          isAddressesFetching={isAddressesFetching}
          requestedServiceInfo={requestedServiceInfo}
          destinationAddressesInputFields={destinationAddressesInputFields}
          appendDestinationAddressesInputFields={appendDestinationAddressesInputFields}
        />
      </FormProvider>

      <Flex
        justifyContent={["center", "center", "flex-end"]}
        flexDirection={["column", "column", "row"]}
        gap={4}
        mt={6}
      >
        <Button
          colorScheme="blue"
          w={["full", "full", "min"]}
          type="submit"
          isLoading={isSubmitting || isHolidaysFetching}
        >
          Editar
        </Button>
        <Button
          w={["full", "full", "min"]}
          as={Link}
          to="/servicos/solicitados"
        >
          Serviços solicitados
        </Button>
      </Flex>

    </StandardBackgroundForm>
  )
}
