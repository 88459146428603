import { Box, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { Invoice } from "api/invoices/_types/Invoice";
import { format, } from "date-fns";
import { FaCalendarAlt } from "react-icons/fa";

import { InvoiceDetails } from "./InvoiceDetails";

interface KanbanInvoiceCardProps {
  invoice: Invoice
}

export function KanbanInvoiceCard({ invoice }: KanbanInvoiceCardProps) {

  const {
    isOpen: isInvoiceDetailModalOpen,
    onToggle: onToggleInvoiceDetailModal,
  } = useDisclosure()


  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleInvoiceDetailModal}
      cursor='pointer'
    >

      <HStack justify="space-between" w="full">
        <Text fontWeight="bold" fontSize="lg">
          {invoice.collectorId ? invoice?.collector?.trading_name : invoice.aggregate?.trading_name}
        </Text>


      </HStack>

      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA INICIAL</Text>
          </HStack>
          <Text ml={5}>{format(new Date(invoice?.reportStartDate), "dd/MM/yyyy")}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA FINAL</Text>
          </HStack>
          <Text ml={5}>{format(new Date(invoice?.reportFinalDate), "dd/MM/yyyy")}</Text>
        </Box>

      </VStack>

      <Modal
        isOpen={isInvoiceDetailModalOpen}
        onClose={onToggleInvoiceDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <InvoiceDetails
          invoiceId={invoice.id}
        />
      </Modal>
    </Box>
  )
}
