import { createContext, ReactNode } from 'react'
import swal from 'sweetalert'
import { api } from '../services/api'

interface ProviderContextProviderProps {
  children: ReactNode
  pathname?: string
}

export interface ProviderProps {
  id: string
  company_name: string
  trading_name: string
  hub_id: string
  cnpj: string
  email: string
  material: string
  unit_cost: number
  payment_conditional: string
  day_expiration_1: string
  day_expiration_2: string
  payment_type: string
  cellphone: string
  telephone: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  business_open: string
  business_close: string
  saturday_open: string | null
  saturday_close: string | null
  sunday_open: string | null
  sunday_close: string | null
  situation: string
  observation: string
  material_price: number
  deadline: number
  hubIDProvider: {
    id: string
    name: string
  }
  is_primary_provider: boolean
}

type ProviderInput = Omit<ProviderProps, 'id' | 'hubIDProvider'>

interface ProviderContextProps {
  createProvider: (providerInput: ProviderInput) => Promise<boolean>
  deleteProvider: (providerId: string) => Promise<boolean | undefined>
  editProvider: (
    providerId: string,
    providerInput: ProviderInput,
  ) => Promise<boolean | undefined>
}

export const ProviderContext = createContext<ProviderContextProps>(
  {} as ProviderContextProps,
)

export function ProviderContextProvider({
  children,
  pathname,
}: ProviderContextProviderProps) {
  async function createProvider(providerInput: ProviderInput) {
    try {
      await api.post('/provider', providerInput)
      setTimeout(() => {
        swal('Poof! O fornecedor foi adicionado com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function deleteProvider(providerId: string) {
    try {
      await api.delete(`/provider/${providerId}`)
      swal('Poof! O fornecedor foi excluído com sucesso!', {
        icon: 'success',
      }).then((res) => res && window.location.reload())
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function editProvider(
    providerId: string,
    providerInput: ProviderInput,
  ) {
    try {
      await api.put(`/provider/${providerId}`, providerInput)
      setTimeout(() => {
        swal('Poof! O fornecedor foi atualizado com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  return (
    <ProviderContext.Provider
      value={{ createProvider, editProvider, deleteProvider }}
    >
      {children}
    </ProviderContext.Provider>
  )
}
