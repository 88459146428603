import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetCollectServicesWithDelayOccurrencesResponse {
  services: {
    id: string
    protocol: number
    step: string
    customer: string
    source_hub: string
    source_tz: string
    destination_hub: string
    destination_tz: string
    shipping: string
    intercurrence: string
  }[]
}

export async function getCollectServicesWithDelayOccurrences() {
  try {
    const { data } = await api.get<GetCollectServicesWithDelayOccurrencesResponse>('/services/dashboard/collects/with-delay-occurrences')

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
