import { api } from "services/api"
import request from "axios"
import { QueryKey, useQuery, UseQueryOptions } from "react-query"
import { RequestError } from "utils/errors/RequestErrors"
import { useToastify } from "hooks/toastify/useToastify"
import { useAuth } from "hooks/auth/useAuth"
import { ServiceRouteNetworkData } from "./dtos/ServiceRouteNetwork"

interface ServiceRouteNetworkQueryParams {
  source_branch_id?: string
  destination_branch_id?: string
  hubs?: string
  collect_date?: string
  collect_hour_end?: string
  delivery_date?: string,
  delivery_hour?: string
  modal?: 'AÉREO' | 'RODOVIÁRIO'
  crossdocking_shipping_id?: string
  shipping_id?: string
}

type QueryOptions = UseQueryOptions<ServiceRouteNetworkData, unknown, ServiceRouteNetworkData, QueryKey>

interface UseServiceRouteNetworksOptions {
  queryKey?: string
  queryOptions?: QueryOptions
  queryParams?: ServiceRouteNetworkQueryParams
}

export function useServiceRouteNetworks(options?: UseServiceRouteNetworksOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getServiceRouteNetworks(queryParams?: ServiceRouteNetworkQueryParams) {
    try {
      const { data } = await api.get(`/route-network-service/`, {
        params: queryParams && {
          ...queryParams,
        }
      })

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response

        throw new RequestError(message, status)
      } else {
        throw new RequestError("Erro inesperado!")
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([
    "serviceRouteNetworks",
    { ...options?.queryParams }
  ], () => getServiceRouteNetworks(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
