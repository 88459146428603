type DataProps = {
  service_id: string
  collector_id: string
  operational_number: string
  board_volume: number
  cte_photo?: FileList
  box_photo?: FileList
  departure_latitude: string
  departure_longitude: string
  departure_timestamp: string
  board_observation: string
}

export function finishBoardServiceFormatDataToBack(data: DataProps) {
  const formData = new FormData()
  formData.append('service_id', data.service_id)
  formData.append('collector_id', data.collector_id)
  formData.append('board_volume', data.board_volume as any)
  formData.append('operational_number', data.operational_number as any)
  formData.append('cte_photo', data.cte_photo[0])
  if (data.box_photo){
    formData.append('box_photo', data.box_photo[0])
  }
  formData.append('departure_timestamp', data.departure_timestamp as any)
  formData.append('departure_latitude', data.departure_latitude as any)
  formData.append('departure_longitude', data.departure_longitude as any)
  formData.append('board_observation', data.board_observation as any)

  return formData
}
