import { Button, Flex, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { add } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useDriver } from "hooks/driver/useDriver";
import { useAggregatesReport } from "hooks/report/useAggregatesReport";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils/DateFunctions/formatDate";
import { formatDateOnlyFieldTypeToShow } from "utils/formatDateOnlyFieldTypeToShow";
import * as yup from "yup"

interface AggregatesReportInputs {
  driver_id: string
  start_filter: string
  end_filter: string
  find_by: 'GERAL' | 'AGREGADO'
}

const aggregatesReportHeaders = [
  { key: 'protocol', label: 'PROTOCOLO' },
  { key: 'customer', label: 'CLIENTE' },
  { key: 'execution_date', label: 'DATA DE REALIZAÇÃO DO SERVIÇO' },
  { key: 'cost_center', label: 'CENTRO DE CUSTO' },
  { key: 'address', label: 'ENDEREÇO' },
  { key: 'source_hub', label: 'HUB ORIGEM' },
  { key: 'source_cities', label: 'CIDADE(S) DE ORIGEM' },
  { key: 'destination_hub', label: 'HUB DESTINO' },
  { key: 'destination_cities', label: 'CIDADE(S) DE DESTINO' },
  { key: 'type', label: 'TIPO DE SERVIÇO' },
  { key: 'vehicle', label: 'VEÍCULO' },
  { key: 'driver', label: 'MOTORISTA' },
  { key: 'cost', label: 'CUSTO DO SERVIÇO' },
  { key: 'service_observations', label: 'OBSERVAÇÕES DO SERVIÇO' }
]

const aggregateExtrasDiscountsReportHeader = [
  { key: 'type', label: 'TIPO' },
  { key: 'serviceDate', label: 'DATA DO SERVIÇO' },
  { key: 'value', label: 'VALOR' },
  { key: 'description', label: 'DESCRIÇÃO' },
  { key: 'serviceRoute', label: 'ROTA DO SERVIÇO' },
  { key: 'serviceProtocols', label: 'PROTOCOLO(S) DE SERVIÇO' },
  { key: 'status', label: 'STATUS' },
]

const findBySelectOptions = [
  { key: '0', value: 'GERAL', showOption: 'GERAL' },
  { key: '1', value: 'AGREGADO', showOption: 'AGREGADO' },
]

const schema = yup.object().shape({
  find_by: yup.string().required('Campo Obrigatório'),
  driver_id: yup.string().when('find_by', {
    is: 'AGREGADO',
    then: yup.string().required('Campo Obrigatório'),
  }),
  start_filter: yup.string().required('Campo obrigatório!'),
  end_filter: yup.string().required('Campo obrigatório!')
})

export function AggregatesReport() {
  const [
    enableAggregatesReportRequest,
    setEnableAggregatesReportRequest
  ] = useState(false)

  const {
    drivers: {
      data: drivers,
      isFetching: isFetchingDrivers
    }
  } = useDriver(null, true)

  const driversSelectOptions = drivers?.map(driver => {
    return {
      key: driver.id,
      value: driver.id,
      showOption: `${driver.firstname} ${driver.lastname}`
    }
  })

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-aggregate-report')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<AggregatesReportInputs>({
    resolver: yupResolver(schema),
    shouldUnregister: true
  })

  const [
    driverFilter,
    startFilter,
    endFilter,
    findBySelected
  ] = useWatch({
    control,
    name: ['driver_id', 'start_filter', 'end_filter', 'find_by']
  })

  const isFindByAggregate = findBySelected === 'AGREGADO'

  const isValidStartDate = new Date(startFilter) instanceof Date &&
    !isNaN(new Date(startFilter).getMilliseconds())

  const maxEndFilter = isValidStartDate
    ? formatDate.handle(add(new Date(startFilter), {
      days: 31
    }), 'DateWithoutHourToInput')
    : ''

  const driverFilteredInfo = drivers?.find(driver => {
    return driver.id === driverFilter
  })

  const {
    data: aggregatesReportData,
    isFetching: isAggregatesReportDataFetching,

  } = useAggregatesReport({
    queryOptions: {
      enabled: enableAggregatesReportRequest,
      select: (data) => {
        return {
          ...data,
          aggregatesReportData: data.aggregateReportData.map(aggregateReport => ({
            ...aggregateReport,
          })),
        }
      }
    },
    queryParams: {
      driver_id: driverFilter ? driverFilter : null,
      start_filter: startFilter,
      end_filter: endFilter
    }
  })

  const handleGenerateReport = () => {
    setEnableAggregatesReportRequest(true)
    setTimeout(() => {
      setEnableAggregatesReportRequest(false)
    }, 500)
  }

  const aggregatesCsvReportProps = {
    data: aggregatesReportData?.aggregatesReportData ?? [],
    headers: aggregatesReportHeaders,
    filename: isFindByAggregate ? `relatorio-agregados-${driverFilteredInfo?.firstname}-${startFilter}-${endFilter}.csv` : `relatorio-agregados-${startFilter}-${endFilter}.csv`
  }

  const aggregatesExtrasDiscounts = aggregatesReportData?.extrasDiscounts.map(
    (extraDiscount) => {
      let status = 'Solicitado'

      if (Boolean(extraDiscount.rejected_at)) {
        status = 'Rejeitado'
      }

      if (Boolean(extraDiscount.approved_at) && !extraDiscount.effective_at) {
        status = 'Aprovado'
      }

      if (Boolean(extraDiscount.effective_at)) {
        status = 'Efetivado'
      }

      return {
        ...extraDiscount,
        value: new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(extraDiscount.value / 100),
        serviceDate: formatDateOnlyFieldTypeToShow(extraDiscount.service_date),
        serviceRoute: extraDiscount.service_route,
        serviceProtocols: extraDiscount.services.length ? extraDiscount.services.map(service => service.service.protocol).join(', ') : '-',
        status,
      }
    }
  )

  const aggregatesExtrasDiscountsCsvReportProps = {
    data: aggregatesExtrasDiscounts
      ? aggregatesExtrasDiscounts
      : [],
    headers: aggregateExtrasDiscountsReportHeader,
    filename: `relatorio-extras-descontos-${driverFilteredInfo?.firstname}-${startFilter}-${endFilter}`
  }


  return (
    <StandardBackgroundForm
      title='Relatório de agregados'
      onSubmit={handleSubmit(handleGenerateReport)}
    >
      <Flex
        gap={4}
        direction='column'
      >
        <Select
          {...register('find_by')}
          name='find_by'
          options={findBySelectOptions}
          label='Pesquisar por'
          placeholder='Selecione uma opção...'
          error={errors.find_by}
          required
        />
        {isFindByAggregate && (
          <Select
            {...register('driver_id')}
            name='driver_id'
            label='Motorista'
            error={errors.driver_id}
            placeholder='Selecione uma opção...'
            options={driversSelectOptions}
            required
          />
        )}
        <Stack
          w='full'
          spacing={4}
          direction={['column', 'column', 'row']}
        >
          <Input
            {...register('start_filter')}
            name='start_filter'
            label='Data inicial do filtro'
            type='date'
            error={errors.start_filter}
            required
          />

          <Input
            {...register('end_filter')}
            name='end_filter'
            label='Data final do filtro'
            type='date'
            error={errors.end_filter}
            max={maxEndFilter}
            required
          />
        </Stack>

        <Flex direction={["column", "column", "row"]} gap={2} justify={['center', 'center', 'flex-end']}>
          <Button
            isLoading={isAggregatesReportDataFetching || isFetchingDrivers}
            type='submit'
            w={['full', 'full', 'min']}
            colorScheme='blue'
          >
            Gerar relatório
          </Button>
          {aggregatesReportData?.aggregatesReportData?.length > 0 && (
            <GenerateExcelReportButton mt={-4} buttonTitle="Exportar CSV de Serviços" csvReportProps={aggregatesCsvReportProps} />
          )}
          {aggregatesReportData?.extrasDiscounts?.length > 0 && (
            <GenerateExcelReportButton mt={-4} buttonTitle="Exportar CSV de Extras/Descontos" csvReportProps={aggregatesExtrasDiscountsCsvReportProps} />
          )}
        </Flex>
      </Flex>


    </StandardBackgroundForm>
  )
}
