import {
  Button,
  Flex,
  FormLabel,
  Icon,
  Link as ChakraLink
} from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";

import { Radio } from "components/Inputs/RadioInput";

import { useFormContext, useWatch } from "react-hook-form";
import { FiFile } from "react-icons/fi";
import { positiveNegativeOptions } from "utils/CustomLists/positiveNegativeOptions";

export interface ActivateAggregateFormInputs {
  signedContract: FileList
  sheetAttachment: FileList
  hasOpenProvisionComunication: 'yes' | 'no'
  hasOnboarding: 'yes' | 'no'
}

export function ActivateAggregateForm() {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext<ActivateAggregateFormInputs>()

  const [signedContract] = useWatch({
    control,
    name: ['signedContract']
  })

  const [sheetAttachment] = useWatch({
    control,
    name: ['sheetAttachment']
  })

  return (
    <Flex direction="column" gap={6} align="center">
      <Input
        {...register('signedContract')}
        name='signedContract'
        type='file'
        hidden
        label='Contrato Assinado'
        error={errors.signedContract}
      >
        <Flex
          align='center'
        >
          <Button
            leftIcon={<Icon as={FiFile} />}
            as={FormLabel}
            htmlFor='signedContract'
            h='48px'
            _hover={{
              cursor: 'pointer'
            }}
            size='sm'
          >
            Escolha um arquivo
          </Button>
          {signedContract && signedContract.length > 0 && (
            <ChakraLink
              href={URL.createObjectURL(signedContract[0])}
              isExternal
            >
              {signedContract[0].name} (Visualizar)
            </ChakraLink>
          )}
        </Flex>
      </Input>
      <Input
        {...register('sheetAttachment')}
        name='sheetAttachment'
        type='file'
        hidden
        label='Anexo ficha de EPIs/Patrimônios'
        error={errors.sheetAttachment}
      >
        <Flex
          align='center'
        >
          <Button
            leftIcon={<Icon as={FiFile} />}
            as={FormLabel}
            htmlFor='sheetAttachment'
            h='48px'
            _hover={{
              cursor: 'pointer'
            }}
            size='sm'
          >
            Escolha um arquivo
          </Button>
          {sheetAttachment && sheetAttachment.length > 0 && (
            <ChakraLink
              href={URL.createObjectURL(sheetAttachment[0])}
              isExternal
            >
              {sheetAttachment[0].name} (Visualizar)
            </ChakraLink>
          )}
        </Flex>
      </Input>
      <Radio
        {...register('hasOpenProvisionComunication')}
        name='hasOpenProvisionComunication'
        radioOptions={positiveNegativeOptions}
        label='Aberta Comunicação para Provisão CP?'
        error={errors.hasOpenProvisionComunication}
        required
      />
      <Radio
        {...register('hasOnboarding')}
        name='hasOnboarding'
        radioOptions={positiveNegativeOptions}
        label='Onboarding realizado?'
        error={errors.hasOnboarding}
        required
      />
    </Flex>
  )
}
