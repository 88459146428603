import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  Text,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Button,
  Flex,
  Tooltip,
  Icon,
} from '@chakra-ui/react'
import { ForwardRefRenderFunction, forwardRef, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'
import { FaInfo } from 'react-icons/fa'

interface InputProps extends ChakraInputProps {
  name: string
  accept?: string
  label?: string
  addChildren?: string
  required?: boolean
  error?: FieldError
  show?: boolean
  children?: ReactNode
  showPassword?: boolean
  tooltipLabel?: string
  setShow?: any
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    accept,
    addChildren,
    tooltipLabel,
    show,
    showPassword,
    setShow,
    children,
    required = false,
    error = null,
    ...rest
  },
  ref,
) => {

  function handleShowPassword() {
    setShow(!showPassword)
  }

  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <Flex flex="1" justify="space-between" >
          <FormLabel htmlFor={name} noOfLines={1}>
            <Flex h="full" w="full" gap="1" align="center">
              <Text fontSize="sm" color="gray.700" w="full">
                {label}
              </Text>
              {required && (
                <Text display="block" mt="2" color="red" fontWeight="bold" fontSize="sm" as="sup">
                  {' '}
                  *
                </Text>
              )}
            </Flex>
          </FormLabel>

          {tooltipLabel && (
            <Tooltip hasArrow placement="left" label={tooltipLabel} fontSize="md">
              <span>
                <Icon as={FaInfo} />
              </span>
            </Tooltip>
          )}
        </Flex>
      )}

      <InputGroup size="lg">
        {!!addChildren && (
          <InputLeftAddon
            children={addChildren}
            pointerEvents="none"
            color="gray.600"
            background="gray.200"
            fontSize="1.2em"
          />
        )}
        <ChakraInput
          name={name}
          id={name}
          accept={accept}
          focusBorderColor="#38c3fa"
          bgColor="gray.100"
          variant="filled"
          _hover={{
            bgcolor: 'gray.900',
          }}
          required={required}
          size="lg"
          onWheel={(e) => e.currentTarget.blur()}
          ref={ref}
          {...rest}
        />
        {children}
        {!!show && (
          <InputRightElement width="4rem" bg="transparent">
            <Button
              h="1.75rem"
              mr="1px"
              size="sm"
              bg="transparent"
              _hover={{
                background: "transparent"
              }}
              _focus={{
                border: "none"
              }}
              onClick={handleShowPassword}
            >
              {showPassword ? 'Ocultar' : 'Mostrar'}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const Input = forwardRef(InputBase)
