import { Box, Button, Flex, FormControl, FormLabel, Select, Text } from "@chakra-ui/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { useForm, useWatch } from "react-hook-form";
import { InvoiceStatus } from "api/invoices/_types/Invoice";
import { editInvoiceStatus } from "api/invoices/editInvoiceStatus";
import { FormEvent } from "react";


interface EditInvoiceStatusProps {
  invoiceId: string
  status: InvoiceStatus
}

export interface EditInvoiceStatusSchema {
  status: InvoiceStatus
}

interface OptionSelect {
  value: string
  label: string
}



const editInvoiceStatusSchema = yup.object({
  status: yup.string().required(),
})

const optionsByStatus: Record<string, OptionSelect[]> = {
  analyzing: [
    { value: 'checking-invoice', label: 'Conferência de fatura' },
    { value: 'approved-invoice', label: 'Fatura aprovada' },
    { value: 'repproved', label: 'Relatórios recusados' },
  ],
  'checking-invoice': [
    { value: 'operational-inconsistency', label: 'Inconsistência operacional' },
    { value: 'collector-inconsistency', label: 'Inconsistência do coletador' },
    { value: 'approved-invoice', label: 'Fatura aprovada' },
    { value: 'repproved', label: 'Relatórios recusados' },
  ],
  'operational-inconsistency': [
    { value: 'collector-inconsistency', label: 'Inconsistência do coletador' },
    { value: 'approved-invoice', label: 'Fatura aprovada' },
    { value: 'repproved', label: 'Relatórios recusados' },
  ],
  'collector-inconsistency': [
    { value: 'approved-invoice', label: 'Fatura aprovada' },
  ],
  'approved-invoice': [
    { value: 'checking-values', label: 'Conferência de valores' },
  ],
  'checking-values': [
    { value: 'checking-cp-value', label: 'Conferência de valor (Cp)' },
  ],
  'checking-cp-value': [
    { value: 'approved', label: 'Fatura aprovada' },
    { value: 'repproved', label: 'Relatórios recusados' },
  ],
};


export function EditInvoiceStatus({ invoiceId, status }: EditInvoiceStatusProps) {
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<EditInvoiceStatusSchema>({
    resolver: yupResolver(editInvoiceStatusSchema)
  })

  const nextStatusSelected = useWatch({
    control,
    name: 'status'
  })

  const { mutateAsync: editInvoiceStatusFn } = useMutation({
    mutationFn: editInvoiceStatus,
    onSuccess(_data, { invoiceId }) {
      queryClient.invalidateQueries({ queryKey: ['invoice', invoiceId] })
      queryClient.invalidateQueries({ queryKey: 'invoices' })
      queryClient.invalidateQueries({ queryKey: nextStatusSelected })
      reset()
    }
  })

  async function handleEditInvoiceStatus(values: EditInvoiceStatusSchema, event: FormEvent) {
    event.preventDefault()
    event.stopPropagation()
    await promiseMessage(editInvoiceStatusFn({
      status: values.status,
      invoiceId
    }), 'Status atualizado')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleEditInvoiceStatus)}
    >
      <FormControl isInvalid={!!errors.status}>
        <FormLabel fontSize="sm">
          Próximo Status
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register('status')}
          name="status"
          placeholder="Selecione..."
          rounded="md"
          size='sm'
        >
          {optionsByStatus[status]?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}

        </Select>
      </FormControl>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
