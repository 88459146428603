import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, Spinner, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { getDeliveriesWithoutQRCodePendingValidation } from "api/service/deliveries/getDeliveriesWithoutQRCodePendingValidation";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { DeliveriesWithoutQRCodePendingValidationFilters } from "./components/DeliveriesWithoutQRCodePendingValidationFilters";
import { DeliveriesWithoutQRCodePendingValidationTableRow } from "./components/DeliveriesWithoutQRCodePendingValidationTableRow";

export function DeliveriesWithoutQRCodePendingValidation() {
  const { userLogged } = useAuth()

  const history = useHistory()

  const userCanValidateFinishedDeliveryWithoutQRCode = userLogged?.permissions.includes('validate-finished-delivery-without-qr-code')

  useEffect(() => {
    if (!userCanValidateFinishedDeliveryWithoutQRCode) history.push('/')
  }, [history, userCanValidateFinishedDeliveryWithoutQRCode])

  const searchParams = useSearchParams()

  const page = searchParams.get('page') ?? '1'
  const destinationCollector = searchParams.get('destinationCollector')

  const { data: result, isLoading: isDeliveriesWithoutQRCodePendingValidationLoading } =
    useQuery(['deliveries-without-qr-code-pending-validation', page, destinationCollector], () => getDeliveriesWithoutQRCodePendingValidation({
      currentPage: page,
      pageSize: '10',
      destinationCollector
    }))

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: result?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: 1
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  return (
    <Box
      bg="white"
      borderRadius="md"
      p="4"
    >
      <Flex
        w="full"
        direction="column"
        gap={6}
      >
        <Heading letterSpacing="tight">Validar Entregas Sem QR Code</Heading>

        <DeliveriesWithoutQRCodePendingValidationFilters />

        {isDeliveriesWithoutQRCodePendingValidationLoading ? (
          <Spinner />
        ) : (
          <TableContainer
            rounded="md"
          >
            <Table
              size="sm"
            >
              <Thead
                h="40px"
                bg="#38c3fa"
              >
                <Tr>
                  <Th></Th>
                  <Th textColor="white">Protocolo</Th>
                  <Th textColor="white">Cliente</Th>
                  <Th textColor="white">Coletador de Destino</Th>
                </Tr>
              </Thead>
              <Tbody>
                {result?.deliveries.map((service) => {
                  return (
                    <DeliveriesWithoutQRCodePendingValidationTableRow
                      key={service.id}
                      service={service}
                    />
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />
      </Flex>
    </Box>
  )
}
