import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { Region } from "./dtos/Region";

interface RegionsQueryParams {
  pageSize?: number
  currentPage?: number
}

interface RegionsResponse {
  regions: Region[]
  totalPages: 1
}

type QueryOptions = UseQueryOptions<RegionsResponse, unknown, RegionsResponse, QueryKey>

interface UseRegionOptions {
  queryKey?: string
  queryOptions?: QueryOptions
  queryParams?: RegionsQueryParams
}


export function useRegions(options?: UseRegionOptions) {
  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getRegions(queryParams?: RegionsQueryParams) {

    try {
      const { data } = await api.get('/regions', {
        params: {
          ...queryParams
        }
      })

      return data

    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new Error("Erro inesperado!")
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([options?.queryKey || 'regions', {...options?.queryParams}], () => getRegions(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
