import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Flex, Stack } from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { useAuth } from "hooks/auth/useAuth";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { formatDate } from "utils/DateFunctions/formatDate";
import { useCostReport } from "hooks/report/useCostReport";
import { serviceHandleStep } from "utils/ServiceFunctions/serviceDict";
import { CostReportTable } from "./components/CostReportTable";
import { add } from "date-fns";
import { useNonInactiveCustomers } from "hooks/customer/useNonInactiveCustomers";

interface CostReportInputs {
  search_type: 'general' | 'customer'
  customer_id: string
  start_filter: string
  end_filter: string
}

const costReportHeaders = [
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'CLIENTE', key: 'customer' },
  { label: 'ETAPA', key: 'step' },
  { label: 'TIPO DE SERVIÇO', key: 'service_type' },
  { label: 'DATA DA COLETA', key: 'collect_date' },
  { label: 'DATA DO EMBARQUE', key: 'departure_board_date' },
  { label: 'TRANSPORTADORA', key: 'shipping' },
  { label: 'HUB ORIGEM', key: 'source_hub' },
  { label: 'CIDADE ORIGEM', key: 'source_cities' },
  { label: 'COLETADOR ORIGEM', key: 'source_collector' },
  { label: 'BASE ORIGEM', key: 'source_branch' },
  { label: 'HUB DESTINO', key: 'destination_hub' },
  { label: 'CIDADE DESTINO', key: 'destination_cities' },
  { label: 'COLETADOR DESTINO', key: 'destination_collector' },
  { label: 'BASE DESTINO', key: 'destination_branch' },
  { label: 'QUANTIDADE ENDEREÇOS ENTREGA', key: 'delivery_count' },
  { label: 'QUANTIDADE ENDEREÇOS COLETA', key: 'collect_count' },
  { label: 'RASTREADOR', key: 'tracker' },
  { label: 'CROSSDOCKING', key: 'has_crossdocking' },
  { label: 'RASTREADOR', key: 'tracker' },
  { label: 'RASTREADOR CROSSDOCKING', key: 'crossdocking_tracker' },
  { label: 'BASE ORIGEM INTERMEDIÁRIA', key: 'source_crossdocking_branch' },
  { label: 'BASE DESTINO INTERMEDIÁRIA', key: 'destination_crossdocking_branch' },
  { label: 'CTE', key: 'cte' },
  { label: 'CTE CROSSDOCKING', key: 'crossdocking_cte' },
  { label: 'TRANSPORTADORA CROSSDOCKING', key: 'crossdocking_shipping' },
  { label: 'IDENTIFICADOR DO EMBARQUE ATUAL', key: 'which_board' },
  { label: 'BUDGET DE TRANSFERÊNCIA', key: 'transfer_budget' },
  { label: 'VALOR CTE TRANSFERÊNCIA', key: 'cte_transfer_cost' },
  { label: 'VALOR CTE CROSSDOCKING TRANSFERÊNCIA', key: 'crossdocking_cte_transfer_cost' },
  { label: 'VOLUME (VALIDAÇÃO EMBARQUE)', key: 'board_volume' },
  { label: 'FORNECEDOR', key: 'provider' },
  { label: 'PAPELÃO 3L', key: 'terciaria3l' },
  { label: 'PAPELÃO 8L', key: 'terciaria8l' },
  { label: 'GELO SECO', key: 'gelo_seco' },
  { label: 'FRANQUIA', key: 'franchising' },
  { label: 'PESO TAXADO', key: 'taxed_weight' },
  { label: 'CENTRO DE CUSTO', key: 'cost_center' },
  { label: 'CUSTO COLETA', key: 'collect_cost' },
  { label: 'CUSTO COLETA ADICIONAL', key: 'additional_collect_cost' },
  { label: 'CUSTO ENTREGA', key: 'deliveryCost' },
  { label: 'CUSTO ENTREGA ADICIONAL', key: 'additional_delivery_cost' },
  { label: 'DESVIO DE BUDGET', key: 'detour' },
  { label: 'VEÍCULO', key: 'vehicle' },
  { label: 'CTE LOGLIFE', key: 'cte_loglife' },
  { label: 'DATA EMISSÃO CTE LOGLIFE', key: 'cte_loglife_emission_date' },
  { label: 'CTE COMPLEMENTAR', key: 'cte_complementary' },
  { label: 'DATA EMISSÃO CTE COMPLEMENTAR', key: 'cte_complementary_emission_date' },
  { label: 'MOTORISTAS DE COLETA', key: 'collect_drivers' },
  { label: 'MOTORISTAS DE ENTREGA', key: 'delivery_drivers' },
  { label: 'CUSTO TOTAL DE GELO SECO', key: 'gelo_deco_total_cost' },
  { label: 'VALOR DO ORÇAMENTO', key: 'budget_price' },
  { label: 'VALOR TOTAL COLETAS ADICIONAIS', key: 'additional_collects_total_value' },
  { label: 'VALOR TOTAL ENTREGAS ADICIONAIS', key: 'additional_deliveries_total_value' },
  { label: 'VALOR TOTAL KG EXTRA', key: 'total_value_extra_kg_price' },
  { label: 'VALOR TOTAL MATERIAL EXTRA (CLIENTE)', key: 'total_value_customer_extra_material' },
  { label: 'VALOR OUTRAS COBRANÇAS', key: 'total_value_other_charges' },
  { label: 'VALOR COLETA ADICIONAL', key: 'additional_collect_value' },
  { label: 'VALOR ENTREGA ADICIONAL', key: 'additional_delivery_value' },
  { label: 'VALOR KG EXTRA', key: 'extra_kg_price' },
  { label: 'VALOR TOTAL DO SERVIÇO', key: 'service_total_value' },
  { label: 'OBSERVAÇÕES DO SERVIÇO', key: 'observation' }
]

const searchTypeSelectOptions = [
  { key: '0', value: 'general', showOption: 'GERAL' },
  { key: '1', value: 'customer', showOption: 'CLIENTE' },
]

const schema = yup.object().shape({
  search_type: yup.string().required('Campo obrigatório!'),
  customer_id: yup.string().when('search_type', {
    is: 'customer',
    then: yup.string().required('Campo obrigatório!')
  }),
  start_filter: yup.string().required('Campo obrigatório!'),
  end_filter: yup.string().required('Campo obrigatório!')
})

export function CostReport() {
  const [
    enableCostReportRequest,
    setEnableCostReportRequest
  ] = useState(false)

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<CostReportInputs>({
    resolver: yupResolver(schema),
    shouldUnregister: true
  })

  const searchType = useWatch<CostReportInputs, 'search_type'>({
    control,
    name: 'search_type',
  })


  const {
    data: customersData,
    isFetching: isCustomersDataFetching
  } = useNonInactiveCustomers({
    queryOptions: {
      enabled: searchType === 'customer'
    }
  })

  const customerSelectOptions = customersData?.customers.map(customer => {
    return {
      key: customer.id,
      value: customer.id,
      showOption: customer.trading_firstname
    }
  })

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-cost-report')) {
      redirect('/')
    }
  }, [userLogged, redirect])

  useEffect(() => {
    if (userLogged?.user_type === 'CLIENTE') {
      setValue('customer_id', userLogged?.customer_id)
    }
  }, [userLogged, setValue])

  const [
    customerFiltered,
    startFilter,
    endFilter
  ] = useWatch({
    control,
    name: ['customer_id', 'start_filter', 'end_filter']
  })

  const isValidStartDate = new Date(startFilter) instanceof Date &&
    !isNaN(new Date(startFilter).getMilliseconds())

  const maxEndFilter = isValidStartDate
    ? formatDate.handle(add(new Date(startFilter), {
      days: 31
    }), 'DateWithoutHourToInput')
    : ''

  const selectedCustomer = useWatch({
    control,
    name: 'customer_id'
  })

  const selectedCustomerInfo = customersData?.customers.find(customer => {
    return customer.id === selectedCustomer
  })

  const {
    data: costReportData,
    isFetching: isCostReportDataFetching,

  } = useCostReport({
    queryOptions: {
      enabled: enableCostReportRequest,
      select: (costReportData) => {
        return costReportData.map(data => {
          return {
            ...data,
            step: serviceHandleStep(data.step),
            collect_date: formatDate.handle(data.collect_date, 'DateWithoutHourToShow'),
            collect_hour_end: formatDate.handle(data.collect_hour_end, 'DateOnlyWithHourMinute'),
            collect_hour_start: formatDate.handle(data.collect_hour_start, 'DateOnlyWithHourMinute'),
            departure_board_date: data.departure_board_date.map(departure => {
              return formatDate.handle(departure.departure_timestamp, 'DateWithoutHourToShow')
            }),
            delivery_date: formatDate.handle(data.delivery_date, 'DateWithoutHourToShow'),
            delivery_hour: formatDate.handle(data.delivery_hour, 'DateOnlyWithHourMinute')
          }
        })
      }
    },
    queryParams: {
      customer_id: customerFiltered,
      start_filter: startFilter,
      end_filter: endFilter
    }
  })

  const handleGenerateReport = () => {
    setEnableCostReportRequest(true)
    setTimeout(() => {
      setEnableCostReportRequest(false)
    }, 500)
  }

  const csvReportFilename = searchType === 'customer' && selectedCustomer
    ? `relatorio-custo-cliente-${selectedCustomerInfo.trading_firstname
    }-${startFilter}-${endFilter}`
    : `relatorio-custo-geral-${startFilter}-${endFilter}`

  const csvReportProps = {
    data: costReportData ?? [],
    headers: costReportHeaders,
    filename: csvReportFilename,
  }

  return (
    <StandardBackgroundForm
      title='Relatório de custos'
      onSubmit={handleSubmit(handleGenerateReport)}
    >
      <Flex
        gap={4}
        direction='column'
      >
        <Select
          {...register('search_type')}
          name='search_type'
          label='Tipo de busca'
          error={errors.search_type}
          placeholder='Selecione uma opção...'
          options={searchTypeSelectOptions}
          required
        />

        {searchType === 'customer' && (
          <Select
            {...register('customer_id')}
            name='customer_id'
            label='Cliente'
            error={errors.customer_id}
            placeholder='Selecione uma opção...'
            options={customerSelectOptions}
            isDisabled={isCustomersDataFetching || userLogged?.user_type === 'CLIENTE'}
            required
          />
        )}

        <Stack
          w='full'
          spacing={4}
          direction={['column', 'column', 'row']}
        >
          <Input
            {...register('start_filter')}
            name='start_filter'
            label='Data inicial do filtro'
            type='date'
            error={errors.start_filter}
            required
          />

          <Input
            {...register('end_filter')}
            name='end_filter'
            label='Data final do filtro'
            type='date'
            error={errors.end_filter}
            max={maxEndFilter}
            required
          />
        </Stack>

        <Flex gap={2} justify={['center', 'center', 'flex-end']}>
          <Button
            isLoading={isCostReportDataFetching}
            type='submit'
            w={['full', 'full', 'min']}
            colorScheme='blue'
          >
            Gerar relatório
          </Button>
          {costReportData && (
            <GenerateExcelReportButton mt={-4} csvReportProps={csvReportProps} />
          )}
        </Flex>

        {costReportData && (
          <CostReportTable
            costReportData={costReportData}
          />
        )}
      </Flex>


    </StandardBackgroundForm>
  )
}
