import { Box, FormControl, FormLabel, Input, Select, Stack, Text, Textarea } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext, } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";

const receiptTypeOptions = [
  'Fornecedor comum',
  'Funcionário',
  'Gelo Seco',
  'Acordo Jurídico'
]


export function PaymentForecastInquiryForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()



  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      receiptType: 'Tipo de Comprovante',
      startDate: 'Data Inicial',
      endDate: 'Data Final',
      paymentDescription: 'Descrição do pagamento',
    };


    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };


  return (
    <Box w='full'>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.receiptType}>
            <FormLabel fontSize="sm">
              Tipo de Comprovante
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register("formData.receiptType.value")}
              name="formData.receiptType.value"
              placeholder="Selecione uma opção..."
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('receiptType')}
            >
              {Object.entries(receiptTypeOptions).map(([key, value]) => (
                <option key={key} value={value}>{value}</option>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.startDate} >
            <FormLabel fontSize='sm'>
              Data Inicial
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.startDate.value')}
              name='formData.startDate.value'
              type="date"
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('startDate')}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.endDate}>
            <FormLabel fontSize='sm'>
              Data Final
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.endDate.value')}
              name='formData.endDate.value'
              type="date"
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('endDate')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <FormControl isInvalid={!!errors.formData?.paymentDescription} mt={3}>
        <FormLabel fontSize="sm">
          Descrição do pagamento
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register('formData.paymentDescription.value')}
          name='formData.paymentDescription.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('paymentDescription')}
        />
      </FormControl>
    </Box>
  )
}
