import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ExternalComunicationsSidebarRootProps {
  children: ReactNode
}

export function ExternalComunicationsSidebarRoot({
  children
}: ExternalComunicationsSidebarRootProps) {
  return (
    <Box
      p="2"
      maxW={["140px", "140px", "200px"]}
      w="full"
      borderRight="1px solid"
      borderColor="gray.50"
      fontSize="sm"
    >
      {children}
    </Box>
  )
}
