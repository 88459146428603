import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Stack } from "@chakra-ui/react";
import { getBackupCollectorsUtilization } from "api/collectors/getBackupCollectorsUtilization";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { useForm } from "react-hook-form";
import { FaSearch } from "react-icons/fa";
import { FaFileExport } from "react-icons/fa6";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

interface BackupCollectorsUtilizationReportSchema {
  startDate: string
  finalDate: string
}

const headers = [
  { label: 'Protocolo', key: 'protocol' },
  { label: 'Cliente', key: 'customer.trading_firstname' },
  { label: 'Cidade(s) de origem', key: 'sourceCities' },
  { label: 'Cidade(s) de destino', key: 'destinationCities' },
  { label: 'Coletador origem', key: 'sourceCollector' },
  { label: 'Coletador destino', key: 'destinationCollector' },
  { label: 'Fase', key: 'step' },
]

export function BackupCollectorsUtilizationReport() {
  const { userLogged } = useAuth()

  const userCanViewBackupCollectorsReport = userLogged?.permissions?.includes('view-backup-collectors-report')

  const searchParams = useSearchParams()

  const startDate = searchParams.get('startDate')
  const finalDate = searchParams.get('finalDate')

  const history = useHistory()

  useEffect(() => {
    if (!userCanViewBackupCollectorsReport) {
      history.push('/')
    }
  }, [userCanViewBackupCollectorsReport, history])

  const {
    register,
    handleSubmit,
  } = useForm<BackupCollectorsUtilizationReportSchema>()

  const {
    data: backupCollectorsUtilizationResult,
    isLoading: isLoadingBackupCollectorsUtilizationResult
  } = useQuery({
    queryKey: ['collectors-backup', startDate, finalDate],
    queryFn: () => getBackupCollectorsUtilization({
      finalDate,
      startDate
    }),
    enabled: Boolean(finalDate) && Boolean(startDate)
  })

  const csvProps = {
    data: backupCollectorsUtilizationResult ? backupCollectorsUtilizationResult.services.map(
      (service) => {
        return {
          ...service,
          sourceCities: Array.from(new Set(service.sourceAddresses.map((address) => address.city.name))),
          destinationCities: Array.from(new Set(service.destinationAddresses.map((address) => address.city.name))),
        }
      }
    ) : [],
    headers,
    filename: `relatório-coltador-backup.csv`,
  }

  function handleGenerateReport(data: BackupCollectorsUtilizationReportSchema) {
    if (data.startDate) {
      searchParams.set('startDate', data.startDate)
    } else {
      searchParams.delete('startDate')
    }

    if (data.finalDate) {
      searchParams.set('finalDate', data.finalDate)
    } else {
      searchParams.delete('finalDate')
    }

    history.replace({ search: searchParams.toString() })
  }

  return (
    <Box
      p={4}
      bg="white"
      rounded="md"
      as="form"
      onSubmit={handleSubmit(handleGenerateReport)}
    >
      <Heading letterSpacing="tight">Relatório de coletador backup</Heading>

      <Stack
        mt={3}
        gap={3}
        direction={{ base: 'column', md: 'row'}}
      >

        <FormControl>

          <FormLabel>
            Data inicial
          </FormLabel>
          <Input
            {...register('startDate')}
            type="date"
          />
        </FormControl>


        <FormControl>
          <FormLabel>
            Data final
          </FormLabel>
          <Input
            {...register('finalDate')}
            type="date"
          />
        </FormControl>
      </Stack>


      <Flex mt={3} justify="end" gap={2} direction={{ base: 'column', md: 'row' }}>
        {backupCollectorsUtilizationResult && (
          <Button
            leftIcon={<FaFileExport />}
            type="button"
            colorScheme="green"
            as={CSVLink}
            separator={';'}
            {...csvProps}
            w={{ base: 'full', md: 'min' }}
            isLoading={isLoadingBackupCollectorsUtilizationResult}
          >
            Exportar
          </Button>
        )}
        <Button
          leftIcon={<FaSearch />}
          type="submit"
          isLoading={isLoadingBackupCollectorsUtilizationResult}
          w={{ base: 'full', md: 'min' }}
        >
          Gerar relatório
        </Button>
      </Flex>

    </Box>
  )
}
