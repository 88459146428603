import * as yup from 'yup'

const schema = yup.object().shape({
  user_type: yup.string().required('Campo Obrigatório'),
  permissions: yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
      case 'string':
        return yup.string().required('Campo Obrigatório')
      default:
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
    }
  }),
})

export { schema }
