import { Button, Icon, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react"
import { deleteContingency } from "api/contingencies/deleteContingency"
import { GetContingenciesResponse } from "api/contingencies/getContingencies"
import { Contingency } from "api/contingencies/_types/Contingency"
import { format } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { useToastify } from "hooks/toastify/useToastify"
import { FaFolder, FaTimes } from "react-icons/fa"
import { useMutation, useQueryClient } from "react-query"
import { captalize } from "utils/captalize"
import { ContingencyDetails } from "./ContingencyDetails"

interface ContingencyTableRowProps {
  contingency: Contingency
}

export function ContingencyTableRow({ contingency }: ContingencyTableRowProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const { userLogged } = useAuth()

  const userCanDeleteContingency = userLogged?.permissions?.includes(
    'delete-contingency'
  )

  const {
    isOpen: isContingencyDetailModalOpen,
    onToggle: onToggleContingencyDetailModal,
  } = useDisclosure()

  const { mutateAsync: deleteContingencyFn } = useMutation({
    mutationFn: deleteContingency,
    onSuccess(_data, { contingencyId }) {
      const cachedContingencies = queryClient.getQueriesData<GetContingenciesResponse>({
        queryKey: ['contingencies']
      })

      cachedContingencies.forEach(([cachedKey, cachedData]) => {
        if (!cachedData) {
          return null
        }

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          contingencies: cachedData.contingencies.filter((cachedContingency) => {
            return cachedContingency.id !== contingencyId
          })
        })

      })
    },
  })

  async function handleDeleteContingency() {
    await promiseMessage(deleteContingencyFn({
      contingencyId: contingency?.id
    }), 'Contingência excluida!')
  }


  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Contingência"
          icon={<FaFolder />}
          size="sm"
          variant="ghost"
          onClick={onToggleContingencyDetailModal}
          colorScheme='yellow'
        />

        <Modal
          isOpen={isContingencyDetailModalOpen}
          onClose={onToggleContingencyDetailModal}
          size='6xl'
        >
          <ModalOverlay />
          <ContingencyDetails
            contingencyId={contingency.id}
          />
        </Modal>
      </Td>
      <Td>{contingency?.name}</Td>
      <Td>{format(new Date(contingency?.updatedAt), "dd/MM/yyyy '-' HH:mm")}</Td>
      <Td>{captalize(`${contingency?.createdByUser?.firstname} ${contingency?.createdByUser?.lastname}`) }</Td>
      <Td>
        {userCanDeleteContingency && (
          <Button
            size="xs"
            leftIcon={<Icon as={FaTimes} />}
            variant="ghost"
            lineHeight="1"
            onClick={handleDeleteContingency}
          >
            Excluir
          </Button>
        )}
      </Td>
    </Tr>
  )
}
