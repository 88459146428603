import { Box, Divider, Heading } from "@chakra-ui/react"
import { ReactNode } from "react"

interface ValidateNewServiceAddressesRootProps {
  title: string
  children: ReactNode
}

export function ValidateNewServiceAddressesRoot({ children, title }: ValidateNewServiceAddressesRootProps) {
  return (
    <Box
      p="6"
      bg="white"
      display="flex"
      flexDirection="column"
      rounded="md"
      gap="4"
    >
      <Heading size="lg">
        {title}
      </Heading>
      <Divider />
      {children}
    </Box>
  )
}
