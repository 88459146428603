import { Spinner, Stack } from "@chakra-ui/react";
import { Options, Select } from "components/Inputs/SelectInput";
import { useFormContext } from "react-hook-form";

interface BranchesInputsGroupsProps {
  sourceBranchesOptions?: Options[]
  destinationCrossdockingBranchesOptions?: Options[]
  sourceCrossdockingBranchesOptions?: Options[]
  destinationBranchesOptions?: Options[]
  isCrossdockingService: boolean
  isBranchesFetching: boolean
  isDisabled?: boolean
}

export function BranchesInputsGroups({
  sourceBranchesOptions,
  destinationCrossdockingBranchesOptions,
  sourceCrossdockingBranchesOptions,
  destinationBranchesOptions,
  isCrossdockingService,
  isBranchesFetching,
  isDisabled = false
}: BranchesInputsGroupsProps) {

  const { register, formState: { errors } } = useFormContext()

  return (
    <Stack
      direction={["column", "column", "row"]}
      spacing={6}
      w="full"
    >
      {isBranchesFetching ? (
        <Spinner />
      ) : (
        <>
          <Select
            {...register("source_branch_id")}
            name="source_branch_id"
            label="Base origem"
            options={sourceBranchesOptions}
            error={errors.source_branch_id}
            placeholder="Selecione uma opção..."
            isDisabled={isDisabled}
            required
          />
          {isCrossdockingService && (
            <Select
              {...register("destination_crossdocking_branch_id")}
              name="destination_crossdocking_branch_id"
              label="Base destino crossdocking"
              options={destinationCrossdockingBranchesOptions}
              error={errors.destination_crossdocking_branch_id}
              placeholder="Selecione uma opção..."
              isDisabled={isDisabled}
              required
            />
          )}
          {isCrossdockingService && (
            <Select
              {...register("source_crossdocking_branch_id")}
              name="source_crossdocking_branch_id"
              label="Base origem crossdocking"
              options={sourceCrossdockingBranchesOptions}
              error={errors.source_crossdocking_branch_id}
              placeholder="Selecione uma opção..."
              isDisabled={isDisabled}
              required
            />
          )}

          <Select
            {...register("destination_branch_id")}
            name="destination_branch_id"
            label="Base destino"
            options={destinationBranchesOptions}
            error={errors.destination_branch_id}
            placeholder="Selecione uma opção..."
            isDisabled={isDisabled}
            required
          />
        </>
      )}

    </Stack>
  )
}
