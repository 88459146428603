import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface RefusePurchaseOrderBody {
  refuseDescription: string

}
interface RefusePurchaseOrderReqFunctionProps {
  purchaseOrderId: string
  input: RefusePurchaseOrderBody
}

export async function refusePurchaseOrder({
  purchaseOrderId,
  input
}: RefusePurchaseOrderReqFunctionProps) {
  try {

    await api.put(`/purchase-orders/${purchaseOrderId}/refuse`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

