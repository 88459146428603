import { Box, Flex, Spinner } from "@chakra-ui/react"
import { FaUser, FaPlaneDeparture, FaPlaneArrival, FaClock, FaShippingFast, FaInfo } from "react-icons/fa"
import { getTimezoneNameByValue } from "utils/getTimezoneNameByValue"
import { IHubsProps } from "utils/RequestFunctions/Hubs/requestHubFunctions"
import { EmptyContentTableAlert } from "../../../../../components/Alerts/EmptyContentTableAlert"
import { SearchBox } from "../../../../../components/SearchBox/SearchBox"
import { BranchProps } from "../../../../../contexts/BranchContext"
import { BudgetProps } from "../../../../../contexts/BudgetContext"
import { ServiceProps } from "../../../../../contexts/ServiceContext"
import { useKanbanSearchBox } from "../../../../../hooks/useKanbanSearchBox"
import { CitiesProps } from "../../../../../services/getFunctions/city/getCity"
import { formatDate } from "../../../../../utils/DateFunctions/formatDate"
import { searchBoxFilter } from "../../../../../utils/searchBoxFilter"
import { serviceHandleStep } from "../../../../../utils/ServiceFunctions/serviceDict"
import { sortByDate } from "../../../../../utils/SortFunctions/sortByDate"
import { sortByHour } from "../../../../../utils/SortFunctions/sortByHour"
import { KanbanCard } from "../KanbanCard/KanbanCard"
import { KanbanCardHeader } from "../KanbanCardHeader/KanbanCardHeader"
import { KanbanCardItem } from "../KanbanCardItem/KanbanCardItem"

interface IKanbanBoardedServicesProps {
  services?: ServiceProps[]
  budgets?: BudgetProps[]
  cities?: CitiesProps[]
  branches?: BranchProps[]
  hubs: IHubsProps[]
}

function changeUrlByStep(service_id: string, service_step: string) {
  switch (service_step) {
    case "toBoardValidate":
      return `/servicos/embarques/validar/${service_id}`
    case "toAllocateService":
      return `/servicos/alocar/${service_id}`
    case "toAvailableService":
      return `/servicos/disponibilizar/desembarque/${service_id}`
    case "toLandingService":
      return `/servicos/desembarques/${service_id}`
    case "landingService":
      return `/servicos/desembarques/${service_id}`
    default:
      return "/"
  }
}

export function KanbanBoardedServices({ services, budgets, cities, branches, hubs }: IKanbanBoardedServicesProps) {
  const { handleSearchServices, isLoadingSearch, searchedWords } = useKanbanSearchBox()

  const filteredSteps = [
    "toBoardValidate",
    "toAllocateService",
    "toAvailableService",
    "availableService",
    "toLadingService",
    "landingService"
  ]

  const boardedServices = services
    ?.filter(service => filteredSteps.includes(service.step)) || []

  const orderedBoardedServices =
    boardedServices.sort((current, next) => {
      const isCurrentCrossdockingService = current.serviceIDRequested.crossdocking_collector_id !== null
      const isNextCrossdockingService = next.serviceIDRequested.crossdocking_collector_id !== null

      const currentServiceStep = current.step
      const nextServiceStep = next.step

      const stepsToGetForecastValues = ["toBoardValidate", "toAllocateService"]

      let currentAvailableForecastDate: Date | string = ''
      let currentAvailableForecastHour: Date | string = ''
      let nextAvailableForecastDate: Date | string = ''
      let nextAvailableForecastHour: Date | string = ''

      if (stepsToGetForecastValues.includes(currentServiceStep)) {
        if (isCurrentCrossdockingService) {
          const currentCrossdockingCollectorId = current.serviceIDRequested.crossdocking_collector_id

          const isCrossdockingLandingFinished =
            current.serviceIDLanding.find(board => board.collector_id === currentCrossdockingCollectorId) !== undefined &&
            current.serviceIDLanding.find(board => board.collector_id === currentCrossdockingCollectorId)?.step === "DONE"

          if (isCrossdockingLandingFinished) {
            currentAvailableForecastDate = current.serviceIDRequested.availability_forecast_day!
            currentAvailableForecastHour = current.serviceIDRequested.availability_forecast_time!
          } else {
            currentAvailableForecastDate = current.serviceIDRequested.crossdocking_availability_forecast_day!
            currentAvailableForecastHour = current.serviceIDRequested.crossdocking_availability_forecast_time!
          }
        } else {
          currentAvailableForecastDate = current.serviceIDRequested.availability_forecast_day!
          currentAvailableForecastHour = current.serviceIDRequested.availability_forecast_time!
        }
      } else {
        const currentSourceCollectorId = current.serviceIDRequested.source_collector_id

        if (isCurrentCrossdockingService) {
          const currentCrossdockingCollectorId = current.serviceIDRequested.crossdocking_collector_id

          const isCrossdockingLandingFinished =
            current.serviceIDLanding.find(board => board.collector_id === currentCrossdockingCollectorId) !== undefined &&
            current.serviceIDLanding.find(board => board.collector_id === currentCrossdockingCollectorId)?.step === "DONE"

          if (isCrossdockingLandingFinished) {
            currentAvailableForecastDate = current.serviceIDAllocate
              .find(allocate => allocate.collector_id === currentSourceCollectorId)?.allocate_availability_date!
            currentAvailableForecastDate = current.serviceIDAllocate
              .find(allocate => allocate.collector_id === currentSourceCollectorId)?.allocate_availability_hour!
          } else {
            currentAvailableForecastDate = current.serviceIDAllocate
              .find(allocate => allocate.collector_id === currentCrossdockingCollectorId)?.allocate_availability_date!
            currentAvailableForecastDate = current.serviceIDAllocate
              .find(allocate => allocate.collector_id === currentCrossdockingCollectorId)?.allocate_availability_hour!
          }
        } else {
          currentAvailableForecastDate = current.serviceIDAllocate
            .find(allocate => allocate.collector_id === currentSourceCollectorId)?.allocate_availability_date!
          currentAvailableForecastDate = current.serviceIDAllocate
            .find(allocate => allocate.collector_id === currentSourceCollectorId)?.allocate_availability_hour!
        }
      }

      if (stepsToGetForecastValues.includes(nextServiceStep)) {
        if (isNextCrossdockingService) {
          const nextCrossdockingCollectorId = next.serviceIDRequested.crossdocking_collector_id

          const isCrossdockingLandingFinished =
            next.serviceIDLanding.find(board => board.collector_id === nextCrossdockingCollectorId) !== undefined &&
            next.serviceIDLanding.find(board => board.collector_id === nextCrossdockingCollectorId)?.step === "DONE"

          if (isCrossdockingLandingFinished) {
            nextAvailableForecastDate = next.serviceIDRequested.availability_forecast_day!
            nextAvailableForecastHour = next.serviceIDRequested.availability_forecast_time!
          } else {
            nextAvailableForecastDate = next.serviceIDRequested.crossdocking_availability_forecast_day!
            nextAvailableForecastHour = next.serviceIDRequested.crossdocking_availability_forecast_time!
          }
        } else {
          nextAvailableForecastDate = next.serviceIDRequested.availability_forecast_day!
          nextAvailableForecastHour = next.serviceIDRequested.availability_forecast_time!
        }
      } else {
        const nextSourceCollectorId = next.serviceIDRequested.source_collector_id

        if (isNextCrossdockingService) {
          const nextCrossdockingCollectorId = next.serviceIDRequested.crossdocking_collector_id

          const isCrossdockingLandingFinished =
            next.serviceIDLanding.find(board => board.collector_id === nextCrossdockingCollectorId) !== undefined &&
            next.serviceIDLanding.find(board => board.collector_id === nextCrossdockingCollectorId)?.step === "DONE"

          if (isCrossdockingLandingFinished) {
            nextAvailableForecastDate = next.serviceIDAllocate
              .find(allocate => allocate.collector_id === nextSourceCollectorId)?.allocate_availability_date!
            nextAvailableForecastDate = next.serviceIDAllocate
              .find(allocate => allocate.collector_id === nextSourceCollectorId)?.allocate_availability_hour!
          } else {
            nextAvailableForecastDate = next.serviceIDAllocate
              .find(allocate => allocate.collector_id === nextCrossdockingCollectorId)?.allocate_availability_date!
            nextAvailableForecastDate = next.serviceIDAllocate
              .find(allocate => allocate.collector_id === nextCrossdockingCollectorId)?.allocate_availability_hour!
          }
        } else {
          nextAvailableForecastDate = next.serviceIDAllocate
            .find(allocate => allocate.collector_id === nextSourceCollectorId)?.allocate_availability_date!
          nextAvailableForecastDate = next.serviceIDAllocate
            .find(allocate => allocate.collector_id === nextSourceCollectorId)?.allocate_availability_hour!
        }
      }

      return sortByDate(currentAvailableForecastDate, nextAvailableForecastDate) ||
        sortByHour(currentAvailableForecastHour, nextAvailableForecastHour) ||
        0
    })

  const servicesFilteredByUser = orderedBoardedServices.filter(service => {
    const budgetFiltered = budgets
      ?.find(budget => budget.id === service.serviceIDRequested.budget_id)

    const sourceCities = cities
      ?.filter(city => budgetFiltered?.source_cities.includes(city.id))
      .map(city => city.name)
      .join(', ')

    const destinationCities = cities
      ?.filter(city => budgetFiltered?.destination_cities.includes(city.id))
      .map(city => city.name)
      .join(', ')

    const collectDate = formatDate.handle(service.serviceIDRequested.collect_date, "DateWithoutHourToShow")
    const collectHourStart = formatDate.handle(service.serviceIDRequested.collect_hour_start, "DateOnlyWithHourMinute")
    const collectHourEnd = formatDate.handle(service.serviceIDRequested.collect_hour_end, "DateOnlyWithHourMinute")

    const crossdockingCollectorId = service.serviceIDRequested.crossdocking_collector_id
    const hasCrossdocking = crossdockingCollectorId !== null

    const shipping = hasCrossdocking
      ? service.serviceIDBoard.find(board => board.collector_id === crossdockingCollectorId) !== undefined
        ? service.serviceIDBoard.find(board => board.collector_id === crossdockingCollectorId)?.step === "DONE"
          ? branches?.find(branch => branch.id === service.serviceIDRequested.source_crossdocking_branch_id)?.shippingIDBranch.trading_name
          : branches?.find(branch => branch.id === service.serviceIDRequested.source_branch_id)?.shippingIDBranch.trading_name
        : branches?.find(branch => branch.id === service.serviceIDRequested.source_branch_id)?.shippingIDBranch.trading_name
      : branches?.find(branch => branch.id === service.serviceIDRequested.source_branch_id)?.shippingIDBranch.trading_name

    const deliveryDate = formatDate.handle(service.serviceIDRequested.delivery_date, "DateWithoutHourToShow")
    const deliveryHour = formatDate.handle(service.serviceIDRequested.delivery_hour, "DateOnlyWithHourMinute")

    const stepsToGetForecastValues = ["toBoardValidate", "toAllocateService"]

    const allocatedHour = hasCrossdocking
      ? stepsToGetForecastValues.includes(service.step)
        ? service.serviceIDLanding.find(landing => landing.collector_id === crossdockingCollectorId)?.step === "DONE"
          ? service.serviceIDRequested.availability_forecast_time
          : service.serviceIDRequested.crossdocking_availability_forecast_time
        : service.serviceIDLanding.find(landing => landing.collector_id === crossdockingCollectorId) !== undefined
          ? service.serviceIDLanding.find(landing => landing.collector_id === crossdockingCollectorId)?.step === "DONE"
            ? service.serviceIDAllocate.find(allocate => allocate.collector_id === crossdockingCollectorId) !== undefined
              ? service.serviceIDAllocate.find(allocate => allocate.collector_id === crossdockingCollectorId)?.allocate_availability_hour
              : "-"
            : service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id) !== undefined
              ? service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id)?.allocate_availability_hour
              : "-"
          : service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id) !== undefined
            ? service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id)?.allocate_availability_hour
            : "-"
      : stepsToGetForecastValues.includes(service.step)
        ? service.serviceIDRequested.availability_forecast_time
        : service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id)?.allocate_availability_hour

    const allocatedDate = hasCrossdocking
      ? stepsToGetForecastValues.includes(service.step)
        ? service.serviceIDLanding.find(landing => landing.collector_id === crossdockingCollectorId)?.step === "DONE"
          ? service.serviceIDRequested.availability_forecast_day
          : service.serviceIDRequested.crossdocking_availability_forecast_day
        : service.serviceIDLanding.find(landing => landing.collector_id === crossdockingCollectorId) !== undefined
          ? service.serviceIDLanding.find(landing => landing.collector_id === crossdockingCollectorId)?.step === "DONE"
            ? service.serviceIDAllocate.find(allocate => allocate.collector_id === crossdockingCollectorId) !== undefined
              ? service.serviceIDAllocate.find(allocate => allocate.collector_id === crossdockingCollectorId)?.allocate_availability_date
              : "-"
            : service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id) !== undefined
              ? service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id)?.allocate_availability_date
              : "-"
          : service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id) !== undefined
            ? service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id)?.allocate_availability_date
            : "-"
      : stepsToGetForecastValues.includes(service.step)
        ? service.serviceIDRequested.availability_forecast_day
        : service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id)?.allocate_availability_date

    const allocatedHourFormated = allocatedHour && allocatedHour !== '-'
      ? formatDate.handle(allocatedHour, "DateOnlyWithHourMinute")
      : '-'

    const allocatedDateFormated = allocatedDate && allocatedDate !== '-'
      ? formatDate.handle(allocatedDate, "DateWithoutHourToShow")
      : '-'

    const serviceStep = serviceHandleStep(service.step)

    const filter = searchBoxFilter(
      `${service.customerIDService.trading_firstname} ${sourceCities} ${destinationCities} ${collectDate} ${collectHourStart} ${collectHourEnd} ${shipping} ${deliveryDate} ${deliveryHour} ${allocatedDateFormated} ${allocatedHourFormated} ${serviceStep}`,
      searchedWords
    )

    if (searchedWords === '') return service

    return filter
  })

  return (
    <Flex direction="column">
      <SearchBox
        placeholder="Filtrar palavras..."
        handleSearch={(event) => handleSearchServices(event)}
        width="full"
      />
      <Flex
        overflowY="auto"
        maxHeight={1000}
        flexDirection="column"
        gap="4"
        sx={{
          '&::-webkit-scrollbar': {
            width: '10px',
          },
          '&::-webkit-scrollbar-track': {
            width: '10px',
            bg: 'gray.100',
          },
          '&::-webkit-scrollbar-thumb': {
            bg: 'gray.300',
            width: '4px',
            borderRadius: '24px',
          },
        }}
      >
        {isLoadingSearch ? (
          <Spinner />
        ) : (
          <>
            {!servicesFilteredByUser.length ? (
              <EmptyContentTableAlert
                title="Oops"
                description="Não foram encontrados resultados para o filtro. Tente novamente!"
              />
            ) : (
              servicesFilteredByUser.map(service => {
                const budgetFiltered = budgets
                  ?.find(budget => budget.id === service.serviceIDRequested.budget_id)

                const sourceCities = cities
                  ?.filter(city => budgetFiltered?.source_cities.includes(city.id))
                  .map(city => city.name)
                  .join(', ')

                const destinationCities = cities
                  ?.filter(city => budgetFiltered?.destination_cities.includes(city.id))
                  .map(city => city.name)
                  .join(', ')

                const collectDate = formatDate.handle(service.serviceIDRequested.collect_date, "DateWithoutHourToShow")
                const collectHourStart = formatDate.handle(service.serviceIDRequested.collect_hour_start, "DateOnlyWithHourMinute")
                const collectHourEnd = formatDate.handle(service.serviceIDRequested.collect_hour_end, "DateOnlyWithHourMinute")

                const crossdockingCollectorId = service.serviceIDRequested.crossdocking_collector_id
                const hasCrossdocking = crossdockingCollectorId !== null

                const shipping = hasCrossdocking
                  ? service.serviceIDBoard.find(board => board.collector_id === crossdockingCollectorId) !== undefined
                    ? service.serviceIDBoard.find(board => board.collector_id === crossdockingCollectorId)?.step === "DONE"
                      ? branches?.find(branch => branch.id === service.serviceIDRequested.source_crossdocking_branch_id)?.shippingIDBranch.trading_name
                      : branches?.find(branch => branch.id === service.serviceIDRequested.source_branch_id)?.shippingIDBranch.trading_name
                    : branches?.find(branch => branch.id === service.serviceIDRequested.source_branch_id)?.shippingIDBranch.trading_name
                  : branches?.find(branch => branch.id === service.serviceIDRequested.source_branch_id)?.shippingIDBranch.trading_name

                const deliveryDate = formatDate.handle(service.serviceIDRequested.delivery_date, "DateWithoutHourToShow")
                const deliveryHour = formatDate.handle(service.serviceIDRequested.delivery_hour, "DateOnlyWithHourMinute")

                const stepsToGetForecastValues = ["toBoardValidate", "toAllocateService"]

                const allocatedHour = hasCrossdocking
                  ? stepsToGetForecastValues.includes(service.step)
                    ? service.serviceIDLanding.find(landing => landing.collector_id === crossdockingCollectorId)?.step === "DONE"
                      ? service.serviceIDRequested.availability_forecast_time
                      : service.serviceIDRequested.crossdocking_availability_forecast_time
                    : service.serviceIDLanding.find(landing => landing.collector_id === crossdockingCollectorId) !== undefined
                      ? service.serviceIDLanding.find(landing => landing.collector_id === crossdockingCollectorId)?.step === "DONE"
                        ? service.serviceIDAllocate.find(allocate => allocate.collector_id === crossdockingCollectorId) !== undefined
                          ? service.serviceIDAllocate.find(allocate => allocate.collector_id === crossdockingCollectorId)?.allocate_availability_hour
                          : "-"
                        : service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id) !== undefined
                          ? service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id)?.allocate_availability_hour
                          : "-"
                      : service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id) !== undefined
                        ? service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id)?.allocate_availability_hour
                        : "-"
                  : stepsToGetForecastValues.includes(service.step)
                    ? service.serviceIDRequested.availability_forecast_time
                    : service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id)?.allocate_availability_hour

                const allocatedDate = hasCrossdocking
                  ? stepsToGetForecastValues.includes(service.step)
                    ? service.serviceIDLanding.find(landing => landing.collector_id === crossdockingCollectorId)?.step === "DONE"
                      ? service.serviceIDRequested.availability_forecast_day
                      : service.serviceIDRequested.crossdocking_availability_forecast_day
                    : service.serviceIDLanding.find(landing => landing.collector_id === crossdockingCollectorId) !== undefined
                      ? service.serviceIDLanding.find(landing => landing.collector_id === crossdockingCollectorId)?.step === "DONE"
                        ? service.serviceIDAllocate.find(allocate => allocate.collector_id === crossdockingCollectorId) !== undefined
                          ? service.serviceIDAllocate.find(allocate => allocate.collector_id === crossdockingCollectorId)?.allocate_availability_date
                          : "-"
                        : service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id) !== undefined
                          ? service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id)?.allocate_availability_date
                          : "-"
                      : service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id) !== undefined
                        ? service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id)?.allocate_availability_date
                        : "-"
                  : stepsToGetForecastValues.includes(service.step)
                    ? service.serviceIDRequested.availability_forecast_day
                    : service.serviceIDAllocate.find(allocate => allocate.collector_id === service.serviceIDRequested.source_collector_id)?.allocate_availability_date

                const allocatedHourFormated = allocatedHour && allocatedHour !== '-'
                  ? formatDate.handle(allocatedHour, "DateOnlyWithHourMinute")
                  : '-'

                const allocatedDateFormated = allocatedDate && allocatedDate !== '-'
                  ? formatDate.handle(allocatedDate, "DateWithoutHourToShow")
                  : '-'


                const serviceStep = serviceHandleStep(service.step)
                const sourceHub = hubs?.find(hub => hub.id === service?.serviceIDRequested?.budgetIDService.source_hub_id)

                const destinationHub = hubs?.find(hub => hub.id === service.serviceIDRequested?.budgetIDService.destination_hub_id)

                const crossdockingHub = hubs?.find(hub => hub.id === service?.serviceIDRequested?.budgetIDService.crossdocking_hub_id)

                const timezoneSourceHub = getTimezoneNameByValue(sourceHub?.timezone)
                const timezoneDestinationHub = getTimezoneNameByValue(destinationHub?.timezone)
                const timezoneCrossdockingHub = getTimezoneNameByValue(crossdockingHub?.timezone)

                return (
                  <KanbanCard key={service.id}>
                    <KanbanCardHeader serviceId={service.id} protocol={service.protocol} />
                    <Box p={4}>
                      <KanbanCardItem
                        icon={FaUser}
                        content={service.customerIDService.trading_firstname}
                      />
                      <KanbanCardItem
                        icon={FaPlaneDeparture}
                        content={sourceCities ?? '-'}
                      />
                      <KanbanCardItem
                        icon={FaPlaneArrival}
                        content={destinationCities ?? '-'}
                      />
                      <KanbanCardItem
                        icon={FaClock}
                        content={`${collectDate} - ${collectHourStart} -> ${collectHourEnd}`}
                      />
                      <KanbanCardItem
                        icon={FaClock}
                        content={`${deliveryDate} - ${deliveryHour}`}
                      />
                      <KanbanCardItem
                        icon={FaInfo}
                        content={`${serviceStep}`}
                      />
                      <KanbanCardItem
                        icon={FaClock}
                        content={`${allocatedDateFormated} - ${allocatedHourFormated}`}
                      />
                      {timezoneSourceHub && (
                        <KanbanCardItem
                          icon={FaClock}
                          content={`Fuso Origem: ${timezoneSourceHub}`}
                        />
                      )}
                      {timezoneDestinationHub && (
                        <KanbanCardItem
                          icon={FaClock}
                          content={`Fuso Destino: ${timezoneDestinationHub}`}
                        />
                      )}
                      {timezoneCrossdockingHub && (
                        <KanbanCardItem
                          icon={FaClock}
                          content={`Fuso Crossdocking: ${timezoneCrossdockingHub}`}
                        />
                      )}
                      <KanbanCardItem
                        icon={FaShippingFast}
                        content={`${shipping}`}
                        isLink
                        url={changeUrlByStep(service.id, service.step)}
                      />
                    </Box>
                  </KanbanCard>
                )
              })
            )}
          </>
        )}


      </Flex>
    </Flex>
  )
}
