import { api } from "services/api"
import request from "axios"
import { QueryKey, useQuery, UseQueryOptions } from "react-query"
import { RequestError } from "utils/errors/RequestErrors"
import { useToastify } from "hooks/toastify/useToastify"
import { useAuth } from "hooks/auth/useAuth"
import { User, UserSituation } from "./dtos/User"

interface UserQueryParams {
  situation?: UserSituation
  customer_id?: string
  collector_id?: string
}

type QueryOptions = UseQueryOptions<User[], unknown, User[], QueryKey>

export async function getUsers(queryParams?: UserQueryParams) {
  try {
    const { data } = await api.get<User[]>(`/user/`, {
      params: queryParams && {
        ...queryParams,
      }
    })

    return data
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}
interface UseUsersOptions {
  queryKey?: string
  queryOptions?: QueryOptions
  queryParms?: UserQueryParams
}

export function useUsers(options?: UseUsersOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([
    options?.queryKey ?? "users",
    {...options?.queryParms}
  ], () => getUsers(options?.queryParms), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
