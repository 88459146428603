import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Button,
  Stack,
  Icon,
  Image,
  Alert,
  AlertIcon,
  Link as ChakraLink,
  useMediaQuery,
  FormLabel,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useState, useMemo } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { handleChangeUrl } from '../../../utils/handleChangeUrl'
import { negative_positive } from '../../../utils/customLists'
import { FiExternalLink, FiFile, FiPlus } from 'react-icons/fi'
import { format } from 'date-fns'

import { LandingAccordion } from '../../../components/Accordions/Service/LandingAccordion/LandingAccordion'

import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { TextArea } from '../../../components/Inputs/TextInput'
import { Select } from '../../../components/Inputs/SelectInput'
import { Input } from '../../../components/Inputs/Input'

import { useService } from '../../../hooks/services/service'
import { useDriver } from '../../../hooks/driver/useDriver'
import { useBranch } from '../../../hooks/branch/useBranch'
import { useLandingServiceFunctions } from '../../../hooks/services/landing/useLandingServiceFunctions'
import { useAuth } from '../../../hooks/auth/useAuth'
import { useSwal } from '../../../hooks/swal/useSwal'
import { SubmitButton } from '../../../components/Buttons/SubmitButton'
import { ListButton } from '../../../components/Buttons/ListButton'
import { FieldError } from 'react-hook-form'
import { validateHasFile } from 'utils/imageValidation'
import { ExternalComunications } from 'components/ExternalComunications'
import { validateHaveDuplicateFiles } from 'utils/validateHaveDuplicateFiles'
import { useGeolocation } from 'hooks/useGeolocation'
import { toast } from 'react-toastify'

interface FormInputProps {
  step: string
  service_id: string
  is_start_landing_page: boolean
  is_edit_current_service: boolean
  collector_id: string
  arrival_latitude: string
  arrival_longitude: string
  arrival_timestamp: string
  branch_id: string
  driver_id: string
  board_volume: number
  landing_volume: number | null
  box_break: boolean | null
  box_break_photos: FileList | null
  cargo_photo: FileList
  departure_latitude: string | null
  departure_longitude: string | null
  departure_timestamp: string | null
  cargo_photo_change: string
  observation: string
}

interface BranchProps {
  id: string
  nickname: string
  shipping_id: string
  email: string
  hub_id: string
  cellphone: string
  telephone: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  business_open: string
  business_close: string
  saturday_open: string | null
  saturday_close: string | null
  sunday_open: string | null
  sunday_close: string | null
  observation: string
  shippingIDBranch: {
    id: string
    company_name: string
    trading_name: string
    modal: string
  }
  hubIDBranch: {
    name: string
  }
}

interface QueryParams {
  service_id: string
  slug: string
}

interface ILocationProps {
  state: {
    collector_id: string
  }
}

interface ILandingFormProps {
  isDisabled?: boolean
  title: string
}

const landingSchema = yup.object().shape({
  driver_id: yup.string().required('Campo Obrigatório!'),

  is_start_landing_page: yup.boolean(),
  is_edit_current_service: yup.boolean(),

  branch_id: yup.string().when('is_start_landing_page', {
    is: false,
    then: yup.string().required('Campo Obrigatório!'),
  }),
  landing_volume: yup.number().when('is_start_landing_page', {
    is: false,
    then: yup
      .number()
      .positive('Insira um valor positivo!')
      .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
      .typeError('Insira um valor válido!')
      .required('Campo Obrigatório.')
      .typeError('Insira um valor válido!')
      .required('Campo Obrigatório.'),
  }),
  box_break: yup.string().when('is_start_landing_page', {
    is: false,
    then: yup.string().required('Campo Obrigatório!'),
  }),
  box_break_photos: yup.mixed().when('box_break', {
    is: 'true',
    then: yup.mixed().when('is_edit_current_service', {
      is: false,
      then: yup.mixed().test('hasFile', 'Campo obrigatório', value => validateHasFile(value))
    }).test("duplicateFiles", "Não é permitido envio de arquivos iguais", async function (value) {
      const filesToCheck = [];

      if (!Array.isArray(value)) {
        filesToCheck.push(Object.assign({}, { attachment: value }));
      }

      if (!Array.isArray(this.parent.cargo_photo)) {
        filesToCheck.push(Object.assign({}, { attachment: this.parent.cargo_photo }));
      }
      return !validateHaveDuplicateFiles(...filesToCheck)
    })
  }),
  cargo_photo: yup.mixed().when('is_start_landing_page', {
    is: false,
    then: yup.mixed().when('is_edit_current_service', {
      is: true,
      then: yup.mixed().when('cargo_photo_change', {
        is: true,
        then: yup.mixed().required('Campo obrigatório')
      }),
      otherwise: yup.mixed().test('hasFile', 'Campo obrigatório', value => validateHasFile(value))
    }).test("duplicateFiles", "Não é permitido envio de arquivos iguais", async function (value) {
      const filesToCheck = [];

      if (value !== undefined) {
        filesToCheck.push(Object.assign({}, { attachment: value }));
      }

      if (this.parent.box_break_photos !== undefined) {
        filesToCheck.push(Object.assign({}, { attachment: this.parent.box_break_photos }));
      }
      return !validateHaveDuplicateFiles(...filesToCheck)
    }),
  }),
  cargo_photo_change: yup.string().when('is_start_landing_page', {
    is: false,
    then: yup.string().when('is_edit_current_service', {
      is: true,
      then: yup.string().required('Campo Obrigatório!'),
    }),
  }),
  observation: yup.string(),
})

async function alertGeolocationError(error: GeolocationPositionError) {
  if (error.code === 1) {
    toast.error("Para iniciar/finalizar o desembarque, é necessário que a localização esteja habilitada!")
  }
  if (error.code === 2) {
    toast.error("Localização não está disponível! Tente recarregar a página ou verifique as configurações do dispositivo.")
  }
}


export function LandingService({
  isDisabled = false,
  title,
}: ILandingFormProps) {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  const [destinationBranches, setDestinationBranches] = useState<BranchProps[]>(
    [],
  )

  const [hasBoxBreak, setHasBoxBreak] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const {
    createLandingService: { mutateAsync: createLandingService },
    finishLandingService: { mutateAsync: finishLandingService },
    updateLandingService: { mutateAsync: updateLandingService },
  } = useLandingServiceFunctions()
  const { standardMessage, confirmMessage } = useSwal()
  const { userLogged } = useAuth()

  const { service_id, slug }: QueryParams = useParams()
  const { state }: ILocationProps = useLocation()
  const { push: redirectTo } = useHistory()

  const location = useLocation()
  const resState = location.state as { path?: string }
  const hasPathRedirect = resState?.path !== undefined && resState?.path !== null

  const {
    service: { data: service, isLoading: isServiceLoading },
  } = useService(service_id, false, false)
  const {
    drivers: { data: drivers, isLoading: isDriverLoading },
  } = useDriver(null, true, false)
  const {
    branches: { data: branches, isLoading: isBranchLoading },
  } = useBranch(null, true, false)

  const { position, error: geolocationError } = useGeolocation()

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    resolver: yupResolver(landingSchema),
  })

  const isDriverSelected = watch('driver_id')
  const changeCargoPhoto = watch('cargo_photo_change')
  const collectorId = watch('collector_id')
  const branchId = watch('branch_id')
  const hasBoxBreakInLandingService = watch('box_break')

  const attachmentPhoto = watch("box_break_photos")
  const cargoPhoto = watch("cargo_photo")

  const boxBreakPhotoPreview = attachmentPhoto
    ? Object.entries(attachmentPhoto).map(([key, value]) => URL.createObjectURL(value))[0]
    : null

  const cargoPhotoPreview = cargoPhoto
    ? Object.entries(cargoPhoto).map(([key, value]) => URL.createObjectURL(value))[0]
    : null

  const landingServiceByCollectorId = useMemo(() => {
    const collectorValueLandingService = service?.serviceIDLanding.map(
      (landingService) => landingService.collector_id,
    )

    if (!collectorValueLandingService) {
      return service?.serviceIDLanding.filter(
        (landingService) => landingService.collector_id === null,
      )
    } else {
      return service?.serviceIDLanding.filter(
        (landingService) => landingService.collector_id === collectorId,
      )
    }
  }, [service, collectorId])

  const boardServiceByCollectorId = useMemo(() => {
    const collectorValueBoardService = service?.serviceIDBoard.find(
      (boardService) => boardService.collector_id,
    )

    if (!collectorValueBoardService) {
      return service?.serviceIDBoard.filter(
        (boardService) => boardService.collector_id === null,
      )
    } else {
      return service?.serviceIDBoard.filter(
        (boardService) => boardService.branch_id === branchId,
      )
    }
  }, [service, branchId])


  useEffect(() => {

    const hasCrossdockingCollectorID = service?.serviceIDRequested.crossdocking_collector_id
    const sourceCrossdockingBranchID = service?.serviceIDRequested.source_crossdocking_branch_id
    const sourceBranchID = service?.serviceIDRequested.source_branch_id

    if (!hasCrossdockingCollectorID) {
      const destinationBranchFiltered = branches
        ? branches.filter((branch) => branch.id === sourceBranchID)
        : []
      const branchId = String(
        destinationBranchFiltered.map((branch) => branch.id),
      )
      const destinationCollectorId = service?.serviceIDRequested.destination_collector_id ?? ''
      setDestinationBranches(destinationBranchFiltered)
      setValue('branch_id', branchId)
      if (!state?.collector_id) {
        setValue('collector_id', destinationCollectorId)
      } else {
        setValue('collector_id', state?.collector_id)
      }
    } else {
      const landingServiceWithCrossdockingBranch =
        service?.serviceIDLanding.filter(
          (landingService) => landingService.branch_id === sourceBranchID,
        ) ?? []
      const landingServiceCrossdockingBranchStep =
        landingServiceWithCrossdockingBranch.map((service) => service.step
        ) ?? []
      if (
        landingServiceWithCrossdockingBranch.length === 0 ||
        landingServiceCrossdockingBranchStep[0] === 'GOING'
      ) {
        const sourceBranch = branches
          ? branches.filter((branch) => branch.id === sourceBranchID)
          : []
        const branchId = String(sourceBranch.map((branch) => branch.id))
        const crossdockingCollectorId =
          service.serviceIDRequested.crossdocking_collector_id ?? ''

        setDestinationBranches(sourceBranch)
        setValue('branch_id', branchId)
        if (!state?.collector_id) {
          setValue('collector_id', crossdockingCollectorId)
        } else {
          setValue('collector_id', state?.collector_id)
        }
      } else {
        const sourceCrossdockingBranch = branches
          ? branches.filter(
            (branch) => branch.id === sourceCrossdockingBranchID,
          )
          : []
        const sourceCrossDockingBranchID = String(
          sourceCrossdockingBranch.map((branch) => branch.id),
        )
        const destinationCollectorId =
          service.serviceIDRequested.destination_collector_id ?? ''

        setDestinationBranches(sourceCrossdockingBranch)
        setValue('branch_id', sourceCrossDockingBranchID)
        if (!state?.collector_id) {
          setValue('collector_id', destinationCollectorId)
        } else {
          setValue('collector_id', state?.collector_id)
        }
      }
    }
  }, [
    service,
    branches,
    setValue,
    state?.collector_id,
  ])

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (!permissions?.includes('add-landing-service')) {
            redirectTo('/')
          }
        }
      } catch {
        redirectTo('/')
      }
    }
    run()
  }, [slug, userLogged, redirectTo])

  useEffect(() => {
    function run() {
      let boardVolume = 0
      boardServiceByCollectorId?.forEach((bService) => {
        if (bService.board_volume !== null) {
          boardVolume += bService.board_volume
        }
      })
      setValue('board_volume', boardVolume)
    }
    run()
  }, [setValue, boardServiceByCollectorId, collectorId])

  useEffect(() => {
    function run() {
      const landingServiceStepByCollectorID = landingServiceByCollectorId?.map(
        (landingService) => landingService.step,
      ) ?? null

      if ((!landingServiceStepByCollectorID || !landingServiceStepByCollectorID[0]) && !slug) {
        setValue('is_start_landing_page', true)
        if (geolocationError) {
          return alertGeolocationError(geolocationError)
        }
        if(position){
          setValue('arrival_latitude', String(position?.coords.latitude))
          setValue('arrival_longitude', String(position?.coords.longitude))
        }

        setValue(
          'arrival_timestamp',
          format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        )
        setValue('service_id', service_id)
      } else {
        setValue('is_start_landing_page', false)
        if (landingServiceStepByCollectorID && landingServiceStepByCollectorID[0] === 'GOING' && !slug) {
          setValue('service_id', service_id)
          landingServiceByCollectorId?.map((landingService) => {
            setValue('driver_id', landingService.driver_id)
            return (
              setValue('arrival_latitude', landingService.arrival_latitude),
              setValue('arrival_longitude', landingService.arrival_longitude),
              setValue('arrival_timestamp', landingService.arrival_timestamp)
            )
          })
          setValue(
            'departure_timestamp',
            format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
          )
          if (geolocationError) {
            return alertGeolocationError(geolocationError)
          }
          if(position){
            setValue('departure_latitude', position?.coords.latitude.toString())
            setValue(
              'departure_longitude',
              position?.coords.longitude.toString(),
            )
          }

        }
      }
    }

    run()
  }, [setValue, service, slug, landingServiceByCollectorId, service_id, geolocationError, position])


  const isUserTypeDriver = userLogged?.user_type === 'MOTORISTA'

  const landingDrivers = isUserTypeDriver
    ? drivers
      ?.filter((driver) => driver.collector_id === collectorId)
      .filter((driver) => driver.id === userLogged?.driver_id)
    : drivers?.filter(
      (driver) => driver.collector_id === collectorId,
    )

  useEffect(() => {
    function run() {
      if (hasBoxBreak === 'SIM') {
        setValue('box_break', true)
      } else {
        setValue('box_break', false)
      }
    }
    run()
  }, [setValue, hasBoxBreak])

  useEffect(() => {
    function run() {
      if (slug) {
        setValue('is_edit_current_service', true)
        landingServiceByCollectorId?.map((service) => {
          if (service.box_break === false) {
            setHasBoxBreak('NÃO')
          } else if (service.box_break === true) {
            setHasBoxBreak('SIM')
          }

          // if (sourceDrivers.length > 0) {
          setValue('driver_id', service.driver_id)
          // }

          return (
            setValue('service_id', service.service_id),
            setValue('arrival_latitude', service.arrival_latitude),
            setValue('arrival_longitude', service.arrival_longitude),
            setValue('arrival_timestamp', service.arrival_timestamp),
            setValue('branch_id', service.branch_id),
            setValue('landing_volume', service.landing_volume),
            setValue('box_break', service.box_break),
            //setValue('cargo_photo', service.cargo_photo),
            setValue('departure_latitude', service.departure_latitude),
            setValue('departure_longitude', service.departure_longitude),
            setValue('departure_timestamp', service.departure_timestamp),
            setValue('observation', service.observation)

          )
        })
      } else {
        setValue('is_edit_current_service', false)
      }
    }
    run()
  }, [slug, setValue, landingServiceByCollectorId])


  async function handleCreateLandingService(values: FormInputProps) {
    if (geolocationError) {
      return alertGeolocationError(geolocationError)
    }
    const hasCreateLandingService = await confirmMessage({
      title: 'Deseja iniciar um desembarque?',
    })

    if (hasCreateLandingService) {
      setIsLoading(true)
      try {
        const createLandingServiceResponse = await createLandingService(values)
        if (createLandingServiceResponse && userLogged) {
          redirectTo({
            pathname: `/servicos/desembarques/${service_id}`,
            state: {
              path: resState?.path
            }
          })
          setIsLoading(false)
        }
      } catch {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  async function handleFinishLandingService(values: FormInputProps) {

    if (geolocationError) {
      return alertGeolocationError(geolocationError)
    }

    const formData = new FormData()
    formData.append('service_id', values.service_id as any)
    formData.append('collector_id', values.collector_id as any)
    formData.append('landing_volume', values.landing_volume as any)
    formData.append('box_break', values.box_break as any)
    formData.append('cargo_photo', values.cargo_photo[0])
    formData.append('departure_timestamp', values.departure_timestamp as any)
    formData.append('departure_latitude', values.departure_latitude as any)
    formData.append('departure_longitude', values.departure_longitude as any)
    formData.append('observation', values.observation as any)

    if (values.box_break === true) {
      Object.entries(values.box_break_photos).forEach(([key, photo]) => {
        formData.append('box_break_photos', photo)
      })
    }

    const finishLandingServiceReq = {
      serviceID: service_id,
      finishLandingServiceInput: formData as any,
    }

    const hasFinishLandingService = await confirmMessage({
      title: 'Deseja finalizar um desembarque?',
    })

    if (hasFinishLandingService) {
      if (Number(values.landing_volume) !== values.board_volume) {
        return standardMessage(
          'Os valores de volume de embarque e desembarque devem ser iguais!',
        )
      }
      const hasCrossdocking =
        service?.serviceIDRequested.crossdocking_collector_id ===
        collectorId
      formData.append('hasCrossdocking', hasCrossdocking as any)
      setIsLoading(true)
      try {
        const finishLandingServiceResponse = await finishLandingService({
          ...finishLandingServiceReq,
        })
        if (finishLandingServiceResponse && userLogged) {
          if (hasPathRedirect) {
            redirectTo(resState?.path)
          } else {
            redirectTo('/servicos/desembarques')
          }
        }
      } catch {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const handleUpdateLandingService = async (values: FormInputProps) => {
    const formData = new FormData()
    formData.append('branch_id', values.branch_id)
    formData.append('collector_id', values.collector_id)
    formData.append('service_id', values.service_id)
    formData.append('driver_id', values.driver_id)
    formData.append('landing_volume', values.landing_volume as any)
    formData.append('box_break', values.box_break as any)
    formData.append('cargo_photo', values.cargo_photo[0])

    if (slug) {
      if (values.cargo_photo_change === 'SIM') {
        formData.append('cargoPhotoChoose', 'SIM')
      }
    }

    formData.append('departure_timestamp', values.departure_timestamp as any)
    formData.append('departure_latitude', values.departure_latitude as any)
    formData.append('departure_longitude', values.departure_longitude as any)
    formData.append('observation', values.observation as any)

    const updateLandingServiceReq = {
      serviceID: service_id,
      updateLandingServiceInput: formData as any,
    }

    const hasUpdateLandingService = await confirmMessage({
      title: 'Deseja atualizar um desembarque',
    })

    if (hasUpdateLandingService) {
      if (Number(values.landing_volume) !== values.board_volume) {
        return standardMessage(
          'Os valores de volume de embarque e desembarque devem ser iguais!',
        )
      }
      setIsLoading(true)
      try {
        const updateLandingServiceResponse = await updateLandingService({
          ...updateLandingServiceReq,
        })
        if (updateLandingServiceResponse && userLogged) {
          if(hasPathRedirect){
            redirectTo(resState?.path)
          }else{
            redirectTo(`/servico/editar/${service_id}`)
          }
        }
      } catch {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }


  const {
    isOpen: isExternalComunicationModalOpen,
    onOpen: onOpenExternalComunicationModal,
    onClose: onCloseExternalComunicationModal,
  } = useDisclosure()

  if (isLoading || (isServiceLoading || isDriverLoading || isBranchLoading)) {
    return <GeneralContentLoading />
  }

  if (landingServiceByCollectorId && landingServiceByCollectorId?.length === 0) {
    return (
      <>
        {(userLogged?.user_type === 'COLETADOR' || userLogged?.user_type === 'MOTORISTA') && (
          <ExternalComunications.TriggerButton
            aria-label="help-me"
            onClick={onOpenExternalComunicationModal}
          />
        )}

        <ExternalComunications.Root
          isOpen={isExternalComunicationModalOpen}
          onClose={onCloseExternalComunicationModal}
        >
          <ExternalComunications.Content
            serviceId={service_id}
            onClose={onCloseExternalComunicationModal}
          />
        </ExternalComunications.Root>

        <Flex>
          <Box
            as="form"
            flex="1"
            borderRadius="8px"
            bg="white"
            p={['6', '8']}
            onSubmit={handleSubmit(handleCreateLandingService)}
            noValidate
          >
            <Flex
              align="center"
              justify="space-between"
              direction={['column', 'column', 'row']}
            >
              <Heading size="lg" fontFamily="poppins">
                Iniciar Desembarque
              </Heading>
            </Flex>

            <Divider my="6" borderColor="gray.700" />

            {service && (
              <LandingAccordion serviceByID={service} branchId={branchId} />
            )}

            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'column']}
            >
              {destinationBranches.map((branch) => (
                <Input
                  key={branch.id}
                  defaultValue={`${branch.nickname} | ${branch.cep} | ${branch.street} | ${branch.neighborhood} | ${branch.state}`}
                  isDisabled={true}
                  name="firstname"
                  label="Endereço da Base"
                />
              ))}

              <Select
                {...register('driver_id')}
                name="driver_id"
                label="Motorista Responsável"
                error={errors.driver_id}
                drivers={landingDrivers}
                isDisabled={isDisabled}
                placeholder="Selecione uma opção..."
                required
              />

              {isDriverSelected && (
                <Button type="submit" colorScheme="blue">
                  Iniciar Desembarque
                </Button>
              )}
            </Stack>

            <Flex mt="8" justify="flex-end">
              <HStack>
                <Link to="/servicos/desembarques">
                  <Button type="button" colorScheme="gray">
                    Lista de Serviços à Desembarcar
                  </Button>
                </Link>
              </HStack>
            </Flex>
          </Box>
        </Flex>
      </>
    )
  } else {
    return (
      <>
        {(userLogged?.user_type === 'COLETADOR' || userLogged?.user_type === 'MOTORISTA') && (
          <ExternalComunications.TriggerButton
            aria-label="help-me"
            onClick={onOpenExternalComunicationModal}
          />
        )}

        <ExternalComunications.Root
          isOpen={isExternalComunicationModalOpen}
          onClose={onCloseExternalComunicationModal}
        >
          <ExternalComunications.Content
            serviceId={service_id}
            onClose={onCloseExternalComunicationModal}
          />
        </ExternalComunications.Root>

        <Flex>
          <Box
            as="form"
            flex="1"
            borderRadius="8px"
            bg="white"
            p={['6', '8']}
            onSubmit={handleSubmit(
              slug ? handleUpdateLandingService : handleFinishLandingService,
            )}
            noValidate
          >
            <Heading size="lg" fontFamily="poppins">
              {title}
            </Heading>

            <Divider my="6" borderColor="gray.700" />

            {service && (
              <LandingAccordion
                serviceByID={service}
                branchId={service.serviceIDRequested.source_branch_id ?? ''}
              />
            )}

            <Alert status="info">
              <AlertIcon />
              {slug ? 'Informações do Desembarque' : 'Desembarque Iniciado'}
            </Alert>

            <Divider my="6" borderColor="gray.700" />

            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              {destinationBranches.map((branch) => (
                <Input
                  key={branch.id}
                  defaultValue={branch.nickname.toUpperCase()}
                  isDisabled={true}
                  name="firstname"
                  label="Base de Origem"
                />
              ))}

              <Select
                {...register('driver_id')}
                name="driver_id"
                label="Motorista Responsável"
                error={errors.driver_id}
                drivers={landingDrivers}
                isDisabled={isDisabled}
                placeholder="Selecione uma opção..."
                required
              />
            </Stack>
            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              <HStack w="100%" spacing="24px">
                <Input
                  {...register('board_volume')}
                  name="board_volume"
                  label="Volume Embarcado"
                  type="number"
                  isDisabled={true}
                />
                <Input
                  {...register('landing_volume')}
                  name="landing_volume"
                  label="Volume Desembarcado"
                  error={errors.landing_volume}
                  type="number"
                  isDisabled={isDisabled}
                  required
                />
              </HStack>
              <Select
                name="box_break"
                value={hasBoxBreak}
                onChange={(e) => setHasBoxBreak(e.target.value)}
                label="Caixas com Avarias"
                placeholder="Selecione uma opção..."
                negative_positive={negative_positive}
                error={errors.box_break}
                isDisabled={isDisabled}
                required
              />
            </Stack>

            {!!slug && (
              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Select
                  {...register('cargo_photo_change')}
                  negative_positive={negative_positive}
                  placeholder="Selecione uma opção..."
                  name="cargo_photo_change"
                  label="Alterar Foto da Carga?"
                  error={errors.cargo_photo_change}
                  isDisabled={isDisabled}
                  required
                />
              </Stack>
            )}

            {slug ? (
              <>
                {service?.serviceIDLanding.map((landingService) => (
                  <Stack
                    key={landingService.id}
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input name="link_box" hidden>
                      <ChakraLink
                        fontSize="lg"
                        href={handleChangeUrl(
                          landingService.cargo_photo as string,
                        )}
                        isExternal
                      >
                        Foto do Cargo <Icon as={FiExternalLink} mx="2px" />
                      </ChakraLink>
                    </Input>
                  </Stack>
                ))}

                {changeCargoPhoto === 'SIM' && (
                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      {...register("cargo_photo")}
                      name="cargo_photo"
                      type="file"
                      accept="image/*"
                      hidden
                      label="Foto da carga"
                      error={errors.cargo_photo}
                      required
                      multiple={false}

                    >
                      <Button
                        leftIcon={<Icon as={FiFile} />}
                        as={FormLabel}
                        htmlFor="cargo_photo"
                        _hover={{
                          cursor: "pointer"
                        }}

                      >
                        Upload
                      </Button>
                    </Input>
                    {!!cargoPhotoPreview && (
                      <Image
                        boxSize="100px"
                        objectFit="cover"
                        src={cargoPhotoPreview}
                      />
                    )}
                  </Stack>
                )}
              </>
            ) : (
              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <>
                  <Input
                    {...register("cargo_photo")}
                    name="cargo_photo"
                    type="file"
                    accept="image/*"
                    hidden
                    label="Foto da carga"
                    error={errors.cargo_photo as FieldError}
                    required
                    multiple={false}
                  >
                    <Button
                      leftIcon={<Icon as={FiFile} />}
                      as={FormLabel}
                      htmlFor="cargo_photo"
                      _hover={{
                        cursor: "pointer"
                      }}
                    >
                      Upload
                    </Button>
                  </Input>
                  {!!cargoPhotoPreview && (
                    <Image
                      boxSize="100px"
                      objectFit="cover"
                      src={cargoPhotoPreview}
                    />
                  )}
                </>

                {hasBoxBreakInLandingService && (
                  <>
                    <Input
                      {...register("box_break_photos")}
                      name="box_break_photos"
                      type="file"
                      accept="image/*"
                      hidden
                      label="Foto da(s) caixa(s) com avaria"
                      error={errors.box_break_photos as FieldError}
                      required
                    >
                      <Button
                        as={FormLabel}
                        htmlFor="box_break_photos"
                        _hover={{
                          cursor: "pointer"
                        }}
                        leftIcon={<Icon as={FiFile} />}
                      >
                        Upload
                      </Button>
                    </Input>
                    {boxBreakPhotoPreview && (
                      <Image
                        boxSize="100px"
                        objectFit="cover"
                        src={boxBreakPhotoPreview}
                        alt=""
                      />
                    )}
                  </>
                )}
              </Stack>
            )}

            <TextArea
              {...register('observation')}
              name="observation"
              label="Observações"
              isDisabled={isDisabled}
            />

            <Flex mt="8" justify="flex-end">
              <Flex w="full" justifySelf="center">
                {/* {userLogged?.permissions.includes("add-occurrence") && ( */}
                <Link
                  to={{
                    pathname: '/ocorrencia/criar',
                    state: {
                      service_id,
                    },
                  }}
                >
                  <Button colorScheme="gray" rightIcon={<Icon as={FiPlus} />}>
                    {isWideVersion ? 'Adicionar Ocorrência' : 'Ocorrência'}
                  </Button>
                </Link>
              </Flex>
              <Stack spacing="12px" direction="row">
                {slug ? (
                  <SubmitButton
                    action="Atualizar Desembarque"
                    isSubmitting={isSubmitting}
                  />
                ) : (
                  <SubmitButton
                    action="Finalizar Desembarque"
                    isSubmitting={isSubmitting}
                  />
                )}
                <ListButton
                  href="/servicos/desembarques"
                  name="Serviços à Desembarcar"
                />
              </Stack>
            </Flex>
          </Box>
        </Flex>
      </>
    )
  }
}
