import { Flex, Input, MenuItemOption, MenuList, MenuOptionGroup } from "@chakra-ui/react"
import { ChangeEvent } from "react";

interface City {
  id: string;
  name: string;
}

interface FilterMenuProps {
  name: string
  valueFilter: string;
  handleSetFilters: (event: ChangeEvent<HTMLInputElement>) => void;
  citiesNamesFiltered: City[];
  handleSetFiltersCities: (cityId: string) => void;
}

export function BusinessServicesCheckboxFilterMenuList({
  name,
  valueFilter,
  handleSetFiltersCities,
  citiesNamesFiltered,
  handleSetFilters }: FilterMenuProps) {
  return (
    <MenuList
      rounded="xl"
      boxShadow="md"
      w="full"
    >
      <Input
        name={name}
        placeholder="Buscar cidade..."
        value={valueFilter}
        onChange={(event) => handleSetFilters(event)}
        m="2"
        w="min"
        rounded="lg"
      />
      <Flex
        direction="column"
        maxH="150px"
        overflowY="auto"
      >
        {citiesNamesFiltered.map((city) => {
          return (
            <MenuOptionGroup key={city.id} type='checkbox'>
              <MenuItemOption value={city.id} onClick={() => handleSetFiltersCities(city.id)}>{city.name}</MenuItemOption>
            </MenuOptionGroup>
          )
        })}
      </Flex>
    </MenuList>
  )
}
