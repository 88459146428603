import { useEffect } from "react";
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";
import { useAuth } from "hooks/auth/useAuth";
import { useHistory, Link } from "react-router-dom";
import { useRegions } from "hooks/regions/useRegions";

import { Box, Button, Flex, Heading, Icon, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { FiEdit3, FiEye, FiPlus, FiTrash2 } from "react-icons/fi";
import { useRegionFunctions } from "hooks/regions/useRegionFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { usePagination } from "@ajna/pagination";
import { Pagination } from "components/Pagination/Pagination";

export function RegionList() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()
  const { push: redirect, location } = useHistory()

  const { promiseMessage } = useToastify();

  const userHasPermissionViewRegions =
    userLogged?.permissions.includes("view-regions");

  useEffect(() => {
    if (!userHasPermissionViewRegions) {
      redirect("/");
    }
  }, [userHasPermissionViewRegions, redirect]);


  const currentPageFilter = location.search.match(`[?&]page=([^&]+)`)

  const { data: regions, isFetching: isFetchingRegions } = useRegions({
    queryParams: {
      pageSize: Number(rowsPerPage),
      currentPage: currentPageFilter ? Number(currentPageFilter[1]) : 1
    }
  })
  const { deleteRegion: { mutateAsync: deleteRegion } } = useRegionFunctions()

  async function handleDeleteRegion(regionId: string) {
    await promiseMessage(
      deleteRegion(regionId, {
        onSuccess: () => {
          redirect("/regioes");
        },
      }),
      "Região deletada com sucesso!"
    );
  }

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: regions?.totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  const handleChangePage = (page: number) => setCurrentPage(page)

  useEffect(() => {
    if (userHasPermissionViewRegions) {
      redirect({
        pathname: '/regioes',
        search: `?page=${currentPage}`
      })
    }
  }, [currentPage, redirect, userHasPermissionViewRegions])

  if (isFetchingRegions) {
    return <GeneralContentLoading />
  }

  return (

      <Box
        p={4}
        bg="white"
        borderRadius="8px"
      >
        <Flex
          w="full"
          direction="column"
          gap={6}
        >
          <Flex
            mb="8"
            justify="space-between"
            align="center"
            direction={['column', 'column', 'row']}
          >
            <Heading fontSize="xl">Regiões</Heading>

            {userLogged !== null && (
              <Link to="/regioes/adicionar">
                {userLogged?.permissions.includes('create-regions') && (
                  <Button
                    colorScheme="gray"
                    rightIcon={<Icon as={FiPlus} />}
                    size="sm"
                    mt={['2', '2', '0']}
                  >
                    Adicionar Região
                  </Button>
                )}
              </Link>
            )}
          </Flex>

          {isFetchingRegions ? (
            <Spinner />
          ) : (
            <TableContainer>
              <Table size='sm' variant='striped'>
                <Thead>
                  <Th>NOME</Th>
                  <Th>ACRÔNIMO</Th>
                  <Th></Th>
                </Thead>
                <Tbody>
                  {regions?.regions.map((region) => {
                    return (
                      <Tr key={region.id}>
                        <Td>{region.name}</Td>
                        <Td>{region.acronym}</Td>
                        <Td isNumeric>
                          {userLogged !== null && (
                            <>
                              {userLogged.permissions.includes('view-regions') && (
                                <Link to={`/regioes/vizualizar/${region.id}`} >
                                  <Icon
                                    cursor="pointer"
                                    as={FiEye}
                                    transition="all 0.2s"
                                    _hover={{ opacity: 0.8 }}
                                    fontSize="20"
                                  />
                                </Link>
                              )}
                              {userLogged.permissions.includes('edit-regions') && (
                                <Link to={`/regioes/editar/${region.id}`} >
                                  <Icon
                                    cursor="pointer"
                                    as={FiEdit3}
                                    transition="all 0.2s"
                                    _hover={{ opacity: 0.8 }}
                                    fontSize="20"
                                    ml="4"
                                  />
                                </Link>
                              )}
                              {userLogged.permissions.includes('delete-regions') && (
                                <Icon
                                  onClick={() => handleDeleteRegion(region.id)}
                                  cursor="pointer"
                                  as={FiTrash2}
                                  transition="all 0.2s"
                                  _hover={{ opacity: 0.8 }}
                                  fontSize="20"
                                  ml="4"
                                />

                              )}
                            </>
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Flex>
        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />
      </Box>

  )

}
