import { Box, Flex } from "@chakra-ui/react";
import { PatrimonyStatus as PatrimonyStatusTypes } from "api/patrimonies/patrimony";
import { patrimonyStatusMap } from "utils/patrimonyMappers";

interface PatrimonyStatusProps {
  status: PatrimonyStatusTypes;
}

export function PatrimonyStatus({ status }: PatrimonyStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {status === 'in-possession' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'available' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'disabled' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {patrimonyStatusMap[status]}
    </Flex>
  )
}
