import { Button, Flex } from "@chakra-ui/react";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";

import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { WarehouseForm, WarehouseFormInputs } from "./components/WarehouseForm"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";

import { useToastify } from "hooks/toastify/useToastify";
import { useSwal } from "hooks/swal/useSwal";
import { useAuth } from "hooks/auth/useAuth";

import { useWarehouse } from "hooks/warehouse/useWarehouse";
import { useWarehouseFunctions } from "hooks/warehouse/useWarehouseFunctions";

interface Params {
  id: string
}

const editWarehouseSchema = yup.object().shape({
  situation: yup.string().required("Campo obrigatório"),
  name: yup.string().required("Campo obrigatório")
})

export function EditWarehousePage() {

  const { id } = useParams<Params>()
  const { push: redirectTo } = useHistory()
  const { userLogged } = useAuth()

  useEffect(() => {
      if (!userLogged?.permissions.includes('edit-warehouse')) {
      redirectTo("/")
    }
  }, [userLogged, redirectTo])



  const {data: warehouse, isFetching: isFetchingWarehouse, isFetched: isFetchedWarehouse} = useWarehouse({warehouseId: id, queryOptions: {enabled: !!id}})

  const {updateWarehouse: {mutateAsync: updateWarehouse}}= useWarehouseFunctions()


  const { promiseMessage } = useToastify()
  const { confirmMessage, standardMessage } = useSwal()

  const formMethods = useForm<WarehouseFormInputs>({
    resolver: yupResolver(editWarehouseSchema)
  })

  const { handleSubmit, setValue, formState: { isSubmitting } } = formMethods

  async function handleEditWarehouse({ ...values }: WarehouseFormInputs) {
    const hasEditWarehouse = await confirmMessage({ title: "Deseja editar o almoxarifado?" })

    if (hasEditWarehouse) {
      const successMessage = 'Almoxarifado editado com sucesso!'

      await promiseMessage(updateWarehouse({ warehouse_id: id, input: values }, {
        onSuccess: () => redirectTo("/almoxarifados")
      }), successMessage)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  useEffect(() => {
    if (isFetchedWarehouse && warehouse) {
      setValue("name", warehouse.name)
      setValue("situation", warehouse.situation)

    }
  }, [ setValue, isFetchedWarehouse, warehouse])

  if (isFetchingWarehouse) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm
      title="Editar Almoxarifado"
      onSubmit={handleSubmit(handleEditWarehouse)}
    >
      <FormProvider {...formMethods}>
        <WarehouseForm slug="editar"/>
      </FormProvider>

      <Flex
        mt={6}
        direction={["column", "column", "row"]}
        align="center"
        justify={["center", "center", "flex-end"]}
      >
        <Button
          as={Link}
          colorScheme='gray'
          w={['full', 'full', 'min']}
          isLoading={isSubmitting}
          to='/almoxarifados'
        >
          Lista de almoxarifados
        </Button>
        <Button
          w={["full", "full", "min"]}
          colorScheme="blue"
          type="submit"
          isLoading={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}
