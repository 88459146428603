import { Accordion } from '@chakra-ui/react'
import { memo } from 'react'
import { ServiceProps } from '../../../../contexts/ServiceContext'
import { InformationAccordion } from '../InformationAccordion/InformationAccordion'

interface AllocateAccordionProps {
  serviceByID: ServiceProps
}

function AllocateAccordion({ serviceByID }: AllocateAccordionProps) {
  return (
    <Accordion allowMultiple>
      <InformationAccordion serviceByID={serviceByID} />
    </Accordion>
  )
}

export default memo(AllocateAccordion)
