import { useEffect, useState } from "react";
import { usePagination } from "@ajna/pagination";
import {
  Box,
  Flex,
  Heading,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import { FiCheckCircle } from "react-icons/fi";
import { useService } from "hooks/services/service";
import { useAddresses } from "hooks/address/useAddresses";
import { useAuth } from "hooks/auth/useAuth";
import { useHistory } from "react-router-dom";
import { Pagination } from "components/Pagination/Pagination";
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";
import { SearchBox } from "components/SearchBox/SearchBox";
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions";
import { formatDate } from "utils/DateFunctions/formatDate";
import { searchBoxFilter } from "utils/searchBoxFilter";
import { TableFilterButton } from "components/Filters/TableFilterButton";
import { serviceSortByDate } from "utils/ServiceFunctions/serviceSortByDate";
import { ValidateAddressesRequestedToCancelModal } from "./components/ValidateAddressesRequestedToCancelModal";
import { EmptyContentTableAlert } from "components/Alerts/EmptyContentTableAlert";
import { ValidateServicesRequestedToCancelModal } from "pages/Services/PendingValidations/components/ValidateServiceRequestedToCancelModal";
import { ServiceCancelReasons } from "hooks/services/useServiceFunctions";

interface FilterOptionInputs {
  protocol: string;
  address: string;
  collect_date: string;
  customer: string;
  collector: string;
}

const collectsAddressesAndServicesCancelfilterOptions = [
  { key: "protocol", value: "Protocolo", checked: false },
  { key: "step", value: "Tipo de cancelamento", checked: false },
  { key: "address", value: "Endereço", checked: false },
  { key: "collect_date", value: "Data da coleta", checked: false },
  { key: "customer", value: "Cliente", checked: false },
  { key: "city_source", value: "Cidade de Origem", checked: false },
  { key: "city_destination", value: "Cidade de Destino", checked: false },
];

const collectsAddressesAndServicesCancelOrderOptions = [
  { key: "protocol", value: "Protocolo", checked: false },
  { key: "collect_date", value: "Data da coleta", checked: false },
];

export function CollectAddressesAndServicesRequestedToCancel() {
  const [protocol, setProtocol] = useState(0)
  const [addressId, setAddressId] = useState('')
  const [serviceId, setServiceId] = useState('')
  const [collectDate, setCollectDate] = useState('')
  const [serviceAddresses, setServiceAddresses] = useState('')
  const [serviceCancelReason, setServiceCancelReason] = useState<ServiceCancelReasons | null>(null)
  const [serviceCancelObservation, setServiceCancelObservation] = useState<string | null>(null)

  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE;
  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions();

  useEffect(() => {
    onLoadSetFilterOptions(collectsAddressesAndServicesCancelfilterOptions);
  }, [onLoadSetFilterOptions]);

  useEffect(() => {
    onLoadSetOrderOptions(collectsAddressesAndServicesCancelOrderOptions);
  }, [onLoadSetOrderOptions]);

  const { userLogged } = useAuth();
  const { push: redirectTo } = useHistory();

  const userHasPermissionToValidateCancelAddressRequest =
    userLogged?.permissions.includes(
      "service-validate-cancel-collect-address-request"
    );
  const userHasPermissionToValidateCancelServiceRequest =
    userLogged?.permissions.includes("service-validate-cancel-request");

  useEffect(() => {
    if (
      !userHasPermissionToValidateCancelAddressRequest &&
      !userHasPermissionToValidateCancelServiceRequest
    ) {
      redirectTo("/");
    }
  }, [
    userHasPermissionToValidateCancelAddressRequest,
    redirectTo,
    userHasPermissionToValidateCancelServiceRequest,
  ]);

  const stepsToRequestServices = [
    "toValidateAddressesRequestedToCancel",
    "toValidateCancelRequest",
    "toCollectService",
    "collectingService",
    "toValidateAdditionaCollectAddresses"
  ];
  const {
    servicesBySteps: { data: services, isLoading: isServicesLoading },
  } = useService(null, true, false, false, stepsToRequestServices);

  const { data: addresses, isLoading: isAddressesLoading } = useAddresses();
  const { register, watch } = useForm<FilterOptionInputs>();

  const {
    isOpen: isValidateAddressToCancelOpen,
    onOpen: onOpenValidateAddressToCancel,
    onClose: onCloseValidateAddressToCancel,
  } = useDisclosure();

  const {
    isOpen: isValidateServiceToCancelOpen,
    onOpen: onOpenValidateServiceToCancel,
    onClose: onCloseValidateServiceToCancel,
  } = useDisclosure();

  const filterValues = watch([
    "protocol",
    "address",
    "collect_date",
    "customer",
    "collector",
  ]);

  const servicesWithCollectAddressesRequestedToCancel = services?.filter(service => {
    return service.serviceIDRequested.addresses_requested_to_cancel !== null &&
      service.serviceIDRequested.addresses_requested_to_cancel.length > 0
  }).reduce((acc, service) => {
    service.serviceIDRequested.addresses_requested_to_cancel.forEach(adressRequestedToCancel => {

      const address = addresses?.find(address => {
        return address.id === adressRequestedToCancel
      })

      const serviceSourceAddresses = service.serviceIDRequested
        .source_address_id;

      const serviceDestinationAddresses = service.serviceIDRequested
        .destination_address_id

      const sourceCities = Array.from(
        new Set(
          addresses
            ?.filter((address) => serviceSourceAddresses.includes(address.id))
            .map((address) => address.cityIDAddress.name)
        )
      )
        .join(", ")
        .toUpperCase();

      const destinationCities = Array.from(
        new Set(
          addresses
            ?.filter((address) =>
              serviceDestinationAddresses.includes(address.id)
            )
            .map((address) => address.cityIDAddress.name)
        )
      )
        .join(", ")
        .toUpperCase();

      acc.push({
        id: service.id,
        protocol: service.protocol,
        step: service.step,
        city_source: sourceCities,
        city_destination: destinationCities,
        address: `${address?.street}, ${address?.number}, ${address?.neighborhood} - ${address?.cep} |  ${address.trading_name} | ${address.branch}`,
        address_id: service.serviceIDRequested.addresses_requested_to_cancel[0],
        collect_date: service.serviceIDRequested.collect_date,
        customer: service.customerIDService.trading_firstname,
      });

    })

    return acc
  }, [])

  const servicesRequestedToCancel = services?.filter(service => {
    return service.step === 'toValidateCancelRequest'
  }).map(service => {

    const address = '-'

    const serviceSourceAddresses = service.serviceIDRequested
      .source_address_id;

    const serviceDestinationAddresses = service.serviceIDRequested
      .destination_address_id

    const sourceCities = Array.from(
      new Set(
        addresses
          ?.filter((address) => serviceSourceAddresses.includes(address.id))
          .map((address) => address.cityIDAddress.name)
      )
    )
      .join(", ")
      .toUpperCase();

    const destinationCities = Array.from(
      new Set(
        addresses
          ?.filter((address) =>
            serviceDestinationAddresses.includes(address.id)
          )
          .map((address) => address.cityIDAddress.name)
      )
    )
      .join(", ")
      .toUpperCase();

    return {
      id: service.id,
      protocol: service.protocol,
      step: service.step,
      city_source: sourceCities,
      city_destination: destinationCities,
      address,
      address_id: service.serviceIDRequested.addresses_requested_to_cancel[0],
      collect_date: service.serviceIDRequested.collect_date,
      customer: service.customerIDService.trading_firstname,
      cancel_observation: service.cancel_observation,
      cancel_reason: service.cancel_reason,
    }
  })

  const servicesAndCollectAddressesRequestToCancelFiltered =
    servicesWithCollectAddressesRequestedToCancel?.concat(servicesRequestedToCancel)
      ?.filter((service) => {
        const [
          protocolFiltered,
          addressFiltered,
          collectDateFiltered,
          customerFiltered,
        ] = filterValues;
        const serviceAddresses = service.addresses as string;

        const protocolFilter = protocolFiltered
          ? searchBoxFilter(String(service.protocol), protocolFiltered)
          : null;
        const addressFilter = addressFiltered
          ? searchBoxFilter(serviceAddresses, addressFiltered)
          : null;
        const collectDateFilter = collectDateFiltered
          ? searchBoxFilter(
            formatDate.handle(service.collect_date, "DateWithoutHourToShow"),
            collectDateFiltered
          )
          : null;
        const customerFilter = customerFiltered
          ? searchBoxFilter(service.customer, customerFiltered)
          : null;

        if (filterValues.some((value) => !!value)) {
          return (
            protocolFilter ||
            collectDateFilter ||
            customerFilter ||
            addressFilter
          );
        }

        return service;
      })
      .sort((a, b) => {
        const isProtocolOrder = orderOptionSelected.includes("protocol")
          ? a.protocol - b.protocol
          : 0;

        const isCollectDateOrder = orderOptionSelected.includes("collect_date")
          ? serviceSortByDate(a.collect_date, b.collect_date)
          : 0;

        return isProtocolOrder || isCollectDateOrder;
      });

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: servicesAndCollectAddressesRequestToCancelFiltered?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    });

  function handleOpenValidateServiceToCancelModal(
    id: string,
    protocol: number,
    collectDate: string,
    cancelObservation: string,
    cancelReason: ServiceCancelReasons
  ) {
    setServiceId(id)
    setProtocol(protocol)
    setCollectDate(collectDate)
    setServiceCancelReason(cancelReason)
    setServiceCancelObservation(cancelObservation)
    onOpenValidateServiceToCancel()
  }

  function handleOpenValidateAddressToCancelModal(
    id: string,
    protocol: number,
    addressId: string,
    addresses: string,
  ) {
    setServiceId(id)
    setProtocol(protocol)
    setAddressId(addressId)
    setServiceAddresses(addresses)
    onOpenValidateAddressToCancel()
  }

  if (isServicesLoading || isAddressesLoading) {
    return <GeneralContentLoading />;
  }

  return (

      <Box bg="white" padding={[2, 4]} borderRadius="8px" flex="1">
        <Flex gap={2} direction="column">
          <Heading
            size="lg"
            fontFamily="poppins"
            textAlign={["center", "center", "justify"]}
          >
            Solicitações de cancelamento (serviço/endereços de coleta)
          </Heading>
          {!servicesAndCollectAddressesRequestToCancelFiltered?.length ? (
            <EmptyContentTableAlert
              title="Oops!"
              description="Não há solicitações de cancelamento pendentes para validação!"
            />
          ) : (
            <>
              <ValidateAddressesRequestedToCancelModal
                onClose={onCloseValidateAddressToCancel}
                isOpen={isValidateAddressToCancelOpen}
                addressId={addressId}
                serviceId={serviceId}
                addressInfo={serviceAddresses}
                protocol={protocol}
              />
              <ValidateServicesRequestedToCancelModal
                onClose={onCloseValidateServiceToCancel}
                isOpen={isValidateServiceToCancelOpen}
                serviceId={serviceId}
                collectDate={collectDate}
                protocol={protocol}
                cancelObservation={serviceCancelObservation}
                cancelReason={serviceCancelReason}
              />

              <Flex
                justify="space-between"
                direction={["column", "column", "row"]}
                w="full"
                gap={2}
              >
                <Flex>
                  <TableFilterButton />
                </Flex>
                <Flex direction="column" gap="4">
                  {filterOptions
                    .filter((option) => option.checked === true)
                    .map((option) => {
                      return (
                        <SearchBox
                          {...register(option.key as keyof FilterOptionInputs)}
                          key={option.key}
                          size="sm"
                          placeholder={"Buscar " + option.value}
                          handleSearch={() => { }}
                        />
                      );
                    })}
                </Flex>
              </Flex>
              <TableContainer w="full">
                <Table colorScheme="gray" variant="striped" size="sm">
                  <Thead>
                    <Tr>
                      <Td></Td>
                      {collectsAddressesAndServicesCancelfilterOptions.map(
                        (option) => {
                          return (
                            <Th key={option.key}>
                              {option.value.toUpperCase()}
                            </Th>
                          );
                        }
                      )}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {servicesAndCollectAddressesRequestToCancelFiltered
                      ?.slice(offset, offset + Number(itemLimit))
                      ?.map((service) => {
                        return (
                          <Tr key={service.id}>
                            <Td>
                              {service.step === "toValidateCancelRequest" &&
                                userHasPermissionToValidateCancelServiceRequest && (
                                  <Icon
                                    onClick={() => handleOpenValidateServiceToCancelModal(
                                      service.id,
                                      service.protocol,
                                      service.collect_date,
                                      service.cancel_observation,
                                      service.cancel_reason
                                    )}
                                    cursor="pointer"
                                    as={FiCheckCircle}
                                    fontSize="20"
                                    mr="2"
                                    mt={["2", "2", "0"]}
                                  />
                                )}
                              {service.address !== "-" &&
                                userHasPermissionToValidateCancelAddressRequest && (
                                  <Icon
                                    onClick={() => handleOpenValidateAddressToCancelModal(
                                      service.id,
                                      service.protocol,
                                      service.address_id,
                                      service.address
                                    )}
                                    cursor="pointer"
                                    as={FiCheckCircle}
                                    fontSize="20"
                                    mr="2"
                                    mt={["2", "2", "0"]}
                                  />
                                )}
                            </Td>
                            <Td>{service.protocol} </Td>
                            <Td>
                              {service.step === "toValidateCancelRequest"
                                ? "SERVIÇO"
                                : "ENDEREÇO DE COLETA"}{" "}
                            </Td>
                            <Td whiteSpace="normal">
                              {service.address === ""
                                ? "-"
                                : service.address}
                            </Td>

                            <Td>
                              {formatDate.handle(
                                service.collect_date,
                                "DateWithoutHourToShow"
                              )}
                            </Td>
                            <Td>{service.customer}</Td>
                            <Td>{service.city_source}</Td>
                            <Td>{service.city_destination}</Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </TableContainer>
            </>
          )}
          <Pagination
            currentPage={currentPage}
            pagesQuantity={pagesCount}
            pages={pages}
            handlePageChange={setCurrentPage}
          />
        </Flex>
      </Box>

  );
}
