import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface SendCltDriverDismissDocumentationBody {
  doesReceivedDismissionDocumentsFromAccounting: boolean
  hasCheckedPeriodFuelAndRentalBalance: boolean
  hasCheckedGrrf: boolean
  doesOperationLeaderKnownAboutDismiss: boolean
  terminationDocumentsAttachmentsIds: string[]
  paymentDate: Date
  shouldAdvanceStatus: boolean
}

interface SendCltDriverDismissDocumentationProps {
  body: SendCltDriverDismissDocumentationBody
  cltDriverId: string
}

export async function sendCltDriverDismissDocumentation({
  body,
  cltDriverId
}: SendCltDriverDismissDocumentationProps) {
  try {
    const response = await api.put(`/clt-drivers/${cltDriverId}/dismiss/documentation`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
