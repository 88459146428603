import axios from "axios"
import { useAuth } from "hooks/auth/useAuth"
import { useMutation, useQueryClient } from "react-query"
import { api } from "services/api"
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum"
import { RequestError } from "utils/errors/RequestErrors"

interface NotificationTypeInput {
  name: string
  profiles: string[]
  notification_type_id?: string
}

async function createNotificationTypeReqFunction({
  name,
  profiles
}: NotificationTypeInput) {
  try {
    const { data } = await api.post('/notification-type', {
      name,
      profiles
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

async function updateNotificationTypeReqFunction({
  name,
  profiles,
  notification_type_id
}: NotificationTypeInput) {
  try {
    const { data } = await api.put(`/notification-type/${notification_type_id}`, {
      name,
      profiles
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

async function deleteNotificationTypeReqFunction(id: string) {
  try {
    await api.delete(`/notification-type/${id}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

export function useNotificationTypeFunctions() {

  const { handleLogout } = useAuth()

  const queryClient = useQueryClient()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  const onRequestSuccess = async () => {
    await queryClient.invalidateQueries(['notificationTypes'])
  }

  const createNotificationType = useMutation(
    'createNotificationTypeReqFunction', createNotificationTypeReqFunction, {
    onError: onRequestError,
    onSuccess: onRequestSuccess
  })

  const updateNotificationType = useMutation(
    'updateNotificationTypeReqFunction', updateNotificationTypeReqFunction, {
    onError: onRequestError,
    onSuccess: onRequestSuccess
  })

  const deleteNotificationType = useMutation(
    'deleteNotificationTypeReqFunction', deleteNotificationTypeReqFunction, {
    onError: onRequestError,
    onSuccess: onRequestSuccess
  })

  return { createNotificationType, updateNotificationType, deleteNotificationType }
}
