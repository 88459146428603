import { Regional } from "api/regionals/_types/Regional";
import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Vaccine, VaccineStatus } from "./vaccine";

interface GetVaccinesReportParams {
  status?: VaccineStatus | 'all' | null
  collaborator: string | null
}

export interface GetVaccinesResponse {
  vaccines: Array<Vaccine & {
    regional: Regional
    cardFrontPhotoAttachment: Attachment
    cardBackPhotoAttachment: Attachment
  }>
}

export async function getVaccinesReport({
  status,
  collaborator,
}: GetVaccinesReportParams) {
  try {
    const response = await api.get<GetVaccinesResponse>('/vaccines/report', {
      params: {
        status,
        collaborator,
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
