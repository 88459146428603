import { Flex, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { Aso } from "api/asos/aso";
import { Regional } from "api/regionals/_types/Regional";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { FaPen, FaSearch } from "react-icons/fa";
import { asoTypeMap } from "utils/asoMappers";
import { AsoDetail } from "./AsoDetail";
import { AsoStatus } from "./AsoStatus";
import { EditAso } from "./EditAso";

interface AsoTableRowProps {
  aso: Aso & { regional: Regional }
}

export function AsosTableRow({ aso }: AsoTableRowProps) {
  const { userLogged } = useAuth()
  const userCanEditAso = userLogged?.permissions.includes('edit-aso')

  const {
    isOpen: isDetailModalOpen,
    onOpen: onOpenDetailModal,
    onClose: onCloseDetailModal,
  } = useDisclosure()

  const {
    isOpen: isEditAsoOpen,
    onToggle: onToggleEditAso,
  } = useDisclosure()

  return (
    <Tr>
      <Td>
        <Flex align="center" gap={3}>
          <IconButton
            aria-label="Visualizar aso"
            icon={<FaSearch />}
            size="sm"
            variant="ghost"
            onClick={onOpenDetailModal}
          />
          {userCanEditAso && (
            <IconButton
              aria-label="Editar preço"
              icon={<FaPen />}
              variant="ghost"
              size="sm"
              onClick={onToggleEditAso}
            />
          )}
        </Flex>

        <Modal
          isOpen={isDetailModalOpen}
          onClose={onCloseDetailModal}
          isCentered
          size='6xl'
        >
          <ModalOverlay />

          <AsoDetail
            asoId={aso.id}
            onClose={onCloseDetailModal}
          />

        </Modal>
        <Modal
          isOpen={isEditAsoOpen}
          onClose={onToggleEditAso}
          size="4xl"
        >
          <ModalOverlay />

          <EditAso
            asoId={aso.id}
            onCloseModal={onToggleEditAso} />

        </Modal>
      </Td>
      <Td>{aso?.regional ? aso?.regional?.code?.toUpperCase() : '-'}</Td>
      <Td>
        <AsoStatus status={aso.status} />
      </Td>
      <Td>{format(new Date(aso.created_at), 'dd/MM/yyyy')}</Td>
      <Td>{asoTypeMap[aso.type]}</Td>
      <Td>{aso.collaborator_name}</Td>
      <Td>{aso.status === 'active' ? (
        format(new Date(aso.realized_at), 'dd/MM/yyyy')
      ) : (
        '-'
      )}</Td>
      <Td>{aso.status === 'active' ? (
        format(new Date(aso.due_date), 'dd/MM/yyyy')
      ) : (
        '-'
      )}</Td>

    </Tr>
  )
}
