import { Box, Button, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, Heading, Input, Radio, RadioGroup, Select, Stack, Text, VStack } from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { sendCltDriverDismissLegalPreventionDetails } from "api/cltDrivers/sendCltDriverDismissLegalPreventionDetails";
import { format, parseISO, set } from "date-fns";
import { CltDriver } from "api/cltDrivers/_types/CltDriver";
import { useEffect } from "react";

interface SendCltDriverDismissLegalPreventionDetailsProps {
  cltDriver: CltDriver
}

export interface SendCltDriverDismissLegalPreventionDetailsSchema {
  dismissDate: string
  doesEmployeeHaveNoStabilityOfJob: string
  doesOperationLeaderKnownAboutDismiss: string
  hasConferredEmployeeHasPendingDocumentsWithCompany: string
  hasConferredPointRegisters: string
  legalPreventionDocumentsChecklist: string[]
  shouldAdvanceStatus: string
}

const sendCltDriverDismissLegalPreventionDetailsSchema = yup.object({
  doesEmployeeHaveNoStabilityOfJob: yup.string(),
  doesOperationLeaderKnownAboutDismiss: yup.string(),
  hasConferredEmployeeHasPendingDocumentsWithCompany: yup.string(),
  hasConferredPointRegisters: yup.string(),
  legalPreventionDocumentsChecklist: yup.array().of(yup.string()).nullable(),
})

const legalPreventionDocumentsChecklist = [
  { name: 'Regulamento interno' },
  { name: 'Contrato de experiência' },
  { name: 'Acordo de compensação de horas' },
  { name: 'Registro de treinamentos obrigatórios' },
  { name: 'Contrato aluguel de moto' },
  { name: 'Ficha de empregado' },
  { name: 'Sem advertência pendente de assinatura' },
]



export function SendCltDriverDismissLegalPreventionDetails({ cltDriver }: SendCltDriverDismissLegalPreventionDetailsProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<SendCltDriverDismissLegalPreventionDetailsSchema>({
    resolver: yupResolver(sendCltDriverDismissLegalPreventionDetailsSchema)
  })

  useEffect(() => {
    if (cltDriver) {
      setValue("dismissDate", cltDriver?.dismissDate ? format(parseISO(cltDriver?.dismissDate), 'yyyy-MM-dd') : null)
      setValue("doesEmployeeHaveNoStabilityOfJob", cltDriver.doesEmployeeHaveNoStabilityOfJob)
      setValue("doesOperationLeaderKnownAboutDismiss", cltDriver.doesOperationLeaderKnownAboutDismiss ? 'yes' : 'no')
      setValue("hasConferredEmployeeHasPendingDocumentsWithCompany", cltDriver.hasConferredEmployeeHasPendingDocumentsWithCompany ? 'yes' : 'no')
      setValue("hasConferredPointRegisters", cltDriver.hasConferredPointRegisters ? 'yes' : 'no')
      setValue("legalPreventionDocumentsChecklist", Boolean(cltDriver.legalPreventionDocumentsChecklist) ? cltDriver.legalPreventionDocumentsChecklist : [])
    }
  }, [cltDriver, setValue])

  const { mutateAsync: sendCltDriverDismissLegalPreventionDetailsFn } = useMutation({
    mutationFn: sendCltDriverDismissLegalPreventionDetails,
    onSuccess(_data, { body, cltDriverId }) {
      queryClient.invalidateQueries({ queryKey: 'clts-drivers' })
      queryClient.invalidateQueries({ queryKey: 'dismissing-legal-prevention' })
      queryClient.invalidateQueries({ queryKey: 'dismissing-approvation' })
      queryClient.invalidateQueries({ queryKey: ['clt-driver', cltDriverId] })
    }
  })


  async function handleSendCltDriverDismissLegalPreventionDetails(values: SendCltDriverDismissLegalPreventionDetailsSchema) {
    let dismissDate = null
    if (Boolean(values?.dismissDate)) {
      const [year, month, day] = values?.dismissDate?.split('-').map(Number)
      dismissDate = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()
    }
    await promiseMessage(sendCltDriverDismissLegalPreventionDetailsFn({
      body: {
        ...values,
        hasConferredEmployeeHasPendingDocumentsWithCompany: values.hasConferredEmployeeHasPendingDocumentsWithCompany === 'yes',
        hasConferredPointRegisters: values.hasConferredPointRegisters === 'yes',
        doesOperationLeaderKnownAboutDismiss: values.doesOperationLeaderKnownAboutDismiss === 'yes',
        shouldAdvanceStatus: values.shouldAdvanceStatus ? values.shouldAdvanceStatus === 'yes' : false,
        dismissDate: dismissDate
      }, cltDriverId: cltDriver?.id
    }), 'Prevenção jurídica realizada')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleSendCltDriverDismissLegalPreventionDetails)}
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'>Demissão - Prevenção Jurídica</Heading>
      <FormControl mt={3} isInvalid={!!errors.legalPreventionDocumentsChecklist}>
        <FormLabel fontSize='sm'>
          Documentação assinada
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          control={control}
          name="legalPreventionDocumentsChecklist"
          render={({ field }) => {
            return (
              <CheckboxGroup
                value={field.value}
                onChange={field.onChange}
              >
                <VStack
                  spacing={3}
                  p={2}
                  border="1px solid"
                  borderColor="gray.200"
                  rounded="md"
                  align="left"
                >
                  {legalPreventionDocumentsChecklist.map(check => {
                    return (
                      <Checkbox key={check.name} value={check.name}>
                        <Text fontSize="sm">
                          {check.name}
                        </Text>
                      </Checkbox>
                    )
                  })}
                </VStack>

              </CheckboxGroup>
            )
          }}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.hasConferredPointRegisters} mt={3}>
        <FormLabel fontSize='sm'>
          Realizou conferência de registros de ponto ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasConferredPointRegisters"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.hasConferredEmployeeHasPendingDocumentsWithCompany} mt={3}>
        <FormLabel fontSize='sm'>
          Realizou conferência se funcionário tem alguma pendência de documentação com a empresa ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasConferredEmployeeHasPendingDocumentsWithCompany"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.doesOperationLeaderKnownAboutDismiss} mt={3}>
        <FormLabel fontSize='sm'>
          Líder da operação ciente para realizar demissão ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="doesOperationLeaderKnownAboutDismiss"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors?.dismissDate} mt={3}>
        <FormLabel fontSize="sm">
          Data da demissão:
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('dismissDate')}
          name="dismissDate"
          type="date"
          rounded="md"
          size='sm'
        />
      </FormControl>
      <FormControl isInvalid={!!errors.doesEmployeeHaveNoStabilityOfJob} mt={3}>
        <FormLabel fontSize="sm">
          Colaborador não está com estabilidade de emprego ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register('doesEmployeeHaveNoStabilityOfJob')}
          name="doesEmployeeHaveNoStabilityOfJob"
          placeholder="Selecione..."
          rounded="md"
          size='sm'
        >
          <option value='Não está'>Não está</option>
          <option value='Está com estabilidade de emprego'>Está com estabilidade de emprego</option>
        </Select>

      </FormControl>
      <FormControl isInvalid={!!errors.shouldAdvanceStatus} mt={3}>
        <FormLabel fontSize="sm">
          Passar para o próximo status?
        </FormLabel>
        <Select
          {...register('shouldAdvanceStatus')}
          name="shouldAdvanceStatus"
          placeholder="Selecione..."
          size="sm"
          rounded="md"
        >
          <option value='yes'>Sim</option>
          <option value='no'>Não</option>
        </Select>
      </FormControl>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
