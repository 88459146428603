import { Box, Button, Flex, FormControl, FormLabel, Heading, IconButton, Input, Radio, RadioGroup, Stack, Text, Textarea } from "@chakra-ui/react"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { set } from "date-fns";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { FaPlus, FaTrash } from "react-icons/fa";
import { completeOperationalAudit } from "api/audits/completeOperationalAudit";

interface AuditOperationalProps {
  auditId: string
}

interface AuditOperationalSchema {
  realizedDate: string
  services: {
    serviceProtocol: string
    isWithNonDivergentInputs: string
    collectHourInsideHourBlock: string
    hasBoxPhotoInCollect: string
    hasContentDeclarationInCollect: string
    isContentDeclarationSignedInCollect: string
    isContentDeclarationFilledInCollect: string
    isContentDeclarationStampedInCollect: string
    isReleaseMadeBySourceCollector: string
    deliveryHourInsideHourBlock: string
    hasBoxPhotoInDelivery: string
    hasContentDeclarationInDelivery: string
    isContentDeclarationSignedInDelivery: string
    isReleaseMadeByDestinationCollector: string
  }[]
  nonCompliance: string
}

const auditOperationalSchema = yup.object({
  realizedDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),

  nonCompliance: yup.string()
})
export function AuditOperational({
  auditId
}: AuditOperationalProps) {

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<AuditOperationalSchema>({
    resolver: yupResolver(auditOperationalSchema),
    defaultValues: {
      services: [{
        serviceProtocol: '',
        isWithNonDivergentInputs: '',
        collectHourInsideHourBlock: '',
        hasBoxPhotoInCollect: '',
        hasContentDeclarationInCollect: '',
        isContentDeclarationSignedInCollect: '',
        isContentDeclarationFilledInCollect: '',
        isContentDeclarationStampedInCollect: '',
        isReleaseMadeBySourceCollector: '',
        deliveryHourInsideHourBlock: '',
        hasBoxPhotoInDelivery: '',
        hasContentDeclarationInDelivery: '',
        isContentDeclarationSignedInDelivery: '',
        isReleaseMadeByDestinationCollector: '',
      }]
    }
  })
  const {
    fields: serviceFields,
    append: appendServiceField,
    remove: removeServiceField } = useFieldArray({
      control,
      name: 'services'
    })

  const { mutateAsync: completeOperationalAuditFn } = useMutation({
    mutationFn: completeOperationalAudit,
    onSuccess() {
      queryClient.invalidateQueries(['audits'])
      queryClient.invalidateQueries({ queryKey: 'pending-audits' })
      queryClient.invalidateQueries({ queryKey: 'approved-audits' })
      queryClient.invalidateQueries({ queryKey: 'reproved-audits' })
      queryClient.invalidateQueries({ queryKey: ['audit', auditId] })
    }
  })
  const handleAppendServiceField = () => {
    appendServiceField({
      serviceProtocol: '',
      isWithNonDivergentInputs: '',
      collectHourInsideHourBlock: '',
      hasBoxPhotoInCollect: '',
      hasContentDeclarationInCollect: '',
      isContentDeclarationSignedInCollect: '',
      isContentDeclarationFilledInCollect: '',
      isContentDeclarationStampedInCollect: '',
      isReleaseMadeBySourceCollector: '',
      deliveryHourInsideHourBlock: '',
      hasBoxPhotoInDelivery: '',
      hasContentDeclarationInDelivery: '',
      isContentDeclarationSignedInDelivery: '',
      isReleaseMadeByDestinationCollector: '',
    })
  }

  async function handleAuditOperational(values: AuditOperationalSchema) {
    const updatedServices = values.services.map(service => {
      const updatedService = {
        ...service,
        serviceProtocol: service.serviceProtocol,
        isWithNonDivergentInputs: service.isWithNonDivergentInputs === 'yes',
        collectHourInsideHourBlock: service.collectHourInsideHourBlock === 'yes',
        hasBoxPhotoInCollect: service.hasBoxPhotoInCollect === 'yes',
        hasContentDeclarationInCollect: service.hasContentDeclarationInCollect === 'yes',
        isContentDeclarationSignedInCollect: service.isContentDeclarationSignedInCollect === 'yes',
        isContentDeclarationFilledInCollect: service.isContentDeclarationFilledInCollect === 'yes',
        isContentDeclarationStampedInCollect: service.isContentDeclarationStampedInCollect === 'yes',
        isReleaseMadeBySourceCollector: service.isReleaseMadeBySourceCollector === 'yes',
        deliveryHourInsideHourBlock: service.deliveryHourInsideHourBlock === 'yes',
        hasBoxPhotoInDelivery: service.hasBoxPhotoInDelivery === 'yes',
        hasContentDeclarationInDelivery: service.hasContentDeclarationInDelivery === 'yes',
        isContentDeclarationSignedInDelivery: service.isContentDeclarationSignedInDelivery === 'yes',
        isReleaseMadeByDestinationCollector: service.isReleaseMadeByDestinationCollector === 'yes'
      };
      return updatedService
    });

    await promiseMessage(completeOperationalAuditFn({
      body: {
        realizedDate: values.realizedDate,
        nonCompliance: values.nonCompliance,
        services: updatedServices
      },
      auditId
    }), 'Auditoria realizada!')
  }

  return (
    <Box
      w="full"
      as="form"
      maxH='500px'
      overflowY='scroll'
      onSubmit={handleSubmit(handleAuditOperational)}
    >
      <Heading letterSpacing="tight" size='sm'> Auditoria Operacional</Heading>

      <FormControl isInvalid={!!errors?.realizedDate} mt={3}>
        <FormLabel fontSize="sm">
          Data realizada
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('realizedDate')}
          name="realizedDate"
          type="date"
          rounded="md"
          w={["full", "full", "sm"]}
        />
      </FormControl>
      <Flex
        borderColor="gray.200"
        rounded="lg"
        direction="column"
        mt={3}
        gap={3}
      >
        {serviceFields?.map((field, index) => {
          return (
            <Flex
              key={field.id}
              border="1px solid"
              borderColor="gray.100"
              borderRadius="base"
              p="4"
              direction="column"
              gap="6"
            >
              <FormControl
                isInvalid={!!errors?.services?.[index]?.serviceProtocol}
                mt={3}>
                <FormLabel fontSize="sm">
                  Protocolo do Serviço
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register(`services.${index}.serviceProtocol`)}
                  name={`services.${index}.serviceProtocol`}
                  rounded="md"
                  type="number"
                />
              </FormControl>
              <Stack
                spacing="6"
                direction={["column", "column", "row"]}
                mt="3"
              >
                <Stack
                  direction="column"
                  w="full"
                  spacing="0.25"
                >
                  <FormControl
                    isInvalid={!!errors?.services?.[index]?.isWithNonDivergentInputs}
                  >
                    <FormLabel fontSize="sm">
                      Insumos sem divergência?
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>
                    <Controller
                      name={`services.${index}.isWithNonDivergentInputs`}
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio value="yes">
                              <Text fontSize="sm">Sim</Text>
                            </Radio>
                            <Radio value="no">
                              <Text fontSize="sm">Não</Text>
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Stack>
                <Stack
                  direction="column"
                  w="full"
                  spacing="0.25"
                >
                  <FormControl
                    isInvalid={!!errors?.services?.[index]?.collectHourInsideHourBlock}
                  >
                    <FormLabel fontSize="sm">
                      Horário de coleta dentro do bloco de horário?
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>
                    <Controller
                      name={`services.${index}.collectHourInsideHourBlock`}
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio value="yes">
                              <Text fontSize="sm">Sim</Text>
                            </Radio>
                            <Radio value="no">
                              <Text fontSize="sm">Não</Text>
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Stack>
              </Stack>

              <Stack
                spacing="6"
                direction={["column", "column", "row"]}
                mt="3"
              >
                <Stack
                  direction="column"
                  w="full"
                  spacing="0.25"
                >
                  <FormControl
                    isInvalid={!!errors?.services?.[index]?.hasBoxPhotoInCollect}
                  >
                    <FormLabel fontSize="sm">
                      Foto da caixa na coleta?
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>
                    <Controller
                      name={`services.${index}.hasBoxPhotoInCollect`}
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio value="yes">
                              <Text fontSize="sm">Sim</Text>
                            </Radio>
                            <Radio value="no">
                              <Text fontSize="sm">Não</Text>
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Stack>
                <Stack
                  direction="column"
                  w="full"
                  spacing="0.25"
                >
                  <FormControl
                    isInvalid={!!errors?.services?.[index]?.hasContentDeclarationInCollect}
                  >
                    <FormLabel fontSize="sm">
                      Foto da declaração de conteúdo na coleta?
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>
                    <Controller
                      name={`services.${index}.hasContentDeclarationInCollect`}
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio value="yes">
                              <Text fontSize="sm">Sim</Text>
                            </Radio>
                            <Radio value="no">
                              <Text fontSize="sm">Não</Text>
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Stack>
              </Stack>

              <Stack
                spacing="6"
                direction={["column", "column", "row"]}
                mt="3"
              >
                <Stack
                  direction="column"
                  w="full"
                  spacing="0.25"
                >
                  <FormControl
                    isInvalid={!!errors?.services?.[index]?.isContentDeclarationSignedInCollect}
                  >
                    <FormLabel fontSize="sm">
                      Declaração de conteúdo assinada na coleta?
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>
                    <Controller
                      name={`services.${index}.isContentDeclarationSignedInCollect`}
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio value="yes">
                              <Text fontSize="sm">Sim</Text>
                            </Radio>
                            <Radio value="no">
                              <Text fontSize="sm">Não</Text>
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Stack>
                <Stack
                  direction="column"
                  w="full"
                  spacing="0.25"
                >
                  <FormControl
                    isInvalid={!!errors?.services?.[index]?.isContentDeclarationFilledInCollect}
                  >
                    <FormLabel fontSize="sm">
                      Declaração de conteúdo preenchida na coleta?
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>
                    <Controller
                      name={`services.${index}.isContentDeclarationFilledInCollect`}
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio value="yes">
                              <Text fontSize="sm">Sim</Text>
                            </Radio>
                            <Radio value="no">
                              <Text fontSize="sm">Não</Text>
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Stack>
              </Stack>

              <Stack
                spacing="6"
                direction={["column", "column", "row"]}
                mt="3"
              >
                <Stack
                  direction="column"
                  w="full"
                  spacing="0.25"
                >
                  <FormControl
                    isInvalid={!!errors?.services?.[index]?.isContentDeclarationStampedInCollect}
                  >
                    <FormLabel fontSize="sm">
                      Declaração de conteúdo com carimbo na coleta?
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>
                    <Controller
                      name={`services.${index}.isContentDeclarationStampedInCollect`}
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio value="yes">
                              <Text fontSize="sm">Sim</Text>
                            </Radio>
                            <Radio value="no">
                              <Text fontSize="sm">Não</Text>
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Stack>
                <Stack
                  direction="column"
                  w="full"
                  spacing="0.25"
                >
                  <FormControl
                    isInvalid={!!errors?.services?.[index]?.isReleaseMadeBySourceCollector}
                  >
                    <FormLabel fontSize="sm">
                      Lançamento feito pelo coletador de origem?
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>
                    <Controller
                      name={`services.${index}.isReleaseMadeBySourceCollector`}
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio value="yes">
                              <Text fontSize="sm">Sim</Text>
                            </Radio>
                            <Radio value="no">
                              <Text fontSize="sm">Não</Text>
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Stack>
              </Stack>

              <Stack
                spacing="6"
                direction={["column", "column", "row"]}
                mt="3"
              >
                <Stack
                  direction="column"
                  w="full"
                  spacing="0.25"
                >
                  <FormControl
                    isInvalid={!!errors?.services?.[index]?.deliveryHourInsideHourBlock}
                  >
                    <FormLabel fontSize="sm">
                      Horário de entrega dentro do bloco de horário?
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>
                    <Controller
                      name={`services.${index}.deliveryHourInsideHourBlock`}
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio value="yes">
                              <Text fontSize="sm">Sim</Text>
                            </Radio>
                            <Radio value="no">
                              <Text fontSize="sm">Não</Text>
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Stack>
                <Stack
                  direction="column"
                  w="full"
                  spacing="0.25"
                >
                  <FormControl
                    isInvalid={!!errors?.services?.[index]?.hasBoxPhotoInDelivery}
                  >
                    <FormLabel fontSize="sm">
                      Foto da caixa na entrega?
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>
                    <Controller
                      name={`services.${index}.hasBoxPhotoInDelivery`}
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio value="yes">
                              <Text fontSize="sm">Sim</Text>
                            </Radio>
                            <Radio value="no">
                              <Text fontSize="sm">Não</Text>
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Stack>
              </Stack>

              <Stack
                spacing="6"
                direction={["column", "column", "row"]}
                mt="3"
              >
                <Stack
                  direction="column"
                  w="full"
                  spacing="0.25"
                >
                  <FormControl
                    isInvalid={!!errors?.services?.[index]?.hasContentDeclarationInDelivery}
                  >
                    <FormLabel fontSize="sm">
                      Foto da declaração de conteúdo na entrega?
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>
                    <Controller
                      name={`services.${index}.hasContentDeclarationInDelivery`}
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio value="yes">
                              <Text fontSize="sm">Sim</Text>
                            </Radio>
                            <Radio value="no">
                              <Text fontSize="sm">Não</Text>
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Stack>
                <Stack
                  direction="column"
                  w="full"
                  spacing="0.25"
                >
                  <FormControl
                    isInvalid={!!errors?.services?.[index]?.isContentDeclarationSignedInDelivery}
                  >
                    <FormLabel fontSize="sm">
                      Declaração de conteúdo assinada na entrega?
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>
                    <Controller
                      name={`services.${index}.isContentDeclarationSignedInDelivery`}
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio value="yes">
                              <Text fontSize="sm">Sim</Text>
                            </Radio>
                            <Radio value="no">
                              <Text fontSize="sm">Não</Text>
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Stack>
              </Stack>
              <Stack
                spacing="6"
                direction={["column", "column", "row"]}
                mt="3"
              >
                <Stack
                  direction="column"
                  w="full"
                  spacing="0.25"
                >
                  <FormControl
                    isInvalid={!!errors?.services?.[index]?.isReleaseMadeByDestinationCollector}
                  >
                    <FormLabel fontSize="sm">
                      Lançamento feito pelo coletador de destino?
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>
                    <Controller
                      name={`services.${index}.isReleaseMadeByDestinationCollector`}
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio value="yes">
                              <Text fontSize="sm">Sim</Text>
                            </Radio>
                            <Radio value="no">
                              <Text fontSize="sm">Não</Text>
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Stack>

              </Stack>

              {serviceFields.length > 1 && (
                <Flex
                  w="full"
                  justify="flex-end"
                >
                  <IconButton
                    aria-label="Remover serviço"
                    icon={<FaTrash />}
                    variant="ghost"
                    onClick={() => removeServiceField(index)}
                    size='lg'
                  />
                </Flex>
              )}
            </Flex>
          )

        })}
        {serviceFields.length >= 1 && serviceFields.length <= 2 && (
          <Button
            size="sm"
            leftIcon={<FaPlus />}
            colorScheme="blue"
            onClick={handleAppendServiceField}
            mt="3"
            alignSelf="end"
          >
            Adicionar
          </Button>
        )}
      </Flex>
      <FormControl isInvalid={!!errors.nonCompliance} mt={3}>
        <FormLabel fontSize="sm">
          Não conformidade
        </FormLabel>
        <Textarea
          {...register('nonCompliance')}
          placeholder="Descreva a não conformidade..."
          size='sm'
          rounded='md'
        />
      </FormControl>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Auditar
        </Button>
      </Flex>
    </Box>
  )
}
