import { Button, Flex, Icon, Select } from "@chakra-ui/react";
import { DocumentStatus } from "api/documents/types/Document";
import { useSearchParams } from "hooks/useSearchParams";
import { CSVLink } from "react-csv";
import { useForm } from "react-hook-form";
import { FaFileExport, FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface DocumentsReportFiltersSchema {
  status: DocumentStatus | 'all'
}

interface DocumentsReportFiltersProps {
  csvProps: {
    headers: {
      label: string
      key: string
    }[]
    data: {
      name: string
      document_type: string
      classification: string
      version: string
      status: string
      document_id: string
    }[]
    filename: string
  }
}

export function DocumentsReportFilters({
  csvProps
}: DocumentsReportFiltersProps) {
  const searchParams = useSearchParams()

  const { replace } = useHistory()

  const status = searchParams.get('status')


  const {
    register,
    handleSubmit,
    reset,
  } = useForm<DocumentsReportFiltersSchema>({
    defaultValues: {
      status: status as DocumentStatus | 'all' ?? 'all'
    }
  })

  function handleSearchAdvertencesReport(values: DocumentsReportFiltersSchema) {
    if (values.status) {
      searchParams.set('status', values.status)
    } else {
      searchParams.delete('status')
    }

    replace({ search: searchParams.toString() })

  }

  function handleRemoveFilters() {
    searchParams.delete('status')
    reset({ status: 'all' })

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }


  return (
    <Flex
      justify={{ base: 'center', md: 'space-between' }}
      direction={{ base: 'column', md: 'row' }}
      align="center"
      mt="6"
      as="form"
      gap="2"
      w='full'
      onSubmit={handleSubmit(handleSearchAdvertencesReport)}
    >
      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Select
          {...register("status")}
          defaultValue="all"
          size="sm"
          rounded="md"
          placeholder="Selecione uma opção..."
          w={{ base: "full", md: '250px' }}
        >
          <option value="all">Todos status</option>
          <option value="non-valudated">Não avaliado</option>
          <option value="active">Ativo</option>
          <option value="expired">Vencido</option>
          <option value="inactive">Inativo</option>
        </Select>
      </Flex>

      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Button
          leftIcon={<FaFileExport />}
          size="sm"
          type="button"
          colorScheme="green"
          as={CSVLink}
          separator={';'}
          {...csvProps}
          w={{ base: 'full', md: 'min' }}
        >
          Exportar
        </Button>
        <Button
          leftIcon={<FaSearch />}
          size="sm"
          type="submit"
          w={{ base: 'full', md: 'min' }}
        >
          Gerar relatório
        </Button>
        <Button
          type="submit"
          size="sm"
          variant="outline"
          leftIcon={<Icon as={FaTimes} />}
          w={['full', 'full', 'min']}
          onClick={handleRemoveFilters}
        >
          Remover filtros
        </Button>
      </Flex>
    </Flex>
  )
}
