import { FormEvent } from "react"
import {  useFormContext } from "react-hook-form"

import { FormDataItem } from "api/supports/createSupport"
import { Box, FormControl, FormLabel, Input, Select, Stack, Text } from "@chakra-ui/react"
import { AnalyzeSupportSchema } from "./AnalyzeSupport"
import { AnalysisFormDataItem } from "api/supports/analyzeSupport"


export function AnalyzeRpaRequestForm() {
  const { register, setValue, formState: { errors } } = useFormContext<AnalyzeSupportSchema>()

  const handleInputChange = (field: keyof AnalysisFormDataItem) => (event: FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      controlCard: 'Card de Controle',
      rpaReceived: 'RPA Recebido',
      rpaNumber: 'Número do RPA',
      paymentDate: 'Data de Pagamento',
    };


    setValue(`analysisFormData.${field}.label`, labelMap[field]);
    setValue(`analysisFormData.${field}.value`, value);
    setValue(`analysisFormData.${field}.type`, target.type);
  };

  return (
    <Box w='full'>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.analysisFormData?.controlCard}>
            <FormLabel fontSize="sm">
              Card de Controle
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register("analysisFormData.controlCard.value")}
              name="analysisFormData.controlCard.value"
              placeholder="Selecione uma opção..."
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('controlCard')}
            >
              <option value='Sim'>Sim</option>
              <option value='Não'>Não</option>

            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.analysisFormData?.rpaReceived}>
            <FormLabel fontSize="sm">
              RPA Recebido
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register("analysisFormData.rpaReceived.value")}
              name="analysisFormData.rpaReceived.value"
              placeholder="Selecione uma opção..."
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('rpaReceived')}
            >
              <option value='Sim'>Sim</option>
              <option value='Não'>Não</option>

            </Select>
          </FormControl>
        </Stack>

      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors?.analysisFormData?.rpaNumber}>
            <FormLabel fontSize="sm">
              Número do RPA
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('analysisFormData.rpaNumber.value')}
              name="analysisFormData.rpaNumber.value"
              size="sm"
              rounded="md"
              onChangeCapture={handleInputChange('rpaNumber')}
            />
          </FormControl>

        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.analysisFormData?.paymentDate} >
            <FormLabel fontSize='sm'>
            Data de Pagamento
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('analysisFormData.paymentDate.value')}
              name='analysisFormData.paymentDate.value'
              type="date"
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('paymentDate')}
            />
          </FormControl>
        </Stack>
      </Stack>
    </Box>
  )
}
