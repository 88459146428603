import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { getAudits } from "api/audits/getAudits"
import { useAuth } from "hooks/auth/useAuth"
import { useEffect } from "react"
import { FaExchangeAlt, FaPlus } from "react-icons/fa"
import { useInfiniteQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { AuditsKanbanColumn } from "./components/AuditsKanbanColumn"
import { CreateAudit } from "./components/CreateAudit"

export function AuditsKanban() {

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanViewAudits = userLogged?.permissions?.includes(
    'view-audits'
  )

  const userCanCreateAudit = userLogged?.permissions?.includes(
    'create-audit'
  )

  useEffect(() => {
    if (!userCanViewAudits) history.push('/')
  }, [history, userCanViewAudits])

  const {
    isOpen: isCreateAuditOpen,
    onOpen: onOpenCreateAudit,
    onClose: onCloseCreateAudit
  } = useDisclosure()


  const {
    data: pendingAuditsResult,
    isLoading: isPendingAuditsResultLoading,
    hasNextPage: hasNextPagePendingAudits,
    fetchNextPage: fetchNextPagePendingAudits,
    isFetchingNextPage: isFetchingNextPagePendingAudits,
  } = useInfiniteQuery({
    queryKey: ['pending-audits'],
    queryFn: ({ pageParam = 1 }) => getAudits({
      currentPage: pageParam,
      pageSize: 10,
      status: 'pending',
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: approvedAuditsResult,
    isLoading: isApprovedAuditsResultLoading,
    hasNextPage: hasNextPageApprovedAudits,
    fetchNextPage: fetchNextPageApprovedAudits,
    isFetchingNextPage: isFetchingNextPageApprovedAudits,
  } = useInfiniteQuery({
    queryKey: ['approved-audits'],
    queryFn: ({ pageParam = 1 }) => getAudits({
      currentPage: pageParam,
      pageSize: 10,
      status: 'approved',
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })
  const {
    data: reprovedAuditsResult,
    isLoading: isReprovedAuditsResultLoading,
    hasNextPage: hasNextPageReprovedAudits,
    fetchNextPage: fetchNextPageReprovedAudits,
    isFetchingNextPage: isFetchingNextPageReprovedAudits,
  } = useInfiniteQuery({
    queryKey: ['reproved-audits'],
    queryFn: ({ pageParam = 1 }) => getAudits({
      currentPage: pageParam,
      pageSize: 10,
      status: 'reproved',
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const columnsAudits = [
    {
      title: 'Pendentes',
      audits: pendingAuditsResult?.pages.flatMap((page) => page.audits) || [],
      auditsCount: pendingAuditsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPagePendingAudits,
      fetchNextPage: fetchNextPagePendingAudits,
      isFetchingNextPage: isFetchingNextPagePendingAudits,
      isLoading: isPendingAuditsResultLoading,
    },
    {
      title: 'Aprovadas',
      audits: approvedAuditsResult?.pages.flatMap((page) => page.audits) || [],
      auditsCount: approvedAuditsResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageApprovedAudits,
      fetchNextPage: fetchNextPageApprovedAudits,
      isFetchingNextPage: isFetchingNextPageApprovedAudits,
      isLoading: isApprovedAuditsResultLoading,
    },
    {
      title: 'Reprovadas',
      audits: reprovedAuditsResult?.pages.flatMap((page) => page.audits) || [],
      auditsCount: reprovedAuditsResult?.pages[0]?.meta?.count,
      color: 'red.300',
      hasNextPage: hasNextPageReprovedAudits,
      fetchNextPage: fetchNextPageReprovedAudits,
      isFetchingNextPage: isFetchingNextPageReprovedAudits,
      isLoading: isReprovedAuditsResultLoading,
    },
  ]


  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Auditorias</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar tabela"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => history.push('/auditorias')}
          />
          {userCanCreateAudit && (
            <IconButton
              aria-label="Criar auditoria"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateAudit}
            />
          )}
        </Flex>
        <Modal
          isOpen={isCreateAuditOpen}
          onClose={onCloseCreateAudit}
          isCentered
          size="2xl"
        >
          <ModalOverlay />
          <CreateAudit onClose={onCloseCreateAudit} />
        </Modal>
      </Flex>
      <Flex gap={4} overflowX="auto" mt={4}>
        {columnsAudits?.map((audit, index) => {
          return (
            <AuditsKanbanColumn
              key={index}
              audit={audit}
            />
          )
        })}
      </Flex>
    </Box>
  )
}
