import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface DeletePmcParams {
  pmcId: string
}

export async function deletePmc({ pmcId }: DeletePmcParams) {
  try {
    await api.delete(`/pmcs/${pmcId}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
