import { Flex, Stack } from "@chakra-ui/react";

import { Radio } from "components/Inputs/RadioInput";
import { useFormContext } from "react-hook-form";

import { positiveNegativeOptions } from "utils/CustomLists/positiveNegativeOptions";

interface ValidatePurchaseOrderProps {
  isProductForm: boolean
}

export interface ValidatePurchaseOrderFormInputs {
  isFinishedCorrectly: 'yes' | 'no'
  hasRequestedFinancialPayment: 'yes' | 'no'
  hasConferredEquity: 'yes' | 'no'
}

export function ValidatePurchaseOrderForm({
  isProductForm
}: ValidatePurchaseOrderProps) {

  const {
    register,
    formState: {
      errors
    }
  } = useFormContext<ValidatePurchaseOrderFormInputs>()


  const labels = isProductForm
    ? {
      isFinishedCorrectlyLabel: 'Produto entregue corretamente?',
      purchaseTypeAttachment: 'NF do produto',
    }
    : {
      isFinishedCorrectlyLabel: 'Serviço finalizado corretamente?',
      purchaseTypeAttachment: 'RPA do serviço',
    }


  return (
    <Flex direction="column" gap={6} align="center">
      <Stack
        w='full'
        direction='row'
      >

        <Radio
          {...register('isFinishedCorrectly')}
          name='isFinishedCorrectly'
          error={errors.isFinishedCorrectly}
          radioOptions={positiveNegativeOptions}
          label={labels.isFinishedCorrectlyLabel}
          placeholder='Selecione uma opção...'
          required
        />
        <Radio
          {...register('hasRequestedFinancialPayment')}
          name='hasRequestedFinancialPayment'
          error={errors.hasRequestedFinancialPayment}
          radioOptions={positiveNegativeOptions}
          label='Solicitado pagamento ao financeiro?'
          placeholder='Selecione uma opção...'
          required
        />
      </Stack>
      <Radio
        {...register('hasConferredEquity')}
        name='hasConferredEquity'
        error={errors.hasConferredEquity}
        radioOptions={positiveNegativeOptions}
        label='Conferiu se patrimonio deu entrada no controle de patrimonio?'
        placeholder='Selecione uma opção...'
        required
      />

    </Flex>
  )
}
