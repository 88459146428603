import { usePagination } from "@ajna/pagination";
import { Box, Button, Divider, Flex, Heading, Icon, Spinner, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tooltip, Tr, useDisclosure } from "@chakra-ui/react";
import { TableFilterButton } from "components/Filters/TableFilterButton";
import { Pagination } from "components/Pagination/Pagination";
import { SearchBox } from "components/SearchBox/SearchBox";

import { useAuth } from "hooks/auth/useAuth";
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions";
import { ThirdPartyRnc } from "hooks/rnc/dtos/ThirdPartyRnc";
import { useThirdPartyRncFunctions } from "hooks/rnc/useThirdPartyRncFunctions";
import { useThirdPartyRncs } from "hooks/rnc/useThirdPartyRncs";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect, useReducer } from "react";
import { useForm, useWatch } from "react-hook-form";
import { FaEye } from "react-icons/fa";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils/DateFunctions/formatDate";
import { searchBoxFilter } from "utils/searchBoxFilter";
import { DeleteThirdPartyRncPoppover } from "./components/DeleteThirdPartyRncPoppover";
import { ViewThirdPartyRncInfoModal } from "./components/ViewThirdPartyRncInfoModal";

interface FinalizedThirdPartyRncsFilterInputs {
  protocol: string
  type: string
  provider: string
  collector: string
  createdAt: string
  owner: string
}

const finalizedThirdPartyRncsFilterOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'type', value: 'Tipo (fornecedor/coletador)', checked: false },
  { key: 'provider', value: 'Fornecedor', checked: false },
  { key: 'collector', value: 'Coletador', checked: false },
  { key: 'createdAt', value: 'Data registro', checked: false },
  { key: 'owner', value: 'Aberta por', checked: false },
]

interface ActionPayload {
  thirdPartyRnc: ThirdPartyRnc
}

interface Action {
  payload: ActionPayload
}

function reducer(state: ThirdPartyRnc, action: Action) {
  return {
    ...action.payload.thirdPartyRnc
  }
}

export function FinalizedThirdPartyRncsList() {
  const [thirdPartyRncState, dispatch] = useReducer(reducer, null);

  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const { push: redirect } = useHistory()
  const { userLogged } = useAuth()

  const userHasViewThirdPartyRncPermission =
    userLogged?.permissions.includes('view-third-party-rnc')

  const userHasDeleteThirdPartyRncPermission =
    userLogged?.permissions.includes('delete-third-party-rnc')

  useEffect(() => {
    if (!userHasViewThirdPartyRncPermission) redirect('/')
  }, [userHasViewThirdPartyRncPermission, redirect])

  const {
    control,
    register
  } = useForm<FinalizedThirdPartyRncsFilterInputs>()

  const {
    data: thirdPartyRncs,
    isFetching: isFetchingThirdPartyRncs
  } = useThirdPartyRncs({
    queryParams: {
      status: 'finalized',
      collector_id: userLogged?.collector_id,
    }
  })

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    deleteThirdPartyRnc: { mutateAsync: deleteThirdPartyRnc }
  } = useThirdPartyRncFunctions()

  async function handleDeleteThirdPartyRnc(id: string) {
    await promiseMessage(deleteThirdPartyRnc(id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('thirdPartyRncs')
      }
    }), 'Rnc excluído com sucesso!')
  }

  const {
    filterOptions,
    onLoadSetFilterOptions
  } = useFilterOptions()

  useEffect(() => {
    onLoadSetFilterOptions(finalizedThirdPartyRncsFilterOptions)
  }, [onLoadSetFilterOptions])

  const [
    protocolFiltered,
    typeFiltered,
    providerFiltered,
    collectorFiltered,
    createdAtFiltered,
    ownerFiltered
  ] = useWatch({
    control,
    name: [
      'protocol',
      'type',
      'provider',
      'collector',
      'createdAt',
      'owner'
    ]
  })

  const thirdPartyRncsFiltered = thirdPartyRncs
    ?.filter(thirdPartyRnc => {
      const protocolFilter = protocolFiltered
        ? searchBoxFilter(String(thirdPartyRnc.protocol), String(protocolFiltered))
        : true

      const typeFilter = typeFiltered
        ? searchBoxFilter(thirdPartyRnc.type, typeFiltered)
        : true

      const providerFilter = providerFiltered
        ? searchBoxFilter(
          thirdPartyRnc.provider
            ? thirdPartyRnc.provider.trading_name
            : '-', providerFiltered)
        : true

      const collectorFilter = collectorFiltered
        ? searchBoxFilter(
          thirdPartyRnc.collector
            ? thirdPartyRnc.collector.trading_name
            : '-', collectorFiltered
        )
        : true

      const createdAtFilter = createdAtFiltered
        ? searchBoxFilter(
          formatDate.handle(thirdPartyRnc.created_at, 'DateWithoutHourToShow'),
          createdAtFiltered
        )
        : true

      const ownerFilter = ownerFiltered
        ? searchBoxFilter(
          `${thirdPartyRnc.owner.firstname} ${thirdPartyRnc.owner.lastname}`,
          ownerFiltered
        )
        : true

      return protocolFilter && typeFilter && providerFilter && collectorFilter
        && createdAtFilter && ownerFilter
    })

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: thirdPartyRncsFiltered?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  const handlePageChange = (page: number) => setCurrentPage(page)

  useEffect(() => {
    const debounce = setTimeout(() => {
      setCurrentPage(1)
    }, 500)

    return () => clearTimeout(debounce)
  }, [
    setCurrentPage,
    protocolFiltered,
    typeFiltered,
    providerFiltered,
    collectorFiltered,
    createdAtFiltered,
    ownerFiltered
  ])

  const {
    isOpen: isViewThirdPartyRncInfoModalOpen,
    onOpen: onOpenViewThirdPartyRncInfoModal,
    onClose: onCloseViewThirdPartyRncInfoModal,
  } = useDisclosure()

  function handleOpenViewThirdPartyRncInfoModal(thirdPartyRnc: ThirdPartyRnc) {
    dispatch({
      payload: {
        thirdPartyRnc
      }
    })

    onOpenViewThirdPartyRncInfoModal()
  }


  return (

      <Box
        p={4}
        bg="white"
        borderRadius="8px"
      >
        <ViewThirdPartyRncInfoModal
          isOpen={isViewThirdPartyRncInfoModalOpen}
          onClose={onCloseViewThirdPartyRncInfoModal}
          thirdPartyRnc={thirdPartyRncState}
        />

        <Flex
          w="full"
          direction="column"
          gap={6}
        >

          <Heading fontSize="xl">Rncs de terceiros finalizados</Heading>

          <Divider />

          <Flex justify='space-between' gap={4}>
            <TableFilterButton />
            <Stack direction='column'>
              {
                filterOptions
                  .filter(option => option.checked)
                  .map(option => {
                    return (
                      <SearchBox
                        {...register(option.key as keyof FinalizedThirdPartyRncsFilterInputs)}
                        key={option.key}
                        size="sm"
                        placeholder={'Buscar ' + option.value}
                        handleSearch={() => { }}
                      />
                    )
                  })
              }
            </Stack>
          </Flex>

          {isFetchingThirdPartyRncs ? (
            <Spinner />
          ) : (
            <TableContainer>
              <Table size='sm' variant='striped'>
                <Thead>
                  <Tr>
                    {finalizedThirdPartyRncsFilterOptions
                      .filter(option => {
                        return userLogged?.user_type === 'COLETADOR'
                          ? option.key !== 'provider'
                          : option
                      })
                      .map(option => {
                        return (
                          <Th key={option.key}>{option.value.toLocaleUpperCase()}</Th>
                        )
                      })}
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {thirdPartyRncsFiltered
                    ?.slice(offset, offset + Number(itemLimit))
                    ?.map(thirdPartyRnc => {
                      return (
                        <Tr key={thirdPartyRnc.protocol}>
                          <Td>{thirdPartyRnc.protocol}</Td>
                          <Td>{thirdPartyRnc.type}</Td>
                          {userLogged?.user_type !== 'COLETADOR' && (
                            <Td>{thirdPartyRnc.provider ? thirdPartyRnc.provider.trading_name : '-'}</Td>
                          )}
                          <Td>{thirdPartyRnc.collector ? thirdPartyRnc.collector.trading_name : '-'}</Td>
                          <Td>{formatDate.handle(thirdPartyRnc.created_at, 'DateWithoutHourToShow')}</Td>
                          <Td>{`${thirdPartyRnc.owner.firstname} ${thirdPartyRnc.owner.lastname}`}</Td>
                          <Td isNumeric>
                            <Tooltip label='Visualizar' >
                              <Button
                                variant='ghost'
                                onClick={() => {
                                  handleOpenViewThirdPartyRncInfoModal(thirdPartyRnc)
                                }}
                              >
                                <Icon fontSize='lg' as={FaEye} />
                              </Button>
                            </Tooltip>
                            {userHasDeleteThirdPartyRncPermission && (
                              <DeleteThirdPartyRncPoppover
                                id={thirdPartyRnc.id}
                                onDeleteThirdPartyRnc={handleDeleteThirdPartyRnc}
                              />
                            )}
                          </Td>
                        </Tr>
                      )
                    })}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </Flex>
      </Box>

  )
}
