import { Button, Flex, Icon, Input, Select } from "@chakra-ui/react";
import { AuditStatus } from "api/audits/_types/Audit";
import { useSearchParams } from "hooks/useSearchParams";
import { CSVLink } from "react-csv";
import { useForm } from "react-hook-form";
import { FaFileExport, FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { auditStatusMap } from "utils/auditMappers";
import { auditOptions } from "./CreateAudit";

interface AuditsReportFiltersSchema {
  startDate: string
  endDate: string
  status: AuditStatus | 'all'
  type: string
}

interface AuditsReportFiltersProps {
  csvProps: {
    headers: {
      label: string
      key: string
    }[]
    data: unknown[]
    filename: string
  }
}

export function AuditsReportFilters({
  csvProps
}: AuditsReportFiltersProps) {
  const searchParams = useSearchParams()

  const { replace } = useHistory()

  const status = searchParams.get('status')
  const startDate = searchParams.get('startDate')
  const endDate = searchParams.get('endDate')
  const type = searchParams.get('type')

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<AuditsReportFiltersSchema>({
    defaultValues: {
      startDate,
      endDate,
      status: status as AuditStatus | 'all' ?? 'all',
      type
    }
  })

  function handleSearchAuditsReport(values: AuditsReportFiltersSchema) {
    if (values.startDate) {
      searchParams.set('startDate', values.startDate)
    } else {
      searchParams.delete('startDate')
    }

    if (values.endDate) {
      searchParams.set('endDate', values.endDate)
    } else {
      searchParams.delete('endDate')
    }

    if (values.status) {
      searchParams.set('status', values.status)
    } else {
      searchParams.delete('status')
    }

    if (values.type) {
      searchParams.set('type', values.type)
    } else {
      searchParams.delete('type')
    }

    replace({ search: searchParams.toString() })

  }

  function handleRemoveFilters() {
    searchParams.delete('status')
    searchParams.delete('type')
    searchParams.delete('startDate')
    searchParams.delete('endDate')

    reset({ status: 'all' })

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }


  return (
    <Flex
      justify={{ base: 'center', md: 'space-between' }}
      direction={{ base: 'column', md: 'row' }}
      align="center"
      mt="6"
      as="form"
      gap="2"
      w='full'
      onSubmit={handleSubmit(handleSearchAuditsReport)}
    >
      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Input
          {...register("startDate")}
          type="date"
          size="sm"
          rounded="md"
          w={{ base: 'full', md: 'fit-content' }}
        />

        <Input
          {...register("endDate")}
          type="date"
          size="sm"
          rounded="md"
          w={{ base: 'full', md: 'fit-content' }}
        />

        <Select
          {...register("status")}
          defaultValue="all"
          size="sm"
          rounded="md"
          placeholder="Selecione uma opção..."
          w={{ base: "full", md: '250px' }}
        >
          <option value="all">Todos status</option>
          {Object.entries(auditStatusMap).map(([key, value]) => {
            return (
              <option key={key} value={key}>{value}</option>
            )
          })}
        </Select>
        <Select
          {...register('type')}
          name="type"
          placeholder="Tipo de auditoria..."
          size="sm"
          rounded="md"
          w={["full", "full", "200px"]}
        >
          {Object.entries(auditOptions).map(([key, value]) => (
            <option key={key} value={value}>{value}</option>
          ))}
        </Select>
      </Flex>

      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Button
          leftIcon={<FaFileExport />}
          size="sm"
          type="button"
          colorScheme="green"
          as={CSVLink}
          separator={';'}
          {...csvProps}
          w={{ base: 'full', md: 'min' }}
        >
          Exportar
        </Button>
        <Button
          leftIcon={<FaSearch />}
          size="sm"
          type="submit"
          w={{ base: 'full', md: 'min' }}
        >
          Gerar relatório
        </Button>
        <Button
          type="submit"
          size="sm"
          variant="outline"
          leftIcon={<Icon as={FaTimes} />}
          w={['full', 'full', 'min']}
          onClick={handleRemoveFilters}
        >
          Remover filtros
        </Button>
      </Flex>
    </Flex>
  )
}
