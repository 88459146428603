import { createContext, ReactNode } from 'react'
import { api } from '../services/api'

import 'react-toastify/dist/ReactToastify.css'
import swal from 'sweetalert'

interface KnitProviderProps {
  children: ReactNode
  pathname?: string
}

export interface KnitProps {
  id: string
  arrival_date: string
  arrival_hour: string
  departure_date: string
  departure_hour: string
  destination_branch_id: string
  destination_hub_id: string
  shipping_id: string
  source_branch_id: string
  source_hub_id: string
  truck_flight: string
  shippingIDRouteNetwork: {
    company_name: string
  }
  sourceHubIDRouteNetwork: {
    name: string
  }
  destinationHubIDRouteNetwork: {
    name: string
  }
  sourceBranchIDRouteNetwork: {
    nickname: string
  }
  destinationBranchIDRouteNetwork: {
    nickname: string
  }
}

// type KnitInputProps = Omit<KnitProps, 'id' | 'shippingIDRouteNetwork' | 'sourceHubIDRouteNetwork' | 'destinationHubIDRouteNetwork' | 'sourceBranchIDRouteNetwork' | 'destinationBranchIDRouteNetwork'>;
type KnitInputProps = {
  csvFile: {} | null
}

interface KnitContextProps {
  createKnit: (knitInput: KnitInputProps) => Promise<boolean>
  deleteKnit: (knitId: string) => Promise<boolean>
}

export const KnitContext = createContext<KnitContextProps>(
  {} as KnitContextProps,
)

export function KnitProvider({ children, pathname }: KnitProviderProps) {
  async function createKnit(knitInput: KnitInputProps) {
    try {
      await api.post('/route-network', knitInput)
      swal('Poof! A malha foi cadastrada com sucesso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function deleteKnit(knitId: string) {
    try {
      await api.delete(`/route-network/${knitId}`)
      swal('Poof! A rota foi excluída com sucesso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  return (
    <KnitContext.Provider value={{ createKnit, deleteKnit }}>
      {children}
    </KnitContext.Provider>
  )
}
