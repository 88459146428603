import { Options } from "components/Inputs/SelectInput";

export const rncRiskRatingOptions: Options[] = [
  { key: '0', value: 'ALVARÁ', showOption: 'Alvará' },
  { key: '1', value: 'BUDGET', showOption: 'Budget' },
  { key: '2', value: 'FISCAL', showOption: 'Fiscal' },
  { key: '3', value: 'FLUXO DE CAIXA', showOption: 'Fluxo de caixa' },
  { key: '4', value: 'FURO DE ESTOQUE', showOption: 'Furo de estoque' },
  { key: '5', value: 'JURÍDICO', showOption: 'Jurídico' },
  { key: '6', value: 'NÃO PROCEDENTE', showOption: 'Não procedente' },
  { key: '7', value: 'ORGANIZAÇÃO E CONTROLE', showOption: 'Organização e controle' },
  { key: '8', value: 'SATISFAÇÃO DO CLIENTE', showOption: 'Satisfação do cliente' },
  { key: '9', value: 'SATISFAÇÃO DO COLABORADOR', showOption: 'Satisfação do colaborador' },
  { key: '10', value: 'INCAPACITAÇÃO', showOption: 'Incapacitação' },
  { key: '11', value: 'SLA', showOption: 'Sla' },
  { key: '12', value: 'BUGS/SISTEMA', showOption: 'Bugs/sistema' },
  { key: '13', value: 'NO SHOW', showOption: 'No show' },
  { key: '14', value: 'EXTRAVIO DE CARGA', showOption: 'Extravio de carga' },
  { key: '15', value: 'PERDA DE ESTABILIDADE', showOption: 'Perda de estabilidade' },
].sort((a, b) => a.value.localeCompare(b.value))
