import { Button, Flex, Input, Select } from "@chakra-ui/react"
import { PatrimonyRequestStatus } from "api/patrimonies/patrimonyRequest"
import { useSearchParams } from "hooks/useSearchParams"
import { useForm } from "react-hook-form"
import { FaSearch, FaTimes } from "react-icons/fa"
import { useHistory } from "react-router-dom"

interface PatrimonyRequestsTableFiltersSchema {
  status: PatrimonyRequestStatus | 'all'
  patrimonyType: string
  identifier: string
  responsibleName: string
  protocol: string
}


export function PatrimonyRequestsTableFilters() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()


  const patrimonyType = searchParams.get('patrimonyType')
  const status = searchParams.get('status') ?? 'all'
  const identifier = searchParams.get('identifier')
  const responsibleName = searchParams.get('responsibleName')
  const protocol = searchParams.get('protocol')

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<PatrimonyRequestsTableFiltersSchema>({
    defaultValues: {
      patrimonyType: patrimonyType ?? '',
      status: status as PatrimonyRequestStatus | 'all',
      identifier: identifier ?? '',
      responsibleName: responsibleName ?? '',
      protocol: protocol ?? '',
    }
  })

  async function handleFilterOnboardings(data: PatrimonyRequestsTableFiltersSchema) {
    if (data.patrimonyType) {
      searchParams.set('patrimonyType', data.patrimonyType)
    } else {
      searchParams.delete('patrimonyType')
    }

    if (data.identifier) {
      searchParams.set('identifier', data.identifier)
    } else {
      searchParams.delete('identifier')
    }

    if (data.responsibleName) {
      searchParams.set('responsibleName', data.responsibleName)
    } else {
      searchParams.delete('responsibleName')
    }
    if (data.protocol) {
      searchParams.set('protocol', data.protocol)
    } else {
      searchParams.delete('protocol')
    }

    if (
      data.status) {
      searchParams.set('status', data.status)
    } else {
      searchParams.delete('status')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    reset()
    searchParams.delete('patrimonyType')
    searchParams.delete('brand')
    searchParams.delete('status')
    searchParams.delete('identifier')
    searchParams.delete('responsibleName')
    searchParams.delete('protocol')

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterOnboardings)}
    >
      <Input
        {...register("patrimonyType")}
        placeholder="Tipo"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />

      <Input
        {...register("responsibleName")}
        placeholder="Responsável"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />
      <Input
        {...register("identifier")}
        placeholder="Nº patrimônio"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />
      <Input
        {...register("protocol")}
        placeholder="Protocolo"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />

      <Select
        {...register("status")}
        defaultValue="all"
        size="sm"
        rounded="md"
        w={["full", "full", "200px"]}
      >
        <option value="all">Todos status</option>
        <option value="requested">Solicitados</option>
        <option value="active">Ativos</option>
        <option value="returned">Devolvido</option>

      </Select>

      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={["full", "full", "min"]}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleRemoveFilters}
        w={["full", "full", "min"]}
      >
        Remover filtros
      </Button>
    </Flex>
  )
}
