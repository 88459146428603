import { ExternalComunicationsChatContent } from "./ExternalComunicationsChatContent";
import { ExternalComunicationsChatForm } from "./ExternalComunicationsChatForm";
import { ExternalComunicationsChatHeader } from "./ExternalComunicationsChatHeader";
import { ExternalComunicationsChatRoot } from "./ExternalComunicationsChatRoot";

export const ExternalComunicationsChat = {
  Root: ExternalComunicationsChatRoot,
  Header: ExternalComunicationsChatHeader,
  Content: ExternalComunicationsChatContent,
  Form: ExternalComunicationsChatForm,
}
