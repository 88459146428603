import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CreateProductBody {
  situation: string
  material_id: string
  general_provider_id: string
  warehouse_id: string,
  measure: string
  unit_cost: number
  min_stock: number
  standard_stock: number
  current_stock: number
}

interface CreateProductProps {
  body: CreateProductBody
}

export async function createProduct({
  body
}: CreateProductProps) {
  try {
    const response = await api.post(`/products`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
