import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface SendCltDriverSigningDocumentDetailsBody {
  doesEmployeeWithdrewThePosition: boolean
  signingDocumentsChecklist: string[]
  doesFoodMeanVoucherDelivered: boolean
  epiRecordAttachmentId: string
  shouldAdvanceStatus: boolean
}

interface SendCltDriverSigningDocumentDetailsProps {
  body: SendCltDriverSigningDocumentDetailsBody
  cltDriverId: string
}

export async function sendCltDriverSigningDocumentDetails({
  body,
  cltDriverId
}: SendCltDriverSigningDocumentDetailsProps) {
  try {
    const response = await api.put(`/clt-drivers/${cltDriverId}/hire/signing-document-details`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
