import { usePagination } from "@ajna/pagination";
import { ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { getRequestedServiceLogs, RequestedService } from "api/service/getRequestedServiceLogs";
import { Pagination } from "components/Pagination/Pagination";
import { format } from "date-fns";
import { useState } from "react";
import { useQuery } from "react-query";

interface RequestedServiceLogsProps {
  requestedServiceId: string
}

function fieldsMapper(field: keyof RequestedService) {
  switch (field) {
    case 'collectHourStart':
      return 'Horário inicial da coleta'
    case 'availabilityForecastDay':
      return 'Data prevista para disponibilidade'
    case 'availabilityForecastTime':
      return 'Horário previsto para disponibilidade'
    case 'boardDate':
      return 'Data do embarque'
    case 'boardHour':
      return 'Horário do embarque'
    case 'caixaTermica':
      return 'Caixa térmica'
    case 'collectDate':
      return 'Data da coleta'
    case 'collectHourEnd':
      return 'Horário final da coleta'
    case 'crossdockingAvailabilityForecastDay':
      return 'Data prevista de disponibilidade crossdocking'
    case 'crossdockingAvailabilityForecastTime':
      return 'Horário previsto de disponibilidade crossdocking'
    case 'crossdockingBoardDate':
      return 'Data de embarque crossdocking'
    case 'crossdockingBoardHour':
      return 'Horário de embarque crossdocking'
    case 'crossdockingCollectorId':
      return 'Coletador crossdocking'
    case 'crossdockingModal':
      return 'Modal crossdocking'
    case 'crossdockingPlannedFlight':
      return 'Viagem planejada crossdocking'
    case 'deadline':
      return 'Prazo'
    case 'deliveryDate':
      return 'Data de entrega'
    case 'deliveryHour':
      return 'Horário de entrega'
    case 'destinationBranchId':
      return 'Base destino'
    case 'destinationCollectorId':
      return 'Coletador destino'
    case 'destinationCrossdockingBranchId':
      return 'Base destino crossdocking'
    case 'embalagemSecundaria':
      return 'Embalagem secundária'
    case 'franchising':
      return 'Franquia'
    case 'geloSeco':
      return 'Gelo seco'
    case 'gelox':
      return 'Gelox'
    case 'isopor3l':
      return 'Isopor 3l'
    case 'isopor7l':
      return 'Isopor 7l'
    case 'materialType':
      return 'Tipo de material'
    case 'modal':
      return 'Modal'
    case 'plannedFlight':
      return 'Viagem planejada'
    case 'providerId':
      return 'Fornecedor'
    case 'serviceType':
      return 'Tipo de serviço'
    case 'sourceBranchId':
      return 'Base de origem'
    case 'sourceCollectorId':
      return 'Coletador de origem'
    case 'sourceCrossdockingBranchId':
      return 'Base de origem crossdocking'
    case 'terciaria3l':
      return 'Terciária 3l'
    case 'terciaria8l':
      return 'Terciária 8l'
    case 'vehicle':
      return 'Veículo'
    default:
      return field
  }
}

export function RequestedServiceLogs({
  requestedServiceId
}: RequestedServiceLogsProps) {
  const [paginationPage, setPaginationPage] = useState(1)


  const {
    data: requestedServiceLogsResult,
    isLoading: isLoadingRequestedServiceLogsResult
  } = useQuery({
    queryKey: ['requested-service-logs', requestedServiceId, paginationPage],
    queryFn: () => getRequestedServiceLogs({
      pageSize: 10,
      currentPage: paginationPage,
      requestedServiceId
    })
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: requestedServiceLogsResult?.meta?.totalPages,
    initialState: {
      currentPage: paginationPage,
      pageSize: 10
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    setPaginationPage(page)
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Logs de edição de serviço solicitado
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <Table
          size="sm"
        >
          <Thead>
            <Tr>
              <Th color="blackAlpha.600" fontSize="sm" fontWeight="medium" letterSpacing="wide" fontFamily="Roboto" textTransform="none">Data</Th>
              <Th color="blackAlpha.600" fontSize="sm" fontWeight="medium" letterSpacing="wide" fontFamily="Roboto" textTransform="none">Detalhes</Th>
              <Th color="blackAlpha.600" fontSize="sm" fontWeight="medium" letterSpacing="wide" fontFamily="Roboto" textTransform="none">Atualizado por</Th>
            </Tr>
          </Thead>

          <Tbody>
            {isLoadingRequestedServiceLogsResult ? (
              Array.from({length: 10}).map((_, index) => {
                return (
                  <Tr key={String(index)}>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>

                  </Tr>
                )
              })
            ) : (
              requestedServiceLogsResult?.logs?.map((log) => {
                return (
                  <Tr key={log.id}>
                    <Td>{format(new Date(log.createdAt), "dd/MM/yyyy '-' HH:mm")}</Td>
                    <Td>
                      {Object.entries(log.logDetail).map(([key, value]) => {
                         if (key !== 'destinationAddressesInput' && key !== 'protocol') {

                           return (
                             <Text>{fieldsMapper(key as keyof RequestedService)}: {value.prev} ➡️ {value.new}</Text>
                           )
                         }
                         return []
                      })}
                    </Td>
                    <Td>{log?.updatedBy?.firstname ?? '-'}</Td>
                  </Tr>
                )
              })
            )}





          </Tbody>

        </Table>

        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />

      </ModalBody>
    </ModalContent>
  )
}
