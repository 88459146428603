import axios from "axios"
import { useAuth } from "hooks/auth/useAuth"
import { Bug } from "./dtos/Bug"
import { useToastify } from "hooks/toastify/useToastify"
import { useMutation } from "react-query"
import { api } from "services/api"
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum"
import { RequestError } from "utils/errors/RequestErrors"



async function validateBugReqFunction(bugId: string) {
  try {
    const { data } = await api.patch(`/bugs/${bugId}/validate`)

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

async function removeBugValidationReqFunction(bugId: string) {
  try {
    const { data } = await api.patch(`/bugs/${bugId}/validation/remove`)

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

interface EditBugSubjectReqFunctionProps {
  bugId: string
  requestBody: {
    subject: string
  }
}

async function editBugSubjectReqFunction({
  bugId,
  requestBody,
}: EditBugSubjectReqFunctionProps) {
  try {
    const { data } = await api.patch(`/bugs/${bugId}/subject`, requestBody)

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

interface EditBugDescriptionReqFunctionProps {
  bugId: string
  requestBody: {
    description: string
  }
}

async function editBugDescriptionReqFunction({
  bugId,
  requestBody,
}: EditBugDescriptionReqFunctionProps) {
  try {
    const { data } = await api.patch(`/bugs/${bugId}/description`, requestBody)

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

interface CreateBugCommentReqFunctionProps {
  bugId: string
  requestBody: {
    content: string
  }
}

async function createBugCommentReqFunction({
  bugId,
  requestBody,
}: CreateBugCommentReqFunctionProps) {
  try {
    const { data } = await api.post(`/bugs/${bugId}/comments`, requestBody)

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

async function deleteBugReqFunction(bugId: string) {
  try {
    await api.delete(`/bugs/${bugId}`)

  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

interface AssignUserToBugReqFunctionProps {
  bugId: string
  userId: string
}


async function assignUserToBugReqFunction({
  bugId,
  userId,
}: AssignUserToBugReqFunctionProps) {
  try {
    await api.patch(`/bugs/${bugId}/users/${userId}/assign`)

  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

async function removeAssignedUserFromBugReqFunction({
  bugId,
  userId,
}: AssignUserToBugReqFunctionProps) {
  try {
    await api.patch(`/bugs/${bugId}/users/${userId}/assign/remove`)

  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

interface BugReportResponse {
  bugs: Bug[]
}

interface GenerateBugReportProps {
  initialDate?: string
  finalDate?: string
}

async function generateBugReportReqFunction(
  input: GenerateBugReportProps
): Promise<BugReportResponse> {
  try {
    const { data } = await api.post(`/bugs/report`, {
      ...input
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError(error.response.data.message)
    }
  }
}

export function useBugsFunctions() {
  const { errorMessage } = useToastify()

  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    errorMessage(message)

    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) await handleLogout()
  }

  const validateBug = useMutation(validateBugReqFunction)
  const removeBugValidation = useMutation(removeBugValidationReqFunction)
  const editBugSubject = useMutation(editBugSubjectReqFunction)
  const editBugDescription = useMutation(editBugDescriptionReqFunction)
  const createBugComment = useMutation(createBugCommentReqFunction)
  const deleteBug = useMutation(deleteBugReqFunction)
  const assignUserToBug = useMutation(assignUserToBugReqFunction)
  const removeAssignedUserFromBug = useMutation(removeAssignedUserFromBugReqFunction)
  const generateBugReport = useMutation('bugReport', generateBugReportReqFunction, {
    onError: onRequestError
  })

  return {
    validateBug,
    removeBugValidation,
    editBugSubject,
    editBugDescription,
    createBugComment,
    deleteBug,
    assignUserToBug,
    removeAssignedUserFromBug,
    generateBugReport
  }
}
