import { Button, FormControl, FormLabel, Input, Stack } from "@chakra-ui/react";
import { FaFileImport } from "react-icons/fa";
import { FieldErrors } from "react-hook-form";
import { ChangeEvent } from "react";

interface FileUploadProps<T> {
  label: string;
  fieldName: keyof T;
  register: (name: keyof T) => any;
  errors: FieldErrors<T>;
  handleUpload(event: ChangeEvent<HTMLInputElement>): Promise<void>
}

export const FileUpload = <T,>({ label, fieldName, register, errors, handleUpload }: FileUploadProps<T>) => {
  return (
    <Stack direction="column" w="full">
      <Button
        as={FormLabel}
        htmlFor={fieldName as string}
        lineHeight="1"
        leftIcon={<FaFileImport />}
        size="sm"
        w="min"
        cursor="pointer"
        border={!!errors[fieldName] && '2px solid'}
        borderColor={(!!errors[fieldName]) && 'red.500'}
      >
        {label}
      </Button>
      <FormControl isInvalid={!!errors[fieldName]}>
        <Input
          {...register(fieldName)}
          name={fieldName as string}
          id={fieldName as string}
          type="file"
          hidden
          onChangeCapture={handleUpload}
        />
      </FormControl>
    </Stack>
  );
}


