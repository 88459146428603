import { Box, Flex, FormControl, FormLabel, Spinner, Stack, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { GeneralCheckbox } from 'components/Inputs/GeneralCheckbox'
import { SearchBox } from 'components/SearchBox/SearchBox'
import { Collector } from 'hooks/collector/dtos/Collector'
import { useCustomer } from 'hooks/customer/useCustomer'
import { useLLMUsers } from 'hooks/user/useLLMUsers'
import { useEffect, useState } from 'react'
import { Controller, NestedValue, useForm } from 'react-hook-form'
import { searchBoxFilter } from 'utils/searchBoxFilter'
import { CustomerProps } from '../../../contexts/CustomerContext'
import { PermissionProps } from '../../../contexts/PermissionContext'
import { useAuth } from '../../../hooks/auth/useAuth'
import { IDriverProps } from '../../../services/getFunctions/driver/getDrivers'
import { negative_positive } from '../../../utils/customLists'
import { ProfileProps } from '../../../utils/RequestFunctions/Profile/requestProfileFunctions'
import { RequestedUserProps } from '../../../utils/RequestFunctions/RequestedUser/requestRequestedUserFunctions'
import { schema } from '../../../validations/requestedUserSchema'
import { FormActionButton } from '../../Buttons/FormActionButton'
import { ListButton } from '../../Buttons/ListButton'
import { SubmitButton } from '../../Buttons/SubmitButton'
import { Input } from '../../Inputs/Input'
import { Select } from '../../Inputs/SelectInput'
import { StandardBackgroundForm } from '../StandardBackgroundForm'
import AsyncSelect from 'react-select/async';

interface IFormInputProps {
  user_type: string
  loglife_employee: string
  customer_id: string
  collector_id: string
  driver_id: string
  permissions: NestedValue<string[]>
  email: string
  firstname: string
  lastname: string
  owner: string
  customers: string[]
  substitute_id: string
  collectors_ids: {
    label: string
    value: string
  }[]
}

interface IDetailEditRequestedUserProps {
  submit: (values: IFormInputProps) => Promise<void>
  customers?: CustomerProps[]
  collectors?: Collector[]
  drivers?: IDriverProps[]
  permissions?: PermissionProps[]
  profiles?: ProfileProps[]
  requestedUser: RequestedUserProps
  slug: string
}

export function DetailEditRequestedUserForm({
  submit,
  customers,
  collectors,
  drivers,
  permissions,
  profiles,
  requestedUser,
  slug,
}: IDetailEditRequestedUserProps) {

  const { userLogged } = useAuth()
  const [customerFiltered, setCustomerFiltered] = useState('')

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(schema),
  })

  const {
    customers: { isFetching: isCustomersFetching }
  } = useCustomer(null, true)

  const userType = watch('user_type')
  const collectorId = watch('collector_id')

  const driversFilteredByCollectorId = drivers?.filter(
    (driver) => driver.collector_id === collectorId) ?? []

  const userTypesToSelect = profiles?.map((profile, index) => {
    return { id: profile.id, name: profile.user_type }
  })

  useEffect(() => {
    setValue('user_type', requestedUser.user_type)
    setValue(
      'loglife_employee',
      requestedUser.loglife_employee === true ? 'SIM' : 'NÃO',
    )

    setValue(
      'collector_id',
      requestedUser.collector_id ? requestedUser.collector_id : '',
    )

    if (requestedUser.collectors.length) {
      requestedUser.collectors.forEach((collector, index) => {
        setValue(`collectors_ids.${index}`, { label: collector.collector.trading_name, value: collector.collector.id })
      })
    }

    setValue(
      'driver_id',
      requestedUser.driver_id ? requestedUser.driver_id : '',
    )
    setValue('email', requestedUser.email)
    setValue('firstname', requestedUser.firstname)
    setValue('lastname', requestedUser.lastname)
    setValue('owner', requestedUser.ownerIDRequestedUser.firstname)
  }, [customers, requestedUser, setValue])

  useEffect(() => {
    if (!isCustomersFetching) {
      setValue(
        'customers',
        requestedUser.customers ?? [],
      )
    }
  }, [isCustomersFetching, setValue, requestedUser])

  useEffect(() => {
    if (userType) {
      if (userType !== 'CLIENTE' && userType !== 'REMETENTE') {
        setValue('customers', [])
      }
      if (userType !== 'COLETADOR' && userType !== 'MOTORISTA') {
        setValue('collector_id', '')
      }
      if (userType !== 'MOTORISTA') {
        setValue('driver_id', '')
      }
    }
  }, [userType, setValue])

  const isLLMUser = watch('loglife_employee') === 'SIM'

  const {
    data: llmUsersData,
    isFetching: isFetchingLlmUsersData
  } = useLLMUsers({
    queryOptions: {
      enabled: isLLMUser
    }
  })

  const substituteUsersOptions = llmUsersData?.users.map((user) => {
    return {
      key: user.id,
      value: user.id,
      showOption: user.firstname + ' ' + user.lastname
    }
  }).sort((a, b) => a.showOption.localeCompare(b.showOption))

  useEffect(() => {
    if (requestedUser && !isFetchingLlmUsersData) {
      setValue('substitute_id', requestedUser.substitute_id ?? '')
    }
  }, [requestedUser, setValue, isFetchingLlmUsersData])

  const customersCheckboxOptions = customers?.filter(customer => {
    return userLogged?.user_type === 'CLIENTE'
      ? customer.id === userLogged?.customer_id
      : customer
  })?.map((customer) => {
    return {
      key: customer.id,
      value: customer.id,
      showOption: customer.trading_firstname
    }
  })

  const customersOptions = customersCheckboxOptions?.filter((customer) => {
    const customerFilter = searchBoxFilter(
      customer.showOption,
      customerFiltered,
    )
    if (customerFiltered === "") {
      return customer
    }
    return customerFilter
  })

  async function promiseOptionsCollectors(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    return collectors
      ?.filter((collector) => collector.situation === 'ATIVO')
      ?.filter((collector) => !inputValue || collector.trading_name.toLowerCase().includes(inputValue.toLowerCase()))
      ?.sort((a, b) => a.trading_name.localeCompare(b.trading_name))
      ?.map((collector) => {
        return { value: collector.id, label: collector.trading_name.toUpperCase() };
      });
  }


  return (
    <StandardBackgroundForm
      onSubmit={handleSubmit(submit)}
      title="Solicitar Criação de Usuário"
    >
      <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Select
          {...register('user_type')}
          name="user_type"
          label="Tipo de Usuário"
          placeholder="Selecione uma opção..."
          user_types={userTypesToSelect}
          error={errors.user_type}
          isDisabled={slug === 'visualizar'}
          required
        />
        <Stack w="full" spacing="24px" direction={['column', 'column', 'row']}>
          <Select
            {...register('loglife_employee')}
            name="loglife_employee"
            label="Funcionário Loglife"
            placeholder="Selecione uma opção..."
            negative_positive={negative_positive}
            error={errors.loglife_employee}
            isDisabled={slug === 'visualizar'}
            required
          />

          <Input
            {...register('owner')}
            name="owner"
            label="Solicitado Por"
            isDisabled={true}
            required
          />
        </Stack>
      </Stack>

      <Stack mt="4">
        {isFetchingLlmUsersData ? (
          <Spinner />
        ) : (
          <Select
            {...register("substitute_id")}
            name="substitute_id"
            error={errors.substitute_id}
            options={substituteUsersOptions}
            label="Usuário suplente"
            placeholder="Selecione um usuário..."
            isDisabled={slug === 'visualizar'}
          />
        )}
      </Stack>
      {userType === 'COLETADOR' && (
        <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
          <FormControl isInvalid={!!errors?.collectors_ids}>
            <FormLabel w="full" fontSize='sm'>
              Coletador(es)
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              control={control}
              name="collectors_ids"
              render={({ field }) => (
                <AsyncSelect
                  name={field.name}
                  onChange={field.onChange}
                  value={field.value}
                  cacheOptions
                  defaultOptions
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: '2px',
                      borderRadius: '6px',
                      borderColor: 'gray.200',
                      border: state.isFocused ? 'none' : '',
                      boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                    }),
                    placeholder: (base, props) => ({
                      ...base,
                      color: 'blackAlpha.900'
                    }),
                    dropdownIndicator(base, props) {
                      return {
                        ...base,
                        color: 'blackAlpha.900',
                        width: '34px',
                      }
                    },
                  }}
                  noOptionsMessage={() => 'Não há coletadores cadastrados ou encontrados!'}
                  placeholder="Selecione um coletador..."
                  loadOptions={promiseOptionsCollectors}
                  isClearable={true}
                  isMulti
                  isDisabled={slug === 'visualizar'}
                />
              )}
            />
          </FormControl>
        </Stack>
      )}
      <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
        <Input
          {...register('email')}
          name="email"
          label="E-mail"
          error={errors.email}
          isDisabled={slug === 'visualizar'}
          required
        />
        {userType === 'MOTORISTA' && (
          <Select
            {...register('collector_id')}
            name="collector_id"
            collectors={collectors}
            placeholder="Selecione uma opção..."
            label="Pertence ao Coletador"
            error={errors.collector_id}
            isDisabled={slug === 'visualizar'}
            required
          />
        )}
      </Stack>

      <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
        <Stack w="full" spacing="24px" direction={['column', 'column', 'row']}>
          <Input
            {...register('firstname')}
            name="firstname"
            label="Nome"
            error={errors.firstname}
            isDisabled={slug === 'visualizar'}
            required
          />
          <Input
            {...register('lastname')}
            name="lastname"
            label="Sobrenome"
            error={errors.lastname}
            isDisabled={slug === 'visualizar'}
            required
          />
        </Stack>
        {userType === 'MOTORISTA' && collectorId && (
          <Stack w="full">
            <Select
              {...register('driver_id')}
              name="driver_id"
              drivers={driversFilteredByCollectorId}
              placeholder="Selecione uma opção..."
              label="Pertence ao Motorista"
              error={errors.driver_id}
              isDisabled={slug === 'visualizar'}
              required
            />
          </Stack>
        )}
      </Stack>

      {(userType === 'CLIENTE' || userType === 'REMETENTE') && (
        <Stack w="full" mt="4" spacing="10px" justify="flex-start">
          <>
            <SearchBox
              isCheckbox={true}
              size="sm"
              placeholder="Buscar Cliente..."
              handleSearch={(e) =>
                setCustomerFiltered(e.target.value)
              }
            />
            <Box
              overflowY="auto"
              height="300px"
              borderWidth="1px"
              borderRadius="lg"
              p="2"
            >
              <Controller
                control={control}
                name="customers"
                render={({ field }) => (
                  <GeneralCheckbox
                    name="customers"
                    defaultCheckedOptions={requestedUser?.customers}
                    onCheckboxChange={field.onChange}
                    checkboxOptions={customersOptions}
                    label="Cliente(s)"
                  />
                )}
              />
            </Box>
          </>

        </Stack>
      )}

      {(userLogged?.permissions.includes('edit-requested-user') ||
        userLogged?.permissions.includes('view-requested-user')) && (
          <Flex mt="4" justifyContent="flex-end" alignItems="center">
            <Stack direction="row" spacing="12px">
              {slug === 'editar' ? (
                <SubmitButton action="Salvar" isSubmitting={isSubmitting} />
              ) : (
                <>
                  {userLogged?.permissions.includes('edit-requested-user') && (
                    <FormActionButton
                      action="Editar"
                      href={`/usuario-solicitado/editar/${requestedUser?.id}`}
                    />
                  )}
                </>
              )}
              {userLogged?.permissions.includes('view-requested-user') && (
                <ListButton name="Solicitações" href="/usuarios-solicitados" />
              )}
            </Stack>
          </Flex>
        )}
    </StandardBackgroundForm>
  )
}
