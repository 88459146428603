import { Box, Flex, Heading, IconButton, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { Empty } from "components/Empty";

import { useCallback, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { DelayedCollects } from "./components/DelayedCollects";
import { NextToDelayCollects } from "./components/NextToDelayCollects";

export function DelayedOrNextToDelayCollectServices() {
  const [isError, setIsError] = useState(false)

  const setError = useCallback(() => setIsError(true), [])

  const handleReloadPage = () => window.location.reload()

  const history = useHistory()

  const handleReturnToDashboard = () => history.goBack()

  if (isError) {
    return (
      <Empty.Root>
        <Empty.ActionButton onClick={handleReloadPage}>Recarregar a página</Empty.ActionButton>
      </Empty.Root>
    )
  }

  return (

      <Box
        p="6"
        rounded="md"
        bg="white"
        display="flex"
        flexDirection="column"
        gap="6"
      >
        <Flex
          align="center"
          gap="2"
        >
          <IconButton
            aria-label="Voltar ao dashboard"
            icon={<FaArrowLeft />}
            variant="ghost"
            size="sm"
            onClick={handleReturnToDashboard}
          />
          <Heading letterSpacing="tight">Coletas em Atraso ou Próximas a Atrasar</Heading>
        </Flex>


        <Tabs isLazy>
          <TabList>
            <Tab>Em Atraso</Tab>
            <Tab>Próximas a Atrasar</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <DelayedCollects onSetIsError={setError} />
            </TabPanel>
            <TabPanel>
              <NextToDelayCollects onSetIsError={setError} />
            </TabPanel>
          </TabPanels>

        </Tabs>
      </Box>

  )
}
