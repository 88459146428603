import { CollectorCost } from "hooks/cost/dtos/CollectorCost"

export class ValidateSourceCollectorVehicleHasCost {
  async handle(
    collectorCost: CollectorCost[],
    sourceCollectorId: string,
    vehicle: string,
  ) {
    const collectorCostsFilteredBySourceCollectorId = collectorCost.filter(
      (cost) => cost.collector_id === sourceCollectorId,
    )

    if (vehicle === 'CARRO') {
      const collectorDontHaveCarCost = collectorCostsFilteredBySourceCollectorId
        .map(cost => cost.cost_car).reduce((a, b) => a + b, 0) === 0

      if (
        !collectorCostsFilteredBySourceCollectorId.length ||
        collectorDontHaveCarCost
      ) {
        return 'O coletador de origem não possui custo cadastrado para o tipo de veículo selecionado. Deseja Continuar?'
      }
    }
    if (vehicle === 'MOTO') {
      const collectorDontHaveMotorcycleCost = collectorCostsFilteredBySourceCollectorId
        .map(cost => cost.cost_motorcycle).reduce((a, b) => a + b, 0) === 0

      if (
        !collectorCostsFilteredBySourceCollectorId.length ||
        collectorDontHaveMotorcycleCost
      ) {
        return 'O coletador de origem não possui custo cadastrado para o tipo de veículo selecionado. Deseja Continuar?'
      }
    }
    if (vehicle === 'CAMINHÃO') {
      const collectorDontHaveTruckCost = collectorCostsFilteredBySourceCollectorId
        .map(cost => cost.cost_truck).reduce((a, b) => a + b, 0) === 0

      if (
        !collectorCostsFilteredBySourceCollectorId.length ||
        collectorDontHaveTruckCost
      ) {
        return 'O coletador de origem não possui custo cadastrado para o tipo de veículo selecionado. Deseja Continuar?'
      }
    }
  }
}

const validateSourceCollectorVehicleHasCost =
  new ValidateSourceCollectorVehicleHasCost()

export { validateSourceCollectorVehicleHasCost }
