import { Box, Button, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, Heading, IconButton, Input, InputGroup, InputLeftAddon, Modal, ModalOverlay, Select, Stack, Text, Textarea, useDisclosure, VStack } from "@chakra-ui/react";
import { useLLMUsers } from "hooks/user/useLLMUsers";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { captalize } from "utils/captalize";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { AttachmentTableRowField } from "./AttachmentTableRowField";
import { useCurrency } from "react-hook-currency";
import { FaPlus } from "react-icons/fa";
import { CreateSupport } from "pages/Support/components/CreateSupport";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { useSearchParams } from "hooks/useSearchParams";
import { useHistory } from "react-router-dom";
import { checkInvoice } from "api/invoices/checkInvoice";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import { FormEvent } from "react";

interface CheckInvoiceProps {
  invoiceId: string
}

export interface CheckInvoiceSchema {
  approvedReportAttachment: {
    attachmentId: string
    file: File
  }
  divergences: string
  hasContractAdditive: string
  hasUnforeseenCharge: string
  isApproved: string
  responsibleId: string
  unforeseenChargeChecklist: string[]
  approvedValueInCents: number
}

const checkInvoiceSchema = yup.object({
  responsibleId: yup.string().required(),
  isApproved: yup.string().required(),
  divergences: yup.mixed().when('isApproved', {
    is: 'no',
    then: yup.string().required()
  }),
  approvedValueInCents: yup.mixed().when('isApproved', {
    is: 'yes',
    then:  yup.number().required().transform((value, originalValue) => Math.ceil(transformStringToNumber(originalValue) * 100)),
  }),
  hasContractAdditive: yup.string().required(),
  hasUnforeseenCharge: yup.string().required(),
  unforeseenChargeChecklist: yup.mixed().when('hasUnforeseenCharge', {
    is: 'yes',
    then: yup.array().min(1),
  }),
  approvedReportAttachment: yup.mixed().when('isApproved', {
    is: 'yes',
    then: yup.object().required(),
  }),

})


const unforeseenChargeChecklist = [
  'Informado via comunicação interna para o comercial'
]

export function CheckInvoice({ invoiceId }: CheckInvoiceProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const formMethods = useForm<CheckInvoiceSchema>({
    resolver: yupResolver(checkInvoiceSchema),
  })

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = formMethods

  const [isApproved, hasContractAdditive, hasUnforeseenCharge] = useWatch({
    control,
    name: ['isApproved', 'hasContractAdditive', 'hasUnforeseenCharge']
  })

  const isOpenSupport = hasContractAdditive === 'yes'
  const supportRequested = searchParams.get('supportRequested')
  const hasSupportRequested = supportRequested === 'true'

  const {
    data: llmUsersData
  } = useLLMUsers({
    queryParams: {
      situation: 'ATIVO'
    }
  })

  const internalUsers = llmUsersData?.users?.filter((user) => !['MOTORISTA', 'CLIENTE', 'REMETENTE', 'COLETADOR'].includes(user.user_type)).sort((a, b) => `${a.firstname} ${a.lastname}`.localeCompare(
    `${b.firstname} ${b.lastname}`))

  const handleSetAttachmentId = (attachmentId: string) => {
    setValue(`approvedReportAttachment.attachmentId`, attachmentId);
  };

  const {
    format: currencyFormat, onChange: onCurrencyChange,
  } = useCurrency({
    style: 'decimal'
  })

  const {
    isOpen: isCreateSupportOpen,
    onOpen: onOpenCreateSupport,
    onClose: onCloseCreateSupport
  } = useDisclosure()

  const { mutateAsync: checkInvoiceFn } = useMutation({
    mutationFn: checkInvoice,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'invoices' })
      queryClient.invalidateQueries({ queryKey: ['invoice', invoiceId] })

      searchParams.delete('supportRequested')
      replace({ search: searchParams.toString() })
      reset()
    }
  })

  async function handleCheckInvoice(values: CheckInvoiceSchema, event: FormEvent) {
    event.preventDefault()
    event.stopPropagation()
    await promiseMessage(checkInvoiceFn({
      body: {
        isApproved: values.isApproved === 'yes',
        approvedReportAttachmentId: values.isApproved === 'yes' ? values.approvedReportAttachment.attachmentId : null,
        divergences: values.divergences,
        hasContractAdditive: values.hasContractAdditive === 'yes',
        hasUnforeseenCharge: values.hasUnforeseenCharge === 'yes',
        responsibleId: values.responsibleId,
        unforeseenChargeChecklist: values.unforeseenChargeChecklist,
        approvedValueInCents: values.approvedValueInCents
      },
      invoiceId
    }), 'Conferência de fatura realizada!')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleCheckInvoice)}
    >
      <Heading letterSpacing="tight" size='sm'>Conferência de Fatura</Heading>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.responsibleId}>
            <FormLabel fontSize="sm">
              Responsável
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              {...register('responsibleId')}
              name="responsibleId"
              placeholder="Selecione..."
              size="sm"
              rounded="md"
            >
              {internalUsers?.map((user) => {
                return (
                  <option key={user.id} value={user.id}>{captalize(`${user.firstname} ${user.lastname}`)}</option>
                )
              })}
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isApproved}>
            <FormLabel fontSize="sm">
              Aprovado
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              {...register('isApproved')}
              name="isApproved"
              placeholder="Selecione..."
              size="sm"
              rounded="md"
            >
              <option value='yes'>Sim</option>
              <option value='no'>Não</option>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      {isApproved === 'yes' && (
        <>
          <FormControl mt={3} isInvalid={!!errors.approvedReportAttachment}>
            <FormProvider {...formMethods}>
              <FormLabel fontSize='sm'>
                Anexe aqui o relatório final aprovado
              </FormLabel>
              <AttachmentTableRowField
                isFilledField={!!formMethods.watch(`approvedReportAttachment.attachmentId`)}
                onSetAttachmentId={(attachmentId) => handleSetAttachmentId(attachmentId)}
                title='Anexe aqui o relatório final aprovado'
                />
            </FormProvider>
          </FormControl><FormControl isInvalid={!!errors?.approvedValueInCents}>
            <FormLabel fontSize="sm">
              Valor aprovado
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="approvedValueInCents"
              control={control}
              render={({ field }) => {
                return (
                  <InputGroup
                    size="sm"
                  >
                    <InputLeftAddon
                      borderTopLeftRadius="md"
                      borderBottomLeftRadius="md"
                    >
                      R$
                    </InputLeftAddon>
                    <Input
                      {...register("approvedValueInCents")}
                      size="sm"
                      rounded="md"
                      w="full"
                      name={field.name}
                      defaultValue={currencyFormat('000')}
                      onChange={e => field.onChange(onCurrencyChange(e))} />
                  </InputGroup>
                );
              }} />
          </FormControl>
        </>
      )}
      {isApproved === 'no' && (
        <FormControl isInvalid={!!errors?.divergences} mt={3}>
          <FormLabel fontSize="sm">
            Reportar as divergências na fatura e de estoque (se for o caso)
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Textarea
            {...register('divergences')}
            name="divergences"
            size="sm"
            rounded="md"
          />
        </FormControl>
      )}
      <FormControl isInvalid={!!errors.hasContractAdditive} mt={3}>
        <FormLabel fontSize="sm">
          Existe alguma cobrança fora do previsto que vá gerar aditivo contratual?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register('hasContractAdditive')}
          name="hasContractAdditive"
          placeholder="Selecione..."
          size="sm"
          rounded="md"
        >
          <option value='yes'>Sim</option>
          <option value='no'>Não</option>
        </Select>
      </FormControl>
      {isOpenSupport && (
        <Flex mt={3} alignItems="baseline" justifyContent="space-between">
          <FormLabel fontSize='sm'>
            Abrir atendimento
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <IconButton
            aria-label="Abrir atendimento"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onOpenCreateSupport} />

        </Flex>
      )}
      <FormControl isInvalid={!!errors.hasUnforeseenCharge} mt={3}>
        <FormLabel fontSize="sm">
          Existe alguma cobrança fora de previsto que precisa informar ao comercial?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register('hasUnforeseenCharge')}
          name="hasUnforeseenCharge"
          placeholder="Selecione..."
          size="sm"
          rounded="md"
        >
          <option value='yes'>Sim</option>
          <option value='no'>Não</option>
        </Select>
      </FormControl>

      {hasUnforeseenCharge === 'yes' && (
        <FormControl mt={3} isInvalid={!!errors.unforeseenChargeChecklist}>
          <FormLabel fontSize='sm'>
            Comunicação
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            control={control}
            name="unforeseenChargeChecklist"
            render={({ field }) => {
              return (
                <CheckboxGroup
                  value={field.value}
                  onChange={field.onChange}
                >
                  <VStack
                    spacing={3}
                    p={2}
                    border="1px solid"
                    borderColor="gray.200"
                    rounded="md"
                    align="left"
                  >
                    {Object.entries(unforeseenChargeChecklist).map(([key, value]) => {
                      return (
                        <Checkbox key={key} value={value}>
                          <Text fontSize="sm">
                            {value}
                          </Text>
                        </Checkbox>

                      )
                    })}

                  </VStack>

                </CheckboxGroup>
              )
            }}
          />
        </FormControl>
      )}
      <Modal
        isOpen={isCreateSupportOpen}
        onClose={onCloseCreateSupport}
        isCentered
        size="2xl"
      >
        <ModalOverlay />
        <CreateSupport
          onClose={onCloseCreateSupport}
          required={true} />
      </Modal>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting || (isOpenSupport && !hasSupportRequested)}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
