import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Alert,
  AlertIcon,
  Link as ChakraLink,
  Icon,
  useMediaQuery,
  useDisclosure,
  AlertDescription,
  AlertTitle,
  Modal,
  ModalOverlay,
  Table,
  Tbody,
  Tr,
  Td,
  IconButton,
  Popover,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import { useState, useContext, useEffect, Fragment, useMemo, useReducer } from 'react'
import { useHistory, useParams } from 'react-router'
import { FiCheckSquare, FiExternalLink, FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { useQuery, useQueryClient } from "react-query";
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import swal from 'sweetalert'

import {
  deadline_delivery,
  modal_types,
  service_types,
  vehicle_types,
} from '../../../utils/customLists'
import { detailFormatDateToFront } from '../../../utils/ServiceFunctions/Detail/detailFormatDateToFront'
import { serviceFormatHourToFront } from '../../../utils/ServiceFunctions/serviceFormatHourToFront'
import { serviceFormatDateToFront } from '../../../utils/ServiceFunctions/serviceFormatDateToFront'
import { serviceHandleStep } from '../../../utils/ServiceFunctions/serviceDict'
import { handleChangeUrl } from '../../../utils/handleChangeUrl'


import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { Select } from '../../../components/Inputs/SelectInput'
import { TextArea } from '../../../components/Inputs/TextInput'
import { Input } from '../../../components/Inputs/Input'

import {
  RequestedServiceContext,
  RequestedServiceProps,
} from '../../../contexts/ServiceContext'
import { CollectedServiceProps } from '../../../contexts/CollectedServiceContext'
import { AllocatedServiceProps } from '../../../contexts/AllocateServiceContext'
import { DeliveryServiceProps } from '../../../contexts/DeliveryServiceContext'
import { AuthContext } from '../../../contexts/AuthContext'

import { useService } from '../../../hooks/services/service'
import { useDriver } from '../../../hooks/driver/useDriver'
import { LogContext } from '../../../contexts/LogContext'
import { LandingServiceProps } from '../../../services/getFunctions/getLandingServiceFunctions'
import { BoardServiceProps } from '../../../services/getFunctions/getBoardServiceFunctions'
import { AvailableServiceProps } from '../../../services/getFunctions/getAvailableServiceFunctions'

import { FaExternalLinkAlt, FaEye, FaFileInvoice, FaInfoCircle, FaTrash } from 'react-icons/fa'
import { AddCollectAddressModal } from '../../../components/Modal/Service/AddCollectAddress/AddCollectAddressModal'
import { GenerateContentDeclarationModal } from '../../../components/Modal/Service/ContentDeclaration/GenerateContentDeclaration'
import { useMinutaFunctions } from '../../../hooks/minuta/useMinutaFunctions'
import { formatDate } from '../../../utils/DateFunctions/formatDate'
import { useInput } from '../../../hooks/input/useInput'
import { InputProps } from '../../../contexts/InputContext'
import { GenerateWithdrawAuthorizationModal } from '../../../components/Modal/Service/WithdrawAuthorization/GenerateWithdrawAuthorizationModal'
import { useAddresses } from '../../../hooks/address/useAddresses'
import { Address } from 'hooks/address/dtos/Address'
import { differenceInHours, getDay, set, sub, isBefore, isSameDay, isAfter, subHours, format } from 'date-fns'
import { ServiceInternComunicationModal } from './components/Modal/ServiceInternComunicationModal'
import { RequestCancelServiceModal } from 'components/Modal/Service/RequestCancelServiceModal'
import { RequestDeliveryAddressesModal } from './components/Modal/RequestDeliveryAddressesModal'
import { ServiceOpenedInternComunicationsModal } from './components/Modal/ServiceOpenedInternComunicationsModal'
import { CancelServiceModal } from 'components/Modal/Service/CancelServiceModal'
import { useOccurrenceFunctions } from 'hooks/occurrences/useOccurrenceFunctions'
import { useSwal } from 'hooks/swal/useSwal'
import { useToastify } from 'hooks/toastify/useToastify'

import { BoardServiceAuthorizationModal } from './components/Modal/BoardServiceAuthorizationModal'
import { useCollectServiceFunctions } from 'hooks/collectService/useCollectServiceFunctions'
import { useRequestServiceFunctions } from 'hooks/services/request/useRequestServiceFunctions'
import { useServiceSocket } from 'hooks/socket/useServiceSocket'
import { useServiceFunctions } from 'hooks/services/useServiceFunctions'
import { positiveNegativeOptions } from 'utils/CustomLists/positiveNegativeOptions'
import { Radio } from 'components/Inputs/RadioInput'
import { DetailsOccurrenceModal } from './components/Modal/DetailsOccurrenceModal'
import { OccurrenceProps } from 'contexts/OccurrenceContext'
import { useCity } from 'hooks/city/useCity'
import { getServiceTotalCost } from 'api/service/getServiceTotalCost'
import { TotalPriceServiceModal } from './components/Modal/TotalPriceServiceModal'
import { GenerateServiceLabels } from './components/Modal/GenerateServiceLabels'
import { useHub } from 'hooks/hub/useHub'
import { getTimezoneNameByValue } from 'utils/getTimezoneNameByValue'
import { RequestStepChangeModal } from './components/Modal/RequestStepChangeModal'
import { captalize } from 'utils/captalize'
import { getServiceCosts } from 'api/service/getServiceCosts'
import { ServiceCosts } from './components/Modal/ServiceCosts'
import { RequestedServiceLogs } from './components/RequestedServiceLogs'

interface FormInputProps {
  protocol: string
  customer: string
  service_type: string
  vehicle: string
  collect_date: string
  delivery_date: string
  step: string
}

interface QueryParams {
  service_id: string
}

interface IInputsToShowProps {
  inputName: string
  quantity: number
}

interface IOccurrenceMaterialProps {
  input_id: string
  quantity: number
}

interface IDestinationAddressInputProps {
  address_id: string
  quantity: number
}

function setInputsToShow(
  inputs: InputProps[],
  materials: IOccurrenceMaterialProps[],
): IInputsToShowProps[] {
  const inputsToShow = materials?.reduce((acc, curr) => {
    inputs.map((input) => {
      if (input.id === curr.input_id) {
        return acc.push({ inputName: input.name, quantity: curr.quantity })
      }
      return []
    })
    return acc
  }, [] as IInputsToShowProps[])

  return inputsToShow
}

function compareDifferenceInDaysBetweenCollectDateAndDateNow(
  collectDate?: Date | string
) {
  if (collectDate) {
    const [
      collectDay,
      collectMonth,
      collectYear
    ] = formatDate
      .handle(collectDate, "DateWithoutHourToShow")
      .split("/")
      .map(dataParam => Number(dataParam))

    const collectDateWithCurrentHours = set(new Date(), {
      date: collectDay,
      month: collectMonth - 1,
      year: collectYear
    })

    const difference = Math.ceil(differenceInHours(
      collectDateWithCurrentHours,
      new Date()
    ) / 24) | 0

    return difference <= 0
  }

  return false
}

interface ActionPayload {
  occurrence: OccurrenceProps
}

interface Action {
  payload: ActionPayload
}

function reducer(occurrenceState: ActionPayload, action: Action) {
  return {
    occurrence: action.payload.occurrence
  }
}

const hubSchema = yup.object().shape({
  protocol: yup.string().required('Campo Obrigatório'),
})

export function DetailService() {
  const { service_id }: QueryParams = useParams()
  const history = useHistory()

  const { serviceSocketConnection } = useServiceSocket()
  const queryClient = useQueryClient()

  const [allocatedServiceFilteredById, setAllocatedServiceFilteredById] =
    useState<AllocatedServiceProps[]>([])
  const [availableServiceFilteredById, setAvailableServiceFilteredById] =
    useState<AvailableServiceProps[]>([])
  const [collectedServiceFilteredById, setCollectedServiceFilteredById] =
    useState<CollectedServiceProps[]>([])
  const [deliveryServiceFilteredById, setDeliveryServiceFilteredById] =
    useState<DeliveryServiceProps[]>([])
  const [reqServiceFilteredById, setReqServiceFilteredById] = useState<
    RequestedServiceProps[]
  >([])
  const [boardServiceFilteredById, setBoardServiceFilteredById] = useState<
    BoardServiceProps[]
  >([])
  const [landingServiceFilteredById, setLandingServiceFilteredById] = useState<
    LandingServiceProps[]
  >([])

  const {
    service: { data: serviceById, isLoading: isServiceLoading },
  } = useService(service_id, false)
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses({
    queryParams: { situation: 'ATIVO', customer_id: serviceById?.customer_id, },
    queryOptions: { enabled: !!serviceById }
  })
  const {
    drivers: { data: drivers, isLoading: isDriverLoading },
  } = useDriver(null, true, false)

  const {
    cities: { data: cities, isLoading: isCityLoading },
  } = useCity(null, true, false)

  const {
    inputs: { data: inputs, isLoading: isInputsLoading },
  } = useInput(null, true, false)

  const {
    hubs: { data: hubs, isFetching: isHubsFetching }
  } = useHub(null, true, false)

  const {
    cancelCollectServiceAddress: {
      mutateAsync: cancelCollectServiceAddress,
      isLoading: isCancelCollectAddressLoading,
    },

  } = useCollectServiceFunctions()


  const {
    cancelAddressToCollectService: {
      mutateAsync: cancelAddressToCollectService,
      isLoading: isRequestCancelCollectAddressLoading
    }
  } = useRequestServiceFunctions();

  const [occurrenceState, dispatch] = useReducer(reducer, {
    occurrence: {} as OccurrenceProps
  })

  async function handleCancelCollectServiceAddress(addressId: string) {
    const hasCancelCollectAddress = await confirmMessage({ title: "Deseja cancelar a coleta do endereço?" })

    if (hasCancelCollectAddress) {


      await cancelCollectServiceAddress({
        address_id: addressId,
        service_id: service_id
      }, {
        onSuccess: () => queryClient.invalidateQueries('service')
      })
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  async function handleRequestCancelAddressToCollect(addressId: string) {
    const hasCancelCollectAddress = await confirmMessage({
      title: "Deseja solicitar cancelamento da coleta do endereço?",
    });
    if (hasCancelCollectAddress) {
      await cancelAddressToCollectService({
        service_id: service_id,
        address_id: addressId
      }, {
        onSuccess: () => {
          serviceSocketConnection.emit('requestedCancelCollectAddress', {
            service_protocol: serviceById?.protocol
          })
        }
      });
    } else {
      standardMessage("Ação cancelada com êxito!");
    }
  }


  const serviceFilteredById = useMemo(() => {
    if (serviceById !== undefined) {
      const serviceFiltered = []
      serviceFiltered.push(serviceById)
      return serviceFiltered
    }
  }, [serviceById])

  useEffect(() => {
    if (serviceById !== undefined) {
      if (serviceById.serviceIDCollect !== null) {
        setCollectedServiceFilteredById(serviceById.serviceIDCollect)
      }
      if (serviceById.serviceIDAllocate !== null) {
        setAllocatedServiceFilteredById(serviceById.serviceIDAllocate)
      }
      if (serviceById.serviceIDBoard !== null) {
        setBoardServiceFilteredById(serviceById.serviceIDBoard)
      }
      if (serviceById.serviceIDAvailable !== null) {
        setAvailableServiceFilteredById(serviceById.serviceIDAvailable)
      }
      if (serviceById.serviceIDLanding !== null) {
        setLandingServiceFilteredById(serviceById.serviceIDLanding)
      }
      if (serviceById.serviceIDDelivery !== null) {
        setDeliveryServiceFilteredById(serviceById.serviceIDDelivery)
      }
      if (serviceById?.serviceIDRequested) {
        setReqServiceFilteredById([serviceById?.serviceIDRequested])
      }
    }
  }, [serviceById])

  const [isLoading, setIsLoading] = useState(true)
  const [minutaUrl, setMinutaUrl] = useState('')
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')


  const {
    generateMinuta: { mutateAsync: generateMinuta },
  } = useMinutaFunctions()
  const { deleteService } = useContext(
    RequestedServiceContext,
  )
  const { userLogged } = useContext(AuthContext)
  const { createLog } = useContext(LogContext)

  const [destinationAddressesFiltered, setDestinationAddressesFiltered] =
    useState<Address[]>([])

  const [sourceAddressesFiltered, setSourceAddressesFiltered] = useState<
    Address[]
  >([])

  const { handleSubmit } = useForm<FormInputProps>({
    resolver: yupResolver(hubSchema),
  })

  const { promiseMessage } = useToastify()
  const { confirmMessage, standardMessage } = useSwal()

  const userHasPermissionToCancelAddress = userLogged?.permissions.includes(
    "cancel-collect-address"
  );

  const userHasPermissionRequestToCancelAddress =
    userLogged?.permissions.includes("request-cancel-collect-address");

  const userCanEditServiceRequested = userLogged?.permissions.includes(
    'edit-service-requested'
  )
  const userCanEditServiceCollected = userLogged?.permissions.includes(
    'edit-service-collected'
  )

  const {
    deleteOccurrence: { mutateAsync: deleteOccurrence }
  } = useOccurrenceFunctions()

  const { auditServiceRequest: { mutateAsync: auditServiceRequest } } = useServiceFunctions()

  async function handleDeleteOccurrence(id: string) {
    const hasDeleteOccurrence = await confirmMessage({
      title: 'Deseja excluir a ocorrência?'
    })

    if (hasDeleteOccurrence) {
      await promiseMessage(
        deleteOccurrence(id),
        'Ocorrência excluída com sucesso!'
      )
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const {
    isOpen: isServiceOpenedInternComunicationModalOpen,
    onOpen: onOpenServiceOpenedInternComunicationModal,
    onClose: onCloseServiceOpenedInternComunicationModal,
  } = useDisclosure()

  const {
    isOpen: isRequestDeliveryAddressesModalOpen,
    onOpen: onOpenRequestDeliveryAddressesModal,
    onClose: onCloseRequestDeliveryAddressesModal,
  } = useDisclosure()

  const {
    isOpen: isRequestCancelServiceModalOpen,
    onOpen: onOpenRequestCancelServiceModal,
    onClose: onCloseRequestCancelServiceModal,
  } = useDisclosure()

  const {
    isOpen: isCancelServiceModalOpen,
    onOpen: onOpenCancelServiceModal,
    onClose: onCloseCancelServiceModal,
  } = useDisclosure()

  const {
    isOpen: isContentDeclarationOpen,
    onOpen: onOpenContentDeclaration,
    onClose: onCloseContentDelcaration,
  } = useDisclosure()

  const {
    isOpen: isGenerateAddressesOpen,
    onOpen: onOpenGenerateAddresses,
    onClose: onCloseGenerateAddresses,
  } = useDisclosure()

  const {
    isOpen: isGenerateLabelOpen,
    onOpen: onOpenGenerateLabel,
    onClose: onCloseGenerateLabel,
  } = useDisclosure()

  const {
    isOpen: isGenerateWithdrawAuthorizationOpen,
    onOpen: onOpenGenerateWithdrawAuthorization,
    onClose: onCloseGenerateWithdrawAuthorization,
  } = useDisclosure()

  const {
    isOpen: isBoardServiceAuthorizationOpen,
    onOpen: onOpenBoardServiceAuthorization,
    onClose: onCloseBoardServiceAuthorization
  } = useDisclosure()

  const {
    isOpen: isServiceInternComunicationModalOpen,
    onOpen: onOpenNewInternComunicationModal,
    onClose: onCloseServiceInternComunicationModal,
  } = useDisclosure()

  const {
    isOpen: isDetailsOcurrenceModalOpen,
    onOpen: onOpenDetailsOcurrenceModal,
    onClose: onCloseDetailsOcurrenceModal,
  } = useDisclosure()


  const {
    isOpen: isRequestStepChangeModalOpen,
    onOpen: onOpenRequestStepChangeModal,
    onClose: onCloseRequestStepChangeModal,
  } = useDisclosure()


  function handleDispatchDetailsOccurrenceModal(occurrence: OccurrenceProps) {
    dispatch({
      payload: {
        occurrence
      }
    })

    onOpenDetailsOcurrenceModal()
  }

  const serviceStep = serviceFilteredById
    ?.map((service) => service.step)
    .toString()
  const colServiceLength = collectedServiceFilteredById.length
  const delServiceLength = deliveryServiceFilteredById.length
  const minutasShippingCompanyNames = [
    'SOL CARGAS',
    'AZUL CARGO',
    'BRASIL SUL',
    'BUSLOG',
    'GOLLOG',
    'JEM',
    'CEU',
    'EUCATUR',
    'RM LOG',
    'GARCIA ENCOMENDAS',
    'AGUIA BRANCA ENCOMENDAS',
    'VIAÇAO SÃO CRISTOVÃO',
  ]

  const sourceBranch = serviceById?.serviceIDRequested.sourceBranchIDService

  const stepsNotAllowedToGenerateWithdrawAuthorization = [
    "toValidateRequestedService",
    "cancelledService",
    "unsuccessService",
    "toCollectService",
    "collectingService",
    "toBoardService",
    "boardingService"
  ]

  const crossdockingCollectorIdExists = serviceById?.serviceIDRequested.crossdocking_collector_id
  const destinationCollectorId = serviceById?.serviceIDRequested.destination_collector_id
  const serviceHasPendingSourceAddressesToValidate = serviceById
    ? serviceById.serviceIDRequested.additional_collect_addresses !== null
    : false


  const sourceDriversToBoardAuthorization = crossdockingCollectorIdExists
    ? serviceById.serviceIDBoard.find(board => board.collector_id === crossdockingCollectorIdExists)?.step === "DONE"
      ? drivers?.filter(driver => driver.collector_id === serviceById?.serviceIDRequested.source_collector_id)
      : drivers?.filter(driver => driver.collector_id === crossdockingCollectorIdExists)
    : drivers?.filter(driver => driver.collector_id === serviceById?.serviceIDRequested.source_collector_id)

  const destinationDriversToWithdraw = crossdockingCollectorIdExists
    ? serviceById.serviceIDBoard.find(board => board.collector_id === crossdockingCollectorIdExists)?.step === "DONE"
      ? drivers?.filter(driver => driver.collector_id === destinationCollectorId) ?? []
      : drivers?.filter(driver => driver.collector_id === crossdockingCollectorIdExists) ?? []
    : drivers?.filter(driver => driver.collector_id === destinationCollectorId) ?? []


  useEffect(() => {
    function run() {
      if (
        !isServiceLoading &&
        !isDriverLoading &&
        !isAddressesLoading &&
        !isInputsLoading &&
        !isCancelCollectAddressLoading &&
        !isRequestCancelCollectAddressLoading &&
        !isCityLoading &&
        !isHubsFetching
      ) {
        setIsLoading(false)
      }
    }
    run()
  }, [isAddressesLoading, isServiceLoading, isDriverLoading, isInputsLoading, isCancelCollectAddressLoading, isRequestCancelCollectAddressLoading, isCityLoading, isHubsFetching])

  const budgetSourceCities = useMemo(() => {
    return cities?.filter(city => {
      return serviceById?.serviceIDRequested?.budget_source_cities?.includes(city.id)
    })
  }, [cities, serviceById])

  const budgetSourceCitiesNames = budgetSourceCities?.map(city => {
    return city.name
  })

  const budgetDestinationCities = useMemo(() => {
    return cities?.filter(city => {
      return serviceById?.serviceIDRequested?.budget_destination_cities?.includes(city.id)
    })
  }, [cities, serviceById])

  const budgetDestinationCitiesNames = budgetDestinationCities?.map(city => {
    return city.name
  })

  const sourceAddressToAddModal = useMemo(() => {
    const serviceAlreadyHasAdditionalRequestedSourceAddresses =
      serviceById?.serviceIDRequested.additional_collect_addresses !== null &&
      serviceById?.serviceIDRequested.additional_collect_addresses.length

    const budgetSourceCitiesIds = serviceById?.serviceIDRequested.budgetIDService?.source_cities

    if (serviceAlreadyHasAdditionalRequestedSourceAddresses) {
      return addresses?.filter(address => {
        return budgetSourceCitiesIds?.includes(address.city_id) &&
          !serviceById?.serviceIDRequested.source_address_id.includes(address.id) &&
          !serviceById?.serviceIDRequested.additional_collect_addresses.includes(address.id)
      })
    }

    return addresses?.filter(address => {
      return budgetSourceCitiesIds?.includes(address.city_id) &&
        !serviceById?.serviceIDRequested.source_address_id.includes(address.id)
    })
  }, [addresses, serviceById])

  const sourceProvider = serviceById?.serviceIDRequested.providerHubIDService
  const dayCollectDateService = getDay(new Date(serviceById?.serviceIDRequested.collect_date))
  const dayDeliveryDateService = getDay(new Date(serviceById?.serviceIDRequested.delivery_date))

  useEffect(() => {
    function run() {
      if (addresses) {
        const sourceAddressesReqService = reqServiceFilteredById.flatMap(
          (reqService) => reqService.source_address_id,
        )
        const destinationAddressesReqService = reqServiceFilteredById.flatMap(
          (reqService) => reqService.destination_address_id,
        )
        const sourceAddress = addresses.filter((address) =>
          sourceAddressesReqService.includes(address.id),
        )
        const destinationAddress = addresses.filter((address) =>
          destinationAddressesReqService.includes(address.id),
        )
        setSourceAddressesFiltered(sourceAddress)
        setDestinationAddressesFiltered(destinationAddress)
      }
    }

    run()
  }, [reqServiceFilteredById, addresses])


  const currentDate = new Date();

  const cityWithMinOpeningAndLatestSchedule = sourceAddressesFiltered?.map((address) => address.cityIDAddress)?.reduce((acc, curr) => {
    const [
      scheduleDeadlineHours,
      scheduleDeadlineMinutes,
    ] = format(new Date(curr.schedule_deadline), 'HH:mm').split(':')


    const currDeadline = set(currentDate, {
      hours: Number(scheduleDeadlineHours),
      minutes: Number(scheduleDeadlineMinutes)
    })

    const collectDate = new Date(serviceById?.serviceIDRequested.collect_date)

    const currDeadlineWithCollectDate = set(collectDate, {
      hours: Number(scheduleDeadlineHours),
      minutes: Number(scheduleDeadlineMinutes)
    })

    const [
      collectStartHours,
      collectStartMinutes,
    ] = format(new Date(serviceById?.serviceIDRequested.collect_hour_start), 'HH:mm').split(':')


    const collectStartDateAndHours = set(collectDate, {
      hours: Number(collectStartHours),
      minutes: Number(collectStartMinutes)
    })

    const notEnableRequestCancelAddress = {
      minOpeningDeadline: Infinity,
      latestDeadline: null,
      obj: null
    }
    if (
      curr.opening_deadline < acc.minOpeningDeadline ||
      (curr.opening_deadline === acc.minOpeningDeadline && currDeadline > new Date(acc.latestDeadline))
    ) {

      if (
        curr.opening_deadline === 0 &&
        isSameDay(currentDate, collectDate) &&
        isAfter(currDeadlineWithCollectDate, collectStartDateAndHours)
      ) {
        const twoHoursBefore = isAfter(new Date(), subHours(collectStartDateAndHours, 2))

        if (twoHoursBefore) {
          return notEnableRequestCancelAddress
        } else {
          return {
            minOpeningDeadline: curr.opening_deadline,
            latestDeadline: curr.schedule_deadline,
            obj: curr,
          };
        }
      } else if (curr.opening_deadline > 0) {
        if (isBefore(new Date(), sub(collectStartDateAndHours, { days: curr.opening_deadline }))) {
          return {
            minOpeningDeadline: curr.opening_deadline,
            latestDeadline: curr.schedule_deadline,
            obj: curr,
          };
        }

        if (
          isSameDay(new Date(), sub(collectStartDateAndHours, { days: curr.opening_deadline })) &&
          isBefore(new Date(), currDeadline)
        ) {
          return {
            minOpeningDeadline: curr.opening_deadline,
            latestDeadline: curr.schedule_deadline,
            obj: curr,
          };
        }

        return notEnableRequestCancelAddress
      }
    }

    return acc
  }, { minOpeningDeadline: Infinity, latestDeadline: null, obj: null }).obj;

  function isTwoHoursBeforeCollectStart(collectDate: string) {
    const currentDate = new Date();

    const collectStartDate = new Date(collectDate);
    const [collectStartHours, collectStartMinutes] = format(collectStartDate, 'HH:mm').split(':');


    const collectStartDateAndHours = set(collectStartDate, {
      hours: Number(collectStartHours),
      minutes: Number(collectStartMinutes)
    });

    const twoHoursBeforeCollectStart = subHours(collectStartDateAndHours, 2);

    const isSameCollectDay = isSameDay(currentDate, collectStartDate);

    const isBeforeTwoHours = isBefore(currentDate, twoHoursBeforeCollectStart);

    const isBeforeCollectDate = isBefore(currentDate, collectStartDate);

    return (isSameCollectDay && isBeforeTwoHours) || isBeforeCollectDate;
  }

  const customerCanRequestToCancelAddress = userLogged?.user_type === 'CLIENTE'
    && cityWithMinOpeningAndLatestSchedule !== null
    && isTwoHoursBeforeCollectStart(serviceById?.serviceIDRequested.collect_date)

  async function handleDeleteService(serviceId: string) {
    await swal({
      title: 'Deseja excluir um Serviço?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willEdit) => {
      if (willEdit) {
        const serviceProtocol = serviceFilteredById
          ?.map((service) => service.protocol)
          .toString()
        const response = await deleteService(serviceId)
        if (response && !!userLogged) {
          createLog({
            user_id: userLogged.id,
            action: `EXCLUIR SERVIÇO - PROTOCOLO: ${serviceProtocol}`,
          })
          history.push('/servicos')
        }
      } else {
        swal('Ação cancelada com êxito!')
      }
    })
  }

  async function handleGenerateMinuta() {
    const minutaResponse = await generateMinuta(service_id)
    const bytes = new Uint8Array(minutaResponse)

    const url = URL.createObjectURL(
      new Blob([bytes], { type: 'application/pdf' }),
    )

    setMinutaUrl(url)
  }

  const budgetHubs: string[] = []

  if (serviceById?.serviceIDRequested) {
    if (serviceById?.serviceIDRequested?.budgetIDService.crossdocking_hub_id) {
      budgetHubs.push(
        serviceById?.serviceIDRequested?.budgetIDService.source_hub_id,
        serviceById?.serviceIDRequested?.budgetIDService.crossdocking_hub_id,
        serviceById?.serviceIDRequested?.budgetIDService.destination_hub_id
      )
    } else {
      budgetHubs.push(
        serviceById?.serviceIDRequested?.budgetIDService.source_hub_id,
        serviceById?.serviceIDRequested?.budgetIDService.destination_hub_id
      )
    }
  }

  const sourceHub = hubs?.find(hub => hub.id === serviceById?.serviceIDRequested?.budgetIDService.source_hub_id)

  const destinationHub = hubs?.find(hub => hub.id === serviceById?.serviceIDRequested?.budgetIDService.destination_hub_id)

  const crossdockingHub = hubs?.find(hub => hub.id === serviceById?.serviceIDRequested?.budgetIDService.crossdocking_hub_id)

  const timezoneSourceHub = getTimezoneNameByValue(sourceHub?.timezone)
  const timezoneDestinationHub = getTimezoneNameByValue(destinationHub?.timezone)
  const timezoneCrossdockingHub = getTimezoneNameByValue(crossdockingHub?.timezone)


  const userHasPermissionToRequestInternComunication = userLogged?.permissions.includes("create-intern-comunication")
  const userHasPermissionViewLogsService = userLogged?.permissions.includes("view-log")

  const userHasPermissionToAuditService = userLogged?.permissions.includes("audit-service")

  const isCollectDateEqualOrMinorThanNow = compareDifferenceInDaysBetweenCollectDateAndDateNow(
    serviceById?.serviceIDRequested.collect_date
  )

  const addressesWithOwnBoardOrLanding = addresses?.filter(address => {
    return address.has_own_board_landing === true
  })

  const availableDestinationAddressesToRequest = addresses?.filter(address => {
    const situationFilter = address.situation === 'ATIVO'
    const customerFilter = address.customer_id === serviceById?.customer_id
    const citiesFilter = serviceById?.serviceIDRequested.budgetIDService.destination_cities.includes(address.city_id)
    const differentFromServiceDestinationAddressesFilter = !serviceById?.serviceIDRequested.destination_address_id.includes(address.id)
    const differentFromServiceRequestedDeliveryAddresses = !serviceById?.serviceIDRequested.additional_delivery_addresses.includes(address.id)

    return situationFilter && customerFilter && citiesFilter && differentFromServiceDestinationAddressesFilter && differentFromServiceRequestedDeliveryAddresses
  })

  const serviceIsEnabledToRequestNewAddresses = [
    'toCollectService',
    'collectingService',
    'toValidateCollectAddresses',
    'toValidateAdditionalCollectAddresses',
  ].includes(serviceById?.step)

  const serviceIsEnabledToRequestNewCollectAddresses = (serviceById?.serviceIDRequested?.budgetIDService.source_address_qty > serviceById?.serviceIDRequested.source_address_id.length) || serviceById?.serviceIDRequested?.budgetIDService.price_add_collect !== 0

  const serviceIsEnabledToRequestNewDeliveryAddresses = (serviceById?.serviceIDRequested?.budgetIDService.destination_address_qty > serviceById?.serviceIDRequested.source_address_id.length) || serviceById?.serviceIDRequested?.budgetIDService.price_add_delivery !== 0

  async function handleAuditService(serviceId: string) {
    const hasAuditService = await confirmMessage({
      title: 'Deseja auditar o serviço?'
    })

    if (hasAuditService) {
      await promiseMessage(
        auditServiceRequest(serviceId),
        'Serviço auditado com sucesso!'
      )
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const {
    data: serviceTotalCostData
  } = useQuery(['serviceTotalCost'], () => getServiceTotalCost({
    serviceId: service_id,
  }), {
    enabled: !!service_id,
    cacheTime: 0
  })

  const {
    data: serviceCostsResult
  } = useQuery(['service-costs'], () => getServiceCosts({
    serviceId: service_id,
  }), {
    enabled: !!service_id,
    cacheTime: 0
  })

  const totalCost = !serviceTotalCostData
    ? 'R$0,00'
    : Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(serviceTotalCostData.total)

  const totalServiceCost = !serviceCostsResult
    ? 'R$0,00'
    : Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format((serviceCostsResult.collectsCost + serviceCostsResult.deliveriesCost + serviceCostsResult.extrasDiscountsCost + serviceCostsResult.materialsTotalCost + serviceCostsResult.transferCost))


  const userCanRequestStepChange = userLogged?.permissions.includes('request-service-step-change')

  const serviceAvailableForStepChangeRequests = (
    serviceById?.serviceIDRequested?.service_type === 'FRACIONADO'
    && serviceById.step === 'toBoardService') ||
    (serviceById?.serviceIDRequested?.service_type === 'DEDICADO'
      && serviceById?.step === 'toDeliveryService'
    )

  const {
    isOpen: isRequestedServiceLogsModalOpen,
    onOpen: onOpenRequestedServiceLogsModal,
    onClose: onCloseRequestedServiceLogsModal
  } = useDisclosure()

  const doesUserCanViewRequestedServiceLogs = userLogged?.permissions?.includes('view-requested-service-logs')

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (
    <>
      <Modal
        size="6xl"
        isOpen={isRequestedServiceLogsModalOpen}
        onClose={onCloseRequestedServiceLogsModal}
        isCentered
      >
        <ModalOverlay />

        <RequestedServiceLogs requestedServiceId={serviceById?.serviceIDRequested?.id} />
      </Modal>

      {service_id && (
        <AddCollectAddressModal
          isModalOpen={isGenerateAddressesOpen}
          onClose={onCloseGenerateAddresses}
          collectAddressesToAdd={sourceAddressToAddModal}
          serviceId={service_id}
          serviceProtocol={serviceById.protocol}
        />
      )}

      <ServiceOpenedInternComunicationsModal
        isOpen={isServiceOpenedInternComunicationModalOpen}
        onClose={onCloseServiceOpenedInternComunicationModal}
        serviceId={serviceById.id}
      />

      <RequestDeliveryAddressesModal
        isOpen={isRequestDeliveryAddressesModalOpen}
        onClose={onCloseRequestDeliveryAddressesModal}
        requestedServiceId={serviceById?.serviceIDRequested.id}
        deliveryAddresses={availableDestinationAddressesToRequest}
      />

      <GenerateContentDeclarationModal
        isModalOpen={isContentDeclarationOpen}
        onClose={onCloseContentDelcaration}
        sourceAddresses={sourceAddressesFiltered}
        destinationAddresses={destinationAddressesFiltered}
        addressesWithOwnBoardOrLanding={addressesWithOwnBoardOrLanding}
      />


      <GenerateWithdrawAuthorizationModal
        destinationDrivers={destinationDriversToWithdraw}
        isModalOpen={isGenerateWithdrawAuthorizationOpen}
        onClose={onCloseGenerateWithdrawAuthorization}
        serviceId={serviceById?.id!}
        serviceProtocol={serviceById?.protocol!}
      />
      <BoardServiceAuthorizationModal
        isOpen={isBoardServiceAuthorizationOpen}
        onClose={onCloseBoardServiceAuthorization}
        serviceId={service_id}
        drivers={sourceDriversToBoardAuthorization}
        shipping={sourceBranch?.shippingIDBranch}
      />

      <ServiceInternComunicationModal
        isOpen={isServiceInternComunicationModalOpen}
        onClose={onCloseServiceInternComunicationModal}
        budgetHubs={budgetHubs}
        serviceId={service_id}
      />
      <DetailsOccurrenceModal
        isOpen={isDetailsOcurrenceModalOpen}
        onClose={onCloseDetailsOcurrenceModal}
        occurrence={occurrenceState.occurrence}
        inputs={inputs}
      />

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(() => { })}
          noValidate
        >
          <Flex
            align="center"
            justify="space-between"
            direction={['column', 'column', 'row']}
            gap={6}
          >
            <Heading size="lg" fontFamily="poppins">
              Detalhes do Serviço
            </Heading>
            <Flex gap={6} direction={['column', 'column', 'row']}>
              {userHasPermissionToAuditService && (
                serviceById?.audited_at ? (
                  <Button
                    colorScheme="green"
                    leftIcon={<Icon as={FiCheckSquare} />}
                    size="sm"
                  >
                    Auditado
                  </Button>
                ) : (
                  <Button
                    colorScheme="gray"
                    onClick={() => handleAuditService(service_id)}
                    size="sm"
                  >
                    Auditar serviço
                  </Button>
                ))}

              {doesUserCanViewRequestedServiceLogs && (
                <Button
                  onClick={onOpenRequestedServiceLogsModal}
                  size="sm"
                >
                  Logs de edição
                </Button>
              )}
              {userHasPermissionViewLogsService && (
                <Link
                  to={{
                    pathname: '/logs/filtrar',
                    state: {
                      protocol: serviceById?.protocol,
                    },
                  }}>

                  <Button
                    colorScheme="gray"
                    size="sm"
                  >
                    Logs
                  </Button>
                </Link>
              )}
              {userHasPermissionToRequestInternComunication && (
                <Button
                  colorScheme="gray"
                  rightIcon={<Icon as={FiPlus} />}
                  onClick={onOpenNewInternComunicationModal}
                  size="sm"
                >
                  Nova comunicação interna
                </Button>
              )}
              {serviceStep !== 'toValidateRequestedService' && (
                <Link
                  to={{
                    pathname: '/ocorrencia/criar',
                    state: {
                      service_id,
                    },
                  }}
                >
                  <Button
                    colorScheme="gray"
                    rightIcon={<Icon as={FiPlus} />}
                    size="sm"
                    w="full"
                  >
                    Adicionar Ocorrência
                  </Button>

                </Link>
              )}
            </Flex>
          </Flex>

          <Divider my="6" borderColor="gray.700" />
          {serviceById?.serviceIDRequested.budgetIDService.priority_budget && (
            <Alert status='warning' my='4'>
              <AlertIcon />
              <AlertTitle>Orçamento Prioritário!</AlertTitle>
              <AlertDescription>O serviço possui orçamento prioritário.</AlertDescription>
            </Alert>
          )}
          {serviceFilteredById &&
            serviceFilteredById?.map((service) => (
              <Fragment key={service.id}>
                <Stack
                  spacing="24px"
                  mt="4"
                  direction={['column', 'column', 'row']}
                >
                  <Stack
                    w="100%"
                    spacing="24px"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      isDisabled={true}
                      value={service.protocol}
                      name="protocol"
                      label="Protocolo"
                    />

                    <Input
                      isDisabled={true}
                      value={service.customerIDService.trading_firstname}
                      name="customer"
                      label="Pertence ao Cliente"
                    />
                  </Stack>



                  <Stack
                    w="100%"
                    spacing="24px"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      isDisabled={true}
                      value={service.serviceIDRequested.service_type}
                      name="service_type"
                      label="Tipo de Serviço"
                    />

                    <Input
                      isDisabled={true}
                      value={service.serviceIDRequested.vehicle}
                      name="vehicle"
                      label="Veículo"
                    />
                  </Stack>
                </Stack>

                {userLogged?.loglife_employee && (
                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      name="totalCost"
                      value={totalCost}
                      label="Preço total do serviço"
                      isDisabled
                      children={
                        <Popover placement='auto' >
                          <PopoverTrigger>
                            <Button ml="4" size='lg'>
                              <Icon as={FaInfoCircle} color="blue.900" />
                            </Button>
                          </PopoverTrigger>
                          <Portal>
                            <TotalPriceServiceModal
                              serviceTotalCostData={serviceTotalCostData}
                            />
                          </Portal>

                        </Popover>

                      }
                    />
                    <Input
                      value={totalServiceCost}
                      name="totalServiceCost"
                      label="Custos do Serviço"
                      isDisabled
                      children={
                        <Popover placement='auto'>
                          <PopoverTrigger>
                            <Button ml="4" size='lg'>
                              <Icon as={FaInfoCircle} color="blue.900" />
                            </Button>
                          </PopoverTrigger>
                          <Portal>
                            <ServiceCosts
                              serviceCosts={serviceCostsResult}
                            />
                          </Portal>

                        </Popover>
                      }
                    />
                  </Stack>
                )}

                <Stack
                  spacing="24px"
                  mt="4"
                  direction={['column', 'column', 'row']}
                >
                  <Stack
                    w="full"
                    spacing="24px"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      isDisabled={true}
                      value={serviceFormatDateToFront(
                        service.serviceIDRequested.collect_date,
                      )}
                      name="collect_date"
                      type="date"
                      label="Data da Coleta"
                    />

                    <Input
                      isDisabled={true}
                      value={serviceFormatDateToFront(
                        service.serviceIDRequested.delivery_date,
                      )}
                      name="delivery_date"
                      type="date"
                      label="Data da Entrega"
                    />
                  </Stack>

                  <Stack
                    w="full"
                    spacing="24px"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      isDisabled={true}
                      value={
                        service.serviceIDRequested.crossdocking_collector_id
                          ? 'SIM'
                          : 'NÃO'
                      }
                      name="has_crossdocking"
                      label="Possui Crossdocking?"
                    />

                    <Input
                      name="cte_loglife"
                      value={
                        service.cte_loglife !== null
                          ? service.cte_loglife
                          : '-'
                      }
                      label="CTE Loglife"
                      isDisabled={true}
                    />
                  </Stack>
                </Stack>
                {hubs !== undefined && (

                  <Stack
                    w="full"
                    spacing="24px"
                    direction={['column', 'column', 'row']}
                    mt="4"
                  >
                    {timezoneSourceHub && (
                      <Input
                        name="timezone_source_hub"
                        value={timezoneSourceHub}
                        label="Fuso Hub de Origem"
                        isDisabled={true}
                      />
                    )}
                    {timezoneDestinationHub && (
                      <Input
                        name="timezone_destination_hub"
                        value={timezoneDestinationHub}
                        label="Fuso Hub de Destino"
                        isDisabled={true}
                      />
                    )}
                    {timezoneCrossdockingHub && (
                      <Input
                        name="timezone_crossdocking_hub"
                        value={timezoneCrossdockingHub}
                        label="Fuso Hub Crossdocking"
                        isDisabled={true}
                      />
                    )}

                  </Stack>
                )}
                <Stack
                  spacing="24px"
                  mt="4"
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    isDisabled={true}
                    value={serviceById?.serviceIDRequested?.material_type}
                    name="step"
                    label="Tipo de Material"
                  />
                </Stack>
                <Stack
                  spacing="24px"
                  mt="4"
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    isDisabled={true}
                    value={serviceHandleStep(service.step)}
                    name="step"
                    label="Etapa"
                  />
                </Stack>
              </Fragment>
            ))}

          {(serviceFilteredById && serviceStep !== '') &&
            reqServiceFilteredById?.map((reqService) => {
              const observationsService = [
                reqService.observation.trim(),
                ...sourceAddressesFiltered.map((address) => {
                  const addressObservations = serviceById?.serviceIDRequested.requested_collect_addresses_observations
                    ? typeof serviceById?.serviceIDRequested.requested_collect_addresses_observations === 'string'
                      ? JSON.parse(serviceById?.serviceIDRequested.requested_collect_addresses_observations as string).find(
                        (addressObservation) => addressObservation.address_id === address.id
                      )?.observation
                      : serviceById?.serviceIDRequested.requested_collect_addresses_observations.find(
                        (addressObservation) => addressObservation.address_id === address.id
                      )?.observation
                    : null;


                  return addressObservations ? captalize(`${address.trading_name || address.branch} - ${addressObservations.trim()}`) : null;
                }).filter(Boolean),
                ...serviceById?.occurrenceIDService
                  ?.filter(occurrence => occurrence.intercurrence === "ALTERAÇÃO DE INSUMOS UTILIZADOS")
                  .map((occurrence) => {
                    return occurrence.observation ? `Ocorrência ${occurrence.occurrence_protocol} - ${occurrence.observation.trim()}` : null;
                  }).filter(Boolean)
              ];

              const formattedObservations = observationsService
                .filter(Boolean)
                .join('\n');

              return (
                <Fragment key={reqService.id}>
                  <Accordion mt="4" allowMultiple>
                    <AccordionItem>
                      <h2>
                        <AccordionButton p="4">
                          <Box flex="1" textAlign="left">
                            Informações de agendamento do serviço
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Stack
                          spacing="24px"
                          mt="4"
                          direction={['column', 'column', 'row']}
                          alignItems="end"
                        >
                          <Input
                            isDisabled={true}
                            value={`${budgetSourceCitiesNames.join(
                              ', ',
                            )} > ${budgetDestinationCitiesNames.join(', ')} | ${reqService.service_type
                              } | ${reqService.vehicle} | ${reqService.modal} | ${reqService.franchising
                              }KG | GELO SECO: ${reqService.budget_gelo_seco} | GELOX: ${reqService.budget_gelox
                              }
                        `.toUpperCase()}
                            name="budget"
                            label="Orçamento"
                          />
                          <Button
                            as={Link}
                            to={`/orcamento/visualizar/${reqService.budget_id}`}
                            size='lg'
                            p={6}
                          >
                            <Icon as={FaEye} />
                          </Button>
                        </Stack>
                        {serviceStep !== 'toValidateRequestedService' &&
                          serviceStep !== '' &&
                          userLogged?.user_type !== 'CLIENTE' && (
                            <>
                              {serviceStep !== 'cancelledService' && (
                                <Stack
                                  spacing="24px"
                                  mt="4"
                                  direction={['column', 'column', 'row']}
                                >
                                  {reqService.source_collector_id && (
                                    <Input
                                      isDisabled={true}
                                      value={
                                        reqService.sourceCollectorIDService
                                          .trading_name
                                      }
                                      name="collector_trading_name"
                                      label="Coletador Origem"
                                    />
                                  )}

                                  {reqService.destination_collector_id && (
                                    <Input
                                      isDisabled={true}
                                      value={
                                        reqService.destinationCollectorIDService
                                          .trading_name
                                      }
                                      name="collector_trading_name"
                                      label="Coletador Destino"
                                    />
                                  )}
                                </Stack>
                              )}
                              {reqService.crossdocking_collector_id &&
                                serviceStep !== 'cancelledService' && (
                                  <Stack
                                    spacing="24px"
                                    mt="4"
                                    direction={['column', 'column', 'row']}
                                  >
                                    <Input
                                      isDisabled={true}
                                      value={
                                        reqService
                                          .crossdockingCollectorIDService
                                          .trading_name
                                      }
                                      name="crossdocking_collector_trading_name"
                                      label="Coletador Crossdocking"
                                    />
                                  </Stack>
                                )}
                              {reqService.service_type === 'FRACIONADO' &&
                                serviceStep !== 'cancelledService' && (
                                  <Stack
                                    spacing="24px"
                                    mt="4"
                                    direction={['column', 'column', 'row']}
                                  >
                                    <Input
                                      name="shipping"
                                      value={
                                        sourceBranch
                                          ? `${sourceBranch.shippingIDBranch.company_name} - ${sourceBranch.shippingIDBranch.trading_name}`
                                          : '-'
                                      }
                                      label="Transportadora"
                                      isDisabled={true}
                                    />
                                  </Stack>
                                )}
                            </>
                          )}
                        <Stack
                          spacing="24px"
                          mt="4"
                          direction={['column', 'column', 'row']}
                        >
                          <Select
                            name="deadline"
                            value={reqService.deadline}
                            label="Prazo de entrega"
                            deadline_delivery={deadline_delivery}
                            placeholder="Selecione uma opção..."
                            isDisabled={true}
                          />

                          <Select
                            name="service_type"
                            value={reqService.service_type}
                            label="Tipo de serviço"
                            service_types={service_types}
                            placeholder="Selecione uma opção..."
                            isDisabled={true}
                          />
                        </Stack>
                        {serviceStep !== 'cancelledService' && (
                          <Stack
                            spacing="24px"
                            mt="4"
                            direction={['column', 'column', 'row']}
                          >
                            <Input
                              isDisabled={true}
                              value={reqService.planned_flight}
                              name="planned_flight"
                              label="Viagem Planejada"
                            />
                            {reqService.crossdocking_planned_flight && (
                              <Input
                                isDisabled={true}
                                value={reqService.crossdocking_planned_flight}
                                name="crossdocking_planned_flight"
                                label="Viagem Planejada Crossdocking"
                              />
                            )}
                          </Stack>
                        )}
                        <Stack
                          spacing="24px"
                          mt="4"
                          direction={['column', 'column', 'row']}
                        >
                          <Stack
                            w="100%"
                            spacing="24px"
                            direction={['column', 'column', 'row']}
                          >
                            <Select
                              name="modal"
                              label="Modal"
                              value={reqService.modal}
                              modal_types={modal_types}
                              placeholder="Selecione uma opção..."
                              isDisabled={true}
                            />
                            <Select
                              name="vehicle"
                              value={reqService.vehicle}
                              label="Veículo"
                              vehicle_types={vehicle_types}
                              placeholder="Selecione uma opção..."
                              isDisabled={true}
                            />
                          </Stack>

                          <Input
                            name="franchising"
                            value={reqService.franchising}
                            label="Franquia"
                            addChildren="KG"
                            type="number"
                            isDisabled={true}
                          />
                        </Stack>

                        <Stack
                          spacing="24px"
                          mt="4"
                          direction={['column', 'column', 'row']}
                        >
                          <Input
                            name="caixa_termica"
                            value={reqService.caixa_termica}
                            label="Caixa Térmica"
                            type="number"
                            isDisabled={true}
                          />
                          <Input
                            name="embalagem_secundaria"
                            value={reqService.embalagem_secundaria}
                            label="Embalagem secundária"
                            type="number"
                            isDisabled={true}
                          />
                          <Input
                            name="gelo_seco"
                            value={reqService.gelo_seco}
                            label="Gelo Seco"
                            type="number"
                            isDisabled={true}
                          />

                          <Input
                            name="gelox"
                            value={reqService.gelox}
                            label="Gelox"
                            type="number"
                            isDisabled={true}
                          />
                        </Stack>

                        <Stack
                          spacing="24px"
                          mt="4"
                          direction={['column', 'column', 'row']}
                        >
                          <Input
                            name="isopor3l"
                            value={reqService.isopor3l}
                            label="Isopor 3L"
                            type="number"
                            isDisabled={true}
                          />
                          <Input
                            name="isopor7l"
                            value={reqService.isopor7l}
                            label="Isopor 7L"
                            type="number"
                            isDisabled={true}
                          />
                          <Input
                            name="terciaria3l"
                            value={reqService.terciaria3l}
                            label="Terciária 3L"
                            type="number"
                            isDisabled={true}
                          />

                          <Input
                            name="terciaria8l"
                            value={reqService.terciaria8l}
                            label="Terciária 8L"
                            type="number"
                            isDisabled={true}
                          />
                        </Stack>

                        <Stack
                          spacing="24px"
                          mt="4"
                          direction={['column', 'column', 'row']}
                        >
                        </Stack>

                        <Stack
                          w="100%"
                          spacing="24px"
                          mt="4"
                          direction={['column', 'column', 'row']}
                        >
                          <Stack
                            w="full"
                            spacing="24px"
                            direction={['column', 'column', 'row']}
                          >
                            <Input
                              isDisabled={true}
                              value={serviceFormatHourToFront(
                                reqService.collect_hour_start,
                              )}
                              name="collect_hour_start"
                              type="time"
                              label="Horário Inicial da Coleta"
                            />
                            <Input
                              isDisabled={true}
                              value={serviceFormatHourToFront(
                                reqService.collect_hour_end,
                              )}
                              name="collect_hour_end"
                              type="time"
                              label="Horário Final da Coleta"
                            />
                          </Stack>
                          <Stack
                            w="full"
                            spacing="24px"
                            direction={['column', 'column', 'row']}
                          >
                            <Input
                              isDisabled={true}
                              value={serviceFormatHourToFront(
                                reqService.delivery_hour,
                              )}
                              name="delivery_hour"
                              type="time"
                              label="Horário da Entrega"
                            />
                            <Input
                              isDisabled={true}
                              value={
                                reqService.board_hour !== null
                                  ? serviceFormatHourToFront(
                                    reqService.board_hour,
                                  )
                                  : '-'
                              }
                              name="board_hour"
                              type="time"
                              label="Horário Limite Embarque"
                            />
                          </Stack>
                        </Stack>

                        {reqService.attachments && reqService.attachments.map(attachment =>
                          <Stack w="full"
                            mt={4}
                            mb={4}
                            spacing="24px"
                            direction={['column', 'column', 'row']}>
                            <ChakraLink
                              fontSize="lg"
                              href={handleChangeUrl(attachment)}
                              isExternal
                            >
                              Anexo<Icon as={FiExternalLink} mx="2px" />
                            </ChakraLink>
                          </Stack>
                        )}

                        <TextArea
                          isDisabled={true}
                          value={formattedObservations}
                          name="observation"
                          label="Observações"
                        />
                      </AccordionPanel>
                    </AccordionItem>

                    <Divider my="6" borderColor="gray.700" />

                    <AccordionItem>
                      <h2>
                        <AccordionButton p="4">
                          <Box flex="1" textAlign="left">
                            Informações dos endereços envolvidos no serviço
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Heading size="md" mt="4" fontFamily="poppins">
                          Endereço(s) de Coleta
                        </Heading>
                        {sourceAddressesFiltered.map((address, i) => {
                          const addressIsEnabledToCancelOrRequestCancel = serviceById?.serviceIDCollect.find(addressCollect => addressCollect.id !== address.id)

                          const addressObservations = serviceById?.serviceIDRequested.requested_collect_addresses_observations
                            ? typeof serviceById?.serviceIDRequested.requested_collect_addresses_observations === 'string'
                              ? JSON.parse(serviceById?.serviceIDRequested.requested_collect_addresses_observations as string).find(
                                (addressObservation) => addressObservation.address_id === address.id
                              )?.observation
                              : serviceById?.serviceIDRequested.requested_collect_addresses_observations.find(
                                (addressObservation) => addressObservation.address_id === address.id
                              )?.observation
                            : null

                          return (
                            <Stack
                              key={address.id}
                              spacing="24px"
                              mt="4"
                              direction={['column', 'column', 'column']}
                            >
                              <Input
                                isDisabled={true}
                                value={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep} | ${address.responsible_name}
                      `.toUpperCase()}
                                name="source_address"
                                label="Endereço"
                              />

                              {addressObservations && (
                                <TextArea
                                  name="observations"
                                  defaultValue={addressObservations}
                                  label="Observações"
                                  isDisabled={true}
                                />
                              )}

                              {!addressIsEnabledToCancelOrRequestCancel && (
                                <Stack
                                  direction={["column", "column", "row"]}
                                  spacing={6}
                                  w="full"
                                >
                                  {userHasPermissionToCancelAddress && (
                                    <Button
                                      variant="outline"
                                      colorScheme="red"
                                      onClick={() => handleCancelCollectServiceAddress(address.id)}
                                      w="full"
                                      mt={8}
                                      h="48px"
                                    >
                                      Cancelar endereço
                                    </Button>
                                  )}
                                  {
                                    (
                                      (userHasPermissionRequestToCancelAddress && userLogged?.user_type !== 'CLIENTE') ||
                                      (userHasPermissionRequestToCancelAddress && customerCanRequestToCancelAddress)
                                    ) && (
                                      <Button
                                        variant="outline"
                                        colorScheme="red"
                                        onClick={() => handleRequestCancelAddressToCollect(address?.id)}
                                        w="full"
                                        mt={8}
                                        h="48px"
                                      >
                                        Solicitar cancelamento de endereço
                                      </Button>
                                    )}
                                </Stack>
                              )}
                              <Stack
                                direction={["column", "column", "row"]}
                                spacing={6}
                                w="full"
                                alignItems="end"
                              >
                                {dayCollectDateService > 0 && dayCollectDateService < 6 && (
                                  <Input
                                    isDisabled={true}
                                    value={`${formatDate.handle(address.business_open, 'DateOnlyWithHourMinute')} - ${formatDate.handle(address.business_close, 'DateOnlyWithHourMinute')}`}
                                    name="business_operation"
                                    label="Horário de Funcionamento"
                                  />
                                )}
                                {dayCollectDateService === 0 && (

                                  <Input
                                    isDisabled={true}
                                    value={`${formatDate.handle(address.sunday_open, 'DateOnlyWithHourMinute')} - ${formatDate.handle(address.sunday_close, 'DateOnlyWithHourMinute')}`}
                                    name="sunday_operation"
                                    label="Horário de Funcionamento"
                                  />

                                )}
                                {dayCollectDateService === 6 && (
                                  <Input
                                    isDisabled={true}
                                    value={`${formatDate.handle(address.saturday_open, 'DateOnlyWithHourMinute')} - ${formatDate.handle(address.saturday_close, 'DateOnlyWithHourMinute')}`}
                                    name="saturday_operation"
                                    label="Horário de Funcionamento"
                                  />
                                )}
                                <Button
                                  as={Link}
                                  to={`/endereco/visualizar/${address.id}`}
                                  w="full"
                                  p={6}
                                >
                                  Visualizar endereço
                                </Button>
                              </Stack>
                            </Stack>
                          )
                        })}
                        {sourceProvider && (
                          <Stack
                            spacing="24px"
                            mt="4"
                            direction={['column', 'column', 'column']}
                          >
                            <Input
                              isDisabled={true}
                              value={`| ${sourceProvider.trading_name} | ${sourceProvider.street} | ${sourceProvider.number} | ${sourceProvider.neighborhood} | ${sourceProvider.city} | ${sourceProvider.cep} | ${sourceProvider.complement}`.toUpperCase()}
                              name="source_provider"
                              label="Endereço Fornecedor"
                            />
                            <Stack
                              direction={["column", "column", "row"]}
                              spacing={6}
                              w="full"
                              alignItems="end"
                            >
                              {dayCollectDateService > 0 && dayCollectDateService < 6 && (
                                <Input
                                  isDisabled={true}
                                  value={`Funcionamento de segunda a sexta | ${formatDate.handle(sourceProvider.business_open, 'DateOnlyWithHourMinute')} - ${formatDate.handle(sourceProvider.business_close, 'DateOnlyWithHourMinute')}`}
                                  name="business_operation"
                                  label="Horário de Funcionamento"
                                />
                              )}
                              {dayCollectDateService === 0 && (

                                <Input
                                  isDisabled={true}
                                  value={`Funcionamento aos domingos | ${formatDate.handle(sourceProvider.sunday_open, 'DateOnlyWithHourMinute')} - ${formatDate.handle(sourceProvider.sunday_close, 'DateOnlyWithHourMinute')}`}
                                  name="sunday_operation"
                                  label="Horário de Funcionamento"
                                />

                              )}
                              {dayCollectDateService === 6 && (
                                <Input
                                  isDisabled={true}
                                  value={`Funcionamento aos sábados | ${formatDate.handle(sourceProvider.saturday_open, 'DateOnlyWithHourMinute')} - ${formatDate.handle(sourceProvider.saturday_close, 'DateOnlyWithHourMinute')}`}
                                  name="saturday_operation"
                                  label="Horário de Funcionamento"
                                />
                              )}
                              <Button
                                as={Link}
                                to={`/fornecedor/visualizar/${sourceProvider?.id}`}
                                w="full"
                                p={6}
                              >
                                Visualizar endereço
                              </Button>
                            </Stack>
                          </Stack>
                        )}
                        <Divider my="6" borderColor="gray.700" />

                        <Heading size="md" fontFamily="poppins">
                          Endereço(s) de Entrega
                        </Heading>
                        {destinationAddressesFiltered.map((address, i) => {
                          const hasDestinationAddressesInput = serviceById.serviceIDRequested.destination_addresses_input !== null

                          let destinationAddressInputQuantity = 0

                          if (hasDestinationAddressesInput) {

                            const destinationAddressesInput = serviceById.serviceIDRequested.destination_addresses_input as string[]

                            const destinationAddressInputQuantityInfo = destinationAddressesInput.map(destinationAddressInput =>
                              JSON.parse(destinationAddressInput) as IDestinationAddressInputProps)
                              .find(addressInput => addressInput.address_id === address.id)?.quantity

                            if (destinationAddressInputQuantityInfo) {
                              destinationAddressInputQuantity = destinationAddressInputQuantityInfo
                            }

                          }
                          return (
                            <Stack
                              key={address.id}
                              spacing="24px"
                              mt="4"
                              direction={['column', 'column', 'column']}
                            >
                              <Input
                                isDisabled={true}
                                value={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep} | ${address.complement}
                                  `.toUpperCase()}
                                name="step"
                                label="Endereço"
                              />
                              <Input
                                isDisabled={true}
                                value={destinationAddressInputQuantity}
                                name="destinationAddressInput"
                                label="Quantidade de Caixas"
                              />
                              <Stack
                                direction={["column", "column", "row"]}
                                spacing={6}
                                w="full"
                                alignItems="end"
                              >
                                {dayDeliveryDateService > 0 && dayDeliveryDateService < 6 && (
                                  <Input
                                    isDisabled={true}
                                    value={`${formatDate.handle(address.business_open, 'DateOnlyWithHourMinute')} - ${formatDate.handle(address.business_close, 'DateOnlyWithHourMinute')}`}
                                    name="business_operation"
                                    label="Horário de Funcionamento"
                                  />
                                )}
                                {dayDeliveryDateService === 0 && (

                                  <Input
                                    isDisabled={true}
                                    value={`${formatDate.handle(address.sunday_open, 'DateOnlyWithHourMinute')} - ${formatDate.handle(address.sunday_close, 'DateOnlyWithHourMinute')}`}
                                    name="sunday_operation"
                                    label="Horário de Funcionamento"
                                  />

                                )}
                                {dayDeliveryDateService === 6 && (
                                  <Input
                                    isDisabled={true}
                                    value={`${formatDate.handle(address.saturday_open, 'DateOnlyWithHourMinute')} - ${formatDate.handle(address.saturday_close, 'DateOnlyWithHourMinute')}`}
                                    name="saturday_operation"
                                    label="Horário de Funcionamento"
                                  />
                                )}
                                <Button
                                  as={Link}
                                  to={`/endereco/visualizar/${address.id}`}
                                  w="full"
                                  p={6}
                                >
                                  Visualizar endereço
                                </Button>
                              </Stack>
                            </Stack>
                          )
                        }
                        )}
                      </AccordionPanel>
                    </AccordionItem>

                    <Divider my="6" borderColor="gray.700" />

                    <AccordionItem>
                      <h2>
                        <AccordionButton p="4">
                          <Box flex="1" textAlign="left">
                            Informação dos endereços sem sucesso nas coletas
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        {serviceById?.serviceIDCollect.find(collect => {
                          return collect.step === 'UNSUCCESS'
                        }) ? (
                          serviceById?.serviceIDCollect
                            .filter(collect => {
                              return collect.step === 'UNSUCCESS'
                            })
                            .map(collect => {

                              const contentDeclarations = typeof collect.content_declarations === 'string' ? JSON.parse(collect.content_declarations) : collect.content_declarations

                              return (
                                <Flex key={collect.id} direction='column' gap="6">
                                  <Input
                                    name="responsible_name"
                                    label="Nome do responsável"
                                    defaultValue={`${collect?.responsible_name}`}
                                    isDisabled={true}
                                  />

                                  {collect.address_id && (
                                    <Input
                                      isDisabled={true}
                                      value={`${collect.addressIDCollect.trading_name} | ${collect.addressIDCollect.branch} | ${collect.addressIDCollect.street} | ${collect.addressIDCollect.number} | ${collect.addressIDCollect.neighborhood} | ${collect.addressIDCollect.cityIDAddress.name} | ${collect.addressIDCollect.cep}
                                `.toUpperCase()}
                                      name="address"
                                      label="Endereço"
                                    />
                                  )}

                                  {collect.provider_id && (
                                    <Input
                                      isDisabled={true}
                                      value={`${sourceProvider.company_name} | ${sourceProvider.trading_name} | ${sourceProvider.street} | ${sourceProvider.number} | ${sourceProvider.neighborhood} | ${sourceProvider.city} | ${sourceProvider.cep}
                         `.toUpperCase()}
                                      name="step"
                                      label="Endereço"
                                    />
                                  )}

                                  {collect.unsuccess_reason && (
                                    <Input
                                      name='unsuccess_reason'
                                      label='Motivo'
                                      value={collect.unsuccess_reason}
                                      isDisabled
                                    />
                                  )}

                                  {contentDeclarations && (
                                    <Table size="sm">
                                      <Tbody>
                                        {contentDeclarations.map((declaration) => {
                                          return (
                                            <Tr key={declaration.key}>
                                              <Td>{declaration.filename}</Td>
                                              <Td isNumeric>
                                                <IconButton
                                                  aria-label="Ver declaração"
                                                  as={ChakraLink}
                                                  colorScheme="blue"
                                                  size="sm"
                                                  icon={<FaExternalLinkAlt />}
                                                  href={handleChangeUrl(declaration.key)}
                                                  isExternal
                                                />
                                              </Td>
                                            </Tr>
                                          )
                                        })}
                                      </Tbody>
                                    </Table>
                                  )}
                                </Flex>
                              )
                            })
                        ) : (
                          <Alert status="info" mt="4" mb="4">
                            <AlertIcon />
                            SEM ENDEREÇOS SEM SUCESSO NAS COLETAS DESSE
                            SERVIÇO
                          </Alert>
                        )}

                      </AccordionPanel>
                    </AccordionItem>

                    <Divider my="6" borderColor="gray.700" />

                    {colServiceLength > 0 && (
                      <>
                        <AccordionItem>
                          <h2>
                            <AccordionButton p="4">
                              <Box flex="1" textAlign="left">
                                Informação de coletas do serviço
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {collectedServiceFilteredById
                              .filter(
                                (colService) => colService.step === 'DONE',
                              )
                              .map((colService) => {
                                const contentDeclarations = typeof colService.content_declarations === 'string' ? JSON.parse(colService.content_declarations) : colService.content_declarations

                                const boxPhotos = typeof colService.box_photos === 'string' ? JSON.parse(colService.box_photos) : colService.box_photos

                                return (

                                  <Fragment key={colService.id}>

                                    {serviceById?.collects_total_volumes && (
                                      <Input
                                        name="date_hour_service_start"
                                        value={serviceById?.collects_total_volumes}
                                        label="Volume total de coletas"
                                        isDisabled={true}
                                      />
                                    )}

                                    <Stack
                                      spacing="24px"
                                      mt="4"
                                      direction={['column', 'column', 'row']}
                                    >
                                      {/* INICIAL-LOCATION-BUTTON */}
                                      <Button colorScheme="facebook" w="100%">
                                        <a
                                          style={{
                                            width: '100%',
                                            textDecoration: 'none',
                                          }}
                                          rel="noreferrer"
                                          href={`https://www.google.com/maps/place/${colService.arrival_latitude}, ${colService.arrival_longitude}`}
                                          target="_blank"
                                        >
                                          Localização do Início do Serviço
                                        </a>
                                      </Button>

                                      {/* FINAL-LOCATION-BUTTON */}
                                      <Button colorScheme="facebook" w="100%">
                                        <a
                                          style={{
                                            width: '100%',
                                            textDecoration: 'none',
                                          }}
                                          rel="noreferrer"
                                          href={`https://www.google.com/maps/place/${colService.departure_latitude}, ${colService.departure_longitude}`}
                                          target="_blank"
                                        >
                                          Localização do final do Serviço
                                        </a>
                                      </Button>
                                    </Stack>

                                    <Stack
                                      spacing="24px"
                                      mt="4"
                                      direction={['column', 'column', 'row']}
                                    >
                                      <Input
                                        name="date_hour_service_start"
                                        value={detailFormatDateToFront(
                                          colService.arrival_timestamp,
                                        )}
                                        label="Data e Hora do Início do Serviço"
                                        isDisabled={true}
                                      />

                                      <Input
                                        name="date_hour_service_end"
                                        value={
                                          colService.departure_timestamp !== null
                                            ? detailFormatDateToFront(
                                              colService.departure_timestamp,
                                            )
                                            : ''
                                        }
                                        label="Data e Hora do Fim do Serviço"
                                        isDisabled={true}
                                      />
                                    </Stack>
                                    {colService.provider_id && (
                                      <Stack
                                        spacing="24px"
                                        mt="4"
                                        direction={[
                                          'column',
                                          'column',
                                          'row',
                                        ]}
                                      >
                                        <Input
                                          name="address_provider"
                                          value={`| ${colService.providerIDCollect.trading_name} | ${colService.providerIDCollect.street} | ${colService.providerIDCollect.number} | ${colService.providerIDCollect.neighborhood} | ${colService.providerIDCollect.city} | ${colService.providerIDCollect.cep}`.toUpperCase()}
                                          label="Endereço Fornecedor de Gelo Seco"
                                          isDisabled={true}
                                        />
                                      </Stack>
                                    )}
                                    {colService.address_id && (
                                      <Stack
                                        spacing="24px"
                                        mt="4"
                                        direction={[
                                          'column',
                                          'column',
                                          'row',
                                        ]}
                                      >
                                        <Input
                                          isDisabled={true}
                                          value={`${colService.addressIDCollect.trading_name} | ${colService.addressIDCollect.branch} | ${colService.addressIDCollect.street} | ${colService.addressIDCollect.number} | ${colService.addressIDCollect.neighborhood} | ${colService.addressIDCollect.cityIDAddress.name} | ${colService.addressIDCollect.cep}
                        `.toUpperCase()}
                                          name="address"
                                          label="Endereço"
                                        />
                                      </Stack>
                                    )}

                                    <Stack
                                      spacing="24px"
                                      mt="4"
                                      direction={['column', 'column', 'row']}
                                    >
                                      {!!drivers &&
                                        drivers
                                          .filter(
                                            (driver) =>
                                              driver.id === colService.driver_id,
                                          )
                                          .map((driver) => (
                                            <Input
                                              name="driver"
                                              key={driver.id}
                                              value={`${driver.firstname} ${driver.lastname}`}
                                              label="Motorista Responsável"
                                              isDisabled={true}
                                            />
                                          ))}
                                    </Stack>

                                    <Stack
                                      spacing="24px"
                                      mt="4"
                                      direction={['column', 'column', 'row']}
                                    >
                                      <Input
                                        name="responsible_name"
                                        value={
                                          colService.responsible_name !== null
                                            ? colService.responsible_name
                                            : ' '
                                        }
                                        label="Nome do Responsável"
                                        isDisabled={true}
                                      />

                                      <Input
                                        name="volume"
                                        value={
                                          colService.collect_volume !== null
                                            ? colService.collect_volume
                                            : ' '
                                        }
                                        label="Volume"
                                        isDisabled={true}
                                      />
                                    </Stack>
                                    {colService.real_arrival_timestamp_validated && (
                                      <Stack
                                        spacing="24px"
                                        mt="4"
                                        direction={['column', 'column', 'row']}
                                      >
                                        <Input
                                          name="real_arrival_timestamp"
                                          value={detailFormatDateToFront(
                                            colService?.real_arrival_timestamp,
                                          )}
                                          label="Horário real da coleta"
                                          isDisabled={true}
                                        />

                                      </Stack>
                                    )}
                                    {colService.temp && (
                                      <Stack
                                        spacing="24px"
                                        mt="4"
                                        direction={['column', 'column', 'row']}
                                      >
                                        <Input
                                          name="temp"
                                          value={
                                            colService?.temp
                                          }
                                          label="Temperatura"
                                          isDisabled={true}
                                        />

                                      </Stack>
                                    )}

                                    <Stack
                                      spacing="24px"
                                      mt="4"
                                      direction={['column', 'column', 'row']}
                                    >
                                      <Input name="link_box" hidden>
                                        <ChakraLink
                                          fontSize="lg"
                                          href={handleChangeUrl(
                                            colService.open_box_photo as any,
                                          )}
                                          isExternal
                                        >
                                          Foto da caixa aberta{' '}
                                          <Icon as={FiExternalLink} mx="2px" />
                                        </ChakraLink>
                                      </Input>
                                    </Stack>
                                    <Stack
                                      spacing="24px"
                                      mt="4"
                                      direction={['column', 'column', 'row']}
                                    >
                                      {boxPhotos?.map((boxPhoto) => {
                                        return (
                                          <Input name="link_content" hidden key={boxPhoto.key}>
                                            <ChakraLink
                                              fontSize="lg"
                                              href={handleChangeUrl(boxPhoto.key)}
                                              isExternal
                                            >
                                              Foto da caixa - {boxPhoto.filename} {' '}
                                              <Icon as={FiExternalLink} mx="2px" />
                                            </ChakraLink>
                                          </Input>
                                        )
                                      })}
                                    </Stack>
                                    <Stack
                                      spacing="24px"
                                      mt="4"
                                      direction={['column', 'column', 'row']}
                                    >
                                      {contentDeclarations?.map((contentDeclaration) => {
                                        return (
                                          <Input name="link_content" hidden key={contentDeclaration.key}>
                                            <ChakraLink
                                              fontSize="lg"
                                              href={handleChangeUrl(contentDeclaration.key)}
                                              isExternal
                                            >
                                              Declaração de Conteúdo - {contentDeclaration.filename} {' '}
                                              <Icon as={FiExternalLink} mx="2px" />
                                            </ChakraLink>
                                          </Input>
                                        )
                                      })}
                                    </Stack>
                                    <Stack
                                      spacing="24px"
                                      mt="4"
                                      direction={[
                                        'column',
                                        'column',
                                        'column',
                                      ]}
                                    >
                                      <TextArea
                                        name="driver_observation"
                                        value={colService.driverIDCollect.observation}
                                        label="Observações do Motorista"
                                        isDisabled={true}
                                      />
                                      <TextArea
                                        name="collect_observation"
                                        value={
                                          colService.observation !== null
                                            ? colService.observation
                                            : ''
                                        }
                                        label="Observações da Coleta"
                                        isDisabled={true}
                                      />
                                    </Stack>
                                  </Fragment>
                                )
                              })}
                          </AccordionPanel>
                        </AccordionItem>
                        <Divider my="6" borderColor="gray.700" />
                      </>
                    )}

                    {colServiceLength > 0 && (
                      <>
                        <AccordionItem>
                          <h2>
                            <AccordionButton p="4">
                              <Box flex="1" textAlign="left">
                                Informações dos Motoristas Definidos
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            <Heading size="md" mt="4" fontFamily="poppins">
                              Motorista(s) Definidos para a(s) Coleta(s)
                            </Heading>
                            {collectedServiceFilteredById.map((colService) => (
                              <Fragment key={colService.id}>
                                <Stack
                                  spacing="24px"
                                  mt="4"
                                  direction={['column', 'column', 'row']}
                                >
                                  <Input
                                    name="responsible_driver"
                                    value={`${colService.driverIDCollect.firstname} - ${colService.driverIDCollect.lastname}`}
                                    label="Motorista Responsável"
                                    isDisabled={true}
                                  />
                                  {colService.provider_id !== null && (<Input
                                    name="address_provider"
                                    value={`| ${colService.providerIDCollect.trading_name} | ${colService.providerIDCollect.street} | ${colService.providerIDCollect.number} | ${colService.providerIDCollect.neighborhood} | ${colService.providerIDCollect.city} | ${colService.providerIDCollect.cep}`.toUpperCase()}
                                    label="Endereço Fornecedor de Gelo Seco"
                                    isDisabled={true}
                                  />
                                  )}
                                  {colService.address_id && (
                                    <Input
                                      isDisabled={true}
                                      value={`${colService.addressIDCollect.trading_name} | ${colService.addressIDCollect.branch} | ${colService.addressIDCollect.street} | ${colService.addressIDCollect.number} | ${colService.addressIDCollect.neighborhood} | ${colService.addressIDCollect.cityIDAddress.name} | ${colService.addressIDCollect.cep}
                              `.toUpperCase()}
                                      name="address"
                                      label="Endereço"
                                    />
                                  )}
                                </Stack>
                              </Fragment>
                            ))}
                            {delServiceLength > 0 && (
                              <>
                                <Heading size="md" mt="4" fontFamily="poppins">
                                  Motorista(s) Definidos para a(s) Entrega(s)
                                </Heading>
                                {deliveryServiceFilteredById.map(
                                  (delService) => (
                                    <Fragment key={delService.id}>
                                      <Stack
                                        spacing="24px"
                                        mt="4"
                                        direction={['column', 'column', 'row']}
                                      >
                                        <Input
                                          name="responsible_driver"
                                          value={`${delService.driverIDDelivery.firstname} ${delService.driverIDDelivery.lastname}`}
                                          label="Motorista Responsável"
                                          isDisabled={true}
                                        />

                                        {destinationAddressesFiltered.map(
                                          (address) => (
                                            <Input
                                              key={address.id}
                                              isDisabled={true}
                                              value={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep}
                                      `.toUpperCase()}
                                              name="step"
                                              label="Endereço"
                                            />
                                          ),
                                        )}
                                      </Stack>
                                    </Fragment>
                                  ),
                                )}
                              </>
                            )}
                          </AccordionPanel>
                        </AccordionItem>

                        <Divider my="6" borderColor="gray.700" />
                      </>
                    )}

                    {reqService.service_type !== 'DEDICADO' &&
                      userLogged?.user_type !== 'CLIENTE' &&
                      boardServiceFilteredById.length !== 0 && (
                        <>
                          <AccordionItem bg="white">
                            <h2>
                              <AccordionButton p="4">
                                <Box flex="1" textAlign="left">
                                  Informações do embarque
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              {boardServiceFilteredById.map((bService) => {
                                const trackerLink = bService.tracker_link ? bService.tracker_link : null


                                return (
                                  <Fragment key={bService.id}>
                                    <Stack
                                      spacing="24px"
                                      mt="4"
                                      direction={['column', 'column', 'row']}
                                    >
                                      <Input
                                        name="board_volume"
                                        value={
                                          bService.board_volume !== null
                                            ? bService.board_volume
                                            : ''
                                        }
                                        label="Volume Embarcado"
                                        isDisabled={true}
                                      />

                                      <Input
                                        name="board_weight"
                                        value={
                                          bService.board_weight !== null
                                            ? bService.board_weight
                                            : ''
                                        }
                                        label="Peso Embarcado"
                                        isDisabled={true}
                                      />
                                    </Stack>
                                    <Stack
                                      spacing="24px"
                                      mt="4"
                                      direction={['column', 'column', 'row']}
                                    >
                                      <Input
                                        name="cte"
                                        value={
                                          bService.cte !== null
                                            ? bService.cte
                                            : ''
                                        }
                                        label="CTE"
                                        isDisabled={true}
                                      />
                                    </Stack>
                                    <Stack
                                      spacing="24px"
                                      mt="4"
                                      direction={['column', 'column', 'row']}
                                    >
                                      <Input
                                        name="cte_transfer_cost"
                                        value={
                                          bService.cte_transfer_cost !== null
                                            ? bService.cte_transfer_cost
                                            : ''
                                        }
                                        label="Valor CTE de Transferência"
                                        isDisabled={true}
                                      />
                                      {trackerLink !== null ? (
                                        <Input
                                          name="operational_number"
                                          label='Rastreador'
                                          isDisabled
                                          hidden
                                        >
                                          <ChakraLink
                                            fontSize="lg"
                                            href={trackerLink} isExternal
                                          >
                                            {bService.operational_number !== null
                                              ? bService.operational_number
                                              : '-'}
                                          </ChakraLink>
                                        </Input>
                                      ) : (
                                        <Input
                                          name="operational_number"
                                          value={
                                            bService.operational_number !== null
                                              ? bService.operational_number
                                              : '-'
                                          }
                                          label="Rastreador"
                                          isDisabled={true}
                                        />
                                      )}
                                    </Stack>
                                    <Stack
                                      spacing="24px"
                                      mt="4"
                                      direction={['column', 'column', 'row']}
                                    >
                                      <Input
                                        name="real_weight"
                                        value={
                                          bService.real_weight !== null
                                            ? bService.real_weight
                                            : ''
                                        }
                                        label="Peso Real para Custo (balança)"
                                        isDisabled={true}
                                      />

                                      <Input
                                        name="taxed_weight"
                                        value={
                                          bService.taxed_weight !== null
                                            ? bService.taxed_weight
                                            : '-'
                                        }
                                        label="Peso Taxado para Custo"
                                        isDisabled={true}
                                      />
                                    </Stack>
                                    <Stack mt='4'>
                                      <Input
                                        name='real_board_date'
                                        value={
                                          bService.real_board_date
                                            ? formatDate.handle(bService.real_board_date, 'DateWithDateAndHourMinute')
                                            : '-'
                                        }
                                        label='Horário real do embarque'
                                        isDisabled

                                      />

                                    </Stack>
                                    <Stack
                                      spacing="24px"
                                      mt="4"
                                      direction={['column', 'column', 'row']}
                                    >
                                      <Radio
                                        name='open_ticket_email'
                                        radioOptions={positiveNegativeOptions}
                                        defaultCheckedOption={bService.open_ticket_email ? bService.open_ticket_email ? 'yes' : 'no' : ''}
                                        label='Abriu ticket/email?'
                                        isDisabled
                                      />
                                    </Stack>
                                    {userLogged?.user_type !== "CLIENTE" && (
                                      <Stack
                                        spacing="24px"
                                        mt="4"
                                        direction={['column', 'column', 'row']}
                                      >
                                        <Input name="link_cte_photo" hidden>
                                          <ChakraLink
                                            fontSize="lg"
                                            href={handleChangeUrl(
                                              bService.cte_photo as any,
                                            )}
                                            isExternal
                                          >
                                            Foto do CTE{' '}
                                            <Icon as={FiExternalLink} mx="2px" />
                                          </ChakraLink>
                                        </Input>
                                        {bService.box_photo !== null && (
                                          <Input name="link_box_photo" hidden>
                                            <ChakraLink
                                              fontSize="lg"
                                              href={handleChangeUrl(
                                                bService.box_photo as any,
                                              )}
                                              isExternal
                                            >
                                              Foto das Caixas{' '}
                                              <Icon as={FiExternalLink} mx="2px" />
                                            </ChakraLink>
                                          </Input>
                                        )}

                                      </Stack>
                                    )}

                                    <Stack mt="4">
                                      <TextArea
                                        name="board_observation"
                                        value={
                                          bService.board_observation !== null
                                            ? bService.board_observation
                                            : ''
                                        }
                                        label="Observações do Embarque"
                                        isDisabled={true}
                                      />
                                    </Stack>
                                    <Stack mt="4">
                                      <TextArea
                                        name="board_validate_observation"
                                        value={
                                          bService.validate_observation !== null
                                            ? bService.validate_observation
                                            : ''
                                        }
                                        label="Observações do Embarque Validado"
                                        isDisabled={true}
                                      />
                                    </Stack>
                                  </Fragment>
                                )
                              })}
                            </AccordionPanel>
                          </AccordionItem>

                          <Divider my="6" borderColor="gray.700" />
                        </>
                      )}

                    {reqService.service_type !== 'DEDICADO' &&
                      allocatedServiceFilteredById.length !== 0 && (
                        <>
                          <AccordionItem bg="white">
                            <h2>
                              <AccordionButton p="4">
                                <Box flex="1" textAlign="left">
                                  Informações de alocação
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              {allocatedServiceFilteredById.map((aService) => (
                                <Fragment key={aService?.id}>
                                  <Stack
                                    spacing="24px"
                                    mt="4"
                                    direction={['column', 'column', 'row']}
                                  >
                                    {aService?.allocate_flight !== null && (
                                      <>
                                        <Input
                                          name="board_volume"
                                          value={aService?.allocate_flight}
                                          label="Volume Embarcado"
                                          isDisabled={true}
                                        />
                                      </>
                                    )}
                                  </Stack>
                                  <Stack
                                    spacing="24px"
                                    mt="4"
                                    direction={['column', 'column', 'row']}
                                  >
                                    <Input
                                      name="allocate_availability_date"
                                      value={serviceFormatDateToFront(
                                        aService.allocate_availability_date,
                                      )}
                                      type="date"
                                      label="Data Prevista para Disponibilidade"
                                      isDisabled={true}
                                    />
                                    <Input
                                      name="allocate_availability_hour"
                                      value={serviceFormatHourToFront(
                                        aService.allocate_availability_hour,
                                      )}
                                      label="Horário Previsto para Disponibilidade"
                                      type="time"
                                      isDisabled={true}
                                    />
                                  </Stack>
                                  {aService?.airline_information && (
                                    <Stack mt="4">
                                      <Input
                                        name="airline_information"
                                        value={
                                          aService.airline_information !== null
                                            ? aService.airline_information
                                            : ''
                                        }
                                        label="Ticket/Email"
                                        isDisabled={true}
                                      />
                                    </Stack>
                                  )}

                                  <Stack mt="4">
                                    <TextArea
                                      name="allocate_observation"
                                      value={
                                        aService.observation !== null
                                          ? aService.observation
                                          : ''
                                      }
                                      label="Observações"
                                      isDisabled={true}
                                    />
                                  </Stack>
                                </Fragment>
                              ))}
                            </AccordionPanel>
                          </AccordionItem>

                          <Divider my="6" borderColor="gray.700" />
                        </>
                      )}

                    {reqService.service_type !== 'DEDICADO' &&
                      availableServiceFilteredById?.length !== 0 && (
                        <>
                          <AccordionItem bg="white">
                            <h2>
                              <AccordionButton p="4">
                                <Box flex="1" textAlign="left">
                                  Informações de disponibilização
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              {availableServiceFilteredById?.map(
                                (avService) => (
                                  <Fragment key={avService.id}>
                                    <Stack
                                      spacing="24px"
                                      mt="4"
                                      direction={['column', 'column', 'row']}
                                    >
                                      <Input
                                        name="landing_availability_date"
                                        value={serviceFormatDateToFront(
                                          avService.landing_availability_date,
                                        )}
                                        type="date"
                                        label="Data Prevista para Disponibilidade"
                                        isDisabled={true}
                                      />
                                      <Input
                                        name="landing_availability_hour"
                                        value={serviceFormatHourToFront(
                                          avService.landing_availability_hour,
                                        )}
                                        label="Horário Previsto para Disponibilidade"
                                        type="time"
                                        isDisabled={true}
                                      />
                                    </Stack>

                                    <Stack mt="4">
                                      <TextArea
                                        name="available_observation"
                                        value={
                                          avService.observation !== null
                                            ? avService.observation
                                            : ''
                                        }
                                        label="Observações"
                                        isDisabled={true}
                                      />
                                    </Stack>
                                  </Fragment>
                                ),
                              )}
                            </AccordionPanel>
                          </AccordionItem>

                          <Divider my="6" borderColor="gray.700" />
                        </>
                      )}
                    {reqService.service_type !== 'DEDICADO' &&
                      landingServiceFilteredById?.length !== 0 && (
                        <>
                          <AccordionItem bg="white">
                            <h2>
                              <AccordionButton p="4">
                                <Box flex="1" textAlign="left">
                                  Informações de desembarque
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              {landingServiceFilteredById?.map(
                                (landingService) => (
                                  <Fragment key={landingService.id}>
                                    <Stack
                                      spacing="24px"
                                      mt="4"
                                      direction={['column', 'column', 'row']}
                                    >
                                      <Input name="link_box" hidden>
                                        <ChakraLink
                                          fontSize="lg"
                                          href={handleChangeUrl(
                                            landingService.cargo_photo as any,
                                          )}
                                          isExternal
                                        >
                                          Foto da Carga{' '}
                                          <Icon as={FiExternalLink} mx="2px" />
                                        </ChakraLink>
                                      </Input>
                                    </Stack>

                                    <Stack
                                      spacing="24px"
                                      mt="4"
                                      direction={['column', 'column', 'row']}
                                    >
                                      <Input
                                        name="landing_volume"
                                        value={
                                          landingService.landing_volume !== null
                                            ? landingService.landing_volume
                                            : '-'
                                        }
                                        label="Volume Desembarcado"
                                        isDisabled={true}
                                      />
                                      <Input
                                        name="box_break"
                                        value={landingService.box_break ? 'SIM' : 'NÃO'}
                                        label="Caixas com Avarias"
                                        isDisabled={true}
                                      />
                                      <Input
                                        name="departure_timestamp"
                                        value={
                                          landingService.departure_timestamp !==
                                            null
                                            ? detailFormatDateToFront(
                                              landingService.departure_timestamp,
                                            )
                                            : '-'
                                        }
                                        label="Data e Hora final do Desembarque"
                                        isDisabled={true}
                                      />
                                    </Stack>

                                    <Stack mt="4">
                                      <TextArea
                                        name="landing_observation"
                                        value={
                                          landingService.observation !== null
                                            ? landingService.observation
                                            : ''
                                        }
                                        label="Observações"
                                        isDisabled={true}
                                      />
                                    </Stack>
                                  </Fragment>
                                ),
                              )}
                            </AccordionPanel>
                          </AccordionItem>

                          <Divider my="6" borderColor="gray.700" />
                        </>
                      )}

                    {delServiceLength > 0 && (
                      <>
                        <AccordionItem bg="white">
                          <h2>
                            <AccordionButton p="4">
                              <Box flex="1" textAlign="left">
                                Informações da entrega do serviço
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {deliveryServiceFilteredById?.map((delService) => (
                              <Fragment key={delService.id}>
                                <Stack
                                  spacing="24px"
                                  mt="4"
                                  direction={['column', 'column', 'row']}
                                >
                                  {/* INICIAL-LOCATION-BUTTON */}
                                  <Button colorScheme="facebook" w="100%">
                                    <a
                                      style={{
                                        width: '100%',
                                        textDecoration: 'none',
                                      }}
                                      rel="noreferrer"
                                      href={`https://www.google.com/maps/place/${delService.arrival_latitude}, ${delService.arrival_longitude}`}
                                      target="_blank"
                                    >
                                      Localização do Início da Entrega
                                    </a>
                                  </Button>

                                  {/* FINAL-LOCATION-BUTTON */}
                                  <Button colorScheme="facebook" w="100%">
                                    <a
                                      style={{
                                        width: '100%',
                                        textDecoration: 'none',
                                      }}
                                      rel="noreferrer"
                                      href={`https://www.google.com/maps/place/${delService.departure_latitude}, ${delService.departure_longitude}`}
                                      target="_blank"
                                    >
                                      Localização do final da Entrega
                                    </a>
                                  </Button>
                                </Stack>

                                <Stack
                                  spacing="24px"
                                  mt="4"
                                  direction={['column', 'column', 'row']}
                                >
                                  <Input
                                    name="date_hour_service_start"
                                    value={detailFormatDateToFront(
                                      delService.arrival_timestamp,
                                    )}
                                    label="Data e Hora do Início do Serviço"
                                    isDisabled={true}
                                  />

                                  <Input
                                    name="date_hour_service_end"
                                    value={
                                      delService.departure_timestamp !== null
                                        ? detailFormatDateToFront(
                                          delService.departure_timestamp,
                                        )
                                        : ''
                                    }
                                    label="Data e Hora do Fim do Serviço"
                                    isDisabled={true}
                                  />
                                </Stack>

                                {destinationAddressesFiltered.map((address) => (
                                  <Input
                                    isDisabled={true}
                                    key={address.id}
                                    value={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep}
          `.toUpperCase()}
                                    name="delivery_address"
                                    label="Endereço da Entrega"
                                  />
                                ))}

                                <Stack
                                  spacing="24px"
                                  mt="4"
                                  direction={['column', 'column', 'row']}
                                >
                                  <Input
                                    name="responsible_name"
                                    value={
                                      delService.responsible_name !== null
                                        ? delService.responsible_name
                                        : ' '
                                    }
                                    label="Nome do Responsável"
                                    isDisabled={true}
                                  />
                                </Stack>

                                <Stack
                                  spacing="24px"
                                  mt="4"
                                  direction={['column', 'column', 'row']}
                                >
                                  <Input
                                    name="delivery_volume"
                                    value={
                                      delService.delivery_volume !== null
                                        ? delService.delivery_volume
                                        : ' '
                                    }
                                    label="Volume"
                                    isDisabled={true}
                                  />
                                </Stack>
                                {delService.real_arrival_timestamp_validated && (
                                  <Stack
                                    spacing="24px"
                                    mt="4"
                                    direction={['column', 'column', 'row']}
                                  >
                                    <Input
                                      name="real_arrival_timestamp"
                                      value={detailFormatDateToFront(
                                        delService?.real_arrival_timestamp,
                                      )}
                                      label="Horário real da entrega"
                                      isDisabled={true}
                                    />

                                  </Stack>
                                )}

                                <Stack
                                  spacing="24px"
                                  mt="4"
                                  direction="column"
                                >
                                  <Input name="link_box" hidden>
                                    <ChakraLink
                                      fontSize="lg"
                                      href={handleChangeUrl(
                                        delService.box_photo as any,
                                      )}
                                      isExternal
                                    >
                                      Foto da caixa{' '}
                                      <Icon as={FiExternalLink} mx="2px" />
                                    </ChakraLink>
                                  </Input>
                                  {delService.content_declaration_attachments?.map((contentDeclaration) => {

                                    return (
                                      <Input name="link_content" hidden key={contentDeclaration.key}>
                                        <ChakraLink
                                          fontSize="lg"
                                          href={handleChangeUrl(contentDeclaration.key)}
                                          isExternal
                                        >
                                          {contentDeclaration.filename} {' '}
                                          <Icon as={FiExternalLink} mx="2px" />
                                        </ChakraLink>
                                      </Input>
                                    )
                                  })}

                                  <Stack mt="4">
                                    <TextArea
                                      name="delivery_observation"
                                      value={
                                        delService.observation !== null
                                          ? delService.observation
                                          : ''
                                      }
                                      label="Observações da Entrega"
                                      isDisabled={true}
                                    />
                                  </Stack>
                                </Stack>
                                <Stack>
                                  <TextArea
                                    name="collector_observation"
                                    value={delService.driverIDDelivery.collectorIDDriver.observation}
                                    label="Observações do Coletador"
                                    isDisabled={true}
                                  />

                                  <TextArea
                                    name="driver_observation"
                                    value={delService.driverIDDelivery.observation}
                                    label="Observações do Motorista"
                                    isDisabled={true}
                                  />
                                </Stack>

                              </Fragment>
                            ))}
                          </AccordionPanel>
                        </AccordionItem>

                        <Divider my="6" borderColor="gray.700" />
                      </>
                    )}

                    {serviceById?.occurrenceIDService.length > 0 && (
                      <>
                        <AccordionItem bg="white">
                          <h2>
                            <AccordionButton p="4">
                              <Box flex="1" textAlign="left">
                                Ocorrências do serviço
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {serviceById?.occurrenceIDService?.map((occurrence) => (
                              <Fragment key={occurrence.id}>
                                <Stack spacing="24px" mt="4" direction="column">
                                  <Stack
                                    w="full"
                                    spacing="24px"
                                    direction={['column', 'column', 'row']}
                                  >
                                    <Stack
                                      w="full"
                                      spacing="24px"
                                      direction={['column', 'column', 'row']}
                                      alignItems={'end'}
                                    >
                                      <Input
                                        name="occurrence_type"
                                        defaultValue={occurrence.type}
                                        label="Tipo de Ocorrência"
                                        isDisabled={true}
                                      />
                                      <Input
                                        name="interccurrence"
                                        defaultValue={occurrence.intercurrence}
                                        label="Intercorrência"
                                        isDisabled={true}
                                      />
                                      <Input
                                        name="occurrence_protocol"
                                        defaultValue={
                                          occurrence.occurrence_protocol
                                        }
                                        label="Protocolo Ocorrência"
                                        isDisabled={true}
                                      />
                                      <Button
                                        size='lg'
                                        bgColor="gray.100"
                                        variant='ghost'
                                        onClick={() => handleDispatchDetailsOccurrenceModal(occurrence)}
                                      >
                                        <Icon as={FaEye} />
                                      </Button>
                                    </Stack>
                                  </Stack>
                                  <Stack
                                    spacing="24px"
                                    direction={
                                      occurrence.intercurrence !==
                                        'ALTERAÇÃO DE INSUMOS UTILIZADOS' &&
                                        occurrence.intercurrence !==
                                        'REPOSIÇÃO DE INSUMOS'
                                        ? ['column', 'column', 'row']
                                        : 'column'
                                    }
                                  >
                                    {occurrence.intercurrence ===
                                      'REPOSIÇÃO DE INSUMOS' &&
                                      setInputsToShow(
                                        inputs!,
                                        occurrence.materials!,
                                      ).map((input, index) => (
                                        <Fragment key={input.inputName}>
                                          <Stack
                                            w="full"
                                            mt="4"
                                            spacing="24px"
                                            direction={[
                                              'column',
                                              'column',
                                              'row',
                                            ]}
                                          >
                                            <Input
                                              name={`input${index}`}
                                              label="Insumo"
                                              defaultValue={input.inputName}
                                              isDisabled={true}
                                            />
                                            <Input
                                              name={`quantity${index}`}
                                              label="Quantidade"
                                              defaultValue={input.quantity}
                                              isDisabled={true}
                                            />
                                          </Stack>
                                        </Fragment>
                                      ))}


                                  </Stack>
                                  {
                                    userLogged?.permissions.includes('remove-occurrence') &&
                                    (occurrence.type === 'TRANSPORTADORA' || occurrence.type === 'COLETADOR') &&
                                    (
                                      <Button
                                        w='full'
                                        onClick={() => {
                                          handleDeleteOccurrence(occurrence.id)
                                        }}
                                        leftIcon={<Icon as={FaTrash} />}
                                        colorScheme='red'
                                      >
                                        Excluir ocorrência
                                      </Button>
                                    )}

                                </Stack>
                              </Fragment>
                            ))}
                          </AccordionPanel>
                        </AccordionItem>

                        <Divider my="6" borderColor="gray.700" />
                      </>
                    )}

                    {serviceById.maintenanceIDService?.length > 0 && (
                      <>
                        <AccordionItem bg="white">
                          <h2>
                            <AccordionButton p="4">
                              <Box flex="1" textAlign="left">
                                Manutenções do serviço
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {serviceById.maintenanceIDService.map(maintenance => {
                              return (
                                <Flex
                                  gap={4}
                                  direction="column"
                                  w="full"
                                  key={maintenance.id}
                                >
                                  <Input
                                    name="maintenance_type"
                                    value={maintenance.maintenance_type}
                                    label="Tipo de manutenção"
                                    isDisabled={true}
                                  />

                                  <Stack
                                    spacing={4}
                                    direction={["column", "column", "row"]}
                                    w="full"
                                  >
                                    <Input
                                      name="maintenance_arrival_temperature"
                                      value={maintenance.maintenance_arrival_temperature}
                                      label="Temperatura de chegada"
                                      isDisabled={true}
                                    />
                                  </Stack>

                                  <Input name="maintenance_arrival_photo" hidden>
                                    <ChakraLink
                                      fontSize="lg"
                                      href={handleChangeUrl(
                                        maintenance.maintenance_arrival_photo as any,
                                      )}
                                      isExternal
                                    >
                                      Foto de chegada{' '}
                                      <Icon as={FiExternalLink} mx="2px" />
                                    </ChakraLink>
                                  </Input>

                                  {maintenance.status === 'DONE' && (
                                    <Stack
                                      spacing={4}
                                      direction='column'
                                    >
                                      <Stack
                                        spacing={4}
                                        direction={["column", "column", "row"]}
                                        w="full"
                                      >
                                        <Input
                                          name="maintenance_departure_temperature"
                                          value={maintenance.maintenance_departure_temperature}
                                          label="Temperatura de saída"
                                          isDisabled={true}
                                        />
                                        <Input
                                          name="maintenance_allocated_refrigeration_quantity"
                                          value={maintenance.maintenance_allocated_refrigeration_quantity}
                                          label="Quantidade de refrigeração alocada"
                                          isDisabled={true}
                                        />
                                      </Stack>
                                      <Input name="maintenance_departure_photo" hidden>
                                        <ChakraLink
                                          fontSize="lg"
                                          href={handleChangeUrl(
                                            maintenance.maintenance_departure_photo as any,
                                          )}
                                          isExternal
                                        >
                                          Foto de saída{' '}
                                          <Icon as={FiExternalLink} mx="2px" />
                                        </ChakraLink>
                                      </Input>
                                    </Stack>
                                  )}

                                  <Divider />

                                </Flex>
                              )
                            })}
                          </AccordionPanel>
                        </AccordionItem>


                        <Divider my="6" borderColor="gray.700" />
                      </>
                    )}
                    {serviceById.step === "cancelledService" && (
                      <>
                        <AccordionItem>
                          <h2>
                            <AccordionButton p="4">
                              <Box flex="1" textAlign="left">
                                Informações do cancelamento do serviço
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            <Stack
                              spacing="24px"
                              mt="4"
                              direction={['column', 'column', 'row']}
                            >
                              <Input
                                name="cancel_reason"
                                value={serviceById.cancel_reason}
                                label="Motivo do cancelamento"
                                isDisabled={true}
                              />
                            </Stack>
                            <Stack
                              spacing="24px"
                              mt="4"
                              direction={['column', 'column', 'row']}
                            >
                              <TextArea
                                name="cancel_observation"
                                value={serviceById.cancel_observation !== null ? serviceById.cancel_observation : ''}
                                label="Observações"
                                isDisabled={true}
                              />
                            </Stack>
                          </AccordionPanel>
                        </AccordionItem>
                        <Divider my="6" borderColor="gray.700" />
                      </>
                    )}
                    {serviceById.extrasDiscounts.length > 0 && (
                      <>
                        <AccordionItem>
                          <h2>
                            <AccordionButton p="4">
                              <Box flex="1" textAlign="left">
                                Extras e Descontos do Serviço
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {serviceById?.extrasDiscounts?.map((extraDiscount) => {
                              return (
                                <Flex
                                  gap={4}
                                  direction="column"
                                  w="full"
                                  key={extraDiscount.id}
                                >
                                  <Stack
                                    spacing="24px"
                                    mt="4"
                                    direction={['column', 'column', 'row']}
                                  >
                                    <Input
                                      name="type"
                                      value={extraDiscount?.extraDiscount.type}
                                      label="Tipo"
                                      isDisabled={true}
                                    />
                                    <Input
                                      name="value"
                                      value={new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                      }).format((extraDiscount?.extraDiscount.value / 100) / extraDiscount?.extraDiscount?.services?.length)}
                                      label="Valor"
                                      isDisabled={true}
                                    />
                                  </Stack>
                                  <TextArea
                                    isDisabled={true}
                                    value={extraDiscount?.extraDiscount?.description}
                                    name="description"
                                    label="Descrição"
                                  />
                                </Flex>
                              )
                            })}

                          </AccordionPanel>
                        </AccordionItem>
                        <Divider my="6" borderColor="gray.700" />
                      </>
                    )}

                  </Accordion>
                </Fragment>
              )
            })}

          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap={4}
          >
            {userLogged?.permissions.includes('remove-service') && (
              <Button
                colorScheme='red'
                onClick={() => handleDeleteService(service_id)}
                w="full"
              >
                Excluir serviço
              </Button>
            )}

            {userLogged?.permissions.includes('cancel-service') && !serviceHasPendingSourceAddressesToValidate &&
              (userLogged?.user_type === 'CLIENTE' ? (
                serviceStep === 'toValidateRequestedService' ? (
                  <Button
                    type="button"
                    //onClick={handleCancelService}
                    onClick={() => onOpenCancelServiceModal()}
                    w="full"
                  >
                    Cancelar serviço
                  </Button>
                ) : null
              ) : (
                <>
                  <CancelServiceModal
                    serviceId={serviceById.id}
                    serviceProtocol={serviceById.protocol}
                    isOpen={isCancelServiceModalOpen}
                    onClose={onCloseCancelServiceModal}
                  />
                  <Button
                    type="button"
                    onClick={() => onOpenCancelServiceModal()}
                    w="full"
                  >
                    Cancelar serviço
                  </Button>
                </>
              ))}

            {(
              (userLogged.permissions.includes('service-request-cancel') && !serviceHasPendingSourceAddressesToValidate) ||
              (userLogged.permissions.includes('service-request-cancel') && userLogged?.user_type === 'CLIENTE' && cityWithMinOpeningAndLatestSchedule !== null)
            ) && (

                <>
                  <RequestCancelServiceModal
                    serviceId={serviceById.id}
                    serviceProtocol={serviceById.protocol}
                    isOpen={isRequestCancelServiceModalOpen}
                    onClose={onCloseRequestCancelServiceModal}
                  />
                  <Button
                    w='full'
                    colorScheme='red'
                    variant='ghost'
                    onClick={() => onOpenRequestCancelServiceModal()}
                  >
                    Solicitar cancelamento do serviço
                  </Button>
                </>
              )}


            <Modal
              isOpen={isRequestStepChangeModalOpen}
              onClose={onCloseRequestStepChangeModal}
            >
              <ModalOverlay />
              <RequestStepChangeModal
                serviceId={serviceById.id}
                onCloseModal={onCloseRequestStepChangeModal}
              />
            </Modal>
            {serviceAvailableForStepChangeRequests && userCanRequestStepChange && (
              <Button
                w='full'
                colorScheme='red'
                variant='ghost'
                onClick={onOpenRequestStepChangeModal}
              >
                Solicitar alteração de fase do serviço
              </Button>
            )}
            {userLogged?.permissions.includes(
              'request-addresses-to-collect',
            ) && serviceIsEnabledToRequestNewAddresses && (
                serviceIsEnabledToRequestNewCollectAddresses ? (
                  <Button
                    onClick={onOpenGenerateAddresses}
                    w="full"
                  >
                    Adicionar endereços de coleta
                  </Button>
                ) : (
                  <Alert status='warning' my='4'>
                    <AlertIcon />
                    <AlertDescription>O serviço não está elegivel para solicitar mais endereços de coleta.</AlertDescription>
                  </Alert>
                )
              )}
            {userLogged?.permissions.includes(
              'request-addresses-to-delivery',
            ) && serviceIsEnabledToRequestNewAddresses && (
                serviceIsEnabledToRequestNewDeliveryAddresses ? (
                  <Button
                    onClick={onOpenRequestDeliveryAddressesModal}
                    w="full"
                  >
                    Adicionar endereços de entrega
                  </Button>
                ) : (
                  <Alert status='warning' my='4'>
                    <AlertIcon />
                    <AlertDescription>O serviço não está elegivel para solicitar mais endereços de entrega.</AlertDescription>
                  </Alert>
                )

              )}
            {userLogged?.permissions.includes(
              'generate-content-declaration-pdf',
            ) && (
                <Button
                  w="full"
                  onClick={onOpenContentDeclaration}
                  isDisabled={!addressesWithOwnBoardOrLanding}
                >
                  Declaração
                </Button>
              )}
            {(userLogged?.permissions.includes('edit-progress-service') || userCanEditServiceCollected || userCanEditServiceRequested) && (
              <Button
                as={Link}
                to={`/servico/editar/${service_id}`}
                w="full"
              >
                Editar Serviço em Andamento
              </Button>
            )}
            <Modal
              isOpen={isGenerateLabelOpen}
              onClose={onCloseGenerateLabel}
              size="xl"
            >
              <ModalOverlay />

              <GenerateServiceLabels
                sourceAddresses={sourceAddressesFiltered}
                destinationAddresses={destinationAddressesFiltered}
                serviceId={service_id}
              />
            </Modal>
            <Button
              w="full"
              onClick={onOpenGenerateLabel}
              colorScheme="facebook"
            >
              Download Etiqueta
            </Button>
            {!stepsNotAllowedToGenerateWithdrawAuthorization.includes(serviceById?.step!) && serviceById?.serviceIDRequested?.service_type === "FRACIONADO" && (
              <Button
                w="full"
                onClick={onOpenGenerateWithdrawAuthorization}
                colorScheme="facebook"
              >
                Download Autorização de Retirada
              </Button>
            )}

            <Button
              w="full"
              onClick={onOpenBoardServiceAuthorization}
              isDisabled={!sourceDriversToBoardAuthorization || !sourceBranch}
              colorScheme="facebook"
            >
              Download Autorização de Embarque
            </Button>

            {userLogged?.permissions.includes('view-service-intern-comunications') && (
              <Button
                w='full'
                onClick={() => onOpenServiceOpenedInternComunicationModal()}
              >
                Comunicações internas
              </Button>
            )}
            {serviceById?.assigned_pdf && isCollectDateEqualOrMinorThanNow ? (
              <ChakraLink
                w="full"
                href={handleChangeUrl(serviceById.assigned_pdf)}
                isExternal
              >
                <Button
                  colorScheme="facebook"
                  w="full"
                >
                  {isWideVersion ? (
                    'Download Minuta'
                  ) : (
                    <Icon as={FaFileInvoice} />
                  )}
                </Button>
              </ChakraLink>
            ) : (
              <>
                {userLogged?.permissions.includes('download-minuta') && (
                  <>
                    {serviceById && sourceBranch && (
                      <>
                        {serviceById?.serviceIDRequested.service_type ===
                          'FRACIONADO' &&
                          minutasShippingCompanyNames.includes(
                            sourceBranch.shippingIDBranch.company_name,
                          )! && isCollectDateEqualOrMinorThanNow && (
                            <>
                              {minutaUrl === '' ? (
                                <Button
                                  w="full"
                                  onClick={handleGenerateMinuta}
                                  colorScheme="facebook"
                                >
                                  Gerar Minuta
                                </Button>
                              ) : (
                                <a
                                  href={minutaUrl}
                                  download={`Minuta serviço ${serviceById?.protocol}`}
                                >
                                  <Button
                                    colorScheme="facebook"
                                    w="full"
                                    onClick={() =>
                                      setTimeout(() => setMinutaUrl(''), 1500)
                                    }
                                  >
                                    Download Minuta
                                  </Button>
                                </a>
                              )}
                            </>
                          )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {serviceById?.cte_loglife_pdf_associated && (
              <ChakraLink
                w="full"
                href={handleChangeUrl(serviceById.cte_loglife_pdf_associated)}
                isExternal
              >
                <Button
                  colorScheme="facebook"
                  w="full"
                >
                  Download Cte Loglife associado
                </Button>
              </ChakraLink>
            )}
            {serviceById?.serviceIDCollect.some(collect => collect.step === 'VALIDATEUNSUCCESS') && (
              <Link style={{ width: '100%' }} to={`/servicos/coletas-sem-sucesso/${service_id}`}>
                <Button
                  w="full"
                >
                  Validar Coletas Sem Sucesso
                </Button>
              </Link>
            )}
            <Button
              as={Link}
              to='/servicos'
              name="Serviços"
              w='full'
            >
              Serviços
            </Button>
          </Flex>
        </Box>
      </Flex>

    </>
  )
}
