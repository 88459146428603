import { Flex, Stack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { getDate, getMonth } from 'date-fns'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useCity } from '../../../hooks/city/useCity'
import { useHoliday } from '../../../hooks/holiday/useHoliday'
import { useContentLoading } from '../../../hooks/loading/useContentLoading'
import { IEditHolidayDTO } from '../../../pages/Holiday/IHolidayFormDTO'
import { negative_positive } from '../../../utils/customLists'
import { months } from '../../../utils/CustomLists/months'
import { numbersList } from '../../../utils/CustomLists/numbersList'
import { holidaySchema } from '../../../validations/holidaySchema'
import { FormActionButton } from '../../Buttons/FormActionButton'
import { ListButton } from '../../Buttons/ListButton'
import { SubmitButton } from '../../Buttons/SubmitButton'
import { Input } from '../../Inputs/Input'
import { Select } from '../../Inputs/SelectInput'
import { GeneralContentLoading } from '../../Loading/GeneralContentLoading'
import { StandardBackgroundForm } from '../StandardBackgroundForm'

interface IEditHolidayFormProps {
  holidayId: string
  slug: string
  submit: (values: IEditHolidayDTO) => Promise<void>
}

export function EditDetailHolidayForm({
  submit,
  slug,
  holidayId,
}: IEditHolidayFormProps) {
  const { handleSetIsContentLoadingFalse, isContentLoading } =
    useContentLoading()
  const {
    holiday: { data: holiday, isLoading: isHolidayLoading },
  } = useHoliday(holidayId, false)
  const {
    cities: { data: cities, isLoading: isCityLoading },
  } = useCity(null, true)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IEditHolidayDTO>({
    resolver: yupResolver(holidaySchema),
  })

  const nationalFieldValue = watch('national')

  useEffect(() => {
    if (!isCityLoading && !isHolidayLoading) {
      handleSetIsContentLoadingFalse()
    }
  }, [handleSetIsContentLoadingFalse, isCityLoading, isHolidayLoading])

  useEffect(() => {
    if (!isContentLoading && holiday) {
      const monthFromHolidayDate = getMonth(new Date(holiday.date))
      const dayFromHolidayDate = getDate(new Date(holiday.date))

      setValue('name', holiday.name)
      setValue('national', holiday.national === true ? 'SIM' : 'NÃO')
      setValue('day', dayFromHolidayDate)
      setValue('month', monthFromHolidayDate)
      setValue('city_id', holiday.city_id ?? '')
    }
  }, [isContentLoading, holiday, setValue])

  if (isContentLoading) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm
      onSubmit={handleSubmit(submit)}
      title={slug === 'editar' ? 'Editar Feriado' : 'Visualizar Feriado'}
    >
      <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register('name')}
          name="name"
          label="Nome"
          error={errors.name}
          isDisabled={slug === 'visualizar'}
          required
        />
        <Stack w="full" spacing="24px" direction={['column', 'column', 'row']}>
          <Select
            {...register('day')}
            name="day"
            label="Dia"
            placeholder="Selecione uma opção..."
            numbersList={numbersList}
            error={errors.day}
            isDisabled={slug === 'visualizar'}
            required
          />
          <Select
            {...register('month')}
            name="month"
            label="Mês"
            placeholder="Selecione uma opção..."
            monthsOfYear={months}
            error={errors.month}
            isDisabled={slug === 'visualizar'}
            required
          />
        </Stack>
      </Stack>
      <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Select
          {...register('national')}
          name="national"
          negative_positive={negative_positive}
          label="É nacional?"
          placeholder="Selecione uma opção..."
          error={errors.national}
          isDisabled={slug === 'visualizar'}
          required
        />
        {nationalFieldValue === 'NÃO' && (
          <Select
            {...register('city_id')}
            name="city_id"
            placeholder="Selecione uma opção..."
            citiesBack={cities}
            error={errors.city_id}
            label="Cidade"
            isDisabled={slug === 'visualizar'}
            required
          />
        )}
      </Stack>
      <Flex mt="8" justify="flex-end">
        <Stack direction="row">
          {slug === 'visualizar' ? (
            <FormActionButton
              action="Editar"
              href={`/feriado/editar/${holidayId}`}
            />
          ) : (
            <SubmitButton action="Salvar" isSubmitting={isSubmitting} />
          )}
          <ListButton href="/feriados" name="Feriados" />
        </Stack>
      </Flex>
    </StandardBackgroundForm>
  )
}
