import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  Icon,
  Button,
} from '@chakra-ui/react'
import { useState, useEffect, Fragment } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import { formatDateToFrontTable } from '../../../utils/GeneralFunctions/DateFunctions/formatDateToFrontTable'
import { Link } from 'react-router-dom'
import { FiCheckCircle, FiTrash2 } from 'react-icons/fi'
import swal from 'sweetalert'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { useAuth } from '../../../hooks/auth/useAuth'
import { serviceHandleStep } from '../../../utils/ServiceFunctions/serviceDict'
import { BudgetProps } from '../../../contexts/BudgetContext'
import { Occurrence } from 'hooks/occurrences/dtos/Occurrence'
import { useOccurrenceFunctions } from 'hooks/occurrences/useOccurrenceFunctions'
import { FaUndoAlt } from 'react-icons/fa'

interface OccurrenceTableProps {
  occurrences: Occurrence[]
  budgets: BudgetProps[]
  handleRefetchTableData: () => void
}

const occurrenceOptions = [
  {
    key: 'occurrenceProtocol',
    value: 'Protocolo (Ocorrência)',
    checked: false,
  },
  { key: 'serviceProtocol', value: 'Protocolo (Serviço)', checked: false },
  { key: 'date', value: 'Data', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'user', value: 'Aberto Por', checked: false },
  { key: 'type', value: 'Tipo', checked: false },
  { key: 'interccurrence', value: 'Intercorrência', checked: false },
  { key: 'sourceCity', value: 'Cidades Origem', checked: false },
  { key: 'destinationCity', value: 'Cidades Destino', checked: false },
  { key: 'sourceHubRegional', value: 'Regional Hub Origem', checked: false },
  {
    key: 'destinationHubRegional',
    value: 'Regional Hub Destino',
    checked: false,
  },
  { key: 'sourceHub', value: 'Hub Origem', checked: false },
  { key: 'destinationHub', value: 'Hub Destino', checked: false },
  { key: 'serviceStep', value: 'Etapa do Serviço', checked: false },
]

const occurrenceOrderOptions = [
  { key: 'occurrenceProtocol', value: 'Protocolo (Ocorrência)' },
]

export function OccurrenceTable({
  occurrences,
  budgets,
  handleRefetchTableData
}: OccurrenceTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE
  const [searchedOccurrences, setSearchedOccurrences] = useState<
    Occurrence[]
  >([])

  const [slicedOccurrences, setSlicedOccurrences] = useState<Occurrence[]>(
    [],
  )

  const [dateFiltered, setDateFiltered] = useState('')
  const [customerFiltered, setCustomerFiltered] = useState('')
  const [userFiltered, setUserFiltered] = useState('')
  const [typeFiltered, setTypeFiltered] = useState('')
  const [intercurrenceFiltered, setIntercurrenceFiltered] = useState('')
  const [occurrenceProtocolFiltered, setOccurrenceProtocolFiltered] =
    useState('')
  const [serviceProtocolFiltered, setServiceProtocolFiltered] = useState('')
  const [sourceCityFiltered, setSourceCityFiltered] = useState('')
  const [destinationCityFiltered, setDestinationCityFiltered] = useState('')
  const [sourceHubRegionalFiltered, setSourceHubRegionalFiltered] = useState('')
  const [destinationHubRegionalFiltered, setDestinationHubRegionalFiltered] =
    useState('')
  const [serviceStepFiltered, setServiceStepFiltered] = useState('')
  const [sourceHubsFiltered, setSourceHubsFiltered] = useState('')
  const [destinationHubsFiltered, setDestinationHubsFiltered] = useState('')
  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { userLogged } = useAuth()
  const {
    deleteOccurrence: {
      mutateAsync: deleteOccurrence
    }
  } = useOccurrenceFunctions()

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedOccurrences?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(occurrenceOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(occurrenceOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      if (userLogged?.user_type === 'CLIENTE') {
        const occurrencesFilteredByCustomerId = occurrences
          .filter(
            (occurrence) => occurrence?.customer_id === userLogged?.customer_id,
          )
          .filter((occurrence) => occurrence.type === 'CLIENTE')
          .filter((occurrence) => occurrence.step !== 'OCCURRENCE-FINALIZED')
        return setSlicedOccurrences(occurrencesFilteredByCustomerId)
      }

      if (userLogged?.user_type === 'COLETADOR') {
        const occurrencesFilteredByUserId = occurrences
          .filter(
            (occurrence) =>
              occurrence.source_collector_id === userLogged?.collector_id ||
              occurrence.destination_collector_id === userLogged?.collector_id,
          )
          .filter(
            (occurrence) =>
              occurrence.type === 'COLETADOR' ||
              occurrence.type === 'MOTORISTA',
          )
          .filter((occurrence) => occurrence.step !== 'OCCURRENCE-FINALIZED')
        return setSlicedOccurrences(occurrencesFilteredByUserId)
      }

      if (userLogged?.user_type === 'MOTORISTA') {
        const occurrencesFilteredByUserId = occurrences
          .filter((occurrence) => occurrence.owner === userLogged?.id)
          .filter((occurrence) => occurrence.type === 'MOTORISTA')
          .filter((occurrence) => occurrence.step !== 'OCCURRENCE-FINALIZED')
        return setSlicedOccurrences(occurrencesFilteredByUserId)
      }

      const occurrencesDontFinished = occurrences.filter(
        (occurrence) => occurrence.step !== 'OCCURRENCE-FINALIZED',
      )
      setSlicedOccurrences(occurrencesDontFinished)
    }
    run()
  }, [itemLimit, currentPage, offset, userLogged, occurrences])

  useEffect(() => {
    function run() {
      const newSlicedOccurrences = slicedOccurrences?.filter((occurrence) => {
        const sourceHubInfo =
          budgets
            .filter(
              (budget) =>
                budget.id ===
                occurrence.occurrenceIDService.serviceIDRequested.budget_id,
            )
            .map((budget) => {
              return {
                regional: String(budget.sourceHubIDBudget.regional),
                name: budget.sourceHubIDBudget.name
              }
            })[0]


        const destinationHubInfo =
          budgets
            .filter(
              (budget) =>
                budget.id ===
                occurrence.occurrenceIDService.serviceIDRequested.budget_id,
            )
            .map((budget) => {
              return {
                regional: String(budget.destinationHubIDBudget.regional),
                name: budget.destinationHubIDBudget.name
              }
            })[0]

        const occurrenceProtocolFilter = searchBoxFilter(
          occurrence.occurrence_protocol.toString(),
          occurrenceProtocolFiltered,
        )
        const serviceProtocolFilter = searchBoxFilter(
          occurrence.occurrenceIDService.protocol.toString(),
          serviceProtocolFiltered,
        )
        const dateFilter = searchBoxFilter(
          `${formatDateToFrontTable(occurrence.createdAt)}`,
          dateFiltered,
        )
        const customerFilter = searchBoxFilter(
          occurrence.occurrenceIDCustomer.trading_firstname,
          customerFiltered,
        )
        const userFilter = searchBoxFilter(
          `${occurrence.occurrenceIDUser.firstname} ${occurrence.occurrenceIDUser.lastname}`,
          userFiltered,
        )
        const typeFilter = searchBoxFilter(occurrence.type, typeFiltered)
        const intercurrenceFilter = searchBoxFilter(
          occurrence.intercurrence,
          intercurrenceFiltered,
        )
        const sourceCityFilter = searchBoxFilter(
          occurrence.source_city ? occurrence.source_city.join(',') : '-',
          sourceCityFiltered,
        )
        const destinationCityFilter = searchBoxFilter(
          occurrence.destination_city
            ? occurrence.destination_city.join(',')
            : '-',
          destinationCityFiltered,
        )
        const sourceHubRegionalFilter = searchBoxFilter(
          sourceHubInfo?.regional,
          sourceHubRegionalFiltered,
        )
        const destinationHubRegionalFilter = searchBoxFilter(
          destinationHubInfo?.regional,
          destinationHubRegionalFiltered,
        )
        const serviceStepFilter = searchBoxFilter(
          serviceHandleStep(occurrence.service_step),
          serviceStepFiltered,
        )

        const sourceHubFilter = searchBoxFilter(
          sourceHubInfo?.name,
          sourceHubsFiltered,
        )
        const destinationHubFilter = searchBoxFilter(
          destinationHubInfo.name,
          destinationHubsFiltered,
        )

        if (
          dateFiltered === '' &&
          customerFiltered === '' &&
          userFiltered === '' &&
          typeFiltered === '' &&
          intercurrenceFiltered === '' &&
          occurrenceProtocolFiltered === '' &&
          serviceProtocolFiltered === '' &&
          sourceCityFiltered === '' &&
          destinationCityFiltered === '' &&
          serviceStepFiltered === '' &&
          sourceHubRegionalFiltered === '' &&
          destinationHubRegionalFiltered === '' &&
          sourceHubsFiltered === '' &&
          destinationHubsFiltered === ''
        )
          return occurrence

        return (
          dateFilter &&
          customerFilter &&
          userFilter &&
          typeFilter &&
          intercurrenceFilter &&
          occurrenceProtocolFilter &&
          serviceProtocolFilter &&
          sourceCityFilter &&
          destinationCityFilter &&
          serviceStepFilter &&
          sourceHubRegionalFilter &&
          destinationHubRegionalFilter &&
          sourceHubFilter &&
          destinationHubFilter
        )
      })

      if (orderOptionSelected.length > 0) {
        const slicedOccurrencesByOrder = newSlicedOccurrences.sort(
          (a, b) =>
            (orderOptionSelected.includes('occurrenceProtocol') &&
              Number(a.occurrence_protocol) - Number(b.occurrence_protocol)) ||
            0,
        )
        setSearchedOccurrences(slicedOccurrencesByOrder)
      } else {
        setSearchedOccurrences(newSlicedOccurrences)
      }
    }
    run()
  }, [
    dateFiltered,
    customerFiltered,
    userFiltered,
    typeFiltered,
    intercurrenceFiltered,
    occurrenceProtocolFiltered,
    serviceProtocolFiltered,
    slicedOccurrences,
    sourceCityFiltered,
    destinationCityFiltered,
    sourceHubRegionalFiltered,
    destinationHubRegionalFiltered,
    serviceStepFiltered,
    budgets,
    orderOptionSelected,
    sourceHubsFiltered,
    destinationHubsFiltered
  ])

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  async function handleDeleteOccurrence(occurrenceId: string) {
    await swal({
      title: 'Deseja excluir uma Ocorrência?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willEdit) => {
      if (willEdit) {
        await deleteOccurrence(occurrenceId)
      } else {
        swal('Ação cancelada com êxito!')
      }
    })
  }

  return (
    <>
      {slicedOccurrences?.length <= 0 ? (
        <Alert
          status="info"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Oops!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Não há dados para mostrar aqui!
          </AlertDescription>
        </Alert>
      ) : (
        <>
            <Flex gap={2}>
              <TableFilterButton />
              <Button colorScheme="blue" onClick={handleRefetchTableData}>
                <Icon as={FaUndoAlt} />{' '}
              </Button>
            </Flex>
            <Stack mt="4" direction="column">
              {filterOptions
                .filter((option) => option.checked === true)
                .map((option) => (
                  <Fragment key={option.key}>
                    <Flex>
                      {option.key === 'occurrenceProtocol' && (
                        <SearchBox
                          placeholder="Buscar Protocolo Ocorrência..."
                          handleSearch={(e) =>
                            setOccurrenceProtocolFiltered(e.target.value)
                          }
                        />
                      )}
                      {option.key === 'serviceProtocol' && (
                        <SearchBox
                          placeholder="Buscar Protocolo Serviço..."
                          handleSearch={(e) =>
                            setServiceProtocolFiltered(e.target.value)
                          }
                        />
                      )}
                      {option.key === 'date' && (
                        <SearchBox
                          placeholder="Buscar Data..."
                          handleSearch={(e) => setDateFiltered(e.target.value)}
                        />
                      )}
                      {option.key === 'customer' && (
                        <SearchBox
                          placeholder="Buscar Cliente..."
                          handleSearch={(e) =>
                            setCustomerFiltered(e.target.value)
                          }
                        />
                      )}
                      {option.key === 'user' && (
                        <SearchBox
                          placeholder="Buscar Usuário..."
                          handleSearch={(e) => setUserFiltered(e.target.value)}
                        />
                      )}
                      {option.key === 'type' && (
                        <SearchBox
                          placeholder="Buscar Tipo..."
                          handleSearch={(e) => setTypeFiltered(e.target.value)}
                        />
                      )}
                      {option.key === 'interccurrence' && (
                        <SearchBox
                          placeholder="Buscar Intercorrência..."
                          handleSearch={(e) =>
                            setIntercurrenceFiltered(e.target.value)
                          }
                        />
                      )}
                      {option.key === 'sourceCity' && (
                        <SearchBox
                          placeholder="Buscar Cidades Origem..."
                          handleSearch={(e) =>
                            setSourceCityFiltered(e.target.value)
                          }
                        />
                      )}
                      {option.key === 'destinationCity' && (
                        <SearchBox
                          placeholder="Buscar Cidades Destino..."
                          handleSearch={(e) =>
                            setDestinationCityFiltered(e.target.value)
                          }
                        />
                      )}
                      {option.key === 'sourceHubRegional' && (
                        <SearchBox
                          placeholder="Buscar Regional Hub Origem..."
                          handleSearch={(e) =>
                            setSourceHubRegionalFiltered(e.target.value)
                          }
                        />
                      )}
                      {option.key === 'destinationHubRegional' && (
                        <SearchBox
                          placeholder="Buscar Regional Hub Destino..."
                          handleSearch={(e) =>
                            setDestinationHubRegionalFiltered(e.target.value)
                          }
                        />
                      )}
                      {option.key === 'sourceHub' && (
                        <SearchBox
                          placeholder="Buscar Hub Origem..."
                          handleSearch={(e) =>
                            setSourceHubsFiltered(e.target.value)
                          }
                        />
                      )}
                      {option.key === 'destinationHub' && (
                        <SearchBox
                          placeholder="Buscar Hub Destino..."
                          handleSearch={(e) =>
                            setDestinationHubsFiltered(e.target.value)
                          }
                        />
                      )}
                      {option.key === 'serviceStep' && (
                        <SearchBox
                          placeholder="Buscar Etapa Serviço..."
                          handleSearch={(e) =>
                            setServiceStepFiltered(e.target.value)
                          }
                        />
                      )}
                    </Flex>
                  </Fragment>
                ))}
            </Stack>
            <Box overflowX="auto" w="100%">
              <Table colorScheme="gray" variant="striped" size="sm">
                <Thead>
                  <Tr>
                    {occurrenceOptions.map((option) => (
                      <Th key={option.key} fontSize="12" color="blue.900">
                        {option.value.toUpperCase()}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {searchedOccurrences
                    .slice(offset, offset + Number(itemLimit))
                    .map((occurrence, index) => {
                      const sourceHubInfo =
                        budgets
                          .filter(
                            (budget) =>
                              budget.id ===
                              occurrence.occurrenceIDService.serviceIDRequested
                                .budget_id,
                          )
                          .map((budget) => {
                            return {
                              regional: budget.sourceHubIDBudget.regional,
                              name: budget.sourceHubIDBudget.name
                            }
                          })[0]


                      const destinationHubInfo =
                        budgets
                          .filter(
                            (budget) =>
                              budget.id ===
                              occurrence.occurrenceIDService.serviceIDRequested
                                .budget_id,
                          )
                          .map(
                            (budget) => {
                              return {
                                regional: budget.destinationHubIDBudget.regional,
                                name: budget.destinationHubIDBudget.name
                              }
                            }
                          )[0]


                      return (
                        <Tr key={index}>
                          <Td fontSize="12">{occurrence.occurrence_protocol}</Td>
                          <Td fontSize="12">
                            {occurrence.occurrenceIDService.protocol}
                          </Td>
                          <Td fontSize="12">
                            {formatDateToFrontTable(occurrence.createdAt)}
                          </Td>
                          <Td fontSize="12">
                            {occurrence.occurrenceIDCustomer.trading_firstname.toUpperCase()}
                          </Td>
                          <Td fontSize="12">
                            {`${occurrence.occurrenceIDUser.firstname} ${occurrence.occurrenceIDUser.lastname}`.toUpperCase()}
                          </Td>
                          <Td fontSize="12">{occurrence.type.toUpperCase()}</Td>
                          <Td fontSize="12">
                            {occurrence.intercurrence !== null
                              ? occurrence.intercurrence.toUpperCase()
                              : '-'}
                          </Td>
                          <Td fontSize="12">
                            {occurrence.source_city !== null
                              ? occurrence.source_city.join(',')
                              : '-'}
                          </Td>
                          <Td fontSize="12">
                            {occurrence.destination_city !== null
                              ? occurrence.destination_city.join(',')
                              : '-'}
                          </Td>
                          <Td fontSize="12">{sourceHubInfo?.regional}</Td>
                          <Td fontSize="12">{destinationHubInfo?.regional}</Td>
                          <Td fontSize="12">{sourceHubInfo?.name}</Td>
                          <Td fontSize="12">{destinationHubInfo?.name}</Td>
                          <Td fontSize="12">
                            {serviceHandleStep(occurrence.service_step)}
                          </Td>
                          <Td isNumeric>
                            {userLogged !== null && (
                              // <>
                              //   {userLogged.permissions.includes("validate-occurrence") && (
                              //     <Link to={`/ocorrencia/validar/procedencia/${occurrence.id}`}>
                              //       <Icon cursor="pointer" as={FiCheckCircle} fontSize="20" ml="4" mt={["2", "2", "0"]} />
                              //     </Link>
                              //   )}
                              //   {userLogged.permissions.includes("remove-occurrence") && (
                              //     <Icon cursor="pointer" as={FiTrash2} fontSize="20" ml="4" mt={["2", "2", "0"]} onClick={() => handleDeleteOccurrence(occurrence.id)} />
                              //   )}
                              // </>
                              <>
                                {userLogged.user_type !== 'COLETADOR' &&
                                  userLogged.user_type !== 'CLIENTE' &&
                                  userLogged.user_type !== 'MOTORISTA' && (
                                    <Link
                                      to={`/ocorrencia/validar/procedencia/${occurrence.id}`}
                                    >
                                      <Icon
                                        cursor="pointer"
                                        as={FiCheckCircle}
                                        fontSize="20"
                                        ml="4"
                                        mt={['2', '2', '0']}
                                      />
                                    </Link>
                                  )}
                                {userLogged.user_type !== 'COLETADOR' &&
                                  userLogged.user_type !== 'CLIENTE' &&
                                  userLogged.user_type !== 'MOTORISTA' && (
                                    <Icon
                                      cursor="pointer"
                                      as={FiTrash2}
                                      fontSize="20"
                                      ml="4"
                                      mt={['2', '2', '0']}
                                      onClick={() =>
                                        handleDeleteOccurrence(occurrence.id)
                                      }
                                    />
                                  )}
                              </>
                            )}
                          </Td>
                        </Tr>
                      )
                    })}
                </Tbody>
              </Table>
            </Box>
            <Pagination
              handlePageChange={handlePageChange}
              pagesQuantity={pagesCount}
              pages={pages}
              currentPage={currentPage}
            />
          </>
      )}
        </>
      )
      }
