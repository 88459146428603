import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Button,
  Stack,
  Spinner,
  FormControl,
  FormLabel,
  Text,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useEffect, useContext, useState } from 'react'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, NestedValue, useForm } from 'react-hook-form'
import { Input } from '../../components/Inputs/Input'
import { Select } from '../../components/Inputs/SelectInput'
import { negative_positive, situation } from '../../utils/customLists'
import { CheckboxUser } from '../../components/Inputs/CheckboxUser'
import { handlePermissions } from '../../utils/UserFunctions/handlePermissions'
import swal from 'sweetalert'
import { UserContext } from '../../contexts/UserContext'
import { AuthContext } from '../../contexts/AuthContext'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useCustomer } from '../../hooks/customer/useCustomer'
import { useDriver } from '../../hooks/driver/useDriver'
import { usePermission } from '../../hooks/permission/usePermission'
import { useUser } from '../../hooks/user/useUser'
import { IDriverProps } from '../../services/getFunctions/driver/getDrivers'
import { useProfile } from '../../hooks/profile/useProfile'
import { useCollectors } from 'hooks/collector/useCollectors'
import { GeneralCheckbox } from 'components/Inputs/GeneralCheckbox'
import { useLLMUsers } from 'hooks/user/useLLMUsers'
import AsyncSelect from 'react-select/async';

interface FormInputProps {
  user_type: string
  situation: string
  email: string
  firstname: string
  lastname: string
  loglife_employee: string
  password: string
  permissions: NestedValue<string[]>
  customers: string[]
  collector_id: string | null
  driver_id: string | null
  substitute_id: string
  collectors_ids: {
    label: string
    value: string
  }[]
}

interface IUserTypesToSelectProps {
  id: string
  name: string
}

interface IUserFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
  submit: (values: FormInputProps) => Promise<void>
}

const userSchema = yup.object().shape({
  user_type: yup.string().required('Campo Obrigatório'),
  situation: yup.string().required('Campo Obrigatório'),
  email: yup
    .string()
    .email('Formato de e-mail inválido')
    .required('Campo Obrigatório'),
  firstname: yup.string().required('Campo Obrigatório'),
  lastname: yup.string().required('Campo Obrigatório'),
  loglife_employee: yup.string().required('Campo Obrigatório'),
  password: yup.string(),
  permissions: yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return yup.array().nullable()
      case 'string':
        return yup.string().nullable()
      default:
        return yup.array().nullable()
    }
  }),
  customers: yup.mixed().when('user_type', {
    is: 'CLIENTE',
    then: yup.array().min(1, 'Campo obrigatório').required('Campo Obrigatório'),
  }),
  collector_id: yup.string().when('user_type', {
    is: 'MOTORISTA',
    then: yup.string().required('Campo Obrigatório'),
  }),
  driver_id: yup.string().when('user_type', {
    is: 'MOTORISTA',
    then: yup.string().required('Campo Obrigatório'),
  }),
  collectors_ids: yup.mixed().when('user_type', {
    is: 'COLETADOR',
    then: yup.array().required('Campo Obrigatório').min(1),
  }),
})

export function UserForm({
  slug,
  id,
  isDisabled = false,
  href,
  title,
  action,
  submit,
}: IUserFormProps) {
  const [defaultOption, setDefaultOption] = useState<string[]>([])
  const [driversFilteredByCollector, setDriversFilteredByCollector] = useState<
    IDriverProps[]
  >([])
  const [userTypesByProfiles, setUserTypesByProfiles] = useState<
    IUserTypesToSelectProps[]
  >([])
  const [isProfilePermissionsLoading, setIsProfilePermissionsLoading] =
    useState(false)
  const {
    permissions: { data: permissions, isLoading: isPermissionLoading },
  } = usePermission(null, true, false)
  const {
    profiles: { data: profiles, isLoading: isProfilesLoading },
  } = useProfile(null, false)
  const {
    user: { data: user, isLoading: isUserLoading },
  } = useUser(slug !== 'adicionar' ? id || null : null, false, false)

  const { resetUserPassword } = useContext(UserContext)
  const { userLogged } = useContext(AuthContext)

  const [isLoadingPage, setIsLoadingPage] = useState(true)

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    resolver: yupResolver(userSchema),
  })

  const isTypeOfUserSelected = watch('user_type')
  const collectorSelected = watch('collector_id')
  const isLLMUser = watch('loglife_employee') === 'SIM'

  const {
    data: collectors, isLoading: isCollectorsLoading,
  } = useCollectors({
    queryOptions: {
      enabled: isTypeOfUserSelected === 'COLETADOR' || isTypeOfUserSelected === 'MOTORISTA'
    }
  })

  const {
    drivers: { data: drivers, isLoading: isDriverLoading },
  } = useDriver(null, isTypeOfUserSelected === 'MOTORISTA', false)

  const {
    customers: { data: customers, isLoading: isCustomerLoading },
  } = useCustomer(null, isTypeOfUserSelected === 'REMETENTE' || isTypeOfUserSelected === 'CLIENTE', false)

  const {
    data: llmUsersData,
    isFetching: isFetchingLlmUsersData
  } = useLLMUsers({
    queryOptions: {
      enabled: isLLMUser
    }
  })

  const substituteUsersOptions = llmUsersData?.users.map((user) => {
    return {
      key: user.id,
      value: user.id,
      showOption: user.firstname + ' ' + user.lastname
    }
  }).sort((a, b) => a.showOption.localeCompare(b.showOption))

  useEffect(() => {
    function run() {
      if (
        !isCollectorsLoading &&
        !isDriverLoading &&
        !isPermissionLoading &&
        !isCustomerLoading &&
        !isUserLoading &&
        !isProfilesLoading
      ) {
        setIsLoadingPage(false)
      }
    }
    run()
  }, [
    isCollectorsLoading,
    isDriverLoading,
    isPermissionLoading,
    isCustomerLoading,
    isUserLoading,
    isProfilesLoading,
  ])

  useEffect(() => {
    function run() {
      if (collectorSelected && drivers) {
        const driversFiltered = drivers.filter(
          (driver) => driver.collector_id === collectorSelected,
        )
        setDriversFilteredByCollector(driversFiltered)
      }
    }
    run()
  }, [drivers, collectorSelected])

  useEffect(() => {
    function run() {
      const handledPermissions = handlePermissions(isTypeOfUserSelected)

      if (slug === 'adicionar') {
        if (
          isTypeOfUserSelected !== 'COLETADOR' &&
          isTypeOfUserSelected !== 'CLIENTE' &&
          isTypeOfUserSelected !== 'MOTORISTA' &&
          isTypeOfUserSelected !== 'REMETENTE'
        ) {
          setValue('loglife_employee', "SIM")
        } else {
          setValue('loglife_employee', "NÃO")
        }

        setValue('situation', 'ATIVO')
        if (handledPermissions !== null) {
          setValue('permissions', handledPermissions)
        }
      }
    }
    run()
  }, [slug, setValue, isTypeOfUserSelected])

  useEffect(() => {
    function run() {
      if (!!user && driversFilteredByCollector) {
        setValue('driver_id', user.driver_id ? user.driver_id : '')
      }
    }
    run()
  }, [setValue, driversFilteredByCollector, user])

  useEffect(() => {
    if (profiles) {
      const profilesUserTypes = profiles.map((profile, index) => {
        return { id: profile.id, name: profile.user_type }
      })
      setUserTypesByProfiles(profilesUserTypes)
    }
  }, [profiles])


  useEffect(() => {
    function run() {
      if (slug === 'editar' || slug === 'visualizar') {
        if (user && userTypesByProfiles) {
          setDefaultOption(user.permissions)

          setValue('situation', user.situation)
          setValue('loglife_employee', user.loglife_employee === true ? "SIM" : "NÃO")
          setValue('email', user.email)
          setValue('firstname', user.firstname)
          setValue('lastname', user.lastname)
          setValue('password', user.password)
          setValue('permissions', user.permissions)

          if (user.collector_id) {
            setValue('collector_id', user.collector_id)
          }

          if (user.collectors && user.collectors.length) {
            user.collectors.forEach((collector, index) => {
              setValue(`collectors_ids.${index}`, { label: collector.collector.trading_name, value: collector.collector.id })
            })
          }

          if (user.customers) {
            setValue('customers', user.customers.map((customer) => customer.id))
          }
          if (user.collectors_ids?.length > 0) {

            const collectorsIds = collectors
              .filter(collector => user.collectors_ids.includes(collector.id))
              .map(collector => ({
                value: collector.id,
                label: collector.trading_name.toUpperCase()
              }));

            setValue('collectors_ids', collectorsIds)
          }
        }
      }
    }

    run()
  }, [setValue, slug, user, userTypesByProfiles, isLoadingPage, isProfilesLoading, collectors])

  useEffect(() => {
    if (!isProfilesLoading && user) {
      setValue('user_type', user.user_type)
    }
  }, [user, setValue, isProfilesLoading])

  useEffect(() => {
    if (user) {
      setValue('substitute_id', user.substitute_id ? user.substitute_id : '')
    }
  }, [isFetchingLlmUsersData, user, setValue])

  useEffect(() => {
    if (profiles) {
      setIsProfilePermissionsLoading(true)
      const profileByUserType = profiles.find(
        (profile) => profile.user_type === isTypeOfUserSelected,
      )
      if (profileByUserType) {
        setDefaultOption(profileByUserType.permissions)
        setTimeout(() => {
          setIsProfilePermissionsLoading(false)
        }, 1500)
      }
    }
  }, [isTypeOfUserSelected, setValue, profiles])

  async function handleResetUserPassword() {
    await swal({
      title: 'Deseja voltar a senha para a padrão?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then((willChangePassword) => {
      if (willChangePassword) {
        resetUserPassword(user ? user.id : '')
      } else {
        swal('Ação cancelada com êxito!')
      }
    })
  }

  const customersCheckboxOptions = customers?.filter(customer => {
    return userLogged?.user_type === 'CLIENTE'
      ? customer.id === userLogged?.customer_id
      : customer
  })?.map((customer) => {
    return {
      key: customer.id,
      value: customer.id,
      showOption: customer.trading_firstname
    }
  })

  const userCustomers = user?.customers.map((customer) => customer.id)

  async function promiseOptionsCollectors(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    return collectors
      ?.filter((collector) => collector.situation === 'ATIVO')
      ?.filter((collector) => !inputValue || collector.trading_name.toLowerCase().includes(inputValue.toLowerCase()))
      ?.sort((a, b) => a.trading_name.localeCompare(b.trading_name))
      ?.map((collector) => {
        return { value: collector.id, label: collector.trading_name.toUpperCase() };
      });
  }



  if (isLoadingPage) {
    return <GeneralContentLoading />
  }

  if (!isTypeOfUserSelected) {
    return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(submit)}
          noValidate
        >
          <Heading size="lg" fontFamily="poppins">
            {title}
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          <Stack direction={["column", "column", "row"]} spacing="4" >
            <Select
              {...register('user_type')}
              name="user_type"
              label="Tipo de usuário"
              error={errors.user_type}
              options={userTypesByProfiles.map((profile) => {
                return {
                  key: profile.id,
                  value: profile.name,
                  showOption: profile.name,
                }
              })}
              isDisabled={false}
              placeholder="Selecione uma opção..."
              required
            />
          </Stack>

          <Flex mt="8" justify="flex-end">
            <HStack>
              {slug === 'visualizar' ? (
                <Link to={href}>
                  <Button
                    type="button"
                    colorScheme="blue"
                    isLoading={isSubmitting}
                    loadingText="Carregando"
                  >
                    {action}
                  </Button>
                </Link>
              ) : (
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Carregando"
                >
                  {action}
                </Button>
              )}

              <Link to="/usuarios">
                <Button type="button" colorScheme="gray">
                  Lista de Usuários
                </Button>
              </Link>
            </HStack>
          </Flex>
        </Box>
      </Flex>

    )
  } else {
    return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(submit)}
          noValidate
        >
          <Heading size="lg" fontFamily="poppins">
            {title}
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Stack w="full" direction={["column", "column", "row"]} spacing="4" >
              <Select
                {...register('user_type')}
                name="user_type"
                label="Tipo de usuário"
                error={errors.user_type}
                options={userTypesByProfiles.map((profile) => {
                  return {
                    key: profile.id,
                    value: profile.name,
                    showOption: profile.name,
                  }
                })}
                isDisabled={false}
                placeholder="Selecione uma opção..."
                required
              />
            </Stack>

            <Stack w="full" direction={["column", "column", "row"]} spacing="4">
              <Select
                {...register('loglife_employee')}
                name="loglife_employee"
                label="Funcionário LLM"
                error={errors.loglife_employee}
                isDisabled={slug === 'visualizar' || slug === 'adicionar'}
                negative_positive={negative_positive}
                placeholder="Selecione uma opção..."
                required
              />

              {isFetchingLlmUsersData ? (
                <Spinner />
              ) : (
                <Select
                  {...register("substitute_id")}
                  name="substitute_id"
                  error={errors.substitute_id}
                  options={substituteUsersOptions}
                  label="Usuário suplente"
                  placeholder="Selecione um usuário..."
                />
              )}
            </Stack>
          </Stack>

          <Stack mt="4">
            {slug !== 'adicionar' && (
              <Select
                {...register('situation')}
                name="situation"
                label="Situação"
                error={errors.situation}
                isDisabled={isDisabled}
                situations={situation}
                placeholder="Selecione uma opção..."
                required
              />
            )}
          </Stack>
          {isTypeOfUserSelected === 'COLETADOR' && (
            <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
              <FormControl isInvalid={!!errors?.collectors_ids}>
                <FormLabel w="full" fontSize='sm'>
                  Coletador(es)
                  <Text as="sup" color="red.500">*</Text>

                </FormLabel>
                <Controller
                  control={control}
                  name="collectors_ids"
                  render={({ field }) => (
                    <AsyncSelect
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                      cacheOptions
                      defaultOptions
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '2px',
                          borderRadius: '6px',
                          borderColor: 'gray.200',
                          border: state.isFocused ? 'none' : '',
                          boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                        }),
                        placeholder: (base, props) => ({
                          ...base,
                          color: 'blackAlpha.900'
                        }),
                        dropdownIndicator(base, props) {
                          return {
                            ...base,
                            color: 'blackAlpha.900',
                            width: '34px',
                          }
                        },
                      }}
                      noOptionsMessage={() => 'Não há coletadores cadastrados ou encontrados!'}
                      placeholder="Selecione um coletador..."
                      loadOptions={promiseOptionsCollectors}
                      isClearable={true}
                      isMulti
                      isDisabled={isDisabled}
                    />
                  )}
                />
              </FormControl>
            </Stack>
          )}

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('email')}
              name="email"
              label="E-mail"
              placeholder="email@exemplo.com"
              error={errors.email}
              isDisabled={isDisabled}
              required
            />

            {isTypeOfUserSelected === 'MOTORISTA' && (
              <Select
                {...register('collector_id')}
                name="collector_id"
                label="Pertence ao Coletador"
                error={errors.collector_id}
                collectors={collectors}
                placeholder="Selecione uma opção..."
                isDisabled={isDisabled}
                required
              />
            )}
          </Stack>

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Stack
              w="100%"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Input
                {...register('firstname')}
                name="firstname"
                label="Nome"
                error={errors.firstname}
                isDisabled={isDisabled}
                required
              />

              <Input
                {...register('lastname')}
                name="lastname"
                label="Sobrenome"
                error={errors.lastname}
                isDisabled={isDisabled}
                required
              />
            </Stack>

            {isTypeOfUserSelected === 'MOTORISTA' &&
              collectorSelected !== '' && (
                <Select
                  {...register('driver_id')}
                  name="driver_id"
                  label="Pertence ao Motorista"
                  error={errors.driver_id}
                  drivers={driversFilteredByCollector}
                  isDisabled={isDisabled}
                  placeholder="Selecione uma opção..."
                  required
                />
              )}
          </Stack>


          {(isTypeOfUserSelected === 'CLIENTE' ||
            isTypeOfUserSelected === 'REMETENTE') && (
              <Stack mt="4">
                <Controller
                  control={control}
                  name="customers"
                  render={({ field }) => (
                    <GeneralCheckbox
                      name="customers"
                      onCheckboxChange={field.onChange}
                      checkboxOptions={customersCheckboxOptions}
                      label="Cliente(s)"
                      defaultCheckedOptions={userCustomers}
                    />
                  )}
                />
              </Stack>
            )}

          {slug !== 'adicionar' && (
            <>
              {isProfilePermissionsLoading ? (
                <Flex>
                  <Spinner />
                </Flex>
              ) : (
                <Stack mt="4">
                  <CheckboxUser
                    {...register('permissions')}
                    name="permissions"
                    label="Permissões"
                    permissions={permissions}
                    defaultOption={defaultOption}
                    isDisabled={isDisabled}
                  />
                </Stack>
              )}
            </>
          )}

          <Flex mt="8" justify="flex-end">
            <HStack>
              {slug === 'visualizar' ? (
                <Link to={href}>
                  <Button
                    type="button"
                    colorScheme="blue"
                    isLoading={isSubmitting}
                    loadingText="Carregando"
                  >
                    {action}
                  </Button>
                </Link>
              ) : (
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Carregando"
                // onClick={handleSubmitForm}
                >
                  {action}
                </Button>
              )}

              {slug === 'editar' &&
                userLogged?.permissions.includes('remove-user') && (
                  <Button
                    colorScheme="yellow"
                    onClick={handleResetUserPassword}
                  >
                    Resetar Senha
                  </Button>
                )}

              <Link to="/usuarios">
                <Button type="button" colorScheme="gray">
                  Lista de Usuários
                </Button>
              </Link>
            </HStack>
          </Flex>
        </Box>
      </Flex>

    )
  }
}
