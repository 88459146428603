import axios from "axios"
import { api } from "services/api"
import { timezonesUsed } from "utils/DateFunctions/timezones"
import { RequestError } from "utils/errors/RequestErrors"


interface Address {
  id: string
  tradingName: string
  branch: string
  street: string
  neighborhood: string
  number: string
  complement: string
  cep: string
  responsibleName: string
  city: {
    id: string
    name: string
    hub: {
      id: string
      name: string
      timezone: keyof typeof timezonesUsed | null
    };
  };
}

interface Customer {
  id: string
  tradingFirstname: string
}

interface Collector {
  id: string
  tradingName: string
}

interface Branch {
  id: string
  nickname: string
}
interface Shipping {
  id: string
  tradingName: string
}

interface Board {
  id: string
  boardVolume: number
  cte: null | string
  tracker: string
  trackerLink: null | string
}


interface RequestedService {
  id: string
  serviceType: 'DEDICADO' | 'FRACIONADO'
  vehicle: string
  observation: string
  deliveryHour: string
  deliveryDate: string
  sourceCollector: Collector
  destinationCollector: Collector
  sourceBranch: Branch
  destinationBranch: Branch
  shipping: Shipping
}

export interface Service {
  id: string
  step: string
  protocol: number
  hasOccurrence: boolean
  sourceAddresses: Address[]
  destinationAddresses: Address[]
  customer: Customer
  requestedService: RequestedService
  board: Board
  deliveries: {
    id: string
    addressId: string
    step: string
  }[]
}

interface GetDeliveriesRelationResponse {
  services: Service[]
  meta: {
    totalPages: number
    count: number
  }
}

interface GetDeliveriesRelationParams {
  currentPage: number
  pageSize: number
  timezoneOffset: number
  protocols?: string
  customers?: string
  deliveryDates?: string
  deliveryHours?: string
  withdrawInfos?: string
  sourceAddresses?: string
  destinationAddresses?: string
  steps?: string
  sourceCollectors?: string
  destinationCollectors?: string
  collectorId?: string
}

export async function getDeliveriesRelation({
  currentPage,
  pageSize,
  timezoneOffset,
  protocols,
  customers,
  deliveryDates,
  deliveryHours,
  withdrawInfos,
  sourceAddresses,
  destinationAddresses,
  steps,
  sourceCollectors,
  destinationCollectors,
  collectorId,
}: GetDeliveriesRelationParams) {
  try {
    const { data } = await api.get<GetDeliveriesRelationResponse>(`/services/relations/deliveries`, {
      params: {
        currentPage,
        pageSize,
        timezoneOffset,
        protocols,
        customers,
        deliveryDates,
        deliveryHours,
        withdrawInfos,
        sourceAddresses,
        destinationAddresses,
        steps,
        sourceCollectors,
        destinationCollectors,
        collectorId,
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
