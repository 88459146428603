import { Box, Heading } from "@chakra-ui/react";

import { useParams } from "react-router-dom";
import { FinishMaintenanceForm } from "./components/FinishMaintenanceForm";

interface Params {
  maintenance_id: string
}

export function FinishMaintenanceService() {

  const { maintenance_id } = useParams<Params>()

  return (

      <Box
        p={["2", "4"]}
        bg="white"
        borderRadius="8px"
      >
        <Heading mt={4} size="md">Finalizar manutenção</Heading>

        <FinishMaintenanceForm maintenanceId={maintenance_id} />

      </Box>

  )
}
