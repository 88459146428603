import axios from "axios"
import { PurchaseOrder } from "hooks/purchaseOrder/dtos/PurchaseOrder"
import { Warehouse } from "hooks/warehouse/dtos/Warehouse"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"
import { Pmc } from "./pmc"
import { PmcProduct } from "./pmcProduct"

interface GetPmcParams {
  pmcId: string
}

export interface GetPmcResponse {
  pmc: Pmc & {
    warehouse: Warehouse,
    purchaseOrders: PurchaseOrder[]
    pmcProducts: PmcProduct[]
  }
}

export async function getPmc({ pmcId }: GetPmcParams) {
  try {
    const response = await api.get<GetPmcResponse>(`/pmcs/${pmcId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
