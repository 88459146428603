import { useContext } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'
import {
  getAllCollectedServices,
  getOneCollectedService,
} from '../../services/getFunctions/getCollectServiceFunctions'

export function useCollectService(
  serviceId: string | null,
  isEnable?: boolean,
  refetchOnFocus?: boolean,
) {
  const { handleLogout, userLogged } = useContext(AuthContext)

  const collectedServices = useQuery(
    'collectedServices',
    getAllCollectedServices,
    {
      enabled: isEnable && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )
  const collectedService = useQuery(
    ['collectedService', serviceId],
    () => getOneCollectedService(serviceId !== null ? serviceId : ''),
    {
      enabled: !!serviceId && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )

  return {
    collectedServices,
    collectedService,
  }
}
