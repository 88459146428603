import axios from "axios"
import { InputProps } from "contexts/InputContext"
import { Collector } from "hooks/collector/dtos/Collector"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"
import { DispatchStockProposal } from "./dispatchStockProposal"


interface GetDispatchStockProposalParams {
  proposalId: string
}

export interface GetDispatchStockProposalResponse {
  proposal: DispatchStockProposal & {
    collector: Collector
    inputs: {
      id: string
      send_quantity: number
      min_stock: number
      current_stock: number
      period_consume: number
      standard_stock: number
      input: InputProps
    }[]
  }
}


export async function getDispatchStockProposal({
  proposalId
}: GetDispatchStockProposalParams) {

  try {
    const { data } = await api.get<GetDispatchStockProposalResponse>(`/dispatch-stock/proposals/${proposalId}`)

    return data

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
