import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useRef, useState } from 'react'

import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { Input } from '../../components/Inputs/Input'
import { FaFileCsv } from 'react-icons/fa'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useSwal } from '../../hooks/swal/useSwal'
import { useAuth } from '../../hooks/auth/useAuth'
import { useHistory } from 'react-router-dom'
import { usePriceFunctions } from '../../hooks/price/usePriceFunctions'
import { FieldError } from 'react-hook-form'

interface FormInputProps {
  csv_file: {} | null
}

const schema = yup.object().shape({
  csv_file: yup
    .mixed()
    .required('Obrigatório')
    .test(
      'fileSize',
      'O arquivo é muito grande para o envio',
      (value) => value && value.size <= 1024 * 1024 * 9,
    ),
})

export function PriceSendFilePage() {
  const [selectedFileCsv, setSelectedFileCsv] = useState<any>('')
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false)

  const { standardMessage, confirmMessage } = useSwal()
  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  const {
    sendPriceFile: {
      mutateAsync: sendPriceFile,
      isSuccess: isSendPriceFileSuccess,
    },
  } = usePriceFunctions()

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      csv_file: null
    }
  })

  useEffect(() => {
    if (!userLogged?.permissions.includes("import-price")) {
      redirectTo("/")
    }
  }, [userLogged, redirectTo])

  useEffect(() => {
    if (isSendPriceFileSuccess) {
      setIsPageLoading(false)
      reset()
      setSelectedFileCsv('')
    }
  }, [isSendPriceFileSuccess, reset])

  function handleSelectFileCsv(e: { target: HTMLInputElement }) {
    if (!e.target.files || e.target.files.length === 0) {
      setValue('csv_file', null)
      setSelectedFileCsv(undefined)
      return
    }

    setValue('csv_file', e.target.files[0])
    setSelectedFileCsv(e.target.files[0])
  }

  const inputRefCsv = useRef<HTMLInputElement | null>(null)
  const handleInputRefCsv = () => inputRefCsv.current?.click()

  async function handleCreateKnitOnBack(values: FormInputProps) {
    const formData = new FormData()

    formData.append('csv_file', values.csv_file as any)

    const hasSendCteLoglifeList = await confirmMessage({
      title: 'Deseja enviar um arquivo para criação de novos preços?',
    })

    if (hasSendCteLoglifeList) {
      setIsPageLoading(true)
      try {
        await sendPriceFile(formData as any)
      } catch {
        setIsPageLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (isPageLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(handleCreateKnitOnBack)}
          noValidate
        >
          <Heading size="lg" fontFamily="poppins">
            Importar Preços
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          <Input
            {...register('csv_file')}
            name="csv_file"
            label="Arquivo Excel"
            type="file"
            accept=".csv"
            ref={inputRefCsv}
            error={errors.csv_file as FieldError}
            hidden
            onChange={handleSelectFileCsv}
            required
          >
            <Flex w="full" align="center" justify="flex-start">
              <Flex align="center">
                <Button
                  onClick={handleInputRefCsv}
                  mr="2"
                  leftIcon={<Icon as={FaFileCsv} />}
                >
                  Upload
                </Button>
                {!!selectedFileCsv && <Text>{selectedFileCsv.name}</Text>}
              </Flex>
            </Flex>
          </Input>

          <Flex align="center" justify="flex-end">
            <Stack direction="row" spacing="12px">
              <Button type="submit" isLoading={isSubmitting} colorScheme="blue">
                Enviar
              </Button>
            </Stack>
          </Flex>
        </Box>
      </Flex>

  )
}
