import { useState } from 'react'
import { useQuery } from 'react-query'
import { getLogsByProtocol } from '../../services/getFunctions/log/getLog'
import { useAuth } from '../auth/useAuth'
import { useToastify } from '../toastify/useToastify'

const useLogByProtocol = () => {
  const [protocol, setProtocol] = useState('')
  const { handleLogout, userLogged } = useAuth()
  const { errorMessage } = useToastify()

  const handleSetProtocolValue = (protocolValue: string) =>
    setProtocol(protocolValue)

  const logByProtocol = useQuery(
    'logByProtocol',
    () => getLogsByProtocol(protocol),
    {
      enabled: !!protocol && !!userLogged,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        errorMessage(error.message)
        handleLogout()
      },
    },
  )
  return { logByProtocol, handleSetProtocolValue }
}

export { useLogByProtocol }
