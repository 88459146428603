import { api } from "services/api"
import request from "axios"
import { QueryKey, useQuery, UseQueryOptions } from "react-query"
import { RequestError } from "utils/errors/RequestErrors"
import { RouteNetworksResponse } from "./dtos/RouteNetwork"
import { useToastify } from "hooks/toastify/useToastify"
import { useAuth } from "hooks/auth/useAuth"

interface RouteNetworkBySourceAndDestinationBranchRouteParams {
  sourceBranch: string
  destinationBranch: string
}

interface GetRouteNetworksBySourceAndDestinationBranchProps {
  routeParams: RouteNetworkBySourceAndDestinationBranchRouteParams
}

async function getRouteNetworksBySourceAndDestinationBranch({
  routeParams
}: GetRouteNetworksBySourceAndDestinationBranchProps) {
  try {
    const { data } = await api.get(`/source-branch/${routeParams.sourceBranch}/destination-branch/${routeParams.destinationBranch}/route-networks`)

    return data
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

type QueryOptions = UseQueryOptions<RouteNetworksResponse, unknown, RouteNetworksResponse, QueryKey>

interface UseRouteNetworksBySourceAndDestinationBranchOptions {
  queryKey?: string
  queryOptions?: QueryOptions
  routeParams: RouteNetworkBySourceAndDestinationBranchRouteParams
}

export function useRouteNetworksBySourceAndDestinationBranch(
  options?: UseRouteNetworksBySourceAndDestinationBranchOptions
) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery(options?.queryKey ?? "routeNetworksBySourceAndDestinationBranch",
    () => getRouteNetworksBySourceAndDestinationBranch({
      routeParams: options.routeParams
    }), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
