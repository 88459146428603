import { Button, Flex, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner } from "@chakra-ui/react";

import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { ProductForm, ProductFormInputs } from "./ProductForm";
import { FormProvider, useForm } from "react-hook-form";
import { useMaterials } from "hooks/material/useMaterials";
import { useGeneralProviders } from "hooks/generalProvider/useGeneralProviders";
import { useWarehouses } from "hooks/warehouse/useWarehouses";
import { useToastify } from "hooks/toastify/useToastify";
import { createProduct } from "api/products/createProduct";
import { useMutation, useQueryClient } from "react-query";

interface CreateProductProps {
  onClose: () => void
}

const createProductSchema = yup.object().shape({
  situation: yup.string().required(),
  material_id: yup.string().required(),
  general_provider_id: yup.string().required(),
  warehouse_id: yup.string().required(),
  measure: yup.string().required(),
  unit_cost: yup.string().required(),
  min_stock: yup.number().required(),
  standard_stock: yup.number().required(),
  current_stock: yup.number().required(),
})

export function CreateProduct({ onClose }: CreateProductProps) {
  const formMethods = useForm<ProductFormInputs>({
    resolver: yupResolver(createProductSchema)
  })

  const { formState: { isSubmitting }, handleSubmit } = formMethods

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const { data: materials, isFetching: isFetchingMaterials } = useMaterials()
  const { data: generalProviders, isFetching: isFetchingGeneralProviders } = useGeneralProviders()
  const { data: warehouses, isFetching: isFetchingWarehouses } = useWarehouses()

  const { mutateAsync: createProductFn } = useMutation({
    mutationFn: createProduct,
    onSuccess(_data, _variables, _context) {

      queryClient.invalidateQueries({ queryKey: 'products' })
      onClose()
    }
  })

  async function handleCreateProduct(values: ProductFormInputs) {
    await promiseMessage(createProductFn({
      body: values
    }), 'Produto criado!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Criar Produto
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        maxH='600px'
        overflowY='scroll'
        onSubmit={handleSubmit(handleCreateProduct)}
      >
        {(isFetchingMaterials && isFetchingGeneralProviders && isFetchingWarehouses) ? (
          <Spinner />
        ) : (
          <FormProvider {...formMethods}>
            <ProductForm
              slug="criar"
              materials={materials}
              generalProviders={generalProviders}
              warehouses={warehouses}
            />
          </FormProvider>
        )}
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
