import { Th, Tr } from "@chakra-ui/react";


export function BoardsRelationsTableHead() {

  return (
    <Tr
      h="40px"
      bg="#38c3fa"
    >
      <Th></Th>
      <Th color="white">Protocolo</Th>
      <Th color="white">Cliente</Th>
      <Th color="white">Remetentes</Th>
      <Th color="white">Data limite de embarque</Th>
      <Th color="white">Horário limite de embarque</Th>
      <Th color="white">Embarque</Th>
      <Th color="white">Destinatários</Th>
      <Th color="white">Coletador de origem</Th>
      <Th color="white">Coletador de destino</Th>
    </Tr >
  )
}
