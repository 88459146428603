import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

export interface Attachment{
  fileName: string
  key: string
}

export interface ServicesValidationsRealTimes{
  protocol: number,
  type: 'collect' | 'delivery'
  street: string
  neighborhood: string
  number: number
  branch: string
  city: string
  cep: string
  volume: number
  arrival_logitude: string
  arrival_latitude: string
  departure_latitude: string
  departure_longitude: string
  arrival_timestamp: string
  responsible: string
  real_arrival_timestamp: string
  update_id: string
  box_photos: string | Attachment[]
  content_declarations: string | Attachment[]
}

export interface GetServicesValidationsRealTimesResponse {
  services: ServicesValidationsRealTimes[]
}

interface GetServicesValidationsRealTimesQueryParams {
  pageSize: string
  currentPage: string

}

export async function getServicesValidationsRealTimes({
  currentPage,
  pageSize,
}: GetServicesValidationsRealTimesQueryParams) {
  try {
    const { data } = await api.get<GetServicesValidationsRealTimesResponse>('/services/validations/real-times', {
      params: {
        currentPage,
        pageSize,
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
