import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import { useState, useEffect, useContext } from 'react'
import { Pagination } from '../../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../../utils/searchBoxFilter'
import { serviceFormatDateToFrontTable } from '../../../../utils/ServiceFunctions/serviceFormatDateToFrontTable'
import {
  CargoReportProps,
  ReportContext,
} from '../../../../contexts/ReportContext'
import { ReportLoading } from '../../../Loading/ReportLoading'

export function CargoTable() {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [isLoading, setIsLoading] = useState(true)

  const [searchedBillingReport, setSearchedBillingReport] = useState<
    CargoReportProps[]
  >([])
  const [slicedCargoReports, setSlicedCargoReports] = useState<
    CargoReportProps[]
  >([])

  const [protocolFiltered, setProtocolFiltered] = useState('')
  const [customerFiltered, setCustomerFiltered] = useState('')
  const [sourceCityFiltered, setCityFiltered] = useState('')
  const [destinationCityFiltered, setDestinationCityFiltered] = useState('')
  const [collectDateFiltered, setCollectDateFiltered] = useState('')
  const [deliveryDateFiltered, setDeliveryDateFiltered] = useState('')
  const [stepFiltered, setStepFiltered] = useState('')

  const { cargoReport, isCargoReportLoaded } = useContext(ReportContext)

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedBillingReport?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function run() {
      if (isCargoReportLoaded) {
        setIsLoading(false)
      }
    }
    run()
  }, [isCargoReportLoaded])

  useEffect(() => {
    async function run() {
      setSlicedCargoReports(cargoReport)
    }
    run()
  }, [itemLimit, currentPage, offset, cargoReport])

  useEffect(() => {
    function run() {
      const newSlicedBillingReport = slicedCargoReports?.filter(
        (cargoReports) => {
          const protocolFilter = searchBoxFilter(
            cargoReports.protocol.toString(),
            protocolFiltered,
          )
          const customerFilter = searchBoxFilter(
            cargoReports.customer,
            customerFiltered,
          )
          const sourceCityFilter = searchBoxFilter(
            cargoReports.sourceCity,
            sourceCityFiltered,
          )
          const destinationCityFilter = searchBoxFilter(
            cargoReports.destinationCity,
            destinationCityFiltered,
          )
          const collectDateFilter = searchBoxFilter(
            serviceFormatDateToFrontTable(cargoReports.collectDate),
            collectDateFiltered,
          )
          const deliveryDateFilter = searchBoxFilter(
            serviceFormatDateToFrontTable(cargoReports.deliveryDate),
            deliveryDateFiltered,
          )
          const stepFilter = searchBoxFilter(cargoReports.step, stepFiltered)

          if (
            customerFiltered === '' &&
            protocolFiltered === '' &&
            sourceCityFiltered === '' &&
            destinationCityFiltered === '' &&
            collectDateFiltered === '' &&
            deliveryDateFiltered === '' &&
            stepFiltered === ''
          ) {
            return cargoReports
          }
          return (
            protocolFilter &&
            customerFilter &&
            sourceCityFilter &&
            destinationCityFilter &&
            collectDateFilter &&
            deliveryDateFilter &&
            stepFilter
          )
        },
      )
      setSearchedBillingReport(newSlicedBillingReport)
    }
    run()
  }, [
    protocolFiltered,
    customerFiltered,
    sourceCityFiltered,
    destinationCityFiltered,
    collectDateFiltered,
    deliveryDateFiltered,
    stepFiltered,
    slicedCargoReports,
  ])

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  if (isLoading) {
    return <ReportLoading />
  }

  return (
    <>
      {cargoReport.length <= 0 ? (
        <Alert
          status="info"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Oops!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Não há dados para mostrar aqui! Tente ajustar os filtros e tente
            novamente 😀!
          </AlertDescription>
        </Alert>
      ) : (
        <>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Protocolo..."
                        handleSearch={(e) =>
                          setProtocolFiltered(e.target.value)
                        }
                      />
                      <Text>PROTOCOLO</Text>
                    </Stack>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Cliente..."
                        handleSearch={(e) =>
                          setCustomerFiltered(e.target.value)
                        }
                      />
                      <Text>CLIENTE</Text>
                    </Stack>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Cidade Origem..."
                        handleSearch={(e) => setCityFiltered(e.target.value)}
                      />
                      <Text>CIDADE DE ORIGEM</Text>
                    </Stack>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Cidade Destino..."
                        handleSearch={(e) =>
                          setDestinationCityFiltered(e.target.value)
                        }
                      />
                      <Text>CIDADE DESTINO</Text>
                    </Stack>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Data Coleta..."
                        handleSearch={(e) =>
                          setCollectDateFiltered(e.target.value)
                        }
                      />
                      <Text>DATA DA COLETA</Text>
                    </Stack>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Data Entrega..."
                        handleSearch={(e) =>
                          setDeliveryDateFiltered(e.target.value)
                        }
                      />
                      <Text>DATA DA ENTREGA</Text>
                    </Stack>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Etapa..."
                        handleSearch={(e) => setStepFiltered(e.target.value)}
                      />
                      <Text>ETAPA</Text>
                    </Stack>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchedBillingReport
                  ?.slice(offset, offset + Number(itemLimit))
                  .map((cargoReport, index) => {
                    return (
                      <Tr key={index}>
                        <Td>{cargoReport.protocol}</Td>
                        <Td>{cargoReport.customer.toUpperCase()}</Td>
                        <Td>{cargoReport.sourceCity.toUpperCase()}</Td>
                        <Td>{cargoReport.destinationCity.toUpperCase()}</Td>
                        <Td>
                          {serviceFormatDateToFrontTable(
                            cargoReport.collectDate,
                          )}
                        </Td>
                        <Td>
                          {serviceFormatDateToFrontTable(
                            cargoReport.deliveryDate,
                          )}
                        </Td>
                        <Td>{cargoReport.step.toUpperCase()}</Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </Box>
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}
