import axios from "axios"
import { api } from "services/api"
import { timezonesUsed } from "utils/DateFunctions/timezones"
import { RequestError } from "utils/errors/RequestErrors"


interface Address {
  id: string
  street: string
  neighborhood: string
  number: number
  complement: string
  cep: string
  tradingName: string
  branch: string
  responsibleName: string
  city: {
    id: string
    name: string
    hub: {
      id: string
      name: string
      timezone: keyof typeof timezonesUsed | null
    };
  };
}

interface Customer {
  id: string
  tradingFirstname: string
}

interface Collector {
  id: string
  tradingName: string
}

interface Branch {
  id: string
  nickname: string
}
interface Shipping {
  id: string
  tradingName: string
}


interface RequestedService {
  id: string
  serviceType: 'DEDICADO' | 'FRACIONADO'
  vehicle: string
  boardHour: string
  boardDate: string
  observation: string
  sourceCollector: Collector
  destinationCollector: Collector
  sourceBranch: Branch
  destinationBranch: Branch
  shipping: Shipping
}

export interface Service {
  id: string
  protocol: number
  step: string
  isDestinationBoardBranchConfirmed: boolean
  isCrossdockingDestinationBoardBranchConfirmed: boolean | null
  hasOccurrence: boolean
  sourceAddresses: Address[]
  destinationAddresses: Address[]
  customer: Customer
  requestedService: RequestedService
}

interface GetBoardsRelationResponse {
  services: Service[]
  meta: {
    totalPages: number
    count: number
  }
}

interface GetBoardsRelationParams {
  currentPage: number
  pageSize: number
  timezoneOffset: number
  destinationAddresses?: string
  sourceCollectors?: string
  destinationCollectors?: string
  collectorId?: string
  customers?: string
  protocols?: string
  boardDates?: string
  boardHours?: string
}

export async function getBoardsRelation({
  currentPage,
  pageSize,
  timezoneOffset,
  destinationAddresses,
  destinationCollectors,
  sourceCollectors,
  customers,
  protocols,
  collectorId,
  boardDates,
  boardHours
}: GetBoardsRelationParams) {
  try {
    const { data } = await api.get<GetBoardsRelationResponse>(`/services/relations/boards`, {
      params: {
        currentPage,
        pageSize,
        timezoneOffset,
        destinationAddresses,
        destinationCollectors,
        sourceCollectors,
        customers,
        protocols,
        collectorId,
        boardDates,
        boardHours
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
