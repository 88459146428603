import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, ModalProps } from "@chakra-ui/react";
import { Bug } from "hooks/bugs/dtos/Bug";
import { ReactNode } from "react";

interface ViewBugModalRootProps extends ModalProps {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  bug: Bug
}

export function ViewBugModalRoot({
  isOpen,
  onClose,
  children,
  bug,
  ...rest
}: ViewBugModalRootProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent
        m="4"
        p="4"
        maxH="600px"
        overflow="auto"
      >
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        {children}
      </ModalContent>
    </Modal>
  )
}
