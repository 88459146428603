import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface EditDispatchStockBody {
  collectorId: string
  destinationCollectorId: string
  shippingId: string
  proposalId: string
  sender: string
  branchId: string
  boardDate: string
  arrivalForecast: string
  withdrawalResponsible: string
  vehicle: string
  invoiceTotalPriceInCents: number
}

interface EditDispatchStockParams {
  dispatchStockId: string
  body: EditDispatchStockBody
}

export async function editDispatchStock({
  dispatchStockId,
  body
}: EditDispatchStockParams) {
  try {
    await api.put(`/dispatch-stocks/${dispatchStockId}`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

