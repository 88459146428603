import { Button, Flex, HStack, Icon, IconButton, Modal, ModalOverlay, Popover, PopoverTrigger, Td, Text, Tooltip, Tr, useDisclosure } from "@chakra-ui/react";
import { Interactions } from "components/Interactions/Interactions";
import { differenceInMinutes, format, isBefore, isSameDay, set } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { Occurrence } from "hooks/occurrences/dtos/Occurrence";
import { Services } from "hooks/services/dtos/InCollectService";
import { interactionStatusColors } from "pages/Dashboard/Llm/components/DelayedCollects";
import { FaCheckCircle, FaClock, FaExclamation, FaEye, FaMapSigns, FaPen, FaPeopleArrows } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IDriverProps } from "services/getFunctions/driver/getDrivers";
import { formatDate } from "utils/DateFunctions/formatDate";
import { RequestedCollectAddressesPopover } from "./RequestedCollectAddressesPopover";

interface InCollectServiceTableRowProps {
  service: Services
  drivers: IDriverProps[]
}

function compareCollectFinalDateWithDateNow(
  collectDate: string,
  collectHourEnd: string
) {
  const [collectEndHours, collectEndMinutes] = formatDate
    .handle(collectHourEnd, 'DateOnlyWithHourMinute').split(':')


  const differenceToNow = differenceInMinutes(
    new Date(),
    set(new Date(collectDate), {
      hours: Number(collectEndHours),
      minutes: Number(collectEndMinutes)
    })
  )

  return differenceToNow > 0
}

function compareCollectDateWithDateNow(
  collectDate: string
) {
  const differenceToNow = differenceInMinutes(
    set(new Date(), {
      hours: 12,
      minutes: 0,
    }),
    set(new Date(collectDate), {
      hours: 12,
      minutes: 0
    })
  )

  return differenceToNow >= 0
}

export function InCollectServiceTableRow({ service, drivers }: InCollectServiceTableRowProps) {
  const { userLogged } = useAuth()
  const isLLMUser = userLogged.loglife_employee

  const { isOpen, onOpen, onClose } = useDisclosure();

  const userHasValidateUnsuccessCollectPermission =
    userLogged?.permissions.includes('validate-unsuccess-collect-service')

  const userHasStartCollectServicePermission =
    userLogged?.permissions.includes('add-collect-service')

  const userHasValidateNewCollectServiceAddressesPermission =
    userLogged?.permissions.includes('validate-new-collect-service-addresses')

  const userHasValidateNewDeliveryServiceAddressesPermission =
    userLogged?.permissions.includes('validate-new-delivery-service-addresses')

  const collectDelayOccurrences = [
    'ATRASO NA COLETA',
    'NO SHOW'
  ]

  const serviceStepsToShowCollectDelayOccurrence = [
    'toCollectService',
    'collectingService',
    'toValidateCollectAddresses',
    'toBoardService',
    'boardingService'
  ]

  const serviceCollectDelayOccurrence =
    service.occurrenceIDService
      .filter(occurrence => collectDelayOccurrences.includes(occurrence.intercurrence))
      .reduce((acc, curr) => {
        return new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr
      }, {} as Occurrence)

  const hasCollectDelayOccurrence = !!serviceCollectDelayOccurrence?.id &&
    serviceStepsToShowCollectDelayOccurrence.includes(service.step)

  const collectDate = serviceCollectDelayOccurrence?.occurrence_date
    ? formatDate.handle(serviceCollectDelayOccurrence.occurrence_date, "DateWithoutHourToShow")
    : formatDate.handle(service.serviceIDRequested.collect_date, "DateWithoutHourToShow")

  const collectHourStart = serviceCollectDelayOccurrence?.occurrence_hour
    ? formatDate.handle(serviceCollectDelayOccurrence.occurrence_hour, "DateOnlyWithHourMinute")
    : formatDate.handle(service.serviceIDRequested.collect_hour_start, "DateOnlyWithHourMinute")

  const collectHourEnd = serviceCollectDelayOccurrence?.occurrence_hour
    ? formatDate.handle(serviceCollectDelayOccurrence.occurrence_hour, "DateOnlyWithHourMinute")
    : formatDate.handle(service.serviceIDRequested.collect_hour_end, "DateOnlyWithHourMinute")

  const deliveryDelayOccurrences = [
    'ATRASO NA ENTREGA',
    'CANCELAMENTO DE VOO',
    'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)',
    'ATRASO NA TRANSFERÊNCIA',
    'ATRASO NA LIBERAÇÃO'
  ]

  const serviceDeliveryDelayOccurrence =
    service.occurrenceIDService
      .filter(occurrence => deliveryDelayOccurrences.includes(occurrence.intercurrence))
      .reduce((acc, curr) => {
        return new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr
      }, {} as Occurrence)

  const deliveryHour = serviceDeliveryDelayOccurrence?.occurrence_hour
    ? formatDate.handle(serviceDeliveryDelayOccurrence.occurrence_hour, "DateOnlyWithHourMinute")
    : formatDate.handle(service.serviceIDRequested.delivery_hour, "DateOnlyWithHourMinute")

  const driversAssigned = []

  if (service.serviceIDRequested.driver_address_assign) {
    service.serviceIDRequested.driver_address_assign.forEach(driverAssigned => {
      const driverAssignedParsed = JSON.parse(driverAssigned)

      driversAssigned.push(driverAssignedParsed.driver_id)
    })
  }
  if (service.serviceIDRequested.driver_provider_assign) {
    service.serviceIDRequested.driver_provider_assign.forEach(driverAssigned => {
      const driverAssignedParsed = JSON.parse(driverAssigned)

      driversAssigned.push(driverAssignedParsed.driver_id)
    })
  }

  const driversAssignedInfo = Array
    .from(new Set(drivers
      ?.filter(driver => {
        return driversAssigned.includes(driver.id)
      })
      .map(driver => {
        return `${driver.firstname} ${driver.lastname}`
      })
    ))
    .join(', ')

  const serviceHasUnsuccessCollectToValidade = service.serviceIDCollect.find(collect => {
    return collect.step === 'VALIDATEUNSUCCESS'
  })

  let serviceHasPendingCollectAddressesToFinishOrStart = true

  const hasSomeCollectServiceInProggress = service.serviceIDCollect.find(collect => {
    return collect.step === 'GOING'
  })

  if (
    service.serviceIDRequested.addresses_requested_to_cancel &&
    service.serviceIDRequested.addresses_requested_to_cancel.length
  ) {
    service.serviceIDRequested.source_address_id =
      service.serviceIDRequested.source_address_id
        .filter(sourceAddress => {
          return !service.serviceIDRequested.addresses_requested_to_cancel.includes(sourceAddress)
        })
  }

  const serviceProviderCount = service.serviceIDRequested.provider_id
    ? 1
    : 0

  if (
    !hasSomeCollectServiceInProggress &&
    service.serviceIDRequested.source_address_id.length + serviceProviderCount ===
    service.serviceIDCollect.length
  ) {
    serviceHasPendingCollectAddressesToFinishOrStart = false
  }

  const serviceHasNewAddressesPendingToValidate =
    (service.serviceIDRequested.additional_collect_addresses !== null &&
      service.serviceIDRequested.additional_collect_addresses.length > 0) ||
    (service.serviceIDRequested.additional_delivery_addresses !== null &&
      service.serviceIDRequested.additional_delivery_addresses.length > 0)

  const isCrossdockingService = service.serviceIDRequested.crossdocking_collector_id

  let isServiceDelayed = false

  if (isCrossdockingService) {

    const isBoardDateBeforeToToday = isBefore(
      new Date(service.serviceIDRequested.board_date),
      new Date()
    )

    const isBoardDateSameToToday = isSameDay(
      new Date(service.serviceIDRequested.board_date),
      new Date()
    )

    if (!isBoardDateSameToToday && isBoardDateBeforeToToday) {
      isServiceDelayed = true
    } else if (isBoardDateSameToToday) {
      const [boardHours, boardMinutes] = format(new Date(service.serviceIDRequested.board_hour), "HH:mm").split(':').map(Number)

      isServiceDelayed = differenceInMinutes(
        set(new Date(), {
          hours: boardHours,
          minutes: boardMinutes
        }), new Date()
      ) < 240
    }
  }

  const requestedValidatedCollectAddresses = service?.serviceIDRequested?.requestedAddresses?.filter(
    (requestedAddress) => requestedAddress.type === 'collect' && requestedAddress.status === 'validated'
  )

  const requestedValidatedCollectAddressesCount = requestedValidatedCollectAddresses?.length
  return (
    <Tr
      color={hasCollectDelayOccurrence
        ? 'yellow.300'
        : compareCollectFinalDateWithDateNow(
          service.serviceIDRequested.collect_date,
          service.serviceIDRequested.collect_hour_end
        )
          ? 'red.300'
          : serviceHasUnsuccessCollectToValidade
            ? 'blue.300'
            : ''
      }
      fontWeight={requestedValidatedCollectAddressesCount > 0 ? 'bold' : 'normal'}
    >
      <Td>
        <Flex align='baseline' gap={2}>
          {service?.serviceIDRequested?.budgetIDService?.priority_budget && (
            <Icon as={FaExclamation} color={'red.500'} />
          )}
          {isServiceDelayed && (
            <Icon as={FaClock} color={'red.500'} />
          )}
          {isLLMUser && (
            <Flex h="full" justify="flex-end" align="flex-end">
              <IconButton
                aria-label="Abrir Interações"
                icon={<FaPeopleArrows />}
                colorScheme={interactionStatusColors[service.last_interaction_status] || interactionStatusColors.default}
                size="sm"
                onClick={onOpen}
                variant='ghost'

              />
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />

                <Interactions isOpen={isOpen} serviceId={service.id} />
              </Modal>

            </Flex>
          )}
        </Flex>
      </Td>
      <Td>
        <Flex>
          {
            userHasValidateUnsuccessCollectPermission &&
            serviceHasUnsuccessCollectToValidade && (
              <Button
                as={Link}
                to={`/servicos/coletas-sem-sucesso/${service.id}`}
                variant='unstyled'
              >
                <Flex w='full' h='full' align='center' justify='center'>
                  <Icon as={FaCheckCircle} />
                </Flex>
              </Button>
            )}
          {
            userHasStartCollectServicePermission &&
            compareCollectDateWithDateNow(service.serviceIDRequested.collect_date) &&
            serviceHasPendingCollectAddressesToFinishOrStart && (
              <Button
                as={Link}
                to={`/servicos/coletas/${service.id}`}
                variant='unstyled'
              >
                <Flex w='full' h='full' align='center' justify='center'>
                  <Icon as={FaPen} />
                </Flex>
              </Button>
            )}
          {
            (userHasValidateNewCollectServiceAddressesPermission || userHasValidateNewDeliveryServiceAddressesPermission) &&
            serviceHasNewAddressesPendingToValidate &&
            (
              <Button
                as={Link}
                to={`/servico/${service.id}/validar-novos-enderecos`}
                variant='unstyled'
              >
                <Flex w='full' h='full' align='center' justify='center'>
                  <Icon as={FaMapSigns} />
                </Flex>
              </Button>
            )}
          <Button
            as={Link}
            to={`/servico/detalhes/${service.id}`}
            variant='unstyled'
          >
            <Flex w='full' h='full' align='center' justify='center'>
              <Icon as={FaEye} />
            </Flex>
          </Button>
        </Flex>
      </Td>
      <Td>
        <HStack
          spacing={3}
        >
          <Text>{service.protocol}</Text>
          {requestedValidatedCollectAddressesCount > 0 && (
            <Popover>
              <PopoverTrigger>

                <Button rounded="full" size="sm">
                  <Tooltip label="Novos endereços validados" rounded="md" hasArrow>
                    <Text>
                      + {requestedValidatedCollectAddressesCount}
                    </Text>
                  </Tooltip>
                </Button>
              </PopoverTrigger>

              <RequestedCollectAddressesPopover requestedCollectAddresses={requestedValidatedCollectAddresses} />
            </Popover>
          )}
        </HStack>
      </Td>
      <Td>{collectDate}</Td>
      <Td>{service.customerIDService.trading_firstname}</Td>
      <Td>{Array.from(new Set(service?.source_cities?.map(city => city.name))).join(', ')}</Td>
      <Td>{Array.from(new Set(service?.destination_cities?.map(city => city.name))).join(', ')}</Td>
      <Td>{service.serviceIDRequested.gelo_seco}</Td>
      <Td>{collectHourStart}</Td>
      <Td>{collectHourEnd}</Td>
      <Td>{deliveryHour}</Td>
      <Td>{service.serviceIDRequested.sourceCollectorIDService.trading_name}</Td>
      <Td>{driversAssignedInfo || '-'}</Td>
    </Tr>
  )

}
