import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Button,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  FormControl,
  FormLabel,
  Text,
  CheckboxGroup,
  Checkbox,
  VStack,
  InputGroup,
  Input as ChakraInput,
  InputLeftElement
} from '@chakra-ui/react'
import { useState, useEffect, useMemo, useReducer } from 'react'
import { useCurrency } from 'react-hook-currency'
import { Link } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, NestedValue, useForm, useWatch } from 'react-hook-form'
import * as yup from 'yup'

import {
  bugdet_situations,
  days_of_week,
  deadline_delivery,
  modal_types,
  negative_positive,
  service_types,
  vehicle_types,
} from '../../utils/customLists'


import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { BudgetLogsModal } from './components/BudgetLogsModal'
import { TextArea } from '../../components/Inputs/TextInput'
import { Select } from '../../components/Inputs/SelectInput'
import { Input } from '../../components/Inputs/Input'

import { CustomerProps } from '../../contexts/CustomerContext'

import { useCity } from '../../hooks/city/useCity'
import { useCustomer } from '../../hooks/customer/useCustomer'
import { useHub } from '../../hooks/hub/useHub'
import { useBudget } from '../../hooks/budget/useBudget'
import { CheckboxDaysWeek } from '../../components/Inputs/CheckboxDaysWeek'
import { useAuth } from '../../hooks/auth/useAuth'
import { formatDate } from 'utils/DateFunctions/formatDate'
import { useSwal } from 'hooks/swal/useSwal'
import { useHistory } from "react-router-dom";
import { Radio } from 'components/Inputs/RadioInput'
import { positiveNegativeOptions } from 'utils/CustomLists/positiveNegativeOptions'
import { BudgetServicesRecurrents } from './components/BudgetServicesRecurrents'
import { useQuery } from 'react-query'
import { getServicesRecurrentsByBudget } from 'api/budgets/getServicesRecurrentsByBudget'
import { useSearchParams } from 'hooks/useSearchParams'

import AsyncSelect from "react-select/async"
import { getShippings } from 'api/shippings/getShippings'
import { captalize } from 'utils/captalize'
import { FaSearch } from 'react-icons/fa'
import { getActiveServicesByBudget } from 'api/budgets/getActiveServicesByBudget'
import { SpecialBudgetCosts } from './components/SpecialBudgetCosts'
import { getSpecialBudgetCosts } from 'api/budgets/getSpecialBudgetCosts'

interface FormInputProps {
  customer_id: string
  situation: string
  source_hub_id: string
  has_crossdocking: 'SIM' | 'NÃO'
  crossdocking_hub_id: string | null
  destination_hub_id: string
  source_cities: NestedValue<string[]>
  destination_cities: NestedValue<string[]>
  source_address_qty: number
  destination_address_qty: number
  deadline: number
  service_type: string
  franchising: number
  modal: string
  vehicle: string
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  price: number
  price_kg_extra: number
  transfer_budget: number
  price_add_collect: number
  price_add_delivery: number
  price_unsuccessful_collect: number
  days_service: NestedValue<string[]>
  deadline_delivery: string
  observation: string
  priority_budget: 'yes' | 'no'
  last_mile_budget: string
  shippingIds: string[]
  shipping_type: 'Rápido' | 'Econômico'
  does_share_extra_kg: 'SIM' | 'NÃO'
  modal_crossdocking: string
  does_validate_new_addresses_automatically: 'yes' | 'no'
}

interface BudgetFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
  submit: (values: FormInputProps) => Promise<void>
}

const budgetFormSchema = yup.object().shape({
  customer_id: yup.string().required('Campo Obrigatório'),
  situation: yup
    .string()
    .required('Campo Obrigatório')
    .test('checkActiveServicesAndSituation', 'Não é permitido definir como INATIVO com serviços ativos para este orçamento', function (value) {
      const { hasActiveServicesOnBudget } = this.options.context;
      return !(hasActiveServicesOnBudget && value === "INATIVO");
    }),
  has_crossdocking: yup.string().required('Campo Obrigatório'),
  crossdocking_hub_id: yup.string().when('has_crossdocking', {
    is: 'SIM',
    then: yup.string().required('Campo Obrigatório'),
  }),
  source_hub_id: yup.string().required('Campo Obrigatório'),
  destination_hub_id: yup.string().required('Campo Obrigatório'),
  source_cities: yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
      case 'string':
        return yup.string().nullable().required('Campo Obrigatório')
      default:
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
    }
  }),
  destination_cities: yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
      case 'string':
        return yup.string().nullable().required('Campo Obrigatório')
      default:
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
    }
  }),
  source_address_qty: yup.number().min(1, 'Valor mínimo precisa ser maior ou igual a 1').typeError('Campo Obrigatório'),
  destination_address_qty: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(1, 'Valor mínimo precisa ser maior ou igual a 1')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  deadline: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  service_type: yup.string().required('Campo Obrigatório'),
  franchising: yup.string().required('Campo Obrigatório'),
  modal: yup.string().required('Campo Obrigatório'),
  vehicle: yup.string().required('Campo Obrigatório'),
  caixa_termica: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  embalagem_secundaria: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  gelo_seco: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .required('Campo Obrigatório'),
  gelox: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .required('Campo Obrigatório'),
  isopor3l: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .required('Campo Obrigatório'),
  isopor7l: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .required('Campo Obrigatório'),
  terciaria3l: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .required('Campo Obrigatório'),
  terciaria8l: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .required('Campo Obrigatório'),
  price: yup.string().required('Campo Obrigatório'),
  price_unsuccessful_collect: yup
    .string()
    .required('Campo Obrigatório')
    .when('price', {
      is: '0,00',
      then: yup.string().required('Campo Obrigatório'),
      otherwise: yup.string().test('price', 'Preço não pode ser 0,00', (value) => value !== '0,00'),
    }),
  price_kg_extra: yup.string().required('Campo Obrigatório'),
  transfer_budget: yup.string().required('Campo Obrigatório'),
  price_add_collect: yup.string().required('Campo Obrigatório'),
  price_add_delivery: yup.string().required('Campo Obrigatório'),
  deadline_delivery: yup.string().required('Campo Obrigatório'),
  priority_budget: yup.string().required('Campo Obrigatório'),
  last_mile_budget: yup.string().when('$isLoglifeEmployee', {
    is: true,
    then: yup.string().required('Campo Obrigatório')
  }),
  shippingIds: yup.array().when('service_type', {
    is: 'FRACIONADO',
    then: yup.array().of(yup.string()).min(1).required(),
  }),
  shipping_type: yup.mixed().when('modal', {
    is: 'AÉREO',
    then: yup.string().required('Campo obrigatório')
  }),
  observation: yup.string(),
  does_share_extra_kg: yup.string().required('Campo obrigatório'),
  does_validate_new_addresses_automatically: yup.string().required('Campo obrigatório'),
  modal_crossdocking: yup.mixed().when('has_crossdocking', {
    is: 'SIM',
    then: yup.string().required('Campo Obrigatório'),
  })
})

const shippingTypesOptions = [
  { key: '1', showOption: 'Rápido', value: 'Rápido' },
  { key: '2', showOption: 'Econômico', value: 'Econômico' },
]

interface CitiesFilterState {
  sourceCity?: string
  destinationCity?: string
}

type CitiesFilterAction = {
  type: 'SOURCE' | 'DESTINATION',
  payload: {
    sourceCity?: string
    destinationCity?: string
  }
}

function reducer(state: CitiesFilterState, action: CitiesFilterAction) {
  if (action.type === 'SOURCE') {
    return {
      sourceCity: action.payload.sourceCity
    }
  }

  if (action.type === 'DESTINATION') {
    return {
      destinationCity: action.payload.destinationCity
    }
  }

  return state
}

export function BudgetForm({
  slug,
  id,
  isDisabled = false,
  href,
  title,
  action,
  submit,
}: BudgetFormProps) {
  const [citiesFilterState, dispatch] = useReducer(reducer, {} as CitiesFilterState)

  const [customersFiltered, setCustomersFiltered] = useState<CustomerProps[]>(
    [],
  )

  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [hasActiveServicesOnBudget, setHasActiveServicesOnBudget] = useState(false)

  const { onClick, onChange, format } = useCurrency({
    style: 'decimal',
  })

  const {
    cities: { data: cities, isLoading: isCityLoading },
  } = useCity(null, true, false)
  const {
    customers: { data: customers, isLoading: isCustomerLoading },
  } = useCustomer(null, true, false)
  const {
    hubs: { data: hubs, isLoading: isHubLoading },
  } = useHub(null, true, false)
  const {
    budget: { data: budget, isLoading: isBudgetLoading },
  } = useBudget(id || null, false, false)

  const { userLogged } = useAuth()

  const doesUserCanEditSpecialBudgetCost = userLogged?.permissions.includes('edit-special-budget-cost')

  const doesUserCanCreateSpecialBudgetCost = userLogged?.permissions.includes('create-special-budget-cost')

  const doesUserCanViewSpecialBudgetCost = userLogged?.permissions.includes('view-special-budget-cost')

  const {
    data: specialBudgetCostsResult
  } = useQuery({
    queryKey: ['special-budget-costs'],
    queryFn: () => getSpecialBudgetCosts({ budgetId: id }),
    enabled: !!id,
    refetchOnWindowFocus: false
  })

  const doesSpecialBudgetCostExists = Boolean(specialBudgetCostsResult?.budgetSpecialCosts?.length)

  const { confirmMessage, standardMessage } = useSwal()
  const { push: redirectTo } = useHistory();

  const searchParams = useSearchParams()

  const page = searchParams.get('page') ?? '1'

  const { data: services } =
    useQuery({
      queryKey: ['budget-services-recurrents', id, page],
      queryFn: () => getServicesRecurrentsByBudget({
        budgetId: id,
        currentPage: page,
        pageSize: '10',
      }),
      refetchOnWindowFocus: false,
      enabled: !!id
    })

  const {
    unregister,
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    resolver: yupResolver(budgetFormSchema),
    context: {
      isLoglifeEmployee: userLogged.loglife_employee,
      hasActiveServicesOnBudget: hasActiveServicesOnBudget
    }
  })

  const situation = useWatch({
    control,
    name: 'situation'
  })

  const isInactiveSituation = situation === 'INATIVO'

  const {
    data: activeServicesByBudgetResult
  } = useQuery({
    queryKey: ['active-services-by-budget', id, isInactiveSituation],
    queryFn: () => getActiveServicesByBudget({ budgetId: id }),
    enabled: slug !== 'adicionar' && isInactiveSituation
  })

  useEffect(() => {
    if (activeServicesByBudgetResult && activeServicesByBudgetResult?.services?.length !== 0) {
      setHasActiveServicesOnBudget(true)
    }
  }, [activeServicesByBudgetResult])


  const {
    isOpen: isOpenBudgetLogsModal,
    onOpen: onOpenBudgetLogsModal,
    onClose: onCloseBudgetLogsModal
  } = useDisclosure()

  const {
    isOpen: isOpenSpecialBudgetCostModal,
    onToggle: onToggleSpecialBudgetCostModal
  } = useDisclosure()


  const {
    isOpen: isOpenBudgetServicesRecurrentsModal,
    onOpen: onOpenBudgetServicesRecurrentsModal,
    onClose: onCloseBudgetServicesRecurrentsModal
  } = useDisclosure()

  useEffect(() => {
    function run() {
      if (
        !isHubLoading &&
        !isCustomerLoading &&
        !isCityLoading &&
        !isBudgetLoading
      ) {
        setTimeout(() => setIsLoadingPage(false), 1000)
      }
    }
    run()
  }, [isHubLoading, isCustomerLoading, isCityLoading, isBudgetLoading])

  const isCustomerSelected = watch('customer_id')
  const selectedHubSource = watch('source_hub_id')
  const selectedHubDestination = watch('destination_hub_id')
  const typeServiceSelected = watch('service_type')
  const hasCrossDockingValue = watch('has_crossdocking')

  const sourceCitiesSelected = useWatch({
    control,
    name: 'source_cities'
  })

  const destinationCitiesSelected = useWatch({
    control,
    name: 'destination_cities'
  })

  const selectedModal = useWatch({
    control,
    name: 'modal'
  })

  const isAirModal = selectedModal === 'AÉREO'

  useEffect(() => {
    if (isCustomerSelected && customers && slug === 'adicionar') {
      const customerById = customers?.find(customer => customer.id === isCustomerSelected)

      setValue("deadline_delivery", formatDate.handle(customerById.deadline_delivery, "DateOnlyWithHourMinute"))
    }
  }, [customers, isCustomerSelected, setValue, slug])

  useEffect(() => {
    if (isAirModal) unregister('shipping_type')
  }, [isAirModal, unregister])

  const defaultDaysServiceOption = useMemo(() => {
    if (sourceCitiesSelected && destinationCitiesSelected) {

      const sourceCitiesWorkingDays = Array.from(new Set(
        cities
          ?.filter((city) => sourceCitiesSelected.includes(city.id))
          ?.flatMap((city) => city.days_service)
      ))

      const destinationCitiesWorkingDays = Array.from(new Set(
        cities
          ?.filter((city) => destinationCitiesSelected.includes(city.id))
          ?.flatMap((city) => city.days_service)
      ))

      const days = Array.from(
        new Set(sourceCitiesWorkingDays.concat(destinationCitiesWorkingDays))
      )
        .reduce((acc, curr) => {
          if (sourceCitiesWorkingDays.includes(curr) && destinationCitiesWorkingDays.includes(curr)) {
            acc.push(curr)
          }

          return acc
        }, [])

      return days_of_week.reduce((acc, curr) => {
        days.forEach(day => {
          if (day === curr.value) {
            acc.push(curr.name)
          }

        })
        return acc
      }, [])

    }

  }, [cities, destinationCitiesSelected, sourceCitiesSelected])

  const daysOfWeek = days_of_week.map((dayOfWeek) => {
    return {
      ...dayOfWeek,
      disabled: !defaultDaysServiceOption?.includes(dayOfWeek.name)
    }
  })

  useEffect(() => {
    function run() {
      if (userLogged !== null && !!customers) {
        if (userLogged.user_type === 'CLIENTE') {
          setCustomersFiltered(
            customers.filter(
              (customer) => customer.id === userLogged.customer_id,
            ),
          )
        } else {
          setCustomersFiltered(customers)
        }
      }
    }
    run()
  }, [userLogged, customers])

  useEffect(() => {
    function run() {
      if (slug === 'adicionar') {
        setValue('caixa_termica', 0)
        setValue('embalagem_secundaria', 0)
        setValue('gelo_seco', 0)
        setValue('gelox', 0)
        setValue('isopor3l', 0)
        setValue('isopor7l', 0)
        setValue('terciaria3l', 0)
        setValue('terciaria8l', 0)
        setValue('situation', 'ATIVO')
      }

      if (typeServiceSelected === 'DEDICADO') {
        setValue('modal', 'RODOVIÁRIO')
        setValue('price_kg_extra', 0)
        setValue('transfer_budget', 0)
      }
    }

    run()
  }, [setValue, typeServiceSelected, slug])


  const defaultDaysOfWeekEditOrViewBudget = useMemo(() => {
    return days_of_week.reduce<string[]>((acc, curr) => {
      budget?.days_service.forEach(day => {
        if (day === curr.value) {
          acc.push(curr.name)
        }

      })
      return acc
    }, [])
  }, [budget])

  useEffect(() => {
    if (slug === 'adicionar') {
      setValue('days_service', defaultDaysServiceOption)
    } else {
      setValue('days_service', defaultDaysOfWeekEditOrViewBudget)
    }
  }, [defaultDaysOfWeekEditOrViewBudget, defaultDaysServiceOption, setValue, slug])

  const sourceCities = cities?.filter(
    (city) => {
      const nameFilter = citiesFilterState.sourceCity
        ? (city.name.toLowerCase().normalize('NFC')).includes(citiesFilterState.sourceCity.toLowerCase().normalize('NFC'))
        : true

      const hubFilter = city.hub_id === selectedHubSource

      return nameFilter && hubFilter
    }
  )

  const destinationCities = cities?.filter(
    (city) => {
      const nameFilter = citiesFilterState.destinationCity
        ? (city.name.toLowerCase().normalize('NFC')).includes(citiesFilterState.destinationCity.toLowerCase().normalize('NFC'))
        : true

      const hubFilter = city.hub_id === selectedHubDestination

      return nameFilter && hubFilter
    }
  )


  useEffect(() => {
    async function run() {
      if (slug !== 'adicionar') {
        if (budget) {

          setValue(
            'has_crossdocking',
            budget.crossdocking_hub_id ? 'SIM' : 'NÃO',
          )
          setValue('crossdocking_hub_id', budget.crossdocking_hub_id ?? '')
          setValue('customer_id', budget.customer_id)
          setValue('situation', budget.situation)
          setValue('source_hub_id', budget.source_hub_id)
          setValue('destination_hub_id', budget.destination_hub_id)
          setValue("source_cities", budget.source_cities)
          setValue("destination_cities", budget.destination_cities)
          if (budget.shippings?.length > 0) {
            setValue('shippingIds', budget.shippings.map(shipping => shipping.shipping_id))
          }
          setValue('source_address_qty', budget.source_address_qty)
          setValue('destination_address_qty', budget.destination_address_qty)
          setValue('deadline', budget.deadline)
          setValue('service_type', budget.service_type)
          setValue('franchising', budget.franchising)
          setValue('modal', budget.modal)
          setValue('vehicle', budget.vehicle)
          setValue('caixa_termica', budget.caixa_termica)
          setValue('embalagem_secundaria', budget.embalagem_secundaria)
          setValue('gelo_seco', budget.gelo_seco)
          setValue('gelox', budget.gelox)
          setValue('isopor3l', budget.isopor3l)
          setValue('isopor7l', budget.isopor7l)
          setValue('terciaria3l', budget.terciaria3l)
          setValue('terciaria8l', budget.terciaria8l)
          setValue('price', budget.price)
          setValue(
            'price_kg_extra',
            budget.price_kg_extra === null ? 0 : budget.price_kg_extra,
          )
          setValue(
            'transfer_budget',
            budget.transfer_budget === null ? 0 : budget.transfer_budget,
          )
          setValue('price_add_collect', budget.price_add_collect)
          setValue('price_add_delivery', budget.price_add_delivery)
          setValue(
            'price_unsuccessful_collect',
            budget.price_unsuccessful_collect,
          )
          setValue("deadline_delivery", formatDate.handle(budget.deadline_delivery, "DateOnlyWithHourMinute"))
          setValue("priority_budget", budget.priority_budget ? 'yes' : 'no')
          setValue("last_mile_budget", String(budget.last_mile_budget / 100))
          setValue('observation', budget.observation)
          setValue('does_share_extra_kg', budget?.does_share_extra_kg ? 'SIM' : 'NÃO')
          setValue('does_validate_new_addresses_automatically', budget.does_validate_new_addresses_automatically ? 'yes' : 'no')
          setValue('modal_crossdocking', budget?.modal_crossdocking)
          if (budget.shipping_type) setValue('shipping_type', budget.shipping_type)
        }
      }
    }

    run()
  }, [setValue, budget, slug, id])


  async function handleConfirmRedirectPage() {
    const hasConfirmRedirect = await confirmMessage({
      title: 'Deseja ir para lista de orçamentos?',
    })

    if (hasConfirmRedirect) {
      redirectTo("/orcamentos")
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  async function promiseOptions(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getShippings({ currentPage: 1, pageSize: 5, tradingName: inputValue })

    return response.shippings.map(s => {
      return { value: s.id, label: captalize(s.trading_name) }
    })
  }

  if (isLoadingPage && slug !== 'adicionar') {
    return <GeneralContentLoading />
  }

  if (!isCustomerSelected) {
    return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(submit)}
          noValidate
        >
          <Heading size="lg" fontFamily="poppins">
            {title}
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Select
              {...register('customer_id')}
              isDisabled={isDisabled}
              customers_first_name={customers}
              name="customer_id"
              label="Pertence ao Cliente"
              placeholder="Selecione uma opção..."
              error={errors.customer_id}
              required
            />

            <Select
              {...register('situation')}
              name="situation"
              label="Situação"
              error={errors.situation}
              isDisabled={slug !== 'editar' ? true : isDisabled}
              situations={bugdet_situations}
              placeholder="Selecione uma opção..."
              required
            />
          </Stack>

          <Flex mt="8" justify="flex-end">
            <HStack>
              {slug === 'visualizar' ? (
                <Link to={href}>
                  <Button
                    type="button"
                    colorScheme="blue"
                    isLoading={isSubmitting}
                    loadingText="Carregando"
                  >
                    {action}
                  </Button>
                </Link>
              ) : (
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Carregando"
                >
                  {action}
                </Button>
              )}
              <Button
                type="button"
                colorScheme="gray"
                onClick={handleConfirmRedirectPage}
              >
                Lista de Orçamentos
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>

    )
  } else {
    return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(submit)}
          noValidate
        >
          <Flex justify='space-between'>
            <Heading size="lg" fontFamily="poppins">
              {title}
            </Heading>
            {(slug === 'editar' || slug === 'visualizar') && (
              <Flex gap={4}>
                {services.recurrentServices.length !== 0 && (
                  <Button colorScheme='blue' onClick={onOpenBudgetServicesRecurrentsModal}>
                    Serviços Recorrentes
                  </Button>
                )}
                {(
                  (doesSpecialBudgetCostExists && (doesUserCanEditSpecialBudgetCost || doesUserCanViewSpecialBudgetCost || doesUserCanCreateSpecialBudgetCost)) ||
                  (!doesSpecialBudgetCostExists && doesUserCanCreateSpecialBudgetCost)
                ) && (
                    <Button
                      colorScheme='blue'
                      onClick={onToggleSpecialBudgetCostModal}>
                      Custo especial
                    </Button>
                  )}

                <Button colorScheme='blue' onClick={onOpenBudgetLogsModal}>
                  Logs
                </Button>
              </Flex>
            )}

          </Flex>

          <BudgetLogsModal
            isOpen={isOpenBudgetLogsModal}
            onClose={onCloseBudgetLogsModal}
            cities={cities}
          />

          <Modal
            isOpen={isOpenSpecialBudgetCostModal}
            onClose={onToggleSpecialBudgetCostModal}
            size='5xl'
          >
            <ModalOverlay />
            <SpecialBudgetCosts
              budgetId={id}
            />

          </Modal>

          <Modal
            isOpen={isOpenBudgetServicesRecurrentsModal}
            onClose={onCloseBudgetServicesRecurrentsModal}
          >
            <ModalOverlay />
            <BudgetServicesRecurrents
              budgetId={id}
            />
          </Modal>

          <Divider my="6" borderColor="gray.700" />

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Select
              {...register('customer_id')}
              isDisabled={isDisabled}
              customers_first_name={customersFiltered}
              name="customer_id"
              label="Pertence ao Cliente"
              placeholder="Selecione uma opção..."
              error={errors.customer_id}
              required
            />

            <Select
              {...register('situation')}
              name="situation"
              label="Situação"
              error={errors.situation}
              isDisabled={slug !== 'editar' ? true : isDisabled}
              situations={bugdet_situations}
              placeholder="Selecione uma opção..."
              required
            />
          </Stack>

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Select
              {...register('source_hub_id')}
              isDisabled={isDisabled}
              name="source_hub_id"
              hubs={hubs}
              label="HUB de origem"
              placeholder="Selecione uma opção..."
              error={errors.source_hub_id}
              required
            />

            <Select
              {...register('destination_hub_id')}
              isDisabled={isDisabled}
              name="destination_hub_id"
              hubs={hubs}
              label="HUB de destino"
              placeholder="Selecione uma opção..."
              error={errors.destination_hub_id}
              required
            />
          </Stack>

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Select
              {...register('has_crossdocking')}
              isDisabled={isDisabled}
              name="has_crossdocking"
              negative_positive={negative_positive}
              label="Possui Crossdocking?"
              placeholder="Selecione uma opção..."
              error={errors.has_crossdocking}
              required
            />

            {hasCrossDockingValue === 'SIM' && (
              <Select
                {...register('crossdocking_hub_id')}
                isDisabled={isDisabled}
                name="crossdocking_hub_id"
                hubs={hubs}
                label="Hub Crossdocking"
                placeholder="Selecione uma opção..."
                error={errors.has_crossdocking}
                required
              />
            )}
          </Stack>
          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Radio
              {...register('priority_budget')}
              name='priority_budget'
              radioOptions={positiveNegativeOptions}
              defaultCheckedOption={slug !== 'adicionar' ? budget?.priority_budget ? 'yes' : 'no' : ''}
              label='Orçamento Prioritário?'
              error={errors.priority_budget}
              required
              isDisabled={isDisabled}
            />
          </Stack>
          {selectedHubSource && selectedHubDestination && (
            <>
              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <FormControl h="full">
                  <FormLabel fontSize="sm">
                    Cidade(s) de origem
                    <Text color="red.500" as="sup">*</Text>
                  </FormLabel>
                  <Controller
                    control={control}
                    name='source_cities'
                    render={({ field }) => {
                      return (
                        <CheckboxGroup
                          value={field.value}
                          onChange={field.onChange}
                        >
                          <InputGroup
                            size="sm"
                          >
                            <InputLeftElement>
                              <FaSearch />
                            </InputLeftElement>
                            <ChakraInput
                              placeholder="Ex: Belo Horizonte"
                              rounded="md"
                              onChange={(event) => dispatch({
                                type: 'SOURCE',
                                payload: {
                                  sourceCity: event.target.value
                                }
                              })}
                            />
                          </InputGroup>
                          <VStack
                            border="1px solid"
                            borderColor="gray.200"
                            align="start"
                            p={3}
                            mt={3}
                            rounded="md"
                            h="115px"
                            overflowY="scroll"
                          >
                            {sourceCities.map((city) => {
                              return (
                                <Checkbox value={city.id}>
                                  {captalize(city.name)}
                                </Checkbox>
                              )
                            })}

                          </VStack>
                        </CheckboxGroup>
                      )
                    }}
                  />
                </FormControl>


                <FormControl>
                  <FormLabel fontSize="sm">
                    Cidade(s) de destino
                    <Text color="red.500" as="sup">*</Text>
                  </FormLabel>
                  <Controller
                    control={control}
                    name='destination_cities'
                    render={({ field }) => {
                      return (
                        <CheckboxGroup
                          value={field.value}
                          onChange={field.onChange}
                        >
                          <InputGroup
                            size="sm"
                          >
                            <InputLeftElement>
                              <FaSearch />
                            </InputLeftElement>
                            <ChakraInput
                              placeholder="Ex: Belo Horizonte"
                              rounded="md"
                              onChange={(event) => dispatch({
                                type: 'DESTINATION',
                                payload: {
                                  destinationCity: event.target.value
                                }
                              })}
                            />
                          </InputGroup>
                          <VStack
                            border="1px solid"
                            borderColor="gray.200"
                            align="start"
                            p={3}
                            mt={3}
                            rounded="md"
                            h="115px"
                            overflowY="scroll"
                          >
                            {destinationCities.map((city) => {
                              return (
                                <Checkbox value={city.id}>
                                  {captalize(city.name)}
                                </Checkbox>
                              )
                            })}

                          </VStack>
                        </CheckboxGroup>
                      )
                    }}
                  />
                </FormControl>
              </Stack>

              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Input
                  {...register('source_address_qty')}
                  name="source_address_qty"
                  type="number"
                  label="Quantidade de endereço(s) de origem"
                  error={errors.source_address_qty}
                  isDisabled={isDisabled}
                  required
                />
                <Input
                  {...register('destination_address_qty')}
                  name="destination_address_qty"
                  type="number"
                  label="Quantidade de endereço(s) de destino"
                  error={errors.destination_address_qty}
                  isDisabled={isDisabled}
                  required
                />
              </Stack>

              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Input
                  {...register('deadline_delivery')}
                  name="deadline_delivery"
                  label="Horário limite de entrega"
                  error={errors.deadline_delivery}
                  type="time"
                  isDisabled={isDisabled}
                  required
                />
                <Select
                  {...register('deadline')}
                  name="deadline"
                  label="Prazo de entrega"
                  error={errors.deadline}
                  deadline_delivery={deadline_delivery}
                  placeholder="Selecione uma opção..."
                  isDisabled={isDisabled}
                  required
                />

                <Select
                  {...register('service_type')}
                  name="service_type"
                  label="Tipo de serviço"
                  error={errors.service_type}
                  service_types={service_types}
                  placeholder="Selecione uma opção..."
                  isDisabled={isDisabled}
                  required
                />

                <Input
                  {...register('franchising')}
                  name="franchising"
                  label="Franquia"
                  addChildren="KG"
                  onChange={onChange}
                  defaultValue={format('000')}
                  error={errors.franchising}
                  isDisabled={isDisabled}
                  required
                />
              </Stack>
              {typeServiceSelected === 'FRACIONADO' && (
                <Stack
                  spacing="24px"
                  mt="4"
                  w="full"
                >
                  <FormControl isInvalid={!!errors.shippingIds}>
                    <FormLabel>
                      Transportadoras
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>
                    <Controller
                      control={control}
                      name="shippingIds"
                      render={({ field }) => (
                        <AsyncSelect
                          name={field.name}
                          onChange={(arr) => field.onChange(arr?.map(v => v.value))}
                          cacheOptions
                          defaultOptions
                          defaultValue={budget?.shippings?.map(shipping => {
                            return {
                              value: shipping.shipping_id,
                              label: captalize(shipping.shipping.trading_name)
                            }
                          })}
                          placeholder="Transportadoras..."
                          loadOptions={promiseOptions}
                          isClearable={true}
                          isMulti
                        />
                      )}
                    />
                    {!!errors.shippingIds && (
                      <Text mt="2" fontSize="sm" color="red.500">Campo Obrigatório</Text>
                    )}
                  </FormControl>
                </Stack>
              )}

              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Stack
                  w="full"
                  spacing="24px"
                  direction={['column', 'column', 'row']}
                >
                  {hasCrossDockingValue === 'SIM' && (
                    <Select
                      {...register('modal_crossdocking')}
                      name="modal_crossdocking"
                      label="Modal Crossdocking"
                      error={errors.modal_crossdocking}
                      modal_types={modal_types}
                      placeholder="Selecione uma opção..."
                      isDisabled={
                        typeServiceSelected === 'DEDICADO' ? true : isDisabled
                      }
                      required
                    />
                  )}
                  <Select
                    {...register('modal')}
                    name="modal"
                    label="Modal"
                    error={errors.modal}
                    modal_types={modal_types}
                    placeholder="Selecione uma opção..."
                    isDisabled={
                      typeServiceSelected === 'DEDICADO' ? true : isDisabled
                    }
                    required
                  />
                </Stack>
                <Stack
                  w="full"
                  spacing="24px"
                  direction={['column', 'column', 'row']}
                >
                  <Select
                    {...register('vehicle')}
                    name="vehicle"
                    label="Veículo"
                    error={errors.vehicle}
                    vehicle_types={vehicle_types}
                    placeholder="Selecione uma opção..."
                    isDisabled={isDisabled}
                    required
                  />
                  {userLogged.loglife_employee && (
                    <Input
                      {...register('last_mile_budget')}
                      name="last_mile_budget"
                      label="Budget Last Mile (coleta + entrega)"
                      onChange={onChange}
                      defaultValue={format('000')}
                      error={errors.last_mile_budget}
                      isDisabled={isDisabled}
                      required
                    />
                  )}
                </Stack>
              </Stack>

              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Input
                  {...register('caixa_termica')}
                  name="caixa_termica"
                  label="Caixa Térmica"
                  type="number"
                  error={errors.caixa_termica}
                  isDisabled={isDisabled}
                  required
                />
                <Stack
                  w="full"
                  spacing="24px"
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    {...register('gelo_seco')}
                    name="gelo_seco"
                    label="Gelo Seco"
                    type="number"
                    error={errors.gelo_seco}
                    isDisabled={isDisabled}
                    required
                  />

                  <Input
                    {...register('gelox')}
                    name="gelox"
                    label="Gelox"
                    type="number"
                    error={errors.gelox}
                    isDisabled={isDisabled}
                    required
                  />
                </Stack>
              </Stack>

              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Input
                  {...register('isopor3l')}
                  name="isopor3l"
                  label="Isopor 3L"
                  type="number"
                  error={errors.isopor3l}
                  isDisabled={isDisabled}
                  required
                />
                <Input
                  {...register('isopor7l')}
                  name="isopor7l"
                  label="Isopor 7L"
                  type="number"
                  error={errors.isopor7l}
                  isDisabled={isDisabled}
                  required
                />
                <Input
                  {...register('terciaria3l')}
                  name="terciaria3l"
                  label="Terciária 3L"
                  type="number"
                  error={errors.terciaria3l}
                  isDisabled={isDisabled}
                  required
                />

                <Input
                  {...register('terciaria8l')}
                  name="terciaria8l"
                  label="Terciária 8L"
                  type="number"
                  error={errors.gelox}
                  isDisabled={isDisabled}
                  required
                />
              </Stack>
              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Input
                  {...register('embalagem_secundaria')}
                  name="embalagem_secundaria"
                  label="Embalagem Secundária Ziplock"
                  type="number"
                  error={errors.embalagem_secundaria}
                  isDisabled={isDisabled}
                  required
                />
              </Stack>

              {typeServiceSelected !== '' && (
                <>
                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      {...register('price')}
                      name="price"
                      label="Preço total"
                      addChildren="R$"
                      onClick={onClick}
                      onChange={onChange}
                      defaultValue={format('000')}
                      error={errors.price}
                      isDisabled={isDisabled}
                      required
                    />
                    <Input
                      {...register('price_unsuccessful_collect')}
                      name="price_unsuccessful_collect"
                      label="Preço da coleta sem sucesso"
                      onClick={onClick}
                      onChange={onChange}
                      defaultValue={format('000')}
                      addChildren="R$"
                      error={errors.price_unsuccessful_collect}
                      isDisabled={isDisabled}
                      required
                    />
                  </Stack>

                  {typeServiceSelected === 'FRACIONADO' &&
                    userLogged !== null &&
                    (userLogged.user_type === 'CLIENTE' ? null : (
                      <Stack
                        spacing="24px"
                        mt="4"
                        direction={['column', 'column', 'row']}
                      >
                        <Input
                          {...register('price_kg_extra')}
                          name="price_kg_extra"
                          label="Preço KG extra"
                          addChildren="R$"
                          onClick={onClick}
                          onChange={onChange}
                          defaultValue={format('000')}
                          error={errors.price_kg_extra}
                          isDisabled={isDisabled}
                          required
                        />
                        <Input
                          {...register('transfer_budget')}
                          name="transfer_budget"
                          label="Budget de Transferência"
                          addChildren="R$"
                          onClick={onClick}
                          onChange={onChange}
                          defaultValue={format('000')}
                          error={errors.price_unsuccessful_collect}
                          isDisabled={isDisabled}
                          required
                        />
                      </Stack>
                    ))}

                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      {...register('price_add_collect')}
                      name="price_add_collect"
                      label="Preço da coleta adicional"
                      addChildren="R$"
                      onClick={onClick}
                      onChange={onChange}
                      defaultValue={format('000')}
                      error={errors.price_add_collect}
                      isDisabled={isDisabled}
                      required
                    />
                    <Input
                      {...register('price_add_delivery')}
                      name="price_add_delivery"
                      label="Preço da entrega adicional"
                      addChildren="R$"
                      onClick={onClick}
                      onChange={onChange}
                      defaultValue={format('000')}
                      error={errors.price_add_delivery}
                      isDisabled={isDisabled}
                      required
                    />
                  </Stack>
                </>
              )}

              {isAirModal && (
                <Stack
                  mt={4}
                >
                  <Select
                    {...register('shipping_type')}
                    name="shipping_type"
                    placeholder="Selecione um tipo..."
                    options={shippingTypesOptions}
                    label="Tipo de envio"
                    required
                  />
                </Stack>
              )}
              <Stack
                mt={4}
                direction={{ base: 'column', md: 'row' }}
                spacing={6}
              >
                <Select
                  {...register('does_share_extra_kg')}
                  name="does_share_extra_kg"
                  placeholder="Selecione um tipo..."
                  negative_positive={negative_positive}
                  label="Compartilha KG Extra?"
                  required
                  isDisabled={isDisabled}
                  error={errors.does_share_extra_kg}
                />

                <Select
                  {...register('does_validate_new_addresses_automatically')}
                  name="does_validate_new_addresses_automatically"
                  placeholder="Selecione uma opção..."
                  options={positiveNegativeOptions}
                  label="Realiza inclusões automáticas de endereço?"
                  required
                  isDisabled={isDisabled}
                  error={errors.does_validate_new_addresses_automatically}
                />
              </Stack>

              {defaultDaysServiceOption !== undefined && (

                <Stack
                  spacing="24px"
                  mt="4"
                  direction={['column', 'column', 'row']}
                >
                  <Controller
                    control={control}
                    name='days_service'
                    render={({ field: { onChange } }) => (
                      <CheckboxDaysWeek
                        name='days_service'
                        defaultOption={slug !== 'adicionar' ? defaultDaysOfWeekEditOrViewBudget : defaultDaysServiceOption}
                        days_of_week={daysOfWeek}
                        isDisabled={isDisabled}
                        label="Dias de Atendimento"
                        onCheckboxChange={onChange}
                        required
                      />
                    )}
                  />
                </Stack>
              )}

              <TextArea
                {...register('observation')}
                name="observation"
                label="Observações"
                isDisabled={isDisabled}
              />
            </>
          )}
          <Flex mt="8" justify="flex-end">
            <HStack>
              {slug === 'visualizar' ? (
                userLogged !== null && (
                  <>
                    {userLogged.permissions.includes('edit-budget') && (
                      <Link to={href}>
                        <Button
                          type="button"
                          colorScheme="blue"
                          isLoading={isSubmitting}
                          loadingText="Carregando"
                        >
                          {action}
                        </Button>
                      </Link>
                    )}
                  </>
                )
              ) : (
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Carregando"
                >
                  {action}
                </Button>
              )}

              <Button
                type="button"
                colorScheme="gray"
                onClick={handleConfirmRedirectPage}
              >
                Lista de Orçamentos
              </Button>

            </HStack>
          </Flex>
        </Box>
      </Flex>

    )
  }
}
