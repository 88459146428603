import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface ApproveCltIntercurrenceBody {
  hasGenerateExtraDiscount?: string
  withMotorcycleRental?: boolean
  withTelephony?: boolean
  withFuel?: boolean
  withVaVr?: boolean
  withVt?: boolean
  withToll?: boolean
  withExtras?: boolean
  withPayroll?: boolean

  isCertificatePresented?: boolean
  hasPaidTheHoursDebitedAtPoint?: boolean
  employeeClockedInForTheDay?: boolean

  wasEntryMadeOnTerminationReceipt?: boolean

  withPontomaisAdjust?: 'yes' | 'no' | 'n/a'
  wasChangedDriverOnBusinessBudget?: 'yes' | 'n/a'
  turnChangeChecklist?: string[]

  additionalValueWasReleased?: boolean
  releaseDate?: Date
  releasedValue?: number

  withVacationPayroll?: boolean
  withPayrollHrAdjustment?: boolean
  customerId?: string
  businessBudgetId?: string
  amountToBeChargedInCents?: number
  vacationPayrollDescription?: string
}

interface ApproveCltIntercurrenceProps {
  body: ApproveCltIntercurrenceBody
  routeParams: {
    cltIntercurrenceId: string
  }
}

export async function approveCltIntercurrence({
  body,
  routeParams
}: ApproveCltIntercurrenceProps) {
  try {
    await api.put(`/clt-intercurrences/${routeParams.cltIntercurrenceId}/approve`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
