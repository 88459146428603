import { Button, Flex, FormControl, FormLabel, IconButton, Input, InputGroup, InputLeftAddon, Link, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Popover, PopoverTrigger, Radio, RadioGroup, Spinner, Stack, Table, TableCaption, Tbody, Td, Text, Textarea, Tr } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { editBilling } from "api/billings/editBilling";
import { getBilling } from "api/billings/getBilling";
import { GetBillingsResponse } from "api/billings/getBillings";
import { format, set } from "date-fns";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect } from "react";
import { useCurrency } from "react-hook-currency";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FaLink, FaFileImport, FaInfo, FaExternalLinkAlt } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import { handleChangeUrl } from "utils/handleChangeUrl";
import * as yup from "yup"
import { BillingServiceBudgetDetail } from "./BillingServiceBudgetDetail";
import { BillingStatus } from "./BillingStatus";

interface EditBillingProps {
  billingId: string
  onCloseModal: () => void
}

interface EditBillingSchema {
  startDate: string
  endDate: string
  dueDate: string
  value: string
  autoSendEmail: string
  paymentSlips: FileList
  invoices: FileList
  otherAttachments: FileList
}

function transformInputDateToTimestamp(inputDate: string) {
  if (inputDate) {
    const [year, month, day] = inputDate.split('-').map(Number)

    const timestamp = set(new Date(), {
      date: day,
      month: month - 1,
      year,
      hours: 12,
      minutes: 0,
      seconds: 0
    }).toISOString()

    return timestamp
  }

  return ''
}

const editBillingSchema = yup.object({
  startDate: yup.string().required().transform(transformInputDateToTimestamp),
  endDate: yup.string().required().transform(transformInputDateToTimestamp),
  dueDate: yup.string().required().transform(transformInputDateToTimestamp),
  value: yup.mixed().required().transform((value: string) => {
    return value ? transformStringToNumber(value) * 100 : ''
  }),
  autoSendEmail: yup.boolean().required().transform(value => value === 'yes'),
  invoices: yup.mixed(),
  otherAttachments: yup.mixed()
})

export function EditBilling({ billingId, onCloseModal }: EditBillingProps) {

  const { data: billingData, isLoading: isBillingDataLoading } = useQuery({
    queryKey: ['billing', billingId],
    queryFn: () => getBilling({ billingId })
  })


  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<EditBillingSchema>({
    resolver: yupResolver(editBillingSchema),
  })

  useEffect(() => {
    if (billingData) {
      const { billing } = billingData

      setValue('startDate', format(new Date(billing.start_date), 'yyyy-MM-dd'))
      setValue('endDate', format(new Date(billing.end_date), 'yyyy-MM-dd'))
      setValue('dueDate', format(new Date(billing.due_date), 'yyyy-MM-dd'))
      setValue('value', (billing.value / 100).toLocaleString('pt-BR', {
        style: 'decimal'
      }))
      setValue('autoSendEmail', billing.auto_send_email ? 'yes' : 'no')
    }
  }, [setValue, billingData])

  const [paymentSlips, invoices, attachments] = useWatch({
    control,
    name: ['paymentSlips', 'invoices', 'otherAttachments']
  })

  const {
    format: currencyFormat, onChange: onCurrencyChange,
  } = useCurrency({
    style: 'decimal'
  })

  const queryClient = useQueryClient()

  const { mutateAsync: editBillingFn } = useMutation({
    mutationFn: editBilling,
    onSuccess(_data, { body, billingId }) {
      const cachedBillings = queryClient.getQueriesData<GetBillingsResponse>({
        queryKey: ['billings']
      })

      cachedBillings.forEach(([cachedKey, cachedData]) => {
        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          billings: cachedData.billings.map((billing) => {
            if (billing.id === billingId) {
              return {
                ...billing,
                auto_send_email: body.get('autoSendEmail') === 'true',
                due_date: body.get('dueDate'),
                start_date: body.get('startDate'),
                end_date: body.get('endDate')
              }
            }

            return billing
          })
        })
      })

      onCloseModal()
    }
  })

  const { promiseMessage } = useToastify()

  async function handleEditBilling(data: EditBillingSchema) {
    const formData = new FormData()

    formData.append('startDate', data.startDate)
    formData.append('endDate', data.endDate)
    formData.append('dueDate', data.dueDate)
    formData.append('value', data.value)
    formData.append('autoSendEmail', data.autoSendEmail)

    if (data.paymentSlips) {
      Object.entries(data.paymentSlips).forEach(([key, file]) => {
        formData.append('paymentSlips', file)
      })
    }

    if (data.invoices) {
      Object.entries(data.invoices).forEach(([key, file]) => {
        formData.append('invoices', file)
      })
    }

    if (data.otherAttachments) {
      Object.entries(data.otherAttachments).forEach(([key, file]) => {
        formData.append('attachments', file)
      })
    }

    await promiseMessage(editBillingFn({
      billingId,
      body: formData
    }), 'Faturamento editado! 🎉')
  }

  return (
    <ModalContent>
      {isBillingDataLoading ? (
        <ModalBody p="14">
          <Spinner />
        </ModalBody>
      ) : (
        <>
          <ModalHeader letterSpacing="tight">Editar Faturamento</ModalHeader>
          <ModalCloseButton />

          <ModalBody
            as="form"
            onSubmit={handleSubmit(handleEditBilling)}
            maxH="600px"
            overflowY="scroll"
          >

            <Table size="sm">
              <Tbody>
                <Tr>
                  <Td>Cliente</Td>
                  <Td isNumeric>{billingData.billing.customer.trading_firstname}</Td>
                </Tr>
                {billingData.billing.service && (
                  <>
                    <Tr>
                      <Td>Protocolo</Td>
                      <Td isNumeric>{billingData.billing.service.protocol}</Td>
                    </Tr>
                    <Tr>
                      <Td>Status</Td>
                      <Td display="flex" justifyContent="flex-end">
                        <BillingStatus status={billingData.billing.status} />
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Orçamento</Td>
                      <Td isNumeric>
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              aria-label="Visualizar informações do orçamento"
                              icon={<FaInfo />}
                              size="xs"
                              colorScheme="linkedin"
                            />
                          </PopoverTrigger>
                          <BillingServiceBudgetDetail budget={billingData.billing.service.serviceIDRequested.budgetIDService} />
                        </Popover>
                      </Td>
                    </Tr>
                  </>
                )}

              </Tbody>
            </Table>

            <Stack
              direction={["column", "column", "row"]}
              spacing="6"
              w="full"
              mt="6"
            >
              <Flex
                direction="column"
                gap="1"
                w="full"
              >
                <Text fontSize="sm">
                  Período inicial
                  <Text ml="1" textColor="red.500" as="sup">*</Text>
                </Text>
                <FormControl isInvalid={!!errors.startDate}>
                  <Input
                    {...register("startDate")}
                    name="startDate"
                    type="date"
                    size="sm"
                    rounded="md"
                    w="full"
                  />
                </FormControl>
              </Flex>
              <Flex
                direction="column"
                gap="1"
                w="full"
              >
                <Text fontSize="sm">
                  Período final
                  <Text ml="1" textColor="red.500" as="sup">*</Text>
                </Text>
                <FormControl isInvalid={!!errors.endDate}>
                  <Input
                    {...register("endDate")}
                    name="endDate"
                    type="date"
                    size="sm"
                    rounded="md"
                    w="full"
                  />
                </FormControl>
              </Flex>
            </Stack>

            <Stack
              direction={["column", "column", "row"]}
              spacing="6"
              mt="6"
            >
              <Flex
                direction="column"
                gap="1"
                w="full"
              >
                <Text fontSize="sm">
                  Valor da fatura
                  <Text ml="1" textColor="red.500" as="sup">*</Text>
                </Text>
                <FormControl isInvalid={!!errors.value}>
                  <Controller
                    name="value"
                    control={control}
                    render={({ field }) => {
                      return (
                        <InputGroup
                          size="sm"
                        >
                          <InputLeftAddon
                            borderTopLeftRadius="md"
                            borderBottomLeftRadius="md"
                          >
                            R$
                          </InputLeftAddon>
                          <Input
                            {...register("value")}
                            size="sm"
                            rounded="md"
                            w="full"
                            name={field.name}
                            defaultValue={currencyFormat('000')}
                            onChange={e => field.onChange(onCurrencyChange(e))}
                          />
                        </InputGroup>
                      )
                    }}
                  />
                </FormControl>
              </Flex>

              <Flex
                direction="column"
                gap="1"
                w="full"
              >
                <Text fontSize="sm">
                  Data de vencimento
                  <Text ml="1" textColor="red.500" as="sup">*</Text>
                </Text>
                <FormControl isInvalid={!!errors.dueDate}>
                  <Input
                    {...register("dueDate")}
                    name="dueDate"
                    type="date"
                    size="sm"
                    rounded="md"
                    w="full"
                  />
                </FormControl>
              </Flex>
            </Stack>


            <Flex
              direction="column"
              gap="1"
              w="full"
              mt="6"
            >
              <Text fontSize="sm">
                Enviar e-mail automaticamente
                <Text ml="1" textColor="red.500" as="sup">*</Text>
              </Text>
              <FormControl isInvalid={!!errors.autoSendEmail}>
                <Controller
                  name="autoSendEmail"
                  control={control}
                  render={({ field }) => {
                    return (
                      <RadioGroup
                        size="sm"
                        onChange={field.onChange}
                        value={field.value ?? ''}
                        name={field.name}
                      >
                        <Stack direction="column">
                          <Radio autoFocus={!!errors.autoSendEmail} value="yes">Sim</Radio>
                          <Radio autoFocus={!!errors.autoSendEmail} value="no">Não</Radio>
                        </Stack>
                      </RadioGroup>
                    )
                  }}
                />
              </FormControl>
            </Flex>

            <Flex
              direction="column"
              gap="1"
              w="full"
              mt="6"
            >
              <Text fontSize="sm">
                Observações
              </Text>
              <Textarea
                size="sm"
                rounded="md"
                defaultValue={billingData.billing.customer.billing_observation}
                w="full"
                isReadOnly

              />
            </Flex>

            <Table size="sm" mt="6">
                <TableCaption>Arquivos anexados</TableCaption>

              <Tbody>
                {billingData?.billing?.payment_slips && (
                  billingData.billing.payment_slips.map((file) => {
                    return (
                      <Tr key={file}>
                        <Td>
                          {file}
                        </Td>
                        <Td>
                          <IconButton
                            as={Link}
                            href={handleChangeUrl(file)}
                            aria-label="Ir para o link"
                            icon={<FaExternalLinkAlt />}
                            size="sm"
                            isExternal
                          />
                        </Td>
                      </Tr>
                    )
                  })
                )}

                {billingData?.billing?.invoices && (
                  billingData.billing.invoices.map((file) => {
                    return (
                      <Tr key={file}>
                        <Td>
                          {file}
                        </Td>
                        <Td>
                          <IconButton
                            as={Link}
                            href={handleChangeUrl(file)}
                            aria-label="Ir para o link"
                            icon={<FaExternalLinkAlt />}
                            size="sm"
                            isExternal
                          />
                        </Td>
                      </Tr>
                    )
                  })
                )}

                {billingData?.billing?.attachments && (
                  billingData.billing.attachments.map((file) => {
                    return (
                      <Tr key={file}>
                        <Td>
                          {file}
                        </Td>
                        <Td>
                          <IconButton
                            as={Link}
                            href={handleChangeUrl(file)}
                            aria-label="Ir para o link"
                            icon={<FaExternalLinkAlt />}
                            size="sm"
                            isExternal
                          />
                        </Td>
                      </Tr>
                    )
                  })
                )}
              </Tbody>
            </Table>

            <Table size="sm" mt="6">
              <Tbody>
                <Tr>
                  <Td>
                    {(!paymentSlips || !paymentSlips.length) ? (
                      'Boleto (opcional)'
                    ) : (
                      Object.entries(paymentSlips).map(([key, file]) => {
                        return (
                          <Link
                            key={key}
                            href={URL.createObjectURL(file)}
                            display="flex"
                            gap="1"
                            isExternal
                          >
                            {file.name.slice(0, 20).padEnd(23, '...')}
                            <FaLink />
                          </Link>
                        )
                      })
                    )}
                  </Td>
                  <Td isNumeric>
                    <IconButton
                      size="sm"
                      as={FormLabel}
                      aria-label="Anexar boleto"
                      icon={<FaFileImport />}
                      htmlFor="paymentSlips"
                      cursor="pointer"
                    />
                    <Input
                      {...register("paymentSlips")}
                      id="paymentSlips"
                      name="paymentSlips"
                      type="file"
                      multiple
                      hidden
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    {(!invoices || !invoices.length) ? (
                      'Fatura'
                    ) : (
                      Object.entries(invoices).map(([key, file]) => {
                        return (
                          <Link
                            key={key}
                            href={URL.createObjectURL(file)}
                            display="flex"
                            gap="1"
                            isExternal
                          >
                            {file.name.slice(0, 20).padEnd(23, '...')}
                            <FaLink />
                          </Link>
                        )
                      })

                    )}

                  </Td>
                  <Td isNumeric>
                    <IconButton
                      size="sm"
                      as={FormLabel}
                      aria-label="Anexar fatura"
                      icon={<FaFileImport />}
                      htmlFor="invoices"
                      cursor="pointer"
                      border={errors.invoices && '2px solid'}
                      borderColor={errors.invoices && 'red.500'}
                    />
                    <Input
                      {...register("invoices")}
                      id="invoices"
                      name="invoices"
                      type="file"
                      multiple
                      hidden
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    {(!attachments || !attachments.length) ? (
                      'Anexo'
                    ) : (
                      Object.entries(attachments).map(([key, file]) => {
                        return (
                          <Link
                            key={key}
                            href={URL.createObjectURL(file)}
                            display="flex"
                            gap="1"
                            isExternal
                          >
                            {file.name.slice(0, 20).padEnd(23, '...')}
                            <FaLink />
                          </Link>
                        )
                      })

                    )}
                  </Td>
                  <Td isNumeric>
                    <IconButton
                      size="sm"
                      as={FormLabel}
                      aria-label="Anexar outros arquivos"
                      icon={<FaFileImport />}
                      htmlFor="otherAttachments"
                      cursor="pointer"
                      border={errors.otherAttachments && '2px solid'}
                      borderColor={errors.otherAttachments && 'red.500'}
                    />
                    <Input
                      {...register("otherAttachments")}
                      id="otherAttachments"
                      name="otherAttachments"
                      type="file"
                      multiple
                      hidden
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>

            <Flex
              w="full"
              justify="flex-end"
              mt="6"
            >

              <Button
                size="sm"
                colorScheme="blue"
                type="submit"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Editar
              </Button>
            </Flex>

          </ModalBody>
        </>
      )
      }
      <ModalFooter></ModalFooter>
    </ModalContent >
  )
}
