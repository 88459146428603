import { Flex, Button, Icon, Stack, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState, useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FaFileCsv } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { useStockFunctions } from '../../hooks/stock/useStockFunctions'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useSwal } from '../../hooks/swal/useSwal'
import { Input } from '../../components/Inputs/Input'
import { StandardBackgroundForm } from '../../components/Form/StandardBackgroundForm'
import { FieldError } from 'react-hook-form'

interface IFormInputProps {
  csv_file: Blob | null
}

const schema = yup.object().shape({
  csv_file: yup
    .mixed()
    .required('Obrigatório')
    .test(
      'fileSize',
      'O arquivo é muito grande para o envio',
      (value) => value && value.size <= 1024 * 1024 * 9,
    ),
})

export function StockSendCsvPage() {
  const [selectedFileCsv, setSelectedFileCsv] = useState<any>('')

  const { confirmMessage, standardMessage } = useSwal()
  const { push: redirectTo } = useHistory()

  const {
    updateStockFromCsv: {
      mutateAsync: updateStockFromCsv,
      isLoading,
      isSuccess,
    },
  } = useStockFunctions()

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (isSuccess) {
      redirectTo('/estoques')
    }
  }, [isSuccess, redirectTo])

  function handleSelectFileCsv(e: { target: HTMLInputElement }) {
    if (!e.target.files || e.target.files.length === 0) {
      setValue('csv_file', null)
      setSelectedFileCsv(undefined)
      return
    }

    setValue('csv_file', e.target.files[0])
    setSelectedFileCsv(e.target.files[0])
  }

  const inputRefCsv = useRef<HTMLInputElement | null>(null)
  const handleInputRefCsv = () => inputRefCsv.current?.click()

  async function handleSendCsvFileToUpdateStock(values: IFormInputProps) {
    const hasSendCsvFile = await confirmMessage({
      title: 'Deseja enviar o arquivo csv selecionado?',
    })

    if (hasSendCsvFile) {
      const formData = new FormData()
      formData.append('csv_file', values.csv_file as Blob)
      await updateStockFromCsv(formData)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm
      onSubmit={handleSubmit(handleSendCsvFileToUpdateStock)}
      title="Atualizar Estoques"
    >
      <Input
        {...register('csv_file')}
        name="csv_file"
        label="Arquivo Excel"
        type="file"
        accept=".csv"
        ref={inputRefCsv}
        error={errors.csv_file as FieldError}
        hidden
        onChange={handleSelectFileCsv}
        required
      >
        <Flex w="full" align="center" justify="flex-start">
          <Flex align="center">
            <Button
              onClick={handleInputRefCsv}
              mr="2"
              leftIcon={<Icon as={FaFileCsv} />}
            >
              Upload
            </Button>
            {!!selectedFileCsv && <Text>{selectedFileCsv.name}</Text>}
          </Flex>
        </Flex>
      </Input>

      <Flex align="center" justify="flex-end">
        <Stack direction="row" spacing="12px">
          <Button type="submit" isLoading={isSubmitting} colorScheme="blue">
            Enviar
          </Button>
        </Stack>
      </Flex>
    </StandardBackgroundForm>
  )
}
