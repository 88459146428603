export const types = [
  { id: 0, name: 'PESSOA FÍSICA' },
  { id: 1, name: 'PESSOA JURÍDICA' },
]

export const situation = [
  { id: 0, name: 'ATIVO' },
  { id: 1, name: 'INATIVO' },
]

export const situation_customer = [
  { id: 0, name: 'ATIVO' },
  { id: 1, name: 'INATIVO' },
  { id: 2, name: 'BLOQUEADO' }
]

export const bugdet_situations = [
  { id: 0, name: 'ATIVO' },
  { id: 1, name: 'INATIVO' },
  { id: 2, name: 'SEM ONUS' },
]

export const payment_conditions = [
  { id: 0, name: 'À VISTA' },
  { id: 1, name: 'FATURADO MENSAL' },
  { id: 2, name: 'FATURADO QUINZENAL' },
]

export const payment_types = [
  { id: 0, name: 'BOLETO' },
  { id: 1, name: 'TRANSFERÊNCIA' },
]

export const billing_types = [
  { key: 'À VISTA', value: 'À VISTA', showOption: 'À VISTA' },
  { key: 'SEMANAL', value: 'SEMANAL', showOption: 'SEMANAL' },
  { key: 'QUINZENAL', value: 'QUINZENAL', showOption: 'QUINZENAL' },
  { key: 'MENSAL', value: 'MENSAL', showOption: 'MENSAL' },
  { key: 'PERSONALIZADO', value: 'PERSONALIZADO', showOption: 'PERSONALIZADO' },
]

export const lifebox_payment_terms = [
  { key: 'À VISTA', value: 'À VISTA', showOption: 'À VISTA' },
  { key: '7 DIAS', value: '7 DIAS', showOption: '7 DIAS' },
  { key: '14 DIAS', value: '14 DIAS', showOption: '14 DIAS' },
  { key: '21 DIAS', value: '21 DIAS', showOption: '21 DIAS' },
  { key: '30 DIAS', value: '30 DIAS', showOption: '30 DIAS' },
]

export const emission_types = [
  { id: 0, name: 'CTE' },
  { id: 1, name: 'NF' },
  { id: 2, name: 'AMBOS' },
]

export const contribution_types = [
  { id: 0, name: 'ISENTO' },
  { id: 1, name: 'CONTRIBUINTE' },
]

export const iss_options = [
  { id: 0, name: 'RETÉM' },
  { id: 1, name: 'NÃO RETÉM' },
]

export const negative_positive = [
  { id: 0, name: 'NÃO' },
  { id: 1, name: 'SIM' },
]

export const material_list = [
  { id: 0, name: 'GELO SECO' },
  { id: 1, name: 'ISOPOR 3L' },
  { id: 2, name: 'ISOPOR 7L' },
  { id: 3, name: 'ISOPOR 17L' },
  { id: 4, name: 'PAPELÃO 3L' },
  { id: 5, name: 'PAPELÃO 8L' },
  { id: 6, name: 'PAPELÃO 17L' },
  { id: 7, name: 'SACO ZIP' },
  { id: 8, name: 'LACRE' },
  { id: 9, name: 'GELOX' },
  { id: 10, name: 'ALMOFADA' },
  { id: 11, name: 'CANISTER 250ML' },
  { id: 12, name: 'CANISTER 1L' },
  { id: 13, name: 'ADESIVO DRY ICE' },
  { id: 14, name: 'FITA ADESIVA' },
  { id: 15, name: 'EMBALAGEM SECUNDÁRIA (POTE 250 ML)' },
  { id: 16, name: 'ADESIVO VEÍCULO' },
  { id: 17, name: 'LONA PLÁSTICA' },
  { id: 18, name: 'PLÁSTICO BOLHA' },
].sort((a, b) => a.name.localeCompare(b.name))

export const input_material_list = [
  { id: 0, name: 'GELO SECO' },
  { id: 1, name: 'GELOX' },
  { id: 2, name: 'CAIXA TÉRMICA' },
  { id: 3, name: 'ISOPOR 7L' },
  { id: 4, name: 'ISOPOR 3L' },
  { id: 5, name: 'TERCIÁRIA 3L' },
  { id: 6, name: 'TERCIÁRIA 8L' },
  { id: 7, name: 'EMBALAGEM SECUNDÁRIA ZIPLOCK' },
  { id: 8, name: 'EMBALAGEM SECUNDÁRIA (POTE 250 ML)' },
  { id: 8, name: 'EMBALAGEM SECUNDÁRIA CANISTER 1L' },
  { id: 9, name: 'LACRE' },
  { id: 10, name: 'ALMOFADA ABSORVENTE' },
  { id: 11, name: 'EMBALAGEM SECUNDÁRIA (SACO ZIP LOCK)' },
  { id: 12, name: 'FITA ADESIVA' },
  { id: 13, name: 'ADESIVO GELO SECO' },
  { id: 14, name: 'ADESIVO VEÍCULO' },
  { id: 15, name: 'PLÁSTICO BOLHA' },
  { id: 16, name: 'LONA PLÁSTICA' },
].sort((a, b) => a.name.localeCompare(b.name))

export const modal_types = [
  { id: 0, name: 'AÉREO' },
  { id: 1, name: 'RODOVIÁRIO' },
].sort((a, b) => a.name.localeCompare(b.name))

export const permissions_list = [
  { key: 'CONFIGURAÇÕES', value: 'config' },
  { key: 'PERMISSÕES', value: 'permission' },
  { key: 'USUÁRIOS', value: 'user' },
  { key: 'CLIENTES', value: 'customer' },
  { key: 'COLETADORES', value: 'collector' },
  { key: 'MOTORISTAS', value: 'driver' },
  { key: 'HUBS', value: 'hub' },
  { key: 'CIDADES', value: 'city' },
  { key: 'ENDEREÇOS', value: 'address' },
  { key: 'ESTOQUES', value: 'stock' },
  { key: 'INSUMOS', value: 'input' },
  { key: 'TRANSPORTADORA', value: 'shipping' },
  { key: 'BASE DE TRANSPORTADORA', value: 'branch' },
  { key: 'FORNECEDOR', value: 'provider' },
  { key: 'CUSTO DE COLETADOR', value: 'collector-cost' },
  { key: 'ORÇAMENTO', value: 'budget' },
  { key: 'SERVIÇOS', value: 'services' },
  { key: 'RELATÓRIOS', value: 'reports' },
  { key: 'REGISTRO NÃO CONFORMIDADE', value: 'rnc' },
  { key: 'FERIADOS', value: 'holiday' },
  { key: 'UPLOADS', value: 'upload' },
  { key: 'DOWNLOADS', value: 'download' },
  { key: 'PERFIS', value: 'profile' },
  { key: 'PREÇOS', value: 'price' },
  { key: 'COMUNICAÇÃO INTERNA', value: 'intern-comunication' },
  { key: 'MATERIAIS', value: 'materials' },
  { key: 'NOTIFICAÇÕES', value: 'notifications' },
  { key: 'AGREGADOS', value: 'aggregates' },
  { key: 'FINANCEIRAS', value: 'financial' },
  { key: 'PMC', value: 'pmc' },
  { key: 'COMPRAS', value: 'purchases' },
  { key: 'RSM', value: 'rsm' },
  { key: 'BUGS', value: 'bugs' },
  { key: 'REGIÕES', value: 'regions' },
  { key: 'CUSTOS DE EMPREGADOS', value: 'employee-cost' },
  { key: 'VACINAS', value: 'vaccines' },
  { key: 'ASOS', value: 'asos' },
  { key: 'TREINAMENTOS', value: 'trainings' },
  { key: 'PADRONIZAÇÕES', value: 'standardizations' },
  { key: 'ONBOARDINGS', value: 'onboardings' },
  { key: 'PATRIMÔNIOS', value: 'patrimonies' },
  { key: 'INTERCORRÊNCIAS CLT', value: 'clt-intercurrences' },
  { key: 'ADVERTÊNCIAS', value: 'advertences' },
  { key: 'INTERNOS CLT', value: 'intern-clts' },
  { key: 'DOCUMENTOS', value: 'documents' },
  { key: 'PERÍODO DE EXPERIÊNCIA', value: 'experience-period' },
  { key: 'FÉRIAS', value: 'vacations' },
  { key: 'PORTADORES', value: 'carriers' },
  { key: 'ATENDIMENTO PARCEIROS', value: 'partner-supports' },
  { key: 'ATENDIMENTO EXTERNO', value: 'external-supports' },
  { key: 'REGIONAIS', value: 'regionals' },
  { key: 'AUDITORIAS', value: 'audits' },
  { key: 'REEMBOLSOS', value: 'refunds' },
  { key: 'ESPORÁDICOS', value: 'sporadics' },
  { key: 'ATENDIMENTOS', value: 'supports' },
  { key: 'FEEDBACKS', value: 'feedbacks' },
  { key: 'CONTINGÊNCIAS', value: 'contingencies' },
  { key: 'CONTROLE DE FATURA', value: 'invoices' }
].sort((a, b) => a.key.localeCompare(b.key))

export const user_types = [
  { id: '0', name: 'ADMIN' },
  { id: '1', name: 'GERÊNCIA' },
  { id: '2', name: 'ANALISTA' },
  { id: '3', name: 'AUXILIAR' },
  { id: '4', name: 'CLIENTE' },
  { id: '5', name: 'COLETADOR' },
  { id: '6', name: 'MOTORISTA' },
].sort((a, b) => a.name.localeCompare(b.name))

export const deadline_delivery = [
  { id: 0, name: 'D + 0' },
  { id: 1, name: 'D + 1' },
  { id: 2, name: 'D + 2' },
  { id: 3, name: 'D + 3' },
  { id: 4, name: 'D + 4' },
  { id: 5, name: 'D + 5' },
]

export const negative_deadline = [
  { id: 0, name: 'D - 0' },
  { id: 1, name: 'D - 1' },
  { id: 2, name: 'D - 2' },
  { id: 2, name: 'D - 3' },
]

export const service_types = [
  { id: 0, name: 'DEDICADO' },
  { id: 1, name: 'FRACIONADO' },
].sort((a, b) => a.name.localeCompare(b.name))

export const vehicle_types = [
  { id: 0, name: 'CAMINHÃO' },
  { id: 1, name: 'CARRO' },
  { id: 2, name: 'MOTO' },
].sort((a, b) => a.name.localeCompare(b.name))

export const break_days_options = [
  { key: 'DIÁRIO (1 dia)', value: 1 },
  { key: 'SEMANAL (7 dias)', value: 7 },
  { key: 'QUINZENAL (15 dias)', value: 15 },
  { key: 'MENSAL (30 dias)', value: 30 },
].sort((a, b) => a.key.localeCompare(b.key))

export const measure_options = [
  { id: 0, name: 'CAIXAS' },
  { id: 1, name: 'LITROS' },
  { id: 2, name: 'UNIDADES' },
  { id: 3, name: 'ROLO' },
]

export const days_of_week = [
  { value: 0, name: 'DOMINGO' },
  { value: 1, name: 'SEGUNDA' },
  { value: 2, name: 'TERÇA' },
  { value: 3, name: 'QUARTA' },
  { value: 4, name: 'QUINTA' },
  { value: 5, name: 'SEXTA' },
  { value: 6, name: 'SÁBADO' },
]

export const service_steps = [
  { key: 'toValidateRequestedService', value: 'AGUARDANDO AGENDAMENTO' },
  { key: 'toCollectService', value: 'AGENDADO' },
  { key: 'collectingService', value: 'COLETANDO SERVIÇO' },
  { key: 'toBoardService', value: 'EMBARCAR SERVIÇO' },
  { key: 'boardingService', value: 'EM ROTA DE EMBARQUE' },
  { key: 'toBoardValidate', value: 'EMBARCADO (VALIDAÇÃO)' },
  { key: 'toAllocateService', value: 'EMBARCADO (ALOCAÇÃO)' },
  { key: 'toAvailableService', value: 'ALOCADO' },
  { key: 'toLandingService', value: 'DISPONÍVEL' },
  { key: 'landingService', value: 'DESEMBARCANDO SERVIÇO' },
  { key: 'toDeliveryService', value: 'EM ROTA DE ENTREGA' },
  { key: 'deliveringService', value: 'ENTREGANDO SERVIÇO' },
  { key: 'finishedService', value: 'ENTREGUE' },
  { key: 'unsuccessService', value: 'SERVIÇO SEM SUCESSO' },
  { key: 'cancelledService', value: 'SERVIÇO CANCELADO' },
].sort((a, b) => a.value.localeCompare(b.value))

export const filter_by = [
  { id: 1, value: 'CLIENTE' },
  { id: 0, value: 'CLIENTE (DATA)' },
  { id: 2, value: 'PROTOCOLO' },
  { id: 3, value: 'DATA' },
]

export const filter_by_customer = [
  { id: 0, value: 'CLIENTE' },
  { id: 1, value: 'PROTOCOLO' },
  { id: 2, value: 'DATA' },
]

export const filter_by_collector = [
  { id: 0, value: 'PROTOCOLO' },
  { id: 1, value: 'DATA' },
]

export const dispatch_sender = [
  { id: 0, name: 'TRANSPORTADORA' },
  { id: 1, name: 'COLETADOR' },
]

export const logFilterOptions = [
  { id: 0, name: 'PROTOCOLO' },
  { id: 1, name: 'EXCLUÍDOS' },
]

export const direction_options = [
  { id: 0, name: 'DIRETORIA COMERCIAL' },
  { id: 1, name: 'DIRETORIA DE LOGÍSTICA' },
  { id: 3, name: 'DIRETORIA DE QUALIDADE DE REGULAÇÃO' },
  { id: 4, name: 'DIRETORIA DE TECH E LEADS' },
  { id: 6, name: 'DIRETORIA FINANCEIRA' },
  { id: 7, name: 'DIRETORIA DE GENTE' },
].sort((a, b) => a.name.localeCompare(b.name))

export const risk_rating = [
  { id: 0, name: 'ALVARÁ' },
  { id: 1, name: 'BUDGET' },
  { id: 2, name: 'FISCAL' },
  { id: 3, name: 'FLUXO DE CAIXA' },
  { id: 4, name: 'FURO DE ESTOQUE' },
  { id: 5, name: 'JURÍDICO' },
  { id: 6, name: 'NÃO PROCEDENTE' },
  { id: 7, name: 'ORGANIZAÇÃO E CONTROLE' },
  { id: 8, name: 'SATISFAÇÃO DO CLIENTE' },
  { id: 9, name: 'SATISFAÇÃO DO COLABORADOR' },
  { id: 10, name: 'INCAPACITAÇÃO' },
  { id: 11, name: 'SLA' },
  { id: 12, name: 'BUGS/SISTEMA' },
  { id: 13, name: 'NO SHOW' },
  { id: 14, name: 'EXTRAVIO DE CARGA' },
  { id: 15, name: 'PERDA DE ESTABILIDADE' },
  { id: 16, name: 'FURO DE PATRIMÔNIO' },
  { id: 17, name: 'NÃO FATURAMENTO' },
].sort((a, b) => a.name.localeCompare(b.name))

export const rnc_types = [
  { id: 0, name: 'INSUMOS NÃO CONFORMES' },
  { id: 1, name: 'CONDUTA' },
  { id: 2, name: 'AGENDAMENTO INCORRETO' },
  { id: 3, name: 'FALTA DE MONITORAMENTO' },
  { id: 4, name: 'PREENCHIMENTO INCORRETO' },
  { id: 5, name: 'CAPACITAÇÃO' },
  { id: 6, name: 'NÃO CONFORMIDADE DE INDICADORES' },
  { id: 7, name: 'DECLARAÇÃO DE CONTEÚDO NÃO CONFORME' },
  { id: 8, name: 'DESVIO DE FLUXO INTERNO' },
  { id: 9, name: 'COMUNICAÇÃO' },
  { id: 10, name: 'MANUTENÇÃO' },
  { id: 11, name: 'SEM FLUXO DEFINIDO' },
  { id: 12, name: 'FALTA DE LANÇAMENTO' },
  { id: 13, name: 'LANÇAMENTO INCORRETO' },
  { id: 14, name: 'ABERTURA DE SERVIÇO INCORRETO' },
  { id: 15, name: 'CAIXA NÃO CONFORME' },
  { id: 16, name: 'EMBARQUE INCORRETO' },
].sort((a, b) => a.name.localeCompare(b.name))

export const business_meeting_options = [
  { id: 0, name: 'REUNIÃO DIRETORIA' },
  { id: 1, name: 'REUNIÃO GENTE E GESTÃO' },
  { id: 2, name: 'REUNIÃO SUCESSO DO CLIENTE' },
  { id: 3, name: 'REUNIÃO GESTÃO REGIONAL' },
  { id: 4, name: 'REUNIÃO QUALIDADE' },
].sort((a, b) => a.name.localeCompare(b.name))

export const regional_list = [
  { value: 1, name: 'Regional 1' },
  { value: 2, name: 'Regional 2' },
  { value: 3, name: 'Regional 3' },
  { value: 4, name: 'Regional 4' },
  { value: 5, name: 'Regional 5' },
  { value: 6, name: 'Regional 6' },
].sort((a, b) => a.name.localeCompare(b.name))

export const payment_type = [
  { key: 'DIÁRIO', value: 'DIÁRIO', showOption: 'DIÁRIO' },
  { key: 'MENSAL', value: 'MENSAL', showOption: 'MENSAL' },
]

