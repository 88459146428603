import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";


interface DeleteContingencyProps {
  contingencyId: string
}

export async function deleteContingency({
  contingencyId
}: DeleteContingencyProps) {
  try {
    const response = await api.delete(`/contingencies/${contingencyId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
