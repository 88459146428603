import { Box, Flex } from "@chakra-ui/react";
import { DispatchStockProposalStatusTypes } from "api/dispachStocks/dispatchStockProposal";
import { dispatchStockProposalStatusMap } from "utils/dispatchStockMappers";

interface DispatchStockProposalStatusProps {
  status: DispatchStockProposalStatusTypes;
}

export function DispatchStockProposalStatus({ status }: DispatchStockProposalStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {status === 'approving' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'reproved' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {status === 'sending' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="blue.300"
        />
      )}

      {status === 'sended' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {dispatchStockProposalStatusMap[status]}
    </Flex>
  )
}
