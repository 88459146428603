import { Button, Flex, Input } from "@chakra-ui/react";
import { useSearchParams } from "hooks/useSearchParams";
import { Controller, useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import AsyncSelect from "react-select/async"
import { captalize } from "utils/captalize";
import { IHubsProps } from "utils/RequestFunctions/Hubs/requestHubFunctions";

interface StocksTableFiltersProps {
  hubs: IHubsProps[]
}

interface StocksTableFiltersSchema {
  collector: string
  input: string
  hubs: string
}

export function StocksTableFilters({ hubs }: StocksTableFiltersProps) {
  const searchParams = useSearchParams()
  const { replace } = useHistory()


  const collector = searchParams.get('collector')
  const input = searchParams.get('input')
  const hubsFilter = searchParams.get('hubs')

  const {
    register,
    handleSubmit,
    reset,
    control
  } = useForm<StocksTableFiltersSchema>({
    defaultValues: {
      collector: collector ?? '',
      input: input ?? '',
      hubs: hubsFilter ?? ''
    }
  })

  async function handleFilterStocks(data: StocksTableFiltersSchema) {
    if (data.collector) {
      searchParams.set('collector', data.collector)
    } else {
      searchParams.delete('collector')
    }

    if (data.input) {
      searchParams.set('input', data.input)
    } else {
      searchParams.delete('input')
    }

    if (data.hubs) {
      searchParams.set('hubs', data.hubs)
    } else {
      searchParams.delete('hubs')
    }


    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    reset()
    searchParams.delete('collector')
    searchParams.delete('input')
    searchParams.delete('hubs')


    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  async function promiseOptions(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    return hubs.map(hub => {
      return { value: hub.id, label: captalize(hub.name) }
    })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterStocks)}
    >
      <Input
        {...register("collector")}
        placeholder="Coletador"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />
      <Input
        {...register("input")}
        placeholder="Insumo"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />

      <Flex w={["full", "full", "xs"]}>
        <Controller
          control={control}
          name="hubs"
          render={({ field }) => (
            <AsyncSelect
              name={field.name}
              onChange={(arr) => field.onChange(arr?.map(v => v.value))}
              cacheOptions
              defaultOptions
              placeholder="Hubs..."
              loadOptions={promiseOptions}
              isClearable={true}
              isMulti
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: '100%',
                }),
              }}
            />
          )}
        />
      </Flex>


      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={["full", "full", "min"]}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleRemoveFilters}
        w={["full", "full", "min"]}
      >
        Remover filtros
      </Button>

    </Flex>
  )
}
