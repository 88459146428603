import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Flex, TableContainer, Table, Thead, Tr, Th, Tbody, ModalFooter, Button } from "@chakra-ui/react";
import { EmptyContentTableAlert } from "components/Alerts/EmptyContentTableAlert";
import { Fragment } from "react";
import { CitiesLogs } from "services/getFunctions/city/getCity"
import { days_of_week } from "utils/customLists";
import { formatDate } from "utils/DateFunctions/formatDate";
import { IHubsProps } from "utils/RequestFunctions/Hubs/requestHubFunctions";
import { CityLogLine } from "./CityLogLine";

interface CityLogsModalProps {
  isOpen: boolean
  onClose: () => void
  cityLogs: CitiesLogs[]
  hubs: IHubsProps[]
}

export function CityLogsModal({ isOpen, onClose, cityLogs, hubs }: CityLogsModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="850px">
        <ModalHeader>Logs</ModalHeader>
        <ModalCloseButton />

        <ModalBody maxH='500px' h='full' overflow='auto' >
          <Flex direction='column' align='center'>
            <TableContainer borderRadius='md' maxW='700px'>
              <Table m='2' maxW='500px' variant='simple' size='sm'>
                <Thead>
                  <Tr>
                    <Th>CAMPO</Th>
                    <Th>VALOR ANTERIOR</Th>
                    <Th>VALOR ATUALIZADO</Th>
                    <Th>ATUALIZADO POR</Th>
                    <Th>DATA DA ATUALIZAÇÃO</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {cityLogs.length !== 0 ? cityLogs.map((log) => {
                    return (
                      <Fragment key={log.id}>
                        {log.log_detail?.situation && (
                          <CityLogLine
                            name="Situação"
                            prevValue={String(log?.log_detail?.situation?.prev_value)}
                            newValue={String(log?.log_detail?.situation?.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`}
                            updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.name && (
                          <CityLogLine
                            name="Nome"
                            prevValue={String(log?.log_detail?.name?.prev_value)}
                            newValue={String(log?.log_detail?.name?.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`}
                            updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.state && (
                          <CityLogLine
                            name="Estado"
                            prevValue={String(log?.log_detail?.state?.prev_value)}
                            newValue={String(log?.log_detail?.state?.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`}
                            updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.hub_id && (
                          <CityLogLine
                            name="Pertence ao HUB"
                            prevValue={hubs?.find((hub) => hub.id === log.log_detail?.hub_id.prev_value)?.name}
                            newValue={hubs?.find((hub) => hub.id === log.log_detail?.hub_id.new_value)?.name}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`}
                            updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.schedule_deadline && (
                          <CityLogLine
                            name="Horário Limite de Agendamento"
                            prevValue={String(formatDate.handle(log?.log_detail?.schedule_deadline?.prev_value, "DateWithDateAndHourMinute"))}
                            newValue={String(formatDate.handle(log?.log_detail?.schedule_deadline?.new_value, "DateWithDateAndHourMinute"))}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`}
                            updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.opening_deadline && (
                          <CityLogLine
                            name="Prazo de Agendamento"
                            prevValue={String(`D-${log?.log_detail?.opening_deadline.prev_value}`)}
                            newValue={String(`D-${log?.log_detail?.opening_deadline.new_value}`)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`}
                            updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.start_attendance_block && (
                          <CityLogLine
                            name="Bloco Inicial de Atendimento"
                            prevValue={String(formatDate.handle(log?.log_detail?.start_attendance_block?.prev_value, "DateWithDateAndHourMinute"))}
                            newValue={String(formatDate.handle(log?.log_detail?.start_attendance_block?.new_value, "DateWithDateAndHourMinute"))}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`}
                            updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.final_attendance_block && (
                          <CityLogLine
                            name="Bloco Final de Atendimento"
                            prevValue={String(formatDate.handle(log?.log_detail?.final_attendance_block?.prev_value, "DateWithDateAndHourMinute"))}
                            newValue={String(formatDate.handle(log?.log_detail?.final_attendance_block?.new_value, "DateWithDateAndHourMinute"))}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`}
                            updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.saturday_start_attendance_block && (
                          <CityLogLine
                            name="Bloco inicial de atendimento sábados"
                            prevValue={String(formatDate.handle(log?.log_detail?.saturday_start_attendance_block?.prev_value, "DateWithDateAndHourMinute"))}
                            newValue={String(formatDate.handle(log?.log_detail?.saturday_start_attendance_block?.new_value, "DateWithDateAndHourMinute"))}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`}
                            updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.saturday_final_attendance_block && (
                          <CityLogLine
                            name="Bloco final de atendimento sábados"
                            prevValue={String(formatDate.handle(log?.log_detail?.saturday_final_attendance_block?.prev_value, "DateWithDateAndHourMinute"))}
                            newValue={String(formatDate.handle(log?.log_detail?.saturday_final_attendance_block?.new_value, "DateWithDateAndHourMinute"))}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`}
                            updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.sunday_start_attendance_block && (
                          <CityLogLine
                            name="Bloco inicial de atendimento domingos"
                            prevValue={String(formatDate.handle(log?.log_detail?.sunday_start_attendance_block?.prev_value, "DateWithDateAndHourMinute"))}
                            newValue={String(formatDate.handle(log?.log_detail?.sunday_start_attendance_block?.new_value, "DateWithDateAndHourMinute"))}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`}
                            updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.sunday_final_attendance_block && (
                          <CityLogLine
                            name="Bloco final de atendimento domingos"
                            prevValue={String(formatDate.handle(log?.log_detail?.sunday_final_attendance_block?.prev_value, "DateWithDateAndHourMinute"))}
                            newValue={String(formatDate.handle(log?.log_detail?.sunday_final_attendance_block?.new_value, "DateWithDateAndHourMinute"))}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`}
                            updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.days_service && (
                          <CityLogLine
                            name="Dias de Atendimento"
                            prevValue={String(days_of_week.reduce<string[]>((acc, curr) => {
                              log?.log_detail?.days_service?.prev_value?.forEach(day => {
                                if (day === curr.value) {
                                  acc.push(" " + curr.name)
                                }
                              })
                              return acc
                            }, []))}
                            newValue={String(days_of_week.reduce<string[]>((acc, curr) => {
                              log?.log_detail?.days_service?.new_value?.forEach(day => {
                                if (day === curr.value) {
                                  acc.push(" " + curr.name)
                                }
                              })
                              return acc
                            }, []))}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`}
                            updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.observation && (
                          <CityLogLine
                            name="Observações"
                            prevValue={String(log?.log_detail?.observation?.prev_value)}
                            newValue={String(log?.log_detail?.observation?.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`}
                            updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                      </Fragment>
                    )
                  }) : (
                    <EmptyContentTableAlert title="Ops..." description="Não há logs para esta cidade!" />
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>

        </ModalBody>
        <ModalFooter>
          <Flex gap={2}>
            <Button
              onClick={onClose}
              colorScheme='gray'
              variant='ghost'
            >
              Fechar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
