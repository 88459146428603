import { Flex, Stack } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { Options, Select } from "components/Inputs/SelectInput";
import { useCurrency } from "react-hook-currency";
import { useFormContext } from "react-hook-form";

export interface EmployeeCostFormInputs {
  remuneration: string
  fapAliquot: number
  thirdyPartyEmployerInss: number
  satEmployerInss: number
  fgts: number
  vacation: number
  oneThirdOfVacation: number
  employerInssVacation: number
  vacationFgts: number
  thirteenthSalary: number
  fgtsThirteenthSalary: number
  employerInsuranceThirteenthSalary: number
  costForecastWithIndemnifiedPriorNotice: number
  fgtsPriorNotice: number
  fgtsFineUponDismissalWithoutJustCause: number
  transportationVoucher: string
  foodVoucher: string
  mealTicket: string
  uniform: string
  healthInsurance: string
  dentalPlan: string
  lifeInsurance: string
  monetaryAllowance: string
  motorbykeCarRental: string
  workMaterials: string
  fuel: string
  cellphone: string
  tracker: string
  homeWork: string
  backup: string
  fairTrader: string
  regionId: string
  vehicle: string
  monthlyKmLimit: string
  kmExtraPrice: string
  profitMargin: string
  taxCharges: string
}

interface EmployeeCostFormProps {
  slug?: string
  regionsOptions: Options[]
}

export const vehicle_types = [
  { key: "0", value: 'CARRO', showOption: 'CARRO' },
  { key: "1", value: 'MOTO', showOption: 'MOTO' },
]

export function EmployeeCostForm({
  regionsOptions,
  slug
}: EmployeeCostFormProps) {
  const isViewPage = slug === 'visualizar'

  const { onClick, onChange, format } = useCurrency({
    style: 'decimal',
  })

  const {
    register,
    formState: {
      errors,
    },
  } = useFormContext<EmployeeCostFormInputs>()

  return (
    <Flex direction='column' gap={4}>
      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register("remuneration")}
          name="remuneration"
          label="Salário"
          addChildren="R$"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.remuneration}
          isDisabled={isViewPage}
          required
        />
        <Input
          {...register("fapAliquot")}
          type="number"
          name="fapAliquot"
          label="Informe alíquota FAP do ano corrente"
          addChildren="%"
          error={errors.fapAliquot}
          isDisabled={isViewPage}
          required
        />



      </Stack>

      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Select
          {...register("regionId")}
          name="regionId"
          label="Região"
          placeholder="Selecione uma região..."
          options={regionsOptions}
          error={errors.regionId}
          isDisabled={isViewPage}
          required
        />

        <Select
          {...register("vehicle")}
          name="vehicle"
          label="Veículo"
          placeholder="Selecione o tipo de veículo..."
          options={vehicle_types}
          error={errors.vehicle}
          isDisabled={isViewPage}
          required
        />
      </Stack>

      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register("thirdyPartyEmployerInss")}
          name="thirdyPartyEmployerInss"
          label="INSS patronal terceiros (Outras Entidades) - Consultar IN 1238/2012"
          type="number"
          addChildren="%"
          error={errors.thirdyPartyEmployerInss}
          isDisabled={isViewPage}
          required
        />

        <Input
          {...register("satEmployerInss")}
          name="satEmployerInss"
          label="INSS patronal SAT (RAT) - Consultar Anexo I da IN 1027/2010"
          type="number"
          addChildren="%"
          error={errors.satEmployerInss}
          isDisabled={isViewPage}
          required
        />
      </Stack>

      <Input
        {...register("fgts")}
        name="fgts"
        label="FGTS"
        addChildren="%"
        type="number"
        error={errors.fgts}
        isDisabled={isViewPage}
        required
      />


      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register("vacation")}
          name="vacation"
          label="Férias"
          type="number"
          addChildren="%"
          error={errors.vacation}
          isDisabled={isViewPage}
          required
        />

        <Input
          {...register("oneThirdOfVacation")}
          name="oneThirdOfVacation"
          label="1/3 de férias"
          type="number"
          addChildren="%"
          error={errors.oneThirdOfVacation}
          isDisabled={isViewPage}
          required
        />
      </Stack>

      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register("employerInssVacation")}
          name="employerInssVacation"
          label="INSS patronal férias"
          type="number"
          addChildren="%"
          error={errors.employerInssVacation}
          isDisabled={isViewPage}
          required
        />

        <Input
          {...register("vacationFgts")}
          name="vacationFgts"
          label="FGTS férias"
          type="number"
          addChildren="%"
          error={errors.vacationFgts}
          isDisabled={isViewPage}
          required
        />
      </Stack>


      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register("thirteenthSalary")}
          name="thirteenthSalary"
          label="13º salário"
          type="number"
          addChildren="%"
          error={errors.thirteenthSalary}
          isDisabled={isViewPage}
          required
        />

        <Input
          {...register("fgtsThirteenthSalary")}
          name="fgtsThirteenthSalary"
          label="FGTS 13º salário"
          type="number"
          addChildren="%"
          error={errors.fgtsThirteenthSalary}
          isDisabled={isViewPage}
          required
        />
      </Stack>

      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register("employerInsuranceThirteenthSalary")}
          name="employerInsuranceThirteenthSalary"
          label="INSS patronal 13º salário"
          type="number"
          addChildren="%"
          error={errors.employerInsuranceThirteenthSalary}
          isDisabled={isViewPage}
          required
        />


        <Input
          {...register("costForecastWithIndemnifiedPriorNotice")}
          name="costForecastWithIndemnifiedPriorNotice"
          label="Previsão de custo com aviso prévio indenizado"
          type="number"
          addChildren="%"
          error={errors.costForecastWithIndemnifiedPriorNotice}
          isDisabled={isViewPage}
          required
        />
      </Stack>

      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register("fgtsPriorNotice")}
          name="fgtsPriorNotice"
          label="FGTS aviso prévio"
          type="number"
          addChildren="%"
          error={errors.fgtsPriorNotice}
          isDisabled={isViewPage}
          required
        />

        <Input
          {...register("fgtsFineUponDismissalWithoutJustCause")}
          name="fgtsFineUponDismissalWithoutJustCause"
          label="Multa FGTS 40% por ocasião da demissão s/justa causa"
          type="number"
          addChildren="%"
          error={errors.fgtsFineUponDismissalWithoutJustCause}
          isDisabled={isViewPage}
          required
        />
      </Stack>


      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register("transportationVoucher")}
          name="transportationVoucher"
          label="Vale transporte"
          addChildren="R$"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.transportationVoucher}
          isDisabled={isViewPage}
          required
        />

        <Input
          {...register("foodVoucher")}
          name="foodVoucher"
          label="Alimentação"
          addChildren="R$"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.foodVoucher}
          isDisabled={isViewPage}
          required
        />
      </Stack>

      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register("mealTicket")}
          name="mealTicket"
          label="Refeição"
          addChildren="R$"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.mealTicket}
          isDisabled={isViewPage}
          required
        />

        <Input
          {...register("uniform")}
          name="uniform"
          label="Uniforme"
          addChildren="R$"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.uniform}
          isDisabled={isViewPage}
          required
        />
      </Stack>

      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register("healthInsurance")}
          name="healthInsurance"
          label="Plano de saúde"
          addChildren="R$"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.healthInsurance}
          isDisabled={isViewPage}
          required
        />

        <Input
          {...register("dentalPlan")}
          name="dentalPlan"
          label="Plano odontológico"
          addChildren="R$"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.dentalPlan}
          isDisabled={isViewPage}
          required
        />
      </Stack>

      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register("lifeInsurance")}
          name="lifeInsurance"
          label="Seguro de vida"
          addChildren="R$"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.lifeInsurance}
          isDisabled={isViewPage}
          required
        />

        <Input
          {...register("monetaryAllowance")}
          name="monetaryAllowance"
          label="Abono pecuniário"
          addChildren="R$"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.monetaryAllowance}
          isDisabled={isViewPage}
          required
        />
      </Stack>

      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register("motorbykeCarRental")}
          name="motorbykeCarRental"
          label="Aluguel moto/carro"
          addChildren="R$"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.motorbykeCarRental}
          isDisabled={isViewPage}
          required
        />

        <Input
          {...register("workMaterials")}
          name="workMaterials"
          label="Materiais de trabalho"
          addChildren="R$"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.workMaterials}
          isDisabled={isViewPage}
          required
        />
      </Stack>

      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register("fuel")}
          name="fuel"
          label="Combustível"
          addChildren="Km/L"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.fuel}
          isDisabled={isViewPage}
          required
        />

        <Input
          {...register("cellphone")}
          name="cellphone"
          label="Celular"
          addChildren="R$"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.cellphone}
          isDisabled={isViewPage}
          required
        />
      </Stack>

      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register("tracker")}
          name="tracker"
          label="Rastreador"
          addChildren="R$"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.tracker}
          isDisabled={isViewPage}
          required
        />

        <Input
          {...register("homeWork")}
          name="homeWork"
          label="Casa trabalho"
          addChildren="R$"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.homeWork}
          isDisabled={isViewPage}
          required
        />
      </Stack>

      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register("backup")}
          name="backup"
          label="Backup"
          addChildren="R$"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.backup}
          isDisabled={isViewPage}
          required
        />

        <Input
          {...register("fairTrader")}
          name="fairTrader"
          label="Feirista"
          addChildren="R$"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.fairTrader}
          isDisabled={isViewPage}
          required
        />
      </Stack>

      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register("monthlyKmLimit")}
          name="monthlyKmLimit"
          label="Limite de KM mensais"
          addChildren="KM"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.monthlyKmLimit}
          isDisabled={isViewPage}
          required
        />

        <Input
          {...register("kmExtraPrice")}
          name="kmExtraPrice"
          label="Preço do KM adicional"
          addChildren="R$"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.fairTrader}
          isDisabled={isViewPage}
          required
        />
      </Stack>

      <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register("profitMargin")}
          name="profitMargin"
          label="Margem de lucro"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.profitMargin}
          isDisabled={isViewPage}
          required
        />

        <Input
          {...register("taxCharges")}
          name="taxCharges"
          label="Encargos fiscais"
          onClick={onClick}
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.taxCharges}
          isDisabled={isViewPage}
          required
        />
      </Stack>

    </Flex>
  )
}
