import { Box, Button, Stack, Link as ChakraLink, Table, Tbody, Tr, IconButton, Td } from '@chakra-ui/react'
import { TextArea } from 'components/Inputs/TextInput'
import { Address } from 'hooks/address/dtos/Address'
import { useAuth } from 'hooks/auth/useAuth'
import { useEffect, useState, Fragment, useContext } from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { useHistory, useParams } from 'react-router-dom'
import { Input } from '../../../components/Inputs/Input'
import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'

import { CollectedServiceContext } from '../../../contexts/CollectedServiceContext'
import { ProviderProps } from '../../../contexts/ProviderContext'
import { useContentLoading } from '../../../hooks/loading/useContentLoading'
import { useCollectService } from '../../../hooks/services/collectService'
import { useService } from '../../../hooks/services/service'
import { useSwal } from '../../../hooks/swal/useSwal'
import { handleChangeUrl } from '../../../utils/handleChangeUrl'

interface IQueryParams {
  service_id: string
}

export function ValidateUnsuccessCollectedService() {
  const [isResponseLoading, setIsResponseLoading] = useState(false)

  const [
    unsuccessCollectedAddressesToValidate,
    setUnsuccessCollectedAddressesToValidate,
  ] = useState<Address[] | null>(null)
  const [
    unsuccessCollectedProvidersToValidate,
    setUnsuccessCollectedProvidersToValidate,
  ] = useState<ProviderProps[] | null>(null)

  const { userLogged } = useAuth()
  const { service_id }: IQueryParams = useParams()
  const { push: redirectTo, goBack } = useHistory()
  const { confirmMessage, standardMessage } = useSwal()
  const { handleSetIsContentLoadingFalse, isContentLoading } =
    useContentLoading()

  useEffect(() => {
    if (!userLogged?.permissions.includes('validate-unsuccess-collect-service')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  const {
    service: { data: service, isLoading: isServiceLoading },
  } = useService(service_id, false, false)
  const {
    collectedService: {
      data: collectedServiceByServiceId,
      isLoading: isCollectedServiceLoading,
      refetch,
    },
  } = useCollectService(service_id, false, false)

  const { updateUnsuccessCollectedService } = useContext(
    CollectedServiceContext,
  )

  useEffect(() => {
    if (!isCollectedServiceLoading && !isServiceLoading) {
      handleSetIsContentLoadingFalse()
    }
  }, [
    isCollectedServiceLoading,
    handleSetIsContentLoadingFalse,
    isServiceLoading,
  ])

  useEffect(() => {
    if (collectedServiceByServiceId) {
      const unsuccessAddressesToValidate = collectedServiceByServiceId
        .filter((collectService) => collectService.step === 'VALIDATEUNSUCCESS')
        .filter((collectService) => collectService.address_id !== null)
        .map((collectService) => collectService.addressIDCollect)

      setUnsuccessCollectedAddressesToValidate(unsuccessAddressesToValidate)
    }
  }, [collectedServiceByServiceId])

  useEffect(() => {
    if (collectedServiceByServiceId) {
      const unsuccessProvidersToValidate = collectedServiceByServiceId
        .filter((collectService) => collectService.step === 'VALIDATEUNSUCCESS')
        .filter((collectService) => collectService.provider_id !== null)
        .map((collectService) => collectService.providerIDCollect)

      setUnsuccessCollectedProvidersToValidate(unsuccessProvidersToValidate)
    }
  }, [collectedServiceByServiceId])

  useEffect(() => {
    if (!isCollectedServiceLoading) {
      if (
        unsuccessCollectedAddressesToValidate !== null &&
        unsuccessCollectedProvidersToValidate !== null
      ) {
        if (
          unsuccessCollectedAddressesToValidate.length === 0 &&
          unsuccessCollectedProvidersToValidate.length === 0
        ) {
          setIsResponseLoading(true)
          goBack()
        }
      }
    }
  }, [
    unsuccessCollectedAddressesToValidate,
    unsuccessCollectedProvidersToValidate,
    isCollectedServiceLoading,
    service,
    goBack,
  ])

  const handleReturnServiceToCollectOrCollecting = async (
    collectId: string,
  ) => {
    const hasReturnServiceToCollectOrCollecting = await confirmMessage({
      title: 'Deseja retornar a coleta para a fase inicial?',
    })

    if (hasReturnServiceToCollectOrCollecting) {
      setIsResponseLoading(true)
      const updateUnsuccessCollectedServiceReqObj = {
        hasReturnServiceToCollect: true,
        hasValidateCollectAsUnsuccess: false,
        collect_id: collectId,
        is_business: service?.is_business === true,
      }
      try {
        const updateUnsuccessCollectedServiceResponse =
          await updateUnsuccessCollectedService(
            service_id,
            updateUnsuccessCollectedServiceReqObj,
          )
        if (updateUnsuccessCollectedServiceResponse) {
          refetch()
          setIsResponseLoading(false)
        }
      } catch {
        setIsResponseLoading(false)
      }
      return
    }

    standardMessage('Ação cancelada com êxito!')
  }

  const handleValidateCollectServiceAsUnsuccess = async (collectId: string) => {
    const hasValidateCollectServiceAsUnsuccess = await confirmMessage({
      title: 'Deseja validar a coleta como sem sucesso?',
    })

    if (hasValidateCollectServiceAsUnsuccess) {
      setIsResponseLoading(true)
      const updateUnsuccessCollectedServiceReqObj = {
        hasReturnServiceToCollect: false,
        hasValidateCollectAsUnsuccess: true,
        collect_id: collectId,
        is_business: service?.is_business === true,
      }
      try {
        const updateUnsuccessCollectedServiceResponse =
          await updateUnsuccessCollectedService(
            service_id,
            updateUnsuccessCollectedServiceReqObj,
          )
        if (updateUnsuccessCollectedServiceResponse) {
          refetch()
          setIsResponseLoading(false)
        }
      } catch {
        setIsResponseLoading(false)
      }
      return
    }

    standardMessage('Ação cancelada com êxito!')
  }

  if (isContentLoading || isResponseLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Box flex="1" borderRadius="8px" bg="white" p={['6', '8']}>
        {collectedServiceByServiceId &&
          unsuccessCollectedAddressesToValidate !== null && (
            <>
              {unsuccessCollectedAddressesToValidate?.map((address) => (
                <Fragment key={address.id}>
                  {collectedServiceByServiceId
                    .filter(
                      (collectService) =>
                        collectService.step === 'VALIDATEUNSUCCESS',
                    )
                    .filter(
                      (collectService) =>
                        collectService.address_id === address.id,
                    )
                    .map((collectService) => {
                      const contentDeclarations = collectService.content_declarations
                        ? typeof collectService.content_declarations === 'string'
                          ? JSON.parse(collectService.content_declarations) as { key: string, filename: string }[]
                          : collectService.content_declarations as { key: string, filename: string }[]
                          : null

                      return (
                        <Fragment key={collectService.id}>
                          <Stack direction="column" spacing="6">
                            <Stack
                              spacing="24px"
                              direction={['column', 'column', 'row']}
                            >
                              <Input
                                name="driver_id"
                                label="Motorista"
                                defaultValue={`${collectService.driverIDCollect.firstname} ${collectService.driverIDCollect.lastname}`}
                                isDisabled={true}
                              />
                              <Input
                                name="service_protocol"
                                label="Protocolo"
                                defaultValue={
                                  collectService.serviceIDCollect.protocol
                                }
                                isDisabled={true}
                              />
                            </Stack>
                            <Stack
                              spacing="24px"
                              direction={['column', 'column', 'row']}
                            >
                              <Input
                                name="responsible_name"
                                label="Nome do responsável"
                                defaultValue={`${collectService.responsible_name}`}
                                isDisabled={true}
                              />

                            </Stack>
                            <Stack
                              spacing="24px"
                              direction={['column', 'column', 'row']}
                            >
                              <Input
                                name="unsuccess_reason"
                                label="Motivo"
                                defaultValue={`${collectService.unsuccess_reason ?? '-'}`}
                                isDisabled={true}
                              />

                            </Stack>
                            <Input
                              // key={address.id}
                              name="unsuccessAddresses"
                              label="Endereço Sem Sucesso"
                              defaultValue={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep} | ${address.responsible_name}`}
                              isDisabled={true}
                            />
                            <Stack
                              spacing="24px"
                              direction={['column', 'column', 'row']}
                            >
                              <TextArea
                                name="observation"
                                label="Observações"
                                value={collectService.observation}
                                isDisabled
                              />
                            </Stack>
                            {contentDeclarations && (
                              <Table size="sm">
                                <Tbody>
                                  {contentDeclarations.map((declaration) => {
                                    return (
                                      <Tr key={declaration.key}>
                                        <Td>{declaration.filename}</Td>
                                        <Td isNumeric>
                                          <IconButton
                                            aria-label="Ver declaração"
                                            as={ChakraLink}
                                            colorScheme="blue"
                                            size="sm"
                                            icon={<FaExternalLinkAlt />}
                                            href={handleChangeUrl(declaration.key)}
                                            isExternal
                                          />
                                        </Td>
                                      </Tr>
                                    )
                                  })}
                                </Tbody>
                              </Table>
                            )}
                          </Stack>
                          <Stack
                            w="full"
                            direction={['column', 'column', 'row']}
                            mt="4"
                            spacing={4}
                          >
                            <Button
                              onClick={() =>
                                handleReturnServiceToCollectOrCollecting(
                                  collectService.id,
                                )
                              }
                              w="full"
                              colorScheme="facebook"
                            >
                              Retornar Para Coleta
                            </Button>
                            <Button
                              onClick={() =>
                                handleValidateCollectServiceAsUnsuccess(
                                  collectService.id,
                                )
                              }
                              w="full"
                              colorScheme="blackAlpha"
                            >
                              Validar Como Sem Sucesso
                            </Button>
                          </Stack>
                        </Fragment>
                      )
                    })}
                </Fragment>
              ))}
            </>
          )}

        {unsuccessCollectedProvidersToValidate !== null &&
          collectedServiceByServiceId && (
            <>
              {unsuccessCollectedProvidersToValidate.map((provider) => (
                <Fragment key={provider.id}>
                  {collectedServiceByServiceId
                    .filter(
                      (collectService) =>
                        collectService.step === 'VALIDATEUNSUCCESS',
                    )
                    .filter(
                      (collectService) =>
                        collectService.provider_id === provider.id,
                    )
                    .map((collectService) => (
                      <Fragment key={collectService.id}>
                        <Stack direction="column" mt="4" spacing="24px">
                          <Stack
                            spacing="24px"
                            direction={['column', 'column', 'row']}
                          >
                            <Input
                              name="driver_id"
                              label="Motorista"
                              defaultValue={` ${collectService.driverIDCollect.firstname} ${collectService.driverIDCollect.lastname}`}
                              isDisabled={true}
                            />
                            <Input
                              name="service_protocol"
                              label="Protocolo"
                              defaultValue={
                                collectService.serviceIDCollect.protocol
                              }
                              isDisabled={true}
                            />
                          </Stack>
                          <Input
                            key={provider.id}
                            name="unsuccessProviders"
                            label="Endereço de Fornecedor Gelo Seco Sem Sucesso"
                            defaultValue={`| ${provider.trading_name} | ${provider.street} | ${provider.number} | ${provider.neighborhood} | ${provider.cep}`.toUpperCase()}
                            isDisabled={true}
                          />
                        </Stack>
                        <Stack
                          w="full"
                          direction={['column', 'column', 'row']}
                          mt="4"
                        >
                          <Button
                            onClick={() =>
                              handleReturnServiceToCollectOrCollecting(
                                collectService.id,
                              )
                            }
                            w="full"
                            colorScheme="facebook"
                          >
                            Retornar Para Coleta
                          </Button>
                          <Button
                            onClick={() =>
                              handleValidateCollectServiceAsUnsuccess(
                                collectService.id,
                              )
                            }
                            w="full"
                            colorScheme="blackAlpha"
                          >
                            Validar Como Sem Sucesso
                          </Button>
                        </Stack>
                      </Fragment>
                    ))}
                </Fragment>
              ))}
            </>
          )}
      </Box>

  )
}
