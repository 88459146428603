import { getHours, getMinutes } from 'date-fns'

export function branchFormatDateToFront(data: string | null) {
  if (data === null) {
    return data
  }

  let hour = getHours(new Date(data)).toString()
  let minute = getMinutes(new Date(data)).toString()

  if (hour.length === 1) {
    hour = `0${hour}`
  }

  if (minute.length === 1) {
    minute = `0${minute}`
  }

  return `${hour}:${minute}`
}
