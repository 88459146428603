import { MenuButton, IconButton, IconButtonProps, MenuButtonProps } from "@chakra-ui/react";
import { FaEllipsisH } from "react-icons/fa";

interface BugActionsMenuTriggerProps extends Omit<MenuButtonProps, 'aria-label'>, IconButtonProps { }

export function BugActionsMenuTrigger({ ...rest }: BugActionsMenuTriggerProps) {
  return (
    <MenuButton
      as={IconButton}
      icon={<FaEllipsisH />}
      size="xs"
      {...rest}
    />
  )
}
