import { Box, Flex, Icon, IconButton, Td, Text, Tooltip, Tr, Link as ChakraLink, Button } from "@chakra-ui/react"
import { formatDate } from "utils/DateFunctions/formatDate"
import { serviceHandleStep } from '../../../utils/ServiceFunctions/serviceDict'
import { FaInfoCircle, FaSearch } from "react-icons/fa"
import { Link } from "react-router-dom"
import { serviceDelay } from "utils/serviceDelay"
import { formatTimeByTimezoneUser } from "utils/formatTimeByTimezoneUser"
import { format } from "date-fns"
import { FaArrowRight } from "react-icons/fa6"
import { useAuth } from "hooks/auth/useAuth"

interface DeliveriesTableRowProps {
  service: {
    id: string
    protocol: number,
    deliveryDate: string
    deliveryHour: string
    serviceType: string
    sourceCollector: {
      id: string
      tradingName: string
    }
    destinationCollector: {
      id: string
      tradingName: string
    }
    destinationAddresses: {
      id: string
      street: string
      neighborhood: string
      number: number
      cep: string
      branch: string
      tradingName: string
      responsibleName: string
      city: {
        id: string
        name: string
      }
      hub: {
        id: string
        name: string
        timezone: string
      }
      isDelivered: boolean
    }[]
    sourceAddresses: {
      id: string
      street: string
      neighborhood: string
      number: number
      cep: string
      branch: string
      tradingName: string
      responsibleName: string
      city: {
        id: string
        name: string
      }
      hub: {
        id: string
        name: string
        timezone: string
      }
    }[]
    customer: {
      id: string
      tradingFirstname: string
    }
    sourceBranch: {
      id: string
      nickname: string
      shipping: {
        id: string
        companyName: string
      }
    } | null
    destinationBranch: {
      id: string
      nickname: string
      shipping: {
        id: string
        companyName: string
      }
    } | null
    boardService: {
      id: string
      cte: string
      boardVolume: number
      operationalNumber: string
      trackerLink: string | null
    } | null
    vehicle: string
    cte: number
    tracker: number
    step: string
    hasOccurrence: boolean
  }
}

export function DeliveriesTableRow({ service }: DeliveriesTableRowProps) {
  const { userLogged } = useAuth()

  const destinationHubTimezone = service.sourceAddresses[0].hub.timezone

  const serviceInDelay = serviceDelay({
    timezoneOffset: destinationHubTimezone,
    serviceDate: service.deliveryDate,
    serviceHour: service.deliveryHour
  })


  const userCanDoServiceMaintenance = userLogged?.permissions.includes('view-maintenance-service')
  const userCanValidateFiscalRetention = userLogged?.permissions.includes('validate-fiscal-retention')
  const userCanDoServiceLanding = userLogged?.permissions.includes('add-landing-service')
  const userCanAvailableService = userLogged?.permissions.includes('add-available-service')
  const userCanAllocateService = userLogged?.permissions.includes('add-allocated-service')
  const userCanValidateBoardService = userLogged?.permissions.includes('valid-boarding-service')
  const userCanDeliveryAddress = userLogged?.permissions.includes('add-delivery-service')

  const pathRedirect = '/relacao/servicos?tab=deliveries&page=1'

  return (
    <Tr
      key={service.protocol}
      color={service.hasOccurrence ? 'yellow.400' : serviceInDelay ? 'red.400' : ''}
    >
      <Td>
        <Flex align="center" gap="3">
          <IconButton
            as={Link}
            to={`/servico/detalhes/${service.id}`}
            aria-label="Detalhes do serviço"
            size="sm"
            icon={<FaSearch />}
            variant="ghost"
          />

          {(service.step === 'toBoardValidate' && userCanValidateBoardService) && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              rounded="md"
              variant="ghost"
              to={{
                pathname: `/servicos/embarques/validar/${service.id}`,
                state: {
                  path: pathRedirect
                }
              }}
            >
              Validar embarque
            </Button>
          )}

          {(service.step === 'toAllocateService' && userCanAllocateService) && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              rounded="md"
              variant="ghost"
              to={{
                pathname: `/servicos/alocar/${service.id}`,
                state: {
                  path: pathRedirect
                }
              }}
            >
              Alocar
            </Button>
          )}

          {(service.step === 'toAvailableService' && userCanAvailableService) && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              rounded="md"
              variant="ghost"
              to={{
                pathname: `/servicos/disponibilizar/desembarque/${service.id}`,
                state: {
                  path: pathRedirect
                }
              }}
            >
              Disponibilizar
            </Button>
          )}

          {(['toLandingService', 'landingService'].includes(service.step) && userCanDoServiceLanding) && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              rounded="md"
              variant="ghost"
              to={{
                pathname: `/servicos/desembarques/${service.id}`,
                state: {
                  path: pathRedirect
                }
              }}
            >
              Desembarcar
            </Button>
          )}

          {(['toMaintenanceService', 'ongoingMaintenanceService'].includes(service.step) && userCanDoServiceMaintenance) && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              rounded="md"
              variant="ghost"
              to={{
                pathname: `/servico/manutencao/${service.id}`,
                state: {
                  path: pathRedirect
                }
              }}
            >
              Realizar manutenção
            </Button>
          )}

          {(service.step === 'toValidateFiscalRetention' && userCanValidateFiscalRetention) && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              rounded="md"
              variant="ghost"
              to={{
                pathname: `/servico/retencao-fiscal/validar/${service.id}`,
                state: {
                  path: pathRedirect
                }
              }}
            >
              Validar retenção fiscal
            </Button>
          )}

          {(['toDeliveryService', 'deliveringService'].includes(service.step) && userCanDeliveryAddress) && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              rounded="md"
              variant="ghost"
              to={{
                pathname: `/servicos/entregas/${service.id}`,
                state: {
                  path: pathRedirect
                }
              }}
            >
              Entregar
            </Button>
          )}
        </Flex>
      </Td>
      <Td>{service.protocol}</Td>
      <Td>
        <Text maxW="300px" whiteSpace="pre-line">
          {service.customer.tradingFirstname}
        </Text>
      </Td>
      <Td>
        {service.serviceType === 'DEDICADO' ? (
          service?.sourceAddresses?.map((address, index) => (
            <Text w="300px" key={index} whiteSpace="pre-line">
              {service.sourceAddresses.length > 1 && index + 1 + '.'} {address.tradingName} - {address.branch}: {address.street}, {address.neighborhood} - {address.city.name} | {address.responsibleName}
            </Text>
          ))
        ) : (
          <Text w="300px" whiteSpace="pre-line">
            Material/Materiais de {' '}
            {service?.sourceAddresses.map(address => address.city.name).join(', ')}
          </Text>
        )}
      </Td>
      <Td>
        {service.serviceType === 'DEDICADO' ? (
          'Serviço Dedicado'
        ) : (
          <>
            {`Retirada de ${service?.boardService?.boardVolume ?? 'Não informado/encontrado'} vol(s).na ${' '}`}
            <br />
            {`${service?.destinationBranch?.shipping.companyName} - ${service?.destinationBranch?.nickname} ${' '}`}
            <br />
            {`CTe: ${service.cte ?? 'Não informado/encontrado'},`}
            <br />
            {service?.boardService?.trackerLink ? (
              <>
                Rastreador: <ChakraLink
                  href={service.boardService.trackerLink}
                  isExternal
                >
                  {service.tracker}
                </ChakraLink>
              </>
            ) : (
              <>
                {`Rastreador: ${service?.tracker ?? 'Não informado/encontrado'}`}

              </>
            )}
          </>
        )}
      </Td>
      <Td>
        {destinationHubTimezone ? (
          <Flex>
            {format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.deliveryDate), timezoneOffset: destinationHubTimezone }), 'dd/MM/yyyy')}

            {Number(destinationHubTimezone.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
              <Tooltip label={destinationHubTimezone}>
                <Box as="span" ml="2">
                  <Icon as={FaInfoCircle} />
                </Box>
              </Tooltip>
            )}
          </Flex>
        ) : (
          formatDate.handle(service.deliveryDate, "DateWithoutHourToShow")
        )}
      </Td>
      <Td>
        {destinationHubTimezone ? (
          <Flex>
            {format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.deliveryHour), timezoneOffset: destinationHubTimezone }), 'HH:mm')}
            {Number(destinationHubTimezone.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
              <Tooltip label={destinationHubTimezone}>
                <Box as="span" ml="2">
                  <Icon as={FaInfoCircle} />
                </Box>
              </Tooltip>
            )}
          </Flex>
        ) : (
          formatDate.handle(service.deliveryHour, "DateOnlyWithHourMinute")
        )}
      </Td>
      <Td>
        {service?.destinationAddresses?.map((address, index) => {

          const isDeliveredAddress = Boolean(address.isDelivered)

          if (isDeliveredAddress) {
            return (
              <Text as="del" w="300px" key={index} whiteSpace="pre-line">
                {service.destinationAddresses.length > 1 && index + 1 + '.'} {address.tradingName} - {address.branch}: {address.street}, {address.neighborhood} - {address.city.name} | {address.responsibleName}
              </Text>
            )
          }

          if (['toDeliveryService', 'deliveringService'].includes(service.step)) {
            return (
              <Text w="300px" key={index} whiteSpace="pre-line" _hover={{
                opacity: 0.7
              }}>
                <Link to={{
                  pathname: `/servicos/entregas/${service.id}/enderecos/${address.id}`,
                  state: {
                    path: pathRedirect
                  }
                }}>
                  {service.destinationAddresses.length > 1 && index + 1 + '.'} {address.tradingName} - {address.branch}: {address.street}, {address.neighborhood} - {address.city.name} | {address.responsibleName}
                </Link>
              </Text>
            )
          }


          return (
            <Text w="300px" key={index} whiteSpace="pre-line">
              {service.destinationAddresses.length > 1 && index + 1 + '.'} {address.tradingName} - {address.branch}: {address.street}, {address.neighborhood} - {address.city.name} | {address.responsibleName}
            </Text>
          )
        }
        )}
      </Td>
      <Td>{serviceHandleStep(service.step)}</Td>
      <Td>{service.sourceCollector.tradingName}</Td>
      <Td>{service.destinationCollector.tradingName}</Td>
    </Tr>
  )
}
