import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface ActivatePatrimonyRequestedParams {
  patrimonyRequestId: string
  patrimonyId: string
  receiptAttachmentId: string
}

export async function activatePatrimonyRequested({
  patrimonyRequestId,
  patrimonyId,
  receiptAttachmentId
}: ActivatePatrimonyRequestedParams) {
  try {
    await api.patch(`patrimony-requests/${patrimonyRequestId}/patrimonies/${patrimonyId}/activate`, {
      receiptAttachmentId
    })

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
