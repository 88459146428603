import { Alert, AlertDescription, AlertIcon, Button, Flex, FormControl, FormLabel, Input, ModalBody, ModalContent, ModalHeader, Select, Stack, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { confirmCollectsTotalVolumesAndInputs } from "api/service/confirmCollectsTotalVolumesAndInputs";
import { useService } from "hooks/services/service";
import { useToastify } from "hooks/toastify/useToastify";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";

interface ConfirmCollectsTotalVolumesAndInputsSchema {
  collectsTotalVolumes: number
  hasChangeInputs: 'yes' | 'no'
  inputs: {
    name: string
    quantity: number
  }[]
}

const confirmCollectsTotalVolumesAndInputsSchema = yup.object({
  collectsTotalVolumes: yup.number().typeError('Campo obrigatório.'),
  hasChangeInputs: yup.string().required('Campo obrigatório.'),
  inputs: yup.array().of(yup.object({
    name: yup.string().required('Campo obrigatório.'),
    quantity: yup.number().typeError('Campo obrigatório.'),
  }))
})

interface ConfirmCollectsTotalVolumesAndInputsProps {
  inputs: {
    name: string
    quantity: number
  }[]
  serviceId: string
  path: string
}

export function ConfirmCollectsTotalVolumesAndInputs({
  inputs,
  serviceId,
  path
}: ConfirmCollectsTotalVolumesAndInputsProps) {
  const { push: history } = useHistory()
  const location = useLocation()
  const state = location.state as { path?: string }
  const hasPathRedirect = state?.path !== undefined && state?.path !== null
  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<ConfirmCollectsTotalVolumesAndInputsSchema>({
    resolver: yupResolver(confirmCollectsTotalVolumesAndInputsSchema),
    defaultValues: {
      hasChangeInputs: 'no',
      inputs
    }
  })
  const {
    service: { data: service }
  } = useService(serviceId)

  const totalCollectVolumeService = service?.serviceIDCollect.reduce((accumulator, current) => {
    return accumulator + (current.collect_volume || 0);
  }, 0);

  const {
    fields,
  } = useFieldArray({
    control,
    name: 'inputs'
  })

  const hasChangeInputsFieldValue = useWatch({
    control,
    name: 'hasChangeInputs'
  })

  const collectsTotalVolumes = useWatch({
    control,
    name: 'collectsTotalVolumes'
  })

  const totalCollectsExceedsSumCollectsService = totalCollectVolumeService < Number(collectsTotalVolumes)

  const hasChangeInputs = hasChangeInputsFieldValue === 'yes'

  const { mutateAsync: confirmCollectsTotalVolumesAndInputsFn } = useMutation({
    mutationFn: confirmCollectsTotalVolumesAndInputs,
    onSuccess: () => {
      if (hasPathRedirect) {
        history(path)
        return
      }
      history(`/servicos/coletas/${serviceId}`)
    }

  })

  const { promiseMessage } = useToastify()

  async function handleConfirmCollectsTotalVolumesAndInputs(values: ConfirmCollectsTotalVolumesAndInputsSchema) {
    await promiseMessage(confirmCollectsTotalVolumesAndInputsFn({
      routeParams: {
        serviceId,
      },
      body: {
        ...values,
        hasChangeInputs: values.hasChangeInputs === 'yes'
      }
    }), 'Volumes de coleta e insumos confirmados!')
  }

  return (
    <ModalContent>
      <ModalHeader>Confirmar Volumes Totais e Insumos de Coletas</ModalHeader>

      <ModalBody>
        <Flex
          direction="column"
          gap="3"
          as="form"
          onSubmit={handleSubmit(handleConfirmCollectsTotalVolumesAndInputs)}
        >
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing="3"
          >
            <FormControl isInvalid={!!errors.collectsTotalVolumes}>
              <FormLabel fontSize="sm">
                Volume total
                <Text color="red" as="sup">*</Text>
              </FormLabel>
              <Input
                {...register('collectsTotalVolumes')}
                size="sm"
                rounded="md"
                type="number"
              />
            </FormControl>

            <FormControl isInvalid={!!errors.hasChangeInputs}>
              <FormLabel fontSize="sm">
                Alterar insumos?
                <Text color="red" as="sup">*</Text>
              </FormLabel>
              <Select
                {...register('hasChangeInputs')}
                size="sm"
                rounded="md"
              >
                <option value="yes">Sim</option>
                <option value="no">Não</option>
              </Select>
            </FormControl>
          </Stack>

          {hasChangeInputs && (
            fields.map((field, index) => {
              return (
                <Stack
                  key={field.id}
                  direction={{ base: 'column', lg: 'row' }}
                >
                  <FormControl isInvalid={
                    errors?.inputs
                      ? !!errors?.inputs[index].name
                      : false
                  }>
                    <FormLabel fontSize="sm">
                      Insumo
                      <Text color="red" as="sup">*</Text>
                    </FormLabel>
                    <Input
                      {...register(`inputs.${index}.name`)}
                      size="sm"
                      rounded="md"
                      isDisabled
                    />
                  </FormControl>

                  <FormControl isInvalid={
                    errors?.inputs
                      ? !!errors?.inputs[index].quantity
                      : false
                  }>
                    <FormLabel fontSize="sm">
                      Quantidade
                      <Text color="red" as="sup">*</Text>
                    </FormLabel>
                    <Input
                      {...register(`inputs.${index}.quantity`)}
                      size="sm"
                      rounded="md"
                      type="number"
                    />
                  </FormControl>
                </Stack>
              )
            })
          )}

          {totalCollectsExceedsSumCollectsService && (
            <Alert
              status='info'
              size='sm'
              my='2'
              p='2'
              width='fit-content'
              w="full"
              borderRadius='md'
            >
              <AlertIcon boxSize='4' />
              <AlertDescription fontSize='sm'>O volume total inserido é maior que a somatória do volume de todas as coletas.</AlertDescription>
            </Alert>
          )}
          <Button
            type="submit"
            colorScheme="blue"
            w="min"
            alignSelf="end"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Salvar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
