import { createContext, ReactNode, useState } from "react";

interface ConsultOccurrencesContextProps {
  query: string
  handleChangeQuery: (value: string) => void
}

interface ConsultOccurrencesProviderProps {
  children: ReactNode
}

export const ConsultOccurrencesContext = createContext(
  {} as ConsultOccurrencesContextProps
)

export function ConsultOccurrencesProvider({
  children
}: ConsultOccurrencesProviderProps) {
  const [query, setQuery] = useState('')

  const handleChangeQuery = (value: string) => {
    setQuery(value)
  }

  return (
    <ConsultOccurrencesContext.Provider value={{
      query,
      handleChangeQuery
    }}>
      {children}
    </ConsultOccurrencesContext.Provider>
  )
}
