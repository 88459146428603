import { usePagination } from "@ajna/pagination"
import { Box, Button, Flex, Heading, IconButton, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react"
import { getExperiencePeriods } from "api/experiencePeriods/getExperiencePeriods"
import { ExperiencePeriod, ExperiencePeriodStatus } from "api/experiencePeriods/types/ExperiencePeriod"
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton"
import { Pagination } from "components/Pagination/Pagination"
import { format } from "date-fns"
import { useSearchParams } from "hooks/useSearchParams"
import { useState } from "react"
import { FaExchangeAlt, FaFileDownload, FaFileExport } from "react-icons/fa"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { experiencePeriodStatusMap } from "utils/experiencePeriodMappers"
import { ExperiencePeriodTableFilters } from "./components/ExperiencePeriodTableFilters"
import { ExperiencePeriodTableRow } from "./components/ExperiencePeriodTableRow"

const headers = [
  { label: 'COLABORADOR', key: 'name' },
  { label: 'DATA DE ADMISSÃO', key: 'admission_date' },
  { label: '45 DIAS', key: 'period_45_days_end_date' },
  { label: '90 DIAS', key: 'period_90_days_end_date' },
  { label: 'REGIONAL', key: 'regional' },
  { label: 'STATUS', key: 'status' },
]

function formatValuesToReport(values: ExperiencePeriod[]) {
  return values?.map((experiencePeriod) => ({
    ...experiencePeriod,
    admission_date: experiencePeriod.admission_date
      ? format(new Date(experiencePeriod.admission_date), 'dd/MM/yyyy')
      : '-',
    period_45_days_end_date: experiencePeriod.period_45_days_end_date
      ? format(new Date(experiencePeriod.period_45_days_end_date), 'dd/MM/yyyy')
      : '-',
    period_90_days_end_date: experiencePeriod.period_90_days_end_date
      ? format(new Date(experiencePeriod.period_90_days_end_date), 'dd/MM/yyyy')
      : '-',
    status: experiencePeriodStatusMap[experiencePeriod?.status]
  }))
}


export function ExperiencePeriods() {
  const searchParams = useSearchParams()
  const history = useHistory()

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status')
  const name = searchParams.get('name')

  const {
    data: experiencePeriodsResult
  } = useQuery({
    queryKey: ['experience-periods', page, status, name],
    queryFn: () => getExperiencePeriods({
      currentPage: page,
      pageSize: '10',
      status: status as ExperiencePeriodStatus | 'all',
      name
    }),
    keepPreviousData: true
  })

  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }


  const {
    data: experiencePeriodsDataReportResult,
    isLoading: isLoadingExperiencePeriodsDataReportResult
  } = useQuery({
    queryKey: ['experience-periods-report', page, status, name, experiencePeriodsResult?.meta?.count],
    queryFn: () => getExperiencePeriods({
      currentPage: '1',
      pageSize: String(experiencePeriodsResult?.meta?.count),
      status: ["active", "validating-45-days", "validating-90-days", "dismissing"].includes(status) ? status as ExperiencePeriodStatus : 'all',
      name
    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: experiencePeriodsResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: experiencePeriodsResult?.meta?.pageSize
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const csvReportProps = {
    data: experiencePeriodsDataReportResult ? formatValuesToReport(experiencePeriodsDataReportResult?.experiencePeriods) : [],
    headers,
    filename: `periodos-de-experencia.csv`,
  }
  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Periodos de Experiência</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar kanban"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => history.push('/periodos-experiencia/kanban')}
          />
        </Flex>
      </Flex>
      <ExperiencePeriodTableFilters />
      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Nome Funcionário</Th>
              <Th color="white">Data de Admissão</Th>
              <Th color="white">45 dias</Th>
              <Th color="white">90 dias</Th>
              <Th color="white">Regional</Th>
              <Th color="white">Status</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {experiencePeriodsResult?.experiencePeriods?.map((experience) => {
              return (
                <ExperiencePeriodTableRow
                  key={experience.id}
                  experiencePeriod={experience}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {!experiencePeriodsDataReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingExperiencePeriodsDataReportResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
