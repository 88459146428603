import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { api } from 'services/api'
import { RequestError } from 'utils/errors/RequestErrors'
import {
  CreateDeliveryServiceInputProps,
  createDeliveryServiceReqFunction,
  finishDeliveryServiceReqFunction,
  FinishDeliveryServiceReqProps,
  updateDeliveryServiceReqFunction,
  UpdateDeliveryServiceReqProps,
} from '../../../utils/RequestFunctions/Service/Delivery/requestDeliveryServiceFunctions'
import { useSwal } from '../../swal/useSwal'

interface AssignDeliveryDriversInput {
  addresses: {
    address_id: string
    driver_id: string
    volume?: number
  }[]
}

interface AssignDeliveryDriversRequest {
  service_id: string
  input: AssignDeliveryDriversInput
}

async function assignDeliveryDriversReqFunction({
  service_id,
  input
}: AssignDeliveryDriversRequest) {
  try {
    await api.post(`/delivery-service/assign-drivers/${service_id}`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

export const useDeliveryServiceFunctions = () => {
  const queryClient = useQueryClient()
  const { successMessage, errorMessage } = useSwal()

  const createDeliveryService = useMutation(
    'createDeliveryService',
    (createLandingServiceInput: CreateDeliveryServiceInputProps) =>
      createDeliveryServiceReqFunction(createLandingServiceInput),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['services'])
        queryClient.invalidateQueries(['service'])
        successMessage('A entrega foi iniciada com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const finishDeliveryService = useMutation(
    'finishDeliveryService',
    ({
      serviceID,
      finishDeliveryServiceInput,
    }: FinishDeliveryServiceReqProps) =>
      finishDeliveryServiceReqFunction({
        serviceID,
        finishDeliveryServiceInput,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['services'])
        queryClient.invalidateQueries(['service'])
        successMessage('A entrega foi finalizada com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const updateDeliveryService = useMutation(
    'updateDeliveryService',
    ({
      serviceID,
      updateDeliveryServiceInput,
    }: UpdateDeliveryServiceReqProps) =>
      updateDeliveryServiceReqFunction({
        serviceID,
        updateDeliveryServiceInput,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['services'])
        queryClient.invalidateQueries(['service'])
        successMessage('A entrega foi atualizada com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const assignDeliveryDrivers = useMutation(
    'assignDeliveryDrivers', assignDeliveryDriversReqFunction
  )

  return {
    createDeliveryService,
    finishDeliveryService,
    updateDeliveryService,
    assignDeliveryDrivers
  }
}
