import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { getBusinessCosts } from "api/businessCosts/getBusinessCosts";
import { Pagination } from "components/Pagination/Pagination";

import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { BusinessCostsTableFilter } from "./components/BusinessCostsTableFilter";
import { BusinessCostsTableRow } from "./components/BusinessCostsTableRow";

export function BusinessCosts() {
  const { userLogged } = useAuth()
  const searchParams = useSearchParams()
  const history = useHistory()

  const userCanCreateBusinessCost = userLogged?.permissions.includes('create-business-cost')
  const userCanViewBusinessCosts = userLogged?.permissions.includes('view-business-costs')

  useEffect(() => {
    if (!userCanViewBusinessCosts) history.push('/')
  }, [userCanViewBusinessCosts, history])

  const customer = searchParams.get('customer')
  const situation = searchParams.get('situation')
  const page = searchParams.get('page') ?? '1'

  const { data: businessCostsResult } = useQuery({
    queryKey: [
      'business-costs',
      page,
      situation,
      customer
    ],
    queryFn: () => getBusinessCosts({
      currentPage: page,
      pageSize: '10',
      customer,
      situation
    })
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: businessCostsResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: businessCostsResult?.meta?.pageSize
    },
  })

  const handleChangePage = (page: number) => {
    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })

    setCurrentPage(page)
  }

  return (

      <Box
        p="6"
        rounded="md"
        bg="white"
      >
        <Flex justify="space-between">
          <Heading letterSpacing="tight">Custos business</Heading>

          {userCanCreateBusinessCost && (
            <IconButton
              aria-label="Novo custo business"
              icon={<FaPlus />}
              size="sm"
              colorScheme="blue"
              onClick={() => history.push('/custo/business/criar')}
            />
          )}
        </Flex>

        <BusinessCostsTableFilter />

        <TableContainer
          border="1px solid"
          borderColor="gray.200"
          rounded="md"
          mt="6"
        >
          <Table size="sm">
            <Thead>
              <Tr
                h="40px"
                bg="#38c3fa"
              >
                <Th></Th>
                <Th textColor="white">Cliente</Th>
                <Th textColor="white">Rota</Th>
                <Th textColor="white">Data inicial</Th>
                <Th textColor="white">Data final</Th>
                <Th textColor="white">Situação</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {businessCostsResult?.businessCosts.map((businessCost) => {
                return (
                  <BusinessCostsTableRow
                    key={businessCost.id}
                    businessCost={businessCost}
                  />
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>

        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />

      </Box>

  )
}
