import { ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, Tbody, Td, Tr } from "@chakra-ui/react"
import { getProduct } from "api/products/getProduct"
import { useQuery } from "react-query"
import { captalize } from "utils/captalize";
import { ProductStatus } from "./ProductStatus";

interface ProductDetailProps {
  productId: string
}

export function ProductDetails({
  productId,
}: ProductDetailProps) {


  const {
    data: productResult,
    isLoading: isLoadingProductResult
  } = useQuery({
    queryKey: ['product', productId],
    queryFn: () => getProduct({ productId })
  })

  return (
    <ModalContent maxW='750px'>
      <ModalHeader>
        Detalhes do Produto
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Table size="sm">
          <Tbody>
            {isLoadingProductResult ? (
              Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr key={index}>
                    <Td>
                      <Skeleton w="full" h="16px" />
                    </Td>
                    <Td isNumeric>
                      <Skeleton w="full" h="16px" />
                    </Td>
                  </Tr>
                )
              })
            ) : (
              <>
                <Tr>
                  <Td>Status</Td>
                  <Td display="flex" justifyContent="flex-end">
                    <ProductStatus status={productResult?.product?.situation} />
                  </Td>

                </Tr>

                <Tr>
                  <Td>Material</Td>
                  <Td isNumeric>
                    {captalize(productResult?.product?.material?.material_name)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Almoxarifado</Td>
                  <Td isNumeric>
                    {captalize(productResult?.product?.warehouse.name)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Fornecedor</Td>
                  <Td isNumeric>{captalize(productResult?.product?.generalProvider?.trading_name)}</Td>
                </Tr>
                <Tr>
                  <Td>Medida</Td>
                  <Td isNumeric>
                    {captalize(productResult?.product?.measure)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Custo Unitário</Td>
                  <Td isNumeric>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(Number(productResult?.product?.unit_cost) / 100)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Estoque Mínimo</Td>
                  <Td isNumeric>
                    {productResult?.product?.min_stock}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Estoque Padrão</Td>
                  <Td isNumeric>
                    {productResult?.product?.standard_stock}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Estoque Atual</Td>
                  <Td isNumeric>
                    {productResult?.product?.current_stock}
                  </Td>
                </Tr>

              </>
            )}
          </Tbody>
        </Table>
      </ModalBody>

    </ModalContent>
  )
}
