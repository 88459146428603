import { Heading, IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { getPurchaseOrder, PurchaseOrderApprovedAttachments, PurchaseOrderInvoices, PurchaseOrderQuoteProviders } from "api/purchaseOrders/getPurchaseOrder"
import { FaExternalLinkAlt } from "react-icons/fa"
import { useQuery } from "react-query"
import { formatDate } from "utils/DateFunctions/formatDate"
import { handleChangeUrl } from "utils/handleChangeUrl"
import { PurchaseOrderStatus } from "./PurchaseOrderStatus"


interface PurchaseOrderDetailProps {
  purchaseOrderId: string
}

export function PurchaseOrderDetail({ purchaseOrderId }: PurchaseOrderDetailProps) {

  const { data: purchaseOrderData, isLoading: isPurchaseOrderDataLoading } = useQuery({
    queryKey: ['purchaseOrder', purchaseOrderId],
    queryFn: () => getPurchaseOrder({ purchaseOrderId })
  })

  const purchaseOrder = purchaseOrderData?.purchaseOrder

  const purchaseOrderQuoteProviders: PurchaseOrderQuoteProviders[] =
    purchaseOrder?.quoted_at
      ? typeof purchaseOrderData?.purchaseOrder.quote_providers === 'string'
        ? JSON.parse(purchaseOrderData?.purchaseOrder.quote_providers)
        : purchaseOrderData?.purchaseOrder.quote_providers
      : []

  const isProductPurchaseOrder = purchaseOrder?.type === 'PRODUTO'
  const isPmcPurchaseOrder = purchaseOrder?.purchase_description === 'PMC'

  const purchaseOrderInvoices: PurchaseOrderInvoices[] =
    purchaseOrder?.purchase_invoice
      ? typeof purchaseOrderData?.purchaseOrder.purchase_invoice === 'string'
        ? JSON.parse(purchaseOrderData?.purchaseOrder.purchase_invoice)
        : purchaseOrderData?.purchaseOrder.purchase_invoice
      : []


  const purchaseOrderApprovedAttachments: PurchaseOrderApprovedAttachments[] =
    purchaseOrder?.approved_quote_attachments
      ? typeof purchaseOrderData?.purchaseOrder.approved_quote_attachments === 'string'
        ? JSON.parse(purchaseOrderData?.purchaseOrder.approved_quote_attachments)
        : purchaseOrderData?.purchaseOrder.approved_quote_attachments
      : []

  return (
    <ModalContent
      maxW='800px'
    >
      {isPurchaseOrderDataLoading ? (
        <ModalBody
          p="6"
          display="flex"
          alignItems="center"
          justifyContent="center"

        >
          <Spinner />
        </ModalBody>
      ) : (

        <>
          <ModalHeader letterSpacing="tight">Detalhes do Pedido de Compra</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            maxH='500px'
            overflow='auto'
          >
            <TableContainer>
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>Protocolo</Td>
                    <Td isNumeric>{purchaseOrder.protocol}</Td>
                  </Tr>
                  <Tr>
                    <Td>Tipo</Td>
                    <Td isNumeric>{purchaseOrder.type}</Td>
                  </Tr>
                  {isProductPurchaseOrder && !isPmcPurchaseOrder && (
                    <Tr>
                      <Td>Quantidade</Td>
                      <Td isNumeric>{purchaseOrder.quantity}</Td>
                    </Tr>
                  )}
                  {isProductPurchaseOrder && !isPmcPurchaseOrder && (
                    <Tr>
                      <Td>Responsável pelo Produto</Td>
                      <Td isNumeric>{purchaseOrder?.productResponsible?.firstname} {purchaseOrder?.productResponsible?.lastname}</Td>
                    </Tr>
                  )}

                  <Tr>
                    <Td>Descrição da compra</Td>
                    <Td isNumeric>{purchaseOrder?.purchase_description}</Td>
                  </Tr>

                  {!isPmcPurchaseOrder && (
                    <Tr>
                      <Td>Motivo da compra</Td>
                      <Td isNumeric alignSelf='flex-end' display="flex" justifyContent="flex-end">
                        <Text w='220px' whiteSpace="pre-line">{purchaseOrder?.purchase_reason}</Text>
                      </Td>
                    </Tr>
                  )}
                  {!isPmcPurchaseOrder && (
                    <Tr>
                      <Td>Data Limite</Td>
                      <Td isNumeric>{formatDate.handle(purchaseOrder.arrival_limit_date, 'DateWithoutHourToShow')}</Td>
                    </Tr>
                  )}
                  {purchaseOrder?.quoted_at !== null && (
                    <Tr>
                      <Td>Tipo de Cotação</Td>
                      <Td isNumeric alignSelf='flex-end' display="flex" justifyContent="flex-end">
                        <Text w="90px" whiteSpace="pre-line">{purchaseOrder.quote_type}</Text>
                      </Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td>Status</Td>
                    <Td isNumeric alignSelf='flex-end' display="flex" justifyContent="flex-end">
                      <PurchaseOrderStatus status={purchaseOrder.status} />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>

            {purchaseOrder?.pmc && purchaseOrder.pmc?.pmcProducts.length !== 0 && (
              <TableContainer w="full" mt="6">
                <Heading mb="2" fontSize="md" letterSpacing="tight">Produtos da pmc</Heading>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th>Fornecedor</Th>
                      <Th>Produto</Th>
                      <Th>Quantidade aprovada</Th>
                      <Th>Valor total</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {purchaseOrder?.pmc?.pmcProducts?.filter(pmcProduct => pmcProduct.product.general_provider_id === purchaseOrder.provider_id).map((pmcProduct) => {
                      return (
                        <Tr key={pmcProduct.product_id}>
                          <Td>{pmcProduct?.product?.generalProvider?.trading_name}</Td>
                          <Td>{pmcProduct.product.material.material_name}</Td>
                          <Td>{pmcProduct.approved_quantity}</Td>
                          <Td>{(pmcProduct.product.unit_cost / 100).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                          })}</Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            )}

            {purchaseOrder?.quoted_at !== null && (
              <TableContainer w='full' mt="6">
                <Heading mb="2" fontSize="md" letterSpacing="tight">Fornecedores cotados</Heading>
                <Table size="sm" >
                  <Thead>
                    <Tr>
                      <Th>Fornecedor</Th>
                      <Th>Cotação realizada em</Th>
                      <Th>Orçamento</Th>

                    </Tr>
                  </Thead>
                  <Tbody>
                    {purchaseOrderQuoteProviders?.map((quoteProvider) => {
                      return (
                        <Tr key={quoteProvider.providerName}>
                          <Td>{quoteProvider.providerName}</Td>
                          <Td>{formatDate.handle(purchaseOrder.quoted_at, 'DateWithDateAndHourMinute')}</Td>
                          <Td>
                            <IconButton
                              aria-label="Abrir anexo"
                              as={Link}
                              href={handleChangeUrl(quoteProvider.budgetAttachment)}
                              icon={<FaExternalLinkAlt />}
                              size="sm"
                              isExternal
                            />
                          </Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            )}

            {purchaseOrder?.approved_at !== null && (
              <TableContainer mt="6">
                <Heading mb="2" fontSize="md" letterSpacing="tight">Aprovação</Heading>
                <Table size="sm">
                  <Tr>
                    <Td>Fornecedor Aprovado</Td>
                    <Td isNumeric>{purchaseOrder?.approved_provider}</Td>
                  </Tr>
                  <Tr>
                    <Td>Aprovado em</Td>
                    <Td isNumeric>{formatDate.handle(purchaseOrder?.approved_at, 'DateWithDateAndHourMinute')}</Td>
                  </Tr>
                  {purchaseOrder.purchase_approved_value && (
                    <Tr>
                      <Td>Valor Aprovado</Td>
                      <Td isNumeric>
                        {(purchaseOrder.purchase_approved_value / 100).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </Td>
                    </Tr>
                  )}
                </Table>
              </TableContainer>
            )}

            {purchaseOrder?.purchased_at !== null && (
              <TableContainer mt="6">
                <Heading mb="2" fontSize="md" letterSpacing="tight">Compra</Heading>
                <Table size="sm">
                  <Tbody>
                    <Tr>
                      <Td>Fornecedor</Td>
                      <Td isNumeric>{purchaseOrder?.provider.trading_name}</Td>
                    </Tr>
                    <Tr>
                      <Td>Classificação financeira</Td>
                      <Td isNumeric>{purchaseOrder?.financialClassification.type}</Td>
                    </Tr>
                    <Tr>
                      <Td>Centro de custo</Td>
                      <Td isNumeric>{purchaseOrder?.provider_cost_center}</Td>
                    </Tr>
                    <Tr>
                      <Td>Data de vencimento</Td>
                      <Td isNumeric>{formatDate.handle(purchaseOrder?.purchase_due_date, 'DateWithoutHourToShow')}</Td>
                    </Tr>

                    <Tr>
                      <Td>Possui Nota Fiscal</Td>
                      <Td isNumeric>{purchaseOrder.purchase_has_invoice ? 'Sim' : 'Não'}</Td>
                    </Tr>

                    <Tr>
                      <Td>Forma de Pagamento</Td>
                      <Td isNumeric>{purchaseOrder.payment_type}</Td>
                    </Tr>
                    {purchaseOrder.installments_quantity && (
                      <Tr>
                        <Td>Quantidade de parcelas</Td>
                        <Td isNumeric>{purchaseOrder.installments_quantity}</Td>
                      </Tr>
                    )}

                    {purchaseOrderInvoices?.map((invoice) => {
                      return (
                        <Tr>
                          <Td>Nota Fiscal</Td>
                          <Td isNumeric alignSelf='flex-end' display="flex" justifyContent="flex-end">
                            <IconButton
                              aria-label="Abrir anexo"
                              as={Link}
                              href={handleChangeUrl(invoice.key)}
                              icon={<FaExternalLinkAlt />}
                              size="sm"
                              isExternal
                            />
                          </Td>
                        </Tr>
                      )
                    })}
                    {purchaseOrderApprovedAttachments && (
                      purchaseOrderApprovedAttachments?.map((invoice) => {
                        return (
                          <Tr>
                            <Td>Anexos</Td>
                            <Td isNumeric alignSelf='flex-end' display="flex" justifyContent="flex-end">
                              <IconButton
                                aria-label="Abrir anexo"
                                as={Link}
                                href={handleChangeUrl(invoice.key)}
                                icon={<FaExternalLinkAlt />}
                                size="sm"
                                isExternal
                              />
                            </Td>
                          </Tr>
                        )
                      })
                    )}
                    {purchaseOrder?.refused_at !== null && (
                      <>
                        <ModalHeader fontSize='sm' letterSpacing="tight">Recusa</ModalHeader>
                        <Tr>
                          <Td>Motivo da Recusa</Td>
                          <Td isNumeric>{purchaseOrder?.refuse_description}</Td>
                        </Tr>
                        <Tr>
                          <Td>Recusa realizada em</Td>
                          <Td isNumeric>{formatDate.handle(purchaseOrder?.refused_at, 'DateWithoutHourToShow')}</Td>
                        </Tr>
                      </>
                    )}
                  </Tbody>
                </Table>

              </TableContainer>
            )}

            {purchaseOrder?.arrived_at !== null && (
              <TableContainer mt="6">
                <Table size="sm">
                  <Tbody>
                    <Tr>
                      <Td>{isProductPurchaseOrder
                        ? 'Produto entregue corretamente?'
                        : 'Serviço finalizado corretamente?'}</Td>
                      <Td isNumeric>{purchaseOrder?.is_finished_correctly
                        ? 'Sim'
                        : 'Não'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Solicitado pagamento ao financeiro?</Td>
                      <Td isNumeric>{purchaseOrder?.has_requested_financial_payment
                        ? 'Sim'
                        : 'Não'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>{isProductPurchaseOrder
                        ? 'Visualizar NF'
                        : 'Visualizar RPA'}</Td>
                      <Td isNumeric alignSelf='flex-end' display="flex" justifyContent="flex-end">

                        <IconButton
                          aria-label="Abrir anexo"
                          as={Link}
                          href={handleChangeUrl(purchaseOrder?.purchase_type_attachment)}
                          icon={<FaExternalLinkAlt />}
                          size="sm"
                          isExternal
                        />
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text w="450px" whiteSpace="pre-line"> Conferiu se a nota está com CFOP de venda a contribuinte do ICMS ? Não pode ser CFOPs 6.107/6.108</Text>

                      </Td>
                      <Td isNumeric>{purchaseOrder?.has_cfop
                        ? 'Sim'
                        : 'Não'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Conferiu se patrimônio deu entrada no controle de patrimonio?</Td>
                      <Td isNumeric>{purchaseOrder?.has_conferred_equity
                        ? 'Sim'
                        : 'Não'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Chegou em</Td>
                      <Td isNumeric>{formatDate.handle(purchaseOrder?.arrived_at, 'DateWithoutHourToShow')}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </ModalBody>
        </>
      )}

    </ModalContent>

  )
}
