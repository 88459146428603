import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  Button,
  useMediaQuery,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye, FiPower, FiTrash2 } from 'react-icons/fi'
import { useContext, useState, useEffect, Fragment } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import swal from 'sweetalert'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import { UserContext } from '../../../contexts/UserContext'
import { useAuth } from '../../../hooks/auth/useAuth'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { User } from 'hooks/user/dtos/User'
import { CSVLink } from 'react-csv'
import { useSwal } from 'hooks/swal/useSwal'
import { useUserFunctions } from 'hooks/user/useUserFunctions'

interface UserTableProps {
  users: User[]
  refetch: () => void
}

const headers = [
  { label: 'NOME', key: 'name' },
  { label: 'EMAIL', key: 'email' },
  { label: 'SITUAÇÃO', key: 'situation' },
  { label: 'TIPO DE USUÁRIO', key: 'user_type' },
  { label: 'FUNCIONÁRIO LOGLIFE', key: 'employee' },
  { label: 'PERTENCE AO CLIENTE', key: 'customer' },
  { label: 'PERTENCE AO COLETADOR', key: 'collector' },
]

const headersUsersLlm = [
  { label: 'Colaborador', key: 'name' },
  { label: 'Treinamento', key: 'training' },
  { label: 'Data de vencimento', key: 'dueDate' },
]

function formatUsersLlmValuesToReport(
  usersData: User[],
) {
  const usersLlmDataFormated = usersData.map((user) => {
    return {
      name: `${user.firstname} ${user.lastname}`,
    }
  })

  return usersLlmDataFormated
}


function formatValuesToReport(
  usersData: User[],
) {
  const driversDataFormated = usersData.map((user) => {
    return {
      ...user,
      name: `${user.firstname} ${user.lastname}`,
      collector: user.collectorIDUser ? user.collectorIDUser?.trading_name : '-',
      customer: user.customerIDUser ? user?.customerIDUser?.trading_firstname : '-',
      employee: user?.loglife_employee ? 'SIM' : 'NÃO',
    }
  })

  return driversDataFormated
}

const userOptions = [
  { key: 'name', value: 'Nome', checked: false },
  { key: 'email', value: 'Email', checked: false },
  { key: 'situation', value: 'Situação', checked: false },
  { key: 'userType', value: 'Tipo de Usuário', checked: false },
  { key: 'employee', value: 'Func. Loglife', checked: false },
  { key: 'customer', value: 'Pertence ao Cliente', checked: false },
  { key: 'collector', value: 'Pertence ao Coletador', checked: false },
]

const userOrderOptions = [{ key: 'name', value: 'Nome' }]

export function UserTable({ users, refetch }: UserTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE
  const [searchedUsers, setSearchedUsers] = useState<User[]>([])
  const [slicedUsers, setSlicedUsers] = useState<User[]>([])
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  // SEARCHBOX FILTERS
  const [nameFiltered, setNameFiltered] = useState('')
  const [emailFiltered, setEmailFiltered] = useState('')
  const [situationFiltered, setSituationFiltered] = useState('')
  const [typeFiltered, setTypeFiltered] = useState('')
  const [funcFiltered, setFuncFiltered] = useState('')
  const [customerFiltered, setCustomerFiltered] = useState('')
  const [collectorFiltered, setCollectorFiltered] = useState('')
  // ---------------------------------------------------------------------------- //

  // HOOKS
  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { userLogged } = useAuth()
  // ---------------------------------------------------------------------------- //

  const { deleteUser } = useContext(UserContext)
  const { confirmMessage, standardMessage } = useSwal()
  const { requestInactivateUser: { mutateAsync: requestInactivateUser } } = useUserFunctions()

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedUsers.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(userOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(userOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      setSlicedUsers(users)
    }
    run()
  }, [itemLimit, currentPage, offset, users])

  useEffect(() => {
    function run() {
      const newSlicedUsers = slicedUsers?.filter((users) => {
        const nameFilter = searchBoxFilter(
          `${users.firstname} ${users.lastname}`,
          nameFiltered,
        )
        const emailFilter = searchBoxFilter(
          users.email, emailFiltered,
        )
        const situationFilter =
          users.situation.toLowerCase().startsWith(
            situationFiltered.toLowerCase()
          )

        const typeFilter = searchBoxFilter(users.user_type, typeFiltered)
        const functFilter = searchBoxFilter(
          users.loglife_employee === true ? 'Sim' : 'Não',
          funcFiltered,
        )
        const customerFilter = searchBoxFilter(
          users.customer_id !== null
            ? users.customerIDUser.trading_firstname
            : ' ',
          customerFiltered,
        )
        const collectorFilter = searchBoxFilter(
          users.user_type === 'COLETADOR'
            ? users.collectors.map((collector) => collector.collector.trading_name).join(', ')
            : users.collectorIDUser
              ? users.collectorIDUser.trading_name
              : '',
          collectorFiltered,
        )

        if (
          nameFiltered === '' &&
          situationFiltered === '' &&
          typeFiltered === '' &&
          funcFiltered === '' &&
          customerFiltered === '' &&
          collectorFiltered === '' &&
          emailFiltered === ''
        ) {
          return users
        }
        return (
          nameFilter &&
          situationFilter &&
          typeFilter &&
          functFilter &&
          customerFilter &&
          collectorFilter &&
          emailFilter
        )
      })

      if (orderOptionSelected.length > 0) {
        const slicedUserByOrder = newSlicedUsers.sort(
          (a, b) =>
            (orderOptionSelected.includes('name') &&
              `${a.firstname} ${a.lastname}`.localeCompare(
                `${b.firstname} ${b.lastname}`,
              )) ||
            0,
        )
        setSearchedUsers(slicedUserByOrder)
      } else {
        setSearchedUsers(newSlicedUsers)
      }
    }

    run()
  }, [
    nameFiltered,
    emailFiltered,
    situationFiltered,
    typeFiltered,
    funcFiltered,
    customerFiltered,
    collectorFiltered,
    slicedUsers,
    orderOptionSelected,
  ])

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  async function handleDeleteUser(userId: string) {
    await swal({
      title: 'Deseja excluir um usuário?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        deleteUser(userId).then((res) => {
          if (res) {
            refetch()
          }
        })
      } else {
        swal('Ação cancelada com êxito!')
      }
    })
  }

  async function handleRequestInactivateUser(userId: string) {
    const hasInactivateUser = await confirmMessage({
      title: 'Deseja solicitar inativação do usuário?'
    })

    if (hasInactivateUser) {
      await requestInactivateUser(userId)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const csvReportProps = {
    data: formatValuesToReport(slicedUsers),
    headers,
    filename: `relatório-usuários.csv`,
  }

  const usersLlm = users.filter(user => {
    const userTypeFilter = !['COLETADOR', 'MOTORISTA', 'CLIENTE', 'REMETENTE'].includes(user.user_type)

    return user.loglife_employee === true && user.situation === 'ATIVO' && userTypeFilter
  })

  const csvReportUsersLlmProps = {
    data: formatUsersLlmValuesToReport(usersLlm),
    headers: headersUsersLlm,
    filename: `relatório-usuários-llm.csv`,
  }


  return (
    <>
      {slicedUsers.length <= 0 ? (
        <Alert
          status="info"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Oops!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Não há dados para mostrar aqui. Tente cadastrar um usuário e tente
            novamente!
          </AlertDescription>
        </Alert>
      ) : (
        <>
          <TableFilterButton />
          <Stack mt="4" direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Fragment key={option.key}>
                  <Flex>
                    {option.key === 'name' && (
                      <SearchBox
                        placeholder="Buscar Nome..."
                        handleSearch={(e) => setNameFiltered(e.target.value)}
                      />
                    )}
                    {option.key === 'email' && (
                      <SearchBox
                        placeholder="Buscar Email..."
                        handleSearch={(e) => setEmailFiltered(e.target.value)}
                      />
                    )}
                    {option.key === 'situation' && (
                      <SearchBox
                        placeholder="Buscar Situação..."
                        handleSearch={(e) =>
                          setSituationFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'userType' && (
                      <SearchBox
                        placeholder="Buscar Tipo..."
                        handleSearch={(e) => setTypeFiltered(e.target.value)}
                      />
                    )}
                    {option.key === 'employee' && (
                      <SearchBox
                        placeholder="Buscar Funcionário..."
                        handleSearch={(e) => setFuncFiltered(e.target.value)}
                      />
                    )}
                    {option.key === 'customer' && (
                      <SearchBox
                        placeholder="Buscar Cliente..."
                        handleSearch={(e) =>
                          setCustomerFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'collector' && (
                      <SearchBox
                        placeholder="Buscar Coletador..."
                        handleSearch={(e) =>
                          setCollectorFiltered(e.target.value)
                        }
                      />
                    )}
                  </Flex>
                </Fragment>
              ))}
          </Stack>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  {userOptions.map((option) => (
                    <Th key={option.key} fontSize="12" color="blue.900">
                      {option.value.toUpperCase()}
                    </Th>
                  ))}
                  <Th>Suplente</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchedUsers
                  .slice(offset, offset + Number(itemLimit))
                  .map((user, index) => (
                    <Tr key={index}>
                      <Td fontSize="12">
                        {`${user.firstname} ${user.lastname}`.toUpperCase()}
                      </Td>
                      <Td fontSize="12">{user.email}</Td>
                      <Td fontSize="12">{user.situation.toUpperCase()}</Td>
                      <Td fontSize="12">{user.user_type.toUpperCase()}</Td>
                      <Td fontSize="12">
                        {user.loglife_employee === true ? 'SIM' : 'NÃO'}
                      </Td>
                      <Td fontSize="12">
                        {user.customers.length !== 0
                          ? user?.customers.map(customer => customer?.trading_firstname).join(", ")
                          : '-'}
                      </Td>
                      <Td fontSize="12">
                        {user.collectors?.length
                          ? user.collectors?.map((collector) => collector.collector.trading_name).join(', ')
                          : user?.collectorIDUser
                            ? user.collectorIDUser.company_name
                            : '-'}
                      </Td>
                      <Td fontSize="12">
                        {`${user?.substitute ? user?.substitute?.firstname + " " + user?.substitute?.lastname : '-'}`.toUpperCase()}
                      </Td>
                      <Td isNumeric>
                        {userLogged !== null && (
                          <>
                            {userLogged.permissions.includes('view-user') && (
                              <Link to={`/usuario/visualizar/${user.id}`}>
                                <Icon
                                  cursor="pointer"
                                  as={FiEye}
                                  fontSize="20"
                                />
                              </Link>
                            )}
                            {userLogged.permissions.includes('edit-user') && (
                              <Link to={`/usuario/editar/${user.id}`}>
                                <Icon
                                  cursor="pointer"
                                  as={FiEdit3}
                                  fontSize="20"
                                  ml="4"
                                  mt={['2', '2', '0']}
                                />
                              </Link>
                            )}
                            {userLogged.permissions.includes('request-inactivate-user') && (
                              <Icon
                                cursor="pointer"
                                as={FiPower}
                                fontSize="20"
                                ml="4"
                                mt={['2', '2', '0']}
                                onClick={() => handleRequestInactivateUser(user.id)}
                              />
                            )}
                            {userLogged.permissions.includes('remove-user') && (
                              <Icon
                                cursor="pointer"
                                as={FiTrash2}
                                fontSize="20"
                                ml="4"
                                mt={['2', '2', '0']}
                                onClick={() => handleDeleteUser(user.id)}
                              />
                            )}
                          </>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Box>
          <Flex justifyContent="flex-end" mt="4" direction={['column', 'column', 'row']} gap={2}>
            <CSVLink
              {...csvReportProps}
              separator={';'}
              style={{
                flex: isWideVersion ? 0 : 1,
                width: isWideVersion ? '' : '100%',
              }}
            >
              <Button type="button" colorScheme="green" w={['full', 'full', '']}>
                Exportar CSV
              </Button>
            </CSVLink>
            <CSVLink
              {...csvReportUsersLlmProps}
              separator={';'}
              style={{
                flex: isWideVersion ? 0 : 1,
                width: isWideVersion ? '' : '100%',
              }}
            >
              <Button type="button" colorScheme="green" w={['full', 'full', '']}>
                Exportar CSV LLM
              </Button>
            </CSVLink>
          </Flex>

          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}
