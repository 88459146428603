import { Flex, Spinner, Stack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { addDays, format } from 'date-fns'
import { useExcludedServicesLogs } from 'hooks/log/useExcludedServicesLogs'
import { useCallback, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { FaFilter } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { SubmitButton } from '../../components/Buttons/SubmitButton'
import { StandardBackgroundForm } from '../../components/Form/StandardBackgroundForm'
import { Input } from '../../components/Inputs/Input'
import { Select } from '../../components/Inputs/SelectInput'
import { LogTable } from '../../components/Tables/Log/LogTable'
import { useLogByDate } from '../../hooks/log/useLogByDate'
import { useLogByProtocol } from '../../hooks/log/useLogByProtocol'
import { logFilterOptions } from '../../utils/customLists'
import { ExcludedServicesLogs } from './components/ExcludedServicesLogs'

interface IFormInputProps {
  filter_by: string
  date: string
  protocol: string
}

const logFilterSchema = yup.object().shape({
  filter_by: yup.string().required('Campo Obrigatório'),
  date: yup.string().when('filter_by', {
    is: 'DATA',
    then: yup.string().required('Campo Obrigatório'),
  }),
  protocol: yup.string().when('filter_by', {
    is: 'PROTOCOLO',
    then: yup.string().required('Campo Obrigatório.'),
  }),
})

export function LogFilter() {
  const { location } = useHistory()
  const state = location.state as { protocol?: string }

  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const {
    handleSetProtocolValue,
    logByProtocol: { data: logByProtocol, isLoading: isLogByProtocolLoading },
  } = useLogByProtocol()
  const {
    handleSetDateValue,
    logByDate: { data: logByDate, isLoading: isLogByDateLoading },
  } = useLogByDate()

  const currentPageFilter = location.search.match(`[?&]page=([^&]+)`)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(logFilterSchema),
    defaultValues: {
      filter_by: state?.protocol ? 'PROTOCOLO': '',
      protocol: state?.protocol ? state?.protocol : ''
    }
  })

  const filterByValue = watch('filter_by')
  const protocolValue = watch('protocol')
  const dateValue = watch('date')

  const isFilterByExcludedService = filterByValue === 'EXCLUÍDOS'

  const {
    data: logsByExcludedServiceData,
    isFetching: isFetchingLogsData,
  } = useExcludedServicesLogs({
    queryOptions:{
      enabled: isFilterByExcludedService
    },
    queryParams: {
      pageSize: Number(rowsPerPage),
      currentPage: currentPageFilter ? Number(currentPageFilter[1]) : 1
    }
  })

  const handleSubmitForm = useCallback(() => {
    if (filterByValue === 'PROTOCOLO')
      return handleSetProtocolValue(protocolValue)

    if (filterByValue === 'DATA')
      return handleSetDateValue(
        format(addDays(new Date(dateValue), 1), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      )
  }, [filterByValue, protocolValue, dateValue, handleSetProtocolValue, handleSetDateValue])


  const handleClearFieldAndHookStates = () => {
    handleSetDateValue('')
    handleSetProtocolValue('')
    setValue('date', '')
    setValue('protocol', '')
  }

  const hasSubmitted = useRef(false)

  useEffect(() => {
    if (!hasSubmitted.current) {
      hasSubmitted.current = true
      handleSubmit(handleSubmitForm)()
    }
  }, [handleSubmit, handleSubmitForm])

  return (
    <StandardBackgroundForm
      title="Filtrar Logs"
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <Select
        {...register('filter_by')}
        name="filter_by"
        label="Filtrar Por"
        error={errors.filter_by}
        isDisabled={false}
        placeholder="Selecione uma opção..."
        onChangeCapture={handleClearFieldAndHookStates}
        logFilterOptions={logFilterOptions}
        required
      />

      {filterByValue === 'DATA' && (
        <Stack mt="4">
          <Input
            {...register('date')}
            name="date"
            error={errors.date}
            label="Data"
            onChangeCapture={() => handleSetDateValue('')}
            type="date"
            required
          />
        </Stack>
      )}
      {filterByValue === 'PROTOCOLO' && (
        <Stack mt="4">
          <Input
            {...register('protocol')}
            name="protocol"
            error={errors.protocol}
            label="Protocolo"
            onChangeCapture={() => handleSetProtocolValue('')}
            required
          />
        </Stack>
      )}

      {(isLogByProtocolLoading || isLogByDateLoading || isFetchingLogsData) && <Spinner />}

      <Flex mt="4" mb="4" justify="flex-end" align="center">
        <SubmitButton
          action="Filtrar"
          isSubmitting={isSubmitting}
          isDisabled={isSubmitting}
          icon={FaFilter}
        />
      </Flex>

      {logByProtocol && protocolValue && <LogTable logs={logByProtocol} />}

      {logByDate && dateValue && <LogTable logs={logByDate} />}

      {isFilterByExcludedService && logsByExcludedServiceData && <ExcludedServicesLogs logs={logsByExcludedServiceData} rowsPerPage={rowsPerPage}/>}
    </StandardBackgroundForm>
  )
}
