import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  HStack,
  Spinner,
  Stack,
} from '@chakra-ui/react'
import { Address } from 'hooks/address/dtos/Address'
import { Fragment, useEffect, useState } from 'react'
import { BranchProps } from '../../../../contexts/BranchContext'
import {
  RequestedServiceProps,
  ServiceProps,
} from '../../../../contexts/ServiceContext'
import { useBudget } from '../../../../hooks/budget/useBudget'
import { useCity } from '../../../../hooks/city/useCity'
import { LandingServiceProps } from '../../../../services/getFunctions/getLandingServiceFunctions'
import { serviceFormatDateToFront } from '../../../../utils/ServiceFunctions/serviceFormatDateToFront'
import { serviceFormatHourToFront } from '../../../../utils/ServiceFunctions/serviceFormatHourToFront'
import { Input } from '../../../Inputs/Input'
import { TextArea } from '../../../Inputs/TextInput'

interface IRequestedAccordionProps {
  serviceByID: ServiceProps
  addresses: Address[]
  branches: BranchProps[]
}

export function RequestedAccordion({
  serviceByID,
  addresses,
  branches,
}: IRequestedAccordionProps) {
  const [requestedServiceById, setRequestedServiceById] = useState<
    RequestedServiceProps[]
  >([])
  const [landingServiceById, setLandingServiceById] = useState<
    LandingServiceProps[]
  >([])
  const [stepOfService, setStepOfService] = useState('')

  const {
    cities: { data: cities, isLoading: isCitiesLoading },
  } = useCity(null, true)
  const {
    budget: { data: budget, isLoading: isBudgetLoading },
  } = useBudget(serviceByID.serviceIDRequested.budget_id, false)

  useEffect(() => {
    function run() {
      if (serviceByID !== undefined) {
        setStepOfService(serviceByID.step)
        if (serviceByID.serviceIDRequested !== null) {
          const requestedServiceFiltered = []
          requestedServiceFiltered.push(serviceByID.serviceIDRequested)
          setRequestedServiceById(requestedServiceFiltered)
        }
        if (serviceByID.serviceIDLanding !== null) {
          setLandingServiceById(serviceByID.serviceIDLanding)
        }
      }
    }
    run()
  }, [serviceByID])

  return (
    <>
      {(isCitiesLoading || isBudgetLoading) && (
        <Flex>
          <Spinner />
        </Flex>
      )}
      {requestedServiceById.map((service) => (
        <Fragment key={service.id}>
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  Informações do serviço
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {(stepOfService === 'toCollectService' ||
                stepOfService === 'collectingService') && (
                <>
                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      name="protocol"
                      value={serviceByID?.protocol}
                      label="Protocolo"
                      isDisabled={true}
                    />
                  </Stack>
                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      name="collect_date"
                      value={serviceFormatDateToFront(service.collect_date)}
                      type="date"
                      label="Data da Coleta"
                      isDisabled={true}
                    />

                    <HStack w="100%">
                      <Input
                        name="start_collect_hour"
                        value={serviceFormatHourToFront(
                          service.collect_hour_start,
                        )}
                        type="time"
                        label="Horário Inicial da Coleta"
                        isDisabled={true}
                      />
                      <Input
                        name="final_collect_hour"
                        value={serviceFormatHourToFront(
                          service.collect_hour_end,
                        )}
                        type="time"
                        label="Horário Final da Coleta"
                        isDisabled={true}
                      />
                    </HStack>
                  </Stack>
                </>
              )}

              {(stepOfService === 'toDeliveryService' ||
                stepOfService === 'deliveringService') && (
                <>
                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      name="protocol"
                      value={serviceByID.protocol}
                      label="Protocolo"
                      isDisabled={true}
                    />
                  </Stack>

                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      name="collect_date"
                      value={serviceFormatDateToFront(service.collect_date)}
                      type="date"
                      label="Data da Coleta"
                      isDisabled={true}
                    />

                    <Input
                      name="delivery_hour"
                      value={serviceFormatHourToFront(service.delivery_hour)}
                      type="time"
                      label="Horário Limite para Entrega"
                      isDisabled={true}
                    />
                  </Stack>
                </>
              )}

              {(stepOfService === 'toBoardService' ||
                stepOfService === 'boardingService') && (
                <Stack
                  spacing="24px"
                  mt="4"
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    name="protocol"
                    value={serviceByID.protocol}
                    label="Protocolo"
                    isDisabled={true}
                  />
                </Stack>
              )}

              <Stack spacing="24px" mt="4" direction="column">
                <Input
                  name="source_cities"
                  label="Cidade(s) de Origem"
                  defaultValue={cities
                    ?.filter((city) => budget?.source_cities.includes(city.id))
                    .map((city) => city.name)
                    .join(', ')}
                  isDisabled={true}
                />
                <Input
                  name="destination_cities"
                  label="Cidade(s) de Destino"
                  defaultValue={cities
                    ?.filter((city) =>
                      budget?.destination_cities.includes(city.id),
                    )
                    .map((city) => city.name)
                    .join(', ')}
                  isDisabled={true}
                />
              </Stack>

              {serviceByID.serviceIDRequested.service_type === 'FRACIONADO' && (
                <Stack
                  spacing="24px"
                  mt="4"
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    name="source_branch_id"
                    label="Base de Origem"
                    defaultValue={String(
                      branches
                        .filter(
                          (branch) =>
                            branch.id ===
                            serviceByID.serviceIDRequested.source_branch_id,
                        )
                        .map((branch) => branch.nickname),
                    )}
                    isDisabled={true}
                  />
                  <Input
                    name="source_branch_id"
                    label="Base de Destino"
                    defaultValue={String(
                      branches
                        .filter(
                          (branch) =>
                            branch.id ===
                            serviceByID.serviceIDRequested
                              .destination_branch_id,
                        )
                        .map((branch) => branch.nickname),
                    )}
                    isDisabled={true}
                  />
                </Stack>
              )}

              <AccordionItem mt="4">
                <AccordionButton p="4" border="none">
                  <Box flex="1" textAlign="left">
                    Endereços para coleta
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    {addresses
                      .filter((address) =>
                        serviceByID.serviceIDRequested.source_address_id.includes(
                          address.id,
                        ),
                      )
                      .map((address) => (
                        <Input
                          key={address.id}
                          name="address_id"
                          label="Endereço de Coleta"
                          defaultValue={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep} | ${address.responsible_name}
                      `.toUpperCase()}
                          isDisabled={true}
                        />
                      ))}
                  </Stack>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem mt="4">
                <AccordionButton p="4" border="none">
                  <Box flex="1" textAlign="left">
                    Endereços para entrega
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    {addresses
                      .filter((address) =>
                        serviceByID.serviceIDRequested.destination_address_id.includes(
                          address.id,
                        ),
                      )
                      .map((address) => (
                        <Input
                          key={address.id}
                          name="address_id"
                          label="Endereço de Entrega"
                          defaultValue={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep} | ${address.responsible_name}
                      `.toUpperCase()}
                          isDisabled={true}
                        />
                      ))}
                  </Stack>
                </AccordionPanel>
              </AccordionItem>

              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Input
                  name="service_type"
                  value={service.service_type}
                  label="Tipo de Serviço"
                  isDisabled={true}
                />
                <Input
                  name="vehicle"
                  value={service.vehicle}
                  label="Veículo"
                  isDisabled={true}
                />
              </Stack>

              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Input
                  name="caixa_termica"
                  value={service.caixa_termica}
                  label="Caixa Térmica"
                  isDisabled={true}
                />
                <Input
                  name="embalagem_secundaria"
                  value={service.embalagem_secundaria}
                  label="Embalagem Secundária"
                  isDisabled={true}
                />
                <Input
                  name="gelo_seco"
                  value={service.gelo_seco}
                  label="Gelo Seco"
                  isDisabled={true}
                />
                <Input
                  name="gelox"
                  value={service.gelox}
                  label="Gelox"
                  isDisabled={true}
                />
              </Stack>

              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Input
                  name="isopor3l"
                  value={service.isopor3l}
                  label="Isopor 3l"
                  isDisabled={true}
                />
                <Input
                  name="isopor7l"
                  value={service.isopor7l}
                  label="Isopor7l"
                  isDisabled={true}
                />
                <Input
                  name="terciaria3l"
                  value={service.terciaria3l}
                  label="Terciária3l"
                  isDisabled={true}
                />
                <Input
                  name="terciaria8l"
                  value={service.terciaria8l}
                  label="Terciária8l"
                  isDisabled={true}
                />
              </Stack>
              <Stack mt="4">
                {(stepOfService === 'toDeliveryService' ||
                  stepOfService === 'deliveringService') &&
                  landingServiceById.map((lService) => (
                    <TextArea
                      value={lService.observation}
                      name="observation"
                      label="Observações do Desembarque"
                      key={lService.id}
                      isDisabled={true}
                    />
                  ))}

                <TextArea
                  value={service.observation}
                  name="observation"
                  label="Observações do Serviço"
                  isDisabled={true}
                />
              </Stack>
            </AccordionPanel>
          </AccordionItem>

          <Divider my="6" borderColor="gray.700" />
        </Fragment>
      ))}
    </>
  )
}
