import { usePagination } from "@ajna/pagination";
import { Box,  Flex, Heading,  Spinner,  Table, TableContainer, Tbody,  Th, Thead, Tr } from "@chakra-ui/react";
import { getServicesValidationsRealTimes } from "api/service/validateRealTime/getServicesValidationsRealTimes";
import { Pagination } from "components/Pagination/Pagination";


import { useSearchParams } from "hooks/useSearchParams";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { ValidateRealTimeRow } from "./components/ValidateRealTimeRow";

export function ServicesValidationsRealTimes() {
  const { replace } = useHistory()
  const searchParams = useSearchParams()

  const page = searchParams.get('page') ?? '1'

  const { data: servicesValidationsRealTimeData, isLoading: isServicesValidationsRealTimeLoading } =
    useQuery(['services-with-real-time-validation'], () => getServicesValidationsRealTimes({
    currentPage: page,
    pageSize: '10'
  }))

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: servicesValidationsRealTimeData?.services.length,
    initialState: {
      currentPage: Number(page),
      pageSize: 1
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  return (

      <Box
        bg="white"
        borderRadius="md"
        p="4"
      >
        <Flex
          w="full"
          direction="column"
          gap={6}
        >
          <Heading letterSpacing="tight">Validar coletas/entrega horário real</Heading>
          {isServicesValidationsRealTimeLoading ? (
            <Spinner/>
          ): (
          <TableContainer
            rounded="md"
            border="1px solid"
            borderColor="gray.100"
          >
            <Table
              size="sm"
            >
              <Thead
                h="40px"
                bg="#38c3fa"
              >
                <Tr>
                  <Th w="200px" textColor="white">Protocolo</Th>
                  <Th w="200px" textColor="white">Tipo</Th>
                  <Th w="200px" textColor="white">Responsável</Th>
                  <Th w="200px" textColor="white">Endereço</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {servicesValidationsRealTimeData?.services?.map((service) => {
                  return (
                    <ValidateRealTimeRow key={service.protocol} service={service}/>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
          )}
          <Pagination
            currentPage={currentPage}
            pages={pages}
            pagesQuantity={pagesCount}
            handlePageChange={handleChangePage}
          />
        </Flex>
      </Box>

  )
}
