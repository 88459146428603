import { Button, Flex, FormLabel, Icon, Link, Stack, Text } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { TextArea } from "components/Inputs/TextInput";
import { useFormContext, useWatch } from "react-hook-form";
import { FaFile } from "react-icons/fa";
import { handleChangeUrl } from "utils/handleChangeUrl";

interface CrmMeetingFormInputs {
  meetingDate: string
  meetingParticipants: string
  meetingSummary: string
  meetingAttachment: FileList
}

interface CrmMeetingFormProps {
  isViewPage: boolean
  meetingAttachmentPreview?: string
}

export function CrmMeetingForm({
  isViewPage,
  meetingAttachmentPreview
}: CrmMeetingFormProps) {

  const {
    register,
    formState: { errors }
  } = useFormContext<CrmMeetingFormInputs>()

  const meetingAttachment = useWatch({
    name: 'meetingAttachment'
  })


  return (
    <Flex
      gap={4}
      direction='column'
    >
      <Input
        {...register('meetingDate')}
        name='meetingDate'
        type='date'
        label='Data'
        error={errors.meetingDate}
        isDisabled={isViewPage}
        required
      />

      <TextArea
        {...register('meetingParticipants')}
        name='meetingParticipants'
        label='Participantes'
        error={errors.meetingParticipants}
        isDisabled={isViewPage}
        required
      />

      <TextArea
        {...register('meetingSummary')}
        name='meetingSummary'
        label='Resumo'
        error={errors.meetingSummary}
        isDisabled={isViewPage}
        required
      />

      {!isViewPage && (
        <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Input
            {...register('meetingAttachment')}
            name="meetingAttachment"
            label="Anexo"
            type="file"
            hidden
            error={errors.meetingAttachment}
            required

          >

            <Flex w="full" align="center" justify="flex-start">
              <Flex align="center">
                <Button
                  as={FormLabel}
                  htmlFor='meetingAttachment'
                  _hover={{
                    cursor: 'pointer'
                  }}
                  mr="2"
                  leftIcon={<Icon as={FaFile} />}
                >
                  Upload
                </Button>
              </Flex>
              {meetingAttachment && (
                <Link
                  href={URL.createObjectURL(meetingAttachment[0])}
                  isExternal
                >
                  <Text as='b'>{meetingAttachment[0].name} (Visualizar)</Text>
                </Link>
              )}
            </Flex>
          </Input>
        </Stack>
      )}
      {meetingAttachmentPreview && (
        <Link
          href={handleChangeUrl(meetingAttachmentPreview)}
          isExternal
        >
          <Text as='b'>Visualizar anexo</Text>
        </Link>
      )}
    </Flex>
  )
}
