import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  Text,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Button,
  Flex,
} from '@chakra-ui/react'
import { ForwardRefRenderFunction, forwardRef } from 'react'
import { FieldError } from 'react-hook-form'
import ReactInputMask, { Props as InputMaskProps } from 'react-input-mask'

type InputMaskAdjustedProps = Omit<
  InputMaskProps,
  'color' | 'height' | 'size' | 'width'
>
type ChakraInputAdjustedProps = Omit<ChakraInputProps, 'children'>

interface InputProps extends ChakraInputAdjustedProps, InputMaskAdjustedProps {
  name?: string
  accept?: string
  label?: string
  mask: string | string[]
  maskChar?: string
  addChildren?: string
  required?: boolean
  error?: FieldError
  show?: boolean
  applyMask?: boolean
  alwaysShowMask?: boolean
  value?: any
  showPassword?: boolean
  setShow?: any
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    accept,
    addChildren,
    show,
    showPassword,
    setShow,
    children,
    mask,
    maskChar,
    value,
    applyMask = false,
    required = false,
    alwaysShowMask,
    error = null,
    ...rest
  },
  ref,
) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <Flex flex="1" justify="space-between" >
          <FormLabel htmlFor={name} noOfLines={1}>
            {label}
            {required && (
              <Text color="red.500" as="sup">
                {' '}
                *
              </Text>
            )}
          </FormLabel>
        </Flex>
      )}
      <InputGroup size="lg">
        {!!addChildren && (
          <InputLeftAddon
            children={addChildren}
            pointerEvents="none"
            color="gray.600"
            background="gray.200"
            fontSize="1.2em"
          />
        )}
        <ChakraInput
          as={ReactInputMask as any}
          mask={mask}
          maskChar={maskChar}
          name={name}
          value={value}
          id={name}
          alwaysShowMask={alwaysShowMask}
          accept={accept}
          focusBorderColor="#38c3fa"
          bgColor="gray.100"
          variant="filled"
          _hover={{
            bgcolor: 'gray.900',
          }}
          required={required}
          size="lg"
          ref={ref}
          {...rest}
        />
        {children}
        {!!show && (
          <InputRightElement width="4.5rem">
            <Button
              h="1.75rem"
              mr="1px"
              size="sm"
              onClick={() => setShow(!showPassword)}
            >
              {showPassword ? 'Ocultar' : 'Mostrar'}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const InputMaskCustom = forwardRef(InputBase)
