import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CompleteRhAuditBody {
  realizedDate: string
  isCustomerPopTrainingRegistered: boolean
  isCleaningDesinfectionTrainingRegistered: boolean
  isBiosecurityTrainingRegistered: boolean
  isLastMonthCleaningRegistered: boolean
  isWithVaccineCard: boolean
  isWithEarCnh: boolean
  isWithMotorcycleFreightAnttCourse: boolean
  isWithCrlvLocationCategory: boolean
  isWithAso: boolean
  isEpiKitDelivered: boolean
  isTimeCardRecordedCorrectly: boolean
  isOpenedSalaryAccount: string
  isWithBadge: boolean
  isWithBadgeCord: boolean
  isDeliveredMotorcycleLabels: string
  isDeliveredThermalBox: string
  nonCompliance?: string
}

interface CompleteRhAuditParams {
  body: CompleteRhAuditBody
  auditId: string
}

export async function completeRhAudit({
  body,
  auditId
}: CompleteRhAuditParams) {
  try {
    await api.put(`/audits/${auditId}/human-resources`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
