import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm"
import { AddressIcmsTypes, AddressPersonTypes, AddressSituationType, useAddressesFunctions } from "hooks/address/useAddressesFunctions"
import { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { Link, useHistory, useParams } from "react-router-dom"
import { formatDate } from "utils/DateFunctions/formatDate"
import { AddressForm, AddressFormInputs } from "./components/AddressForm"
import * as yup from "yup"
import { isCnpjValid, isCpfValid } from "utils/cpfCnpjValidateFunctions"
import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Flex, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { useSwal } from "hooks/swal/useSwal"
import { set } from "date-fns"
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading"
import { useAddress } from "hooks/address/useAddress"
import { useCity } from "hooks/city/useCity"
import { statesWithUfList } from "utils/CustomLists/statesWithUfList"
import { useCustomer } from "hooks/customer/useCustomer"
import { useAuth } from "hooks/auth/useAuth"
import { useCep } from "hooks/useCep"
import { useToastify } from "hooks/toastify/useToastify"
import { AddressLogsModal } from "./components/AddressLogsModal"

interface QueryParams {
  slug: string
  id: string
}

const transforFormTimeInputInDate = (time: string | undefined) => {
  if (!time) return null

  const [hours, minutes] = time.split(":").map(value => Number(value))

  const timestamp = set(new Date(), {
    hours,
    minutes
  })

  return timestamp
}

const addressFormSchema = yup.object().shape({
  situation: yup.string().required("Campo obrigatório"),
  customer_id: yup.string().required("Campo obrigatório"),
  type: yup.string().required("Campo obrigatório"),
  cnpj_cpf: yup
    .string()
    .when('type', {
      is: 'PESSOA FÍSICA',
      then: yup.string().transform(isCpfValid).required('CPF inválido'),
    })
    .when('type', {
      is: 'PESSOA JURÍDICA',
      then: yup.string().transform(isCnpjValid).required('CNPJ inválido'),
    }),
  trading_name: yup.string().when('type', {
    is: 'PESSOA JURÍDICA',
    then: yup.string().required("Campo obrigatório")
  }),
  branch: yup.string().required("Campo obrigatório"),
  responsible_name: yup.string().required("Campo obrigatório"),
  responsible_email: yup.string(),
  responsible_telephone: yup.string().required("Campo obrigatório"),
  cep: yup.string().required("Campo obrigatório"),
  street: yup.string().required("Campo obrigatório"),
  number: yup.string().required("Campo obrigatório"),
  complement: yup.string(),
  neighborhood: yup.string().required("Campo obrigatório"),
  state: yup.string().required("Campo obrigatório"),
  city_id: yup.string().required("Campo obrigatório"),
  reference_point: yup.string(),
  icms: yup.string().required("Campo obrigatório"),
  has_business_operation: yup.string().required("Campo obrigatório"),
  business_open: yup.string().when("has_business_operation", {
    is: "SIM",
    then: yup.string().required("Campo obrigatório")
  }),
  business_close: yup.string().when("has_business_operation", {
    is: "SIM",
    then: yup.string().required("Campo obrigatório")
  }),
  has_saturday_operation: yup.string().required("Campo obrigatório"),
  saturday_open: yup.string().when("has_saturday_operation", {
    is: "SIM",
    then: yup.string().required("Campo obrigatório")
  }),
  saturday_close: yup.string().when("has_saturday_operation", {
    is: "SIM",
    then: yup.string().required("Campo obrigatório")
  }),
  has_sunday_operation: yup.string().required("Campo obrigatório"),
  sunday_open: yup.string().when("has_sunday_operation", {
    is: "SIM",
    then: yup.string().required("Campo obrigatório")
  }),
  sunday_close: yup.string().when("has_sunday_operation", {
    is: "SIM",
    then: yup.string().required("Campo obrigatório")
  }),
  has_own_board_landing: yup.string().required('Campo obrigatório'),
  deadline: yup.number().typeError('Campo Obrigatório').required('Campo Obrigatório'),
  observation: yup.string()
})

export function EditViewAddress() {

  const { userLogged } = useAuth()

  const userLoggedHasPermissionToEditAddress =
    userLogged?.permissions.includes('edit-address')

  const userLoggedHasPermissionToRequestChangesAddress =
    userLogged?.permissions.includes('request-changes-address') && userLogged?.user_type === 'CLIENTE'

  const formMethods = useForm<AddressFormInputs>({
    resolver: yupResolver(addressFormSchema),
  })

  const { slug, id } = useParams<QueryParams>()
  const { push: redirectTo } = useHistory()

  const { confirmMessage, standardMessage } = useSwal()
  const { warnMessage } = useToastify()

  const { data: address, isFetching: isFetchingAddress } = useAddress(id, {
    cacheTime: 0
  })


  const {
    updateAddress: { mutateAsync: updateAddress, isLoading: isUpdateAddressLoading },
  } = useAddressesFunctions()

  const isViewPage = slug === "visualizar"

  const { setValue, watch, handleSubmit, formState: { isSubmitting } } = formMethods

  const cepValue = watch("cep")
  const isValidCep = cepValue ? !cepValue.includes("_") : false
  const cepFormatted = isValidCep ? cepValue.split("-").join("") : null

  const {
    data: cepData, isFetching: isFetchingCepData, refetch: refetchCep
  } = useCep({ cep: cepFormatted, useQueryOptions: { enabled: isValidCep } })


  useEffect(() => {
    if (isValidCep) refetchCep()
  }, [refetchCep, isValidCep, cepValue])

  const isErrorOnConsultCep = cepData?.erro

  useEffect(() => {
    if (isErrorOnConsultCep && !isFetchingCepData) {
      warnMessage("Ocorreu um erro ao consultar o cep informado!")
    }
  }, [isErrorOnConsultCep, warnMessage, isFetchingCepData])

  const stateFromCepUf = Object.entries(statesWithUfList).find(([key, value]) => value.uf === cepData?.uf)?.[0]

  const selectedState = watch("state")
  const isStateSelected = !!selectedState

  const {
    cities: { data: cities, isFetching: isFetchingCities }
  } = useCity(null, isStateSelected, false)

  const cityFilteredByCep = cities?.find(city => city.name === cepData?.localidade?.toLocaleUpperCase())?.id

  useEffect(() => {
    if (!isErrorOnConsultCep && !isFetchingCities && !isFetchingCepData && isStateSelected) {
      const timeout = setTimeout(() => {
        if (!cityFilteredByCep) {
          warnMessage("O cep informado é de uma cidade não cadastrada no sistema. Para criação do endereço, é necessário cadastrá-la anteriormente!")
          setValue('city_id', '')
        }
      }, 2000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [setValue, cityFilteredByCep, warnMessage, isErrorOnConsultCep, isFetchingCities, isFetchingCepData, isStateSelected])

  const {
    customers: { data: customers, isFetching: isFetchingCustomers }
  } = useCustomer(null, true)


  useEffect(() => {
    if (address) {


      setValue("situation", address.situation as AddressSituationType)
      setValue("type", address.type as AddressPersonTypes)
      setValue("cnpj_cpf", address.cnpj_cpf)
      setValue("branch", address.branch)
      setValue("responsible_name", address.responsible_name)
      setValue("responsible_email", address.responsible_email)
      setValue("responsible_telephone", address.responsible_telephone)
      setValue("cep", address.cep)
      setValue("street", address.street)
      setValue("number", address.number)
      setValue("complement", address.complement)
      setValue("neighborhood", address.neighborhood)
      setValue("reference_point", address.reference_point)
      setValue("trading_name", address.trading_name)
      setValue("icms", address.icms as AddressIcmsTypes)
      setValue("observation", address.observation)
      setValue("has_business_operation", "SIM")
      setValue("business_open", formatDate.handle(address.business_open, "DateOnlyWithHourMinute"))
      setValue("business_close", formatDate.handle(address.business_close, "DateOnlyWithHourMinute"))

      const addressHasSaturdayOperation = address.saturday_open !== null

      if (addressHasSaturdayOperation) {
        setValue("has_saturday_operation", "SIM")
        setValue("saturday_open", formatDate.handle(address.saturday_open, "DateOnlyWithHourMinute"))
        setValue("saturday_close", formatDate.handle(address.saturday_close, "DateOnlyWithHourMinute"))
      } else {
        setValue("has_saturday_operation", "NÃO")
      }

      const addressHasSundayOperation = address.sunday_open !== null

      if (addressHasSundayOperation) {
        setValue("has_sunday_operation", "SIM")
        setValue("sunday_open", formatDate.handle(address.sunday_open, "DateOnlyWithHourMinute"))
        setValue("sunday_close", formatDate.handle(address.sunday_close, "DateOnlyWithHourMinute"))
      } else {
        setValue("has_sunday_operation", "NÃO")
      }

      setValue('has_own_board_landing', address.has_own_board_landing ? 'SIM' : 'NÃO')
      setValue('deadline', address.deadline)
    }
  }, [setValue, address])

  useEffect(() => {
    if (address && !isFetchingCustomers) {
      setValue("customer_id", address.customer_id)
    }
  }, [address, setValue, isFetchingCustomers])

  useEffect(() => {
    if (cityFilteredByCep === null || isFetchingCepData) {
      setValue('city_id', '')
      setValue("state", '')
    } else {
      if (cepData && !isErrorOnConsultCep && !isFetchingCepData && !isFetchingCities) {
        if (stateFromCepUf) setValue('state', stateFromCepUf?.toLocaleUpperCase())
        if (cityFilteredByCep) setValue('city_id', cityFilteredByCep)
      }
    }

  }, [cepData, stateFromCepUf, setValue, cityFilteredByCep, isErrorOnConsultCep, isFetchingCepData, isFetchingCities])

  const formTitle = isViewPage ? "Visualizar endereço" : "Editar endereço"

  async function handleUpdateAddress({
    has_business_operation,
    has_sunday_operation,
    has_saturday_operation,
    ...values
  }: AddressFormInputs) {

    const hasUpdateAddress = await confirmMessage({ title: "Deseja atualizar um endereço?" })

    if (hasUpdateAddress) {
      const businessOpen = transforFormTimeInputInDate(values.business_open)
      const businessClose = transforFormTimeInputInDate(values.business_close)
      const saturdayOpen = transforFormTimeInputInDate(values.saturday_open)
      const saturdayClose = transforFormTimeInputInDate(values.saturday_close)
      const sundayOpen = transforFormTimeInputInDate(values.sunday_open)
      const sundayClose = transforFormTimeInputInDate(values.sunday_close)

      const updateAddressInputData = {
        ...values,
        business_open: businessOpen!,
        business_close: businessClose!,
        saturday_open: saturdayOpen,
        saturday_close: saturdayClose,
        sunday_open: sundayOpen,
        sunday_close: sundayClose,
        has_own_board_landing: values.has_own_board_landing === 'SIM'
      }

      await updateAddress({ address_id: id!, input: updateAddressInputData }, {
        onSuccess: () => redirectTo("/enderecos")
      })
    } else {
      standardMessage("Ação cancelada com êxito!")
    }

  }

  const {
    isOpen: isOpenAddressLogsModal,
    onOpen: onOpenAddressLogsModal,
    onClose: onCloseAddressLogsModal
  } = useDisclosure()

  if (isUpdateAddressLoading || isFetchingAddress) {
    return <GeneralContentLoading />
  }


  return (
    <StandardBackgroundForm
      title={formTitle}
      onSubmit={handleSubmit(handleUpdateAddress)}
    >
      <Flex w='full' justifyContent='flex-end'>
        {(slug === 'editar' || slug === 'visualizar') && (
          <Button colorScheme='blue' onClick={onOpenAddressLogsModal}>
            Logs
          </Button>
        )}
      </Flex>
      {!isFetchingCustomers && (
        <Modal
          isOpen={isOpenAddressLogsModal}
          onClose={onCloseAddressLogsModal}
          isCentered
        >
          <ModalOverlay />
          <AddressLogsModal
            addressId={id}
            customers={customers}
          />

        </Modal>
      )}

      <FormProvider {...formMethods} >
        <AddressForm
          slug={slug}
          cities={cities}
        />
      </FormProvider>
      <Flex
        direction={["column", "column", "row"]}
        justify={["center", "center", "flex-end"]}
        mt={6}
        gap={4}
      >
        {isViewPage ? (
          <>
            {userLoggedHasPermissionToEditAddress && (
              <Button
                as={Link}
                to={`/endereco/editar/${id}`}
                type="button"
                colorScheme="blue"
                w={["full", "full", "min"]}
                isLoading={isFetchingAddress}
              >
                Editar
              </Button>
            )}
            {userLoggedHasPermissionToRequestChangesAddress && (
              <Button
                as={Link}
                to={`/endereco/solicitar/alteracoes/${id}`}
                type="button"
                colorScheme="blue"
                w={["full", "full", "min"]}
                isLoading={isFetchingAddress}
              >
                Solicitar Alteração
              </Button>
            )}
          </>
        ) : (
          <Button
            type="submit"
            colorScheme="blue"
            w={["full", "full", "min"]}
            isLoading={isFetchingAddress || isSubmitting}
          >
            Salvar
          </Button>
        )}
        <Button
          as={Link}
          to="/enderecos"
          colorScheme="gray"
          w={["full", "full", "min"]}
          isLoading={isFetchingAddress || isSubmitting}
        >
          Endereços
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}
