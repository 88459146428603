import { Flex, FlexProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ExternalComunicationsMessageRootProps extends FlexProps {
  children: ReactNode
}

export function ExternalComunicationsMessageRoot({
  children,
  ...rest
}: ExternalComunicationsMessageRootProps) {
  return (
    <Flex
      gap="1"
      {...rest}
    >
      {children}
    </Flex>
  )
}
