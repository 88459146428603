import { Box, FormControl, FormLabel, Input, Select, Text, Textarea } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";


export function EmergencySuppliesRequestForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = event.currentTarget
    const value = target.value
    const labelMap: { [key in keyof FormDataItem]: string } = {
      emergencyShippingSender: 'Remetente do envio emergencial',
      emergencyShippingRecipient: 'Destinatário do envio emergencial',
      justificationForSupplies: 'Explicação para necessidade de insumos',
      proposedQuantityOfSupplies: 'Proposta de quantitativo a enviar de insumos',
      confirmedUseOfTarpaulinForProtection: 'Alinhado uso de lona para proteção caso não saia da matriz os insumos?',
      expectedShippingDate: 'Data prevista do envio'
    };

    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };

  return (
    <Box w='full'>
      <FormControl isInvalid={!!errors.formData?.emergencyShippingSender} mt={3}>
        <FormLabel fontSize='sm'>
          Remetente do envio emergencial
          <Text as="sup" color="red.500">*</Text>
          </FormLabel>
        <Input
          {...register('formData.emergencyShippingSender.value')}
          name='formData.emergencyShippingSender.value'
          size='sm'
          rounded="md"
          placeholder='cidade/coletador'
          onChangeCapture={handleInputChange('emergencyShippingSender')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.emergencyShippingRecipient} mt={3}>
        <FormLabel fontSize='sm'>
          Destinatário do envio emergencial
          <Text as="sup" color="red.500">*</Text>
          </FormLabel>
        <Input
          {...register('formData.emergencyShippingRecipient.value')}
          name='formData.emergencyShippingRecipient.value'
          size='sm'
          rounded="md"
          placeholder='cidade/coletador'
          onChangeCapture={handleInputChange('emergencyShippingRecipient')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.expectedShippingDate} mt={3}>
        <FormLabel fontSize='sm'>
          Data prevista do envio
          <Text as="sup" color="red.500">*</Text>
          </FormLabel>
        <Input
          {...register('formData.expectedShippingDate.value')}
          name='formData.expectedShippingDate.value'
          type="date"
          size='sm'
          rounded="md"
          placeholder='cidade/coletador'
          onChangeCapture={handleInputChange('expectedShippingDate')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.justificationForSupplies} mt={3}>
        <FormLabel fontSize="sm">
          Explicação para necessidade de insumos
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register('formData.justificationForSupplies.value')}
          name='formData.justificationForSupplies.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('justificationForSupplies')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.confirmedUseOfTarpaulinForProtection} mt={3}>
        <FormLabel fontSize="sm">
          Alinhado uso de lona para proteção caso não saia da matriz os insumos?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register("formData.confirmedUseOfTarpaulinForProtection.value")}
          name="formData.confirmedUseOfTarpaulinForProtection.value"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('confirmedUseOfTarpaulinForProtection')}
        >

          <option value='Sim'>Sim</option>
          <option value='Não'>Não</option>
          <option value='N.A'>N.A</option>

        </Select>
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.proposedQuantityOfSupplies} mt={3}>
        <FormLabel fontSize="sm">
          Proposta de quantitativo a enviar de insumos
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register('formData.proposedQuantityOfSupplies.value')}
          name='formData.proposedQuantityOfSupplies.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('proposedQuantityOfSupplies')}
        />
      </FormControl>
    </Box>
  )

}
