import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

export interface LandingsPendingValidationsResponse {
  id: string
  protocol: number
  customer: string
  destination_collector: string
  destination_branch: string
}

export interface GetLandingsPendingValidationsResponseResponse {
  landings: LandingsPendingValidationsResponse[]
  meta: {
    totalPages: number
    pageSize: number
    currentPage: number
  }
}


interface GetLandingsPendingValidationsQueryParams {
  pageSize: string
  currentPage: string
}

export async function getLandingsPendingValidations({
  currentPage,
  pageSize,
}: GetLandingsPendingValidationsQueryParams) {
  try {
    const { data } = await api.get<GetLandingsPendingValidationsResponseResponse>('/services/landings/pending-validations', {
      params: {
        currentPage,
        pageSize,
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
