import { Box, Flex } from "@chakra-ui/react";
import { PassiveFeedbackStatus as PassiveFeedbackStatusTypes } from "api/feedbacks/_types/PassiveFeedback";
import { passiveFeedbackStatusMap } from "utils/feedbackMappers";

interface PassiveFeedbackStatusProps {
  status: PassiveFeedbackStatusTypes;
}

export function PassiveFeedbackStatus({ status }: PassiveFeedbackStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {status === 'done' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'scheduled' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {passiveFeedbackStatusMap[status]}
    </Flex>
  )
}
