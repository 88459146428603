import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm"
import { FormProvider, useForm } from "react-hook-form"
import { MaterialForm, MaterialFormInputs } from "./components/MaterialForm"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useMaterialFunctions } from "hooks/material/useMaterialFunctions"
import { useToastify } from "hooks/toastify/useToastify"
import { Button, Flex } from "@chakra-ui/react"
import { useAuth } from "hooks/auth/useAuth"
import { useHistory } from "react-router-dom"
import { useEffect } from "react"

const createMaterialSchema = yup.object().shape({
  material_name: yup.string().required("Campo obrigatório")
})

export function CreateMaterialPage() {
  const formMethods = useForm<MaterialFormInputs>({
    resolver: yupResolver(createMaterialSchema)
  })

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged.permissions.includes('create-material')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  const { promiseMessage } = useToastify()

  const { handleSubmit, formState: { isSubmitting } } = formMethods

  const { createMaterial: { mutateAsync: createMaterial } } = useMaterialFunctions()

  async function handleCreateMaterial({
    material_name
  }: MaterialFormInputs) {

    await promiseMessage(createMaterial(material_name), 'Material criado com sucesso!')
  }

  return (
    <StandardBackgroundForm title="Criar material" onSubmit={handleSubmit(handleCreateMaterial)}>
      <FormProvider {...formMethods}>
        <MaterialForm />
      </FormProvider>

      <Flex
        mt={6}
        direction={["column", "column", "row"]}
        align="center"
        justify={["center", "center", "flex-end"]}
      >
        <Button
          w={["full", "full", "min"]}
          colorScheme="blue"
          type="submit"
          isLoading={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}
