import { useEffect } from "react";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { useToastify } from "hooks/toastify/useToastify";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { useAuth } from "hooks/auth/useAuth";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils/DateFunctions/formatDate";
import { add } from "date-fns";
import { Button, Flex, Stack } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { useRncFunctions } from "hooks/rnc/useRncFunctions";
import { rncHeaders } from "utils/ReportFunctions/handleChangeHeaders";
import { reportRncFormatDataToFront } from "utils/ReportFunctions/reportFormatDatatoFront";

interface RNCReportInputs {
  filterType: 'GERAL' | 'ETAPA'
  step: string
  initialDate: string
  finalDate: string
}

const schema = yup.object().shape({
  filterType: yup.string().required('Campo obrigatório'),
  step: yup.string().when('filterType', {
    is: 'ETAPA',
    then: yup.string().required('Campo Obrigatório'),
  }),
  initialDate: yup.string().when('filterType', {
    is: 'GERAL',
    then: yup.string().required('Campo Obrigatório'),
  }),
  finalDate: yup.string().when('filterType', {
    is: 'GERAL',
    then: yup.string().required('Campo Obrigatório'),
  }),
})

const filterByOptions = [
  { key: '0', value: 'GERAL', showOption: 'GERAL' },
  { key: '1', value: 'ETAPA', showOption: 'ETAPA' }
]

const rncStepsOptions = [
  { key: '0', value: 'AWAITING VALIDATION', showOption: 'AGUARDANDO VALIDAÇÃO' },
  { key: '1', value: 'AWAITING RESPONSE', showOption: 'AGUARDANDO RESPOSTA' },
  { key: '2', value: 'AWAITING ALIGNMENT', showOption: 'AGUARDANDO ALINHAMENTO' },
  { key: '3', value: 'AWAITING ATTACHMENT', showOption: 'AGUARDANDO ANEXO' },
]

export function RNCReport() {
  const { promiseMessage } = useToastify()
  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<RNCReportInputs>({
    resolver: yupResolver(schema),
  })

  const { generateRncReport: {
    mutateAsync: generateRncReport,
    isLoading: isGenerateRncReportDataLoading,
    data: rncReportData

  } } = useRncFunctions()
  const [
    initialDateFilter,
    filterTypeSelected,
  ] = useWatch({
    control,
    name: ['initialDate', 'filterType']
  })

  const isValidStartDate = new Date(initialDateFilter) instanceof Date &&
    !isNaN(new Date(initialDateFilter).getMilliseconds())

  const maxEndFilter = isValidStartDate
    ? formatDate.handle(add(new Date(initialDateFilter), {
      days: 31
    }), 'DateWithoutHourToInput')
    : ''

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-rnc-report')) {
      redirect('/')
    }
  }, [userLogged, redirect])

  const csvReportProps = {
    data: rncReportData !== undefined ? reportRncFormatDataToFront(rncReportData)  : [],
    headers: rncHeaders,
    filename: `relatorio-rnc.csv`

  }
  async function handleGenerateRNCReport(values: RNCReportInputs) {
    await promiseMessage(generateRncReport(values), "Relatório gerado com sucesso!")
  }
  return (
    <StandardBackgroundForm
      title='Relatório de RNC'
      onSubmit={handleSubmit(handleGenerateRNCReport)}
    >
      <Flex
        gap={4}
        direction='column'
      >

        <Stack
          w='full'
          spacing={4}
          direction={['column', 'column', 'row']}
        >
          <Select
            {...register('filterType')}
            name='filterType'
            label='Filtrar Por'
            options={filterByOptions}
            placeholder="Selecione uma opção"
            required
          />

        </Stack>
        {filterTypeSelected === 'ETAPA' && (
          <Stack
            w='full'
            spacing={4}
            direction={['column', 'column', 'row']}
          >
            <Select
              {...register('step')}
              name='step'
              label='Etapa'
              options={rncStepsOptions}
              placeholder="Selecione uma opção"
              required
            />
          </Stack>
        )}
        {filterTypeSelected === 'GERAL' && (
          <Stack
            w='full'
            spacing={4}
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('initialDate')}
              name='initialDate'
              label='Data inicial do filtro'
              type='date'
              error={errors.initialDate}
              required
            />

            <Input
              {...register('finalDate')}
              name='finalDate'
              label='Data final do filtro'
              type='date'
              error={errors.finalDate}
              max={maxEndFilter}
              required
            />
          </Stack>
        )}
        <Flex gap={2} justify={['center', 'center', 'flex-end']}>
          <Button
            isLoading={isGenerateRncReportDataLoading}
            type='submit'
            w={['full', 'full', 'min']}
            colorScheme='blue'
          >
            Gerar relatório
          </Button>
          {rncReportData !== undefined && (
            <GenerateExcelReportButton
              mt={-4}
              csvReportProps={csvReportProps}
              isLoading={isGenerateRncReportDataLoading} />
          )}
        </Flex>
      </Flex>
    </StandardBackgroundForm>
  )
}
