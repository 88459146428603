import { Box, Button, Flex, FormControl, FormLabel, Heading, Select, Text, Textarea } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup"
import { realizePassiveFeedback } from "api/feedbacks/realizePassiveFeedback";
import { useToastify } from "hooks/toastify/useToastify";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup"

interface RealizeRecurrentCltDriverPassiveFeedbackProps {
  passiveFeedbackId: string
}

interface RealizeRecurrentCltDriverPassiveFeedbackSchema {
  workSatisfactionRate: number
  routeChallengesEncountered: string
  roleResponsibilityFeedback: string
  impactOfPartnersAbsencesOnRoutine: string
  awareOfStandardizationReason: string
  leadershipRate: number
  supportFeedback: string
  companyImprovementFeedback: string
  loglifeReferralScore: number
  observations?: string
}

const realizeRecurrentCltDriverPassiveFeedbackSchema = yup.object({
  workSatisfactionRate: yup.number().required(),
  routeChallengesEncountered: yup.string().required(),
  roleResponsibilityFeedback: yup.string().required(),
  impactOfPartnersAbsencesOnRoutine: yup.string().required(),
  awareOfStandardizationReason: yup.string().required(),
  leadershipRate: yup.number().required(),
  supportFeedback: yup.string().required(),
  companyImprovementFeedback: yup.string().required(),
  loglifeReferralScore: yup.number().required(),
  observations: yup.string().required(),
})

const formSelectOptions = Array.from({ length: 10 }, (_, i) => (i + 1).toString());


export function RealizeRecurrentCltDriverPassiveFeedback({
  passiveFeedbackId
}: RealizeRecurrentCltDriverPassiveFeedbackProps) {
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<RealizeRecurrentCltDriverPassiveFeedbackSchema>({
    resolver: yupResolver(realizeRecurrentCltDriverPassiveFeedbackSchema)
  })

  const { mutateAsync: realizeRecurrentCltDriverPassiveFeedbackFn } = useMutation({
    mutationFn: realizePassiveFeedback,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'passives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'scheduled-passives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'done-passives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: ['passive-feedback', passiveFeedbackId] })
    }
  })

  async function handleRealizeRecurrentCltDriverPassiveFeedback(values: RealizeRecurrentCltDriverPassiveFeedbackSchema) {
    await promiseMessage(realizeRecurrentCltDriverPassiveFeedbackFn({
      body: {
        ...values,
      },
      passiveFeedbackId
    }), 'Feedback realizado')
  }

  return (
    <Box
      w="full"
      as="form"
      maxH='500px'
      overflowY='scroll'
      onSubmit={handleSubmit(handleRealizeRecurrentCltDriverPassiveFeedback)}
    >
      <Heading letterSpacing="tight" size='sm'>Formulário Recorrente</Heading>
      <FormControl isInvalid={!!errors.workSatisfactionRate} mt={3}>
        <FormLabel fontSize="sm">
          Avalie sua satisfação em trabalhar na Loglife até hoje (1 a 10)
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register('workSatisfactionRate')}
          name="workSatisfactionRate"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
        >
          {Object.entries(formSelectOptions).map(([key, value]) => (
            <option key={key} value={value}>{value}</option>
          ))}

        </Select>
      </FormControl>
      <FormControl isInvalid={!!errors.routeChallengesEncountered} mt={3}>
        <FormLabel fontSize="sm">
          Você encontrou algum desafio específico nas rotas que faz? Como lidou com isso?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("routeChallengesEncountered")}
          name="routeChallengesEncountered"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.roleResponsibilityFeedback} mt={3}>
        <FormLabel fontSize="sm">
          Como você se sente em relação ao seu papel e responsabilidades na equipe?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("roleResponsibilityFeedback")}
          name="roleResponsibilityFeedback"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.impactOfPartnersAbsencesOnRoutine} mt={3}>
        <FormLabel fontSize="sm">
          Como as faltas dos seus parceiros de trabalho impactam na sua rotina?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("impactOfPartnersAbsencesOnRoutine")}
          name="impactOfPartnersAbsencesOnRoutine"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.awareOfStandardizationReason} mt={3}>
        <FormLabel fontSize="sm">
          Está ciente da exigência da padronização?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("awareOfStandardizationReason")}
          name="awareOfStandardizationReason"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.leadershipRate} mt={3}>
        <FormLabel fontSize="sm">
          Nota para liderança? (1 a 10)
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register('leadershipRate')}
          name="leadershipRate"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
        >
          {Object.entries(formSelectOptions).map(([key, value]) => (
            <option key={key} value={value}>{value}</option>
          ))}

        </Select>
      </FormControl>
      <FormControl isInvalid={!!errors.supportFeedback} mt={3}>
        <FormLabel fontSize="sm">
          Você se sente apoiado? O que poderia ser melhorado?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("supportFeedback")}
          name="supportFeedback"
          size="sm"
          rounded="md"
        />
      </FormControl>

      <FormControl isInvalid={!!errors.companyImprovementFeedback} mt={3}>
        <FormLabel fontSize="sm">
          Existe algo que gostaria de mudar ou melhorar na empresa?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("companyImprovementFeedback")}
          name="companyImprovementFeedback"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.loglifeReferralScore} mt={3}>
        <FormLabel fontSize="sm">
          Qual a possibilidade de indicar a Loglife para alguém trabalhar? (0 a 10)
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register('loglifeReferralScore')}
          name="loglifeReferralScore"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
        >
          {Object.entries(formSelectOptions).map(([key, value]) => (
            <option key={key} value={value}>{value}</option>
          ))}

        </Select>
      </FormControl>
      <FormControl isInvalid={!!errors.companyImprovementFeedback} mt={3}>
        <FormLabel fontSize="sm">
          Observações:

        </FormLabel>
        <Textarea
          {...register("observations")}
          name="observations"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
