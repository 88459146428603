import { OnboardingType } from './onboarding';
import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface CreateOnboardingBody {
  trainingType: OnboardingType
  name: string
  collector: string
  cpf: string
  email: string
}

interface CreateOnboardingParams {
  body: CreateOnboardingBody
}

export async function createOnboarding({
  body
}: CreateOnboardingParams) {
  try {
    await api.post('/onboardings', body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

