import * as yup from 'yup'

const schema = yup.object().shape({
  customer_id: yup.string().required('Campo Obrigatório'),
  business_budget_id: yup.string().required('Campo Obrigatório'),
  source_collector_id: yup.string().required('Campo Obrigatório'),
  driver_id: yup.string().required('Campo Obrigatório'),
  source_address_id: yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
      case 'string':
        return yup.string().required('Campo Obrigatório')
      default:
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
    }
  }),
  destination_address_id: yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
      case 'string':
        return yup.string().required('Campo Obrigatório')
      default:
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
    }
  }),
  vehicle: yup.string().required('Campo Obrigatório'),
  service_type: yup.string().required('Campo Obrigatório'),
  caixa_termica: yup
    .number()
    .min(0, 'Valor precisa ser maior ou igual a zero')
    .typeError('Campo Obrigatório')
    .required('Campo Obrigatório'),
  embalagem_secundaria: yup
    .number()
    .min(0, 'Valor precisa ser maior ou igual a zero')
    .typeError('Campo Obrigatório')
    .required('Campo Obrigatório'),
  gelo_seco: yup
    .number()
    .min(0, 'Valor precisa ser maior ou igual a zero')
    .typeError('Campo Obrigatório')
    .required('Campo Obrigatório'),
  gelox: yup
    .number()
    .min(0, 'Valor precisa ser maior ou igual a zero')
    .typeError('Campo Obrigatório')
    .required('Campo Obrigatório'),
  isopor3l: yup
    .number()
    .min(0, 'Valor precisa ser maior ou igual a zero')
    .typeError('Campo Obrigatório')
    .required('Campo Obrigatório'),
  isopor7l: yup
    .number()
    .min(0, 'Valor precisa ser maior ou igual a zero')
    .typeError('Campo Obrigatório')
    .required('Campo Obrigatório'),
  terciaria3l: yup
    .number()
    .min(0, 'Valor precisa ser maior ou igual a zero')
    .typeError('Campo Obrigatório')
    .required('Campo Obrigatório'),
  terciaria8l: yup
    .number()
    .min(0, 'Valor precisa ser maior ou igual a zero')
    .typeError('Campo Obrigatório')
    .required('Campo Obrigatório'),
  route_start_date: yup
    .date()
    .typeError('Campo Obrigatório')
    .required('Campo Obrigatório'),
  route_start_hour: yup.string().required('Campo Obrigatório'),
  route_end_hour: yup.string().required('Campo Obrigatório'),
  is_recurrent: yup.string().required("Campo Obrigatório"),
  recurrence_type: yup.mixed().when('is_recurrent', {
    is: 'SIM',
    then: yup.string().required('Campo Obrigatório'),
  }),
  recurrence: yup.mixed().when('recurrence_type', {
    is: 'PERÍODO DE RECORRÊNCIA',
    then: yup.string().required('Campo Obrigatório'),
  }),
  days_of_week: yup.mixed().when('is_recurrent', {
    is: 'SIM',
    then: yup.lazy((value) => {
      switch (typeof value) {
        case 'object':
          return yup
            .array()
            .typeError('Campo Obrigatório')
            .min(1, 'Campo Obrigatório')
            .of(yup.string())
        case 'string':
          return yup.string().nullable().required('Campo Obrigatório')
        default:
          return yup
            .array()
            .typeError('Campo Obrigatório')
            .min(1, 'Campo Obrigatório')
            .of(yup.string())
      }
    }),
  }),
  is_source_addresses_schedule_updated: yup.string().test('is_true', 'O planejamento de coletas não está atualizado', value => value === "SIM"),
  is_destination_addresses_schedule_updated: yup.string().test('is_true', 'O planejamento de entregas não está atualizado', value => value === "SIM"),
  source_addresses_schedule: yup.array().of(yup.object().shape({
    address_id: yup.string().required("Campo Obrigatório"),
    planned_hour: yup.string().required("Campo Obrigatório")
  })),
  destination_addresses_schedule: yup.array().of(yup.object().shape({
    address_id: yup.string().required("Campo Obrigatório"),
    planned_hour: yup.string().required("Campo Obrigatório")
  })),
  deadline: yup
    .number()
    .typeError('Campo Obrigatório')
    .required('Campo Obrigatório'),
  automation_start_date: yup.mixed().when(['recurrence_type', 'is_recurrent'], {
    is: (recurrenceType: string, isRecurrent: string) => recurrenceType === 'ALTERNATE WEEKLY' && isRecurrent === 'SIM',
    then: yup.string().required('Campo Obrigatório'),
  }),
  observation: yup.string(),
})

export { schema }
