import { Button, Flex, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner } from "@chakra-ui/react";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { CustomerExtraAndDiscountForm, ExtraAndDiscountShema } from "./CustomerExtraAndDiscountForm";
import { useBusinessBudgetRoutes } from "hooks/businessBudget/useBusinessBudgetsRoutes";
import { useNonInactiveCustomers } from "hooks/customer/useNonInactiveCustomers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { editCustomerExtraDiscount } from "api/extrasAndDiscounts/editCustomerExtraDiscount";
import { useToastify } from "hooks/toastify/useToastify";
import { getExtraAndDiscount } from "api/extrasAndDiscounts/getExtraAndDiscount";
import { useEffect } from "react";


interface EditCustomerExtraDiscountProps {
  onClose: () => void
  extraAndDiscountId: string
}

const editExtrasAndDiscountsFormSchema = yup.object().shape({
  customer_id: yup.string().required(),
  type: yup.string().required(),
  service_type: yup.string().required(),
  protocol: yup.mixed().when('service_type', {
    is: 'EXPRESS',
    then: yup.string().required()
  }),
  route: yup.mixed().when('service_type', {
    is: 'BUSINESS',
    then: yup.string().required()
  }),
  occurrence_date: yup.string().when("service_type", {
    is: 'BUSINESS',
    then: yup.string().required()
  }),
  description: yup.string().required(),
  value: yup.string().required(),
})


export function EditCustomerExtraDiscount({ onClose, extraAndDiscountId }: EditCustomerExtraDiscountProps) {

  const formMethods = useForm<ExtraAndDiscountShema>({
    resolver: yupResolver(editExtrasAndDiscountsFormSchema)
  })

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const { control, handleSubmit, setValue, formState: { isSubmitting } } = formMethods

  const {
    data: customersData,
    isFetching: isCustomersDataFetching
  } = useNonInactiveCustomers()

  const [customerSelected, serviceType] = useWatch({
    control,
    name: ['customer_id', 'service_type']
  })

  const isBusinessServiceType = serviceType === 'BUSINESS'

  const {
    data: businessBudgetRoutesData
  } = useBusinessBudgetRoutes({
    customer_id: customerSelected,
    queryOptions: {
      enabled: !!customerSelected && isBusinessServiceType
    }
  })

  const { mutateAsync: editCustomerExtraDiscountFn } = useMutation({
    mutationFn: editCustomerExtraDiscount,
    onSuccess(_data, _variables, _context) {

      queryClient.invalidateQueries({ queryKey: 'customers-extras-discounts' })
      onClose()
    }
  })

  const {
    data: extraAndDiscountResult,
    isLoading: isLoadingextraAndDiscountResult
  } = useQuery({
    queryKey: ['customer-extra-discount', extraAndDiscountId],
    queryFn: () => getExtraAndDiscount({ extraAndDiscountId })
  })

  useEffect(() => {
    if (extraAndDiscountResult) {
      setValue('type', extraAndDiscountResult?.extraAndDiscount.type)
      setValue('service_type', extraAndDiscountResult?.extraAndDiscount.service_type)
      setValue('description', extraAndDiscountResult?.extraAndDiscount.description)
      setValue('value', String(extraAndDiscountResult?.extraAndDiscount.value / 100))
      setValue('protocol', extraAndDiscountResult?.extraAndDiscount.protocol)
      if (extraAndDiscountResult?.extraAndDiscount?.occurrence_date) {
        setValue('occurrence_date', extraAndDiscountResult?.extraAndDiscount?.occurrence_date)
      }
    }
  }, [extraAndDiscountResult, setValue])

  useEffect(() => {
    if (customersData && extraAndDiscountResult) {
      if (extraAndDiscountResult.extraAndDiscount.customer) {
        setValue('customer_id', extraAndDiscountResult?.extraAndDiscount?.customer.id)

        if (businessBudgetRoutesData) {
          setValue('route', extraAndDiscountResult?.extraAndDiscount?.route)
        }
      }
    }
  }, [setValue, customersData, extraAndDiscountResult, businessBudgetRoutesData])


  async function handleEditExtrasAndDiscounts(
    values: ExtraAndDiscountShema
  ) {
    const successMessage = values.type === 'DESCONTO'
      ? 'Desconto atualizado com sucesso!'
      : 'Extra atualizado com sucesso!'

    const formData = new FormData()

    formData.append('type', values.type)
    formData.append('service_type', values.service_type)
    if (values.protocol) {
      formData.append('protocol', values.protocol.toString())
    }
    if (values.route) {
      formData.append('route', values.route)
    }
    formData.append('description', values.description)
    formData.append('value', values.value)
    formData.append('attachment', values.attachment[0])

    if (values.occurrence_date) {
      formData.append("occurrence_date", values.occurrence_date)
    }

    await promiseMessage(editCustomerExtraDiscountFn({
      customerId: values.customer_id,
      body: formData,
      extraAndDiscountId
    }), successMessage)
  }


  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Editar Extra/Desconto
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        maxH='600px'
        overflowY='scroll'
        onSubmit={handleSubmit(handleEditExtrasAndDiscounts)}
      >
        {(isCustomersDataFetching || isLoadingextraAndDiscountResult) ? (
          <Spinner />
        ) : (
          <FormProvider {...formMethods}>
            <CustomerExtraAndDiscountForm
              customers={customersData.customers}
              businessBudgetRoutesData={businessBudgetRoutesData}
              slug='edit'
              attachmentPreview={extraAndDiscountResult?.extraAndDiscount?.attachment}
            />

          </FormProvider>
        )}
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Editar
          </Button>
        </Flex>

      </ModalBody>
    </ModalContent>
  )
}
