import { Box, Button, Divider, Flex, Heading, Icon, IconButton, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react"

import { Link, useHistory } from "react-router-dom"
import { usePagination } from "@ajna/pagination"
import { useEffect, useState } from "react"
import { Pagination } from "components/Pagination/Pagination"
import { useToastify } from "hooks/toastify/useToastify"
import { useQueryClient } from "react-query"
import { FaCheckCircle, FaClone, FaEllipsisH, FaEye, FaPen, FaPlus, FaTimes, FaTrash } from "react-icons/fa"
import { useAuth } from "hooks/auth/useAuth"
import { useCltDriversExtrasDiscounts } from "hooks/cltDriver/useCltDriversExtrasDiscounts"
import { useCltDriverFunctions } from "hooks/cltDriver/useCltDriverFunctions"
import { CltDriversExtrasDiscountsTableStatusButton } from "./components/CltDriversExtrasDiscountsTableStatusButton"
import { CltDriversExtrasDiscountsStatusEnum } from "contexts/CltDriversExtrasDiscountsStatusContext"
import { useCltDriversExtrasDiscountsStatus } from "hooks/cltDriver/useCltDriversExtrasDiscountsStatus"
import { ViewExtraDiscountModal } from "components/Modal/ViewExtraDiscountModal"
import { ExtraDiscount } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts"
import { formatDate } from "utils/DateFunctions/formatDate"
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton"


const headers = [
  { label: 'MOTORISTA', key: 'cltDriver' },
  { label: 'TIPO', key: 'type' },
  { label: 'DATA DO SERVIÇO', key: 'service_date' },
  { label: 'VALOR', key: 'value' },
  { label: 'ROTA', key: 'route' },
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'DESCRIÇÃO', key: 'description' },
  { label: 'CRIADO POR', key: 'created_by' },
]

function formatValuesToReport(values: ExtraDiscount[]) {
  return values?.map((extraDiscount) => ({
    ...extraDiscount,
    cltDriver: `${extraDiscount.cltDriver.driver.firstname} ${extraDiscount.cltDriver.driver.lastname} `,
    service_date: formatDate.handle(extraDiscount.service_date, 'DateWithoutHourToShow'),
    value: extraDiscount.value / 100,
    route: extraDiscount.service_route ?? '-',
    protocol: extraDiscount.services ? extraDiscount?.services?.map((service) => service?.service?.protocol).join(', ') : '-',
    created_by: extraDiscount?.createdBy ? `${extraDiscount?.createdBy.firstname} ${extraDiscount?.createdBy.lastname}` : '-',
  }))
}

export function CltDriversExtrasDiscountsList() {
  const [extraDiscount, setExtraDiscount] = useState({} as ExtraDiscount)

  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()

  const { push: redirect, location } = useHistory()
  const { status } = useCltDriversExtrasDiscountsStatus()

  const userHasPermissionToViewCollectorExtraDiscount =
    userLogged?.permissions.includes('view-clt-driver-extra-discount')

  useEffect(() => {
    if (!userHasPermissionToViewCollectorExtraDiscount) {
      redirect('/')
    }
  }, [userHasPermissionToViewCollectorExtraDiscount, redirect])

  const statusFilter: unknown = location.search.match(`[?&]status=([^&]+)`)
  const currentPageFilter = location.search.match(`[?&]page=([^&]+)`)

  const {
    data: cltDriversExtrasDiscountsData,
    isFetching: isFetchingCltDriversExtrasDiscountsData
  } = useCltDriversExtrasDiscounts({
    queryParams: {
      pageSize: Number(rowsPerPage),
      status: statusFilter ? statusFilter[1] : status,
      currentPage: currentPageFilter ? Number(currentPageFilter[1]) : 1
    }
  })

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: cltDriversExtrasDiscountsData?.totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  const handleChangePage = (page: number) => setCurrentPage(page)

  useEffect(() => {
    if (userHasPermissionToViewCollectorExtraDiscount) {
      redirect({
        pathname: '/extras-descontos/motoristas-clt',
        search: `?page=${currentPage}&=${status}`
      })
    }
  }, [currentPage, redirect, status, userHasPermissionToViewCollectorExtraDiscount])

  const {
    approveCltDriverExtraDiscount: {
      mutateAsync: approveCltDriverExtraDiscount
    },
    rejectCltDriverExtraDiscount: {
      mutateAsync: rejectCltDriverExtraDiscount
    },
    deleteCltDriverExtraDiscount: {
      mutateAsync: deleteCltDriverExtraDiscount
    },
    effectiveCltDriverExtraDiscount
  } = useCltDriverFunctions()

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  async function handleApproveCltDriverExtraDiscount(
    cltDriverExtraDiscountId: string
  ) {
    await promiseMessage(approveCltDriverExtraDiscount(
      cltDriverExtraDiscountId, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['cltDriversExtrasDiscounts'])
      }
    }
    ), 'Extra/Desconto de motorista CLT aprovado com sucesso!')
  }

  async function handleEffectiveCltDriverExtraDiscount(
    cltDriverExtraDiscountId: string
  ) {
    await promiseMessage(effectiveCltDriverExtraDiscount.mutateAsync(
      cltDriverExtraDiscountId, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('cltDriversExtrasDiscounts')
      }
    }
    ), 'Extra/Desconto de motorista CLT efetivado com sucesso!')
  }

  async function handleRejectCltDriverExtraDiscount(
    cltDriverExtraDiscountId: string
  ) {
    await promiseMessage(rejectCltDriverExtraDiscount(
      cltDriverExtraDiscountId, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['cltDriversExtrasDiscounts'])
      }
    }
    ), 'Extra/Desconto de motorista CLT recusado com sucesso!')
  }

  async function handleDeleteCltDriverExtraDiscount(
    cltDriverExtraDiscountId: string
  ) {
    await promiseMessage(deleteCltDriverExtraDiscount(
      cltDriverExtraDiscountId, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['cltDriversExtrasDiscounts'])
      }
    }
    ), 'Extra/Desconto de motorista CLT excluido com sucesso!')
  }

  function handleUpdateCltDriverExtraDiscount(extraDiscountId: string) {
    window.open(`/extra-desconto/${extraDiscountId}/motorista-clt`, '_blank')
  }

  const cltDriversExtrasDiscountsStatusActionsOptions = [
    {
      status: 'requested',
      actions: [
        {
          type: 'Aprovar',
          permission: 'approve-clt-driver-extra-discount',
          icon: FaCheckCircle,
          handle: handleApproveCltDriverExtraDiscount,
        },
        {
          type: 'Editar',
          permission: 'update-clt-driver-extra-discount',
          icon: FaPen,
          handle: handleUpdateCltDriverExtraDiscount
        },
        {
          type: 'Recusar',
          permission: 'reject-clt-driver-extra-discount',
          icon: FaTimes,
          handle: handleRejectCltDriverExtraDiscount
        },
        {
          type: 'Excluir',
          permission: 'delete-clt-driver-extra-discount',
          icon: FaTrash,
          handle: handleDeleteCltDriverExtraDiscount
        },
      ]
    },
    {
      status: 'approved',
      actions: [
        {
          type: 'Efetivar',
          permission: 'effective-clt-driver-extra-discount',
          icon: FaCheckCircle,
          handle: handleEffectiveCltDriverExtraDiscount
        },
        {
          type: 'Editar',
          permission: 'update-clt-driver-extra-discount',
          icon: FaPen,
          handle: handleUpdateCltDriverExtraDiscount
        },
        {
          type: 'Excluir',
          permission: 'delete-clt-driver-extra-discount',
          icon: FaTrash,
          handle: handleDeleteCltDriverExtraDiscount
        },
      ]
    },
    {
      status: 'rejected',
      actions: [
        {
          type: 'Excluir',
          permission: 'delete-clt-driver-extra-discount',
          icon: FaTrash,
          handle: handleDeleteCltDriverExtraDiscount
        },
      ]
    },
    {
      status: 'effectivated',
      actions: [
        {
          type: 'Editar',
          permission: 'update-clt-driver-extra-discount',
          icon: FaPen,
          handle: handleUpdateCltDriverExtraDiscount
        },
        {
          type: 'Excluir',
          permission: 'delete-clt-driver-extra-discount',
          icon: FaTrash,
          handle: handleDeleteCltDriverExtraDiscount
        },
      ]
    },
  ]

  const {
    isOpen: isViewExtraDiscountModalOpen,
    onOpen: onOpenViewExtraDiscountModal,
    onClose: onCloseViewExtraDiscountModal,
  } = useDisclosure()

  const handleOpenViewExtraDiscountModal = (extraDiscount: ExtraDiscount) => {
    setExtraDiscount(extraDiscount)
    onOpenViewExtraDiscountModal()
  }

  const csvReportProps = {
    data: cltDriversExtrasDiscountsData ? formatValuesToReport(cltDriversExtrasDiscountsData?.extrasDiscounts) : [],
    headers,
    filename: `extras-e-descontos-motoristas-clt.csv`,
  }

  return (
    <>

      <ViewExtraDiscountModal
        onClose={onCloseViewExtraDiscountModal}
        isOpen={isViewExtraDiscountModalOpen}
        extraDiscount={extraDiscount}
        type='cltDriver'
      />

      <Box
        p={4}
        bg='white'
        rounded='lg'
      >
        <Flex
          gap={4}
          direction='column'

        >
          <Flex
            align="center"
            gap={6}
            justify={["center", "center", "space-between"]}
            direction={["column", "column", "row"]}
            flex="1"
            w="full"
          >
            <Heading size='lg'>Extras/Descontos de Motoristas CLT - {CltDriversExtrasDiscountsStatusEnum[status]}</Heading>
            <Button
              as={Link}
              size="sm"
              w={["full", "full", "min"]}
              colorScheme="gray"
              to="/extra-desconto/motorista-clt/criar"
              leftIcon={<Icon as={FaPlus} />}
            >
              Novo
            </Button>
          </Flex>

          <Divider />

          {isFetchingCltDriversExtrasDiscountsData ? (
            <Spinner />
          ) : (
            <TableContainer>
              <Table size='sm' variant='striped'>
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>MOTORISTA</Th>
                    <Th>TIPO</Th>
                    <Th>DATA DO SERVIÇO</Th>
                    <Th>VALOR</Th>
                    <Th>ROTA</Th>
                    <Th>PROTOCOLO</Th>
                    <Th>
                      <CltDriversExtrasDiscountsTableStatusButton />
                    </Th>
                    <Th></Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {cltDriversExtrasDiscountsData?.extrasDiscounts.map((extraDiscount) => {

                    const cltDriverStatus = CltDriversExtrasDiscountsStatusEnum[status]

                    const [serviceYear, serviceMonth, serviceDay] = extraDiscount.service_date.split('-')

                    return (
                      <Tr key={extraDiscount.id}>
                        <Td>
                          {extraDiscount.is_duplicated && (
                            <Icon as={FaClone} mx="2px" title="Extra/Desconto duplicado" color='blue.800' />
                          )}
                        </Td>
                        <Td>{`${extraDiscount.cltDriver.driver.firstname} ${extraDiscount.cltDriver.driver.lastname} `}</Td>
                        <Td>{extraDiscount.type}</Td>
                        <Td>{`${serviceDay}/${serviceMonth}/${serviceYear}`}</Td>
                        <Td>{
                          new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                          }).format(extraDiscount.value / 100)
                        }</Td>
                        <Td>{extraDiscount.service_route ?? '-'}</Td>
                        <Td>{extraDiscount.services ? extraDiscount?.services?.map((service) => service?.service?.protocol).join(', ') : '-'}</Td>
                        <Td>{cltDriverStatus}</Td>
                        <Td>
                          <Button
                            as={IconButton}
                            icon={<Icon as={FaEye} />}
                            onClick={() => handleOpenViewExtraDiscountModal(extraDiscount)}
                          />
                        </Td>
                        <Td>
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              variant='ghost'
                              aria-label='Options'
                              icon={<FaEllipsisH />}
                            />
                            <MenuList>
                              <MenuGroup title="Ações">
                                {cltDriversExtrasDiscountsStatusActionsOptions
                                  ?.find((option) => option.status === status)?.actions.filter((action) => userLogged?.permissions.includes(action.permission)).map((action) => {
                                    return (
                                      <MenuItem
                                        key={action.type}
                                        onClick={() => action.handle(extraDiscount.id)}
                                        display="flex"
                                        alignItems="center"
                                        gap="2"
                                      >
                                        <Icon as={action.icon} />
                                        {action.type}
                                      </MenuItem>

                                    )
                                  })}
                              </MenuGroup>
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          <GenerateExcelReportButton csvReportProps={csvReportProps} />
          <Pagination
            currentPage={currentPage}
            pages={pages}
            pagesQuantity={pagesCount}
            handlePageChange={handleChangePage}
          />
        </Flex>
      </Box>
    </>
  )
}
