import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UnsuccessBoardServicesToValidate } from "./Boarded/UnsuccessBoardServicesToValidate";
import { UnsuccessCollectsPendingValidationTable } from "./Collected/UnsuccessCollectsPendingValidationTable";

export function ServiceUnsuccessPendingValidateList() {
  const searchParams = useSearchParams()
  const history = useHistory()
  const { userLogged } = useAuth()

  const userHasPermissionToValidateUnsuccesCollectAddress = userLogged?.permissions.includes("validate-unsuccess-collect-service")

  const userHasPermissionToValidateUnsuccesBoards = userLogged?.permissions.includes('validate-unsuccess-board-service')

  useEffect(() => {
    if (!userHasPermissionToValidateUnsuccesCollectAddress && !userHasPermissionToValidateUnsuccesBoards) {
      history.push('/')
    }
  }, [history, userHasPermissionToValidateUnsuccesCollectAddress, userHasPermissionToValidateUnsuccesBoards])

  function handleChangeTab() {
    searchParams.set('page', '1')

    history.replace({ search: searchParams.toString() })
  }
  return (
    <Box
      p="6"
      rounded="md"
      bg="white"
      display="flex"
      flexDirection="column"
      gap="6"
    >
      <Tabs isLazy onChange={handleChangeTab}>
        <TabList>
          <Tab>Coletas</Tab>
          <Tab>Embarques</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <UnsuccessCollectsPendingValidationTable />
          </TabPanel>
          <TabPanel>
            <UnsuccessBoardServicesToValidate />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )

}
