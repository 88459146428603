import axios from "axios";
import { useAuth } from "hooks/auth/useAuth";
import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { RequestError } from "utils/errors/RequestErrors";

interface CreateWarehouseInput{
  situation: string
  name: string
}

interface UpdateWarehouseRequestProps {
  warehouse_id: string
  input: CreateWarehouseInput
}

async function createWarehouseReqFunction(input: CreateWarehouseInput) {
  try {
    await api.post('/warehouses', input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

async function updateWarehouseReqFunction({
  warehouse_id, input
}: UpdateWarehouseRequestProps) {
  try {
    await api.put(`/warehouses/${warehouse_id}`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

async function deleteWarehouseReqFunction(warehouse_id: string) {
  try {
    await api.delete(`/warehouses/${warehouse_id}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

export function useWarehouseFunctions() {

  const queryClient = useQueryClient()
  const { handleLogout, } = useAuth()

  const onRequestSuccess = async () => {
    await queryClient.invalidateQueries("warehouses")
  }

  const onRequestError = async (error: RequestError) => {
    if (error.httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  const createWarehouse = useMutation('createWarehouse', createWarehouseReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  const updateWarehouse = useMutation('updateWarehouse', updateWarehouseReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  const deleteWarehouse = useMutation('deleteWarehouse', deleteWarehouseReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  return { createWarehouse, updateWarehouse, deleteWarehouse }
}
