import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface DeleteExperiencePeriodProps {
  experiencePeriodId: string
}

export async function deleteExperiencePeriod({
  experiencePeriodId
}: DeleteExperiencePeriodProps) {
  try {
    const response = await api.delete(`/experience-periods/${experiencePeriodId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
