import { Td, Tr } from "@chakra-ui/react";
import { Carrier } from "api/carriers/_types/Carrier";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { Collector } from "hooks/collector/dtos/Collector";
import { captalize } from "utils/captalize";
import { CarrierStatus } from "./CarrierStatus";

interface CarriersReportTableRowProps {
  carrier: Carrier & {
    collector: Collector
    dtVaccineDoseAttachment: Attachment
    hepatiteBVaccineDoseAttachment: Attachment
    tripliceViralVaccineDoseAttachment: Attachment
    cnhAttachment: Attachment
    crlvAttachment: Attachment
    anttMotorFreightAttachment: Attachment
    vehicleSidePhotoAttachment: Attachment
    vehicleRearPhotoAttachment: Attachment
    vehicleInternPhotoAttachment: Attachment
  }
}


export function CarriersReportTableRow({ carrier }: CarriersReportTableRowProps) {

  return (
    <Tr>
      <Td>{carrier.name}</Td>
      <Td>{captalize(carrier.collector.trading_name)}</Td>
      <Td>{carrier.cpf}</Td>
      <Td>{carrier.email}</Td>
      <Td>
        <CarrierStatus status={carrier.status} />
      </Td>
    </Tr>
  )
}
