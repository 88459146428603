import axios from "axios";
import { useAuth } from "hooks/auth/useAuth";
import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { RequestError } from "utils/errors/RequestErrors";

interface FinishMaintenanceProps {
  input: FormData
  maintenanceId: string
}

async function createMaintenanceReqFunction(input: FormData) {
  try {
    await api.post('/maintenance-service', input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

async function finishMaintenanceReqFunction({
  input,
  maintenanceId
}: FinishMaintenanceProps) {
  try {
    await api.put(`/maintenance-service/${maintenanceId}`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

export function useMaintenanceServiceFunctions() {

  const { handleLogout } = useAuth()

  const queryClient = useQueryClient()

  const onRequestSuccess = async () => {
    await queryClient.invalidateQueries(['maintenanceServices'])
  }

  const onRequestError = async ({ httpCode }: RequestError) => {
    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  const createMaintenance = useMutation('createMaintenance',
    createMaintenanceReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  const finishMaintenance = useMutation('finishMaintenance',
    finishMaintenanceReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  return { createMaintenance, finishMaintenance }
}
