import {
  Menu,
  MenuButton,
  Button,
  Icon,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  MenuDivider,
  useMediaQuery,
} from '@chakra-ui/react'
import { useFilterOptions } from '../../hooks/filterOptions/useFilterOptions'
import { FaFilter } from 'react-icons/fa'

export function TableFilterButton() {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  const {
    handleRegisterOptions,
    handleChangeOrderOptions,
    orderOptions,
    orderOptionSelected,
    filterOptions,
  } = useFilterOptions()

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        colorScheme="blue"
        variant="outline"
        leftIcon={isWideVersion ? <Icon as={FaFilter} /> : undefined}
      >
        {isWideVersion ? 'Filtros' : <Icon as={FaFilter} />}
      </MenuButton>
      <MenuList overflowY="auto" maxH="400px">
        <MenuOptionGroup title="Ordenar Por" type="checkbox">
          {orderOptions.map((option) => (
            <MenuItemOption
              fontSize="12"
              isChecked={orderOptionSelected.includes(option.key)}
              onClick={(e) => handleChangeOrderOptions(e.currentTarget.value)}
              key={option.key}
              value={option.key}
            >
              {option.value}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
        <MenuDivider />
        <MenuOptionGroup title="Filtrar por" type="checkbox">
          {filterOptions.map((option) => (
            <MenuItemOption
              fontSize="12"
              isChecked={option.checked === true}
              onClick={() => handleRegisterOptions(option.key)}
              key={option.key}
              value={option.key}
            >
              {option.value}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
