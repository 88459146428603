import { Button, Flex, Input, Select } from "@chakra-ui/react";
import { SporadicStatus } from "api/sporadics/_types/Sporadic";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";


interface SporadicsTableFilterProps {
  hasStatusFilter?: boolean
}

interface SporadicsTableFiltersSchema {
  status: SporadicStatus | 'all'
  protocol: string
}

export function SporadicsTableFilter({
  hasStatusFilter = true
}: SporadicsTableFilterProps) {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const protocol = searchParams.get('protocol')
  const status = searchParams.get('status') ?? 'all'

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<SporadicsTableFiltersSchema>({
    defaultValues: {
      protocol: protocol ?? '',
      status: status as SporadicStatus | 'all'
    }
  })

  async function handleFilterSporadics(data: SporadicsTableFiltersSchema) {
    if (data.protocol) {
      searchParams.set('protocol', data.protocol)
    } else {
      searchParams.delete('protocol')
    }
    if (
      data.status) {
      searchParams.set('status', data.status)
    } else {
      searchParams.delete('status')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    reset()
    searchParams.delete('status')
    searchParams.delete('protocol')

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterSporadics)}
    >
      <Input
        {...register("protocol")}
        placeholder="Protocolo"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />
      {hasStatusFilter && (
        <Select
          {...register("status")}
          defaultValue="all"
          size="sm"
          rounded="md"
          w={["full", "full", "200px"]}
        >
          <option value="all">Todos status</option>
          <option value="in-billing">Em Faturamento</option>
          <option value="billed">Faturados</option>
          <option value="canceled">Cancelados</option>
        </Select>
      )}

      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={["full", "full", "min"]}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleRemoveFilters}
        w={["full", "full", "min"]}
      >
        Remover filtros
      </Button>

    </Flex>
  )
}
