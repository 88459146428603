import * as yup from "yup"
import { Button, Flex, Icon, Spinner } from "@chakra-ui/react";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { TextArea } from "components/Inputs/TextInput";
import { useRnc } from "hooks/rnc/useRnc";
import { useLLMUsers } from "hooks/user/useLLMUsers";
import { Fragment } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FaPlus, FaTrash } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { useRncFunctions } from "hooks/rnc/useRncFunctions";
import { RncAlignAccordion } from "components/Accordions/Rnc/RncAlignAccordion";

interface RncAlignments {
  alignedBy: string
  date: string
  description: string
}

interface AlignInternRncInputs {
  alignments: RncAlignments[]
}

interface Params {
  rncId: string
}

const alignInterRncFormSchema = yup.object().shape({
  alignments: yup.array().min(1, 'Campo obrigatório.').of(yup.object({
    alignedBy: yup.string().required('Campo obrigatório.'),
    date: yup.string().required('Campo obrigatório.'),
    description: yup.string().required('Campo obrigatório.'),
  }))
})

export function AlignInternRnc() {

  const { push: redirect, goBack } = useHistory()
  const { rncId } = useParams<Params>()

  const {
    data: llmUsersData,
    isFetching: isFetchingLlmUsersData
  } = useLLMUsers({
    queryParams: {
      situation: 'ATIVO'
    }
  })

  const llmUsersSelectOptions = llmUsersData?.users.map((user) => {
    return {
      key: user.id,
      value: user.id,
      showOption: `${user.firstname} ${user.lastname}`
    }
  })

  const {
    rnc: {
      data: rnc,
      isFetching: isFetchingRnc
    }
  } = useRnc(rncId)

  const {
    register,
    control,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<AlignInternRncInputs>({
    resolver: yupResolver(alignInterRncFormSchema)
  })

  const {
    fields: rncAlignmentFields,
    append: appendRncAlignmentField,
    remove: handleRemoveRncAlignmentField
  } = useFieldArray({
    control,
    name: 'alignments'
  })

  const handleAppendRncAlignmentField = () => {
    appendRncAlignmentField({
      alignedBy: '',
      date: '',
      description: ''
    })
  }

  const { alignInternRnc } = useRncFunctions()

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  async function handleAlignInternRnc(input: AlignInternRncInputs) {
    await promiseMessage(alignInternRnc.mutateAsync({
      input,
      rncId
    }, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['rncs'])
        redirect('/rnc/pendentes-alinhamento')
      }
    }), 'Alinhamentos salvos com sucesso!')
  }

  return (
    <StandardBackgroundForm
      title="Alinhar RNC"
      onSubmit={handleSubmit(handleAlignInternRnc)}
    >
      {(isFetchingRnc || isFetchingLlmUsersData) ? (
        <Spinner />
      ) : (
        <Flex direction="column" gap={4}>
          <RncAlignAccordion rnc={rnc} />

          {rnc && (
            <Input
              name="rncResponsible"
              defaultValue={`${rnc.UserResponsibleIDRNC.firstname} ${rnc.UserResponsibleIDRNC.lastname}`}
              label="Responsável pela RNC"
              isDisabled
            />
          )}

          {rncAlignmentFields.map((field, index) => {
            return (
              <Fragment key={field.id}>
                <Select
                  {...register(`alignments.${index}.alignedBy`)}
                  name={`alignments.${index}.alignedBy`}
                  options={llmUsersSelectOptions}
                  label="Responsável pelo alinhamento"
                  placeholder="Selecione um usuário..."
                  error={
                    errors.alignments
                      ? errors?.alignments[index]?.alignedBy
                      : undefined
                  }
                  required
                />

                <Input
                  {...register(`alignments.${index}.date`)}
                  name={`alignments.${index}.date`}
                  label="Data do alinhamento"
                  type="date"
                  error={
                    errors.alignments
                      ? errors?.alignments[index]?.date
                      : undefined
                  }
                  required
                />

                <TextArea
                  {...register(`alignments.${index}.description`)}
                  name={`alignments.${index}.description`}
                  label="Descrição"
                  error={
                    errors.alignments
                      ? errors?.alignments[index]?.description
                      : undefined
                  }
                  required
                />
                <Button
                  colorScheme="red"
                  leftIcon={<Icon as={FaTrash} />}
                  onClick={() => {
                    handleRemoveRncAlignmentField(index)
                  }}
                  variant="ghost"
                  w="min"
                  alignSelf="flex-end"
                  size="sm"
                >
                  Remover
                </Button>
              </Fragment>
            )
          })}
          <Button
            colorScheme="blue"
            leftIcon={<Icon as={FaPlus} />}
            onClick={handleAppendRncAlignmentField}
            w="min"
            size="sm"
          >
            Alinhamento
          </Button>
        </Flex>
      )}
      <Flex justify="flex-end" gap={2}>
        <Button
          type="submit"
          colorScheme="blue"
        >
          Salvar
        </Button>
        <Button onClick={goBack}>Voltar</Button>
      </Flex>
    </StandardBackgroundForm>
  )
}
