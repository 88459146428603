import { Flex, IconButton, Input, Select } from "@chakra-ui/react";
import { CarrierStatus } from "api/carriers/_types/Carrier";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { carrierStatusMap } from "utils/carrierMappers";

interface CarrierTableFiltersSchema {
  status: CarrierStatus | 'all'
  name: string
}

export function CarriersTableFilters() {
  const searchParams = useSearchParams()
  const history = useHistory()

  const name = searchParams.get('name')
  const status = searchParams.get('status') ?? 'all'

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<CarrierTableFiltersSchema>({
    defaultValues: {
      name,
      status: status as CarrierStatus | 'all',
    }
  })

  async function handleFilterCarriers(data: CarrierTableFiltersSchema) {
    if (data.name) {
      searchParams.set('name', data.name)
    } else {
      searchParams.delete('name')
    }

    if (data.status) {
      searchParams.set('status', data.status)
    } else {
      searchParams.delete('status')
    }

    searchParams.set('page', '1')

    history.replace({ search: searchParams.toString() })
  }

  function handleClearFilters() {
    searchParams.delete('collaboratorName')
    searchParams.delete('status')

    searchParams.set('page', '1')

    reset({
      name: '',
      status: 'all'
    })

    history.replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={{ base: 'column', lg: 'row' }}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterCarriers)}
    >
      <Input
        {...register("name")}
        placeholder="Nome"
        size="sm"
        rounded="md"
        w={{ base: 'full', lg: '300px' }}
      />

      <Select
        {...register("status")}
        defaultValue="all"
        size="sm"
        rounded="md"
        w={{ base: 'full', lg: '300px' }}
      >
        {Object.entries(carrierStatusMap).map(([statusKey, statusValue]) => {
          return <option key={statusKey} value={statusKey}>{statusValue}</option>
        })}
        <option value="all">Todos status</option>
      </Select>

      <IconButton
        aria-label=""
        icon={<FaSearch />}
        variant="ghost"
        size="sm"
        type="submit"
      />

      <IconButton
        aria-label=""
        icon={<FaTimes />}
        variant="ghost"
        type="button"
        size="sm"
        onClick={handleClearFilters}
      />

    </Flex>
  )
}
