import {
  Button,
  Flex,
  FormLabel,
  Icon,
  Link as ChakraLink,
  Stack
} from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";

import { Radio } from "components/Inputs/RadioInput";

import { useFormContext, useWatch } from "react-hook-form";
import { FiFile } from "react-icons/fi";
import { positiveNegativeOptions } from "utils/CustomLists/positiveNegativeOptions";

export interface InactivateAggregateFormInputs {
  legalControlRisk: 'yes' | 'no'
  patrimoniesRefund: 'yes' | 'no'
  bhInactivation: 'yes' | 'no'
  hasOpenProvisionAdjustComunication: 'yes' | 'no'
  contractDismissalAttachment: FileList
}

export function InactivateAggregateForm() {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext<InactivateAggregateFormInputs>()

  const [contractDismissalAttachment] = useWatch({
    control,
    name: ['contractDismissalAttachment']
  })

  return (
    <Flex direction="column" gap={6} align="center">
      <Stack
        w='full'
        direction='row'
      >
        <Radio
          {...register('legalControlRisk')}
          name='legalControlRisk'
          radioOptions={positiveNegativeOptions}
          label='Controle risco jurídico?'
          error={errors.legalControlRisk}
          required
        />
        <Radio
          {...register('patrimoniesRefund')}
          name='patrimoniesRefund'
          radioOptions={positiveNegativeOptions}
          label='Devolução de patrimônios?'
          error={errors.patrimoniesRefund}
          required
        />
      </Stack>

      <Radio
        {...register('bhInactivation')}
        name='bhInactivation'
        radioOptions={positiveNegativeOptions}
        label='Inativação plataforma BH ocupacional?'
        error={errors.bhInactivation}
        required
      />
      <Radio
        {...register('hasOpenProvisionAdjustComunication')}
        name='hasOpenProvisionAdjustComunication'
        radioOptions={positiveNegativeOptions}
        label='Abertura comunicação financeiro ajuste provisão?'
        error={errors.hasOpenProvisionAdjustComunication}
        required
      />
      <Input
        {...register('contractDismissalAttachment')}
        name='contractDismissalAttachment'
        type='file'
        hidden
        label='Anexo Rescisão Contratual'
        error={errors.contractDismissalAttachment}
      >
        <Flex
          align='center'
        >
          <Button
            leftIcon={<Icon as={FiFile} />}
            as={FormLabel}
            htmlFor='contractDismissalAttachment'
            h='48px'
            _hover={{
              cursor: 'pointer'
            }}
            size='sm'
          >
            Escolha um arquivo
          </Button>
          {contractDismissalAttachment && contractDismissalAttachment.length > 0 && (
            <ChakraLink
              href={URL.createObjectURL(contractDismissalAttachment[0])}
              isExternal
            >
              {contractDismissalAttachment[0].name} (Visualizar)
            </ChakraLink>
          )}
        </Flex>
      </Input>
    </Flex>
  )

}
