import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface ConfirmCollectsTotalVolumesAndInputsBody {
  collectsTotalVolumes: number
  hasChangeInputs: boolean
  inputs: {
    name: string
    quantity: number
  }[]
}

interface ConfirmCollectsTotalVolumesAndInputsProps {
  routeParams: {
    serviceId: string
  }
  body: ConfirmCollectsTotalVolumesAndInputsBody
}

export async function confirmCollectsTotalVolumesAndInputs({
  routeParams,
  body
}: ConfirmCollectsTotalVolumesAndInputsProps) {
  try {
    const { data } = await api.patch(`/collect-service/${routeParams.serviceId}/confirm-volumes`, body)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
