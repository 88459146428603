import { Flex, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface IKanbanCardHeaderProps {
  protocol: number
  serviceId: string
}

export function KanbanCardHeader({ protocol, serviceId }: IKanbanCardHeaderProps) {

  return (
    <Flex
      align="flex-start"
      justify="center"
      bg="blue.300"
      borderRadius="8px 8px 0 0"
    >
      <Link to={`/servico/detalhes/${serviceId}`}>
        <Text
          color="white"
          fontWeight="bold"
          lineHeight={1.8}
          transition="0.3s"
          _hover={{
            opacity: 0.8
          }}
        >
          {protocol}
        </Text>
      </Link>
    </Flex>
  )
}
