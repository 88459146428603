export class RequestError extends Error {
  public message: string
  public httpCode: number;

  constructor(message: string, httpCode?: number) {
    super(message)
    this.message = message
    this.httpCode = httpCode ?? 500
  }
}
