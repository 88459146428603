import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Flex, ModalBody, ModalContent, ModalHeader, Button, Link, Text, Spinner, TableContainer, Table, Tbody, Tr, Td, ModalCloseButton, Input, FormControl, FormLabel, RadioGroup, Stack, Radio } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { getPurchaseOrder, PurchaseOrderQuoteProviders } from "api/purchaseOrders/getPurchaseOrder";
import { useMemo } from "react";
import { handleChangeUrl } from "utils/handleChangeUrl";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { usePurchaseOrdersSocket } from "hooks/purchaseOrder/usePurchaseOrdersSocket";
import { GetPurchaseOrdersResponse } from "api/purchaseOrders/getPurchaseOrders";
import { approvePurchaseOrder } from "api/purchaseOrders/approvePurchaseOrder";
import { useToastify } from "hooks/toastify/useToastify";
import { RadioOption } from "components/Inputs/RadioInput";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";


interface PurchaseOrderProviderOptions extends RadioOption {
  url?: string
}

interface ApprovePurchaseOrderProps {
  purchaseOrderId: string
  onCloseModal: () => void
}

export interface ApprovePurchaseOrderFormInputs {
  approvedProvider: string
  purchaseApprovedValue: string
}

const approvePurchaseOrderSchema = yup.object().shape({
  approvedProvider: yup.mixed().required('Campo obrigatório'),
  purchaseApprovedValue: yup.number()
    .typeError('Campo obrigatório')
    .required('Campo obrigatório')
    .transform((value, originalValue) => Math.ceil(transformStringToNumber(originalValue) * 100)),
})

export function ApprovePurchaseOrder({
  purchaseOrderId,
  onCloseModal
}: ApprovePurchaseOrderProps) {

  const { promiseMessage } = useToastify()

  const { data: purchaseOrderData, isLoading: isPurchaseOrderDataLoading } = useQuery({
    queryKey: ['purchaseOrder', purchaseOrderId],
    queryFn: () => getPurchaseOrder({ purchaseOrderId })
  })

  const purchaseOrder = purchaseOrderData?.purchaseOrder
  const isPurchaseOrderTypePmc = purchaseOrder?.purchase_description === 'PMC'

  const { purchaseOrderSocketConnection } = usePurchaseOrdersSocket()

  const purchaseOrderProvidersRadioOptions = useMemo(() => {
    if (purchaseOrderData) {

      const purchaseOrderQuoteProviders: PurchaseOrderQuoteProviders[] =
        JSON.parse(purchaseOrderData?.purchaseOrder.quote_providers)

      const options: PurchaseOrderProviderOptions[] = purchaseOrderQuoteProviders?.map((quoteProvider, index) => {
        return {
          key: String(index),
          value: quoteProvider.providerName.toUpperCase(),
          showOption: quoteProvider.providerName,
          url: handleChangeUrl(quoteProvider.budgetAttachment)
        }
      })

      if (options) {
        return [
          ...options,
          {
            key: String(options.length + 1),
            value: 'NENHUM',
            showOption: 'NENHUM'
          }
        ]
      }
    }

    return []
  }, [purchaseOrderData])


  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<ApprovePurchaseOrderFormInputs>({
    resolver: yupResolver(approvePurchaseOrderSchema)
  })

  const queryClient = useQueryClient()

  const { mutateAsync: approvePurchaseOrderFn } = useMutation({
    mutationFn: approvePurchaseOrder,
    onSuccess(_data, { input, purchaseOrderId }) {
      const cachedPurchaseOrders = queryClient.getQueriesData<GetPurchaseOrdersResponse>({
        queryKey: ['purchaseOrders']
      })
      queryClient.invalidateQueries(['purchaseOrders'])
      cachedPurchaseOrders.forEach(([cachedKey, cachedData]) => {
        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          purchaseOrders: cachedData.purchaseOrders.map((purchaseOrder) => {
            if (purchaseOrder.id === purchaseOrderId) {
              return {
                ...purchaseOrder,
                status: purchaseOrderData?.purchaseOrder.status

              }
            }

            return purchaseOrder
          })
        })
      })

      onCloseModal()
    }
  })


  async function handleApprovePurchaseOrder(values: ApprovePurchaseOrderFormInputs) {
    await promiseMessage(approvePurchaseOrderFn({
      purchaseOrderId,
      input: {
        approvedProvider: values.approvedProvider === 'NENHUM'
          ? null
          : values.approvedProvider,
        purchaseApprovedValue: values.purchaseApprovedValue
      }
    }, {
      onSuccess: async (data) => {
        purchaseOrderSocketConnection.emit('purchaseOrderApproved', {
          purchaseOrderProtocol: data?.purchaseOrder?.protocol,
          quotedBy: data.purchaseOrder.quoted_by,
        })
      }
    }), "Aprovação finalizada com sucesso! 🎉")
  }


  return (
    <ModalContent maxW='500px'>
      <ModalHeader letterSpacing="tight">Aprovar Cotação de Pedido de Compra</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as='form'
        onSubmit={handleSubmit(handleApprovePurchaseOrder)}
        maxH='600px'
        overflowY='scroll'
      >
        {isPurchaseOrderDataLoading ? (
          <Spinner />
        ) : (
          <>
            <TableContainer>
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>Protocolo</Td>
                    <Td isNumeric>{purchaseOrder.protocol}</Td>
                  </Tr>
                  <Tr>
                    <Td>Tipo</Td>
                    <Td isNumeric>{purchaseOrder.type}</Td>
                  </Tr>
                  <Tr>
                    <Td>Descrição da compra</Td>
                    <Td isNumeric>{purchaseOrder?.purchase_description}</Td>
                  </Tr>

                  {!isPurchaseOrderTypePmc && (
                    <Tr>
                      <Td>Motivo da compra</Td>
                      <Td isNumeric alignSelf='flex-end' display="flex" justifyContent="flex-end">
                        <Text w='220px' whiteSpace="pre-line">{purchaseOrder?.purchase_reason}</Text>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            <Flex mt='4' direction="column" gap={6} align="center">
              <FormControl isInvalid={!!errors?.purchaseApprovedValue}>
                <FormLabel fontSize="sm">
                  Valor Aprovado na compra
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('purchaseApprovedValue')}
                  name="purchaseApprovedValue"
                  placeholder="Ex.: 15"
                  size="sm"
                  borderRadius="md"
                  w={["full", "full", "min"]}
                />
              </FormControl>

              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormLabel fontSize="sm">
                  Cotação aprovada
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <FormControl isInvalid={!!errors.approvedProvider}>
                  <RadioGroup >
                    {purchaseOrderProvidersRadioOptions.map((purchaseOrder) => (
                      <Stack direction="column">
                        <Radio
                          {...register('approvedProvider')}
                          name="approvedProvider"
                          size='sm'
                          rounded='md'
                          mr='7'
                          key={purchaseOrder.key}
                          value={purchaseOrder.value}>
                          {purchaseOrder.showOption}
                        </Radio>
                      </Stack>
                    ))}
                  </RadioGroup>

                </FormControl>
              </Stack>
              {purchaseOrderProvidersRadioOptions.map(purchaseOrderAttachment => {
                return (
                  <Link
                    key={purchaseOrderAttachment.url}
                    href={purchaseOrderAttachment.url}
                    isExternal
                    alignSelf='flex-start'
                  >
                    {purchaseOrderAttachment.url && (
                      <Text as='b'>{purchaseOrderAttachment.showOption} (Visualizar cotação)</Text>
                    )}
                  </Link>
                );
              })}
            </Flex></>

        )}
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Aprovar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
