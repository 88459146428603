import { Box, Flex } from "@chakra-ui/react";
import { PatrimonyRequestStatus as PatrimonyRequestStatusTypes } from "api/patrimonies/patrimonyRequest";
import { requestPatrimonyStatusMap } from "utils/patrimonyMappers";


interface PatrimonyRequestStatusProps {
  status: PatrimonyRequestStatusTypes;
}

export function PatrimonyRequestStatus({ status }: PatrimonyRequestStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {(status === 'requested' || status === 'returning') && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {(status === 'active' || status === 'returned') && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}


      {requestPatrimonyStatusMap[status]}
    </Flex>
  )
}
