import {
  Flex,
  Box,
  Heading,
  Divider,
  Stack,
  Button,
  HStack,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ListButton } from '../../../../components/Buttons/ListButton'
import { Input } from '../../../../components/Inputs/Input'
import { GeneralContentLoading } from '../../../../components/Loading/GeneralContentLoading'

import { useBranch } from '../../../../hooks/branch/useBranch'
import { useContentLoading } from '../../../../hooks/loading/useContentLoading'
import { useService } from '../../../../hooks/services/service'

interface IQueryParams {
  service_id: string
}

export function LandingServicesToEdit() {
  const { service_id }: IQueryParams = useParams()
  const { isContentLoading, handleSetIsContentLoadingFalse } =
    useContentLoading()

  const {
    service: { data: service, isLoading: isServiceLoading },
  } = useService(service_id, false, false)
  const {
    branches: { data: branches, isLoading: isBranchLoading },
  } = useBranch(null, true, false)

  useEffect(() => {
    if (!isServiceLoading && !isBranchLoading) {
      handleSetIsContentLoadingFalse()
    }
  }, [isServiceLoading, isBranchLoading, handleSetIsContentLoadingFalse])

  if (isContentLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex>
        <Box flex="1" borderRadius="8px" bg="white" p={['6', '8']}>
          <Heading size="lg" fontFamily="poppins">
            Editar Serviços Desembarcados
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          {service?.serviceIDLanding.map((landingService) => (
            <Stack
              key={landingService.id}
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'column']}
            >
              <Input
                defaultValue={`${landingService.landing_volume}`}
                isDisabled={true}
                name="volume"
                label="Volume Desembarcado"
              />

              {branches
                ?.filter((branch) => branch.id === landingService.branch_id)
                .map((branch) => (
                  <Input
                    defaultValue={`${branch.nickname} - ${branch.street} - ${branch.city}`}
                    isDisabled={true}
                    key={branch.id}
                    name="branch"
                    label="Base"
                  />
                ))}

              <Link
                to={{
                  pathname: `/servicos/desembarques/andamento/${landingService.service_id}`,
                  state: {
                    collector_id: landingService.collector_id,
                  },
                }}
              >
                <Button type="button" colorScheme="blue" w="100%">
                  Editar Desembarque
                </Button>
              </Link>
              <Divider my="6" borderColor="gray.700" />
            </Stack>
          ))}

          <Flex mt="8" justify="flex-end">
            <HStack>
              <ListButton
                href="/servicos/desembarques"
                name="Seriços à Desembarcar"
              />
            </HStack>
          </Flex>
        </Box>
      </Flex>

  )
}
