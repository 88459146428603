import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface FormField {
  label: string
  value: string
  type: string
}

export interface AnalysisFormDataItem {
  [key: string]: FormField
}


interface AnalyzeSupportBody {
  deadline: string
  internSupport: {
    analysisFormData?: AnalysisFormDataItem
  }
}

interface AnalyzeSupportProps {
  body: AnalyzeSupportBody
  supportId: string

}
export async function analyzeSupport({
  body,
  supportId
}: AnalyzeSupportProps) {
  try {
    const response = await api.put(`/supports/${supportId}/analyze`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
