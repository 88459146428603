import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Stack,
  Grid,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { ServiceProps } from '../../../../contexts/ServiceContext'
import { Input } from '../../../Inputs/Input'

interface IServicesToAddAddressModalProps {
  isModalOpen: boolean
  servicesToAddAddress: ServiceProps[]
  onClose: () => void
}

export function ServicesToAddAddressModal({
  isModalOpen,
  servicesToAddAddress,
  onClose,
}: IServicesToAddAddressModalProps) {
  return (
    <Modal
      size="2xl"
      blockScrollOnMount={false}
      isOpen={isModalOpen}
      onClose={onClose}
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Serviço (s)</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)','repeat(2, 1fr)']} w="full" p="2" gap={6}>
            {servicesToAddAddress.map((service) => (
              <Stack mt="2" w="full" key={service.id}>
                <Input
                  size="md"
                  name="service"
                  label="Serviço"
                  defaultValue={`${service.protocol}`}
                  isDisabled={true}
                />
                <Stack>
                  <Link to={`/servico/detalhes/${service.id}`}>
                    <Button size="sm" w="full" mr="3" type="button" colorScheme="blue">
                      Adicionar Endereço
                    </Button>
                  </Link>
                </Stack>
              </Stack>
            ))}
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
