import { Box, Flex } from "@chakra-ui/react";
import { TrainingLlmStatus as TrainingLlmStatusTypes } from "api/trainingLlm/trainingLlm";

export const trainingLlmMStatusMap: Record<TrainingLlmStatusTypes, string> = {
  pending: 'Pendente',
  approved: 'Aprovado',
  reproved: 'Reprovado'
}

interface TrainingLlmStatusProps {
  status: TrainingLlmStatusTypes;
}

export function TrainingLlmStatus({ status }: TrainingLlmStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {status === 'pending' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'approved' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'reproved' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {trainingLlmMStatusMap[status]}
    </Flex>
  )
}
