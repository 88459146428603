import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

export async function deleteCustomer(customerId: string) {
  try {
    await api.delete(`/customer/${customerId}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
