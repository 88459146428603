
import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Document } from "./types/Document";

interface GetDocumentResponse {
  document: Document & {
    documentAttachments: {
      id: string
      version: number
      status: 'active' | 'inactive'
      created_at: string
      updated_at: string
      createdAt: string
      updatedAt: string
      attachment_id: string
      document_id: string
      attachment: Attachment
    }[]
    updatedBy: {
      id: string
      firstname: string
      lastname: string
  }
  }
}

interface GetDocumentProps {
  routeParams: {
    documentId: string
  }
}

export async function getDocument({
  routeParams
}: GetDocumentProps) {
  try {
    const response = await api.get<GetDocumentResponse>(`/documents/${routeParams.documentId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
