import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface EditPatrimonyBody {
  brand: string
  identifier: number
  valueInCents: number
}

interface EditPatrimonyParams {
  body: EditPatrimonyBody,
  patrimonyTypeId: string
  patrimonyId: string
}

export async function editPatrimony({
  body,
  patrimonyTypeId,
  patrimonyId
}: EditPatrimonyParams) {
  try {

    await api.put(`/patrimony-types/${patrimonyTypeId}/patrimonies/${patrimonyId}`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

