import { Box, FormLabel, Text, VStack } from "@chakra-ui/react";
import { getSupportSubject } from "api/supports/getSupportSubject";
import { AttachmentTableRowField } from "pages/Support/AttachmentTableRowField";
import { useEffect, useReducer } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useQuery } from "react-query";
import { CreateSupportSchema } from "../CreateSupport";
import { AttachmentState, SetAttachmentAction } from "../InternalSupportForm";
import { AdjustmentRequestForm } from "./AdjustmentRequestForm";
import { AppUsageForm } from "./AppUsageForm";
import { LegalAgreementForm } from "./LegalAgreementForm";
import { LogLifeCreditCardForm } from "./LogLifeCreditCardForm";
import { PaymentForecastInquiryForm } from "./PaymentForecastInquiryForm";
import { PaymentReceiptForm } from "./PaymentReceiptForm";
import { PaymentRequestForm } from "./PaymentRequestForm";
import { RpaRequestForm } from "./RpaRequestForm";
import { UberRequestForm } from "./UberRequestForm";

interface CreateAccountPayableProps {
  reducer: (state: AttachmentState[], action: SetAttachmentAction) => AttachmentState[];
}

export function CreateAccountPayable({ reducer }: CreateAccountPayableProps) {

  const { control, setValue } = useFormContext<CreateSupportSchema>()
  const [attachments, setAttachments] = useReducer(reducer, [] as AttachmentState[])

  useEffect(() => {
    if (attachments.length !== 0) {
      setValue(`formData.attachments.label`, 'Anexos');
      setValue(`formData.attachments.value`, JSON.stringify(attachments));
      setValue(`formData.attachments.type`, 'attachments');
    }
  }, [attachments, setValue])

  const {
    fields: attachmentsFields,
    append: appendAttachmentsField,
    remove: removeAttachmentsField,
  } = useFieldArray({
    control,
    name: 'attachments'
  })

  const [supportSubjectId, attachmentsSelected, collectorAdjustment] = useWatch({
    control,
    name: ['subject', 'attachments', 'formData.isCollectorAdjustment.value']
  })

  const {
    data: supportSubjectResult
  } = useQuery({
    queryFn: () => getSupportSubject({ supportSubjectId }),
    queryKey: ['support-subject', supportSubjectId],
    enabled: !!supportSubjectId
  })


  const isCollectorAdjustment = collectorAdjustment === 'Sim'

  const subjectIsPaymentReceipt = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Comprovante de pagamento'.toLowerCase()
  const subjectIsPaymentForecastInquiry = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Consulta previsão de pagamento'.toLowerCase()
  const subjectIsPaymentRequest = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Solicitação de pagamento'.toLowerCase()
  const subjectIsAppUsage = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Utilização de aplicativos (Uber/99)'.toLowerCase()
  const subjectIsLogLifeCreditCard = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Cartão de crédito LogLife'.toLowerCase()
  const subjectIsUberRequest = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Solicitação de Uber'.toLowerCase()
  const subjectIsAdjustmentRequest = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Solicitação de reajuste'.toLowerCase()
  const subjectIsLegalAgreement = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Acordo jurídico'.toLowerCase()

  const subjectIsRpaRequest = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Solicitação de RPA'.toLowerCase()


  return (
    <Box w='full'>

      {subjectIsPaymentReceipt && (
        <PaymentReceiptForm />
      )}

      {subjectIsPaymentForecastInquiry && (
        <PaymentForecastInquiryForm />
      )}
      {subjectIsAdjustmentRequest && (
        <AdjustmentRequestForm />
      )}

      {(subjectIsPaymentRequest || subjectIsAppUsage || subjectIsLogLifeCreditCard || isCollectorAdjustment || subjectIsLegalAgreement) && (
        <>
          <FormLabel fontSize='sm' mt={3}>
            {isCollectorAdjustment ? 'Anexo Comprovação do Reajuste' : 'Anexar NF/Boleto/Documento/Comprovante/Outro'}

            <Text as="sup" color="red.500">*</Text>
          </FormLabel><VStack spacing={3}>
            {attachmentsFields.map((field, index) => {

              const isFilledField = Boolean(attachmentsSelected[index]?.file?.length);
              return (
                <AttachmentTableRowField
                  key={field.id}
                  index={index}
                  onSetAttachmentId={setAttachments}
                  isFilledField={isFilledField}
                  onAppendField={() => appendAttachmentsField({})}
                  onRemoveField={() => removeAttachmentsField(index)} />
              );
            })}
          </VStack>
        </>
      )}

      {subjectIsPaymentRequest && (
        <PaymentRequestForm />
      )}

      {subjectIsAppUsage && (
        <AppUsageForm />
      )}

      {subjectIsLogLifeCreditCard && (
        <LogLifeCreditCardForm />
      )}

      {subjectIsUberRequest && (
        <UberRequestForm />
      )}

      {subjectIsLegalAgreement && (
        <LegalAgreementForm />
      )}

      {subjectIsRpaRequest && (
        <RpaRequestForm />
      )}
    </Box>
  )
}
