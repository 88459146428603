import { Box, Button, Flex, FormControl, FormLabel, Heading, IconButton, Input, Link, Progress, Stack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { useForm, useWatch } from "react-hook-form"
import { validateHasFile } from "utils/fileValidation";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { ChangeEvent } from "react";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { finishTrainingOnboarding } from "api/onboardings/finishTrainingOnboarding";
import { GetOnboardingsResponse } from "api/onboardings/getOnboardings";

interface TrainingOnboardingOnboardingProps {
  onboardingId: string
  isTrainingLlm: boolean
}

interface TrainingOnboardingOnboardingSchema {
  certificateId: FileList
  leaderEmail: string
}

const trainingOnboardingOnboardingSchema = yup.object({
  certificateId: yup.mixed().test(value => validateHasFile(value)).required(),
  leaderEmail: yup.mixed().when("$isTrainingLlm", {
    is: true,
    then: yup.string().email().required()
  })
})

export function TrainingOnboarding({ onboardingId, isTrainingLlm }: TrainingOnboardingOnboardingProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const { mutation: uploadCertificateFn, uploadProggress: uploadProggressCertificate } = useAttachmentMutation()

  const { mutateAsync: finishTrainingOnboardingFn } = useMutation({
    mutationFn: finishTrainingOnboarding,
    onSuccess(_data, { body, onboardingId }) {
      const cachedOnboardings = queryClient.getQueriesData<GetOnboardingsResponse>({
        queryKey: ['onboardings']
      })
      queryClient.invalidateQueries(['onboardings'])
      queryClient.invalidateQueries({ queryKey: 'inviting-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'training-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'testing-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'finished-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'inactive-onboardings' })
      queryClient.invalidateQueries({ queryKey: ['onboarding', onboardingId]})

      cachedOnboardings.forEach(([cachedKey, cachedData]) => {
        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          onboardings: cachedData.onboardings.map((onboarding) => {
            if (onboarding.id === onboardingId) {
              return {
                ...onboarding,
              }
            }

            return onboarding
          })
        })
      })
    }
  })

  const {
    control,
    handleSubmit,
    register,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<TrainingOnboardingOnboardingSchema>({
    resolver: yupResolver(trainingOnboardingOnboardingSchema),
    context: { isTrainingLlm: isTrainingLlm }
  })


  const [certificateId] = useWatch({
    control,
    name: ['certificateId']
  })



  async function handleUploadCertificate(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadCertificateFn.mutateAsync(formData)

  }

  async function handleTrainingOnboarding({ leaderEmail }: TrainingOnboardingOnboardingSchema) {
    await promiseMessage(finishTrainingOnboardingFn({
      body: {
        certificateId: uploadCertificateFn?.data?.attachment?.id,
        leaderEmail: leaderEmail
      },
      onboardingId: onboardingId
    }), 'Onboarding finalizado! 🎉')
  }

  return (
    <Box
      w="full"
      as="form"
      maxH='500px'
      overflowY='scroll'
      onSubmit={handleSubmit(handleTrainingOnboarding)}
    >
      <Heading letterSpacing="tight" size='sm'>Treinamento</Heading>
      <>
        <Stack
          direction="column"
          w="full"
          mt="3"
        >
          <Button
            as={FormLabel}
            htmlFor="certificateId"
            lineHeight="1"
            leftIcon={<FaFileImport />}
            size="sm"
            w="min"
            cursor="pointer"
            border={!!errors?.certificateId && '2px solid'}
            borderColor={(!!errors?.certificateId) && 'red.500'}
          >
            Certificado
          </Button>
          <FormControl isInvalid={!!errors?.certificateId}>
            <Input
              {...register('certificateId')}
              name="certificateId"
              id="certificateId"
              type="file"
              hidden
              onChangeCapture={handleUploadCertificate}
            />
          </FormControl>
        </Stack>

        {certificateId && (
          <Table size="sm">
            <Tbody>
              {Object.entries(certificateId).map(([key, file]) => {
                return (
                  <Tr key={key}>
                    <Td fontSize="xs" maxW="100px">{file.name}</Td>
                    <Td fontSize="xs" w="200px">
                      <Progress size="sm" rounded="md" value={uploadProggressCertificate} />
                    </Td>
                    <Td fontSize="xs" isNumeric>
                      {uploadCertificateFn.data && (
                        <IconButton
                          aria-label="Visualizar anexo"
                          as={Link}
                          size="sm"
                          icon={<FaExternalLinkAlt />}
                          href={uploadCertificateFn.data.attachment.link}
                          isExternal
                        />
                      )}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        )}
      </>
      {isTrainingLlm && (
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors?.leaderEmail}>
              <FormLabel fontSize="sm">
                E-mail líder direto
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('leaderEmail')}
                name="leaderEmail"
                size="sm"
                rounded="md"
                type='email'
              />
            </FormControl>
          </Stack>
        </Stack>
      )}

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
