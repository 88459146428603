import { Button, Flex, IconButton, Td, Text, Tr, Link as ChakraLink, Tooltip, Box, Icon } from "@chakra-ui/react";
import { Service } from "api/service/getDeliveriesRelation";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { FaArrowRight, FaInfoCircle, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { formatDate } from "utils/DateFunctions/formatDate";
import { formatTimeByTimezoneUser } from "utils/formatTimeByTimezoneUser";
import { serviceDelay } from "utils/serviceDelay";
import { serviceHandleStep } from "utils/ServiceFunctions/serviceDict";

interface DeliveriesTableRowPros {
  service: Service
}

export function DeliveriesTableRow({ service }: DeliveriesTableRowPros) {
  const { userLogged } = useAuth()
  const userTimezone = new Date().getTimezoneOffset() / 60

  const destinationHubTimezone = Boolean(service?.sourceAddresses?.length)
  ? service?.sourceAddresses[0].city.hub.timezone
  : null

  const isUserTimezoneDifferentFromSourceHubTimezone = userTimezone !== Number(destinationHubTimezone?.split('-')[1])

  const serviceInDelay = serviceDelay({
    timezoneOffset: destinationHubTimezone,
    serviceDate: service?.requestedService?.deliveryDate,
    serviceHour: service?.requestedService?.deliveryHour
  })


  const userCanDoServiceMaintenance = userLogged?.permissions.includes('view-maintenance-service')
  const userCanValidateFiscalRetention = userLogged?.permissions.includes('validate-fiscal-retention')
  const userCanDoServiceLanding = userLogged?.permissions.includes('add-landing-service')
  const userCanAvailableService = userLogged?.permissions.includes('add-available-service')
  const userCanAllocateService = userLogged?.permissions.includes('add-allocated-service')
  const userCanValidateBoardService = userLogged?.permissions.includes('valid-boarding-service')
  const userCanDeliveryAddress = userLogged?.permissions.includes('add-delivery-service')

  const pathRedirect = '/relacao/servicos?tab=deliveries&page=1'

  return(
    <Tr
    key={service.protocol}
    color={service.hasOccurrence ? 'yellow.400' : serviceInDelay ? 'red.400' : ''}
  >
    <Td>
      <Flex align="center" gap="3">
        <IconButton
          as={Link}
          to={`/servico/detalhes/${service.id}`}
          aria-label="Detalhes do serviço"
          size="sm"
          icon={<FaSearch />}
          variant="ghost"
        />

        {(service.step === 'toBoardValidate' && userCanValidateBoardService) && (
          <Button
            as={Link}
            size="xs"
            lineHeight="1"
            leftIcon={<FaArrowRight />}
            rounded="md"
            variant="ghost"
            to={{
              pathname: `/servicos/embarques/validar/${service.id}`,
              state: {
                path: pathRedirect
              }
            }}
          >
            Validar embarque
          </Button>
        )}

        {(service.step === 'toAllocateService' && userCanAllocateService) && (
          <Button
            as={Link}
            size="xs"
            lineHeight="1"
            leftIcon={<FaArrowRight />}
            rounded="md"
            variant="ghost"
            to={{
              pathname: `/servicos/alocar/${service.id}`,
              state: {
                path: pathRedirect
              }
            }}
          >
            Alocar
          </Button>
        )}

        {(service.step === 'toAvailableService' && userCanAvailableService) && (
          <Button
            as={Link}
            size="xs"
            lineHeight="1"
            leftIcon={<FaArrowRight />}
            rounded="md"
            variant="ghost"
            to={{
              pathname: `/servicos/disponibilizar/desembarque/${service.id}`,
              state: {
                path: pathRedirect
              }
            }}
          >
            Disponibilizar
          </Button>
        )}

        {(['toLandingService', 'landingService'].includes(service.step) && userCanDoServiceLanding) && (
          <Button
            as={Link}
            size="xs"
            lineHeight="1"
            leftIcon={<FaArrowRight />}
            rounded="md"
            variant="ghost"
            to={{
              pathname: `/servicos/desembarques/${service.id}`,
              state: {
                path: pathRedirect
              }
            }}
          >
            Desembarcar
          </Button>
        )}

        {(['toMaintenanceService', 'ongoingMaintenanceService'].includes(service.step) && userCanDoServiceMaintenance) && (
          <Button
            as={Link}
            size="xs"
            lineHeight="1"
            leftIcon={<FaArrowRight />}
            rounded="md"
            variant="ghost"
            to={{
              pathname: `/servico/manutencao/${service.id}`,
              state: {
                path: pathRedirect
              }
            }}
          >
            Realizar manutenção
          </Button>
        )}

        {(service.step === 'toValidateFiscalRetention' && userCanValidateFiscalRetention) && (
          <Button
            as={Link}
            size="xs"
            lineHeight="1"
            leftIcon={<FaArrowRight />}
            rounded="md"
            variant="ghost"
            to={{
              pathname: `/servico/retencao-fiscal/validar/${service.id}`,
              state: {
                path: pathRedirect
              }
            }}
          >
            Validar retenção fiscal
          </Button>
        )}

        {(['toDeliveryService', 'deliveringService'].includes(service.step) && userCanDeliveryAddress) && (
          <Button
            as={Link}
            size="xs"
            lineHeight="1"
            leftIcon={<FaArrowRight />}
            rounded="md"
            variant="ghost"
            to={{
              pathname: `/servicos/entregas/${service.id}`,
              state: {
                path: pathRedirect
              }
            }}
          >
            Entregar
          </Button>
        )}
      </Flex>
    </Td>
    <Td>{service.protocol}</Td>
    <Td>
      <Text maxW="300px" whiteSpace="pre-line">
        {service.customer.tradingFirstname}
      </Text>
    </Td>
    <Td>
      {service?.requestedService?.serviceType === 'DEDICADO' ? (
        service?.sourceAddresses?.map((address, index) => (
          <Text w="300px" key={index} whiteSpace="pre-line">
            {service.sourceAddresses.length > 1 && index + 1 + '.'} {address.tradingName} - {address.branch}: {address.street}, {address.neighborhood} - {address.city.name} | {address.responsibleName}
          </Text>
        ))
      ) : (
        <Text w="300px" whiteSpace="pre-line">
          Material/Materiais de {' '}
          {service?.sourceAddresses.map(address => address.city.name).join(', ')}
        </Text>
      )}
    </Td>
    <Td>
      {service?.requestedService?.serviceType === 'DEDICADO' ? (
        'Serviço Dedicado'
      ) : (
        <>
          {`Retirada de ${service?.board?.boardVolume ?? 'Não informado/encontrado'} vol(s).na ${' '}`}
          <br />
          {`${service?.requestedService?.shipping?.tradingName} - ${service?.requestedService?.destinationBranch?.nickname} ${' '}`}
          <br />
          {`CTe: ${service?.board?.cte ?? 'Não informado/encontrado'},`}
          <br />
          {service?.board?.trackerLink ? (
            <>
              Rastreador: <ChakraLink
                href={service?.board?.trackerLink}
                isExternal
              >
                {service?.board?.tracker}
              </ChakraLink>
            </>
          ) : (
            <>
              {`Rastreador: ${service?.board?.tracker ?? 'Não informado/encontrado'}`}

            </>
          )}
        </>
      )}
    </Td>
    <Td>
      {destinationHubTimezone ? (
        <Flex>
          {format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service?.requestedService?.deliveryDate), timezoneOffset: destinationHubTimezone }), 'dd/MM/yyyy')}

          {Number(destinationHubTimezone.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
            <Tooltip label={destinationHubTimezone}>
              <Box as="span" ml="2">
                <Icon as={FaInfoCircle} />
              </Box>
            </Tooltip>
          )}
        </Flex>
      ) : (
        formatDate.handle(service?.requestedService?.deliveryDate, "DateWithoutHourToShow")
      )}
    </Td>
    <Td>
      {destinationHubTimezone ? (
        <Flex>
          {format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service?.requestedService?.deliveryHour), timezoneOffset: destinationHubTimezone }), 'HH:mm')}
          {isUserTimezoneDifferentFromSourceHubTimezone && (
            <Tooltip label={destinationHubTimezone}>
              <Box as="span" ml="2">
                <Icon as={FaInfoCircle} />
              </Box>
            </Tooltip>
          )}
        </Flex>
      ) : (
        formatDate.handle(service?.requestedService?.deliveryHour, "DateOnlyWithHourMinute")
      )}
    </Td>
    <Td>
      {service?.destinationAddresses?.map((address, index) => {
        const doesAddressAlreadyDelivered = service?.deliveries?.find(
          (delivery) => delivery.addressId === address.id && ['DONE', 'UNSUCCESS'].includes(delivery.step)
        )


        if (doesAddressAlreadyDelivered) {
          return (
            <Text as="del" w="300px" key={index} whiteSpace="pre-line">
              {service.destinationAddresses.length > 1 && index + 1 + '.'} {address.tradingName} - {address.branch}: {address.street}, {address.neighborhood} - {address.city.name} | {address.responsibleName}
            </Text>
          )
        }

        if (['toDeliveryService', 'deliveringService'].includes(service.step)) {
          return (
            <Text w="300px" key={index} whiteSpace="pre-line" _hover={{
              opacity: 0.7
            }}>
              <Link to={{
                pathname: `/servicos/entregas/${service.id}/enderecos/${address.id}`,
                state: {
                  path: pathRedirect
                }
              }}>
                {service.destinationAddresses.length > 1 && index + 1 + '.'} {address.tradingName} - {address.branch}: {address.street}, {address.neighborhood} - {address.city.name} |{address.responsibleName}
              </Link>
            </Text>
          )
        }


        return (
          <Text w="300px" key={index} whiteSpace="pre-line">
            {service.destinationAddresses.length > 1 && index + 1 + '.'} {address.tradingName} - {address.branch}: {address.street}, {address.neighborhood} - {address.city.name} |{address.responsibleName}
          </Text>
        )
      }
      )}
    </Td>
    <Td>{serviceHandleStep(service.step)}</Td>
    <Td>{service?.requestedService?.sourceCollector.tradingName}</Td>
    <Td>{service?.requestedService?.destinationCollector.tradingName}</Td>
  </Tr>
  )
}
