
import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface UpdatePricesByCsvProps {
  body: FormData
}

export async function updatePricesByCsv({
  body
}: UpdatePricesByCsvProps) {
  try {
    await api.post('/prices/update-by-csv', body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

