import { Box, Divider, Flex, Grid, GridItem, Heading, Image, Text, useMediaQuery } from "@chakra-ui/react"
import loginBanner from "../../assets/images/login-banner.jpg"
import loglifeIcon from "../../assets/images/loglife_icon.jpg"
import { LoginForm } from "./components/LoginForm"

export function Login() {
  const [isWideVersion] = useMediaQuery('(min-width: 800px)')

  return (
    <Box
      h="calc(100vh)"
      w="calc(100vw)"
      display="flex"
      p="4"
      alignItems="center"
      justify-content="center"
    >
      {!isWideVersion && (
        <Image
          src={loginBanner}
          w="full"
          h="full"
          m="-4"
          position="absolute"
          filter="blur(12px)"
        />
      )}

      <Grid
        w="1250px"
        boxShadow="lg"
        m="0 auto"
        h="600px"
        templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]}
      >
        <GridItem
          h="full"
          position="relative"
          overflow="hidden"
          rounded="md"
        >
          <Box
            bg="white"
            w="full"
            h="full"
            py="14"
            px={["18", "20", "20"]}
            display="flex"
            flexDirection="column"
            gap="4"
          >
            <Flex
              gap="2"
              align="center"
            >
              <Image w="6" h="6" src={loglifeIcon} rounded="2xl" />
              <Heading size="md" color="gray.900">LogLife</Heading>
            </Flex>

            <Text
              color="gray.500"
              fontSize="md"
              as="b"
              mt="4"
            >
              Bem vindo de volta! Por favor, realize o login em sua conta.
            </Text>

            <Divider />

            <LoginForm />

          </Box>
        </GridItem>
        {isWideVersion && (
          <GridItem
            h="full"
            w="full"
            position="relative"
            overflow="hidden"
          >
            <Image
              h="full"
              w="full"
              position="absolute"
              src={loginBanner}
              borderRightRadius="md"
            />
          </GridItem>
        )}
      </Grid>
    </Box>
  )
}
