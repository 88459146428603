import { Box, FormControl, FormLabel, Input, Select, Stack, Text, Textarea } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { handleSwitchMask } from "utils/handleChangeMask";
import { CreateSupportSchema } from "../CreateSupport";

const accountTypesOptions = [
  'Corrente',
  'Poupança',
  'Salário',
]
export function AccountChangeForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value
    const labelMap: { [key in keyof FormDataItem]: string } = {
      bank: 'Banco',
      agency: 'Agência',
      accountNumber: 'Número da conta',
      accountType: 'Tipo da conta',
      cpf: 'CPF do titular',
      changeReason: 'Motivo da alteração',
      fullName: 'Nome completo',
    };

    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };

  return (
    <Box w='full'>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.bank}>
            <FormLabel fontSize='sm'>
              Banco
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.bank.value')}
              name='formData.bank.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('bank')}
            />
          </FormControl>

        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.agency}>
            <FormLabel fontSize='sm'>
              Agência
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.agency.value')}
              name='formData.agency.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('agency')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <FormControl isInvalid={!!errors.formData?.accountNumber}>
        <FormLabel fontSize='sm'>
          Número da conta
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.accountNumber.value')}
          name='formData.accountNumber.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('accountNumber')}
        />
      </FormControl>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.accountType} >
            <FormLabel fontSize="sm">
              Tipo da conta
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register("formData.accountType.value")}
              name="formData.accountType.value"
              placeholder="Selecione uma opção..."
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('accountType')}
            >
              {Object.entries(accountTypesOptions).map(([key, value]) => (
                <option key={key} value={value}>{value}</option>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors?.formData?.cpf}>
            <FormLabel fontSize="sm">
              CPF do titular
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.cpf.value')}
              name="formData.cpf.value"
              as={ReactInputMask as any}
              mask={handleSwitchMask('cpf')}
              size="sm"
              rounded="md"
              onChangeCapture={handleInputChange('cpf')}
            />
          </FormControl>
        </Stack>

      </Stack>

      <FormControl isInvalid={!!errors.formData?.changeReason} mt={3}>
        <FormLabel fontSize="sm">
          Motivo da alteração
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register('formData.changeReason.value')}
          name='formData.changeReason.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('changeReason')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.fullName} mt={3}>
        <FormLabel fontSize="sm">
          Nome completo
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.fullName.value')}
          name='formData.fullName.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('fullName')}
        />
      </FormControl>

    </Box>
  )

}
