import { Box, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { format } from "date-fns";
import { FaCalendarAlt, FaHubspot } from "react-icons/fa";

import { Standardization } from "api/standardizations/standardization";
import { StandardizationDetail } from "./StandardizationDetail";


interface StandardizationsKanbanCardProps {
  standardization: Standardization
}

export function StandardizationsKanbanCard({ standardization }: StandardizationsKanbanCardProps) {

  const {
    isOpen: isStandardizationDetailModalOpen,
    onToggle: onToggleStandardizationDetailModal,
  } = useDisclosure()


  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleStandardizationDetailModal}
      cursor='pointer'
    >

      <Text fontWeight="bold" mb={2}>
        {standardization?.name}
      </Text>

      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">

        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA ENVIO</Text>
          </HStack>
          <Text ml={5}>{format(new Date(standardization?.sended_at), "dd/MM/yyyy")}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaHubspot} />
            <Text fontSize="xs" fontWeight="bold">HUB</Text>
          </HStack>
          <Text ml={5}>{standardization?.hub?.name}</Text>
        </Box>

      </VStack>
      <Modal
        isOpen={isStandardizationDetailModalOpen}
        onClose={onToggleStandardizationDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <StandardizationDetail
          standardizationId={standardization.id}
        />
      </Modal>
    </Box>
  )
}
