import { Button, Flex, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { cancelAdvertence } from "api/advertences/cancelAdvertence";
import { completeAdvertence } from "api/advertences/completeAdvertence";
import { GetAdvertencesResponse } from "api/advertences/getAdvertences";
import { Advertence } from "api/advertences/types/Advertence";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { RiSearchLine } from "react-icons/ri";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { advertenceTypeMap, regionalTypeMap } from "utils/advertenceMapppers";
import { AdvertenceDetail } from "./AdvertenceDetail";
import { AdvertenceStatus } from "./AdvertenceStatus";


interface AdvertenceTableRowProps {
  advertence: Advertence
}
export function AdvertenceTableRow({ advertence }: AdvertenceTableRowProps) {
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanViewAdvertences = userLogged?.permissions?.includes('view-advertences')

  useEffect(() => {
    if (!userCanViewAdvertences) history.push('/')
  }, [history, userCanViewAdvertences])


  const userCanCompleteAdvertence = userLogged?.permissions?.includes('complete-advertence')
  const userCanCancelAdvertence = userLogged?.permissions?.includes('cancel-advertence')

  const {
    isOpen: isAdvertenceDetailModalOpen,
    onOpen: onOpenAdvertenceDetailModal,
    onClose: onCloseAdvertenceDetailModal,
  } = useDisclosure()


  const { mutateAsync: cancelAdvertenceFn } = useMutation({
    mutationFn: cancelAdvertence,
    onSuccess: (_data, { routeParams }) => {
      const cached = queryClient.getQueriesData<GetAdvertencesResponse>({
        queryKey: ['advertences']
      })
      queryClient.invalidateQueries({ queryKey: 'awaiting-application-advertences' })
      queryClient.invalidateQueries({ queryKey: 'parent-verification-advertences' })
      queryClient.invalidateQueries({ queryKey: 'cancelled-advertences' })
      queryClient.invalidateQueries({ queryKey: 'done-advertences' })
      queryClient.invalidateQueries({ queryKey: ['advertence', routeParams?.advertenceId] })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          advertences: cachedValue.advertences.map((advertence) => {
            if (advertence.id === routeParams.advertenceId) {
              return { ...advertence, status: 'canceled' }
            }

            return advertence
          })
        })
      })
    }
  })

  const { mutateAsync: completeAdvertenceFn } = useMutation({
    mutationFn: completeAdvertence,
    onSuccess: (_data, { routeParams }) => {
      const cached = queryClient.getQueriesData<GetAdvertencesResponse>({
        queryKey: ['advertences']
      })
      queryClient.invalidateQueries({ queryKey: 'awaiting-application-advertences' })
      queryClient.invalidateQueries({ queryKey: 'parent-verification-advertences' })
      queryClient.invalidateQueries({ queryKey: 'cancelled-advertences' })
      queryClient.invalidateQueries({ queryKey: 'done-advertences' })
      queryClient.invalidateQueries({ queryKey: ['advertence', routeParams?.advertenceId] })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          advertences: cachedValue.advertences.map((advertence) => {
            if (advertence.id === routeParams.advertenceId) {
              return { ...advertence, status: 'done' }
            }

            return advertence
          })
        })
      })
    }
  })

  async function handleCancelAdvertence() {
    await promiseMessage(
      cancelAdvertenceFn({ routeParams: { advertenceId: advertence.id } }),
      'Advertência cancelada com sucesso!'
    )
  }

  async function handleCompleteAdvertence() {
    await promiseMessage(
      completeAdvertenceFn({ routeParams: { advertenceId: advertence.id } }),
      'Advertência concluída com sucesso!'
    )
  }

  return (
    <Tr>
      <Td>
        <Modal
          isOpen={isAdvertenceDetailModalOpen}
          onClose={onCloseAdvertenceDetailModal}
          size='6xl'
        >
          <ModalOverlay />

          <AdvertenceDetail advertenceId={advertence.id} />

        </Modal>
        <IconButton
          aria-label="Detalhes da advertência"
          icon={<RiSearchLine />}
          onClick={onOpenAdvertenceDetailModal}
          size="sm"
        />
      </Td>
      <Td>{advertence?.user.firstname} {advertence?.user.lastname}</Td>
      <Td>{regionalTypeMap[advertence.regional]}</Td>
      <Td>{advertence.date
        ? format(new Date(advertence.date), 'dd/MM/yyyy')
        : '-'}</Td>
      <Td>{advertenceTypeMap[advertence.advertence_type]}</Td>
      <Td>
        <AdvertenceStatus status={advertence.status} />
      </Td>
      <Td>
        <Flex align="center" gap="2">
          {userCanCancelAdvertence && advertence?.status === 'awaiting-application' && (
            <Button
              size="xs"
              lineHeight="1"
              leftIcon={<FaTimes />}
              variant="ghost"
              onClick={handleCancelAdvertence}
            >
              Cancelar
            </Button>
          )}

          {userCanCompleteAdvertence && advertence?.status === 'parent-verification' && (
            <Button
              size="xs"
              lineHeight="1"
              leftIcon={<FaTimes />}
              variant="ghost"
              onClick={handleCompleteAdvertence}
            >
              Concluir
            </Button>
          )}

        </Flex>
      </Td>
    </Tr>
  )
}
