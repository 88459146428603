import { Box, Flex, FormControl, FormLabel, Input, Stack, Text, useRadioGroup } from "@chakra-ui/react";
import { GetServiceBoardsResponse } from "api/service/getServiceBoards";
import { format } from "date-fns";
import { Controller, useFormContext } from "react-hook-form";
import { FaCheck } from "react-icons/fa";
import { CreateRefundSchema } from "./CreateRefund";
import { RadioItem } from "./RadioItem";


interface ShippingRefundFormProps {
  serviceBoardsResult: GetServiceBoardsResponse
}

export function ShippingRefundForm({ serviceBoardsResult }: ShippingRefundFormProps) {

  const { register, control, formState: { errors } } = useFormContext<CreateRefundSchema>()

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'boardServiceId',
    defaultValue: '',
  });

  const groupProps = getRootProps();


  return (
    <Box w="full">
      <Stack
        w="full"
        spacing={3}
      >
        <FormControl isInvalid={!!errors.serviceProtocol}>
          <FormLabel fontSize="sm">
            Protocolo do serviço
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('serviceProtocol')}
            name="serviceProtocol"
            rounded="md"
            size="sm"
          />
        </FormControl>
        <FormControl isInvalid={!!errors.boardServiceId}>
          <FormLabel fontSize="sm">
            Selecione o Embarque
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>

          <Controller
            name="boardServiceId"
            control={control}
            render={({ field }) => (
              <Flex
                {...groupProps}
                {...field}
                direction="row"
                wrap="wrap"
                gap={3}
              >
                {serviceBoardsResult?.boards.map((board) => {
                  const radio = getRadioProps({ value: board.id });
                  return (
                    <Box
                      key={board.id}
                      p="4"
                      bg={"white"}
                      borderRadius="md"
                      borderWidth={field.value === board.id ? "2px" : "1px"}
                      borderColor={field.value === board.id ? "blue.500" : "gray.300"}
                      position="relative"
                      _hover={{ borderColor: 'blue.500' }}
                      minWidth="250px"
                      maxWidth="350px"
                    >
                      <Box
                        position="absolute"
                        top="2"
                        right="2"
                      >
                        <RadioItem
                          props={radio}
                          radio={{
                            styles: {},
                            icon: FaCheck,
                          }}
                        />
                      </Box>
                      <Stack spacing={2}>
                        <Text fontWeight="bold" maxW='200px'>{board?.branchIDBoard?.shippingIDBranch?.trading_name}</Text>
                        <Text>{format(new Date(board.arrival_timestamp), 'dd/MM/yyyy')}</Text>
                        <Text>{board.operational_number}</Text>
                      </Stack>
                    </Box>
                  );
                })}
              </Flex>

            )}
          />
        </FormControl>

      </Stack>

    </Box>
  )
}
