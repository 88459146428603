import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"
import { BillingStatus } from "./billing"

interface CloseBillingParams {
  billingId: string
}

export interface CloseBillingResponse {
  billing: {
    status: BillingStatus
  }
}


export async function closeBilling({
  billingId,
}: CloseBillingParams) {
  try {
    const response = await api.patch<CloseBillingResponse>(`/billings/${billingId}/close`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
