import { Button, Flex, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Text, } from "@chakra-ui/react";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { createContingency } from "api/contingencies/createContingency";

interface CreateContingencyProps {
  onClose: () => void
}

export interface CreateContingencySchema {
  name: string
}

const createContingencySchema = yup.object({
  name: yup.string().required(),
})

export function CreateContingency({ onClose }: CreateContingencyProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    register,
    handleSubmit,

    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CreateContingencySchema>({
    resolver: yupResolver(createContingencySchema),
  })



  const { mutateAsync: createContingencyFn } = useMutation({
    mutationFn: createContingency,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'contingencies' })
      onClose()
    }
  })


  async function handleCreateContingency(values: CreateContingencySchema) {
    await promiseMessage(createContingencyFn({
      body: {
        ...values
      }
    }), 'Contingência criada!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Criar Contingência
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleCreateContingency)}
        maxH='600px'
        overflowY='scroll'
      >
        <FormControl isInvalid={!!errors.name}>
          <FormLabel fontSize="sm">
            Nome
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('name')}
            name="name"
            rounded="md"
            size="sm"
          />
        </FormControl>
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
