import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import { Address } from "./dtos/Address";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";

type UseQueryOptionsProps = UseQueryOptions<Address, unknown, Address, "address">

async function getAddress(address_id?: string) {
  try {
    const { data } = await api.get(`/address/${address_id}`)

    return data
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new Error("Erro inesperado!")
    }
  }
}

export function useAddress(address_id?: string, queryOptions?: UseQueryOptionsProps) {
  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const hasAddressIdToEnableQuery = !!address_id

  return useQuery("address", () => getAddress(address_id), {
    ...queryOptions,
    enabled: hasAddressIdToEnableQuery,
    refetchOnWindowFocus: false,
    onError: async (error) => {
      if (error instanceof RequestError) {
        errorMessage(error.message)
        if (error.httpCode === 401) await handleLogout()
      } else {
        console.log(error, "Request unique address error!")
      }
    }
  })
}
