import { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import * as yup from "yup"
import { Button, Flex, Stack } from "@chakra-ui/react";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm"
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { crmTypeOptions } from "utils/CustomLists/crmTypeOptions";
import { useCrmReport } from "hooks/report/useCrmReport";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { formatDate } from "utils/DateFunctions/formatDate";
import { handleChangeUrl } from "utils/handleChangeUrl";
import { CrmReportProps } from "hooks/report/dtos/CrmReport";
import { useAuth } from "hooks/auth/useAuth";

interface CrmReportSelectData extends Omit<CrmReportProps, "owner" | "customer" | "invoice_number" | "rnc_attachments"> {
  customer: string
  invoice_number: string | number
  rnc_attachments: string[] | string
  owner: string
}


interface CrmReportInputs {
  findBy: 'general' | 'type'
  crmType: string
  startDate: string
  endDate: string
}

const CrmReportHeaders = [
  { key: 'customer', label: 'CLIENTE' },
  { key: 'type', label: 'TIPO' },
  { key: 'meeting_date', label: 'DATA REUNIÃO' },
  { key: 'meeting_participants', label: 'PARTICIPANTES' },
  { key: 'meeting_summary', label: 'RESUMO' },
  { key: 'meeting_attachment', label: 'ANEXO REUNIÃO' },
  { key: 'invoice_number', label: 'NÚMERO FATURA' },
  { key: 'original_invoice_expiration_date', label: 'VENCIMENTO ORIGINAL' },
  { key: 'payment_forecast_date', label: 'PREVISÃO PAGAMENTO' },
  { key: 'rnc_date', label: 'DATA RNC' },
  { key: 'rnc_description', label: 'DESCRIÇÃO RNC' },
  { key: 'rnc_attachments', label: 'ANEXOS RNC' },
  { key: 'note_date', label: 'DATA ANOTAÇÃO' },
  { key: 'note_description', label: 'DESCRIÇÃO ANOTAÇÃO' },
  { key: 'owner', label: 'RESPONSÁVEL' },
  { key: 'created_at', label: 'DATA CRIAÇÃO' },
]

const searchTypeOptions = [
  { key: '0', value: 'general', showOption: 'GERAL' },
  { key: '1', value: 'type', showOption: 'TIPO' },
]

const schema = yup.object().shape({
  findBy: yup.string().required('Campo obrigatório'),
  crmType: yup.string().when('findBy', {
    is: 'type',
    then: yup.string().required('Campo obrigatório!')
  }),
  startDate: yup.string().required('Campo obrigatório!'),
  endDate: yup.string().required('Campo obrigatório!')
})


export function CrmReport() {

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-crm-report')) {
      redirect('/')
    }
  }, [userLogged, redirect])

  const [
    enableCrmReportRequest,
    setEnableCrmReportRequest
  ] = useState(false)

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<CrmReportInputs>({
    resolver: yupResolver(schema),
    shouldUnregister: true
  })

  const [
    startFilter,
    endFilter,
    crmType
  ] = useWatch({
    control,
    name: ['startDate', 'endDate', 'crmType']
  })

  const findBySelected = useWatch({
    control,
    name: 'findBy'
  })

  const findBySelectedIsType = findBySelected === 'type'

  const { data: crmReportData, isFetching: isCrmReportRequestFetching } = useCrmReport<CrmReportSelectData[]>({
    queryOptions: {
      enabled: enableCrmReportRequest,
      select: (crmReportData) => {
        return crmReportData.reportData.map((crmReport) => {
          return {
            ...crmReport,
            customer: crmReport.customer.trading_firstname ?? '-',
            meeting_date: crmReport.meeting_date ? formatDate.handle(String(crmReport?.meeting_date), "DateWithoutHourToShow") : '-',
            meeting_participants: crmReport.meeting_participants ?? '-',
            meeting_summary: crmReport.meeting_summary ?? '-',
            meeting_attachment: crmReport.meeting_attachment ? handleChangeUrl(crmReport.meeting_attachment) : '-',
            invoice_number: crmReport.invoice_number ?? '-',
            original_invoice_expiration_date: crmReport?.original_invoice_expiration_date
              ? formatDate.handle(String(crmReport?.original_invoice_expiration_date), "DateWithoutHourToShow")
              : '-',
            payment_forecast_date: crmReport.payment_forecast_date
              ? formatDate.handle(String(crmReport.payment_forecast_date), "DateWithoutHourToShow")
              : '-',
            rnc_date: crmReport.rnc_date ? formatDate.handle(String(crmReport.rnc_date), "DateWithoutHourToShow") : '-',
            rnc_description: crmReport.rnc_description ?? '-',
            rnc_attachments: crmReport.rnc_attachments ? crmReport.rnc_attachments.map((attachment) => {
              return `${handleChangeUrl(attachment)} \n`
            }).join('\n') : '-',
            note_date: crmReport.note_date ? formatDate.handle(String(crmReport.note_date), "DateWithoutHourToShow") : '-',
            note_description: crmReport.note_description ?? '-',
            owner: crmReport.owner ? `${crmReport.owner.firstname} ${crmReport.owner.firstname}` : '-',
            created_at: formatDate.handle(String(crmReport?.created_at), "DateWithoutHourToShow") ?? '-',
          }
        })
      }
    },
    queryParams: {
      crmType: crmType,
      startDate: startFilter,
      endDate: endFilter
    }
  })

  async function handleGenerateReport() {
    setEnableCrmReportRequest(true)
    setTimeout(() => {
      setEnableCrmReportRequest(false)
    }, 500)
  }

  const csvReportFilename = `relatorio-crm-${startFilter}-${endFilter}.csv`

  const csvReportProps = {
    data: crmReportData ?? [],
    headers: CrmReportHeaders,
    filename: csvReportFilename,
  }
  return (
    <StandardBackgroundForm
      title='Relatório CRM'
      onSubmit={handleSubmit(handleGenerateReport)}
    >
      <Flex
        gap={4}
        direction='column'
      >
        <Select
          {...register('findBy')}
          name='findBy'
          label='Procurar por'
          placeholder='Selecione uma opção...'
          options={searchTypeOptions}
          error={errors.findBy}
          required
        />
        {findBySelected && (
          <>
            {findBySelectedIsType && (
              <Select
                {...register('crmType')}
                label='Tipo de Crm'
                name='crmType'
                placeholder='Selecione uma opção...'
                options={crmTypeOptions}
                error={errors.crmType}
                required
              />
            )}
            <Stack
              w='full'
              spacing={4}
              direction={['column', 'column', 'row']}
            >
              <Input
                {...register('startDate')}
                name='startDate'
                label='Data inicial do filtro'
                type='date'
                error={errors.startDate}
                required />

              <Input
                {...register('endDate')}
                name='endDate'
                label='Data final do filtro'
                type='date'
                error={errors.endDate}
                required />
            </Stack>

          </>
        )}
        <Flex gap={2} justify={['center', 'center', 'flex-end']}>
          <Button
            isLoading={isCrmReportRequestFetching}
            type='submit'
            w={['full', 'full', 'min']}
            colorScheme='blue'
          >
            Gerar relatório
          </Button>
          {crmReportData && (
            <GenerateExcelReportButton mt={-4} csvReportProps={csvReportProps} />
          )}
        </Flex>
      </Flex>
    </StandardBackgroundForm>
  )
}
