import { Button, Flex, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Stack, Text } from "@chakra-ui/react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form"
import AsyncSelect from "react-select/async"
import { getRegionals } from "api/regionals/getRegionals"
import { statesWithUfList } from "utils/CustomLists/statesWithUfList"
import { useCity } from "hooks/city/useCity"
import { captalize } from "utils/captalize"
import { AttachmentTableRowField } from "./AttachmentTableRowField"
import { useAggregates } from "hooks/aggregate/useAggregates"
import { useCollectors } from "hooks/collector/useCollectors"
import { useEffect } from "react"
import { useMutation, useQueryClient } from "react-query"
import { createInvoice } from "api/invoices/createInvoice"
import { useToastify } from "hooks/toastify/useToastify"
import { set } from "date-fns"

interface CreateInvoiceProps {
  onCloseModal: () => void
}

interface CreateInvoiceSchema {
  billingType: 'Agregado' | 'Coletador'
  collectorId: string
  aggregateId: string
  tradingName: string
  companyName: string
  cityId: string
  state: string
  regionalId: {
    value: string
    label: string
  }
  billingEmail: string
  reportStartDate: string
  reportFinalDate: string
  approvedReportAttachment: {
    attachmentId: string
    file: File
  }

}

const createInvoiceSchema = yup.object({
  billingType: yup.string().required(),
  collectorId: yup.mixed().when('billingType', {
    is: 'Coletador',
    then: yup.string().required()
  }),
  aggregateId: yup.mixed().when('billingType', {
    is: 'Agregado',
    then: yup.string().required()
  }),
  cityId: yup.string().required(),
  state: yup.string().required(),
  regionalId: yup.object().required(),
  reportStartDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  reportFinalDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  billingEmail: yup.string().required(),
  approvedReportAttachment: yup.object().required(),
})

const billingTypeOptions = [
  'Agregado',
  'Coletador',
]

export function CreateInvoice({
  onCloseModal
}: CreateInvoiceProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const formMethods = useForm<CreateInvoiceSchema>({
    resolver: yupResolver(createInvoiceSchema),
  })

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = formMethods


  const {
    cities: { data: cities },
  } = useCity(null, true)

  const {
    data: collectors
  } = useCollectors({
    queryParams: {
      situation: 'ATIVO'
    }
  })
  const {
    data: aggregates
  } = useAggregates({
    queryParams: {
      status: 'active'
    }
  })

  const [state, billingType, collectorId, aggregateId] = useWatch({
    control,
    name: ['state', 'billingType', 'collectorId', 'aggregateId']
  })

  const isAgggregateBillingType = billingType === 'Agregado'
  const isCollectorBillingType = billingType === 'Coletador'

  useEffect(() => {
    if (isAgggregateBillingType && aggregateId) {
      const aggregateById = aggregates?.find(aggregate => aggregate.id === aggregateId)

      setValue("tradingName", aggregateById.trading_name)
      setValue("companyName", aggregateById.company_name)
    }
    if (isCollectorBillingType && collectorId) {
      const collectorById = collectors?.find(collector => collector.id === collectorId)

      setValue("tradingName", collectorById.trading_name)
      setValue("companyName", collectorById.company_name)
    }
  }, [aggregateId, aggregates, collectorId, collectors, isAgggregateBillingType, isCollectorBillingType, setValue])

  const handleSetAttachmentId = (attachmentId: string) => {
    setValue(`approvedReportAttachment.attachmentId`, attachmentId);
  };


  async function regionalPromiseOptions(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getRegionals({ currentPage: 1, pageSize: 10 })

    return response?.regionals?.filter(regional => regional.code.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())).map(regional => {
      return { value: regional.id, label: regional.code.toUpperCase() }
    })
  }

  const { mutateAsync: createInvoiceFn } = useMutation({
    mutationFn: createInvoice,
    onSuccess(_data, _variables, _context) {
      queryClient.invalidateQueries({ queryKey: 'invoices' })
      queryClient.invalidateQueries({ queryKey: 'invoices-analyzing' })
      onCloseModal()
    }
  })



  async function handleCreateInvoice(values: CreateInvoiceSchema) {

    await promiseMessage(createInvoiceFn({
      body: {
        approvedReportAttachmentId: values?.approvedReportAttachment?.attachmentId,
        regionalId: values.regionalId.value,
        billingEmail: values.billingEmail,
        cityId: values.cityId,
        reportStartDate: values.reportStartDate,
        reportFinalDate: values.reportFinalDate,
        ...(values.billingType === 'Agregado' && { aggregateId: values.aggregateId }),
        ...(values.billingType === 'Coletador' && { collectorId: values.collectorId }),
      }
    }), 'Fatura criada!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Criar fatura</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleCreateInvoice)}
      >
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.billingType}>
              <FormLabel fontSize="sm">
                Tipo de fatura
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register('billingType')}
                name="billingType"
                placeholder="Selecione..."
                rounded="md"
                size='sm'
              >
                {Object.entries(billingTypeOptions).map(([key, value]) => (
                  <option key={key} value={value}>{value}</option>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            {isCollectorBillingType && (
              <FormControl isInvalid={!!errors?.collectorId}>
                <FormLabel fontSize="sm">
                  Coletador
                </FormLabel>
                <Select
                  {...register('collectorId')}
                  name="collectorId"
                  placeholder="Selecione..."
                  rounded="md"
                  size='sm'
                >
                  {collectors?.map(collector => (
                    <option value={collector.id} key={collector.id}>
                      {captalize(collector.trading_name)}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
            {isAgggregateBillingType && (
              <FormControl isInvalid={!!errors?.aggregateId}>
                <FormLabel fontSize="sm">
                  Agregado
                </FormLabel>
                <Select
                  {...register('aggregateId')}
                  name="aggregateId"
                  placeholder="Selecione..."
                  rounded="md"
                  size='sm'
                >
                  {aggregates?.map(aggregate => (
                    <option value={aggregate.id} key={aggregate.id}>
                      {captalize(`${aggregate?.driver?.firstname} ${aggregate?.driver?.lastname}`)}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}

          </Stack>
        </Stack>
        {(collectorId || aggregateId) && (
          <Stack
            spacing="6"
            direction={["column", "column", "row"]}
            mt="3"
          >
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors.tradingName}>
                <FormLabel fontSize="sm">
                  Nome fantasia
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('tradingName')}
                  name="tradingName"
                  rounded="md"
                  size="sm"
                  isDisabled
                />
              </FormControl>
            </Stack>
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors.companyName}>
                <FormLabel fontSize="sm">
                  Razão social da sua empresa
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('companyName')}
                  name="companyName"
                  rounded="md"
                  size="sm"
                  isDisabled
                />
              </FormControl>
            </Stack>
          </Stack>
        )}
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.state}>
              <FormLabel fontSize="sm">
                Estado
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register('state')}
                name="state"
                placeholder="Selecione..."
                rounded="md"
                size='sm'
              >
                {Object.entries(statesWithUfList).map(([key, value]) => (
                  <option value={key} key={key}>
                    {value.uf.toUpperCase()}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Stack>
          {state && (
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors.cityId}>
                <FormLabel fontSize="sm">
                  Cidade
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Select
                  {...register('cityId')}
                  name="cityId"
                  placeholder="Selecione..."
                  rounded="md"
                  size='sm'
                >
                  {cities?.filter((city) => city.state === state.toUpperCase())
                    .map(city => {
                      return (
                        <option value={city.id} key={city.id}>
                          {captalize(city?.name)}
                        </option>
                      )
                    })}

                </Select>
              </FormControl>
            </Stack>
          )}

        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors?.regionalId}>
              <FormLabel fontSize="sm">
                Regional
              </FormLabel>
              <Controller
                control={control}
                name="regionalId"
                render={({ field }) => (
                  <AsyncSelect
                    name={field.name}
                    onChange={field.onChange}
                    cacheOptions
                    defaultOptions
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: '0px',
                        minHeight: '20px',
                        height: '32px',
                        borderRadius: '6px',
                        border: state.isFocused ? 'none' : '',
                        borderColor: 'rgba(0, 0, 0, 0.09)',
                        fontSize: '14px',
                        fontWeight: 'normal',
                        boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : '',
                      }),
                      valueContainer: (baseStyles) => ({
                        ...baseStyles,
                        padding: '0px 8px'
                      }),
                      input: (baseStyles) => ({
                        ...baseStyles,
                        margin: '0px',
                        padding: '0px',
                        height: '100%',
                      }),
                      multiValue: (baseStyles) => ({
                        ...baseStyles,
                        margin: '2px'
                      }),
                      dropdownIndicator: (baseStyles) => ({
                        ...baseStyles,
                        padding: '4px'
                      }),
                      clearIndicator: (baseStyles) => ({
                        ...baseStyles,
                        padding: '4px'
                      }),
                      placeholder: (baseStyles) => ({
                        ...baseStyles,
                        color: 'black'
                      })
                    }}
                    placeholder="selecione..."
                    loadOptions={regionalPromiseOptions}
                    isClearable={true}
                  />
                )}
              />
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.billingEmail}>
              <FormLabel fontSize="sm">
                Informe o e-mail para faturamento
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('billingEmail')}
                name="billingEmail"
                rounded="md"
                size="sm"
                type='email'
              />
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors?.reportStartDate} mt={3}>
              <FormLabel fontSize="sm">
                Relatório contendo serviços do dia
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('reportStartDate')}
                name="reportStartDate"
                type="date"
                size="sm"
                rounded="md"
              />
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors?.reportFinalDate} mt={3}>
              <FormLabel fontSize="sm">
                Até o dia
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('reportFinalDate')}
                name="reportFinalDate"
                type="date"
                size="sm"
                rounded="md"
              />
            </FormControl>
          </Stack>
        </Stack>
        <FormControl mt={3} isInvalid={!!errors.approvedReportAttachment}>
          <FormProvider {...formMethods}>
            <FormLabel fontSize='sm'>
              Anexo
            </FormLabel>
            <AttachmentTableRowField
              isFilledField={!!formMethods.watch(`approvedReportAttachment.attachmentId`)}
              onSetAttachmentId={(attachmentId) => handleSetAttachmentId(attachmentId)}
              title='Anexe aqui o seu relatório de serviços'
              />
          </FormProvider>
        </FormControl>
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
