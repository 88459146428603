import { usePagination } from "@ajna/pagination"
import { Box, Button, Flex, Heading, IconButton, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react"
import { getStandardizations } from "api/standardizations/getStandardizations"
import { StandardizationStatus } from "api/standardizations/standardization"
import { Pagination } from "components/Pagination/Pagination"

import { useAuth } from "hooks/auth/useAuth"
import { useSearchParams } from "hooks/useSearchParams"
import { useEffect, useState } from "react"
import { FaExchangeAlt, FaFileDownload, FaFileExport, FaPlus } from "react-icons/fa"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { StandardizationsTableFilters } from "./components/StandardizationsFilterTable"
import { StandardizationTableRow } from "./components/StandardizationTableRow"
import { Standardization } from "api/standardizations/standardization";
import { format } from "date-fns"
import { standardizationStatusMapper } from "utils/standardizations/standardizationMappers"
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton"

const headers = [
  { label: 'COLABORADOR', key: 'name' },
  { label: 'HUB', key: 'hub' },
  { label: 'DATA DE ENVIO', key: 'sended_at' },
  { label: 'STATUS', key: 'status' },
]

function formatValuesToReport(values: Standardization[]) {
  return values?.map((standardization) => ({
    ...standardization,
    hub: standardization?.hub?.name,
    sended_at: format(new Date(standardization.sended_at), 'dd/MM/yyyy'),
    status: standardizationStatusMapper[standardization.status],
  }))
}

export function Standardizations() {
  const { userLogged } = useAuth()
  const { replace, push: redirect } = useHistory()
  const searchParams = useSearchParams()

  const userCanViewStandardizations = userLogged?.permissions.includes('view-standardizations')
  const userCanCreateStandardization = userLogged?.permissions.includes('create-standardization')

  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }

  useEffect(() => {
    if (!userCanViewStandardizations) redirect('/')
  }, [redirect, userCanViewStandardizations])

  const page = searchParams.get('page') ?? '1'
  const hub = searchParams.get('hub')
  const status = searchParams.get('status') ?? 'all'

  const { data: standardizationsData } = useQuery({
    queryKey: ['standardizations', page,
      hub,
      status],
    queryFn: () => getStandardizations({
      currentPage: page,
      pageSize: '10',
      hub,
      status: status as StandardizationStatus | 'all'
    }),
    keepPreviousData: true
  })

  const {
    data: standardizationsDataReportResult,
    isLoading: isLoadingStandardizationsDataReportResult
  } = useQuery({
    queryKey: [
      'asos-report',
      status,
      hub,
      standardizationsData?.meta?.count,
    ],
    queryFn: () => getStandardizations({
      currentPage: '1',
      pageSize: String(standardizationsData?.meta?.count),
      status: ["pending", "active", "inactive"].includes(status) ? status as StandardizationStatus : 'all',
      hub
    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: standardizationsData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: standardizationsData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  const csvReportProps = {
    data: standardizationsDataReportResult ? formatValuesToReport(standardizationsDataReportResult?.standardizations) : [],
    headers,
    filename: `padronizacoes.csv`,
  }

  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Padronizações</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar kanban"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => redirect('/padronizacoes/kanban')}
          />
          {userCanCreateStandardization && (
            <IconButton
              aria-label="Criar Padronização"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={() => redirect("/padronizacao/criar")}
            />
          )}
        </Flex>


      </Flex>
      <StandardizationsTableFilters />
      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Colaborador</Th>
              <Th color="white">Hub</Th>
              <Th color="white">Data de envio</Th>
              <Th color="white">Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {standardizationsData?.standardizations?.map((standardization) => {
              return (
                <StandardizationTableRow
                  key={standardization.id}
                  standardization={standardization}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {!standardizationsDataReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingStandardizationsDataReportResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>

  )
}
