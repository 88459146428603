import axios from "axios";
import { addDays, set } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { RequestError } from "utils/errors/RequestErrors";

interface DownloadCustomerAccountCteZipFileQueryParams {
  customer_id: string
  start_date: string | Date
  end_date: string | Date
}

type QueryOptions = UseQueryOptions<"arraybuffer", unknown, "arraybuffer", ["downloadCustomerAccountCteZipFile"]>

interface UseDownloadCustomerAccountCteZipFunctionOptions {
  queryParams?: DownloadCustomerAccountCteZipFileQueryParams,
  queryOptions?: QueryOptions
}

async function downloadCustomerAccountCteZipFileReqFunction({
  customer_id,
  start_date,
  end_date
}: DownloadCustomerAccountCteZipFileQueryParams) {

  try {
    const startDateWithDefaultHours = addDays(set(new Date(start_date), {
      hours: 0,
      minutes: 0,
      seconds: 1
    }), 1)
    const endDateWithDefaultHours = addDays(set(new Date(end_date), {
      hours: 23,
      minutes: 59,
      seconds: 59
    }), 1)

    const { data } = await api.get('/accounts/customer/download-cte/zip', {
      params: {
        customer_id,
        start_date: startDateWithDefaultHours,
        end_date: endDateWithDefaultHours
      },
      responseType: 'arraybuffer'
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

export function useDownloadCustomerAccountCteZipFunction(
  options?: UseDownloadCustomerAccountCteZipFunctionOptions
) {

  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  const downloadCustomerAccountCteZipFile = useQuery(
    ["downloadCustomerAccountCteZipFile",
      options?.queryParams
    ],
    () => downloadCustomerAccountCteZipFileReqFunction({ ...options?.queryParams }), {
    ...options.queryOptions,
    onError: onRequestError,
  })

  return { downloadCustomerAccountCteZipFile }
}
