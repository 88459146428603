import { ShippingProps } from 'contexts/ShippingContext'
import { User } from 'hooks/user/dtos/User'
import { api } from '../../../services/api'
import { CitiesProps } from '../../../services/getFunctions/city/getCity'
import { IHubsProps } from '../Hubs/requestHubFunctions'

export type PriceCategory = 'Rápido' | 'Econômico'

export type PriceSituations  = 'active' | 'inactive'


type LogDetail = {
  [Property in keyof PriceProps]: {
    prev_value: PriceProps[Property]
    new_value: PriceProps[Property]
  }
}

export interface PriceLogs {
  id: string
  log_detail: LogDetail
  created_at: string
  updated_at: string
  createdAt: string
  updatedAt: string
  price_id: string
  updated_by: string
  updatedBy: User
}

export type PriceProps = {
  id: string
  source_city_id: string
  source_hub_id: string
  destination_city_id: string
  destination_hub_id: string
  air_minimum_price: number
  air_extra_kg: number
  air_deadline: number
  highway_minimum_price: number
  highway_extra_kg: number
  highway_deadline: number
  additional_collect_price: number
  additional_delivery_price: number
  route_type: string
  sourceCityIDPrice: CitiesProps
  destinationCityIDPrice: CitiesProps
  sourceHubIDPrice: IHubsProps
  destinationHubIDPrice: IHubsProps
  observation: string | null
  category: PriceCategory
  logs: PriceLogs[]
  createdBy: User
  vehicle: string
  shippings: {
    PriceId: string
    ShippingId: string
    shipping: ShippingProps
  }[]
  situation: PriceSituations
  service_type: 'FRACIONADO' | 'DEDICADO'
}

export type CreatePriceInputProps = {
  source_city_id: string
  source_hub_id: string
  destination_city_id: string
  destination_hub_id: string
  air_minimum_price: number | null
  air_extra_kg: number | null
  air_deadline: number | null
  highway_minimum_price: number | null
  highway_extra_kg: number | null
  highway_deadline: number | null
  additional_collect_price: number
  additional_delivery_price: number
  route_type: string
  category: PriceCategory
  vehicle: string
  shippingIds: string[]
  service_type: 'FRACIONADO' | 'DEDICADO'
}

export type EditPriceInputProps = CreatePriceInputProps

export type EditPriceReqProps = {
  id: string
  input: EditPriceInputProps
}

export type ConsultPriceInputProps = {
  source_ufs: string[]
  source_cities_ids: string[]
  destination_ufs: string[]
  destination_cities_ids: string[]
  route_type: string
  vehicle: string
  shippingIds?: string[]
}

export type PriceSendFileInputProps = {
  csv_file: {} | null
}

async function getAllPricesReqFunction() {
  try {
    const { data } = await api.get<PriceProps[]>('/price')

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function getOnePriceReqFunction(id: string) {
  try {
    const { data } = await api.get<PriceProps>(`/price/${id}`)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function createPriceReqFunction(input: CreatePriceInputProps) {
  try {
    const { data } = await api.post('/price', input)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function editPriceReqFunction({ id, input }: EditPriceReqProps) {
  try {
    const { data } = await api.put(`/price/${id}`, input)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function deletePriceReqFunction(id: string) {
  try {
    const { data } = await api.delete(`/price/${id}`)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function consultPriceReqFunction(input: ConsultPriceInputProps) {
  try {
    const { data } = await api.post(`/consult-price`, input)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function sendPriceFileReqFunction(input: PriceSendFileInputProps) {
  try {
    await api.post('/price/import', input)
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

export {
  getAllPricesReqFunction,
  getOnePriceReqFunction,
  createPriceReqFunction,
  editPriceReqFunction,
  deletePriceReqFunction,
  consultPriceReqFunction,
  sendPriceFileReqFunction
}
