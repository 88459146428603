import { Box, Flex } from "@chakra-ui/react"
import { addressesRequestedChangesChangeStatusMap } from "utils/addressesWithRequestedChangesMappers"

interface AddressesRequestedChangesChangeStatusProps {
  status: 'approved' | 'reproved' | 'pending'
}

export function AddressesRequestedChangesChangeStatus({
  status
}: AddressesRequestedChangesChangeStatusProps) {
  return (
    <Flex
      align="center"
      gap="2"
    >
      {status === 'pending' && (
        <Box w="2" h="2" rounded="full" bgColor="orange.300" />
      )}
      {status === 'reproved' && (
        <Box w="2" h="2" rounded="full" bgColor="red.300" />
      )}
      {status === 'approved' && (
        <Box w="2" h="2" rounded="full" bgColor="green.300" />
      )}
      {addressesRequestedChangesChangeStatusMap[status]}
    </Flex>

  )
}
