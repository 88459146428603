import { Regional } from "api/regionals/_types/Regional";
import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { User } from "hooks/user/dtos/User";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Training, TrainingStatus } from "./training";

interface GetTrainingsReportParams {
  status?: TrainingStatus | 'all'
  collaborator?: string
}

export interface GetTrainingsResponse {
  trainings: Array<Training & {
    regional: Regional & {
      responsible: User
    } | null
    trainingAttachment: Attachment | null
    certificateAttachment: Attachment | null
  }>
}

export async function getTrainingsReport({
  status,
  collaborator
}: GetTrainingsReportParams) {
  try {
    const response = await api.get<GetTrainingsResponse>('/trainings/report', {
      params: {
        status,
        collaborator,
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
