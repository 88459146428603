import { Attachment } from 'hooks/attachment/dtos/Attachment';
import { Customer } from "api/customers/_types/Customer";
import { Regional } from "api/regionals/_types/Regional";
import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Audit, CommercialAudit, FieldAudit, FinancialAudit, FineAudit, HumanResourcesAudit, IsoQualityAudit, LlmHumanResourcesAudit, MaintenanceAudit, OperationalAudit, VisaQualityAudit } from "./_types/Audit";

export interface GetAuditResponse {
  audit: Audit & {
    customer: Customer
    regional: Regional
    commercialAudit: CommercialAudit
    fieldAudit: FieldAudit & {
      collaboratorPhotoAttachment: Attachment
      vehicleRearPhotoAttachment: Attachment
      tertiaryPackingPhotoAttachment: Attachment
      internChestPhotoAttachment: Attachment
      cnhPhotoAttachment: Attachment
      crlvPhotoAttachment: Attachment
    }
    financialAudit: FinancialAudit & {
      reportAttachment: Attachment
    }
    fineAudit: FineAudit & {
      reportAttachment: Attachment
    }
    humanResourcesAudit: HumanResourcesAudit
    isoQualityAudit: IsoQualityAudit
    llmHumanResourcesAudit: LlmHumanResourcesAudit
    maintenanceAudit: MaintenanceAudit & {
      reportAttachment: Attachment
    }
    visaQualityAudit: VisaQualityAudit
    operationalAudits: OperationalAudit[]
  }
}

interface GetAuditProps {
  routeParams: {
    auditId: string
  }
}

export async function getAudit({
  routeParams
}: GetAuditProps) {
  try {
    const response = await api.get<GetAuditResponse>(`/audits/${routeParams.auditId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
