import { useEffect } from "react";
import { usePagination } from "@ajna/pagination";
import {
  Box,
  Flex,
  Heading,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,

} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import { FiCheckCircle } from "react-icons/fi";
import { useService } from "hooks/services/service";
import { useAuth } from "hooks/auth/useAuth";
import { Link, useHistory } from "react-router-dom";
import { Pagination } from "components/Pagination/Pagination";
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";
import { SearchBox } from "components/SearchBox/SearchBox";
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions";
import { formatDate } from "utils/DateFunctions/formatDate";
import { searchBoxFilter } from "utils/searchBoxFilter";
import { TableFilterButton } from "components/Filters/TableFilterButton";
import { serviceSortByDate } from "utils/ServiceFunctions/serviceSortByDate";
import { useBranch } from "hooks/branch/useBranch";
import { serviceFormatHourToFront } from "utils/ServiceFunctions/serviceFormatHourToFront";


interface FilterOptionInputs {
  protocol: string;
  delivery_date: string;
  delivery_hour: string;
  source_branch: string;
  destination_branch: string;
  customer: string;
}

const serviceFiscalRetentionFilterOptions = [
  { key: "protocol", value: "PROTOCOLO", checked: false },
  { key: 'delivery_date', value: 'DATA DA ENTREGA', checked: false },
  { key: 'delivery_hour', value: 'HORÁRIO DA ENTREGA', checked: false },
  { key: 'source_branch', value: 'BASE DE ORIGEM', checked: false },
  { key: 'destination_branch', value: 'BASE DE DESTINO', checked: false },
  { key: "customer", value: "CLIENTE", checked: false },

];

const serviceFiscalRetentionOrderOptions = [
  { key: "protocol", value: "Protocolo", checked: false },
  { key: "delivery_date", value: "Data da entrega", checked: false },
];

export function FiscalRetentionList() {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE;
  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions();

  useEffect(() => {
    onLoadSetFilterOptions(serviceFiscalRetentionFilterOptions);
  }, [onLoadSetFilterOptions]);
  useEffect(() => {
    onLoadSetOrderOptions(serviceFiscalRetentionOrderOptions);
  }, [onLoadSetOrderOptions]);

  const { userLogged } = useAuth();
  const { push: redirectTo } = useHistory();

  const userHasPermissionToValidateServiceWithFiscalRetention =
    userLogged?.permissions.includes("validate-fiscal-retention");

  useEffect(() => {
    if (!userHasPermissionToValidateServiceWithFiscalRetention) {
      redirectTo("/");
    }
  }, [userHasPermissionToValidateServiceWithFiscalRetention, redirectTo]);

  const stepsToRequestServices = ["toValidateFiscalRetention"];
  const {
    servicesBySteps: { data: services, isLoading: isServicesLoading },
  } = useService(null, true, false, false, stepsToRequestServices);


  const {
    branches: { data: branches, isLoading: isBranchLoading },
  } = useBranch(null, true, false)

  const { register, watch } = useForm<FilterOptionInputs>();

  const filterValues = watch([
    "protocol",
    "delivery_date",
    "delivery_hour",
    "source_branch",
    "destination_branch",
    "customer",
  ]);

  const servicesWithFiscalRetention = services?.map((service) => {

    const sourceCrossdockingBranchID =
      service.serviceIDRequested.source_crossdocking_branch_id

    const sourceBranch = sourceCrossdockingBranchID
      ? service.serviceIDBoard.find(
        (boardService) =>
          boardService.collector_id ===
          service.serviceIDRequested.crossdocking_collector_id,
      ) !== undefined
        ? service.serviceIDLanding.find(
          (landingService) =>
            landingService.collector_id ===
            service.serviceIDRequested.crossdocking_collector_id,
        ) !== undefined
          ? String(
            branches
              .filter(
                (branch) =>
                  branch.id ===
                  service.serviceIDRequested
                    .source_crossdocking_branch_id,
              )
              .map((branch) => branch.nickname),
          )
          : String(
            branches
              .filter(
                (branch) =>
                  branch.id ===
                  service.serviceIDRequested.source_branch_id,
              )
              .map((branch) => branch.nickname),
          )
        : String(
          branches
            .filter(
              (branch) =>
                branch.id ===
                service.serviceIDRequested.source_branch_id,
            )
            .map((branch) => branch.nickname),
        )
      : String(
        branches
          .filter(
            (branch) =>
              branch.id === service.serviceIDRequested.source_branch_id,
          )
          .map((branch) => branch.nickname),
      )

    const destinationBranch = sourceCrossdockingBranchID
      ? service.serviceIDBoard.find(
        (boardService) =>
          boardService.collector_id ===
          service.serviceIDRequested.crossdocking_collector_id,
      ) !== undefined
        ? service.serviceIDLanding.find(
          (landingService) =>
            landingService.collector_id ===
            service.serviceIDRequested.crossdocking_collector_id,
        ) !== undefined
          ? String(
            branches
              .filter(
                (branch) =>
                  branch.id ===
                  service.serviceIDRequested.destination_branch_id,
              )
              .map((branch) => branch.nickname),
          )
          : String(
            branches
              .filter(
                (branch) =>
                  branch.id ===
                  service.serviceIDRequested
                    .destination_crossdocking_branch_id,
              )
              .map((branch) => branch.nickname),
          )
        : String(
          branches
            .filter(
              (branch) =>
                branch.id ===
                service.serviceIDRequested
                  .destination_crossdocking_branch_id,
            )
            .map((branch) => branch.nickname),
        )
      : String(
        branches
          .filter(
            (branch) =>
              branch.id ===
              service.serviceIDRequested.destination_branch_id,
          )
          .map((branch) => branch.nickname),
      )
    return {
      id: service.id,
      protocol: service.protocol,
      delivery_date: service.serviceIDRequested.delivery_date,
      delivery_hour: service.serviceIDRequested.delivery_hour,
      source_branch: sourceBranch,
      destination_branch: destinationBranch,
      customer: service.customerIDService.trading_firstname

    };
  });

  const fiscalRetentionServicesFiltered = servicesWithFiscalRetention
    ?.filter((service) => {
      const [
        protocolFiltered,
        deliveryDateFiltered,
        sourceBranchFiltered,
        destinationBranchFiltered,
        customerFiltered,

      ] = filterValues;

      const protocolFilter = protocolFiltered
        ? searchBoxFilter(String(service.protocol), protocolFiltered)
        : null;

      const deliveryDateFilter = deliveryDateFiltered
        ? searchBoxFilter(
          formatDate.handle(service.delivery_date, "DateWithoutHourToShow"),
          deliveryDateFiltered
        )
        : null;

      const sourceBranchFilter = sourceBranchFiltered ? searchBoxFilter(
        service.source_branch,
        sourceBranchFiltered,
      ) : null;

      const destinationBranchFilter = destinationBranchFiltered ? searchBoxFilter(
        service.destination_branch,
        destinationBranchFiltered,
      ) : null;


      const customerFilter = customerFiltered
        ? searchBoxFilter(service.customer, customerFiltered)
        : null;


      if (filterValues.some((value) => !!value)) {
        return (
          protocolFilter ||
          deliveryDateFilter ||
          sourceBranchFilter ||
          destinationBranchFilter ||
          customerFilter
        );
      }

      return service;
    })
    .sort((a, b) => {
      const isProtocolOrder = orderOptionSelected.includes("protocol")
        ? a.protocol - b.protocol
        : 0;

      const isDeliveryDateOrder = orderOptionSelected.includes("delivery_date")
        ? serviceSortByDate(a.delivery_date, b.delivery_date)
        : 0;

      return isProtocolOrder || isDeliveryDateOrder;
    });

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: services?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    });
  if (isServicesLoading || isBranchLoading) {
    return <GeneralContentLoading />;
  }

  return (
    <>

        <Box bg="white" padding={[2, 4]} borderRadius="8px" flex="1">
          <Flex gap="2" direction="column">
            <Heading
              size="lg"
              fontFamily="poppins"
              textAlign={["center", "center", "justify"]}
            >
              Retenções Fiscais
            </Heading>
            <Flex justify="space-between" flex="1" w="full">
              <TableFilterButton />
              <Flex direction="column" gap="4">
                {filterOptions
                  .filter((option) => option.checked === true)
                  .map((option) => {
                    return (
                      <SearchBox
                        {...register(option.key as keyof FilterOptionInputs)}
                        key={option.key}
                        size="sm"
                        placeholder={"Buscar " + option.value}
                        handleSearch={() => { }}
                      />
                    );
                  })}
              </Flex>
            </Flex>

            <TableContainer w="full">
              <Table colorScheme="gray" variant="striped" size="sm">
                <Thead>
                  <Tr>
                    <Td></Td>
                    {serviceFiscalRetentionFilterOptions.map((option) => {
                      return (
                        <Th key={option.key}>{option.value.toUpperCase()}</Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {fiscalRetentionServicesFiltered
                    ?.slice(offset, offset + Number(itemLimit))
                    ?.map((service) => {
                      return (
                        <Tr key={service.id}>

                          <Td>
                            <Link
                              to={`/servico/retencao-fiscal/validar/${service.id}`}
                            >
                              <Icon
                                cursor="pointer"
                                as={FiCheckCircle}
                                fontSize="20"
                                mr="2"
                                mt={["2", "2", "0"]}
                              />
                            </Link>

                          </Td>
                          <Td>{service.protocol} </Td>

                          <Td>
                            {formatDate.handle(
                              service.delivery_date,
                              "DateWithoutHourToShow"
                            )}
                          </Td>
                          <Td>{serviceFormatHourToFront(service.delivery_hour)}</Td>
                          <Td>{service.source_branch}</Td>
                          <Td>{service.destination_branch}</Td>
                          <Td>{service.customer}</Td>

                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>
            <Pagination
              currentPage={currentPage}
              pagesQuantity={pagesCount}
              pages={pages}
              handlePageChange={setCurrentPage}
            />
          </Flex>
        </Box>

    </>
  );
}
