import { usePagination } from "@ajna/pagination"
import { Box, Button, Divider, Flex, Heading, Icon, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";

import { Pagination } from "components/Pagination/Pagination"
import { useHistory } from "react-router-dom";

import { useEffect, useReducer, useState } from "react";
import { useAuth } from "hooks/auth/useAuth";
import { formatDate } from "utils/DateFunctions/formatDate";
import { FaEye, FaFileInvoice, FaTrash } from "react-icons/fa";
import { useAwaitingInvoicePurchaseOrders } from "hooks/purchaseOrder/useAwaitingInvoicePurchaseOrders";
import { InvoicePurchaseOrderModal } from "./components/InvoicePurchaseOrderModal";
import { usePurchaseOrderFunctions } from "hooks/purchaseOrder/usePurchaseOrderFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { useQueryClient } from "react-query";
import { PurchaseOrder } from "hooks/purchaseOrder/dtos/PurchaseOrder";
import { ViewPurchaseOrderModal } from "./components/ViewPurchaseOrderModal";

interface ActionPayload {
  currentPage?: number
}

interface Action {
  type: 'set-awaiting-invoice-purchase-order-current-page'
  payload: ActionPayload
}

function reducer(state: ActionPayload, action: Action) {
  if (action.type === 'set-awaiting-invoice-purchase-order-current-page') {
    return {
      currentPage: action.payload.currentPage
    }
  }
  return {
    ...state,
    ...action.payload
  }
}

export function PurchaseOrdersAwaitingInvoice() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  const { promiseMessage } = useToastify()

  const queryClient = useQueryClient()

  const [purchaseOrder, setPurchaseOrder] = useState({} as PurchaseOrder)

  const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState<string | null>(null);

  const [listAwaitingQuoteDataState, dispatch] = useReducer(
    reducer,
    {} as ActionPayload
  )
  const userHasPermissionToViewPurchaseOrdersAwaitingInvoice =
    userLogged.permissions.includes('view-purchase-orders-awaiting-invoice')

  const userHasPermissionAddInvoicePurchaseOrder =
    userLogged.permissions.includes('add-invoice-purchase-order')

  const userHasPermissionToDeletePurchaseOrdersAwaitingInvoice =
    userLogged.permissions.includes('delete-purchase-order')

  useEffect(() => {
    if (!userHasPermissionToViewPurchaseOrdersAwaitingInvoice) {
      redirect('/')
    }
  }, [userHasPermissionToViewPurchaseOrdersAwaitingInvoice, redirect])

  const {
    data: purchaseOrdersAwaitingInvoiceResponseData,
    isFetching: isFetchingPurchaseOrdersAwaitingInvoiceData
  } = useAwaitingInvoicePurchaseOrders({
    queryParams: {
      currentPage: listAwaitingQuoteDataState.currentPage,
      pageSize: Number(rowsPerPage)
    },
    queryOptions: {
      enabled: !!listAwaitingQuoteDataState.currentPage
    }
  })

  const { deletePurchaseOrder: { mutateAsync: deletePurchaseOrder } } = usePurchaseOrderFunctions()

  async function handleDeletePurchaseOrder(purchaseOrderId: string) {
    await promiseMessage(deletePurchaseOrder(purchaseOrderId, {
      onSuccess: () => {
        queryClient.invalidateQueries(['purchaseOrdersAwaitingInvoice'])
      }
    }), 'Pedido de compra excluído com sucesso!')
  }

  const {
    isOpen: isInvoicePurchaseOrderModalOpen,
    onOpen: onOpenInvoicePurchaseOrderModal,
    onClose: onCloseInvoicePurchaseOrderModal
  } = useDisclosure()

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: purchaseOrdersAwaitingInvoiceResponseData?.totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  useEffect(() => {
    dispatch({
      type: 'set-awaiting-invoice-purchase-order-current-page',
      payload: {
        currentPage
      }
    })
  }, [currentPage])

  const {
    isOpen: isViewPurchaseOrderModalOpen,
    onOpen: onOpenViewPurchaseOrderModal,
    onClose: onCloseViewPurchaseOrderModal,
  } = useDisclosure()

  const handleOpenViewPurchaseOrderModal = (purchaseOrder: PurchaseOrder) => {
    setPurchaseOrder(purchaseOrder)
    onOpenViewPurchaseOrderModal()
  }

  const handleChangePage = (page: number) => setCurrentPage(page)

  const handleOpenModal = (purchaseOrderId: string) => {
    setSelectedPurchaseOrderId(purchaseOrderId);
    onOpenInvoicePurchaseOrderModal()
  };


  return (
    <>
      <ViewPurchaseOrderModal
        onClose={onCloseViewPurchaseOrderModal}
        isOpen={isViewPurchaseOrderModalOpen}
        purchaseOrder={purchaseOrder}
      />
      <Box
        borderRadius='8px'
        p={4}
        bg='white'
      >
        {isFetchingPurchaseOrdersAwaitingInvoiceData ? <Spinner /> :
          <Flex
            w="full"
            direction="column"
            gap={6}
          >
            <Heading fontSize="xl">Aguardando Nota Fiscal</Heading>
            <Divider />
            <TableContainer>
              <Table variant="striped" size="sm">
                <Thead>
                  <Tr>
                    <Th>PROTOCOLO</Th>
                    <Th>SOLICITANTE</Th>
                    <Th>DATA SOLICITAÇÃO</Th>
                    <Th>TIPO</Th>
                    <Th>TIPO DE COTAÇÃO</Th>
                    <Th>DATA LIMITE</Th>
                    <Th>FORNECEDOR APROVADO</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {purchaseOrdersAwaitingInvoiceResponseData?.purchaseOrders.map(purchaseOrder => {
                    return (
                      <Tr key={purchaseOrder.id}>
                        <Td>{purchaseOrder.protocol}</Td>
                        <Td>{`${purchaseOrder.owner.firstname} ${purchaseOrder.owner.lastname}`}</Td>
                        <Td>{formatDate.handle(purchaseOrder.created_at, 'DateWithoutHourToShow')}</Td>
                        <Td>{purchaseOrder.type}</Td>
                        <Td>{purchaseOrder.quote_type}</Td>
                        <Td>{formatDate.handle(purchaseOrder.arrival_limit_date, 'DateWithoutHourToShow')}</Td>
                        <Td>{purchaseOrder.approved_provider}</Td>
                        <Td isNumeric>
                          <Button
                            onClick={() => handleOpenViewPurchaseOrderModal(purchaseOrder)}
                            variant="ghost"
                          >
                            <Icon
                              cursor="pointer"
                              as={FaEye}
                              transition="all 0.2s"
                              _hover={{ opacity: 0.8 }} />
                          </Button>
                          {userHasPermissionAddInvoicePurchaseOrder && (
                            <Button
                              onClick={() => { handleOpenModal(purchaseOrder.id) }}
                              variant="ghost"
                            >
                              <Icon
                                cursor="pointer"
                                as={FaFileInvoice}
                                transition="all 0.2s"
                                _hover={{ opacity: 0.8 }} />
                            </Button>
                          )}
                          {userHasPermissionToDeletePurchaseOrdersAwaitingInvoice && (
                            <Button
                              onClick={() => handleDeletePurchaseOrder(purchaseOrder.id)}
                              variant="ghost"
                            >
                              <Icon
                                cursor="pointer"
                                as={FaTrash}
                                transition="all 0.2s"
                                _hover={{ opacity: 0.8 }}
                              />
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <Pagination
              currentPage={currentPage}
              pages={pages}
              pagesQuantity={pagesCount}
              handlePageChange={handleChangePage}
            />
          </Flex>
        }
      </Box>
      {selectedPurchaseOrderId && (
        <InvoicePurchaseOrderModal
          isInvoiceModalOpen={isInvoicePurchaseOrderModalOpen}
          onCloseInvoiceModal={() => {
            setSelectedPurchaseOrderId(null);
            onCloseInvoicePurchaseOrderModal();
          }}
          purchaseOrderId={selectedPurchaseOrderId} />
      )}
    </>
  )
}
