import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"
import { AddressRequestedChange } from "./addressRequestedChange"

export interface GetAddressRequestedChangeResponse {
  addressRequestedChange: AddressRequestedChange
}

export async function getAddressRequestedChanges(
  addressRequestChangeId: string
) {
  try {
    const { data } = await api.get<GetAddressRequestedChangeResponse>(
      `/address/changes/${addressRequestChangeId}`,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
