import { Box, Card, CardBody, CardHeader, Flex, Grid, GridItem, Heading, Icon, IconButton, Table, Tbody, Td, Text, Tooltip, Tr } from "@chakra-ui/react"
import { getCollectServicesWithDelayOccurrences } from "api/service/getCollectServicesWithDelayOccurrences"
import { Empty } from "components/Empty"
import { FaArrowLeft, FaInfoCircle } from "react-icons/fa"
import { useQuery } from "react-query"
import { Link, useHistory } from "react-router-dom"
import { captalize } from "utils/captalize"
import { serviceHandleStep } from "utils/ServiceFunctions/serviceDict"
import { InfoCardsLoading } from "../components/InfoCardsLoading"

export function CollectServicesWithDelayOccurrence() {
  const {
    data: result,
    isError,
    isLoading: isLoadingCollectServicesWithDelayOccurrence
  } = useQuery({
    queryKey: ['collect-services-with-delay-occurrences'],
    queryFn: getCollectServicesWithDelayOccurrences
  })

  const handleReloadPage = () => window.location.reload()

  const history = useHistory()

  const handleReturnToDashboard = () => history.goBack()

  if (isError) {
    return (
      <Empty.Root>
        <Empty.ActionButton onClick={handleReloadPage}>Recarregar a página</Empty.ActionButton>
      </Empty.Root>
    )
  }

  return (

    <Box
      p="6"
      rounded="md"
      bg="white"
      display="flex"
      flexDirection="column"
      gap="6"
    >
      <Flex
        align="center"
        gap="2"
      >
        <IconButton
          aria-label="Voltar ao dashboard"
          icon={<FaArrowLeft />}
          variant="ghost"
          size="sm"
          onClick={handleReturnToDashboard}
        />
        <Heading letterSpacing="tight">Serviços Atrasados com Ocorrência de Atraso - Coleta</Heading>
      </Flex>

      <Grid
        gap="6"
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }}
      >
        {isLoadingCollectServicesWithDelayOccurrence && (
          <InfoCardsLoading />
        )}
        {result?.services?.map((service) => {
          return (
            <GridItem
              key={service.id}
            >
              <Card
                rounded="xl"
                transition="all 0.2s"
                _hover={{
                  transform: 'translateY(-0.5rem)'
                }}
              >
                <CardHeader
                  as={Link}
                  display="flex"
                  alignItems="center"
                  to={`/servico/detalhes/${service.id}`}
                  h="40px"
                  bg="#38c3fa"
                  borderTopRadius="xl"
                >
                  <Text fontWeight="bold" color="white">{service.protocol}</Text>
                </CardHeader>

                <CardBody>
                  <Table size="sm">
                    <Tbody>
                      <Tr >
                        <Td fontSize='md'>Status</Td>
                        <Td isNumeric fontSize='md'>{captalize(serviceHandleStep(service.step))}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize='md'>Cliente</Td>
                        <Td isNumeric fontSize='md'>{captalize(service.customer)}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize='md'>Hub de Origem
                          {Number(service.source_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
                            <Tooltip label={service.source_tz}>
                              <Box as="span" ml="2">
                                <Icon as={FaInfoCircle} />
                              </Box>
                            </Tooltip>
                          )}
                        </Td>
                        <Td isNumeric fontSize='md'>{captalize(service.source_hub)}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize='md'>Hub de Destino
                          {Number(service.destination_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
                            <Tooltip label={service.destination_tz}>
                              <Box as="span" ml="2">
                                <Icon as={FaInfoCircle} />
                              </Box>
                            </Tooltip>
                          )}
                        </Td>
                        <Td isNumeric fontSize='md'>{captalize(service.destination_hub)} </Td>
                      </Tr>
                      <Tr>
                        <Td fontSize='md'>Transportadora</Td>
                        <Td isNumeric fontSize='md'>{service.shipping ? captalize(service.shipping) : '-'}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize='md'>Intercorrência</Td>
                        <Td isNumeric fontSize='md'>{service.intercurrence ? captalize(service.intercurrence) : '-'}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </CardBody>
              </Card>
            </GridItem>
          )
        })}
      </Grid>
    </Box>

  )
}
