import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, HStack, IconButton, Skeleton, SkeletonText, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { getCltDrivers } from "api/cltDrivers/getCltDrivers";
import { CltDriver } from "api/cltDrivers/_types/CltDriver";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { Pagination } from "components/Pagination/Pagination";
import { format, setDay } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { FaExchangeAlt, FaFileDownload, FaFileExport, FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { captalize } from "utils/captalize";
import { cltDriverStatusMap } from "utils/cltDriverMappers";
import { CltDriversTableFilter } from "./components/CltDriversTableFilters";
import { CltDriverTableRow } from "./components/CltDriversTableRow";

const headers = [
  { label: 'MOTORISTA', key: 'collaborator_name' },
  { label: 'CPF', key: 'cpf' },
  { label: 'RG', key: 'rg' },
  { label: 'E-MAIL', key: 'email' },
  { label: 'CELULAR', key: 'cellphone' },
  { label: 'DATA DE NASCIMENTO', key: 'birth_date' },
  { label: 'ENDEREÇO', key: 'address' },
  { label: 'DIAS DE TRABALHO', key: 'days_of_work' },
  { label: 'HORÁRIO INICIAL DE TRABALHO', key: 'start_work_hour' },
  { label: 'HORÁRIO FINAL DE TRABALHO', key: 'end_work_hour' },
  { label: 'HORÁRIO INICIAL DE INTERVALO', key: 'start_interval_hour' },
  { label: 'HORÁRIO FINAL DE INTERVALO', key: 'end_interval_hour' },
  { label: 'HORÁRIO INICIAL DE TRABALHO FINAL DE SEMANA', key: 'start_weekend_work_hour' },
  { label: 'HORÁRIO FINAL DE TRABALHO FINAL DE SEMANA', key: 'end_weekend_work_hour' },
  { label: 'SALÁRIO', key: 'salary' },
  { label: 'DATA DE ADMISSÃO', key: 'admission_date' },
  { label: 'REGIONAL', key: 'regional' },
  { label: 'CARGO', key: 'office' },
  { label: 'CENTRO DE CUSTO', key: 'cost_center' },
  { label: 'BENEFÍCIOS', key: 'benefits' },
  { label: 'VALOR DIÁRIO (VALE REFEIÇÃO)', key: 'vr_value' },
  { label: 'TIPO DE PAGAMENTO (VA)', key: 'va_payment_type' },
  { label: 'VALOR DIÁRIO/MENSAL(VA)', key: 'va_value' },
  { label: 'VALOR MENSAL (ALUGUEL DE MOTO)', key: 'motorcycle_location_value' },
  { label: 'VALOR MENSAL (CASA-TRABALHO)', key: 'home_work_monthly_value_in_cents' },
  { label: 'KM DIÁRIO (SEG-SEX OU 12x36)', key: 'business_days_daily_km_in_meters' },
  { label: 'COMBUSTÍVEL OPERACIONAL DIÁRIO (SEG-SEX OU 12x36)', key: 'business_days_fuel_price_in_cents' },
  { label: 'VALOR DIÁRIO (PEDÁGIO)', key: 'toll_value' },
  { label: 'VALOR DIÁRIO (VT)', key: 'vt_value' },
  { label: 'VALOR MENSAL (AUXÍLIO COMBUSTÍVEL)', key: 'fuel_aid_value' },
  { label: 'STATUS', key: 'status' },

]

function formatValuesToReport(values: CltDriver[]) {
  return values?.map((cltDriver) => ({
    ...cltDriver,
    collaborator_name: `${cltDriver?.driver?.firstname} ${cltDriver?.driver?.lastname} `,
    cpf: cltDriver?.driver?.cpf,
    birth_date: cltDriver.birth_date
      ? format(new Date(cltDriver.birth_date), 'dd/MM/yyyy')
      : '-',
    status: cltDriverStatusMap[cltDriver?.status],
    days_of_work: cltDriver.days_of_work.map((weekDay) => captalize(format(setDay(new Date(), Number(weekDay)), 'eeee', {
      locale: ptBR
    }))),
    start_weekend_work_hour: cltDriver?.start_weekend_work_hour ? cltDriver?.start_weekend_work_hour : '-',
    end_weekend_work_hour: cltDriver?.end_weekend_work_hour ? cltDriver?.end_weekend_work_hour : '-',
    admission_date: cltDriver.admission_date
      ? format(new Date(cltDriver.admission_date), 'dd/MM/yyyy')
      : '-',
    benefits: cltDriver?.benefits.map(benefit => benefit).join(', '),
    vr_value: cltDriver?.vr_value ? cltDriver?.vr_value / 100 : '-',
    va_payment_type: cltDriver?.va_payment_type ? cltDriver?.va_payment_type === 'daily' ? 'DIÁRIO' : 'MENSAL' : '-',
    va_value: cltDriver?.va_value ? cltDriver?.va_value / 100 : '-',
    motorcycle_location_value: cltDriver?.motorcycle_location_value ? cltDriver?.motorcycle_location_value / 100 : '-',
    home_work_monthly_value_in_cents: cltDriver?.home_work_monthly_value_in_cents ? cltDriver?.home_work_monthly_value_in_cents / 100 : '-',
    business_days_daily_km_in_meters: cltDriver?.business_days_daily_km_in_meters ? cltDriver?.business_days_daily_km_in_meters / 100 : '-',
    business_days_fuel_price_in_cents: cltDriver?.business_days_fuel_price_in_cents ? cltDriver?.business_days_fuel_price_in_cents / 100 : '-',
    toll_value: cltDriver?.toll_value ? cltDriver?.toll_value / 100 : '-',
    vt_value: cltDriver?.vt_value ? cltDriver?.vt_value / 100 : '-',
    fuel_aid_value: cltDriver?.fuel_aid_value ? cltDriver?.fuel_aid_value / 100 : '-',
    salary: cltDriver?.salary / 100
  }))
}




export function CltDrivers() {

  const { userLogged } = useAuth()
  const history = useHistory()

  const doesUserCanViewCltDriver = userLogged?.permissions?.includes(
    'view-clt-drivers'
  )

  const doesUserCanCreateCltDriver = userLogged?.permissions?.includes(
    'create-clt-driver'
  )

  useEffect(() => {
    if (!doesUserCanViewCltDriver) history.push('/')
  }, [history, doesUserCanViewCltDriver])

  const searchParams = useSearchParams()

  const page = searchParams.get('page') ?? '1'
  const search = searchParams.get('search')


  const {
    data: cltDriversResult,
    isFetching: isFethingCltDriversResult
  } = useQuery({
    queryKey: [
      'clt-drivers',
      page,
      search
    ],
    queryFn: () => getCltDrivers({
      pageSize: 10,
      currentPage: Number(page),
      search
    }),
    keepPreviousData: true
  })

  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }

  const {
    data: cltDriversDataReportResult,
    isLoading: isLoadingCltDriversDataReportResult
  } = useQuery({
    queryKey: [
      'clt-drivers-report',
      page,
      search,
      cltDriversResult?.count,
    ],
    queryFn: () => getCltDrivers({
      currentPage: 1,
      pageSize: cltDriversResult?.count,
      search

    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: cltDriversResult?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: 10
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const csvReportProps = {
    data: cltDriversDataReportResult ? formatValuesToReport(cltDriversDataReportResult?.cltDrivers) : [],
    headers,
    filename: `motoristas-clt.csv`,
  }

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Motoristas CLT</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar kanban"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => history.push('/motoristas/clt/kanban')}
          />
          {doesUserCanCreateCltDriver && (
            <IconButton
              as={Link}
              aria-label=""
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              to="/motorista-clt/registrar"
            />

          )}
        </Flex>

      </Flex>

      <CltDriversTableFilter />

      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Motorista</Th>
              <Th color="white">Regional</Th>
              <Th color="white">Status</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {isFethingCltDriversResult ? (
              Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr key={String(index)}>
                    <Td>
                      <HStack spacing={3}>
                        <Skeleton w={8} h={8} />
                        <Skeleton w={8} h={8} />
                      </HStack>
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="180px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="30px" />
                    </Td>
                    <Td>
                      <SkeletonText skeletonHeight={3} noOfLines={1} w="130px" />
                    </Td>
                    <Td>
                      <Skeleton w={8} h={8} />
                    </Td>
                  </Tr>
                )
              })
            ) : (
              cltDriversResult?.cltDrivers?.map((cltDriver) => {
                return (
                  <CltDriverTableRow cltDriver={cltDriver} />
                )
              })
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {!cltDriversDataReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingCltDriversDataReportResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
