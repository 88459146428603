import * as yup from 'yup'

const recurrentServiceSchema = yup.object().shape({
  customer_id: yup.string().required('Campo obrigatório'),
  situation: yup.string().required('Campo Obrigatório'),
  budget_id: yup.string().required('Campo obrigatório'),
  source_address_id: yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
      case 'string':
        return yup.string().nullable().required('Campo Obrigatório')
      default:
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
    }
  }),
  destination_address_id: yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
      case 'string':
        return yup.string().nullable().required('Campo Obrigatório')
      default:
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
    }
  }),
  source_collector_id: yup.string().required('Campo Obrigatório'),
  destination_collector_id: yup.string().required('Campo Obrigatório'),
  source_branch_id: yup.string().when('service_type', {
    is: 'FRACIONADO',
    then: yup.string().required('Campo Obrigatório'),
  }),
  destination_branch_id: yup.string().when('service_type', {
    is: 'FRACIONADO',
    then: yup.string().required('Campo Obrigatório'),
  }),
  deadline: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0)
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  service_type: yup.string().required('Campo Obrigatório'),
  modal: yup.string().required('Campo Obrigatório'),
  vehicle: yup.string().required('Campo Obrigatório'),
  franchising: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0)
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  caixa_termica: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0)
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  embalagem_secundaria: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0)
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  gelo_seco: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0)
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  gelox: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0)
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  isopor3l: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0)
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  isopor7l: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0)
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  terciaria3l: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0)
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  terciaria8l: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0)
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  collect_hour_start: yup.string().required('Campo obrigatório'),
  collect_hour_end: yup.string().required('Campo obrigatório'),
  delivery_hour: yup.string().required('Campo obrigatório'),
  board_hour: yup.string().when('service_type', {
    is: 'FRACIONADO',
    then: yup.string().when('modal', {
      is: 'RODOVIÁRIO',
      then: yup.string().required('Campo Obrigatório'),
    }),
  }),
  board_date: yup
    .number()
    .nullable()
    .when('service_type', {
      is: 'FRACIONADO',
      then: yup
        .number()
        .nullable()
        .when('modal', {
          is: 'RODOVIÁRIO',
          then: yup
            .number()
            .typeError('Campo Obrigatório')
            .required('Campo Obrigatório'),
        }),
    }),
  availability_forecast_time: yup.string().when('service_type', {
    is: 'FRACIONADO',
    then: yup.string().when('modal', {
      is: 'RODOVIÁRIO',
      then: yup.string().required('Campo Obrigatório'),
    }),
  }),
  availability_forecast_deadline: yup
    .number()
    .nullable()
    .when('service_type', {
      is: 'FRACIONADO',
      then: yup
        .number()
        .nullable()
        .when('modal', {
          is: 'RODOVIÁRIO',
          then: yup
            .number()
            .typeError('Campo Obrigatório')
            .required('Campo Obrigatório'),
        }),
    }),
  material_type: yup.string().required('Campo obrigatório'),
  days_of_week: yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
      case 'string':
        return yup.string().nullable().required('Campo Obrigatório')
      default:
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
    }
  }),
  driver_address_assign: yup.array().of(yup.object().shape({
    driver_id: yup.string(),
    address_id: yup.string()
  })),
  driver_provider_assign: yup.array().of(yup.object().shape({
    driver_id: yup.string(),
    provider_id: yup.string()
  })),
  observation: yup.string(),
})

export { recurrentServiceSchema }
