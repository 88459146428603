import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetMultiBoardServicesResponse {
  boards: {
    service_id: string
    board_service_id: string
    franchising: number
    protocol: number
    price_kg_extra: number | null
    board_volume: number
    transfer_budget: number
    customer: string
    operational_number: string
    collects_total_volumes: number
    does_share_extra_kg: boolean
  }[]
}

interface GetMultiBoardServicesProps {
  queryParams: {
    servicesIds: string[]
  }
}

export async function getMultiBoardServices({
  queryParams
}: GetMultiBoardServicesProps) {
  try {
    const { data } = await api.get<GetMultiBoardServicesResponse>('/boards/multi-validations', {
      params: {
        ...queryParams
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
