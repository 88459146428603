import axios from "axios"
import { InternComunication } from "hooks/internComunication/dtos/InternComunication"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetInternComunicationsByOwnerIdProps {
  routeParams: {
    ownerId: string
  }
  queryParams: {
    pageSize: string
    currentPage: string
    customer?: string
    responsible?: string
    serviceProtocol?: string
    sourceHub?: string
    destinationHub?: string
    date?: string
    hour?: string
    timezoneOffset: number
    comunicationType?: string
    comunicationProtocol?: string
  }
}

interface GetInternComunicationsByOwnerIdResponse {
  internComunications: InternComunication[]
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
  }
}

export async function getInternComunicationsByOwnerId({
  routeParams,
  queryParams
}: GetInternComunicationsByOwnerIdProps) {
  try {
    const response = await api.get<GetInternComunicationsByOwnerIdResponse>(`/intern-comunications/owners/${routeParams.ownerId}`, {
      params: { ...queryParams }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
