import * as yup from "yup"
import { Box, Button, ButtonGroup, Divider, Heading, Stack } from "@chakra-ui/react";
import { MaterialRequest } from "hooks/materialRequests/dtos/MaterialRequest";
import { useFieldArray, useForm } from "react-hook-form";
import { MaterialRequestInfo } from "./MaterialRequestInfo";
import { MaterialRequestModal } from "./MaterialRequestModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToastify } from "hooks/toastify/useToastify";
import { useQueryClient } from "react-query";
import { useMaterialRequestsFunction } from "hooks/materialRequests/useMaterialRequestsFunctions";
import { Input } from "components/Inputs/Input";
import { TextArea } from "components/Inputs/TextInput";
import { Fragment, useEffect } from "react";
import { Select } from "components/Inputs/SelectInput";

interface DeliveryMaterialRequestFormInputs {
  deliveredProducts: {
    approvedQuantity: number
    productId: string
    quantity: number
  }[]
  deliveredObservations: string
}

interface DeliveryMaterialRequestProps {
  isOpen: boolean
  onClose: () => void
  materialRequest: MaterialRequest
}

const approveMaterialRequestSchema = yup.object().shape({
  deliveredProducts: yup.array().min(1).of(yup.object({
    productId: yup.string().required('Campo obrigatório'),
    approvedQuantity: yup.number(),
    quantity: yup.number().required('Campo obrigatório').max(yup.ref('approvedQuantity'), 'O valor excede a quantidade aprovada.').typeError('Insira um valor válido.'),
  })),
  deliveredObservations: yup.string().nullable()
})

export function DeliveryMaterialRequestModal({
  isOpen,
  onClose,
  materialRequest
}: DeliveryMaterialRequestProps) {

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<DeliveryMaterialRequestFormInputs>({
    defaultValues: {
      deliveredObservations: null,
    },
    resolver: yupResolver(approveMaterialRequestSchema)
  })

  const {
    fields: deliveredProductsFields,
    update,
  } = useFieldArray({
    control,
    name: 'deliveredProducts'
  })

  useEffect(() => {
    if (materialRequest) {
      materialRequest?.products?.forEach((product, index) => {
        update(index, {
          approvedQuantity: product.approved_quantity,
          productId: product.product.id,
          quantity: 0
        })
      })
    }
  }, [update, materialRequest])

  const {
    deliveryMaterialRequest
  } = useMaterialRequestsFunction()

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  async function handleDeliveryMaterialRequest(
    values: DeliveryMaterialRequestFormInputs
  ) {
    await promiseMessage(deliveryMaterialRequest.mutateAsync({
      materialRequestId: materialRequest.id,
      input: values
    }, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('materialRequests')
        onClose()
      }
    }), 'RSM entregue com sucesso!')
  }

  const productsSelectOptions = materialRequest?.products?.map((product) => {
    return {
      key: product.product.id,
      value: product.product.id,
      showOption: product.product.material.material_name
    }
  })


  return (
    <MaterialRequestModal.Root
      title="Entregar RSM"
      isOpen={isOpen}
      onClose={onClose}
    >
      <MaterialRequestModal.Content>
        <MaterialRequestInfo materialRequest={materialRequest} />

        <Divider />

        <Box
          as="form"
          onSubmit={handleSubmit(handleDeliveryMaterialRequest)}
          noValidate
          display="flex"
          flexDirection="column"
          gap="4"
        >
         <Stack
            spacing="4"
            direction={{
              base:
                'column'
            }}
            border="1px solid"
            borderColor={errors?.deliveredProducts ? "red.500" : "gray.200"}
            rounded="xl"
            py="3"
            px="4"
          >
            <Heading fontSize="lg" letterSpacing="tight">Produtos</Heading>

            {deliveredProductsFields.map((field, index) => {

              return (
                <Fragment key={field.id}>

                  <Divider borderColor="gray.200" />
                  <Stack spacing="4" direction={{ base: 'column', lg: 'row' }}>
                    <Select
                      {...register(`deliveredProducts.${index}.productId`)}
                      name={`deliveredProducts.${index}.productId`}
                      label="Produto"
                      options={productsSelectOptions}
                      error={
                        errors.deliveredProducts
                          ? errors.deliveredProducts[index]?.productId
                          : undefined
                      }
                      isDisabled
                      required
                    />

                    <Stack w="full" spacing="4" direction={{ base: 'column', lg: 'row' }}>
                    <Input
                        {...register(`deliveredProducts.${index}.approvedQuantity`)}
                        name={`deliveredProducts.${index}.ved`}
                        label="Qtde aprovada"
                        required
                        isDisabled
                      />

                      <Input
                        {...register(`deliveredProducts.${index}.quantity`)}
                        name={`deliveredProducts.${index}.quantity`}
                        type="number"
                        label="Qtde entregue"
                        error={errors?.deliveredProducts
                          ? errors?.deliveredProducts[index]?.quantity
                          : undefined
                        }
                        max={field.approvedQuantity}
                        required
                      />


                    </Stack>
                  </Stack>
                </Fragment>
              )
            })}
          </Stack>

          <TextArea
            {...register("deliveredObservations")}
            name="deliveredObservations"
            label="Observações"
            error={errors.deliveredObservations}
          />

          <ButtonGroup alignSelf="flex-end" >
            <Button
              type="submit"
              colorScheme="blue"
            >
              Aprovar
            </Button>
          </ButtonGroup>

        </Box>
      </MaterialRequestModal.Content>

    </MaterialRequestModal.Root>
  )
}
