import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface ReturnRequestedPatrimonyBody {
  defectiveOrBrokenDescription: string
  defectOrBrokenAttachmentId: string
  isDefectiveOrBroken: string
}

interface ReturnRequestedPatrimonyParams {
  body: ReturnRequestedPatrimonyBody,
  patrimonyRequestId: string
  patrimonyId: string
}

export async function returnRequestedPatrimony({
  body,
  patrimonyRequestId,
  patrimonyId
}: ReturnRequestedPatrimonyParams) {
  try {

    await api.patch(`patrimony-requests/${patrimonyRequestId}/patrimonies/${patrimonyId}/return`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

