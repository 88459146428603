import { Button, ButtonProps, Icon } from "@chakra-ui/react";
import { FaCheck, FaTimes } from "react-icons/fa";

interface ValidateNewServiceAddressActionProps extends ButtonProps {
  actionType: 'validate' | 'invalidate'
}

export function ValidateNewServiceAddressAction({
  actionType,
  ...rest
}: ValidateNewServiceAddressActionProps) {
  return (
    <>
      {actionType === 'validate' ? (
        <Button
          bgColor="green.400"
          _hover={{
            bgColor: "green.500"
          }}
          {...rest}
        >
          <Icon as={FaCheck} color='white' />
        </Button>
      ) : (
        <Button
          colorScheme="red"
          variant="outline"
          borderWidth="thin"
          borderColor='red.300'
          {...rest}
        >
          <Icon as={FaTimes} />
        </Button>
      )}
    </>
  )
}
