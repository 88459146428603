import { ViewBugModalComment } from "./ViewBugModalComment";
import { ViewBugModalCommentForm } from "./ViewBugModalCommentForm";
import { ViewBugModalContent } from "./ViewBugModalContent";
import { ViewBugModalContentHeader } from "./ViewBugModalHeader";
import { ViewBugModalRoot } from "./ViewBugModalRoot";

export const ViewBugModal = {
  Root: ViewBugModalRoot,
  Content: ViewBugModalContent,
  ContentHeader: ViewBugModalContentHeader,
  CommentForm: ViewBugModalCommentForm,
  Comment: ViewBugModalComment,
}
