import { Avatar, AvatarProps } from "@chakra-ui/react";

interface ExternalComunicationsMessageAvatarProps extends AvatarProps {
  name: string
}

export function ExternalComunicationsMessageAvatar({
  name,
  ...rest
}: ExternalComunicationsMessageAvatarProps) {
  return (
    <Avatar
      size="xs"
      mt="4"
      name={name}
      {...rest}
    />
  )
}
