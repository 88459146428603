import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, Spinner, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { getLandingsPendingValidations } from "api/service/landing/getLandingsPendingValidations";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { ValidatePendingLandingServicesRow } from "./components/ValidatePendingLandingServicesRow";

export function ValidatePendingLandingServices() {
  const { userLogged } = useAuth()
  const history = useHistory()

  const userCanValidateFinishedLandingWithoutQRCode = userLogged?.permissions.includes('validate-finished-landing-without-qr-code')

  useEffect(() => {
    if (!userCanValidateFinishedLandingWithoutQRCode) history.push('/')
  }, [history, userCanValidateFinishedLandingWithoutQRCode])


  const searchParams = useSearchParams()

  const page = searchParams.get('page') ?? '1'

  const { data: landingsPendingValidationsData, isLoading: isLandingsPendingValidationsLoading } =
    useQuery(['landings-validations', page], () => getLandingsPendingValidations({
      currentPage: page,
      pageSize: '10'
    }))

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: landingsPendingValidationsData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: 1
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  return (
    <Box
      bg="white"
      borderRadius="md"
      p="4"
    >
      <Flex
        w="full"
        direction="column"
        gap={6}
      >
        <Heading letterSpacing="tight">Validar Desembarques Sem QR Code</Heading>

        {isLandingsPendingValidationsLoading ? (
          <Spinner />
        ) : (
          <TableContainer
            rounded="md"
          >
            <Table
              size="sm"
            >
              <Thead
                h="40px"
                bg="#38c3fa"
              >
                <Tr>
                  <Th></Th>
                  <Th textColor="white">Protocolo</Th>
                  <Th textColor="white">Cliente</Th>
                  <Th textColor="white">Coletador de Destino</Th>
                  <Th textColor="white">Base de Destino</Th>
                </Tr>
              </Thead>
              <Tbody>
                {landingsPendingValidationsData?.landings.map((service) => {
                  return (
                    <ValidatePendingLandingServicesRow
                    key={service.id}
                    service={service} />
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />
      </Flex>
    </Box>
  )
}
