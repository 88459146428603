import { useEffect } from "react"
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { RegionForm, RegionFormInputs } from "./components/RegionForm";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAuth } from "hooks/auth/useAuth";
import { Flex, Button } from "@chakra-ui/react";
import { useRegionFunctions } from "hooks/regions/useRegionFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { useRegion } from "hooks/regions/useRegion";
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";

interface Params {
  slug: string
  id: string
}

const editRegionFormSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório."),
  acronym: yup.string().required("Campo obrigatório."),
});

export function EditViewRegion() {
  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  const { promiseMessage } = useToastify();
  const { slug, id } = useParams<Params>()

  const userHasPermissionEditRegions =
    userLogged?.permissions.includes("edit-regions");

  useEffect(() => {
    if (!userHasPermissionEditRegions && slug !== 'visualizar') {
      redirect('/')
    }
  }, [redirect, slug, userHasPermissionEditRegions])

  const { updateRegion: { mutateAsync: updateRegion } } = useRegionFunctions()


  const formMethods = useForm<RegionFormInputs>({
    resolver: yupResolver(editRegionFormSchema)
  })

  const {
    handleSubmit,
    setValue,
    formState: {
      isSubmitting
    }
  } = formMethods

  const isViewPage = slug === "vizualizar"
  const formTitle = isViewPage ? "Visualizar região" : "Editar região"

  const { data: region, isFetching: isFetchingRegion } = useRegion({ regionId: id, queryOptions: { enabled: !!id } })

  useEffect(() => {
    if (region !== undefined) {
      setValue('name', region?.region.name)
      setValue('acronym', region?.region.acronym)
    }
  }, [region, setValue])


  async function handleEditRegion({ ...values }: RegionFormInputs) {
    await promiseMessage(updateRegion({ input: values, regionId: id }, {
      onSuccess: () => {
        redirect("/regioes")
      }
    }),
      'Região atualizada com suecsso!')
  }


  if (isFetchingRegion) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm title={formTitle} onSubmit={handleSubmit(handleEditRegion)}>
      <FormProvider {...formMethods}>
        <RegionForm slug={slug} />
      </FormProvider>

      <Flex
        mt={6}
        gap={4}
        direction={["column", "column", "row"]}
        align="center"
        justify={["center", "center", "flex-end"]}
      >
        <Button
          as={Link}
          colorScheme='gray'
          w={['full', 'full', 'min']}
          isLoading={isSubmitting}
          to='/regioes'
        >
          Lista de Regiões
        </Button>

        {isViewPage ? (
          <Button
            as={Link}
            to={`/regioes/editar/${id}`}
            type="button"
            colorScheme="blue"
            w={["full", "full", "min"]}
            isLoading={isFetchingRegion}
          >
            Editar
          </Button>
        ) : (
          <Button
            type="submit"
            colorScheme="blue"
            w={["full", "full", "min"]}
            isLoading={isFetchingRegion || isSubmitting}
          >
            Salvar
          </Button>
        )}
      </Flex>
    </StandardBackgroundForm>
  )
}
