import { ExtrasAndDiscounts } from './dtos/ExtrasAndDiscounts';
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { useToastify } from 'hooks/toastify/useToastify';
import { useAuth } from 'hooks/auth/useAuth';
import { HttpErrorStatusCodeEnum } from 'utils/errors/HttpStatusCodeEnum';

interface CreateCustomerExtrasAndDiscountsReqFunctionParams {
  customer_id: string
  input: FormData
}
async function createCustomerExtrasAndDiscountsReqFunction({
  customer_id,
  input
}: CreateCustomerExtrasAndDiscountsReqFunctionParams) {
  try {
    await api.post(`/customer/${customer_id}/extras-and-discounts`, input)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado.')
    }
  }
}

interface UpdateCustomerExtraAndDiscountReqFunctionProps {
  extraAndDiscountId: string
  customerId: string
  input: FormData
}

async function updateCustomerExtraAndDiscountReqFunction({
  extraAndDiscountId,
  customerId,
  input
}: UpdateCustomerExtraAndDiscountReqFunctionProps) {
  try {
    await api.put(`/customers/${customerId}/extras-and-discounts/${extraAndDiscountId}`, input)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado.')
    }
  }
}

async function deleteCustomerExtraAndDiscountReqFunction(id: string) {
  try {
    await api.delete(`/extras-and-discounts/${id}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

async function finishCustomerExtraAndDiscountReqFunction(id: string) {
  try {
    await api.patch(`/extras-and-discounts/${id}/close`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

interface ExtrasAndDiscountsResponse {
  extrasDiscounts: Pick<ExtrasAndDiscounts, "id" | "customer" | "occurrence_date" | "route" | "value" | "description">[]
}

interface GenerateExtrasAndDiscountsReportProps {
  initialDate?: string
  finalDate?: string
  customerId: string
}

async function generateExtrasAndDiscountsReportReqFunction(
  input: GenerateExtrasAndDiscountsReportProps
): Promise<ExtrasAndDiscountsResponse> {
  try {
    const { data } = await api.post(`/report/extras-discounts/customer/closed`, input)
    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError(error.response.data.message)
    }
  }
}

export function useExtrasAndDiscountsFunctions() {

  const queryClient = useQueryClient()
  const { errorMessage } = useToastify()

  const { handleLogout } = useAuth()

  const onRequestSuccess = async () => {
    await queryClient.invalidateQueries("extrasAndDiscounts")
  }

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    errorMessage(message)

    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) await handleLogout()
  }

  const createCustomerExtrasAndDiscounts = useMutation(
    'createCustomerExtrasAndDiscounts',
    createCustomerExtrasAndDiscountsReqFunction
  )

  const updateCustomerExtraAndDiscount = useMutation(
    'updateCustomerExtraAndDiscount',
    updateCustomerExtraAndDiscountReqFunction
  )

  const deleteCustomerExtraAndDiscount = useMutation(
    'deleteCustomerExtraAndDiscount',
    deleteCustomerExtraAndDiscountReqFunction, {
    onSuccess: onRequestSuccess
  })

  const finishCustomerExtraAndDiscount = useMutation(
    'finishCustomerExtraAndDiscount',
    finishCustomerExtraAndDiscountReqFunction, {
    onSuccess: onRequestSuccess
  })

  const generateExtrasAndDiscountsReport = useMutation('extrasAndDiscountsReport', generateExtrasAndDiscountsReportReqFunction, {
    onError: onRequestError
  })

  return {
    createCustomerExtrasAndDiscounts,
    deleteCustomerExtraAndDiscount,
    finishCustomerExtraAndDiscount,
    updateCustomerExtraAndDiscount,
    generateExtrasAndDiscountsReport
  }
}
