import { useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import axios from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { InternComunicationReport, InternComunicationReportTransformedData } from "./dtos/InternComunicationReport";
import { addDays, set } from "date-fns";

interface InternComunicationReportQueryParams {
  start_filter: string
  end_filter: string
}

type QueryOptions = UseQueryOptions<InternComunicationReport[], unknown, InternComunicationReportTransformedData[], ["internComunicationReport"]>

interface UseInternComunicationReportOptions {
  queryOptions?: QueryOptions
  queryParams?: InternComunicationReportQueryParams
}

export function useInternComunicationReport(options?: UseInternComunicationReportOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function generateInternComunicationReportReqFunction({
    start_filter,
    end_filter
  }: InternComunicationReportQueryParams) {
    try {

      const startFilter = addDays(set(new Date(start_filter), {
        hours: 0,
        minutes: 0
      }), 1)


      const endFilter = addDays(set(new Date(end_filter), {
        hours: 23,
        minutes: 59
      }), 1)

      const { data } = await api.get(`/report/intern-comunication`, {
        params: {
          start_filter: startFilter,
          end_filter: endFilter
        }
      })

      return data
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response

        throw new RequestError(message, status)
      } else {
        throw new RequestError(error.response.data.message)
      }
    }
  }

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    errorMessage(message)

    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) await handleLogout()

  }

  return useQuery([
    "internComunicationReport",
    options.queryParams.start_filter,
    options.queryParams.end_filter
  ], () => generateInternComunicationReportReqFunction(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )
}
