import { GridItem, Skeleton } from "@chakra-ui/react"

export function InfoCardsLoading() {
  return (
    <>
      {Array.from({ length: 6 }).map((_, index) => {
        return (
          <GridItem key={index}>
            <Skeleton rounded="xl" h="238px" w="full" />
          </GridItem>
        )
      })}
    </>
  )
}
