import { Address } from 'hooks/address/dtos/Address';
import { useToastify } from 'hooks/toastify/useToastify';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query'
import axios from 'axios';
import { useAuth } from 'hooks/auth/useAuth';
import { api } from 'services/api'

import { RequestError } from "utils/errors/RequestErrors"

interface ServicesWithNewRequestedAddressesQueryParams {
  query?: string
  currentPage?: number
  pageSize?: number
}

interface ServiceWithNewRequestedAddresses {
  id: string
  protocol: string
  customerIDService:{
    id: string
    trading_firstname: string
  }
  serviceIDRequested: {
    id: string
    collect_date: string
    additional_delivery_addresses: string[],
    additional_collect_addresses: string[],
    sourceCollectorIDService: {
        id: string
        trading_name: string
    }
},
  newRequestedCollectAddresses: Address[]
  newRequestedDeliveryAddresses: Address[]
}
interface ServiceWithNewRequestedAddressesResponse {
  services: ServiceWithNewRequestedAddresses[]
  totalPages: number
}

type QueryOptions = UseQueryOptions<ServiceWithNewRequestedAddressesResponse, unknown, ServiceWithNewRequestedAddressesResponse, QueryKey>

interface useServiceWithNewRequestedAddressesProps {
  queryKey?: QueryKey
  queryParams?: ServicesWithNewRequestedAddressesQueryParams
  queryOptions?: QueryOptions
}


export function useServicesWithNewRequestedAddresses(options?: useServiceWithNewRequestedAddressesProps) {
  const { handleLogout } = useAuth()
  const { errorMessage } = useToastify()
  async function getServiceWithNewRequestedAddresses() {
    try {
      const { data } = await api.get(`/services/addresses/requested`, {
        params: {
          ...options?.queryParams
        }
      })
      return data
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response

        throw new RequestError(message, status)
      } else {
        throw new RequestError('Erro inesperado!')
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([options?.queryKey || 'servicesWithNewRequestedAddresses',
  { ...options?.queryParams }
  ], getServiceWithNewRequestedAddresses, {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )
}
