import { Flex, Box, Heading, Button, Icon } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { CollectorTable } from '../../components/Tables/Collector/CollectorTable'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useHub } from '../../hooks/hub/useHub'
import { useCollectors } from 'hooks/collector/useCollectors'

export function CollectorList() {
  const [isLoading, setIsLoading] = useState(true)

  const { userLogged } = useContext(AuthContext)

  const {
    hubs: { data: hubs, isLoading: isHubLoading },
  } = useHub(null, true, false)
  const {
    data: collectors, isLoading: isCollectorsLoading,
  } = useCollectors()

  const permissions = userLogged?.permissions

  useEffect(() => {
    function run() {
      if (!isCollectorsLoading && !isHubLoading) {
        setIsLoading(false)
      }
    }
    run()
  }, [isCollectorsLoading, isHubLoading])

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontFamily="poppins">
              Lista de Coletadores
            </Heading>
            {permissions !== undefined && (
              <Link to="/coletador/adicionar">
                {userLogged?.permissions.includes('add-collector') && (
                  <Button
                    colorScheme="gray"
                    rightIcon={<Icon as={FiPlus} />}
                    size="sm"
                  >
                    Adicionar Coletador
                  </Button>
                )}
              </Link>
            )}
          </Flex>
          {!!hubs && !!collectors && (
            <CollectorTable hubs={hubs} collectors={collectors} />
          )}
        </Box>
      </Flex>

  )
}
