import { useAuth } from "hooks/auth/useAuth"
import { useSwal } from "hooks/swal/useSwal"
import { useMutation, useQueryClient } from "react-query"
import { api } from "services/api"

export type AddressPersonTypes = 'PESSOA JURÍDICA' | 'PESSOA FÍSICA'
export type AddressIcmsTypes = 'ISENTO' | 'CONTRIBUINTE'
export type AddressSituationType = 'ATIVO' | 'INATIVO'

export interface AddressInput {
  customer_id: string
  type: AddressPersonTypes
  cnpj_cpf: string
  trading_name: string
  branch: string
  responsible_name: string
  responsible_email: string
  cep: string
  state: string
  city_id: string
  street: string
  number: string
  neighborhood: string
  complement: string
  reference_point: string
  icms: AddressIcmsTypes
  business_open: Date
  business_close: Date
  saturday_open?: Date | null
  saturday_close?: Date | null
  sunday_open?: Date | null
  sunday_close?: Date | null
  observation: string
  situation: AddressSituationType
  has_own_board_landing: boolean
  deadline: number
}

interface UpdateAddress {
  address_id: string
  input: AddressInput
}

interface RequestChangeAddress {
  address_id: string
  input: AddressInput
}

async function createAddressReqFunction(input: AddressInput) {
  try {
    await api.post("/address", input)
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function updateAddressReqFunction({ address_id, input }: UpdateAddress) {
  try {
    await api.put(`/address/${address_id}`, input)
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function requestChangeAddressReqFunction({ address_id, input }: RequestChangeAddress) {
  try {
    await api.put(`/address/${address_id}/request-changes`, input)
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function deleteAddressReqFunction(address_id: string) {
  try {
    await api.delete(`/address/${address_id}`)
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

export function useAddressesFunctions() {

  const { successMessage, errorMessage } = useSwal()
  const { handleLogout } = useAuth()
  const queryClient = useQueryClient()

  async function onSuccessRequest(message: string) {
    await queryClient.invalidateQueries("addresses")
    await queryClient.invalidateQueries("address")
    return successMessage(message)
  }

  async function onErrorRequest(message: string, status: number) {
    errorMessage(message)

    if (status === 401) await handleLogout()
  }

  const createAddress = useMutation("createAddress", createAddressReqFunction, {
    onSuccess: () => onSuccessRequest("Endereço criado com sucesso!"),
    onError: (error: any) => onErrorRequest(error.message, error.status)
  })
  const updateAddress = useMutation("updateAddress", updateAddressReqFunction, {
    onSuccess: () => onSuccessRequest("Endereço atualizado com sucesso!"),
    onError: (error: any) => onErrorRequest(error.message, error.status)
  })
  const deleteAddress = useMutation("deleteAddress", deleteAddressReqFunction, {
    onSuccess: () => onSuccessRequest("Endereço excluído com sucesso!"),
    onError: (error: any) => onErrorRequest(error.message, error.status)
  })
  const requestChangeAddress = useMutation("requestChangeAddress", requestChangeAddressReqFunction, {
    onSuccess: () => onSuccessRequest("Solicitação de alteração no endereço realizada com sucesso!"),
    onError: (error: any) => onErrorRequest(error.message, error.status)
  })

  return {
    createAddress,
    updateAddress,
    deleteAddress,
    requestChangeAddress
  }
}
