import { SubmitHandler } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'
import { HubForm } from './HubForm'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { AuthContext } from '../../contexts/AuthContext'
import { useHubFunctions } from '../../hooks/hub/useHubFunctions'
import { useSwal } from '../../hooks/swal/useSwal'

interface FormInputProps {
  name: string
  state: string
  situation: string
  regional: number
  timezone: string
  hub_responsible: string
  observation: string
}

interface QueryParamProps {
  slug: string
}

export function HubAdd() {
  const [isLoading, setIsLoading] = useState(false)

  const { slug }: QueryParamProps = useParams()
  const {
    postHub: { mutate, isLoading: isMutationLoading, isError },
  } = useHubFunctions()
  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useContext(AuthContext)

  const { push: redirectTo } = useHistory()

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (slug === 'adicionar') {
            if (!permissions?.includes('add-hub')) {
              redirectTo('/')
            }
          }
        }
      } catch {
        redirectTo('/')
      }
    }
    run()
  }, [slug, redirectTo, userLogged])

  const handleCreateHub: SubmitHandler<FormInputProps> = async (values) => {
    const response = await confirmMessage({ title: 'Deseja criar um Hub?' })

    if (response) {
      mutate(values)
      if (isMutationLoading) {
        setIsLoading(true)
      }
      if (isError) {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (
    <HubForm
      title="Adicionar HUB"
      action="Salvar"
      slug={slug}
      submit={handleCreateHub}
      href=""
    />
  )
}
