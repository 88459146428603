import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { getActivesFeedbacks } from "api/feedbacks/getActivesFeedbacks";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaExchangeAlt, FaPlus } from "react-icons/fa";
import { useInfiniteQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { ActivesFeedbacksTableFilter } from "./components/ActivesFeedbacksTableFilter";
import { CreateActiveFeedback } from "./components/CreateActiveFeedback";
import { KanbanActiveFeedbackColumn } from "./components/KanbanActiveFeedbackColumn";


export function ActivesFeedbacks() {
  const { userLogged } = useAuth()
  const history = useHistory()
  const searchParams = useSearchParams()

  const name = searchParams.get('name')
  const dueDate = searchParams.get('dueDate')

  const userCanViewActivesFeedbacks = userLogged?.permissions?.includes(
    'view-actives-feedbacks'
  )

  const userCanCreateActiveFeedback = userLogged?.permissions?.includes(
    'create-active-feedback'
  )

  useEffect(() => {
    if (!userCanViewActivesFeedbacks) history.push('/')
  }, [history, userCanViewActivesFeedbacks])


  const {
    data: scheduledActivesFeedbacksResult,
    isLoading: isScheduledActivesFeedbacksResultLoading,
    hasNextPage: hasNextPageScheduledActivesFeedbacks,
    fetchNextPage: fetchNextPageScheduledActivesFeedbacks,
    isFetchingNextPage: isFetchingNextPageScheduledActivesFeedbacks,
  } = useInfiniteQuery({
    queryKey: ['scheduled-actives-feedbacks', name, dueDate],
    queryFn: ({ pageParam = 1 }) => getActivesFeedbacks({
      currentPage: pageParam,
      pageSize: 10,
      status: 'scheduled',
      name,
      dueDate
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined;
      return nextPage;
    },
    keepPreviousData: true,
  });


  const {
    data: directLeaderApprovingFeedbacksResult,
    isLoading: isDirectLeaderApprovingFeedbacksLoading,
    hasNextPage: hasNextPageDirectLeaderApprovingFeedbacks,
    fetchNextPage: fetchNextPageDirectLeaderApprovingFeedbacks,
    isFetchingNextPage: isFetchingNextPageDirectLeaderApprovingFeedbacks,
  } = useInfiniteQuery({
    queryKey: ['direct-leader-approving-feedbacks', name, dueDate],
    queryFn: ({ pageParam = 1 }) => getActivesFeedbacks({
      currentPage: pageParam,
      pageSize: 10,
      status: 'direct-leader-approving',
      name,
      dueDate
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined;
      return nextPage;
    },
    keepPreviousData: true,
  });


  const {
    data: executiveLeaderApprovingFeedbacksResult,
    isLoading: isExecutiveLeaderApprovingFeedbacksLoading,
    hasNextPage: hasNextPageExecutiveLeaderApprovingFeedbacks,
    fetchNextPage: fetchNextPageExecutiveLeaderApprovingFeedbacks,
    isFetchingNextPage: isFetchingNextPageExecutiveLeaderApprovingFeedbacks,
  } = useInfiniteQuery({
    queryKey: ['executive-leader-approving-feedbacks', name, dueDate],
    queryFn: ({ pageParam = 1 }) => getActivesFeedbacks({
      currentPage: pageParam,
      pageSize: 10,
      status: 'executive-leader-approving',
      name,
      dueDate
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined;
      return nextPage;
    },
    keepPreviousData: true,
  });


  const {
    data: awaitingLeaderActivesFeedbacksResult,
    isLoading: isAwaitingLeaderActivesFeedbacksLoading,
    hasNextPage: hasNextPageAwaitingLeaderActivesFeedbacks,
    fetchNextPage: fetchNextPageAwaitingLeaderActivesFeedbacks,
    isFetchingNextPage: isFetchingNextPageAwaitingLeaderActivesFeedbacks,
  } = useInfiniteQuery({
    queryKey: ['awaiting-leader-actives-feedbacks', name, dueDate],
    queryFn: ({ pageParam = 1 }) => getActivesFeedbacks({
      currentPage: pageParam,
      pageSize: 10,
      status: 'awaiting-leader-feedback',
      name,
      dueDate
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined;
      return nextPage;
    },
    keepPreviousData: true,
  });


  const {
    data: doneActivesFeedbacksResult,
    isLoading: isDoneActivesFeedbacksLoading,
    hasNextPage: hasNextPageDoneActivesFeedbacks,
    fetchNextPage: fetchNextPageDoneActivesFeedbacks,
    isFetchingNextPage: isFetchingNextPageDoneActivesFeedbacks,
  } = useInfiniteQuery({
    queryKey: ['done-actives-feedbacks', name, dueDate],
    queryFn: ({ pageParam = 1 }) => getActivesFeedbacks({
      currentPage: pageParam,
      pageSize: 10,
      status: 'done',
      name,
      dueDate
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined;
      return nextPage;
    },
    keepPreviousData: true,
  });


  const columnsActivesFeedbacks = [
    {
      title: 'Avaliação Programada',
      activesFeedbacks: scheduledActivesFeedbacksResult?.pages.flatMap((page) => page.activesFeedbacks) || [],
      activesFeedbacksCount: scheduledActivesFeedbacksResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageScheduledActivesFeedbacks,
      fetchNextPage: fetchNextPageScheduledActivesFeedbacks,
      isFetchingNextPage: isFetchingNextPageScheduledActivesFeedbacks,
      isLoading: isScheduledActivesFeedbacksResultLoading,
    },
    {
      title: 'Avaliação do Líder Direto',
      activesFeedbacks: directLeaderApprovingFeedbacksResult?.pages.flatMap((page) => page.activesFeedbacks) || [],
      activesFeedbacksCount: directLeaderApprovingFeedbacksResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageDirectLeaderApprovingFeedbacks,
      fetchNextPage: fetchNextPageDirectLeaderApprovingFeedbacks,
      isFetchingNextPage: isFetchingNextPageDirectLeaderApprovingFeedbacks,
      isLoading: isDirectLeaderApprovingFeedbacksLoading,
    },
    {
      title: 'Aprovação do Diretor Executivo',
      activesFeedbacks: executiveLeaderApprovingFeedbacksResult?.pages.flatMap((page) => page.activesFeedbacks) || [],
      activesFeedbacksCount: executiveLeaderApprovingFeedbacksResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageExecutiveLeaderApprovingFeedbacks,
      fetchNextPage: fetchNextPageExecutiveLeaderApprovingFeedbacks,
      isFetchingNextPage: isFetchingNextPageExecutiveLeaderApprovingFeedbacks,
      isLoading: isExecutiveLeaderApprovingFeedbacksLoading,
    },
    {
      title: 'Aguardando Feedback do Líder',
      activesFeedbacks: awaitingLeaderActivesFeedbacksResult?.pages.flatMap((page) => page.activesFeedbacks) || [],
      activesFeedbacksCount: awaitingLeaderActivesFeedbacksResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageAwaitingLeaderActivesFeedbacks,
      fetchNextPage: fetchNextPageAwaitingLeaderActivesFeedbacks,
      isFetchingNextPage: isFetchingNextPageAwaitingLeaderActivesFeedbacks,
      isLoading: isAwaitingLeaderActivesFeedbacksLoading,
    },
    {
      title: 'Concluído',
      activesFeedbacks: doneActivesFeedbacksResult?.pages.flatMap((page) => page.activesFeedbacks) || [],
      activesFeedbacksCount: doneActivesFeedbacksResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageDoneActivesFeedbacks,
      fetchNextPage: fetchNextPageDoneActivesFeedbacks,
      isFetchingNextPage: isFetchingNextPageDoneActivesFeedbacks,
      isLoading: isDoneActivesFeedbacksLoading,
    },
  ];


  const {
    isOpen: isCreateActiveFeedbackOpen,
    onOpen: onOpenCreateActiveFeedback,
    onClose: onCloseCreateActiveFeedback
  } = useDisclosure()

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Feedbacks Ativos</Heading>

        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar tabela"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => history.push('/feedbacks-ativos/tabela')}
          />
          {userCanCreateActiveFeedback && (
            <IconButton
              aria-label="Criar feedback"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateActiveFeedback}
            />
          )}
        </Flex>
        <Modal
          isOpen={isCreateActiveFeedbackOpen}
          onClose={onCloseCreateActiveFeedback}
          isCentered
          size="4xl"
        >
          <ModalOverlay />
          <CreateActiveFeedback onCloseModal={onCloseCreateActiveFeedback} />
        </Modal>
      </Flex>

      <ActivesFeedbacksTableFilter isKanbanPage={true} />

      <Flex
        w="full"
        gap={4}
        overflowX="auto"
        mt={4}
        pb={4}
        whiteSpace="nowrap"
      >
        {columnsActivesFeedbacks.map((activeFeedback, index) => (
          <KanbanActiveFeedbackColumn
            key={index}
            activeFeedbacks={activeFeedback}
          />
        ))}
      </Flex>
    </Box>
  )
}
