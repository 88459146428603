import * as yup from "yup";
import { Button, Flex, Icon, Spinner } from "@chakra-ui/react";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { useDriver } from "hooks/driver/useDriver";
import { FormProvider, useForm } from "react-hook-form";
import { CltDriversForm, CltDriversFormInputs } from "./components/CltDriversForm";
import { Link, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaList, FaSave } from "react-icons/fa";
import { useCltDriverFunctions } from "hooks/cltDriver/useCltDriverFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";


const registerCltDriverFormSchema = yup.object().shape({
  driver_id: yup.string().required('Campo obrigatório'),
  cellphone: yup.string().required('Campo obrigatório'),
  rg: yup.string().required('Campo obrigatório'),
  birth_date: yup.string().required('Campo obrigatório'),
  address: yup.string().required('Campo obrigatório'),
  days_of_work: yup.array().of(yup.string()).min(1, 'Campo obrigatório'),
  start_work_hour: yup.string().required('Campo obrigatório'),
  end_work_hour: yup.string().required('Campo obrigatório'),
  start_interval_hour: yup.string().required('Campo obrigatório'),
  end_interval_hour: yup.string().required('Campo obrigatório'),
  weekend_work: yup.string().required('Campo obrigatório'),
  start_weekend_work_hour: yup.string().when('weekend_work', {
    is: 'yes',
    then: yup.string().required('Campo Obrigatório'),
  }),
  end_weekend_work_hour: yup.string().when('weekend_work', {
    is: 'yes',
    then: yup.string().required('Campo Obrigatório'),
  }),
  salary: yup.string().required('Campo obrigatório'),
  family_salary: yup.string().required('Campo obrigatório'),
  admission_date: yup.string().required('Campo obrigatório'),
  regional: yup.string().required('Campo obrigatório'),
  office: yup.string().required('Campo obrigatório'),
  cost_center: yup.string().required('Campo obrigatório'),
  benefits: yup.array().of(yup.string()),
  email: yup.string().required('Campo obrigatório'),
  vr_value: yup.string().when('benefits', {
    is: (value: string[]) => value?.includes('VR'),
    then: yup.string().required('Campo obrigatório'),
  }),
  va_payment_type: yup.string().when('benefits', {
    is: (value: string[]) => value?.includes('VA'),
    then: yup.string().required('Campo obrigatório'),
  }),
  va_value: yup.string().when('benefits', {
    is: (value: string[]) => value?.includes('VA'),
    then: yup.string().required('Campo obrigatório'),
  }),
  motorcycle_location_value: yup.mixed().when('benefits', {
    is: (value: string[]) => value?.includes('ALUGUEL DE MOTO'),
    then: yup.string().required('Campo obrigatório'),
  }),
  home_work_monthly_value: yup.string().when('benefits', {
    is: (value: string[]) => value?.includes('COMBUSTÍVEL CASA-TRABALHO'),
    then: yup.string().required('Campo obrigatório'),
  }),
  business_days_daily_km: yup.string().when(['benefits', 'days_of_work'], {
    is: (benefitsValue: string[], daysOfWorkValue: string[]) => benefitsValue?.includes('COMBUSTÍVEL OPERACIONAL') && daysOfWorkValue?.some(day => Number(day) >= 1 && Number(day) <= 5),
    then: yup.string().required('Campo obrigatório'),
  }),
  business_days_fuel_price: yup.string().when(['benefits', 'days_of_work'], {
    is: (benefitsValue: string[], daysOfWorkValue: string[]) => benefitsValue?.includes('COMBUSTÍVEL OPERACIONAL') && daysOfWorkValue?.some(day => Number(day) >= 1 && Number(day) <= 5),
    then: yup.string().required('Campo obrigatório'),
  }),
  weekend_days_daily_km: yup.string().when(['benefits', 'days_of_work'], {
    is: (benefitsValue: string[], daysOfWorkValue: string[]) => benefitsValue?.includes('COMBUSTÍVEL OPERACIONAL') && daysOfWorkValue?.some(day => Number(day) === 0 || Number(day) === 6),
    then: yup.string().required('Campo obrigatório'),
  }),
  weekend_days_fuel_price: yup.string().when(['benefits', 'days_of_work'], {
    is: (benefitsValue: string[], daysOfWorkValue: string[]) => benefitsValue?.includes('COMBUSTÍVEL OPERACIONAL') && daysOfWorkValue?.some(day => Number(day) === 0 || Number(day) === 6),
    then: yup.string().required('Campo obrigatório')
  }),
  cellphone_cost_help: yup.mixed().when('benefits', {
    is: (value: string[]) => value?.includes('AJUDA DE CUSTO CELULAR'),
    then: yup.string().required('Campo obrigatório'),
  }),
  toll_value: yup.string().when('benefits', {
    is: (value: string[]) => value?.includes('PEDÁGIO'),
    then: yup.string().required('Campo obrigatório'),
  }),
  vt_value: yup.string().when('benefits', {
    is: (value: string[]) => value?.includes('VT'),
    then: yup.string().required('Campo obrigatório'),
  }),
  fuel_aid_value: yup.string().when('benefits', {
    is: (value: string[]) => value?.includes('AUXÍLIO COMBUSTÍVEL'),
    then: yup.string().required('Campo obrigatório'),
  }),
})

export function RegisterCltDriver() {
  const { push: redirect } = useHistory()
  const { userLogged } = useAuth()

  const userHasCreateCltDriverPermission = userLogged?.permissions.includes('create-clt-driver')

  useEffect(() => {
    if (!userHasCreateCltDriverPermission) {
      redirect('/')
    }
  }, [userHasCreateCltDriverPermission, redirect])

  const {
    drivers: {
      data: drivers,
      isFetching: isFetchingDrivers
    }
  } = useDriver(null, true)

  const formMethods = useForm<CltDriversFormInputs>({
    resolver: yupResolver(registerCltDriverFormSchema),
    defaultValues: {
      days_of_work: []
    }
  })

  const {
    registerCltDriver: {
      mutateAsync: registerCltDriver,
    }
  } = useCltDriverFunctions()

  const { handleSubmit } = formMethods

  const { promiseMessage } = useToastify()

  async function handleRegisterCltDriver(values: CltDriversFormInputs) {
    await promiseMessage(
      registerCltDriver({
        ...values,
        days_of_work: values.days_of_work.map(Number),
        motorcycle_location_value: values.motorcycle_location_value ? Math.ceil(transformStringToNumber(values.motorcycle_location_value) * 100) : undefined,
        business_days_daily_km_in_meters: values.business_days_daily_km ? Math.ceil(transformStringToNumber(values.business_days_daily_km) * 1000) : null,
        business_days_fuel_price_in_cents: values.business_days_fuel_price ? Math.ceil(transformStringToNumber(values.business_days_fuel_price) * 100) : null,
        weekend_days_daily_km_in_meters: values.weekend_days_daily_km ? Math.ceil(transformStringToNumber(values.weekend_days_daily_km) * 1000) : null,
        weekend_days_fuel_price_in_cents: values.weekend_days_fuel_price ? Math.ceil(transformStringToNumber(values.weekend_days_fuel_price) * 100) : null,
        cellphone_cost_help: values.cellphone_cost_help ? Math.ceil(transformStringToNumber(values.cellphone_cost_help) * 100) : null,
        home_work_monthly_value_in_cents: values.home_work_monthly_value ? Math.ceil(transformStringToNumber(values.home_work_monthly_value) * 100) : null
      }, {
        onSuccess: () => redirect('/motoristas-clt')
      }),
      'Motorista clt cadastrado com sucesso!'
    )
  }

  return (
    <StandardBackgroundForm
      title='Cadastrar motorista clt'
      onSubmit={handleSubmit(handleRegisterCltDriver)}
    >
      {isFetchingDrivers ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <CltDriversForm drivers={drivers} />
        </FormProvider>
      )}
      <Flex
        mt={4}
        gap={4}
        w='full'
        align='center'
        direction={['column', 'column', 'row']}
        justify={['center', 'center', 'flex-end']}
      >
        <Button
          w={['full', 'full', 'min']}
          as={Link}
          variant='ghost'
          to='/motoristas-clt'
          leftIcon={<Icon as={FaList} />}
        >
          Lista de motoristas clt
        </Button>
        <Button
          w={['full', 'full', 'min']}
          type='submit'
          colorScheme='blue'
          leftIcon={<Icon as={FaSave} />}
        >
          Cadastrar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}
