import axios from "axios";
import { ExtraDiscount, ExtraDiscountStatus } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

export interface GetCollectorsExtrasDiscountsParams {
  status?: ExtraDiscountStatus
  pageSize?: number
  currentPage?: number
  collector?: string
  startDate?: string
  endDate?: string
}

export interface GetCollectorsExtrasDiscountsResponse {
  extrasDiscounts: ExtraDiscount[]
  totalPages: number
  count: number
}

export async function getCollectorsExtrasDiscounts({
  status,
  pageSize,
  currentPage,
  collector,
  startDate,
  endDate,
}: GetCollectorsExtrasDiscountsParams) {
  try {
    const response = await api.get<GetCollectorsExtrasDiscountsResponse>('/list/collectors/extras-discounts', {
      params: {
        status,
        pageSize,
        currentPage,
        collector,
        startDate,
        endDate,
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
