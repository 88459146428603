import { usePagination } from "@ajna/pagination";
import { Box, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { getTransferBudgetDeviationReport } from "api/service/getTransferBudgetDeviationReport";
import { Pagination } from "components/Pagination/Pagination";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { TransferBudgetDeviationReportFilters } from "./components/TransferBudgetDeviationReportTableFilters";


const headers = [
  { label: 'Protocolo', key: 'protocol' },
  { label: 'Cliente', key: 'customer' },
  { label: 'Data da coleta', key: 'collectDate' },
  { label: 'Cidade(s) origem', key: 'sourceCities' },
  { label: 'Cidade(s) destino', key: 'destinationCities' },
  { label: 'Volumes', key: 'volumes' },
  { label: 'Peso franquia', key: 'franchising' },
  { label: 'Peso taxado', key: 'taxedWeight' },
  { label: 'Valor CTE', key: 'cteValue' },
  { label: 'Budget', key: 'budget' },
  { label: 'Desvio', key: 'detour' },
]


export function TransferBudgetDeviationReport() {
  const [isConsultEnabled, setIsConsultEnabled] = useState(false)


  const { userLogged } = useAuth()
  const searchParams = useSearchParams()
  const history = useHistory()

  const startDate = searchParams.get('startDate')
  const finalDate = searchParams.get('finalDate')

  const userCanViewTransferBudgetDeviationReport = userLogged?.permissions.includes('view-transfer-budget-deviation-report')

  useEffect(() => {
    if (!userCanViewTransferBudgetDeviationReport) history.push('/')
  }, [history, userCanViewTransferBudgetDeviationReport])

  const {
    data: transferBudgetDeviationReportResult,
    isLoading: isLoadingTransferBudgetDeviationReportResult,
  } = useQuery({
    queryKey: ['shippings-costs-report', startDate, finalDate],
    queryFn: () => getTransferBudgetDeviationReport({
      queryParams: {
        finalDate,
        startDate,
      },
    }),
    onSuccess: () => {
      setIsConsultEnabled(false)
    },
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    enabled: !!startDate && !!finalDate && isConsultEnabled
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
    offset,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
    pagesCount: transferBudgetDeviationReportResult ? Math.ceil(transferBudgetDeviationReportResult?.length / 10) : 0
  })

  const csvPropsData = transferBudgetDeviationReportResult
    ? transferBudgetDeviationReportResult?.map(service => {

      const cteValue = service.boards.reduce((acc, curr) => acc += curr.cteTransferCost, 0)
      const taxedWeight = service.boards.reduce((acc, curr) => acc += curr.taxedWeight, 0) / service.boards.length

      const extraKg = taxedWeight > service.requestedService.budget.franchising
        ? Math.ceil(taxedWeight - service.requestedService.budget.franchising)
        : 0

      const budget = service.requestedService.budget.transferBudget + ((extraKg * service.requestedService.budget.extraKgPrice) * 0.6)

      return {
        ...service,
        collectDate: format(new Date(service.collectDate), 'dd/MM/yyyy'),
        sourceCities: service.sourceAddresses ? Array.from(new Set(service.sourceAddresses.map((address) => address.city.name))).join(', ') : '',
        destinationCities: service.destinationAddresses ? Array.from(new Set(service.destinationAddresses.map((address) => address.city.name))).join(', ') : '',
        volumes: service.boards.map((board) => board.boardVolume).join(', '),
        franchising: service.requestedService.budget.franchising,
        customer: service.customer.tradingFirstname,
        taxedWeight: taxedWeight.toFixed(2).toLocaleString(),
        cteValue: cteValue.toFixed(2).toLocaleString(),
        budget: budget.toFixed(2).toLocaleString(),
        detour: (budget - cteValue).toFixed(2).toLocaleString(),
      }
    })
    : []

  const csvProps = {
    data: csvPropsData,
    headers,
    filename: `relatorio-desvio-budget-de-transferencia-${new Date().toISOString()}.csv`,
  }

  const handleChangePage = (page: number) => setCurrentPage(page)

  return (
    <Box
      p="6"
      bg="white"
      rounded="md"
    >
      <Heading letterSpacing="tight">Relatório de Desvio de Budget de Transferência</Heading>

      <TransferBudgetDeviationReportFilters
        csvProps={csvProps}
        isLoading={isLoadingTransferBudgetDeviationReportResult}
        onSetIsConsultEnabled={setIsConsultEnabled}
      />
      {transferBudgetDeviationReportResult && (
        <TableContainer mt="6" rounded="md">
          <Table
            size="sm"
          >
            <Thead>
              <Tr
                h="40px"
                bg="#38c3fa"
              >
                <Th color="white">PROTOCOLO</Th>
                <Th color="white">CLIENTE</Th>
              </Tr>
            </Thead>
            <Tbody>
              {transferBudgetDeviationReportResult?.slice(offset, offset + 10).map((service, index) => {


                return (
                  <Tr key={index}>
                    <Td>{service.protocol}</Td>
                    <Td>{service.customer.tradingFirstname}</Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Pagination
        handlePageChange={handleChangePage}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </Box>
  )
}
