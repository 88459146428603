import axios from 'axios';
import { useAuth } from 'hooks/auth/useAuth';
import { useMutation } from 'react-query';
import { api } from 'services/api';
import { HttpErrorStatusCodeEnum } from 'utils/errors/HttpStatusCodeEnum';
import { RequestError } from 'utils/errors/RequestErrors';

interface CreateMaterialRequestProps {
  warehouseId: string
  input: {
    products: {
      productId: string
      quantity: number
    }[]
    deliveryLimitDate: string
    observations: string
  }
}

interface ApproveMaterialRequestProps {
  materialRequestId: string
  input: {
    approvedProducts: {
      productId: string
      quantity: number
    }[]
    approvedObservations: string
  }
}

interface DeliveryMaterialRequestProps {
  materialRequestId: string
  input: {
    deliveredProducts: {
      productId: string
      quantity: number
    }[]
    deliveredObservations: string
  }
}

async function createMaterialRequestFn({
  warehouseId,
  input
}: CreateMaterialRequestProps) {
  try {
    await api.post(`/warehouses/${warehouseId}/request/material`, input)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado")
    }
  }
}

export function useMaterialRequestsFunction() {
  const { handleLogout } = useAuth()

  const createMaterialRequest = useMutation("createMaterialRequest", createMaterialRequestFn,
    {
      onError: async (error: RequestError) => {
        if (error.httpCode === HttpErrorStatusCodeEnum["HttpUnauthorized"]) {
          await handleLogout()
        }
      },
    })

  const approveMaterialRequest = useMutation("approveMaterialRequest", async ({
    materialRequestId,
    input
  }: ApproveMaterialRequestProps) => {
    try {
      await api.patch(`/material-requests/${materialRequestId}/approve`, input)
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response

        throw new RequestError(message, status)
      } else {
        throw new RequestError("Erro inesperado")
      }
    }
  }, {
    onError: async (error: RequestError) => {
      if (error.httpCode === HttpErrorStatusCodeEnum["HttpUnauthorized"]) {
        await handleLogout()
      }
    },
  })

  const reproveMaterialRequest = useMutation("reproveMaterialRequest", async (
    materialRequestId: string
  ) => {
    try {
      await api.patch(`/material-requests/${materialRequestId}/reprove`)
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response

        throw new RequestError(message, status)
      } else {
        throw new RequestError("Erro inesperado")
      }
    }
  }, {
    onError: async (error: RequestError) => {
      if (error.httpCode === HttpErrorStatusCodeEnum["HttpUnauthorized"]) {
        await handleLogout()
      }
    },
  })

  const deliveryMaterialRequest = useMutation("deliveryMaterialRequest", async ({
    materialRequestId,
    input
  }: DeliveryMaterialRequestProps) => {
    try {
      await api.patch(`/material-requests/${materialRequestId}/delivery`, input)
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response

        throw new RequestError(message, status)
      } else {
        throw new RequestError("Erro inesperado")
      }
    }
  }, {
    onError: async (error: RequestError) => {
      if (error.httpCode === HttpErrorStatusCodeEnum["HttpUnauthorized"]) {
        await handleLogout()
      }
    },
  })


  return { createMaterialRequest, approveMaterialRequest, reproveMaterialRequest, deliveryMaterialRequest }
}
