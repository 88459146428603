import { Button, Flex, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { deletePrice } from "api/prices/deletePrice";
import { GetPricesResponse } from "api/prices/getPrices";
import { Price } from "api/prices/_types/Price";
import { PriceLogsModal } from "components/Form/Price/components/PriceLogsModal";
import { useAuth } from "hooks/auth/useAuth";
import { useCity } from "hooks/city/useCity";
import { useHub } from "hooks/hub/useHub";
import { useSwal } from "hooks/swal/useSwal";
import { useToastify } from "hooks/toastify/useToastify";
import { FaFileWord, FaPen, FaSearch, FaTrash } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { captalize } from "utils/captalize";
import { statesWithUfList } from "utils/CustomLists/statesWithUfList";
import { EditPrice } from "./EditPrice";
import { PriceDetails } from "./PriceDetails";

interface PriceTableRowProps {
  price: Price
}

export function PriceTableRow({ price }: PriceTableRowProps) {
  const { userLogged } = useAuth()
  const { confirmMessage, standardMessage } = useSwal()

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const userCanDeletePrice = userLogged?.permissions.includes('remove-price')
  const userCanEditPrice = userLogged?.permissions.includes('edit-price')

  const {
    hubs: { data: hubs },
  } = useHub(null, true)

  const {
    cities: { data: cities },
  } = useCity(null, true)


  const { mutateAsync: deletePriceFn } = useMutation({
    mutationFn: deletePrice,
    onSuccess(_data, { priceId }) {
      const cachedPrices = queryClient.getQueriesData<GetPricesResponse>({
        queryKey: ['prices']
      })

      cachedPrices.forEach(([cachedKey, cachedData]) => {
        if (!cachedData) {
          return null
        }

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          prices: cachedData?.prices?.filter((cachedPrice) => {
            return cachedPrice.id !== priceId
          })
        })

      })
    },
  })


  async function handleDeletePrice(id: string) {
    const hasConfirmed = await confirmMessage({
      title: 'Deseja excluir um preço?',
    })

    if (hasConfirmed) {
      await promiseMessage(deletePriceFn({
        priceId: id
      }), 'Preço excluído!')


    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const {
    isOpen: isPriceDetailModalOpen,
    onToggle: onTogglePriceDetailModal,
  } = useDisclosure()

  const {
    isOpen: isEditPriceOpen,
    onToggle: onToggleEditPrice,
  } = useDisclosure()

  const {
    isOpen: isOpenPriceLogsModal,
    onOpen: onOpenPriceLogsModal,
    onClose: onClosePriceLogsModal
  } = useDisclosure()


  return (
    <Tr key={price.id}>
      <Td>
        <Flex align="center" gap={3}>
          <IconButton
            aria-label="Visualizar Preço"
            icon={<FaSearch />}
            size="sm"
            variant="ghost"
            onClick={onTogglePriceDetailModal}
          />
          {userCanEditPrice && (
            <IconButton
              aria-label="Editar preço"
              icon={<FaPen />}
              variant="ghost"
              size="sm"
              onClick={onToggleEditPrice} />
          )}
        </Flex>
        <Modal
          isOpen={isPriceDetailModalOpen}
          onClose={onTogglePriceDetailModal}
          size='6xl'
        >
          <ModalOverlay />
          <PriceDetails
            priceId={price?.id}
          />
        </Modal>
        <Modal
          isOpen={isEditPriceOpen}
          onClose={onToggleEditPrice}
          size="4xl"
        >
          <ModalOverlay />

          <EditPrice
            priceId={price.id}
            onClose={onToggleEditPrice} />

        </Modal>
      </Td>
      <Td>
        {captalize(
          Object.entries(statesWithUfList)
            .filter(
              ([key, value]) =>
                key.toUpperCase() === price.sourceCity.state,
            )
            .map(([key, value]) => value.uf)[0]
        )}
      </Td>
      <Td>
        {captalize(price.sourceCity.name.toUpperCase())}
      </Td>
      <Td>
        {captalize(
          Object.entries(statesWithUfList)
            .filter(
              ([key, value]) =>
                key.toUpperCase() ===
                price.destinationCity.state,
            )
            .map(([key, value]) => value.uf)[0])
        }
      </Td>
      <Td>
        {captalize(price.destinationCity.name.toUpperCase())}
      </Td>
      <Td>
        {price.airMinimumPrice !== null
          ? price.airMinimumPrice
          : '-'}
      </Td>
      <Td>
        {price.airDeadline !== null
          ? `D + ${price.airDeadline}`
          : '-'
        }
      </Td>
      <Td>
        {price.airExtraKg !== null ? price.airExtraKg : '-'}
      </Td>
      <Td>
        {price.highwayMinimumPrice !== null
          ? price.highwayMinimumPrice
          : '-'}
      </Td>
      <Td>
        {price.highwayDeadline !== null
          ? `D + ${price.highwayDeadline}`
          : '-'
        }
      </Td>
      <Td>
        {price.highwayExtraKg !== null
          ? price.highwayExtraKg
          : '-'}
      </Td>
      <Td>
        {price.category}
      </Td>
      <Td>
        {
          price.category === 'Rápido'
            ? price.routeType === "AÉREO" || price.routeType === "AMBOS"
              ? '2 KG'
              : '-'
            : price.category === 'Econômico'
              ? price.routeType === "AÉREO" || price.routeType === "AMBOS"
                ? '10 KG'
                : '-'
              : '-'
        }
      </Td>
      <Td>
        {
          price.category === 'Rápido'
            ? price.routeType === "RODOVIÁRIO" || price.routeType === "AMBOS"
              ? '10 KG'
              : '-'
            : price.category === 'Econômico'
              ? price.routeType === "RODOVIÁRIO" || price.routeType === "AMBOS"
                ? '10 KG'
                : '-'
              : '-'
        }
      </Td>
      <Td>{captalize(price.vehicle)}</Td>
      <Td>{price.situation === 'active' ? 'Ativo' : 'Inativo'}</Td>
      <Td>{price.createdByUser !== null ? captalize(price?.createdByUser?.firstname) + " " + captalize(price?.createdByUser?.lastname) : "-"}</Td>
      <Td>{price?.serviceType ? captalize(price.serviceType): '-'}</Td>
      <Td >
        {userCanDeletePrice && (
          <Button
            lineHeight="1"
            leftIcon={<FaTrash />}
            size="xs"
            onClick={() => handleDeletePrice(price.id)}
          >
            Excluir
          </Button>
        )}
        {cities && hubs && (
          <Button
            lineHeight="1"
            leftIcon={<FaFileWord />}
            size="xs"
            onClick={onOpenPriceLogsModal}
            ml={3}
          >
            Logs
          </Button>
        )}

        <PriceLogsModal
          isOpen={isOpenPriceLogsModal}
          onClose={onClosePriceLogsModal}
          cities={cities}
          hubs={hubs}
          priceId={price.id}
        />

      </Td>
    </Tr>
  )
}
