import {
  Flex,
  Box,
  Heading,
  Divider,
  Button,
  Stack,
  Alert,
  AlertIcon,
  Icon,
  Text,
} from '@chakra-ui/react'
import { useEffect, useContext, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useHistory, useLocation, useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { useForm } from 'react-hook-form'

import {
  AllocatedServiceContext,
} from '../../../contexts/AllocateServiceContext'

import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { TextArea } from '../../../components/Inputs/TextInput'
import { Input } from '../../../components/Inputs/Input'

import AllocateAccordion from '../../../components/Accordions/Service/AllocateAccordion/AllocateAccordion'


import { allocatedServiceFormatDataToBack } from '../../../utils/ServiceFunctions/Allocated/allocateServiceFormatDataToBack'
import { AuthContext } from '../../../contexts/AuthContext'

import { useService } from '../../../hooks/services/service'

import { FiPlus } from 'react-icons/fi'
import { useSwal } from '../../../hooks/swal/useSwal'
import { formatDate } from 'utils/DateFunctions/formatDate'

interface IFormInputProps {
  collector_id: string
  allocate_flight: string
  allocate_availability_date: string
  allocate_availability_hour: string
  airline_information: string
  observation: string
}

interface QueryParams {
  service_id: string
  slug: string
}

interface ILocationProps {
  state: {
    collector_id: string
  }
}

interface IAllocateServiceFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
}

const userSchema = yup.object().shape({
  allocate_availability_date: yup.string().required('Campo Obrigatório'),
  allocate_availability_hour: yup.string().required('Campo Obrigatório'),
  airline_information: yup.mixed().when('$isFormWithTicket', {
    is: true,
    then: yup.string().required('Campo obrigatório')
  })
})

export function AllocatedService({
  isDisabled = false,
}: IAllocateServiceFormProps) {
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isPlannedRouteValid, setIsPlannedRouteValid] = useState(false)

  const { createAllocatedService, editCurrentAllocatedService } = useContext(
    AllocatedServiceContext,
  )
  const { userLogged } = useContext(AuthContext)

  const { confirmMessage, standardMessage } = useSwal()

  const { service_id, slug }: QueryParams = useParams()
  const { state }: ILocationProps = useLocation()
  const { push: redirectTo } = useHistory()

  const location = useLocation()
  const resState = location.state as { path?: string }
  const hasPathRedirect = resState?.path !== undefined && resState?.path !== null

  const {
    service: { data: service, isLoading: isServiceLoading },
  } = useService(service_id, false, false)

  const serviceModal = service?.serviceIDRequested.modal

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(userSchema),
    context: {
      isFormWithTicket: !isPlannedRouteValid && serviceModal === 'AÉREO'
    }
  })

  useEffect(() => {
    function run() {
      if (!isServiceLoading) {
        setIsLoadingPage(false)
      }
    }
    run()
  }, [isServiceLoading])

  useEffect(() => {
    if (state?.collector_id && service) {
      return setValue('collector_id', state?.collector_id)
    }

    const serviceHasCrossdocking = service?.serviceIDRequested.crossdocking_collector_id !== null

    if (!serviceHasCrossdocking) {
      setValue('collector_id', service?.serviceIDRequested.source_collector_id)
    } else {
      const isCrossdockingAllocateFinished = service?.serviceIDAllocate.find(allocate => {
        return allocate.collector_id === service?.serviceIDRequested.crossdocking_collector_id
      })

      if (isCrossdockingAllocateFinished) {
        setValue('collector_id', service?.serviceIDRequested.source_collector_id)

      } else {
        setValue('collector_id', service?.serviceIDRequested.crossdocking_collector_id)
      }
    }
  }, [
    state,
    service,
    setValue,
  ])

  useEffect(() => {
    if (!userLogged?.permissions?.includes('add-allocated-service')) {
      redirectTo('/')
    }
  }, [redirectTo, userLogged])

  useEffect(() => {
    if (slug && service) {
      const allocatedService = service.serviceIDAllocate.find(allocate => {
        return allocate.collector_id === state.collector_id
      })

      setValue(
        'allocate_flight',
        allocatedService.allocate_flight !== null
          ? allocatedService.allocate_flight
          : ' ',
      )
      setValue(
        'allocate_availability_date',
        formatDate.handle(
          allocatedService.allocate_availability_date, "DateWithoutHourToInput"
        ),
      )
      setValue(
        'allocate_availability_hour',
        formatDate.handle(
          allocatedService.allocate_availability_hour, "DateOnlyWithHourMinute"
        ),
      )
      setValue('observation', allocatedService.observation)
    }
  }, [setValue, slug, service, state])

  async function handleCreateAllocateService(values: IFormInputProps) {
    const hasAllocateService = await confirmMessage({
      title: 'Deseja alocar um serviço?',
    })

    if (hasAllocateService) {
      setIsLoading(true)
      try {
        const formattedValues = allocatedServiceFormatDataToBack(values)
        const createAllocateServiceResponse = await createAllocatedService({
          service_id,
          ...formattedValues,
        })

        if (createAllocateServiceResponse && userLogged) {
          if(hasPathRedirect){
            redirectTo(resState?.path)
          }else{
            redirectTo('/servicos/alocar')
          }
        } else {
          setIsLoading(false)
        }
      } catch (err) {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const handleUpdateAllocatedService = async (values: IFormInputProps) => {
    if (slug) {
      const hasUpdateAllocatedService = await confirmMessage({
        title: 'Deseja atualizar um serviço alocado?',
      })

      if (hasUpdateAllocatedService) {
        setIsLoading(true)
        try {
          const formattedValues = allocatedServiceFormatDataToBack(values)
          const updateAllocatedServiceResponse =
            await editCurrentAllocatedService(service_id, {
              service_id,
              ...formattedValues
            })

          if (updateAllocatedServiceResponse && userLogged) {
            redirectTo(`/servico/editar/${service_id}`)
          }
        } catch (err) {
          setIsLoading(false)
        }
      } else {
        standardMessage('Ação cancelada com êxito!')
      }
    }
  }


  const isCrossdockingAllocation = service?.serviceIDRequested.crossdocking_collector_id ?
    !service.serviceIDAllocate.find(allocate => {
      return allocate.collector_id === service.serviceIDRequested.crossdocking_collector_id
    })
    : false

  const availabilityForecastDate = isCrossdockingAllocation
    ? service?.serviceIDRequested.crossdocking_availability_forecast_day
    : service?.serviceIDRequested.availability_forecast_day

  const availabilityForecastHour = isCrossdockingAllocation
    ? service?.serviceIDRequested.crossdocking_availability_forecast_time
    : service?.serviceIDRequested.availability_forecast_time

  const plannedRoute = isCrossdockingAllocation
    ? service?.serviceIDRequested.crossdocking_planned_flight
    : service?.serviceIDRequested.planned_flight

  useEffect(() => {
    if (isPlannedRouteValid) {

      setValue('allocate_flight', plannedRoute)

      const formattedAvailabilityForecastDate = formatDate.handle(
        availabilityForecastDate, 'DateWithoutHourToInput'
      )

      setValue('allocate_availability_date', formattedAvailabilityForecastDate)

      const formattedAvailabilityForecastHour = formatDate.handle(
        availabilityForecastHour, 'DateOnlyWithHourMinute'
      )

      setValue('allocate_availability_hour', formattedAvailabilityForecastHour)
    }
  }, [
    setValue,
    plannedRoute,
    isPlannedRouteValid,
    availabilityForecastDate,
    availabilityForecastHour
  ])

  const handleValidatePlannedRouteDateHour = () => setIsPlannedRouteValid(true)
  const handleInvalidatePlannedRouteDateHour = () => setIsPlannedRouteValid(false)

  if (isLoading || isLoadingPage) {
    return <GeneralContentLoading />
  }

  return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(
            slug ? handleUpdateAllocatedService : handleCreateAllocateService,
          )}
          noValidate
        >
          <Flex
            align="center"
            justify="space-between"
            direction={['column', 'column', 'row']}
          >
            <Heading size="lg" fontFamily="poppins">
              Alocar Serviço
            </Heading>
            {/* {userLogged?.permissions.includes("add-occurrence") && ( */}
            <Link
              to={{
                pathname: '/ocorrencia/criar',
                state: {
                  service_id,
                },
              }}
            >
              <Button
                colorScheme="gray"
                rightIcon={<Icon as={FiPlus} />}
                size="sm"
                mt={['2', '2', '0']}
              >
                Adicionar Ocorrência
              </Button>
            </Link>
            {/* )} */}
          </Flex>

          <Divider my="6" borderColor="gray.700" />

          {service && <AllocateAccordion serviceByID={service} />}

          <Alert status="info">
            <AlertIcon />
            Informações do serviço!
          </Alert>

          <Divider my="6" borderColor="gray.700" />

          <Stack spacing={6} direction={['column', 'column', 'row']} w='full'>
            <Input
              name='planned_route'
              label='Viagem planejada'
              defaultValue={plannedRoute}
              isDisabled
            />
            <Stack spacing={2} w='full'>
              <Text>Voo alocado corresponde ao planejado?</Text>
              <Stack direction='row' w='full' spacing={4}>
                <Button
                  w='full'
                  h={12}
                  onClick={handleValidatePlannedRouteDateHour}
                >
                  Sim
                </Button>
                <Button
                  w='full'
                  h={12}
                  onClick={handleInvalidatePlannedRouteDateHour}
                >
                  Não
                </Button>
              </Stack>
            </Stack>
          </Stack>

          {serviceModal === 'AÉREO' && !isPlannedRouteValid && (
            <Stack mt={4} spacing={6} direction={['column', 'column', 'row']} w='full'>
              <Input
                {...register('allocate_flight')}
                isDisabled={isDisabled}
                name="allocate_flight"
                error={errors.allocate_flight}
                label="Voo Alocado"
                required
              />
              <Input
                {...register('airline_information')}
                isDisabled={isDisabled}
                name="airline_information"
                error={errors.airline_information}
                label="Ticket/Email"
                required
              />
            </Stack>
          )}

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Input
              {...register('allocate_availability_date')}
              isDisabled={isDisabled}
              name="allocate_availability_date"
              type="date"
              error={errors.allocate_availability_date}
              label="Data Prevista para Disponibilidade"
              required
            />

            <Input
              {...register('allocate_availability_hour')}
              name="allocate_availability_hour"
              label="Horário Previsto para Disponibilidade"
              error={errors.allocate_availability_hour}
              type="time"
              isDisabled={isDisabled}
              required
            />
          </Stack>

          <TextArea
            {...register('observation')}
            name="observation"
            label="Observações"
            isDisabled={isDisabled}
          />

          <Flex mt="8" justify={['center', 'center', 'flex-end']}>
            <Stack w='full' spacing={2} direction={['column', 'column', 'row']}>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
                loadingText="Carregando"
                w={['full', 'full', 'min']}
              >
                {slug ? 'Atualizar Serviço Alocado' : 'Alocar Serviço'}
              </Button>

              <Button
                as={Link}
                to="/servicos/alocar"
                type="button"
                colorScheme="gray"
                w={['full', 'full', 'min']}
              >
                Lista de Serviços à Alocar
              </Button>
            </Stack>
          </Flex>
        </Box>
      </Flex>

  )
}
