import { Box, Flex } from "@chakra-ui/react";
import { OnboardingStatus as OnboardingStatusTypes } from "api/onboardings/onboarding";
import { onboardingStatusMap } from "utils/onboardingMappers";

interface OnboardingStatusProps {
  status: OnboardingStatusTypes;
}

export function OnboardingStatus({ status }: OnboardingStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {['inviting', 'training', 'testing'].includes(status) && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'finished' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'inactive' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {onboardingStatusMap[status]}
    </Flex>
  )
}
