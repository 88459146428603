import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Document } from "./types/Document";

interface GetDocumentsReportProps {
  queryParams: {
    status: string
  }
}

export interface GetDocumentsReportResponse {
  documents: Array<Document & {
    documentAttachments: {
      id: string
      version: number
      status: 'active' | 'inactive'
      created_at: string
      updated_at: string
      createdAt: string
      updatedAt: string
      attachment_id: string
      document_id: string
      attachment: Attachment
    }[]
  }
  >
}

export async function getDocumentsReport({
  queryParams
}: GetDocumentsReportProps) {
  try {
    const { data } = await api.get<GetDocumentsReportResponse>('/documents/report', {
      params: { ...queryParams }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

