import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Vacation } from "./_types/Vacation";
import { VacationPeriod } from "./_types/VacationPeriod";
import { VacationPeriodPaymentAttachment } from "./_types/VacationPeriodPaymentAttachment";

interface GetVacationParams {
  vacationId: string
}

interface GetVacationResponse {
  vacation: Vacation & {
    periods: Array<VacationPeriod & {
      receiptAttachment: Attachment
      paymentAttachments: VacationPeriodPaymentAttachment[]
    }>
  }
}

export async function getVacation({ vacationId }: GetVacationParams) {
  try {
    const response = await api.get<GetVacationResponse>(`/vacations/${vacationId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
