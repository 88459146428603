export function occurrenceStatusDict(occurrenceStatus: string) {
  switch (occurrenceStatus) {
    case 'AWAITING-PROCEDURE':
      return 'EM ANDAMENTO'
    case 'OCCURRENCE-FINALIZED':
      return 'FINALIZADA'
    default:
      return '-'
  }
}
