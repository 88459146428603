import { Button, Flex, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner } from "@chakra-ui/react";

import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { ProductForm, ProductFormInputs } from "./ProductForm";
import { FormProvider, useForm } from "react-hook-form";
import { useMaterials } from "hooks/material/useMaterials";
import { useGeneralProviders } from "hooks/generalProvider/useGeneralProviders";
import { useWarehouses } from "hooks/warehouse/useWarehouses";
import { useToastify } from "hooks/toastify/useToastify";
import { editProduct } from "api/products/editProduct";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getProduct } from "api/products/getProduct";
import { useEffect } from "react";

interface EditProductProps {
  onClose: () => void
  productId: string
}

const editProductSchema = yup.object().shape({
  situation: yup.string().required(),
  material_id: yup.string().required(),
  general_provider_id: yup.string().required(),
  warehouse_id: yup.string().required(),
  measure: yup.string().required(),
  unit_cost: yup.string().required(),
  min_stock: yup.number().required(),
  standard_stock: yup.number().required(),
  current_stock: yup.number().required(),
})

export function EditProduct({ onClose, productId }: EditProductProps) {
  const formMethods = useForm<ProductFormInputs>({
    resolver: yupResolver(editProductSchema)
  })

  const { formState: { isSubmitting }, handleSubmit, setValue } = formMethods

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const {
    data: productResult,
    isLoading: isLoadingProductResult
  } = useQuery({
    queryKey: ['product', productId],
    queryFn: () => getProduct({ productId })
  })

  useEffect(() => {
    if (productResult !== undefined) {
      setValue('situation', productResult?.product?.situation)
      setValue('material_id', productResult?.product?.material_id)
      setValue('general_provider_id', productResult?.product?.general_provider_id)
      setValue('warehouse_id', productResult?.product?.warehouse_id)
      setValue('measure', productResult?.product?.measure)
      setValue('unit_cost', productResult?.product?.unit_cost / 100)
      setValue('min_stock', productResult?.product?.min_stock)
      setValue('standard_stock', productResult?.product?.standard_stock)
      setValue('current_stock', productResult?.product?.current_stock)
    }

  }, [productResult, setValue])

  const { data: materials, isFetching: isFetchingMaterials } = useMaterials()
  const { data: generalProviders, isFetching: isFetchingGeneralProviders } = useGeneralProviders()
  const { data: warehouses, isFetching: isFetchingWarehouses } = useWarehouses()

  const { mutateAsync: editProductFn } = useMutation({
    mutationFn: editProduct,
    onSuccess(_data, _variables, _context) {

      queryClient.invalidateQueries({ queryKey: 'products' })
      onClose()
    }
  })

  async function handleEditProduct(values: ProductFormInputs) {
    await promiseMessage(editProductFn({
      body: values,
      productId
    }), 'Produto editado com sucesso!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Editar Produto
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        maxH='600px'
        overflowY='scroll'
        onSubmit={handleSubmit(handleEditProduct)}
      >
        {(isFetchingMaterials && isFetchingGeneralProviders && isFetchingWarehouses && isLoadingProductResult) ? (
          <Spinner />
        ) : (
          <FormProvider {...formMethods}>
            <ProductForm
              slug="editar"
              materials={materials}
              generalProviders={generalProviders}
              warehouses={warehouses}
            />
          </FormProvider>
        )}
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Editar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
