import { Box, Flex } from "@chakra-ui/react";
import { CltDriverStatus as CltDriverStatusTypes } from "api/cltDrivers/_types/CltDriver";
import { cltDriverStatusMap } from "utils/cltDriverMappers";

interface CltDriverStatusProps {
  status: CltDriverStatusTypes;
}

export function CltDriverStatus({ status }: CltDriverStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {(status.includes('hiring') || status.includes('dismissing')) && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {(status === 'active' || status === 'moved') && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'dismissed' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {cltDriverStatusMap[status]}
    </Flex>
  )
}
