import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Flex, Heading, Stack } from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { useAuth } from "hooks/auth/useAuth";
import { Input } from "components/Inputs/Input";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { formatDate } from "utils/DateFunctions/formatDate";
import { add } from "date-fns";
import { useToastify } from "hooks/toastify/useToastify";
import { useNonInactiveCustomers } from "hooks/customer/useNonInactiveCustomers";
import { Select } from "components/Inputs/SelectInput";
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";
import { ClientManagementResponse, useClientManagementReport } from "hooks/report/useClientManagementReport";
import { handleChangeUrl } from "utils/handleChangeUrl";

interface ClientManagementReportInputs {
  initialDate: string
  finalDate: string
  customerId: string
}

const ClientManagementReportHeaders = [
  { key: 'responsible_name', label: 'RESPONSÁVEL' },
  { key: 'street', label: 'RUA' },
  { key: 'neighborhood', label: 'BAIRRO' },
  { key: 'number', label: 'NÚMERO' },
  { key: 'city', label: 'CIDADE' },
  { key: 'state', label: 'ESTADO' },
  { key: 'cep', label: 'CEP' },
  { key: 'type', label: 'TIPO' },
  { key: 'protocol', label: 'PROTOCOLO' },
  { key: 'content_declarations', label: 'DECLARAÇÕES DE CONTEÚDO' },
  { key: 'temp', label: 'TEMPERATURA' }
]

const schema = yup.object().shape({
  customerId: yup.string().required('Campo obrigatório'),
  initialDate: yup.string().required('Campo obrigatório!'),
  finalDate: yup.string().required('Campo obrigatório!')
})

function formatClientManagementReportData(clientManagementData?: ClientManagementResponse) {
  return clientManagementData?.data?.map(clientManagement => {
    return {
      ...clientManagement,
      content_declarations: clientManagement?.content_declarations
        ? typeof clientManagement?.content_declarations === 'string'
          ? JSON.parse(clientManagement?.content_declarations).map((attachment) => {
            return `${handleChangeUrl(attachment?.filename)} \n`
          }).join('\n')
          : clientManagement?.content_declarations.map((attachment) => {
            return `${handleChangeUrl(attachment.filename)} \n`
          }).join('\n')
        : '-'
    }
  })
}

export function ClientManagementReport() {

  const { promiseMessage } = useToastify()

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<ClientManagementReportInputs>({
    resolver: yupResolver(schema),
  })

  const [
    initialDateFilter,
    finalDateFilter
  ] = useWatch({
    control,
    name: ['initialDate', 'finalDate']
  })

  const isValidStartDate = new Date(initialDateFilter) instanceof Date &&
    !isNaN(new Date(initialDateFilter).getMilliseconds())

  const maxEndFilter = isValidStartDate
    ? formatDate.handle(add(new Date(initialDateFilter), {
      days: 31
    }), 'DateWithoutHourToInput')
    : ''

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-customer-managment-report')) {
      redirect('/')
    }
  }, [userLogged, redirect])

  const {
    data: customersData,
    isFetching: isCustomersDataFetching
  } = useNonInactiveCustomers()

  useEffect(() => {
    if (
      (userLogged?.user_type === 'CLIENTE' || userLogged?.user_type === 'REMETENTE') &&
      customersData
    ) {
      setValue('customerId', userLogged?.customer_id)
    }
  }, [setValue, userLogged, customersData])

  const { generateClientManagementReport: {
    mutateAsync: generateClientManagementReport,
    isLoading: isGenerateClientManagementReportDataLoading,
    data: clientManagementData
  } } = useClientManagementReport()

  async function handleGenerateClientManagementReport({ initialDate, finalDate, customerId }: ClientManagementReportInputs) {
    await promiseMessage(
      generateClientManagementReport({
        initialDate,
        finalDate,
        customerId
      }), "Relatório gerado com sucesso!"
    )
  }

  const csvReportFilename = `relatorio-gerencial-de-clientes-${initialDateFilter}-${finalDateFilter}`

  const csvReportProps = {
    data: clientManagementData !== undefined ? formatClientManagementReportData(clientManagementData) : [],
    headers: ClientManagementReportHeaders,
    filename: csvReportFilename,
  }

  if (isCustomersDataFetching) {
    return <GeneralContentLoading />
  }

  return (
    <Box
      bg="white"
      borderRadius="md"
      p="4"
    >
      <Heading letterSpacing="tight">Relatório Gerencial de Cliente</Heading>
      <Flex
        mt={4}
        gap={4}
        direction='column'
        as="form"
        onSubmit={handleSubmit(handleGenerateClientManagementReport)}
      >
        <Stack
          w='full'
          spacing={4}
          direction={['column', 'column', 'row']}
        >
          <Select
            {...register("customerId")}
            name="customerId"
            label="Cliente"
            placeholder="Selecione uma opção..."
            customers={customersData?.customers}
            error={errors.customerId}
            isDisabled={userLogged?.user_type === 'CLIENTE' || userLogged?.user_type === 'REMETENTE'}
            required
          />
        </Stack>
        <Stack
          w='full'
          spacing={4}
          direction={['column', 'column', 'row']}
        >
          <Input
            {...register('initialDate')}
            name='initialDate'
            label='Data inicial do filtro'
            type='date'
            error={errors.initialDate}
            required
          />

          <Input
            {...register('finalDate')}
            name='finalDate'
            label='Data final do filtro'
            type='date'
            error={errors.finalDate}
            max={maxEndFilter}
            required
          />
        </Stack>

        <Flex gap={2} justify={['center', 'center', 'flex-end']}>
          <Button
            isLoading={isGenerateClientManagementReportDataLoading}
            type='submit'
            w={['full', 'full', 'min']}
            colorScheme='blue'
          >
            Gerar relatório
          </Button>
          {clientManagementData !== undefined && (
            <GenerateExcelReportButton
              mt={-4}
              csvReportProps={csvReportProps}
              isLoading={isGenerateClientManagementReportDataLoading} />
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
