import { Box, Flex, FormLabel, Heading, IconButton, Text, useDisclosure } from "@chakra-ui/react"
import { StandardInternComunicationModal } from "components/InternComunications/components/StandardInternComunicationModal"
import { FaPlus } from "react-icons/fa"


export function OperationalInconsistency() {

  const {
    isOpen: isStandardInternComunicationModalOpen,
    onOpen: onOpenStandardInternComunicationModal,
    onClose: onCloseStandardInternComunicationModal
  } = useDisclosure()

  return (
    <Box
      w="full"
      as="form"
    >
      <Heading letterSpacing="tight" size='sm'>Operacional Inconsistência</Heading>
      <Flex mt={3} alignItems="baseline" justifyContent="space-between">
        <FormLabel fontSize='sm'>
          Abrir comunicação interna
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <IconButton
          aria-label="Abrir atendimento"
          icon={<FaPlus />}
          colorScheme="blue"
          size="sm"
          onClick={onOpenStandardInternComunicationModal} />

      </Flex>
      <StandardInternComunicationModal
        isOpen={isStandardInternComunicationModalOpen}
        onClose={onCloseStandardInternComunicationModal}
      />
    </Box>
  )
}
