import * as yup from "yup"
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm"
import { FormProvider, useForm } from "react-hook-form"
import { Link, useHistory, useParams } from "react-router-dom"
import { CrmForm, CrmFormInputs } from "./components/CrmForm"
import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Flex, Spinner } from "@chakra-ui/react"
import { validateFileSize, validateHasFile } from "utils/imageValidation"
import { useCrm } from "hooks/crms/useCrm"

interface ViewCrmParams {
  crmId: string
}

const createCrmSchema = yup.object().shape({
  type: yup.string().required('Campo obrigatório'),
  customerId: yup.string().required('Campo obrigatório'),
  meetingDate: yup.mixed().when('type', {
    is: 'REUNIÃO',
    then: yup.string().required('Campo obrigatório'),
  }),
  meetingParticipants: yup.mixed().when('type', {
    is: 'REUNIÃO',
    then: yup.string().required('Campo obrigatório'),
  }),
  meetingSummary: yup.mixed().when('type', {
    is: 'REUNIÃO',
    then: yup.string().required('Campo obrigatório'),
  }),
  meetingAttachment: yup.mixed().when('type', {
    is: 'REUNIÃO',
    then: yup.mixed()
      .test('hasFile', 'Campo obrigatório', value => validateHasFile(value))
      .test('fileSize', 'É suportado o upload de imagem somente até 10Mb', value => validateFileSize(value)),
  }),
  invoiceNumber: yup.mixed().when('type', {
    is: 'INADIMPLÊNCIA',
    then: yup.string().required('Campo obrigatório'),
  }),
  originalInvoiceExpirationDate: yup.mixed().when('type', {
    is: 'INADIMPLÊNCIA',
    then: yup.string().required('Campo obrigatório'),
  }),
  paymentForecastDate: yup.mixed().when('type', {
    is: 'INADIMPLÊNCIA',
    then: yup.string().required('Campo obrigatório'),
  }),
  rncDate: yup.mixed().when('type', {
    is: 'RNC DE CLIENTE',
    then: yup.string().required('Campo obrigatório'),
  }),
  rncDescription: yup.mixed().when('type', {
    is: 'RNC DE CLIENTE',
    then: yup.string().required('Campo obrigatório'),
  }),
  noteDate: yup.mixed().when('type', {
    is: 'ANOTAÇÃO',
    then: yup.string().required('Campo obrigatório'),
  }),
  noteDescription: yup.mixed().when('type', {
    is: 'ANOTAÇÃO',
    then: yup.string().required('Campo obrigatório'),
  }),
})

export function ViewCrm() {
  const formMethods = useForm<CrmFormInputs>({
    resolver: yupResolver(createCrmSchema)
  })

  const { push: redirect } = useHistory()
  const { crmId } = useParams<ViewCrmParams>()
  const {
    data: crmData,
    isFetching: isFetchingCrmData
  } = useCrm({
    crmId
  })

  const { handleSubmit } = formMethods


  return (
    <StandardBackgroundForm
      title='Criar Crm'
      onSubmit={handleSubmit(() => { })}
    >
      <FormProvider {...formMethods}>
        {isFetchingCrmData ? (
          <Spinner />
        ) : (
          <CrmForm slug='view' crm={crmData?.crm} />
        )}
      </FormProvider>

      <Flex justify='flex-end' mt={4}>
        <Button
          as={Link}
          to='/crms'
          variant='ghost'
        >
          Crms
        </Button>
      </Flex>

    </StandardBackgroundForm>
  )
}
