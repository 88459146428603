import axios from "axios"
import { set } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { useQuery, UseQueryOptions } from "react-query"
import { api } from "services/api"
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum"
import { RequestError } from "utils/errors/RequestErrors"
import { InCollectServiceData } from "./dtos/InCollectService"

interface InCollectServicesQueryParams {
  page_size?: number
  current_page?: number
  protocol?: string
  collect_date?: string
  collect_hour_start?: string
  collect_hour_end?: string
  delivery_hour?: string
  customer?: string
  source_city?: string
  destination_city?: string
  driver_id?: string
  customer_id?: string
  collector_id?: string
  gelo_seco?: string
  step?: string
}

type QueryOptions = UseQueryOptions<InCollectServiceData, unknown, InCollectServiceData, ["inProgressServices"]>

interface UseInCollectServicesOptions {
  queryParams?: InCollectServicesQueryParams,
  queryOptions?: QueryOptions
}

async function getInCollectServicesReqFunction(queryParams?: InCollectServicesQueryParams) {
  try {
    const { data } = await api.get('/service/list/in-collect', {
      params: {
        ...queryParams,
        collect_hour_start: queryParams?.collect_hour_start
          ? set(new Date(), {
            hours: Number(queryParams?.collect_hour_start.split(':')[0]),
            minutes: Number(queryParams?.collect_hour_start.split(':')[1])
          })
          : undefined,
        collect_hour_end: queryParams?.collect_hour_end
          ? set(new Date(), {
            hours: Number(queryParams?.collect_hour_end.split(':')[0]),
            minutes: Number(queryParams?.collect_hour_end.split(':')[1])
          })
          : undefined,
        delivery_hour: queryParams?.delivery_hour
          ? set(new Date(), {
            hours: Number(queryParams?.delivery_hour.split(':')[0]),
            minutes: Number(queryParams?.delivery_hour.split(':')[1])
          })
          : undefined,
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

export function useInCollectServices(options?: UseInCollectServicesOptions) {

  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  return useQuery([
    'inCollectServices',
    { ...options?.queryParams }
  ],
    () => getInCollectServicesReqFunction(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )

}
