import { Flex, Spinner } from "@chakra-ui/react"
import { Input } from "components/Inputs/Input"
import { useProfile } from "hooks/profile/useProfile"
import { Controller, NestedValue, useFormContext } from "react-hook-form"
import { ProfilesCheckbox } from "./ProfilesCheckbox"

export interface NotificationTypeFormInputs {
  name: string
  profiles: NestedValue<string[]>
}

interface NotificationTypeFormProps {
  mode: 'create' | 'edit' | 'view',
  defaultSelectedProfiles?: string[]
}

export function NotificationTypeForm({
  mode,
  defaultSelectedProfiles
}: NotificationTypeFormProps) {

  const {
    profiles: {
      data: profiles,
      isFetching: isFetchingProfiles
    }
  } = useProfile(null)

  const profilesOptions = profiles?.map(profile => {
    return {
      key: profile.id,
      value: profile.user_type
    }
  })

  const {
    control,
    register,
    formState: { errors }
  } = useFormContext<NotificationTypeFormInputs>()

  return (
    <Flex
      direction='column'
      gap={4}
    >
      <Input
        {...register('name')}
        name='name'
        error={errors.name}
        label='Nome'
        isDisabled={mode === 'view'}
        required
      />
      {isFetchingProfiles ? (
        <Spinner />
      ) : (
        <Controller
          name='profiles'
          control={control}
          render={({ field: { onChange } }) => {
            return (
              <ProfilesCheckbox
                {...register('profiles')}
                name='profiles'
                label='Perfis'
                onCheckboxChange={onChange}
                profiles={profilesOptions}
                defaultCheckedOptions={defaultSelectedProfiles}
                isDisabled={mode === 'view'}
                error={errors.profiles}
                required
              />
            )
          }}
        />
      )}

    </Flex>
  )
}
