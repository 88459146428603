import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface ValidateSupportResolutionBody {
  content: string
  attachmentsIds?: string[]
  doesNeedNewReturn: boolean
}

interface ValidateSupportResolutionProps {
  body: ValidateSupportResolutionBody
  supportId: string
  supportResolutionReplyId: string
}
export async function validateSupportResolution({
  body,
  supportId,
  supportResolutionReplyId
}: ValidateSupportResolutionProps) {
  try {
    const response = await api.put(`/supports/${supportId}/resolutions/${supportResolutionReplyId}`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
