import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { AsoStatus } from "./aso";

interface CreateAsoBody {
  type: string
  regionalId: string;
  collaboratorType: string
  collaboratorName: string
  collaboratorEmail: string
  status: AsoStatus
  attachmentId?: string
  dueDate?: string
  realizedAt?: string
}

interface CreateAsoParams {
  body: CreateAsoBody
}

export async function createAso({
  body
}: CreateAsoParams) {
  try {
    await api.post('/asos', {
      type: body.type,
      regionalId: body.regionalId,
      collaboratorType: body.collaboratorType,
      collaboratorName: body.collaboratorName,
      collaboratorEmail: body.collaboratorEmail,
      status: body.status,
      attachmentId: body.attachmentId ?? null,
      dueDate: body.dueDate ?? null,
      realizedAt: body.realizedAt ?? null
    })

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
