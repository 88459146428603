import { usePagination } from "@ajna/pagination"
import { Box, Flex, Heading, Spinner, Table, TableContainer, Tbody, Td, Thead, Tr } from "@chakra-ui/react"
import { Pagination } from "components/Pagination/Pagination"
import { RouteNetwork } from "hooks/useRouteNetwork/dtos/RouteNetwork"
import { useRouteNetworks } from "hooks/useRouteNetwork/useRouteNetworks"
import { useSearchParams } from "hooks/useSearchParams"
import { useHistory } from "react-router-dom"

import { Modal, RouteNetworksTableFilters } from "./components/RouteNetworksTableFilters"
import { RouteNetworksTableRow } from "./components/RouteNetworkTableRow"


function generateKeyRoutesNetwork(item: RouteNetwork) {
  return [
    item.shipping.trading_name,
    item.source_hub.name,
    item.destination_hub.name,
    item.source_branch.nickname,
    item.destination_branch.nickname,
    item.departure_date,
    item.departure_hour,
    item.arrival_date,
    item.arrival_hour,
    item.truck_flight,
    item.modal
  ].join('|');
}

export function RouteNetworkList() {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const searchParams = useSearchParams()
  const { replace } = useHistory()


  const page = searchParams.get('page') ?? '1'
  const search = searchParams.get('search')
  const source_branch = searchParams.get('source_branch')
  const destination_branch = searchParams.get('destination_branch')
  const departure_date = searchParams.get('departure_date')
  const arrival_date = searchParams.get('arrival_date')
  const shipping = searchParams.get('shipping')
  const modal = searchParams.get('modal') ?? ''

  const {
    data: routeNetworkData,
    isFetching: isFetchingRouteNetworkData
  } = useRouteNetworks({
    queryParams: {
      destination_branch,
      source_branch,
      search,
      arrival_date,
      departure_date,
      shipping,
      modal: modal as Modal,
      current_page: Number(page),
      page_size: 10,
    },
  })

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: routeNetworkData?.total_pages,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: Number(page)
      },
    });

  const uniqueRoutesSet = new Set();
  const routeNetworks = routeNetworkData?.route_networks.filter(route => {
    const routeKey = generateKeyRoutesNetwork(route);
    if (uniqueRoutesSet.has(routeKey)) {
      return false;
    }
    uniqueRoutesSet.add(routeKey);
    return true;
  });

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Malhas</Heading>

      </Flex>
      <RouteNetworksTableFilters />
      {isFetchingRouteNetworkData ? (
        <Spinner />
      ) : (

        <TableContainer
          border="1px solid"
          borderColor="gray.200"
          rounded="md"
          mt="6"
        >
          <Table size="sm">
            <Thead>
              <Tr
                h="40px"
                bg="#38c3fa"
              >
                <Td color="white">TRANSPORTADORA</Td>
                <Td color="white">HUB ORIGEM</Td>
                <Td color="white">HUB DESTINO</Td>
                <Td color="white">BASE ORIGEM</Td>
                <Td color="white">BASE DESTINO</Td>
                <Td color="white">DATA DE SAÍDA</Td>
                <Td color="white">HORÁRIO DE SAÍDA</Td>
                <Td color="white">DATA DE CHEGADA</Td>
                <Td color="white">HORÁRIO DE CHEGADA</Td>
                <Td color="white">NÚMERO/NOME DO VOO</Td>
                <Td color="white">MODAL</Td>
              </Tr>
            </Thead>
            <Tbody>
              {routeNetworks?.map((route) => {
                return (
                  <RouteNetworksTableRow
                    key={route.id}
                    routeNetwork={route} />
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )

}
