import { Button, ButtonProps, Flex, useMediaQuery } from '@chakra-ui/react'
import { CSVLink } from 'react-csv'
import { Data } from 'react-csv/components/CommonPropTypes'

interface IReportHeaderProps {
  label: string
  key: string
}

interface IGenerateExcelReportButtonProps extends ButtonProps {
  csvReportProps: {
    data: string | Data
    filename: string
    headers: IReportHeaderProps[]
  }
  buttonTitle?: string
}

export function GenerateExcelReportButton({
  csvReportProps,
  buttonTitle = 'Exportar CSV',
  ...rest
}: IGenerateExcelReportButtonProps) {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  return (
    <Flex justifyContent="flex-end" mt="4">
      <CSVLink
        {...csvReportProps!}
        separator={';'}
        style={{
          flex: isWideVersion ? 0 : 1,
          width: isWideVersion ? '' : '100%',
        }}
      >
        <Button type="button" colorScheme="green" w={['full', 'full', '']} {...rest}>
          {buttonTitle}
        </Button>
      </CSVLink>
    </Flex>
  )
}
