import { Avatar, AvatarProps, Tooltip } from "@chakra-ui/react";
import { captalize } from "utils/captalize";

interface TooltipAvatarProps extends AvatarProps {
  name: string
}

export function TooltipAvatar({ name, ...rest }: TooltipAvatarProps) {
  return (
    <Tooltip label={captalize(name)} fontSize="xs">
      <Avatar name={name} {...rest} />
    </Tooltip>
  )
}
