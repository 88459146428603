import { Flex, Box, Heading, Button, Icon } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link, useHistory } from 'react-router-dom'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useAuth } from '../../hooks/auth/useAuth'
import { useRequestedUser } from '../../hooks/requestedUser/useRequestedUser'

import { RequestedUserTable } from '../../components/Tables/RequestedUser/RequestedUserTable'
import { useEffect } from 'react'

export function RequestedUserList() {
  const {
    requestedUsers: {
      data: requestedUsers,
      isLoading: isRequestedUsersLoading,
    },
  } = useRequestedUser(null, false)

  const { push: redirectTo } = useHistory()
  const { userLogged } = useAuth()

  const permissions = userLogged?.permissions

  useEffect(() => {
    if (!permissions?.includes('view-requested-user')) {
      redirectTo('/')
    }
  }, [permissions, redirectTo])

  if (isRequestedUsersLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontFamily="poppins">
              Usuários Solicitados
            </Heading>
            {permissions !== undefined && (
              <Link to="/usuario-solicitado/adicionar">
                {userLogged?.permissions.includes('add-requested-user') && (
                  <Button
                    colorScheme="gray"
                    rightIcon={<Icon as={FiPlus} />}
                    size="sm"
                  >
                    Solicitar Usuário
                  </Button>
                )}
              </Link>
            )}
          </Flex>
          {!!requestedUsers && (
            <RequestedUserTable requestedUsers={requestedUsers} />
          )}
        </Box>
      </Flex>

  )
}
