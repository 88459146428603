import { Spinner } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useCollectors } from "hooks/collector/useCollectors"
import { useNonInactiveCustomers } from "hooks/customer/useNonInactiveCustomers"
import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { Type } from "typescript"
import * as yup from "yup"
import { GenerateExcelReportButton } from "../../components/Buttons/GenerateExcelReportButton"
import { StandardBackgroundForm } from "../../components/Form/StandardBackgroundForm"
import { useAuth } from "../../hooks/auth/useAuth"
import { useReport } from "../../hooks/report/useReport"
import { useShipping } from "../../hooks/shipping/useShipping"
import { formatDate } from "../../utils/DateFunctions/formatDate"
import { handleChangeHeaders } from "../../utils/ReportFunctions/handleChangeHeaders"
import { handleChangeReportFormTilte } from "../../utils/ReportFunctions/handleChangeTitle"
import { reportBillingFormatDataToFront, reportCargoFormatDataToFront, reportCteFormatDataToFront, reportOccurrenceFormatDataToFront, reportRncFormatDataToFront, reportSlaFormatDataToFront, reportStockFormatDataToFront } from "../../utils/ReportFunctions/reportFormatDatatoFront"
import { CustomerManagmentReportTable } from "./components/CustomerManagmentReportTable"
import { ReportForm } from "./components/ReportForm"
import { SpotReportTable } from "./components/SpotReportTable"

interface IFormProps {
  find_by: string
  service_type: string
  customer_id?: string
  collector_id?: string
  shipping_id?: string
  startFilter: Date
  endFilter: Date
}

interface IParams {
  slug: string
}

function changePathToQueryReport(slug: string) {
  switch (slug) {
    case 'agendamentos':
      return 'schedule'
    case 'cargas':
      return 'cargo'
    case 'controle-estoque':
      return 'stock-control'
    case 'cte':
      return 'cte'
    case 'custo':
      return 'cost'
    case 'estoque':
      return 'stock'
    case 'faturamento':
      return 'billing'
    case 'gerencial-cliente':
      return 'customer-managment'
    case 'sla':
      return 'sla'
    case 'ocorrencias':
      return 'occurrence'
    case 'despacho-estoque':
      return 'dispatch-stock'
    case 'rnc':
      return 'rnc'
    case 'pontos':
      return 'spot'
    default:
      return ''
  }
}




const switchDataCsv = (slug: string, data: Array<Type>) => {
  if (data) {
    switch (slug) {
      case 'sla':
        return reportSlaFormatDataToFront(data as any)
      case 'estoque':
        return reportStockFormatDataToFront(data as any)
      case 'faturamento':
        return reportBillingFormatDataToFront(data as any)
      case 'cargas':
        return reportCargoFormatDataToFront(data as any)
      case 'cte':
        return reportCteFormatDataToFront(data as any)
      case 'controle-estoque':
        return data
      case 'ocorrencias':
        return reportOccurrenceFormatDataToFront(data as any)
      case 'rnc':
        return reportRncFormatDataToFront(data as any)
      case 'gerencial-cliente':
        return data
      case 'pontos':
        return data
      default:
        return []
    }
  }
}

const scheme = yup.object().shape({
  find_by: yup.string().required("Campo obrigatório"),
  service_type: yup.string().required("Campo obrigatório"),
  customer_id: yup.string().when("find_by", {
    is: "CLIENTE",
    then: yup.string().required("Campo obrigatório"),
  }),
  collector_id: yup.string().when("find_by", {
    is: "COLETADOR",
    then: yup.string().required("Campo obrigatório"),
  }),
  shipping_id: yup.string().when("find_by", {
    is: "TRANSPORTADORA",
    then: yup.string().required("Campo obrigatório"),
  }),
  startFilter: yup.date().typeError("Campo obrigatório!"),
  endFilter: yup.date().typeError("Campo obrigatório!"),
})

export function ReportPage() {
  const [startFilterToReportName, setStartFilterToReportName] = useState<string | Date>('')
  const [endFilterToReportName, setEndFilterToReportName] = useState<string | Date>('')

  const { slug } = useParams<IParams>()
  const { userLogged } = useAuth()

  const isUserLoggedCustomer = userLogged?.user_type === "CLIENTE"
  const isUserLoggedCollector = userLogged?.user_type === "COLETADOR"

  const formProps = useForm<IFormProps>({
    resolver: yupResolver(scheme),
    defaultValues: {
      find_by: isUserLoggedCustomer
        ? "CLIENTE"
        : isUserLoggedCollector
          ? "COLETADOR"
          : undefined,
      customer_id: userLogged?.customer_id ?? undefined,
      collector_id: userLogged?.collector_id ?? undefined,
    }
  })

  const { watch, handleSubmit, unregister, setValue, reset } = formProps

  useEffect(() => {
    if (isUserLoggedCustomer) {
      setValue('find_by', "CLIENTE")
      setValue('customer_id', userLogged?.customer_id)
    }
  }, [setValue, isUserLoggedCustomer, userLogged])

  const findByOptionSelected = watch("find_by")

  const isFindByCustomer = findByOptionSelected === "CLIENTE"
  const isFindByCollector = findByOptionSelected === "COLETADOR"
  const isFindByShipping = findByOptionSelected === "TRANSPORTADORA"

  const {
    data: collectors, isLoading: isCollectorsLoading,
  } = useCollectors()

  const {
    data: customersData,
    isFetching: isCustomersDataFetching
  } = useNonInactiveCustomers({
    queryOptions: {
      enabled: isFindByCustomer
    }
  })

  const {
    shipping: { data: shipping, isLoading: isShippingLoading }
  } = useShipping(null, isFindByShipping)

  const {
    generateReport: { mutateAsync: generateReport, isLoading: isReportLoading, data: reportData }
  } = useReport()

  const csvReportData = {
    data: switchDataCsv(slug, reportData) ?? '',
    headers: handleChangeHeaders(slug, findByOptionSelected),
    filename: slug !== 'ocorrencias' && slug !== 'controle-estoque'
      ? `relatorio-${slug}-${startFilterToReportName}_${endFilterToReportName}.csv`
      : `relatorio-${slug}.csv`,
  }

  async function handleGenerateReport(values: IFormProps) {
    const startFilterFormattedToName = formatDate.handle(values.startFilter, "DateWithoutHourToInput")
    const endFilterFormattedToName = formatDate.handle(values.endFilter, "DateWithoutHourToInput")
    setStartFilterToReportName(startFilterFormattedToName)
    setEndFilterToReportName(endFilterFormattedToName)

    const generateReportProps = {
      path: changePathToQueryReport(slug),
      input: {
        is_customer: userLogged?.user_type === 'CLIENTE',
        is_driver: userLogged?.user_type === 'MOTORISTA',
        customer_id: values.customer_id,
        collector_id: values.collector_id,
        shipping_id: values.shipping_id,
        is_business: values.service_type === "SERVIÇO BUSINESS",
        startFilter: values.startFilter,
        endFilter: values.endFilter
      }
    }

    await generateReport(generateReportProps)
    unregister("collector_id")
    unregister("customer_id")
    unregister("shipping_id")
    reset()
  }


  return (
    <StandardBackgroundForm title={handleChangeReportFormTilte(slug)} onSubmit={handleSubmit(handleGenerateReport)}>
      <FormProvider {...formProps}>
        <ReportForm
          slug={slug}
          isFindByCustomer={isFindByCustomer}
          isFindByCollector={isFindByCollector}
          isFindByShipping={isFindByShipping}
          collectors={collectors}
          customers={customersData?.customers}
          shipping={shipping}
          isCollectorsLoading={isCollectorsLoading}
          isCustomersLoading={isCustomersDataFetching}
          isShippingLoading={isShippingLoading}
        />
      </FormProvider>
      {isReportLoading ? (
        <Spinner />
      ) : (
        <>
          {slug === "pontos" && reportData && (
            <SpotReportTable
              reportData={reportData}
            />
          )}
          {slug === "gerencial-cliente" && reportData && (
            <CustomerManagmentReportTable
              reportData={reportData}
            />
          )}
        </>
      )}

      {reportData && !!reportData.length && (
        <GenerateExcelReportButton csvReportProps={csvReportData} />
      )}
    </StandardBackgroundForm>
  )
}
