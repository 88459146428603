import { Box, FormControl, FormLabel, Input, Select, Text, Textarea } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext, useWatch, } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";

const numberOfStopsOptions = Array.from({ length: 3 }, (_, i) => (i + 1).toString());


export function UberRequestForm() {

  const { register, setValue, control, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      numberOfStops: 'Quantidade de pontos',
      departureFullAddress: 'Endereço completo partida',
      firstStopFullAddress: 'Endereço completo primeiro ponto',
      secondStopFullAddress: 'Endereço completo segundo ponto',
      destinationFullAddress: 'Endereço completo destino',
      usageDateTime: 'Dia/horário para utilização',
      usageDescriptionOrReason: 'Descrição/Motivo do uso',
      uberCostCenter: 'Centro de custo uso Uber',
    };


    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };

  const numberOfStops = useWatch({
    control,
    name: 'formData.numberOfStops.value',
  });

  const numberOfStopsGreaterThanOne = Number(numberOfStops) > 1
  const numberOfStopsMax = Number(numberOfStops) === 3


  return (
    <Box w='full'>
      <FormControl isInvalid={!!errors.formData?.numberOfStops} mt={3}>
        <FormLabel fontSize='sm'>
          Quantidade de pontos
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register("formData.numberOfStops.value")}
          name="formData.numberOfStops.value"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('numberOfStops')}
        >

          {Object.entries(numberOfStopsOptions).map(([key, value]) => (
            <option key={key} value={value}>{value}</option>
          ))}

        </Select>
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.departureFullAddress} mt={3}>
        <FormLabel fontSize="sm">
          Endereço completo partida
        </FormLabel>
        <Textarea
          {...register('formData.departureFullAddress.value')}
          name='formData.departureFullAddress.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('departureFullAddress')}
        />
      </FormControl>
      {numberOfStopsGreaterThanOne && (
        <FormControl isInvalid={!!errors.formData?.firstStopFullAddress} mt={3}>
          <FormLabel fontSize="sm">
            Endereço completo primeiro ponto
          </FormLabel>
          <Textarea
            {...register('formData.firstStopFullAddress.value')}
            name='formData.firstStopFullAddress.value'
            size='sm'
            rounded='md'
            onChangeCapture={handleInputChange('firstStopFullAddress')}
          />
        </FormControl>
      )}
      {numberOfStopsMax && (
        <FormControl isInvalid={!!errors.formData?.secondStopFullAddress} mt={3}>
          <FormLabel fontSize="sm">
            Endereço completo segundo ponto
          </FormLabel>
          <Textarea
            {...register('formData.secondStopFullAddress.value')}
            name='formData.secondStopFullAddress.value'
            size='sm'
            rounded='md'
            onChangeCapture={handleInputChange('secondStopFullAddress')}
          />
        </FormControl>
      )}
      <FormControl isInvalid={!!errors.formData?.destinationFullAddress} mt={3}>
        <FormLabel fontSize="sm">
          Endereço completo destino
        </FormLabel>
        <Textarea
          {...register('formData.destinationFullAddress.value')}
          name='formData.destinationFullAddress.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('destinationFullAddress')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.usageDateTime} mt={3}>
        <FormLabel fontSize='sm'>
          Dia/horário para utilização
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.usageDateTime.value')}
          name='formData.usageDateTime.value'
          type="datetime-local"
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('usageDateTime')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.usageDescriptionOrReason} mt={3}>
        <FormLabel fontSize="sm">
          Descrição/Motivo do uso
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register('formData.usageDescriptionOrReason.value')}
          name='formData.usageDescriptionOrReason.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('usageDescriptionOrReason')}
        />
      </FormControl>

      <FormControl isInvalid={!!errors.formData?.uberCostCenter} mt={3}>
        <FormLabel fontSize='sm'>
          Centro de custo uso Uber
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.uberCostCenter.value')}
          name='formData.uberCostCenter.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('uberCostCenter')}
        />
      </FormControl>
    </Box>
  )
}
