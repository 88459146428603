import { useEffect } from 'react'
import { useAuth } from "hooks/auth/useAuth"
import { useHistory, useParams } from "react-router-dom"

import { Box, Button, Flex, Heading, IconButton } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import { addDays, format, set, subDays } from 'date-fns'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { FaArrowLeft } from 'react-icons/fa'
import { useMutation, useQuery } from 'react-query'
import { useToastify } from 'hooks/toastify/useToastify'
import { DispatchStockForm, DispatchStockFormSchema } from './components/DispatchStockForm'
import { editDispatchStock } from 'api/dispachStocks/editDispatchStock'
import { getDispatchStock } from 'api/dispachStocks/getDispatchStock'
import { transformStringToNumber } from 'utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber'
import { getDispatchStockProposal } from 'api/dispachStocks/getDispatchStockProposal'

interface QueryParams {
  id: string
}


const today = new Date()

function formatBoardDateToValidate(value: any, originalValue: string) {
  if (!value) return null

  value = set(addDays(new Date(originalValue), 1), { hours: 12 })

  return value
}

const editDispatchStockSchema = yup.object().shape({
  collectorId: yup.string().required('Campo Obrigatório'),
  destinationCollectorId: yup.string().required('Campo Obrigatório'),
  shippingId: yup.string().required('Campo Obrigatório'),
  proposalId: yup.string().required('Campo Obrigatório'),
  sender: yup.string().required('Campo Obrigatório'),
  branchId: yup.string().required('Campo Obrigatório'),
  boardDate: yup
    .date()
    .transform(formatBoardDateToValidate)
    .min(
      subDays(today, 2),
      `A data de embarque deve ser dia ${format(
        new Date(today),
        'dd/MM/yyyy',
      )} ou após esta data`,
    ),
  arrivalForecast: yup
    .date()
    .transform(formatBoardDateToValidate)
    .when(
      'board_date',
      (board_date, schema) =>
        board_date &&
        schema.min(
          subDays(new Date(board_date), 1),
          'A data prevista deve ser maior ou igual à data de embarque',
        ),
    ),
  withdrawalResponsible: yup.string().required('Campo obrigatório'),
  vehicle: yup.string().when('withdrawal_responsible', {
    is: 'COLETADOR',
    then: yup.string().required('Campo obrigatório')
  }),
  invoiceTotalValue: yup.number().required().transform((value, originalValue) => Math.ceil(transformStringToNumber(originalValue) * 100))
})

export function EditDispatchStock() {
  const history = useHistory()
  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()
  const { id } = useParams<QueryParams>()

  const formMethods = useForm<DispatchStockFormSchema>({
    resolver: yupResolver(editDispatchStockSchema)
  })
  const { handleSubmit, setValue, formState: { isSubmitting } } = formMethods

  const userCanEditDispatchStock = userLogged?.permissions.includes('create-dispatch-stock')

  const { data: dispatchStockData } = useQuery({
    queryKey: ['dispatchStock', id],
    queryFn: () => getDispatchStock({ dispatchStockId: id })
  })

  const {
    data: dispatchStockProposalData,
  } = useQuery({
    queryKey: ['dispatch-stock-proposal', dispatchStockData?.dispatchStock?.proposal_id],
    queryFn: () => getDispatchStockProposal({ proposalId: dispatchStockData?.dispatchStock?.proposal_id }),
    enabled: !!dispatchStockData?.dispatchStock?.proposal_id
  })

  const invoiceTotalValue = dispatchStockProposalData?.proposal?.inputs.reduce((acc, curr) => {
    return acc + (curr.send_quantity * curr.input.unit_cost);
  }, 0);

  useEffect(() => {
    if (dispatchStockData) {

      const dispatchStock = dispatchStockData?.dispatchStock

      const boardDateFormated = format(
        new Date(dispatchStock.board_date),
        'yyyy-MM-dd',
      )
      const arrivalDateFormated = format(
        new Date(dispatchStock.arrival_forecast),
        'yyyy-MM-dd',
      )

      setValue('withdrawalResponsible', dispatchStock?.withdrawal_responsible)
      setValue('sender', dispatchStock?.sender)
      setValue('vehicle', dispatchStock?.vehicle)
      setValue('boardDate', boardDateFormated)
      setValue('arrivalForecast', arrivalDateFormated)
      setValue('invoiceTotalValue', String(invoiceTotalValue))

    }
  }, [dispatchStockData, invoiceTotalValue, setValue])


  const { mutateAsync: editDispatchStockFn } = useMutation({
    mutationFn: editDispatchStock,
    onSuccess: () => {
      history.push('/despachos')
    }
  })

  useEffect(() => {
    if (!userCanEditDispatchStock) history.push('/')
  }, [history, userCanEditDispatchStock])




  async function handleEditDispatchStock({
    arrivalForecast,
    boardDate,
    branchId,
    collectorId,
    destinationCollectorId,
    proposalId,
    sender,
    shippingId,
    vehicle,
    withdrawalResponsible,
    invoiceTotalValue
  }: DispatchStockFormSchema) {
    await promiseMessage(editDispatchStockFn({
      dispatchStockId: id,
      body: {
        arrivalForecast,
        boardDate,
        branchId,
        collectorId,
        destinationCollectorId,
        proposalId,
        sender,
        shippingId,
        vehicle,
        withdrawalResponsible,
        invoiceTotalPriceInCents: Number(invoiceTotalValue)
      }
    }), 'Despacho de estoque editado! ')
  }


  return (

    <Box
      p="6"
      bg="white"
      rounded="md"
      as="form"
      onSubmit={handleSubmit(handleEditDispatchStock)}
    >
      <Flex align="center" gap="2">
        <IconButton
          icon={<FaArrowLeft />}
          aria-label="Voltar"
          size="sm"
          variant="ghost"
          onClick={() => history.goBack()}
        />
        <Heading letterSpacing="tight" fontSize="2xl">Editar Despacho</Heading>
      </Flex>

      <FormProvider {...formMethods}>
        <DispatchStockForm dispatchStock={dispatchStockData?.dispatchStock} />
      </FormProvider>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Editar
        </Button>
      </Flex>
    </Box>

  )
}
