import { Box, Circle, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { FaCalendarAlt, FaTrash } from "react-icons/fa";
import { ExperiencePeriodDetail } from "./ExperiencePeriodDetail";
import { ExperiencePeriod } from "api/experiencePeriods/types/ExperiencePeriod";
import { format } from "date-fns";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { deleteExperiencePeriod } from "api/experiencePeriods/deleteExperiencePeriod";
import { GetExperiencePeriodsResponse } from "api/experiencePeriods/getExperiencePeriods";
import { useAuth } from "hooks/auth/useAuth";

interface ExperiencePeriodsKanbanCardProps {
  experiencePeriod: ExperiencePeriod
}

export function ExperiencePeriodsKanbanCard({ experiencePeriod }: ExperiencePeriodsKanbanCardProps) {
  const { userLogged } = useAuth()
  const userHasDeleteExperiencePeriodPermission = userLogged?.permissions.includes('delete-experience-period')

  const {
    isOpen: isExperiencePeriodDetailModalOpen,
    onToggle: onToggleExperiencePeriodDetailModal,
  } = useDisclosure()

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const { mutateAsync: deleteExperiencePeriodFn } = useMutation({
    mutationFn: deleteExperiencePeriod,
    onSuccess(_data, { experiencePeriodId }) {
      const cachedExperiencePeriods = queryClient.getQueriesData<GetExperiencePeriodsResponse>({
        queryKey: ['experience-periods'],
      })

      queryClient.invalidateQueries({ queryKey: 'active-experience-periods' })
      queryClient.invalidateQueries({ queryKey: 'validating-45-days-experience-periods' })
      queryClient.invalidateQueries({ queryKey: 'validating-90-days-experience-periods' })
      queryClient.invalidateQueries({ queryKey: 'dismissing-experience-periods' })

      cachedExperiencePeriods.forEach(([cachedKey, cachedData]) => {
        if (!cachedData) {
          return null
        }

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          experiencePeriods: cachedData?.experiencePeriods?.filter((cachedExperiencePeriod) => {
            return cachedExperiencePeriod.id !== experiencePeriodId
          })
        })

      })
    },
  })

  async function handleDeleteExperiencePeriod() {
    await promiseMessage(deleteExperiencePeriodFn({
      experiencePeriodId: experiencePeriod.id
    }), 'Periodo de experiência excluido!')
  }


  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleExperiencePeriodDetailModal}
      cursor='pointer'
    >
      <HStack justify="space-between" w="full">
        <Text fontWeight="bold" mb={2}>
          {experiencePeriod?.name}
        </Text>
        {userHasDeleteExperiencePeriodPermission && (
          <Circle
            size="5"
            color="white"
            onClick={(e) => {
              e.stopPropagation()
              handleDeleteExperiencePeriod()
            }}
          >
            <Icon as={FaTrash} boxSize={"3.5"} color='blue.900' />
          </Circle>
        )}

      </HStack>


      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA ADMISSÃO</Text>
          </HStack>
          <Text ml={5}>{experiencePeriod?.admission_date ? format(new Date(experiencePeriod?.admission_date), 'dd/MM/yyyy') : '-'}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">45 DIAS</Text>
          </HStack>
          <Text ml={5}>{experiencePeriod?.admission_date ? format(new Date(experiencePeriod?.period_45_days_end_date), 'dd/MM/yyyy') : '-'}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">90 DIAS</Text>
          </HStack>
          <Text ml={5}>{experiencePeriod?.admission_date ? format(new Date(experiencePeriod?.period_90_days_end_date), 'dd/MM/yyyy') : '-'}</Text>
        </Box>

      </VStack>

      <Modal
        isOpen={isExperiencePeriodDetailModalOpen}
        onClose={onToggleExperiencePeriodDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <ExperiencePeriodDetail
          experiencePeriodId={experiencePeriod.id}
        />
      </Modal>
    </Box>
  )
}
