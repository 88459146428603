export class SwitchRouteTypes {
  handle() {
    const routes = [
      { id: 0, value: 'AÉREO' },
      { id: 1, value: 'AMBOS' },
      { id: 2, value: 'RODOVIÁRIO' },
    ]

    return routes
  }
}

const switchRouteTypes = new SwitchRouteTypes()

export { switchRouteTypes }
