import { Box, Table, Tbody, Td, Tr } from "@chakra-ui/react"
import { GetActiveFeedbackResponse } from "api/feedbacks/getActiveFeedback"
import { format } from "date-fns"
import { ActiveFeedbackStatus } from "./ActiveFeedbackStatus"

interface ActiveFeedbackDetailTableProps {
  activeFeedback: GetActiveFeedbackResponse
}

export function ActiveFeedbackDetailTable({
  activeFeedback
}: ActiveFeedbackDetailTableProps) {
  return (
    <Box
      w='full'
      maxH='500px'
      overflowY='scroll'
    >
      <Table size="sm" >
        <Tbody>
          {activeFeedback?.activeFeedback?.cltDriver && (
            <>
              <Tr>
                <Td>Nome Completo</Td>
                <Td isNumeric>{`${activeFeedback?.activeFeedback?.cltDriver?.driver?.firstname} ${activeFeedback?.activeFeedback?.cltDriver?.driver?.lastname} `}</Td>
              </Tr>
              <Tr>
                <Td>CPF</Td>
                <Td isNumeric>{activeFeedback?.activeFeedback?.cltDriver?.driver?.cpf}</Td>
              </Tr>
              <Tr>
                <Td>E-mail do colaborador</Td>
                <Td isNumeric>{activeFeedback?.activeFeedback?.cltDriver?.email}</Td>
              </Tr>
              <Tr>
                <Td>RG</Td>
                <Td isNumeric>{activeFeedback?.activeFeedback?.cltDriver?.rg}</Td>
              </Tr>
              <Tr>
                <Td>Celular</Td>
                <Td isNumeric>{activeFeedback?.activeFeedback?.cltDriver?.cellphone}</Td>
              </Tr>
              {activeFeedback?.activeFeedback?.cltDriver?.admission_date && (
                <Tr>
                  <Td>Data de admissão</Td>
                  <Td isNumeric>{format(new Date(activeFeedback?.activeFeedback?.cltDriver?.admission_date), "dd/MM/yyyy")}</Td>
                </Tr>
              )}
            </>
          )}
          {activeFeedback?.activeFeedback?.internClt && (
            <>
              <Tr>
                <Td>Nome</Td>
                <Td isNumeric>{activeFeedback?.activeFeedback?.internClt.name}</Td>
              </Tr>
              <Tr>
                <Td>CPF</Td>
                <Td isNumeric>{activeFeedback?.activeFeedback?.internClt.cpf}</Td>
              </Tr>
              <Tr>
                <Td>RG</Td>
                <Td isNumeric>{activeFeedback?.activeFeedback?.internClt.rg}</Td>
              </Tr>

              <Tr>
                <Td>Regional</Td>
                <Td isNumeric>{activeFeedback?.activeFeedback?.internClt.regional === 'other' ? 'Outra' : activeFeedback?.activeFeedback?.internClt.regional.toUpperCase()}</Td>
              </Tr>
              {activeFeedback?.activeFeedback?.internClt.admission_date && (
                <Tr>
                  <Td>Data da admissão</Td>
                  <Td isNumeric>{format(new Date(activeFeedback?.activeFeedback?.internClt.admission_date), 'dd/MM/yyyy')}</Td>
                </Tr>
              )}
            </>
          )}
          <Tr>
            <Td>Status</Td>
            <Td display="flex" justifyContent="flex-end">
              <ActiveFeedbackStatus status={activeFeedback?.activeFeedback?.status} />
            </Td>
          </Tr>

          {['executive-leader-approving', 'awaiting-leader-feedback', 'done'].includes(activeFeedback?.activeFeedback?.status) && (
            <>
              <Tr>
                <Td>Responsabilidade</Td>
                <Td isNumeric>{activeFeedback?.activeFeedback?.responsability}</Td>
              </Tr>
              <Tr>
                <Td>Aprenda todo dia</Td>
                <Td isNumeric>{activeFeedback?.activeFeedback?.learnEveryDay}</Td>
              </Tr>
              <Tr>
                <Td>Ame a 2ª feira</Td>
                <Td isNumeric>{activeFeedback?.activeFeedback?.loveMonday}</Td>
              </Tr>
              <Tr>
                <Td>Resultado em equipe</Td>
                <Td isNumeric>{activeFeedback?.activeFeedback?.teamResult}</Td>
              </Tr>
              <Tr>
                <Td>Não existe sucesso</Td>
                <Td isNumeric>{activeFeedback?.activeFeedback?.doesNotExistsSuccess}</Td>
              </Tr>
              <Tr>
                <Td>Pega e faz</Td>
                <Td isNumeric>{activeFeedback?.activeFeedback?.catchesAndDoes}</Td>
              </Tr>
              <Tr>
                <Td>Sem fingimento</Td>
                <Td isNumeric>{activeFeedback?.activeFeedback?.noLies}</Td>
              </Tr>
              <Tr>
                <Td>Sugere manter colaborador (a)?</Td>
                <Td isNumeric>
                  {activeFeedback?.activeFeedback?.doesKeepCollaborator ? 'Sim' : 'Não'}
                </Td>
              </Tr>
              <Tr>
                <Td>Colaborador (a) terá promoção?</Td>
                <Td isNumeric>
                  {activeFeedback?.activeFeedback?.doesCollaboratorHasPromotion ? 'Sim' : 'Não'}
                </Td>
              </Tr>
              <Tr>
                <Td>Missão (observações) passadas para o próximo semestre</Td>
                <Td isNumeric>
                  {activeFeedback?.activeFeedback?.nextPeriodMissions}
                </Td>
              </Tr>
            </>
          )}

          {['awaiting-leader-feedback', 'done'].includes(activeFeedback?.activeFeedback?.status) && (
            <>
              <Tr>
                <Td>Manter?</Td>
                <Td isNumeric>
                  {activeFeedback?.activeFeedback?.doesExecutiveLeaderKeepCollaborator ? 'Sim' : 'Não'}
                </Td>
              </Tr>
              <Tr>
                <Td>Subir nível?</Td>
                <Td isNumeric>
                  {activeFeedback?.activeFeedback?.doesExecutiveLeaderApproveCollaboratorPromotion ? 'Sim' : 'Não'}
                </Td>
              </Tr>
            </>
          )}

        </Tbody>
      </Table>
    </Box>
  )
}
