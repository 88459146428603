import axios from "axios"
import { BudgetProps } from "contexts/BudgetContext"
import { User } from "hooks/user/dtos/User"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetBudgetLogsParams {
  budgetId: string
  pageSize: number
  currentPage: number
}

interface GetBudgetLogsResponse {
  logs: {
    id: string,
    oldValue: any
    newValue: any
    fieldName: keyof BudgetProps
    createdByUser: Pick<User, 'id' | 'firstname' | 'lastname'>
    createdAt: string
  }[]
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
  }
}

export async function getBudgetLogs({
  currentPage,
  pageSize,
  budgetId
}: GetBudgetLogsParams) {
  try {

    const { data } = await api.get<GetBudgetLogsResponse>(`/budget/${budgetId}/logs`, {
      params: {
        pageSize,
        currentPage
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
