import { Stack, Flex, Button } from "@chakra-ui/react"
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading"
import { Address } from "hooks/address/dtos/Address"
import { useAuth } from "hooks/auth/useAuth"
import { useCollectServiceFunctions } from "hooks/collectService/useCollectServiceFunctions"
import { useRequestServiceFunctions } from "hooks/services/request/useRequestServiceFunctions"
import { useServiceSocket } from "hooks/socket/useServiceSocket"
import { useSwal } from "hooks/swal/useSwal"
import { useEffect } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import { SubmitButton } from "../../../../components/Buttons/SubmitButton"
import { StandardBackgroundForm } from "../../../../components/Form/StandardBackgroundForm"
import { Select } from "../../../../components/Inputs/SelectInput"
import { ProviderProps } from "../../../../contexts/ProviderContext"
import { IDriverProps } from "../../../../services/getFunctions/driver/getDrivers"

interface DriverAddressAssignedProps {
  address_id: string;
  driver_id: string
}

interface IAssignDriverFormProps {
  driver_address_assign: Array<{
    driver_id: string
    address_id: string
  }>
  driver_provider_assign: Array<{
    driver_id: string
    provider_id: string
  }>
}

interface IAssignDriverToAddressFormProps {
  requestedSourceAddresses?: string[]
  requestedSourceProvider?: string[]
  addresses?: Address[]
  providers?: ProviderProps[]
  driversFilteredBySourceCollector?: IDriverProps[]
  hasEditAssignedDrivers: boolean
  driversAssignedToAddresses?: string[] | null
  driversAssignedToProvider?: string[] | null
  onAssignDriverToAddress: (data: IAssignDriverFormProps) => Promise<void>
  sourceAddressesPendingStart?: any[]
  sourceProviderPendingStart?: string[]
  serviceId?: string
  serviceProtocol?: number
}

export function AssignDriverToAddressForm({
  requestedSourceAddresses,
  addresses,
  requestedSourceProvider,
  providers,
  driversFilteredBySourceCollector,
  hasEditAssignedDrivers,
  driversAssignedToAddresses,
  driversAssignedToProvider,
  onAssignDriverToAddress,
  serviceId,
  serviceProtocol,
  sourceAddressesPendingStart,
  sourceProviderPendingStart
}: IAssignDriverToAddressFormProps) {

  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: {
      errors,
      isDirty,
      isSubmitting
    }
  } = useFormContext<IAssignDriverFormProps>()

  const {
    fields: commomSourceAddresses,
    update: updateCommonSourceAddresses,
    remove: removeCommonSourceAddress
  } = useFieldArray({
    control,
    name: "driver_address_assign"
  })

  const {
    fields: providerSourceAddresses,
    update: updateProviderSourceAddresses,
  } = useFieldArray({
    control,
    name: "driver_provider_assign"
  })

  const {
    cancelCollectServiceAddress: {
      mutateAsync: cancelCollectServiceAddress,
      isLoading: isCancelCollectAddressLoading,
    },

  } = useCollectServiceFunctions()


  const {
    cancelAddressToCollectService: {
      mutateAsync: cancelAddressToCollectService,
      isLoading: isRequestCancelCollectAddressLoading
    },
  } = useRequestServiceFunctions();

  const { userLogged } = useAuth();

  const userHasPermissionToCancelAddress = userLogged?.permissions.includes(
    "cancel-collect-address"
  );

  const userHasPermissionRequestToCancelAddress =
    userLogged?.permissions.includes("request-cancel-collect-address");

  const { serviceSocketConnection } = useServiceSocket()

  useEffect(() => {
    if (hasEditAssignedDrivers) {
      let hasNewAddressesToAssign = false

      if (requestedSourceAddresses && driversAssignedToAddresses) {
        hasNewAddressesToAssign = requestedSourceAddresses.length > driversAssignedToAddresses.length
      }

      if (!isDirty) {
        if (hasNewAddressesToAssign) {
          const driversAssignedToAddressesParsed = driversAssignedToAddresses?.map(driverAddress => JSON.parse(driverAddress) as DriverAddressAssignedProps)

          requestedSourceAddresses?.reduce((acc, curr) => {
            const assignment = driversAssignedToAddressesParsed?.find(driverAssigned => {

              return driverAssigned.address_id === curr
            })

            if (assignment) {
              acc.push({
                address_id: assignment.address_id,
                driver_id: assignment.driver_id
              })
            } else {
              acc.push({
                address_id: curr,
                driver_id: ''
              })
            }

            return acc
          }, [] as DriverAddressAssignedProps[])
            .forEach((assignment, index) => {
              updateCommonSourceAddresses(index, {
                address_id: assignment.address_id,
                driver_id: assignment.driver_id
              })
            })
        } else {
          driversAssignedToAddresses
            ?.map(value => JSON.parse(value))
            .forEach((address, index) => {
              updateCommonSourceAddresses(index, {
                address_id: address.address_id,
                driver_id: address.driver_id
              })

            })
        }
      }
    } else {
      requestedSourceAddresses?.forEach((address, index) => {
        updateCommonSourceAddresses(index, {
          address_id: address,
          driver_id: ''
        })
      })
    }
  }, [
    hasEditAssignedDrivers,
    isDirty,
    updateCommonSourceAddresses,
    driversAssignedToAddresses,
    requestedSourceAddresses,
  ])

  useEffect(() => {
    if (hasEditAssignedDrivers) {
      if (!isDirty) {
        driversAssignedToProvider
          ?.map(value => JSON.parse(value))
          .forEach((providerAssignment, index) => {
            updateProviderSourceAddresses(index, {
              driver_id: providerAssignment.driver_id,
              provider_id: providerAssignment.provider_id
            })
          })
      }
    } else {
      requestedSourceProvider?.forEach((provider, index) => {
        updateProviderSourceAddresses(index, {
          driver_id: '',
          provider_id: provider
        })
      })
    }
  }, [
    updateProviderSourceAddresses,
    hasEditAssignedDrivers,
    isDirty,
    driversAssignedToProvider,
    requestedSourceProvider
  ])

  const commomSourceAddressesSelected = watch("driver_address_assign")

  const { confirmMessage, standardMessage } = useSwal()

  async function handleCancelCollectServiceAddress(addressIndex: number) {
    const hasCancelCollectAddress = await confirmMessage({ title: "Deseja cancelar a coleta do endereço?" })

    if (hasCancelCollectAddress) {
      const addressIdFilteredByIndex = commomSourceAddressesSelected.find(
        (commomSourceAddress, index) => index === addressIndex
      )?.address_id

      await cancelCollectServiceAddress({
        address_id: addressIdFilteredByIndex,
        service_id: serviceId!
      }, {
        onSuccess: () => removeCommonSourceAddress(addressIndex)
      })
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  async function handleRequestCancelAddressToCollect(addressIndex: number) {
    const hasCancelCollectAddress = await confirmMessage({
      title: "Deseja solicitar cancelamento da coleta do endereço?",
    });

    const addressIdFilteredByIndex = commomSourceAddressesSelected.find(
      (commomSourceAddress, index) => index === addressIndex
    )?.address_id

    if (hasCancelCollectAddress) {
      await cancelAddressToCollectService({
        service_id: serviceId,
        address_id: addressIdFilteredByIndex
      }, {
        onSuccess: () => {
          serviceSocketConnection.emit('requestedCancelCollectAddress', {
            service_protocol: serviceProtocol
          })
          removeCommonSourceAddress(addressIndex)
        }
      });
    } else {
      standardMessage("Ação cancelada com êxito!");
    }
  }

  if (isCancelCollectAddressLoading || isRequestCancelCollectAddressLoading) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm onSubmit={handleSubmit(onAssignDriverToAddress)} title={hasEditAssignedDrivers ? "Editar motoristas atribuídos" : "Atribuir motoristas"}>
      <Stack>
        {commomSourceAddresses.map((field, index) => {
          return (
            <Stack key={field.id} direction="row" spacing={6}>
              <Select
                {...register(`driver_address_assign.${index}.driver_id`)}
                name={`driver_address_assign.${index}.driver_id`}
                label="Motorista"
                drivers={driversFilteredBySourceCollector}
                error={errors.driver_address_assign ? errors?.driver_address_assign[index]?.driver_id : undefined}
                placeholder="Selecione uma opção..."
                required
              />
              <Stack w="full" direction="row">
                <Select
                  {...register(`driver_address_assign.${index}.address_id`)}
                  name={`driver_address_assign.${index}.address_id`}
                  label="Endereço"
                  addresses={addresses}
                  error={errors.driver_address_assign ? errors?.driver_address_assign[index]?.address_id : undefined}
                  isDisabled={true}
                  placeholder="Selecione uma opção..."
                  required
                />
                {!hasEditAssignedDrivers && userHasPermissionToCancelAddress && (
                  <Stack>
                    <Button
                      variant="outline"
                      colorScheme="red"
                      onClick={() => handleCancelCollectServiceAddress(index)}
                      w="full"
                      mt={8}
                      h="48px"
                    >
                      Cancelar endereço
                    </Button>
                  </Stack>
                )}
                {!hasEditAssignedDrivers && userHasPermissionRequestToCancelAddress && (
                  <Stack>
                    <Button
                      variant="outline"
                      colorScheme="red"
                      onClick={() => handleRequestCancelAddressToCollect(index)}
                      w="full"
                      mt={8}
                      h="48px"
                    >
                      Solicitar cancelamento de endereço
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Stack>
          )
        })}
        {providerSourceAddresses.map((field, index) => {
          return (
            <Stack key={field.id} direction="row" spacing={6}>
              <Select
                {...register(`driver_provider_assign.${index}.driver_id`)}
                name={`driver_provider_assign.${index}.driver_id`}
                label="Motorista"
                drivers={driversFilteredBySourceCollector}
                error={errors.driver_provider_assign ? errors?.driver_provider_assign[index]?.driver_id : undefined}
                placeholder="Selecione uma opção..."
                required
              />
              <Select
                {...register(`driver_provider_assign.${index}.provider_id`)}
                name={`driver_provider_assign.${index}.provider_id`}
                label="Endereço Fornecedor"
                providerAddress={providers}
                error={errors.driver_provider_assign ? errors?.driver_provider_assign[index]?.provider_id : undefined}
                isDisabled={true}
                placeholder="Selecione uma opção..."
                required
              />
            </Stack>
          )
        })}
      </Stack>
      <Flex mt={4} align="center" justify="flex-end">
        {(commomSourceAddresses.length > 0 || providerSourceAddresses.length > 0) && (
          <SubmitButton
            action="Salvar"
            isSubmitting={isSubmitting}
          />
        )}
      </Flex>
    </StandardBackgroundForm>
  )
}
