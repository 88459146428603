import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { IHubsProps } from "utils/RequestFunctions/Hubs/requestHubFunctions";
import { StandardizationAttachmentTypes } from "./getStandardization";
import { Standardization, StandardizationStatus } from "./standardization";

interface GetStandardizationsReportParams {
  status?: StandardizationStatus | 'all' | null
  sendedAtStartDate?: string
  sendedAtEndDate?: string
}

export interface GetStandardizationsReportResponse {
  standardizations: Array<Standardization & {
    attachments: {
      id: string
      type: StandardizationAttachmentTypes
      created_at: string
      updated_at: string
      attachment_id: string
      standardization_id: string
      attachment: Attachment
    }[],
    hub: IHubsProps
  }
  >
}

export async function getStandardizationsReport({
  status,
  sendedAtStartDate,
  sendedAtEndDate
}: GetStandardizationsReportParams) {
  try {
    const { data } = await api.get<GetStandardizationsReportResponse>('/standardizations/report', {
      params: {
        status,
        sendedAtStartDate,
        sendedAtEndDate
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

