import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface MoveCltDriverBody {
  accidentDate: string
  inssBenefitStartDate: string
  inssBenefitFinalDate: string
  moveType: string
  shouldAdvanceStatus: boolean
}

interface MoveCltDriverProps {
  body: MoveCltDriverBody
  cltDriverId: string
}

export async function moveCltDriver({
  body,
  cltDriverId
}: MoveCltDriverProps) {
  try {
    const response = await api.put(`/clt-drivers/${cltDriverId}/move`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
