import { NotificationActions } from "./NotificationActions";
import { NotificationIcon } from "./NotificationIcon";
import { NotificationMessage } from "./NotificationMessage";
import { NotificationRoot } from "./NotificationRoot";
import { NotificationTitle } from "./NotificationTitle";

export const Notification = {
  Root: NotificationRoot,
  Icon: NotificationIcon,
  Title: NotificationTitle,
  Message: NotificationMessage,
  Actions: NotificationActions
}
