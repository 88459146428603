import {
  Box,
  Button,
  Flex,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Spinner,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { editSpecialBudgetCost } from "api/budgets/editSpecialBudgetCost"
import { Collector } from "hooks/collector/dtos/Collector"
import { useCollectors } from "hooks/collector/useCollectors"
import { useToastify } from "hooks/toastify/useToastify"
import { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber"
import { specialBudgetCostSchema } from "./SpecialBudgetCostForm"
import { SpecialBudgetCostForm, SpecialBudgetCostSchema } from "./SpecialBudgetCostForm"

interface EditSpecialBudgetCostProps {
  budgetId: string
  specialCost: {
    id: string
    valueInCents: number
    collectors: {
      collector: Collector
    }[]
  }
  onToggleEditSpecialBudgetCost: () => void
}

export function EditSpecialBudgetCost({
  specialCost,
  onToggleEditSpecialBudgetCost
}: EditSpecialBudgetCostProps) {

  const formMethods = useForm<SpecialBudgetCostSchema>({
    resolver: yupResolver(specialBudgetCostSchema)
  })

  const { formState: { isSubmitting }, handleSubmit, setValue, reset } = formMethods


  const { promiseMessage } = useToastify();
  const queryClient = useQueryClient();

  const { data: collectors, isLoading: isCollectorsLoading } = useCollectors();

  useEffect(() => {
    if (specialCost) {
      setValue("valueIncents", String(specialCost?.valueInCents / 100))
      setValue('collectorsIds', specialCost?.collectors?.map(collector => collector?.collector?.id))
    }
  }, [setValue, specialCost])



  const { mutateAsync: editSpecialBudgetCostFn } = useMutation({
    mutationFn: editSpecialBudgetCost,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'special-budget-costs' })
      onToggleEditSpecialBudgetCost()
      reset()
    }
  })

  async function handleEditSpecialBudgetCost(values: SpecialBudgetCostSchema, event: React.MouseEvent) {
    event.stopPropagation()
    await promiseMessage(editSpecialBudgetCostFn({
      body: {
        collectorsIds: values.collectorsIds,
        valueInCents: Math.ceil(transformStringToNumber(values.valueIncents) * 100)
      },

      budgetSpecialCostId: specialCost.id
    }), 'Custo especial atualizado!')
  }

  return (

    <PopoverContent>
      <PopoverArrow />
      <PopoverHeader>Editar Custo Especial</PopoverHeader>
      <PopoverCloseButton />
      <PopoverBody>
        {isCollectorsLoading ? (
          <Spinner />
        ) : (
          <FormProvider {...formMethods}>
            <Box as="form"
            >
              <SpecialBudgetCostForm collectors={collectors} />
              <Flex mt="4" justifyContent="flex-end">
                <Button
                  type="submit"
                  size="sm"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                  onClick={handleSubmit(handleEditSpecialBudgetCost)}
                >
                  Editar
                </Button>
              </Flex>
            </Box>
          </FormProvider>
        )}
      </PopoverBody>
    </PopoverContent>

  );
}



