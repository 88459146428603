import { Flex, Box, Heading, Button, Icon } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link, useHistory } from 'react-router-dom'

import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { useAuth } from '../../../hooks/auth/useAuth'
import { useEffect } from 'react'
import { useRequestedBusinessService } from '../../../hooks/services/requestedBusiness/useRequestedBusinessService'
import { RecurrentRequestedBusinessServiceTable } from '../../../components/Tables/Service/RequestedBusiness/RecurrentRequestedBusinessServiceTable'
import { useAddresses } from '../../../hooks/address/useAddresses'

export function RecurrentRequestedBusinessServiceList() {
  const { data: addresses, isLoading: isAddressesLoading} = useAddresses()
  const {
    requestedBusinessServices: {
      data: requestedBusinessServices,
      isLoading: isRequestedBusinessServiceLoading,
    },
  } = useRequestedBusinessService()

  const { push: redirectTo } = useHistory()
  const { userLogged } = useAuth()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-requested-business-service')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  if (isRequestedBusinessServiceLoading || isAddressesLoading) {
    return <GeneralContentLoading />
  }

  const recurrentRequestedBusinessServices = requestedBusinessServices
    ?.filter(requestedService => requestedService.is_recurrent === true)

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontFamily="poppins">
              Serviços Recorrentes Business
            </Heading>
            <Link to="/servico-business/solicitar">
              {userLogged?.permissions.includes(
                'add-requested-business-service',
              ) && (
                  <Button
                    colorScheme="gray"
                    rightIcon={<Icon as={FiPlus} />}
                    size="sm"
                  >
                    Solicitar Serviço Business
                  </Button>
                )}
            </Link>
          </Flex>
          {recurrentRequestedBusinessServices && addresses && (
            <RecurrentRequestedBusinessServiceTable
              addresses={addresses}
              requestedBusinessServices={recurrentRequestedBusinessServices}
            />
          )}
        </Box>
      </Flex>

  )
}
