import { Button, Flex, Input, Select } from "@chakra-ui/react"
import { DispatchStockStepTypes } from "api/dispachStocks/dispatchStock"
import { useSearchParams } from "hooks/useSearchParams"
import { useForm } from "react-hook-form"
import { FaSearch, FaTimes } from "react-icons/fa"
import { useHistory } from "react-router-dom"
import { dispatchStockStepMap } from "utils/dispatchStockMappers"

interface DispatchStockTableFiltersSchema {
  protocol: number
  branch: string
  dispatchInvoice: number
  step: DispatchStockStepTypes | 'all'
  destinationCollector: string
}

export function DispatchStockTableFilters() {
  const searchParams = useSearchParams()
  const history = useHistory()

  const protocol = searchParams.get('protocol')
  const branch = searchParams.get('branch')
  const dispatchInvoice = searchParams.get('dispatchInvoice')
  const destinationCollector = searchParams.get('destinationCollector')
  const step = searchParams.get('step') ?? 'all'

  const {
    register,
    reset,
    handleSubmit
  } = useForm<DispatchStockTableFiltersSchema>({
    defaultValues: {
      protocol: protocol ? Number(protocol) : undefined,
      branch,
      dispatchInvoice: dispatchInvoice ? Number(dispatchInvoice) : undefined,
      destinationCollector,
      step: step as DispatchStockStepTypes | 'all'
    }
  })

  function handleSearchDispatchStocks(values: DispatchStockTableFiltersSchema) {
    if (values.step) {
      searchParams.set('step', values.step)
    } else {
      searchParams.delete('step')
    }

    if (values.protocol) {
      searchParams.set('protocol', values.protocol.toString())
    } else {
      searchParams.delete('protocol')
    }

    if (values.branch) {
      searchParams.set('branch', values.branch)
    } else {
      searchParams.delete('branch')
    }

    if (values.dispatchInvoice) {
      searchParams.set('dispatchInvoice', values.dispatchInvoice.toString())
    } else {
      searchParams.delete('dispatchInvoice')
    }

    if (values.destinationCollector) {
      searchParams.set('destinationCollector', values.destinationCollector)
    } else {
      searchParams.delete('destinationCollector')
    }

    searchParams.set('page', '1')

    history.replace({ search: searchParams.toString() })
  }

  function handleClearFilters() {
    searchParams.set('step', 'all')
    searchParams.delete('protocol')
    searchParams.delete('branch')
    searchParams.delete('dispatchInvoice')
    searchParams.delete('destinationCollector')
    searchParams.set('page', '1')

    reset({
      step: 'all'
    })

    history.replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      as="form"
      mt="6"
      gap="2"
      direction={{ base: 'column', lg: 'row' }}
      onSubmit={handleSubmit(handleSearchDispatchStocks)}
    >
      <Flex
        w="full"
        wrap="wrap"
        gap="2"
        direction={{ base: 'column', md: 'row' }}
      >
        <Input
          {...register('protocol')}
          size="sm"
          w={{ base: 'full', lg: 'fit-content' }}
          placeholder="Protocolo"
          rounded="md"
        />

        <Input
          {...register('branch')}
          size="sm"
          w={{ base: 'full', lg: 'fit-content' }}
          placeholder="Base"
          rounded="md"
        />

        <Input
          {...register('dispatchInvoice')}
          size="sm"
          w={{ base: 'full', lg: 'fit-content' }}
          placeholder="Nota fiscal"
          rounded="md"
        />

        <Input
          {...register('destinationCollector')}
          size="sm"
          w={{ base: 'full', lg: 'fit-content' }}
          placeholder="Coletador de destino"
          rounded="md"
        />

        <Select
          {...register('step')}
          name="step"
          size="sm"
          w={{ base: 'full', lg: '212px' }}
          rounded="md"
        >
          {Object.entries(dispatchStockStepMap).map(([key, value]) => {
            return (
              <option key={key} value={key}>{value}</option>
            )
          })}
          <option value='all'>Todas etapas</option>
        </Select>
      </Flex>

      <Flex
        flex="1"
        flexDirection={{ base: 'column', lg: 'row' }}
        w="full"
        gap="2"
      >
        <Button
          leftIcon={<FaSearch />}
          size="sm"
          type='submit'
        >
          Filtrar resultados
        </Button>
        <Button
          leftIcon={<FaTimes />}
          size="sm"
          onClick={handleClearFilters}
          variant="outline"
        >
          Remover filtros
        </Button>
      </Flex>
    </Flex>

  )
}
