import axios from "axios";
import { User } from "hooks/user/dtos/User";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { TrainingLlm, TrainingLlmStatus } from "./trainingLlm";

interface GetTrainingsLlmParams {
  pageSize: string
  currentPage: string
  status?: TrainingLlmStatus | 'all' | null
  collaborator?: string
}

export interface GetTrainingsLlmResponse {
  llmTrainings: Array<TrainingLlm & { collaborator: User }>
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
    count: number
  }
}

export async function getTrainingsLlm({
  pageSize,
  currentPage,
  status,
  collaborator
}: GetTrainingsLlmParams) {
  try {
    const { data } = await api.get<GetTrainingsLlmResponse>('/llm/trainings', {
      params: {
        pageSize,
        currentPage,
        status,
        collaborator
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

