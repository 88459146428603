import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Onboarding } from "./onboarding";

interface GetOnboardingsReportProps {
  queryParams: {
    startDate: string
    endDate: string
    status: string
    timezoneOffset: number,
  }
}

export interface GetOnboardingsReportResponse {
  onboardings: Array<Onboarding & {
    certificate: {
      id: string
      title: string
      link: string
      createdAt: string
      updatedAt: string
    }
  }>
}

export async function getOnboardingReport({
  queryParams
}: GetOnboardingsReportProps) {
  try {
    const { data } = await api.get<GetOnboardingsReportResponse>('/reports/onboardings', {
      params: { ...queryParams }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

