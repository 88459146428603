import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Patrimony } from "./patrimony";
import { PatrimonyRequest } from "./patrimonyRequest";

interface GetPatrimonyRequestParams {
  patrimonyRequestId: string
}

interface GetPatrimonyRequestResponse {
  patrimonyRequest: {
    regional: string
    responsible_name: string
    limit_date: string
    sector: string
    created_at: string
    updated_at: string
    receipt_attachment_id: string
    patrimonies: Array<PatrimonyRequest & {
      patrimony: Patrimony,
      receipt: {
        id: string
        title: string
        link: string
        createdAt: string
        updatedAt: string
      }
    }>
  }
}

export async function getPatrimonyRequest({ patrimonyRequestId }: GetPatrimonyRequestParams) {
  try {
    const response = await api.get<GetPatrimonyRequestResponse>(`patrimony-requests/${patrimonyRequestId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
