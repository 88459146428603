import { Api } from '../api/apiClass'
import { ICepApiProps } from './ICepApi'

class ApiCep extends Api {
  constructor() {
    super(process.env.REACT_APP_API_CEP)
  }

  async get(cep: string) {
    const { data } = await this.server.get<ICepApiProps>(cep)

    return data
  }
}

const apiCep = new ApiCep()

export { apiCep }
