import { Box, BoxProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ExternalComunicationsChatRootProps extends BoxProps {
  children: ReactNode
}

export function ExternalComunicationsChatRoot({
  children,
  ...rest
}: ExternalComunicationsChatRootProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      fontSize="sm"
      w="full"
      {...rest}
    >
      {children}
    </Box>
  )
}
