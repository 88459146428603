import { Box, Flex } from "@chakra-ui/react"

type PmcPurchaseOrderStatusTypes = 'refused' | 'quoting' | 'approving' | 'purchasing' | 'sending-invoice' | 'purchased'

interface PmcPurchaseOrderStatusProps {
  status: PmcPurchaseOrderStatusTypes
}

const pmcPurchaseOrderStatusMap: Record<PmcPurchaseOrderStatusTypes, string> = {
  purchased: 'Comprado',
  "sending-invoice": 'Enviando NF',
  purchasing: 'Em compra',
  quoting: 'Cotando',
  refused: 'Recusado',
  approving: 'Em aprovação'

}

export function PmcPurchaseOrderStatus({ status }: PmcPurchaseOrderStatusProps) {
  return (
    <Flex
      align="center"
      gap="2"
    >
      {status === 'refused' && (
        <Box w="2" h="2" rounded="full" bgColor="red.300" />
      )}
      {['quoting', 'purchasing', 'sending-invoice', 'approving'].includes(status) && (
        <Box w="2" h="2" rounded="full" bgColor="orange.300" />
      )}
      {status === 'purchased' && (
        <Box w="2" h="2" rounded="full" bgColor="green.300" />
      )}
      {pmcPurchaseOrderStatusMap[status]}
    </Flex>
  )
}
