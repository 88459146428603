import { RequestError } from 'utils/errors/RequestErrors';

import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { api } from 'services/api'
import {
  AddAddressesToCollectInputProps,
  addAddressToCollectReqFunction,
  requestServiceReqFunction,
  ValidateAddressesToCollectReqProps,
  validateAddressToCollectReqFunction,
} from '../../../utils/RequestFunctions/Service/Request/requestRequestServiceFunctions'
import { useSwal } from '../../swal/useSwal'
import { HttpErrorStatusCodeEnum } from 'utils/errors/HttpStatusCodeEnum';
import { useAuth } from 'hooks/auth/useAuth';

interface CancelAddress {
  address_id: string
  service_id: string
}

interface ValidateCancelAddress {
  address_id: string
  service_id: string
  is_validated: boolean
}

interface DestinationAddressInput {
  address_id: string
  quantity: number
}

export interface ValidateRequestedServiceInputProps {
  customer_id: string
  budget_id: string
  source_address_id: string[]
  destination_address_id: string[]
  source_collector_id: string
  crossdocking_collector_id?: string | null
  destination_collector_id: string

  source_branch_id?: string
  destination_crossdocking_branch_id?: string
  source_crossdocking_branch_id?: string
  destination_branch_id?: string

  deadline: number
  service_type: string

  crossdocking_modal?: string
  modal: string

  crossdocking_planned_flight?: string
  planned_flight?: string

  vehicle: string
  franchising: number

  caixa_termica: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  embalagem_secundaria: number

  collect_date: Date
  collect_hour_start: Date
  collect_hour_end: Date

  delivery_date: Date
  delivery_hour: Date

  crossdocking_board_date?: Date
  crossdocking_board_hour?: Date
  crossdocking_availability_forecast_day?: Date
  crossdocking_availability_forecast_time?: Date

  board_date?: Date
  board_hour?: Date
  availability_forecast_day?: Date
  availability_forecast_time?: Date

  provider_id?: string
  material_type: string
  destination_addresses_input?: DestinationAddressInput[]
}

export interface RequestNewDeliveryAddressesReqFunction {
  requestedServiceId: string
  input:{
    addresses: string[]
    addressesObservations?: {
      address_id: string
      observation: string
    }[]
  }
}

interface ValidateRequestedServiceReqProps {
  serviceId: string,
  input: ValidateRequestedServiceInputProps
}

interface ValidateRequestedDeliveryAddress {
  id: string
  address_id: string
  status: 'validated' | 'invalidated'
}

interface UpdateRequestedServiceAttachmentPhotoReqProps {
  requestedServiceId: string
  input: FormData
}

async function updateValidatedRequestedServiceReqFunction({
  serviceId,
  input
}: ValidateRequestedServiceReqProps) {
  try {
    await api.put(`in-progress/requested-service/${serviceId}`, {
      ...input
    })


  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

async function validateRequestedServiceReqFunction({
  serviceId,
  input
}: ValidateRequestedServiceReqProps) {
  try {
    await api.put(`/requested-service/validate/${serviceId}`, {
      ...input
    })


  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError(error || 'Erro inesperado!')
    }
  }
}

async function requestNewDeliveryAddressesReqFunction({
  input,
  requestedServiceId,
}: RequestNewDeliveryAddressesReqFunction) {
  try {
    await api.patch(`/requested-service/request/delivery-addresses/${requestedServiceId}`, {
      ...input
    })

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

async function validateRequestedDeliveryAddressReqFunction({
  id,
  status,
  address_id
}: ValidateRequestedDeliveryAddress) {
  try {
    await api.patch(`/requested-service/validate/delivery-addresses/${id}`, {
      status,
      address_id
    })

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

async function updateRequestedServiceAttachmentPhotoReqFunction({
  requestedServiceId,
  input
}: UpdateRequestedServiceAttachmentPhotoReqProps) {
  try {
    await api.patch(`/requested-service/${requestedServiceId}/attachments`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado")
    }
  }
}

export const useRequestServiceFunctions = () => {
  const queryClient = useQueryClient()
  const { successMessage, errorMessage } = useSwal()
  const { handleLogout } = useAuth()

  const onRequestError = ({ httpCode }: RequestError) => {
    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      handleLogout()
    }
  }

  const onValidateRequestedServiceRequestSuccess = async () => {
    await queryClient.invalidateQueries(['services', 'service', 'requestedService'])
  }

  const requestService = useMutation('requestService', requestServiceReqFunction, {
    onSuccess: (data ) => {
      queryClient.invalidateQueries(['service'])
      queryClient.invalidateQueries(['requestedService'])
      successMessage('Serviço solicitado com sucesso!')

      return data
    },

    onError: (error: any) => {
      errorMessage(error.message || 'Erro Inesperado!')
    }
  })

  const validateRequestedDeliveryAddress = useMutation(
    'validateRequestedDeliveryAddress', validateRequestedDeliveryAddressReqFunction, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['servicesBySteps'])
    },
    onError: async (error: RequestError) => {
      const { httpCode } = error

      if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
        await handleLogout()
      }
    }
  })

  const requestNewDeliveryAddresses = useMutation(
    'requestNewDeliveryAddresses', requestNewDeliveryAddressesReqFunction, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['requestedService'])
    },
    onError: async (error: RequestError) => {
      const { httpCode } = error

      if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
        await handleLogout()
      }
    }
  })

  const addAddressesToCollectService = useMutation(
    'addAddressesToCollectService',
    ({ service_id, input }: AddAddressesToCollectInputProps) =>
      addAddressToCollectReqFunction({ service_id, input }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['service'])
        queryClient.invalidateQueries(['requestedService'])
        successMessage('Solicitação enviada com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const validateAddressToCollectService = useMutation(
    'validateAddressToCollectService',
    ({
      service_id,
      validateAddressToCollectInput,
    }: ValidateAddressesToCollectReqProps) =>
      validateAddressToCollectReqFunction({
        service_id,
        validateAddressToCollectInput,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['service'])
        successMessage('Endereço validado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const invalidateAddressToCollectService = useMutation(
    'invalidateAddressToCollectService',
    ({
      service_id,
      validateAddressToCollectInput,
    }: ValidateAddressesToCollectReqProps) =>
      validateAddressToCollectReqFunction({
        service_id,
        validateAddressToCollectInput,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['service'])
        successMessage('Endereço invalidado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  async function requestCancelAddressToCollectReqFunction({ address_id, service_id }: CancelAddress) {
    try {
      await api.patch(`requested-service/request-cancel-address/${service_id}`, { address_id })
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  }


  async function validateCancelAddressToCollectReqFunction({ address_id, service_id, is_validated }: ValidateCancelAddress) {
    try {
      await api.patch(`/requested-service/validate-cancel-address/${service_id}`, { address_id, is_validated })
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new RequestError('Erro inesperado!')
      }

    }
  }

  const cancelAddressToCollectService = useMutation("cancelAddressToCollectService", requestCancelAddressToCollectReqFunction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['service'])
      queryClient.invalidateQueries(['services'])
      queryClient.invalidateQueries(['servicesBySteps'])
      successMessage('Solicitação enviada com sucesso!')
    },

    onError: (error: any) => {
      errorMessage(error.message || 'Erro Inesperado!')
    }
  })

  const validateCancelAddressToCollectService = useMutation("validateCancelAddressToCollectService", validateCancelAddressToCollectReqFunction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['service'])
      queryClient.invalidateQueries(['services'])
      queryClient.invalidateQueries(['servicesBySteps'])
      successMessage('Validação concluída com sucesso!')

    },

    onError: (error: unknown) => {
      if (error instanceof RequestError) {
        errorMessage(error.message)
      } else {
        errorMessage('Erro Inesperado!')
      }
    }
  })

  const validateRequestedService = useMutation("validateRequestedService", validateRequestedServiceReqFunction, {
    onError: onRequestError,
    onSuccess: onValidateRequestedServiceRequestSuccess,
  })

  const updateValidatedRequestedService = useMutation(
    "validateRequestedService", updateValidatedRequestedServiceReqFunction,
    {
      onError: onRequestError,
    }
  )

  const updateRequestedServiceAttachmentPhoto = useMutation('updateRequestedServiceAttachmentPhoto', updateRequestedServiceAttachmentPhotoReqFunction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['service'])
      queryClient.invalidateQueries(['requestedService'])
    },
    onError: (error: any) => {
      errorMessage(error.message || 'Erro Inesperado!')
    }
  })

  return {
    addAddressesToCollectService,
    validateAddressToCollectService,
    invalidateAddressToCollectService,
    requestService,
    cancelAddressToCollectService,
    validateCancelAddressToCollectService,
    validateRequestedService,
    requestNewDeliveryAddresses,
    validateRequestedDeliveryAddress,
    updateValidatedRequestedService,
    updateRequestedServiceAttachmentPhoto
  }
}
