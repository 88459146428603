import { Button, Flex, Input, Select } from "@chakra-ui/react";
import { StandardizationStatus } from "api/standardizations/standardization";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface StandardizationsTableFiltersSchema {
  hub: string
  status: StandardizationStatus | 'all'
}

export function StandardizationsTableFilters() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const hub = searchParams.get('hub')
  const status = searchParams.get('status') ?? 'all'

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<StandardizationsTableFiltersSchema>({
    defaultValues: {
      hub: hub ?? '',
      status: status as StandardizationStatus | 'all'
    }
  })

  async function handleFilterStandardizations(data: StandardizationsTableFiltersSchema) {
    if (data.hub) {
      searchParams.set('hub', data.hub)
    } else {
      searchParams.delete('hub')
    }


    if (
      data.status) {
      searchParams.set('status', data.status)
    } else {
      searchParams.delete('status')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    reset()
    searchParams.delete('hub')
    searchParams.delete('status')

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }


  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterStandardizations)}
    >

      <Input
        {...register("hub")}
        placeholder="Hub"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />

      <Select
        {...register("status")}
        defaultValue="all"
        size="sm"
        rounded="md"
        w={["full", "full", "200px"]}
      >
        <option value="all">Todos status</option>
        <option value="approved">Aprovadas</option>
        <option value="pending">Pendentes</option>
        <option value="reproved">Reprovadas</option>
      </Select>

      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={["full", "full", "min"]}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleRemoveFilters}
        w={["full", "full", "min"]}
      >
        Remover filtros
      </Button>

    </Flex>
  )
}
