import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";


interface DeleteBusinessCostParams {
  businessCostId: string
}

export async function deleteBusinessCost({
  businessCostId
}: DeleteBusinessCostParams) {
  try {
    const { data } = await api.delete(`/business-costs/${businessCostId}`)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

