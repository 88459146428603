import { usePagination } from "@ajna/pagination"
import { Box, Flex, Heading, Divider, Spinner, TableContainer, Table, Thead, Tr, Th, Tbody, Button, Icon, useDisclosure  } from "@chakra-ui/react"
import { TableFilterButton } from "components/Filters/TableFilterButton"
import { Input } from "components/Inputs/Input"
import { Options, Select } from "components/Inputs/SelectInput"
import { Pagination } from "components/Pagination/Pagination"
import { useAuth } from "hooks/auth/useAuth"
import { useDriver } from "hooks/driver/useDriver"
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions"
import { useInCollectServices } from "hooks/services/useInCollectServices"
import { Fragment, useEffect, useMemo, useReducer } from "react"
import { useForm, useWatch } from "react-hook-form"
import {  FaLayerGroup, FaUndoAlt } from "react-icons/fa"
import { useQueryClient } from "react-query"
import { GenerateServicesLabelsModal } from "./components/GenerateServicesLabelsModal"
import { InCollectServiceTableRow } from "./components/InCollectServiceTableRow"


interface InCollectServicesFilterInputs {
  protocol?: string
  collect_date?: string
  collect_hour_start?: string
  collect_hour_end?: string
  delivery_hour?: string
  customer?: string
  source_city?: string
  destination_city?: string
  driver_id?: string
  collector?: string
  gelo_seco?: string
}

interface ActionPayload {
  inProgressServiceData?: InCollectServicesFilterInputs
  currentPage?: number
}

interface Action {
  type: 'set-in-collect-services-data' | 'set-in-collect-services-current-page'
  payload: ActionPayload
}

function reducer(state: ActionPayload, action: Action) {
  if (action.type === 'set-in-collect-services-current-page') {
    return {
      inProgressServiceData: { ...state.inProgressServiceData },
      currentPage: action.payload.currentPage
    }
  }

  return {
    ...state,
    ...action.payload
  }
}

const inProgressServicesListFilterOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'collect_date', value: 'Data da coleta', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'source_city', value: 'Cidade(s) de origem', checked: false },
  { key: 'destination_city', value: 'Cidade(s) de destino', checked: false },
  { key: 'gelo_seco', value: 'Gelo seco', checked: false },
  { key: 'collect_hour_start', value: 'Horário inicial coleta', checked: false },
  { key: 'collect_hour_end', value: 'Horário final coleta', checked: false },
  { key: 'delivery_hour', value: 'Horário da entrega', checked: false },
  { key: 'collector', value: 'Coletador', checked: false },
  { key: 'driver_id', value: 'Motorista', checked: false },
]



export function InCollectServicesList() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const [inCollectServicesDataState, dispatch] = useReducer(
    reducer,
    {} as ActionPayload
  )

  const {
    isOpen: isGenerateServicesLabelsModalOpen,
    onOpen: onOpenGenerateServicesLabelsModal,
    onClose: onCloseGenerateServicesLabelsModal
  } = useDisclosure()

  const handleOpenGenerateServicesLabelsModal = () => {
    onOpenGenerateServicesLabelsModal()
  }

  const { userLogged } = useAuth()

  const {
    filterOptions,
    onLoadSetFilterOptions
  } = useFilterOptions()


  const filterOptionsByUserLogged = useMemo(() => {
    return inProgressServicesListFilterOptions.filter(option => {
      const userLoggedDriverFilter = userLogged?.user_type === 'MOTORISTA'
        ? option.key !== 'driver_id'
        : true
      const userLoggedCollectorFilter = userLogged?.user_type === 'COLETADOR'
        ? option.key !== 'collector'
        : true

      return userLoggedDriverFilter && userLoggedCollectorFilter
    })
  }, [userLogged])

  useEffect(() => {
    onLoadSetFilterOptions(filterOptionsByUserLogged)
  }, [onLoadSetFilterOptions, filterOptionsByUserLogged])



  const { register, control } = useForm<InCollectServicesFilterInputs>()

  const [
    protocol,
    collectDate,
    collectHourStart,
    collectHourEnd,
    deliveryHour,
    customer,
    sourceCity,
    destinationCity,
    driver,
    collector,
    geloSeco,
  ] = useWatch({
    control,
    name: [
      'protocol',
      'collect_date',
      'collect_hour_start',
      'collect_hour_end',
      'delivery_hour',
      'customer',
      'source_city',
      'destination_city',
      'driver_id',
      'collector',
      'gelo_seco'
    ]
  })

  const {
    drivers: {
      data: drivers,
      isFetching: isFetchingDrivers,
    }
  } = useDriver(null, true)

  const driversSelectOptions = drivers?.reduce((drivers, currDriver) => {
    if (
      userLogged?.user_type === 'COLETADOR' &&
      currDriver.collector_id === userLogged?.collector_id
    ) {
      drivers.push({
        key: currDriver.id,
        value: currDriver.id,
        showOption: `${currDriver.firstname} ${currDriver.lastname}`
      })
    } else {
      drivers.push({
        key: currDriver.id,
        value: currDriver.id,
        showOption: `${currDriver.firstname} ${currDriver.lastname}`
      })
    }

    return drivers
  }, [] as Options[])

  const {
    data: inProgressServicesData,
    isFetching: isFetchingInProgressServicesData
  } = useInCollectServices({
    queryParams: {
      ...inCollectServicesDataState.inProgressServiceData,
      driver_id: userLogged?.user_type === 'MOTORISTA'
        ? userLogged?.driver_id
        : inCollectServicesDataState?.inProgressServiceData?.driver_id,
      collector_id: userLogged?.collector_id,
      customer_id: userLogged?.customer_id,
      current_page: inCollectServicesDataState.currentPage,
      page_size: Number(rowsPerPage)
    },
    queryOptions: {
      enabled: !!inCollectServicesDataState.currentPage
    }
  })

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: inProgressServicesData?.total_pages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  useEffect(() => {
    dispatch({
      type: 'set-in-collect-services-current-page',
      payload: {
        currentPage
      }
    })
  }, [currentPage])

  useEffect(() => {
    const debounce = setTimeout(() => {
      dispatch({
        type: 'set-in-collect-services-data',
        payload: {
          inProgressServiceData: {
            protocol: protocol,
            collect_date: collectDate,
            collect_hour_start: collectHourStart,
            collect_hour_end: collectHourEnd,
            delivery_hour: deliveryHour,
            customer: customer,
            source_city: sourceCity,
            destination_city: destinationCity,
            driver_id: driver,
            collector: collector,
            gelo_seco: geloSeco
          }
        }
      })

      setCurrentPage(1)
    }, 1000)

    return () => clearTimeout(debounce)
  }, [
    protocol,
    collectDate,
    collectHourStart,
    collectHourEnd,
    deliveryHour,
    sourceCity,
    destinationCity,
    driver,
    customer,
    collector,
    geloSeco,
    setCurrentPage
  ])

  const handleChangePage = (page: number) => setCurrentPage(page)

  const queryClient = useQueryClient()

  const handleRefetchTableData = async () => {
    await queryClient.invalidateQueries(['inCollectServices'])
  }


  return (

    <Box
      borderRadius='8px'
      p={4}
      bg='white'
    >
      <Flex
        gap={4}
        direction='column'
      >
        <Heading size='md'>Serviços em Coleta</Heading>

        <Divider />

        <Flex w={['full', 'full', 'full']} justify='space-between'>
          <Flex gap={2}>
            <TableFilterButton />
            <Button colorScheme="blue" onClick={handleRefetchTableData}>
              <Icon as={FaUndoAlt} />{' '}
            </Button>
          </Flex>

          {userLogged?.permissions.includes('generate-services-labels') && (
            <Button
              leftIcon={<Icon as={FaLayerGroup} />}
              onClick={handleOpenGenerateServicesLabelsModal}
            >
              Gerar Etiquetas
            </Button>
          )}
        </Flex>

        <GenerateServicesLabelsModal
          isOpen={isGenerateServicesLabelsModalOpen}
          onClose={onCloseGenerateServicesLabelsModal}
        />

        {filterOptions
          .filter(option => option.checked)
          .map(option => {
            return (
              <Fragment key={option.key}>
                {option.key === 'collect_date' ? (
                  <Input
                    {...register('collect_date')}
                    name='collect_date'
                    label='Data da coleta'
                    type='date'
                    size='sm'
                  />
                ) : (
                  option.key === 'collect_hour_start' ? (
                    <Input
                      {...register('collect_hour_start')}
                      name='collect_hour_start'
                      label='Horário inicial da coleta'
                      type='time'
                      size='sm'
                    />
                  ) : (
                    option.key === 'collect_hour_end' ? (
                      <Input
                        {...register('collect_hour_end')}
                        name='collect_hour_end'
                        label='Horário final da coleta'
                        type='time'
                        size='sm'
                      />
                    ) : (
                      option.key === 'delivery_hour' ? (
                        <Input
                          {...register('delivery_hour')}
                          name='delivery_hour'
                          label='Horário da entrega'
                          type='time'
                          size='sm'
                        />
                      ) : (
                        option.key === 'driver_id' ? (
                          <Select
                            {...register('driver_id')}
                            name='driver_id'
                            label='Motorista'
                            options={driversSelectOptions}
                            isDisabled={isFetchingDrivers}
                            placeholder='Selecione uma opção...'
                            size='sm'
                          />
                        ) : (
                          <Input
                            {...register(option.key as keyof InCollectServicesFilterInputs)}
                            name={option.key}
                            label={`Buscar ${option.value}`}
                            size='sm'
                            placeholder='Buscar...'
                          />
                        )
                      ))))}
              </Fragment>
            )
          })}

        {isFetchingInProgressServicesData ? (
          <Spinner />
        ) : (
          <TableContainer>
            <Table size='sm' variant='striped'>
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th></Th>
                  {inProgressServicesListFilterOptions.map(option => {
                    return (
                      <Th key={option.key}>{option.value.toLocaleUpperCase()}</Th>
                    )
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {inProgressServicesData?.services?.map(service => {
                  return (
                    <InCollectServiceTableRow
                      key={service.id}
                      drivers={drivers}
                      service={service}
                    />
                  )
                })}

              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />

      </Flex>
    </Box>


  )
}
