class SwitchIntercurrenceTypes {
  handle(ocurrenceType: string, serviceStep?: string, serviceModal?: string) {
    if (ocurrenceType === 'MOTORISTA') {
      const intercurrences = [
        { key: '0', value: 'DECLARAÇÃO NÃO PREENCHIDA CORRETAMENTE' },
        { key: '1', value: 'CTE FALTANDO VOLUME' },
        { key: '2', value: 'REPOSIÇÃO DE INSUMOS' },
        { key: '3', value: 'RETORNO DE COLETA/ENTREGA' },
        { key: '4', value: 'ALTERAÇÃO DE INSUMOS UTILIZADOS' },
      ].sort((a, b) => a.value.localeCompare(b.value))

      return intercurrences
    }

    if (ocurrenceType === 'CLIENTE') {
      const intercurrences = [
        { key: '0', value: 'ATRASO NA COLETA DO MEU MATERIAL' },
        { key: '1', value: 'ATRASO NA ENTREGA DO MEU MATERIAL' },
        { key: '2', value: 'PROBLEMA DE POSTURA/ATENDIMENTO DA LOGLIFE' },
        { key: '3', value: 'CAIXA RECEBIDA FORA DO PADRÃO' },
        { key: '4', value: 'OUTRAS COBRANÇAS' },
        { key: '5', value: 'DECLARAÇÃO DE CONTEÚDO NÃO CONFORME' },
        { key: '6', value: 'ENTREGA EM LOCAL ERRADO' },
        { key: '7', value: 'FALTA DE FOTO CAIXA' },
        { key: '8', value: 'FALTA DE FOTO DECLARAÇÃO DE CONTEÚDO' },
        { key: '9', value: 'INSUMOS ERRADOS' },
        { key: '10', value: 'RECLAMAÇÃO DE CLIENTE' },
        { key: '12', value: 'NECESSIDADE DE MANUTENÇÃO DE REFRIGERAÇÃO' },
        { key: '13', value: 'CLIENTE SEM DECLARAÇÃO DE CONTEÚDO' },
      ].sort((a, b) => a.value.localeCompare(b.value))

      const stepsServices = [
        'toCollectService',
        'collectingService',
        'toValidateAdditionalCollectAddresses'
      ]

      if (stepsServices.includes(serviceStep!)) {
        const prevIntercurrences = [...intercurrences]

        prevIntercurrences.push({ key: '11', value: 'ALTERAÇÃO DE INSUMOS UTILIZADOS' })
        return prevIntercurrences.sort((a, b) => a.value.localeCompare(b.value))
      }

      return intercurrences
    }

    if (ocurrenceType === 'COLETADOR') {
      const intercurrences = [
        { key: '0', value: 'ATRASO NA COLETA' },
        { key: '1', value: 'ATRASO NA ENTREGA' },
        { key: '2', value: 'RECLAMAÇÃO DE CLIENTE' },
        { key: '3', value: 'INSUMOS ERRADOS' },
        { key: '4', value: 'DECLARAÇÃO DE CONTEÚDO NÃO CONFORME' },
        { key: '5', value: 'NO SHOW' },
        { key: '6', value: 'ENTREGA NO LOCAL ERRADO' },
        { key: '7', value: 'CAIXAS NÃO IDENTIFICADAS' },
        { key: '8', value: 'FALTA DE LANÇAMENTO NO SISTEMA' },
        { key: '9', value: 'FALTA DE FOTO CAIXA/CTE' },
        { key: '10', value: 'FALTA DE FOTO DECLARAÇÃO' },
        { key: '11', value: 'REPOSIÇÃO DE INSUMOS' },
        { key: '12', value: 'RETORNO DE COLETA/ENTREGA' },
        { key: '13', value: 'ALTERAÇÃO DE INSUMOS UTILIZADOS' },
        { key: '15', value: 'EMBARQUE ERRADO' },
        { key: '16', value: 'ATRASO AUDITORIA' },
        { key: '17', value: 'USO DE DECLARAÇÃO GENÉRICA' },
        { key: '18', value: 'CARGA EXTRAVIADA' },
        { key: '19', value: 'NECESSIDADE DE MANUTENÇÃO DE REFRIGERAÇÃO' },
        { key: '20', value: 'ATRASO DEVIDO AO SISTEMA' },
        { key: '21', value: 'ALTERAÇÃO DE EMBARQUE' },
        { key: '22', value: 'TENTATIVA DE ENTREGA SEM SUCESSO' },
        { key: '23', value: 'EMBARQUE ATRASADO' },
        { key: '24', value: 'DECLARAÇÃO DE CONTEÚDO INCOMPLETA' },
      ].sort((a, b) => a.value.localeCompare(b.value))

      if (serviceStep === 'finishedService') {
        const prevIntercurrences = [...intercurrences]

        prevIntercurrences.push({
          key: '20',
          value: 'TEMPO DE ESPERA PELA MATRIZ',
        })

        return prevIntercurrences
      }

      return intercurrences
    }

    if (ocurrenceType === 'TRANSPORTADORA') {

      const intercurrences = [
        { key: '0', value: 'VALOR DO CTE DIVERGENTE DA TABELA' },
        { key: '1', value: 'TRANSPORTADORA SEM SISTEMA' },
        { key: '4', value: 'CARGA EXTRAVIADA' },
        { key: '5', value: 'CARGA AVARIADA' },
        { key: '6', value: 'DESVIO DE BUDGET DE TRANSFERÊNCIA' },
        { key: '10', value: 'DESEMBARQUE PARCIAL' },
        { key: '11', value: 'ATRASO NA LIBERAÇÃO' }

      ].sort((a, b) => a.value.localeCompare(b.value))

      const stepsServicesFiscalRetention = ['toAvailableService', 'availableService', 'toLandingService']

      if (stepsServicesFiscalRetention.includes(serviceStep!)) {
        intercurrences.push({ key: (intercurrences.length + 1).toString(), value: 'RETENÇÃO FISCAL' })
      }

      if (serviceModal! === 'AÉREO') {

        const prevIntercurrences = [...intercurrences]

        prevIntercurrences.push({ key: (prevIntercurrences.length + 1).toString(), value: 'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)' })
        prevIntercurrences.push({ key: (prevIntercurrences.length + 2).toString(), value: 'CANCELAMENTO DE VOO' })
        return prevIntercurrences.sort((a, b) => a.value.localeCompare(b.value))
      }
      if (serviceModal! === 'RODOVIÁRIO') {
        const prevIntercurrences = [...intercurrences]
        prevIntercurrences.push({ key: (prevIntercurrences.length + 1).toString(), value: 'ATRASO NA TRANSFERÊNCIA' })
        return prevIntercurrences.sort((a, b) => a.value.localeCompare(b.value))
      }


      return intercurrences
    }
  }
}

const switchIntercurrenceTypes = new SwitchIntercurrenceTypes()

export { switchIntercurrenceTypes }
