import { Button, Flex, Stack } from "@chakra-ui/react";
import { add } from "date-fns";
import { Collector } from "hooks/collector/dtos/Collector";
import { useFormContext } from "react-hook-form";
import { formatDate } from "utils/DateFunctions/formatDate";
import { Input } from "../../../../components/Inputs/Input";
import { Select } from "../../../../components/Inputs/SelectInput";
import { CustomerProps } from "../../../../contexts/CustomerContext";
import { ShippingProps } from "../../../../contexts/ShippingContext";
import { useAuth } from "../../../../hooks/auth/useAuth";

const serviceTypeOptions = [
  { key: "business", value: "SERVIÇO BUSINESS" },
  { key: "express", value: "SERVIÇO EXPRESS" },
]

export const findByReportOptions = [
  { key: "general", value: "GERAL" },
  { key: "customer", value: "CLIENTE" },
  { key: "shipping", value: "TRANSPORTADORA" },
  { key: "collector", value: "COLETADOR" },
]

const reportsFilteredByGeneralAndCustomer = [
  'cte',
  'sla',
  'custo',
  'estoque',
  'faturamento',
  'gerencial-cliente',
  'pontos'
]

const reportsFilteredByGeneralCustomerAndCollector = [
  'agendamento',
  'cargas'
]

const reportsFilteredOnlyByGeneral = [
  'controle-estoque',
  'despacho-estoque',
  'rnc'
]

const reportsFilteredOnlyByCollector = [
  'coletador'
]

interface IReportFormProps {
  slug: string
  customers?: CustomerProps[]
  collectors?: Collector[]
  shipping?: ShippingProps[]
  isFindByCustomer: boolean
  isFindByCollector: boolean
  isFindByShipping: boolean
  isCollectorsLoading: boolean
  isCustomersLoading: boolean
  isShippingLoading: boolean
}

export function ReportForm({
  slug,
  customers,
  collectors,
  shipping,
  isFindByCustomer,
  isFindByCollector,
  isFindByShipping,
  isCollectorsLoading,
  isCustomersLoading,
  isShippingLoading,
}: IReportFormProps) {

  const { register, watch, formState: { errors, isSubmitting } } = useFormContext()

  const { userLogged } = useAuth()

  const isUserLoggedCustomer = userLogged?.user_type === "CLIENTE"
  const isUserLoggedCollector = userLogged?.user_type === "COLETADOR"

  const isFindByGeneralAndCustomer = reportsFilteredByGeneralAndCustomer.some(value => value === slug)
  const isFindByGeneralCustomerAndCollector = reportsFilteredByGeneralCustomerAndCollector.some(value => value === slug)
  const isFindByOnlyGeneral = reportsFilteredOnlyByGeneral.some(value => value === slug)
  const isFindByOnlyCollector = reportsFilteredOnlyByCollector.some(value => value === slug)

  const findByOptionsByReport = isFindByGeneralAndCustomer
    ? findByReportOptions.filter(option => ["general", "customer"].includes(option.key))
    : isFindByGeneralCustomerAndCollector
      ? findByReportOptions.filter(option => ["general", "customer", "collector"].includes(option.key))
      : isFindByOnlyGeneral
        ? findByReportOptions.filter(option => ["general"].includes(option.key))
        : isFindByOnlyCollector
          ? findByReportOptions.filter(option => ["collector"].includes(option.key))
          : findByReportOptions

  const [
    isFindBySelected,
    isServiceTypeSelected,
    startFilter
  ] = watch([
    "find_by",
    "service_type",
    "startFilter"
  ])
  const isValidStartDate = new Date(startFilter) instanceof Date && !isNaN(new Date(startFilter).getMilliseconds())

  const maxEndFilter = isValidStartDate
    ? formatDate.handle(add(new Date(startFilter), {
      days: 31
    }), 'DateWithoutHourToInput')
    : ''

  return (
    <Flex direction="column" gap="4">
      <Select
        {...register("find_by")}
        name="find_by"
        isDisabled={isUserLoggedCollector || isUserLoggedCustomer}
        placeholder="Selecione uma opção..."
        label="Pesquisar por"
        error={errors.find_by}
        required
      >
        {findByOptionsByReport.map(option => {
          return (
            <option key={option.key} value={option.value}>{option.value}</option>
          )
        })}
      </Select>
      <Select
        {...register("service_type")}
        name="service_type"
        placeholder="Selecione uma opção..."
        label="Tipo de serviço"
        error={errors.service_type}
        required
      >
        {serviceTypeOptions.map(option => {
          return (
            <option key={option.key} value={option.value}>{option.value}</option>
          )
        })}

      </Select>
      {isFindByCustomer && isServiceTypeSelected && (
        <Select
          {...register("customer_id")}
          name="customer_id"
          label="Cliente"
          isLoading={isCustomersLoading}
          customers={customers}
          isDisabled={isUserLoggedCustomer}
          placeholder="Selecione uma opção..."
          error={errors.customer_id}
          required
        />
      )}
      {isFindByCollector && isServiceTypeSelected && (
        <Select
          {...register("collector_id")}
          name="collector_id"
          label="Coletador"
          isLoading={isCollectorsLoading}
          isDisabled={isUserLoggedCollector}
          collectors={collectors}
          placeholder="Selecione uma opção..."
          error={errors.collector_id}
          required
        />
      )}
      {isFindByShipping && isServiceTypeSelected && (
        <Select
          {...register("shipping_id")}
          name="shipping_id"
          label="Transportadora"
          isLoading={isShippingLoading}
          shipping={shipping}
          placeholder="Selecione uma opção..."
          error={errors.shipping_id}
          required
        />
      )}

      {isFindBySelected && isServiceTypeSelected && (
        <Stack flex="1" direction={['column', 'column', 'row']} spacing="24px">
          <Input
            {...register("startFilter")}
            name="startFilter"
            type="date"
            label="Início do período"
            error={errors.startFilter}
            required
          />
          <Input
            {...register("endFilter")}
            name="endFilter"
            type="date"
            label="Fim do período"
            error={errors.endFilter}
            max={maxEndFilter}
            required
          />
        </Stack>
      )}

      <Flex flex="1" alignSelf={["center", "center", "flex-end"]}>
        <Button
          type="submit"
          isLoading={isSubmitting}
          colorScheme="blue"
        >
          Gerar relatório
        </Button>
      </Flex>

    </Flex>
  )
}
