import { Box, Flex } from "@chakra-ui/react";
import { ExtraAndDiscountStatus } from "api/extrasAndDiscounts/_types/ExtraAndDiscount";


interface CustomerExtraDiscountStatusProps {
  status: ExtraAndDiscountStatus
}

export function CustomerExtraDiscountStatus({ status }: CustomerExtraDiscountStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >

      {status === 'requested' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}
      {status === 'closed' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}
      {status === 'requested' ? 'Solicitado' : 'Finalizado'}

    </Flex>
  )
}
