import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm"
import { FormProvider, useForm } from "react-hook-form"

import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

import { useToastify } from "hooks/toastify/useToastify"
import { Button, Flex } from "@chakra-ui/react"
import { useAuth } from "hooks/auth/useAuth"
import { useHistory } from "react-router-dom"
import { useEffect } from "react"
import { FinancialClassificationForm, FinancialClassificationFormInputs } from "./components/FinancialClassificationForm"
import { useFinancialClassificationFunctions } from "hooks/financialClassification/useFinancialClassificationFunctions"

const createFinancialClassificationSchema = yup.object().shape({
  type: yup.string().required("Campo obrigatório")
})

export function CreateFinancialClassificationPage() {
  const formMethods = useForm<FinancialClassificationFormInputs>({
    resolver: yupResolver(createFinancialClassificationSchema)
  })

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged.permissions.includes('create-financial-classification')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  const { promiseMessage } = useToastify()

  const { handleSubmit, formState: { isSubmitting } } = formMethods

  const { createFinancialClassification: { mutateAsync: createFinancialClassification } } = useFinancialClassificationFunctions()

  async function handleCreateFinancialClassification({
    type
  }: FinancialClassificationFormInputs) {
    const successMessage = 'Classificação Financeira criada com sucesso!'

    await promiseMessage(createFinancialClassification(type, {
      onSuccess: () => redirectTo("/classificacao-financeira")
    }), successMessage)
  }

  return (
    <StandardBackgroundForm title="Criar Classificação Financeira" onSubmit={handleSubmit(handleCreateFinancialClassification)}>
      <FormProvider {...formMethods}>
        <FinancialClassificationForm />
      </FormProvider>

      <Flex
        mt={6}
        direction={["column", "column", "row"]}
        align="center"
        justify={["center", "center", "flex-end"]}
      >
        <Button
          w={["full", "full", "min"]}
          colorScheme="blue"
          type="submit"
          isLoading={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}
