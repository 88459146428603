import { Stack, FormLabel, FormControl, Select, Input, Flex, Button, Text, Box } from "@chakra-ui/react"
import { createTrainingLlmManually } from "api/trainingLlm/createTrainingLlmManually"
import { useToastify } from "hooks/toastify/useToastify"
import { useLLMUsers } from "hooks/user/useLLMUsers"
import { useFormContext } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import { captalize } from "utils/captalize"
import { CreateTrainingLlmSchema } from "./CreateTrainingLlm"

export function CreateTrainingLlmManuallyForm() {
  const { promiseMessage } = useToastify()
  const {register, handleSubmit, formState: {errors, isSubmitting}} = useFormContext<CreateTrainingLlmSchema>()

  const {
    data: llmUsersData
  } = useLLMUsers({
    queryParams: {
      situation: 'ATIVO'
    }
  })

  const queryClient = useQueryClient()

  const { mutateAsync: createTrainingLlmManuallyFn } = useMutation({
    mutationFn: createTrainingLlmManually,
    onSuccess() {
      queryClient.invalidateQueries(['trainingsLlm'])
      queryClient.invalidateQueries(['pending-trainings-llm'])
    }
  })


  async function handleCreateTrainingLlm({ collaboratorId, dueDate, training }: CreateTrainingLlmSchema) {
    await promiseMessage(createTrainingLlmManuallyFn({
      body: {
        collaboratorId,
        dueDate,
        training
      }
    }), 'Treinamento LLM solicitado! 🎉')
  }

  return (
    <Box
      as='form'
      onSubmit={handleSubmit(handleCreateTrainingLlm)}
    >
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormLabel fontSize="sm">
            Colaborador
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <FormControl isInvalid={!!errors.collaboratorId}>
            <Select
              {...register('collaboratorId')}
              name="collaboratorId"
              placeholder="Selecione..."
              size="sm"
              rounded="md"
            >
              {llmUsersData?.users?.map((user) => {
                return (
                  <option key={user.id} value={user.id}>{captalize(`${user.firstname} ${user.lastname}`)}</option>
                )
              })}
            </Select>
          </FormControl>
        </Stack>
      </Stack><Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors?.training}>
            <FormLabel fontSize="sm">
              Treinamento
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('training')}
              name="training"
              size="sm"
              rounded="md" />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors?.dueDate}>
            <FormLabel fontSize="sm">
              Data limite para realização
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('dueDate')}
              name="dueDate"
              type="date"
              size="sm"
              rounded="md" />
          </FormControl>
        </Stack>
      </Stack>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Criar
        </Button>
      </Flex>
    </Box>
  )
}
