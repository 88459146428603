import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CltIntercurrenceBody {
  type: string
  date: Date
  description: string
  regional: string
  partnerType: string
  collaboratorName: string

  accidentAddress?: string
  bodyPart?: string
  medicalCareTime?: string
  medicalCertificateCid?: string
  medicalName?: string
  medicalCrm?: string

  attachmentId?: string
  discountTotalValueInCents?: number

  minutesQuantity?: number
}

interface CreateCltIntercurrenceProps {
  body: CltIntercurrenceBody
}

export async function createCltIntercurrence({
  body
}: CreateCltIntercurrenceProps) {
  try {
    const response = await api.post(`/clt-intercurrences`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
