import { useMemo, useState, useEffect, ChangeEvent } from "react";
import { usePagination } from "@ajna/pagination";
import { Heading, Flex, InputGroup, InputLeftElement, Icon, Box, Divider, IconButton, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Input, Spinner } from "@chakra-ui/react";
import { Pagination } from "components/Pagination/Pagination";

import { useAuth } from "hooks/auth/useAuth";
import { useBusinessServices } from "hooks/services/requestedBusiness/useBusinessServices";
import { FaChevronDown, FaEdit, FaEllipsisH, FaEye, FaFilter, FaSearch, FaTrash } from "react-icons/fa";
import { useHistory, Link } from "react-router-dom";
import { BusinessServicesCheckboxFilterButton } from "./components/BusinessServicesCheckboxFilterButton";
import { useCitiesNames } from "hooks/city/useCitiesNames";
import { formatDate } from "utils/DateFunctions/formatDate";
import { useSwal } from "hooks/swal/useSwal";
import { useRequestedBusinessServiceFunctions } from "hooks/services/requestedBusiness/useRequestedBusinessServiceFunctions";
import { searchBoxFilter } from "utils/searchBoxFilter";


export interface FilterOptions {
  showValue: string
  value: string
  selected: boolean
}

const generalFilterOptions = [
  { showValue: 'Cliente', value: 'customer', selected: false },
  { showValue: 'Protocolo', value: 'protocol', selected: false },
  { showValue: 'Cidade de Origem', value: 'sourceCities', selected: false },
  { showValue: 'Cidade de Destino', value: 'destinationCities', selected: false },
]

export function BusinessServices() {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE
  const { userLogged } = useAuth()
  const { confirmMessage, standardMessage } = useSwal()

  const { push: redirect, location } = useHistory()
  const [generalFilters, setGeneralFilters] = useState<FilterOptions[]>(generalFilterOptions)

  const [filters, setFilters] = useState({
    protocol: '',
    customer: '',
    sourceCities: [],
    destinationCities: [],
    sourceCity: '',
    destinationCity: ''
  })

  function handleSetFilters(event: ChangeEvent<HTMLInputElement>) {
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        [event.target.name]: event.target.value
      }
    })
  }

  function handleSetFiltersSourceCities(cityId: string) {
    let updatedSourceCities = [...filters.sourceCities];

    if (updatedSourceCities.includes(cityId)) {
      updatedSourceCities = updatedSourceCities.filter((id) => id !== cityId);
    } else {
      updatedSourceCities.push(cityId);
    }

    setFilters({
      ...filters,
      sourceCities: updatedSourceCities,
    });
  }
  function handleSetFiltersDestinationCities(cityId: string) {
    let updatedDestinationCities = [...filters.destinationCities];

    if (updatedDestinationCities.includes(cityId)) {
      updatedDestinationCities = updatedDestinationCities.filter((id) => id !== cityId);
    } else {
      updatedDestinationCities.push(cityId);
    }

    setFilters({
      ...filters,
      destinationCities: updatedDestinationCities,
    });
  }

  const userLoggedHasViewRequestedBusinessService = userLogged?.permissions.includes('view-requested-business-service')

  const currentPageFilter = location.search.match(`[?&]page=([^&]+)`)

  const { data: businessServicesData, isFetching: isFetchingBusinessServices } = useBusinessServices({
    queryParams: {
      currentPage: currentPageFilter ? Number(currentPageFilter[1]) : 1,
      pageSize: Number(itemLimit),
      customer: filters.customer ? filters.customer : undefined,
      protocol: filters.protocol ? filters.protocol : undefined,
      sourceCities: filters.sourceCities.length > 0 ? filters.sourceCities.join(',') : undefined,
      destinationCities: filters.destinationCities.length > 0 ? filters.destinationCities.join(',') : undefined
    }
  })

  const {
    deleteRequestedBusinessService: {
      mutateAsync: deleteRequestedBusinessService,
    },
  } = useRequestedBusinessServiceFunctions()


  const { data } = useCitiesNames()

  const sourceCitiesNamesFiltered = data?.pages[0]?.cities.filter((city) => {
    const filter = filters.sourceCity
      ? searchBoxFilter(city.name, filters.sourceCity)
      : false

    return filter
  })

  const destinationCitiesNamesFiltered = data?.pages[0]?.cities.filter((city) => {
    const filter = filters.destinationCity
      ? searchBoxFilter(city.name, filters.destinationCity)
      : false

    return filter
  })
  const sourceCitiesFiltersSelectedCount = filters.sourceCities.length
  const destinationCitiesFiltersSelectedCount = filters.destinationCities.length


  function handleSetGeneralFilter(filterType: string) {
    const newGeneralFilters = [...generalFilters]

    const generalFilterSelected = newGeneralFilters.find(
      (generalFilter) => generalFilter.value === filterType
    )
    generalFilterSelected.selected = !generalFilterSelected.selected


    setGeneralFilters(() => newGeneralFilters)
  }
  const generalFiltersSelected = useMemo(() => {
    return generalFilters
      .filter((generalFilter) => generalFilter.selected)
      .map((generalFilter) => generalFilter.value)
  }, [generalFilters])



  async function handleDeleteRequestedBusinessService(id: string) {
    const hasDeleteBusinessBudget = await confirmMessage({
      title: 'Deseja excluir um serviço solicitado business?',
    })

    if (hasDeleteBusinessBudget) {
      await deleteRequestedBusinessService(id)
      return
    }

    standardMessage('Ação cancelada com êxito!')
  }

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: businessServicesData?.totalPages,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    });

  useEffect(() => {
    if (userLoggedHasViewRequestedBusinessService) {
      redirect({
        pathname: '/servicos-solicitados-business',
        search: `page=${currentPage}`
      })
    }
  }, [redirect, currentPage, userLoggedHasViewRequestedBusinessService])

  const handleChangePage = (page: number) => setCurrentPage(page)

  return (

      <Box
        p="6"
        bg="white"
        rounded="md"
        boxShadow="md"
      >
        <Heading size="lg">Serviços Solicitados Business</Heading>
        <Flex
          w="full"
          align="center"
          justify="flex-end"
        >
          <Flex
            gap="4"
            w={["full", "full", "min"]}
            direction={["column", "column", "row"]}
          >
            <BusinessServicesCheckboxFilterButton.Root title="Filtros" icon={FaFilter}>
              <BusinessServicesCheckboxFilterButton.Content filterOptions={generalFilterOptions} onSetFilterOption={handleSetGeneralFilter} />
            </BusinessServicesCheckboxFilterButton.Root>
          </Flex>
        </Flex>

        <Divider mt="4" />
        <Flex
          mt="4"
          gap="4"
          p="2"
          overflow="auto"
        >
          {generalFiltersSelected.includes('customer') && (
            <InputGroup
              w={["full", "full", "min"]}
            >
              <InputLeftElement
                pointerEvents="none"
              >
                <Icon
                  as={FaSearch}
                />
              </InputLeftElement>
              <Input
                name="customer"
                placeholder="Cliente..."
                value={filters.customer}
                onChange={(event) => handleSetFilters(event)}
                size="md"
                w={["full", "full", "min"]}
                rounded="xl"
                boxShadow="base"
                pl={8}
              />
            </InputGroup>
          )}
          {generalFiltersSelected.includes('protocol') && (
            <InputGroup
              w={["full", "full", "min"]}
            >
              <InputLeftElement
                pointerEvents="none"
              >
                <Icon
                  as={FaSearch}
                />
              </InputLeftElement>
              <Input
                name="protocol"
                placeholder="Protocolo..."
                value={filters.protocol}
                onChange={(event) => handleSetFilters(event)}
                size="md"
                w={["full", "full", "min"]}
                rounded="xl"
                boxShadow="base"
                pl={8}
              />
            </InputGroup>
          )}
          {generalFiltersSelected.includes('sourceCities') && (
            <BusinessServicesCheckboxFilterButton.Root
              title="Cidades de Origem"
              icon={FaChevronDown}
              checkedOptionsCount={sourceCitiesFiltersSelectedCount}
            >
              <BusinessServicesCheckboxFilterButton.ContentList
                name="sourceCity"
                valueFilter={filters.sourceCity}
                citiesNamesFiltered={sourceCitiesNamesFiltered}
                handleSetFilters={handleSetFilters}
                handleSetFiltersCities={handleSetFiltersSourceCities}
              />
            </BusinessServicesCheckboxFilterButton.Root>
          )}
          {generalFiltersSelected.includes('destinationCities') && (
            <BusinessServicesCheckboxFilterButton.Root
              title="Cidades de Destino"
              icon={FaChevronDown}
              checkedOptionsCount={destinationCitiesFiltersSelectedCount}
            >
              <BusinessServicesCheckboxFilterButton.ContentList
                name="destinationCity"
                valueFilter={filters.destinationCity}
                citiesNamesFiltered={destinationCitiesNamesFiltered}
                handleSetFilters={handleSetFilters}
                handleSetFiltersCities={handleSetFiltersDestinationCities}
              />
            </BusinessServicesCheckboxFilterButton.Root>
          )}
        </Flex>

        {isFetchingBusinessServices ? (
          <Spinner />
        ) : (
          <TableContainer
            fontSize="xs"
            rounded="md"
            border="1px solid"
            borderColor="gray.100"
            ml="2"
          >
            <Table
              size="md"
              variant="simple"
            >
              <Thead
                bg="blue.50"
              >
                <Tr>
                  <Th>Cliente</Th>
                  <Th>Protocolo</Th>
                  <Th>Data Início da Rota</Th>
                  <Th>Horário Final da Rota</Th>
                  <Th>Cidade(s) Origem</Th>
                  <Th>Cidade(s) Destino</Th>
                  <Th>Gelo Seco</Th>
                  <Th>Motorista</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {businessServicesData?.businessServices.map((businessService) => {
                  return (
                    <Tr key={businessService?.id}>
                      <Td>{businessService?.customerIDBusinessService.trading_firstname}</Td>
                      <Td>{businessService?.serviceIDRequestedBusiness.protocol}</Td>
                      <Td>{formatDate.handle(businessService?.route_start_date, "DateWithoutHourToShow")}</Td>
                      <Td>{formatDate.handle(businessService?.route_end_hour, "DateOnlyWithHourMinute")}</Td>
                      <Td>{businessService?.sourceCities.join(', ')}</Td>
                      <Td>{businessService.destinationCities.join(', ')}</Td>
                      <Td>{businessService.gelo_seco}</Td>
                      <Td>{businessService?.driverIDBusinessService.firstname} {businessService?.driverIDBusinessService.lastname}</Td>
                      <Td>
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            variant='ghost'
                            aria-label='Options'
                            icon={<FaEllipsisH />}
                          />
                          <MenuList
                            boxShadow="md"
                          >
                            <MenuGroup
                              title="Ações"
                            >
                              {userLogged.permissions.includes(
                                'view-requested-business-service',
                              ) && (
                                  <MenuItem
                                    as={Link}
                                    to={`/servico-business/visualizar/${businessService.id}`}
                                  >
                                    <Flex
                                      gap={2}
                                      align="center"
                                    >
                                      <Icon
                                        as={FaEye}
                                        fontSize="md"
                                      />
                                      Visualizar
                                    </Flex>

                                  </MenuItem>
                                )}
                              {userLogged.permissions.includes(
                                'edit-requested-business-service',
                              ) && (

                                  <MenuItem
                                    as={Link}
                                    to={`/servico-business/editar/${businessService.id}`}
                                  >
                                    <Flex
                                      gap={2}
                                      align="center"
                                    >
                                      <Icon
                                        as={FaEdit}
                                        fontSize="md"
                                      />
                                      Editar
                                    </Flex>

                                  </MenuItem>
                                )}
                              {userLogged.permissions.includes(
                                'remove-requested-business-service',
                              ) && (
                                  <MenuItem
                                    onClick={() =>
                                      handleDeleteRequestedBusinessService(
                                        businessService.id,
                                      )
                                    }
                                  >
                                    <Flex
                                      gap={2}
                                      align="center"
                                    >
                                      <Icon
                                        as={FaTrash}
                                        fontSize="md"
                                      />
                                      Deletar
                                    </Flex>

                                  </MenuItem>
                                )}
                            </MenuGroup>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />
      </Box>

  )
}
