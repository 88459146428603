import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

type CreateBillingBody = FormData

interface CreateBillingParams {
  body: CreateBillingBody
}


export async function createBilling({
  body,
}: CreateBillingParams) {
  try {
    await api.post(`/billings`, body)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
