import request from "axios"
import { useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { UserTasks } from "./dtos/UserTasks";

type QueryOptions = UseQueryOptions<UserTasks, unknown, UserTasks, "userTasks">

interface UseUserTasksOptions {
  queryOptions?: QueryOptions
  id: string
}

async function getUserTasks(id: string) {
  try {
    const { data } = await api.get(`/user/tasks/${id}`)

    return data
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

export function useUserTasks(options?: UseUserTasksOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery("userTasks", () => getUserTasks(options.id), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    enabled: !!options?.id,
    onError: async (error) => onRequestError(error)
  })
}
