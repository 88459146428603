import { format, toDate } from 'date-fns'

interface DataProps {
  collector_id: string
  allocate_flight: string
  allocate_availability_date: string
  allocate_availability_hour: string
  airline_information: string
  observation: string
}

export function allocatedServiceFormatDataToBack(data: DataProps) {
  const availabilityHourSplit = data.allocate_availability_hour.split(':')
  const availabilityDateSplit = data.allocate_availability_date.split('-')

  const availabilityHourSplitToDate = toDate(Date.now()).setHours(
    Number(availabilityHourSplit[0]),
    Number(availabilityHourSplit[1]),
    0,
  )

  const availabilityHourSplitToDateFormated = format(
    toDate(availabilityHourSplitToDate),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )
  const formatedDataWeekClose = format(
    new Date(
      Number(availabilityDateSplit[0]),
      Number(availabilityDateSplit[1]) - 1,
      Number(availabilityDateSplit[2]),
    ),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )

  const newAllocatedServiceFormatedData = {
    ...data,
    allocate_availability_date: formatedDataWeekClose,
    allocate_availability_hour: availabilityHourSplitToDateFormated,
  }
  return newAllocatedServiceFormatedData
}
