import { useEffect } from "react";
import { usePagination } from "@ajna/pagination";
import {
  Box,
  Flex,
  Heading,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import { FiCheckCircle } from "react-icons/fi";
import { useService } from "hooks/services/service";
import { useAddresses } from "hooks/address/useAddresses";
import { useAuth } from "hooks/auth/useAuth";
import { useHistory } from "react-router-dom";
import { Pagination } from "components/Pagination/Pagination";
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";
import { SearchBox } from "components/SearchBox/SearchBox";
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions";
import { formatDate } from "utils/DateFunctions/formatDate";
import { searchBoxFilter } from "utils/searchBoxFilter";
import { TableFilterButton } from "components/Filters/TableFilterButton";
import { serviceSortByDate } from "utils/ServiceFunctions/serviceSortByDate";

import { ValidateServicesRequestedToCancelModal } from "./components/ValidateServiceRequestedToCancelModal";

interface FilterOptionInputs {
  protocol: string;
  address: string;
  collect_date: string;
  customer: string;
  collector: string;
}

const serviceRequestToCancelfilterOptions = [
  { key: "protocol", value: "Protocolo", checked: false },
  { key: "collect_date", value: "Data da coleta", checked: false },
  { key: "city_source", value: "Cidade de Origem", checked: false },
  { key: "city_destination", value: "Cidade de Destino", checked: false },
  { key: "customer", value: "Cliente", checked: false },
  { key: "collector", value: "Coletador de Origem", checked: false },
  {
    key: "collector_destination",
    value: "Coletador de Destino",
    checked: false,
  },
];

const serviceRequestToCancelOrderOptions = [
  { key: "protocol", value: "Protocolo", checked: false },
  { key: "collect_date", value: "Data da coleta", checked: false },
];

export function ServiceRequestedToCancel() {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE;
  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions();

  useEffect(() => {
    onLoadSetFilterOptions(serviceRequestToCancelfilterOptions);
  }, [onLoadSetFilterOptions]);
  useEffect(() => {
    onLoadSetOrderOptions(serviceRequestToCancelOrderOptions);
  }, [onLoadSetOrderOptions]);

  const { userLogged } = useAuth();
  const { push: redirectTo } = useHistory();

  const userHasPermissionToValidateCancelServiceRequest =
    userLogged?.permissions.includes("service-validate-cancel-request");

  useEffect(() => {
    if (!userHasPermissionToValidateCancelServiceRequest) {
      redirectTo("/");
    }
  }, [userHasPermissionToValidateCancelServiceRequest, redirectTo]);

  const stepsToRequestServices = ["toValidateCancelRequest"];
  const {
    servicesBySteps: { data: services, isLoading: isServicesLoading },
  } = useService(null, true, false, false, stepsToRequestServices);

  const { data: addresses, isLoading: isAddressesLoading } = useAddresses();
  const { register, watch } = useForm<FilterOptionInputs>();

  const {
    isOpen: isValidateServiceToCancelOpen,
    onOpen: onOpenValidateServiceToCancel,
    onClose: onCloseValidateServiceToCancel,
  } = useDisclosure();

  const filterValues = watch([
    "protocol",
    "address",
    "collect_date",
    "customer",
    "collector",
  ]);

  const servicesWithCollectAddressesToCancel = services?.map((service) => {
    const collectSourceAddresses = service.serviceIDRequested
      .source_address_id as string[];

    const collectDestinationAddresses = service.serviceIDRequested
      .destination_address_id as string[];

    const citySource = Array.from(
      new Set(
        addresses
          ?.filter((address) => collectSourceAddresses.includes(address.id))
          .map((address) => address.cityIDAddress.name)
      )
    )
      .join(", ")
      .toUpperCase();

    const cityDestination = Array.from(
      new Set(
        addresses
          ?.filter((address) =>
            collectDestinationAddresses.includes(address.id)
          )
          .map((address) => address.cityIDAddress.name)
      )
    )
      .join(", ")
      .toUpperCase();

    return {
      id: service.id,
      protocol: service.protocol,
      city_source: citySource,
      city_destination: cityDestination,
      collect_date: service.serviceIDRequested.collect_date,
      customer: service.customerIDService.trading_firstname,
      collector:
        service.serviceIDRequested.sourceCollectorIDService.trading_name,
      collector_destination:
        service.serviceIDRequested.destinationCollectorIDService.trading_name,
      cancel_observation: service.cancel_observation,
      cancel_reason: service.cancel_reason
    };
  });

  const collectServicesFiltered = servicesWithCollectAddressesToCancel
    ?.filter((service) => {
      const [
        protocolFiltered,

        collectDateFiltered,
        customerFiltered,
        collectorFiltered,
      ] = filterValues;

      const protocolFilter = protocolFiltered
        ? searchBoxFilter(String(service.protocol), protocolFiltered)
        : null;

      const collectDateFilter = collectDateFiltered
        ? searchBoxFilter(
            formatDate.handle(service.collect_date, "DateWithoutHourToShow"),
            collectDateFiltered
          )
        : null;
      const customerFilter = customerFiltered
        ? searchBoxFilter(service.customer, customerFiltered)
        : null;
      const collectorFilter = collectorFiltered
        ? searchBoxFilter(service.collector, collectorFiltered)
        : null;

      if (filterValues.some((value) => !!value)) {
        return (
          protocolFilter ||
          collectDateFilter ||
          customerFilter ||
          collectorFilter
        );
      }

      return service;
    })
    .sort((a, b) => {
      const isProtocolOrder = orderOptionSelected.includes("protocol")
        ? a.protocol - b.protocol
        : 0;

      const isCollectDateOrder = orderOptionSelected.includes("collect_date")
        ? serviceSortByDate(a.collect_date, b.collect_date)
        : 0;

      return isProtocolOrder || isCollectDateOrder;
    });

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: services?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    });
  if (isServicesLoading || isAddressesLoading) {
    return <GeneralContentLoading />;
  }

  return (
    <>

        <Box bg="white" padding={[2, 4]} borderRadius="8px" flex="1">
          <Flex gap="2" direction="column">
            <Heading
              size="lg"
              fontFamily="poppins"
              textAlign={["center", "center", "justify"]}
            >
              Solicitações de cancelamento de serviço
            </Heading>
            <Flex justify="space-between" flex="1" w="full">
              <TableFilterButton />
              <Flex direction="column" gap="4">
                {filterOptions
                  .filter((option) => option.checked === true)
                  .map((option) => {
                    return (
                      <SearchBox
                        {...register(option.key as keyof FilterOptionInputs)}
                        key={option.key}
                        size="sm"
                        placeholder={"Buscar " + option.value}
                        handleSearch={() => {}}
                      />
                    );
                  })}
              </Flex>
            </Flex>

            <TableContainer w="full">
              <Table colorScheme="gray" variant="striped" size="sm">
                <Thead>
                  <Tr>
                    <Td></Td>
                    {serviceRequestToCancelfilterOptions.map((option) => {
                      return (
                        <Th key={option.key}>{option.value.toUpperCase()}</Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {collectServicesFiltered
                    ?.slice(offset, offset + Number(itemLimit))
                    ?.map((service) => {
                      return (
                        <Tr key={service.id}>
                          <ValidateServicesRequestedToCancelModal
                            onClose={onCloseValidateServiceToCancel}
                            isOpen={isValidateServiceToCancelOpen}
                            serviceId={service.id}
                            collectDate={service.collect_date}
                            protocol={service.protocol}
                            cancelObservation={service.cancel_observation}
                            cancelReason={service.cancel_reason}
                          />
                          <Td>
                            <Icon
                              onClick={() => {
                                onOpenValidateServiceToCancel();
                              }}
                              cursor="pointer"
                              as={FiCheckCircle}
                              fontSize="20"
                              mr="2"
                              mt={["2", "2", "0"]}
                            />
                          </Td>
                          <Td>{service.protocol} </Td>

                          <Td>
                            {formatDate.handle(
                              service.collect_date,
                              "DateWithoutHourToShow"
                            )}
                          </Td>
                          <Td>{service.city_source}</Td>
                          <Td>{service.city_destination}</Td>
                          <Td>{service.customer}</Td>
                          <Td>{service.collector}</Td>
                          <Td>{service.collector_destination}</Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>
            <Pagination
              currentPage={currentPage}
              pagesQuantity={pagesCount}
              pages={pages}
              handlePageChange={setCurrentPage}
            />
          </Flex>
        </Box>

    </>
  );
}
