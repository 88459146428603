import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Patrimony } from "./patrimony";
import { PatrimonyType } from "./patrimonyType";

interface GetPatrimonyParams {
  patrimonyId: string
}

interface GetPatrimonyResponse {
  patrimony: Patrimony & {
    patrimonyType: PatrimonyType
  }
}

export async function getPatrimony({ patrimonyId }: GetPatrimonyParams) {
  try {
    const response = await api.get<GetPatrimonyResponse>(`patrimonies/${patrimonyId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
