import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { PurchaseOrderResponse } from "./dtos/PurchaseOrder"

type QueryOptions = UseQueryOptions<PurchaseOrderResponse, unknown, PurchaseOrderResponse, QueryKey>

interface UsePurchaseOrderOptions {
  purchaseOrderId: string
  queryKey?: string
  queryOptions?: QueryOptions
}

export function usePurchaseOrder(options?: UsePurchaseOrderOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getPurchaseOrder() {
    try {
      const { data } = await api.get(`/purchase-orders/${options.purchaseOrderId}`)

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new Error("Erro inesperado!")
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }


  return useQuery([
    options?.queryKey || 'purchaseOrder',
    options.purchaseOrderId
  ], getPurchaseOrder, {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
