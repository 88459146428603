import * as yup from "yup"
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Divider, Flex, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import { useSwal } from "hooks/swal/useSwal";
import { useInternComunicationsFunctions } from "hooks/internComunication/useInternComunicationsFunctions";
import { TextArea } from "components/Inputs/TextInput";
import { Radio } from "components/Inputs/RadioInput";
import { positiveNegativeOptions } from "utils/CustomLists/positiveNegativeOptions";
import { FaInfo } from "react-icons/fa";
import { useAuth } from "hooks/auth/useAuth";
import { useQueryClient } from "react-query";

interface InternComunicationValidateAnswerModalInputs {
  comunication_answer: string
  request_changes: 'yes' | 'no'
}

interface InternComunicationOpeningQuestionMessages {
  comunication_question: string
}

interface InternComunicationAutoGeneratedValidateModalInfo {
  id: string
  hasOwner: boolean
  comunicationSubject: string
  comunicationType: string
  internComunicationOpeningQuestionMessages: InternComunicationOpeningQuestionMessages
}

interface InternComunicationValidateAnswerModalProps {
  isInternComunicationAutoGeneratedValidateModalOpen: boolean
  onCloseInternComunicationAutoGeneratedValidateModal: () => void
  internComunicationAutoGeneratedValidateModalInfo: InternComunicationAutoGeneratedValidateModalInfo
}

function cleanText(text: string) {
  return text?.replace(/<\/?[^>]+(>|$)/g, '').replace(/strong/g, '').trim();
}

const internComunicationValidateAnswerModalSchema = yup.object().shape({
  comunication_answer: yup.string().required('Campo obrigatório'),
  request_changes: yup.mixed().when('$hasOwner', {
    is: true,
    then: yup.boolean().required('Campo obrigatório').typeError('Campo obrigatório').transform((value: 'yes' | 'no') => {
      if (typeof value !== 'string') {
        return null
      }

      return value === 'yes'
    })
  })
})

export function InternComunicationValidateAutoGeneratedModal({
  internComunicationAutoGeneratedValidateModalInfo,
  isInternComunicationAutoGeneratedValidateModalOpen,
  onCloseInternComunicationAutoGeneratedValidateModal
}: InternComunicationValidateAnswerModalProps) {
  const { userLogged } = useAuth()

  const isInternComunicationEnabledToRequestChanges =
    internComunicationAutoGeneratedValidateModalInfo.hasOwner ||
    (internComunicationAutoGeneratedValidateModalInfo?.comunicationType === 'DIRETORIA COMERCIAL' && internComunicationAutoGeneratedValidateModalInfo?.comunicationSubject === 'ALTERAÇÃO DE INSUMOS UTILIZADOS')

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<InternComunicationValidateAnswerModalInputs>({
    resolver: yupResolver(internComunicationValidateAnswerModalSchema),
    context: {
      hasOwner: internComunicationAutoGeneratedValidateModalInfo.hasOwner
    }
  })

  const {
    validateAutoGeneratedInternComunication: {
      mutateAsync: validateAutoGeneratedInternComunication
    }
  } = useInternComunicationsFunctions()

  const { standardMessage, confirmMessage } = useSwal()

  const queryClient = useQueryClient()

  async function handleValidateInternComunicationAnswer(
    values: InternComunicationValidateAnswerModalInputs
  ) {

    const hasValidateInternComunication = await confirmMessage({
      title: 'Deseja validar e encerrar a comunicação interna?'
    })

    const validateInternComunicationAnswerInput = {
      ...values,
    }

    if ((internComunicationAutoGeneratedValidateModalInfo?.comunicationType === 'DIRETORIA COMERCIAL' && internComunicationAutoGeneratedValidateModalInfo?.comunicationSubject === 'ALTERAÇÃO DE INSUMOS UTILIZADOS')) {
      validateInternComunicationAnswerInput['status'] = 'WAITING-MONITORING-VALIDATION'
      validateInternComunicationAnswerInput['comunication_type'] = 'DIRETORIA DE LOGÍSTICA - PLANEJAMENTO'
    }

    if (hasValidateInternComunication) {

      await validateAutoGeneratedInternComunication({
        id: internComunicationAutoGeneratedValidateModalInfo.id,
        input: {
          ...validateInternComunicationAnswerInput,
          has_owner: internComunicationAutoGeneratedValidateModalInfo?.hasOwner,
        }
      }, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ['team-intern-comunications', userLogged?.id]
          })
          reset()
          onCloseInternComunicationAutoGeneratedValidateModal()
        }
      })
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  const questionFormatted = cleanText(
    internComunicationAutoGeneratedValidateModalInfo.internComunicationOpeningQuestionMessages.comunication_question
  )

  return (
    <Modal
      isOpen={isInternComunicationAutoGeneratedValidateModalOpen}
      onClose={onCloseInternComunicationAutoGeneratedValidateModal}
    >
      <ModalOverlay />
      <ModalContent as="form" noValidate onSubmit={handleSubmit(handleValidateInternComunicationAnswer)}>
        <ModalHeader>Validar resposta comunicação interna</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap={6} p={4}>
            <Flex
              w="full"
              gap={6}
              flexDirection="column"
              alignItems="center"
            >
              <TextArea
                name="comunication_question"
                label="Solicitação"
                isReadOnly={true}
                opacity="0.5"
                value={questionFormatted}
                style={{
                  minHeight: '100px',
                  resize: 'vertical',
                  overflow: 'hidden',
                }}
              />
            </Flex>
            <Divider />

            <TextArea
              {...register("comunication_answer")}
              name="comunication_answer"
              label="Resolução"
              error={errors.comunication_answer}
              required
            />

            {isInternComunicationEnabledToRequestChanges && (
              <Flex>
                <Radio
                  {...register('request_changes')}
                  name="request_changes"
                  radioOptions={positiveNegativeOptions}
                  required
                  error={errors.request_changes}
                  label="Solicitar alterações?"
                />

                <Popover>
                  <PopoverTrigger>
                    <IconButton
                      aria-label="Informações adicionais da solicitação"
                      icon={<FaInfo />}
                      size="xs"
                      colorScheme="blue"
                      w="min"
                    />
                  </PopoverTrigger>

                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                      Ao marcar essa opção, será aberta uma nova comunicação direta entre você e quem fez a ação inicial.
                    </PopoverBody>
                  </PopoverContent>
                </Popover>

              </Flex>
            )}

          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex gap={2}>
            <Button variant='ghost' onClick={onCloseInternComunicationAutoGeneratedValidateModal}>
              Fechar
            </Button>
            <Button isLoading={isSubmitting} type="submit" colorScheme='blue'>
              Validar
            </Button>
          </Flex>

        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
