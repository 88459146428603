import { Button, Flex, Icon, Input, Select, Text } from "@chakra-ui/react";
import { PurchaseOrderStatus } from "api/purchaseOrders/purchaseOrder";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface PurchaseOrdersTableFiltersSchema {
  status: PurchaseOrderStatus | 'all'
  protocol: string
}


export function PurchaseOrdersTableFilters() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const { userLogged } = useAuth()

  const {
    register,
    reset,
    handleSubmit,
    setValue,
  } = useForm<PurchaseOrdersTableFiltersSchema>()

  const status = searchParams.get('status') as PurchaseOrderStatus | 'all'
  const protocol = searchParams.get('protocol')

  useEffect(() => {
    if (status) {
      setValue('status', status)
    }

    if (protocol) {
      setValue('protocol', protocol)
    }

  }, [setValue, status, protocol])

  async function handleFilterPurchaseOrders({
    status,
    protocol
  }: PurchaseOrdersTableFiltersSchema) {
    if (protocol) {
      searchParams.set('protocol', protocol)
    } else {
      searchParams.delete('protocol')
    }

    if (status) {
      searchParams.set('status', status)
    } else {
      searchParams.set('status', 'all')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })

  }

  function handleClearFilters() {
    searchParams.set('status', 'all')

    reset()

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterPurchaseOrders)}
    >
      <Text alignSelf={["flex-start", "flex-start", "center"]} fontSize="sm">Filtros:</Text>
      <Input
        {...register('protocol')}
        name="protocol"
        placeholder="Protocolo..."
        size="sm"
        borderRadius="md"
        w={["full", "full", "min"]}
      />
      <Select
        {...register('status')}
        defaultValue="all"
        size="sm"
        borderRadius="md"
        w={["full", "full", "200px"]}
        isDisabled={!!userLogged?.customer_id}
      >
        <option value="all">Todos status</option>
        <option value="analyzing">Análise</option>
        <option value="quoting">Aguardando Cotação</option>
        <option value="approving">Aguardando Aprovação</option>
        <option value="purchasing">Aguardando Compra</option>
        <option value="awaiting-invoice">Aguardando Envio de NF</option>
        <option value="arriving">Aguardando Chegada</option>
        <option value="refused">Recusadas</option>
        <option value="finished">Finalizadas</option>
      </Select>

      <Button
        type="submit"
        size="sm"
        leftIcon={<Icon as={FaSearch} />}
        w={['full', 'full', 'min']}
        isDisabled={!!userLogged?.customer_id}
      >
        Filtrar resultados
      </Button>

      <Button
        type="submit"
        size="sm"
        variant="outline"
        leftIcon={<Icon as={FaTimes} />}
        w={['full', 'full', 'min']}
        onClick={handleClearFilters}
        isDisabled={!!userLogged?.customer_id}
      >
        Remover filtros
      </Button>
    </Flex>
  )
}
