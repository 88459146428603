import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Stack,
  Text,
  Button,
  Icon,
  useDisclosure,
  AlertTitle,
  Alert,
  AlertDescription,
} from '@chakra-ui/react'
import { useEffect, useState, useMemo, Fragment } from 'react'

import { Controller, FormProvider, NestedValue, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { ProviderProps } from '../../contexts/ProviderContext'

import {
  deadline_delivery,
  modal_types,
  negative_positive,
  service_types,
  vehicle_types,
} from '../../utils/customLists'
import { format, add, getDay, set } from 'date-fns'


import { CheckboxService } from '../../components/Inputs/CheckboxService'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { TextArea } from '../../components/Inputs/TextInput'
import { Select } from '../../components/Inputs/SelectInput'
import { Input } from '../../components/Inputs/Input'

import { useCustomer } from '../../hooks/customer/useCustomer'
import { useBranch } from '../../hooks/branch/useBranch'
import { useBudget } from '../../hooks/budget/useBudget'
import { useService } from '../../hooks/services/service'
import { serviceFormatHourToFront } from '../../utils/ServiceFunctions/serviceFormatHourToFront'
import { serviceFormatDateToFront } from '../../utils/ServiceFunctions/serviceFormatDateToFront'
import { useAuth } from '../../hooks/auth/useAuth'
import { useContentLoading } from '../../hooks/loading/useContentLoading'
import { SubmitButton } from '../../components/Buttons/SubmitButton'
import { CancelButton } from '../../components/Buttons/CancelButton'
import { ListButton } from '../../components/Buttons/ListButton'
import { FormActionButton } from '../../components/Buttons/FormActionButton'
import { FaExchangeAlt, FaPen, FaTimes } from 'react-icons/fa'
import { useShipping } from '../../hooks/shipping/useShipping'
import { serviceEditValidateSchema } from '../../validations/serviceEditValidateSchema'
import { BranchProps } from '../../contexts/BranchContext'
import { switchRequestedServiceMateryalTypes } from '../../utils/CustomLists/switchRequestedServiceMateryalTypes'
import { useAddresses } from '../../hooks/address/useAddresses'
import { useCollectors } from 'hooks/collector/useCollectors'
import { GeloSecoAddressesProps } from 'utils/RequestFunctions/Service/Request/requestRequestServiceFunctions'
import { useProviders } from 'hooks/provider/useProviders'
import { CancelServiceModal } from 'components/Modal/Service/CancelServiceModal'
import { useServiceRouteNetworks } from 'hooks/useRouteNetwork/useServiceRouteNetworks'
import { usePlannedRoutes } from 'hooks/usePlannedRoutes'
import { PlannedRoutesInputsGroup } from './ValidateRequested/components/inputGroups/PlannedRoutesInputsGroup'
import { formatDate } from 'utils/DateFunctions/formatDate'
import { comparePlannedRouteArrivalHourIsBiggerThanDestinationBranchOpenHour } from 'utils/ServiceFunctions/comparePlannedRouteArrivalHourIsBiggerThanDestinationBranchOpenHour'
import { comparePlannedRouteDepartureHourIsLessThanSourceBranchCloseHour } from 'utils/ServiceFunctions/comparePlannedRouteDepartureHourIsLessThanSourceBranchCloseHour'
import { comparePlannedRouteDepartureHourIsLessThanSourceBranchOpenHour } from 'utils/ServiceFunctions/comparePlannedRouteDepartureHourIsLessThanSourceBranchOpenHour'
import { findBranchOpenAndCloseHoursBasedOnDayOfWeek } from 'utils/ServiceFunctions/findBranchOpenAndCloseHoursBasedOnDayOfWeek'
import { findPlannedRouteInfoAndFormatAccordinglyWithModal } from 'utils/ServiceFunctions/findPlannedRouteInfoAndFormatAccordinglyWithModal'
import { Address } from 'hooks/address/dtos/Address'


interface IDestinationAddressesInputProps {
  address_id: string
  quantity: number
}

interface FormInputProps {
  customer_id: string
  budget_id: string
  owner: string
  is_unique_collect_address_service: boolean
  source_address_id: NestedValue<string[]>
  destination_address_id: NestedValue<string[]>
  destination_addresses_input: IDestinationAddressesInputProps[] | []
  gelo_seco_addresses: GeloSecoAddressesProps[]

  is_edit_page: boolean
  has_crossdocking: string
  shipping_id: string

  crossdocking_collector_id: string | null
  source_crossdocking_branch_id: string | null
  destination_crossdocking_branch_id: string | null
  source_collector_id: string | null
  destination_collector_id: string | null
  source_branch_id: string | null
  destination_branch_id: string | null
  provider_id: string | null
  deadline: number

  crossdocking_planned_flight: string | null
  planned_flight: string
  crossdocking_board_date: string | null
  crossdocking_board_hour: string | null
  board_date: string
  board_hour: string

  crossdocking_availability_forecast_day: string | null
  crossdocking_availability_forecast_time: string | null

  has_connection: string | null
  has_connection_crossdocking: string | null
  connection_branch_id: string | null
  crossdocking_connection_branch_id: string | null
  planned_connection_flight: string | null
  crossdocking_planned_connection_flight: string | null

  availability_forecast_day: string
  availability_forecast_time: string
  service_type: string
  franchising: number

  modal: string
  crossdocking_modal: string

  vehicle: string
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  collect_date: string
  collect_hour_start: string
  collect_hour_end: string
  delivery_date: string
  delivery_hour: string
  material_type: string
  observation: string
  requested_collect_addresses_observations: {
    address_id: string
    observation: string
  }[]
}

interface WeekendResponse {
  isDayOfWeek: boolean
  isSaturday: boolean
  isSunday: boolean
}

function isWeekend(date: string | undefined): WeekendResponse {
  if (typeof date === 'string') {
    const [year, month, day] = date.split("-").map(value => Number(value))

    const deliveryDate = set(new Date(), {
      date: day,
      month: month - 1,
      year: year
    })

    const dayOfWeek = deliveryDate.getDay();

    return {
      isDayOfWeek: dayOfWeek === 0 || dayOfWeek === 6,
      isSaturday: dayOfWeek === 6,
      isSunday: dayOfWeek === 0
    }

  }
  return {
    isDayOfWeek: false,
    isSaturday: false,
    isSunday: false
  }

}

interface ServiceFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
  submit: (values: FormInputProps) => Promise<void>
  setHasCancelled?: () => void
  setHasValidate?: () => void
  setHasCurrent?: () => void
}

export function ServiceEditDetailValidateForm({
  slug,
  id,
  isDisabled = false,
  href,
  title,
  action,
  submit,
  setHasCancelled,
  setHasValidate,
  setHasCurrent,
}: ServiceFormProps) {
  const [doesChangeRouteNetwork, setDoesChangeRouteNetwork] = useState(false)
  const [isRouteNetworkFetchEnabled, setIsRouteNetworkFetchEnabled] = useState(false)

  const [providerList, setProviderList] = useState<ProviderProps[]>([])
  const [connectionBranches, setConnectionBranches] = useState<BranchProps[]>(
    [],
  )
  const [crossdockingConnectionBranches, setCrossdockingConnectionBranches] =
    useState<BranchProps[]>([])

  const [defaultSourceAddressChecked, setDefaultSourceAddressChecked] =
    useState<string[]>([])
  const [defaultSourceCollectedAddresses, setDefaultSourceCollectedAddresses] =
    useState<string[]>([])
  const [
    defaultDestinationDeliveryAddresses,
    setDefaultDestinationDeliveryAddresses,
  ] = useState<string[]>([])
  const [
    defaultDestinationAddressChecked,
    setDefaultDestinationAddressChecked,
  ] = useState<string[]>([])

  const { isContentLoading, handleSetIsContentLoadingFalse } =
    useContentLoading()
  const { userLogged } = useAuth()

  const {
    data: collectors, isLoading: isCollectorsLoading,
  } = useCollectors()
  const {
    shipping: { data: shipping, isLoading: isShippingLoading },
  } = useShipping(null, true, false)
  const {
    branches: { data: branches, isLoading: isBranchLoading },
  } = useBranch(null, true, false)
  const { data: providers, isLoading: isProviderLoading } = useProviders()
  const {
    customers: { data: customers, isLoading: isCustomerLoading },
  } = useCustomer(null, true, false)
  const {
    budgets: { data: budgets, isLoading: isBudgetLoading },
  } = useBudget(null, true, false)
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()
  const {
    service: { data: serviceById, isLoading: isServiceLoading },
  } = useService(id || null, false, false)

  const formMethods = useForm<FormInputProps>({
    resolver: yupResolver(serviceEditValidateSchema),
  })

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = formMethods

  const {
    isOpen: isCancelServiceModalOpen,
    onOpen: onOpenCancelServiceModal,
    onClose: onCloseCancelServiceModal,
  } = useDisclosure()

  const { fields } = useFieldArray({
    control,
    name: 'destination_addresses_input',
  })

  const {
    fields: geloSecoAddressesFields,
    append: appendGeloSecoAddressField,
    remove: removeGeloSecoAddressField,
    update: updateGeloSecoAddressesFields
  } = useFieldArray({
    control,
    name: "gelo_seco_addresses"
  })

  function handleUpdateGeloSecoAddressesFields(e: { target: HTMLInputElement }) {
    const isCheckedField = e.target.checked

    if (!isCheckedField) {
      const fieldIndex = geloSecoAddressesFields.findIndex(field => field.address_id === e.target.value)

      removeGeloSecoAddressField(fieldIndex)
    }
  }

  function handleAppendGeloSecoAddress() {
    appendGeloSecoAddressField({ address_id: '' })
  }

  function handleRemoveGeloSecoAddress(index: number) {
    removeGeloSecoAddressField(index)
  }

  useEffect(() => {
    if (serviceById) {
      const geloSecoAddresses: GeloSecoAddressesProps[] =
        serviceById.serviceIDRequested.gelo_seco_addresses
          ? JSON.parse(serviceById.serviceIDRequested.gelo_seco_addresses as string)
          : []

      geloSecoAddresses?.forEach((geloSecoAddress, index) => {
        updateGeloSecoAddressesFields(index, { ...geloSecoAddress })
      })
    }

  }, [serviceById, updateGeloSecoAddressesFields])

  const isCustomerSelected = watch('customer_id')
  const shippingSelected = watch('shipping_id')
  const isBudgetSelected = watch('budget_id')
  const plannedFlight = watch('planned_flight')
  const plannedConnectionFlight = watch('planned_connection_flight')
  const crossdockingPlannedFlight = watch('crossdocking_planned_flight')
  const plannedCrossdockingConnectionFlight = watch('crossdocking_planned_connection_flight')
  const hasCrossdocking = watch('has_crossdocking')

  const collectDate = watch('collect_date')
  const collectHourEnd = watch('collect_hour_end')
  const deliveryDate = watch('delivery_date')
  const deliveryHour = watch('delivery_hour')
  const serviceType = watch('service_type')

  const modalType = watch('modal')
  const crossdockingModalType = watch('crossdocking_modal')
  const selectedSourceBranch = watch('source_branch_id')
  const selectedCrossdockingSourceBranch = watch(
    'source_crossdocking_branch_id',
  )
  const selectedCrossdockingDestinationBranch = watch(
    'destination_crossdocking_branch_id',
  )
  const selectedDestinationBranch = watch('destination_branch_id')
  const sourceCollectorId = watch('source_collector_id')
  const geloSecoQuantity = watch('gelo_seco')
  const materialType = watch('material_type')

  const hasConnection = watch('has_connection')
  const hasConnectionCrossdocking = watch('has_connection_crossdocking')
  const connectionBranch = watch('connection_branch_id')
  const crossdockingConnectionBranch = watch(
    'crossdocking_connection_branch_id',
  )

  useEffect(() => {
    const routeNetworkTimeout = setTimeout(() => {
      setIsRouteNetworkFetchEnabled(true)
      setIsRouteNetworkFetchEnabled(false)
    }, 1500)

    return () => clearTimeout(routeNetworkTimeout)
  }, [
    collectDate,
    collectHourEnd,
    deliveryDate,
    deliveryHour,
    selectedSourceBranch,
    selectedCrossdockingSourceBranch
  ])

  const sourceBranchShippingId = branches?.find(branch => {
    return branch.id === selectedSourceBranch
  })?.shipping_id

  const sourceCrossdockingBranchShippingId = branches?.find(branch => {
    return branch.id === selectedCrossdockingSourceBranch
  })?.shipping_id

  const isFractionedService = serviceById?.serviceIDRequested.service_type === 'FRACIONADO'
  const isCrossdockingService = serviceById?.serviceIDRequested.crossdocking_collector_id !== null
  const isValidCollectDate = new Date(collectDate) instanceof Date && !isNaN(new Date(collectDate).getMilliseconds())
  const isValidDeliveryDate = new Date(deliveryDate) instanceof Date && !isNaN(new Date(deliveryDate).getMilliseconds())

  const deadlineValue = watch('deadline')

  useEffect(() => {
    if (isValidCollectDate && slug === 'editar') {
      const deliveryDateByCollectDate = formatDate.handle(
        add(new Date(collectDate), { days: 1 + deadlineValue }),
        'DateWithoutHourToInput')

      setValue('delivery_date', deliveryDateByCollectDate)
    }
  }, [isValidCollectDate, collectDate, setValue, slug, deadlineValue])

  const {
    data: serviceRouteNetworksData,
    isFetching: isFetchingServiceRouteNetworksData
  } = useServiceRouteNetworks({
    queryOptions: {
      enabled: isFractionedService &&
        isValidCollectDate &&
        isValidDeliveryDate &&
        (isCrossdockingService
          ? (!!sourceBranchShippingId && !!sourceCrossdockingBranchShippingId)
          : !!sourceBranchShippingId) &&
        doesChangeRouteNetwork &&
        isRouteNetworkFetchEnabled
    },
    queryParams: {
      collect_date: collectDate,
      collect_hour_end: collectHourEnd,
      delivery_date: deliveryDate,
      delivery_hour: deliveryHour,
      shipping_id: sourceBranchShippingId,
      crossdocking_shipping_id: sourceCrossdockingBranchShippingId,
      modal: modalType as 'AÉREO' | 'RODOVIÁRIO'
    }
  })

  const {
    plannedRoutesWithoutCrossdockingAndConnection,
    plannedRoutesFromSourceBranchToConnectionCrossdockingBranch,
    plannedRoutesFromSourceBranchToConnectionBranch,
    plannedRoutesFromConnectionBranchToDestinationBranch,
    plannedRoutesFromConnectionCrossdockingBranchToDestinationCrossdockingBranch,
    plannedRoutesFromSourceBranchToDestinationCrossdockingBranch,
    plannedRoutesFromSourceCrossdockingBranchToConnectionBranch,
    plannedRoutesFromSourceCrossdockingBranchToDestinationBranch
  } = usePlannedRoutes({
    destinationBranchSelected: selectedDestinationBranch,
    hasConnection: hasConnection === 'SIM',
    isCrossdockingService,
    isFractionedService,
    routeNetworks: serviceRouteNetworksData?.routeNetworks,
    sourceBranchSelected: selectedSourceBranch,
    connectionBranchSelected: connectionBranch,
    crossdockingConnectionBranchSelected: crossdockingConnectionBranch,
    hasCrossdockingConnection: hasConnectionCrossdocking === 'SIM',
    sourceCrossdockingBranchSelected: selectedCrossdockingSourceBranch,
    destinationCrossdockingBranchSelected: selectedCrossdockingDestinationBranch
  })

  const serviceHasGeloSeco = geloSecoQuantity > 0

  const dateNow = format(new Date(), 'yyyy-MM-dd')

  // BUDGET BY ID
  const budgetSelectedById = useMemo(() => {
    if (budgets) {
      return budgets.filter((budget) => budget.id === isBudgetSelected)
    }
  }, [budgets, isBudgetSelected])

  // BUDGETS BY CUSTOMER
  const budgetsByCustomer = useMemo(() => {
    if (budgets) {
      return budgets.filter(
        (budget) => budget.customer_id === isCustomerSelected,
      )
    }
  }, [budgets, isCustomerSelected])

  // SOURCE ADDRESSES
  const sourceAddressOfBudget = useMemo(() => {
    if (budgetSelectedById && addresses) {
      const sourceCitiesOfBudget = budgetSelectedById.flatMap(
        (budget) => budget.source_cities,
      )
      const sourceAddressesList = addresses
        .filter((address) => address.situation === 'ATIVO')
        .filter((address) => address.customer_id === isCustomerSelected)
        .filter((address, i) =>
          sourceCitiesOfBudget?.includes(address.cityIDAddress.id),
        ).filter((address) => address.deadline >= budgetSelectedById[0].deadline)

      return sourceAddressesList ?? []
    }
  }, [budgetSelectedById, addresses, isCustomerSelected])

  // DESTINATION ADDRESSES
  const destinationAddressOfBudget = useMemo(() => {
    if (budgetSelectedById && addresses) {
      const destinationCitiesOfBudget = budgetSelectedById.flatMap(
        (budget) => budget.destination_cities,
      )
      const destinationAddressesList = addresses
        .filter((address) => address.situation === 'ATIVO')
        .filter((address) => address.customer_id === isCustomerSelected)
        .filter((address, i) =>
          destinationCitiesOfBudget?.includes(address.cityIDAddress.id),
        ).filter((address) => address.deadline >= budgetSelectedById[0].deadline)

      return destinationAddressesList ?? []
    }
  }, [budgetSelectedById, addresses, isCustomerSelected])

  const sourceCollector = useMemo(() => {
    if (budgetSelectedById && collectors) {
      const sourceHubOfBudget = String(
        budgetSelectedById.map((budget) => budget.source_hub_id),
      )
      return collectors.filter((collector) =>
        collector.hub_list.includes(sourceHubOfBudget),
      )
    }
  }, [budgetSelectedById, collectors])

  const crossdockingCollectors = useMemo(() => {
    if (budgetSelectedById && collectors) {
      const crossdockingHubOfBudget = String(
        budgetSelectedById.map((budget) => budget.crossdocking_hub_id),
      )
      return collectors.filter((collector) =>
        collector.hub_list.includes(crossdockingHubOfBudget),
      )
    }
  }, [budgetSelectedById, collectors])

  const destinationCollector = useMemo(() => {
    if (budgetSelectedById && collectors) {
      const destinationHubOfBudget = String(
        budgetSelectedById.map((budget) => budget.destination_hub_id),
      )
      return collectors.filter((collector) =>
        collector.hub_list.includes(destinationHubOfBudget),
      )
    }
  }, [budgetSelectedById, collectors])

  const sourceBranch = useMemo(() => {
    if (budgetSelectedById && branches) {
      const sourceHubOfBudget = String(
        budgetSelectedById.map((budget) => budget.source_hub_id),
      )
      return branches
        .filter((branch) => sourceHubOfBudget === branch.hub_id)
        .filter((branch) =>
          hasCrossdocking === 'SIM'
            ? branch.shippingIDBranch.modal === crossdockingModalType
            : branch.shippingIDBranch.modal === modalType,
        )
    }
  }, [
    budgetSelectedById,
    branches,
    modalType,
    hasCrossdocking,
    crossdockingModalType,
  ])

  const crossdockingSourceBranches = useMemo(() => {
    if (shippingSelected && branches && budgetSelectedById) {
      const crossdockingHubOfBudget = String(
        budgetSelectedById.map((budget) => budget.crossdocking_hub_id),
      )
      return branches
        .filter((branch) => branch.hub_id === crossdockingHubOfBudget)
        .filter((branch) => branch.shippingIDBranch.modal === modalType)
        .filter((branch) => branch.shipping_id === shippingSelected)
    }
  }, [shippingSelected, branches, modalType, budgetSelectedById])

  // DESTINATION BRANCH BY SOURCE BRANCH
  const destinationBranches = useMemo(() => {
    if (budgetSelectedById && branches) {
      const destinationHubOfBudget = String(
        budgetSelectedById.map((budget) => budget.destination_hub_id),
      )
      const sourceShippingIdBySourceBranch = branches
        .filter((branch) => branch.id === selectedSourceBranch)
        .map((branch) => branch.shippingIDBranch.id)

      const destinationBranch = branches
        .filter((branch) => branch.id !== selectedSourceBranch)
        .filter((branch) =>
          sourceShippingIdBySourceBranch?.includes(branch.shipping_id),
        )
        .filter((branch) => branch.shippingIDBranch.modal === modalType)
        .filter((branch) => branch.hub_id === destinationHubOfBudget)

      return destinationBranch ?? []
    }
  }, [selectedSourceBranch, budgetSelectedById, modalType, branches])

  // DESTINATION BRANCH BY CROSSDOCKING SOURCE BRANCH
  const destinationBranchBySourceCrossdockingBranch = useMemo(() => {
    if (budgetSelectedById && branches) {
      const destinationHubOfBudget = String(
        budgetSelectedById.map((budget) => budget.destination_hub_id),
      )
      const sourceShippingIdBySourceBranch = branches
        .filter((branch) => branch.id === selectedCrossdockingSourceBranch)
        .map((branch) => branch.shippingIDBranch.id)

      const destinationBranchBySourceCrossdockingBranch = branches
        .filter((branch) => branch.id !== selectedCrossdockingSourceBranch)
        .filter((branch) =>
          sourceShippingIdBySourceBranch?.includes(branch.shipping_id),
        )
        .filter((branch) => branch.shippingIDBranch.modal === modalType)
        .filter((branch) => branch.hub_id === destinationHubOfBudget)

      return destinationBranchBySourceCrossdockingBranch ?? []
    }
  }, [
    budgetSelectedById,
    branches,
    modalType,
    selectedCrossdockingSourceBranch,
  ])

  const destinationCrossdockingBranchBySourceBranch = useMemo(() => {
    if (budgetSelectedById && branches) {
      const crossdockingHubOfBudget = String(
        budgetSelectedById.map((budget) => budget.crossdocking_hub_id),
      )

      const sourceShippingIdBySourceBranch = branches
        .find(branch => branch.id === selectedSourceBranch)?.shipping_id

      const destinationCrossdockingBranchBySourceBranch = branches
        .filter((branch) => branch.id !== selectedSourceBranch
          && branch.shipping_id === sourceShippingIdBySourceBranch
          && branch.shippingIDBranch.modal === crossdockingModalType
          && branch.hub_id === crossdockingHubOfBudget)

      return destinationCrossdockingBranchBySourceBranch ?? []
    }
  }, [
    selectedSourceBranch,
    budgetSelectedById,
    crossdockingModalType,
    branches,
  ])

  const sourceAddressesSelected = useWatch({
    control,
    name: "source_address_id"
  })
  const destinationAddressesSelected = useWatch({
    control,
    name: "destination_address_id"
  })


  const weekendInfo = isWeekend(deliveryDate);

  const addressesWithoutOpeningHoursSaturdayOrSunday = weekendInfo.isDayOfWeek ? addresses?.reduce((acc: { sunday: Address[], saturday: Address[] }, address) => {


    if (destinationAddressesSelected.includes(address.id)) {

      if (weekendInfo.isSaturday && address.saturday_open === null) {
        acc.saturday.push(address);
      }

      if (weekendInfo.isSunday && address.sunday_open === null) {
        acc.sunday.push(address);
      }
    }
    return acc;
  }, { sunday: [], saturday: [] }) : { sunday: [], saturday: [] };

  const geloSecoAddressesSelected = useWatch({
    control,
    name: "gelo_seco_addresses"
  })

  const requestedCollectAddressesObservationsOptions = sourceAddressOfBudget?.filter((address) => sourceAddressesSelected?.includes(address.id))?.map((address) => {
    return {
      key: address.id,
      value: address.id,
      showOption: `${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} |
      ${address.complement ?? '-'} | ${address.reference_point ?? '-'} ${address.cityIDAddress.name} | ${address.cep}`.toUpperCase()
    }
  })

  const {
    fields: requestCollectAddressObservationFields,
    append: appendRequestCollectAddressObservationField,
    remove: removeRequestCollectAddressObservationField
  } = useFieldArray({
    control,
    name: 'requested_collect_addresses_observations'
  })

  function handleRemoveRequestCollectAddressObservationField(index: number) {
    removeRequestCollectAddressObservationField(index)
  }
  function handleAppendRequestCollectAddressObservationField() {
    appendRequestCollectAddressObservationField({ address_id: '', observation: '' })
  }

  const isAllSourceAddressesAssignedWithGeloSeco =
    sourceAddressesSelected?.length === geloSecoAddressesSelected?.length

  const geloSecoAddressesSelectedAddresses = geloSecoAddressesSelected
    ?.map(sourceAddress => sourceAddress.address_id)

  const geloSecoAddressesOptions = sourceAddressOfBudget
    ?.filter(sourceAddress => sourceAddressesSelected.includes(sourceAddress.id))
    .map(sourceAddress => {
      return {
        key: sourceAddress.id,
        value: sourceAddress.id,
        showOption: `${sourceAddress.street}, ${sourceAddress.number}, ${sourceAddress.neighborhood
          } - ${sourceAddress.cep}`,
        disabled: geloSecoAddressesSelectedAddresses?.includes(sourceAddress.id)
      }
    })

  useEffect(() => {
    const subscribe = watch((data) => {
      if (branches) {
        if (data.has_crossdocking === 'SIM') {
          const crossdockingBranchShipping = branches.find(
            (branch) => branch.id === data.source_crossdocking_branch_id,
          )?.shipping_id
          if (crossdockingBranchShipping) {
            const branchesByCrossdockingBranchShipping = branches.filter(
              (branch) => branch.shipping_id === crossdockingBranchShipping,
            )
            setConnectionBranches(branchesByCrossdockingBranchShipping)
          }
        } else {
          const sourceBranchShipping = branches.find(
            (branch) => branch.id === data.source_branch_id,
          )?.shipping_id
          if (sourceBranchShipping) {
            const branchesBySourceBranchShipping = branches.filter(
              (branch) => branch.shipping_id === sourceBranchShipping,
            )
            setConnectionBranches(branchesBySourceBranchShipping)
          }
        }
      }
      if (branches && data.has_crossdocking === 'SIM') {
        const sourceBranchShipping = branches.find(
          (branch) => branch.id === data.source_branch_id,
        )?.shipping_id
        if (sourceBranchShipping) {
          const crossdockingBranchesBySourceBranchShipping = branches.filter(
            (branch) => branch.shipping_id === sourceBranchShipping,
          )
          setCrossdockingConnectionBranches(
            crossdockingBranchesBySourceBranchShipping,
          )
        }
      }
    })

    return () => {
      subscribe.unsubscribe()
    }
  }, [watch, branches])

  useEffect(() => {
    if (modalType === 'RODOVIÁRIO') {
      setValue('has_connection', 'NÃO')
      setValue('connection_branch_id', null)
    }

    if (crossdockingModalType === 'RODOVIÁRIO') {
      setValue('has_connection_crossdocking', 'NÃO')
      setValue('crossdocking_connection_branch_id', null)
    }
  }, [modalType, crossdockingModalType, setValue])

  useEffect(() => {
    if (
      !isCollectorsLoading &&
      !isBranchLoading &&
      !isProviderLoading &&
      !isCustomerLoading &&
      !isBudgetLoading &&
      !isAddressesLoading &&
      !isServiceLoading &&
      !isFetchingServiceRouteNetworksData &&
      !isShippingLoading
    ) {
      handleSetIsContentLoadingFalse()
    }
  }, [
    isCollectorsLoading,
    isBranchLoading,
    isProviderLoading,
    isCustomerLoading,
    isBudgetLoading,
    isAddressesLoading,
    isServiceLoading,
    isFetchingServiceRouteNetworksData,
    handleSetIsContentLoadingFalse,
    isShippingLoading,
  ])

  useEffect(() => {
    if (slug === 'editar') {
      return setValue('is_edit_page', true)
    }

    setValue('is_edit_page', false)
  }, [slug, setValue])

  useEffect(() => {
    function run() {
      if (
        slug === 'visualizar' ||
        slug === 'editar' ||
        slug === 'validar' ||
        slug === 'andamento'
      ) {
        if (
          serviceById &&
          branches &&
          destinationAddressOfBudget &&
          sourceAddressOfBudget &&
          !isContentLoading
        ) {
          if (slug === 'andamento') {
            const defaultCollectedAddress = serviceById.serviceIDCollect.map(
              (service) => service.address_id,
            )
            const defaultDeliveryAddress = serviceById.serviceIDDelivery.map(
              (service) => service.address_id,
            )
            setDefaultSourceCollectedAddresses(defaultCollectedAddress)
            setDefaultDestinationDeliveryAddresses(defaultDeliveryAddress)
          }

          const collectHourStart = serviceFormatHourToFront(
            serviceById.serviceIDRequested.collect_hour_start,
          )
          const collectHourEnd = serviceFormatHourToFront(
            serviceById.serviceIDRequested.collect_hour_end,
          )
          const deliveryHour = serviceFormatHourToFront(
            serviceById.serviceIDRequested.delivery_hour,
          )

          const crossdockingBoardDate =
            serviceById.serviceIDRequested.crossdocking_board_date !== null
              ? serviceFormatDateToFront(
                serviceById.serviceIDRequested.crossdocking_board_date,
              )
              : null
          const crossdockingBoardHour =
            serviceById.serviceIDRequested.crossdocking_board_hour !== null
              ? serviceFormatHourToFront(
                serviceById.serviceIDRequested.crossdocking_board_hour,
              )
              : null
          const boardDate =
            serviceById.serviceIDRequested.board_date !== null
              ? serviceFormatDateToFront(
                serviceById.serviceIDRequested.board_date,
              )
              : null
          const boardHour =
            serviceById.serviceIDRequested.board_hour !== null
              ? serviceFormatHourToFront(
                serviceById.serviceIDRequested.board_hour,
              )
              : null
          const crossdockingAvailabilityForecastDay =
            serviceById.serviceIDRequested
              .crossdocking_availability_forecast_day !== null
              ? serviceFormatDateToFront(
                serviceById.serviceIDRequested
                  .crossdocking_availability_forecast_day,
              )
              : null
          const crossdockingAvailabilityForecastTime =
            serviceById.serviceIDRequested
              .crossdocking_availability_forecast_time !== null
              ? serviceFormatHourToFront(
                serviceById.serviceIDRequested
                  .crossdocking_availability_forecast_time,
              )
              : null
          const availabilityForecastDay =
            serviceById.serviceIDRequested.availability_forecast_day !== null
              ? serviceFormatDateToFront(
                serviceById.serviceIDRequested.availability_forecast_day,
              )
              : null
          const availabilityForecastTime =
            serviceById.serviceIDRequested.availability_forecast_time !== null
              ? serviceFormatHourToFront(
                serviceById.serviceIDRequested.availability_forecast_time,
              )
              : null
          const destinationAddressesInput:
            | IDestinationAddressesInputProps[]
            | [] = serviceById.serviceIDRequested.destination_addresses_input
              ? serviceById.serviceIDRequested.destination_addresses_input?.map(
                (address) => JSON.parse(address as string),
              )
              : []

          const collectDate = serviceFormatDateToFront(
            serviceById.serviceIDRequested.collect_date,
          )
          const deliveryDate = serviceFormatDateToFront(
            serviceById.serviceIDRequested.delivery_date,
          )

          setDefaultSourceAddressChecked(
            serviceById.serviceIDRequested.source_address_id,
          )
          setDefaultDestinationAddressChecked(
            serviceById.serviceIDRequested.destination_address_id,
          )

          setValue('customer_id', serviceById.customer_id)
          setValue(
            'shipping_id',
            serviceById.serviceIDRequested.source_crossdocking_branch_id ===
              null
              ? ''
              : String(
                branches
                  .filter(
                    (branch) =>
                      branch.id ===
                      serviceById.serviceIDRequested
                        .source_crossdocking_branch_id,
                  )
                  .map((branch) => branch.shippingIDBranch.id),
              ),
          )
          setValue(
            'has_crossdocking',
            slug === 'validar' || slug === 'andamento'
              ? budgetSelectedById?.map(
                (budget) => budget.crossdocking_hub_id,
              )[0]
                ? 'SIM'
                : 'NÃO'
              : 'NÃO',
          )
          setValue('budget_id', serviceById.serviceIDRequested.budget_id)
          setValue(
            'source_address_id',
            serviceById.serviceIDRequested.source_address_id,
          )
          setValue(
            'destination_address_id',
            serviceById.serviceIDRequested.destination_address_id,
          )
          setValue(
            'source_collector_id',
            serviceById.serviceIDRequested.source_collector_id === null
              ? ''
              : serviceById.serviceIDRequested.source_collector_id,
          )
          setValue(
            'crossdocking_collector_id',
            serviceById.serviceIDRequested.crossdocking_collector_id === null
              ? ''
              : serviceById.serviceIDRequested.crossdocking_collector_id,
          )
          setValue(
            'destination_collector_id',
            serviceById.serviceIDRequested.destination_collector_id === null
              ? ''
              : serviceById.serviceIDRequested.destination_collector_id,
          )
          setValue(
            'source_branch_id',
            serviceById.serviceIDRequested.source_branch_id === null
              ? ''
              : serviceById.serviceIDRequested.source_branch_id,
          )
          setValue(
            'source_crossdocking_branch_id',
            serviceById.serviceIDRequested.source_crossdocking_branch_id ===
              null
              ? ''
              : serviceById.serviceIDRequested.source_crossdocking_branch_id,
          )
          setValue(
            'destination_crossdocking_branch_id',
            serviceById.serviceIDRequested
              .destination_crossdocking_branch_id === null
              ? ''
              : serviceById.serviceIDRequested
                .destination_crossdocking_branch_id,
          )
          setValue(
            'destination_branch_id',
            serviceById.serviceIDRequested.destination_branch_id === null
              ? ''
              : serviceById.serviceIDRequested.destination_branch_id,
          )
          setValue(
            'provider_id',
            serviceById.serviceIDRequested.provider_id === ' ' ||
              serviceById.serviceIDRequested.provider_id === null
              ? ''
              : serviceById.serviceIDRequested.provider_id,
          )
          setValue('owner', serviceById.serviceIDRequested.owner)
          setValue('deadline', serviceById.serviceIDRequested.deadline)
          setValue('service_type', serviceById.serviceIDRequested.service_type)
          setValue('franchising', serviceById.serviceIDRequested.franchising)
          setValue(
            'crossdocking_modal',
            serviceById.serviceIDRequested.crossdocking_modal !== null
              ? serviceById.serviceIDRequested.crossdocking_modal
              : '',
          )
          setValue('modal', serviceById.serviceIDRequested.modal)
          setValue(
            'crossdocking_planned_flight',
            serviceById.serviceIDRequested.crossdocking_planned_flight === null
              ? ''
              : serviceById.serviceIDRequested.crossdocking_planned_flight,
          )
          setValue(
            'planned_flight',
            serviceById.serviceIDRequested.planned_flight === null
              ? ''
              : serviceById.serviceIDRequested.planned_flight,
          )
          setValue('vehicle', serviceById.serviceIDRequested.vehicle)
          setValue(
            'caixa_termica',
            serviceById.serviceIDRequested.caixa_termica,
          )
          setValue(
            'embalagem_secundaria',
            serviceById.serviceIDRequested.embalagem_secundaria,
          )
          setValue('gelo_seco', serviceById.serviceIDRequested.gelo_seco)
          setValue('gelox', serviceById.serviceIDRequested.gelox)
          setValue('isopor3l', serviceById.serviceIDRequested.isopor3l)
          setValue('isopor7l', serviceById.serviceIDRequested.isopor7l)
          setValue('terciaria3l', serviceById.serviceIDRequested.terciaria3l)
          setValue('terciaria8l', serviceById.serviceIDRequested.terciaria8l)
          setValue('collect_date', collectDate)
          setValue('collect_hour_start', collectHourStart)
          setValue('collect_hour_end', collectHourEnd)
          setValue('delivery_date', deliveryDate)
          setValue('delivery_hour', deliveryHour)
          setValue('destination_addresses_input', destinationAddressesInput)
          if (crossdockingBoardDate) {
            setValue('crossdocking_board_date', crossdockingBoardDate)
          }
          setValue(
            'crossdocking_board_hour',
            crossdockingBoardHour !== null ? crossdockingBoardHour : '',
          )
          if (boardDate) {
            setValue('board_date', boardDate)
          }
          setValue('board_hour', boardHour !== null ? boardHour : '')
          setValue(
            'crossdocking_availability_forecast_day',
            crossdockingAvailabilityForecastDay !== null
              ? crossdockingAvailabilityForecastDay
              : '',
          )
          setValue(
            'crossdocking_availability_forecast_time',
            crossdockingAvailabilityForecastTime !== null
              ? crossdockingAvailabilityForecastTime
              : '',
          )
          setValue(
            'availability_forecast_day',
            availabilityForecastDay !== null ? availabilityForecastDay : '',
          )
          setValue(
            'availability_forecast_time',
            availabilityForecastTime !== null ? availabilityForecastTime : '',
          )
          setValue(
            'material_type',
            serviceById.serviceIDRequested.material_type !== null
              ? serviceById.serviceIDRequested.material_type
              : '',
          )
          if (serviceById?.serviceIDRequested.requested_collect_addresses_observations !== null && serviceById?.serviceIDRequested.requested_collect_addresses_observations.length) {
            const requestedCollectAddressObservationsData: { address_id: string, observation: string }[] = JSON.parse(serviceById?.serviceIDRequested.requested_collect_addresses_observations as string)

            setValue('requested_collect_addresses_observations', requestedCollectAddressObservationsData)
          }
          setValue('observation', serviceById.serviceIDRequested.observation)

          if (destinationAddressesInput.length > 0) {
            destinationAddressesInput.forEach((address, index) => {
              setValue(
                `destination_addresses_input.${index}.address_id`,
                address.address_id,
              )
              setValue(
                `destination_addresses_input.${index}.quantity`,
                address.quantity,
              )
            })
          }
        }
      }
    }

    run()
  }, [
    setValue,
    slug,
    serviceById,
    destinationAddressOfBudget,
    budgetSelectedById,
    sourceAddressOfBudget,
    branches,
    isContentLoading,
  ])

  useEffect(() => {
    if (budgetSelectedById && sourceAddressesSelected) {
      const priceAddCollect = budgetSelectedById.map(budget => budget.price_add_collect)[0]

      if (sourceAddressesSelected?.length > budgetSelectedById[0].source_address_qty && priceAddCollect === 0) {
        setValue("is_unique_collect_address_service", true)
      } else {
        setValue("is_unique_collect_address_service", false)
      }
    }
  }, [budgetSelectedById, setValue, sourceAddressesSelected])

  useEffect(() => {
    const filterProviders = () => {
      if (Number(geloSecoQuantity) > 0 && !!providers && !!budgetSelectedById) {
        const sourceHubIdBudget = String(
          budgetSelectedById.map((budget) => budget.source_hub_id),
        )
        const providerHasGeloSeco = providers
          .filter((provider) => provider.material === 'GELO SECO')
          .filter((provider) => provider.hub_id === sourceHubIdBudget)
        setProviderList(providerHasGeloSeco)
      }
    }
    filterProviders()
  }, [geloSecoQuantity, budgetSelectedById, providers])

  useEffect(() => {
    if (serviceType === 'DEDICADO') {
      setValue('destination_collector_id', sourceCollectorId)
    }
  }, [serviceType, sourceCollectorId, setValue])

  const dayOfWeekFromCollectDate = getDay(add(new Date(collectDate), { days: 1 }))

  useEffect(() => {
    if (isCrossdockingService && doesChangeRouteNetwork) {
      if (hasConnectionCrossdocking === 'SIM') {
        const debounceCrossdockingWithCrossdockingConnectionDelayTimeout = setTimeout(() => {
          const existsSourceBranchToCrossdockingConnectionBranchPlannedFlight =
            findPlannedRouteInfoAndFormatAccordinglyWithModal(
              plannedRoutesFromSourceBranchToConnectionCrossdockingBranch,
              plannedCrossdockingConnectionFlight,
              crossdockingModalType as 'AÉREO' | 'RODOVIÁRIO',
            )
          const existsCrossdockingConnectionBranchToDestinationCrossdockingBranchPlannedFlight =
            findPlannedRouteInfoAndFormatAccordinglyWithModal(
              plannedRoutesFromConnectionCrossdockingBranchToDestinationCrossdockingBranch,
              crossdockingPlannedFlight,
              crossdockingModalType as 'AÉREO' | 'RODOVIÁRIO',
            )

          const existsSourceBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
            dayOfWeekFromCollectDate,
            branches,
            selectedSourceBranch
          )

          const existsDestinationCrossdockingBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
            dayOfWeekFromCollectDate,
            branches,
            selectedCrossdockingDestinationBranch
          )

          if (
            !existsSourceBranchToCrossdockingConnectionBranchPlannedFlight ||
            !existsCrossdockingConnectionBranchToDestinationCrossdockingBranchPlannedFlight
          ) {
            if (existsSourceBranchAttendanceHourOnCollectDate) {
              const {
                branchCloseHour: sourceBranchCloseHour
              } = existsSourceBranchAttendanceHourOnCollectDate

              setValue('crossdocking_board_hour', sourceBranchCloseHour)
            }

            if (existsDestinationCrossdockingBranchAttendanceHourOnCollectDate) {
              const {
                branchOpenHour: destinationCrossdockingBranchOpenHour
              } = existsDestinationCrossdockingBranchAttendanceHourOnCollectDate

              setValue(
                "crossdocking_availability_forecast_time",
                destinationCrossdockingBranchOpenHour
              )
            }
          } else {
            const {
              departureDate,
              departureHour
            } = existsSourceBranchToCrossdockingConnectionBranchPlannedFlight

            const {
              arrivalHour,
              arrivalDate
            } = existsCrossdockingConnectionBranchToDestinationCrossdockingBranchPlannedFlight

            setValue('crossdocking_board_date', departureDate)
            setValue('crossdocking_availability_forecast_day', arrivalDate)

            if (existsSourceBranchAttendanceHourOnCollectDate) {
              const {
                branchOpenHour: sourceBranchOpenHour,
                branchCloseHour: sourceBranchCloseHour
              } = existsSourceBranchAttendanceHourOnCollectDate


              const isPlannedRouteDepartureHourLessThanSourceBranchCloseHour =
                comparePlannedRouteDepartureHourIsLessThanSourceBranchCloseHour(
                  departureHour,
                  sourceBranchCloseHour,
                )

              const isPlannedRouteDepartureHourLessThanSourceBranchOpenHour =
                comparePlannedRouteDepartureHourIsLessThanSourceBranchOpenHour(
                  departureHour,
                  sourceBranchOpenHour
                )

              if (isPlannedRouteDepartureHourLessThanSourceBranchOpenHour) {
                setValue(
                  'crossdocking_board_date',
                  formatDate.handle(departureDate, 'DateWithoutHourToInput')
                )
                setValue('crossdocking_board_hour', sourceBranchCloseHour)
              } else if (isPlannedRouteDepartureHourLessThanSourceBranchCloseHour) {
                setValue('crossdocking_board_hour', departureHour)
              } else {
                setValue('crossdocking_board_hour', sourceBranchCloseHour)
              }
            } else {
              setValue('crossdocking_board_hour', departureHour)
            }

            if (existsDestinationCrossdockingBranchAttendanceHourOnCollectDate) {

              const {
                branchOpenHour: destinationCrossdockingBranchCloseHour
              } = existsDestinationCrossdockingBranchAttendanceHourOnCollectDate

              const isPlannedRouteArrivalHourBiggerThanDestinationCrossdockingBranchOpenHour =
                comparePlannedRouteArrivalHourIsBiggerThanDestinationBranchOpenHour(
                  arrivalHour,
                  destinationCrossdockingBranchCloseHour,
                )

              if (isPlannedRouteArrivalHourBiggerThanDestinationCrossdockingBranchOpenHour) {
                setValue('crossdocking_availability_forecast_time', arrivalHour)
              } else {
                setValue('crossdocking_availability_forecast_time', destinationCrossdockingBranchCloseHour)
              }
            } else {
              setValue('crossdocking_availability_forecast_time', arrivalHour)
            }
          }
        }, 1500)

        return () => clearTimeout(debounceCrossdockingWithCrossdockingConnectionDelayTimeout)
      }

      const debounceCrossdockingWithoutCrossdockingConnectionDelayTimeout = setTimeout(() => {
        setValue('crossdocking_planned_connection_flight', undefined)

        const existsSourceBranchToDestinationCrossdockingBranchPlannedFlight =
          findPlannedRouteInfoAndFormatAccordinglyWithModal(
            plannedRoutesFromSourceBranchToDestinationCrossdockingBranch,
            crossdockingPlannedFlight,
            crossdockingModalType as 'AÉREO' | 'RODOVIÁRIO',
          )

        const existsSourceBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
          dayOfWeekFromCollectDate,
          branches,
          selectedSourceBranch
        )
        const existsDestinationCrossdockingBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
          dayOfWeekFromCollectDate,
          branches,
          selectedCrossdockingDestinationBranch
        )

        if (!existsSourceBranchToDestinationCrossdockingBranchPlannedFlight) {
          if (existsSourceBranchAttendanceHourOnCollectDate) {
            const {
              branchCloseHour: sourceBranchCloseHour
            } = existsSourceBranchAttendanceHourOnCollectDate

            setValue('crossdocking_board_hour', sourceBranchCloseHour)
          }

          if (existsDestinationCrossdockingBranchAttendanceHourOnCollectDate) {
            const {
              branchOpenHour: destinationCrossdockingBranchOpenHour
            } = existsDestinationCrossdockingBranchAttendanceHourOnCollectDate

            setValue('availability_forecast_time', destinationCrossdockingBranchOpenHour)
          }
        } else {

          const {
            arrivalDate,
            arrivalHour,
            departureDate,
            departureHour
          } = existsSourceBranchToDestinationCrossdockingBranchPlannedFlight

          setValue('crossdocking_board_date', departureDate)
          setValue('crossdocking_availability_forecast_day', arrivalDate)

          if (existsSourceBranchAttendanceHourOnCollectDate) {
            const {
              branchOpenHour: sourceBranchOpenHour,
              branchCloseHour: sourceBranchCloseHour
            } = existsSourceBranchAttendanceHourOnCollectDate

            const isPlannedRouteDepartureHourLessThanSourceBranchCloseHour =
              comparePlannedRouteDepartureHourIsLessThanSourceBranchCloseHour(
                departureHour,
                sourceBranchCloseHour,
              )

            const isPlannedRouteDepartureHourLessThanSourceBranchOpenHour =
              comparePlannedRouteDepartureHourIsLessThanSourceBranchOpenHour(
                departureHour,
                sourceBranchOpenHour
              )

            if (isPlannedRouteDepartureHourLessThanSourceBranchOpenHour) {
              setValue(
                'crossdocking_board_date',
                formatDate.handle(departureDate, 'DateWithoutHourToInput')
              )
              setValue('crossdocking_board_hour', sourceBranchCloseHour)
            } else if (isPlannedRouteDepartureHourLessThanSourceBranchCloseHour) {
              setValue('crossdocking_board_hour', departureHour)
            } else {
              setValue('crossdocking_board_hour', sourceBranchCloseHour)
            }
          } else {
            setValue('crossdocking_board_hour', departureHour)
          }


          if (existsDestinationCrossdockingBranchAttendanceHourOnCollectDate) {
            const {
              branchOpenHour: destinationCrossdockingBranchOpenHour
            } = existsDestinationCrossdockingBranchAttendanceHourOnCollectDate

            const isPlannedRouteArrivalHourBiggerThanDestinationCrossdockingBranchOpenHour =
              comparePlannedRouteArrivalHourIsBiggerThanDestinationBranchOpenHour(
                arrivalHour,
                destinationCrossdockingBranchOpenHour,
              )

            if (isPlannedRouteArrivalHourBiggerThanDestinationCrossdockingBranchOpenHour) {
              setValue('crossdocking_availability_forecast_time', arrivalHour)
            } else {
              setValue('crossdocking_availability_forecast_time', destinationCrossdockingBranchOpenHour)
            }
          } else {
            setValue('crossdocking_availability_forecast_time', arrivalHour)
          }
        }
      }, 1500)

      return () => clearTimeout(debounceCrossdockingWithoutCrossdockingConnectionDelayTimeout)
    }
  }, [
    plannedRoutesFromSourceBranchToDestinationCrossdockingBranch,
    plannedRoutesFromSourceBranchToConnectionCrossdockingBranch,
    plannedRoutesFromConnectionCrossdockingBranchToDestinationCrossdockingBranch,
    selectedSourceBranch,
    isCrossdockingService,
    hasConnectionCrossdocking,
    branches,
    crossdockingModalType,
    crossdockingPlannedFlight,
    dayOfWeekFromCollectDate,
    selectedCrossdockingDestinationBranch,
    plannedCrossdockingConnectionFlight,
    setValue,
    doesChangeRouteNetwork
  ])

  useEffect(() => {
    if (isCrossdockingService && doesChangeRouteNetwork) {
      if (hasConnection) {
        const debounceCrossdockingWithConnectionDelayTimeout = setTimeout(() => {
          const existsSourceCrossdockingBranchToConnectionBranchPlannedFlight =
            findPlannedRouteInfoAndFormatAccordinglyWithModal(
              plannedRoutesFromSourceCrossdockingBranchToConnectionBranch,
              plannedConnectionFlight,
              modalType as 'AÉREO' | 'RODOVIÁRIO',
            )

          const existsConnectionBranchToDestinationBranchPlannedFlight =
            findPlannedRouteInfoAndFormatAccordinglyWithModal(
              plannedRoutesFromConnectionBranchToDestinationBranch,
              plannedFlight,
              modalType as 'AÉREO' | 'RODOVIÁRIO',
            )

          const existsSourceCrossdockingBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
            dayOfWeekFromCollectDate,
            branches,
            selectedCrossdockingSourceBranch
          )

          const existsDestinationBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
            dayOfWeekFromCollectDate,
            branches,
            selectedDestinationBranch
          )

          if (
            !existsSourceCrossdockingBranchToConnectionBranchPlannedFlight ||
            !existsConnectionBranchToDestinationBranchPlannedFlight
          ) {

            if (existsSourceCrossdockingBranchAttendanceHourOnCollectDate) {
              const {
                branchCloseHour: sourceCrossdockingBranchCloseHour
              } = existsSourceCrossdockingBranchAttendanceHourOnCollectDate

              setValue('board_hour', sourceCrossdockingBranchCloseHour)
            }

            if (existsDestinationBranchAttendanceHourOnCollectDate) {
              const {
                branchOpenHour: destinationBranchCloseHour
              } = existsDestinationBranchAttendanceHourOnCollectDate

              setValue('availability_forecast_time', destinationBranchCloseHour)
            }
          } else {
            const {
              departureDate,
              departureHour
            } = existsConnectionBranchToDestinationBranchPlannedFlight

            const {
              arrivalDate,
              arrivalHour
            } = existsSourceCrossdockingBranchToConnectionBranchPlannedFlight


            setValue('board_date', departureDate)
            setValue('availability_forecast_day', arrivalDate)

            if (existsSourceCrossdockingBranchAttendanceHourOnCollectDate) {
              const {
                branchOpenHour: sourceCrossdockingBranchOpenHour,
                branchCloseHour: sourceCrossdockingBranchCloseHour
              } = existsSourceCrossdockingBranchAttendanceHourOnCollectDate

              const isPlannedConnectionRouteDepartureHourLessThanSourceCrossdockingBranchCloseHour =
                comparePlannedRouteDepartureHourIsLessThanSourceBranchCloseHour(
                  departureHour,
                  sourceCrossdockingBranchCloseHour,
                )

              const isPlannedConnectionRouteDepartureHourLessThanSourceCrossdockingBranchOpenHour =
                comparePlannedRouteDepartureHourIsLessThanSourceBranchOpenHour(
                  departureHour,
                  sourceCrossdockingBranchOpenHour
                )

              if (isPlannedConnectionRouteDepartureHourLessThanSourceCrossdockingBranchOpenHour) {
                setValue(
                  'board_date',
                  formatDate.handle(departureDate, 'DateWithoutHourToInput')
                )
                setValue('board_hour', sourceCrossdockingBranchCloseHour)
              } else if (isPlannedConnectionRouteDepartureHourLessThanSourceCrossdockingBranchCloseHour) {
                setValue('board_hour', departureHour)
              } else {
                setValue('board_hour', sourceCrossdockingBranchOpenHour)
              }
            } else {
              setValue('board_hour', departureHour)
            }

            if (existsDestinationBranchAttendanceHourOnCollectDate) {

              const {
                branchOpenHour: destinationBranchOpenHour
              } = existsDestinationBranchAttendanceHourOnCollectDate


              const isPlannedConnectionRouteArrivalHourBiggerThanDestinationBranchCloseHour =
                comparePlannedRouteArrivalHourIsBiggerThanDestinationBranchOpenHour(
                  arrivalHour,
                  destinationBranchOpenHour,
                )

              if (isPlannedConnectionRouteArrivalHourBiggerThanDestinationBranchCloseHour) {
                setValue('availability_forecast_time', arrivalHour)
              } else {
                setValue('availability_forecast_time', destinationBranchOpenHour)
              }

            } else {
              setValue('availability_forecast_time', arrivalHour)
            }
          }
        }, 1500)

        return () => clearTimeout(debounceCrossdockingWithConnectionDelayTimeout)
      }

      const debounceCrossdockingWithoutConnectionDelayTimeout = setTimeout(() => {
        setValue('planned_connection_flight', undefined)
        const existsSourceCrossdockingBranchToDestinationBranchPlannedFlight =
          findPlannedRouteInfoAndFormatAccordinglyWithModal(
            plannedRoutesFromSourceCrossdockingBranchToDestinationBranch,
            plannedFlight,
            modalType as 'AÉREO' | 'RODOVIÁRIO',
          )

        const existsSourceCrossdockingBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
          dayOfWeekFromCollectDate,
          branches,
          selectedCrossdockingSourceBranch
        )

        const existsDestinationBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
          dayOfWeekFromCollectDate,
          branches,
          selectedDestinationBranch
        )

        if (!existsSourceCrossdockingBranchToDestinationBranchPlannedFlight) {
          if (existsSourceCrossdockingBranchAttendanceHourOnCollectDate) {
            const {
              branchCloseHour: sourceCrossdockingBranchCloseHour
            } = existsSourceCrossdockingBranchAttendanceHourOnCollectDate

            setValue('board_hour', sourceCrossdockingBranchCloseHour)
          }

          if (existsDestinationBranchAttendanceHourOnCollectDate) {
            const {
              branchOpenHour: destinationBranchOpenHour
            } = existsDestinationBranchAttendanceHourOnCollectDate

            setValue('availability_forecast_time', destinationBranchOpenHour)
          }
        } else {
          const {
            arrivalDate,
            arrivalHour,
            departureDate,
            departureHour
          } = existsSourceCrossdockingBranchToDestinationBranchPlannedFlight

          setValue('board_date', departureDate)
          setValue('availability_forecast_day', arrivalDate)

          if (existsSourceCrossdockingBranchAttendanceHourOnCollectDate) {
            const {
              branchOpenHour: sourceCrossdockingBranchOpenHour,
              branchCloseHour: sourceCrossdockingBranchCloseHour
            } = existsSourceCrossdockingBranchAttendanceHourOnCollectDate

            const isPlannedRouteDepartureHourLessThanSourceCrossdockingBranchCloseHour =
              comparePlannedRouteDepartureHourIsLessThanSourceBranchCloseHour(
                departureHour,
                sourceCrossdockingBranchCloseHour,
              )

            const isPlannedRouteDepartureHourLessThanSourceCrossdockingBranchOpenHour =
              comparePlannedRouteDepartureHourIsLessThanSourceBranchOpenHour(
                departureHour,
                sourceCrossdockingBranchOpenHour
              )

            if (isPlannedRouteDepartureHourLessThanSourceCrossdockingBranchOpenHour) {
              setValue(
                'board_date',
                formatDate.handle(departureDate, 'DateWithoutHourToInput')
              )
              setValue('board_hour', sourceCrossdockingBranchCloseHour)
            } else if (isPlannedRouteDepartureHourLessThanSourceCrossdockingBranchCloseHour) {
              setValue('board_hour', departureHour)
            } else {
              setValue('board_hour', sourceCrossdockingBranchCloseHour)
            }
          } else {
            setValue('board_hour', departureHour)
          }
          if (existsDestinationBranchAttendanceHourOnCollectDate) {

            const {
              branchOpenHour: destinationBranchOpenHour
            } = existsDestinationBranchAttendanceHourOnCollectDate


            const isPlannedConnectionRouteArrivalHourBiggerThanDestinationBranchCloseHour =
              comparePlannedRouteArrivalHourIsBiggerThanDestinationBranchOpenHour(
                arrivalHour,
                destinationBranchOpenHour,
              )

            if (isPlannedConnectionRouteArrivalHourBiggerThanDestinationBranchCloseHour) {
              setValue('availability_forecast_time', arrivalHour)
            } else {
              setValue('availability_forecast_time', destinationBranchOpenHour)
            }

          } else {
            setValue('availability_forecast_time', arrivalHour)
          }
        }
      }, 1500)

      return () => clearTimeout(debounceCrossdockingWithoutConnectionDelayTimeout)
    }
  }, [
    plannedRoutesFromSourceCrossdockingBranchToDestinationBranch,
    plannedRoutesFromConnectionBranchToDestinationBranch,
    plannedRoutesFromSourceCrossdockingBranchToConnectionBranch,
    branches,
    selectedCrossdockingSourceBranch,
    dayOfWeekFromCollectDate,
    selectedDestinationBranch,
    isCrossdockingService,
    hasConnection,
    modalType,
    plannedFlight,
    plannedConnectionFlight,
    setValue,
    doesChangeRouteNetwork
  ])

  useEffect(() => {
    if (!isCrossdockingService && doesChangeRouteNetwork) {
      setValue('source_crossdocking_branch_id', undefined)
      setValue('destination_crossdocking_branch_id', undefined)
      setValue('crossdocking_connection_branch_id', undefined)
      setValue('crossdocking_collector_id', undefined)
      setValue('crossdocking_board_date', undefined)
      setValue('crossdocking_board_hour', undefined)
      setValue('crossdocking_availability_forecast_day', undefined)
      setValue('crossdocking_availability_forecast_time', undefined)

      if (hasConnection === 'SIM') {
        const debounceWithConnectionDelayTimeout = setTimeout(() => {
          const existsSourceBranchToConnectionBranchPlannedFlight =
            findPlannedRouteInfoAndFormatAccordinglyWithModal(
              plannedRoutesFromSourceBranchToConnectionBranch,
              plannedConnectionFlight,
              modalType as 'AÉREO' | 'RODOVIÁRIO',
            )

          const existsConnectionBranchToDestinationBranchPlannedFlight =
            findPlannedRouteInfoAndFormatAccordinglyWithModal(
              plannedRoutesFromConnectionBranchToDestinationBranch,
              plannedFlight,
              modalType as 'AÉREO' | 'RODOVIÁRIO',
            )

          const existsSourceBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
            dayOfWeekFromCollectDate,
            branches,
            selectedSourceBranch
          )

          const existsDestinationBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
            dayOfWeekFromCollectDate,
            branches,
            selectedDestinationBranch
          )

          if (
            !existsSourceBranchToConnectionBranchPlannedFlight ||
            !existsConnectionBranchToDestinationBranchPlannedFlight
          ) {
            if (existsSourceBranchAttendanceHourOnCollectDate) {
              const {
                branchCloseHour: sourceBranchCloseHour,
              } = existsSourceBranchAttendanceHourOnCollectDate

              setValue('board_hour', sourceBranchCloseHour)
            }

            if (existsDestinationBranchAttendanceHourOnCollectDate) {
              const {
                branchOpenHour: destinationBranchOpenHour
              } = existsDestinationBranchAttendanceHourOnCollectDate

              setValue('availability_forecast_time', destinationBranchOpenHour)
            }
          } else {

            const {
              departureDate,
              departureHour
            } = existsSourceBranchToConnectionBranchPlannedFlight

            const {
              arrivalDate,
              arrivalHour
            } = existsConnectionBranchToDestinationBranchPlannedFlight

            setValue('board_date', departureDate)
            setValue('availability_forecast_day', arrivalDate)

            if (existsSourceBranchAttendanceHourOnCollectDate) {
              const {
                branchOpenHour: sourceBranchOpenHour,
                branchCloseHour: sourceBranchCloseHour
              } = existsSourceBranchAttendanceHourOnCollectDate

              const isPlannedConnectionRouteDepartureHourLessThanSourceBranchCloseHour =
                comparePlannedRouteDepartureHourIsLessThanSourceBranchCloseHour(
                  departureHour,
                  sourceBranchCloseHour,
                )

              const isPlannedConnectionRouteDepartureHourLessThanSourceBranchOpenHour =
                comparePlannedRouteDepartureHourIsLessThanSourceBranchOpenHour(
                  departureHour,
                  sourceBranchOpenHour
                )

              if (isPlannedConnectionRouteDepartureHourLessThanSourceBranchOpenHour) {
                setValue(
                  'board_date',
                  formatDate.handle(departureDate, 'DateWithoutHourToInput')
                )
                setValue('board_hour', sourceBranchCloseHour)
              } else if (isPlannedConnectionRouteDepartureHourLessThanSourceBranchCloseHour) {
                setValue('board_hour', departureHour)
              } else {
                setValue('board_hour', sourceBranchCloseHour)
              }
            } else {
              setValue('board_hour', departureHour)
            }

            if (existsDestinationBranchAttendanceHourOnCollectDate) {
              const {
                branchOpenHour: destinationBranchOpenHour
              } = existsDestinationBranchAttendanceHourOnCollectDate


              const isPlannedConnectionRouteArrivalHourBiggerThanDestinationBranchOpenHour =
                comparePlannedRouteArrivalHourIsBiggerThanDestinationBranchOpenHour(
                  arrivalHour,
                  destinationBranchOpenHour,
                )

              if (isPlannedConnectionRouteArrivalHourBiggerThanDestinationBranchOpenHour) {
                setValue('availability_forecast_time', arrivalHour)
              } else {
                setValue('availability_forecast_time', destinationBranchOpenHour)
              }

            } else {
              setValue('availability_forecast_time', arrivalHour)
            }

          }
        }, 1500)
        return () => clearTimeout(debounceWithConnectionDelayTimeout)
      }

      const debounceWithoutConnectionDelayTimeout = setTimeout(() => {
        setValue('planned_connection_flight', undefined)

        const existsSourceBranchToDestinationBranchPlannedFlight =
          findPlannedRouteInfoAndFormatAccordinglyWithModal(
            plannedRoutesWithoutCrossdockingAndConnection,
            plannedFlight,
            modalType as 'AÉREO' | 'RODOVIÁRIO',
          )

        const existsSourceBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
          dayOfWeekFromCollectDate,
          branches,
          selectedSourceBranch
        )

        const existsDestinationBranchAttendanceHourOnCollectDate = findBranchOpenAndCloseHoursBasedOnDayOfWeek(
          dayOfWeekFromCollectDate,
          branches,
          selectedDestinationBranch
        )

        if (!existsSourceBranchToDestinationBranchPlannedFlight) {
          if (existsSourceBranchAttendanceHourOnCollectDate) {
            const {
              branchCloseHour: sourceBranchCloseHour
            } = existsSourceBranchAttendanceHourOnCollectDate

            setValue('board_hour', sourceBranchCloseHour)
          }

          if (existsDestinationBranchAttendanceHourOnCollectDate) {
            const {
              branchOpenHour: destinationBranchOpenHour,
            } = existsDestinationBranchAttendanceHourOnCollectDate

            setValue('availability_forecast_time', destinationBranchOpenHour)
          }
        } else {

          const {
            arrivalDate,
            arrivalHour,
            departureDate,
            departureHour
          } = existsSourceBranchToDestinationBranchPlannedFlight

          setValue('board_date', departureDate)
          setValue('availability_forecast_day', arrivalDate)

          if (existsSourceBranchAttendanceHourOnCollectDate) {
            const {
              branchOpenHour: sourceBranchOpenHour,
              branchCloseHour: sourceBranchCloseHour
            } = existsSourceBranchAttendanceHourOnCollectDate

            const isPlannedRouteDepartureHourLessThanSourceBranchCloseHour =
              comparePlannedRouteDepartureHourIsLessThanSourceBranchCloseHour(
                departureHour,
                sourceBranchCloseHour,
              )

            const isPlannedRouteDepartureHourLessThanSourceBranchOpenHour =
              comparePlannedRouteDepartureHourIsLessThanSourceBranchOpenHour(
                departureHour,
                sourceBranchOpenHour
              )

            if (isPlannedRouteDepartureHourLessThanSourceBranchOpenHour) {
              setValue(
                'board_date',
                formatDate.handle(departureDate, 'DateWithoutHourToInput')
              )
              setValue('board_hour', sourceBranchCloseHour)
            } else if (isPlannedRouteDepartureHourLessThanSourceBranchCloseHour) {
              setValue('board_hour', departureHour)
            } else {
              setValue('board_hour', sourceBranchCloseHour)
            }
          } else {
            setValue('board_hour', departureHour)
          }

          if (existsDestinationBranchAttendanceHourOnCollectDate) {
            const {
              branchOpenHour: destinationBranchOpenHour
            } = existsDestinationBranchAttendanceHourOnCollectDate

            const isPlannedRouteArrivalHourBiggerThanDestinationBranchOpenHour =
              comparePlannedRouteArrivalHourIsBiggerThanDestinationBranchOpenHour(
                arrivalHour,
                destinationBranchOpenHour,
              )

            if (isPlannedRouteArrivalHourBiggerThanDestinationBranchOpenHour) {
              setValue('availability_forecast_time', arrivalHour)
            } else {
              setValue('availability_forecast_time', destinationBranchOpenHour)
            }
          } else {
            setValue('availability_forecast_time', arrivalHour)
          }
        }
      }, 1500)

      return () => clearTimeout(debounceWithoutConnectionDelayTimeout)
    }
  }, [
    plannedRoutesWithoutCrossdockingAndConnection,
    plannedRoutesFromConnectionBranchToDestinationBranch,
    plannedRoutesFromSourceBranchToConnectionBranch,
    branches,
    selectedSourceBranch,
    dayOfWeekFromCollectDate,
    connectionBranch,
    selectedCrossdockingDestinationBranch,
    hasConnection,
    isCrossdockingService,
    crossdockingPlannedFlight,
    plannedFlight,
    plannedConnectionFlight,
    setValue,
    selectedDestinationBranch,
    modalType,
    doesChangeRouteNetwork
  ])

  if (isContentLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(submit)}
          noValidate
        >
          <Heading size="lg" fontFamily="poppins">
            {title}
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          <Stack mt="4" direction="column">
            <HStack w="full" spacing="24px">
              <Select
                {...register('customer_id')}
                name="customer_id"
                label="Pertence ao Cliente"
                error={errors.customer_id}
                isDisabled={true}
                customers_first_name={customers}
                placeholder="Selecione uma opção..."
                required
              />
            </HStack>

            {isCustomerSelected && (
              <Select
                {...register('budget_id')}
                name="budget_id"
                label="Pertence ao Orçamento"
                error={errors.budget_id}
                isDisabled={true}
                budgets={budgetsByCustomer}
                placeholder="Selecione uma opção..."
                required
              />
            )}
          </Stack>

          {isCustomerSelected &&
            sourceAddressOfBudget &&
            destinationAddressOfBudget && (
              <>
                <Stack
                  spacing="24px"
                  mt="4"
                  direction={
                    sourceAddressOfBudget.length > 0
                      ? ['column', 'column', 'row']
                      : 'column'
                  }
                >
                  {sourceAddressOfBudget.length > 0 ? (
                    <Controller
                      name="source_address_id"
                      control={control}
                      render={({ field: { onChange } }) => {
                        return (
                          <CheckboxService
                            onCheckboxChange={onChange}
                            onChange={handleUpdateGeloSecoAddressesFields}
                            name="source_address_id"
                            error={errors.source_address_id}
                            defaultCheckedOptions={defaultSourceAddressChecked}
                            addresses={sourceAddressOfBudget}
                            readOnlyAddresses={defaultSourceCollectedAddresses}
                            slug={slug}
                            label="Endereço(s) de origem"
                            isDisabled={!(slug === 'editar')}
                            required
                          />
                        )
                      }}
                    />
                  ) : (
                    <>
                      <Flex w="full" justify="center">
                        <Text fontSize="3xl">NÃO HÁ ENDEREÇOS DE ORIGEM</Text>
                      </Flex>
                    </>
                  )}
                  {destinationAddressOfBudget.length > 0 ? (
                    <Controller
                      name="destination_address_id"
                      control={control}
                      render={({ field: { onChange } }) => {
                        return (
                          <CheckboxService
                            onCheckboxChange={onChange}
                            name="destination_address_id"
                            error={errors.destination_address_id}
                            addresses={destinationAddressOfBudget}
                            defaultCheckedOptions={
                              defaultDestinationAddressChecked
                            }
                            readOnlyAddresses={
                              defaultDestinationDeliveryAddresses
                            }
                            slug={slug}
                            label="Endereço(s) de destino"
                            isDisabled={!(slug === 'editar' || slug === 'andamento')}
                            required
                          />
                        )
                      }}
                    />

                  ) : (
                    <>
                      <Flex w="full" justify="center">
                        <Text fontSize="3xl"> NÃO HÁ ENDEREÇOS DE DESTINO</Text>
                      </Flex>
                    </>
                  )}
                </Stack>
                <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
                  <Text>Adicionar observações em endereços</Text>
                </Stack>
                <Flex direction="column">
                  {requestCollectAddressObservationFields.map((field, index) => {
                    return (
                      <Flex direction='column'>
                        <Stack
                          w="full"
                          spacing="24px"
                          mt="4"
                          direction={['column', 'column', 'row']}
                        >
                          <Select
                            {...register(`requested_collect_addresses_observations.${index}.address_id`)}
                            name={`requested_collect_addresses_observations.${index}.address_id`}
                            label='Endereço'
                            placeholder='Selecione uma opção...'
                            error={
                              errors.requested_collect_addresses_observations
                                ? errors?.requested_collect_addresses_observations[index]?.address_id
                                : undefined
                            }
                            options={requestedCollectAddressesObservationsOptions}
                            isDisabled={!(slug === 'editar' || slug === 'andamento')}
                            required
                          />
                        </Stack>

                        <Stack
                          w="full"
                          spacing="24px"
                          mt="4"
                          direction={['column', 'column', 'row']}
                        >
                          <TextArea
                            {...register(`requested_collect_addresses_observations.${index}.observation`)}
                            name={`requested_collect_addresses_observations.${index}.observation`}
                            label='Observações'
                            error={
                              errors.requested_collect_addresses_observations
                                ? errors?.requested_collect_addresses_observations[index]?.observation
                                : undefined
                            }
                            isDisabled={!(slug === 'editar' || slug === 'andamento')}
                            required
                          />

                        </Stack>
                        <Stack pt={8}>
                          <Button
                            leftIcon={<Icon as={FaTimes} />}
                            variant="ghost"
                            onClick={() => handleRemoveRequestCollectAddressObservationField(index)}
                            h="48px"
                            isDisabled={!(slug === 'editar' || slug === 'andamento')}
                          >
                            Remover
                          </Button>
                        </Stack>
                      </Flex>
                    )
                  })}
                  <Button
                    alignSelf="flex-start"
                    onClick={handleAppendRequestCollectAddressObservationField}
                    mt={2}
                    isDisabled={!(slug === 'editar' || slug === 'andamento')}
                  >
                    Adicionar
                  </Button>
                </Flex>
                {sourceAddressOfBudget.length > 0 &&
                  destinationAddressOfBudget.length > 0 && (
                    <>
                      {slug === 'validar' || slug === 'andamento' ? (
                        serviceType === 'FRACIONADO' ? (
                          <>
                            <Stack
                              spacing="24px"
                              mt="4"
                              direction={['column', 'column', 'row']}
                            >
                              <Select
                                {...register('has_crossdocking')}
                                name="has_crossdocking"
                                label="Crossdocking"
                                error={errors.has_crossdocking}
                                negative_positive={negative_positive}
                                placeholder="Selecione uma opção..."
                                isDisabled={true}
                                required
                              />
                              {hasCrossdocking === 'SIM' && (
                                <Select
                                  {...register('shipping_id')}
                                  name="shipping_id"
                                  label="Transportadora Crossdocking"
                                  error={errors.shipping_id}
                                  shipping={shipping}
                                  placeholder="Selecione uma opção..."
                                  required
                                />
                              )}
                            </Stack>

                            <Stack
                              spacing="24px"
                              mt="4"
                              direction={['column', 'column', 'row']}
                            >
                              <Select
                                {...register('source_collector_id')}
                                name="source_collector_id"
                                label="Coletador de Origem"
                                error={errors.source_collector_id}
                                sourceCollector={sourceCollector}
                                placeholder="Selecione uma opção..."
                                isDisabled={
                                  slug !== 'validar' && slug !== 'andamento'
                                    ? true
                                    : isDisabled
                                }
                                required
                              />
                              {hasCrossdocking === 'SIM' && (
                                <Select
                                  {...register('crossdocking_collector_id')}
                                  name="crossdocking_collector_id"
                                  label="Coletador Intermediário"
                                  error={errors.crossdocking_collector_id}
                                  sourceCollector={crossdockingCollectors}
                                  placeholder="Selecione uma opção..."
                                  isDisabled={
                                    slug !== 'validar' && slug !== 'andamento'
                                      ? true
                                      : isDisabled
                                  }
                                  required
                                />
                              )}
                              <Select
                                {...register('destination_collector_id')}
                                name="destination_collector_id"
                                label="Coletador de Destino"
                                error={errors.destination_collector_id}
                                destinationCollector={destinationCollector}
                                placeholder="Selecione uma opção..."
                                isDisabled={
                                  slug !== 'validar' && slug !== 'andamento'
                                    ? true
                                    : isDisabled
                                }
                                required
                              />
                            </Stack>

                            {hasCrossdocking === 'SIM' ? (
                              <Stack
                                spacing="24px"
                                mt="4"
                                direction={['column', 'column', 'row']}
                              >
                                <Select
                                  {...register('source_branch_id')}
                                  name="source_branch_id"
                                  label="Base de Origem"
                                  onChangeCapture={() => setValue('crossdocking_planned_flight', '')}
                                  error={errors.source_branch_id}
                                  sourceBranch={sourceBranch}
                                  placeholder="Selecione uma opção..."
                                  isDisabled={
                                    slug !== 'validar' && slug !== 'andamento'
                                      ? true
                                      : isDisabled
                                  }
                                  required
                                />

                                {selectedSourceBranch && (
                                  <Select
                                    {...register(
                                      'destination_crossdocking_branch_id',
                                    )}
                                    name="destination_crossdocking_branch_id"
                                    label="Base Destino Intermediária"
                                    onChangeCapture={() => setValue('crossdocking_planned_flight', '')}
                                    error={
                                      errors.destination_crossdocking_branch_id
                                    }
                                    sourceBranch={
                                      destinationCrossdockingBranchBySourceBranch
                                    }
                                    placeholder="Selecione uma opção..."
                                    isDisabled={
                                      slug !== 'validar' && slug !== 'andamento'
                                        ? true
                                        : isDisabled
                                    }
                                    required
                                  />
                                )}

                                <Select
                                  {...register('source_crossdocking_branch_id')}
                                  name="source_crossdocking_branch_id"
                                  label="Base Origem Intermediária"
                                  onChangeCapture={() => setValue('planned_flight', '')}
                                  error={errors.source_crossdocking_branch_id}
                                  sourceBranch={crossdockingSourceBranches}
                                  placeholder="Selecione uma opção..."
                                  isDisabled={
                                    slug !== 'validar' && slug !== 'andamento'
                                      ? true
                                      : isDisabled
                                  }
                                  required
                                />

                                {!!selectedCrossdockingSourceBranch && (
                                  <Select
                                    {...register('destination_branch_id')}
                                    name="destination_branch_id"
                                    label="Base de Destino"
                                    onChangeCapture={() => setValue('planned_flight', '')}
                                    error={errors.destination_branch_id}
                                    destinationBranch={
                                      destinationBranchBySourceCrossdockingBranch
                                    }
                                    placeholder="Selecione uma opção..."
                                    isDisabled={
                                      slug !== 'validar' && slug !== 'andamento'
                                        ? true
                                        : isDisabled
                                    }
                                    required
                                  />
                                )}
                              </Stack>
                            ) : (
                              <Stack
                                spacing="24px"
                                mt="4"
                                direction={['column', 'column', 'row']}
                              >
                                <Select
                                  {...register('source_branch_id')}
                                  name="source_branch_id"
                                  label="Base de Origem"
                                  error={errors.source_branch_id}
                                  onChangeCapture={() => setValue('planned_flight', '')}
                                  sourceBranch={sourceBranch}
                                  placeholder="Selecione uma opção..."
                                  isDisabled={
                                    slug !== 'validar' && slug !== 'andamento'
                                      ? true
                                      : isDisabled
                                  }
                                  required
                                />
                                <Select
                                  {...register('destination_branch_id')}
                                  name="destination_branch_id"
                                  label="Base de Destino"
                                  onChangeCapture={() => setValue('planned_flight', '')}
                                  error={errors.destination_branch_id}
                                  destinationBranch={destinationBranches}
                                  placeholder="Selecione uma opção..."
                                  isDisabled={
                                    slug !== 'validar' && slug !== 'andamento'
                                      ? true
                                      : isDisabled
                                  }
                                  required
                                />
                              </Stack>
                            )}
                          </>
                        ) : (
                          <Stack
                            spacing="24px"
                            mt="4"
                            direction={['column', 'column', 'row']}
                          >
                            <Select
                              {...register('source_collector_id')}
                              name="source_collector_id"
                              label="Coletador de origem"
                              error={errors.source_collector_id}
                              sourceCollector={sourceCollector}
                              placeholder="Selecione uma opção..."
                              isDisabled={
                                slug !== 'validar' && slug !== 'andamento'
                                  ? true
                                  : isDisabled
                              }
                              required
                            />
                            <Select
                              {...register('destination_collector_id')}
                              name="destination_collector_id"
                              label="Coletador de Destino"
                              error={errors.destination_collector_id}
                              destinationCollector={destinationCollector}
                              placeholder="Selecione uma opção..."
                              isDisabled={
                                slug !== 'validar' && slug !== 'andamento'
                                  ? true
                                  : isDisabled
                              }
                              required
                            />
                          </Stack>
                        )
                      ) : null}

                      {slug !== 'editar' && (
                        <>
                          <Stack
                            spacing="24px"
                            mt="4"
                            direction={['column', 'column', 'row']}
                          >
                            {hasCrossdocking === 'SIM' &&
                              crossdockingModalType === 'AÉREO' && (
                                <Select
                                  {...register('has_connection_crossdocking')}
                                  name="has_connection_crossdocking"
                                  label="Possui Conexão Crossdocking?"
                                  negative_positive={negative_positive}
                                  defaultValue="NÃO"
                                  placeholder="Selecione uma opção..."
                                  error={errors.has_connection_crossdocking}
                                  required
                                />
                              )}
                            {modalType === 'AÉREO' && (
                              <Select
                                {...register('has_connection')}
                                name="has_connection"
                                label="Possui Conexão?"
                                negative_positive={negative_positive}
                                error={errors.has_connection}
                                defaultValue="NÃO"
                                placeholder="Selecione uma opção..."
                                required
                              />
                            )}
                          </Stack>

                          {hasConnectionCrossdocking === 'SIM' &&
                            selectedSourceBranch && (
                              <Stack
                                spacing="24px"
                                mt="4"
                                direction={['column', 'column', 'row']}
                              >
                                <Select
                                  {...register(
                                    'crossdocking_connection_branch_id',
                                  )}
                                  name="crossdocking_connection_branch_id"
                                  label="Base de Conexão Crossdocking"
                                  sourceBranch={crossdockingConnectionBranches}
                                  error={
                                    errors.crossdocking_connection_branch_id
                                  }
                                  placeholder="Selecione uma opção..."
                                  required
                                />
                              </Stack>
                            )}

                          {hasConnection === 'SIM' && (
                            <>
                              {hasCrossdocking === 'SIM'
                                ? selectedCrossdockingSourceBranch && (
                                  <Stack
                                    spacing="24px"
                                    mt="4"
                                    direction={['column', 'column', 'row']}
                                  >
                                    <Select
                                      {...register('connection_branch_id')}
                                      name="connection_branch_id"
                                      label="Base de conexão"
                                      destinationBranch={connectionBranches}
                                      error={errors.connection_branch_id}
                                      placeholder="Selecione uma opção..."
                                      required
                                    />
                                  </Stack>
                                )
                                : selectedSourceBranch && (
                                  <Stack
                                    spacing="24px"
                                    mt="4"
                                    direction={['column', 'column', 'row']}
                                  >
                                    <Select
                                      {...register('connection_branch_id')}
                                      name="connection_branch_id"
                                      label="Base de conexão"
                                      destinationBranch={connectionBranches}
                                      error={errors.connection_branch_id}
                                      placeholder="Selecione uma opção..."
                                      required
                                    />
                                  </Stack>
                                )}
                            </>
                          )}
                        </>
                      )}

                      <Stack
                        spacing="24px"
                        mt="4"
                        direction={['column', 'column', 'row']}
                      >
                        <Select
                          {...register('deadline')}
                          name="deadline"
                          label="Prazo de entrega"
                          error={errors.deadline}
                          deadline_delivery={deadline_delivery}
                          placeholder="Selecione uma opção..."
                          isDisabled={
                            slug !== 'validar' && slug !== 'andamento'
                              ? true
                              : isDisabled
                          }
                          required
                        />

                        <Select
                          {...register('service_type')}
                          name="service_type"
                          label="Tipo de serviço"
                          error={errors.service_type}
                          service_types={service_types}
                          placeholder="Selecione uma opção..."
                          isDisabled={
                            slug !== 'validar' && slug !== 'andamento'
                              ? true
                              : isDisabled
                          }
                          required
                        />
                      </Stack>
                      <Stack
                        spacing="24px"
                        mt="4"
                        direction={['column', 'column', 'row']}
                      >
                        <Stack
                          w="full"
                          spacing="24px"
                          direction={['column', 'column', 'row']}
                        >
                          {hasCrossdocking === 'SIM' && (
                            <Select
                              {...register('crossdocking_modal')}
                              name="crossdocking_modal"
                              label="Modal Crossdocking"
                              error={errors.crossdocking_modal}
                              modal_types={modal_types}
                              placeholder="Selecione uma opção..."
                              isDisabled={
                                slug !== 'validar' && slug !== 'andamento'
                                  ? true
                                  : isDisabled
                              }
                              required
                            />
                          )}
                          <Select
                            {...register('modal')}
                            name="modal"
                            label="Modal"
                            error={errors.modal}
                            modal_types={modal_types}
                            placeholder="Selecione uma opção..."
                            isDisabled={
                              slug !== 'validar' && slug !== 'andamento'
                                ? true
                                : isDisabled
                            }
                            required
                          />
                        </Stack>
                      </Stack>

                      {serviceType === 'FRACIONADO' &&
                        (slug === 'validar' || slug === 'andamento') && (
                          <Stack
                            w="full"
                            spacing="24px"
                            mt="4"
                            direction={['column', 'column', 'column']}
                          >
                            <FormProvider {...formMethods}>
                              <PlannedRoutesInputsGroup
                                hasConnection={hasConnection === 'SIM'}
                                isCrossdockingService={isCrossdockingService}
                                isFetchingRouteNetworks={isFetchingServiceRouteNetworksData}
                                hasCrossdockingConnection={hasConnectionCrossdocking === 'SIM'}
                                plannedRoutesWithoutCrossdockingAndConnection={plannedRoutesWithoutCrossdockingAndConnection}
                                plannedRoutesFromSourceBranchToConnectionBranch={plannedRoutesFromSourceBranchToConnectionBranch}
                                plannedRoutesFromConnectionBranchToDestinationBranch={plannedRoutesFromConnectionBranchToDestinationBranch}
                                plannedRoutesFromSourceBranchToConnectionCrossdockingBranch={plannedRoutesFromSourceBranchToConnectionCrossdockingBranch}
                                plannedRoutesFromSourceCrossdockingBranchToConnectionBranch={plannedRoutesFromSourceCrossdockingBranchToConnectionBranch}
                                plannedRoutesFromSourceBranchToDestinationCrossdockingBranch={plannedRoutesFromSourceBranchToDestinationCrossdockingBranch}
                                plannedRoutesFromSourceCrossdockingBranchToDestinationBranch={plannedRoutesFromSourceCrossdockingBranchToDestinationBranch}
                                plannedRoutesFromConnectionCrossdockingBranchToDestinationCrossdockingBranch={plannedRoutesFromConnectionCrossdockingBranchToDestinationCrossdockingBranch}
                              />
                            </FormProvider>

                            {!doesChangeRouteNetwork && (
                              <Button
                                leftIcon={<Icon as={FaExchangeAlt} />}
                                onClick={() => setDoesChangeRouteNetwork(true)}
                                overflow='hidden'
                              >
                                Alterar viagem planejada
                              </Button>
                            )}
                          </Stack>
                        )}

                      <Stack
                        spacing="24px"
                        mt="4"
                        direction={['column', 'column', 'row']}
                      >
                        <Select
                          {...register('vehicle')}
                          name="vehicle"
                          label="Veículo"
                          error={errors.vehicle}
                          vehicle_types={vehicle_types}
                          placeholder="Selecione uma opção..."
                          isDisabled={
                            slug !== 'validar' && slug !== 'andamento'
                              ? true
                              : isDisabled
                          }
                          required
                        />
                        <Input
                          {...register('franchising')}
                          name="franchising"
                          label="Franquia"
                          addChildren="KG"
                          type="number"
                          error={errors.franchising}
                          isDisabled={
                            slug !== 'validar' && slug !== 'andamento'
                              ? true
                              : isDisabled
                          }
                          required
                        />
                      </Stack>

                      <Stack
                        spacing="24px"
                        mt="4"
                        direction={['column', 'column', 'row']}
                      >
                        <Input
                          {...register('caixa_termica')}
                          name="caixa_termica"
                          label="Caixa Térmica"
                          type="number"
                          error={errors.caixa_termica}
                          isDisabled={
                            slug !== 'validar' &&
                              slug !== 'andamento' &&
                              slug !== 'editar'
                              ? true
                              : isDisabled
                          }
                          required
                        />
                        <Stack
                          w="full"
                          spacing="24px"
                          direction={['column', 'column', 'row']}
                        >
                          <Input
                            {...register('gelo_seco')}
                            name="gelo_seco"
                            label="Gelo Seco"
                            type="number"
                            error={errors.gelo_seco}
                            isDisabled={
                              slug !== 'validar' &&
                                slug !== 'andamento' &&
                                slug !== 'editar'
                                ? true
                                : isDisabled
                            }
                            required
                          />

                          <Input
                            {...register('gelox')}
                            name="gelox"
                            label="Gelox"
                            type="number"
                            error={errors.gelox}
                            isDisabled={
                              slug !== 'validar' &&
                                slug !== 'andamento' &&
                                slug !== 'editar'
                                ? true
                                : isDisabled
                            }
                            required
                          />
                        </Stack>
                      </Stack>

                      <Stack
                        spacing="24px"
                        mt="4"
                        direction={['column', 'column', 'row']}
                      >
                        <Input
                          {...register('isopor3l')}
                          name="isopor3l"
                          label="Isopor 3L"
                          type="number"
                          error={errors.isopor3l}
                          isDisabled={
                            slug !== 'validar' &&
                              slug !== 'andamento' &&
                              slug !== 'editar'
                              ? true
                              : isDisabled
                          }
                          required
                        />
                        <Input
                          {...register('isopor7l')}
                          name="isopor7l"
                          label="Isopor 7L"
                          type="number"
                          error={errors.isopor7l}
                          isDisabled={
                            slug !== 'validar' &&
                              slug !== 'andamento' &&
                              slug !== 'editar'
                              ? true
                              : isDisabled
                          }
                          required
                        />
                        <Input
                          {...register('terciaria3l')}
                          name="terciaria3l"
                          label="Terciária 3L"
                          type="number"
                          error={errors.terciaria3l}
                          isDisabled={
                            slug !== 'validar' &&
                              slug !== 'andamento' &&
                              slug !== 'editar'
                              ? true
                              : isDisabled
                          }
                          required
                        />

                        <Input
                          {...register('terciaria8l')}
                          name="terciaria8l"
                          label="Terciária 8L"
                          type="number"
                          error={errors.gelox}
                          isDisabled={
                            slug !== 'validar' &&
                              slug !== 'andamento' &&
                              slug !== 'editar'
                              ? true
                              : isDisabled
                          }
                          required
                        />
                      </Stack>

                      <Stack
                        spacing="24px"
                        mt="4"
                        direction={['column', 'column', 'row']}
                      >
                        <Input
                          {...register('embalagem_secundaria')}
                          name="embalagem_secundaria"
                          label="Embalagem Secundária Ziplock"
                          type="number"
                          error={errors.embalagem_secundaria}
                          isDisabled={
                            slug !== 'validar' &&
                              slug !== 'andamento' &&
                              slug !== 'editar'
                              ? true
                              : isDisabled
                          }
                          required
                        />
                      </Stack>

                      {geloSecoQuantity > 0 &&
                        (slug === 'validar' || slug === 'andamento') && (
                          <Stack
                            spacing="24px"
                            mt="4"
                            direction={['column', 'column', 'row']}
                          >
                            <Select
                              {...register('provider_id')}
                              name="provider_id"
                              label="Fornecedor de gelo seco"
                              error={errors.provider_id}
                              providerList={providerList}
                              placeholder="Selecione uma opção..."
                              isDisabled={
                                slug !== 'validar' && slug !== 'andamento'
                                  ? true
                                  : isDisabled
                              }
                              required
                            />
                          </Stack>
                        )}

                      <Stack
                        spacing="24px"
                        mt="4"
                        direction={['column', 'column', 'row']}
                      >
                        <Input
                          {...register('collect_date')}
                          isDisabled={isDisabled}
                          name="collect_date"
                          type="date"
                          min={dateNow}
                          label="Data da coleta"
                          error={errors.collect_date}
                          required
                        />
                        <HStack w="full" spacing="24px">
                          <Input
                            {...register('collect_hour_start')}
                            isDisabled={isDisabled}
                            name="collect_hour_start"
                            type="time"
                            label="Horário inicial da coleta"
                            error={errors.collect_hour_start}
                            required
                          />

                          <Input
                            {...register('collect_hour_end')}
                            isDisabled={isDisabled}
                            name="collect_hour_end"
                            type="time"
                            label="Horário final da coleta"
                            error={errors.collect_hour_end}
                            required
                          />
                        </HStack>
                      </Stack>

                      {collectDate && (
                        <Stack
                          spacing="24px"
                          mt="4"
                          direction={['column', 'column', 'row']}
                        >
                          <Input
                            {...register('delivery_date')}
                            isDisabled={
                              slug !== 'validar' && slug !== 'andamento'
                                ? true
                                : isDisabled
                            }
                            name="delivery_date"
                            type="date"
                            min={dateNow}
                            label="Data da entrega"
                            error={errors.delivery_date}
                            required
                          />

                          <Input
                            {...register('delivery_hour')}
                            isDisabled={
                              slug !== 'validar' && slug !== 'andamento'
                                ? true
                                : isDisabled
                            }
                            name="delivery_hour"
                            type="time"
                            label="Horário limite para entregas"
                            error={errors.collect_hour_start}
                            required
                          />
                        </Stack>
                      )}
                      {addressesWithoutOpeningHoursSaturdayOrSunday.saturday.length !== 0 && (
                        <Alert status='warning' mt='4' flexDirection='column' rounded='sm'>

                          <AlertTitle >Endereço(s) sem funcionamento aos sábados!</AlertTitle>
                          {addressesWithoutOpeningHoursSaturdayOrSunday.saturday.map((address) => {
                            return (
                              <AlertDescription key={address.id} alignSelf='flex-start'>{`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} |
                    ${address.complement ?? '-'} | ${address.reference_point ?? '-'} ${address.cityIDAddress.name} | ${address.cep}`.toUpperCase()}</AlertDescription>
                            )
                          })}
                        </Alert>
                      )}
                      {addressesWithoutOpeningHoursSaturdayOrSunday.sunday.length !== 0 && (
                        <Alert status='warning' mt='4' flexDirection='column' rounded='sm'>
                          <AlertTitle>Endereço(s) sem funcionamento aos domingos!</AlertTitle>
                          {addressesWithoutOpeningHoursSaturdayOrSunday.sunday.map((address) => {
                            return (
                              <AlertDescription alignSelf='flex-start'>{`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} |
                    ${address.complement ?? '-'} | ${address.reference_point ?? '-'} ${address.cityIDAddress.name} | ${address.cep}`.toUpperCase()}</AlertDescription>
                            )
                          })}
                        </Alert>
                      )}

                      {serviceType !== 'DEDICADO' &&
                        (slug === 'validar' || slug === 'andamento') && (
                          <>
                            {hasCrossdocking === 'SIM' && (
                              <Stack
                                spacing="24px"
                                w="full"
                                mt="4"
                                direction={['column', 'column', 'row']}
                              >
                                <Input
                                  {...register('crossdocking_board_date')}
                                  isDisabled={isDisabled}
                                  name="crossdocking_board_date"
                                  type="date"
                                  label="Data limite para embarque crossdocking"
                                  error={errors.crossdocking_board_date}
                                  required
                                />
                                <Input
                                  {...register('crossdocking_board_hour')}
                                  isDisabled={isDisabled}
                                  name="crossdocking_board_hour"
                                  type="time"
                                  label="Horário limite para embarque crossdocking"
                                  error={errors.crossdocking_board_hour}
                                  required
                                />
                              </Stack>
                            )}
                            <Stack
                              spacing="24px"
                              w="full"
                              mt="4"
                              direction={['column', 'column', 'row']}
                            >
                              <Input
                                {...register('board_date')}
                                isDisabled={isDisabled}
                                name="board_date"
                                type="date"
                                label="Data limite para embarque"
                                error={errors.board_hour}
                                required
                              />
                              <Input
                                {...register('board_hour')}
                                isDisabled={isDisabled}
                                name="board_hour"
                                type="time"
                                label="Horário limite para embarque"
                                error={errors.board_hour}
                                required
                              />
                            </Stack>
                          </>
                        )}

                      {userLogged?.user_type !== 'CLIENTE' &&
                        serviceType === 'FRACIONADO' &&
                        (slug === 'validar' || slug === 'andamento') &&
                        hasCrossdocking === 'SIM' && (
                          <Stack
                            spacing="24px"
                            mt="4"
                            w="full"
                            direction={['column', 'column', 'row']}
                          >
                            <Input
                              {...register(
                                'crossdocking_availability_forecast_day',
                              )}
                              isDisabled={isDisabled}
                              name="crossdocking_availability_forecast_day"
                              type="date"
                              label="Data Previsão de Disponibilidade Crossdocking"
                              error={
                                errors.crossdocking_availability_forecast_day
                              }
                              required
                            />
                            <Input
                              {...register(
                                'crossdocking_availability_forecast_time',
                              )}
                              isDisabled={isDisabled}
                              name="crossdocking_availability_forecast_time"
                              type="time"
                              label="Hora Previsão de Disponibilidade Crossdocking"
                              error={
                                errors.crossdocking_availability_forecast_time
                              }
                              required
                            />
                          </Stack>
                        )}

                      {userLogged?.user_type !== 'CLIENTE' &&
                        serviceType === 'FRACIONADO' &&
                        (slug === 'validar' || slug === 'andamento') && (
                          <Stack
                            spacing="24px"
                            mt="4"
                            w="full"
                            direction={['column', 'column', 'row']}
                          >
                            <Input
                              {...register('availability_forecast_day')}
                              isDisabled={isDisabled}
                              name="availability_forecast_day"
                              type="date"
                              label="Data Previsão de Disponibilidade"
                              error={errors.availability_forecast_day}
                              required
                            />
                            <Input
                              {...register('availability_forecast_time')}
                              isDisabled={isDisabled}
                              name="availability_forecast_time"
                              type="time"
                              label="Hora Previsão de Disponibilidade"
                              error={errors.availability_forecast_time}
                              required
                            />
                          </Stack>
                        )}

                      <Stack mt="4">
                        <Select
                          {...register('material_type')}
                          name="material_type"
                          label="Tipo de Material"
                          error={errors.material_type}
                          placeholder="Selecione uma opção..."
                          requestedServiceMaterialTypes={switchRequestedServiceMateryalTypes.handle()}
                          required
                        />
                      </Stack>

                      {(materialType === 'CORRELATOS' ||
                        materialType === 'CARGA GERAL') && (
                          <>
                            {fields.map((item, index) => (
                              <Fragment key={index}>
                                <Flex
                                  w="full"
                                  align="center"
                                  direction={['column', 'column', 'row']}
                                >
                                  <Stack
                                    w="full"
                                    spacing="24px"
                                    mt="4"
                                    direction={['column', 'column', 'row']}
                                  >
                                    <Select
                                      {...register(
                                        `destination_addresses_input.${index}.address_id`,
                                      )}
                                      name={`destination_addresses_input.${index}.address_id`}
                                      label="Endereço"
                                      addresses={addresses}
                                      error={
                                        errors.destination_addresses_input
                                          ? errors?.destination_addresses_input[
                                            index
                                          ]?.address_id
                                          : undefined
                                      }
                                      isDisabled={true}
                                      required
                                    />
                                    <Input
                                      {...register(
                                        `destination_addresses_input.${index}.quantity`,
                                      )}
                                      name={`destination_addresses_input.${index}.quantity`}
                                      label="Quantidade"
                                      error={
                                        errors.destination_addresses_input
                                          ? errors?.destination_addresses_input[
                                            index
                                          ]?.quantity
                                          : undefined
                                      }
                                      required
                                    />
                                  </Stack>
                                </Flex>
                                <Divider my="6" borderColor="gray.700" />
                              </Fragment>
                            ))}
                          </>
                        )}

                      {serviceHasGeloSeco && slug === "editar" && (
                        <>
                          {geloSecoAddressesFields.map((field, index) => {
                            return (
                              <Stack key={field.id} mt={6}>

                                <Stack direction={["column", "column", "row"]} spacing={6}>
                                  <Select
                                    {...register(`gelo_seco_addresses.${index}.address_id`)}
                                    name={`gelo_seco_addresses.${index}.address_id`}
                                    label="Endereço"
                                    placeholder="Selecione uma opção..."
                                    options={geloSecoAddressesOptions}
                                    error={
                                      errors.gelo_seco_addresses
                                        ? errors?.gelo_seco_addresses[index]?.address_id
                                        : undefined
                                    }
                                    required
                                  />
                                  <Stack pt={[0, 0, 8]}>
                                    <Button
                                      leftIcon={<Icon as={FaTimes} />}
                                      variant="ghost"
                                      onClick={() => handleRemoveGeloSecoAddress(index)}
                                      h="48px"
                                    >
                                      Remover
                                    </Button>
                                  </Stack>

                                </Stack>

                                <Divider />
                              </Stack>
                            )
                          })}
                          {!isAllSourceAddressesAssignedWithGeloSeco && (
                            <Flex mt={6}>
                              <Button
                                colorScheme="blue"
                                onClick={handleAppendGeloSecoAddress}
                                w="full"
                              >
                                Adicionar gelo seco no endereço
                              </Button>
                            </Flex>
                          )}
                        </>
                      )}

                      <Stack mt="4">
                        <TextArea
                          {...register('observation')}
                          name="observation"
                          label="Observações"
                          isDisabled={isDisabled}
                        />
                      </Stack>
                    </>
                  )}
              </>
            )}

          <Flex mt="8" justify="flex-end">
            <HStack>
              {slug === 'visualizar' ? (
                <FormActionButton href={href} action={action} icon={FaPen} />
              ) : (
                <SubmitButton
                  isSubmitting={isSubmitting}
                  action={action}
                  onClick={
                    slug === 'validar'
                      ? setHasValidate
                      : slug === 'andamento'
                        ? setHasCurrent
                        : () => { }
                  }
                />
              )}

              {slug === 'editar' &&
                userLogged?.permissions.includes('edit-requested-service') && (
                  <>
                    <CancelServiceModal
                      serviceId={serviceById.id}
                      serviceProtocol={serviceById.protocol}
                      isOpen={isCancelServiceModalOpen}
                      onClose={onCloseCancelServiceModal}
                    />
                    <CancelButton
                      type="submit"
                      isSubmitting={isSubmitting}
                      action="Cancelar Serviço"
                      onClick={() => { onOpenCancelServiceModal(); setHasCancelled() }}
                    />
                  </>

                )}
              <ListButton href="/servicos/solicitados" name="Serviços" />
            </HStack>
          </Flex>
        </Box>
      </Flex>

  )
}
