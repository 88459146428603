import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface ApprovePmcBody {
  pmcProducts: {
    approvedQuantity: number
    productId: string
  }[]
}

interface ApprovePmcParams {
  pmcId: string
  body: ApprovePmcBody
}

interface ApprovePmcResponse {
  notificationProfiles: string[]
  pmc: {
    protocol: number
  }
}

export async function approvePmc({
  pmcId,
  body: { pmcProducts }
}: ApprovePmcParams) {
  try {
    const response = await api.patch<ApprovePmcResponse>(`/pmcs/${pmcId}/approve`, {
      pmcProducts
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
