import axios from "axios"
import { Collector } from "hooks/collector/dtos/Collector"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"
import { DispatchStockProposal } from "./dispatchStockProposal"

interface GetDispatchStockProposalsProps {
  queryParams: {
    currentPage: string
    pageSize: string
    collector?: string
    protocol?: string
    status: string
  }
}

export interface GetDispatchStockProposalsResponse {
  proposals: Array<DispatchStockProposal & {
    collector: Collector
  }>
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
  }
}


export async function getDispatchStockProposals({
  queryParams
}: GetDispatchStockProposalsProps) {

  try {
    const { data } = await api.get<GetDispatchStockProposalsResponse>('/dispatch-stock/proposals', {
      params: {
        ...queryParams
      }
    })

    return data

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
