import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface ApproveDispatchStockProposalProps {
  routeParams: { proposalId: string }
}

export async function approveDispatchStockProposal({
  routeParams
}: ApproveDispatchStockProposalProps) {
  try {
    await api.patch(`/dispatch-stock/proposals/${routeParams.proposalId}`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
