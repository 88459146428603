import { useToastify } from "hooks/toastify/useToastify"
import { useMutation, useQuery, useQueryClient } from "react-query"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Stack, Text } from "@chakra-ui/react";
import { AnalysisFormDataItem, analyzeSupport } from "api/supports/analyzeSupport";

import { format, set } from "date-fns";
import { getSupport } from "api/supports/getSupport";
import { useEffect, useState } from "react";
import { AnalyzeRpaRequestForm } from "./AnalyzeRpaRequestForm";

export interface AnalyzeSupportProps {
  supportId: string
}

function removeEmptyFields(obj: AnalysisFormDataItem): AnalysisFormDataItem {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value.value)
  );
}


export interface AnalyzeSupportSchema {
  deadline: string
  analysisFormData?: AnalysisFormDataItem
}

const analyzeSupportSchema = yup.object({
  deadline: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    value = new Date(originalValue).toISOString()

    return value
  }),
  analysisFormData: yup.object().shape({
    controlCard: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required(),
      })
    }),
    rpaReceived: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required(),
      })
    }),
    rpaNumber: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required(),
      })
    }),
    paymentDate: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),
  })
})


export function AnalyzeSupport({
  supportId
}: AnalyzeSupportProps) {

  const {
    data: supportResult,
  } = useQuery({
    queryKey: ['support', supportId],
    queryFn: () => getSupport({ supportId }),
    refetchOnWindowFocus: false,
  })

  const [subjectSupport, setSubjectSupport] = useState('')

  useEffect(() => {
    setSubjectSupport(supportResult?.support?.subject?.name)
  }, [supportResult])


  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const formMethods = useForm<AnalyzeSupportSchema>({
    resolver: yupResolver(analyzeSupportSchema),
    context: {
      subject: subjectSupport
    }
  })

  const { handleSubmit, register, setValue, formState: { isSubmitting, errors } } = formMethods

  useEffect(() => {
    if (supportResult) {
      setValue('deadline', format(new Date(supportResult.support.deadline), "yyyy-MM-dd'T'HH:mm"))
    }
  }, [supportResult, setValue])

  const { mutateAsync: analyzeSupportFn } = useMutation({
    mutationFn: analyzeSupport,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'analyzing-supports' })
      queryClient.invalidateQueries({ queryKey: 'supporting-supports' })
      queryClient.invalidateQueries({ queryKey: ['support', supportId] })
    }
  })

  async function handleAnalyzeSupport(values: AnalyzeSupportSchema) {
    await promiseMessage(analyzeSupportFn({
      body: {
        ...values,
        internSupport: {
          analysisFormData: removeEmptyFields(values.analysisFormData)
        }
      },
      supportId
    }), 'Análise realizada!')
  }


  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleAnalyzeSupport)}
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'>Analisar atendimento</Heading>
      <FormProvider {...formMethods}>
        <Stack
          direction="column"
          w="full"
          mt="3"
        >
          <FormControl isInvalid={!!errors?.deadline}>
            <FormLabel fontSize="sm">
              Prazo para resposta
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('deadline')}
              name="deadline"
              type="datetime-local"
              size="sm"
              rounded="md"
            />
          </FormControl>
        </Stack>
        {supportResult?.support?.subject?.name === 'Solicitação de RPA' && (
          <AnalyzeRpaRequestForm />
        )}
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Salvar
          </Button>
        </Flex>
      </FormProvider>
    </Box>
  )
}
