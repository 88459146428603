import { Flex, Box, Heading, Button, Icon } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { AuthContext } from '../../contexts/AuthContext'
import { useContext, useEffect, useState } from 'react'
import { InputTable } from '../../components/Tables/Input/InputTable'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useInput } from '../../hooks/input/useInput'
import { useGeneralProviders } from 'hooks/generalProvider/useGeneralProviders'

export function InputList() {
  const [isLoading, setIsLoading] = useState(true)

  const { userLogged } = useContext(AuthContext)

  const {
    inputs: { data: inputs, isLoading: isInputLoading, refetch },
  } = useInput(null, true, false)
  const { data: generalProviders, isFetching: isFetchingGeneralProviders } = useGeneralProviders()
  const permissions = userLogged?.permissions

  useEffect(() => {
    function run() {
      if (!isInputLoading && !isFetchingGeneralProviders) {
        setIsLoading(false)
      }
    }
    run()
  }, [isInputLoading, isFetchingGeneralProviders])

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontFamily="poppins">
              Lista de Insumos de Transporte
            </Heading>
            {permissions !== undefined && (
              <Link to="/insumo/adicionar">
                {userLogged?.permissions.includes('add-input') && (
                  <Button
                    colorScheme="gray"
                    rightIcon={<Icon as={FiPlus} />}
                    size="sm"
                  >
                    Adicionar Insumo de Transporte
                  </Button>
                )}
              </Link>
            )}
          </Flex>
          {!!generalProviders && !!inputs && (
            <InputTable
              generalProviders={generalProviders}
              inputs={inputs}
              refetch={refetch}
            />
          )}
        </Box>
      </Flex>

  )
}
