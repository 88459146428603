import { Box, Circle, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { format } from "date-fns";
import { FaCalendarAlt, FaPen } from "react-icons/fa";

import { Aso } from "api/asos/aso";
import { AsoDetail } from "./AsoDetail";
import { asoTypeMap } from "utils/asoMappers";
import { useAuth } from "hooks/auth/useAuth";
import { EditAso } from "./EditAso";

interface AsosKanbanCardProps {
  aso: Aso
}

export function AsosKanbanCard({ aso }: AsosKanbanCardProps) {
  const { userLogged } = useAuth()
  const userCanEditAso = userLogged?.permissions.includes('edit-aso')

  const {
    isOpen: isAsoDetailModalOpen,
    onToggle: onToggleAsoDetailModal,
  } = useDisclosure()
  const {
    isOpen: isEditAsoOpen,
    onToggle: onToggleEditAso,
  } = useDisclosure()


  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleAsoDetailModal}
      cursor='pointer'
    >
      <HStack justify="space-between" w="full">

        <Text fontWeight="bold" mb={2}>
          {aso?.collaborator_name}
        </Text>
        {userCanEditAso && (
          <Circle
            size="5"
            color="white"
            onClick={(e) => {
              e.stopPropagation()
              onToggleEditAso()
            }}
          >
            <Icon as={FaPen} boxSize={"3.5"} color='blue.900' />
          </Circle>
        )}
      </HStack>

      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">

        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA SOLICITAÇÃO</Text>
          </HStack>
          <Text ml={5}>{format(new Date(aso?.created_at), "dd/MM/yyyy")}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">TIPO</Text>
          </HStack>
          <Text ml={5}>{asoTypeMap[aso.type]}</Text>
        </Box>

      </VStack>
      <Modal
        isOpen={isAsoDetailModalOpen}
        onClose={onToggleAsoDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <AsoDetail
          asoId={aso.id}
          onClose={onToggleAsoDetailModal}
        />
      </Modal>
      <Modal
        isOpen={isEditAsoOpen}
        onClose={onToggleEditAso}
        size="4xl"
      >
        <ModalOverlay />

        <EditAso
          asoId={aso.id}
          onCloseModal={onToggleEditAso} />

      </Modal>
    </Box>
  )
}
