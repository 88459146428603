import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface SaveDispatchStockMaterialBody {
  quantity: number
}

interface SaveDispatchStockMaterialParams {
  dispatchStockId: string
  inputId: string
  body: SaveDispatchStockMaterialBody
}

export async function saveDispatchStockMaterial({
  dispatchStockId,
  inputId,
  body
}: SaveDispatchStockMaterialParams) {
  try {
    await api.post(`/dispatch-stocks/${dispatchStockId}/materials/${inputId}`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

