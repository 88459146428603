import { api } from "services/api"
import axios from "axios"
import { RequestError } from "utils/errors/RequestErrors"

interface GetServiceCostsParams {
  serviceId: string
}

export interface GetServiceCostsResponse {
  collectsCost: number
  deliveriesCost: number
  extrasDiscountsCost: number
  materialsTotalCost: number
  transferCost: number
}

export async function getServiceCosts({
  serviceId
}: GetServiceCostsParams) {
  try {
    const { data } = await api.get<GetServiceCostsResponse>(`/services/${serviceId}/costs`)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }

}
