import { Box, Button, FormControl, FormLabel, IconButton, Input, Link, Progress, Stack, Table, Tbody, Td, Text, Textarea, Tr } from "@chakra-ui/react";
import { getSupportSubject } from "api/supports/getSupportSubject";
import { Select } from "components/Inputs/SelectInput";
import { CreateAttachmentResponse } from "hooks/attachment/useAttachmentMutation";
import { useAuth } from "hooks/auth/useAuth";
import { Collector } from "hooks/collector/dtos/Collector";
import { ChangeEvent } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";
import { UseMutationResult, useQuery } from "react-query";
import { CreateSupportSchema } from "./CreateSupport";

interface PartnerSupportFormProps {
  uploadRequestAttachmentFn: UseMutationResult<CreateAttachmentResponse, unknown, FormData, unknown>
  uploadProggressRequestAttachment: number
  collectors: Collector[]
}


export function PartnerSupportForm({
  uploadProggressRequestAttachment,
  uploadRequestAttachmentFn,
  collectors
}: PartnerSupportFormProps) {
  const { userLogged } = useAuth()

  const isUserLoggedCollector = userLogged?.user_type === 'COLETADOR'

  const { register, control, formState: { errors } } = useFormContext<CreateSupportSchema>()


  const [requestAttachmentId, supportSubjectId] = useWatch({
    control,
    name: ['requestAttachmentId', 'subject'],
  })

  const {
    data: supportSubjectResult
  } = useQuery({
    queryFn: () => getSupportSubject({ supportSubjectId }),
    queryKey: ['support-subject', supportSubjectId],
    enabled: !!supportSubjectId
  })


  const isRequestToChangeRegistrationData = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Solicitação de alteração de dados cadastrais/conta de pagamento'.toLowerCase();

  const typeRequiresAttachment = [
    'Envio de fatura',
    'Envio de nf',
    'Envio de registro de limpeza'
  ].map(item => item.toLowerCase()).includes(supportSubjectResult?.supportSubject?.name.toLowerCase());

  async function handleUploadRequestAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadRequestAttachmentFn.mutateAsync(formData)

  }


  return (
    <Box w="full">
      <Stack
        mt={3}
        spacing={3}
        w="full"
        direction={{ base: 'column', lg: 'row' }}
      >
        <FormControl isInvalid={!!errors.collectorId}>

          <FormLabel fontSize="sm">
            Coletador
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>

          <Select
            {...register("collectorId")}
            name="collectorId"
            placeholder="Selecione uma opção..."
            size='sm'
            rounded="md"
            isDisabled={isUserLoggedCollector}
          >
            {collectors?.map((collector) => {
              return <option key={collector.id} value={collector.id}>{collector.trading_name}</option>
            })}
          </Select>
        </FormControl>
      </Stack>
      {isRequestToChangeRegistrationData && (
        <>
          <FormControl isInvalid={!!errors.bank} mt={3}>
            <FormLabel fontSize="sm">
              Banco
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('bank')}
              name="bank"
              size="sm"
              rounded="md"
            />
          </FormControl>
          <FormControl isInvalid={!!errors.agency} mt={3}>
            <FormLabel fontSize="sm">
              Agência
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('agency')}
              name="agency"
              size="sm"
              rounded="md"
            />
          </FormControl>
          <FormControl isInvalid={!!errors.accountNumber} mt={3}>
            <FormLabel fontSize="sm">
              Conta
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('accountNumber')}
              name="accountNumber"
              size="sm"
              rounded="md"
            />
          </FormControl>
        </>
      )}
      <FormControl isInvalid={!!errors.description} mt={3}>
        <FormLabel fontSize="sm">
          Descreva sua solicitação
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Textarea
          {...register("description")}
          name="description"
          size="sm"
          rounded="md"
        />
      </FormControl>
      {typeRequiresAttachment && (
        <>
          <Stack
            direction="column"
            w="full"
            mt="3"
          >
            <Button
              as={FormLabel}
              htmlFor="requestAttachmentId"
              lineHeight="1"
              leftIcon={<FaFileImport />}
              size="sm"
              w="min"
              cursor="pointer"
              border={!!errors?.requestAttachmentId && '2px solid'}
              borderColor={(!!errors?.requestAttachmentId) && 'red.500'}
            >
              Anexar Documento
            </Button>
            <FormControl isInvalid={!!errors?.requestAttachmentId}>
              <Input
                {...register('requestAttachmentId')}
                name="requestAttachmentId"
                id="requestAttachmentId"
                type="file"
                hidden
                onChangeCapture={handleUploadRequestAttachment}
              />
            </FormControl>
          </Stack>

          {requestAttachmentId && (
            <Table size="sm">
              <Tbody>
                {Object.entries(requestAttachmentId).map(([key, file]) => {
                  return (
                    <Tr key={key}>
                      <Td fontSize="xs" maxW="100px">{file.name}</Td>
                      <Td fontSize="xs" w="200px">
                        <Progress size="sm" rounded="md" value={uploadProggressRequestAttachment} />
                      </Td>
                      <Td fontSize="xs" isNumeric>
                        {uploadRequestAttachmentFn.data && (
                          <IconButton
                            aria-label="Visualizar anexo"
                            as={Link}
                            size="sm"
                            icon={<FaExternalLinkAlt />}
                            href={uploadRequestAttachmentFn.data.attachment.link}
                            isExternal
                          />
                        )}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          )}
        </>
      )}
    </Box>
  )
}
