import { Box, Flex } from "@chakra-ui/react";
import { InvoiceStatus as InvoiceStatusTypes } from "api/invoices/_types/Invoice";
import { invoiceStatusMap } from "utils/invoiceMappers";

interface InvoiceStatusProps {
  status: InvoiceStatusTypes;
}

export function InvoiceStatus({ status }: InvoiceStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {['analyzing', 'checking-invoice', 'operational-inconsistency', 'collector-inconsistency', 'checking-values', 'checking-cp-value'].includes(status) && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {['approved-invoice', 'approved'].includes(status) && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}
      {status === 'repproved' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {invoiceStatusMap[status]}
    </Flex>
  )
}
