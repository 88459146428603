import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CompleteOperationalAuditBody {
  realizedDate: string
  nonCompliance?: string
  services: {
    serviceProtocol: string
    isWithNonDivergentInputs: boolean
    collectHourInsideHourBlock: boolean
    hasBoxPhotoInCollect: boolean
    hasContentDeclarationInCollect: boolean
    isContentDeclarationSignedInCollect: boolean
    isContentDeclarationFilledInCollect: boolean
    isContentDeclarationStampedInCollect: boolean
    isReleaseMadeBySourceCollector: boolean
    deliveryHourInsideHourBlock: boolean
    hasBoxPhotoInDelivery: boolean
    hasContentDeclarationInDelivery: boolean
    isContentDeclarationSignedInDelivery: boolean
    isReleaseMadeByDestinationCollector: boolean
  }[]
}

interface CompleteOperationalAuditParams {
  body: CompleteOperationalAuditBody
  auditId: string
}

export async function completeOperationalAudit({
  body,
  auditId
}: CompleteOperationalAuditParams) {
  try {
    await api.put(`/audits/${auditId}/operational`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
