import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye, FiTrash2 } from 'react-icons/fi'
import { useContext, useState, useEffect, Fragment } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import swal from 'sweetalert'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import { InputContext, InputProps } from '../../../contexts/InputContext'
import { useAuth } from '../../../hooks/auth/useAuth'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { GeneralProviderResponse } from 'hooks/generalProvider/dtos/GeneralProvider'

interface InputTableProps {
  inputs: InputProps[]
  generalProviders: GeneralProviderResponse
  refetch: () => void
}

const inputOptions = [
  { key: 'name', value: 'Nome', checked: false },
  { key: 'price', value: 'Preço', checked: false },
  { key: 'minStock', value: 'Estoque Mínimo', checked: false },
  { key: 'standardStock', value: 'Estoque Padrão', checked: false },
  { key: 'provider', value: 'Fornecedor', checked: false },
]

const inputOrderOptions = [{ key: 'name', value: 'Nome' }]

export function InputTable({ inputs, generalProviders, refetch }: InputTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE
  const [searchedInputs, setSearchedInputs] = useState<InputProps[]>([])

  const [slicedInputs, setSlicedInputs] = useState<InputProps[]>([])
  const [nameFiltered, setNameFiltered] = useState('')
  const [priceFiltered, setPriceFiltered] = useState('')
  const [minStockFiltered, setMinStockFiltered] = useState('')
  const [standardStockFiltered, setStandardStockFiltered] = useState('')
  const [providerFiltered, setProviderFiltered] = useState('')

  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { deleteInput } = useContext(InputContext)
  const { userLogged } = useAuth()

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedInputs?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(inputOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(inputOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      setSlicedInputs(inputs)
    }
    run()
  }, [itemLimit, currentPage, offset, inputs])

  useEffect(() => {
    function run() {
      const newSlicedInputs = slicedInputs?.filter((inputs) => {
        const providerFilteredByInput = generalProviders?.generalProviders
          .filter((provider) => provider.id === inputs.general_provider_id)
          .map((provider) => provider.company_name)
          .toString()

        const nameFilter = searchBoxFilter(inputs.name, nameFiltered)
        const priceFilter = searchBoxFilter(
          `${inputs.unit_price.toFixed(2).toString().replace('.', ',')}`,
          priceFiltered,
        )
        const minStockFilter = searchBoxFilter(
          inputs.minimum_stock.toString(),
          minStockFiltered,
        )
        const standardStockFilter = searchBoxFilter(
          inputs.standard_stock.toString(),
          standardStockFiltered,
        )
        const providerFilter = searchBoxFilter(
          providerFilteredByInput,
          providerFiltered,
        )

        if (
          nameFiltered === '' &&
          priceFiltered === '' &&
          minStockFiltered === '' &&
          standardStockFiltered === '' &&
          providerFiltered === ''
        )
          return inputs

        return (
          nameFilter &&
          priceFilter &&
          minStockFilter &&
          standardStockFilter &&
          providerFilter
        )
      })

      if (orderOptionSelected.length > 0) {
        const slicedInputsByOrder = newSlicedInputs.sort(
          (a, b) =>
            (orderOptionSelected.includes('name') &&
              a.name.localeCompare(b.name)) ||
            0,
        )
        setSearchedInputs(slicedInputsByOrder)
      } else {
        setSearchedInputs(newSlicedInputs)
      }
    }
    run()
  }, [
    nameFiltered,
    priceFiltered,
    minStockFiltered,
    standardStockFiltered,
    providerFiltered,
    slicedInputs,
    orderOptionSelected,
    generalProviders
  ])

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  async function handleDeleteInput(inputId: string) {
    await swal({
      title: 'Deseja excluir um Insumo?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willEdit) => {
      if (willEdit) {
        const response = await deleteInput(inputId)
        if (response) refetch()
      } else {
        swal('Ação cancelada com êxito!')
      }
    })
  }

  return (
    <>
      {slicedInputs?.length <= 0 ? (
        <Alert
          status="info"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Oops!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Não há dados para mostrar aqui!
          </AlertDescription>
        </Alert>
      ) : (
        <>
          <TableFilterButton />
          <Stack mt="4" direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Fragment key={option.key}>
                  <Flex>
                    {option.key === 'name' && (
                      <SearchBox
                        placeholder="Buscar Nome..."
                        handleSearch={(e) => setNameFiltered(e.target.value)}
                      />
                    )}
                    {option.key === 'price' && (
                      <SearchBox
                        placeholder="Buscar Preço..."
                        handleSearch={(e) => setPriceFiltered(e.target.value)}
                      />
                    )}
                    {option.key === 'minStock' && (
                      <SearchBox
                        placeholder="Buscar Estoque Mínimo..."
                        handleSearch={(e) =>
                          setMinStockFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'standardStock' && (
                      <SearchBox
                        placeholder="Buscar Estoque Padrão..."
                        handleSearch={(e) =>
                          setStandardStockFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'provider' && (
                      <SearchBox
                        placeholder="Buscar Fornecedor..."
                        handleSearch={(e) =>
                          setProviderFiltered(e.target.value)
                        }
                      />
                    )}
                  </Flex>
                </Fragment>
              ))}
          </Stack>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  {inputOptions.map((option) => (
                    <Th key={option.key} fontSize="12" color="blue.900">
                      {option.value.toUpperCase()}
                    </Th>
                  ))}
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchedInputs
                  .slice(offset, offset + Number(itemLimit))
                  .map((input, index) => {

                    const providerFilteredByInput = generalProviders?.generalProviders
                      .filter((provider) => provider.id === input.general_provider_id)
                      .map((provider) => provider.company_name)
                      .toString()


                    return (
                      <Tr key={index}>
                        <Td fontSize="12">{input.name.toUpperCase()}</Td>
                        <Td fontSize="12">{`R$ ${input.unit_price
                          .toFixed(2)
                          .toString()
                          .replace('.', ',')}`}</Td>
                        <Td fontSize="12">{input.minimum_stock}</Td>
                        <Td fontSize="12">{input.standard_stock}</Td>
                        <Td fontSize="12">
                          {providerFilteredByInput.toUpperCase()}
                        </Td>
                        <Td isNumeric>
                          {userLogged !== null && (
                            <>
                              {userLogged?.permissions.includes(
                                'view-input',
                              ) && (
                                  <Link to={`/insumo/visualizar/${input.id}`}>
                                    <Icon
                                      cursor="pointer"
                                      as={FiEye}
                                      fontSize="20"
                                    />
                                  </Link>
                                )}
                              {userLogged?.permissions.includes(
                                'edit-input',
                              ) && (
                                  <Link to={`/insumo/editar/${input.id}`}>
                                    <Icon
                                      cursor="pointer"
                                      as={FiEdit3}
                                      fontSize="20"
                                      ml="4"
                                      mt={['2', '2', '0']}
                                    />
                                  </Link>
                                )}
                              {userLogged?.permissions.includes(
                                'remove-input',
                              ) && (
                                  <Icon
                                    cursor="pointer"
                                    as={FiTrash2}
                                    fontSize="20"
                                    ml="4"
                                    mt={['2', '2', '0']}
                                    onClick={() => handleDeleteInput(input.id)}
                                  />
                                )}
                            </>
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </Box>
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}
