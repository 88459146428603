import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { GetServicesWithDelayedValidationResponse } from "api/service/getServicesWithDelayedValidation";
import { format } from "date-fns";
import { captalize } from "utils/captalize";

interface DelayedValidationsModalProps {
  isOpen: boolean
  onClose: () => void
  delayedValidations: Pick<GetServicesWithDelayedValidationResponse, 'services'>
}

export function DelayedValidationsModal({
  isOpen,
  onClose,
  delayedValidations,
}: DelayedValidationsModalProps) {

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="bold" fontSize="2xl">Validações em atraso</ModalHeader>
        <ModalCloseButton />

        <ModalBody
          maxH="570px"
          overflowY="auto"
          overflowX="auto"
        >
          <TableContainer
            rounded="md"
            mt="6"
          >
            <Table size="sm">
              <Thead>
                <Tr
                  h="40px"
                  bg="#38c3fa"
                >
                  <Th color="white">Protocolo</Th>
                  <Th color="white">Cliente</Th>
                  <Th color="white">Data da coleta</Th>
                  <Th color="white">Origem</Th>
                  <Th color="white">Destino</Th>
                  <Th color="white">Horário inicial da coleta</Th>
                  <Th color="white">Horário final da coleta</Th>
                </Tr>
              </Thead>

              <Tbody>
                {delayedValidations?.services.map((service) => {
                  return (
                    <Tr key={service.id}>
                      <Td>{service.protocol}</Td>
                      <Td>{captalize(service.customerIDService.trading_firstname)}</Td>
                      <Td>{format(new Date(service.serviceIDRequested.collect_date), 'dd/MM/yyyy')}</Td>
                      <Td>{Array.from(new Set(service.sourceAddresses.map((address) => captalize(address.cityIDAddress.name))))}</Td>
                      <Td>{Array.from(new Set(service.destinationAddresses.map((address) => captalize(address.cityIDAddress.name))))}</Td>
                      <Td>{format(new Date(service.serviceIDRequested.collect_hour_start), 'HH:mm')}</Td>
                      <Td>{format(new Date(service.serviceIDRequested.collect_hour_end), 'HH:mm')}</Td>
                    </Tr>
                  )
                })}

              </Tbody>
            </Table>
          </TableContainer>

        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
