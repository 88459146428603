const rncDict = (status: string) => {
  switch (status) {
    case 'AWAITING RESPONSE':
      return 'AGUARDANDO RESPOSTA'
    case 'AWAITING VALIDATION':
      return 'AGUARDANDO VALIDAÇÃO'
    case 'AWAITING ALIGNMENT':
      return 'AGUARDANDO ALINHAMENTO'
    case 'DONE':
      return 'FINALIZADO'
    default:
      return '-'
  }
}

export { rncDict }
