import { usePagination } from "@ajna/pagination"
import { Box, Button, Flex, Heading, Icon, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";

import { useAuth } from "hooks/auth/useAuth";


import { Pagination } from "components/Pagination/Pagination"
import { useSwal } from "hooks/swal/useSwal";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect, useReducer, useMemo, Fragment } from "react";
import { FaPen, FaTrash, FaUndoAlt } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { useWarehouseFunctions } from "hooks/warehouse/useWarehouseFunctions";
import { useWarehouses } from "hooks/warehouse/useWarehouses";
import { useForm, useWatch } from "react-hook-form";
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions";
import { FiPlus } from "react-icons/fi";
import { TableFilterButton } from "components/Filters/TableFilterButton";
import { situation } from "utils/customLists";
import { Input } from "components/Inputs/Input";
import { useQueryClient } from "react-query";
import { Select } from "components/Inputs/SelectInput";

interface IWarehousesFilterInputs {
  q: string
  situation: string
}

const warehouseListFilterOptions = [
  { key: 'q', value: 'q', checked: false },
  { key: 'situation', value: 'Situação', checked: false },
]

interface ActionPayload {
  warehouseData?: IWarehousesFilterInputs
  currentPage?: number
}

interface Action {
  type: 'set-warehouse-current-data' | 'set-warehouse-current-page'
  payload: ActionPayload
}

function reducer(state: ActionPayload, action: Action) {
  if (action.type === 'set-warehouse-current-page') {
    return {
      warehouseData: state.warehouseData,
      currentPage: action.payload.currentPage
    }
  }

  return {
    ...state,
    ...action.payload
  }
}

export function WarehouseList() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  const userLoggedHasPermissionToViewWarehouse = userLogged?.permissions.includes('view-warehouse')

  const { confirmMessage, standardMessage } = useSwal()
  const { promiseMessage } = useToastify()

  const { register, control } = useForm<IWarehousesFilterInputs>()

  const [listWarehouseDataState, dispatch] = useReducer(
    reducer,
    {} as ActionPayload
  )

  useEffect(() => {
    if (!userLoggedHasPermissionToViewWarehouse) {
      redirectTo('/')
    }
  }, [userLoggedHasPermissionToViewWarehouse, redirectTo])

  const [
    querySearched,
    situationSearched,
  ] = useWatch({
    control,
    name: [
      'q',
      'situation',
    ]
  })

  const filterOptionsWithValuesRenamed = useMemo(() => {
    return warehouseListFilterOptions.map((option) => {
      if (option.key === 'q') {
        option.value = 'Busca Geral'
      }
      return option
    })
  }, [])

  const {
    filterOptions,
    onLoadSetFilterOptions
  } = useFilterOptions()

  useEffect(() => {
    onLoadSetFilterOptions(filterOptionsWithValuesRenamed)
  }, [filterOptionsWithValuesRenamed, onLoadSetFilterOptions])


  const { data: warehouses, isFetching: isFetchingWarehouses } = useWarehouses({
    queryParams: {
      ...listWarehouseDataState.warehouseData,
      current_page: listWarehouseDataState.currentPage,
      page_size: Number(rowsPerPage)
    }
  })

  const { deleteWarehouse: { mutateAsync: deleteWarehouse } } = useWarehouseFunctions()

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: warehouses?.total_pages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  useEffect(() => {
    dispatch({
      type: 'set-warehouse-current-page',
      payload: {
        currentPage: currentPage
      }
    })
  }, [currentPage])

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      dispatch({
        type: 'set-warehouse-current-data',
        payload: {
          warehouseData: {
            q: querySearched,
            situation: situationSearched,
          }
        }
      })

      setCurrentPage(1)
    }, 1000)

    return () => clearTimeout(debounceTimeout)
  }, [
    querySearched,
    situationSearched,
    dispatch,
    setCurrentPage
  ])


  async function handleDeleteWarehouse(warehouse_id: string) {
    const hasDeleteWarehouse = await confirmMessage({ title: "Deseja excluir o almoxarifado?" })

    if (hasDeleteWarehouse) {
      await promiseMessage(deleteWarehouse(warehouse_id), "Almoxarifado excluído com sucesso!")
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  const handleChangePage = (page: number) => setCurrentPage(page)

  const queryClient = useQueryClient()
  const handleRefetchTableData = async () => {
    await queryClient.invalidateQueries(['warehouses'])
  }

  const filterOptionsChecked = filterOptions.filter((option) => option.checked)

  if (isFetchingWarehouses) {
    return <GeneralContentLoading />
  }
  return (

      <Box
        p={4}
        bg="white"
        borderRadius="8px"
      >
        <Flex
          w="full"
          direction="column"
          gap={6}
        >

          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontFamily="poppins">
              Lista de Almoxarifados
            </Heading>
            <Link to="/almoxarifado/criar">
              <Button
                colorScheme="gray"
                rightIcon={<Icon as={FiPlus} />}
                size="sm"
              >
                Adicionar Almoxarifado
              </Button>

            </Link>
          </Flex>
          <Flex gap={2}>
            <TableFilterButton />
            <Button colorScheme="blue" onClick={handleRefetchTableData}>
              <Icon as={FaUndoAlt} />{' '}
            </Button>
          </Flex>
          {filterOptionsChecked.map((option) => {
            return (
              <Fragment key={option.key}>
                {option.key === 'situation' ? (
                  <Select
                    {...register('situation')}
                    name='situation'
                    label='Situação'
                    situations={situation}
                    placeholder='Selecione uma opção...'
                  />
                ) : (
                  <Input
                    {...register('q')}
                    name='q'
                    label='Buscar por palavra'
                  />
                )}
              </Fragment>
            )
          })}

          {isFetchingWarehouses ? (
            <Spinner />
          ) : (
            <TableContainer>
              <Table variant="striped" size="sm">
                <Thead>
                  <Tr>
                    <Th>NOME</Th>
                    <Th>SITUAÇÃO</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {warehouses?.warehouses.map(warehouse => {
                    return (
                      <Tr key={warehouse.id}>
                        <Td>{warehouse.name}</Td>
                        <Td>{warehouse.situation}</Td>
                        <Td isNumeric>
                          {userLogged?.permissions.includes('delete-warehouse') && (
                            <Button as={Link} to={`/almoxarifado/editar/${warehouse.id}`} size="xs" variant="unstyled">
                              <Icon
                                cursor="pointer"
                                as={FaPen}
                                transition="all 0.2s"
                                _hover={{ opacity: 0.8 }}
                              />
                            </Button>
                          )}
                          {userLogged?.permissions.includes('edit-warehouse') && (
                            <Button size="xs" variant="unstyled" ml={2}>
                              <Icon
                                onClick={async () => await handleDeleteWarehouse(warehouse.id)}
                                cursor="pointer"
                                as={FaTrash}
                                transition="all 0.2s"
                                _hover={{ opacity: 0.8 }}
                              />
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          <Pagination
            currentPage={currentPage}
            pages={pages}
            pagesQuantity={pagesCount}
            handlePageChange={handleChangePage}
          />
        </Flex>
      </Box>

  )
}
