import { Button, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { deleteExperiencePeriod } from "api/experiencePeriods/deleteExperiencePeriod";
import { GetExperiencePeriodsResponse } from "api/experiencePeriods/getExperiencePeriods";
import { ExperiencePeriod } from "api/experiencePeriods/types/ExperiencePeriod";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import { ExperiencePeriodDetail } from "./ExperiencePeriodDetail";
import { ExperiencePeriodStatus } from "./ExperiencePeriodStatus";

interface ExperiencePeriodTableRowProps {
  experiencePeriod: ExperiencePeriod
}

export function ExperiencePeriodTableRow({ experiencePeriod }: ExperiencePeriodTableRowProps) {
  const history = useHistory()
  const { userLogged } = useAuth()
  const userCanViewExperiencePeriods = userLogged?.permissions?.includes('view-experience-periods')
  const userHasDeleteExperiencePeriodPermission = userLogged?.permissions.includes('delete-experience-period')

  useEffect(() => {
    if (!userCanViewExperiencePeriods) history.push('/')
  }, [history, userCanViewExperiencePeriods])


  const {
    isOpen: isExperiencePeriodDetailModalOpen,
    onOpen: onOpenExperiencePeriodDetailModal,
    onClose: onCloseExperiencePeriodDetailModal,
  } = useDisclosure()

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const { mutateAsync: deleteExperiencePeriodFn } = useMutation({
    mutationFn: deleteExperiencePeriod,
    onSuccess(_data, { experiencePeriodId }) {
      const cachedExperiencePeriods = queryClient.getQueriesData<GetExperiencePeriodsResponse>({
        queryKey: ['experience-periods']
      })
      cachedExperiencePeriods.forEach(([cachedKey, cachedData]) => {
        if (!cachedData) {
          return null
        }

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          experiencePeriods: cachedData?.experiencePeriods?.filter((cachedExperiencePeriod) => {
            return cachedExperiencePeriod.id !== experiencePeriodId
          })
        })

      })
    },
  })

  async function handleDeleteExperiencePeriod() {
    await promiseMessage(deleteExperiencePeriodFn({
      experiencePeriodId: experiencePeriod.id
    }), 'Periodo de experiência excluido!')
  }

  return (

    <Tr>
      <Td>
        <Modal
          isOpen={isExperiencePeriodDetailModalOpen}
          onClose={onCloseExperiencePeriodDetailModal}
        >
          <ModalOverlay />
          <ExperiencePeriodDetail experiencePeriodId={experiencePeriod?.id} />
        </Modal>
        <IconButton
          aria-label="Detalhes do periodo de experiência"
          icon={<FaSearch />}
          onClick={onOpenExperiencePeriodDetailModal}
          size="sm"
        />
      </Td>
      <Td>{experiencePeriod?.name}</Td>
      <Td>{format(new Date(experiencePeriod?.admission_date), 'dd/MM/yyyy')}</Td>
      <Td>{format(new Date(experiencePeriod?.period_45_days_end_date), 'dd/MM/yyyy') ?? '-'}</Td>
      <Td>{format(new Date(experiencePeriod?.period_90_days_end_date), 'dd/MM/yyyy') ?? '-'}</Td>
      <Td>{experiencePeriod?.regional}</Td>
      <Td>
        <ExperiencePeriodStatus status={experiencePeriod?.status} />
      </Td>
      <Td>
        {userHasDeleteExperiencePeriodPermission && (
          <Button
            leftIcon={<FaTimes />}
            lineHeight="1"
            size="xs"
            variant="ghost"
            onClick={handleDeleteExperiencePeriod}
          >
            Excluir
          </Button>
        )}

      </Td>
    </Tr>
  )
}
