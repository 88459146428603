import { Box, Card, CardBody, CardHeader, Flex, Icon, IconButton, Modal, ModalOverlay, Table, Tbody, Td, Text, Tooltip, Tr, useDisclosure } from "@chakra-ui/react"
import { Interactions } from "components/Interactions/Interactions"
import { formatDistanceToNowStrict, set } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { ptBR } from "date-fns/locale"
import { FaInfoCircle, FaPeopleArrows } from "react-icons/fa"
import { Link } from "react-router-dom"
import { captalize } from "utils/captalize"
import { timezonesUsed } from "utils/DateFunctions/timezones"
import { serviceHandleStep } from "utils/ServiceFunctions/serviceDict"
import { interactionStatusColors } from "./DelayedCollects"

interface DeleyedCollectCardProps {
  service: {
    id: string
    protocol: number
    step: string
    customer: string
    source_hub: string
    source_tz: string
    destination_hub: string
    destination_tz: string
    collect_date: string
    collect_hour_end: string
    last_interaction_status: string
  }

}
export function DeleyedCollectCard({ service }: DeleyedCollectCardProps) {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [
    day,
    month,
    year
  ] = formatInTimeZone(service.collect_date, timezonesUsed[service.source_tz].timezone, 'dd/MM/yyyy').split('/').map(Number)

  const [
    hours,
    minutes
  ] = formatInTimeZone(service.collect_hour_end, timezonesUsed[service.source_tz].timezone, 'HH:mm').split(':').map(Number)

  const collectDateTime = set(new Date(), {
    date: day,
    month: month - 1,
    year,
    hours,
    minutes
  })

  const now = new Date();
  let distanceCollectDateTime = null
  let timeUntilCollect = null

  if (now > collectDateTime) {
    distanceCollectDateTime = formatDistanceToNowStrict(collectDateTime, { locale: ptBR });
  } else {
    timeUntilCollect = formatDistanceToNowStrict(collectDateTime, { locale: ptBR });
  }

  return (
    <Card
      rounded="xl"
      transition="all 0.2s"
      _hover={{
        transform: 'translateY(-0.5rem)'
      }}
      h="full"
    >
      <CardHeader
        as={Link}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        to={`/servico/detalhes/${service.id}`}
        h="40px"
        bg="#38c3fa"
        borderTopRadius="xl"
      >
        <Text fontWeight="bold" color="white">{service.protocol}</Text>
      </CardHeader>

      <CardBody
        as={Flex}
        gap="6"
        direction="column"
      >
        <Table size="sm">
          <Tbody>
            <Tr>
              <Td fontSize='lg'>Status</Td>
              <Td isNumeric fontSize='lg'>{captalize(serviceHandleStep(service.step))}</Td>
            </Tr>
            <Tr>
              <Td fontSize='lg'>Cliente</Td>
              <Td isNumeric fontSize='lg'>{captalize(service.customer)}</Td>
            </Tr>
            <Tr>
              <Td fontSize='lg'>
                <Flex>
                  Hub de Origem
                  {Number(service.source_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
                    <Tooltip label={service.source_tz}>
                      <Box as="span" ml="2">
                        <Icon as={FaInfoCircle} />
                      </Box>
                    </Tooltip>
                  )}
                </Flex>
              </Td>
              <Td isNumeric fontSize='lg'>{captalize(service.source_hub)}</Td>
            </Tr>
            <Tr>
              <Td fontSize='lg'>
                <Flex>
                  Hub de Destino
                  {Number(service.destination_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
                    <Tooltip label={service.destination_tz}>
                      <Box as="span" ml="2">
                        <Icon as={FaInfoCircle} />
                      </Box>
                    </Tooltip>
                  )}
                </Flex>
              </Td>
              <Td isNumeric fontSize='lg'>{captalize(service.destination_hub)}</Td>
            </Tr>
            {timeUntilCollect && (
              <Tr>
                <Td fontSize='lg'>Coleta em: </Td>
                <Td fontSize='lg' isNumeric>{captalize(timeUntilCollect)}</Td>
              </Tr>
            )}
            {distanceCollectDateTime && (
              <Tr>
                <Td fontSize='lg'>Tempo de Atraso</Td>
                <Td fontSize='lg' isNumeric>{captalize(distanceCollectDateTime)}</Td>
              </Tr>
            )}
          </Tbody>
        </Table>

        <Flex h="full" justify="flex-end" align="flex-end">
          <IconButton
            aria-label="Abrir Interações"
            icon={<FaPeopleArrows />}
            colorScheme={interactionStatusColors[service.last_interaction_status] || interactionStatusColors.default}
            size="sm"
            onClick={onOpen}

          />
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />

            <Interactions isOpen={isOpen} serviceId={service.id} />
          </Modal>

        </Flex>
      </CardBody>
    </Card>
  )
}
