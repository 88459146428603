import { FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

interface TicketLogAdditionalValueCltIntercurrenceFormSchema {
  employeeCarLicensePlate: string
}

export function TicketLogAdditionalValueCltIntercurrenceForm() {

  const {
    register,
    formState: {
      errors,
    }
  } = useFormContext<TicketLogAdditionalValueCltIntercurrenceFormSchema>()

  return (
    <>
      <FormControl isInvalid={!!errors.employeeCarLicensePlate}>
        <FormLabel>
          Placa do funcionário
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('employeeCarLicensePlate')}
        />
      </FormControl>
    </>
  )
}
