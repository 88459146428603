import axios from "axios"
import { useAuth } from "hooks/auth/useAuth"
import { useQuery, UseQueryOptions } from "react-query"
import { api } from "services/api"
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum"
import { RequestError } from "utils/errors/RequestErrors"
import { InProgressServiceData } from "./dtos/InProgressService"

interface InProgressServicesQueryParams {
  page_size?: number
  current_page?: number
  protocol?: number
  collect_date?: string
  delivery_date?: string
  customer?: string
  source_city?: string
  destination_city?: string
  shipping?: string
  source_collector?: string
  destination_collector?: string
  driver_id?: string
  step?: string
  collector_id?: string
  customer_id?: string
  owner_id?: string
  responsible?: string
}

type QueryOptions = UseQueryOptions<InProgressServiceData, unknown, InProgressServiceData, ["inProgressServices"]>

interface UseInProgressServicesOptions {
  queryParams?: InProgressServicesQueryParams,
  queryOptions?: QueryOptions
}

async function getInProgressServicesReqFunction(queryParams?: InProgressServicesQueryParams) {
  try {
    const { data } = await api.get('/service/list/in-progress', {
      params: {
        ...queryParams,
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

export function useInProgressServices(options?: UseInProgressServicesOptions) {

  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  return useQuery([
    'inProgressServices',
    { ...options?.queryParams }
  ],
    () => getInProgressServicesReqFunction(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )

}
