import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface ApplyAdvertenceBody {
  advertenceId: string
  signedAdvertence: string
  advertenceAttachmentId: string
  proofOfSendingAdvertenceAttachmentId?: string
}

interface ApplyAdvertenceProps {
  body: ApplyAdvertenceBody
  routeParams: {
    advertenceId: string
  }
}

export async function applyAdvertence({
  body,
  routeParams
}: ApplyAdvertenceProps) {
  try {
    await api.put(`/advertences/${routeParams.advertenceId}/apply`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
