const primarySlugs = [
  'controle-estoque',
  'despacho-estoque',
  'rnc',
  'cargas',
  'agendamentos',
  'ocorrencias',
  'coletador',
]
const secondarySlugs = ['cargas', 'agendamentos', 'ocorrencias']

class SwitchReportFilters {
  handle(slug: string) {
    const filterByOptions = [{ id: 0, name: 'GERAL' }].sort((a, b) =>
      a.name.localeCompare(b.name),
    )

    if (!primarySlugs.includes(slug)) {
      const newFilterByOptions = [...filterByOptions]

      newFilterByOptions.push({ id: 1, name: 'CLIENTE' })

      return newFilterByOptions
    }

    if (secondarySlugs.includes(slug)) {
      if (slug === 'ocorrencias') {
        const newFilterByOptions = [...filterByOptions]

        newFilterByOptions.push(
          { id: 1, name: 'COLETADOR' },
          { id: 2, name: 'CLIENTE' },
          { id: 3, name: 'TRANSPORTADORA' },
          { id: 4, name: 'MOTORISTA' },
        )

        return newFilterByOptions
      }

      const newFilterByOptions = [...filterByOptions]

      newFilterByOptions.push(
        { id: 1, name: 'COLETADOR' },
        { id: 2, name: 'CLIENTE' },
      )

      return newFilterByOptions
    }

    if (
      slug !== 'rnc' &&
      !secondarySlugs.includes(slug) &&
      !primarySlugs.slice(0, 5).includes(slug)
    ) {
      const newFilterByOptions = [...filterByOptions]

      newFilterByOptions.push({ id: 1, name: 'COLETADOR' })

      return newFilterByOptions
    }

    return filterByOptions
  }
}

const switchReportFilters = new SwitchReportFilters()

export { switchReportFilters }
