import { Button, Flex, Select } from "@chakra-ui/react";
import { TrainingStatus } from "api/trainings/training";
import { useHub } from "hooks/hub/useHub";
import { useUsers } from "hooks/user/useUsers";
import { useSearchParams } from "hooks/useSearchParams";
import { CSVLink } from "react-csv";
import { useForm } from "react-hook-form";
import { FaFileExport, FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { captalize } from "utils/captalize";

interface TrainingsReportFiltersSchema {
  hubId: string
  collaboratorId: string
  status: TrainingStatus | 'all'
}

interface TrainingsReportFiltersProps {
  csvProps: {
    headers: {
      label: string
      key: string
    }[]
    data: any[]
    filename: string
  }
}

export function TrainingsReportFilters({
  csvProps
}: TrainingsReportFiltersProps) {
  const searchParams = useSearchParams()

  const { replace } = useHistory()

  const {
    register,
    handleSubmit,
  } = useForm<TrainingsReportFiltersSchema>({
    defaultValues: {
      status: 'all'
    }
  })

  const {
    hubs: {
      data: hubs,
      isLoading: isHubsLoading
    }
  } = useHub(null, true)

  const { data: users, isLoading: isUsersLoading } = useUsers({
    queryParms: {
      situation: 'ATIVO'
    }
  })

  function handleSearchTrainingsReport(values: TrainingsReportFiltersSchema) {
    if (values.hubId) {
      searchParams.set('hubId', values.hubId)
    } else {
      searchParams.delete('hubId')
    }

    if (values.collaboratorId) {
      searchParams.set('collaboratorId', values.collaboratorId)
    } else {
      searchParams.delete('collaboratorId')
    }

    if (values.status) {
      searchParams.set('status', values.status)
    } else {
      searchParams.delete('status')
    }

    replace({ search: searchParams.toString() })

  }

  return (
    <Flex
      justify={{
        base:
          'center',
        md:
          'space-between'
      }}
      direction={{
        base: 'column',
        md: 'row'
      }}
      align="center"
      mt="6"
      as="form"
      gap="2"
      w='full'
      onSubmit={handleSubmit(handleSearchTrainingsReport)}
    >
      <Flex w={{ base: 'full', md: 'min'}} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Select
          {...register("hubId")}
          name="hubId"
          placeholder="Selecione um hub..."
          size="sm"
          rounded="md"
          w={{ base: 'full', md: '200px' }}
          disabled={isHubsLoading}
        >
          {hubs?.map((hub) => {
            return (
              <option key={hub.id} value={hub.id}>{captalize(hub.name)}</option>
            )
          })}
        </Select>

        <Select
          {...register("collaboratorId")}
          name="collaboratorId"
          placeholder="Selecione um colaborador..."
          size="sm"
          rounded="md"
          w={{ base: 'full', md: '250px' }}
          disabled={isUsersLoading}
        >
          {users?.filter(user => user.loglife_employee || user.user_type === 'MOTORISTA').map((user) => {
            return (
              <option key={user.id} value={user.id}>{captalize(`${user.firstname} ${user.lastname}`)}</option>
            )
          })}
        </Select>

        <Select
          {...register("status")}
          name="status"
          defaultValue="all"
          size="sm"
          rounded="md"
          w={["full", "full", "200px"]}
        >
          <option value="all">Todos status</option>
          <option value="pending">Pendente</option>
          <option value="active">Ativo</option>
          <option value="inactive">Inativo</option>
        </Select>
      </Flex>

      <Flex w={{ base: 'full', md: 'min'}} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Button
          leftIcon={<FaFileExport />}
          size="sm"
          type="button"
          colorScheme="green"
          as={CSVLink}
          separator={';'}
          {...csvProps}
          w={{ base: 'full', md: 'min'}}
        >
          Exportar
        </Button>
        <Button
          leftIcon={<FaSearch />}
          size="sm"
          type="submit"
          w={{ base: 'full', md: 'min'}}
        >
          Gerar relatório
        </Button>
      </Flex>
    </Flex>
  )
}
