import { useQuery } from 'react-query'
import {
  getAllRequestedBusinessServiceReqFunction,
  getOneRequestedBusinessServiceReqFunction,
} from '../../../utils/RequestFunctions/Service/RequestedBusiness/requestRequestedBusinessServiceFunctions'
import { useAuth } from '../../auth/useAuth'
import { useSwal } from '../../swal/useSwal'

interface UseRequestedBusinessServiceOptions {
  queryParams: {
    step: 'collect' | 'delivery'
  }
}

export function useRequestedBusinessService(
  id?: string,
  refetchOnFocus?: boolean,
  options?: UseRequestedBusinessServiceOptions
) {
  const { handleLogout, userLogged } = useAuth()
  const { errorMessage } = useSwal()

  const requestedBusinessServices = useQuery(
    'requestedBusinessServices',
    () => getAllRequestedBusinessServiceReqFunction({
      queryParams: options?.queryParams
    }),
    {
      enabled: !id && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
        if (error.message.includes('Token')) handleLogout()
      },
    },
  )

  const requestedBusinessService = useQuery(
    ['requestedBusinessService', id],
    () => getOneRequestedBusinessServiceReqFunction(id || ''),
    {
      enabled: !!id && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
        if (error.message.inclues('Token')) handleLogout()
      },
    },
  )

  return {
    requestedBusinessServices,
    requestedBusinessService,
  }
}
