import { Box, FormControl, FormLabel, Input, InputGroup, InputLeftAddon, Select, Stack, Text } from "@chakra-ui/react";

import {

  Controller,
  useFormContext,

} from "react-hook-form";

import { situation } from "utils/customLists";
import { Material } from "hooks/material/dtos/Material";
import { GeneralProviderResponse } from "hooks/generalProvider/dtos/GeneralProvider";
import { useCurrency } from "react-hook-currency";
import { WarehouseResponse } from "hooks/warehouse/dtos/Warehouse";
import { useEffect } from "react";
import { captalize } from "utils/captalize";

interface ProductFormProps {
  slug: string
  materials?: Material[]
  generalProviders?: GeneralProviderResponse
  warehouses?: WarehouseResponse
}

export interface ICostCenters {
  name: string
}

export interface ProductFormInputs {
  situation: string
  material_id: string
  general_provider_id: string
  warehouse_id: string,
  measure: string
  unit_cost: number
  min_stock: number
  standard_stock: number
  current_stock: number
}

export function ProductForm({ slug, materials, generalProviders, warehouses }: ProductFormProps) {
  const {
    register,
    setValue,
    control,
    formState: {
      errors
    }
  } = useFormContext<ProductFormInputs>()

  useEffect(() => {
    if (slug === 'criar') {
      setValue('situation', 'ATIVO')
    }
  }, [setValue, slug])

  const {
     onChange: onCurrencyChange,
  } = useCurrency({
    style: 'decimal'
  })

  return (
    <Box w='full'>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.situation}>
            <FormLabel fontSize="sm">
              Situação
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register('situation')}
              name="situation"
              placeholder="Selecione..."
              rounded="md"
              size='sm'
              disabled={slug === 'criar'}
            >
              {situation?.map(situation => (
                <option key={situation.id} value={situation.name}>{captalize(situation.name)}</option>
              ))}
            </Select>
          </FormControl>

        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.material_id}>
            <FormLabel fontSize="sm">
              Material
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register('material_id')}
              name="material_id"
              placeholder="Selecione..."
              rounded="md"
              size='sm'
            >
              {materials?.map(material => (
                <option key={material.id} value={material.id}>{captalize(material.material_name)}</option>
              ))}
            </Select>
          </FormControl>
        </Stack>

      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.warehouse_id}>
            <FormLabel fontSize="sm">
              Almoxarifado
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register('warehouse_id')}
              name="warehouse_id"
              placeholder="Selecione..."
              rounded="md"
              size='sm'

            >
              {warehouses?.warehouses?.map(warehouse => (
                <option key={warehouse.id} value={warehouse.id}>{captalize(warehouse.name)}</option>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.general_provider_id}>
            <FormLabel fontSize="sm">
              Fornecedor
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register('general_provider_id')}
              name="general_provider_id"
              placeholder="Selecione..."
              rounded="md"
              size='sm'
            >
              {generalProviders?.generalProviders?.map(generalProvider => (
                <option key={generalProvider.id} value={generalProvider.id}>{captalize(generalProvider.trading_name)}</option>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.measure}>
            <FormLabel fontSize="sm">
              Medida
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('measure')}
              name='measure'
              rounded="md"
              size="sm"
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >

          <FormControl isInvalid={!!errors.unit_cost}>
            <FormLabel fontSize="sm">
              Custo Unitário
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="unit_cost"
              control={control}
              render={({ field }) => {
                return (
                  <InputGroup
                    size="sm"
                  >
                    <InputLeftAddon
                      borderTopLeftRadius="md"
                      borderBottomLeftRadius="md"
                    >
                      R$
                    </InputLeftAddon>
                    <Input
                     {...register('unit_cost')}
                      size="sm"
                      rounded="md"
                      w="full"
                      name={field.name}
                      value={field.value}
                      onChange={e => field.onChange(onCurrencyChange(e))}
                    />
                  </InputGroup>
                )
              }}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.min_stock}>
            <FormLabel fontSize="sm">
              Estoque Mínimo
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('min_stock')}
              name='min_stock'
              type='number'
              rounded="md"
              size="sm"
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.standard_stock}>
            <FormLabel fontSize="sm">
              Estoque Padrão
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('standard_stock')}
              name='standard_stock'
              type='number'
              rounded="md"
              size="sm"
            />
          </FormControl>

        </Stack>
      </Stack>
      <FormControl isInvalid={!!errors.current_stock} mt={3}>
            <FormLabel fontSize="sm">
            Estoque Atual
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('current_stock')}
              name='current_stock'
              type='number'
              rounded="md"
              size="sm"
            />
          </FormControl>
    </Box>
  )
}
