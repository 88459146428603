import axios from "axios"
import { BranchProps } from "contexts/BranchContext"
import { api } from "services/api"
import { IDriverProps } from "services/getFunctions/driver/getDrivers"
import { RequestError } from "utils/errors/RequestErrors"
import { Landing } from "./@types/landing"


export interface GetLandingResponse  extends Landing{
  driverIDLanding: IDriverProps
  branchIDLanding: BranchProps
  serviceIDLanding:{
    protocol: number
  }

}


interface GetLandingsPendingValidationsQueryParams {
  landingId: string
}

export async function getLandingService({
  landingId
}: GetLandingsPendingValidationsQueryParams) {
  try {
    const { data } = await api.get<GetLandingResponse>(`/landing-service/${landingId}`)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
