import { usePagination } from "@ajna/pagination";
import { Alert, AlertIcon, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Skeleton, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { getPriceLogs } from "api/prices/getPriceLogs";
import { Pagination } from "components/Pagination/Pagination";
import { useSearchParams } from "hooks/useSearchParams";
import { Fragment } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CitiesProps } from "services/getFunctions/city/getCity";
import { formatDate } from "utils/DateFunctions/formatDate";
import { IHubsProps } from "utils/RequestFunctions/Hubs/requestHubFunctions";

import { PriceLogLine } from "./PriceLogLine";


interface PriceLogsModalProps {
  isOpen: boolean
  onClose: () => void
  cities: CitiesProps[]
  hubs: IHubsProps[]
  priceId: string
}

export function PriceLogsModal({
  isOpen,
  onClose,
  hubs,
  cities,
  priceId
}: PriceLogsModalProps) {

  const searchParams = useSearchParams()
  const { replace } = useHistory()
  const page = searchParams.get('page') ?? '1'

  const {
    data: priceLogsResult,
    isLoading: isLoadingPriceLogsResult
  } = useQuery({
    queryKey: ['price-logs', priceId, page],
    enabled: isOpen,
    queryFn: () => getPriceLogs({
      currentPage: Number(page),
      pageSize: 10,
      priceId,
    })
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: priceLogsResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: priceLogsResult?.meta?.pageSize,
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }


  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="850px">
        <ModalHeader>Logs</ModalHeader>
        <ModalCloseButton />

        <ModalBody maxH='500px' h='full' overflow='auto' >
          <Flex direction='column' align='center'>
            <TableContainer
              rounded='md'
              maxW='700px'
              border="1px solid"
              borderColor="gray.200"
            >
              {isLoadingPriceLogsResult ? (
                <>
                  <Skeleton h="15px" />
                  <Skeleton h="15px" />
                  <Skeleton h="15px" />
                  <Skeleton h="15px" />
                </>
              ) : (
                Boolean(priceLogsResult?.logs?.length) ? (
                  <Table size='sm'>
                    <Thead>
                      <Tr
                        h="40px"
                        bg="#38c3fa"
                      >
                        <Th color="white">CAMPO</Th>
                        <Th color="white">VALOR ANTERIOR</Th>
                        <Th color="white">VALOR ATUALIZADO</Th>
                        <Th color="white">ATUALIZADO POR</Th>
                        <Th color="white">DATA DA ATUALIZAÇÃO</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {priceLogsResult?.logs && priceLogsResult?.logs?.map((log) => {
                        return (
                          <Fragment key={log.id}>
                            {log.logDetail?.sourceCityId && (
                              <PriceLogLine
                                name="Cidade Origem"
                                prevValue={cities?.find((city) => city.id === log.logDetail?.sourceCityId.prev_value)?.name}
                                newValue={cities?.find((city) => city.id === log.logDetail?.sourceCityId.new_value)?.name}
                                updatedBy={`${log?.updatedByUser.firstname} ${log?.updatedByUser.lastname}`}
                                updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                              />
                            )}
                            {log.logDetail?.sourceHubId && (
                              <PriceLogLine
                                name="Hub Origem"
                                prevValue={hubs?.find((hub) => hub.id === log.logDetail?.sourceHubId.prev_value)?.name}
                                newValue={hubs?.find((hub) => hub.id === log.logDetail?.sourceHubId.new_value)?.name}
                                updatedBy={`${log?.updatedByUser.firstname} ${log?.updatedByUser.lastname}`}
                                updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                              />
                            )}
                            {log.logDetail?.destinationCityId && (
                              <PriceLogLine
                                name="Cidade Destino"
                                prevValue={cities?.find((city) => city.id === log.logDetail?.destinationCityId.prev_value)?.name}
                                newValue={cities?.find((city) => city.id === log.logDetail?.destinationCityId.new_value)?.name}
                                updatedBy={`${log?.updatedByUser.firstname} ${log?.updatedByUser.lastname}`}
                                updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                              />
                            )}
                            {log.logDetail?.destinationHubId && (
                              <PriceLogLine
                                name="Hub Destino"
                                prevValue={hubs?.find((hub) => hub.id === log.logDetail?.destinationHubId.prev_value)?.name}
                                newValue={hubs?.find((hub) => hub.id === log.logDetail?.destinationHubId.new_value)?.name}
                                updatedBy={`${log?.updatedByUser.firstname} ${log?.updatedByUser.lastname}`}
                                updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                              />
                            )}
                            {log?.logDetail?.category && (
                              <PriceLogLine
                                name="Categoria"
                                prevValue={String(log.logDetail?.category.prev_value)}
                                newValue={String(log.logDetail?.category.new_value)}
                                updatedBy={`${log?.updatedByUser.firstname} ${log?.updatedByUser.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                              />
                            )}
                            {log?.logDetail?.vehicle && (
                              <PriceLogLine
                                name="Veículo"
                                prevValue={String(log.logDetail?.vehicle.prev_value)}
                                newValue={String(log.logDetail?.vehicle.new_value)}
                                updatedBy={`${log?.updatedByUser.firstname} ${log?.updatedByUser.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                              />
                            )}
                            {log?.logDetail?.routeType && (
                              <PriceLogLine
                                name="Tipo de Rota"
                                prevValue={String(log.logDetail?.routeType.prev_value)}
                                newValue={String(log.logDetail?.routeType.new_value)}
                                updatedBy={`${log?.updatedByUser.firstname} ${log?.updatedByUser.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                              />
                            )}
                            {log?.logDetail?.airMinimumPrice && (
                              <PriceLogLine
                                name="Preço mínimo aéreo"
                                prevValue={String(log.logDetail?.airMinimumPrice.prev_value)}
                                newValue={String(log.logDetail?.airMinimumPrice.new_value)}
                                updatedBy={`${log?.updatedByUser.firstname} ${log?.updatedByUser.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                              />
                            )}
                            {log?.logDetail?.airExtraKg && (
                              <PriceLogLine
                                name="KG extra aéreo"
                                prevValue={String(log.logDetail?.airExtraKg.prev_value)}
                                newValue={String(log.logDetail?.airExtraKg.new_value)}
                                updatedBy={`${log?.updatedByUser.firstname} ${log?.updatedByUser.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                              />
                            )}
                            {log?.logDetail?.airDeadline && (
                              <PriceLogLine
                                name="Prazo aéreo"
                                prevValue={String(`D-${log.logDetail?.airDeadline.prev_value}`)}
                                newValue={String(`D-${log.logDetail?.airDeadline.new_value}`)}
                                updatedBy={`${log?.updatedByUser.firstname} ${log?.updatedByUser.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                              />
                            )}
                            {log?.logDetail?.highwayMinimumPrice && (
                              <PriceLogLine
                                name="Preço mínimo rodoviário"
                                prevValue={String(log.logDetail?.highwayMinimumPrice.prev_value)}
                                newValue={String(log.logDetail?.highwayMinimumPrice.new_value)}
                                updatedBy={`${log?.updatedByUser.firstname} ${log?.updatedByUser.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                              />
                            )}
                            {log?.logDetail?.highwayExtraKg && (
                              <PriceLogLine
                                name="KG extra rodoviário"
                                prevValue={String(log.logDetail?.highwayExtraKg.prev_value)}
                                newValue={String(log.logDetail?.highwayExtraKg.new_value)}
                                updatedBy={`${log?.updatedByUser.firstname} ${log?.updatedByUser.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                              />
                            )}
                            {log?.logDetail?.highwayDeadline && (
                              <PriceLogLine
                                name="Prazo rodoviário"
                                prevValue={String(`D-${log.logDetail?.highwayDeadline.prev_value}`)}
                                newValue={String(`D-${log.logDetail?.highwayDeadline.new_value}`)}
                                updatedBy={`${log?.updatedByUser.firstname} ${log?.updatedByUser.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                              />
                            )}
                            {log?.logDetail?.additionalCollectPrice && (
                              <PriceLogLine
                                name="Preço adicional coleta"
                                prevValue={String(log.logDetail?.additionalCollectPrice.prev_value)}
                                newValue={String(log.logDetail?.additionalCollectPrice.new_value)}
                                updatedBy={`${log?.updatedByUser.firstname} ${log?.updatedByUser.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                              />
                            )}
                            {log?.logDetail?.additionalDeliveryPrice && (
                              <PriceLogLine
                                name="Preço adicional entrega"
                                prevValue={String(log.logDetail?.additionalDeliveryPrice.prev_value)}
                                newValue={String(log.logDetail?.additionalDeliveryPrice.new_value)}
                                updatedBy={`${log?.updatedByUser.firstname} ${log?.updatedByUser.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                              />
                            )}
                            {log?.logDetail?.observation && (
                              <PriceLogLine
                                name="Observações"
                                prevValue={String(log.logDetail?.observation.prev_value)}
                                newValue={String(log.logDetail?.observation.new_value)}
                                updatedBy={`${log?.updatedByUser.firstname} ${log?.updatedByUser.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                              />
                            )}
                          </Fragment>

                        )
                      })}
                    </Tbody>
                  </Table>
                ) : (

                  <Flex bg={'red.200'} w='full'>
                    <Alert status="info">
                      <AlertIcon />
                      Não há logs para este preço!
                    </Alert>
                  </Flex>
                )
              )}
            </TableContainer>
            {Boolean(priceLogsResult?.logs?.length) && (
              <Pagination
                currentPage={currentPage}
                pages={pages}
                pagesQuantity={pagesCount}
                handlePageChange={handleChangePage}
              />
            )}
          </Flex>

        </ModalBody>
        <ModalFooter>
          <Flex gap={2}>
            <Button
              onClick={onClose}
              colorScheme='gray'
              variant='ghost'
            >
              Fechar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal >
  )
}
