import { MenuList, Flex, MenuItem, Checkbox, MenuDivider, Text } from "@chakra-ui/react"
import { FilterOptions } from "pages/Occurences/ConsultOccurrences"

interface ConsultOccurrencesCheckboxFilterButtonContentProps {
  filterOptions: FilterOptions[]
  onSetFilterOption: (option: string) => void
}

export function ConsultOccurrencesCheckboxFilterButtonContent({
  filterOptions,
  onSetFilterOption,
}: ConsultOccurrencesCheckboxFilterButtonContentProps) {

  return (
    <MenuList
      rounded="xl"
      boxShadow="md"
      w="full"
    >
      <Flex maxH="300px" overflowY="auto" w="full" direction="column">

          {filterOptions.map((filterOption) => {
            return (
              <MenuItem key={filterOption.value} w="full">
                <Checkbox
                  onChange={
                    (event) => onSetFilterOption(event.target.value)
                  }
                  w="full"
                  value={filterOption.value}
                  checked={filterOption.selected}
                >
                  <Text w="full" fontSize="xs">
                    {filterOption.showValue}
                  </Text>
                </Checkbox>
              </MenuItem>
            )
          })}
      </Flex>
      <MenuDivider />
    </MenuList>
  )
}
