import { Box, Button, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, Heading, Input, Radio, RadioGroup, Select, Stack, Text, VStack } from "@chakra-ui/react";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm, useWatch } from "react-hook-form";
import { dismissalDocsInternClt } from "api/internClts/dismissalDocsInternClt";
import { format, parseISO } from "date-fns";
import { InternClt } from "api/internClts/_types/internClt";
import { useEffect } from "react";


interface DismissalDocsInternCltProps {
  internClt: InternClt
}

export interface DismissalDocsInternCltSchema {
  isDocsVerified: string
  isLegalRiskVerified: string
  signedDocs: string[]
  isOperationLeaderAware: string
  isWithNotice: string
  lastWorkDate: string
  isDismissalSignedByCollaborator: string
  shouldAdvanceStatus: string
}

const dismissalDocsInternCltSchema = yup.object({
  // isDocsVerified: yup.string(),
  // isLegalRiskVerified: yup.string(),
  // signedDocs: yup.array().of(yup.string()),
  // isOperationLeaderAware: yup.string(),
  // isWithNotice: yup.string(),
  // lastWorkDate: yup.mixed().when('isWithNotice', {
  //   is: 'yes',
  //   then: yup.string().transform((value, originalValue, ctx) => {
  //     if (!value) return null

  //     const [year, month, day] = originalValue?.split('-').map(Number)
  //     value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

  //     return value
  //   })
  // }),
  // isDismissalSignedByCollaborator: yup.string(),
})

const signedDocsChecklist = [
  { name: 'Entregas de patrimônio' },
  { name: 'Regulamento interno' },
  { name: 'Contrato de experiência' },
]

export function DismissalDocsInternClt({ internClt }: DismissalDocsInternCltProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<DismissalDocsInternCltSchema>({
    resolver: yupResolver(dismissalDocsInternCltSchema)
  })

  useEffect(() => {
    if (internClt) {
      setValue("isDocsVerified", Boolean(internClt?.is_docs_verified)  ? internClt?.is_docs_verified ? 'yes' : 'no' : null)
      setValue("isLegalRiskVerified", Boolean(internClt?.is_legal_risk_verified)  ? internClt?.is_legal_risk_verified ? 'yes' : 'no' : null)
      if (Boolean(internClt?.signed_docs)) {
        setValue("signedDocs", internClt?.signed_docs)
      }
      setValue("isOperationLeaderAware", Boolean(internClt?.is_operation_leader_aware)  ? internClt?.is_operation_leader_aware ? 'yes' : 'no' : null)
      setValue("isWithNotice", Boolean(internClt?.is_with_notice)  ? internClt?.is_with_notice ? 'yes' : 'no' : null)
      setValue("lastWorkDate", internClt?.last_work_date ? format(parseISO(internClt?.last_work_date), 'yyyy-MM-dd') : null)
      setValue("isDismissalSignedByCollaborator", Boolean(internClt?.is_dismissal_signed_by_collaborator) ? internClt?.is_dismissal_signed_by_collaborator : null)
    }
  }, [internClt, setValue])

  const isWithNotice = useWatch({
    control,
    name: 'isWithNotice'
  })

  const { mutateAsync: dismissalDocsInternCltFn } = useMutation({
    mutationFn: dismissalDocsInternClt,
    onSuccess(_data, { body, internCltId }) {
      queryClient.invalidateQueries({ queryKey: 'intern-clts' })
      queryClient.invalidateQueries({ queryKey: 'dismissal-docs-intern-clts' })
      queryClient.invalidateQueries({ queryKey: 'in-notice-intern-clts' })
      queryClient.invalidateQueries({ queryKey: 'dismissal-comunication-intern-clts' })
      queryClient.invalidateQueries({ queryKey: ['intern-clt', internCltId] })
    }
  })

  async function handleDismissalDocsInternClt(values: DismissalDocsInternCltSchema) {
    await promiseMessage(dismissalDocsInternCltFn({
      body: {
        ...values,
        isDocsVerified: values.isDocsVerified === 'yes',
        isLegalRiskVerified: values.isLegalRiskVerified === 'yes',
        isOperationLeaderAware: values.isOperationLeaderAware === 'yes',
        isWithNotice: values.isWithNotice === 'yes',
        lastWorkDate: values.isWithNotice === 'yes' ? values.lastWorkDate : null,
        shouldAdvanceStatus: values.shouldAdvanceStatus ? values.shouldAdvanceStatus === 'yes' : false
      },
      internCltId: internClt.id
    }), 'Documentação enviada!')
  }


  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleDismissalDocsInternClt)}
    >
      <Heading letterSpacing="tight" size='sm'>Demissão documentação</Heading>
      <FormControl isInvalid={!!errors.isDocsVerified} mt={3}>
        <FormLabel>
          Documentação verificada?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isDocsVerified"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.isLegalRiskVerified} mt={3}>
        <FormLabel>
          Risco jurídico verificado?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isLegalRiskVerified"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl mt={3} isInvalid={!!errors.signedDocs}>
        <FormLabel fontSize='sm'>
          Documentação assinada
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          control={control}
          name="signedDocs"
          render={({ field }) => {
            return (
              <CheckboxGroup
                value={field.value}
                onChange={field.onChange}
              >
                <VStack
                  spacing={3}
                  p={2}
                  border="1px solid"
                  borderColor="gray.200"
                  rounded="md"
                  align="left"
                >
                  {signedDocsChecklist.map(check => {
                    return (
                      <Checkbox key={check.name} value={check.name}>
                        <Text fontSize="sm">
                          {check.name}
                        </Text>
                      </Checkbox>
                    )
                  })}
                </VStack>

              </CheckboxGroup>
            )
          }}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.isOperationLeaderAware} mt={3}>
        <FormLabel>
          Líder da operação ciente para realizar a demissão?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isOperationLeaderAware"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.isWithNotice} mt={3}>
        <FormLabel>
          Funcionário irá cumprir aviso prévio?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isWithNotice"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      {isWithNotice === 'yes' && (
        <FormControl isInvalid={!!errors?.lastWorkDate}>
          <FormLabel fontSize="sm">
            Data último dia de trabalho
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('lastWorkDate')}
            name="lastWorkDate"
            type="date"
            rounded="md"
            size='sm'
          />
        </FormControl>
      )}
      <FormControl isInvalid={!!errors.isDismissalSignedByCollaborator} mt={3}>
        <FormLabel>
          Assinatura do aviso de demissão pelo colaborador?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isDismissalSignedByCollaborator"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="Sim">Sim</Radio>
                <Radio size='sm' rounded='md' value="Não">Não</Radio>
                <Radio size='sm' rounded='md' value="N/A">N/A</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.shouldAdvanceStatus} mt={3}>
        <FormLabel fontSize="sm">
          Passar para o próximo status?
        </FormLabel>
        <Select
          {...register('shouldAdvanceStatus')}
          name="shouldAdvanceStatus"
          placeholder="Selecione..."
          size="sm"
          rounded="md"
        >
          <option value='yes'>Sim</option>
          <option value='no'>Não</option>
        </Select>
      </FormControl>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
