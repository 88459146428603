import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye, FiTrash2 } from 'react-icons/fi'
import { useState, useEffect } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { BusinessBudgetProps } from '../../../utils/RequestFunctions/BusinessBudget/requestBusinessBudgetFunctions'
import { useAuth } from '../../../hooks/auth/useAuth'
import { GenerateExcelReportButton } from 'components/Buttons/GenerateExcelReportButton'
import { CitiesProps } from 'services/getFunctions/city/getCity'
import { useToastify } from 'hooks/toastify/useToastify'
import { useBusinessBudgetFunctions } from 'hooks/businessBudget/useBusinessBudgetFunctions'
import { useQueryClient } from 'react-query'
import { format, getDate, getDaysInMonth, isSameMonth, isSameYear, parseISO } from 'date-fns'


interface BusinessBudgetTableProps {
  businessBudgets: BusinessBudgetProps[]
  cities: CitiesProps[]
}

const cityOptions = [
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'routeNickname', value: 'Apelido da Rota', checked: false },
  { key: 'monthlyPrice', value: 'Preço Mensal', checked: false },
  { key: 'vehicle', value: 'Veículo', checked: false },
  {
    key: 'monthlyKmFranshising',
    value: 'Franquia Mensal de KM',
    checked: false,
  },
  {
    key: 'weekTimeFranchisingFiltered',
    value: 'Franquia de Tempo Semanal',
    checked: false,
  },
  {
    key: 'sourceHub',
    value: 'Hub Origem',
    checked: false,
  },
  { key: 'createdBy', value: 'Criado Por', checked: false },
  { key: 'situation', value: 'Situação', checked: false },
]

const cityOrderOptions = [{ key: 'customer', value: 'Cliente' }]

const headers = [
  { label: "CLIENTE", key: "customer" },
  { label: "APELIDO DA ROTA", key: "route_nickname" },
  { label: "PREÇO MENSAL", key: "monthly_price" },
  { label: "VEÍCULO", key: "vehicle" },
  { label: 'CAIXA TÉRMICA', key: 'caixa_termica' },
  { label: 'EMBALAGEM SECUNDÁRIA', key: 'embalagem_secundaria' },
  { label: 'GELO SECO', key: 'gelo_seco' },
  { label: 'GELOX', key: 'gelox' },
  { label: 'ISOPOR 3L', key: 'isopor3l' },
  { label: 'ISOPOR 7L', key: 'isopor7l' },
  { label: 'TERCIÁRIA 3L', key: 'terciaria3l' },
  { label: 'TERCIÁRIA 8L', key: 'terciaria8l' },
  { label: "FRANQUIA MENSAL DE KM", key: "monthly_km_franchising" },
  { label: "FRANQUIA DE TEMPO SEMANAL", key: "week_time_franchising" },
  { label: "PREÇO DO KM ADICIONAL", key: 'km_extra_price' },
  { label: "PREÇO DA HE", key: 'extra_hour_price' },
  { label: "HUB ORIGEM", key: "source_hub" },
  { label: "HUB DESTINO", key: "destination_hub" },
  { label: "CIDADE(S) DE ORIGEM", key: "source_cities" },
  { label: "CIDADE(S) DE DESTINO", key: "destination_cities" },
  { label: "SITUAÇÃO", key: "situation" },
  { label: "MOTORISTA EXECUTOR", key: "drivers" },
  { label: "COLETADOR RESPONSÁVEL", key: "sourceCollectorIDBusinessBudget.trading_name" },
  { label: "DATA INICIAL DA ROTA", key: "start_date" },
  { label: "DATA FINAL DA ROTA", key: "end_date" },
  { label: "PREÇO MENSAL (PROPORCIONAL)", key: "proportionalPrice" }
];

const daysOfWeek = {
  0: 'Segunda-feira',
  1: 'Terça-feira',
  2: 'Quarta-feira',
  3: 'Quinta-feira',
  4: 'Sexta-feira',
  5: 'Sábado',
  6: 'Domingo',
}


function formatValuesToReport(values: BusinessBudgetProps[], cities: CitiesProps[]) {
  return values?.filter(value => value.situation === 'ATIVO')?.map((value) => {

    let proportionalPrice = value.monthly_price

    const isStartDateInCurrentMonth = isSameMonth(
      new Date(value.start_date),
      new Date()
    )

    const isStartDateInCurrentYear = isSameYear(
      new Date(value.start_date),
      new Date()
    )

    if (isStartDateInCurrentMonth && isStartDateInCurrentYear) {
      const currentMonthDaysCount = getDaysInMonth(new Date())

      const startDateDayInMonth = getDate(new Date(value.start_date))

      proportionalPrice = (value.monthly_price / currentMonthDaysCount) * (currentMonthDaysCount - (startDateDayInMonth - 1))
    }
    const driversBudget = value.drivers ? Object.entries(value.drivers).map(([name, details]) => {
      const valueInReais = details.value_in_cents ? (details.value_in_cents / 100).toFixed(2) : '-'
      const attendanceDays = details.attendance_days.map((attendanceDay) => daysOfWeek[attendanceDay]).join(', ')

      return `Motorista: ${name !== 'undefined' ? name : '-'}\n` +
        `Dias de atendimento: ${attendanceDays}\n` +
        `Horário Inicial: ${format(parseISO(details.start_hour), 'HH:mm')}\n` +
        `Horário Final: ${format(parseISO(details.end_hour), 'HH:mm')}\n` +
        `Valor contratado mensal/diario: R$${valueInReais}\n`;

    }).join('\n ') : '-'


    return {
      ...value,
      customer: value.customerIDBusinessBudget.trading_firstname,
      source_hub: value.sourceHubIDBusinessBudget.name,
      destination_hub: value.destinationHubIDBusinessBudget.name,
      start_date: format(new Date(value.start_date), 'dd/MM/yyyy'),
      end_date: format(new Date(value.end_date), 'dd/MM/yyyy'),
      source_cities: cities
        ?.filter((city) => value.source_cities.includes(city.id))
        ?.map((city) => city.name)
        .join(', '),
      destination_cities: cities
        ?.filter((city) => value.destination_cities.includes(city.id))
        ?.map((city) => city.name)
        .join(', '),
      km_extra_price: value.km_extra_price.toString(),
      extra_hour_price: value.extra_hour_price.toString(),
      proportionalPrice: proportionalPrice.toFixed(2),
      drivers: driversBudget
    }
  })
}


export function BusinessBudgetTable({
  businessBudgets,
  cities
}: BusinessBudgetTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [searchedBusinessBudgets, setSearchedBusinessBudgets] = useState<
    BusinessBudgetProps[]
  >([])
  const [slicedBusinessBudgets, setSlicedBusinessBudgets] = useState<
    BusinessBudgetProps[]
  >([])

  const [customerFiltered, setCustomerFiltered] = useState('')
  const [routeNicknameFiltered, setRouteNicknameFiltered] = useState('')
  const [monthlyPriceFiltered, setMonthlyPriceFiltered] = useState('')
  const [vehicleFiltered, setVehicleFiltered] = useState('')
  const [monthlyKmFranchisingFiltered, setMonthlyKmFranchisingFiltered] =
    useState('')
  const [weekTimeFranchisingFiltered, setWeekTimeFranchisingFiltered] =
    useState('')
  const [situationFiltered, setSituationFiltered] = useState('')
  const [sourceHubsFiltered, setSourceHubsFiltered] = useState('')
  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { userLogged } = useAuth()

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedBusinessBudgets.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(cityOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(cityOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      if (userLogged !== null) {
        if (userLogged.user_type === 'CLIENTE') {
          const budgetsPerPage = businessBudgets
            .filter((budget) => budget.customer_id === userLogged.customer_id)
          setSlicedBusinessBudgets(budgetsPerPage)
        } else {
          setSlicedBusinessBudgets(businessBudgets)
        }
      }
    }

    run()
  }, [itemLimit, currentPage, offset, businessBudgets, userLogged])

  useEffect(() => {
    function run() {
      const newSlicedBusinessBudgets = slicedBusinessBudgets?.filter(
        (budget) => {
          const customerFilter = searchBoxFilter(
            budget.customerIDBusinessBudget.trading_firstname,
            customerFiltered,
          )
          const monthlyPriceFilter = searchBoxFilter(
            String(budget.monthly_price),
            monthlyPriceFiltered,
          )
          const MonthlyKmFranchisingFilter = searchBoxFilter(
            String(budget.monthly_km_franchising),
            monthlyKmFranchisingFiltered,
          )
          const weekTimeFranchisingFilter = searchBoxFilter(
            String(budget.week_time_franchising),
            weekTimeFranchisingFiltered,
          )

          const situationFilter = situationFiltered
            ? budget.situation.toLowerCase().startsWith(
              situationFiltered.toLowerCase()
            )
            : budget.situation

          const vehicleFilter = searchBoxFilter(budget.vehicle, vehicleFiltered)
          const routeNicknameFilter = searchBoxFilter(
            budget.route_nickname,
            routeNicknameFiltered,
          )
          const sourceHubFilter = searchBoxFilter(
            budget.sourceHubIDBusinessBudget.name,
            sourceHubsFiltered,
          )

          if (
            customerFiltered === '' &&
            monthlyKmFranchisingFiltered === '' &&
            weekTimeFranchisingFiltered === '' &&
            situationFiltered === '' &&
            vehicleFiltered === '' &&
            routeNicknameFiltered === '' &&
            monthlyPriceFiltered === '' &&
            sourceHubsFiltered === ''
          )
            return budget

          return (
            customerFilter &&
            MonthlyKmFranchisingFilter &&
            weekTimeFranchisingFilter &&
            situationFilter &&
            vehicleFilter &&
            routeNicknameFilter &&
            monthlyPriceFilter &&
            sourceHubFilter
          )
        },
      )

      if (orderOptionSelected.length > 0) {
        const slicedBusinessBudgetsByOrder = newSlicedBusinessBudgets.sort(
          (a, b) =>
            (orderOptionSelected.includes('customer') &&
              a.customerIDBusinessBudget.trading_firstname.localeCompare(
                b.customerIDBusinessBudget.trading_firstname,
              )) ||
            0,
        )

        return setSearchedBusinessBudgets(slicedBusinessBudgetsByOrder)
      }

      setSearchedBusinessBudgets(newSlicedBusinessBudgets)
    }
    run()
  }, [
    customerFiltered,
    monthlyKmFranchisingFiltered,
    weekTimeFranchisingFiltered,
    situationFiltered,
    vehicleFiltered,
    routeNicknameFiltered,
    monthlyPriceFiltered,
    sourceHubsFiltered,
    slicedBusinessBudgets,
    orderOptionSelected,
  ])

  const handleChangeSetter = (key: string, value: string) => {
    switch (key) {
      case 'customer':
        return setCustomerFiltered(value)
      case 'routeNickname':
        return setRouteNicknameFiltered(value)
      case 'monthlyPrice':
        return setMonthlyPriceFiltered(value)
      case 'vehicle':
        return setVehicleFiltered(value)
      case 'monthlyKmFranshising':
        return setMonthlyKmFranchisingFiltered(value)
      case 'weekTimeFranchisingFiltered':
        return setWeekTimeFranchisingFiltered(value)
      case 'situation':
        return setSituationFiltered(value)
      case 'sourceHub':
        return setSourceHubsFiltered(value)
    }
  }

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  const csvReportProps = {
    data: formatValuesToReport(businessBudgets, cities) ?? [],
    headers,
    filename: `relatório-orçamento-business.csv`,
  };

  const {
    deleteBusinessBudget: {
      mutateAsync: deleteBusinessBudget
    }
  } = useBusinessBudgetFunctions()

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  async function handleDeleteBusinessBudget(businessBudgetId: string) {
    await promiseMessage(
      deleteBusinessBudget(businessBudgetId, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['businessBudgets'])
        }
      }),
      'Orçamento business excluído com sucesso!'
    )
  }

  return (
    <>
      {slicedBusinessBudgets.length <= 0 ? (
        <Alert
          status="info"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Oops!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Não há dados para mostrar aqui!
          </AlertDescription>
        </Alert>
      ) : (
        <>
          <TableFilterButton />
          <Stack mt="4" direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Flex key={option.key}>
                  <SearchBox
                    placeholder={`Buscar ${option.value}...`}
                    handleSearch={(e) =>
                      handleChangeSetter(option.key, e.target.value)
                    }
                  />
                </Flex>
              ))}
          </Stack>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  {cityOptions.map((option) => (
                    <Th key={option.key} fontSize="12" color="blue.900">
                      {option.value.toUpperCase()}
                    </Th>
                  ))}
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchedBusinessBudgets
                  .slice(offset, offset + Number(itemLimit))
                  .map((budget, index) => (
                    <Tr key={budget.id}>
                      <Td fontSize="12">
                        {budget.customerIDBusinessBudget.trading_firstname}
                      </Td>
                      <Td fontSize="12">{budget.route_nickname}</Td>
                      <Td fontSize="12">{budget.monthly_price}</Td>
                      <Td fontSize="12">{budget.vehicle}</Td>
                      <Td fontSize="12">{budget.monthly_km_franchising}</Td>
                      <Td fontSize="12">{budget.week_time_franchising}</Td>
                      <Td fontSize="12">{budget.sourceHubIDBusinessBudget.name}</Td>
                      <Td fontSize="12">{budget?.createdBy?.firstname} {budget?.createdBy?.lastname}</Td>
                      <Td fontSize="12">{budget.situation}</Td>
                      <Td isNumeric>
                        {userLogged !== null && (
                          <>
                            {userLogged.permissions.includes(
                              'view-business-budget',
                            ) && (
                                <Link
                                  to={`/orcamento-business/visualizar/${budget.id}`}
                                >
                                  <Icon
                                    cursor="pointer"
                                    as={FiEye}
                                    fontSize="20"
                                  />
                                </Link>
                              )}
                            {userLogged.permissions.includes(
                              'edit-business-budget',
                            ) && (
                                <Link
                                  to={`/orcamento-business/editar/${budget.id}`}
                                >
                                  <Icon
                                    cursor="pointer"
                                    as={FiEdit3}
                                    fontSize="20"
                                    ml="4"
                                    mt={['2', '2', '0']}
                                  />
                                </Link>
                              )}
                            {userLogged.permissions.includes(
                              'remove-business-budget',
                            ) && (
                                <Icon
                                  cursor="pointer"
                                  as={FiTrash2}
                                  fontSize="20"
                                  ml="4"
                                  mt={['2', '2', '0']}
                                  onClick={() =>
                                    handleDeleteBusinessBudget(budget.id)
                                  }
                                />
                              )}
                          </>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Box>
          <GenerateExcelReportButton csvReportProps={csvReportProps} />
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}
