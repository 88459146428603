import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface SendAsoAttachmentParams {
  attachmentId: string
  asoId: string
}

interface SendAsoAttachmentBody {
  dueDate: string
}

interface SendAsoAttachmentProps {
  params: SendAsoAttachmentParams
  body: SendAsoAttachmentBody
}


export async function sendAsoAttachment({
  params,
  body
}: SendAsoAttachmentProps) {
  try {
    await api.patch(`/asos/${params.asoId}/attachments/${params.attachmentId}`, {
      dueDate: body.dueDate
    })

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
