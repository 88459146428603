import { useEffect, useState } from "react";
import { Box, Button, Flex, Heading, IconButton, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { useAuth } from "hooks/auth/useAuth";
import { FaExchangeAlt, FaFileDownload, FaFileExport, FaPlus } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { useSearchParams } from "hooks/useSearchParams";
import { useQuery } from "react-query";
import { usePagination } from "@ajna/pagination";
import { Pagination } from "components/Pagination/Pagination";
import { getInternClts } from "api/internClts/getInternClts";
import { InternClt, InternCltStatus } from "api/internClts/_types/internClt";
import { InternCltTableRow } from "./components/InternCltTableRow";
import { InternCltsTableFilters } from "./components/InternCltsTableFilters";
import { format } from "date-fns";
import { internCltBenefitMap, internCltJourneyTypeMap, internCltStatusMap } from "utils/internCltMappers";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";

const headers = [
  { label: 'COLABORADOR', key: 'name' },
  { label: 'CPF', key: 'cpf' },
  { label: 'RG', key: 'rg' },
  { label: 'E-MAIL', key: 'email' },
  { label: 'DATA DE NASCIMENTO', key: 'born_date' },
  { label: 'ENDEREÇO', key: 'address_street' },
  { label: 'CIDADE', key: 'address_city' },
  { label: 'ESTADO', key: 'address_state' },
  { label: 'REGIONAL', key: 'regional' },
  { label: 'DATA DE ADMISSÃO', key: 'admission_date' },
  { label: 'SALÁRIO', key: 'salary_in_cents' },
  { label: 'POSSUI GRATIFICAÇÃO DE LIDERANÇA?', key: 'has_leadership_gratification' },
  { label: 'POSSUI COMISSÃO?', key: 'has_commission' },
  { label: 'TIPO DE JORNADA', key: 'journey_type' },
  { label: 'INTERVALO DE JORNADA', key: 'interval' },
  { label: 'BENEFÍCIOS', key: 'benefits' },
  { label: 'STATUS', key: 'status' },



]

function formatValuesToReport(values: InternClt[]) {
  return values?.map((internClt) => ({
    ...internClt,

    regional: internClt.regional === 'other' ? 'Outra' : internClt.regional.toUpperCase(),
    born_date: internClt.born_date
      ? format(new Date(internClt.born_date), 'dd/MM/yyyy')
      : '-',
    address_street: `
      ${internClt.address_street}, ${' '}
      ${internClt.address_number}, ${internClt.address_neighborhood} - ${internClt.address_cep}`,
    admission_date: internClt.admission_date
      ? format(new Date(internClt.admission_date), 'dd/MM/yyyy')
      : '-',
    salary_in_cents: internClt.salary_in_cents / 100,
    has_leadership_gratification: internClt.has_leadership_gratification ? 'Sim' : 'Não',
    has_commission: internClt.has_commission ? 'Sim' : 'Não',
    journey_type: internCltJourneyTypeMap[internClt.journey_type],
    status: internCltStatusMap[internClt?.status],
    interval: `${format(new Date(internClt.work_interval_start_hour), 'HH:mm')} até ${format(new Date(internClt.work_interval_end_hour), 'HH:mm')}`,
    benefits: internClt.benefits ? internClt.benefits?.map(benefit => internCltBenefitMap[benefit]).join(', ') : '-'
  }))
}


export function InternClts() {
  const { userLogged } = useAuth()
  const history = useHistory()
  const searchParams = useSearchParams()

  const userCanViewInternClts = userLogged?.permissions.includes('view-intern-clts')
  const userCanCreateInternClt = userLogged?.permissions.includes('create-intern-clt')

  useEffect(() => {
    if (!userCanViewInternClts) history.push('/')
  }, [userCanViewInternClts, history])

  const page = searchParams.get('page') ?? 1
  const name = searchParams.get('name')
  const status = searchParams.get('status') ?? 'all'


  const { data: internCltsData } = useQuery({
    queryKey: ['intern-clts',
      page,
      name,
      status,
    ],
    queryFn: () => getInternClts({
      currentPage: Number(page),
      pageSize: 10,
      name,
      status: status as InternCltStatus | 'all',
    }),
    keepPreviousData: true
  })

  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }

  const {
    data: internCltsDataReportResult,
    isLoading: isLoadingInternCltsDataReportResult
  } = useQuery({
    queryKey: ['intern-clts-report',
      page,
      name,
      status,
      internCltsData?.meta?.count
    ],
    queryFn: () => getInternClts({
      currentPage: 1,
      pageSize: internCltsData?.meta?.count,
      name,
      status: ["inactive", "hiring", "pontomais-registering", "sending-bank-details", "active", "dismissal-docs", "in-notice", "dismissal-comunication", "dismissal-payment", "dismissal-financial", "dismissed"].includes(status) ? status as InternCltStatus : 'all',
    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: internCltsData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: internCltsData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const csvReportProps = {
    data: internCltsDataReportResult ? formatValuesToReport(internCltsDataReportResult?.internClts) : [],
    headers,
    filename: `internos-clt.csv`,
  }

  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Internos CLT</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar kanban"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => history.push('/internos-clt/kanban')}
          />
          {userCanCreateInternClt && (
            <IconButton
              as={Link}
              aria-label="Criar Interno CLT"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              to="/interno-clt/criar"
            />
          )}
        </Flex>
      </Flex>

      <InternCltsTableFilters />

      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Nome</Th>
              <Th color="white">Cargo</Th>
              <Th color="white">Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {internCltsData?.internClts?.map((internClt) => {
              return (
                <InternCltTableRow
                  key={internClt.id}
                  internClt={internClt}
                />
              )
            })}

          </Tbody>
        </Table>
      </TableContainer>
      {!internCltsDataReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingInternCltsDataReportResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>

  )
}
