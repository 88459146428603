import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { api } from 'services/api'
import { RequestError } from 'utils/errors/RequestErrors'
import {
  CreateBoardServiceInputProps,
  createBoardServiceReqFunction,
  finishBoardServiceReqFunction,
  FinishBoardServiceReqProps,
  updateBoardServiceReqFunction,
  UpdateBoardServiceReqProps,
  validateBoardServiceReqFunction,
  ValidateBoardServiceReqProps,
} from '../../../utils/RequestFunctions/Service/Board/requestBoardServiceFunctions'
import { useSwal } from '../../swal/useSwal'

interface SetUnsuccessBoardServiceReqProps {
  id: string
  formData: FormData
}

interface ValidateUnsuccessBoardServiceInputs {
  id: string
  is_validated: 'yes' | 'no'
}

async function setUnsuccessBoardServiceReqFunction({
  id,
  formData
}: SetUnsuccessBoardServiceReqProps) {
  try {
    await api.put(`/board-service/unsuccess/${id}`, formData)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

async function validateUnsuccessBoardServiceReqFunction({
  id,
  is_validated
}: ValidateUnsuccessBoardServiceInputs) {
  try {
    await api.put(`/board-service/validate/unsuccess/${id}`, {
      is_validated
    })
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

export const useBoardServiceFunctions = () => {
  const queryClient = useQueryClient()
  const { successMessage, errorMessage } = useSwal()

  const createBoardService = useMutation(
    'createBoardService',
    (createBoardServiceInput: CreateBoardServiceInputProps) =>
      createBoardServiceReqFunction(createBoardServiceInput),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['services'])
        queryClient.invalidateQueries(['service'])
        successMessage('O embarque foi iniciado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const finishBoardService = useMutation(
    'finishBoardService',
    ({ serviceID, finishBoardServiceInput }: FinishBoardServiceReqProps) =>
      finishBoardServiceReqFunction({ serviceID, finishBoardServiceInput }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['services'])
        queryClient.invalidateQueries(['service'])
        successMessage('O embarque foi finalizado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const validateBoardService = useMutation(
    'validateBoardService',
    ({
      serviceID,
      serviceModal,
      validateBoardServiceInput,
    }: ValidateBoardServiceReqProps) =>
      validateBoardServiceReqFunction({
        serviceID,
        serviceModal,
        validateBoardServiceInput,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['services'])
        queryClient.invalidateQueries(['service'])
        successMessage('O embarque foi validado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const updateBoardService = useMutation(
    'updateBoardService',
    ({ serviceID, updateBoardServiceInput }: UpdateBoardServiceReqProps) =>
      updateBoardServiceReqFunction({ serviceID, updateBoardServiceInput }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['services'])
        queryClient.invalidateQueries(['service'])
        successMessage('O embarque foi atualizado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const setUnsuccessBoardService = useMutation('setUnsuccessBoardService', setUnsuccessBoardServiceReqFunction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['services'])
      queryClient.invalidateQueries(['service'])
      successMessage('O embarque foi colocado como sem sucesso!')
    },

    onError(error: RequestError) {
      errorMessage(error.message)
    }
  })

  const validateUnsuccessBoardService = useMutation('validateUnsuccessBoardService', validateUnsuccessBoardServiceReqFunction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['services'])
    },

    onError(error: RequestError) {
      errorMessage(error.message)
    }
  })

  return {
    createBoardService,
    finishBoardService,
    validateBoardService,
    updateBoardService,
    setUnsuccessBoardService,
    validateUnsuccessBoardService
  }
}
