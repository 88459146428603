import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CompleteFinancialAuditBody {
  realizedDate: string
  reportAttachmentId: string
  deviationFoundValueInCents: number
  nonCompliance?: string
  status: string
}

interface CompleteFinancialAuditParams {
  body: CompleteFinancialAuditBody
  auditId: string
}

export async function completeFinancialAudit({
  body,
  auditId
}: CompleteFinancialAuditParams) {
  try {
    await api.put(`/audits/${auditId}/financial`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
