import { Flex } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { useFormContext } from "react-hook-form";

export interface MaterialFormInputs {
  material_name: string
}

export function MaterialForm() {

  const {
    register,
    formState: {
      errors
    }
  } = useFormContext<MaterialFormInputs>()

  return (
    <Flex align="center" gap={6}>
      <Input
        {...register("material_name")}
        name="material_name"
        placeholder="Digite o nome do material..."
        label="Nome"
        error={errors.material_name}
        required
      />
    </Flex>
  )
}
