import { ModalBody, ModalCloseButton, ModalContent, ModalHeader } from "@chakra-ui/react";
import { getAso } from "api/asos/getAso";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { editAsoField } from "api/asos/editAsoField";
import { GetAsosResponse } from "api/asos/getAsos";
import { getCltDrivers } from "api/cltDrivers/getCltDrivers";
import { captalize } from "utils/captalize";
import { getAggregates } from "hooks/aggregate/useAggregates";
import { getRegionals } from "api/regionals/getRegionals";
import { getInternClts } from "api/internClts/getInternClts";
import { EditableFieldForm } from "./EditableFieldForm";


interface EditAsoProps {
  onCloseModal: () => void
  asoId: string
}

export function EditAso({ onCloseModal, asoId }: EditAsoProps) {

  const { data: asoData } = useQuery({
    queryKey: ['aso', asoId],
    queryFn: () => getAso({ asoId })
  })

  async function regionalPromiseOptions(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getRegionals({ currentPage: 1, pageSize: 10 })

    return response.regionals.filter(regional => regional.code.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())).map(regional => {
      return { value: regional.id, label: regional.code.toUpperCase() }
    })
  }

  async function llmCollaboratorsPromiseOptions(inputValue: string): Promise<Array<{ value: string, label: string, email: string }>> {
    const response = await getInternClts({ currentPage: 1, pageSize: 5, name: inputValue })

    return response.internClts.map(internClt => {
      return {
        value: internClt.name,
        label: internClt.name,
        email: internClt.email
      }
    })
  }

  async function cltDriversCollaboratorsPromiseOptions(inputValue: string): Promise<Array<{ value: string, label: string, email: string }>> {
    const response = await getCltDrivers({ currentPage: 1, pageSize: 5, search: inputValue })

    return response.cltDrivers.map(cltDriver => {
      return {
        value: captalize(`${cltDriver.driver.firstname} ${cltDriver.driver.lastname}`),
        label: captalize(`${cltDriver.driver.firstname} ${cltDriver.driver.lastname}`),
        email: cltDriver.driver.email.toLocaleLowerCase()
      }
    })
  }

  async function aggregatesCollaboratorsPromiseOptions(inputValue: string): Promise<Array<{ value: string, label: string, email: string }>> {
    const response = await getAggregates({ status: 'active' })

    return response.filter(aggregate => `${aggregate.driver.firstname} ${aggregate.driver.lastname}`.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())).map(aggregate => {
      return {
        value: captalize(`${aggregate.driver.firstname} ${aggregate.driver.lastname}`),
        label: captalize(`${aggregate.driver.firstname} ${aggregate.driver.lastname}`),
        email: aggregate.driver.email.toLocaleLowerCase()
      }
    })
  }


  const queryClient = useQueryClient()


  const { mutateAsync: editAsoFieldFn, isLoading } = useMutation({
    mutationFn: editAsoField,
    onSuccess: (_data, { asoId, fieldName, body }) => {
      queryClient.invalidateQueries({ queryKey: ['aso', asoId] })
      queryClient.invalidateQueries({ queryKey: 'pending-asos' })

      const cachedAsos = queryClient.getQueriesData<GetAsosResponse>({
        queryKey: ['asos']
      })

      cachedAsos.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          asos: cachedValue.asos.map(aso => {
            if (aso.id === asoId) {
              aso[fieldName] = body.value

              return { ...aso }
            }

            return aso
          })
        })
      })
    }
  })


  async function handleEditAsoFieldFn({
    fieldName,
    value
  }: {
    fieldName: string
    value: string
  }) {
    await editAsoFieldFn({
      body: {
        value
      },
      asoId,
      fieldName
    })
  }


  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Editar Aso</ModalHeader>
      <ModalCloseButton />

      <ModalBody>

        <EditableFieldForm
          label="Regional"
          fieldName="regional_id"
          initialValue={asoData?.aso?.regional?.code}
          handleEditAsoFieldFn={handleEditAsoFieldFn}
          type="async-select"
          loadAsyncOptions={regionalPromiseOptions}
          isLoading={isLoading}
        />

        <EditableFieldForm
          label="Tipo de colaborador"
          fieldName="collaborator_type"
          initialValue={asoData?.aso?.collaborator_type}
          handleEditAsoFieldFn={handleEditAsoFieldFn}
          type="select"
          options={[
            { value: "aggregate-driver", label: "Motorista Agregado" },
            { value: "clt-driver", label: "Motorista CLT" },
            { value: "llm", label: "Funcionário LLM" },
            { value: "motorcyclist", label: "Motociclista" },
          ]}
          isLoading={isLoading}
        />
        {asoData && asoData?.aso?.collaborator_type === 'llm' && (
          <EditableFieldForm
            label="Colaborador"
            fieldName="collaborator_name"
            initialValue={asoData?.aso?.collaborator_name}
            handleEditAsoFieldFn={handleEditAsoFieldFn}
            type="async-select"
            loadAsyncOptions={llmCollaboratorsPromiseOptions}
            isLoading={isLoading}
          />

        )}
        {asoData && asoData?.aso?.collaborator_type  === 'aggregate-driver' && (
          <EditableFieldForm
            label="Colaborador"
            fieldName="collaborator_name"
            initialValue={asoData?.aso?.collaborator_name}
            handleEditAsoFieldFn={handleEditAsoFieldFn}
            type="async-select"
            loadAsyncOptions={aggregatesCollaboratorsPromiseOptions}
            isLoading={isLoading}
          />
        )}
        {asoData && asoData?.aso?.collaborator_type  === 'clt-driver' && (
          <EditableFieldForm
            label="Colaborador"
            fieldName="collaborator_name"
            initialValue={asoData?.aso?.collaborator_name}
            handleEditAsoFieldFn={handleEditAsoFieldFn}
            type="async-select"
            loadAsyncOptions={cltDriversCollaboratorsPromiseOptions}
            isLoading={isLoading}
          />
        )}

        <EditableFieldForm
          label="Email"
          fieldName="collaborator_email"
          initialValue={asoData?.aso?.collaborator_email}
          handleEditAsoFieldFn={handleEditAsoFieldFn}
          type="text"
          isLoading={isLoading}
        />
        <EditableFieldForm
          label="Status"
          fieldName="status"
          initialValue={asoData?.aso?.status}
          handleEditAsoFieldFn={handleEditAsoFieldFn}
          type="select"
          options={[
            { value: "active", label: "Ativo" },
            { value: "pending", label: "Pendente" },
          ]}
          isLoading={isLoading}
        />
        <EditableFieldForm
          label="Tipo"
          fieldName="type"
          initialValue={asoData?.aso?.type}
          handleEditAsoFieldFn={handleEditAsoFieldFn}
          type="select"
          options={[
            { value: "admission", label: "Admissional" },
            { value: "periodical", label: "Periódico" },
            { value: "dismissal", label: "Demissional" },
          ]}
          isLoading={isLoading}
        />
        <EditableFieldForm
          label="Data de Vencimento"
          fieldName="due_date"
          initialValue={asoData?.aso?.due_date}
          handleEditAsoFieldFn={handleEditAsoFieldFn}
          type="date"
          isLoading={isLoading}
        />

        <EditableFieldForm
          label="Data de Realização"
          fieldName="realized_at"
          initialValue={asoData?.aso?.realized_at}
          handleEditAsoFieldFn={handleEditAsoFieldFn}
          type="date"
          isLoading={isLoading}
        />
      </ModalBody>
    </ModalContent>
  )
}
