import { useQuery, UseQueryOptions } from "react-query";
import { api } from "../../services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { Address } from "./dtos/Address";

interface AddressesQueryParams {
  customer_id?: string
  cities?: string[]
  situation?: 'ATIVO' | 'INATIVO'
}

type QueryOptions = UseQueryOptions<Address[], unknown, Address[], "addresses">

interface UseAddressesOptions {
  queryParams?: AddressesQueryParams,
  queryOptions?: QueryOptions
}

export function useAddresses(options?: UseAddressesOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getAddress(queryParams?: AddressesQueryParams) {

    try {
      const { data } = await api.get(`/address/`, {
        params: queryParams && {
          ...queryParams,
          cities: queryParams.cities?.join(",")
        }
      })

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response

        throw new RequestError(message, status)
      } else {
        throw new Error("Erro inesperado!")
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery("addresses", () => getAddress(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => await onRequestError(error)
  })
}
