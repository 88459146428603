import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface ApproveInvoiceBody {
  attachments?: {
    _id: string
    type: "billet" | "receipt"
  }[]
  isWithoutReturn: boolean
}

interface ApproveInvoiceProps {
  invoiceId: string
  body: ApproveInvoiceBody
}

export async function approveInvoice({
  invoiceId,
  body
}: ApproveInvoiceProps) {
  try {
    await api.patch(`invoices/${invoiceId}/approve-invoice`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
