import { Box, Button, Divider, Flex, IconButton, Popover, PopoverArrow, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Spinner, TabPanel } from "@chakra-ui/react";
import { useExternalComunications } from "hooks/externalComunications/useExternalComunications";
import { useExternalComunicationsFunctions } from "hooks/externalComunications/useExternalComunicationsFunctions";
import { Fragment, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { ExternalComunicationsSidebar } from "./ExternalComunicationsSidebar";
import { ExternalComunicationsSubjectButton } from "./ExternalComunicationsSubjectButton";
import { CreateExternalComunicationForm, CreateExternalComunicationFormInputs } from "./CreateExternalComunicationForm";
import { useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { ExternalComunicationsMessage } from "./ExternalComunicationsMessage";
import { useAuth } from "hooks/auth/useAuth";
import { formatDistance } from "date-fns";
import { ptBR } from "date-fns/locale";
import { ExternalComunicationsChat } from "./ExternalComunicationsChat";
import { ExternalComunicationsChatFormInputs } from "./ExternalComunicationsChat/ExternalComunicationsChatForm";
import { useExternalComunicationsSocket } from "hooks/externalComunications/useExternalComunicationsSocket";

const tabPanelStyle = {
  mt: "4",
  gap: "2",
  p: "1",
  display: "flex",
  flexDirection: "column",
  maxH: "400px",
  overflowY: "auto",
  w: "full",
}

interface ExternalComunicationsContentProps {
  serviceId?: string
  onClose: () => void
}

interface AnswerExternalComunicationFormInputs {
  content: string
}

export function ExternalComunicationsContent({
  serviceId,
  onClose,
}: ExternalComunicationsContentProps) {
  const [selectedExternalComunicationId, setSelectedExternalComunicationId] = useState<string | null>(null)
  const [externalComunicationStatus, setExternalComunicationStatus] = useState<'open' | 'closed'>('open')
  const [answerExternalComunicationInfo, setAnswerExternalComunicationInfo] = useState({
    externalComunicationId: '',
    questionId: ''
  })

  const { userLogged } = useAuth()

  function handleChangeExternalComunicationStatus(status: 'open' | 'closed') {
    setExternalComunicationStatus(status)
  }

  const {
    createExternalComunication,
    answerExternalComunication,
    createExternalComunicationQuestion,
    closeExternalComunication
  } = useExternalComunicationsFunctions()

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()
  const {
    emitCreatedExternalComunicationNotification,
    emitAnsweredExternalComunicationNotification,
    emitAskedExternalComunicationNotification,
    emitClosedExternalComunicationNotification
  } = useExternalComunicationsSocket()

  async function handleCreateExternalComunication(
    values: CreateExternalComunicationFormInputs
  ) {
    await promiseMessage(createExternalComunication.mutateAsync({
      serviceId,
      input: values
    }, {
      onSuccess: (data) => {
        queryClient.invalidateQueries('externalComunications')
        queryClient.invalidateQueries('externalComunicationsMetrics')
        setSelectedExternalComunicationId(data.externalComunication.id)

        emitCreatedExternalComunicationNotification({
          profiles: data.notificationProfiles,
          serviceProtocol: data.externalComunication.service.protocol
        })
      },
    }), 'Comunicação externa criada com sucesso!')

  }

  async function handleAnswerExternalComunication(
    values: AnswerExternalComunicationFormInputs
  ) {
    await answerExternalComunication.mutateAsync({
      externalComunicationId: answerExternalComunicationInfo.externalComunicationId,
      questionId: answerExternalComunicationInfo.questionId,
      input: values
    }, {
      onSuccess: (data) => {
        queryClient.invalidateQueries('externalComunications')

        emitAnsweredExternalComunicationNotification({
          questionOwner: data.questionOwner,
          serviceProtocol: data.externalComunication.service.protocol
        })
      }
    })
  }

  async function handleCreateExternalComunicationQuestion(
    values: ExternalComunicationsChatFormInputs
  ) {
    await createExternalComunicationQuestion.mutateAsync({
      externalComunicationId: selectedExternalComunicationId,
      input: values
    }, {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('externalComunications')

        emitAskedExternalComunicationNotification({
          profiles: data.notificationProfiles,
          serviceProtocol: data.externalComunication.service.protocol
        })
      }
    })
  }

  async function handleCloseExternalComunication() {
    await closeExternalComunication.mutateAsync({
      externalComunicationId: selectedExternalComunicationId
    }, {
      onSuccess: (data) => {
        queryClient.invalidateQueries('externalComunicationsMetrics')
        queryClient.invalidateQueries('externalComunications')

        emitClosedExternalComunicationNotification({
          profiles: data.notificationProfiles,
          serviceProtocol: data.externalComunication.service.protocol
        })
      }
    })
  }

  const {
    data: externalComunicationsData,
    isLoading: isLoadingInitialExternalComunicationsData,
    isFetching: isFetchingExternalComunicationsData,
    fetchNextPage,
    hasNextPage,
  } = useExternalComunications({
    queryParams: {
      status: externalComunicationStatus,
      pageSize: 10,
    },
    queryOptions: {
      keepPreviousData: true,
      getNextPageParam: (lastPage: any, pages) => {
        if (pages.length < pages[0].totalPages) {
          return pages.length + 1
        } else {
          return undefined
        }
      },
    }
  })

  const externalComunicationSelected = externalComunicationsData
    ?.pages
    ?.flatMap((page) => page.externalComunications)
    ?.find((externalComunication) => externalComunication.id === selectedExternalComunicationId)

  const externalComunicationSelectedHasQuestionWithoutAnswer =
    !!externalComunicationSelected?.questions?.find((question) => question.answer === null)

  const isAnswerChatForm = externalComunicationSelectedHasQuestionWithoutAnswer

  const isEnabledToDigitSomethingInChat = isAnswerChatForm
    ? userLogged?.loglife_employee
    : userLogged?.id === externalComunicationSelected?.questions[0]?.askedBy.id

  useEffect(() => {
    if (isAnswerChatForm && externalComunicationSelected) {
      const questionIndex = externalComunicationSelected.questions.length - 1

      setAnswerExternalComunicationInfo({
        externalComunicationId: externalComunicationSelected.id,
        questionId: externalComunicationSelected.questions[questionIndex].id
      })
    }
  }, [isAnswerChatForm, externalComunicationSelected])

  const handleFetchNextPage = () => fetchNextPage()


  return (
    <Flex
      border="1px solid"
      borderColor="gray.50"
      boxShadow="md"
      rounded="md"
      w="full"
    >
      <ExternalComunicationsSidebar.Root>
        <Box
          as="header"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          Assuntos
        </Box>
        <Divider />
        <ExternalComunicationsSidebar.Content
          onChangeExternalComunicationStatus={handleChangeExternalComunicationStatus}
        >
          <TabPanel
            sx={tabPanelStyle}
          >
            {!userLogged?.loglife_employee && (
              <Popover
                placement="top"
              >

                <PopoverTrigger>
                  <IconButton
                    aria-label="Nova comunicação"
                    icon={<FaPlus />}
                    position="absolute"
                    bottom={["20", "20", "12"]}
                    left={["90", "120", "150"]}
                    rounded="lg"
                    colorScheme="blue"
                    size="sm"
                  />
                </PopoverTrigger>
                <PopoverContent
                  boxShadow="md"
                  _focus={{ outline: 'none' }}
                >
                  <PopoverArrow />
                  <PopoverCloseButton
                    _hover={{
                      bgColor: "gray.200"
                    }}
                  />
                  <PopoverHeader
                    fontSize="md"
                    fontWeight="bold"
                    border="0"
                  >
                    Nova comunicação
                  </PopoverHeader>
                  <CreateExternalComunicationForm
                    onCreateExternalComunication={handleCreateExternalComunication}
                  />
                </PopoverContent>
              </Popover>
            )}

            {isLoadingInitialExternalComunicationsData ? (
              <Spinner />
            ) : (
              externalComunicationsData?.pages?.map((page) => {
                return page.externalComunications?.map((externalComunication) => {
                  return (
                    <ExternalComunicationsSubjectButton
                      key={externalComunication.id}
                      serviceProtocol={externalComunication.service.protocol}
                      subject={externalComunication.subject}
                      type="open"
                      onClick={() => setSelectedExternalComunicationId(externalComunication.id)}
                    />
                  )
                })
              })
            )}
            {hasNextPage && (
              <Button
                size="sm"
                variant="ghost"
                fontSize="xs"
                isLoading={isFetchingExternalComunicationsData}
                p="2"
                _focus={{
                  outline: 'none'
                }}
                onClick={handleFetchNextPage}
              >
                Carregar Mais
              </Button>
            )}
          </TabPanel>
          <TabPanel sx={tabPanelStyle}>
            {isLoadingInitialExternalComunicationsData ? (
              <Spinner />
            ) : (
              externalComunicationsData?.pages?.map((page) => {
                return page.externalComunications?.map((externalComunication) => {
                  return (
                    <ExternalComunicationsSubjectButton
                      key={externalComunication.id}
                      serviceProtocol={externalComunication.service.protocol}
                      subject={externalComunication.subject}
                      type="closed"
                      onClick={() => setSelectedExternalComunicationId(externalComunication.id)}
                    />
                  )
                })
              })
            )}

            {hasNextPage && (
              <Button
                size="sm"
                variant="ghost"
                fontSize="xs"
                isLoading={isFetchingExternalComunicationsData}
                p="2"
                _focus={{
                  outline: 'none'
                }}
                onClick={handleFetchNextPage}
              >
                Carregar Mais
              </Button>
            )}
          </TabPanel>
        </ExternalComunicationsSidebar.Content>
      </ExternalComunicationsSidebar.Root>
      <ExternalComunicationsChat.Root>

        <ExternalComunicationsChat.Header
          title={externalComunicationSelected
            ? `${externalComunicationSelected?.service.protocol} - ${externalComunicationSelected?.subject}`
            : '-'
          }
          onClose={onClose}
        />

        <ExternalComunicationsChat.Content>
          {createExternalComunication.isLoading ? (
            <Spinner />
          ) : (
            externalComunicationSelected && externalComunicationSelected?.questions?.map((question) => {
              return (
                <Fragment key={question.id}>
                  <ExternalComunicationsMessage.Root>
                    <ExternalComunicationsMessage.ContentContainer>
                      {userLogged?.id === question.askedBy.id ? (
                        <ExternalComunicationsMessage.ContentHeader
                          alignSelf="flex-end"
                          content={
                            `Você - ${formatDistance(new Date(question.created_at), new Date(), { addSuffix: true, locale: ptBR })}`
                          }
                        />
                      ) : (
                        <ExternalComunicationsMessage.ContentHeader
                          alignSelf="flex-end"
                          content={
                            `${question.askedBy.firstname} ${question.askedBy.lastname} - ${formatDistance(new Date(question.created_at), new Date(), { addSuffix: true, locale: ptBR })}`
                          }
                        />
                      )}
                      <ExternalComunicationsMessage.ContentBody
                        message={question.content}
                      />
                    </ExternalComunicationsMessage.ContentContainer>
                    <ExternalComunicationsMessage.Avatar
                      name={`${question.askedBy.firstname} ${question.askedBy.lastname}`}
                    />
                  </ExternalComunicationsMessage.Root>
                  {question.answer && (
                    <ExternalComunicationsMessage.Root>
                      <ExternalComunicationsMessage.Avatar
                        name={`${question.answer.answeredBy.firstname} ${question.answer.answeredBy.lastname}`}
                      />
                      <ExternalComunicationsMessage.ContentContainer>
                        {userLogged?.id === question.answer.answeredBy.id ? (
                          <ExternalComunicationsMessage.ContentHeader
                            content={
                              `Você - ${formatDistance(new Date(question.answer.created_at), new Date(), { addSuffix: true, locale: ptBR })}`
                            }
                          />
                        ) : (
                          <ExternalComunicationsMessage.ContentHeader
                            content={
                              `${question.answer.answeredBy.firstname} ${question.answer.answeredBy.lastname} - ${formatDistance(new Date(question.answer.created_at), new Date(), { addSuffix: true, locale: ptBR })}`
                            }
                          />
                        )}
                        <ExternalComunicationsMessage.ContentBody
                          bgColor="white"
                          alignSelf="flex-start"
                          borderTopLeftRadius="0"
                          message={question.answer.content}
                        />
                      </ExternalComunicationsMessage.ContentContainer>
                    </ExternalComunicationsMessage.Root>
                  )}
                </Fragment>
              )
            })
          )}

        </ExternalComunicationsChat.Content>

        {externalComunicationStatus === 'open' && (
          isAnswerChatForm ? (
            <ExternalComunicationsChat.Form
              isEnabledToDigitSomethingInChat={isEnabledToDigitSomethingInChat}
              onSubmitChat={handleAnswerExternalComunication}
              isQuestionForm={false}
            />
          ) : (
            <ExternalComunicationsChat.Form
              isEnabledToDigitSomethingInChat={isEnabledToDigitSomethingInChat}
              onSubmitChat={handleCreateExternalComunicationQuestion}
              isQuestionForm={true}
              onCloseExternalComunication={handleCloseExternalComunication}
            />
          )
        )}
      </ExternalComunicationsChat.Root>
    </Flex >
  )
}
