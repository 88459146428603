import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Patrimony } from "./patrimony";
import { PatrimonyRequest, PatrimonyRequestStatus } from "./patrimonyRequest";
import { PatrimonyType } from "./patrimonyType";

interface GetPatrimonyRequestsParams {
  pageSize: string
  currentPage: string
  status?: PatrimonyRequestStatus | 'all' | null
  patrimonyType?: string
  identifier?: string
  responsibleName?: string
  protocol?: string
}

export interface GetPatrimonyRequestsResponse {
  patrimoniesRequests: Array<PatrimonyRequest & {
    patrimony: Patrimony
    patrimonyRequest: {
      id: string
      protocol: number
    }
    patrimonyType: PatrimonyType
  }>
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
  }
}

export async function getPatrimonyRequests({
  pageSize,
  currentPage,
  patrimonyType,
  status,
  identifier,
  responsibleName,
  protocol
}: GetPatrimonyRequestsParams) {
  try {
    const { data } = await api.get<GetPatrimonyRequestsResponse>('/patrimony-requests', {
      params: {
        pageSize,
        currentPage,
        patrimonyType,
        status,
        identifier,
        responsibleName,
        protocol
      }
    })
    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

