import { Button, Flex, Input, Select } from "@chakra-ui/react";
import { ActiveFeedbackStatus } from "api/feedbacks/_types/ActiveFeedback";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { activeFeedbackStatusMap } from "utils/feedbackMappers";


interface ActivesFeedbacksTableFilterProps {
  isKanbanPage?: boolean
}

interface ActivesFeedbacksTableFiltersSchema {
  name: string
  status: ActiveFeedbackStatus | 'all'
  dueDate: string
}

export function ActivesFeedbacksTableFilter({
  isKanbanPage = false
}: ActivesFeedbacksTableFilterProps) {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const status = searchParams.get('status') ?? 'all'
  const name = searchParams.get('name') ?? ''
  const dueDate = searchParams.get('dueDate')

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<ActivesFeedbacksTableFiltersSchema>({
    defaultValues: {
      name,
      status: status as ActiveFeedbackStatus | 'all',
      dueDate
    }
  })

  async function handleFilterActivesFeedbacks(data: ActivesFeedbacksTableFiltersSchema) {

    if (data.status) {
      searchParams.set('status', data.status)
    } else {
      searchParams.delete('status')
    }

    if (data.name) {
      searchParams.set('name', data.name)
    } else {
      searchParams.delete('name')
    }

    if (data.dueDate) {
      searchParams.set('dueDate', data.dueDate)
    } else {
      searchParams.delete('dueDate')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    reset()
    searchParams.delete('name')
    searchParams.delete('status')
    searchParams.delete('dueDate')

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterActivesFeedbacks)}
    >

      <Input
        {...register('name')}
        name="name"
        size="sm"
        borderRadius="md"
        w={{ base: "full", md: "200px" }}
        placeholder="Digite um nome"
      />

      <Input
        {...register("dueDate")}
        type="date"
        size="sm"
        rounded="md"
        w={{ base: 'full', md: 'fit-content' }}
      />

      {!isKanbanPage && (
        <Select
          {...register('status')}
          defaultValue="all"
          size="sm"
          borderRadius="md"
          w={["full", "full", "200px"]}
        >
          <option value="all">Todos status</option>
          {Object.entries(activeFeedbackStatusMap).map(([key, value]) => {
            return (
              <option key={key} value={key}>{value}</option>
            )
          })}
        </Select>
      )}
      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={["full", "full", "min"]}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleRemoveFilters}
        w={["full", "full", "min"]}
      >
        Remover filtros
      </Button>

    </Flex>
  )
}
