import { Button, Flex, HStack, Stack, useDisclosure } from '@chakra-ui/react'
import { useState, useEffect, useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'

import { useHub } from '../../../hooks/hub/useHub'
import { useCity } from '../../../hooks/city/useCity'

import { Controller, NestedValue, useForm } from 'react-hook-form'
import { Input } from '../../../components/Inputs/Input'
import { Select } from '../../../components/Inputs/SelectInput'
import { TextArea } from '../../../components/Inputs/TextInput'
import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { CheckboxDaysWeek } from '../../../components/Inputs/CheckboxDaysWeek'
import { days_of_week, negative_positive, situation } from '../../../utils/customLists'
import { apiIbge } from '../../../services/ibgeApiservice/ibgeApi'
import { IIbgeApiStatesProps } from '../../../services/ibgeApiservice/IIbgeApi'
import { citySchema } from '../../../validations/citySchema'
import { useContentLoading } from '../../../hooks/loading/useContentLoading'
import { formatHourToFrontInput } from '../../../utils/GeneralFunctions/HourFunctions/formatHourToFrontInput'
import { ListButton } from '../../../components/Buttons/ListButton'
import { FormActionButton } from '../../../components/Buttons/FormActionButton'
import { SubmitButton } from '../../../components/Buttons/SubmitButton'
import { StandardBackgroundForm } from '../../../components/Form/StandardBackgroundForm'
import { useAuth } from '../../../hooks/auth/useAuth'
import { IFormInputs } from '../../../pages/City/CityAddDetailEdit'
import { formatDate } from '../../../utils/DateFunctions/formatDate'
import { CityLogsModal } from './components/CityLogsModal'

interface IFormInputProps {
  name: string
  state: string
  hub_id: string
  schedule_deadline: string
  opening_deadline: number
  days_service: NestedValue<string[]>
  situation: string
  start_attendance_block: string
  final_attendance_block: string
  has_saturday_attendance: string
  saturday_start_attendance_block: Date | string
  saturday_final_attendance_block: Date | string
  has_sunday_attendance: string
  sunday_start_attendance_block: Date | string
  sunday_final_attendance_block: Date | string
  observation: string
}

interface ICityFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
  submit: (values: IFormInputs) => Promise<void>
}

const openingDeadlineOptions = [
  { key: '0', value: 0, showOption: 'D - 0' },
  { key: '1', value: 1, showOption: 'D - 1' },
  { key: '2', value: 2, showOption: 'D - 2' },
  { key: '3', value: 3, showOption: 'D - 3' },
  { key: '4', value: 4, showOption: 'D - 4' },
  { key: '5', value: 5, showOption: 'D - 5' },
]

export function CityForm({
  slug,
  id,
  isDisabled = false,
  href,
  title,
  action,
  submit,
}: ICityFormProps) {
  const [statesList, setStatesList] = useState<IIbgeApiStatesProps[]>([])

  const {
    hubs: { data: hubs, isLoading: isHubLoading },
  } = useHub(null, true, false)
  const {
    city: { data: city, isLoading: isCityLoading },
  } = useCity(id || null, false, false)

  const { userLogged } = useAuth()

  const { handleSetIsContentLoadingFalse, isContentLoading } =
    useContentLoading()

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(citySchema),
  })

  const hasSaturdayAttendance = watch("has_saturday_attendance")
  const hasSundayAttendance = watch("has_sunday_attendance")

  const defaultDaysOfWeek = useMemo(() => {
    return days_of_week.reduce<string[]>((acc, curr) => {
      city?.days_service.forEach(day => {
        if (day === curr.value) {
          acc.push(curr.name)
        }

      })
      return acc
    }, [])
  }, [city])

  useEffect(() => {
    if (!isHubLoading && !isCityLoading) {
      handleSetIsContentLoadingFalse()
    }
  }, [isHubLoading, isCityLoading, handleSetIsContentLoadingFalse])

  useEffect(() => {
    async function setStateListOnSelectField() {
      const statesList = await apiIbge.getStates('estados')
      setStatesList(statesList)
    }

    setStateListOnSelectField()
  }, [])

  useEffect(() => {
    if (slug === 'adicionar') {
      setValue('situation', 'ATIVO')
    }

    if (slug === 'editar' || slug === 'visualizar') {
      if (city && statesList) {
        const scheduleDeadlineFormated = formatHourToFrontInput(
          city.schedule_deadline,
        )
        const startAttendanceBlockFormated = formatHourToFrontInput(
          city.start_attendance_block,
        )
        const finalAttendanceBlockFormated = formatHourToFrontInput(
          city.final_attendance_block,
        )
        const saturdayStartAttendanceBlockFormated = city.saturday_start_attendance_block
          ? formatDate.handle(city.saturday_start_attendance_block, "DateOnlyWithHourMinute")
          : ''
        const saturdayFinalAttendanceBlockFormated = city.saturday_final_attendance_block
          ? formatDate.handle(city.saturday_final_attendance_block, "DateOnlyWithHourMinute")
          : ''
        const sundayStartAttendanceBlockFormated = city.sunday_start_attendance_block
          ? formatDate.handle(city.sunday_start_attendance_block, "DateOnlyWithHourMinute")
          : ''
        const sundayFinalAttendanceBlockFormated = city.sunday_final_attendance_block
          ? formatDate.handle(city.sunday_final_attendance_block, "DateOnlyWithHourMinute")
          : ''


        setValue('name', city.name)
        setValue('state', city.state)
        setValue('hub_id', city.hub_id)
        setValue('situation', city.situation)
        setValue('schedule_deadline', scheduleDeadlineFormated)
        setValue('opening_deadline', city.opening_deadline)
        setValue('start_attendance_block', startAttendanceBlockFormated)
        setValue('final_attendance_block', finalAttendanceBlockFormated)
        setValue('has_saturday_attendance', saturdayStartAttendanceBlockFormated !== '' ? "SIM" : "NÃO")
        setValue('saturday_start_attendance_block', saturdayStartAttendanceBlockFormated)
        setValue('saturday_final_attendance_block', saturdayFinalAttendanceBlockFormated)
        setValue('has_sunday_attendance', sundayStartAttendanceBlockFormated !== '' ? "SIM" : "NÃO")
        setValue('sunday_start_attendance_block', sundayStartAttendanceBlockFormated)
        setValue('sunday_final_attendance_block', sundayFinalAttendanceBlockFormated)
        setValue('days_service', defaultDaysOfWeek)
        setValue('observation', city.observation)
      }
    }
  }, [setValue, statesList, slug, city, defaultDaysOfWeek])

  const {
    isOpen: isOpenCityLogsModal,
    onOpen: onOpenCityLogsModal,
    onClose: onCloseCityLogsModal
  } = useDisclosure()

  if (isContentLoading) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm onSubmit={handleSubmit(submit)} title={title}>
      <Flex w='full' justifyContent='flex-end'>
        {(slug === 'editar' || slug === 'visualizar') && (
          <Button colorScheme='blue' onClick={onOpenCityLogsModal}>
            Logs
          </Button>
        )}
      </Flex>
      {city && hubs &&(
        <CityLogsModal
          isOpen={isOpenCityLogsModal}
          onClose={onCloseCityLogsModal}
          cityLogs={city?.logs}
          hubs={hubs}
        />
      )}
      <Stack>
        <Select
          {...register('situation')}
          name="situation"
          label="Situação"
          error={errors.situation}
          isDisabled={slug === 'adicionar' ? true : isDisabled}
          situations={situation}
          placeholder="Selecione uma opção..."
          required
        />
      </Stack>

      <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register('name')}
          isDisabled={isDisabled}
          name="name"
          label="Nome"
          error={errors.name}
          required
        />

        <Select
          {...register('state')}
          name="state"
          label="Estado"
          error={errors.state}
          isDisabled={isDisabled}
          states={statesList}
          placeholder="Selecione o estado"
          required
        />
      </Stack>

      <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Select
          {...register('hub_id')}
          name="hub_id"
          label="Pertence ao HUB"
          error={errors.hub_id}
          isDisabled={isDisabled}
          hubs={hubs}
          placeholder="Selecione o HUB"
          required
        />

        <Stack w="full" spacing="24px" direction={['column', 'column', 'row']}>
          <Input
            {...register('schedule_deadline')}
            isDisabled={isDisabled}
            name="schedule_deadline"
            type="time"
            label="Horário Limite de Agendamento"
            error={errors.schedule_deadline}
            required
          />
          <Select
            {...register('opening_deadline')}
            isDisabled={isDisabled}
            name="opening_deadline"
            placeholder="Selecione uma opção..."
            options={openingDeadlineOptions}
            label="Prazo de Agendamento"
            error={errors.opening_deadline}
            required
          />
        </Stack>
      </Stack>

      <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
        <Input
          {...register('start_attendance_block')}
          isDisabled={isDisabled}
          name="start_attendance_block"
          type="time"
          label="Bloco Inicial de Atendimento"
          error={errors.schedule_deadline}
          required
        />
        <Input
          {...register('final_attendance_block')}
          isDisabled={isDisabled}
          name="final_attendance_block"
          type="time"
          label="Bloco Final de Atendimento"
          error={errors.final_attendance_block}
          required
        />
      </Stack>

      <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
        <Select
          {...register("has_saturday_attendance")}
          name="has_saturday_attendance"
          label="Possui atendimento ao sábados?"
          negative_positive={negative_positive}
          placeholder="Selecione uma opção..."
          error={errors.has_saturday_attendance}
          required
        />
        {hasSaturdayAttendance === "SIM" && (
          <Stack w="full" spacing="24px" direction={['column', 'column', 'row']}>
            <Input
              {...register("saturday_start_attendance_block")}
              name="saturday_start_attendance_block"
              error={errors.saturday_start_attendance_block}
              type="time"
              label="Bloco inicial de atendimento sábados"
              required
            />
            <Input
              {...register("saturday_final_attendance_block")}
              name="saturday_final_attendance_block"
              error={errors.saturday_final_attendance_block}
              type="time"
              label="Bloco final de atendimento sábados"
              required
            />

          </Stack>
        )}
      </Stack>

      <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
        <Select
          {...register("has_sunday_attendance")}
          name="has_sunday_attendance"
          label="Possui atendimento ao domingos?"
          negative_positive={negative_positive}
          placeholder="Selecione uma opção..."
          error={errors.has_sunday_attendance}
          required
        />
        {hasSundayAttendance === "SIM" && (
          <Stack w="full" spacing="24px" direction={['column', 'column', 'row']}>
            <Input
              {...register("sunday_start_attendance_block")}
              name="sunday_start_attendance_block"
              error={errors.sunday_start_attendance_block}
              type="time"
              label="Bloco inicial de atendimento domingos"
              required
            />
            <Input
              {...register("sunday_final_attendance_block")}
              name="sunday_final_attendance_block"
              error={errors.sunday_final_attendance_block}
              type="time"
              label="Bloco final de atendimento domingos"
              required
            />

          </Stack>
        )}
      </Stack>

      <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Controller
          control={control}
          name='days_service'
          render={({ field: { onChange } }) => (
            <CheckboxDaysWeek
              name="days_service"
              defaultOption={defaultDaysOfWeek}
              days_of_week={days_of_week}
              isDisabled={isDisabled}
              label="Dias de Atendimento"
              onCheckboxChange={onChange}
              required
            />
          )}
        />
      </Stack>

      <TextArea
        {...register('observation')}
        name="observation"
        label="Observações"
        isDisabled={isDisabled}
      />
      <Flex mt="8" justify="flex-end">
        <HStack>
          {userLogged?.permissions.includes('edit-city') &&
            slug === 'visualizar' && (
              <FormActionButton action={action} href={href} />
            )}

          {userLogged?.permissions.includes('add-city') &&
            slug === 'adicionar' && (
              <SubmitButton action={action} isSubmitting={isSubmitting} />
            )}

          {userLogged?.permissions.includes('edit-city') &&
            slug === 'editar' && (
              <SubmitButton action={action} isSubmitting={isSubmitting} />
            )}

          <ListButton href="/cidades" name="Lista de Cidades" />
        </HStack>
      </Flex>
    </StandardBackgroundForm>
  )
}
