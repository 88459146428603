import { Circle, Icon, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react"
import { Regional } from "api/regionals/_types/Regional"
import { unverifySporadic } from "api/sporadics/unverifySporadic"
import { verifySporadic } from "api/sporadics/verifySporadic"
import { Sporadic } from "api/sporadics/_types/Sporadic"
import { format } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { useToastify } from "hooks/toastify/useToastify"
import { FaCheck, FaSearch } from "react-icons/fa"
import { useMutation, useQueryClient } from "react-query"
import { SporadicDetail } from "./SporadicDetail"
import { SporadicStatus } from "./SporadicStatus"

interface SporadicTableRowProps {
  sporadic: Sporadic & {
    regional: Regional
  }
}

export function SporadicTableRow({ sporadic }: SporadicTableRowProps) {

  const { userLogged } = useAuth()

  const userCanVerifySporadic = userLogged?.permissions.includes('verify-sporadic')

  const {
    isOpen: isSporadicDetailModalOpen,
    onToggle: onToggleSporadicDetailModal,
  } = useDisclosure()

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const { mutateAsync: verifySporadicFn } = useMutation({
    mutationFn: verifySporadic,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'in-billing' })
      queryClient.invalidateQueries({ queryKey: 'billed' })
      queryClient.invalidateQueries({ queryKey: 'canceled' })
      queryClient.invalidateQueries({ queryKey: 'sporadics' })

    },
  })

  const { mutateAsync: unverifySporadicFn } = useMutation({
    mutationFn: unverifySporadic,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'in-billing' })
      queryClient.invalidateQueries({ queryKey: 'canceled' })
      queryClient.invalidateQueries({ queryKey: 'billed' })
      queryClient.invalidateQueries({ queryKey: 'sporadics' })
    },
  })

  async function handleVerifyToggle(event: React.MouseEvent) {
    event.stopPropagation();

    if (sporadic?.isVerified) {
      await promiseMessage(unverifySporadicFn({ sporadicId: sporadic?.id }),
        'Verificação removida!')

    } else {
      await promiseMessage(verifySporadicFn({ sporadicId: sporadic?.id }), 'Esporádico verificado!')

    }

  }


  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Esporádico"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onToggleSporadicDetailModal}
        />

        <Modal
          isOpen={isSporadicDetailModalOpen}
          onClose={onToggleSporadicDetailModal}
          size='6xl'
        >
          <ModalOverlay />
          <SporadicDetail
            sporadicId={sporadic.id}
            onClose={onToggleSporadicDetailModal}
          />
        </Modal>
      </Td>
      <Td>
        <Circle
          size="5"
          bg={sporadic?.isVerified ? "green.400" : "gray.300"}
          color="white"
          cursor="pointer"
          onClick={userCanVerifySporadic ? handleVerifyToggle : (e) => e.stopPropagation()}
        >
          {sporadic?.isVerified && < Icon as={FaCheck} boxSize={2} />}
        </Circle>
      </Td>
      <Td>{sporadic.protocol}</Td>
      <Td>{format(new Date(sporadic?.startDate), "dd/MM/yyyy '-' HH:mm")}</Td>
      <Td>{format(new Date(sporadic?.finalDate), "dd/MM/yyyy '-' HH:mm")}</Td>
      <Td>{sporadic?.regional?.name}</Td>
      <Td>
        <SporadicStatus status={sporadic.status} />
      </Td>

    </Tr>
  )
}
