import { ModalContent, ModalHeader, ModalCloseButton, ModalBody, Flex, Button, Spinner, IconButton, Link, Table, TableContainer, Tbody, Td, Tr, Checkbox, FormControl, FormLabel, Input, Progress, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react"
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { format, set } from "date-fns";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { ChangeEvent, useEffect } from "react";
import { useToastify } from "hooks/toastify/useToastify";
import { validateHasFile } from "utils/fileValidation";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getVaccine } from "api/vaccines/getVaccine";
import { editVaccine } from "api/vaccines/editVaccine";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";
import { doseVaccineTypesMap, vaccineCollaboratorTypeMap, vaccinePendingTypesMap, vaccineTypesMap } from "utils/vaccineMappers";
import { VaccineStatus } from "./VaccineStatus";
import { captalize } from "utils/captalize";

interface EditVaccineProps {
  onCloseModal: () => void
  vaccineId: string
}

interface EditVaccineSchema {
  cardFrontPhotoId: FileList
  cardBackPhotoId: FileList
  doses: string[]
  nextDoseDate: string
  pending: 'yes' | 'no'
  alterCardPhoto: 'yes' | 'no'
}

const createVaccineSchema = yup.object({
  nextDoseDate: yup.string().transform((value: string) => {
    if (value) {
      const [year, month, day] = value.split('-').map(Number)
      return set(new Date(), {
        year,
        month: month - 1,
        date: day,
        hours: 12,
        minutes: 0,
      }).toISOString()
    }

    return ''
  }),
  doses: yup
    .array()
    .typeError('Campo Obrigatório')
    .min(1, 'Campo Obrigatório')
    .of(yup.string()),
  pending: yup.string().required(),
  alterCardPhoto: yup.string().required(),
  cardFrontPhotoId: yup.mixed().when('alterCardPhoto', {
    is: 'yes',
    then: yup.mixed().test(value => validateHasFile(value)).required('Campo Obrigatório')
  }),
  cardBackPhotoId: yup.mixed().when('alterCardPhoto', {
    is: 'yes',
    then: yup.mixed().test(value => validateHasFile(value)).required('Campo Obrigatório')
  })
})

export function EditVaccine({ onCloseModal, vaccineId }: EditVaccineProps) {

  const { data: vaccineData, isLoading: isVaccineDataLoading } = useQuery({
    queryKey: ['vaccine', vaccineId],
    queryFn: () => getVaccine({ vaccineId })
  })

  const { mutation: uploadCardFrontPhotoFn, uploadProggress: uploadProggressCardFrontPhoto } = useAttachmentMutation()

  const { mutation: uploadCardBackPhotoFn, uploadProggress: uploadProggressCardBackPhoto } = useAttachmentMutation()


  const queryClient = useQueryClient()

  const { mutateAsync: editVaccineFn } = useMutation({
    mutationFn: editVaccine,
    onSuccess(_data, { vaccineId }) {
      queryClient.invalidateQueries(['vaccines'])
      queryClient.invalidateQueries({ queryKey: 'pending-vaccines' })
      queryClient.invalidateQueries({ queryKey: 'on-time-vaccines' })
      queryClient.invalidateQueries({ queryKey: 'inactive-vaccines' })
      queryClient.invalidateQueries({
        queryKey: ['vaccine', vaccineId]
      })

      onCloseModal()
    }
  })

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<EditVaccineSchema>({
    resolver: yupResolver(createVaccineSchema),
    defaultValues: {
      alterCardPhoto: 'no'
    }
  })

  const [cardFrontPhotoId, cardBackPhotoId, alterCardPhoto] = useWatch({
    control,
    name: ['cardFrontPhotoId', 'cardBackPhotoId', 'alterCardPhoto'],
  })

  useEffect(() => {
    if (vaccineData !== undefined) {
      const vaccine = vaccineData.vaccine
      setValue('doses', vaccine.doses)
      setValue('nextDoseDate', vaccine.next_dose_date ? format(new Date(vaccine.next_dose_date), 'yyyy-MM-dd') : '')
      setValue('doses', vaccine.doses)
    }

  }, [vaccineData, setValue])

  setValue('doses', vaccineData?.vaccine.doses);

  async function handleUploadCardFrontPhoto(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()
    formData.append('attachment', event.target.files[0])
    await uploadCardFrontPhotoFn.mutateAsync(formData)

  }
  async function handleUploadCardBackPhoto(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()
    formData.append('attachment', event.target.files[0])
    await uploadCardBackPhotoFn.mutateAsync(formData)

  }

  const { promiseMessage } = useToastify()


  async function handleEditVaccine({
    doses,
    nextDoseDate,
    pending }: EditVaccineSchema) {

    const isPending = pending === 'yes'

    await promiseMessage(editVaccineFn({
      body: {
        cardBackPhotoId: uploadCardBackPhotoFn?.data?.attachment?.id,
        cardFrontPhotoId: uploadCardFrontPhotoFn?.data?.attachment?.id,
        doses,
        nextDoseDate,
        pending: isPending
      },
      vaccineId: vaccineId
    }), 'Vacina editada! 🎉')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Editar Vacina</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleEditVaccine)}
        maxH='600px'
        overflowY='scroll'
      >
        {isVaccineDataLoading ? (
          <Spinner />
        ) : (
          <>
            <TableContainer>
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>Colaborador</Td>
                    <Td isNumeric>{vaccineData.vaccine.collaborator_name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Tipo de Colaborador</Td>
                    <Td isNumeric>{vaccineCollaboratorTypeMap[vaccineData.vaccine.collaborator_type]}</Td>
                  </Tr>
                  <Tr>
                    <Td>Regional</Td>
                    <Td isNumeric>{vaccineData.vaccine.regional ? vaccineData.vaccine.regional.code.toUpperCase() : '-'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Responsável</Td>
                    <Td isNumeric>{
                    vaccineData.vaccine.regional
                    ? captalize(`${vaccineData.vaccine?.regional?.responsible?.firstname} ${vaccineData?.vaccine?.regional?.responsible.lastname}`)
                    : '-'
                  }</Td>
                  </Tr>
                  <Tr>
                    <Td>Vacina</Td>
                    <Td display="flex" justifyContent="flex-end">
                      {vaccineTypesMap[vaccineData.vaccine.type]}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td display="flex" justifyContent="flex-end">
                      <VaccineStatus status={vaccineData.vaccine.status} />
                    </Td>
                  </Tr>
                  {vaccineData.vaccine.cardFrontPhotoAttachment && (
                    <Tr>
                      <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">
                        Frente Cartão
                      </Td>
                      <Td isNumeric>
                        <IconButton
                          aria-label="Abrir anexo"
                          as={Link}
                          href={vaccineData.vaccine.cardFrontPhotoAttachment.link}
                          icon={<FaExternalLinkAlt />}
                          size="sm"
                          isExternal
                        />
                      </Td>
                    </Tr>
                  )}
                  {vaccineData.vaccine.cardBackPhotoAttachment && (
                    <Tr>
                      <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">
                        Verso Cartão
                      </Td>
                      <Td isNumeric>
                        <IconButton
                          aria-label="Abrir anexo"
                          as={Link}
                          href={vaccineData.vaccine.cardBackPhotoAttachment.link}
                          icon={<FaExternalLinkAlt />}
                          size="sm"
                          isExternal
                        />
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            <Stack
              spacing="6"
              direction={["column", "column", "row"]}
              mt="3"
            >
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormLabel fontSize="sm">
                  Deseja alterar as fotos do cartão de vacina ?
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <FormControl isInvalid={!!errors.alterCardPhoto}>
                  <RadioGroup
                    defaultValue="no"
                  >
                    {Object.entries(vaccinePendingTypesMap).map(([key, value]) => (
                      <Radio
                        {...register('alterCardPhoto')}
                        name="alterCardPhoto"
                        size='sm'
                        rounded='md'
                        mr='7'
                        key={key}
                        value={key}>
                        {value}
                      </Radio>
                    ))}
                  </RadioGroup>

                </FormControl>
              </Stack>
            </Stack>
            {alterCardPhoto === 'yes' && (
              <>
                <Stack
                  direction="column"
                  w="full"
                  mt="3"
                >
                  <Button
                    as={FormLabel}
                    htmlFor="cardFrontPhotoId"
                    lineHeight="1"
                    leftIcon={<FaFileImport />}
                    size="sm"
                    w="min"
                    cursor="pointer"
                    border={!!errors?.cardFrontPhotoId && '2px solid'}
                    borderColor={!!errors?.cardFrontPhotoId && 'red.500'}
                  >
                    Anexar Cartão Frente
                  </Button>
                  <FormControl isInvalid={!!errors?.cardFrontPhotoId}>
                    <Input
                      {...register('cardFrontPhotoId')}
                      name="cardFrontPhotoId"
                      id="cardFrontPhotoId"
                      type="file"
                      hidden
                      onChangeCapture={handleUploadCardFrontPhoto}
                    />
                  </FormControl>
                </Stack>

                {cardFrontPhotoId && (
                  <Table size="sm">
                    <Tbody>
                      {Object.entries(cardFrontPhotoId).map(([key, file]) => {
                        return (
                          <Tr key={key}>
                            <Td fontSize="xs" maxW="100px">{file.name}</Td>
                            <Td fontSize="xs" w="200px">
                              <Progress size="sm" rounded="md" value={uploadProggressCardFrontPhoto} />
                            </Td>
                            <Td fontSize="xs" isNumeric>
                              {uploadCardFrontPhotoFn.data && (
                                <IconButton
                                  aria-label="Visualizar anexo"
                                  as={Link}
                                  size="sm"
                                  icon={<FaExternalLinkAlt />}
                                  href={uploadCardFrontPhotoFn.data.attachment.link}
                                  isExternal
                                />
                              )}
                            </Td>
                          </Tr>
                        )
                      })}
                    </Tbody>
                  </Table>
                )}
                <Stack
                  direction="column"
                  w="full"
                  mt="3"
                >
                  <Button
                    as={FormLabel}
                    htmlFor="cardBackPhotoId"
                    lineHeight="1"
                    leftIcon={<FaFileImport />}
                    size="sm"
                    w="min"
                    cursor="pointer"
                    border={!!errors?.cardBackPhotoId && '2px solid'}
                    borderColor={!!errors?.cardBackPhotoId && 'red.500'}
                  >
                    Anexar Cartão Verso
                  </Button>
                  <FormControl isInvalid={!!errors?.cardBackPhotoId}>
                    <Input
                      {...register('cardBackPhotoId')}
                      name="cardBackPhotoId"
                      id="cardBackPhotoId"
                      type="file"
                      hidden
                      onChangeCapture={handleUploadCardBackPhoto}
                    />
                  </FormControl>
                </Stack>

                {cardBackPhotoId && (
                  <Table size="sm">
                    <Tbody>
                      {Object.entries(cardBackPhotoId).map(([key, file]) => {
                        return (
                          <Tr key={key}>
                            <Td fontSize="xs" maxW="100px">{file.name}</Td>
                            <Td fontSize="xs" w="200px">
                              <Progress size="sm" rounded="md" value={uploadProggressCardBackPhoto} />
                            </Td>
                            <Td fontSize="xs" isNumeric>
                              {uploadCardBackPhotoFn.data && (
                                <IconButton
                                  aria-label="Visualizar anexo"
                                  as={Link}
                                  size="sm"
                                  icon={<FaExternalLinkAlt />}
                                  href={uploadCardBackPhotoFn.data.attachment.link}
                                  isExternal
                                />
                              )}
                            </Td>
                          </Tr>
                        )
                      })}
                    </Tbody>
                  </Table>
                )}
              </>
            )}

            <Stack
              spacing="6"
              direction={["column", "column", "row"]}
              mt="3"
            >
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormLabel fontSize="sm">
                  Doses
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <FormControl isInvalid={!!errors.doses}>
                  {Object.entries(doseVaccineTypesMap).map(([key, value]) => (
                    <Checkbox
                      {...register('doses')}
                      name="doses"
                      size='sm'
                      rounded='md'
                      mr='6'
                      key={key}
                      value={key}
                    >
                      {value}
                    </Checkbox>
                  ))}
                </FormControl>
              </Stack>
            </Stack>

            <Stack
              direction="column"
              w="full"
              mt="3"
            >
              <FormControl isInvalid={!!errors?.nextDoseDate}>
                <FormLabel fontSize="sm">
                  Data próxima dose
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('nextDoseDate')}
                  name="nextDoseDate"
                  type="date"
                  size="sm"
                  rounded="md"
                />
              </FormControl>
            </Stack>
            <Stack
              spacing="6"
              direction={["column", "column", "row"]}
              mt="3"
            >
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormLabel fontSize="sm">
                  Pendência ?
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <FormControl isInvalid={!!errors.pending}>
                  <RadioGroup
                    defaultValue={vaccineData?.vaccine.status === 'pending' ? 'yes' : 'no'}
                  >
                    {Object.entries(vaccinePendingTypesMap).map(([key, value]) => (
                      <Radio
                        {...register('pending')}
                        name="pending"
                        size='sm'
                        rounded='md'
                        mr='7'
                        key={key}
                        value={key}>
                        {value}
                      </Radio>
                    ))}
                  </RadioGroup>

                </FormControl>
              </Stack>
            </Stack>
          </>
        )}
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Editar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
