import { ButtonGroup, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { deleteRegional } from "api/regionals/deleteRegional";
import { GetRegionalsResponse } from "api/regionals/getRegionals";
import { Regional } from "api/regionals/_types/Regional";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { User } from "hooks/user/dtos/User";
import { FaSearch } from "react-icons/fa";
import { FaPen, FaTrash } from "react-icons/fa6";
import { useMutation, useQueryClient } from "react-query";
import { captalize } from "utils/captalize";
import { EditRegional } from "./EditRegional";
import { RegionalDetail } from "./RegionalDetail";

interface RegionalsTableRowProps {
  regional: Regional & {
    responsible: User
  }
}

export function RegionalsTableRow({ regional }: RegionalsTableRowProps) {
  const { userLogged } = useAuth()

  const userCanEditRegional = userLogged?.permissions.includes('edit-regional')
  const userCanDeleteRegional = userLogged?.permissions.includes('delete-regional')


  const {
    isOpen: isRegionalDetailModalOpen,
    onOpen: onOpenRegionalDetailModal,
    onClose: onCloseRegionalDetailModal
  } = useDisclosure()

  const {
    isOpen: isEditRegionalModalOpen,
    onOpen: onOpenEditRegionalModal,
    onClose: onCloseEditRegionalModal
  } = useDisclosure()

  const queryClient = useQueryClient()

  const { mutateAsync: deleteRegionalFn } = useMutation({
    mutationFn: deleteRegional,
    onSuccess(_data, variables) {
      const cached = queryClient.getQueriesData<GetRegionalsResponse>({
        queryKey: ['regionals']
      })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          regionals: cachedValue.regionals.filter(regional => regional.id !== variables.regionalId)
        })
      })
    },
  })

  const { promiseMessage } = useToastify()

  async function handleDeleteRegional(regionalId: string) {
    await promiseMessage(deleteRegionalFn({
      regionalId
    }), 'Regional excluída com sucesso!')
  }

  return (
    <Tr>
      <Td>
        <ButtonGroup spacing={3}>
          <Modal
            isOpen={isRegionalDetailModalOpen}
            onClose={onCloseRegionalDetailModal}
          >
            <ModalOverlay />

            <RegionalDetail regionalId={regional.id} />

          </Modal>
          <IconButton
            aria-label="Detalhes da regional"
            icon={<FaSearch />}
            onClick={onOpenRegionalDetailModal}
            variant="ghost"
            size="sm"
          />

          {userCanEditRegional && (
            <>
              <Modal
                isOpen={isEditRegionalModalOpen}
                onClose={onCloseEditRegionalModal}
              >
                <ModalOverlay />

                <EditRegional
                  onCloseModal={onCloseEditRegionalModal}
                  regionalId={regional.id}
                />

              </Modal>
              <IconButton
                aria-label="Editar regional"
                icon={<FaPen />}
                onClick={onOpenEditRegionalModal}
                variant="ghost"
                size="sm"
              />
            </>
          )}

        </ButtonGroup>
      </Td>
      <Td>{regional.name}</Td>
      <Td>{regional.code.toUpperCase()}</Td>
      <Td>{regional.responsible ? captalize(`${regional.responsible.firstname} ${regional.responsible.lastname}`) : '-'}</Td>
      <Td>
        {userCanDeleteRegional && (
          <IconButton
            aria-label="Excluir regional"
            icon={<FaTrash />}
            onClick={() => handleDeleteRegional(regional.id)}
            variant="ghost"
            size="sm"
          />
        )}
      </Td>
    </Tr>
  )
}
