import { useEffect } from "react"
import { usePagination } from "@ajna/pagination"
import { Flex, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { TableFilterButton } from "components/Filters/TableFilterButton"
import { Pagination } from "components/Pagination/Pagination"
import { SearchBox } from "components/SearchBox/SearchBox"
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions"
import { CostReportTransformedData } from "hooks/report/dtos/CostReport"
import { useForm, useWatch } from "react-hook-form"
import { searchBoxFilter } from "utils/searchBoxFilter"

interface CostReportFilterOptionsInputs {
  protocol: string
  customer: string
  sourceCities: string
  destinationCities: string
  collectDate: string
  deliveryDate: string
  step: string
}

const costReportTableFilterOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'sourceCities', value: 'Cidade(s) de origem', checked: false },
  { key: 'destinationCities', value: 'Cidade(s) de destino', checked: false },
  { key: 'collectDate', value: 'Data da coleta', checked: false },
  { key: 'deliveryDate', value: 'Data da entrega', checked: false },
  { key: 'step', value: 'Etapa', checked: false },
]

interface CostReportTableProps {
  costReportData: CostReportTransformedData[]
}

export function CostReportTable({
  costReportData
}: CostReportTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const { filterOptions, onLoadSetFilterOptions } = useFilterOptions()

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(costReportTableFilterOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  const {
    control,
    register
  } = useForm()

  const [
    protocolFiltered,
    customerFiltered,
    sourceCitiesFiltered,
    destinationCitiesFiltered,
    collectDateFiltered,
    deliveryDateFiltered,
    stepFiltered
  ] = useWatch({
    control,
    name: [
      'protocol',
      'customer',
      'sourceCities',
      'destinationCities',
      'collectDate',
      'deliveryDate',
      'step'
    ]
  })

  const costReportDataFiltered = costReportData
    ?.filter(costData => {
      const protocolFilter = protocolFiltered
        ? searchBoxFilter(String(costData.protocol), protocolFiltered)
        : true

      const customerFilter = customerFiltered
        ? searchBoxFilter(costData.customer, customerFiltered)
        : true

      const sourceCitiesFilter = sourceCitiesFiltered
        ? searchBoxFilter(costData.source_cities, sourceCitiesFiltered)
        : true

      const destinationCitiesFilter = destinationCitiesFiltered
        ? searchBoxFilter(costData.destination_cities, destinationCitiesFiltered)
        : true

      const collectDateFilter = collectDateFiltered
        ? searchBoxFilter(costData.collect_date, collectDateFiltered)
        : true

      const deliveryDateFilter = deliveryDateFiltered
        ? searchBoxFilter(costData.delivery_date, deliveryDateFiltered)
        : true

      const stepFilter = stepFiltered
        ? searchBoxFilter(costData.step, stepFiltered)
        : true

      return protocolFilter &&
        customerFilter &&
        sourceCitiesFilter &&
        destinationCitiesFilter &&
        collectDateFilter &&
        deliveryDateFilter &&
        stepFilter
    })

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: costReportDataFiltered.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  return (

    <Flex
      direction='column'
      gap={4}
    >
      <Flex justify='space-between' gap={4}>
        <TableFilterButton />
        <Stack direction='column'>
          {
            filterOptions
              .filter(option => option.checked)
              .map(option => {
                return (
                  <SearchBox
                    {...register(option.key as keyof CostReportFilterOptionsInputs)}
                    key={option.key}
                    size="sm"
                    placeholder={'Buscar ' + option.value}
                    handleSearch={() => { }}
                  />
                )
              })
          }
        </Stack>
      </Flex>
      <TableContainer>
        <Table variant='striped' size='sm'>
          <Thead>
            <Tr>
              {costReportTableFilterOptions.map(option => {
                return (
                  <Th>{option.value.toLocaleUpperCase()}</Th>
                )
              })}
            </Tr>
          </Thead>
          <Tbody>
            {
              costReportDataFiltered
                .slice(offset, offset + Number(itemLimit))
                .map(costData => {
                  return (
                    <Tr key={costData.service_id}>
                      <Td>{costData.protocol}</Td>
                      <Td>{costData.customer}</Td>
                      <Td>{costData.source_cities}</Td>
                      <Td>{costData.destination_cities}</Td>
                      <Td>{costData.collect_date}</Td>
                      <Td>{costData.delivery_date}</Td>
                      <Td>{costData.step}</Td>
                    </Tr>
                  )
                })
            }
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        handlePageChange={handlePageChange}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />

    </Flex>
  )
}
