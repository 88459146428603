import { Box, Flex } from "@chakra-ui/react";
import { businessCostSituationMapper } from "utils/businessCostsMappers";

interface BusinessCostSituationProps {
  situation: 'active' | 'inactive'
}

export function BusinessCostSituation({
  situation
}: BusinessCostSituationProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >

      {situation === 'active' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {situation === 'inactive' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {businessCostSituationMapper[situation]}
    </Flex>
  )
}
