import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface EditRegionalBody {
  name: string
  code: string
  responsibleId: string
}

interface EditRegionalProps {
  body: EditRegionalBody
  regionalId: string
}

export async function editRegional({
  body,
  regionalId
}: EditRegionalProps) {
  try {
    const response = await api.put(`/regionals/${regionalId}`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
