import { Box, Flex } from "@chakra-ui/react";
import { AuditStatus as AuditStatusTypes } from "api/audits/_types/Audit";
import { auditStatusMap } from "utils/auditMappers";

interface AuditStatusProps {
  status: AuditStatusTypes
}

export function AuditStatus({ status }: AuditStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {status === 'pending' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'approved' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'reproved' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}
      {auditStatusMap[status]}
    </Flex>
  )
}
