import axios from "axios";
import { Warehouse } from "hooks/warehouse/dtos/Warehouse";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Pmc } from "./pmc";

export interface GetPmcsParams {
  pageSize: string
  currentPage: string
  protocol?: string | null
  status: 'all' | 'approving' | 'receiving' | 'received'
}

interface PmcType extends Pmc {
  warehouse: Pick<Warehouse, 'name'>
}

export interface GetPmcsResponse {
  pmcs: PmcType[],
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
  }
}


export async function getPmcs({
  currentPage,
  pageSize,
  status,
  protocol
}: GetPmcsParams) {
  try {
    const response = await api.get<GetPmcsResponse>('/pmcs', {
      params: {
        pageSize,
        currentPage,
        protocol,
        status
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
