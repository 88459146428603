import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { getVaccines } from "api/vaccines/getVaccines";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { FaExchangeAlt, FaPlus } from "react-icons/fa";
import { useInfiniteQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreateVaccine } from "./components/CreateVaccine";
import { VaccinesKanbanColumn } from "./components/VaccinesKanbanColumn";

export function VaccinesKanban() {
  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  const userHasViewVaccinesPermission = userLogged?.permissions.includes('view-vaccines')
  const userHasCreateVaccinePermission = userLogged?.permissions.includes('create-vaccine')

  useEffect(() => {
    if (!userHasViewVaccinesPermission) {
      redirect('/')
    }
  }, [userHasViewVaccinesPermission, redirect])

  const {
    isOpen: isCreateVaccineModalOpen,
    onOpen: onOpenCreateVaccineModal,
    onClose: onCloseCreateVaccineModal
  } = useDisclosure()


  const {
    data: pendingVaccinesResult,
    isLoading: isPendingVaccinesResultLoading,
    hasNextPage: hasNextPagePendingVaccines,
    fetchNextPage: fetchNextPagePendingVaccines,
    isFetchingNextPage: isFetchingNextPagePendingVaccines,
  } = useInfiniteQuery({
    queryKey: ['pending-vaccines'],
    queryFn: ({ pageParam = 1 }) => getVaccines({
      currentPage: pageParam,
      pageSize: '10',
      status: 'pending',
      timezoneOffset: String(new Date().getTimezoneOffset() / 3),
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: onTimeVaccinesResult,
    isLoading: isOnTimeVaccinesResultLoading,
    hasNextPage: hasNextPageOnTimeVaccines,
    fetchNextPage: fetchNextPageOnTimeVaccines,
    isFetchingNextPage: isFetchingNextPageOnTimeVaccines,
  } = useInfiniteQuery({
    queryKey: ['on-time-vaccines'],
    queryFn: ({ pageParam = 1 }) => getVaccines({
      currentPage: pageParam,
      pageSize: '10',
      status: 'on-time',
      timezoneOffset: String(new Date().getTimezoneOffset() / 3),
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })
  const {
    data: inactiveVaccinesResult,
    isLoading: isInactiveVaccinesResultLoading,
    hasNextPage: hasNextPageInactiveVaccines,
    fetchNextPage: fetchNextPageInactiveVaccines,
    isFetchingNextPage: isFetchingNextPageInactiveVaccines,
  } = useInfiniteQuery({
    queryKey: ['inactive-vaccines'],
    queryFn: ({ pageParam = 1 }) => getVaccines({
      currentPage: pageParam,
      pageSize: '10',
      status: 'inactive',
      timezoneOffset: String(new Date().getTimezoneOffset() / 3),
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const columnsVaccines = [
    {
      title: 'Pendentes',
      vaccines: pendingVaccinesResult?.pages.flatMap((page) => page.vaccines) || [],
      vaccinesCount: pendingVaccinesResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPagePendingVaccines,
      fetchNextPage: fetchNextPagePendingVaccines,
      isFetchingNextPage: isFetchingNextPagePendingVaccines,
      isLoading: isPendingVaccinesResultLoading,
    },
    {
      title: 'Aprovadas',
      vaccines: onTimeVaccinesResult?.pages.flatMap((page) => page.vaccines) || [],
      vaccinesCount: onTimeVaccinesResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageOnTimeVaccines,
      fetchNextPage: fetchNextPageOnTimeVaccines,
      isFetchingNextPage: isFetchingNextPageOnTimeVaccines,
      isLoading: isOnTimeVaccinesResultLoading,
    },
    {
      title: 'Reprovadas',
      vaccines: inactiveVaccinesResult?.pages.flatMap((page) => page.vaccines) || [],
      vaccinesCount: inactiveVaccinesResult?.pages[0]?.meta?.count,
      color: 'red.300',
      hasNextPage: hasNextPageInactiveVaccines,
      fetchNextPage: fetchNextPageInactiveVaccines,
      isFetchingNextPage: isFetchingNextPageInactiveVaccines,
      isLoading: isInactiveVaccinesResultLoading,
    },
  ]


  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Vacinas</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar tabela"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => redirect('/vacinas')}
          />
          {userHasCreateVaccinePermission && (
            <IconButton
              aria-label="Criar Vacina"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateVaccineModal}
            />
          )}
        </Flex>
        <Modal
          isOpen={isCreateVaccineModalOpen}
          onClose={onCloseCreateVaccineModal}
          size="2xl"
          isCentered
        >
          <ModalOverlay />
          <CreateVaccine onCloseModal={onCloseCreateVaccineModal} />
        </Modal>
      </Flex>
      <Flex gap={4} overflowX="auto" mt={4}>
        {columnsVaccines?.map((vaccine, index) => {
          return (
            <VaccinesKanbanColumn
              key={index}
              vaccine={vaccine}
            />
          )
        })}
      </Flex>
    </Box>
  )
}
