import { Button, Flex, FormControl, FormLabel, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Stack, Text } from "@chakra-ui/react"
import { useToastify } from "hooks/toastify/useToastify"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { useHistory } from "react-router-dom";
import { confirmDestinationBranch } from "api/service/confirmDestinationBranch/confirmDestinationBranch";
import { useMutation, useQueryClient } from "react-query";

interface ConfirmDestinationProps {
  onCloseModal: () => void
  serviceId: string
  destinationBranch: string
}

interface ConfirmDestinationBranchSchema {
  hasConfirm: string
}

const confirmDestinationBranchSchema = yup.object({
  hasConfirm: yup.mixed().required().transform((value) => {
    return value.toLowerCase() === 'yes'
  })
})

export function ConfirmDestinationBranch({
  onCloseModal,
  serviceId,
  destinationBranch
}: ConfirmDestinationProps) {

  const { promiseMessage } = useToastify()

  const { push: redirectTo } = useHistory()

  const {
    register,
    handleSubmit,

    formState: {
      errors,
      isSubmitting
    }
  } = useForm<ConfirmDestinationBranchSchema>({
    resolver: yupResolver(confirmDestinationBranchSchema)
  })

  const queryClient = useQueryClient()

  const { mutateAsync: confirmDestinationBranchFn } = useMutation({
    mutationFn: confirmDestinationBranch
  })

  async function handleConfirmDestinationBranch({ hasConfirm }: ConfirmDestinationBranchSchema) {
    await promiseMessage(confirmDestinationBranchFn({
      body: {
        hasConfirm: hasConfirm
      },
      serviceId: serviceId
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['inBoardServices'])
        if (hasConfirm) {
          redirectTo(`/servicos/embarques/${serviceId}`)
          return
        }
        onCloseModal()
      }
    }), 'Confirmação salva com sucesso! ')
  }
  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Confirmação de Base de Destino</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleConfirmDestinationBranch)}
      >
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              {`Base de destino corresponde a ${destinationBranch}?`}
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.hasConfirm}>
              <Select
                {...register('hasConfirm')}
                name="hasConfirm"
                placeholder="Selecione..."
                size="sm"
                rounded="md"
              >
                <option value='yes'>Sim</option>
                <option value='no'>Não</option>
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Salvar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
