import { Card, CardBody, CardHeader, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

interface DashboardCardProps {
  title: string
  count: number
  isAlertCard?: boolean
  icon: IconType
  path?: string
  isComposed?: boolean
  composedCount?: number
}

export function DashboardCard({
  title,
  count,
  isAlertCard = false,
  icon,
  path,
  isComposed = false,
  composedCount = 0,
}: DashboardCardProps) {
  return (
    <Card h="full" position="relative" overflow="hidden" rounded="2xl" boxShadow="md" role="group">
      {path ? (
        <CardHeader role="group">
          <Heading
            as={Link}
            to={path}
            display="flex"
            alignItems="center"
            gap="2"
            fontSize="1.175rem"
            letterSpacing="tight"
          >
            <Text
              transition="text-decoration-color 0.2s"
              textDecoration="underline"
              textDecorationColor="rgba(255, 255, 255, 0)"
              _groupHover={{ textDecorationColor: "gray.800" }}
            >
              {title}
            </Text>
            <Icon as={FaAngleRight} visibility="hidden" opacity="0" transition="all 0.2s" _groupHover={{ visibility: "visible", opacity: "1" }} />
          </Heading>
        </CardHeader>
      ) : (
        <CardHeader role="group">
          <Heading
            display="flex"
            alignItems="center"
            gap="2"
            fontSize="1.175rem"
            letterSpacing="tight"
          >
            <Text
              transition="text-decoration-color 0.2s"
              textDecoration="underline"
              textDecorationColor="rgba(255, 255, 255, 0)"
              _groupHover={{ textDecorationColor: "gray.800" }}
            >
              {title}
            </Text>
          </Heading>
        </CardHeader>
      )}


      <CardBody>
        <Flex
          w="full"
          align="flex-end"
          justify="space-between"
          h="full"
        >
          {isAlertCard ? (
            <Flex
              align="center"
              gap={3}
            >
              <Text
                fontSize="5xl"
                fontWeight="bold"
                color={count > 0 ? 'red.500' : 'gray.800'}
              >
                {count}
              </Text>
              {isComposed && (
                <Text
                  fontSize="5xl"
                  fontWeight="bold"
                  color={composedCount > 0 ? 'yellow.500' : 'gray.800'}
                  _before={{
                    content: '"|"',
                    mr: 3
                  }}
                >
                  {composedCount}
                </Text>
              )}
            </Flex>
          ) : (
            <Text
              fontSize="5xl"
              fontWeight="bold"
              color="gray.800"
            >
              {count}
            </Text>
          )}
          <Icon
            as={icon}
            fontSize="7xl"
            transition="all ease-in-out 0.3s"
            _groupHover={{
              transform: 'translateY(-40%) scale(1.1)',
            }}
          />
        </Flex>
      </CardBody>
    </Card>
  )
}
