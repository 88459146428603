import { addDays } from 'date-fns'
import { useCollectors } from 'hooks/collector/useCollectors'
import { useDriver } from 'hooks/driver/useDriver'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CreateBusinessBudgetForm } from '../../components/Form/Budget/Business/CreateBusinessBudgetForm'
import { EditDetailBusinessBudgetForm } from '../../components/Form/Budget/Business/EditDetailBusinessBudgetForm'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useBusinessBudget } from '../../hooks/businessBudget/useBusinessBudget'
import { useBusinessBudgetFunctions } from '../../hooks/businessBudget/useBusinessBudgetFunctions'
import { useCity } from '../../hooks/city/useCity'
import { useCustomer } from '../../hooks/customer/useCustomer'
import { useHub } from '../../hooks/hub/useHub'
import { useSwal } from '../../hooks/swal/useSwal'
import { setDate } from '../../utils/DateFunctions/setDate'
import { transformStringToNumber } from '../../utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber'
import {
  BusinessBudgetInputProps,
  EditBusinessBudgetReqProps,
} from '../../utils/RequestFunctions/BusinessBudget/requestBusinessBudgetFunctions'

interface IQueryParams {
  id?: string
  slug: string
}

interface IFormInputProps {
  customer_id: string
  source_hub_id: string
  destination_hub_id: string
  source_collector_id: string
  source_cities: string[]
  destination_cities: string[]
  route_nickname: string
  vehicle: string
  start_date: string
  end_date: string
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  monthly_price: string
  budget: string
  monthly_km_franchising: string
  week_time_franchising: string
  km_extra_price: string
  extra_hour_price: string
  weekday_daily_budget_backup: string
  weekend_daily_budget_backup: string
  drivers: {
    driver: string
    attendance_days: string[]
    start_hour: string
    end_hour: string
    value_in_cents: string
  }[]
  situation?: 'ATIVO' | 'INATIVO'
  last_readjustment: string
  observation: string
}

interface IAttendanceDaysProps {
  [driver: string]: {
    attendance_days?: string[]
    start_hour?: string
    end_hour?: string
    value_in_cents?: number
  }
}

async function formatValuesToBack(
  values: IFormInputProps,
): Promise<BusinessBudgetInputProps> {
  const drivers = values.drivers.reduce((acc, curr) => {
    if (!acc[curr.driver]) {
      acc[curr.driver] = {}
    }

    const [startHour, startMinute] = curr.start_hour.split(':')
    const [endHour, endMinute] = curr.end_hour.split(':')

    acc[curr.driver].attendance_days = curr.attendance_days
    acc[curr.driver].start_hour = setDate.handleSetHourWithFormat(
      Number(startHour),
      Number(startMinute),
      'FullDate',
    )
    acc[curr.driver].end_hour = setDate.handleSetHourWithFormat(
      Number(endHour),
      Number(endMinute),
      'FullDate',
    )

    acc[curr.driver].value_in_cents =Number((transformStringToNumber(curr.value_in_cents) * 100).toFixed(2))

    return acc
  }, {} as IAttendanceDaysProps)

  const startDate = addDays(new Date(values.start_date), 1)
  const endDate = addDays(new Date(values.end_date), 1)

  return {
    ...values,
    situation: values.situation || 'ATIVO',
    monthly_price: transformStringToNumber(values.monthly_price),
    budget: transformStringToNumber(values.budget),
    monthly_km_franchising: transformStringToNumber(
      values.monthly_km_franchising,
    ),
    week_time_franchising: transformStringToNumber(
      values.week_time_franchising,
    ),
    km_extra_price: transformStringToNumber(values.km_extra_price),
    extra_hour_price: transformStringToNumber(values.extra_hour_price),
    weekday_daily_budget_backup: transformStringToNumber(values.weekday_daily_budget_backup),
    weekend_daily_budget_backup: transformStringToNumber(values.weekend_daily_budget_backup),
    source_cities:
      values.source_cities.toString().split(',').length > 1
        ? values.source_cities
        : values.source_cities.toString().split('  '),
    destination_cities:
      values.destination_cities.toString().split(',').length > 1
        ? values.destination_cities
        : values.destination_cities.toString().split('  '),
    drivers,
    start_date: startDate,
    end_date: endDate,
  }
}

export function CreateDetailEditBusinessBudget() {
  const { confirmMessage, standardMessage } = useSwal()
  const { slug, id }: IQueryParams = useParams()
  const { push: redirectTo } = useHistory()

  const {
    hubs: { data: hubs, isLoading: isHubsLoading },
  } = useHub(null, true)
  const {
    cities: { data: cities, isLoading: isCitiesLoading },
  } = useCity(null, true)
  const {
    customers: { data: customers, isLoading: isCustomerLoading },
  } = useCustomer(null, true)
  const {
    data: collectors, isLoading: isCollectorsLoading,
  } = useCollectors()
  const {
    businessBudget: {
      data: businessBudget,
      isLoading: isBusinessBudgetLoading,
    },
  } = useBusinessBudget(id)

  const {
    drivers: { data: drivers, isLoading: isDriverLoading },
  } = useDriver(null, true, false)

  const activeDrivers = drivers?.filter(driver => driver.situation === 'ATIVO')

  const {
    createBusinessBudget: {
      mutateAsync: createBusinessBudget,
      isLoading: isCreateBusinessBudgetLoading,
      isSuccess: isCreateBusinessBudgetSuccess,
    },
    editBusinessBudget: {
      mutateAsync: editBusinessBudget,
      isLoading: isEditBusinessBudgetLoading,
      isSuccess: isEditBusinessBudgetSuccess,
    },
  } = useBusinessBudgetFunctions()

  useEffect(() => {
    if (isCreateBusinessBudgetSuccess || isEditBusinessBudgetSuccess) {
      redirectTo('/orcamentos-business')
    }
  }, [isCreateBusinessBudgetSuccess, isEditBusinessBudgetSuccess, redirectTo])

  async function handleCreateBusinessBudget(values: IFormInputProps) {
    const hasCreateBusinessBudget = await confirmMessage({
      title: 'Deseja criar um orçamento business?',
    })

    if (hasCreateBusinessBudget) {
      const formatedValues = await formatValuesToBack(values)
      await createBusinessBudget(formatedValues)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  async function handleEditBusinessBudget(values: IFormInputProps) {
    const hasEditBusinessBudget = await confirmMessage({
      title: 'Deseja atualizar um orçamento business?',
    })

    if (hasEditBusinessBudget) {
      const formatedValues = await formatValuesToBack(values)
      const editBusinessReqObj: EditBusinessBudgetReqProps = {
        id: id!,
        input: formatedValues,
      }
      await editBusinessBudget(editBusinessReqObj)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (
    isHubsLoading ||
    isCitiesLoading ||
    isCustomerLoading ||
    isCollectorsLoading ||
    isCreateBusinessBudgetLoading ||
    isBusinessBudgetLoading ||
    isEditBusinessBudgetLoading ||
    isDriverLoading
  ) {
    return <GeneralContentLoading />
  }

  return (
    <>
      {slug === 'adicionar' && (
        <CreateBusinessBudgetForm
          hubs={hubs}
          cities={cities}
          customers={customers}
          collectors={collectors}
          drivers={activeDrivers}
          submit={handleCreateBusinessBudget}
        />
      )}

      {(slug === 'editar' || slug === 'visualizar') && businessBudget && (
        <EditDetailBusinessBudgetForm
          slug={slug}
          hubs={hubs}
          cities={cities}
          customers={customers}
          collectors={collectors}
          drivers={activeDrivers}
          submit={handleEditBusinessBudget}
          businessBudget={businessBudget}
        />
      )}
    </>
  )
}
