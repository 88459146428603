import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Stack,
  useMediaQuery,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye, FiTrash2 } from 'react-icons/fi'
import { useState, useEffect, Fragment } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { EmptyContentTableAlert } from '../../Alerts/EmptyContentTableAlert'
import { useSwal } from '../../../hooks/swal/useSwal'
import { useAuth } from '../../../hooks/auth/useAuth'
import { useHubFunctions } from '../../../hooks/hub/useHubFunctions'
import { IHubsProps } from '../../../utils/RequestFunctions/Hubs/requestHubFunctions'

interface IHubTableProps {
  hubs: IHubsProps[]
}

const hubOptions = [
  { key: 'name', value: 'Nome', checked: false },
  { key: 'state', value: 'Estado', checked: false },
  { key: 'regional', value: 'Regional', checked: false },
]

const hubOrderOptions = [
  { key: 'name', value: 'Nome' },
  { key: 'state', value: 'Estado' },
]

export function HubsTable({ hubs }: IHubTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [searchedHubs, setSearchedHubs] = useState<IHubsProps[]>([])
  const [slicedHubs, setSlicedHubs] = useState<IHubsProps[]>([])
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  // SEARCHBOX FILTERS
  const [cityFiltered, setCityFiltered] = useState('')
  const [stateFiltered, setStateFiltered] = useState('')
  const [regionalFiltered, setRegionalFiltered] = useState('')
  // ---------------------------------------------------------------------------- //

  // HOOKS
  const {
    deleteHub: { mutate: deleteHub },
  } = useHubFunctions()
  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useAuth()
  // ---------------------------------------------------------------------------- //

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedHubs?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(hubOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(hubOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      setSlicedHubs(hubs)
    }
    run()
  }, [hubs, currentPage])

  useEffect(() => {
    function run() {
      const newSlicedHubs = slicedHubs.filter((hubs) => {
        const cityFilter = searchBoxFilter(hubs.name, cityFiltered)
        const stateFilter = searchBoxFilter(hubs.state, stateFiltered)
        const regionalFilter = searchBoxFilter(
          String(hubs.regional),
          regionalFiltered,
        )

        if (
          cityFiltered === '' &&
          stateFiltered === '' &&
          regionalFiltered === ''
        ) {
          return hubs
        }
        return cityFilter && stateFilter && regionalFilter
      })

      if (orderOptionSelected.length > 0) {
        newSlicedHubs.sort(
          (a, b) =>
            (orderOptionSelected.includes('name') &&
              a.name.localeCompare(b.name)) ||
            (orderOptionSelected.includes('state') &&
              a.state.localeCompare(b.state)) ||
            0,
        )
        setSearchedHubs(newSlicedHubs)
      } else {
        setSearchedHubs(newSlicedHubs)
      }
    }
    run()
  }, [
    cityFiltered,
    stateFiltered,
    regionalFiltered,
    slicedHubs,
    orderOptionSelected,
  ])

  const handlePageChange = (page: number) => {
    return setCurrentPage(page)
  }

  const handleChangeSetter = (key: string, value: string) => {
    switch (key) {
      case 'name':
        return setCityFiltered(value)
      case 'state':
        return setStateFiltered(value)
      case 'regional':
        return setRegionalFiltered(value)
    }
  }

  const handleDeleteHub = async (hubId: string) => {
    const hasConfirmed = await confirmMessage({
      title: 'Deseja excluir um hub?',
    })

    if (hasConfirmed) {
      deleteHub(hubId)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (slicedHubs.length <= 0) {
    return (
      <EmptyContentTableAlert
        title="Oops!"
        description="Não há dados para mostrar aqui!"
      />
    )
  }

  return (
    <>
      <Flex
        mb="2"
        wrap="wrap"
        justify={isWideVersion ? 'space-between' : 'flex-start'}
      >
        <Box>
          <TableFilterButton />
        </Box>
        <Box w={isWideVersion ? '' : 'full'}>
          <Stack mt={isWideVersion ? '0' : '2'} direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Flex key={option.key}>
                  <SearchBox
                    placeholder={`Buscar ${option.value}...`}
                    handleSearch={(e) =>
                      handleChangeSetter(option.key, e.target.value)
                    }
                  />
                </Flex>
              ))}
          </Stack>
        </Box>
      </Flex>
      <Box overflowX="auto" w="full">
        <Table w="full" colorScheme="gray" variant="striped" size="sm">
          <Thead>
            <Tr>
              {hubOptions.map((option) => (
                <Th key={option.key} fontSize="12" color="blue.900">
                  {option.value.toUpperCase()}
                </Th>
              ))}
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {searchedHubs
              .slice(offset, offset + Number(itemLimit))
              .map((hub, index) => (
                <Tr key={index}>
                  <Td fontSize="12">{hub.name.toUpperCase()}</Td>
                  <Td fontSize="12">{hub.state.toUpperCase()}</Td>
                  <Td fontSize="12">{hub.regional}</Td>
                  <Td isNumeric>
                    {userLogged !== null && (
                      <>
                        {userLogged?.permissions.includes('view-hub') && (
                          <Link to={`/hub/visualizar/${hub.id}`}>
                            <Icon cursor="pointer" as={FiEye} fontSize="20" />
                          </Link>
                        )}
                        {userLogged?.permissions.includes('edit-hub') && (
                          <Link to={`/hub/editar/${hub.id}`}>
                            <Icon
                              cursor="pointer"
                              as={FiEdit3}
                              fontSize="20"
                              ml="4"
                              mt={['2', '2', '0']}
                            />
                          </Link>
                        )}
                        {userLogged?.permissions.includes('remove-hub') && (
                          <Icon
                            cursor="pointer"
                            as={FiTrash2}
                            fontSize="20"
                            ml="4"
                            mt={['2', '2', '0']}
                            onClick={() => handleDeleteHub(hub.id)}
                          />
                        )}
                      </>
                    )}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>
      <Pagination
        handlePageChange={handlePageChange}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </>
  )
}
