import { Box, Button, Divider, Grid, GridItem, Icon, IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";
import { getAso } from "api/asos/getAso";
import { GetAsosResponse } from "api/asos/getAsosReport";
import { inactivateAso } from "api/asos/inactivateAso";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaExternalLinkAlt, FaTimes } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { asoCollaboratorTypeMap, asoTypeMap } from "utils/asoMappers";
import { captalize } from "utils/captalize";
import { AsoStatus } from "./AsoStatus";
import { SendAsoAttachment } from "./SendAsoAttachment";

interface AsoDetailProps {
  asoId: string
  onClose: () => void
}

export function AsoDetail({
  asoId,
  onClose
}: AsoDetailProps) {
  const { userLogged } = useAuth()

  const { data: asoData, isLoading: isAsoDataLoading } = useQuery({
    queryKey: ['aso', asoId],
    queryFn: () => getAso({ asoId })
  })

  const userHasSendAsoAttachmentPermission = userLogged?.permissions.includes('send-aso-attachment')
  const userHasInactivateAsoPermission = userLogged?.permissions.includes('inactivate-aso')

  const queryClient = useQueryClient()

  const { mutateAsync: inactivateAsoFn } = useMutation({
    mutationFn: inactivateAso,
    onSuccess(_data, { asoId }) {
      const cachedAsos = queryClient.getQueriesData<GetAsosResponse>({
        queryKey: ['asos']
      })
      queryClient.invalidateQueries({ queryKey: 'pending-asos' })
      queryClient.invalidateQueries({ queryKey: 'active-asos' })
      queryClient.invalidateQueries({ queryKey: 'inactive-asos' })
      queryClient.invalidateQueries({ queryKey: 'asos' })

      cachedAsos.forEach(([cachedKey, cachedData]) => {

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          asos: cachedData.asos.map((aso) => {
            if (aso.id === asoId) {
              return {
                ...aso,
                status: 'inactive',
                inactivated_at: new Date().toISOString(),
              }
            }

            return aso
          })
        })
      })
    },
  })

  const { promiseMessage } = useToastify()

  async function handleInactivateAso() {
    await promiseMessage(inactivateAsoFn({
      asoId
    }), 'Aso inativada!')
  }

  return (
    <ModalContent>
      {isAsoDataLoading ? (
        <ModalBody
          p="6"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
        </ModalBody>
      ) : (
        <>
          <ModalHeader letterSpacing="tight">Detalhes do ASO</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Grid
              templateColumns={
                (asoData?.aso.status === 'pending' && userHasSendAsoAttachmentPermission)
                  ? "1fr auto 1fr"
                  : "1fr"
              }
              gap={6}
            >
              <GridItem>
                <TableContainer>
                  <Table size="sm">
                    <Tbody>
                      <Tr>
                        <Td>Regional</Td>
                        <Td isNumeric>{asoData.aso.regional ? asoData.aso.regional.code.toUpperCase() : '-'}</Td>
                      </Tr>
                      <Tr>
                        <Td>Status</Td>
                        <Td display="flex" justifyContent="flex-end">
                          <AsoStatus status={asoData.aso.status} />
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Colaborador</Td>
                        <Td isNumeric maxW="150px" overflow="hidden" textOverflow="ellipsis">
                          {captalize(`${asoData.aso.collaborator_name}`)}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Responsável</Td>
                        <Td isNumeric maxW="150px" overflow="hidden" textOverflow="ellipsis">
                          {asoData.aso.regional ? captalize(`${asoData.aso.regional.responsible.firstname} ${asoData.aso.regional.responsible.lastname}`) : '-'}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Data da solicitação</Td>
                        <Td isNumeric>{format(new Date(asoData.aso.created_at), 'dd/MM/yyyy')}</Td>
                      </Tr>
                      {asoData?.aso?.realized_at && (
                        <Tr>
                          <Td>Data de realização</Td>
                          <Td isNumeric>{format(new Date(asoData.aso.realized_at), 'dd/MM/yyyy')}</Td>
                        </Tr>
                      )}
                      {asoData?.aso?.due_date && (
                        <Tr>
                          <Td>Data de vencimento</Td>
                          <Td isNumeric>{format(new Date(asoData.aso.due_date), 'dd/MM/yyyy')}</Td>
                        </Tr>
                      )}
                      <Tr>
                        <Td>Tipo</Td>
                        <Td isNumeric>{asoTypeMap[asoData.aso.type]}</Td>
                      </Tr>
                      <Tr>
                        <Td>Tipo de colaborador</Td>
                        <Td isNumeric>{asoCollaboratorTypeMap[asoData.aso.collaborator_type]}</Td>
                      </Tr>
                      {asoData?.aso?.attachment && (
                        <Tr>
                          <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">{asoData?.aso?.attachment?.title}</Td>
                          <Td isNumeric>
                            <IconButton
                              aria-label="Abrir anexo"
                              as={Link}
                              href={asoData?.aso?.attachment?.link}
                              icon={<FaExternalLinkAlt />}
                              size="sm"
                              isExternal
                            />
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
                {userHasInactivateAsoPermission && asoData?.aso?.status !== 'inactive' && (
                  <Box display="flex" justifyContent="flex-end" mt={4}>
                    <Button
                      size="sm"
                      leftIcon={<Icon as={FaTimes} />}
                      colorScheme="blue"
                      onClick={handleInactivateAso}
                    >
                      Inativar
                    </Button>
                  </Box>
                )}
              </GridItem>
              {userHasSendAsoAttachmentPermission && asoData?.aso?.status === 'pending' && (
                <>
                  <Box>
                    <Divider orientation="vertical" />
                  </Box>
                  <GridItem>
                    <SendAsoAttachment
                      asoId={asoData?.aso?.id}
                      onCloseModal={onClose}
                    />
                  </GridItem>
                </>
              )}
            </Grid>
          </ModalBody>
        </>
      )}
    </ModalContent>
  )
}
