import { useEffect } from "react";
import { Button, Flex, FormLabel, Icon, Stack, Link as ChakraLink, Text } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { PurchaseOrder, PurchaseOrderQuoteType } from "hooks/purchaseOrder/dtos/PurchaseOrder";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { FaPlus, FaTrash } from "react-icons/fa";
import { FiFile } from "react-icons/fi";

interface userOptions {
  key: string
  value: string
  showOption: string
}

interface PurchaseOrderQuoteProvidersProps {
  purchaseOrder: PurchaseOrder
  users: userOptions[]
}

interface PurchaseOrderQuoteProvidersFormInputProps {
  providerName: string
  budgetAttachment: FileList
}

export interface QuotePurchaseOrderFormInputs {
  quoteType: PurchaseOrderQuoteType
  quoteProviders: PurchaseOrderQuoteProvidersFormInputProps[]
  responsibleForApproval: string
}

const purchaseOrderQuoteTypeOptions = [
  { key: '0', value: 'PMC', showOption: 'PMC' },
  { key: '1', value: 'ENDOMARKETING E IMÓVEL', showOption: 'ENDOMARKETING E IMÓVEL' },
  { key: '2', value: 'OUTRA', showOption: 'OUTRA' },
]


export function QuotePurchaseOrderForm({ purchaseOrder, users }: PurchaseOrderQuoteProvidersProps) {

  const {
    register,
    control,
    setValue,
    formState: {
      errors
    }
  } = useFormContext<QuotePurchaseOrderFormInputs>()

  const isPurchaseOrderTypePmc = purchaseOrder.purchase_description === 'PMC'

  useEffect(() => {
    if (isPurchaseOrderTypePmc && purchaseOrder) {
      setValue('quoteType', 'PMC')
    }
  }, [isPurchaseOrderTypePmc, purchaseOrder, setValue])

  const {
    fields: quoteProvidersFields,
    append: appendQuoteProviderField,
    remove: removeQuoteProviderField
  } = useFieldArray({
    control,
    name: 'quoteProviders'
  })

  const [quoteProviders] = useWatch({
    control,
    name: ['quoteProviders']
  })


  function handleAppendQuoteProviderField() {
    appendQuoteProviderField({
      providerName: '',
      budgetAttachment: undefined
    })
  }


  return (
    <Flex direction="column" gap={6} align="center">

      <Select
        {...register('quoteType')}
        name="quoteType"
        options={purchaseOrderQuoteTypeOptions}
        label="Tipo"
        placeholder="Selecione uma opção..."
        error={errors.quoteType}
        isDisabled={isPurchaseOrderTypePmc}
        required
      />

      <Select
        {...register('responsibleForApproval')}
        name="responsibleForApproval"
        options={users}
        label="Responsável pela aprovação"
        placeholder="Selecione uma opção..."
        error={errors.responsibleForApproval}
        required
      />
      {quoteProvidersFields.map((field, index) => {
        return (
          <Stack
            w='full'
            key={field.id}
            spacing={4}
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register(`quoteProviders.${index}.providerName`)}
              name={`quoteProviders.${index}.providerName`}
              label='Fornecedor'
              error={
                errors.quoteProviders
                  ? errors?.quoteProviders[index]?.providerName
                  : undefined
              }
              required
            />
            <Stack
              w='full'
              spacing={4}
              direction={['column', 'column', 'row']}
            >
              <Input
                {...register(`quoteProviders.${index}.budgetAttachment`)}
                name={`quoteProviders.${index}.budgetAttachment`}
                type='file'
                hidden
                label='Anexo'
                error={
                  errors.quoteProviders
                    ? errors?.quoteProviders[index]?.budgetAttachment
                    : undefined
                }
              >
                <Button
                  leftIcon={<Icon as={FiFile} />}
                  as={FormLabel}
                  htmlFor={`quoteProviders.${index}.budgetAttachment`}
                  h='48px'
                  _hover={{
                    cursor: 'pointer'
                  }}
                  size='sm'
                >
                  Escolha um arquivo
                </Button>
              </Input>
              {quoteProviders && quoteProviders[index]?.budgetAttachment.length > 0 && (

                <ChakraLink
                  p='8'
                  href={URL.createObjectURL(quoteProviders[index]?.budgetAttachment[0])}
                  isExternal
                >
                  {quoteProviders[index]?.budgetAttachment[0]?.name} (Visualizar)

                </ChakraLink>
              )}
            </Stack>
            <Stack w='min' pt='8'>
              <Button
                colorScheme='red'
                variant='ghost'
                onClick={() => removeQuoteProviderField(index)}
                w='full'
              >
                <Icon as={FaTrash} />
              </Button>
            </Stack>
          </Stack>
        )
      })}

      {quoteProvidersFields.length < 3 && (
        <Flex direction="column" w="full" gap="2">
          <Button
            alignSelf='flex-start'
            leftIcon={<Icon as={FaPlus} />}
            onClick={handleAppendQuoteProviderField}
          >
            Adicionar
          </Button>
          {errors.quoteProviders && (
            <Text fontSize="sm" color="red.500">{errors.quoteProviders.message}</Text>
          )}
        </Flex>
      )}
    </Flex>
  )
}
