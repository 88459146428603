import { Box, FormLabel, Text, VStack } from "@chakra-ui/react";
import { getSupportSubject } from "api/supports/getSupportSubject";
import { AttachmentTableRowField } from "pages/Support/AttachmentTableRowField";
import { useEffect, useReducer } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useQuery } from "react-query";
import { CreateSupportSchema } from "../CreateSupport";
import { AttachmentState, SetAttachmentAction } from "../InternalSupportForm";
import { BuildingMaintenanceRequestForm } from "./BuildingMaintenanceRequestForm";
import { EmergencySuppliesRequestForm } from "./EmergencySuppliesRequestForm";
import { ExistingCollectorSuppliesForm } from "./ExistingCollectorSuppliesForm";
import { HubsSuppliesQuantificationForm } from "./HubsSuppliesQuantificationForm";
import { NewCollectorSuppliesForm } from "./NewCollectorSuppliesForm";
import { PatrimonyRequestForm } from "./PatrimonyRequestForm";
import { ProviderNeedsRequestForm } from "./ProviderNeedsRequestForm";
import { ServiceRequestForm } from "./ServiceRequestForm";
import { VehicleRentalForm } from "./VehicleRentalForm";
import { WarehouseSuppliesRequestForm } from "./WarehouseSuppliesRequestForm";


interface CreatePurchaseAndWarehouseProps {
  reducer: (state: AttachmentState[], action: SetAttachmentAction) => AttachmentState[];
}

export function CreatePurchaseAndWarehouse({ reducer }: CreatePurchaseAndWarehouseProps) {

  const { control, setValue } = useFormContext<CreateSupportSchema>()
  const [attachments, setAttachments] = useReducer(reducer, [] as AttachmentState[])

  useEffect(() => {
    if (attachments.length !== 0) {
      setValue(`formData.attachments.label`, 'Anexos');
      setValue(`formData.attachments.value`, JSON.stringify(attachments));
      setValue(`formData.attachments.type`, 'attachments');
    }
  }, [attachments, setValue])

  const {
    fields: attachmentsFields,
    append: appendAttachmentsField,
    remove: removeAttachmentsField,
  } = useFieldArray({
    control,
    name: 'attachments'
  })

  const [supportSubjectId, attachmentsSelected] = useWatch({
    control,
    name: ['subject', 'attachments']
  })

  const {
    data: supportSubjectResult
  } = useQuery({
    queryFn: () => getSupportSubject({ supportSubjectId }),
    queryKey: ['support-subject', supportSubjectId],
    enabled: !!supportSubjectId
  })


  const subjectIsVehicleRental = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Aluguel de veículo'.toLowerCase()
  const subjectIsNewCollectorSupplies = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Envio de insumos para novo coletador'.toLowerCase()
  const subjectIsProviderNeedsRequest = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Solicitação de necessidade de fornecedor'.toLowerCase()
  const subjectIsBuildingMaintenanceRequest = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Manutenção/Melhoria predial'.toLowerCase()
  const subjectIsHubsSuppliesQuantification = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Quantitativo insumos a enviar aos Hubs'.toLowerCase()
  const subjectIsEmergencySuppliesRequest = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Solicitação de envio insumo emergencial'.toLowerCase()
  const subjectIsWarehouseSuppliesRequest = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Solicitação de material do almoxarifado'.toLowerCase()
  const subjectIsServiceRequest = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Solicitação de serviço'.toLowerCase()
  const subjectIsPatrimonyRequest = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Solicitação de patrimônio'.toLowerCase()
  const subjectIsExistingCollectorSupplies = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Envio de insumo para coletador já existente'.toLowerCase()



  return (
    <Box w='full'>
      {subjectIsVehicleRental && (
        <VehicleRentalForm />
      )}

      {subjectIsNewCollectorSupplies && (
        <NewCollectorSuppliesForm />
      )}

      {subjectIsProviderNeedsRequest && (
        <ProviderNeedsRequestForm />
      )}

      {subjectIsBuildingMaintenanceRequest && (
        <BuildingMaintenanceRequestForm />
      )}

      {subjectIsHubsSuppliesQuantification && (
        <HubsSuppliesQuantificationForm />
      )}

      {subjectIsEmergencySuppliesRequest && (
        <EmergencySuppliesRequestForm />
      )}

      {subjectIsWarehouseSuppliesRequest && (
        <WarehouseSuppliesRequestForm />
      )}

      {subjectIsServiceRequest && (
        <ServiceRequestForm />
      )}

      {subjectIsExistingCollectorSupplies && (
        <ExistingCollectorSuppliesForm />
      )}

      {subjectIsPatrimonyRequest && (
        <PatrimonyRequestForm />
      )}

      {subjectIsHubsSuppliesQuantification && (

        <>
          <FormLabel fontSize='sm' mt={3}>
            Anexos (Opcional)
            <Text as="sup" color="red.500">*</Text>
          </FormLabel><VStack spacing={3}>
            {attachmentsFields.map((field, index) => {

              const isFilledField = Boolean(attachmentsSelected[index]?.file?.length);
              return (
                <AttachmentTableRowField
                  key={field.id}
                  index={index}
                  onSetAttachmentId={setAttachments}
                  isFilledField={isFilledField}
                  onAppendField={() => appendAttachmentsField({})}
                  onRemoveField={() => removeAttachmentsField(index)} />
              );
            })}
          </VStack>
        </>
      )}



    </Box>
  )
}
