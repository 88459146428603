import { Avatar, Box, Button, Divider, Flex, Grid, GridItem, Icon, IconButton, Image, Input, InputGroup, InputLeftElement, List, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Portal, Text, useMediaQuery, } from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import loglifeLogo from 'assets/images/loglife-logo.png';
import {
  FaAngleDoubleRight,
  FaBell,
  FaBusinessTime,
  FaCalendarDay,
  FaClipboardCheck,
  FaCog,
  FaDollarSign,
  FaExchangeAlt,
  FaExclamationTriangle,
  FaFileCode,
  FaHeadset,
  FaHome,
  FaLock,
  FaPeopleArrows,
  FaPlus,
  FaRegCommentDots,
  FaSearch,
  FaStamp,
  FaTags,
  FaUpload,
  FaUser,
  FaUserCog,
  FaUserPlus,
  FaUsers,
  FaUserShield,
  FaUserTie
} from "react-icons/fa";
import { FaBagShopping, FaBookOpen, FaBox, FaBoxesStacked, FaBoxOpen, FaBug, FaBuildingCircleArrowRight, FaChartLine, FaChartSimple, FaCity, FaComments, FaDolly, FaDownload, FaFileCircleExclamation, FaFileCircleXmark, FaFileImport, FaFileInvoice, FaFileInvoiceDollar, FaFileMedical, FaHandHoldingDollar, FaHandshake, FaHeartPulse, FaHubspot, FaIdCard, FaList, FaListCheck, FaLocationDot, FaMapLocation, FaMoneyBillTransfer, FaMoneyCheckDollar, FaPen, FaPeopleCarryBox, FaPersonChalkboard, FaRegFilePdf, FaRightFromBracket, FaRoute, FaSackDollar, FaShip, FaSignsPost, FaSun, FaSyringe, FaTrello, FaTruckArrowRight, FaTruckFast, FaTruckPlane, FaTruckRampBox, FaUmbrellaBeach, FaWarehouse } from 'react-icons/fa6'
import { GrTask } from 'react-icons/gr'
import { LuBell } from 'react-icons/lu'
import { TbAlertTriangle, TbCalendar, TbChecklist, TbTimelineEventX } from 'react-icons/tb'
import { IconType } from "react-icons";
import { useAuth } from "hooks/auth/useAuth";
import { Link } from "react-router-dom";
import { RiBuilding2Line, RiExternalLinkFill } from "react-icons/ri";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia"
import { CgFileDocument } from "react-icons/cg"
import { BiNetworkChart } from "react-icons/bi"
import { Submenu } from "./components/Submenu";
import { useUserTasks } from "hooks/user/useUserTasks";
import { captalize } from "utils/captalize";
import { useQuery } from "react-query";
import { getUserNotifications } from "api/users/getUserNotifications";
import { formatDistance } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Notification } from "components/Notification";
import { GoInfo } from "react-icons/go";
import { handleChangeUrl } from "utils/handleChangeUrl";
import { CreateBug } from "./components/CreateBug";
import { HiDocument } from "react-icons/hi";
import { FiTag } from "react-icons/fi";
import { getDocumentsMenu } from "api/documents/getDocumentsMenu";

type Option = {
  name: string
  icon?: IconType
  subMenus?: Option[]
  allowedPermissions?: string[]
  type?: string
  href?: string
  children?: ReactNode
}

const menuOptions: Option[] = [
  {
    name: 'Início',
    icon: FaHome,
    type: 'internal',
    href: '/'
  },
  {
    name: 'Operacional',
    icon: FaBusinessTime,
    allowedPermissions: [
      'access-business-service',
      'view-service-list',
      'view-service-consult',
      'add-single-service',
      'view-recurrent-service',
      'view-requested-service',
      'add-collect-service',
      'add-boarding-service',
      'valid-boarding-service',
      'add-allocated-service',
      'add-available-service',
      'validate-fiscal-retention',
      'add-landing-service',
      'view-maintenance-service',
      'add-delivery-service',
      'view-collects',
      'view-boards',
      'view-deliveries',
      'view-load-ratio-service',
      'view-shipping',
      'view-branch',
      'view-shipping-costs',
      'view-route-network',
      'view-city',
      'view-hubs',
      'view-holiday',
      'view-schedule-report',
      'view-cargo-in-transit-report',
      'view-spot-report',
      'view-maintenance-report',
      'view-occurrence-report',
      'view-business-services-schedule'
    ],
    subMenus: [
      // {
      //   name: 'Serviços Business',
      //   icon: FaTruckFast,
      //   allowedPermissions: ['access-business-service'],
      //   subMenus: [
      //     {
      //       name: 'Agenda',
      //       icon: FaList,
      //       allowedPermissions: ['view-business-services-schedule'],
      //       type: 'internal',
      //       href: '/servicos/business/agenda'
      //     },
      //     {
      //       name: 'Solicitados',
      //       icon: FaList,
      //       allowedPermissions: ['view-requested-business-service'],
      //       type: 'internal',
      //       href: '/servicos-solicitados-business'
      //     },
      //     {
      //       name: 'Recorrentes',
      //       icon: FaList,
      //       allowedPermissions: ['view-requested-business-service'],
      //       type: 'internal',
      //       href: '/servicos-solicitados-business/recorrentes'
      //     },
      //     {
      //       name: 'Consultar',
      //       icon: FaSearch,
      //       allowedPermissions: ['consult-business-service'],
      //       type: 'internal',
      //       href: '/servicos-business/consultar'
      //     },
      //     {
      //       name: 'Solicitar',
      //       icon: FaPlus,
      //       allowedPermissions: ['add-requested-business-service'],
      //       type: 'internal',
      //       href: '/servico-business/solicitar'
      //     },
      //     {
      //       name: 'Coletas',
      //       icon: FaList,
      //       allowedPermissions: ['add-collect-business-service'],
      //       type: 'internal',
      //       href: '/servicos-business/coletas'
      //     },
      //     {
      //       name: 'Entregas',
      //       icon: FaList,
      //       allowedPermissions: ['add-delivery-business-service'],
      //       type: 'internal',
      //       href: '/servicos-business/entregas'
      //     },
      //   ]
      // },
      {
        name: 'Serviços Express',
        icon: FaTruckPlane,
        allowedPermissions: [
          'view-service-list',
          'view-service-consult',
          'add-single-service',
          'view-recurrent-service',
          'view-requested-service',
          'add-collect-service',
          'add-boarding-service',
          'valid-boarding-service',
          'add-allocated-service',
          'add-available-service',
          'validate-fiscal-retention',
          'add-landing-service',
          'view-maintenance-service',
          'add-delivery-service',
          'view-collects',
          'view-boards',
          'view-deliveries',
          'view-load-ratio-service',
          'view-services-with-step-change'
        ],
        subMenus: [
          {
            name: 'Planejamento',
            icon: FaListCheck,
            allowedPermissions: [
              'add-single-service',
              'add-recurrent-service',
              'view-recurrent-service',
              'execute-recurrent-service',
              'view-requested-service',
              'validate-new-collect-service-addresses',
              'service-validate-cancel-collect-address-request',
              'service-validate-cancel-request'
            ],
            subMenus: [
              {
                name: 'Solicitar',
                icon: FaPlus,
                allowedPermissions: ['add-single-service'],
                type: 'internal',
                href: '/servico/unico/solicitar'
              },
              {
                name: 'Solicitar Recorrente',
                icon: FaPlus,
                allowedPermissions: ['add-recurrent-service'],
                type: 'internal',
                href: '/servico/recorrente/solicitar'
              },
              {
                name: 'Recorrentes',
                icon: FaList,
                allowedPermissions: ['view-recurrent-service'],
                type: 'internal',
                href: '/servicos/recorrentes'
              },
              {
                name: 'Executar Recorrentes',
                icon: FaList,
                allowedPermissions: ['execute-recurrent-service'],
                type: 'internal',
                href: '/servicos/recorrentes/executar'
              },
              {
                name: 'Solicitados',
                icon: FaList,
                allowedPermissions: ['view-requested-service'],
                type: 'internal',
                href: '/servicos/solicitados'
              },
              {
                name: 'Novos Endereços Solicitados',
                icon: FaList,
                allowedPermissions: ['validate-new-collect-service-addresses'],
                type: 'internal',
                href: '/servicos/novos-enderecos'
              },
              {
                name: 'Solicitações de Cancelamento (Validar)',
                icon: FaList,
                allowedPermissions: [
                  'service-validate-cancel-collect-address-request',
                  'service-validate-cancel-request'
                ],
                type: 'internal',
                href: '/servicos/solicitacoes-cancelamentos'
              },
            ]
          },
          {
            name: 'Monitoramento',
            icon: FaRoute,
            subMenus: [
              {
                name: 'Kanban',
                icon: FaTrello,
                allowedPermissions: ['view-service-kanban'],
                type: 'internal',
                href: '/servicos/kanban'
              },
              {
                name: 'Consultar',
                icon: FaSearch,
                allowedPermissions: ['view-service-consult'],
                type: 'internal',
                href: '/servicos-express/consultar'
              },
              {
                name: 'Em Andamento',
                icon: FaList,
                allowedPermissions: ['view-service-list'],
                type: 'internal',
                href: '/servicos-em-andamento'
              },
              {
                name: 'Editar em Andamento',
                icon: FaPen,
                allowedPermissions: ['edit-progress-service'],
                type: 'internal',
                href: '/servicos-express/editar'
              },
              {
                name: 'Coletas',
                icon: FaList,
                allowedPermissions: [
                  'add-collect-service'
                ],
                type: 'internal',
                href: '/servicos/coletas'
              },
              {
                name: 'Embarques',
                icon: FaList,
                allowedPermissions: [
                  'add-boarding-service'
                ],
                type: 'internal',
                href: '/servicos/embarques'
              },
              {
                name: 'Validar sem Sucesso',
                icon: FaList,
                allowedPermissions: ['validate-unsuccess-collect-service', 'validate-unsuccess-board-service'],
                type: 'internal',
                href: '/servicos/sem-sucesso'
              },
              {
                name: 'Validar sem QrCode',
                icon: FaList,
                allowedPermissions: ['validate-finished-landing-without-qr-code', 'validate-finished-delivery-without-qr-code'],
                type: 'internal',
                href: '/servicos/validar/sem-qr'
              },
              {
                name: 'Validar alteração de fase',
                icon: FaList,
                allowedPermissions: ['view-services-with-step-change'],
                type: 'internal',
                href: '/servicos/validar/step'
              },
              {
                name: 'Horário Real Coleta/Entrega (Validar)',
                icon: FaList,
                allowedPermissions: [
                  'service-validate-real-time'
                ],
                type: 'internal',
                href: '/servicos/validar/tempo-real-coleta-entrega'
              },
              {
                name: 'Embarques a Validar',
                icon: FaList,
                allowedPermissions: [
                  'valid-boarding-service'
                ],
                type: 'internal',
                href: '/servicos/embarques/validar'
              },
              {
                name: 'Alocar',
                icon: FaList,
                allowedPermissions: [
                  'add-allocated-service'
                ],
                type: 'internal',
                href: '/servicos/alocar'
              },
              {
                name: 'Disponibilizar',
                icon: FaList,
                allowedPermissions: [
                  'add-available-service'
                ],
                type: 'internal',
                href: '/servicos/disponibilizar/desembarque'
              },
              {
                name: 'Retenções Fiscais',
                icon: FaList,
                allowedPermissions: [
                  'validate-fiscal-retention'
                ],
                type: 'internal',
                href: '/servicos/retencoes-fiscais'
              },
              {
                name: 'Desembarques',
                icon: FaList,
                allowedPermissions: [
                  'add-landing-service'
                ],
                type: 'internal',
                href: '/servicos/desembarques'
              },
              {
                name: 'Manutenções',
                icon: FaList,
                allowedPermissions: [
                  'view-maintenance-service'
                ],
                type: 'internal',
                href: '/servicos/manutencoes'
              },
              {
                name: 'Entregas',
                icon: FaList,
                allowedPermissions: [
                  'add-delivery-service'
                ],
                type: 'internal',
                href: '/servicos/entregas'
              },
              {
                name: 'Relação de Cargas em Trânsito',
                icon: FaList,
                allowedPermissions: [
                  'view-load-ratio-service'
                ],
                type: 'internal',
                href: '/servicos/relacao/cargas'
              },
              {
                name: 'Relação de serviços',
                icon: FaList,
                allowedPermissions: ['view-collects', 'view-boards', 'view-deliveries'],
                type: 'internal',
                href: '/servicos/relacoes'
              },
            ]
          },
        ]
      },
      {
        name: 'Transportadoras',
        icon: FaShip,
        subMenus: [
          {
            name: 'Nova',
            icon: FaPlus,
            allowedPermissions: ['add-shipping'],
            type: 'internal',
            href: '/transportadora/adicionar'
          },
          {
            name: 'Cadastradas',
            icon: FaList,
            allowedPermissions: ['view-shipping'],
            type: 'internal',
            href: '/transportadoras'
          },
          {
            name: 'Bases',
            icon: RiBuilding2Line,
            allowedPermissions: ['view-branch'],
            subMenus: [
              {
                name: 'Nova',
                icon: FaPlus,
                allowedPermissions: ['add-branch'],
                type: 'internal',
                href: '/transportadoras/base/adicionar'
              },
              {
                name: 'Cadastradas',
                icon: FaList,
                type: 'internal',
                href: '/transportadoras/bases'
              }
            ]
          },
          {
            name: 'Custo de Transportadoras',
            icon: LiaFileInvoiceDollarSolid,
            allowedPermissions: ['view-shipping-costs'],
            subMenus: [
              {
                name: 'Novo',
                icon: FaPlus,
                allowedPermissions: ['create-shipping-cost'],
                type: 'internal',
                href: '/transportadoras/custos/adicionar'
              },
              {
                name: 'Cadastrados',
                icon: FaList,
                type: 'internal',
                href: '/transportadoras/custos'
              }
            ]
          },
          {
            name: 'Malhas',
            icon: BiNetworkChart,
            allowedPermissions: ['view-route-network'],
            subMenus: [
              {
                name: 'Nova',
                icon: FaPlus,
                allowedPermissions: ['add-route-network'],
                type: 'internal',
                href: '/malha/enviar'
              },
              {
                name: 'Cadastrados',
                icon: FaList,
                type: 'internal',
                href: '/malhas'
              }
            ]
          },
        ]
      },
      {
        name: 'Cidades',
        icon: FaCity,
        subMenus: [
          {
            name: 'Nova',
            icon: FaPlus,
            allowedPermissions: ['add-city'],
            type: 'internal',
            href: '/cidade/adicionar'
          },
          {
            name: 'Cadastradas',
            icon: FaList,
            allowedPermissions: ['view-city'],
            type: 'internal',
            href: '/cidades'
          },
          {
            name: 'Hubs',
            icon: FaHubspot,
            allowedPermissions: ['view-hub'],
            subMenus: [
              {
                name: 'Novo',
                icon: FaPlus,
                allowedPermissions: ['add-hub'],
                type: 'internal',
                href: '/hub/adicionar'
              },
              {
                name: 'Cadastrados',
                icon: FaList,
                type: 'internal',
                href: '/hubs'
              }
            ]
          },
          {
            name: 'Feriados',
            icon: FaUmbrellaBeach,
            allowedPermissions: ['view-holiday'],
            subMenus: [
              {
                name: 'Novo',
                icon: FaPlus,
                allowedPermissions: ['add-holiday'],
                type: 'internal',
                href: '/feriado/adicionar'
              },
              {
                name: 'Cadastrados',
                icon: FaList,
                type: 'internal',
                href: '/feriados'
              }
            ]
          },
        ]
      },
      {
        name: 'Relatórios',
        icon: FaChartSimple,
        subMenus: [
          {
            name: 'Desvio de budget de transferência',
            allowedPermissions: ['view-transfer-budget-deviation-report'],
            type: 'internal',
            href: '/relatorios/servicos/budget-de-transferencia'
          },
          {
            name: 'Agendamentos',
            allowedPermissions: ['view-schedule-report'],
            type: 'internal',
            href: '/relatorios/agendamentos'
          },
          {
            name: 'Cargas em Trânsito',
            allowedPermissions: ['view-cargo-in-transit-report'],
            type: 'internal',
            href: '/relatorios/cargas'
          },
          {
            name: 'Pontos',
            allowedPermissions: ['view-spot-report'],
            type: 'internal',
            href: '/relatorios-novos/pontos'
          },
          {
            name: 'Manutenção',
            allowedPermissions: ['view-maintenance-report'],
            type: 'internal',
            href: '/relatorios/servicos/manutencao'
          },
          {
            name: 'Relatório de Ocorrências',
            allowedPermissions: ['view-occurrence-report'],
            type: 'internal',
            href: '/relatorios/servicos/ocorrencia'
          },
          {
            name: 'Custo de Transportadora',
            allowedPermissions: ['view-shippings-costs-report'],
            type: 'internal',
            href: '/custo-transportadora/relatorio'
          },
        ]
      }
    ]
  },
  {
    name: 'Recursos Humanos',
    icon: FaUsers,
    subMenus: [
      {
        name: 'Asos',
        icon: FaFileMedical,
        allowedPermissions: ['view-asos'],
        type: 'internal',
        href: '/asos'
      },
      {
        name: 'Padronizações',
        icon: FaListCheck,
        allowedPermissions: ['view-standardizations'],
        type: 'internal',
        href: '/padronizacoes'
      },
      {
        name: 'Vacinas',
        icon: FaSyringe,
        allowedPermissions: ['view-vaccines'],
        type: 'internal',
        href: '/vacinas'
      },
      {
        name: 'Treinamentos',
        icon: FaPersonChalkboard,
        allowedPermissions: ['view-trainings'],
        type: 'internal',
        href: '/controle/treinamentos'
      },
      {
        name: 'Treinamentos LLM',
        icon: FaPersonChalkboard,
        allowedPermissions: ['view-trainings-llm'],
        type: 'internal',
        href: '/controle/treinamentos/llm'
      },
      {
        name: 'Onboardings',
        icon: FaPersonChalkboard,
        allowedPermissions: ['view-onboardings'],
        type: 'internal',
        href: '/onboardings',
      },
      {
        name: 'Internos CLT',
        icon: FaIdCard,
        subMenus: [
          {
            name: 'Cadastrados',
            icon: FaList,
            allowedPermissions: ['view-intern-clts'],
            type: 'internal',
            href: '/internos-clt'
          },
          {
            name: 'Cargos',
            icon: FaUserTie,
            allowedPermissions: ['view-intern-clts-offices'],
            type: 'internal',
            href: '/internos-clt/cargos'
          },
        ]
      },
      {
        name: 'Coletadores',
        icon: FaTruckRampBox,
        subMenus: [
          {
            name: 'Novo',
            icon: FaPlus,
            allowedPermissions: ['add-collector'],
            type: 'internal',
            href: '/coletador/adicionar'
          },
          {
            name: 'Cadastrados',
            icon: FaList,
            allowedPermissions: ['view-collector'],
            type: 'internal',
            href: '/coletadores'
          },
          {
            name: 'Custo Express',
            icon: FaSackDollar,
            allowedPermissions: ['view-collector-cost'],
            subMenus: [
              {
                name: 'Novo',
                icon: FaPlus,
                allowedPermissions: ['add-collector-cost'],
                type: 'internal',
                href: '/custos/coletador/adicionar'
              },
              {
                name: 'Cadastrados',
                icon: FaPlus,
                type: 'internal',
                href: '/custos/coletadores'
              },
            ]
          },
          {
            name: 'Custo Business',
            icon: FaSackDollar,
            allowedPermissions: ['view-business-costs'],
            type: 'internal',
            href: '/custos/business'
          },
          {
            name: 'Extras/Descontos',
            icon: FaMoneyBillTransfer,
            allowedPermissions: [
              'view-collector-extra-discount'
            ],
            subMenus: [
              {
                name: 'Novo',
                icon: FaPlus,
                allowedPermissions: ['create-collector-extra-discount'],
                type: 'internal',
                href: '/extra-desconto/coletador/criar'
              },
              {
                name: 'Cadastrados',
                icon: FaList,
                type: 'internal',
                href: '/extras-descontos/coletadores'
              }
            ]
          },
        ]
      },
      {
        name: 'Motoristas',
        icon: FaUsers,
        allowedPermissions: ['view-driver'],
        subMenus: [
          {
            name: 'Novo',
            icon: FaPlus,
            allowedPermissions: ['add-driver'],
            type: 'internal',
            href: '/motorista/adicionar'
          },
          {
            name: 'Cadastrados',
            icon: FaList,
            type: 'internal',
            href: '/motoristaS'
          }
        ]
      },
      {
        name: 'Motoristas CLT',
        icon: FaUsers,
        allowedPermissions: [
          'view-clt-drivers',
          'view-clt-driver-extra-discount'
        ],
        subMenus: [
          {
            name: 'Novo',
            icon: FaPlus,
            allowedPermissions: ['create-clt-driver'],
            type: 'internal',
            href: '/motorista-clt/registrar'
          },
          {
            name: 'Cadastrados',
            icon: FaList,
            allowedPermissions: ['view-clt-drivers'],
            type: 'internal',
            href: '/motoristas-clt'
          },
          {
            name: 'Extras/Descontos',
            icon: FaMoneyBillTransfer,
            allowedPermissions: ['view-clt-driver-extra-discount'],
            subMenus: [
              {
                name: 'Novo',
                icon: FaPlus,
                allowedPermissions: ['create-clt-driver-extra-discount'],
                type: 'internal',
                href: '/extra-desconto/motorista-clt/criar'
              },
              {
                name: 'Cadastrados',
                icon: FaList,
                type: 'internal',
                href: '/extras-descontos/motoristas-clt'
              }
            ]
          }
        ]
      },
      {
        name: 'Agregados',
        icon: FaUsers,
        allowedPermissions: [
          'view-aggregate',
          'view-aggregate-extra-discount'
        ],
        subMenus: [
          {
            name: 'Novo',
            icon: FaPlus,
            allowedPermissions: ['create-aggregate'],
            type: 'internal',
            href: '/agregado/criar'
          },
          {
            name: 'Cadastrados',
            icon: FaList,
            allowedPermissions: ['view-aggregate'],
            type: 'internal',
            href: '/agregados'
          },
          {
            name: 'Extras/Descontos',
            icon: FaMoneyBillTransfer,
            allowedPermissions: ['view-aggregate-extra-discount'],
            subMenus: [
              {
                name: 'Novo',
                icon: FaPlus,
                allowedPermissions: ['create-aggregate-extra-discount'],
                type: 'internal',
                href: '/extra-desconto/agregado/criar'
              },
              {
                name: 'Cadastrados',
                icon: FaList,
                type: 'internal',
                href: '/extras-descontos/agregados'
              }
            ]
          }
        ]
      },
      {
        name: 'Intercorrências CLT',
        icon: TbTimelineEventX,
        allowedPermissions: ['view-clt-intercurrences'],
        type: 'internal',
        href: '/intercorrencias-clt',
      },
      {
        name: 'Advertências',
        icon: TbAlertTriangle,
        allowedPermissions: ['view-advertences'],
        type: 'internal',
        href: '/advertencias',
      },
      {
        name: 'Período de Experiência',
        icon: TbCalendar,
        allowedPermissions: ['view-experience-periods'],
        type: 'internal',
        href: '/periodos-experiencia'
      },
      {
        name: 'Férias',
        icon: FaSun,
        allowedPermissions: ['view-vacations'],
        type: 'internal',
        href: '/ferias'
      },
      {
        name: 'Auditorias',
        icon: FaClipboardCheck,
        allowedPermissions: ['view-audits'],
        type: 'internal',
        href: '/auditorias'
      },
      {
        name: 'Feedbacks',
        icon: FaRegCommentDots,
        subMenus: [
          {
            name: 'Passivos',
            icon: FaRegCommentDots,
            allowedPermissions: ['view-passives-feedbacks'],
            type: 'internal',
            href: '/feedbacks-passivos/kanban'
          },
          {
            name: 'Ativos',
            icon: FaRegCommentDots,
            allowedPermissions: ['view-actives-feedbacks'],
            type: 'internal',
            href: '/feedbacks-ativos/kanban'
          },
        ]
      },
      {
        name: 'Relatórios',
        icon: FaChartSimple,
        allowedPermissions: [
          'view-asos-report',
          'view-standardizations-report',
          'view-vaccines-report',
          'view-trainings-report',
          'view-llm-trainings-report',
          'view-aggregate-report',
          'view-collector-report',
          'view-productivity-report',
          'view-usage-report',
          'view-onboardings-report',
          'view-advertences-report',
          'view-documents-report'
        ],
        subMenus: [
          {
            name: 'Asos',
            allowedPermissions: ['view-asos-report'],
            type: 'internal',
            href: '/asos/relatorio'
          },
          {
            name: 'Padronizações',
            allowedPermissions: ['view-standardizations-report'],
            type: 'internal',
            href: '/padronizacoes/relatorio'
          },
          {
            name: 'Vacinas',
            allowedPermissions: ['view-vaccines-report'],
            type: 'internal',
            href: '/vacinas/relatorio'
          },
          {
            name: 'Treinamentos',
            allowedPermissions: ['view-trainings-report'],
            type: 'internal',
            href: '/controle/treinamentos/relatorio'
          },
          {
            name: 'Treinamentos LLM',
            allowedPermissions: ['view-llm-trainings-report'],
            type: 'internal',
            href: '/controle/treinamentos/llm/relatorio'
          },
          {
            name: 'Onboardings',
            allowedPermissions: ['view-onboardings-report'],
            type: 'internal',
            href: '/onboardings/relatorio'
          },
          {
            name: 'Agregados',
            allowedPermissions: ['view-aggregate-report'],
            type: 'internal',
            href: '/relatorios/agregados'
          },
          {
            name: 'Coletador',
            allowedPermissions: ['view-collector-report'],
            type: 'internal',
            href: '/relatorios/coletador'
          },
          {
            name: 'Coletador Backup',
            allowedPermissions: ['view-backup-collectors-report'],
            type: 'internal',
            href: '/relatorios/coletadores/backup'
          },
          {
            name: 'Produtividade',
            allowedPermissions: ['view-productivity-report'],
            type: 'internal',
            href: '/relatorios/produtividade'
          },
          {
            name: 'Utilização',
            allowedPermissions: ['view-usage-report'],
            type: 'internal',
            href: '/relatorios/utilizacao'
          },
          {
            name: 'Advertências',
            allowedPermissions: ['view-advertences-report'],
            type: 'internal',
            href: '/relatorio/advertencias'
          },
          {
            name: 'Documentos',
            allowedPermissions: ['view-documents-report'],
            type: 'internal',
            href: '/relatorio/documentos'
          },
        ]
      },
    ]
  },
  {
    name: 'Compras e Almoxarifado',
    icon: FaClipboardCheck,
    subMenus: [
      {
        name: 'Compras',
        icon: FaBagShopping,
        subMenus: [
          {
            name: 'Novo Pedido',
            icon: FaPlus,
            allowedPermissions: ['create-purchase-order'],
            type: 'internal',
            href: '/pedido-compra/criar'
          },
          {
            name: 'Pedidos',
            icon: FaList,
            allowedPermissions: ['view-purchase-orders'],
            type: 'internal',
            href: '/compras'
          },
          {
            name: 'Fornecedores',
            icon: FaPeopleCarryBox,
            allowedPermissions: [
              'view-provider',
              'view-general-provider'
            ],
            subMenus: [
              {
                name: 'Geral',
                icon: FaBuildingCircleArrowRight,
                subMenus: [
                  {
                    name: 'Novo',
                    icon: FaPlus,
                    allowedPermissions: ['create-general-provider'],
                    type: 'internal',
                    href: '/fornecedor-geral/criar'
                  },
                  {
                    name: 'Cadastrados',
                    icon: FaList,
                    type: 'internal',
                    href: '/fornecedor-geral'
                  }
                ]
              },
              {
                name: 'Gelo Seco',
                icon: FaBuildingCircleArrowRight,
                allowedPermissions: ['view-provider'],
                subMenus: [
                  {
                    name: 'Novo',
                    icon: FaPlus,
                    allowedPermissions: ['add-provider'],
                    type: 'internal',
                    href: '/fornecedor/adicionar'
                  },
                  {
                    name: 'Cadastrados',
                    icon: FaList,
                    type: 'internal',
                    href: '/fornecedores'
                  }
                ]
              },
              {
                name: 'Classificação Financeira',
                icon: FaMoneyBillTransfer,
                allowedPermissions: [
                  'view-financial-classification'
                ],
                subMenus: [
                  {
                    name: 'Nova',
                    icon: FaPlus,
                    allowedPermissions: ['create-financial-classification'],
                    type: 'internal',
                    href: '/classificacao-financeira/criar'
                  },
                  {
                    name: 'Cadastradas',
                    icon: FaList,
                    type: 'internal',
                    href: '/classificacao-financeira'
                  }
                ]
              }
            ]
          },
          {
            name: 'Materiais',
            icon: FaBoxesStacked,
            allowedPermissions: ['view-material'],
            subMenus: [
              {
                name: 'Novo',
                icon: FaPlus,
                allowedPermissions: ['create-material'],
                type: 'internal',
                href: '/material/criar'
              },
              {
                name: 'Cadastrados',
                icon: FaList,
                type: 'internal',
                href: '/materiais'
              }
            ]
          },
          {
            name: 'PMC',
            icon: FaChartLine,
            allowedPermissions: ['view-pmc'],
            subMenus: [
              {
                name: 'Novo',
                icon: FaPlus,
                allowedPermissions: ['create-pmc'],
                type: 'internal',
                href: '/pmc/criar'
              },
              {
                name: 'Novo Pedido de Compra de PMC',
                icon: FaPlus,
                allowedPermissions: ['create-purchase-order-pmc'],
                type: 'internal',
                href: '/pmc/pedido-compra/criar'
              },
              {
                name: 'Solicitadas',
                icon: FaList,
                type: 'internal',
                href: '/pmcs'
              }
            ]
          },
          {
            name: 'Produtos',
            icon: FaBoxOpen,
            allowedPermissions: ['view-product'],
            type: 'internal',
            href: '/produtos',
          },
          {
            name: 'RSM',
            icon: FaDolly,
            allowedPermissions: ['view-rsm'],
            subMenus: [
              {
                name: 'Solicitar',
                icon: FaPlus,
                allowedPermissions: ['create-rsm'],
                type: 'internal',
                href: '/rsm/solicitar'
              },
              {
                name: 'Solicitadas',
                icon: FaList,
                type: 'internal',
                href: '/rsms'
              }
            ]
          },
        ]
      },
      {
        name: 'Almoxarifado',
        icon: FaWarehouse,
        subMenus: [
          {
            name: 'Novo',
            icon: FaPlus,
            allowedPermissions: ['create-warehouse'],
            type: 'internal',
            href: '/almoxarifado/criar'
          },
          {
            name: 'Cadastrados',
            icon: FaList,
            allowedPermissions: ['view-warehouse'],
            type: 'internal',
            href: '/almoxarifados'
          },
          {
            name: 'Estoques',
            icon: FaBoxesStacked,
            allowedPermissions: ['view-stock'],
            subMenus: [
              {
                name: 'Insumos de Transporte',
                icon: FaList,
                type: 'internal',
                href: '/estoques'
              }
            ]
          },
          {
            name: 'Envio de Estoque',
            icon: FaTruckArrowRight,
            allowedPermissions: [
              'view-dispatch-stocks',
              'view-dispatch-stock-proposals'
            ],
            subMenus: [
              {
                name: 'Nova Proposta',
                icon: FaPlus,
                allowedPermissions: ['add-dispatch-stock-proposal'],
                type: 'internal',
                href: '/coletador/despacho/criar/proposta'
              },
              {
                name: 'Nova Proposta Manual',
                icon: FaPlus,
                allowedPermissions: ['add-dispatch-stock-proposal'],
                type: 'internal',
                href: '/coletador/despacho/criar/proposta/manual'
              },
              {
                name: 'Propostas',
                icon: FaList,
                allowedPermissions: ['view-dispatch-stock-proposal'],
                type: 'internal',
                href: '/estoques/despachos/propostas'
              },
              {
                name: 'Despachos',
                icon: FaList,
                allowedPermissions: ['view-dispatch-stocks'],
                type: 'internal',
                href: '/despachos'
              },
            ]
          },
          {
            name: 'Insumos',
            icon: FaBoxOpen,
            allowedPermissions: [
              'view-input'
            ],
            subMenus: [
              {
                name: 'Novo',
                icon: FaPlus,
                allowedPermissions: ['add-input'],
                type: 'internal',
                href: '/insumo/adicionar'
              },
              {
                name: 'Cadastrados',
                icon: FaList,
                type: 'internal',
                href: '/insumos'
              }
            ]
          },

          {
            name: 'Relatórios',
            icon: FaChartSimple,
            allowedPermissions: [
              'view-stock-control-report',
              'view-stock-report',
              'view-dispatch-stock-report'
            ],
            subMenus: [
              {
                name: 'Controle de Estoque',
                allowedPermissions: ['view-stock-control-report'],
                type: 'internal',
                href: '/relatorios/controle-estoque'
              },
              {
                name: 'Estoque',
                allowedPermissions: ['view-stock-report'],
                type: 'internal',
                href: '/relatorios/estoque'
              },
              {
                name: 'Despacho de Estoque',
                allowedPermissions: ['view-dispatch-stock-report'],
                type: 'internal',
                href: '/despachos/relatorio'
              }
            ]
          }
        ]
      },
      {
        name: 'Patrimônios Internos',
        icon: FaBox,
        subMenus: [
          {
            name: 'Tipos de Patrimônio',
            icon: FaList,
            type: 'internal',
            allowedPermissions: ['view-patrimony-types'],
            href: '/tipos/patrimonio'
          },
          {
            name: 'Patrimônios',
            icon: FaList,
            type: 'internal',
            allowedPermissions: ['view-patrimonies'],
            href: '/patrimonios'
          },
          {
            name: 'Patrimônios Solicitados',
            icon: FaList,
            type: 'internal',
            allowedPermissions: ['view-patrimony-requests'],
            href: '/patrimonios/solicitados'
          }
        ]
      },
    ]
  },
  {
    name: 'Comercial',
    icon: FaPeopleArrows,
    subMenus: [
      {
        name: 'Clientes',
        icon: FaHeartPulse,
        allowedPermissions: ['view-customer'],
        subMenus: [
          {
            name: 'Novo',
            icon: FaPlus,
            allowedPermissions: ['add-customer'],
            type: 'internal',
            href: '/cliente/adicionar'
          },
          {
            name: 'Cadastrados',
            icon: FaList,
            type: 'internal',
            href: '/clientes'
          },
          {
            name: 'Emails para o cliente',
            icon: FaList,
            allowedPermissions: ['view-customer-emails'],
            type: 'internal',
            href: '/emails/clientes'
          },
        ]
      },
      {
        name: 'Crms',
        icon: FaHandshake,
        allowedPermissions: ['view-crm'],
        subMenus: [
          {
            name: 'Novo',
            icon: FaPlus,
            allowedPermissions: ['create-crm'],
            type: 'internal',
            href: '/crm/criar'
          },
          {
            name: 'Cadastrados',
            icon: FaList,
            type: 'internal',
            href: '/crms'
          },
        ]
      },
      {
        name: 'Endereços',
        icon: FaSignsPost,
        allowedPermissions: ['view-address'],
        subMenus: [
          {
            name: 'Novo',
            icon: FaPlus,
            allowedPermissions: ['add-address'],
            type: 'internal',
            href: '/endereco/adicionar'
          },
          {
            name: 'Cadastrados',
            icon: FaList,
            type: 'internal',
            href: '/enderecos'
          },
          {
            name: 'Alterações Solicitadas',
            icon: FaList,
            allowedPermissions: ['view-addresses-with-requested-changes'],
            type: 'internal',
            href: '/enderecos/alteracoes-solicitadas'
          }
        ]
      },
      {
        name: 'Orçamentos',
        icon: FaFileInvoiceDollar,
        allowedPermissions: [
          'view-budget',
          'view-business-budget',
        ],
        subMenus: [
          {
            name: 'Novo Express',
            icon: FaPlus,
            allowedPermissions: ['add-budget'],
            type: 'internal',
            href: '/orcamento/adicionar'
          },
          {
            name: 'Express',
            icon: FaTruckFast,
            allowedPermissions: ['view-budget'],
            type: 'internal',
            href: '/orcamentos'
          },
          {
            name: 'Novo Business',
            icon: FaPlus,
            allowedPermissions: ['add-business-budget'],
            type: 'internal',
            href: '/orcamento-business/adicionar'
          },
          {
            name: 'Business',
            icon: FaBusinessTime,
            allowedPermissions: ['view-business-budget'],
            type: 'internal',
            href: '/orcamentos-business'
          },
        ]
      },
      {
        name: 'Preços',
        icon: FaTags,
        allowedPermissions: ['view-price'],
        subMenus: [
          {
            name: 'Importar',
            icon: FaFileImport,
            allowedPermissions: ['import-price'],
            type: 'internal',
            href: '/precos/importar'
          },
          {
            name: 'Consultar',
            icon: FaSearch,
            allowedPermissions: ['consult-price'],
            type: 'internal',
            href: '/precos/consultar'
          },
          {
            name: 'Cadastrados',
            icon: FaList,
            type: 'internal',
            href: '/precos'
          }
        ]
      },
      {
        name: 'Precificação Business',
        icon: FaMoneyCheckDollar,
        allowedPermissions: [
          'view-regions',
          'view-employee-costs'
        ],
        subMenus: [
          {
            name: 'Regiões',
            icon: FaLocationDot,
            allowedPermissions: ['view-regions'],
            subMenus: [
              {
                name: 'Nova',
                icon: FaPlus,
                allowedPermissions: ['create-regions'],
                type: 'internal',
                href: '/regioes/adicionar'
              },
              {
                name: 'Cadastradas',
                icon: FaList,
                type: 'internal',
                href: '/regioes'
              }
            ]
          },
          {
            name: 'Custos de Empregados',
            icon: FaSackDollar,
            allowedPermissions: ['view-employee-costs'],
            subMenus: [
              {
                name: 'Novo',
                icon: FaPlus,
                allowedPermissions: ['create-employee-cost'],
                type: 'internal',
                href: '/empregado/custo/criar'
              },
              {
                name: 'Cadastrados',
                icon: FaList,
                type: 'internal',
                href: '/empregados/custos'
              }
            ]
          }
        ]
      },
      {
        name: 'Relatórios',
        icon: FaChartSimple,
        allowedPermissions: [
          'view-crm-report',
          'view-customer-managment-report',
          'view-sla-report',
        ],
        subMenus: [
          {
            name: 'CRM',
            allowedPermissions: ['view-crm-report'],
            type: 'internal',
            href: '/relatorios/crm'
          },
          {
            name: 'Gerencial de Cliente',
            allowedPermissions: ['view-customer-managment-report'],
            type: 'internal',
            href: '/relatorios/gerencial-cliente'
          },
          {
            name: 'SLA',
            allowedPermissions: ['view-sla-report'],
            type: 'internal',
            href: '/relatorios/sla'
          }
        ]
      }
    ]
  },
  {
    name: 'Qualidade',
    icon: FaStamp,
    subMenus: [
      {
        name: 'Portadores',
        icon: FaPeopleCarryBox,
        type: 'internal',
        allowedPermissions: ['view-carriers'],
        href: '/portadores'
      },
      {
        name: 'RNCs',
        icon: FaFileCircleXmark,
        allowedPermissions: [
          'view-rnc-list',
          'view-third-party-rnc'
        ],
        subMenus: [
          {
            name: 'Internas',
            allowedPermissions: ['view-rnc-list'],
            icon: TbChecklist,
            subMenus: [
              {
                name: 'Novo',
                icon: FaPlus,
                allowedPermissions: ['add-rnc'],
                type: 'internal',
                href: '/rnc/adicionar'
              },
              {
                name: 'Aguardando Resposta',
                icon: FaList,
                type: 'internal',
                href: '/rnc/pendentes-resposta'
              },
              {
                name: 'Aguardando Validação',
                icon: FaList,
                type: 'internal',
                href: '/rnc/pendentes-validacao'
              },
              {
                name: 'Aguardando Alinhamento',
                icon: FaList,
                type: 'internal',
                href: '/rnc/pendentes-alinhamento'
              },
              {
                name: 'Aguardando Anexo de Evidências',
                icon: FaList,
                allowedPermissions: ['send-intern-rnc-alignment-attachments'],
                type: 'internal',
                href: '/rncs/pendente-anexo'
              },
              {
                name: 'Finalizados',
                icon: FaList,
                type: 'internal',
                href: '/rnc/finalizados'
              },
            ]
          },
          {
            name: 'Externas',
            allowedPermissions: ['view-third-party-rnc'],
            icon: TbChecklist,
            subMenus: [
              {
                name: 'Novo',
                icon: FaPlus,
                allowedPermissions: ['create-third-party-rnc'],
                type: 'internal',
                href: '/rnc/terceiros/adicionar'
              },
              {
                name: 'Aguardando Resposta',
                icon: FaList,
                allowedPermissions: ['answer-third-party-rnc'],
                type: 'internal',
                href: '/rnc/terceiros/aguardando-resposta'
              },
              {
                name: 'Aguardando Validação',
                icon: FaList,
                allowedPermissions: ['validate-third-party-rnc'],
                type: 'internal',
                href: '/rnc/terceiros/aguardando-validacao'
              },
              {
                name: 'Finalizados',
                icon: FaList,
                type: 'internal',
                href: '/rnc/terceiros/finalizados'
              },
            ]
          },
        ]
      },
      {
        name: 'Ocorrências',
        icon: FaFileCircleExclamation,
        allowedPermissions: [
          'view-occurrence-list'
        ],
        subMenus: [
          {
            name: 'Consultar',
            icon: FaSearch,
            allowedPermissions: ['consult-occurrence'],
            type: 'internal',
            href: '/ocorrencias/consultar'
          },
          {
            name: 'Painel',
            icon: FaList,
            type: 'internal',
            href: '/ocorrencias'
          },
          {
            name: 'Em Alinhamento',
            icon: FaList,
            allowedPermissions: ['align-customer-occurrence'],
            type: 'internal',
            href: '/ocorrencias/pendentes/alinhamento'
          },
          {
            name: 'Anexando Evidências',
            icon: FaList,
            allowedPermissions: ['attach-customer-occurrence-evidences'],
            type: 'internal',
            href: '/ocorrencias/pendentes/anexar-evidencias'
          },
          {
            name: 'Finalizadas (Cliente)',
            icon: FaList,
            allowedPermissions: ['view-finished-customer-occurrences'],
            type: 'internal',
            href: '/ocorrencias/cliente/finalizadas'
          }
        ]
      },
      {
        name: 'Documentos',
        icon: HiDocument,
        allowedPermissions: ['view-documents'],
        type: 'internal',
        href: '/documentos'
      },
      {
        name: 'Relatórios',
        icon: FaChartSimple,
        subMenus: [
          {
            name: 'Comunicações Internas',
            allowedPermissions: ['view-intern-comunication-report'],
            type: 'internal',
            href: '/relatorios/comunicacoes-internas'
          },
          {
            name: 'ESG',
            allowedPermissions: ['view-esg-report'],
            type: 'internal',
            href: '/relatorios/esg'
          },
          {
            name: 'Ocorrências',
            allowedPermissions: ['view-occurrence-report'],
            type: 'internal',
            href: '/relatorios/servicos/ocorrencia'
          },
          {
            name: 'RNC',
            allowedPermissions: ['view-rnc-report'],
            type: 'internal',
            href: '/relatorios/rnc'
          },
          {
            name: 'Portadores',
            allowedPermissions: ['view-carriers-report'],
            type: 'internal',
            href: '/portadores/relatorio'
          }
        ]
      }
    ]
  },
  {
    name: 'Financeiro',
    icon: FaDollarSign,
    subMenus: [
      {
        name: 'Faturamento',
        icon: FaHandHoldingDollar,
        allowedPermissions: [
          'view-billings',
          'request-customer-account',
        ],
        subMenus: [
          {
            name: 'Novo',
            icon: FaPlus,
            allowedPermissions: ['create-billings'],
            type: 'internal',
            href: '/faturamento/adicionar'
          },
          {
            name: 'Faturamentos',
            icon: FaList,
            allowedPermissions: ['view-billings'],
            type: 'internal',
            href: '/faturamentos'
          },
          {
            name: 'Fatura Cliente',
            icon: FaFileInvoice,
            allowedPermissions: ['request-customer-account'],
            type: 'internal',
            href: '/fatura/cliente/gerar'
          },
          {
            name: 'Faturas de Coletador/Agregado',
            icon: FaFileInvoice,
            allowedPermissions: ['view-invoices'],
            type: 'internal',
            href: '/controle/fatura/coletadores-e-agregados'
          }
        ]
      },
      {
        name: 'Extras/Descontos',
        icon: FaMoneyBillTransfer,
        allowedPermissions: [
          'view-extras-and-discounts',
          'view-closed-extras-and-discounts'
        ],
        type: 'internal',
        href: '/extras-e-descontos'
      },
      {
        name: 'Reembolsos',
        icon: FaExchangeAlt,
        allowedPermissions: ['view-refunds'],
        type: 'internal',
        href: '/reembolsos',
      },
      {
        name: 'Esporádicos',
        icon: FaCalendarDay,
        allowedPermissions: ['view-sporadics'],
        type: 'internal',
        href: '/esporadicos/kanban',
      },
      {
        name: 'Relatórios',
        icon: FaChartSimple,
        allowedPermissions: [
          'view-cte-report',
          'view-extras-discounts-report',
          'view-billing-report',
          'view-cost-report'
        ],
        subMenus: [
          {
            name: 'CTE',
            allowedPermissions: ['view-cte-report'],
            type: 'internal',
            href: '/relatorios/cte'
          },
          {
            name: 'Extras/Descontos Finalizados',
            allowedPermissions: ['view-extras-discounts-report'],
            type: 'internal',
            href: '/relatorios/extras-e-descontos'
          },
          {
            name: 'Faturamento',
            allowedPermissions: ['view-billing-report'],
            type: 'internal',
            href: '/relatorios/faturamento'
          },
          {
            name: 'Custo',
            allowedPermissions: ['view-cost-report'],
            type: 'internal',
            href: '/relatorios/custo'
          },
        ]
      }
    ]
  },
  {
    name: 'Utilitários',
    icon: FaCog,
    subMenus: [
      {
        name: 'Comunicações Internas',
        icon: FaComments,
        type: 'internal',
        href: '/comunicacoes-internas',
        allowedPermissions: ['view-intern-comunication']
      },

      {
        name: 'Logs',
        icon: FaFileCode,
        allowedPermissions: ['view-log'],
        subMenus: [
          {
            name: 'Filtrar',
            type: 'internal',
            href: '/logs/filtrar'
          }
        ]
      },
      {
        name: 'Usuários',
        icon: FaUser,
        allowedPermissions: ['view-user'],
        subMenus: [
          {
            name: 'Solicitar',
            icon: FaUserPlus,
            allowedPermissions: ['add-requested-user'],
            type: 'internal',
            href: '/usuario-solicitado/adicionar'
          },
          {
            name: 'Solicitados',
            icon: FaList,
            allowedPermissions: ['view-requested-user'],
            type: 'internal',
            href: '/usuarios-solicitados'
          },
          {
            name: 'Atualizar Permissões',
            icon: FaUserShield,
            allowedPermissions: ['update-users-permissions'],
            type: 'internal',
            href: '/usuarios/alterar-permissoes'
          },
          {
            name: 'Cadastrados',
            icon: FaList,
            type: 'internal',
            href: '/usuarios'
          },
          {
            name: 'Tipos de Notificação',
            icon: FaBell,
            allowedPermissions: ['view-notification-type'],
            subMenus: [
              {
                name: 'Novo',
                icon: FaPlus,
                allowedPermissions: ['create-notification-type'],
                type: 'internal',
                href: '/notificacao/tipo/criar',
              },
              {
                name: 'Cadastrados',
                icon: FaList,
                type: 'internal',
                href: '/notificacao/tipos',
              }
            ]
          },
          {
            name: 'Perfis',
            icon: FaUsers,
            allowedPermissions: ['view-profile'],
            subMenus: [
              {
                name: 'Novo',
                icon: FaPlus,
                allowedPermissions: ['add-profile'],
                type: 'internal',
                href: '/perfil/adicionar',
              },
              {
                name: 'Cadastrados',
                icon: FaList,
                type: 'internal',
                href: '/perfis',
              }
            ]
          },
          {
            name: 'Permissões',
            icon: FaLock,
            allowedPermissions: ['edit-permission'],
            subMenus: [
              {
                name: 'Nova',
                icon: FaPlus,
                allowedPermissions: ['add-permission'],
                type: 'internal',
                href: '/permissao/adicionar',
              },
              {
                name: 'Cadastradas',
                icon: FaList,
                type: 'internal',
                href: '/permissoes',
              }
            ]
          }
        ]
      },
      {
        name: 'Uploads',
        icon: FaUpload,
        allowedPermissions: [
          'upload-cte-loglife',
          'upload-cte-complementary',
          'upload-pdf',
          'upload-csv-associate-pdf-service',
          'upload-stock-update-csv'
        ],
        subMenus: [
          {
            name: 'CTE Loglife',
            allowedPermissions: ['upload-cte-loglife'],
            type: 'internal',
            href: '/cte/loglife/enviar-arquivo',
          },
          {
            name: 'CTE Complementar',
            allowedPermissions: ['upload-cte-complementary'],
            type: 'internal',
            href: '/cte/complementar/enviar-arquivo',
          },
          {
            name: 'PDF',
            allowedPermissions: ['upload-pdf'],
            type: 'internal',
            href: '/upload-pdf',
          },
          {
            name: 'CSV para Associar PDF',
            allowedPermissions: ['upload-csv-associate-pdf-service'],
            type: 'internal',
            href: '/associar-pdf',
          },
          {
            name: 'CSV para Atualizar Estoque',
            allowedPermissions: ['upload-stock-update-csv'],
            type: 'internal',
            href: '/estoque/enviar-arquivo-csv',
          },
        ]
      },
      {
        name: 'Regionais',
        icon: FaMapLocation,
        allowedPermissions: ['view-regionals'],
        type: 'internal',
        href: '/regionais'
      },
      {
        name: 'Relatórios',
        icon: FaChartSimple,
        allowedPermissions: ['view-bugs-report'],
        subMenus: [
          {
            name: 'Bugs',
            icon: FaBug,
            type: 'internal',
            href: '/relatorios/bug',
          }
        ]
      }
    ]
  },
  {
    name: 'Atendimentos',
    icon: FaHeadset,
    subMenus: [
      {
        name: 'Cadastrados',
        icon: FaList,
        allowedPermissions: ['view-supports'],
        type: 'internal',
        href: '/atendimentos'
      },
      {
        name: 'Assuntos',
        icon: FiTag,
        allowedPermissions: ['view-supports-subjects'],
        type: 'internal',
        href: '/atendimentos/assuntos'
      },

    ]
  },
]

const lifeBoxMenuOptions: Option[] = [
  {
    name: 'Certificados',
    icon: FaDownload,
    subMenus: [
      {
        name: 'Certificado LifeBox 8L',
        icon: FaRegFilePdf,
        type: 'external',
        href: handleChangeUrl('47f4265668c69f9c24ac6d961cc625b5-LIFEBOX 8L ziplock - Documento comprobatório Loglife_1077.pdf')
      },
      {
        name: 'Certificado LifeBox 3L',
        icon: FaRegFilePdf,
        type: 'external',
        href: handleChangeUrl('57fc792a981ca632d694e506682c6559-LIFEBOX 3L ziplock - Documento comprobatório Loglife_1078.pdf')
      },
    ],
  }
  // {
  //   name: 'Comprar Lifebox',
  //   icon: FaBagShopping,
  //   type: 'external',
  //   href: 'https://www.lojalifebox.com.br'
  // },
]

const helpMenuOptions: Option[] = [
  {
    name: 'Bugs',
    icon: FaBug,
    subMenus: [
      {
        name: 'Reportar',
        allowedPermissions: ['create-bug'],
        children:
          <PopoverTrigger>
            <Box w="full" h="full" left="0" position="absolute" />
          </PopoverTrigger>

      },
      {
        name: 'Reportados',
        type: 'internal',
        href: '/bugs'
      }
    ]
  },
  {
    name: 'Como Usar o Sistema',
    icon: FaBookOpen,
    type: 'internal',
    href: '/como-usar-o-sistema'
  },
  {
    name: 'Configurações',
    icon: FaUserCog,
    type: 'internal',
    href: '/configuracoes'
  },
  {
    name: 'Contingências',
    icon: FaExclamationTriangle,
    type: 'internal',
    href: '/contingencias',
    allowedPermissions: ['view-contingencies'],
  },
  {
    name: 'Documentos Loglife',
    icon: CgFileDocument,
    subMenus: []
  }
]

export interface SubMenuOptionProps {
  name: string
  icon?: IconType
  allowedPermissions?: string[]
  type?: string
  href?: string
  children?: ReactNode
  subMenus?: SubMenuOptionProps[]
}

export const normalizeText = (text: string) =>
  text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

const filterMenuOptions = (options: SubMenuOptionProps[], filter: string) => {
  return options.reduce((acc, option) => {
    const normalizedFilter = normalizeText(filter);
    const normalizedName = normalizeText(option.name);

    const nameMatch = normalizedName.includes(normalizedFilter);

    const filteredSubMenus = option.subMenus ? filterMenuOptions(option.subMenus, filter) : [];

    if (nameMatch || filteredSubMenus.length > 0) {
      acc.push({
        ...option,
        subMenus: filteredSubMenus.length > 0 ? filteredSubMenus : option.subMenus
      });
    }

    return acc;
  }, []);
};


export function Sidebar() {
  const { userLogged, handleLogout } = useAuth()

  const [isWideVersion] = useMediaQuery('(min-width: 1024px)')

  const [filter, setFilter] = useState("");
  const [isExpanded, setIsExpanded] = useState(false)

  const handleToggleSidebar = () => setIsExpanded(!isExpanded)

  const {
    data: userTasksResult
  } = useUserTasks({
    id: userLogged?.id,
    queryOptions: {
      enabled: userLogged?.loglife_employee
    }
  })

  const {
    data: documentsResult
  } = useQuery({
    queryKey: ['documents-menu'],
    queryFn: () => getDocumentsMenu(),
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 30,
    refetchIntervalInBackground: true,
    enabled: !!userLogged
  })


  const documentSubMenus = documentsResult?.documents?.map((document) => ({
    name: document.name,
    type: 'external',
    href: document?.documentAttachments?.find(attachment => attachment?.status === 'active')?.attachment?.link
  })) || [];


  const updatedHelpMenuOptions = helpMenuOptions.map((option) => {
    if (option.name === 'Documentos Loglife') {
      return {
        ...option,
        subMenus: [...option.subMenus, ...documentSubMenus],
      };
    }
    return option;
  });


  const {
    data: notificationsResult,
  } = useQuery({
    queryKey: ['user-notifications'],
    queryFn: () => getUserNotifications({ pageSize: String(10), currentPage: String(1) }),
    enabled: !!userLogged,
  })

  const filteredMenuOptions = filterMenuOptions(menuOptions, filter);

  return (
    <Box
      h="100svh"
      maxW={isWideVersion
        ? isExpanded
          ? '250px'
          : '80px'
        : isExpanded
          ? 'full'
          : '0px'
      }
      w="full"
      bg="white"
      zIndex="1"
      display="flex"
      justifyContent="flex-start"
      flexDirection="column"
      position={{ base: 'fixed', lg: 'sticky' }}
      p="2"
      transition="all 0.3s"
      boxShadow="base"
    >
      <Flex
        direction="column"
        align="center"
        justify="flex-start"
        gap="3"
        w="full"
      >
        <Flex
          w="full"
          justify={isExpanded ? 'space-between' : 'center'}
          align="center"
          position="relative"
        >
          <Link
            to="/"
          >
            <Image
              src={loglifeLogo}
              w="40px"
              h="40px"
              rounded="full"
              hidden={!isWideVersion && !isExpanded ? true : false}
              alignSelf={isExpanded ? 'flex-start' : 'center'}
              transition="all 0.2s"
              _hover={{
                transform: 'scale(1.2)'
              }}
            />
          </Link>

          <IconButton
            aria-label=""
            icon={<Icon as={FaAngleDoubleRight} />}
            size="xs"
            onClick={handleToggleSidebar}
            transition="hover 0.2s transform 0.3s"
            transform={isExpanded ? 'rotate(180deg)' : 'rotate(0)'}
            position={isExpanded ? 'sticky' : 'absolute'}
            right='-20px'
            top={{ base: !isWideVersion && !isExpanded ? '10' : '0', md: 0 }}
            bg="#38C3FA"
            color="white"
            _hover={{
              bg: '#38C3FA',
              opacity: 0.9,
            }}
          />
        </Flex>
        <InputGroup
          size="sm"
          rounded="md"
          hidden={!isExpanded}
          transition="opacity 0.3s"
          opacity={isExpanded ? 1 : 0}
        >
          <InputLeftElement pointerEvents="none">
            <Icon as={FaSearch} color="gray.400" />
          </InputLeftElement>
          <Input
            placeholder="Pesquisar..."
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            size="sm"
            rounded="md"
          />
        </InputGroup>
        <List hidden={!isWideVersion && !isExpanded ? true : false} mt="6" justifySelf="center" maxH="500px" overflow="scroll" w="full" spacing="2" px="1">
          {filteredMenuOptions.map((option, index) => {
            return (
              <Submenu
                key={option.name}
                onSetIsExpanded={handleToggleSidebar}
                isExpanded={isExpanded}
                filter={filter}
                {...option}
              />
            )
          })}
        </List>
      </Flex>

      <Divider my="6" />

      <Flex
        hidden={!isWideVersion && !isExpanded ? true : false}
        h="full"
        maxH="400px"
        overflowY="scroll"
        direction="column"
        justify="flex-start"
        align="center"
        w="full"
        gap="3"
      >
        <Flex
          h="full"
          w="full"
          direction="column"
          justifyContent="start"
        >
          <Flex
            direction="column"
            w="full"
            align="center"
            justify="center"
            gap="3"
          >

            {isExpanded && (
              <Text
                fontWeight="bold"
                letterSpacing="tight"
                fontSize="0.785rem"
                color="blackAlpha.500"
                alignSelf="start"
                fontFamily="Roboto"
              >
                Ajuda
              </Text>
            )}

            <List justifySelf="flex-start" maxH="500px" overflow="scroll" w="full" spacing="2" px="1">
              {updatedHelpMenuOptions.map((option) => {
                return (
                  <Popover key={option.name} placement={isWideVersion ? 'right-start' : 'auto'}>
                    <Submenu
                      key={option.name}
                      onSetIsExpanded={handleToggleSidebar}
                      isExpanded={isExpanded}
                      children={option?.children}
                      {...option}
                    />
                    <Portal>
                      <CreateBug />
                    </Portal>
                  </Popover>
                )
              })}

              <Popover
                placement={isWideVersion ? 'right-start' : 'auto'}
              >
                <Submenu
                  onSetIsExpanded={handleToggleSidebar}
                  isExpanded={isExpanded}
                  name="Notificações"
                  icon={LuBell}
                  isTrigger
                >
                  <PopoverTrigger>
                    <Box w="full" h="full" left="0" position="absolute" />
                  </PopoverTrigger>
                  {isExpanded && notificationsResult?.meta?.unreadCount > 0 && (
                    <Box display="flex" alignItems="center" justifyContent="center" opacity="1" bg="red" rounded="full" w="25px" h="20px">
                      <Text lineHeight="5" fontSize="0.675rem" color="white" display="flex">
                        {notificationsResult?.meta?.unreadCount > 9 ? '9+' : notificationsResult?.meta?.unreadCount}
                      </Text>
                    </Box>
                  )}
                  {!isExpanded && notificationsResult?.meta?.unreadCount > 0 && (
                    <Box position="absolute" display="flex" alignItems="center" justifyContent="center" opacity="1" bg="red" rounded="full" w="6px" h="6px" right="4" top="-1" />
                  )}
                </Submenu>

                <Portal>
                  <PopoverContent zIndex="tooltip" minW="400px">
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader letterSpacing="tight" fontWeight="bold" border="0">Notificações</PopoverHeader>
                    <PopoverBody p="0">
                      <Grid templateColumns="repeat(1, 1fr)" w="full">
                        {notificationsResult?.notifications?.map((notification) => {
                          return (
                            <Notification.Root
                              key={notification.notification.id}
                              cursor={notification.notification.link ? 'pointer' : 'normal'}
                              position="relative"
                            >
                              {notification.notification.link ? (
                                <Flex w="full" align="center" gap="2" p="4">
                                  <Notification.Icon
                                    icon={GoInfo}
                                    fontSize="2xl"
                                    isRead={notification.is_read}
                                  />
                                  <Box w="full" maxW="800px" onClick={isWideVersion ? () => { } : handleToggleSidebar}>
                                    <Link to={notification.notification.link}>
                                      <Notification.Title title={notification.notification.title} />
                                      <Notification.Message message={
                                        `${notification.notification.message} - ${formatDistance(new Date(notification.created_at), new Date(), { addSuffix: true, locale: ptBR })}`
                                      } />
                                    </Link>
                                  </Box>

                                  <Icon
                                    position="absolute"
                                    as={RiExternalLinkFill}
                                    right="1"
                                    top="1"
                                    fontSize="lg"
                                  />
                                </Flex>

                              ) : (
                                <Flex w="full" align="center" gap="2" p="4">
                                  <Notification.Icon
                                    icon={GoInfo}
                                    fontSize="2xl"
                                    isRead={notification.is_read}
                                  />
                                  <Box w="full" maxW="800px">
                                    <Notification.Title title={notification.notification.title} />
                                    <Notification.Message message={
                                      `${notification.notification.message} - ${formatDistance(new Date(notification.created_at), new Date(), { addSuffix: true, locale: ptBR })}`
                                    } />
                                  </Box>

                                </Flex>
                              )}
                              <Divider />
                            </Notification.Root>
                          )
                        })}
                        <Button as={Link} to="/notificacoes" rounded="none" variant="ghost">Ver todas</Button>
                      </Grid>
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>

              {userLogged?.loglife_employee && (
                <Popover placement={isWideVersion ? 'right-start' : 'auto'}>
                  <Submenu
                    onSetIsExpanded={handleToggleSidebar}
                    isExpanded={isExpanded}
                    name="Minhas Tarefas"
                    icon={GrTask}
                  >
                    <PopoverTrigger>
                      <Box w="full" h="full" left="0" position="absolute" />
                    </PopoverTrigger>
                    {isExpanded && userTasksResult?.quantity > 0 && (
                      <Box display="flex" alignItems="center" justifyContent="center" opacity="1" bg="red" rounded="full" w="25px" h="20px">
                        <Text lineHeight="5" fontSize="0.675rem" color="white" display="flex">
                          {userTasksResult?.quantity > 9 ? '9+' : userTasksResult?.quantity}
                        </Text>
                      </Box>
                    )}
                    {!isExpanded && userTasksResult?.quantity > 0 && (
                      <Box position="absolute" display="flex" alignItems="center" justifyContent="center" opacity="1" bg="red" rounded="full" w="6px" h="6px" right="4" top="-1" />
                    )}
                  </Submenu>

                  <Portal>
                    <PopoverContent zIndex="tooltip" minW="400px">
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader letterSpacing="tight" fontWeight="bold" border="0">Minhas Tarefas</PopoverHeader>
                      <PopoverBody p="0" maxH="500px" overflowY="scroll">
                        <Grid templateColumns="repeat(1, 1fr)" w="full">
                          {userTasksResult?.userTasks?.responsibleInternComunicationsTasks && userTasksResult?.userTasks?.responsibleInternComunicationsTasks
                            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                            .map((task) => {
                              return (
                                <GridItem
                                  as={Link}
                                  to="/comunicacoes-internas"
                                  w="full"
                                  key={task.id}
                                  transition="all 0.2s"
                                  _hover={{
                                    bg: 'blackAlpha.100'
                                  }}
                                  onClick={isWideVersion ? () => { } : handleToggleSidebar}
                                >
                                  <Box p="3">
                                    <Text fontSize="sm" fontWeight="semibold">Responder Comunicação Interna</Text>
                                    <Text fontSize="xs">{task.comunication_subject ? captalize(task.comunication_subject) : 'Sem assunto'} - {formatDistance(new Date(task.createdAt), new Date(), { addSuffix: true, locale: ptBR })}</Text>

                                    <Icon as={RiExternalLinkFill} />
                                  </Box>
                                  <Divider />
                                </GridItem>
                              )
                            })}
                          {userTasksResult?.userTasks?.responsibleOccurrenceAlignmentsTasks && userTasksResult?.userTasks?.responsibleOccurrenceAlignmentsTasks
                            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                            .map((task) => {
                              return (
                                <GridItem
                                  as={Link}
                                  to={`/ocorrencia/anexar-evidencias/${task.occurrence_id}`}
                                  w="full"
                                  key={task.id}
                                  transition="all 0.2s"
                                  _hover={{
                                    bg: 'blackAlpha.100'
                                  }}
                                  onClick={isWideVersion ? () => { } : handleToggleSidebar}
                                >
                                  <Box p="3">
                                    <Text fontSize="sm" fontWeight="semibold">Anexar Evidências de Ocorrência</Text>
                                    <Text fontSize="xs">{captalize(task.occurrence.intercurrence)} - {formatDistance(new Date(task.createdAt), new Date(), { addSuffix: true, locale: ptBR })}</Text>

                                    <Icon as={RiExternalLinkFill} />
                                  </Box>
                                  <Divider />
                                </GridItem>
                              )
                            })}
                          {userTasksResult?.userTasks?.ownerInternComunicationTasks && userTasksResult?.userTasks?.ownerInternComunicationTasks
                            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                            .map((task) => {
                              return (
                                <GridItem
                                  as={Link}
                                  to="/comunicacoes-internas"
                                  w="full"
                                  key={task.id}
                                  transition="all 0.2s"
                                  _hover={{
                                    bg: 'blackAlpha.100'
                                  }}
                                  onClick={isWideVersion ? () => { } : handleToggleSidebar}
                                >
                                  <Box p="3">
                                    <Text fontSize="sm" fontWeight="semibold">Validar Comunicação Interna</Text>
                                    <Text fontSize="xs">{task.comunication_subject ? captalize(task.comunication_subject) : 'Sem assunto'} - {formatDistance(new Date(task.createdAt), new Date(), { addSuffix: true, locale: ptBR })}</Text>

                                    <Icon as={RiExternalLinkFill} />
                                  </Box>
                                  <Divider />
                                </GridItem>
                              )
                            })}
                          {userTasksResult?.userTasks?.intercurrenceResponsibleRncsTasks && userTasksResult?.userTasks?.intercurrenceResponsibleRncsTasks
                            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                            .map((task) => {
                              return (
                                <GridItem
                                  as={Link}
                                  to={`/rnc/responsta/${task.id}`}
                                  w="full"
                                  key={task.id}
                                  transition="all 0.2s"
                                  _hover={{
                                    bg: 'blackAlpha.100'
                                  }}
                                  onClick={isWideVersion ? () => { } : handleToggleSidebar}
                                >
                                  <Box p="3">
                                    <Text fontSize="sm" fontWeight="semibold">Validar Comunicação Interna</Text>
                                    <Text fontSize="xs">{task.cause ? captalize(task.cause) : 'Sem assunto'} - {formatDistance(new Date(task.createdAt), new Date(), { addSuffix: true, locale: ptBR })}</Text>

                                    <Icon as={RiExternalLinkFill} />
                                  </Box>
                                  <Divider />
                                </GridItem>
                              )
                            })}
                        </Grid>
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              )}
            </List>

          </Flex>

          <Divider mb="4" />

          <Flex
            direction="column"
            w="full"
            align="center"
            justify="center"
            gap="3"
          >

            {isExpanded && (
              <Text
                fontWeight="bold"
                letterSpacing="tight"
                fontSize="0.785rem"
                color="blackAlpha.500"
                alignSelf="start"
              >
                LifeBox
              </Text>
            )}

            <List justifySelf="flex-start" maxH="500px" overflow="scroll" w="full" spacing="2" px="1">
              {lifeBoxMenuOptions.map((option) => {
                return (
                  <Submenu key={option.name} onSetIsExpanded={handleToggleSidebar} isExpanded={isExpanded} {...option} />
                )
              })}
            </List>

          </Flex>
        </Flex>
      </Flex>


      <Flex
        hidden={!isWideVersion && !isExpanded ? true : false}
        direction={isExpanded ? 'row' : 'column'}
        gap="3"
        align='flex-end'
        justify={isExpanded ? 'center' : 'flex-end'}
        px="2"
        py="4"
        flex='1'
        h="full"
      >
        <Flex justify="space-between" w="full" gap="3" align="center" direction={isExpanded ? 'row' : 'column'}>
          <Flex align="center" gap="3">
            <Avatar color="white" size="sm" name={`${userLogged?.firstname} ${userLogged?.lastname}`} />

            {isExpanded && (
              <>
                <Flex direction="column" w='full' maxW="150px">
                  <Text fontSize="sm" noOfLines={1} letterSpacing="tight">{captalize(`${userLogged?.firstname} ${userLogged?.lastname}`)}</Text>
                  <Text fontSize="0.675rem" letterSpacing="tight" noOfLines={1}>{userLogged?.email.toLowerCase()}</Text>
                </Flex>
              </>
            )}
          </Flex>

          <Icon
            as={FaRightFromBracket}
            size="sm"
            color="#999999"
            onClick={handleLogout}
            cursor="pointer"
            transition="all 0.2s"
            _hover={{
              transform: 'scale(1.2)'
            }}
          />
        </Flex>
      </Flex>
    </Box >
  )
}
