import { Customer } from './../customers/_types/Customer';
import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Audit, CommercialAudit, FieldAudit, FinancialAudit, FineAudit, HumanResourcesAudit, IsoQualityAudit, LlmHumanResourcesAudit, MaintenanceAudit, OperationalAudit, VisaQualityAudit } from "./_types/Audit";
import { Regional } from 'api/regionals/_types/Regional';

interface GetAuditsReportProps {
  queryParams: {
    startDate: string
    endDate: string
    status: string
    type: string
  }
}

export interface GetAuditsReportResponse {
  audits: Array<Audit & {
    customer: Customer
    regional: Regional
    commercialAudit: CommercialAudit
    fieldAudit: FieldAudit & {
      collaboratorPhotoAttachment: Attachment
      vehicleRearPhotoAttachment: Attachment
      tertiaryPackingPhotoAttachment: Attachment
      internChestPhotoAttachment: Attachment
      cnhPhotoAttachment: Attachment
      crlvPhotoAttachment: Attachment
    }
    financialAudit: FinancialAudit & {
      reportAttachment: Attachment
    }
    fineAudit: FineAudit & {
      reportAttachment: Attachment
    }
    humanResourcesAudit: HumanResourcesAudit
    isoQualityAudit: IsoQualityAudit
    llmHumanResourcesAudit: LlmHumanResourcesAudit
    maintenanceAudit: MaintenanceAudit & {
      reportAttachment: Attachment
    }
    visaQualityAudit: VisaQualityAudit
    operationalAudits: OperationalAudit[]
  }
  >
}

export async function getAuditsReport({
  queryParams
}: GetAuditsReportProps) {
  try {
    const { data } = await api.get<GetAuditsReportResponse>('/reports/audits', {
      params: { ...queryParams }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

