import { IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { PassiveFeedback } from "api/feedbacks/_types/PassiveFeedback";
import { format } from "date-fns";
import { FaSearch } from "react-icons/fa";
import { PassiveFeedbackDetails } from "./PassiveFeedbackDetails";
import { PassiveFeedbackStatus } from "./PassiveFeedbackStatus";

interface PassiveFeedbackTableRowProps {
  passiveFeedback: PassiveFeedback
}

export function PassiveFeedbackTableRow({
  passiveFeedback }: PassiveFeedbackTableRowProps) {

  const {
    isOpen: isPassiveFeedbackDetailModalOpen,
    onToggle: onTogglePassiveFeedbackDetailModal,
  } = useDisclosure()

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Feedback"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onTogglePassiveFeedbackDetailModal}
        />
        <Modal
          isOpen={isPassiveFeedbackDetailModalOpen}
          onClose={onTogglePassiveFeedbackDetailModal}
          size='6xl'
        >
          <ModalOverlay />
          <PassiveFeedbackDetails
            passiveFeedbackId={passiveFeedback.id}
            onClose={onTogglePassiveFeedbackDetailModal}
          />
        </Modal>
      </Td>
      <Td> {
        passiveFeedback?.cltDriverId ? passiveFeedback?.cltDriver?.driver?.firstname : passiveFeedback?.internClt ? passiveFeedback?.internClt?.name : '-'}</Td>
      <Td>
        <PassiveFeedbackStatus
          status={passiveFeedback?.status}
        />
      </Td>
      <Td>{format(new Date(passiveFeedback?.deadline), 'dd/MM/yyyy')}</Td>
    </Tr>
  )
}
