import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Stack,
} from '@chakra-ui/react'
import { useState, useEffect, Fragment } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import { LogProps } from '../../../contexts/LogContext'
import { serviceFormatDateToFrontTable } from '../../../utils/ServiceFunctions/serviceFormatDateToFrontTable'
import { serviceFormatHourToFront } from '../../../utils/ServiceFunctions/serviceFormatHourToFront'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { EmptyContentTableAlert } from '../../Alerts/EmptyContentTableAlert'

interface ILogTableProps {
  logs: LogProps[]
}

const logOptions = [
  { key: 'user', value: 'Usuário', checked: false },
  { key: 'email', value: 'Email', checked: false },
  { key: 'action', value: 'Ação', checked: false },
  { key: 'updatedAt', value: 'Data da Criação', checked: false },
]

const logOrderOptions = [{ key: 'user', value: 'Usuário' }]

export function LogTable({ logs }: ILogTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE
  const [searchedLogs, setSearchedLogs] = useState<LogProps[]>([])

  const [slicedLogs, setSlicedLogs] = useState<LogProps[]>([])
  const [userFiltered, setUserFiltered] = useState('')
  const [actionFiltered, setActionFiltered] = useState('')
  const [emailFiltered, setEmailFiltered] = useState('')
  const [updatedAtFiltered, setUpdatedAtFiltered] = useState('')

  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedLogs?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(logOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(logOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      setSlicedLogs(logs)
    }
    run()
  }, [itemLimit, currentPage, offset, logs])

  useEffect(() => {
    function run() {
      const newSlicedInputs = slicedLogs?.filter((log) => {
        const nameFilter = searchBoxFilter(
          `${log.userIDLogs.firstname} ${log.userIDLogs.lastname}`,
          userFiltered,
        )
        const emailFilter = searchBoxFilter(log.userIDLogs.email, emailFiltered)
        const actionFilter = searchBoxFilter(log.action, actionFiltered)
        const updatedAtFilter = searchBoxFilter(
          `${serviceFormatDateToFrontTable(
            log.updated_at,
          )} - ${serviceFormatHourToFront(log.updated_at)}`,
          updatedAtFiltered,
        )

        if (
          userFiltered === '' &&
          actionFiltered === '' &&
          updatedAtFiltered === '' &&
          emailFiltered === ''
        ) {
          return log
        }
        return nameFilter && emailFilter && actionFilter && updatedAtFilter
      })

      if (orderOptionSelected.length > 0) {
        const slicedLogsByOrder = newSlicedInputs.sort(
          (a, b) =>
            (orderOptionSelected.includes('user') &&
              `${a.userIDLogs.firstname} ${a.userIDLogs.lastname}`.localeCompare(
                `${b.userIDLogs.firstname} ${b.userIDLogs.lastname}`,
              )) ||
            0,
        )

        setSearchedLogs(slicedLogsByOrder)
      } else {
        setSearchedLogs(newSlicedInputs)
      }
    }
    run()
  }, [
    userFiltered,
    actionFiltered,
    emailFiltered,
    updatedAtFiltered,
    slicedLogs,
    orderOptionSelected,
  ])

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  return (
    <>
      {slicedLogs?.length <= 0 ? (
        <EmptyContentTableAlert
          title="Oops!"
          description="Não há dados para mostrar aqui!"
        />
      ) : (
        <>
          <TableFilterButton />
          <Stack mt="4" direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Fragment key={option.key}>
                  <Flex>
                    {option.key === 'user' && (
                      <SearchBox
                        placeholder="Buscar Usuário..."
                        handleSearch={(e) => setUserFiltered(e.target.value)}
                      />
                    )}
                    {option.key === 'email' && (
                      <SearchBox
                        placeholder="Buscar Email..."
                        handleSearch={(e) => setEmailFiltered(e.target.value)}
                      />
                    )}
                    {option.key === 'action' && (
                      <SearchBox
                        placeholder="Buscar Ação..."
                        handleSearch={(e) => setActionFiltered(e.target.value)}
                      />
                    )}
                    {option.key === 'updatedAt' && (
                      <SearchBox
                        placeholder="Buscar Data Criação..."
                        handleSearch={(e) =>
                          setUpdatedAtFiltered(e.target.value)
                        }
                      />
                    )}
                  </Flex>
                </Fragment>
              ))}
          </Stack>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  {logOptions.map((option) => (
                    <Th key={option.key} fontSize="12" color="blue.900">
                      {option.value.toUpperCase()}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {searchedLogs
                  .slice(offset, offset + Number(itemLimit))
                  .map((log, index) => {
                    return (
                      <Tr key={index}>
                        <Td fontSize="12">
                          {`${log.userIDLogs.firstname} ${log.userIDLogs.lastname}`.toUpperCase()}
                        </Td>
                        <Td fontSize="12">
                          {log.userIDLogs.email.toUpperCase()}
                        </Td>
                        <Td fontSize="12">{log.action.toUpperCase()}</Td>
                        <Td fontSize="12">{`${serviceFormatDateToFrontTable(
                          log.updated_at,
                        )} - ${serviceFormatHourToFront(log.updated_at)}`}</Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </Box>
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}
