import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { Boards } from "pages/Board/Boards";
import { Collects } from "pages/Collect/Collects";
import { Deliveries } from "pages/Delivery/Deliveries";
import { useEffect, useState } from "react";
import {  useHistory } from "react-router-dom";

export function ServiceListInProgress() {
  const searchParams = useSearchParams()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userLoggedHasViewCollectsPermission = userLogged?.permissions.includes("view-collects")
  const userLoggedHasViewBoardsPermission = userLogged?.permissions.includes("view-boards")
  const userLoggedHasViewDeliveriesPermission = userLogged?.permissions.includes("view-deliveries")

  useEffect(() => {
    if (!userLoggedHasViewCollectsPermission && !userLoggedHasViewBoardsPermission && !userLoggedHasViewDeliveriesPermission) {
      history.push('/')
    }
  }, [
    userLoggedHasViewCollectsPermission,
    history,
    userLoggedHasViewBoardsPermission,
    userLoggedHasViewDeliveriesPermission
  ])

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      switch (tab) {
        case 'collects':
          setActiveTabIndex(0);
          break;
        case 'boards':
          setActiveTabIndex(1);
          break;
        case 'deliveries':
          setActiveTabIndex(2);
          break;
        default:
          setActiveTabIndex(0);
          break;
      }
    }
  }, [searchParams]);

  function handleChangeTab(index: number) {
    setActiveTabIndex(index);
    searchParams.set('tab', index === 0 ? 'collects' : index === 1 ? 'boards' : 'deliveries');

    searchParams.set('page', '1')
    history.replace({ search: searchParams.toString() })
  }

  return (
    <Box
      p="6"
      rounded="md"
      bg="white"
      display="flex"
      flexDirection="column"
      gap="6"
    >
      <Tabs isLazy index={activeTabIndex} onChange={handleChangeTab}>
        <TabList>
          <Tab>Coletas</Tab>
          <Tab>Embarques</Tab>
          <Tab>Entregas</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Collects />
          </TabPanel>
          <TabPanel>
            <Boards />
          </TabPanel>
          <TabPanel>
            <Deliveries />
          </TabPanel>
        </TabPanels>

      </Tabs>
    </Box>

  )
}
