import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface InviteToOnboardingBody {
  inviteEmail: string
}
interface InviteToOnboardingParams {
  body?: InviteToOnboardingBody
  onboardingId: string
}

export async function inviteToOnboarding({
  body,
  onboardingId,
}: InviteToOnboardingParams) {
  try {
    await api.patch(`/onboardings/${onboardingId}/invite`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
