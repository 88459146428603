import { Box, Button, Flex, Heading, Icon } from "@chakra-ui/react";

import { ReactNode } from "react";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";

export interface AggregatesListRootProps {
  title: string
  children: ReactNode
}

export function AggregatesListRoot({
  children,
  title
}: AggregatesListRootProps) {
  return (

      <Box
        p={4}
        bg="white"
        borderRadius="8px"
        display="flex"
        flexDirection="column"
      >
        <Flex mb="8" gap="2" justify="space-between" align="center" direction={["column", "column", "row"]}>
          <Heading size="md" fontFamily="poppins">
            {title}
          </Heading>
          <Button
            w={["full", "full", "min"]}
            as={Link}
            colorScheme="gray"
            to="/agregado/criar"
            display="flex"
            alignItems="center"
            size="sm"
          >
            <Icon as={FiPlus} />
            Adicionar Agregado
          </Button>

        </Flex>
        {children}
      </Box>


  )
}
