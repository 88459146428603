import { Divider, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner, Table, TableCaption, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react"
import { getDispatchStock } from "api/dispachStocks/getDispatchStock"
import { format } from "date-fns"
import { useQuery } from "react-query"
import { captalize } from "utils/captalize"

interface DispatchStockDetailProps {
  dispatchStockId: string
}

export function DispatchStockDetail({
  dispatchStockId
}: DispatchStockDetailProps) {

  const { data: dispatchStockData, isLoading: isdispatchStockDataLoading } = useQuery({
    queryKey: ['dispatchStock', dispatchStockId],
    queryFn: () => getDispatchStock({ dispatchStockId })
  })

  return (
    <ModalContent>
      {isdispatchStockDataLoading ? (
        <ModalBody
          p="6"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
        </ModalBody>
      ) : (
        <>
          <ModalHeader letterSpacing="tight">Detalhes do Despacho de Estoque</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer w="full" borderRadius="md" border="1px solid" borderColor="gray.200">
              <Table size="sm">
                <TableCaption>Detalhes de abertura</TableCaption>
                <Tbody>
                  <Tr>
                    <Td>Coletador de Origem</Td>
                    <Td isNumeric>{captalize(dispatchStockData?.dispatchStock?.sourceCollector.trading_name)}</Td>
                  </Tr>
                  <Tr>
                    <Td>Coletador de Destino</Td>
                    <Td isNumeric>{dispatchStockData?.dispatchStock?.destination_collector_id ? captalize(dispatchStockData?.dispatchStock?.destinationCollector?.trading_name) : '-'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Protocolo</Td>
                    <Td isNumeric>{dispatchStockData?.dispatchStock?.protocol}</Td>
                  </Tr>
                  {dispatchStockData?.dispatchStock?.invoice_total_price_in_cents && (
                    <Tr>
                      <Td>Valor Total</Td>
                      <Td isNumeric>{Number(dispatchStockData?.dispatchStock?.invoice_total_price_in_cents / 100)}</Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td>Transportadora</Td>
                    <Td isNumeric>{captalize(dispatchStockData?.dispatchStock?.shipping.trading_name)}</Td>
                  </Tr>
                  <Tr>
                    <Td>Responsável Despacho</Td>
                    <Td isNumeric>{captalize(dispatchStockData?.dispatchStock?.sender)}</Td>
                  </Tr>
                  <Tr>
                    <Td>Base Destino</Td>
                    <Td isNumeric>{captalize(dispatchStockData?.dispatchStock?.branch.nickname)}</Td>
                  </Tr>
                  <Tr>
                    <Td>Responsável pela retirada</Td>
                    <Td isNumeric>{dispatchStockData?.dispatchStock?.withdrawal_responsible ? captalize(dispatchStockData?.dispatchStock?.withdrawal_responsible) : '-'}</Td>
                  </Tr>
                  {dispatchStockData?.dispatchStock.vehicle !== '' && (
                    <Tr>
                      <Td>Veiculo</Td>
                      <Td isNumeric>{captalize(dispatchStockData?.dispatchStock?.vehicle)}</Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td>Data de embarque</Td>
                    <Td isNumeric>{format(new Date(dispatchStockData?.dispatchStock.board_date), 'dd/MM/yyyy')}</Td>
                  </Tr>
                  <Tr>
                    <Td>Previsão de chegada</Td>
                    <Td isNumeric>{format(new Date(dispatchStockData?.dispatchStock.arrival_forecast), 'dd/MM/yyyy')}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>

            <Divider my="6" />

            {dispatchStockData?.dispatchStock?.materials.length > 0 && (
              <TableContainer border="1px solid" borderColor="gray.200" rounded="md">
                <Table size="sm">
                  <TableCaption>Materiais</TableCaption>
                  <Tbody>
                    {dispatchStockData?.dispatchStock?.materials.map((material) => {
                      return (
                        <Tr key={material.id}>
                          <Td>{captalize(material.input.name)}</Td>
                          <Td isNumeric>{material.quantity}</Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </ModalBody>
        </>
      )}
    </ModalContent>
  )
}
