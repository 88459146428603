import { api } from "services/api"
import request from "axios"
import { QueryKey, useQuery, UseQueryOptions } from "react-query"
import { RequestError } from "utils/errors/RequestErrors"
import { RouteNetworkData } from "./dtos/RouteNetwork"
import { useToastify } from "hooks/toastify/useToastify"
import { useAuth } from "hooks/auth/useAuth"

interface RouteNetworkQueryParams {
  page_size?: number
  current_page?: number
  search?: string
  source_branch?: string
  destination_branch?: string
  start_arrival_date?: string
  end_arrival_date?: string
  start_departure_date?: string
  end_departure_date?: string
  modal?: 'AÉREO' | 'RODOVIÁRIO'
  shipping?: string
  arrival_date?: string
  departure_date?: string
}

type QueryOptions = UseQueryOptions<RouteNetworkData, unknown, RouteNetworkData, QueryKey>

interface UseRouteNetworksOptions {
  queryKey?: string
  queryOptions?: QueryOptions
  queryParams?: RouteNetworkQueryParams
}

export function useRouteNetworks(options?: UseRouteNetworksOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getRouteNetworks(queryParams?: RouteNetworkQueryParams) {
    try {
      const { data } = await api.get(`/route-network/`, {
        params: queryParams && {
          ...queryParams,
        }
      })

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response

        throw new RequestError(message, status)
      } else {
        throw new RequestError("Erro inesperado!", 500)
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([options?.queryKey ?? "routeNetworks", {
    ...options?.queryParams
  }], () => getRouteNetworks(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
