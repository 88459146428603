import { IconButton, Link, Tooltip } from "@chakra-ui/react";
import { getDocumentByPagePathname } from "api/documents/getDocumentByPagePathname";
import { useAuth } from "hooks/auth/useAuth";
import { useMemo } from "react";
import { FaFileDownload } from "react-icons/fa";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

export function PageRelatedDocument() {
  const { userLogged } = useAuth()
  const location = useLocation()

  const pagePath = useMemo(() => {
    return window?.location?.origin + location?.pathname.replace(/\/[a-f0-9-]{36}/, '/:id').replace(/\/\d+/, '/:id')
  }, [location?.pathname])

  const {
    data: documentByPagePathnameResult,
  } = useQuery({
    queryKey: ['page-document', pagePath],
    queryFn: () => getDocumentByPagePathname({
      relatedPagePathname: pagePath
    }),
    refetchOnWindowFocus: false,
    enabled: Boolean(userLogged)
  })

  return (
    <>
      {documentByPagePathnameResult?.documentAttachment && (
        <Tooltip
          label={documentByPagePathnameResult?.documentAttachment?.document.name}
        >
          <IconButton
            aria-label=""
            as={Link}
            position="fixed"
            top={5}
            right={5}
            icon={<FaFileDownload />}
            colorScheme="blue"
            href={documentByPagePathnameResult?.documentAttachment?.attachment?.link}
            isExternal
          />
        </Tooltip>
      )}
    </>
  )
}
