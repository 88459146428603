import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getPatrimonies } from "api/patrimonies/getPatrimonies";
import { PatrimonyStatus } from "api/patrimonies/patrimony";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreatePatrimony } from "./components/CreatePatrimony";
import { PatrimoniesTableFilters } from "./components/PatrimoniesTableFilters";
import { PatrimonyTableRow } from "./components/PatrimonyTableRow";

export function Patrimonies() {

  const { userLogged } = useAuth()
  const { replace, push: redirect } = useHistory()

  const userHasViewPatrimoniesPermission = userLogged?.permissions.includes('view-patrimonies')
  const userHasCreatePatrimonyPermission = userLogged?.permissions.includes('create-patrimony')

  useEffect(() => {
    if (!userHasViewPatrimoniesPermission) {
      redirect('/')
    }
  }, [userHasViewPatrimoniesPermission, redirect])

  const searchParams = useSearchParams()

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status') ?? 'all'
  const type = searchParams.get('type')
  const brand = searchParams.get('brand')
  const identifier = searchParams.get('identifier')

  const { data: patrimonyData } = useQuery({
    queryKey: ['patrimonies',
      page,
      status,
      type,
      brand,
      identifier
    ],
    queryFn: () => getPatrimonies({
      currentPage: page,
      pageSize: '10',
      brand,
      type,
      status: status as PatrimonyStatus | 'all',
      identifier: identifier
    }),
    keepPreviousData: true
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: patrimonyData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: patrimonyData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreatePatrimonyModalOpen,
    onOpen: onOpenCreatePatrimonyModal,
    onClose: onCloseCreatePatrimonyModal
  } = useDisclosure()

  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Patrimônios Loglife</Heading>
        {userHasCreatePatrimonyPermission && (
          <IconButton
            aria-label="Criar patrimônio"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onOpenCreatePatrimonyModal}
          />
        )}

        <Modal
          isOpen={isCreatePatrimonyModalOpen}
          onClose={onCloseCreatePatrimonyModal}
          isCentered
        >
          <ModalOverlay />
          <CreatePatrimony
            onCloseModal={onCloseCreatePatrimonyModal}
          />
        </Modal>
      </Flex>
      <PatrimoniesTableFilters />
      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th color="white">Tipo de Patrimônio</Th>
              <Th color="white">Número de Patrimônio</Th>
              <Th color="white">Marca</Th>
              <Th color="white">Valor do Patrimônio</Th>
              <Th color="white">Status</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {patrimonyData?.patrimonies.map((patrimony) => {
              return (
                <PatrimonyTableRow
                  key={patrimony.id}
                  patrimony={patrimony}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
