import { ModalContent, ModalHeader, Flex, ModalCloseButton, ModalBody, Table, Tbody, Td, Tr, Icon, Link as ChakraLink, ModalFooter, Button } from "@chakra-ui/react";
import { GetServicesValidationsRealTimesResponse, ServicesValidationsRealTimes } from "api/service/validateRealTime/getServicesValidationsRealTimes";
import { validateRealTime } from "api/service/validateRealTime/validateRealTime";
import { useToastify } from "hooks/toastify/useToastify";
import { FaCheckCircle, FaMapMarkedAlt, FaTimesCircle } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import { useMutation, useQueryClient } from "react-query";
import { formatDate } from "utils/DateFunctions/formatDate";
import { handleChangeUrl } from "utils/handleChangeUrl";
;

interface ValidateRealTimeModalProps {
  service: ServicesValidationsRealTimes
}

enum TypeValidationEnum {
  'collect' = 'Coleta',
  'delivery' = 'Entrega'
}
export function ValidateRealTimeModal({ service }: ValidateRealTimeModalProps) {
  const contentDeclarations = typeof service.content_declarations === 'string'
    ? JSON.parse(service.content_declarations) : service.content_declarations

  const boxPhotos = typeof service.box_photos === 'string'
    ? JSON.parse(service.box_photos) : service.box_photos

  const queryClient = useQueryClient()
  const { mutateAsync: validateRealTimeFn } = useMutation({
    mutationFn: validateRealTime,
    onSuccess(_data, {updateId}){
      const cachedServices = queryClient.getQueriesData<GetServicesValidationsRealTimesResponse>({
        queryKey: ['services-with-real-time-validation']
      })

      cachedServices.forEach(([cachedKey, cachedData]) => {
        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          services: cachedData.services.filter((service) => {
            return service.update_id !== updateId
          })
        })
      })
    }
  })

  const { promiseMessage } = useToastify()

  async function handleValidateRealTime(isValidate: boolean) {
    await promiseMessage(validateRealTimeFn({
      body: {
        isValidated: isValidate,
        type: service.type,
        updateId: service.update_id
      },
      updateId: service.update_id
    }), 'Nova data/horário validado com sucesso! ')
  }


  return (
    <ModalContent >
      <ModalHeader letterSpacing="tight">
        <Flex
          direction="column"
        >
          Validar Nova Data/Horário
        </Flex>
      </ModalHeader >
      <ModalCloseButton />
      <ModalBody
        maxHeight="400px"
        overflowY="scroll"
      >
        <Table size="sm">
          <Tbody>
            <Tr>
              <Td>Protocolo</Td>
              <Td display="flex" justifyContent="flex-end">
                {service.protocol}
              </Td>
            </Tr>
            <Tr>
              <Td>Tipo</Td>
              <Td textAlign="right">{TypeValidationEnum[service.type]}</Td>
            </Tr>
            <Tr>
              <Td>Data e Hora do Início do Serviço</Td>
              <Td textAlign="right">{formatDate.handle(service.arrival_timestamp, 'DateWithDateAndHourMinute')}</Td>
            </Tr>
            <Tr>
              <Td>Real Data e Hora do Início do Serviço</Td>
              <Td textAlign="right">{formatDate.handle(service.real_arrival_timestamp, 'DateWithDateAndHourMinute')}</Td>
            </Tr>
            <Tr>
              <Td>Localização do Início do Serviço</Td>
              <Td textAlign="right">
                <ChakraLink
                  href={`https://www.google.com/maps/place/${service.arrival_latitude}, ${service.arrival_logitude}`}
                  isExternal
                >
                  <Icon as={FaMapMarkedAlt} />
                </ChakraLink>

              </Td>
            </Tr>
            <Tr>
              <Td>Localização do final do Serviço</Td>
              <Td textAlign="right">
                <ChakraLink
                  href={`https://www.google.com/maps/place/${service.departure_latitude}, ${service.departure_longitude}`}
                  isExternal
                >
                  <Icon as={FaMapMarkedAlt} />
                </ChakraLink>

              </Td>
            </Tr>
            <Tr>
              <Td>Endereço</Td>
              <Td textAlign="right">
                {`${service.branch} | ${service.street} | ${service.number} | ${service.neighborhood} | ${service.city} | ${service.cep}`}
              </Td>
            </Tr>
            <Tr>
              <Td>Nome do Responsável</Td>
              <Td textAlign="right">
                {service.responsible}
              </Td>
            </Tr>
            <Tr>
              <Td>Volume</Td>
              <Td textAlign="right">
                {service.volume}
              </Td>
            </Tr>
            <Tr>
              <Td>Declaração de Conteúdo</Td>
              <Td textAlign="right">
                {contentDeclarations?.map(contentDeclaration => {
                  return (
                    <ChakraLink
                      fontSize="lg"
                      href={handleChangeUrl(contentDeclaration.key)}
                      isExternal
                    >

                      <Icon as={FiExternalLink} mx="2px" />
                    </ChakraLink>
                  )
                })}
              </Td>
            </Tr>
            <Tr>
              <Td>Foto das Caixas</Td>
              <Td textAlign="right">
                {boxPhotos?.map(boxPhoto => {
                  return (
                    <ChakraLink
                      fontSize="lg"
                      href={handleChangeUrl(boxPhoto.key)}
                      isExternal
                    >
                      <Icon as={FiExternalLink} mx="2px" />
                    </ChakraLink>
                  )
                })}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button
          size="sm"
          leftIcon={<Icon as={FaTimesCircle} />}
          variant="outline"
          mr={4}
          onClick={() => handleValidateRealTime(false)}
        >
          Invalidar
        </Button>
        <Button
          size="sm"
          leftIcon={<Icon as={FaCheckCircle} />}
          colorScheme='blue'
          onClick={() => handleValidateRealTime(true)}
        >
          Validar
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}
