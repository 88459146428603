import { Button, Flex, Icon, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { closeExtraAndDiscount } from "api/extrasAndDiscounts/closeExtraAndDiscount";
import { deleteExtraAndDiscount } from "api/extrasAndDiscounts/deleteExtraAndDiscount";
import { GetCustomersExtrasDiscountsResponse } from "api/extrasAndDiscounts/getCustomersExtrasDiscounts";
import { ExtraAndDiscount } from "api/extrasAndDiscounts/_types/ExtraAndDiscount";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useSwal } from "hooks/swal/useSwal";
import { useToastify } from "hooks/toastify/useToastify";
import { FaArrowRight, FaPen, FaSearch, FaTimes } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { EditCustomerExtraDiscount } from "./EditCustomerExtraDiscount";
import { CustomerExtraDiscountDetails } from "./CustomerExtraDiscountDetails";
import { CustomerExtraDiscountStatus } from "./CustomerExtraDiscountStatus";


interface CustomerExtraDiscountTableRowProps {
  extraDiscount: ExtraAndDiscount
}

export function CustomerExtraDiscountTableRow({ extraDiscount }: CustomerExtraDiscountTableRowProps) {

  const { userLogged } = useAuth()
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()
  const { confirmMessage, standardMessage } = useSwal()



  const userCanDeleteExtraAndDiscount = userLogged?.permissions?.includes(
    'delete-extra-and-discount'
  )
  const userCanEditExtraAndDiscount = userLogged?.permissions?.includes(
    'update-extra-and-discount'
  )
  const userCanCloseExtraAndDiscount = userLogged?.permissions?.includes(
    'close-extra-and-discount'
  )

  const extraAndDiscountInfo = extraDiscount.service_type === 'EXPRESS'
    ? extraDiscount.protocol
    : extraDiscount.route


  const {
    isOpen: isExtraAndDiscountDetailsOpen,
    onToggle: onToggleExtraAndDiscountDetails,
  } = useDisclosure()


  const { mutateAsync: deleteExtraAndDiscountFn } = useMutation({
    mutationFn: deleteExtraAndDiscount,
    onSuccess: (_data, { extraAndDiscountId }) => {
      const cached = queryClient.getQueriesData<GetCustomersExtrasDiscountsResponse>({
        queryKey: ['customers-extras-discounts']
      })
      queryClient.invalidateQueries({ queryKey: 'customers-extras-discounts' })


      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          extrasAndDiscounts: cachedValue.extrasDiscounts.map((extraAndDiscount) => {
            if (extraAndDiscount.id === extraAndDiscountId) {
              return { ...extraAndDiscount }
            }

            return extraAndDiscount
          })
        })
      })
    }
  })

  const { mutateAsync: closeExtraAndDiscountFn } = useMutation({
    mutationFn: closeExtraAndDiscount,
    onSuccess: (_data, { extraAndDiscountId }) => {
      const cached = queryClient.getQueriesData<GetCustomersExtrasDiscountsResponse>({
        queryKey: ['ecustomers-extras-discounts']
      })
      queryClient.invalidateQueries({ queryKey: 'customers-extras-discounts' })


      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          extrasAndDiscounts: cachedValue.extrasDiscounts.map((extraAndDiscount) => {
            if (extraAndDiscount.id === extraAndDiscountId) {
              return { ...extraAndDiscount }
            }

            return extraAndDiscount
          })
        })
      })
    }
  })


  async function handleDeleteExtraAndDiscount() {
    const hasDeleteGeneralProvider = await confirmMessage({ title: "Deseja excluir o extra/desconto?" })

    if (hasDeleteGeneralProvider) {
      await promiseMessage(deleteExtraAndDiscountFn({
        extraAndDiscountId: extraDiscount?.id
      }), "Extra/Desconto excluído com sucesso!")
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }
  async function handleFinishExtraAndDiscount() {
    const hasFinishGeneralProvider = await confirmMessage({ title: "Deseja finalizar o extra/desconto?" })

    if (hasFinishGeneralProvider) {
      await promiseMessage(closeExtraAndDiscountFn({
        extraAndDiscountId: extraDiscount?.id
      }), "Extra/Desconto finalizado com sucesso!")
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  const {
    isOpen: isEditExtraAndDiscountOpen,
    onToggle: onToggleEditExtraAndDiscountPrice,
  } = useDisclosure()


  return (
    <Tr>
      <Td>
        <Flex align="center" gap={3}>
          <IconButton
            aria-label="Visualizar extra e desconto"
            icon={<FaSearch />}
            size="sm"
            variant="ghost"
            onClick={onToggleExtraAndDiscountDetails}
          />
          {userCanEditExtraAndDiscount && !extraDiscount?.closed_at && (
            <IconButton
              aria-label="Editar extra/desconto"
              icon={<FaPen />}
              variant="ghost"
              size="sm"
              onClick={onToggleEditExtraAndDiscountPrice}
            />
          )}
        </Flex>
        <Modal
          isOpen={isExtraAndDiscountDetailsOpen}
          onClose={onToggleExtraAndDiscountDetails}
          size='6xl'
        >
          <ModalOverlay />
          <CustomerExtraDiscountDetails
            extraDiscount={extraDiscount}
          />
        </Modal>
        <Modal
          isOpen={isEditExtraAndDiscountOpen}
          onClose={onToggleEditExtraAndDiscountPrice}
          size='6xl'
        >
          <ModalOverlay />
          <EditCustomerExtraDiscount
            extraAndDiscountId={extraDiscount.id}
            onClose={onToggleEditExtraAndDiscountPrice}
          />
        </Modal>
      </Td>
      <Td>{extraDiscount.customer.trading_firstname}</Td>
      <Td>{format(new Date(extraDiscount.created_at), 'dd/MM/yyyy')}</Td>
      <Td>{extraDiscount.type}</Td>
      <Td>{extraDiscount.service_type}</Td>
      <Td>{extraAndDiscountInfo}</Td>
      <Td>
        {
          new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          }).format(extraDiscount.value / 100)
        }
      </Td>
      <Td>
        <CustomerExtraDiscountStatus
          status={extraDiscount?.closed_at ? 'closed' : 'requested'}
        />
      </Td>
      <Td>{extraDiscount.createdBy ? `${extraDiscount.createdBy.firstname} ${extraDiscount.createdBy.lastname}` : '-'}</Td>
      <Td>{extraDiscount.closed_at ? format(new Date(extraDiscount.closed_at), 'dd/MM/yyyy') : '-'}</Td>
      <Td>
        {userCanDeleteExtraAndDiscount && (
          <Button
            size="xs"
            leftIcon={<Icon as={FaTimes} />}
            variant="ghost"
            lineHeight="1"
            onClick={handleDeleteExtraAndDiscount}
          >
            Excluir
          </Button>
        )}
        {userCanCloseExtraAndDiscount && !extraDiscount?.closed_at && (
          <Button
            size="xs"
            leftIcon={<Icon as={FaArrowRight} />}
            variant="ghost"
            lineHeight="1"
            onClick={handleFinishExtraAndDiscount}
          >
            Finalizar
          </Button>
        )}
      </Td>
    </Tr>
  )
}
