import { usePagination } from "@ajna/pagination"
import { Flex, Heading, Divider, Box, Spinner, Icon, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Button, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { Pagination } from "components/Pagination/Pagination"

import { useAuth } from "hooks/auth/useAuth"
import { useShippingCostFunctions } from "hooks/shippingCost/useShippingCostFunctions"
import { useShippingCosts } from "hooks/shippingCost/useShippingCosts"
import { useToastify } from "hooks/toastify/useToastify"
import { useSearchParams } from "hooks/useSearchParams"
import { useEffect } from "react"
import { FaPen, FaPlus } from "react-icons/fa6"
import { FiEye, FiEdit3, FiTrash2 } from "react-icons/fi"
import { useQueryClient } from "react-query"
import { useHistory, Link } from "react-router-dom"
import { formatDate } from "utils/DateFunctions/formatDate"
import { BulkCreateShippingCosts } from "./components/BulkCreateShippingCosts"
import { BulkEditShippingCosts } from "./components/BulkEditShippingCosts"
import { ShippingCostsTableFilters } from "./components/ShippincCostsTableFilters"

export function ShippingCosts() {
  const searchParams = useSearchParams()
  const history = useHistory()

  const page = searchParams.get('page')
  const shipping = searchParams.get('shipping')
  const sourceBranch = searchParams.get('sourceBranch')
  const destinationBranch = searchParams.get('destinationBranch')

  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()
  const { promiseMessage } = useToastify();
  const queryClient = useQueryClient()

  const userHasPermissionToViewShippingCosts =
    userLogged.permissions.includes('view-shipping-costs')

  const userCanCreateShippingCost = userLogged.permissions.includes(
    'create-shipping-cost'
  )

  const userCanEditShippingCost =
    userLogged?.permissions.includes("edit-shipping-cost");

  useEffect(() => {
    if (!userHasPermissionToViewShippingCosts) {
      redirect('/')
    }
  }, [userHasPermissionToViewShippingCosts, redirect])

  const { data: shippingCostsData, isFetching: isFetchingShippingCostsResponseData } = useShippingCosts({
    queryParams: {
      currentPage: page ? Number(page) : 1,
      pageSize: Number(rowsPerPage),
      shipping,
      sourceBranch,
      destinationBranch,
    }
  })

  const { deleteShippingCost: { mutateAsync: deleteShippingCost } } = useShippingCostFunctions()

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: shippingCostsData?.totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: page ? Number(page) : 1,
      },
    });

  async function handleDeleteShippingCost(shippingCostId: string) {
    await promiseMessage(deleteShippingCost(shippingCostId, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('shippingCosts')
      }
    }), "Custo de transportadora excluído com sucesso!")
  }

  const handleChangePage = (page: number) => {
    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })

    setCurrentPage(page)
  }

  const {
    isOpen: isBulkCreateShippingCostsModalOpen,
    onOpen: onOpenBulkCreateShippingCostsmodal,
    onClose: onCloseBulkCreateShippingCostsModal
  } = useDisclosure()

  const {
    isOpen: isBulkEditShippingCostsModalOpen,
    onOpen: onOpenBulkEditShippingCostsmodal,
    onClose: onCloseBulkEditShippingCostsModal
  } = useDisclosure()

  return (

    <Box
      borderRadius='8px'
      p={4}
      bg='white'
    >
      {isFetchingShippingCostsResponseData ? (
        <Spinner />
      ) : (
        <Flex
          w="full"
          direction="column"
          gap={6}
        >
          <Flex
            align="center"
            justify="space-between"
            direction={{ base: 'column', lg: 'row' }}
          >
            <Heading letterSpacing="tight">Custos de Transportadoras</Heading>
            <Modal
              isOpen={isBulkCreateShippingCostsModalOpen}
              onClose={onCloseBulkCreateShippingCostsModal}
            >
              <ModalOverlay />

              <BulkCreateShippingCosts
                onCloseModal={onCloseBulkCreateShippingCostsModal}
              />
            </Modal>
            <Flex w={{ base: 'full', lg: 'fit-content' }} align="center" gap="2" direction={{ base: 'column', lg: 'row' }}>
              {userCanCreateShippingCost && (
                <>
                  <Button
                    as={Link}
                    to="/transportadoras/custos/adicionar"
                    size="sm"
                    lineHeight={1}
                    leftIcon={<FaPlus />}
                    alignSelf='flex-end'
                    colorScheme="blue"
                    w={{ base: 'full', lg: 'fit-content' }}
                  >
                    Novo
                  </Button>

                  <Button
                    onClick={onOpenBulkCreateShippingCostsmodal}
                    lineHeight={1}
                    leftIcon={<FaPlus />}
                    colorScheme="green"
                    size="sm"
                    w={{ base: 'full', lg: 'fit-content' }}
                  >
                    Criar por Csv
                  </Button>
                </>
              )}
              {userCanEditShippingCost && (
                <>
                  <Modal
                    isOpen={isBulkEditShippingCostsModalOpen}
                    onClose={onCloseBulkEditShippingCostsModal}
                  >
                    <ModalOverlay />

                    <BulkEditShippingCosts
                      onCloseModal={onCloseBulkEditShippingCostsModal}
                    />
                  </Modal>

                  <Button
                    onClick={onOpenBulkEditShippingCostsmodal}
                    lineHeight={1}
                    leftIcon={<FaPen />}
                    colorScheme="green"
                    size="sm"
                    w={{ base: 'full', lg: 'fit-content' }}
                  >
                    Editar por Csv
                  </Button>
                </>
              )}
            </Flex>

          </Flex>
          <ShippingCostsTableFilters />

          <Divider />
          <TableContainer>
            <Table variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th>TRANSPORTADORA</Th>
                  <Th>BASE DE ORIGEM</Th>
                  <Th>BASE DE DESTINO</Th>
                  <Th>DATA DE CRIAÇÃO</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {shippingCostsData?.shippingCosts.map(shippingCost => {
                  return (
                    <Tr key={shippingCost.id}>

                      <Td>{shippingCost?.shipping.company_name}</Td>
                      <Td>{`${shippingCost?.sourceBranch.nickname} (${shippingCost?.sourceBranch.hubIDBranch.name})`}</Td>
                      <Td>{`${shippingCost?.destinationBranch.nickname} (${shippingCost?.destinationBranch.hubIDBranch.name})`}</Td>
                      <Td>{formatDate.handle(shippingCost.created_at, 'DateWithoutHourToShow')}</Td>

                      <Td isNumeric>
                        {userLogged !== null && (
                          <>
                            <Link to={`/transportadoras/custos/${shippingCost.id}/visualizar`} >
                              <Icon
                                cursor="pointer"
                                as={FiEye}
                                transition="all 0.2s"
                                _hover={{ opacity: 0.8 }}
                                fontSize="20"
                              />
                            </Link>
                            {userLogged.permissions.includes('edit-shipping-cost') && (
                              <Link to={`/transportadoras/custos/${shippingCost.id}/editar`} >
                                <Icon
                                  cursor="pointer"
                                  as={FiEdit3}
                                  transition="all 0.2s"
                                  _hover={{ opacity: 0.8 }}
                                  fontSize="20"
                                  ml="4"
                                />
                              </Link>
                            )}
                            {userLogged.permissions.includes('delete-shipping-cost') && (
                              <Icon
                                onClick={() => handleDeleteShippingCost(shippingCost.id)}
                                cursor="pointer"
                                as={FiTrash2}
                                transition="all 0.2s"
                                _hover={{ opacity: 0.8 }}
                                fontSize="20"
                                ml="4"
                              />
                            )}
                          </>
                        )}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
          <Pagination
            currentPage={currentPage}
            pages={pages}
            pagesQuantity={pagesCount}
            handlePageChange={handleChangePage}
          />
        </Flex>
      )}
    </Box>

  )

}
