import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface DeleteDispatchStockProposalProps {
  proposalId: string
}

export async function deleteDispatchStockProposal({
  proposalId
}: DeleteDispatchStockProposalProps) {
  try {
    await api.delete(`/dispatch-stocks/proposals/${proposalId}`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
