import { Box, Button, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, Heading, IconButton, Input, Link, Progress, Radio, RadioGroup, Select, Stack, Table, Tbody, Td, Text, Tr, VStack } from "@chakra-ui/react"
import { Controller, useForm, useWatch } from "react-hook-form"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { ChangeEvent, useEffect } from "react";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";
import { sendCltDriverSigningDocumentDetails } from "api/cltDrivers/sendCltDriverSigningDocumentDetails";
import { CltDriver } from "api/cltDrivers/_types/CltDriver";


interface SendSigningDocumentProps {
  cltDriver: CltDriver
}


export interface SendSigningDocumentSchema {
  doesEmployeeWithdrewThePosition: string
  signingDocumentsChecklist: string[]
  doesFoodMeanVoucherDelivered: string
  epiRecordAttachmentId: FileList
  shouldAdvanceStatus: string
}

const sendSigningDocumentSchema = yup.object({
  doesEmployeeWithdrewThePosition: yup.string(),
  signingDocumentsChecklist: yup.mixed().when('doesEmployeeWithdrewThePosition', {
    is: 'no',
    then: yup.array().of(yup.string()).nullable(),
  }),
  doesFoodMeanVoucherDelivered: yup.mixed().when('doesEmployeeWithdrewThePosition', {
    is: 'no',
    then: yup.string(),
  }),

  epiRecordAttachmentId: yup.mixed().when('doesEmployeeWithdrewThePosition', {
    is: 'no',
    then: yup.mixed(),
  })
})

const documentationChecklist = [
  { name: 'Regulamento interno' },
  { name: 'Contrato de experiência' },
  { name: 'Acordo de Banco de Horas' },
  { name: 'Contrato de Aluguel de Moto (Motoboy)' },
  { name: 'Registro de funcionário' },
  { name: 'CTPS Assinada' },
  { name: 'Entrega de patrimônio' },
  { name: 'EPIs (kit acidente)' },
  { name: 'Termo de responsabilidade veículo (Motorista)' },
]


export function SendSigningDocument({ cltDriver }: SendSigningDocumentProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const { mutation: uploadEpiRecordAttachmentFn, uploadProggress: uploadProggressEpiRecordAttachment } = useAttachmentMutation()


  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<SendSigningDocumentSchema>({
    resolver: yupResolver(sendSigningDocumentSchema)
  })

  useEffect(() => {
    if (cltDriver) {
      setValue("doesEmployeeWithdrewThePosition", cltDriver.doesEmployeeWithdrewThePosition ? 'yes' : 'no')
      setValue("signingDocumentsChecklist", Boolean(cltDriver.signingDocumentsChecklist) ? cltDriver.signingDocumentsChecklist : [])
      setValue("doesFoodMeanVoucherDelivered", Boolean(cltDriver.doesFoodMeanVoucherDelivered) ? cltDriver.doesFoodMeanVoucherDelivered ? 'yes' : 'no' : '')
    }
  }, [cltDriver, setValue])

  const [epiRecordAttachmentId, doesEmployeeWithdrewThePosition] = useWatch({
    control,
    name: ['epiRecordAttachmentId', 'doesEmployeeWithdrewThePosition'],
  })

  async function handleUploadEpiRecordAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadEpiRecordAttachmentFn.mutateAsync(formData)

  }

  const { mutateAsync: sendCltDriverSigningDocumentDetailsFn } = useMutation({
    mutationFn: sendCltDriverSigningDocumentDetails,
    onSuccess(_data, { body, cltDriverId }) {
      queryClient.invalidateQueries({ queryKey: 'clts-drivers' })
      queryClient.invalidateQueries({ queryKey: 'hiring-bank-details' })
      queryClient.invalidateQueries({ queryKey: 'hiring-signing-document' })
      queryClient.invalidateQueries({ queryKey: 'active' })
      queryClient.invalidateQueries({ queryKey: ['clt-driver', cltDriverId] })
    }
  })

  async function handleSendSigningDocument(values: SendSigningDocumentSchema) {
    await promiseMessage(sendCltDriverSigningDocumentDetailsFn({
      body: {
        ...values,
        doesEmployeeWithdrewThePosition: values.doesEmployeeWithdrewThePosition === 'yes',
        epiRecordAttachmentId: uploadEpiRecordAttachmentFn?.data?.attachment?.id,
        doesFoodMeanVoucherDelivered: values.doesFoodMeanVoucherDelivered === 'yes',
        shouldAdvanceStatus: doesEmployeeWithdrewThePosition === 'yes' ? true : values.shouldAdvanceStatus ? values.shouldAdvanceStatus === 'yes' : false
      },
      cltDriverId: cltDriver?.id
    }), 'Assinatura de documentos realizada')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleSendSigningDocument)}
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'>Contratação - Assinatura de documentos</Heading>
      <FormControl isInvalid={!!errors.doesEmployeeWithdrewThePosition} mt={3}>
        <FormLabel fontSize="sm">
          Funcionário desistiu da vaga ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="doesEmployeeWithdrewThePosition"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      {doesEmployeeWithdrewThePosition === 'no' && (
        <>
          <FormControl mt={3} isInvalid={!!errors.signingDocumentsChecklist}>
            <FormLabel fontSize='sm'>
              Documentação
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              control={control}
              name="signingDocumentsChecklist"
              render={({ field }) => {
                return (
                  <CheckboxGroup
                    value={field.value}
                    onChange={field.onChange}
                  >
                    <VStack
                      spacing={3}
                      p={2}
                      border="1px solid"
                      borderColor="gray.200"
                      rounded="md"
                      align="left"
                    >
                      {documentationChecklist.map(check => {
                        return (
                          <Checkbox key={check.name} value={check.name}>
                            <Text fontSize="sm">
                              {check.name}
                            </Text>
                          </Checkbox>
                        )
                      })}
                    </VStack>

                  </CheckboxGroup>
                )
              }}
            />
          </FormControl>
          <FormControl isInvalid={!!errors.doesFoodMeanVoucherDelivered} mt={3}>
            <FormLabel fontSize="sm">
              VA/VR Entregue ao colaborador ?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="doesFoodMeanVoucherDelivered"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                    <Radio size='sm' rounded='md' value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <Stack
            direction="column"
            w="full"
            mt="3"
          >
            <Button
              as={FormLabel}
              htmlFor="epiRecordAttachmentId"
              lineHeight="1"
              leftIcon={<FaFileImport />}
              size="sm"
              w="min"
              cursor="pointer"
              border={!!errors?.epiRecordAttachmentId && '2px solid'}
              borderColor={(!!errors?.epiRecordAttachmentId) && 'red.500'}
              fontSize="sm"
            >
              Anexo ficha de EPI
            </Button>
            <FormControl isInvalid={!!errors?.epiRecordAttachmentId}>
              <Input
                {...register('epiRecordAttachmentId')}
                name="epiRecordAttachmentId"
                id="epiRecordAttachmentId"
                type="file"
                hidden
                onChangeCapture={handleUploadEpiRecordAttachment}
              />
            </FormControl>
          </Stack>

          {epiRecordAttachmentId && (
            <Table size="sm">
              <Tbody>
                {Object.entries(epiRecordAttachmentId).map(([key, file]) => {
                  return (
                    <Tr key={key}>
                      <Td fontSize="xs" maxW="100px">{file.name}</Td>
                      <Td fontSize="xs" w="200px">
                        <Progress size="sm" rounded="md" value={uploadProggressEpiRecordAttachment} />
                      </Td>
                      <Td fontSize="xs" isNumeric>
                        {uploadEpiRecordAttachmentFn.data && (
                          <IconButton
                            aria-label="Visualizar anexo"
                            as={Link}
                            size="sm"
                            icon={<FaExternalLinkAlt />}
                            href={uploadEpiRecordAttachmentFn.data.attachment.link}
                            isExternal
                          />
                        )}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          )}
          <FormControl isInvalid={!!errors.shouldAdvanceStatus} mt={3}>
            <FormLabel fontSize="sm">
              Passar para o próximo status?
            </FormLabel>
            <Select
              {...register('shouldAdvanceStatus')}
              name="shouldAdvanceStatus"
              placeholder="Selecione..."
              size="sm"
              rounded="md"
            >
              <option value='yes'>Sim</option>
              <option value='no'>Não</option>
            </Select>
          </FormControl>
        </>
      )}
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
