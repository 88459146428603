import { Box, Flex, Icon, Text, ToastId, useToast } from "@chakra-ui/react";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { createContext, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaX } from "react-icons/fa6";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { Socket } from "socket.io-client";
import { connectSocket } from "../services/socket/socket";

interface ServiceSocketContextProps {
  serviceSocketConnection: Socket | null
}

interface ServiceSocketProviderProps {
  children: ReactNode
}

interface IncludedCollectAddressNotificationProps {
  user_type: 'SUCESSO DO CLIENTE - TORRE DE CONTROLE'
  service_protocol: number
}

type UserTypes = 'SUCESSO DO CLIENTE - COMERCIAL' | 'COMERCIAL - DIRETORIA' | 'SUCESSO DO CLIENTE - DIRETORIA'

interface RequestedCancelCollectAddressNotificationProps {
  user_types: UserTypes[]
  service_protocol: number
}

interface RequestedCancelServiceNotificationProps {
  user_types: UserTypes[]
  service_protocol: number
}

interface SetUnsuccessServiceNotificationProps {
  serviceProtocol: number
  internComunicationProtocol: number
  profilesCanReceive: string
}

export const ServiceSocketContext = createContext({} as ServiceSocketContextProps)

export function ServiceSocketProvider({ children }: ServiceSocketProviderProps) {
  const { userLogged } = useAuth()

  const [serviceSocketConnection] = useState<Socket>(connectSocket('service', {
    auth: {
      user: userLogged
    },
  }))

  const { infoMessage } = useToastify()

  const queryClient = useQueryClient()

  const history = useHistory()

  const toast = useToast()
  const toastIdRef = useRef<null | ToastId>(null)

  const closeToast = useCallback(() => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }, [toastIdRef, toast])

  const addToast = useCallback((message: any) => {
    toastIdRef.current = toast({
      position: 'top-right',
      status: 'info',
      isClosable: true,

      render: () => (
        <Box rounded="md" shadow="base" position="relative" color='white' p={3} bg='blue.500'>
          <Flex direction="column" w="90%">
            <Text textAlign="left">
              Uma coleta do serviço   {message.serviceProtocol} foi enviada para validação como sem sucesso.
            </Text>
            <Text
              onClick={() => history.push(`/comunicacoes-internas?type=team&comunicationProtocol=${message.internComunicationProtocol}`)}
              display="flex"
              alignItems="center"
              justifyContent="start"
              gap="2"
              cursor="pointer"
              textAlign="left"
            >
              Responder comunicação
              <FaExternalLinkAlt />
            </Text>
          </Flex>
          <Icon
            as={FaX}
            position="absolute"
            right="2"
            top="2"
            onClick={closeToast}
            cursor="pointer"
          />

        </Box>
      )
    })
  }, [history, toast, closeToast])

  // useEffect(() => {
  //   if (userLogged && !!userLogged?.collector_id) {
  //     serviceSocketConnection?.emit('join collector room', {
  //       collectorId: userLogged?.collector_id
  //     })
  //   }
  // }, [userLogged, serviceSocketConnection])

  // useEffect(() => {
  //   serviceSocketConnection?.on('delayedCollect', data => {
  //     infoMessage(data.service_id)
  //   })

  //   return () => {
  //     serviceSocketConnection?.off("delayedCollect")
  //   }
  // }, [infoMessage, serviceSocketConnection])

  // useEffect(() => {
  //   serviceSocketConnection?.on('delayedDelivery', data => {
  //     infoMessage(data.service_id)
  //   })

  //   return () => {
  //     serviceSocketConnection?.off("delayedDelivery")
  //   }
  // }, [serviceSocketConnection, infoMessage])

  useEffect(() => {

    serviceSocketConnection?.on("getRequestedCancelCollectAddressNotification", async ({
      user_types,
      service_protocol
    }: RequestedCancelCollectAddressNotificationProps) => {
      const isUserLoggedNotificationUserType =
        user_types.includes(userLogged?.user_type as UserTypes)

      if (isUserLoggedNotificationUserType) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications'] })

        const requestedCancelCollectAddressMessage =
          `Foi solicitado o cancelamento de um endereço de coleta para o serviço ${service_protocol}`

        infoMessage(requestedCancelCollectAddressMessage)
      }
    })

    serviceSocketConnection?.on("getIncludedCollectAddressNotification", async ({
      user_type,
      service_protocol
    }: IncludedCollectAddressNotificationProps) => {
      const isUserLoggedNotificationUserType = userLogged?.user_type === user_type

      if (isUserLoggedNotificationUserType) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications'] })

        const includedCollectAddressMessage = `Foi solicitada a inclusão de um novo endereço para o serviço ${service_protocol}`

        infoMessage(includedCollectAddressMessage)
      }
    })

    serviceSocketConnection?.on("getRequestedCancelServiceNotification", async ({
      user_types,
      service_protocol
    }: RequestedCancelServiceNotificationProps) => {
      const isUserLoggedNotificationUserType =
        user_types.includes(userLogged?.user_type as UserTypes)

      if (isUserLoggedNotificationUserType) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications'] })

        const requestedCancelServiceMessage =
          `Foi solicitado o cancelamento do serviço ${service_protocol}`

        infoMessage(requestedCancelServiceMessage)
      }
    })

    const handle = async (message: SetUnsuccessServiceNotificationProps) => {
      const userLoggedCanReceiveNotification = message.profilesCanReceive.includes(userLogged?.user_type)

      if (userLoggedCanReceiveNotification) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications'] })

        addToast(message)
      }
    }

    serviceSocketConnection?.on("getSetUnsuccessCollectAddressNotification", handle)


    return () => {
      serviceSocketConnection?.off("getRequestedCancelCollectAddressNotification")
      serviceSocketConnection?.off("getIncludedCollectAddressNotification")
      serviceSocketConnection?.off("getRequestedCancelServiceNotification")
      serviceSocketConnection?.off("getSetUnsuccessCollectAddressNotification")
    }
  }, [serviceSocketConnection, queryClient, userLogged, infoMessage, addToast])

  return (
    <ServiceSocketContext.Provider value={{
      serviceSocketConnection
    }}>
      {children}
    </ServiceSocketContext.Provider>
  )
}
