import { Box, Button, Flex, FormControl, FormLabel, Heading, Select, Text, Textarea, VStack } from "@chakra-ui/react"
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { SolveSupportAttachmentTableRowField } from "./SolveSupportAttachmentTableRowField";
import { useReducer } from "react";
import { validateSupportResolution } from "api/supports/validateSupportResolution";

interface SolveSupportProps {
  supportId: string
  supportResolutionReplyId: string
}

export interface SolveSupportSchema {
  doesNeedNewReturn: string
  content: string
  attachments: {
    file: FileList
  }[]

}

const solveSupportSchema = yup.object({
  doesNeedNewReturn: yup.string().required(),
  content: yup.mixed().when('doesNeedNewReturn', {
    is: 'yes',
    then: yup.string().required()
  }),
  attachments: yup.mixed().when('doesNeedNewReturn', {
    is: 'yes',
    then: yup.array()
  }),

})

interface SetAttachmentIdActionPayload {
  attachmentId: string
}

export interface SetAttachmentIdAction {
  type: 'ADD' | 'DELETE'
  payload: SetAttachmentIdActionPayload
}

function reducer(state: string[], action: SetAttachmentIdAction) {
  if (action.type === 'ADD') {
    state.push(action.payload.attachmentId)
  }

  if (action.type === 'DELETE') {
    const attachmentIndex = state.findIndex(attachmentId => attachmentId === action.payload.attachmentId)

    state.splice(attachmentIndex, 1)
  }

  return state
}

export function SendSupportResolutionReturn({ supportId, supportResolutionReplyId }: SolveSupportProps) {
  const [attachmentsIds, setAttachmentIds] = useReducer(reducer, [])

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const formMethods = useForm<SolveSupportSchema>({
    resolver: yupResolver(solveSupportSchema),
    defaultValues: {
      attachments: [{ file: undefined }]
    }
  })

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = formMethods

  const {
    fields: attachmentsFields,
    append: appendAttachmentsField,
    remove: removeAttachmentsField,
  } = useFieldArray({
    control,
    name: 'attachments'
  })

  const [attachments, doesNeedNewReturn] = useWatch({
    control,
    name: ['attachments', 'doesNeedNewReturn']
  })


  const hasConfirmDoesNeedNewReturn = doesNeedNewReturn === 'yes'

  const { mutateAsync: validateSupportResolutionFn } = useMutation({
    mutationFn: validateSupportResolution,
    onSuccess(_data, { body, supportId }) {
      queryClient.invalidateQueries({ queryKey: 'supporting-supports' })
      queryClient.invalidateQueries({ queryKey: 'waiting-return-supports' })
      queryClient.invalidateQueries({ queryKey: 'done-supports' })
      queryClient.invalidateQueries({ queryKey: 'cancelled-supports' })
      queryClient.invalidateQueries({ queryKey: ['support', supportId] })
    }
  })


  async function handleSolveSupport(values: SolveSupportSchema) {
    await promiseMessage(validateSupportResolutionFn({
      body: {
        doesNeedNewReturn: values.doesNeedNewReturn === 'yes',
        attachmentsIds,
        content: values.content,
      },
      supportId,
      supportResolutionReplyId
    }), 'Atendimento realizado!')
  }



  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleSolveSupport)}
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'>Retorno de Atendimento</Heading>

      <FormControl isInvalid={!!errors?.doesNeedNewReturn}>
        <FormLabel fontSize='sm' mt={3}>
          Solicitar novo retorno?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register('doesNeedNewReturn')}
          name="doesNeedNewReturn"
          placeholder="Selecione..."
          size="sm"
          rounded="md"
        >
          <option value="yes">Sim</option>
          <option value="no">Não</option>
        </Select>
      </FormControl>

      {hasConfirmDoesNeedNewReturn && (
        <>
          <FormControl isInvalid={!!errors?.content}>
            <FormLabel fontSize='sm' mt={3}>
              Descrição
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Textarea
              {...register('content')}
              name="content"
              size="sm"
              rounded="md" />
          </FormControl>
          <FormLabel fontSize='sm' mt={3}>
            Anexos (Opcional)
            <Text as="sup" color="red.500">*</Text>
          </FormLabel><VStack spacing={3}>
            {attachmentsFields.map((field, index) => {

              const isFilledField = Boolean(attachments[index]?.file?.length);

              return (

                <FormProvider key={field.id} {...formMethods}>
                  <SolveSupportAttachmentTableRowField
                    key={field.id}
                    index={index}
                    onSetAttachmentId={setAttachmentIds}
                    isFilledField={isFilledField}
                    onAppendField={() => appendAttachmentsField({})}
                    onRemoveField={() => removeAttachmentsField(index)} />
                </FormProvider>
              );
            })}
          </VStack>
        </>
      )}

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
