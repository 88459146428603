import { Button, Flex, FormControl, FormLabel, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Progress, Select, Stack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { activatePatrimonyRequested } from "api/patrimonies/activatePatrimonyRequested";
import { getPatrimonies } from "api/patrimonies/getPatrimonies";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { useToastify } from "hooks/toastify/useToastify";
import { ChangeEvent } from "react";
import { useForm, useWatch } from "react-hook-form";
import { FaFileImport, FaExternalLinkAlt } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { validateHasFile } from "utils/fileValidation";
import * as yup from "yup";

interface ActivateRequestedPatrimonySchema {
  patrimonyId: string
  receipt: FileList
}

interface ActivateRequestedPatrimonyProps {
  patrimonyType: string
  patrimonyRequestId: string
  onClose: () => void
}

const activateRequestedPatrimonySchema = yup.object({
  patrimonyId: yup.string().required('Campo obrigatório'),
  receipt: yup.mixed().test(validateHasFile).required()
})

export function ActivateRequestedPatrimony({
  patrimonyType,
  patrimonyRequestId,
  onClose
}: ActivateRequestedPatrimonyProps) {

  const { data: patrimoniesResult } = useQuery({
    queryFn: () => getPatrimonies({ type: patrimonyType, status: 'available' })
  })

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<ActivateRequestedPatrimonySchema>({
    resolver: yupResolver(activateRequestedPatrimonySchema)
  })

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const { mutateAsync: activatePatrimonyRequestedFn } = useMutation({
    mutationFn: activatePatrimonyRequested,
    onSuccess(_data) {
      queryClient.invalidateQueries(['requestsPatrimony'])
      onClose()
    },
  })

  const { mutation: uploadReceiptAttachmentFn, uploadProggress: uploadProggressReceiptAttachment } = useAttachmentMutation()

  const receipt = useWatch({ control, name: 'receipt' })

  async function handleUploadReceiptAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadReceiptAttachmentFn.mutateAsync(formData)
  }

  async function handleActivatePatrimonyRequested(values: ActivateRequestedPatrimonySchema) {
    await promiseMessage(activatePatrimonyRequestedFn({
      patrimonyRequestId: patrimonyRequestId,
      patrimonyId: values.patrimonyId,
      receiptAttachmentId: uploadReceiptAttachmentFn?.data?.attachment?.id
    }), 'Patrimônio ativado!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Ativar Patrimônio Solicitado
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <Flex
          direction="column"
          gap="4"
          as="form"
          onSubmit={handleSubmit(handleActivatePatrimonyRequested)}
        >
          <Stack
            spacing="1"
          >
            <FormLabel fontSize="sm">
              Patrimônio
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.patrimonyId}>
              <Select
                {...register('patrimonyId')}
                placeholder="Selecione uma opção..."
              >
                {patrimoniesResult?.patrimonies?.map((patrimony) => {
                  return (
                    <option value={patrimony.id} >{patrimony.brand} - {patrimony.identifier}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>

          <Stack
            spacing="6"
            direction={["column", "column", "column"]}
            mt="3"
          >
            <Stack
              direction="column"
              spacing="0.25"
              w="full"
            >

              <Button
                as={FormLabel}
                htmlFor="receipt"
                lineHeight="1"
                leftIcon={<FaFileImport />}
                size="sm"
                w="min"
                cursor="pointer"
                border={!!errors?.receipt && '2px solid'}
                borderColor={(!!errors?.receipt) && 'red.500'}
              >
                Anexar comprovante de recebimento assinado
              </Button>
              <FormControl isInvalid={!!errors?.receipt}>
                <Input
                  {...register('receipt')}
                  name="receipt"
                  id="receipt"
                  type="file"
                  hidden
                  onChangeCapture={handleUploadReceiptAttachment}
                />
              </FormControl>
            </Stack>

            {receipt && (
              <Table size="sm">
                <Tbody>
                  {Object.entries(receipt).map(([key, file]) => {
                    return (
                      <Tr key={key}>
                        <Td fontSize="xs" maxW="100px">{file.name}</Td>
                        <Td fontSize="xs" w="200px">
                          <Progress size="sm" rounded="md" value={uploadProggressReceiptAttachment} />
                        </Td>
                        <Td fontSize="xs" isNumeric>
                          {uploadReceiptAttachmentFn.data && (
                            <IconButton
                              aria-label="Visualizar anexo"
                              as={Link}
                              size="sm"
                              icon={<FaExternalLinkAlt />}
                              href={uploadReceiptAttachmentFn.data.attachment.link}
                              isExternal
                            />
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            )}
          </Stack>

          <Button
            type="submit"
            colorScheme="blue"
            alignSelf="end"
            w="fit-content"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Ativar
          </Button>
        </Flex>
      </ModalBody>

    </ModalContent>
  )
}
