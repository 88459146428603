import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Onboarding, OnboardingStatus, OnboardingType } from "./onboarding";

interface GetOnboardingsParams {
  pageSize: string
  currentPage: string
  status?: OnboardingStatus | 'all' | null
  name?: string | null
  trainingType?: OnboardingType | 'all' | null
}

export interface GetOnboardingsResponse {
  onboardings: Onboarding[]
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
    count: number
  }
}

export async function getOnboardings({
  pageSize,
  currentPage,
  status,
  name,
  trainingType
}: GetOnboardingsParams) {
  try {
    const { data } = await api.get<GetOnboardingsResponse>('/onboardings', {
      params: {
        pageSize,
        currentPage,
        status,
        name,
        trainingType
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

