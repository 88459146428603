import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

import { useParams } from 'react-router'

import { useState } from "react";
import { CitiesProps } from "services/getFunctions/city/getCity";
import { daysOfWeekOptions } from "utils/CustomLists/daysOfWeekOptions";
import { EmptyContentTableAlert } from "components/Alerts/EmptyContentTableAlert";
import { useAuth } from "hooks/auth/useAuth";
import { useQuery } from "react-query";
import { getBudgetLogs } from "api/budgets/getBudgetLogs";
import { usePagination } from "@ajna/pagination";
import { Pagination } from "components/Pagination/Pagination";
import { format } from "date-fns";
import { captalize } from "utils/captalize";
import { BudgetProps } from "contexts/BudgetContext";

function fieldNameMap(fieldName: keyof BudgetProps) {
  switch (fieldName) {
    case 'customer_id':
      return 'Cliente'
    case 'situation':
      return 'Situação'
    case 'source_hub_id':
      return 'Hub de origem'
    case 'crossdocking_hub_id':
      return 'Hub crossdocking'
    case 'destination_hub_id':
      return 'Hub destino'
    case 'priority_budget':
      return 'Orçamento prioritário?'
    case 'source_cities':
      return 'Cidade(s) de origem'
    case 'destination_cities':
      return 'Cidade(s) de destino'
    case 'source_address_qty':
      return 'Qtde endereço(s) de origem'
    case 'destination_address_qty':
      return 'Qtde endereço(s) de destino'
    case 'deadline_delivery':
      return 'Horário limite de entrega'
    case 'deadline':
      return 'Prazo de entrega'
    case 'service_type':
      return 'Tipo de serviço'
    case 'franchising':
      return 'Franquia'
    case 'modal':
      return 'Modal'
    case 'vehicle':
      return 'Veículo'
    case 'last_mile_budget':
      return 'Budget last mile (coleta + entrega)'
    case 'caixa_termica':
      return 'Caixa térmica'
    case 'gelo_seco':
      return 'Gelo seco'
    case 'gelox':
      return 'Gelox'
    case 'isopor3l':
      return 'Isopor 3l'
    case 'isopor7l':
      return 'Isopor 7l'
    case 'terciaria3l':
      return 'Terciária 3l'
    case 'terciaria8l':
      return 'Terciária 8l'
    case 'embalagem_secundaria':
      return 'Embalagem secundária ziplock'
    case 'price':
      return 'Preço total'
    case 'price_unsuccessful_collect':
      return 'Preço da coleta sem sucesso'
    case 'price_kg_extra':
      return 'Preço KG extra'
    case 'transfer_budget':
      return 'Budget de transferência'
    case 'price_add_collect':
      return 'Preço da coleta adicional'
    case 'price_add_delivery':
      return 'Preço da entrega adicional'
    case 'days_service':
      return 'Dias de atendimento'
    case 'observation':
      return 'Observações'
    default:
      return '-'
  }
}

interface Params {
  id: string
}

interface BudgetLogsModalProps {
  isOpen: boolean
  onClose: () => void
  cities: CitiesProps[]
}

export function BudgetLogsModal({
  isOpen,
  onClose,
}: BudgetLogsModalProps) {
  const [currentPaginationPage, setCurrentPaginationPage] = useState(1)

  const { userLogged } = useAuth()

  const { id: budgetId } = useParams<Params>()

  const {
    data: budgetLogsResult,
    isLoading: isLoadingBudgetLogsResult
  } = useQuery({
    queryKey: ['budget-logs', budgetId, currentPaginationPage],
    queryFn: () => getBudgetLogs({
      budgetId,
      currentPage: currentPaginationPage,
      pageSize: 10
    }),
    refetchOnWindowFocus: false
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: budgetLogsResult?.meta?.totalPages,
    initialState: {
      currentPage: currentPaginationPage,
      pageSize: budgetLogsResult?.meta?.pageSize
    },
  })

  const daysServiceCheckboxOptions = daysOfWeekOptions.map(day => {
    return {
      ...day,
      value: day.value.toString()
    }
  })

  const budgetHasLogs = budgetLogsResult?.logs.length > 0

  const isCustomerUserLogged = userLogged?.user_type === 'CLIENTE'

  function handleChangePage(page: number) {
    setCurrentPaginationPage(page)

    setCurrentPage(page)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="5xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Logs</ModalHeader>
        <ModalCloseButton />

        <ModalBody maxH='500px' h='full' overflow='auto'>
          {isLoadingBudgetLogsResult ? (
            <Spinner />
          ) : (
            budgetHasLogs ? (
              <Table
                size="sm"
              >
                <Thead
                  h="40px"
                >
                  <Tr>
                    <Th>Campo</Th>
                    <Th>Valor anterior</Th>
                    <Th>Novo Valor</Th>
                    <Th>Data</Th>
                    {!isCustomerUserLogged && (
                      <Th>Criado por</Th>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {budgetLogsResult?.logs?.map(log => {
                    if (['source_cities', 'destination_cities'].includes(log.fieldName)) {
                      return (
                        <Tr key={log.id}>
                          <Td>{fieldNameMap(log.fieldName)}</Td>
                          <Td>{log.oldValue.map(value => captalize(value)).join(', ')}</Td>
                          <Td>{log.newValue.map(value => captalize(value)).join(', ')}</Td>
                          <Td>{format(new Date(log.createdAt), 'dd/MM/yyyy - HH:mm')}</Td>
                          {!isCustomerUserLogged && (
                            <Td>{log.createdByUser ? captalize(`${log?.createdByUser?.firstname} ${log?.createdByUser?.lastname}`) : '-'}</Td>
                          )}
                        </Tr>
                      )
                    }

                    if (['days_service'].includes(log.fieldName)) {

                      const oldDaysService = log.oldValue.map((old) => {
                        const day = daysServiceCheckboxOptions.find(dayOption => dayOption.value === old.toString())

                        return day.showOption
                      })

                      const newDaysService = log.newValue.map((old) => {
                        const day = daysServiceCheckboxOptions.find(dayOption => dayOption.value === old.toString())

                        return day.showOption
                      })


                      return (
                        <Tr key={log.id}>
                          <Td>{fieldNameMap(log.fieldName)}</Td>
                          <Td>{oldDaysService.join(', ')}</Td>
                          <Td>{newDaysService.join(', ')}</Td>
                          <Td>{format(new Date(log.createdAt), 'dd/MM/yyyy - HH:mm')}</Td>
                          {!isCustomerUserLogged && (
                            <Td>{log.createdByUser ?  captalize(`${log?.createdByUser?.firstname} ${log?.createdByUser?.lastname}`) : '-'}</Td>
                          )}
                        </Tr>
                      )
                    }

                    if (log.fieldName === 'priority_budget') {
                      return (
                        <Tr key={log.id}>
                          <Td>{fieldNameMap(log.fieldName)}</Td>
                          <Td>{log.oldValue === true ? 'Sim' : 'Não'}</Td>
                          <Td>{log.newValue === true ? 'Sim' : 'Não'}</Td>
                          <Td>{format(new Date(log.createdAt), 'dd/MM/yyyy - HH:mm')}</Td>
                          {!isCustomerUserLogged && (
                            <Td>{log.createdByUser ? captalize(`${log?.createdByUser?.firstname} ${log?.createdByUser?.lastname}`) : '-'}</Td>
                          )}
                        </Tr>
                      )
                    }

                    if (log.fieldName === 'deadline_delivery') {
                      return (
                        <Tr key={log.id}>
                          <Td>{fieldNameMap(log.fieldName)}</Td>
                          <Td>{format(new Date(log.oldValue), 'HH:mm')}</Td>
                          <Td>{format(new Date(log.newValue), 'HH:mm')}</Td>
                          <Td>{format(new Date(log.createdAt), 'dd/MM/yyyy - HH:mm')}</Td>
                          {!isCustomerUserLogged && (
                            <Td>{log.createdByUser ? captalize(`${log.createdByUser.firstname} ${log.createdByUser.lastname}`) : '-'}</Td>
                          )}
                        </Tr>
                      )
                    }


                    if (['price', 'price_unsuccessful_collect', 'price_kg_extra', 'transfer_budget', 'price_add_collect', 'price_add_delivery'].includes(log.fieldName)) {
                      return (
                        <Tr key={log.id}>
                          <Td>{fieldNameMap(log.fieldName)}</Td>
                          <Td>{Number(log.oldValue.toString()).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                          })}</Td>
                          <Td>{Number(log.newValue.toString()).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                          })}</Td>
                          <Td>{format(new Date(log.createdAt), 'dd/MM/yyyy - HH:mm')}</Td>
                          {!isCustomerUserLogged && (
                            <Td>{log.createdByUser ? captalize(`${log.createdByUser.firstname} ${log.createdByUser.lastname}`) : '-'}</Td>
                          )}
                        </Tr>
                      )
                    }

                    return (
                      <Tr key={log.id}>
                        <Td>{fieldNameMap(log.fieldName)}</Td>
                        <Td>{log.oldValue}</Td>
                        <Td>{log.newValue}</Td>
                        <Td>{format(new Date(log.createdAt), 'dd/MM/yyyy - HH:mm')}</Td>
                        {!isCustomerUserLogged && (
                          <Td>{log.createdByUser ? captalize(`${log.createdByUser.firstname} ${log.createdByUser.lastname}`) : '-'}</Td>
                        )}
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            ) : (
              <EmptyContentTableAlert title="Ops..." description="Não há logs para este orçamento!" />
            )

          )}

        </ModalBody>

        <ModalFooter display="flex" justifyContent="center">
          <Pagination
            currentPage={currentPage}
            pages={pages}
            pagesQuantity={pagesCount}
            handlePageChange={handleChangePage}
          />

        </ModalFooter>
      </ModalContent>

    </Modal >
  )
}
