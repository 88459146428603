import { ServiceProps } from '../../contexts/ServiceContext'
import { api } from '../api'

export interface IRequestAllServicesParams {
  is_business?: boolean
  steps?: string[]
}

export const getAllServices = ({ is_business, steps }: IRequestAllServicesParams) =>
  api
    .get<ServiceProps[]>(`/service/?is_business=${is_business ? true : false}${steps ? `&steps=${steps}` : ''}`).then((res) => res.data)

export const getOneService = (serviceId: string) =>
  api.get<ServiceProps>(`/service/${serviceId}`).then((res) => res.data)

export const getServicesByCustomer = (customerId: string | null) =>
  api
    .get<ServiceProps[]>(`/consult/service/?customer_id=${customerId}`)
    .then((res) => res.data)

export const getServicesByProtocol = (protocolId: string | null) =>
  api
    .get<ServiceProps[]>(`/consult/service/?protocol=${protocolId}`)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })
export const getServicesByStartEndFilter = (
  startFilter: string | null,
  endFilter: string | null,
) =>
  api
    .get<ServiceProps[]>(
      `/consult/service/?startFilter=${startFilter}&endFilter=${endFilter}`,
    )
    .then((res) => res.data)
export const getServicesByStartEndFilterWithCustomer = (
  startFilter: string | null,
  endFilter: string | null,
  customerIdWithDate: string | null,
) =>
  api
    .get<ServiceProps[]>(
      `/consult/service/?startFilter=${startFilter}&endFilter=${endFilter}&customer_id=${customerIdWithDate}`,
    )
    .then((res) => res.data)
