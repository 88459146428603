import { useContext } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AuthContext } from '../../contexts/AuthContext'
import {
  getAllServices,
  getOneService,
} from '../../services/getFunctions/getServiceFunctions'

export function useService(
  serviceId: string | null,
  isEnable?: boolean,
  refetchOnFocus?: boolean,
  is_business?: boolean,
  steps?: string[]
) {
  const { handleLogout, userLogged } = useContext(AuthContext)

  const services = useQuery('services', () => getAllServices({ is_business, steps }), {
    enabled: !steps && !!isEnable && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (error: any) => {
      toast.error('Erro ao consultar informações! Realize o login novamente.')
      handleLogout()
    },
  })

  const servicesBySteps = useQuery('servicesBySteps', () => getAllServices({ steps }), {
    enabled: !!steps && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (error: any) => {
      toast.error('Erro ao consultar informações! Realize o login novamente.')
      handleLogout()
    },
    cacheTime: 0
  })

  const businessServices = useQuery('businessServices', () => getAllServices({ is_business: true }), {
    enabled: !isEnable && !!userLogged && !!is_business,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (error: any) => {
      toast.error('Erro ao consultar informações! Realize o login novamente.')
      handleLogout()
    },
  })
  const service = useQuery(
    ['service', serviceId],
    () => getOneService(serviceId !== null ? serviceId : ''),
    {
      enabled: !!serviceId && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )

  return {
    services,
    servicesBySteps,
    service,
    businessServices,
  }
}
