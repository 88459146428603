import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  CheckboxGroup,
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckBoxProps,
  Text,
  Stack,
  Box,
  Flex,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import { FieldError } from 'react-hook-form'

interface DaysOfWeekProps {
  value: number
  name: string
  disabled?: boolean
}

interface HubProps {
  id: string
  name: string
}

interface InputProps extends ChakraCheckBoxProps {
  slug?: string
  id?: string
  defaultOption?: string[]
  days_of_week?: DaysOfWeekProps[]
  hubs?: HubProps[]
  name: string
  label?: string
  required?: boolean
  error?: FieldError
  onCheckboxChange: () => void
}

const CheckboxBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    slug,
    id,
    days_of_week,
    hubs,
    defaultOption,
    required = false,
    error = null,
    onCheckboxChange,
    ...rest
  },
  ref,
) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <Flex flex="1" justify="space-between" >
          <FormLabel htmlFor={name} noOfLines={1}>
            <Flex h="full" w="full" gap="1" align="center">
              <Text fontSize="sm" color="gray.700" w="full">
                {label}
              </Text>
              {required && (
                <Text display="block" mt="2" color="red" fontWeight="bold" fontSize="sm" as="sup">
                  {' '}
                  *
                </Text>
              )}
            </Flex>
          </FormLabel>
        </Flex>
      )}

      {!!days_of_week && (
        <Box
          overflowY="auto"
          height="100px"
          borderWidth="1px"
          borderRadius="lg"
          p="2"
        >
          <Stack spacing="10px" justify="flex-start">
            {slug !== 'adicionar' && (
              <CheckboxGroup
                colorScheme="blue"
                onChange={onCheckboxChange}
                defaultValue={defaultOption}
              >
                <Stack direction="column">
                  {days_of_week?.map((day) => (
                    <ChakraCheckbox
                      key={day.value}
                      name={name}
                      label={label}
                      value={day.name}
                      ref={ref}
                      {...rest}
                      isDisabled={day.disabled}
                    >
                      {day.name}
                    </ChakraCheckbox>
                  ))}
                </Stack>
              </CheckboxGroup>
            )}
          </Stack>
        </Box>
      )}

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const CheckboxDaysWeek = forwardRef(CheckboxBase)
