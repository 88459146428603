import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CheckInvoiceValuesBody {
  isReceiptCnpjSameOfRegistered: boolean
  isValueSameOfApprovedInvoice: boolean
  receiptAttachmentId: string
}

interface CheckInvoiceValuesProps {
  invoiceId: string
  body: CheckInvoiceValuesBody
}

export async function checkInvoiceValues({
  invoiceId,
  body
}: CheckInvoiceValuesProps) {
  try {
    await api.patch(`invoices/${invoiceId}/check-values`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
