import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, Icon, Spinner, Table, TableContainer, Tbody, Thead, useDisclosure } from "@chakra-ui/react";
import { getDeliveriesRelation } from "api/service/getDeliveriesRelation";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { FaFilter } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { DeliveriesRelationsFilters } from "./DeliveriesRelationsFilters";
import { DeliveriesRelationsTableHead } from "./DeliveriesRelationsTableHead";
import { DeliveriesTableRow } from "./DeliveriesTableRow";


export function DeliveriesRelations() {

  const searchParams = useSearchParams()
  const { userLogged } = useAuth()

  const page = searchParams.get('page') ?? 1

  const history = useHistory()

  const ROWS_PER_PAGE = 10

  const protocols = searchParams.get('protocols')
  const customers = searchParams.get('customers')
  const deliveryDates = searchParams.get('deliveryDates')
  const deliveryHours = searchParams.get('deliveryHours')
  const withdrawInfos = searchParams.get('withdrawInfos')
  const sourceAddresses = searchParams.get('sourceAddresses')
  const destinationAddresses = searchParams.get('destinationAddresses')
  const steps = searchParams.get('steps')
  const sourceCollectors = searchParams.get('sourceCollectors')
  const destinationCollectors = searchParams.get('destinationCollectors')


  const {
    data: deliveriesRelationsResult,
    isLoading: isLoadingDeliveriesRelationsResult
  } = useQuery({
    queryKey: ['deliveries-relations',
      page,
      customers,
      protocols,
      destinationAddresses,
      sourceCollectors,
      destinationCollectors,
      deliveryDates,
      deliveryHours,
      withdrawInfos,
      sourceAddresses,
      steps
    ],
    queryFn: () => getDeliveriesRelation({
      currentPage: Number(page),
      pageSize: ROWS_PER_PAGE,
      timezoneOffset: new Date().getTimezoneOffset() / 60,
      customers,
      protocols,
      destinationAddresses,
      sourceCollectors,
      destinationCollectors,
      deliveryDates,
      deliveryHours,
      withdrawInfos,
      sourceAddresses,
      steps: steps ? steps : [
        'toBoardValidate',
        'toAllocateService',
        'toAvailableService',
        'availableService',
        'toLandingService',
        'landingService',
        'toMaintenanceService',
        'ongoingMaintenanceService',
        'toValidateFiscalRetention',
        'toDeliveryService',
        'deliveringService',
      ].join(','),
      collectorId: userLogged?.collector_id ?? null
    }),
    refetchOnWindowFocus: false
  })

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: deliveriesRelationsResult?.meta?.totalPages,
      initialState: {
        pageSize: ROWS_PER_PAGE,
        isDisabled: false,
        currentPage: Number(page),
      },
    });


  const handleChangePage = (page: number) => {
    setCurrentPage(page)
    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <Flex w='full' justifyContent='space-between' >
        <Heading letterSpacing="tight">Entregas</Heading>
        <Button
          variant="ghost"
          onClick={onToggle}
          boxShadow="md"
          borderRadius="md"
          size="sm"
          leftIcon={
            <Icon as={FaFilter} />
          }
        >
          Filtros
        </Button>
      </Flex>
      <DeliveriesRelationsFilters
        onToggle={onToggle}
        isOpen={isOpen}
      />
      <TableContainer rounded="md" mt={6}>
        <Table size="sm">
          <Thead>
            <DeliveriesRelationsTableHead />
          </Thead>
          <Tbody>
            {isLoadingDeliveriesRelationsResult && (
              <Spinner />
            )}
            {deliveriesRelationsResult?.services?.map((service) => {
              return (
                <DeliveriesTableRow
                  key={service.id}
                  service={service}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
