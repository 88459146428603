import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { useAuth } from 'hooks/auth/useAuth'
import { useToastify } from 'hooks/toastify/useToastify'
import { useCollectorCostFunctions } from 'hooks/cost/useCollectorCostFunctions'
import { transformStringToNumber } from 'utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { AdministrativeRegionConditional, CollectorCost, DayConditional, PeriodConditional } from 'hooks/cost/dtos/CollectorCost'
import { CollectorCostForm } from './CollectorCostForm'
import { useSwal } from 'hooks/swal/useSwal'
import { getPrimaryExpressCostByCity } from 'api/expressCosts/getPrimaryExpressCostByCity'


interface QueryParams {
  slug: string
  id: string
}

interface Attachments {
  attachment: FileList
}

interface FormInputs {
  collector_id: string
  city_id: string
  cost_motorcycle: number
  additional_cost_motorcycle: number
  cost_car: number
  additional_cost_car: number
  cost_truck: number
  additional_cost_truck: number
  observation: string
  attachments: Attachments[]
  admRegionConditionals: AdministrativeRegionConditional[]
  periodConditionals: PeriodConditional[]
  dayConditionals: DayConditional[]
  is_primary_collector: 'yes' | 'no'
}

export function CollectorCostAddDetailEdit() {
  const { slug, id }: QueryParams = useParams()

  const { promiseMessage } = useToastify()

  const {
    createCollectorCost: {
      mutateAsync: createCollectorCost,
      isLoading: isCreateCollectorCostLoading
    },
    updateCollectorCost: {
      mutateAsync: updateCollectorCost,
      isLoading: isUpdateCollectorCostLoading
    },
    updateCollectorCostAttachments: {
      mutateAsync: updateCollectorCostAttachments,
      isLoading: isUpdateCollectorCostAttachmentsLoading
    }
  } = useCollectorCostFunctions()

  const { userLogged } = useAuth()
  const { confirmMessage } = useSwal()
  const history = useHistory()

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (slug === 'adicionar') {
            if (!permissions?.includes('add-collector-cost')) {
              history.push('/')
            }
          }
          if (slug === 'editar') {
            if (!permissions?.includes('edit-collector-cost')) {
              history.push('/')
            }
          }
          if (slug === 'visualizar') {
            if (!permissions?.includes('view-collector-cost')) {
              history.push('/')
            }
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [slug, history, userLogged])

  async function handleCreateCollectorCost(values: FormInputs) {
    const { collectorCost } = await getPrimaryExpressCostByCity({ cityId: values.city_id })

    let hasOpenConfirmMessage = false

    if (collectorCost) {
      hasOpenConfirmMessage = true
    }

    const hasConfirmMessage = !hasOpenConfirmMessage || await confirmMessage({
      title: 'Deseja alterar o coletador principal?',
      text: `O atual coletador principal da cidade é o ${collectorCost?.collectorIDCollectorCost?.trading_name}`,
      buttons: ['Não', 'Sim']
    })

    if (hasConfirmMessage) {
      await promiseMessage(createCollectorCost({
        ...values,
        is_primary_collector: values.is_primary_collector === 'yes',
        cost_motorcycle: transformStringToNumber(String(values.cost_motorcycle)),
        additional_cost_motorcycle: transformStringToNumber(String(values.additional_cost_motorcycle)),
        cost_car: transformStringToNumber(String(values.cost_car)),
        additional_cost_car: transformStringToNumber(String(values.additional_cost_car)),
        cost_truck: transformStringToNumber(String(values.cost_truck)),
        additional_cost_truck: transformStringToNumber(String(values.additional_cost_truck)),
        conditionals: {
          admRegionConditionals: values.admRegionConditionals,
          dayConditionals: values.dayConditionals,
          periodConditionals: values.periodConditionals
        }
      }, {
        onSuccess: async (data: CollectorCost) => {
          if (values.attachments.length !== 0) {
            const formData = new FormData()
            Object.entries(values.attachments).forEach(([key, file]) => {
              formData.append("attachments", file.attachment[0])
            })
            await updateCollectorCostAttachments({ collectorCostId: data.id, input: formData })
          }
          history.push('/custos/coletadores')
        }
      }), 'Custo de coletador criado com sucesso!')
    }
  }


  async function handleEditCollectorCost(values: FormInputs) {

    const { collectorCost } = await getPrimaryExpressCostByCity({ cityId: values.city_id })

    let hasOpenConfirmMessage = false

    if (collectorCost) {
      hasOpenConfirmMessage = true
    }

    const hasConfirmMessage = !hasOpenConfirmMessage || await confirmMessage({
      title: 'Deseja alterar o coletador principal?',
      text: `O atual coletador principal da cidade é o ${collectorCost?.collectorIDCollectorCost?.trading_name}`,
      buttons: ['Não', 'Sim']
    })

    if (hasConfirmMessage) {
      await promiseMessage(updateCollectorCost({
        id,
        input: {
          ...values,
          is_primary_collector: values.is_primary_collector === 'yes',
          cost_motorcycle: transformStringToNumber(String(values.cost_motorcycle)),
          additional_cost_motorcycle: transformStringToNumber(String(values.additional_cost_motorcycle)),
          cost_car: transformStringToNumber(String(values.cost_car)),
          additional_cost_car: transformStringToNumber(String(values.additional_cost_car)),
          cost_truck: transformStringToNumber(String(values.cost_truck)),
          additional_cost_truck: transformStringToNumber(String(values.additional_cost_truck)),
          conditionals: {
            admRegionConditionals: values.admRegionConditionals,
            dayConditionals: values.dayConditionals,
            periodConditionals: values.periodConditionals
          }
        }
      }, {
        onSuccess: async () => {
          if (values.attachments.length !== 0) {
            const formData = new FormData()
            Object.entries(values.attachments).forEach(([key, file]) => {
              formData.append("attachments", file.attachment[0])
            })

            await updateCollectorCostAttachments({ collectorCostId: id, input: formData })
          }
          history.push('/custos/coletadores')
        }
      }), 'Custo de coletador atualizado com sucesso!')
    }


  }

  if (isCreateCollectorCostLoading || isUpdateCollectorCostLoading || isUpdateCollectorCostAttachmentsLoading) {
    return <GeneralContentLoading />
  }

  return (
    <CollectorCostForm
      slug={slug}
      id={id}
      href={slug === 'visualizar' ? `/custos/coletador/editar/${id}` : ''}
      submit={
        slug === 'adicionar'
          ? handleCreateCollectorCost
          : slug === 'editar'
            ? handleEditCollectorCost
            : async () => { }
      }
      title={
        slug === 'adicionar'
          ? 'Adicionar Custo de Coletador'
          : slug === 'editar'
            ? 'Editar Custo de Coletador'
            : 'Visualizar Custo de Coletador'
      }
      isDisabled={slug === 'visualizar'}
      action={slug === 'visualizar' ? 'Editar' : 'Salvar'}
    />
  )
}
