import { LogProps } from '../../../contexts/LogContext'
import { api } from '../../api'

const getAllLogs = () =>
  api
    .get<LogProps[]>('/logs')
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err.response.data.message)
    })

const getLogsByProtocol = (protocol: string | null) =>
  api
    .get<LogProps[]>(`/logs/?protocol=${protocol}`)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err.response.data.message)
    })

const getLogsByDate = (date: string) =>
  api
    .get<LogProps[]>(`/logs/?date=${date}`)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err.response.data.message)
    })

export { getAllLogs, getLogsByProtocol, getLogsByDate }
