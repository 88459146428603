import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { getPurchaseOrders } from "api/purchaseOrders/getPurchaseOrders";
import { PurchaseOrderStatus } from "api/purchaseOrders/purchaseOrder";
import { Pagination } from "components/Pagination/Pagination";

import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { PurchaseOrdersTableFilters } from "./components/PurchaseOrdersTableFilters";
import { PurchaseOrdersTableRow } from "./components/PurchaseOrdersTableRow";

export function PurchaseOrders() {
  const { replace, push: redirect } = useHistory()
  const searchParams = useSearchParams()

  const { userLogged } = useAuth()

  const userHasViewPurchaseOrdersPermission = userLogged?.permissions.includes('view-purchase-orders')

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status') ?? 'all'
  const protocol = searchParams.get('protocol')

  const { data: purchaseOrdersData } = useQuery({
    queryKey: [
      'purchaseOrders',
      page,
      status,
      protocol
    ],
    queryFn: () => getPurchaseOrders({
      currentPage: page,
      pageSize: '10',
       status: status as PurchaseOrderStatus | 'all',
       protocol: protocol
    }),
    keepPreviousData: true
  })

  useEffect(() => {
    if (!userHasViewPurchaseOrdersPermission) {
      redirect('/')
    }
  }, [userHasViewPurchaseOrdersPermission, redirect])

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: purchaseOrdersData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: purchaseOrdersData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  return (

      <Box
        bg="white"
        p="6"
        rounded="md"
      >
        <Flex
          align="center"
          justify="space-between"
        >
          <Heading letterSpacing="tight">Compras</Heading>
        </Flex>
        <PurchaseOrdersTableFilters/>
        <TableContainer
          border="1px solid"
          borderColor="gray.200"
          rounded="md"
          mt="6"
        >
          <Table size="sm">
            <Thead>
              <Tr
                h="40px"
                bg="#38c3fa"
              >
                <Th></Th>
                <Th color="white">Protocolo</Th>
                <Th color="white">Solicitante</Th>
                <Th color="white">Data Solicitação</Th>
                <Th color="white">Tipo</Th>
                <Th color="white">Tipo de Cotação</Th>
                <Th color="white">Status</Th>
                <Th color="white">Data Limite</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {purchaseOrdersData?.purchaseOrders?.map((purchaseOrder) => {
                return (
                  <PurchaseOrdersTableRow key={purchaseOrder.id} purchaseOrder={purchaseOrder} />
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />
      </Box>

  )
}
