import { AsoCollaboratorType, AsoStatus, AsoType } from "api/asos/aso";

export const asoCollaboratorTypeMap: Record<AsoCollaboratorType, string>  = {
  "aggregate-driver": 'Motorista Agregado',
  "clt-driver": "Motorista CLT",
  llm: 'Funcionário LLM',
  motorcyclist: 'Motociclista'
}


export const asoStatusMap: Record<AsoStatus, string> = {
  active: 'Ativo',
  inactive: 'Inativo',
  pending: 'Pendente'
}

export const asoTypeMap: Record<AsoType, string> = {
  admission: 'Admissional',
  periodical: 'Periódico',
  dismissal: 'Demissional'
}

