import { Button, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { deleteStock } from "api/stocks/deleteStock";
import { GetStocksResponse } from "api/stocks/getStocks";
import { Stock } from "api/stocks/types/Stock";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaEdit, FaSearch, FaTimes } from "react-icons/fa";
import { FaFileCode } from "react-icons/fa6";
import { useMutation, useQueryClient } from "react-query";
import { IHubsProps } from "utils/RequestFunctions/Hubs/requestHubFunctions";
import { EditStock } from "./EditStock";
import { StockDetail } from "./StockDetail";
import { StockLogsModal } from "./StockLogsModal";

interface StockTableRowProps {
  stock: Stock
  hubs: IHubsProps[]
}

export function StocksTableRow({ stock, hubs }: StockTableRowProps) {
  const { userLogged } = useAuth()
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const userCanDeleteStock = userLogged?.permissions.includes('remove-stock')
  const userCanEditStock = userLogged?.permissions.includes('edit-stock')

  const { mutateAsync: deleteStockFn } = useMutation({
    mutationFn: deleteStock,
    onSuccess(_data, { stockId }) {
      const cachedTraining = queryClient.getQueriesData<GetStocksResponse>({
        queryKey: ['stocks']
      })

      cachedTraining.forEach(([cachedKey, cachedData]) => {

        if (!cachedData) return

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          stocks: cachedData.stocks.filter(
            (stock) => stock.id !== stockId
          )
        })
      })
    },
  })

  async function handleDeleteStock(stockId: string) {
    await promiseMessage(deleteStockFn({ stockId }), 'Estoque excluído com sucesso!')
  }

  const {
    isOpen: isDetailModalOpen,
    onOpen: onOpenDetailModal,
    onClose: onCloseDetailModal,
  } = useDisclosure()

  const {
    isOpen: isOpenStockLogsModal,
    onOpen: onOpenStockLogsModal,
    onClose: onCloseStockLogsModal
  } = useDisclosure()

  const {
    isOpen: isEditStockModalOpen,
    onOpen: onOpenEditStockModal,
    onClose: onCloseEditStockModal
  } = useDisclosure()

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Estoque"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onOpenDetailModal}
        />
        <Modal
          isOpen={isDetailModalOpen}
          onClose={onCloseDetailModal}
        >
          <ModalOverlay />
          <StockDetail
            stockId={stock.id}
          />
        </Modal>
      </Td>

      <Td>{stock?.collectorIDStock?.trading_name}</Td>
      <Td>{hubs
        .filter((hub) => stock?.collectorIDStock?.hub_list.includes(hub.id))
        .map((hub) => hub.name)
        .join(', ').toUpperCase()}</Td>
      <Td>{stock?.inputIDStock.name}</Td>
      <Td>{stock?.current_stock}</Td>

      <Td>
        {userCanDeleteStock && (
          <Button
            leftIcon={<FaTimes />}
            lineHeight="1"
            size="xs"
            variant="ghost"
            onClick={() => handleDeleteStock(stock.id)}
          >
            Remover
          </Button>
        )}

        {userCanEditStock && (
          <>
            <Button
              leftIcon={<FaEdit />}
              lineHeight="1"
              size="xs"
              variant="ghost"
              onClick={onOpenEditStockModal}
            >
              Editar
            </Button>
            <Modal
              isOpen={isEditStockModalOpen}
              onClose={onCloseEditStockModal}
            >
              <ModalOverlay />
              <EditStock
                stockId={stock.id}
                onCloseModal={onCloseEditStockModal}
              />
            </Modal>
          </>
        )}


        <Button
          leftIcon={<FaFileCode />}
          lineHeight="1"
          size="xs"
          variant="ghost"
          onClick={onOpenStockLogsModal}
        >
          Logs
        </Button>
        <Modal
          isOpen={isOpenStockLogsModal}
          onClose={onCloseStockLogsModal}
          isCentered
        >
          <ModalOverlay />
          <StockLogsModal
            stockId={stock?.id}
          />
        </Modal>
      </Td>
    </Tr>
  )
}
