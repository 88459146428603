import { Flex, Box, Heading } from '@chakra-ui/react'
import { useState, useEffect } from 'react'

import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'

import { AvailableServiceTable } from '../../../components/Tables/Service/Available/AvailableServiceTable'

import { useService } from '../../../hooks/services/service'
import { useBranch } from '../../../hooks/branch/useBranch'
import { useAddresses } from '../../../hooks/address/useAddresses'

export function AvailableServiceList() {
  const [isLoading, setIsLoading] = useState(true)

  const {
    servicesBySteps: { data: services, isLoading: isServiceLoading, refetch },
  } = useService(null, true, false, false, ["toAvailableService", "availableService"])
  const { data: addresses, isLoading: isAddressesLoading} = useAddresses()
  const {
    branches: { data: branches, isLoading: isBranchLoading },
  } = useBranch(null, true, false)

  useEffect(() => {
    function setLoadingFalse() {
      if (!isAddressesLoading && !isServiceLoading && !isBranchLoading) {
        setIsLoading(false)
      }
    }
    setLoadingFalse()
  }, [isAddressesLoading, isServiceLoading, isBranchLoading])

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex
            mb="8"
            justify="space-between"
            align="center"
            direction={['column', 'column', 'row']}
          >
            <Heading size="lg" fontFamily="poppins">
              Serviços a Disponibilizar
            </Heading>
          </Flex>
          <AvailableServiceTable
            services={services}
            addresses={addresses}
            branches={branches}
            refetch={refetch}
          />
        </Box>
      </Flex>

  )
}
