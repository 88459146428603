import {
  Flex,
  Stack,
  Button,
  useMediaQuery,
  Icon,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { negative_positive } from '../../../../../utils/customLists'
import { Options, Select } from '../../../../../components/Inputs/SelectInput'
import { TextArea } from '../../../../../components/Inputs/TextInput'
import { FaList, FaSave } from 'react-icons/fa'
import { Link } from 'react-router-dom'

interface IValidateOccurrenceFormProps {
  isCustomerOccurrence: boolean
  isAlterInputsItercurrence: boolean
  isIntercurrenceWithDetails: boolean
  isGeloSecoQuantityChanged?: boolean
  isAlterBoardIntercurrence: boolean
  providersFilteredByBudgetSourceHubOptions?: Options[]
  routeNetworksSelectOptions?: Options[]
  occurrenceProceed: boolean
}

interface IFormProps {
  proceed: string
  is_customer: boolean
  reason?: string
  cause?: string
  service_new_planned_travel: string
  provider_id: string
  corrective?: string
  preventive?: string
}

export function ValidateOccurrenceForm({
  isCustomerOccurrence,
  isAlterInputsItercurrence,
  isIntercurrenceWithDetails,
  isAlterBoardIntercurrence = false,
  isGeloSecoQuantityChanged,
  routeNetworksSelectOptions,
  providersFilteredByBudgetSourceHubOptions,
  occurrenceProceed
}: IValidateOccurrenceFormProps) {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext<IFormProps>()

  return (
    <Flex direction="column">
      <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Select
          {...register('proceed')}
          negative_positive={negative_positive}
          placeholder="Selecione uma opção..."
          name="proceed"
          label="Procede?"
          error={errors.proceed}
          required
        />
      </Stack>

      {!occurrenceProceed && (
        <Stack mt="4">
          <TextArea
            {...register('reason')}
            name="reason"
            label="Motivo"
            error={errors.reason}
            required
          />
        </Stack>
      )
      }
      {occurrenceProceed && isAlterBoardIntercurrence && (
        <Stack mt="4">
          <Select
            {...register('service_new_planned_travel')}
            label='Nova viagem planejada'
            name='service_new_planned_travel'
            options={routeNetworksSelectOptions}
            error={errors.service_new_planned_travel}
            placeholder='Selecione uma opção...'
            required
          />
        </Stack>
      )}

      {occurrenceProceed && (
        <>
          {isGeloSecoQuantityChanged && isAlterInputsItercurrence && (
            <Stack mt="4">
              <Select
                {...register("provider_id")}
                name="provider_id"
                label="Fornecedor de gelo seco"
                placeholder="Selecione uma opção..."
                options={providersFilteredByBudgetSourceHubOptions}
                error={errors.provider_id}
                required
              />
            </Stack>
          )}
          {isCustomerOccurrence && isIntercurrenceWithDetails && (
            <>
              <Stack mt="4">
                <TextArea
                  {...register('cause')}
                  name="cause"
                  label="Causa"
                  error={errors.cause}
                  required
                />
              </Stack>
              <Stack mt="4">
                <TextArea
                  {...register('corrective')}
                  name="corrective"
                  label="Corretiva"
                  error={errors.corrective}
                  required
                />
              </Stack>
              <Stack mt="4">
                <TextArea
                  {...register('preventive')}
                  name="preventive"
                  label="Preventiva"
                  error={errors.preventive}
                  required
                />
              </Stack>
            </>
          )}
        </>
      )
      }

      <Flex align="center" justify="flex-end" mt="4">
        <Stack spacing="12px" direction="row">
          <Button colorScheme="blue" type="submit" isLoading={isSubmitting}>
            {isWideVersion ? "Validar" : <Icon as={FaSave} />}
          </Button>

          <Button as={Link} to="/ocorrencias" colorScheme="gray" type="button">
            {isWideVersion ? (
              'Lista de Ocorrências'
            ) : (
              <Icon as={FaList} />
            )}
          </Button>
        </Stack>
      </Flex>
    </Flex>
  )
}
