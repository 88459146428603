import { useQuery, UseQueryOptions } from "react-query";
import request from "axios"
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { ThirdPartyRnc } from "./dtos/ThirdPartyRnc";

interface ThirdPartyRncQueryParams {
  collector_id?: string
  provider_id?: string
  status?: string
}

type QueryOptions = UseQueryOptions<ThirdPartyRnc[], unknown, ThirdPartyRnc[], "thirdPartyRncs">

interface UseThirdPartyRncOptions {
  queryOptions?: QueryOptions
  queryParams?: ThirdPartyRncQueryParams
}

async function getThirdPartyRncs(queryParams?: ThirdPartyRncQueryParams) {
  try {
    const { data } = await api.get(`/third-party-rnc`, {
      params: { ...queryParams }
    })

    return data
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new Error("Erro inesperado!")
    }
  }
}

export function useThirdPartyRncs(options?: UseThirdPartyRncOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery("thirdPartyRncs", () => getThirdPartyRncs(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
