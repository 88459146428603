import {
  getAllCities,
  getOneCity,
} from '../../services/getFunctions/city/getCity'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useAuth } from '../auth/useAuth'

export function useCity(
  cityId: string | null,
  isEnable?: boolean,
  refetchOnFocus?: boolean,
  state?: string,
) {
  const { handleLogout, userLogged } = useAuth()

  const cities = useQuery('cities', () => getAllCities(state), {
    enabled: !!isEnable && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (error: any) => {
      toast.error('Erro ao consultar informações! Realize o login novamente.')
      handleLogout()
    },
  })
  const city = useQuery(
    ['city', cityId],
    () => getOneCity(cityId !== null ? cityId : ''),
    {
      enabled: !!cityId && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )

  return {
    cities,
    city,
  }
}
