import { Button, Flex, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Stack, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createPatrimonyType } from "api/patrimonies/createPatrimonyType";
import { editPatrimonyType } from "api/patrimonies/editPatrimonyType";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup"

interface CreateEditPatrimonyTypeProps {
  onCloseModal: () => void
  slug: 'create' | 'edit'
  patrimonyTypeId?: string
  patrimonyType?: string
}

interface CreateEditPatrimonyTypeSchema {
  type: string
}

const createEditPatrimonyTypeSchema = yup.object({
  type: yup.string().required()
})

export function CreateEditPatrimonyType({
  onCloseModal,
  slug,
  patrimonyTypeId,
  patrimonyType
}: CreateEditPatrimonyTypeProps) {
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const isCreatePatrimonyType = slug === 'create'
  const titleModalPatrimonyType = slug === 'create' ? 'Criar tipo de Patrimônio' : 'Editar tipo de Patrimônio'

  const { mutateAsync: createPatrimonyTypeFn } = useMutation({
    mutationFn: createPatrimonyType,
    onSuccess() {
      queryClient.invalidateQueries(['patrimonyTypes'])
      onCloseModal()
    }
  })

  const { mutateAsync: editPatrimonyTypeFn } = useMutation({
    mutationFn: editPatrimonyType,
    onSuccess() {
      queryClient.invalidateQueries(['patrimonyTypes'])
      onCloseModal()
    }
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CreateEditPatrimonyTypeSchema>({
    resolver: yupResolver(createEditPatrimonyTypeSchema)
  })

  useEffect(()=>{
    if(slug === 'edit'){
      setValue('type', patrimonyType)
    }
  },[patrimonyType, setValue, slug])

  async function handleCreatePatrimonyType({ type }: CreateEditPatrimonyTypeSchema) {
    await promiseMessage(createPatrimonyTypeFn({
      body: {
        type
      }
    }), 'Tipo de patrimônio criado! 🎉')
  }

  async function handleEditPatrimonyType({ type }: CreateEditPatrimonyTypeSchema) {
    await promiseMessage(editPatrimonyTypeFn({
      body: {
        type
      },
      patrimonyTypeId: patrimonyTypeId
    }), 'Tipo de patrimônio editado! 🎉')
  }

  return (
    <ModalContent>
      <ModalHeader>{titleModalPatrimonyType}</ModalHeader>

      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={isCreatePatrimonyType ?
          handleSubmit(handleCreatePatrimonyType) :
          handleSubmit(handleEditPatrimonyType)
        }
        maxH='600px'
        overflowY='scroll'
      >
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <FormControl isInvalid={!!errors?.type}>
            <FormLabel fontSize="sm">
              Tipo de Patrimônio
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('type')}
              name="type"
              size="sm"
              rounded="md"
            />
          </FormControl>
        </Stack>

        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            {isCreatePatrimonyType ? 'Criar' : 'Editar'}
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
