import { useEffect, useMemo } from "react"
import * as yup from "yup"
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm"

import { useToastify } from "hooks/toastify/useToastify"
import { FormProvider, useForm } from "react-hook-form"
import { Link, useHistory, useParams } from "react-router-dom"

import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Flex, Spinner } from "@chakra-ui/react"

import { useAuth } from "hooks/auth/useAuth"
import { usePurchaseOrderFunctions } from "hooks/purchaseOrder/usePurchaseOrderFunctions"
import { usePurchaseOrder } from "hooks/purchaseOrder/usePurchaseOrder"
import { ApprovePurchaseOrderForm, ApprovePurchaseOrderFormInputs } from "./components/ApprovePurchaseOrderForm"
import { useQueryClient } from "react-query"
import { PurchaseOrderQuoteProviders } from "hooks/purchaseOrder/dtos/PurchaseOrder"
import { handleChangeUrl } from "utils/handleChangeUrl"
import { usePurchaseOrdersSocket } from "hooks/purchaseOrder/usePurchaseOrdersSocket"

interface ParamsProps {
  purchaseOrderId: string
}

const quotePurchaseOrderSchema = yup.object().shape({
  approvedProvider: yup.mixed().required('Campo obrigatório'),
})

export function ApprovePurchaseOrder() {
  const { purchaseOrderId } = useParams<ParamsProps>()

  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()

  const { push: redirect } = useHistory()

  const {
    data: purchaseOrderData,
    isFetching: isFetchingPurchaseOrderData
  } = usePurchaseOrder({
    purchaseOrderId,
    queryOptions: {
      enabled: !!purchaseOrderId
    }
  })

  const purchaseOrderProvidersRadioOptions = useMemo(() => {
    if (purchaseOrderData) {

      const purchaseOrderQuoteProviders: PurchaseOrderQuoteProviders[] =
        JSON.parse(purchaseOrderData?.purchaseOrder.quote_providers)

      const options = purchaseOrderQuoteProviders?.map((quoteProvider, index) => {
        return {
          key: String(index),
          value: quoteProvider.providerName.toUpperCase(),
          showOption: quoteProvider.providerName,
          url: handleChangeUrl(quoteProvider.budgetAttachment)
        }
      })

      if (options) {
        return [
          ...options,
          {
            key: String(options.length + 1),
            value: 'NENHUM',
            showOption: 'NENHUM'
          }
        ]
      }
    }

    return []
  }, [purchaseOrderData])


  const userHasPermissionToApprovePurchaseOrderQuotes =
    userLogged.permissions.includes('approve-purchase-order-quotes')

  useEffect(() => {
    if (!userHasPermissionToApprovePurchaseOrderQuotes) {
      redirect('/')
    }
  }, [userHasPermissionToApprovePurchaseOrderQuotes, redirect])

  const formMethods = useForm<ApprovePurchaseOrderFormInputs>({
    resolver: yupResolver(quotePurchaseOrderSchema),
  })

  const { handleSubmit, formState: { isSubmitting } } = formMethods

  const {
    approvePurchaseOrder: {
      mutateAsync: approvePurchaseOrder
    }
  } = usePurchaseOrderFunctions()

  const queryClient = useQueryClient()

  const { purchaseOrderSocketConnection } = usePurchaseOrdersSocket()

  async function handleCreatePurchaseOrder(values: ApprovePurchaseOrderFormInputs) {

    const successMessage = "Aprovação finalizada com sucesso!"

    await promiseMessage(approvePurchaseOrder({
      purchaseOrderId,
      input: {
        approvedProvider: values.approvedProvider === 'NENHUM'
          ? null
          : values.approvedProvider
      }
    }, {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(['purchaseOrdersAwaitingApprove'])
        redirect("/compras/cotacoes/aguardando-aprovacao")

        purchaseOrderSocketConnection.emit('purchaseOrderApproved', {
          purchaseOrderProtocol: data.purchaseOrder.protocol,
          quotedBy: data.purchaseOrder.quoted_by,
        })
      }
    }), successMessage)
  }
  return (
    <StandardBackgroundForm
      title='Aprovar Cotações de Pedido de Compra'
      onSubmit={handleSubmit(handleCreatePurchaseOrder)}
    >

      <FormProvider {...formMethods}>
        {isFetchingPurchaseOrderData ? (
          <Spinner />
        ) : (
          <ApprovePurchaseOrderForm
            purchaseOrderProvidersOptions={purchaseOrderProvidersRadioOptions}
          />
        )}
      </FormProvider>

      <Flex justify='flex-end' mt={4} gap={4}>
        <Button
          as={Link}
          colorScheme='gray'
          w={['full', 'full', 'min']}
          isLoading={isSubmitting}
          to='/compras/cotacoes/aguardando-aprovacao'
        >
          Cotações Aguardando Aprovação
        </Button>
        <Button
          type='submit'
          colorScheme='blue'
        >
          Salvar
        </Button>
      </Flex>

    </StandardBackgroundForm>
  )
}
