import { Box, Flex } from "@chakra-ui/react";
import { captalize } from "utils/captalize";


interface ProductStatusProps {
  status: 'ATIVO' | 'INATIVO'
}

export function ProductStatus({ status }: ProductStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {status === 'ATIVO' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'INATIVO' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {captalize(status)}
    </Flex>
  )
}
