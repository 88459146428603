import { Box, Circle, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { FaPen, FaTag } from "react-icons/fa";
import { Vaccine } from "api/vaccines/vaccine";
import { VaccineDetail } from "./VaccineDetail";
import { vaccineCollaboratorTypeMap, vaccineTypesMap } from "utils/vaccineMappers";
import { EditVaccine } from "./EditVaccine";
import { useAuth } from "hooks/auth/useAuth";


interface VaccinesKanbanCardProps {
  vaccine: Vaccine
}

export function VaccinesKanbanCard({ vaccine }: VaccinesKanbanCardProps) {
  const { userLogged } = useAuth()
  const userHasEditVaccinePermission = userLogged?.permissions.includes('edit-vaccine')

  const {
    isOpen: isVaccineDetailModalOpen,
    onToggle: onToggleVaccineDetailModal,
  } = useDisclosure()

  const {
    isOpen: isEditVaccineModalOpen,
    onOpen: onOpenEditVaccineModal,
    onClose: onCloseEditVaccineModal
  } = useDisclosure()


  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleVaccineDetailModal}
      cursor='pointer'
    >
      <HStack justify="space-between" w="full">
        <Text fontWeight="bold" mb={2}>
          {vaccine?.collaborator_name}
        </Text>

        {userHasEditVaccinePermission && (
          <Circle
            size="5"
            color="white"
            onClick={(e) => {
              e.stopPropagation()
              onOpenEditVaccineModal()
            }}
          >
            <Icon as={FaPen} boxSize={"3.5"} color='blue.900' />
          </Circle>
        )}

      </HStack>
      <Modal
        isOpen={isEditVaccineModalOpen}
        onClose={onCloseEditVaccineModal}
        isCentered
      >
        <ModalOverlay />
        <EditVaccine onCloseModal={onCloseEditVaccineModal} vaccineId={vaccine?.id} />
      </Modal>

      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">
        <Box>
          <HStack spacing={2}>
            <Icon as={FaTag} />
            <Text fontSize="xs" fontWeight="bold">TIPO COLABORADOR</Text>
          </HStack>
          <Text ml={5}>{vaccineCollaboratorTypeMap[vaccine.collaborator_type]}</Text>
        </Box>

        <Box>
          <HStack spacing={2}>
            <Icon as={FaTag} />
            <Text fontSize="xs" fontWeight="bold">TIPO VACINA</Text>
          </HStack>
          <Text ml={5}>{vaccineTypesMap[vaccine.type]}</Text>
        </Box>

      </VStack>
      <Modal
        isOpen={isVaccineDetailModalOpen}
        onClose={onToggleVaccineDetailModal}
      >
        <ModalOverlay />
        <VaccineDetail
          vaccineId={vaccine.id}
          isKanban={true}
        />
      </Modal>
    </Box>
  )
}
