import { useEffect } from "react";
import { Button, Flex, Icon } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { useAuth } from "hooks/auth/useAuth";
import { useHistory, Link } from "react-router-dom";
import { RegionForm, RegionFormInputs } from "./components/RegionForm";
import { useToastify } from "hooks/toastify/useToastify";
import { FaList } from "react-icons/fa";
import { useRegionFunctions } from "hooks/regions/useRegionFunctions";

const createRegionFormSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório."),
  acronym: yup.string().required("Campo obrigatório."),
});

export function CreateRegion() {
  const { userLogged } = useAuth();
  const { push: redirect } = useHistory();
  const { promiseMessage } = useToastify();

  const userLoggedHasPermissionToCreateRegions =
    userLogged?.permissions.includes('create-regions')

  useEffect(() => {
    if (!userLoggedHasPermissionToCreateRegions) {
      redirect('/')
    }
  }, [userLoggedHasPermissionToCreateRegions, redirect])

  const formMethods = useForm<RegionFormInputs>({
    resolver: yupResolver(createRegionFormSchema),
  });

  const { handleSubmit } = formMethods;

  const {
    createRegion: { mutateAsync: createRegion },
  } = useRegionFunctions();

  async function handleCreateRegion(values: RegionFormInputs) {
    await promiseMessage(
      createRegion(values, {
        onSuccess: () => {
          redirect("/regioes");
        },
      }),
      "Região cadastrada com sucesso!"
    );
  }

  return (
    <StandardBackgroundForm
      onSubmit={handleSubmit(handleCreateRegion)}
      title="Adicionar Região"
    >
      <FormProvider {...formMethods}>
        <RegionForm slug="create" />
      </FormProvider>

      <Flex w="full" mt={4} gap={2} justify="flex-end">
        <Button type="submit" colorScheme="blue">
          Cadastrar
        </Button>
        <Button as={Link} to="/regioes" leftIcon={<Icon as={FaList} />}>
          Regiões
        </Button>
      </Flex>
    </StandardBackgroundForm>
  );
}
