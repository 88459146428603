import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { CollectorCost } from "./dtos/CollectorCost";

interface CollectorCostsQueryParams {
  situation?: 'ATIVO' | 'INATIVO'
  collectorId?: string
  vehicle?: 'car' | 'motorcycle' | 'truck'
  isPrimary?: boolean
}

type QueryOptions = UseQueryOptions<CollectorCost[], unknown, CollectorCost[], QueryKey>

interface UseCollectorCostsOptions {
  queryKey?: string
  queryOptions?: QueryOptions
  queryParams?: CollectorCostsQueryParams
}

export function useCollectorCosts(options?: UseCollectorCostsOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getCollectorCosts(
    queryParams?: CollectorCostsQueryParams
  ) {
    try {
      const { data } = await api.get(`/cost/collector`, {
        params: { ...queryParams }
      })

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new RequestError('Erro inesperado!')
      }
    }
  }

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    errorMessage(message)

    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) await handleLogout()

  }

  return useQuery(
    [
      options?.queryKey ?? "collectorCosts",
      { ...options?.queryParams }
    ], () => getCollectorCosts(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )
}
