import { RncProps } from '../../../utils/RequestFunctions/Rnc/requestRncFunctions'
import { api } from '../../api'

const getAllRncs = () =>
  api
    .get<RncProps[]>('/rnc')
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })

const getOneRnc = (rncID: string) =>
  api
    .get<RncProps>(`/rnc/${rncID}`)
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })

export { getAllRncs, getOneRnc }
