import { createContext, ReactNode } from 'react'
import { api } from '../services/api'

import 'react-toastify/dist/ReactToastify.css'
import swal from 'sweetalert'
import { ProviderProps } from './ProviderContext'
import { ServiceProps } from './ServiceContext'
import { IDriverProps } from '../services/getFunctions/driver/getDrivers'
import { Address } from 'hooks/address/dtos/Address'

interface CollectedServiceProviderProps {
  children: ReactNode
  pathname?: string
}

interface Attachments {
  key: string
  filename: string
}

export interface CollectedServiceProps {
  id: string
  address_id: string
  service_id: string
  driver_id: string
  provider_id: string
  step: string
  arrival_latitude: string
  arrival_longitude: string
  arrival_timestamp: string
  responsible_name: string | null
  collect_volume: number | null
  collect_sample: number | null
  box_photos?: string | Attachments[]  | null
  box_photo?: {} | null
  open_box_photo: {} | null
  content_declarations?: string | Attachments[]  | null
  content_declaration?: {}  | null
  receipt_photo: {} | null
  departure_latitude: string | null
  departure_longitude: string | null
  departure_timestamp: string | null
  unsuccess_latitude: string | null
  unsuccess_longitude: string | null
  unsuccess_timestamp: string | null
  unsuccess_reason?: string | null
  observation: string | null
  addressIDCollect: Address
  driverIDCollect: IDriverProps
  providerIDCollect: ProviderProps
  serviceIDCollect: ServiceProps
  real_arrival_timestamp?: string
  real_arrival_timestamp_validated?: boolean
  temp?: null | number
}

type CollectedServiceInput = Omit<
  CollectedServiceProps,
  | 'id'
  | 'step'
  | 'addressIDCollect'
  | 'driverIDCollect'
  | 'providerIDCollect'
  | 'serviceIDCollect'
>

type UpdateUnsuccessCollectedServiceProps = {
  hasReturnServiceToCollect: boolean
  hasValidateCollectAsUnsuccess: boolean
  collect_id: string
}

interface CollectedServiceContextProps {
  createCollectedService: (
    collectedServiceInput: CollectedServiceInput,
    isBusiness?: boolean,
  ) => Promise<boolean>
  deleteCollectedService: (
    collectedServiceId: string,
  ) => Promise<boolean | undefined>
  editCollectedService: (
    serviceId: string,
    collectedServiceInput: CollectedServiceInput,
  ) => Promise<boolean | undefined>
  editCurrentCollectedService: (
    serviceId: string,
    collectedServiceInput: CollectedServiceInput,
  ) => Promise<boolean | undefined>
  setUnsuccessCollectedService: (
    collectedServiceId: string,
    collectedServiceInput: CollectedServiceInput,
    isProvider: boolean,
  ) => Promise<boolean | undefined>
  updateUnsuccessCollectedService: (
    serviceId: string,
    updateUnsuccessCollectedServiceObj: UpdateUnsuccessCollectedServiceProps,
  ) => Promise<boolean | undefined>
}

export const CollectedServiceContext =
  createContext<CollectedServiceContextProps>(
    {} as CollectedServiceContextProps,
  )

export function CollectedServiceProvider({
  children,
  pathname,
}: CollectedServiceProviderProps) {
  async function createCollectedService(
    collectedServiceInput: CollectedServiceInput,
    isBusiness?: boolean,
  ) {
    try {
      await api.post('/collect-service', {
        ...collectedServiceInput,
        is_business: isBusiness || false,
      })
      swal('Poof! A coleta foi iniciada com sucesso!', {
        icon: 'success',
      })
      return true
    } catch {
      swal('Erro!', 'Erro ao iniciar coleta!', 'error')
      return false
    }
  }

  async function deleteCollectedService(collectedServiceId: string) {
    try {
      await api.delete(`/collect-service/${collectedServiceId}`)
      return true
    } catch {
      return false
    }
  }

  async function editCollectedService(
    serviceId: string,
    collectedServiceInput: CollectedServiceInput,
  ) {
    try {
      const editResponse = await api.put(
        `/collect-service/${serviceId}`,
        collectedServiceInput,
      )
      if (editResponse) {
        swal('Poof! A coleta foi finalizada com sucesso!', {
          icon: 'success',
        })
        return true
      }
    } catch (error: any) {
      swal('Erro!', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function editCurrentCollectedService(
    serviceId: string,
    collectedServiceInput: CollectedServiceInput,
  ) {
    try {
      await api.put(
        `in-progress/collect-service/${serviceId}`,
        collectedServiceInput,
      )
      swal('Poof! A coleta foi atualizada com sucesso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro!', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function setUnsuccessCollectedService(
    serviceId: string,
    collectedServiceInput: CollectedServiceInput,
    isProvider: boolean,
  ) {
    try {
      await api.put(`/collect-service/${serviceId}`, collectedServiceInput)

      swal('Poof! A coleta foi finalizada como sem sucesso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro!', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function updateUnsuccessCollectedService(
    serviceId: string,
    updateUnsuccessCollectedServiceObj: UpdateUnsuccessCollectedServiceProps,
  ) {
    try {
      await api.put(`/unsuccess-collect-service/${serviceId}`, {
        ...updateUnsuccessCollectedServiceObj,
      })

      swal('Poof! A coleta foi finalizada como sem sucesso!', {
        icon: 'success',
      })

      return true
    } catch (error: any) {
      swal('Erro!', `${error.response.data.message}`, 'error')
      return false
    }
  }

  return (
    <CollectedServiceContext.Provider
      value={{
        createCollectedService,
        deleteCollectedService,
        editCollectedService,
        setUnsuccessCollectedService,
        editCurrentCollectedService,
        updateUnsuccessCollectedService,
      }}
    >
      {children}
    </CollectedServiceContext.Provider>
  )
}
