import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface ReactivateAggregateParams {
  aggregateId: string
}

export async function reactivateAggregate({
  aggregateId,
}: ReactivateAggregateParams) {
  try {
    await api.patch(`/aggregates/${aggregateId}/reactivate`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
