import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Button,
  Stack,
  FormLabel,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Text,
  Link as ChakraLink
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm, useWatch, useFieldArray, FieldError } from 'react-hook-form'

import {
  types,
  situation_customer,
  payment_conditions,
  payment_types,
  emission_types,
  contribution_types,
  iss_options,
  negative_positive,
  billing_types,
  lifebox_payment_terms
} from '../../utils/customLists'


import { Input } from '../../components/Inputs/Input'
import { Select } from '../../components/Inputs/SelectInput'
import { TextArea } from '../../components/Inputs/TextInput'
import { apiCep } from '../../services/cepApiService/cepApi'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { InputMaskCustom } from '../../components/Inputs/InputMask'
import { handleSwitchMask } from '../../utils/handleChangeMask'
import { toast } from 'react-toastify'
import { useCustomer } from '../../hooks/customer/useCustomer'
import { customerSchema } from '../../validations/customerSchema'
import { apiIbge } from '../../services/ibgeApiservice/ibgeApi'
import {
  IIbgeApiCityProps,
  IIbgeApiStatesProps,
} from '../../services/ibgeApiservice/IIbgeApi'
import { formatDate } from 'utils/DateFunctions/formatDate'
import { useUsers } from 'hooks/user/useUsers'
import { FaEye, FaFilePdf, FaPlus, FaTimes, FaTrash } from 'react-icons/fa'
import { useCustomerFunctions } from 'hooks/customer/useCustomerFunctions'
import { useToastify } from 'hooks/toastify/useToastify'
import { useQueryClient } from 'react-query'
import { handleChangeUrl } from 'utils/handleChangeUrl'
import { GeneralCheckbox } from 'components/Inputs/GeneralCheckbox'
import { Radio } from 'components/Inputs/RadioInput'
import { positiveNegativeOptions } from 'utils/CustomLists/positiveNegativeOptions'

interface ICustomerContacts {
  name: string
  email: string
  phone: string
  description: string
  type: string[]
  canReceiveAutomaticallyMessages: string
}

interface IAttachments {
  attachment: FileList
}

export interface IFormInputProps {
  type: string
  situation: string
  trading_firstname: string
  company_lastname: string
  cnpj_cpf: string
  cost_center: string
  deadline_delivery: string
  operational_email: string
  financial_email: string
  cellphone: string
  telephone: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  state: string
  city: string
  has_municipal_register: string
  municipal_register: string
  has_state_register: string
  state_register: string
  attendance_responsible: string
  icms: string
  iss: string
  payment_conditional: string
  day_expiration_1: string
  day_expiration_2: string
  payment_type: string
  emission_type: string
  billing_type: string
  custom_billing_day: number
  lifebox_payment_term: string
  has_waiting_time: string
  attachments: IAttachments[]
  contacts: ICustomerContacts[]
  observation: string
  billing_observation: string
  can_receive_service_extras_email: 'yes' | 'no'
  open_address_inclusion_communication: 'yes' | 'no'
  anual_adjustment_date: string
  has_maintenance: 'yes' | 'no'
  has_temp_check: 'yes' | 'no'
}

interface CustomerFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
  registeredAttachments?: string[]
  submit: (values: IFormInputProps) => Promise<void>
}

const contactTypeCheckboxOptions = [
  { key: '0', value: 'COMERCIAL', showOption: 'COMERCIAL' },
  { key: '1', value: 'FINANCEIRO', showOption: 'FINANCEIRO' },
  { key: '2', value: 'OPERACIONAL', showOption: 'OPERACIONAL' }
]

const canReceiveServiceExtrasEmailRadioOptions = [
  { key: '0', value: 'yes', showOption: 'SIM' },
  { key: '1', value: 'no', showOption: 'NÃO' },
]

export function CustomerForm({
  slug,
  id,
  isDisabled = false,
  href,
  title,
  action,
  submit,
  registeredAttachments
}: CustomerFormProps) {
  const [statesList, setStatesList] = useState<IIbgeApiStatesProps[]>([])
  const [citiesList, setCitiesList] = useState<IIbgeApiCityProps[]>([])

  const [isLoadingPage, setIsLoadingPage] = useState(true)

  const {
    customer: { data: customer, isLoading: isCustomerLoading },
  } = useCustomer(slug !== 'adicionar' ? id || null : null, false, false)
  const { data: users, isLoading: isUsersLoading } = useUsers()

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(customerSchema),
  })

  const typeOfCustomer = watch('type')
  const selectedState = watch('state')
  const cepValue = watch('cep')
  const paymentCondition = watch('payment_conditional')
  const selectedCity = watch('city')
  const hasStateRegister = watch('has_state_register')
  const hasMunicipalRegister = watch('has_municipal_register')
  const billingType = watch('billing_type')

  const attachments = useWatch({
    control,
    name: 'attachments'
  })

  const {
    fields: contactCustomerFields,
    append: appendContactCustomerField,
    remove: removeContactCustomerField,
  } = useFieldArray({
    control,
    name: 'contacts'
  })

  const {
    fields: attachmentsFields,
    append: appendAttachmentField,
    remove: removeAttachmentField
  } = useFieldArray({
    control,
    name: 'attachments'
  })


  function handleRemoveContactCustomerField(index: number) {
    removeContactCustomerField(index)
  }

  function handleAppendContactCustomerField() {
    appendContactCustomerField({ name: '', email: '', phone: '', description: '', type: [] })
  }

  function handleRemoveAttachmentField(index: number) {
    removeAttachmentField(index)
  }

  function handleAppendAttachmentField() {
    appendAttachmentField({ attachment: undefined })
  }


  const isCustomBillingType = billingType === 'PERSONALIZADO'

  useEffect(() => {
    function run() {
      if (!isCustomerLoading && !isUsersLoading) {
        setIsLoadingPage(false)
      }
    }
    run()
  }, [isCustomerLoading, isUsersLoading])

  useEffect(() => {
    function run() {
      if (typeOfCustomer === 'PESSOA FÍSICA') {
        setValue('has_municipal_register', 'ISENTO')
        setValue('has_state_register', 'ISENTO')
      }
    }
    run()
  }, [typeOfCustomer, setValue])

  useEffect(() => {
    async function run() {
      if (slug === 'adicionar') {
        setValue('situation', 'ATIVO')
        const cepSplited = cepValue?.split('-').join('')
        if (
          !cepSplited?.includes('_') &&
          cepSplited !== undefined &&
          cepSplited !== '' &&
          slug === 'adicionar'
        ) {
          try {
            const cepResponse = await apiCep.get(`/${cepSplited}/json/`)
            const cityFilteredByCep = cepResponse.localidade

            const statesListByCep = await apiIbge.getStates('estados')
            const filteredState = statesListByCep
              .filter((state) => state.sigla === cepResponse.uf)
              .map((state) => state.nome)
              .toString()

            setValue('street', cepResponse.logradouro)
            setValue('neighborhood', cepResponse.bairro)
            setValue('city', cityFilteredByCep.toUpperCase())
            setValue('state', filteredState.toUpperCase())
            setValue('complement', cepResponse.complemento)
          } catch {
            toast.error('Erro ao consultar cep!')
          }
        }
      }
    }

    run()
  }, [setValue, cepValue, slug])

  useEffect(() => {
    async function run() {
      const statesList = await apiIbge.getStates('estados')
      setStatesList(statesList)

      const uf = statesList
        .filter((state) => state.nome.toUpperCase() === selectedState)
        .map((state) => state.sigla)
        .toString()

      if (selectedState && selectedCity) {
        const citiesList = await apiIbge.getCities(`/estados/${uf}/municipios`)
        setCitiesList(citiesList)
        setValue('city', selectedCity)
      } else {
        const citiesList = await apiIbge.getCities(`/estados/${uf}/municipios`)
        setCitiesList(citiesList)
      }
    }
    run()
  }, [selectedCity, selectedState, setValue])

  useEffect(() => {
    function run() {
      setValue('icms', 'ISENTO')
      setValue('iss', 'NÃO RETÉM')

      if (customer && statesList) {
        if (slug === 'editar' || slug === 'visualizar') {
          const time = formatDate.handle(customer.deadline_delivery, "DateOnlyWithHourMinute")

          setValue('type', customer.type)
          setValue('situation', customer.situation)
          setValue('trading_firstname', customer.trading_firstname)
          setValue(
            'attendance_responsible',
            customer.attendance_responsible !== null
              ? customer.attendance_responsible
              : '',
          )
          setValue('company_lastname', customer.company_lastname)
          setValue('cnpj_cpf', customer.cnpj_cpf)
          setValue('cost_center', customer.cost_center)
          setValue('deadline_delivery', time)
          setValue('operational_email', customer.operational_email)
          setValue('financial_email', customer.financial_email)
          setValue('cellphone', customer.cellphone)
          setValue('telephone', customer.telephone)
          setValue('cep', customer.cep)
          setValue('street', customer.street)
          setValue('number', customer.number)
          setValue('complement', customer.complement)
          setValue('neighborhood', customer.neighborhood)
          setValue('state', customer.state)
          setValue('city', customer.city)
          setValue(
            'has_municipal_register',
            customer.municipal_register && customer.municipal_register !== ' '
              ? 'CONTRIBUINTE'
              : 'ISENTO',
          )
          setValue(
            'municipal_register',
            customer.municipal_register && customer.municipal_register !== ' '
              ? customer.municipal_register
              : '',
          )
          setValue(
            'has_state_register',
            customer.state_register && customer.state_register !== ' '
              ? 'CONTRIBUINTE'
              : 'ISENTO',
          )
          setValue(
            'state_register',
            customer.state_register && customer.state_register !== ' '
              ? customer.state_register
              : '',
          )
          setValue('icms', customer.icms)
          setValue('iss', customer.iss)
          setValue('payment_conditional', customer.payment_conditional)
          setValue(
            'day_expiration_1',
            customer.day_expiration_1 && customer.day_expiration_1 !== ' '
              ? customer.day_expiration_1
              : '',
          )
          setValue(
            'day_expiration_2',
            customer.day_expiration_2 && customer.day_expiration_2 !== ' '
              ? customer.day_expiration_2
              : '',
          )
          setValue('payment_type', customer.payment_type)
          setValue('emission_type', customer.emission_type)
          setValue('billing_type', customer.billing_type)
          setValue('custom_billing_day', customer.custom_billing_day)
          setValue('lifebox_payment_term', customer.lifebox_payment_term)
          setValue(
            'has_waiting_time',
            customer.has_waiting_time === true ? 'SIM' : 'NÃO',
          )

          setValue('contacts', customer.contacts.map((contact) => {
            return {
              ...contact,
              canReceiveAutomaticallyMessages: contact.canReceiveAutomaticallyMessages ? 'yes' : 'no'
            }
          }))
          setValue('observation', customer.observation)
          setValue('billing_observation', customer.billing_observation)
          setValue('can_receive_service_extras_email', customer.can_receive_service_extras_email ? 'yes' : 'no')
          setValue('open_address_inclusion_communication', customer.open_address_inclusion_communication ? 'yes' : 'no')
          setValue('anual_adjustment_date', customer.anual_adjustment_date)
          setValue('has_maintenance', customer?.has_maintenance ? 'yes' : 'no')
          setValue('has_temp_check', customer?.has_maintenance ? 'yes' : 'no')
        }
      }
    }

    run()
  }, [setValue, slug, customer, statesList])


  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const {
    deleteCustomerAttachment: {
      mutateAsync: deleteCustomerAttachment
    }
  } = useCustomerFunctions()

  async function handleDeleteCustomerAttachment(
    customerId: string,
    filename: string
  ) {
    await promiseMessage(deleteCustomerAttachment({
      customerId,
      filename
    }), 'Anexo removido com sucesso!')

    await queryClient.invalidateQueries(['customer'])
  }

  if (isLoadingPage && slug !== 'adicionar') {
    return <GeneralContentLoading />
  }

  if (typeOfCustomer === '') {
    return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(submit)}
          noValidate
        >
          <Heading size="lg" fontFamily="poppins">
            {title}
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          <Select
            {...register('type')}
            name="type"
            label="Tipo de Cliente"
            error={errors.type}
            isDisabled={isDisabled}
            types={types}
            placeholder="Selecione o tipo de cliente"
            required
          />

          <Flex mt="8" justify="flex-end">
            <HStack>
              {slug === 'visualizar' ? (
                <Link to={href}>
                  <Button
                    type="button"
                    colorScheme="blue"
                    isLoading={isSubmitting}
                    loadingText="Carregando"
                  >
                    {action}
                  </Button>
                </Link>
              ) : (
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Carregando"
                >
                  {action}
                </Button>
              )}

              <Link to="/clientes">
                <Button type="button" colorScheme="gray">
                  Lista de Cidades
                </Button>
              </Link>
            </HStack>
          </Flex>
        </Box>
      </Flex>

    )
  } else {
    return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(submit)}
          noValidate
        >
          <Heading size="lg" fontFamily="poppins">
            {title}
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Select
              {...register('type')}
              name="type"
              label="Tipo de Cliente"
              error={errors.type}
              isDisabled={isDisabled}
              types={types}
              placeholder="Selecione o tipo de cliente"
              required
            />

            {slug === 'adicionar' ? (
              <Input
                {...register('situation')}
                name="situation"
                label="Situação"
                isDisabled
                required
              />
            ) : (
              <Select
                {...register('situation')}
                name="situation"
                label="Situação"
                error={errors.situation}
                isDisabled={isDisabled}
                situations={situation_customer}
                placeholder="Selecione a situação"
                required
              />
            )}
          </Stack>

          <Stack mt="4">
            <Select
              {...register('attendance_responsible')}
              name="attendance_responsible"
              label="Responsável pelo Atendimento"
              error={errors.attendance_responsible}
              placeholder="Selecione uma opção..."
              users={users?.filter((user) =>
                user.user_type.includes('COMERCIAL'),
              )}
              isDisabled={slug === 'visualizar'}
              required
            />
          </Stack>

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('trading_firstname')}
              isDisabled={isDisabled}
              name="trading_firstname"
              label={
                typeOfCustomer === 'PESSOA FÍSICA' ? 'Nome' : 'Nome Fantasia'
              }
              error={errors.trading_firstname}
              required
            />

            <Input
              {...register('company_lastname')}
              isDisabled={isDisabled}
              name="company_lastname"
              label={
                typeOfCustomer === 'PESSOA FÍSICA'
                  ? 'Sobrenome'
                  : 'Razão Social'
              }
              error={errors.company_lastname}
              required
            />
          </Stack>

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Controller
              control={control}
              name="cnpj_cpf"
              render={({ field: { onChange, value } }) => (
                <InputMaskCustom
                  {...register('cnpj_cpf')}
                  isDisabled={isDisabled}
                  name="cnpj_cpf"
                  label={typeOfCustomer === 'PESSOA FÍSICA' ? 'CPF' : 'CNPJ'}
                  mask={
                    typeOfCustomer === 'PESSOA FÍSICA'
                      ? handleSwitchMask('cpf')
                      : handleSwitchMask('cnpj')
                  }
                  defaultValue={value}
                  onChange={onChange}
                  error={errors.cnpj_cpf}
                  required
                />
              )}
            />

            <Stack
              w="full"
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              <Input
                {...register('deadline_delivery')}
                isDisabled={isDisabled}
                name="deadline_delivery"
                type="time"
                label="Horário Limite de Entrega"
                error={errors.deadline_delivery}
                required
              />

              <Input
                {...register('cost_center')}
                isDisabled={isDisabled}
                name="cost_center"
                label="Centro de Custo"
                error={errors.cost_center}
                required
              />
            </Stack>
          </Stack>

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('operational_email')}
              name="operational_email"
              label="Email Operacional"
              placeholder="email@exemplo.com"
              error={errors.operational_email}
              isDisabled={isDisabled}
              required
            />
            <Input
              {...register('financial_email')}
              name="financial_email"
              label="Email Financeiro"
              placeholder="email@exemplo.com"
              error={errors.financial_email}
              isDisabled={isDisabled}
              required
            />
          </Stack>

          <HStack spacing="24px" mt="4">
            <Controller
              control={control}
              name="cellphone"
              render={({ field: { onChange, value } }) => (
                <InputMaskCustom
                  {...register('cellphone')}
                  mask={handleSwitchMask('cellphone')}
                  onChange={onChange}
                  defaultValue={value}
                  name="cellphone"
                  label="Celular"
                  error={errors.cellphone}
                  isDisabled={isDisabled}
                  required
                />
              )}
            />

            <Controller
              control={control}
              name="telephone"
              render={({ field: { onChange, value } }) => (
                <InputMaskCustom
                  {...register('telephone')}
                  name="telephone"
                  defaultValue={value}
                  onChange={onChange}
                  mask={handleSwitchMask('phone')}
                  label="Telefone"
                  error={errors.telephone}
                  isDisabled={isDisabled}
                  required
                />
              )}
            />
          </HStack>

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Controller
              control={control}
              name="cep"
              render={({ field: { onChange, value } }) => (
                <InputMaskCustom
                  {...register('cep')}
                  name="cep"
                  label="CEP"
                  error={errors.cep}
                  isDisabled={isDisabled}
                  defaultValue={value}
                  onChange={onChange}
                  mask={handleSwitchMask('cep')}
                  required
                />
              )}
            />
            <Stack w="full" direction={['column', 'column', 'row']}>
              <Input
                {...register('street')}
                name="street"
                label="Rua"
                error={errors.street}
                isDisabled={isDisabled}
                required
              />

              <Input
                {...register('number')}
                name="number"
                label="Número"
                error={errors.number}
                isDisabled={isDisabled}
                required
              />
            </Stack>
          </Stack>

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('complement')}
              name="complement"
              label="Complemento"
              error={errors.complement}
              isDisabled={isDisabled}
            />
            <Input
              {...register('neighborhood')}
              name="neighborhood"
              label="Bairro"
              error={errors.neighborhood}
              isDisabled={isDisabled}
              required
            />

            <Select
              {...register('state')}
              name="state"
              label="Estado"
              error={errors.state}
              states={statesList}
              placeholder="Selecione um estado"
              isDisabled={isDisabled}
              required
            />
            <Select
              {...register('city')}
              name="city"
              label="Cidade"
              error={errors.city}
              citiesIBGE={citiesList}
              placeholder="Selecione uma Cidade"
              isDisabled={isDisabled}
              required
            />
          </Stack>

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Select
              {...register('has_waiting_time')}
              negative_positive={negative_positive}
              placeholder="Selecione uma opção..."
              name="has_waiting_time"
              label="Possui Cobrança de Tempo de Espera?"
              error={errors.has_waiting_time}
              isDisabled={isDisabled}
              required
            />
            <Controller
              control={control}
              name="anual_adjustment_date"
              render={({ field }) => {
                const value = field.value?.replace(/(\d{2})(\d{1,2})/, '$1/$2')

                return (
                  <Input
                    name="anual_adjustment_date"
                    label="Data de reajuste anual"
                    placeholder='dd/MM'
                    value={value ?? ''}
                    onChange={field.onChange}
                    maxLength={5}
                    required
                    error={errors.anual_adjustment_date}
                    isDisabled={isDisabled}
                  />
                )
              }}
            />
          </Stack>

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <HStack w="100%">
              <Select
                {...register('has_municipal_register')}
                contribution_types={contribution_types}
                placeholder="Selecione uma opção..."
                name="has_municipal_register"
                label="Registro Municipal"
                error={errors.has_municipal_register}
                isDisabled={isDisabled}
                required
              />
              {hasMunicipalRegister === 'CONTRIBUINTE' && (
                <Input
                  {...register('municipal_register')}
                  name="municipal_register"
                  label="Código do registro"
                  error={errors.municipal_register}
                  isDisabled={isDisabled}
                  required
                />
              )}
            </HStack>
            <HStack w="100%">
              <Select
                {...register('has_state_register')}
                contribution_types={contribution_types}
                placeholder="Selecione uma opção..."
                name="has_state_register"
                label="Registro Estadual"
                error={errors.has_municipal_register}
                isDisabled={isDisabled}
                required
              />
              {hasStateRegister === 'CONTRIBUINTE' && (
                <Input
                  {...register('state_register')}
                  name="state_register"
                  label="Código do registro"
                  error={errors.state_register}
                  isDisabled={isDisabled}
                  required
                />
              )}
            </HStack>
          </Stack>

          <HStack spacing="24px" mt="4">
            <Select
              {...register('icms')}
              name="icms"
              label="ICMS"
              contribution_types={contribution_types}
              error={errors.icms}
              placeholder="Selecione uma opção..."
              isDisabled={isDisabled}
              required
            />
            <Select
              {...register('iss')}
              name="iss"
              label="ISS"
              placeholder="Selecione uma opção..."
              iss_options={iss_options}
              error={errors.iss}
              isDisabled={isDisabled}
              required
            />
          </HStack>

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Select
              {...register('payment_conditional')}
              name="payment_conditional"
              label="Condição de pagamento"
              error={errors.payment_conditional}
              payment_conditions={payment_conditions}
              placeholder="Selecione uma opção..."
              isDisabled={isDisabled}
              required
            />
            {paymentCondition === 'FATURADO MENSAL' ? (
              <Input
                {...register('day_expiration_1')}
                name="day_expiration_1"
                label="Dia de vencimento"
                error={errors.day_expiration_1}
                isDisabled={isDisabled}
                required
              />
            ) : paymentCondition === 'FATURADO QUINZENAL' ? (
              <HStack w="100%">
                <Input
                  {...register('day_expiration_1')}
                  name="day_expiration_1"
                  label="1° dia de vencimento"
                  error={errors.day_expiration_1}
                  isDisabled={isDisabled}
                  required
                />
                <Input
                  {...register('day_expiration_2')}
                  name="day_expiration_2"
                  label="2° dia de vencimento"
                  error={errors.day_expiration_2}
                  isDisabled={isDisabled}
                  required
                />
              </HStack>
            ) : null}
          </Stack>

          <HStack spacing="24px" mt="4">
            <Select
              {...register('payment_type')}
              name="payment_type"
              payment_types={payment_types}
              label="Tipo de pagamento"
              placeholder="Selecione uma opção..."
              error={errors.payment_type}
              isDisabled={isDisabled}
              required
            />

            <Select
              {...register('emission_type')}
              name="emission_type"
              label="Tipo de Emissão"
              emission_types={emission_types}
              placeholder="Selecione uma opção..."
              error={errors.emission_type}
              isDisabled={isDisabled}
              required
            />
          </HStack>

          <Stack
            direction={['column', 'column', 'row']}
            spacing={4}
            w="full"
            mt="4"
          >
            <Stack
              direction={['column', 'column', 'row']}
              spacing={4}
              w='full'
            >
              <Select
                {...register('billing_type')}
                name="billing_type"
                options={billing_types}
                label="Tipo de Faturamento"
                placeholder="Selecione uma opção..."
                error={errors.billing_type}
                isDisabled={isDisabled}
                required
              />
              {isCustomBillingType &&
                <Input
                  {...register('custom_billing_day')}
                  name="custom_billing_day"
                  label="Dia do mês"
                  error={errors.custom_billing_day}
                  isDisabled={isDisabled}
                  required
                />}
            </Stack>
            <Select
              {...register('lifebox_payment_term')}
              name="lifebox_payment_term"
              label="Prazo de Pagamento LifeBox"
              options={lifebox_payment_terms}
              placeholder="Selecione uma opção..."
              error={errors.lifebox_payment_term}
              isDisabled={isDisabled}
              required
            />
          </Stack>
          <Divider my="6" borderColor="gray.700" />
          <Stack w="full"
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}>
            <Text>Contatos</Text>
          </Stack>
          <Flex direction="column">
            {contactCustomerFields.map((field, index) => {
              return (
                <Flex direction='column' key={field.id}>
                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      {...register(`contacts.${index}.name`)}
                      name={`contacts.${index}.name`}
                      label="Nome"
                      error={
                        errors.contacts
                          ? errors?.contacts[index]?.name
                          : undefined
                      }
                      isDisabled={isDisabled}
                      required
                    />

                    <Select
                      {...register(`contacts.${index}.canReceiveAutomaticallyMessages`)}
                      name={`contacts.${index}.canReceiveAutomaticallyMessages`}
                      label="Recebe mensagems automáticas (Whatsapp)?"
                      error={errors.type}
                      isDisabled={isDisabled}
                      options={positiveNegativeOptions}
                      placeholder="Selecione uma opção..."
                      required
                    />



                  </Stack>
                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      {...register(`contacts.${index}.email`)}
                      name={`contacts.${index}.email`}
                      label="Email"
                      placeholder="email@exemplo.com"
                      error={
                        errors.contacts
                          ? errors?.contacts[index]?.email
                          : undefined
                      }
                      isDisabled={isDisabled}
                      required
                    />

                    <Controller
                      control={control}
                      name={`contacts.${index}.phone`}
                      render={({ field: { onChange, value } }) => (
                        <InputMaskCustom
                          {...register(`contacts.${index}.phone`)}
                          name={`contacts.${index}.phone`}
                          defaultValue={value}
                          onChange={onChange}
                          mask={
                            value?.length > 13
                              ? handleSwitchMask('cellphone')
                              : handleSwitchMask('mixedPhone')
                          }
                          maskChar=""
                          alwaysShowMask={false}
                          label="Telefone"
                          error={
                            errors.contacts
                              ? errors?.contacts[index]?.phone
                              : undefined
                          }
                          isDisabled={isDisabled}

                        />
                      )}
                    />
                  </Stack>
                  <Stack
                    w="full"
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <TextArea
                      {...register(`contacts.${index}.description`)}
                      name={`contacts.${index}.description`}
                      label='Descrição'
                      isDisabled={isDisabled}
                      error={
                        errors.contacts
                          ? errors?.contacts[index]?.description
                          : undefined
                      }
                    />
                  </Stack>
                  <Stack
                    w="full"
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Controller
                      name={`contacts.${index}.type`}
                      control={control}
                      render={({ field: { onChange } }) => {
                        return (
                          <GeneralCheckbox
                            onCheckboxChange={onChange}
                            name={`contacts.${index}.type`}
                            checkboxOptions={contactTypeCheckboxOptions}
                            defaultCheckedOptions={field.type}
                            label='Tipo de contato'
                            isDisabled={isDisabled}
                            error={
                              errors.contacts
                                ? errors?.contacts[index]?.type as FieldError
                                : undefined
                            }
                            required
                          />
                        )
                      }}
                    />
                  </Stack>
                  <Stack pt={8}>
                    <Button
                      leftIcon={<Icon as={FaTimes} />}
                      variant="ghost"
                      onClick={() => handleRemoveContactCustomerField(index)}
                      h="48px"
                      isDisabled={isDisabled}
                    >
                      Remover
                    </Button>
                  </Stack>
                </Flex>
              )
            })}
            <Button
              leftIcon={<Icon as={FaPlus} />}
              alignSelf="flex-start"
              onClick={handleAppendContactCustomerField}
              mt={2}
              isDisabled={isDisabled}
            >
              Adicionar
            </Button>
            {errors?.contacts && (
              <Text mt='4' as='sup' color='red.400'>{errors.contacts.message}</Text>
            )}
          </Flex>

          <Stack mt="4" direction={["column", "column", "row"]}>
            <Radio
              {...register("can_receive_service_extras_email")}
              name="can_receive_service_extras_email"
              radioOptions={canReceiveServiceExtrasEmailRadioOptions}
              defaultCheckedOption={customer?.can_receive_service_extras_email ? 'yes' : 'no'}
              label="Receber email de extras?"
              error={errors.can_receive_service_extras_email}
              required
            />
            <Radio
              {...register("open_address_inclusion_communication")}
              name="open_address_inclusion_communication"
              radioOptions={canReceiveServiceExtrasEmailRadioOptions}
              defaultCheckedOption={customer?.open_address_inclusion_communication ? 'yes' : 'no'}
              label="Abrir comunicação de inclusão de endereços?"
              error={errors.open_address_inclusion_communication}
              required
            />
          </Stack>

          <Stack mt="4" direction={["column", "column", "row"]}>
            <Radio
              {...register("has_maintenance")}
              name="has_maintenance"
              radioOptions={canReceiveServiceExtrasEmailRadioOptions}
              defaultCheckedOption={customer?.has_maintenance ? 'yes' : 'no'}
              label="Realiza manutenção?"
              error={errors.has_maintenance}
              required
            />
            <Radio
              {...register("has_temp_check")}
              name="has_temp_check"
              radioOptions={canReceiveServiceExtrasEmailRadioOptions}
              defaultCheckedOption={customer?.has_temp_check ? 'yes' : 'no'}
              label="Aferir temperatura na coleta?"
              error={errors.has_temp_check}
              required
              isDisabled={isDisabled}
            />
          </Stack>

          {customer?.attachments && customer?.attachments.length > 0 && (
            <TableContainer>
              <Table size='sm' mt={4}>
                <Thead>
                  <Tr>
                    <Th>Anexos Cadastrados</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {customer?.attachments.map((attachment) => {
                    return (
                      <Tr key={attachment}>
                        <Th>{attachment} </Th>
                        <Th isNumeric>
                          <Button
                            as={ChakraLink}
                            href={handleChangeUrl(attachment)}
                            variant='ghost'
                            isExternal
                          >
                            <Icon as={FaEye} />
                          </Button>
                          {slug === 'editar' && (
                            <Button
                              variant='ghost'
                              onClick={() => {
                                handleDeleteCustomerAttachment(
                                  customer.id,
                                  attachment
                                )
                              }}
                            >
                              <Icon as={FaTrash} />
                            </Button>
                          )}
                        </Th>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          {attachmentsFields.map((field, index) => {
            return (
              <Stack key={field.id} w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
                <Input
                  {...register(`attachments.${index}.attachment`)}
                  name={`attachments.${index}.attachment`}
                  label="Anexo"
                  type="file"
                  accept=".pdf"
                  isDisabled={slug === 'visualizar'}
                  error={errors.attachments ? errors?.attachments[index]?.attachment : undefined}
                  hidden
                >
                  <Stack
                    w="full"
                    spacing="24px"
                    mt="4"
                    justifyContent='space-between'
                    direction={['column', 'column', 'row']}
                  >
                    <Button
                      isDisabled={slug === 'visualizar'}
                      as={FormLabel}
                      htmlFor={`attachments.${index}.attachment`}
                      _hover={{
                        cursor: 'pointer'
                      }}
                      mr="2"
                      leftIcon={<Icon as={FaFilePdf} />}
                    >
                      Upload
                    </Button>
                    {attachments && attachments[index]?.attachment?.length > 0 && (
                      <ChakraLink
                        href={URL.createObjectURL(attachments[index].attachment[0])}
                      >
                        {attachments[index].attachment[0].name} (Visualizar)
                      </ChakraLink>
                    )}
                    <Button
                      leftIcon={<Icon as={FaTimes} />}
                      variant="ghost"
                      onClick={() => handleRemoveAttachmentField(index)}
                      h="48px"
                    >
                      Remover
                    </Button>
                  </Stack>
                </Input>
              </Stack>
            )
          })}

          <Stack
            w="full"
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Button
              isDisabled={slug === 'visualizar'}
              _hover={{
                cursor: 'pointer'
              }}
              mr="2"
              leftIcon={<Icon as={FaFilePdf} />}
              onClick={handleAppendAttachmentField}
            >
              Adicionar anexo
            </Button>
          </Stack>

          <HStack spacing="24px" mt="4">
            <TextArea
              {...register('observation')}
              name="observation"
              label="Observações"
              isDisabled={isDisabled}
            />
          </HStack>

          <HStack spacing="24px" mt="4">
            <TextArea
              {...register('billing_observation')}
              name="billing_observation"
              label="Observações de faturamento"
              isDisabled={isDisabled}
            />
          </HStack>

          <Flex mt="8" justify="flex-end">
            <HStack>
              {slug === 'visualizar' ? (
                <Link to={href}>
                  <Button
                    type="button"
                    colorScheme="blue"
                    isLoading={isSubmitting}
                    loadingText="Carregando"
                  >
                    {action}
                  </Button>
                </Link>
              ) : (
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Carregando"
                >
                  {action}
                </Button>
              )}

              <Link to="/clientes">
                <Button type="button" colorScheme="gray">
                  Lista de Clientes
                </Button>
              </Link>
            </HStack>
          </Flex>
        </Box>
      </Flex>

    )
  }
}
