import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Socket } from "socket.io-client";
import { socketApi } from "../services/socket/socket";

interface ProfileSocketContextProps {
  profileSocketConnection: Socket | null
}

interface ProfileSocketProviderProps {
  children: ReactNode
}

interface ProfileUpdatePermissionNotificationProps {
  user_type: string
}

export const ProfileSocketContext = createContext({} as ProfileSocketContextProps)

export function ProfileSocketProvider({ children }: ProfileSocketProviderProps) {
  const [profileSocketConnection, setProfileSocketConnection] = useState<Socket | null>(null)

  const { infoMessage } = useToastify()
  const { userLogged } = useAuth()

  const queryClient = useQueryClient()

  useEffect(() => {
    setProfileSocketConnection(socketApi.connect("profile"))
  }, [])

  useEffect(() => {
    profileSocketConnection?.on("getProfileUpdatePermissionNotification", async (data: ProfileUpdatePermissionNotificationProps) => {
      const isUserLoggedSameUpdatedProfile = userLogged?.user_type === data.user_type

      if (isUserLoggedSameUpdatedProfile) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications']})

        infoMessage("Suas permissões foram atualizadas! Realize o login novamente para que o(s) novo(s) acesso(s) seja(m) liberado(s)")
      }
    })

    return () => {
      profileSocketConnection?.off("getProfileUpdatePermissionNotification")
    }
  }, [profileSocketConnection, queryClient, infoMessage, userLogged])

  return (
    <ProfileSocketContext.Provider value={{
      profileSocketConnection,
    }}>
      {children}
    </ProfileSocketContext.Provider>
  )
}
