import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getDocuments } from "api/documents/getDocuments";
import { DocumentStatus } from "api/documents/types/Document";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useState } from "react";
import { FaExchangeAlt, FaFileDownload, FaFileExport } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { documentStatusMap } from "utils/documentMappers";
import { CreateDocumentRequest } from "./components/CreateDocumentRequest";
import { DocumentsTableRow } from "./components/DocumentsTableRow";
import { DocumentTableFilters } from "./components/DocumentTableFilters";

const headers = [
  { label: 'NOME', key: 'name' },
  { label: 'TIPO DE DOCUMENTO', key: 'document_type' },
  { label: 'CLASSIFICAÇÃO', key: 'classification' },
  { label: 'STATUS', key: 'status' },
]

export function Documents() {
  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)
  const searchParams = useSearchParams()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanCreateDocumentRequest = userLogged?.permissions?.includes('create-document-request')

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status')
  const name = searchParams.get('name')


  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }

  const {
    data: documentsResult
  } = useQuery({
    queryKey: ['documents', page, status, name],
    queryFn: () => getDocuments({
      currentPage: page,
      pageSize: '10',
      status: status as DocumentStatus | 'all',
      name
    })
  })

  const {
    data: documentsReportResult,
    isLoading: isLoadingDocumentsReportResult
  } = useQuery({
    queryKey: ['documents', status, name],
    queryFn: () => getDocuments({
      currentPage: '1',
      pageSize: documentsResult?.meta?.count.toString(),
      status: status as DocumentStatus | 'all',
      name
    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: documentsResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: documentsResult?.meta?.pageSize
    }
  })

  const csvReportProps = {
    data: documentsReportResult ? documentsReportResult?.documents.map(
      (document) => {
        return {
          ...document,
          status: documentStatusMap[document.status ]
        }
      }
    ) : [],
    headers,
    filename: `documentos.csv`,
  }

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreateDocumentRequestModalOpen,
    onOpen: onOpenCreateDocumentRequestModal,
    onClose: onCloseCreateDocumentRequestModal
  } = useDisclosure()

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >

      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Documentos</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar kanban"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => history.push('/documentos/kanban')}
          />
          {userCanCreateDocumentRequest && (
            <IconButton
              aria-label="Criar documento"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateDocumentRequestModal}
            />
          )}
        </Flex>


        <Modal
          isOpen={isCreateDocumentRequestModalOpen}
          onClose={onCloseCreateDocumentRequestModal}
          isCentered
        >
          <ModalOverlay />
          <CreateDocumentRequest
            onCloseModal={onCloseCreateDocumentRequestModal}
          />
        </Modal>
      </Flex>

      <DocumentTableFilters />

      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Nome</Th>
              <Th color="white">Tipo</Th>
              <Th color="white">Classificação</Th>
              <Th color="white">Status</Th>
              <Th></Th>

            </Tr>
          </Thead>
          <Tbody>
            {documentsResult?.documents?.map((document) => {
              return (
                <DocumentsTableRow
                  key={document.id}
                  document={document}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>

      {!documentsReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingDocumentsReportResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}

      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
