import { Td, Tr, IconButton, Link, Progress } from "@chakra-ui/react";
import { FaExternalLinkAlt } from "react-icons/fa";

interface AttachmentRowProps {
  attachment: {
    id: string;
    link: string;
    progresss: number;
    title: string;
  };
}

export function AttachmentRow({ attachment }: AttachmentRowProps) {
  return (
    <Tr>
      {attachment && (
        <>
          <Td fontSize="xs" maxW="100px">{attachment?.title}</Td>
          <>
            <Td fontSize="xs" w="200px">
              <Progress size="sm" rounded="md" value={attachment?.progresss || 100} />
            </Td>
            <Td fontSize="xs" isNumeric>
              <IconButton
                aria-label="Visualizar anexo"
                as={Link}
                size="sm"
                icon={<FaExternalLinkAlt />}
                href={attachment.link || ''}
                isExternal
              />
            </Td>
          </>
        </>
      )}

    </Tr>
  );
}

