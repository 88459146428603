import { FormControl, FormLabel, Input, InputGroup, InputLeftAddon, Text } from "@chakra-ui/react";
import { useCurrency } from "react-hook-currency";
import { useFormContext } from "react-hook-form";

interface PatrimonyWithoutReturnCltIntercurrenceFormSchema {
  discountTotalValue: string
}

export function PatrimonyWithoutReturnCltIntercurrenceForm() {

  const {
    register,
    formState: {
      errors,
    }
  } = useFormContext<PatrimonyWithoutReturnCltIntercurrenceFormSchema>()

  const { format: currencyFormat, onChange: onCurrencyChange } = useCurrency({
    style: 'decimal'
  })

  return (
    <>
      <FormControl isInvalid={!!errors.discountTotalValue}>
        <FormLabel>
          Valor total do desconto
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <InputGroup>
          <InputLeftAddon>R$</InputLeftAddon>
          <Input
            {...register('discountTotalValue')}
            onChange={onCurrencyChange}
            defaultValue={currencyFormat('000')}
          />
        </InputGroup>
      </FormControl>
    </>
  )
}
