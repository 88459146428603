import { Button, Flex, Icon, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { TextArea } from "components/Inputs/TextInput";
import { useBoardServiceFunctions } from "hooks/services/board/useBoardServiceFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { FiExternalLink } from "react-icons/fi";
import { handleChangeUrl } from "utils/handleChangeUrl";

interface UnsuccessBoardInfo {
  id: string
  unsuccessReason: string
  unsuccessPhoto: string
  unsuccessObservation: string | null
}

interface ValidateUnsuccessBoardModalProps {
  isOpen: boolean;
  onClose: () => void
  unsuccessBoardInfo: UnsuccessBoardInfo
}

export function ValidateUnsuccessBoardModal({
  isOpen,
  onClose,
  unsuccessBoardInfo
}: ValidateUnsuccessBoardModalProps) {
  const {
    validateUnsuccessBoardService: { mutateAsync: validateUnsuccessBoardService }
  } = useBoardServiceFunctions()

  const { promiseMessage } = useToastify()

  async function handleValidateUnsuccessBoard() {
    await promiseMessage(validateUnsuccessBoardService({
      id: unsuccessBoardInfo.id,
      is_validated: 'yes'
    }, {
      onSuccess: onClose
    }), 'Embarque sem sucesso validado!')
  }
  async function handleInvalidateUnsuccessBoard() {
    await promiseMessage(validateUnsuccessBoardService({
      id: unsuccessBoardInfo.id,
      is_validated: 'no'
    }, {
      onSuccess: onClose
    }), 'Embarque sem sucesso invalidado!')
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Validar embarque sem sucesso</ModalHeader>
        <ModalCloseButton />

        <ModalBody as={Flex} flexDirection='column' gap={4} p={4}>
          <Input
            name='unsuccess_reason'
            defaultValue={unsuccessBoardInfo.unsuccessReason}
            label='Motivo'
            isDisabled
          />

          <Button
            as={Link}
            variant='ghost'
            href={handleChangeUrl(
              unsuccessBoardInfo.unsuccessPhoto,
            )}
            leftIcon={<Icon as={FiExternalLink} />}
            isExternal
          >
            Recusa do embarque (foto)
          </Button>

          <TextArea
            name='unsuccess_observation'
            defaultValue={unsuccessBoardInfo.unsuccessObservation ?? 'Sem observações'}
            isDisabled
          />
        </ModalBody>

        <ModalFooter as={Flex} w='full' gap={4}>
          <Button
            w='full'
            onClick={handleInvalidateUnsuccessBoard}
          >
            Invalidar
          </Button>
          <Button
            w='full'
            colorScheme='blue'
            onClick={handleValidateUnsuccessBoard}
          >
            Validar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
