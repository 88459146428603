import { Button, Stack } from '@chakra-ui/react'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { StandardBackgroundForm } from '../../../components/Form/StandardBackgroundForm'
import { Input } from '../../../components/Inputs/Input'
import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { SocketContext } from '../../../contexts/SocketContext'
import { useContentLoading } from '../../../hooks/loading/useContentLoading'
import { useRequestServiceFunctions } from '../../../hooks/services/request/useRequestServiceFunctions'
import { useService } from '../../../hooks/services/service'
import { useSwal } from '../../../hooks/swal/useSwal'
import { useAddresses } from '../../../hooks/address/useAddresses'

interface IQueryParams {
  service_id: string
}

export function ValidateAddressToCollect() {
  const [isLoading, setIsLoading] = useState(false)

  const { goBack } = useHistory()

  const { packetSocketConnection } = useContext(SocketContext)
  const { service_id }: IQueryParams = useParams()
  const { confirmMessage, standardMessage } = useSwal()
  const { isContentLoading, handleSetIsContentLoadingFalse } =
    useContentLoading()
  const {
    service: { data: service, isLoading: isServiceLoading },
  } = useService(service_id, false)
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()
  const {
    validateAddressToCollectService: {
      mutateAsync: validateAddressToCollectService,
      isSuccess: isValidateSuccess,
      isError: isValidateError,
    },
    invalidateAddressToCollectService: {
      mutateAsync: invalidateAddressToCollectService,
      isSuccess: isInvalidateSuccess,
      isError: isInvalidateError,
    },
  } = useRequestServiceFunctions()

  const addressesToValidate = useMemo(() => {
    return addresses?.filter(address => {
      return service?.serviceIDRequested.additional_collect_addresses?.includes(
        address.id
      )
    })
  }, [addresses, service])

  useEffect(() => {
    if (service && addresses) {
      if (addressesToValidate.length === 0) {
        goBack()
      }
    }
  }, [addressesToValidate, goBack, service, addresses])

  async function handleValidateAddressToCollect(addressId: string) {
    const hasValidateAddressToCollect = await confirmMessage({
      title: 'Deseja validar um endereço para coleta?',
    })

    if (hasValidateAddressToCollect) {
      setIsLoading(true)
      const validateAddressInfoObj = {
        address_id: addressId,
        has_validate: true,
      }
      const validateCollectAddressReqObj = {
        service_id,
        validateAddressToCollectInput: validateAddressInfoObj,
      }

      packetSocketConnection.emit("sendNotification", {
        service_id,
        address_id: addressId
      })

      await validateAddressToCollectService(validateCollectAddressReqObj)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  useEffect(() => {
    if (!isServiceLoading && !isAddressesLoading) {
      handleSetIsContentLoadingFalse()
    }
  }, [isServiceLoading, isAddressesLoading, handleSetIsContentLoadingFalse])

  useEffect(() => {
    if (
      isInvalidateSuccess ||
      isInvalidateError ||
      isValidateSuccess ||
      isValidateError
    ) {
      setIsLoading(false)
    }
  }, [
    isInvalidateSuccess,
    isInvalidateError,
    isValidateSuccess,
    isValidateError,
  ])

  async function handleInvalidateAddressToCollect(addressId: string) {
    const hasInvalidateAddressToCollect = await confirmMessage({
      title: 'Deseja invalidar um endereço para coleta?',
    })

    if (hasInvalidateAddressToCollect) {
      setIsLoading(true)
      const invalidateAddressInfoObj = {
        address_id: addressId,
        has_validate: false,
      }
      const invalidateCollectAddressReqObj = {
        service_id,
        validateAddressToCollectInput: invalidateAddressInfoObj,
      }
      await invalidateAddressToCollectService(invalidateCollectAddressReqObj)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (isLoading || isContentLoading) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm
      title="Validar Endereços para Coleta"
      onSubmit={async () => { }}
    >
      {addressesToValidate === null ? (
        <h1>Sem endereços para validar</h1>
      ) : (
        addressesToValidate.map((address) => (
          <Stack
            key={address.id}
            mt="4"
            w="full"
            direction="column"
            spacing="24px"
          >
            <Input
              name="address_id"
              label="Endereço"
              defaultValue={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep} | ${address.responsible_name}`.toUpperCase()}
              isDisabled={true}
            />

            <Stack direction="row" spacing="24px">
              <Button
                w="full"
                onClick={() => handleValidateAddressToCollect(address.id)}
                colorScheme="blue"
              >
                Validar
              </Button>
              <Button
                w="full"
                onClick={() => handleInvalidateAddressToCollect(address.id)}
              >
                Invalidar
              </Button>
            </Stack>
          </Stack>
        ))
      )}
    </StandardBackgroundForm>
  )
}
