import { Button, Flex, Input } from "@chakra-ui/react";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface CltDriversTableFiltersSchema {
  search?: string
}

export function CltDriversTableFilter() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const search = searchParams.get('search')

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<CltDriversTableFiltersSchema>({
    defaultValues: {
      search
    }
  })

  async function handleFilterCltDrivers(data: CltDriversTableFiltersSchema) {

    if (data.search) {
      searchParams.set('search', data.search)
    } else {
      searchParams.delete('search')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    reset()
    searchParams.delete('search')

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterCltDrivers)}
    >
      <Input
        {...register('search')}
        size="sm"
        rounded="md"
        w={{ base: 'full', lg: '200px' }}
      />

      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={{ base: 'full', lg: 'min' }}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleRemoveFilters}
        w={{ base: 'full', lg: 'min' }}
      >
        Remover filtros
      </Button>

    </Flex>
  )
}
