import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Flex, Stack } from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { useAuth } from "hooks/auth/useAuth";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { formatDate } from "utils/DateFunctions/formatDate";
import { useUsers } from "hooks/user/useUsers";
import { useUsageReport } from "hooks/report/useUsageReport";
import { add } from "date-fns";

interface UsageReportInputs {
  search_type: 'general' | 'user'
  user_id: string
  start_filter: string
  end_filter: string
}

const usageReportHeaders = [
  { key: 'service_protocol', label: 'PROTOCOLO' },
  { key: 'username', label: 'USUÁRIO' },
  { key: 'action', label: 'AÇÃO' },
  { key: 'created_at', label: 'DATA REALIZAÇÃO' },
  { key: 'action', label: 'AÇÃO' }
]

const searchTypeOptions = [
  { key: '0', value: 'general', showOption: 'GERAL' },
  { key: '1', value: 'user', showOption: 'USUÁRIO' },
]

const schema = yup.object().shape({
  search_type: yup.string().required('Campo obrigatório!'),
  user_id: yup.string().when('search_type', {
    is: 'user',
    then: yup.string().required('Campo obrigatório!')
  }),
  start_filter: yup.string().required('Campo obrigatório!'),
  end_filter: yup.string().required('Campo obrigatório!')
})

export function UsageReport() {
  const [
    enableUsageReportRequest,
    setEnableUsageReportRequest
  ] = useState(false)

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<UsageReportInputs>({
    resolver: yupResolver(schema),
    shouldUnregister: true
  })

  const [
    selectedSearchType,
    selectedUser,
    startFilter,
    endFilter
  ] = useWatch({
    control,
    name: ['search_type', 'user_id', 'start_filter', 'end_filter']
  })

  const isValidStartDate = new Date(startFilter) instanceof Date &&
    !isNaN(new Date(startFilter).getMilliseconds())

  const maxEndFilter = isValidStartDate
    ? formatDate.handle(add(new Date(startFilter), {
      days: 31
    }), 'DateWithoutHourToInput')
    : ''

  const { data: users, isFetching: isFetchingUsers } = useUsers({
    queryParms: { situation: 'ATIVO' },
    queryOptions: { enabled: selectedSearchType === 'user' }
  })

  const userSelectOptions = users?.map(user => {
    return {
      key: user.id,
      value: user.id,
      showOption: `${user.firstname} ${user.lastname}`
    }
  })

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-usage-report')) {
      redirect('/')
    }
  }, [userLogged, redirect])

  const selectedCustomerInfo = users?.find(user => {
    return user.id === selectedUser
  })

  const {
    data: usageReportData,
    isFetching: isUsageReportDataFetching,

  } = useUsageReport({
    queryOptions: {
      enabled: enableUsageReportRequest,
      select: (usageReportData) => {
        return usageReportData.map(data => {
          return {
            ...data,
            created_at: formatDate.handle(data.created_at, 'DateWithDateAndHourMinute')
          }
        })
      }
    },
    queryParams: {
      user_id: selectedUser,
      start_filter: startFilter,
      end_filter: endFilter
    }
  })

  const handleGenerateReport = () => {
    setEnableUsageReportRequest(true)
    setTimeout(() => {
      setEnableUsageReportRequest(false)
    }, 500)
  }

  const csvReportFilename = selectedSearchType === 'user'
    ? `relatorio-utilizacao-${selectedCustomerInfo?.firstname
    }-${selectedCustomerInfo?.lastname}-${startFilter}-${endFilter}`
    : `relatorio-utilizacao-geral-${startFilter}-${endFilter}`

  const csvReportProps = {
    data: usageReportData ?? [],
    headers: usageReportHeaders,
    filename: csvReportFilename,
  }

  return (
    <StandardBackgroundForm
      title='Relatório de utilização'
      onSubmit={handleSubmit(handleGenerateReport)}
    >
      <Flex
        gap={4}
        direction='column'
      >

        <Select
          {...register('search_type')}
          name='search_type'
          label='Tipo de busca'
          error={errors.search_type}
          placeholder='Selecione uma opção...'
          options={searchTypeOptions}
          required
        />

        {selectedSearchType === 'user' && (
          <Select
            {...register('user_id')}
            name='user_id'
            label='Usuário'
            error={errors.user_id}
            placeholder='Selecione uma opção...'
            options={userSelectOptions}
            isDisabled={isFetchingUsers}
            required
          />
        )}

        <Stack
          w='full'
          spacing={4}
          direction={['column', 'column', 'row']}
        >
          <Input
            {...register('start_filter')}
            name='start_filter'
            label='Data inicial do filtro'
            type='date'
            error={errors.start_filter}
            required
          />

          <Input
            {...register('end_filter')}
            name='end_filter'
            label='Data final do filtro'
            type='date'
            error={errors.end_filter}
            max={maxEndFilter}
            required
          />
        </Stack>

        <Flex gap={2} justify={['center', 'center', 'flex-end']}>
          <Button
            isLoading={isUsageReportDataFetching}
            type='submit'
            w={['full', 'full', 'min']}
            colorScheme='blue'
          >
            Gerar relatório
          </Button>
          {usageReportData && (
            <GenerateExcelReportButton mt={-4} csvReportProps={csvReportProps} />
          )}
        </Flex>
      </Flex>


    </StandardBackgroundForm>
  )
}
