import { Box, Button, Flex, FormControl, FormLabel, Input, Select, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { set } from "date-fns";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { moveCltDriver } from "api/cltDrivers/moveCltDriver";

interface MoveCltDriverProps {
  cltDriverId: string
}

export interface MoveCltDriverSchema {
  accidentDate: string
  inssBenefitStartDate: string
  inssBenefitFinalDate: string
  moveType: string
  shouldAdvanceStatus: string
}


const moveCltDriverSchema = yup.object({
  accidentDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  inssBenefitStartDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  inssBenefitFinalDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  moveType: yup.string().required(),
})

export function MoveCltDriver({ cltDriverId }: MoveCltDriverProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    handleSubmit,
    register,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<MoveCltDriverSchema>({
    resolver: yupResolver(moveCltDriverSchema)
  })

  const { mutateAsync: moveCltDriverFn } = useMutation({
    mutationFn: moveCltDriver,
    onSuccess(_data, { body, cltDriverId }) {
      queryClient.invalidateQueries({ queryKey: 'clts-drivers' })
      queryClient.invalidateQueries({ queryKey: 'dismissing-legal-prevention' })
      queryClient.invalidateQueries({ queryKey: 'active' })
      queryClient.invalidateQueries({ queryKey: 'moved' })
      queryClient.invalidateQueries({ queryKey: ['clts-drivers', cltDriverId] })
    }
  })


  async function handleMoveCltDriver(values: MoveCltDriverSchema) {
    await promiseMessage(moveCltDriverFn({
      body: {
        ...values,
        shouldAdvanceStatus: values.shouldAdvanceStatus ? values.shouldAdvanceStatus === 'yes' : false
      },
      cltDriverId
    }), 'Processo de afastamento iniciado!')
  }
  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleMoveCltDriver)}
    >
      <FormControl isInvalid={!!errors?.accidentDate} mt={3}>
        <FormLabel fontSize="sm">
          Data do Acidente
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('accidentDate')}
          name="accidentDate"
          type="date"
          rounded="md"
          size='sm'
        />
      </FormControl>
      <FormControl isInvalid={!!errors?.inssBenefitStartDate} mt={3}>
        <FormLabel fontSize="sm">
          Data do início do benefício do INSS
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('inssBenefitStartDate')}
          name="inssBenefitStartDate"
          type="date"
          rounded="md"
          size='sm'
        />
      </FormControl>
      <FormControl isInvalid={!!errors?.inssBenefitFinalDate} mt={3}>
        <FormLabel fontSize="sm">
          Data de término do benefício do INSS
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('inssBenefitFinalDate')}
          name="inssBenefitFinalDate"
          type="date"
          rounded="md"
          size='sm'
        />
      </FormControl>
      <FormControl isInvalid={!!errors.moveType} mt={3}>
        <FormLabel fontSize="sm">
          Tipo de Afastamento
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register('moveType')}
          name="moveType"
          placeholder="Selecione..."
          rounded="md"
          size='sm'
        >
          <option value='91'>Acidente de trabalho (91)</option>
          <option value='31'>Auxilio doença (31)</option>
        </Select>
      </FormControl>
      <FormControl isInvalid={!!errors.shouldAdvanceStatus} mt={3}>
        <FormLabel fontSize="sm">
          Passar para o próximo status?
        </FormLabel>
        <Select
          {...register('shouldAdvanceStatus')}
          name="shouldAdvanceStatus"
          placeholder="Selecione..."
          size="sm"
          rounded="md"
        >
          <option value='yes'>Sim</option>
          <option value='no'>Não</option>
        </Select>
      </FormControl>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
