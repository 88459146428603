
import { useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { NotificationType } from "./dtos/NotificationType";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";

type QueryOptions = UseQueryOptions<NotificationType, unknown, NotificationType, ["notificationType"]>

interface UseNotificationTypeOptions {
  notificationTypeId: string
  queryOptions?: QueryOptions
}

export function useNotificationType(options?: UseNotificationTypeOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getNotificationType(id: string) {
    try {
      const { data } = await api.get(`/notification-type/${id}`)

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new RequestError('Erro inesperado!')
      }
    }
  }

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    errorMessage(message)

    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) await handleLogout()

  }

  return useQuery(["notificationType", options.notificationTypeId], () => {
    return getNotificationType(options.notificationTypeId)
  }, {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    enabled: !!options.notificationTypeId,
    onError: onRequestError
  })
}
