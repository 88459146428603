import { FormProvider, useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Button, Flex, FormControl, FormLabel, Heading, Select, Text } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "react-query"
import { useToastify } from "hooks/toastify/useToastify"
import { checkInvoiceValues } from "api/invoices/checkInvoiceValues"
import { AttachmentTableRowField } from "./AttachmentTableRowField"
import { FormEvent } from "react"

interface CheckInvoiceValuesProps {
  invoiceId: string
}

export interface CheckInvoiceValuesSchema {
  isReceiptCnpjSameOfRegistered: string
  isValueSameOfApprovedInvoice: string
  receiptAttachment: {
    attachmentId: string
    file: File
  }
}

const checkInvoiceSchema = yup.object({
  isReceiptCnpjSameOfRegistered: yup.string().required(),
  isValueSameOfApprovedInvoice: yup.string().required(),
  receiptAttachment: yup.mixed().when('isApproved', {
    is: 'yes',
    then: yup.object().required(),
  }),
})

export function CheckInvoiceValues({ invoiceId }: CheckInvoiceValuesProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const formMethods = useForm<CheckInvoiceValuesSchema>({
    resolver: yupResolver(checkInvoiceSchema),
  })

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = formMethods

  const handleSetAttachmentId = (attachmentId: string) => {
    setValue(`receiptAttachment.attachmentId`, attachmentId);
  };



  const { mutateAsync: checkInvoiceValuesFn } = useMutation({
    mutationFn: checkInvoiceValues,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'invoices' })
      queryClient.invalidateQueries({ queryKey: ['invoice', invoiceId] })

      reset()
    }
  })

  async function handlecheckInvoiceValues(values: CheckInvoiceValuesSchema, event: FormEvent) {
    event.preventDefault()
    event.stopPropagation()
    await promiseMessage(checkInvoiceValuesFn({
      body: {
        isReceiptCnpjSameOfRegistered: values.isReceiptCnpjSameOfRegistered === 'yes',
        isValueSameOfApprovedInvoice: values.isValueSameOfApprovedInvoice === 'yes',
        receiptAttachmentId: values.receiptAttachment.attachmentId
      },
      invoiceId
    }), 'Conferência de valor realizada!')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handlecheckInvoiceValues)}
    >
      <Heading letterSpacing="tight" size='sm'>Conferência de Valores</Heading>
      <FormControl mt={3} isInvalid={!!errors.receiptAttachment}>
        <FormProvider {...formMethods}>
          <FormLabel fontSize='sm'>
            Nota fiscal
          </FormLabel>
          <AttachmentTableRowField
            isFilledField={!!formMethods.watch(`receiptAttachment.attachmentId`)}
            onSetAttachmentId={(attachmentId) => handleSetAttachmentId(attachmentId)}
            title='Nota Fiscal'
          />
        </FormProvider>
      </FormControl>
      <FormControl isInvalid={!!errors.isValueSameOfApprovedInvoice} mt={3}>
        <FormLabel fontSize="sm">
          Confere com o valor da fatura aprovada?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register('isValueSameOfApprovedInvoice')}
          name="isValueSameOfApprovedInvoice"
          placeholder="Selecione..."
          size="sm"
          rounded="md"
        >
          <option value='yes'>Sim</option>
          <option value='no'>Não</option>
        </Select>
      </FormControl>
      <FormControl isInvalid={!!errors.isReceiptCnpjSameOfRegistered} mt={3}>
        <FormLabel fontSize="sm">
          CNPJ da NF confere com o cadastrado?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register('isReceiptCnpjSameOfRegistered')}
          name="isReceiptCnpjSameOfRegistered"
          placeholder="Selecione..."
          size="sm"
          rounded="md"
        >
          <option value='yes'>Sim</option>
          <option value='no'>Não</option>
        </Select>
      </FormControl>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
