import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { getDocuments } from "api/documents/getDocuments"
import { useAuth } from "hooks/auth/useAuth"
import { useEffect } from "react"
import { FaExchangeAlt, FaPlus } from "react-icons/fa"
import { useInfiniteQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { CreateDocumentRequest } from "./components/CreateDocumentRequest"
import { DocumentsKanbanColumn } from "./components/DocumentsKanbanColumn"

export function DocumentsKanban() {

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  const userCanCreateDocumentRequest = userLogged?.permissions?.includes('create-document-request')
  const userCanViewDocuments = userLogged?.permissions?.includes('view-documents')

  useEffect(() => {
    if (!userCanViewDocuments) redirect('/')
  }, [redirect, userCanViewDocuments])

  const {
    isOpen: isCreateDocumentRequestModalOpen,
    onOpen: onOpenCreateDocumentRequestModal,
    onClose: onCloseCreateDocumentRequestModal
  } = useDisclosure()

  const {
    data: nonEvaluatedDocumentsResult,
    isLoading: isNonEvaluatedDocumentsResultLoading,
    hasNextPage: hasNextPageNonEvaluatedDocuments,
    fetchNextPage: fetchNextPageNonEvaluatedDocuments,
    isFetchingNextPage: isFetchingNextPageNonEvaluatedDocuments,
  } = useInfiniteQuery({
    queryKey: ['non-evaluated-documents'],
    queryFn: ({ pageParam = 1 }) => getDocuments({
      currentPage: pageParam,
      pageSize: '10',
      status: 'non-evaluated',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: expiredDocumentsResult,
    isLoading: isExpiredDocumentsResultLoading,
    hasNextPage: hasNextPageExpiredDocuments,
    fetchNextPage: fetchNextPageExpiredDocuments,
    isFetchingNextPage: isFetchingNextPageExpiredDocuments,
  } = useInfiniteQuery({
    queryKey: ['expired-documents'],
    queryFn: ({ pageParam = 1 }) => getDocuments({
      currentPage: pageParam,
      pageSize: '10',
      status: 'expired',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })
  const {
    data: activeDocumentsResult,
    isLoading: isActiveDocumentsResultLoading,
    hasNextPage: hasNextPageActiveDocuments,
    fetchNextPage: fetchNextPageActiveDocuments,
    isFetchingNextPage: isFetchingNextPageActiveDocuments,
  } = useInfiniteQuery({
    queryKey: ['active-documents'],
    queryFn: ({ pageParam = 1 }) => getDocuments({
      currentPage: pageParam,
      pageSize: '10',
      status: 'active',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })
  const {
    data: inactiveDocumentsResult,
    isLoading: isInactiveDocumentsResultLoading,
    hasNextPage: hasNextPageInactiveDocuments,
    fetchNextPage: fetchNextPageInactiveDocuments,
    isFetchingNextPage: isFetchingNextPageInactiveDocuments,
  } = useInfiniteQuery({
    queryKey: ['inactive-documents'],
    queryFn: ({ pageParam = 1 }) => getDocuments({
      currentPage: pageParam,
      pageSize: '10',
      status: 'inactive',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const columnsDocuments = [
    {
      title: 'Não avaliados',
      documents: nonEvaluatedDocumentsResult?.pages.flatMap((page) => page.documents) || [],
      documentsCount: nonEvaluatedDocumentsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageNonEvaluatedDocuments,
      fetchNextPage: fetchNextPageNonEvaluatedDocuments,
      isFetchingNextPage: isFetchingNextPageNonEvaluatedDocuments,
      isLoading: isNonEvaluatedDocumentsResultLoading,
    },
    {
      title: 'Ativos',
      documents: activeDocumentsResult?.pages.flatMap((page) => page.documents) || [],
      documentsCount: activeDocumentsResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageActiveDocuments,
      fetchNextPage: fetchNextPageActiveDocuments,
      isFetchingNextPage: isFetchingNextPageActiveDocuments,
      isLoading: isActiveDocumentsResultLoading,
    },

    {
      title: 'Expirados',
      documents: expiredDocumentsResult?.pages.flatMap((page) => page.documents) || [],
      documentsCount: expiredDocumentsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageExpiredDocuments,
      fetchNextPage: fetchNextPageExpiredDocuments,
      isFetchingNextPage: isFetchingNextPageExpiredDocuments,
      isLoading: isExpiredDocumentsResultLoading,
    },


    {
      title: 'Inativos',
      documents: inactiveDocumentsResult?.pages.flatMap((page) => page.documents) || [],
      documentsCount: inactiveDocumentsResult?.pages[0]?.meta?.count,
      color: 'red.300',
      hasNextPage: hasNextPageInactiveDocuments,
      fetchNextPage: fetchNextPageInactiveDocuments,
      isFetchingNextPage: isFetchingNextPageInactiveDocuments,
      isLoading: isInactiveDocumentsResultLoading,
    },
  ]
  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Documetos</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar tabela"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => redirect('/documentos')}
          />
          {userCanCreateDocumentRequest && (
            <IconButton
              aria-label="Criar documento"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateDocumentRequestModal}
            />
          )}
        </Flex>
        <Modal
          isOpen={isCreateDocumentRequestModalOpen}
          onClose={onCloseCreateDocumentRequestModal}
          isCentered
        >
          <ModalOverlay />
          <CreateDocumentRequest
            onCloseModal={onCloseCreateDocumentRequestModal}
          />
        </Modal>
      </Flex>
      <Flex gap={4} overflowX="auto" mt={4}>
        {columnsDocuments?.map((document, index) => {
          return (
            <DocumentsKanbanColumn
              key={index}
              document={document}
            />
          )
        })}
      </Flex>
    </Box>
  )

}
