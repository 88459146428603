import { useHistory, useParams } from 'react-router'
import { useContext, useState, useEffect } from 'react'
import swal from 'sweetalert'
import { CustomerContext } from '../../contexts/CustomerContext'
import { customerFormatDateToBack } from '../../utils/CustomerFunctions/customerFormatDateToBack'
import { CustomerForm, IFormInputProps } from './CustomerForm'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { AuthContext } from '../../contexts/AuthContext'
import { useCustomerFunctions } from 'hooks/customer/useCustomerFunctions'
import { useQueryClient } from 'react-query'

interface QueryParams {
  slug: string
  id: string
}

export function CustomerAddDetailEdit() {
  const { editCustomer } = useContext(CustomerContext)
  const [isLoading, setIsLoading] = useState(false)
  const { slug, id }: QueryParams = useParams()
  const { userLogged } = useContext(AuthContext)

  const {
    updateCustomerAttachments: {
      mutateAsync: updateCustomerAttachments
    },
    createCustomer: {
      mutateAsync: createCustomer
    }
  } = useCustomerFunctions()

  const history = useHistory()

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (slug === 'adicionar') {
            if (!permissions?.includes('add-customer')) {
              history.push('/')
            }
          }
          if (slug === 'editar') {
            if (!permissions?.includes('edit-customer')) {
              history.push('/')
            }
          }
          if (slug === 'visualizar') {
            if (!permissions?.includes('view-customer')) {
              history.push('/')
            }
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [slug, history, userLogged])

  async function handleCreateCustomer(values: IFormInputProps) {
    await swal({
      title: 'Deseja adicionar um cliente?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willCreate) => {
      if (willCreate) {
        const formatedData = customerFormatDateToBack(values)
        await createCustomer(formatedData, {
          onSuccess: async (data) => {
            if (values.attachments) {
              const formData = new FormData()
              values.attachments.forEach(value => {
                formData.append('attachments', value.attachment[0])
              })
              await updateCustomerAttachments({
                customerId: data.id,
                input: formData
              })
            }

            setIsLoading(true)
            history.push('/clientes')
          }
        })
      } else {
        return swal('Ação cancelada com êxito!')
      }
    })
  }

  const queryClient = useQueryClient()

  async function handleEditCustomer(values: IFormInputProps) {
    await swal({
      title: 'Deseja editar um cliente?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willEdit) => {
      if (willEdit) {
        const formatedData = customerFormatDateToBack(values)
        const response = await editCustomer(id, formatedData)
        if (values.attachments) {
          const formData = new FormData()

          values.attachments.forEach(value => {
            formData.append('attachments', value.attachment[0])
          })

          await updateCustomerAttachments({
            customerId: id,
            input: formData
          })
        }
        if (response) {

          queryClient.invalidateQueries('customer')
          setIsLoading(true)
          setTimeout(() => {
            history.push('/clientes')
          }, 1500)
        }
      } else {
        return swal('Ação cancelada com êxito!')
      }
    })
  }

  if (isLoading) {
    return <GeneralContentLoading />
  }

  if (slug === 'adicionar') {
    return (
      <CustomerForm
        slug={slug}
        id={id}
        title="Adicionar Cliente"
        action="Salvar"
        submit={handleCreateCustomer}
        href=""
      />
    )
  } else if (slug === 'editar') {
    return (
      <CustomerForm
        slug={slug}
        id={id}
        title="Editar Cliente"
        action="Salvar"
        submit={handleEditCustomer}
        href=""
      />
    )
  } else {
    return (
      <CustomerForm
        slug={slug}
        id={id}
        title="Visualizar Cliente"
        action="Editar"
        submit={async () => { }}
        isDisabled={true}
        href={`/cliente/editar/${id}`}
      />
    )
  }
}
