import { Box, Button, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, Heading, IconButton, Modal, ModalOverlay, Radio, RadioGroup, Select, Stack, Text, useDisclosure, VStack } from "@chakra-ui/react"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { useSearchParams } from "hooks/useSearchParams";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { CreateRequestPatrimony } from "pages/Patrimony/components/CreateRequestPatrimony";
import { hireCltDriver } from "api/cltDrivers/hireCltDriver";
import { CltDriver } from "api/cltDrivers/_types/CltDriver";
import { useEffect } from "react";

interface HireCltDriverProps {
  cltDriver: CltDriver
}

export interface HireCltDriverSchema {
  hiringDocumentationChecklist: string[]
  hasRequestedMealFoodVoucher: string
  hasRequestedTicketLog: string
  shouldAdvanceStatus: string
}


const hireCltDriverSchema = yup.object({
  hiringDocumentationChecklist: yup.array().of(yup.string()).nullable(),
  hasRequestedMealFoodVoucher: yup.string(),
  hasRequestedTicketLog: yup.string(),
})

const documentationChecklist = [
  { name: 'Comprovante de endereço' },
  { name: 'Carteira de vacinação do colaboradora' },
  { name: 'Carteira de Trabalho (CTPS)' },
  { name: 'Comprovante de escolaridade dos filhos' },
  { name: 'Comprovante de vacinação dos filhos' },
  { name: 'Certidão de Casamento' },
  { name: 'Certidão de nascimento dos filhos' },
]


export function HireCltDriver({ cltDriver }: HireCltDriverProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<HireCltDriverSchema>({
    resolver: yupResolver(hireCltDriverSchema)
  })

  useEffect(() => {
    if (cltDriver) {
      setValue("hiringDocumentationChecklist", cltDriver?.hiringDocumentationChecklist || []);
      setValue("hasRequestedMealFoodVoucher", cltDriver?.hasRequestedMealFoodVoucher ? 'yes' : 'no')
      setValue("hasRequestedTicketLog", cltDriver?.hasRequestedTicketLog ? 'yes' : 'no')
    }
  }, [cltDriver, setValue])


  const {
    isOpen: isCreateRequestPatrimonyModalOpen,
    onOpen: onOpenCreateRequestPatrimonyModal,
    onClose: onCloseCreateRequestPatrimonyModal
  } = useDisclosure()

  const { mutateAsync: hireCltDriverFn } = useMutation({
    mutationFn: hireCltDriver,
    onSuccess(_data, { body, cltDriverId }) {
      queryClient.invalidateQueries({ queryKey: 'clts-drivers' })
      queryClient.invalidateQueries({ queryKey: 'hiring-motorcyle-details-drivers-clt' })
      queryClient.invalidateQueries({ queryKey: 'hiring-drivers-clt' })
      queryClient.invalidateQueries({ queryKey: ['clt-driver', cltDriverId] })

      searchParams.delete('patrimonyRequested')
      replace({ search: searchParams.toString() })

    }
  })

  async function handleHireCltDriver(values: HireCltDriverSchema) {
    await promiseMessage(hireCltDriverFn({
      body: {
        hiringDocumentationChecklist: values.hiringDocumentationChecklist,
        hasRequestedMealFoodVoucher: values.hasRequestedMealFoodVoucher === 'yes',
        hasRequestedTicketLog: values.hasRequestedTicketLog === 'yes',
        shouldAdvanceStatus: values.shouldAdvanceStatus ? values.shouldAdvanceStatus === 'yes' : false
      },
      cltDriverId: cltDriver?.id
    }), 'Contratatação Realizada')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleHireCltDriver)}
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'>Contratação</Heading>
      <FormControl mt={3} isInvalid={!!errors.hiringDocumentationChecklist}>
        <FormLabel fontSize='sm'>
          Documentação
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          control={control}
          name="hiringDocumentationChecklist"
          render={({ field }) => {
            return (
              <CheckboxGroup
                value={field.value}
                onChange={field.onChange}
              >
                <VStack
                  spacing={3}
                  p={2}
                  border="1px solid"
                  borderColor="gray.200"
                  rounded="md"
                  align="left"
                >
                  {documentationChecklist.map(check => {
                    return (
                      <Checkbox key={check.name} value={check.name}>
                        <Text fontSize="sm">
                          {check.name}
                        </Text>
                      </Checkbox>
                    )
                  })}
                </VStack>

              </CheckboxGroup>
            )
          }}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.hasRequestedMealFoodVoucher} mt={3}>
        <FormLabel>
          VA/VR Solicitado ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasRequestedMealFoodVoucher"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.hasRequestedTicketLog} mt={3}>
        <FormLabel>
          Ticket Log Solicitado ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasRequestedTicketLog"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <Flex mt={3} alignItems="baseline" justifyContent="space-between">
        <FormLabel fontSize='sm'>
          Solicitação de patrimônio
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <IconButton
          aria-label="Criar solicitação de patrimônio"
          icon={<FaPlus />}
          colorScheme="blue"
          size="sm"
          onClick={onOpenCreateRequestPatrimonyModal}
        />

      </Flex>
      <FormControl isInvalid={!!errors.shouldAdvanceStatus} mt={3}>
        <FormLabel fontSize="sm">
          Passar para o próximo status?
        </FormLabel>
        <Select
          {...register('shouldAdvanceStatus')}
          name="shouldAdvanceStatus"
          placeholder="Selecione..."
          size="sm"
          rounded="md"
        >
          <option value='yes'>Sim</option>
          <option value='no'>Não</option>
        </Select>
      </FormControl>

      <Modal
        isOpen={isCreateRequestPatrimonyModalOpen}
        onClose={onCloseCreateRequestPatrimonyModal}
        isCentered
      >
        <ModalOverlay />
        <CreateRequestPatrimony
          onCloseModal={onCloseCreateRequestPatrimonyModal}
          required={true}
        />
      </Modal>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
