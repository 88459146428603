import axios from "axios"
import { useMutation, useQueryClient } from "react-query"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"


interface CreateAttachmentReqFunctionParams {
  input: FormData
}

async function createAttachmentReqFunction({input}: CreateAttachmentReqFunctionParams) {
  try {
    const { data } = await api.post('attachments', input)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    }
    throw new RequestError('Erro inesperado!')
  }
}


async function deleteAttachmentReqFunction(attachmentId : string) {
  try {
    await api.delete(`attachments/${attachmentId}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    }
    throw new RequestError('Erro inesperado!')
  }
}

export function useAttachmentFunctions() {

  const queryClient = useQueryClient()

  const onRequestSuccess = async () => {
    await queryClient.invalidateQueries("extraDiscount")
  }

  const createAttachment = useMutation(createAttachmentReqFunction)
  const deleteAttachment = useMutation(deleteAttachmentReqFunction, {
    onSuccess: onRequestSuccess
  })

  return {
    createAttachment,
    deleteAttachment,
  }

}
