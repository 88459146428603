import { Box, FormControl, FormLabel, Input, Select, Stack, Text, Textarea } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext, } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";

const installmentNumberOptions = Array.from({ length: 12 }, (_, i) => (i + 1).toString());

export function LogLifeCreditCardForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      financialClassification: 'Classificação Financeira',
      purchaseCostCenter: 'Centro de Custo da compra',
      purchaseDescription: 'Descrição da compra',
      installmentNumber: 'Número de parcelas',
      supplierName: 'Nome do fornecedor',
      purchaseDate: 'Data da Compra',
      totalAmount: 'Valor total',
    };


    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };



  return (
    <Box w='full'>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.financialClassification}>
            <FormLabel fontSize='sm'>
              Classificação Financeira
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.financialClassification.value')}
              name='formData.financialClassification.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('financialClassification')}
            />
          </FormControl>
        </Stack>

        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.purchaseCostCenter}>
            <FormLabel fontSize='sm'>
              Centro de Custo da compra
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.purchaseCostCenter.value')}
              name='formData.purchaseCostCenter.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('purchaseCostCenter')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <FormControl isInvalid={!!errors.formData?.purchaseDescription} mt={3}>
        <FormLabel fontSize="sm">
          Descrição da compra
        </FormLabel>
        <Textarea
          {...register('formData.purchaseDescription.value')}
          name='formData.purchaseDescription.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('purchaseDescription')}
        />
      </FormControl>

      <FormControl isInvalid={!!errors.formData?.installmentNumber} mt={3}>
        <FormLabel fontSize="sm">
          Número de parcelas
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register("formData.installmentNumber.value")}
          name="formData.installmentNumber.value"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('installmentNumber')}
        >

          {Object.entries(installmentNumberOptions).map(([key, value]) => (
            <option key={key} value={value}>{value}</option>
          ))}

        </Select>
      </FormControl>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.supplierName}>
            <FormLabel fontSize='sm'>
              Nome do fornecedor
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.supplierName.value')}
              name='formData.supplierName.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('supplierName')}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.purchaseDate} >
            <FormLabel fontSize='sm'>
              Data da Compra
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.purchaseDate.value')}
              name='formData.purchaseDate.value'
              type="date"
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('purchaseDate')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <FormControl isInvalid={!!errors.formData?.totalAmount} mt={3}>
        <FormLabel fontSize='sm'>
          Valor total
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.totalAmount.value')}
          name='formData.totalAmount.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('totalAmount')}
        />
      </FormControl>

    </Box>
  )
}
