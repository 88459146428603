import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Flex, Stack } from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { useAuth } from "hooks/auth/useAuth";
import { Input } from "components/Inputs/Input";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { formatDate } from "utils/DateFunctions/formatDate";
import { add } from "date-fns";
import { useESGReport } from "hooks/report/useEsgReport";

interface ESGReportInputs {
  start_filter: string
  end_filter: string
}

const ESGReportHeaders = [
  { key: 'protocol', label: 'PROTOCOLO' },
  { key: 'customer', label: 'CLIENTE' },
  { key: 'modal', label: 'MODAL' },
  { key: 'service_type', label: 'TIPO DE SERVIÇO' },
  { key: 'weight', label: 'KG DA CARGA' },
  { key: 'sender_cep', label: 'CEP DO REMETENTE' },
  { key: 'receiver_cep', label: 'CEP DO DESTINATÁRIO' },
  { key: 'is_crossdocking', label: 'SERVIÇO CROSSDOCKING?' },
  { key: 'vehicle', label: 'VEÍCULO' },
  { key: 'collect_date', label: 'DATA DA COLETA' },
  { key: 'delivery_date', label: 'DATA DA ENTREGA' },
  { key: 'driver', label: 'MOTORISTA'},
  { key: 'source_cities', label: 'CIDADES ORIGEM'},
  { key: 'source_cities_uf', label: 'CIDADES ORIGEM UF'},
  { key: 'destination_cities', label: 'CIDADES DESTINO'},
  { key: 'destination_cities_uf', label: 'CIDADES DESTINO UF'},
  { key: 'source_collector', label: 'COLETADOR DE ORIGEM'},
  { key: 'destination_collector', label: 'COLETADOR DE DESTINO'},
]

const schema = yup.object().shape({
  start_filter: yup.string().required('Campo obrigatório!'),
  end_filter: yup.string().required('Campo obrigatório!')
})

export function ESGReport() {
  const [
    enableESGReportRequest,
    setEnableESGReportRequest
  ] = useState(false)

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<ESGReportInputs>({
    resolver: yupResolver(schema),
    shouldUnregister: true
  })

  const [
    startFilter,
    endFilter
  ] = useWatch({
    control,
    name: ['start_filter', 'end_filter']
  })

  const isValidStartDate = new Date(startFilter) instanceof Date &&
    !isNaN(new Date(startFilter).getMilliseconds())

  const maxEndFilter = isValidStartDate
    ? formatDate.handle(add(new Date(startFilter), {
      days: 31
    }), 'DateWithoutHourToInput')
    : ''

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-esg-report')) {
      redirect('/')
    }
  }, [userLogged, redirect])

  const {
    data: ESGReportData,
    isFetching: isESGReportDataFetching,

  } = useESGReport({
    queryOptions: {
      enabled: enableESGReportRequest,
      select: (ESGReportData) => {
        ESGReportData.esg_report_data = ESGReportData.esg_report_data.map((data) => {
          return {
            ...data,
            collect_date: formatDate.handle(data.collect_date, 'DateWithoutHourToShow'),
            delivery_date: formatDate.handle(data.delivery_date, 'DateWithoutHourToShow'),
          }
        })

        return ESGReportData
      }
    },
    queryParams: {
      start_filter: startFilter,
      end_filter: endFilter
    }
  })

  const handleGenerateReport = () => {
    setEnableESGReportRequest(true)
    setTimeout(() => {
      setEnableESGReportRequest(false)
    }, 500)
  }

  const csvReportFilename = `relatorio-esg-${startFilter}-${endFilter}`

  const csvReportProps = {
    data: ESGReportData?.esg_report_data ?? [],
    headers: ESGReportHeaders,
    filename: csvReportFilename,
  }

  return (
    <StandardBackgroundForm
      title='Relatório ESG'
      onSubmit={handleSubmit(handleGenerateReport)}
    >
      <Flex
        gap={4}
        direction='column'
      >
        <Stack
          w='full'
          spacing={4}
          direction={['column', 'column', 'row']}
        >
          <Input
            {...register('start_filter')}
            name='start_filter'
            label='Data inicial do filtro'
            type='date'
            error={errors.start_filter}
            required
          />

          <Input
            {...register('end_filter')}
            name='end_filter'
            label='Data final do filtro'
            type='date'
            error={errors.end_filter}
            max={maxEndFilter}
            required
          />
        </Stack>

        <Flex gap={2} justify={['center', 'center', 'flex-end']}>
          <Button
            isLoading={isESGReportDataFetching}
            type='submit'
            w={['full', 'full', 'min']}
            colorScheme='blue'
          >
            Gerar relatório
          </Button>
          {ESGReportData && (
            <GenerateExcelReportButton mt={-4} csvReportProps={csvReportProps} />
          )}
        </Flex>
      </Flex>


    </StandardBackgroundForm>
  )
}
