import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Button,
  Stack,
} from '@chakra-ui/react'
import { useEffect, useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { ServiceProps } from '../../../../contexts/ServiceContext'
import { AuthContext } from '../../../../contexts/AuthContext'


import { GeneralContentLoading } from '../../../../components/Loading/GeneralContentLoading'
import { Input } from '../../../../components/Inputs/Input'

import { useService } from '../../../../hooks/services/service'
import { useAddresses } from '../../../../hooks/address/useAddresses'
import { Address } from 'hooks/address/dtos/Address'

interface QueryParams {
  service_id: string
  address_id: string
}

interface DriverFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
}

export function DeliveryAddresses({
  slug,
  id,
  isDisabled = false,
  href,
  title,
  action,
}: DriverFormProps) {
  const [isPageLoading, setIsPageLoading] = useState(true)

  const [destinationAddresses, setDestinationAddresses] = useState<
    Address[]
  >([])

  const [serviceFilteredById, setServiceFilteredById] = useState<
    ServiceProps[]
  >([])

  const { userLogged } = useContext(AuthContext)

  const { service_id }: QueryParams = useParams()

  const history = useHistory()

  const {
    service: { data: serviceById, isLoading: isServiceLoading },
  } = useService(service_id, false, false)
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()

  useEffect(() => {
    function run() {
      if (!isServiceLoading && !isAddressesLoading) {
        setIsPageLoading(false)
      }
    }
    run()
  }, [isServiceLoading, isAddressesLoading])

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (!permissions?.includes('add-collect-service')) {
            history.push('/')
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [slug, history, userLogged])

  useEffect(() => {
    function run() {
      if (serviceById !== undefined) {
        const serviceFiltered = []
        serviceFiltered.push(serviceById)
        setServiceFilteredById(serviceFiltered)
      }
    }
    run()
  }, [serviceById])

  useEffect(() => {
    function run() {
      if (addresses) {
        const destinationddressOfService = serviceFilteredById.flatMap(
          (service) => service.serviceIDRequested.destination_address_id,
        )
        const destinationddress = addresses.filter((address) =>
          destinationddressOfService.includes(address.id),
        )
        setDestinationAddresses(destinationddress)
      }
    }

    run()
  }, [serviceFilteredById, addresses])

  if (isPageLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          noValidate
        >
          <Heading size="lg" fontFamily="poppins">
            {title}
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          {destinationAddresses.map((address) => (
            <Stack
              key={address.id}
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'column']}
            >
              <Input
                defaultValue={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep} | ${address.complement}`.toUpperCase()}
                isDisabled={isDisabled}
                name="firstname"
                label="Endereço Remetente"
              />

              <Link
                to={`/servicos/entregas/andamento/${service_id}/enderecos/${address.id}`}
              >
                <Button type="button" colorScheme="blue" w="100%">
                  Editar Entrega
                </Button>
              </Link>
              <Divider my="6" borderColor="gray.700" />
            </Stack>
          ))}

          <Flex mt="8" justify="flex-end">
            <HStack>
              <Link to="/servicos/entregas">
                <Button type="button" colorScheme="gray">
                  Lista de Serviços à Entregar
                </Button>
              </Link>
            </HStack>
          </Flex>
        </Box>
      </Flex>

  )
}
