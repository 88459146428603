import axios from "axios"
import { Address } from "hooks/address/dtos/Address"
import { User } from "hooks/user/dtos/User"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"


interface GetAddressLogsParams {
  addressId: string
  pageSize: string
  currentPage: string
}

type LogDetail = {
  [Property in keyof Address]: {
    prev_value: Address[Property]
    new_value: Address[Property]
  }
}

export interface AddressLogs {
  id: string
  log_detail: LogDetail
  created_at: string
  updated_at: string
  createdAt: string
  updatedAt: string
  address_id: string
  updated_by: string
  updatedBy: Pick<User, 'id' | 'firstname' | 'lastname'>
}

export interface GetAddressLogsResponse {
  addressLogs: AddressLogs[]
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
  }
}

export async function getAddressLogs({
  addressId,
  currentPage,
  pageSize
}: GetAddressLogsParams) {
  try {
    const { data } = await api.get<GetAddressLogsResponse>(
      `/address/${addressId}/logs`, {
      params: {
        pageSize,
        currentPage
      }
    }
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
