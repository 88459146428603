import { useHistory, useParams } from 'react-router'
import { useState, useContext, useEffect } from 'react'
import swal from 'sweetalert'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { ShippingForm } from './ShippingForm'
import { ShippingContext } from '../../contexts/ShippingContext'
import { shippingFormatDataToBack } from '../../utils/ShippingFunctions/shippingFormatDataToBack'
import { AuthContext } from '../../contexts/AuthContext'

interface QueryParams {
  id: string
  slug: string
}

interface FormInputs {
  company_name: string
  trading_name: string
  cnpj: string
  email: string
  modal: string
  cellphone: string
  telephone: string
  state_register: string | null
  observation: string
  has_board_autofill: 'yes' | 'no'
  cte_transfer_cost: string
  board_weight: string
  real_weight: string
  taxed_weight: string
  is_loglife_payer: string
  is_cte_destination_branch_equal_to_planned: string
}

export function ShippingAddDetailEdit() {
  const [isLoading, setIsLoading] = useState(false)
  const { createShipping, editShipping } = useContext(ShippingContext)
  const { userLogged } = useContext(AuthContext)

  const { id, slug }: QueryParams = useParams()

  const history = useHistory()

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (slug === 'adicionar') {
            if (!permissions?.includes('add-shipping')) {
              history.push('/')
            }
          }
          if (slug === 'editar') {
            if (!permissions?.includes('edit-shipping')) {
              history.push('/')
            }
          }
          if (slug === 'visualizar') {
            if (!permissions?.includes('view-shipping')) {
              history.push('/')
            }
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [slug, history, userLogged])

  async function handleCreateShipping(values: FormInputs) {
    await swal({
      title: 'Deseja adicionar uma transportadora?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willCreate) => {
      if (willCreate) {
        const formatedData = shippingFormatDataToBack(values)
        const response = await createShipping(formatedData)
        if (response) {
          setIsLoading(true)
          setTimeout(() => {
            history.push('/transportadoras')
          }, 1500)
        }
      } else {
        swal('Ação cancelada com êxito!')
      }
    })
  }

  async function handleEditShipping(values: FormInputs) {
    await swal({
      title: 'Deseja editar uma trasnportadora?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willEdit) => {
      if (willEdit) {
        const formatedData = shippingFormatDataToBack(values)
        const response = await editShipping(id, formatedData)
        if (response) {
          setIsLoading(true)
          setTimeout(() => {
            history.push('/transportadoras')
          }, 1500)
        }
      } else {
        swal('Ação cancelada com êxito!')
      }
    })
  }

  if (isLoading) {
    return <GeneralContentLoading />
  }

  if (slug === 'adicionar') {
    return (
      <ShippingForm
        slug={slug}
        href=""
        submit={handleCreateShipping}
        title="Adicionar Transportadora"
        action="Salvar"
      />
    )
  } else if (slug === 'editar') {
    return (
      <ShippingForm
        slug={slug}
        id={id}
        href=""
        submit={handleEditShipping}
        title="Editar Transportadora"
        action="Salvar"
      />
    )
  } else {
    return (
      <ShippingForm
        slug={slug}
        id={id}
        href={`/transportadora/editar/${id}`}
        submit={async () => {}}
        title="Visualizar Transportadora"
        isDisabled={true}
        action="Editar"
      />
    )
  }
}
