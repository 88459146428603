import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CheckInvoiceBody {
  approvedReportAttachmentId: string
  divergences: string
  hasContractAdditive: boolean
  hasUnforeseenCharge: boolean
  isApproved: boolean
  responsibleId: string
  unforeseenChargeChecklist: string[]
  approvedValueInCents: number
}

interface CheckInvoiceProps {
  invoiceId: string
  body: CheckInvoiceBody
}

export async function checkInvoice({
  invoiceId,
  body
}: CheckInvoiceProps) {
  try {
    await api.patch(`invoices/${invoiceId}/check-invoice`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
