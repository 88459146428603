import { Box, Flex, FormControl, FormLabel, Spinner, Stack, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { GeneralCheckbox } from 'components/Inputs/GeneralCheckbox'
import { SearchBox } from 'components/SearchBox/SearchBox'
import { Collector } from 'hooks/collector/dtos/Collector'
import { useLLMUsers } from 'hooks/user/useLLMUsers'
import { useEffect, useState } from 'react'
import { Controller, NestedValue, useForm } from 'react-hook-form'
import { searchBoxFilter } from 'utils/searchBoxFilter'
import { CustomerProps } from '../../../contexts/CustomerContext'
import { PermissionProps } from '../../../contexts/PermissionContext'
import { useAuth } from '../../../hooks/auth/useAuth'
import { IDriverProps } from '../../../services/getFunctions/driver/getDrivers'
import { negative_positive } from '../../../utils/customLists'
import { ProfileProps } from '../../../utils/RequestFunctions/Profile/requestProfileFunctions'
import { schema } from '../../../validations/requestedUserSchema'
import { ListButton } from '../../Buttons/ListButton'
import { SubmitButton } from '../../Buttons/SubmitButton'
import { Input } from '../../Inputs/Input'
import { Select } from '../../Inputs/SelectInput'
import { StandardBackgroundForm } from '../StandardBackgroundForm'
import AsyncSelect from 'react-select/async';

interface IFormInputProps {
  user_type: string
  loglife_employee: string
  customer_id: string
  collector_id: string
  driver_id: string
  permissions: NestedValue<string[]>
  email: string
  firstname: string
  lastname: string
  owner: string
  customers: string
  substitute_id: string
  collectors_ids: {
    label: string
    value: string
  }[]
}

interface ICreateRequestedUserProps {
  submit: (values: IFormInputProps) => Promise<void>
  customers?: CustomerProps[]
  collectors?: Collector[]
  drivers?: IDriverProps[]
  permissions?: PermissionProps[]
  profiles?: ProfileProps[]
}

export function CreateRequestedUserForm({
  submit,
  customers,
  collectors,
  drivers,
  permissions,
  profiles,
}: ICreateRequestedUserProps) {
  const [driversFilteredByCollectorId, setDriversFilteredByCollectorId] =
    useState<IDriverProps[]>([])

  const { userLogged } = useAuth()
  const [customerFiltered, setCustomerFiltered] = useState('')

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(schema),
  })

  const userType = watch('user_type')
  const collectorId = watch('collector_id')
  const isLLMUser = watch('loglife_employee') === 'SIM'

  const isUserLoggedCustomer = userLogged?.user_type === 'CLIENTE'
  const isUserLoggedCollector = userLogged?.user_type === 'COLETADOR'

  const userTypesToSelect = isUserLoggedCollector
    ? profiles
      ?.filter(profile => profile.user_type === 'COLETADOR' ||
        profile.user_type === 'MOTORISTA')
      .map(profile => {
        return { id: profile.id, name: profile.user_type }
      })
    : isUserLoggedCustomer
      ? profiles
        ?.filter(profile => profile.user_type === 'CLIENTE' ||
          profile.user_type === 'REMETENTE')
        .map(profile => {
          return { id: profile.id, name: profile.user_type }
        })
      : profiles?.map(profile => {
        return { id: profile.id, name: profile.user_type }
      })


  useEffect(() => {
    const nonLoglifeEmployeeUserTypes = ['CLIENTE', 'REMETENTE']

    if (nonLoglifeEmployeeUserTypes.includes(userType)) {
      setValue('loglife_employee', 'NÃO')
    } else {
      setValue('loglife_employee', 'SIM')
    }
  }, [userType, setValue])

  useEffect(() => {
    if (customers && userLogged) {
      if (userLogged?.user_type === 'CLIENTE') {
        setValue('customer_id', userLogged?.customer_id)
        return
      }

      if (userLogged?.user_type === 'COLETADOR') {
        setValue('loglife_employee', 'NÃO')
        setValue('collector_id', userLogged?.collector_id)
      }
    }
  }, [customers, userType, userLogged, setValue])

  useEffect(() => {
    if (collectorId && drivers) {
      if (userLogged?.user_type === 'COLETADOR' && userType === 'MOTORISTA') {
        const driversFilteredByCollectorId = drivers.filter(
          (driver) => driver.collector_id === userLogged?.collector_id,
        )
        setDriversFilteredByCollectorId(driversFilteredByCollectorId)
        return
      }

      const driversFilteredByCollectorId = drivers.filter(
        (driver) => driver.collector_id === collectorId,
      )
      setDriversFilteredByCollectorId(driversFilteredByCollectorId)
    }
  }, [collectorId, userType, userLogged, drivers])

  useEffect(() => {
    if (userType !== 'CLIENTE' && userType !== 'REMETENTE') {
      setValue('customer_id', '')
    }
    if (userType !== 'COLETADOR' && userType !== 'MOTORISTA') {
      setValue('collector_id', '')
    }
    if (userType !== 'MOTORISTA') {
      setValue('driver_id', '')
    }
  }, [userType, setValue])

  const customersCheckboxOptions = customers?.filter(customer => {
    return userLogged?.user_type === 'CLIENTE'
      ? customer.id === userLogged?.customer_id
      : customer
  })?.map((customer) => {
    return {
      key: customer.id,
      value: customer.id,
      showOption: customer.trading_firstname
    }
  })

  const customersOptions = customersCheckboxOptions?.filter((customer) => {
    const customerFilter = searchBoxFilter(
      customer.showOption,
      customerFiltered,
    )
    if (customerFiltered === "") {
      return customer
    }
    return customerFilter
  })

  const {
    data: llmUsersData,
    isFetching: isFetchingLlmUsersData
  } = useLLMUsers({
    queryOptions: {
      enabled: isLLMUser
    }
  })

  const substituteUsersOptions = llmUsersData?.users.map((user) => {
    return {
      key: user.id,
      value: user.id,
      showOption: user.firstname + ' ' + user.lastname
    }
  }).sort((a, b) => a.showOption.localeCompare(b.showOption))


  async function promiseOptionsCollectors(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    return collectors
      ?.filter((collector) => collector.situation === 'ATIVO')
      ?.filter((collector) => !inputValue || collector.trading_name.toLowerCase().includes(inputValue.toLowerCase()))
      ?.sort((a, b) => a.trading_name.localeCompare(b.trading_name))
      ?.map((collector) => {
        return { value: collector.id, label: collector.trading_name.toUpperCase() };
      });
  }



  return (
    <StandardBackgroundForm
      onSubmit={handleSubmit(submit)}
      title="Solicitar Criação de Usuário"
    >
      <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Select
          {...register('user_type')}
          name="user_type"
          label="Tipo de Usuário"
          placeholder="Selecione uma opção..."
          user_types={userTypesToSelect}
          error={errors.user_type}
          required
        />
        <Stack direction={["column", "column", "row"]} spacing="4" w="full">
          <Select
            {...register('loglife_employee')}
            name="loglife_employee"
            label="Funcionário LLM"
            placeholder="Selecione uma opção..."
            negative_positive={negative_positive}
            isDisabled={
              userLogged?.user_type === 'COLETADOR' ||
              userLogged?.user_type === 'CLIENTE'
            }
            error={errors.loglife_employee}
            required
          />

          {isFetchingLlmUsersData ? (
            <Spinner />
          ) : (
            <Select
              {...register("substitute_id")}
              name="substitute_id"
              error={errors.substitute_id}
              options={substituteUsersOptions}
              label="Usuário suplente"
              placeholder="Selecione um usuário..."
              isDisabled={
                userLogged?.user_type === 'COLETADOR' ||
                userLogged?.user_type === 'CLIENTE'
              }
            />
          )}
        </Stack>
      </Stack>
      {userType === 'COLETADOR' && (
        <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
          <FormControl isInvalid={!!errors?.collectors_ids}>
            <FormLabel w="full" fontSize='sm'>
              Coletador(es)
              <Text as="sup" color="red.500">*</Text>

            </FormLabel>
            <Controller
              control={control}
              name="collectors_ids"
              render={({ field }) => (
                <AsyncSelect
                  name={field.name}
                  onChange={field.onChange}
                  value={field.value}
                  cacheOptions
                  defaultOptions
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: '2px',
                      borderRadius: '6px',
                      borderColor: 'gray.200',
                      border: state.isFocused ? 'none' : '',
                      boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                    }),
                    placeholder: (base, props) => ({
                      ...base,
                      color: 'blackAlpha.900'
                    }),
                    dropdownIndicator(base, props) {
                      return {
                        ...base,
                        color: 'blackAlpha.900',
                        width: '34px',
                      }
                    },
                  }}
                  noOptionsMessage={() => 'Não há coletadores cadastrados ou encontrados!'}
                  placeholder="Selecione um coletador..."
                  loadOptions={promiseOptionsCollectors}
                  isClearable={true}
                  isMulti
                />
              )}
            />
          </FormControl>
        </Stack>
      )}
      <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
        <Input
          {...register('email')}
          name="email"
          label="E-mail"
          error={errors.email}
          required
        />
        {(userType === 'MOTORISTA') && (
          <Select
            {...register('collector_id')}
            name="collector_id"
            collectors={collectors}
            placeholder="Selecione uma opção..."
            label="Pertence ao Coletador"
            isDisabled={
              userLogged?.user_type === 'COLETADOR' ||
              userLogged?.user_type === 'CLIENTE'
            }
            error={errors.collector_id}
            required
          />
        )}
      </Stack>

      <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
        <Stack w="full" spacing="24px" direction={['column', 'column', 'row']}>
          <Input
            {...register('firstname')}
            name="firstname"
            label="Nome"
            error={errors.firstname}
            required
          />
          <Input
            {...register('lastname')}
            name="lastname"
            label="Sobrenome"
            error={errors.lastname}
            required
          />
        </Stack>
        {userType === 'MOTORISTA' && collectorId && (
          <Stack w="full">
            <Select
              {...register('driver_id')}
              name="driver_id"
              drivers={driversFilteredByCollectorId}
              placeholder="Selecione uma opção..."
              label="Pertence ao Motorista"
              error={errors.driver_id}
              required
            />
          </Stack>
        )}
      </Stack>
      {(userType === 'CLIENTE' || userType === 'REMETENTE') && (
        <Stack w="full" mt="4" spacing="10px" justify="flex-start">
          <>
            <SearchBox
              isCheckbox={true}
              size="sm"
              placeholder="Buscar Cliente..."
              handleSearch={(e) =>
                setCustomerFiltered(e.target.value)
              }
            />
            <Box
              overflowY="auto"
              height="300px"
              borderWidth="1px"
              borderRadius="lg"
              p="2"
            >
              <Controller
                control={control}
                name="customers"
                render={({ field }) => (
                  <GeneralCheckbox
                    name="customers"
                    onCheckboxChange={field.onChange}
                    checkboxOptions={customersOptions}
                    label="Cliente(s)"
                  />
                )}
              />
            </Box>
          </>
        </Stack>
      )}
      <Flex mt="4" justifyContent="flex-end" alignItems="center">
        <Stack direction="row" spacing="12px">
          <SubmitButton action="Salvar" isSubmitting={isSubmitting} />
          {userLogged?.permissions.includes('view-requested-user') && (
            <ListButton name="Solicitações" href="/usuarios-solicitados" />
          )}
          ;
        </Stack>
      </Flex>
    </StandardBackgroundForm>
  )
}
