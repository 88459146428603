import { Button, Flex, FormControl, FormLabel, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Stack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import { useToastify } from "hooks/toastify/useToastify"
import { Controller, useForm, useWatch } from "react-hook-form"
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa"
import { useMutation, useQueryClient } from "react-query"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { validateHasFile } from "utils/fileValidation"
import { bulkEditShippingCosts } from "api/shippings/bulkEditShippingCosts"
import AsyncSelect from 'react-select/async';
import { getShippings } from "api/shippings/getShippings"
import { captalize } from "utils/captalize"

interface BulkEditShippingCostsProps {
  onCloseModal: () => void
}

interface BulkEditShippingCostsSchema {
  shippingId: string
  csv: FileList
}

const bulkEditShippingCostsSchema = yup.object({
  shippingId: yup.string().required(),
  csv: yup.mixed().test(value => validateHasFile(value)),
})

export function BulkEditShippingCosts({
  onCloseModal
}: BulkEditShippingCostsProps) {
  const { promiseMessage } = useToastify()

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<BulkEditShippingCostsSchema>({
    resolver: yupResolver(bulkEditShippingCostsSchema),
  })


  const [csvAttachment] = useWatch({
    control,
    name: ['csv']
  })

  const attachmentCsvPreview = csvAttachment
    ? Object.entries(csvAttachment).map(([key, value]) => URL.createObjectURL(value))[0]
    : null

  const queryClient = useQueryClient()

  const { mutateAsync: bulkEditShippingCostsFn } = useMutation({
    mutationFn: bulkEditShippingCosts,
    onSuccess() {
      queryClient.invalidateQueries(['shipping-costs'])
      onCloseModal()
    }
  })

  async function handleBulkEditShippingCosts({ csv, shippingId }: BulkEditShippingCostsSchema) {
    const formData = new FormData()
    formData.append("csv", csv[0])
    formData.append("shippingId", shippingId)

    await promiseMessage(bulkEditShippingCostsFn({
      body: formData
    }), 'Custos de transportadora editados! 🎉')
  }

  async function promiseOptions(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getShippings({ currentPage: 1, pageSize: 5, tradingName: inputValue })

    return response.shippings.map(s => {
      return { value: s.id, label: captalize(s.trading_name) }
    })
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Editar Custos de Transportadoras </ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as='form'
        onSubmit={handleSubmit(handleBulkEditShippingCosts)}
      >

        <Stack
          direction="column"
          w="full"
          mt="3"
        >
          <FormControl>
            <FormLabel>
              Transportadora
            </FormLabel>
            <Controller
              control={control}
              name="shippingId"
              render={({ field }) => (
                <AsyncSelect
                  name={field.name}
                  onChange={(event) => field.onChange(event?.value ?? '')}
                  cacheOptions
                  defaultOptions
                  placeholder="Transportadora..."
                  loadOptions={promiseOptions}
                  isClearable={true}
                />
              )}
            />
          </FormControl>

          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >

            <FormControl isInvalid={!!errors?.csv}>
              <FormLabel fontSize="sm">
                Arquivo csv
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('csv')}
                name="csv"
                id="csv"
                type="file"
                hidden
                accept=".csv"

              />
            </FormControl>
            <Button
              as={FormLabel}
              htmlFor="csv"
              lineHeight="1"
              leftIcon={<FaFileImport />}
              size="sm"
              w="min"
              cursor="pointer"
              border={!!errors?.csv && '2px solid'}
              borderColor={!!errors?.csv && 'red.500'}
            >
              Anexar csv
            </Button>
            {attachmentCsvPreview && (
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td fontSize="xs" maxW="100px">{csvAttachment[0].name}</Td>

                    <Td fontSize="xs" isNumeric>

                      <IconButton
                        aria-label="Visualizar anexo"
                        as={Link}
                        size="sm"
                        icon={<FaExternalLinkAlt />}
                        href={URL.createObjectURL(csvAttachment[0])}
                        isExternal
                      />

                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            )}
          </Stack>
        </Stack>

        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
