import { Box, Flex } from "@chakra-ui/react";
import { VaccineStatus as VaccineStatusTypes } from "api/vaccines/vaccine";
import { vaccineStatusMap } from "utils/vaccineMappers";

interface VaccineStatusProps {
  status: VaccineStatusTypes;
}

export function VaccineStatus({ status }: VaccineStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {status === 'pending' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'on-time' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'inactive' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {vaccineStatusMap[status]}
    </Flex>
  )
}
