import { AddressesWithRequestedChangesStatus } from "api/addresses/addressRequestedChange";


export const addressesWithRequestedChangesStatusMap: Record<AddressesWithRequestedChangesStatus, string> = {
  pending: 'Pendente',
  approving: 'Aprovando',
  finished: 'Finalizado'
}

export const addressesRequestedChangesChangeStatusMap: Record<'approved' | 'reproved' | 'pending', string> = {
  pending: 'Pendente',
  approved: 'Aprovado',
  reproved: 'Reprovado'
}
