import { Box, Button, Flex, FormControl, FormLabel, Heading, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { sendCltDriverScalabriniDetails } from "api/cltDrivers/sendCltDriverScalabriniDetails";

interface SendCltDriverScalabriniRegisterProps {
  cltDriverId: string
}

export interface SendCltDriverScalabriniRegisterSchema {
  doesRegisteredInScalabriniSystem: string

}

const sendCltDriverScalabriniRegisterSchema = yup.object({
  doesRegisteredInScalabriniSystem: yup.string().required(),
})

export function SendCltDriverScalabriniRegister({ cltDriverId }: SendCltDriverScalabriniRegisterProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<SendCltDriverScalabriniRegisterSchema>({
    resolver: yupResolver(sendCltDriverScalabriniRegisterSchema)
  })

  const { mutateAsync: sendCltDriverScalabriniDetailsFn } = useMutation({
    mutationFn: sendCltDriverScalabriniDetails,
    onSuccess(_data, { body, cltDriverId }) {
      queryClient.invalidateQueries({ queryKey: 'clts-drivers' })
      queryClient.invalidateQueries({ queryKey: 'hiring-scalabrini-register-drivers-clt' })
      queryClient.invalidateQueries({ queryKey: 'hiring-pontomais-register-drivers-clt' })
      queryClient.invalidateQueries({ queryKey: ['clt-driver', cltDriverId] })
    }
  })


  async function handleSendCltDriverScalabriniRegister(values: SendCltDriverScalabriniRegisterSchema) {
    await promiseMessage(sendCltDriverScalabriniDetailsFn({
      body: {
        doesRegisteredInScalabriniSystem: values.doesRegisteredInScalabriniSystem === 'yes'
      }, cltDriverId
    }), 'Cadastro na Scalabrini enviado')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleSendCltDriverScalabriniRegister)}
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'>Contratação - Cadastro na Scalabrini</Heading>
      <FormControl isInvalid={!!errors.doesRegisteredInScalabriniSystem} mt={3}>
        <FormLabel>
          Cadastrado no sistema da Scalabrini ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="doesRegisteredInScalabriniSystem"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
