import axios from "axios"
import { useMutation } from "react-query"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"
import { CrmType } from "./dtos/Crm"

interface CreateCrmInputProps {
  type: CrmType
  meetingDate?: string
  meetingParticipants?: string
  meetingSummary?: string
  invoiceNumber?: number
  originalInvoiceExpirationDate?: string
  paymentForecastDate?: string
  rncDate?: string
  rncDescription?: string
  noteDate?: string
  noteDescription?: string
}


interface CreateCrmReqFunctionProps {
  customerId: string
  input: CreateCrmInputProps | FormData
}

async function createCrmReqFunction({
  customerId,
  input
}: CreateCrmReqFunctionProps) {
  try {
    await api.post(`/customer/${customerId}/crms`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

async function deleteCrmReqFunction(crmId: string) {
  try {
    await api.delete(`/crms/${crmId}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

export function useCrmFunctions() {

  const createCrm = useMutation('createCrm', createCrmReqFunction)
  const deleteCrm = useMutation('deleteCrm', deleteCrmReqFunction)


  return { createCrm, deleteCrm }
}
