import { usePagination } from "@ajna/pagination";
import { Box, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { getShippingsCostsReport } from "api/shippings/getShippingsCostsReport";
import { Pagination } from "components/Pagination/Pagination";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { ShippingsCostsReportFilters } from "./components/ShippingsCostsReportFilters";


const headers = [
  { label: 'TRANSPORTADORA', key: 'shipping' },
  { label: 'RASTREADOR', key: 'tracker' },
  { label: 'CTE', key: 'cte' },
  { label: 'ROTA', key: 'route' },
  { label: 'DATA DO EMBARQUE', key: 'realBoardDate' },
  { label: 'PESO TOTAL', key: 'totalTaxedWeight' },
  { label: 'CUSTO TOTAL', key: 'totalCteTransferCostInCents' },
  { label: 'CUSTO ESPERADO', key: 'expectedCostInCents' },
  { label: 'DESVIO', key: 'desviation' },
]


export function ShippingsCostsReport() {

  const { userLogged } = useAuth()
  const searchParams = useSearchParams()
  const history = useHistory()

  const startDate = searchParams.get('startDate')
  const finalDate = searchParams.get('finalDate')

  const userCanViewShippingsCostsReport = userLogged?.permissions.includes('view-shippings-costs-report')

  useEffect(() => {
    if (!userCanViewShippingsCostsReport) history.push('/')
  }, [history, userCanViewShippingsCostsReport])

  const {
    data: shippingsCostsReportResult,
    isLoading: isLoadingShippingCostsReportResult
  } = useQuery({
    queryKey: ['shippings-costs-report', startDate, finalDate],
    queryFn: () => getShippingsCostsReport({
      queryParams: {
        finalDate,
        startDate,

      }
    }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!startDate && !!finalDate
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
    offset,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
    pagesCount: shippingsCostsReportResult ? Math.ceil(shippingsCostsReportResult?.length / 10) : 0
  })

  const csvPropsData = shippingsCostsReportResult
    ? shippingsCostsReportResult?.map(shippingCost => {
      return {
        ...shippingCost,
        shipping: shippingCost?.shipping?.shipping,
        realBoardDate: format(new Date(shippingCost.realBoardDate), 'dd/MM/yyyy'),
        route: `${shippingCost?.sourceBranch?.nickname} - ${shippingCost?.destinationBranch?.nickname}`,
        totalCteTransferCostInCents: Number((shippingCost.totalCteTransferCostInCents / 100).toFixed(2)),
        expectedCostInCents: Number((shippingCost.expectedCostInCents / 100).toFixed(2)),
        desviation: Number(((shippingCost.expectedCostInCents / 100) - (shippingCost.totalCteTransferCostInCents / 100)).toFixed(2)),
        totalTaxedWeight: Number(shippingCost.totalTaxedWeight.toFixed(2))
      }
    })
    : []

  const csvProps = {
    data: csvPropsData,
    headers,
    filename: `relatório-custo-transportadora-${new Date().toISOString()}.csv`,
  }

  const handleChangePage = (page: number) => setCurrentPage(page)

  return (
    <Box
      p="6"
      bg="white"
      rounded="md"
    >
      <Heading letterSpacing="tight">Custo de transportadora</Heading>
      <ShippingsCostsReportFilters
        csvProps={csvProps}
        isLoading={isLoadingShippingCostsReportResult}
      />
      {shippingsCostsReportResult && (
        <TableContainer mt="6" rounded="md">
          <Table
            size="sm"
          >
            <Thead>
              <Tr
                h="40px"
                bg="#38c3fa"
              >
                <Th color="white">TRANSPORTADORA</Th>
                <Th color="white">RASTREADOR</Th>
                <Th color="white">CTE</Th>
                <Th color="white">ROTA</Th>
                <Th color="white">DATA DO EMBARQUE</Th>
                <Th color="white">PESO TOTAL</Th>
                <Th color="white">CUSTO TOTAL</Th>
                <Th color="white">CUSTO ESPERADO</Th>
                <Th color="white">DESVIO</Th>
              </Tr>
            </Thead>
            <Tbody>
              {shippingsCostsReportResult?.slice(offset, offset + 10).map((shippingCost, index) => {

                const desviation = ((shippingCost?.expectedCostInCents / 100) - (shippingCost?.totalCteTransferCostInCents / 100)).toFixed(2)

                return (
                  <Tr key={index}>
                    <Td>{shippingCost?.shipping?.shipping}</Td>
                    <Td>{shippingCost?.tracker}</Td>
                    <Td>{shippingCost?.cte}</Td>
                    <Td>{`${shippingCost?.sourceBranch?.nickname} - ${shippingCost?.destinationBranch?.nickname}`}</Td>
                    <Td>{format(new Date(shippingCost.realBoardDate), 'dd/MM/yyyy')}</Td>
                    <Td>{shippingCost?.totalTaxedWeight}</Td>
                    <Td>{(shippingCost?.totalCteTransferCostInCents / 100).toFixed(2)}</Td>
                    <Td>{(shippingCost?.expectedCostInCents / 100).toFixed(2)}</Td>
                    <Td>{desviation}</Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Pagination
        handlePageChange={handleChangePage}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </Box>
  )
}
