import { Flex, Box, Heading, Button, Icon } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { BranchTable } from '../../components/Tables/Branch/BranchTable'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useBranch } from '../../hooks/branch/useBranch'

export function BranchList() {
  const [isLoading, setIsLoading] = useState(true)

  const { userLogged } = useContext(AuthContext)

  const {
    branches: { data: branches, isLoading: isBranchLoading },
  } = useBranch(null, true, false)

  useEffect(() => {
    function run() {
      if (!isBranchLoading) {
        setIsLoading(false)
      }
    }
    run()
  }, [isBranchLoading])

  if (isLoading) {
    return <GeneralContentLoading />
  }

  const permissions = userLogged?.permissions

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontFamily="poppins">
              Lista de Bases de Transportadoras
            </Heading>
            {permissions !== undefined && (
              <Link to="/transportadoras/base/adicionar">
                {userLogged?.permissions.includes('add-branch') && (
                  <Button
                    colorScheme="gray"
                    rightIcon={<Icon as={FiPlus} />}
                    size="sm"
                  >
                    Adicionar Base de Transportadora
                  </Button>
                )}
              </Link>
            )}
          </Flex>
          {!!branches && <BranchTable branches={branches} />}
        </Box>
      </Flex>

  )
}
