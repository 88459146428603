import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface CreateCollectorDispatchStockProposalManuallyBody {
  proposalInputs: {
    inputId: string;
    materialName: string;
    sendQuantity: number;
    standardStock: number;
    currentStock: number;
    periodConsume: number;
    minStock: number;
  }[]
}

interface CreateCollectorDispatchStockProposalManuallyParams {
  collectorId: string
  body: CreateCollectorDispatchStockProposalManuallyBody
}

export async function createCollectorDispatchStockProposalManually({
  collectorId,
  body
}: CreateCollectorDispatchStockProposalManuallyParams) {
  try {
    await api.post(`/collectors/${collectorId}/proposals`, body)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
