import { Stack, Button, FormLabel, FormControl, Input, IconButton, Link, Progress, Table, Tbody, Td, Tr, Select, Text } from "@chakra-ui/react"
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation"
import { ChangeEvent, useEffect } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa"

interface MissWorkCltIntercurrenceFormSchema {
  attachment: FileList
  missWorkType: 'justified' | 'not-justified'
}

interface MissWorkCltIntercurrenceFormProps {
  onSetAttachmentId: (attachmentId: string) => void
}

export function MissWorkCltIntercurrenceForm({
  onSetAttachmentId
}: MissWorkCltIntercurrenceFormProps) {
  const { mutation: uploadAttachmentFn, uploadProggress } = useAttachmentMutation()

  async function handleUploadAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadAttachmentFn.mutateAsync(formData)
  }

  useEffect(() => {
    if (uploadAttachmentFn?.data) {
      onSetAttachmentId(uploadAttachmentFn?.data?.attachment?.id)
    }
  }, [uploadAttachmentFn, onSetAttachmentId])

  const {
    control,
    register,
    formState: {
      errors
    }
  } = useFormContext<MissWorkCltIntercurrenceFormSchema>()

  const missWorkType = useWatch({ control, name: 'missWorkType' })
  const attachment = useWatch({ control, name: 'attachment' })

  const isJustifiedMissWork = missWorkType === 'justified'

  return (
    <>
      <FormControl isInvalid={!!errors?.missWorkType}>
        <FormLabel>
          Falta justificada?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register('missWorkType')}
          placeholder="Selecione uma opção..."
        >
          <option value="justified">Justificada</option>
          <option value="not-justified">Não Justificada</option>
        </Select>
      </FormControl>

      {isJustifiedMissWork && (
        <Stack
          direction="column"
          w="full"
          mt="3"
        >
          <Button
            as={FormLabel}
            htmlFor="attachment"
            lineHeight="1"
            leftIcon={<FaFileImport />}
            size="sm"
            w="min"
            cursor="pointer"
            border={!!errors?.attachment && '2px solid'}
            borderColor={!!errors?.attachment && 'red.500'}
          >
            Anexar
          </Button>
          <FormControl isInvalid={!!errors?.attachment}>
            <Input
              {...register('attachment')}
              name="attachment"
              id="attachment"
              type="file"
              hidden
              onChangeCapture={handleUploadAttachment}
            />
          </FormControl>
        </Stack>
      )}
      {attachment && (
        <Table size="sm">
          <Tbody>
            {Object.entries(attachment).map(([key, file]) => {
              return (
                <Tr key={key}>
                  <Td fontSize="xs" maxW="100px">{file.name}</Td>
                  <Td fontSize="xs" w="200px">
                    <Progress size="sm" rounded="md" value={uploadProggress} />
                  </Td>
                  <Td fontSize="xs" isNumeric>
                    {uploadAttachmentFn.data && (
                      <IconButton
                        aria-label="Visualizar anexo"
                        as={Link}
                        size="sm"
                        icon={<FaExternalLinkAlt />}
                        href={uploadAttachmentFn.data.attachment.link}
                        isExternal
                      />
                    )}
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      )}
    </>
  )
}
