import { Box, Button, Flex, FormControl, FormLabel, Grid, GridItem, Heading,  Radio, RadioGroup, Stack, Text, Textarea } from "@chakra-ui/react";
import { getStandardization } from "api/standardizations/getStandardization";
import { validateStandardization } from "api/standardizations/validateStandardization";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { RequestError } from "utils/errors/RequestErrors";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { GetStandardizationsResponse } from "api/standardizations/getStandardizations";
import { useEffect } from "react";

interface ValidateStandardizationSchema {
  cnhStatus: 'approved' | 'reproved'
  crlvStatus: 'approved' | 'reproved'
  motorFreightCertificateStatus: 'approved' | 'reproved'
  cleaningRegisterStatus: 'approved' | 'reproved'
  vaccinesStatus: 'approved' | 'reproved'
  trainingsStatus: 'approved' | 'reproved'
  salaryAccountStatus: 'approved' | 'reproved'
  uniformAndBadgeStatus: 'approved' | 'reproved'
  stickersStatus: 'approved' | 'reproved'
  reflectiveStripStatus: 'approved' | 'reproved'
  redPlateStatus: 'approved' | 'reproved'
  vehicleVisualCleaningStatus: 'approved' | 'reproved'
  vehicleEpiKitStatus: 'approved' | 'reproved'
  tiresConditionStatus: 'approved' | 'reproved'
  approvationObservations: string
  status: 'approved' | 'reproved'
}

const validateStandardizationOptions: Record<'key' | 'label', keyof ValidateStandardizationSchema | string>[] = [
  { key: 'cnhStatus', label: 'CNH' },
  { key: 'crlvStatus', label: 'CRLV' },
  { key: 'motorFreightCertificateStatus', label: 'Certificado Motofrete' },
  { key: 'cleaningRegisterStatus', label: 'Registro de limpeza' },
  { key: 'vaccinesStatus', label: 'Vacinas' },
  { key: 'trainingsStatus', label: 'Treinamentos' },
  { key: 'salaryAccountStatus', label: 'Conta salário' },
  { key: 'uniformAndBadgeStatus', label: 'Uniforme e crachá' },
  { key: 'stickersStatus', label: 'Adesivos' },
  { key: 'reflectiveStripStatus', label: 'Faixa refletiva' },
  { key: 'redPlateStatus', label: 'Placa vermelha' },
  { key: 'vehicleVisualCleaningStatus', label: 'Veículo' },
  { key: 'vehicleEpiKitStatus', label: 'KIT EPI no veículo' },
  { key: 'tiresConditionStatus', label: 'Condição dos pneus' },
]

const validateStandardizationSchema = yup.object({
  cnhStatus: yup.string().required(),
  crlvStatus: yup.string().required(),
  motorFreightCertificateStatus: yup.string().required(),
  cleaningRegisterStatus: yup.string().required(),
  vaccinesStatus: yup.string().required(),
  trainingsStatus: yup.string().required(),
  salaryAccountStatus: yup.string().required(),
  uniformAndBadgeStatus: yup.string().required(),
  stickersStatus: yup.string().required(),
  reflectiveStripStatus: yup.string().required(),
  redPlateStatus: yup.string().required(),
  vehicleVisualCleaningStatus: yup.string().required(),
  vehicleEpiKitStatus: yup.string().required(),
  tiresConditionStatus: yup.string().required(),
  approvationObservations: yup.string().required(),
  status: yup.string().required(),
})


interface ValidateStandardizationProps {
  standardizationId: string
}

export function ValidateStandardization({
  standardizationId
}: ValidateStandardizationProps) {

  const { data: standardizationResult } = useQuery({
    queryKey: ['standardization', standardizationId],
    queryFn: () => getStandardization({
      standardizationId
    })
  })

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<ValidateStandardizationSchema>({
    resolver: yupResolver(validateStandardizationSchema),
    defaultValues: {
      cnhStatus: 'approved'
    }
  })

  useEffect(() => {
    if (standardizationResult) {
      const { standardization } = standardizationResult

      setValue('cnhStatus', standardization.cnh_status)
      setValue('crlvStatus', standardization.crlv_status)
      setValue('motorFreightCertificateStatus', standardization.motor_freight_certificate_status)
      setValue('cleaningRegisterStatus', standardization.cleaning_register_status)
      setValue('vaccinesStatus', standardization.vaccines_status)
      setValue('trainingsStatus', standardization.trainings_status)
      setValue('salaryAccountStatus', standardization.salary_account_status)
      setValue('uniformAndBadgeStatus', standardization.uniform_and_badge_status)
      setValue('stickersStatus', standardization.stickers_status)
      setValue('reflectiveStripStatus', standardization.reflective_strip_status)
      setValue('redPlateStatus', standardization.red_plate_status)
      setValue('vehicleVisualCleaningStatus', standardization.vehicle_visual_cleaning_status)
      setValue('vehicleEpiKitStatus', standardization.vehicle_epi_kit_status)
      setValue('tiresConditionStatus', standardization.tires_condition_status)
      setValue('approvationObservations', standardization.approvation_observations)
      setValue('status', standardization.status as 'approved' | 'reproved')

    }
  }, [standardizationResult, setValue])

  const queryClient = useQueryClient()

  const { mutateAsync: validateStandardizationFn } = useMutation({
    mutationFn: validateStandardization,
    onSuccess: (_data, { body: { status } }) => {
      queryClient.invalidateQueries({ queryKey:'pending-standardizations' })
      queryClient.invalidateQueries({ queryKey: 'approved-standardizations' })
      queryClient.invalidateQueries({ queryKey: 'reproved-standardizations' })
      queryClient.invalidateQueries({ queryKey: ['standardization', standardizationId] })
      const cached = queryClient.getQueriesData<GetStandardizationsResponse>({
        queryKey: ['standardizations']
      })

      cached.forEach(([cachedKey, cachedData]) => {
        if (!cachedData) return

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          standardizations: cachedData.standardizations.map((standardization) => {
            if (standardization.id === standardizationId) {
              return { ...standardization, status }
            }

            return standardization
          })
        })
      })
    },
    onError: (error: RequestError) => {
      toast.error(error.message)
    }
  })

  async function handleValidateStandardization(values: ValidateStandardizationSchema) {
    await validateStandardizationFn({
      body: values,
      params: {
        standardizationId,
      }
    })
  }

  return (
    <Box
      w="full"
      maxH='550px'
      overflowY='scroll'
    >
      <Heading letterSpacing="tight" size='sm'> Validar padronização</Heading>
      <Flex
        direction="column"
        gap="6"
        pb="6"
        as="form"
        onSubmit={handleSubmit(handleValidateStandardization)}
      >
        <Grid
          w="full"
          mt="6"
          gap="6"
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        >
          {validateStandardizationOptions.map((option) => {
            return (
              <GridItem key={option.key}>
                <FormControl isInvalid={!!errors[option.key]}>
                  <Stack>
                    <FormLabel fontSize="sm">
                      {option.label}
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>
                    <Controller
                      control={control}
                      name={option.key as keyof ValidateStandardizationSchema}
                      render={({ field }) => {
                        return (
                          <RadioGroup
                            size="sm"
                            name={field.name}
                            onChange={field.onChange}
                            value={field.value}
                          >
                            <Stack w="full" direction="row" spacing="8">
                              <Radio w="50%" value="approved">Aprovado</Radio>
                              <Radio w="50%" value="reproved">Reprovado</Radio>
                            </Stack>
                          </RadioGroup>
                        )
                      }}
                    />
                  </Stack>
                </FormControl>
              </GridItem>
            )
          })}
        </Grid>

        <Stack>
          <FormControl isInvalid={!!errors.approvationObservations}>
            <FormLabel fontSize="sm">
              Observações de aprovação
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Textarea
              {...register('approvationObservations')}
            />
          </FormControl>
        </Stack>


        <Stack>
          <FormControl isInvalid={!!errors.status}>
            <FormLabel fontSize="sm">
              Status geral
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              control={control}
              name="status"
              render={({ field }) => {
                return (
                  <RadioGroup
                    size="sm"
                    name={field.name}
                    onChange={field.onChange}
                  >
                    <Stack w="full" direction="row" spacing="8">
                      <Radio w="50%" value="approved">Aprovado</Radio>
                      <Radio w="50%" value="reproved">Reprovado</Radio>
                    </Stack>
                  </RadioGroup>
                )
              }}
            />
          </FormControl>
        </Stack>

        <Button
          size="sm"
          colorScheme="blue"
          type="submit"
          disabled={isSubmitting}
          isLoading={isSubmitting}
          w="min"
          alignSelf="end"
        >
          Validar
        </Button>
      </Flex>
    </Box>

  )
}
