import { Button, Flex, Spinner } from "@chakra-ui/react";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { useEmployeeCostsFunctions } from "hooks/employeeCosts/useEmployeeCostsFunctions";
import { useRegions } from "hooks/regions/useRegions";
import { useToastify } from "hooks/toastify/useToastify";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { EmployeeCostForm, EmployeeCostFormInputs } from "./components/EmployeeCostForm";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";

const createEmployeeCostFormSchema = yup.object().shape({
  remuneration: yup.string().required('Campo obrigatório'),
  fapAliquot: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  thirdyPartyEmployerInss: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  satEmployerInss: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  fgts: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  vacation: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  oneThirdOfVacation: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  employerInssVacation: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  vacationFgts: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  thirteenthSalary: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  fgtsThirteenthSalary: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  employerInsuranceThirteenthSalary: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  costForecastWithIndemnifiedPriorNotice: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  fgtsPriorNotice: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  fgtsFineUponDismissalWithoutJustCause: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  transportationVoucher: yup.string().required('Campo obrigatório'),
  foodVoucher: yup.string().required('Campo obrigatório'),
  mealTicket: yup.string().required('Campo obrigatório'),
  uniform: yup.string().required('Campo obrigatório'),
  healthInsurance: yup.string().required('Campo obrigatório'),
  dentalPlan: yup.string().required('Campo obrigatório'),
  lifeInsurance: yup.string().required('Campo obrigatório'),
  monetaryAllowance: yup.string().required('Campo obrigatório'),
  motorbykeCarRental: yup.string().required('Campo obrigatório'),
  workMaterials: yup.string().required('Campo obrigatório'),
  fuel: yup.string().required('Campo obrigatório'),
  cellphone: yup.string().required('Campo obrigatório'),
  tracker: yup.string().required('Campo obrigatório'),
  homeWork: yup.string().required('Campo obrigatório'),
  backup: yup.string().required('Campo obrigatório'),
  fairTrader: yup.string().required('Campo obrigatório'),
  regionId: yup.string().required('Campo obrigatório'),
  vehicle: yup.string().required('Campo obrigatório'),
});


export function CreateEmployeeCost() {

  const { push: redirect } = useHistory()

  const formMethods = useForm<EmployeeCostFormInputs>({
    resolver: yupResolver(createEmployeeCostFormSchema)
  })

  const {
    data: regionsData,
    isFetching: isFetchingRegionsData,
  } = useRegions()

  const regionsOptions = regionsData?.regions.map((region) => {
    return {
      key: region.id,
      value: region.id,
      showOption: `${region.name} (${region.acronym})`
    }
  })

  const { createEmployeeCost } = useEmployeeCostsFunctions()

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  async function handleCreateEmployeeCost(values: EmployeeCostFormInputs) {
    await promiseMessage(
      createEmployeeCost.mutateAsync(values, {
        onSuccess: async () => {
          await queryClient.invalidateQueries('employeeCosts')

          redirect('/empregados/custos')
        }
      }),
      'Custo de empregado criado com sucesso!'
    )
  }

  return (
    <StandardBackgroundForm
      onSubmit={formMethods.handleSubmit(handleCreateEmployeeCost)}
      title="Criar custo de empregado"
    >

      {isFetchingRegionsData ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <EmployeeCostForm regionsOptions={regionsOptions} />
        </FormProvider>
      )}

      <Flex
        w="full"
        align="center"
        justify="flex-end"
        mt="4"
        gap="2"
      >
        <Button
          type="submit"
          colorScheme="blue"
        >
          Salvar
        </Button>

        <Button
          as={Link}
          type="submit"
          to="/empregados/custos"
        >
          Custos de empregados
        </Button>

      </Flex>


    </StandardBackgroundForm>
  )
}
