import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Button,
  Stack,
  Text,
  Icon,
  useDisclosure,
  VStack,
  IconButton,
  Alert,
  AlertIcon,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import { ServiceProps } from '../../../contexts/ServiceContext'
import { DeliveryServiceProps } from '../../../contexts/DeliveryServiceContext'


import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { Input } from '../../../components/Inputs/Input'

import { useService } from '../../../hooks/services/service'
import { useAuth } from '../../../hooks/auth/useAuth'
import { FaUserEdit } from 'react-icons/fa'
import { GenerateLabelModal } from '../../../components/Modal/Service/Label/GenerateLabelModal'
import { useAddresses } from '../../../hooks/address/useAddresses'
import { Address } from 'hooks/address/dtos/Address'
import { ExternalComunications } from 'components/ExternalComunications'
import { AssignDriversStep } from './AssignDriversStep'
import { FaAddressBook, FaArrowLeft, FaFileArrowDown } from 'react-icons/fa6'

interface IQueryParams {
  service_id: string
  address_id: string
}

interface IDeliveryServiceAddressesProps {
  slug: string
  isDisabled?: boolean
}

export function DeliveryServiceAddresses({
  slug,
  isDisabled = false,
}: IDeliveryServiceAddressesProps) {
  const [isLoading, setIsLoading] = useState(true)

  const [sourceModalAddresses, setSourceModalAddresses] = useState<
    Address[]
  >([])
  const [destinationModalAddresses, setDestinationModalAddresses] = useState<
    Address[]
  >([])

  const [serviceFilteredById, setServiceFilteredById] = useState<
    ServiceProps[]
  >([])
  const [deliveryServiceFilteredById, setDeliveryServiceFilteredById] =
    useState<DeliveryServiceProps[]>([])

  const { userLogged } = useAuth()

  const { service_id }: IQueryParams = useParams()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const history = useHistory()

  const location = useLocation()
  const state = location.state as { path?: string }

  const {
    service: { data: serviceById, isLoading: isServiceLoading },
  } = useService(service_id, false, false)
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()

  useEffect(() => {
    if (serviceById && addresses) {
      const sourceAddressesToModal = addresses.filter((address) =>
        serviceById.serviceIDRequested.source_address_id.includes(address.id),
      )
      const destinationAddressesToModal = addresses.filter((address) =>
        serviceById.serviceIDRequested.destination_address_id.includes(
          address.id,
        ),
      )

      setSourceModalAddresses(sourceAddressesToModal)
      setDestinationModalAddresses(destinationAddressesToModal)
    }
  }, [serviceById, addresses])

  useEffect(() => {
    function run() {
      if (serviceById !== undefined) {
        const serviceFiltered = []
        serviceFiltered.push(serviceById)
        setServiceFilteredById(serviceFiltered)

        if (serviceById.serviceIDDelivery !== null) {
          setDeliveryServiceFilteredById(serviceById.serviceIDDelivery)
        }
      }
    }
    run()
  }, [serviceById])

  useEffect(() => {
    function run() {
      if (!isAddressesLoading && !isServiceLoading) {
        setIsLoading(false)
      }
    }
    run()
  }, [isAddressesLoading, isServiceLoading])

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (!permissions?.includes('add-delivery-service')) {
            history.push('/')
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [slug, history, userLogged])

  const isDriverLogged = userLogged?.user_type === 'MOTORISTA'

  const deliveredAddressesIds = serviceById?.serviceIDDelivery?.filter(
    (delivery) => ['DONE', 'VALIDATING'].includes(delivery.step)
  )?.map(delivery => delivery.address_id)

  const destinationAddresses = addresses?.filter(
    (address) => serviceById?.serviceIDRequested?.destination_address_id.includes(address.id)
  )?.filter((address) => {
    const availableDeliveryFilter = !deliveredAddressesIds.includes(address.id)
    const driverFilter = serviceById?.serviceIDRequested?.destinationCollectorIDService.has_driver_assign && isDriverLogged
      ? serviceById?.deliveryDriversAssigned.reduce((assignedAddresses, curr) => {
        if (curr.address_id === address.id && curr.driver_id === userLogged?.driver_id) {
          assignedAddresses.push(curr.address_id)
        }

        return assignedAddresses
      }, []).includes(address.id)
      : true

    return availableDeliveryFilter && driverFilter
  })

  useEffect(() => {
    async function run() {
      const serviceStep = String(
        serviceFilteredById.map((service) => service.step),
      )
      const lengthDestinationAddress = serviceFilteredById.flatMap(
        (service) => service.serviceIDRequested.destination_address_id,
      ).length
      const stepsOfDeliveryService = deliveryServiceFilteredById.map(
        (delService) => delService.step,
      )

      if (serviceStep !== 'toDeliveryService') {
        if (lengthDestinationAddress !== 0) {
          if (deliveryServiceFilteredById.length === lengthDestinationAddress) {
            if (!stepsOfDeliveryService.includes('GOING')) {
              if (stepsOfDeliveryService.includes('DONE') || stepsOfDeliveryService.includes('VALIDATING')) {
                setIsLoading(true)
                history.push({
                  pathname: '/servicos/entregas',
                  state: {
                    path: state?.path !== undefined ? state?.path : null
                  }
                })
              }
            }
          }
        }
      }
    }
    run()
  }, [serviceFilteredById, deliveryServiceFilteredById, history, state?.path])

  const {
    isOpen: isExternalComunicationModalOpen,
    onOpen: onOpenExternalComunicationModal,
    onClose: onCloseExternalComunicationModal,
  } = useDisclosure()

  const [isEditDriversAssigned, setIsEditDriversAssigned] = useState(false)

  const handleSetIsEditDriversAssigned = () => setIsEditDriversAssigned(!isEditDriversAssigned)

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (
    <>
      <ExternalComunications.TriggerButton
        aria-label="help-me"
        onClick={onOpenExternalComunicationModal}
      />

      <ExternalComunications.Root
        isOpen={isExternalComunicationModalOpen}
        onClose={onCloseExternalComunicationModal}
      >
        <ExternalComunications.Content
          serviceId={service_id}
          onClose={onCloseExternalComunicationModal}
        />
      </ExternalComunications.Root>

      <GenerateLabelModal
        isModalOpen={isOpen}
        onClose={onClose}
        sourceAddresses={sourceModalAddresses}
        destinationAddresses={destinationModalAddresses}
        serviceProtocol={serviceById?.protocol!}
      />
      <Flex>
        <Box
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
        >
          <Flex
            align="center"
            justify="space-between"
          >
            <HStack
              spacing={3}
            >
              <IconButton
                aria-label=""
                icon={<FaArrowLeft />}
                as={Link}
                to="/servicos/entregas"
                size="sm"
                variant="ghost"
              />
              <Heading letterSpacing="tight">
                Entregas
              </Heading>
            </HStack>

            <Flex gap={3} align="center">
              {(serviceById?.serviceIDRequested?.destinationCollectorIDService.has_driver_assign && !isDriverLogged) && (
                <VStack
                  onClick={handleSetIsEditDriversAssigned}
                  spacing={1}
                  cursor="pointer"
                  _hover={{
                    transform: 'translateY(-5px)'
                  }}
                  transition="ease-in-out 0.2s"
                >
                  <Icon
                    as={!isEditDriversAssigned ? FaUserEdit : FaAddressBook}
                    color="blue.500"
                    fontSize="lg"
                  />
                  <Text fontSize="xs">
                    {isEditDriversAssigned ? 'Endereços' : 'Motoristas'}
                  </Text>
                </VStack>
              )}
              <VStack
                onClick={onOpen}
                spacing={1}
                cursor="pointer"
                _hover={{
                  transform: 'translateY(-5px)'
                }}
                transition="ease-in-out 0.2s"
              >
                <Icon
                  as={FaFileArrowDown}
                  color="blue.500"
                  fontSize="lg"
                />
                <Text fontSize="xs">
                  Etiqueta
                </Text>
              </VStack>
            </Flex>
          </Flex>

          <Divider my="6" borderColor="gray.700" />

          {isEditDriversAssigned ? (
            <AssignDriversStep
              serviceId={serviceById?.id}
              destinationCollectorId={serviceById?.serviceIDRequested.destination_collector_id}
            />
          ) : (
            destinationAddresses.length === 0 ? (
              <Alert status='info' rounded="md">
                <AlertIcon />
                Todos os endereços do serviço foram entregues, ou você não tem mais entregas atribuídas a você.
              </Alert>
            ) : (
              <>
                <Text
                  fontWeight="semibold"
                  fontSize="2xl"
                  letterSpacing="tight"
                >
                  Endereços
                </Text>
                {destinationAddresses.map((address) => (
                  <Stack
                    key={address.id}
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'column']}
                  >
                    <Input
                      defaultValue={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep} | ${address.responsible_name} | ${address.complement}`.toUpperCase()}
                      isDisabled={isDisabled}
                      name="firstname"
                      label="Endereço Destinatário"
                    />

                    <Link
                      to={{
                        pathname: `/servicos/entregas/${service_id}/enderecos/${address.id}`,
                        state: {
                          path: state?.path !== undefined ? state?.path : null
                        }
                      }}
                    >
                      <Button type="button" colorScheme="blue" w="100%">
                        Entregar
                      </Button>
                    </Link>
                    <Divider my="6" borderColor="gray.700" />
                  </Stack>
                ))}
              </>
            )
          )}
        </Box>
      </Flex>
    </>
  )
}
