import { useMutation, useQueryClient } from 'react-query'
import {
  ConsultPriceInputProps,
  consultPriceReqFunction,
  CreatePriceInputProps,
  createPriceReqFunction,
  deletePriceReqFunction,
  editPriceReqFunction,
  EditPriceReqProps,
  PriceSendFileInputProps,
  sendPriceFileReqFunction,
} from '../../utils/RequestFunctions/Price/requestPriceFunctions'
import { useSwal } from '../swal/useSwal'

export const usePriceFunctions = () => {
  const queryClient = useQueryClient()
  const { successMessage, errorMessage } = useSwal()

  const createPrice = useMutation(
    'createPrice',
    (input: CreatePriceInputProps) => createPriceReqFunction(input),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['prices'])
        await queryClient.invalidateQueries(['price'])
        await successMessage('Preço criado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const editPrice = useMutation(
    'editPrice',
    ({ input, id }: EditPriceReqProps) => editPriceReqFunction({ input, id }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['prices'])
        await queryClient.invalidateQueries(['price'])
        await successMessage('Preço editado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const sendPriceFile = useMutation(
    'sendPriceFile',
    (input: PriceSendFileInputProps) => sendPriceFileReqFunction(input),
    {
      onSuccess: () => {
        successMessage('Arquivo enviado com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  const deletePrice = useMutation(
    'deletePrice',
    (id: string) => deletePriceReqFunction(id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['prices'])
        await queryClient.invalidateQueries(['price'])
        await successMessage('Preço excluído com sucesso!')
      },

      onError: async (error: any) => {
        await errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const consultPrice = useMutation(
    'consultPrice',
    (input: ConsultPriceInputProps) => consultPriceReqFunction(input),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['prices'])
        await queryClient.invalidateQueries(['price'])
        await successMessage('Preço(s) consultado(s) com sucesso!')
      },

      onError: async (error: any) => {
        await errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  return {
    createPrice,
    editPrice,
    deletePrice,
    consultPrice,
    sendPriceFile
  }
}
