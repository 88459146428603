const internComunicationStatusDict = (status: string) => {
  switch (status) {
    case 'WAITING-FINANCIAL-VALIDATION':
      return 'AGUARDANDO VALIDAÇÃO DO FINANCEIRO'
    case 'WAITING-MONITORING-VALIDATION':
      return 'AGUARDANDO VALIDAÇÃO DO MONITORAMENTO'
    case 'WAITING-LAST-MILE-VALIDATION':
      return 'AGUARDANDO VALIDAÇÃO DO LAST MILE'
    case 'WAITING-COMMERCIAL-VALIDATION':
      return 'AGUARDANDO VALIDAÇÃO DO COMERCIAL'
    case 'WAITING-RESPONSIBLE-ANSWER':
      return 'AGUARDANDO RESPOSTA DO RESPONSÁVEL'
    case 'WAITING-OWNER-ANSWER':
      return 'AGUARDADO VALIDAÇÃO DO SOLICITANTE'
    case 'DONE':
      return 'CONCLUÍDA'
    default:
      return '-'
  }
}

export { internComunicationStatusDict }
