import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import { useState, useEffect, useContext } from 'react'
import { Pagination } from '../../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../../utils/searchBoxFilter'
import {
  ReportContext,
  RncReportProps,
} from '../../../../contexts/ReportContext'
import { ReportLoading } from '../../../Loading/ReportLoading'
import { formatDateToFrontTable } from '../../../../utils/GeneralFunctions/DateFunctions/formatDateToFrontTable'

export function RncReportTable() {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [isLoading, setIsLoading] = useState(true)

  const [searchedRncReport, setSearchedRncReport] = useState<RncReportProps[]>(
    [],
  )
  const [slicedRncReports, setSlicedRncReport] = useState<RncReportProps[]>([])

  const [protocolFiltered, setProtocolFiltered] = useState('')
  const [directionFiltered, setDirectionFiltered] = useState('')
  const [
    intercurrenceResponsibleFiltered,
    setIntercurrenceResponsibleFiltered,
  ] = useState('')
  const [openedByFiltered, setOpenedByFiltered] = useState('')
  const [dateFiltered, setDateFiltered] = useState('')

  const { rncReport, isRncReportLoaded } = useContext(ReportContext)

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedRncReport?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function run() {
      if (isRncReportLoaded) {
        setIsLoading(false)
      }
    }
    run()
  }, [isRncReportLoaded])

  useEffect(() => {
    function run() {
      setSlicedRncReport(rncReport)
    }
    run()
  }, [itemLimit, currentPage, offset, rncReport])

  useEffect(() => {
    function run() {
      const newSlicedBillingReport = slicedRncReports?.filter((rncReport) => {
        const protocolFilter = searchBoxFilter(
          String(rncReport.protocol),
          protocolFiltered,
        )
        const directionFilter = searchBoxFilter(
          rncReport.direction,
          directionFiltered,
        )
        const intercurrenceResponsibleFilter = searchBoxFilter(
          rncReport.intercurrence_responsible,
          intercurrenceResponsibleFiltered,
        )
        const openedByFilter = searchBoxFilter(
          rncReport.opened_by,
          openedByFiltered,
        )
        const dateFilter = searchBoxFilter(
          formatDateToFrontTable(rncReport.date),
          dateFiltered,
        )

        if (
          directionFiltered === '' &&
          protocolFiltered === '' &&
          intercurrenceResponsibleFiltered === '' &&
          openedByFiltered === '' &&
          dateFiltered === ''
        )
          return rncReport

        return (
          protocolFilter &&
          directionFilter &&
          intercurrenceResponsibleFilter &&
          openedByFilter &&
          dateFilter
        )
      })

      setSearchedRncReport(newSlicedBillingReport)
    }
    run()
  }, [
    protocolFiltered,
    directionFiltered,
    intercurrenceResponsibleFiltered,
    openedByFiltered,
    dateFiltered,
    slicedRncReports,
  ])

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  if (isLoading) {
    return <ReportLoading />
  }

  return (
    <>
      {slicedRncReports.length <= 0 ? (
        <Alert
          status="info"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Oops!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Não há dados para mostrar aqui! Tente ajustar os filtros e tente
            novamente 😀!
          </AlertDescription>
        </Alert>
      ) : (
        <>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Protocolo..."
                        handleSearch={(e) =>
                          setProtocolFiltered(e.target.value)
                        }
                      />
                      <Text>PROTOCOLO</Text>
                    </Stack>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Diretoria..."
                        handleSearch={(e) =>
                          setDirectionFiltered(e.target.value)
                        }
                      />
                      <Text>DIRETORIA</Text>
                    </Stack>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Responsável pela Intercorrência..."
                        handleSearch={(e) =>
                          setIntercurrenceResponsibleFiltered(e.target.value)
                        }
                      />
                      <Text>RESPONSÁVEL</Text>
                    </Stack>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Aberto Por..."
                        handleSearch={(e) =>
                          setOpenedByFiltered(e.target.value)
                        }
                      />
                      <Text>ABERTO POR</Text>
                    </Stack>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Data..."
                        handleSearch={(e) => setDateFiltered(e.target.value)}
                      />
                      <Text>DATA</Text>
                    </Stack>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchedRncReport
                  .slice(offset, offset + Number(itemLimit))
                  .map((slaReport, index) => {
                    return (
                      <Tr key={index}>
                        <Td>{slaReport.protocol}</Td>
                        <Td>{slaReport.direction.toUpperCase()}</Td>
                        <Td>
                          {slaReport.intercurrence_responsible.toUpperCase()}
                        </Td>
                        <Td>{slaReport.opened_by.toUpperCase()}</Td>
                        <Td>{formatDateToFrontTable(slaReport.date)}</Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </Box>
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}
