import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye, FiTrash2 } from 'react-icons/fi'
import { useContext, useState, useEffect, Fragment } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import swal from 'sweetalert'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import { PermissionContext } from '../../../contexts/PermissionContext'
import { permissions_list } from '../../../utils/customLists'
import { useAuth } from '../../../hooks/auth/useAuth'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { TableFilterButton } from '../../Filters/TableFilterButton'

interface PermissionTableProps {
  permissions: PermissionProps[]
  refetch: () => void
}

interface PermissionProps {
  id: string
  group: string
  order: number
  key: string
  value: string
}

const permissionOptions = [
  { key: 'permission', value: 'Permissão', checked: false },
  { key: 'code', value: 'Código', checked: false },
  { key: 'group', value: 'Grupo', checked: false },
]

const permissionOrderOptions = [{ key: 'permission', value: 'Permissão' }]

export function PermissionTable({
  permissions,
  refetch,
}: PermissionTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [searchedPermissions, setSearchedPermissions] = useState<
    PermissionProps[]
  >([])
  const [slicedPermissions, setSlicedPermissions] = useState<PermissionProps[]>(
    [],
  )

  const [permissionFiltered, setPermissionFiltered] = useState('')
  const [codeFiltered, setCodeFiltered] = useState('')
  const [groupFiltered, setGroupFiltered] = useState('')

  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { deletePermission } = useContext(PermissionContext)
  const { userLogged } = useAuth()

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedPermissions?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(permissionOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(permissionOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      setSlicedPermissions(permissions)
    }
    run()
  }, [itemLimit, currentPage, offset, permissions])

  useEffect(() => {
    function run() {
      const newSlicedPermissions = slicedPermissions?.filter((permissions) => {
        const permissionFilter = searchBoxFilter(
          permissions.key,
          permissionFiltered,
        )
        const codeFilter = searchBoxFilter(permissions.value, codeFiltered)
        const groupFilter = searchBoxFilter(
          permissions_list
            .filter(
              (permissionList) => permissionList.value === permissions.group,
            )
            .map((permissionList) => permissionList.key)
            .toString(),
          groupFiltered,
        )

        if (
          permissionFiltered === '' &&
          codeFiltered === '' &&
          groupFiltered === ''
        )
          return permissions

        return permissionFilter && codeFilter && groupFilter
      })

      if (orderOptionSelected.length > 0) {
        const slicedPermissionsByOrder = newSlicedPermissions.sort(
          (a, b) =>
            (orderOptionSelected.includes('permission') &&
              a.key.localeCompare(b.key)) ||
            0,
        )

        setSearchedPermissions(slicedPermissionsByOrder)
      } else {
        setSearchedPermissions(newSlicedPermissions)
      }
    }
    run()
  }, [
    permissionFiltered,
    codeFiltered,
    groupFiltered,
    slicedPermissions,
    orderOptionSelected,
  ])

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  async function handleDeletePermission(permissionId: string) {
    await swal({
      title: 'Deseja excluir uma permissão?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then((willEdit) => {
      if (willEdit) {
        deletePermission(permissionId).then((res) => {
          if (res) {
            refetch()
          }
        })
      } else {
        swal('Ação cancelada com êxito!')
      }
    })
  }

  return (
    <>
      {slicedPermissions.length <= 0 ? (
        <Alert
          status="info"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Oops!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Não há dados para mostrar aqui!
          </AlertDescription>
        </Alert>
      ) : (
        <>
          <TableFilterButton />
          <Stack mt="4" direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Fragment key={option.key}>
                  <Flex>
                    {option.key === 'permission' && (
                      <SearchBox
                        placeholder="Buscar Permissão..."
                        handleSearch={(e) =>
                          setPermissionFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'code' && (
                      <SearchBox
                        placeholder="Buscar Código..."
                        handleSearch={(e) => setCodeFiltered(e.target.value)}
                      />
                    )}
                    {option.key === 'group' && (
                      <SearchBox
                        placeholder="Buscar Grupo..."
                        handleSearch={(e) => setGroupFiltered(e.target.value)}
                      />
                    )}
                  </Flex>
                </Fragment>
              ))}
          </Stack>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  {permissionOptions.map((option) => (
                    <Th key={option.key} fontSize="12" color="blue.900">
                      {option.value.toUpperCase()}
                    </Th>
                  ))}
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchedPermissions
                  .slice(offset, offset + Number(itemLimit))
                  .map((permission, index) => (
                    <Tr key={index}>
                      <Td fontSize="12">{permission.key.toUpperCase()}</Td>
                      <Td fontSize="12">{permission.value.toUpperCase()}</Td>
                      <Td fontSize="12">
                        {permissions_list
                          .filter(
                            (permissionList) =>
                              permissionList.value === permission.group,
                          )
                          .map((permissionList) => permissionList.key)
                          .toString()}
                      </Td>
                      <Td isNumeric>
                        {userLogged !== null && (
                          <>
                            {userLogged.permissions.includes(
                              'view-permission',
                            ) && (
                              <Link
                                to={`/permissao/visualizar/${permission.id}`}
                              >
                                <Icon
                                  cursor="pointer"
                                  as={FiEye}
                                  fontSize="20"
                                />
                              </Link>
                            )}
                            {userLogged.permissions.includes(
                              'edit-permission',
                            ) && (
                              <Link to={`/permissao/editar/${permission.id}`}>
                                <Icon
                                  cursor="pointer"
                                  as={FiEdit3}
                                  fontSize="20"
                                  ml="4"
                                  mt={['2', '2', '0']}
                                />
                              </Link>
                            )}
                            {userLogged.permissions.includes(
                              'remove-permission',
                            ) && (
                              <Icon
                                cursor="pointer"
                                as={FiTrash2}
                                fontSize="20"
                                ml="4"
                                mt={['2', '2', '0']}
                                onClick={() =>
                                  handleDeletePermission(permission.id)
                                }
                              />
                            )}
                          </>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Box>
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}
