import { Td, Tr } from "@chakra-ui/react";
import { GetAuditResponse } from "api/audits/getAudit";

interface AuditRhTableRowProps {
  audit: GetAuditResponse
}

export function AuditRhTableRow({
  audit
}: AuditRhTableRowProps) {
  return (
    <>
      <Tr>
        <Td>Registro de treinamento POP do cliente?</Td>
        <Td isNumeric>{audit?.audit?.humanResourcesAudit?.isCustomerPopTrainingRegistered ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Registro de treinamento limpeza e desinfecção?</Td>
        <Td isNumeric>{audit?.audit?.humanResourcesAudit?.isCleaningDesinfectionTrainingRegistered ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Registro de treinamento de biossegurança?</Td>
        <Td isNumeric>{audit?.audit?.humanResourcesAudit?.isBiosecurityTrainingRegistered ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Registro de limpeza do mês anterior?</Td>
        <Td isNumeric>{audit?.audit?.humanResourcesAudit?.isLastMonthCleaningRegistered ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Cartão de vacina?</Td>
        <Td isNumeric>{audit?.audit?.humanResourcesAudit?.isWithVaccineCard ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>CNH com EAR?</Td>
        <Td isNumeric>{audit?.audit?.humanResourcesAudit?.isWithEarCnh ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Curso de motofrete/ANTT?</Td>
        <Td isNumeric>{audit?.audit?.humanResourcesAudit?.isWithMotorcycleFreightAnttCourse ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>CRLV categoria aluguel?</Td>
        <Td isNumeric>{audit?.audit?.humanResourcesAudit?.isWithCrlvLocationCategory ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Aso?</Td>
        <Td isNumeric>{audit?.audit?.humanResourcesAudit?.isWithAso ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>KIT EPI entregue?</Td>
        <Td isNumeric>{audit?.audit?.humanResourcesAudit?.isEpiKitDelivered ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Cartão de ponto batido corretamente?</Td>
        <Td isNumeric>{audit?.audit?.humanResourcesAudit?.isTimeCardRecordedCorrectly ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Conta salário aberta?</Td>
        <Td isNumeric>{audit?.audit?.humanResourcesAudit?.isOpenedSalaryAccount === 'yes' ?
          'SIM' : audit?.audit?.humanResourcesAudit?.isOpenedSalaryAccount === 'no' ?
            'NÃO' : 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td>Crachá?</Td>
        <Td isNumeric>{audit?.audit?.humanResourcesAudit?.isWithBadge ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Cordão crachá?</Td>
        <Td isNumeric>{audit?.audit?.humanResourcesAudit?.isWithBadgeCord ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Adesivos moto entregue?</Td>
        <Td isNumeric>{audit?.audit?.humanResourcesAudit?.isDeliveredMotorcycleLabels === 'yes' ?
          'SIM' : audit?.audit?.humanResourcesAudit?.isDeliveredMotorcycleLabels === 'no' ?
            'NÃO' : 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td>Caixa térmica entregue?</Td>
        <Td isNumeric>{audit?.audit?.humanResourcesAudit?.isDeliveredThermalBox === 'yes' ?
          'SIM' : audit?.audit?.humanResourcesAudit?.isDeliveredThermalBox === 'no' ?
            'NÃO' : 'N/A'}</Td>
      </Tr>
    </>
  )
}
