import { Box, Button, Flex, FormControl, FormLabel, Heading, Select, Text, Textarea, VStack } from "@chakra-ui/react"
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { solveSupport } from "api/supports/solveSupport";
import { SolveSupportAttachmentTableRowField } from "./SolveSupportAttachmentTableRowField";
import { useReducer } from "react";

interface SolveSupportProps {
  supportId: string
  onClose: () => void
}

export interface SolveSupportSchema {
  status: 'done' | 'waiting-return'
  content: string
  attachments: {
    file: FileList
  }[]

}

const solveSupportSchema = yup.object({
  content: yup.string().required(),
  attachments: yup.array(),

})

interface SetAttachmentIdActionPayload {
  attachmentId: string
}

export interface SetAttachmentIdAction {
  type: 'ADD' | 'DELETE'
  payload: SetAttachmentIdActionPayload
}

function reducer(state: string[], action: SetAttachmentIdAction) {
  if (action.type === 'ADD') {
    state.push(action.payload.attachmentId)
  }

  if (action.type === 'DELETE') {
    const attachmentIndex = state.findIndex(attachmentId => attachmentId === action.payload.attachmentId)

    state.splice(attachmentIndex, 1)
  }

  return state
}

export function SolveSupport({ supportId, onClose }: SolveSupportProps) {
  const [attachmentsIds, setAttachmentIds] = useReducer(reducer, [])

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const formMethods = useForm<SolveSupportSchema>({
    resolver: yupResolver(solveSupportSchema),
    defaultValues: {
      attachments: [{ file: undefined }]
    }
  })

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = formMethods

  const {
    fields: attachmentsFields,
    append: appendAttachmentsField,
    remove: removeAttachmentsField,
  } = useFieldArray({
    control,
    name: 'attachments'
  })

  const attachments = useWatch({
    control,
    name: 'attachments'
  })


  const { mutateAsync: solveSupportFn } = useMutation({
    mutationFn: solveSupport,
    onSuccess(_data, { body, supportId }) {
      queryClient.invalidateQueries({ queryKey: 'supporting-supports' })
      queryClient.invalidateQueries({ queryKey: 'waiting-return-supports' })
      queryClient.invalidateQueries({ queryKey: 'done-supports' })
      queryClient.invalidateQueries({ queryKey: 'cancelled-supports' })
      queryClient.invalidateQueries({ queryKey: ['support', supportId] })
      onClose()
    }
  })


  async function handleSolveSupport(values: SolveSupportSchema) {
    await promiseMessage(solveSupportFn({
      body: {
        status: values.status,
        content: values.content,
        attachmentsIds
      }, supportId
    }), 'Atendimento realizado!')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleSolveSupport)}
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'>Atendimento</Heading>

      <FormControl isInvalid={!!errors?.status}>
        <FormLabel fontSize='sm' mt={3}>
          Próximo status
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register('status')}
          name="status"
          placeholder="Selecione um status..."
          size="sm"
          rounded="md"
        >
          <option value="done">Finalizado</option>
          <option value="waiting-return">Aguardando retorno</option>
        </Select>
      </FormControl>

      <FormControl isInvalid={!!errors?.content}>
        <FormLabel fontSize='sm' mt={3}>
          Descrição
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register('content')}
          name="content"
          size="sm"
          rounded="md"
        />
      </FormControl>

      <FormLabel fontSize='sm' mt={3}>
        Anexos (Opcional)
        <Text as="sup" color="red.500">*</Text>
      </FormLabel>
      <VStack spacing={3}>
        {attachmentsFields.map((field, index) => {

          const isFilledField = Boolean(attachments[index]?.file?.length)

          return (

            <FormProvider key={field.id} {...formMethods}>
              <SolveSupportAttachmentTableRowField
                key={field.id}
                index={index}
                onSetAttachmentId={setAttachmentIds}
                isFilledField={isFilledField}
                onAppendField={() => appendAttachmentsField({})}
                onRemoveField={() => removeAttachmentsField(index)}
              />
            </FormProvider>
          )
        })}
      </VStack>


      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
