
import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface CreateTrainingLlmBody {
  collaboratorId: string
  training: string
  dueDate: string
}

interface CreateTrainingLLmParams {
  body: CreateTrainingLlmBody
}

export async function createTrainingLlmManually({
  body
}: CreateTrainingLLmParams) {
  try {
    await api.post('/llm/trainings', body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

