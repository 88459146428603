import { useContext } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'
import {
  getAllDrivers,
  getOneDriver,
} from '../../services/getFunctions/driver/getDrivers'

export function useDriver(
  driverId: string | null,
  isEnable?: boolean,
  refetchOnFocus?: boolean,
) {
  const { handleLogout, userLogged } = useContext(AuthContext)

  const drivers = useQuery('drivers', getAllDrivers, {
    enabled: !!isEnable && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (error: any) => {
      toast.error('Erro ao consultar informações! Realize o login novamente.')
      handleLogout()
    },
  })
  const driver = useQuery(
    ['driver', driverId],
    () => getOneDriver(driverId !== null ? driverId : ''),
    {
      enabled: !!driverId && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )

  return {
    drivers,
    driver,
  }
}
