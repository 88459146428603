import { format, set } from 'date-fns'


interface IAssignDriverInputProps {
  driver_id: string
  address_id: string
}

interface IAssignDriverProviderInputProps {
  driver_id: string
  provider_id: string
}


type RecurrentServiceRequest = {
  customer_id: string
  situation: string
  budget_id: string
  owner: string
  source_address_id: string[]
  destination_address_id: string[]
  source_collector_id: string
  destination_collector_id: string
  source_branch_id: string
  destination_branch_id: string
  provider_id: string
  availability_forecast_deadline: number | null
  availability_forecast_day: string
  availability_forecast_time: string
  board_hour: string
  board_date: number | null
  planned_flight: string
  deadline: number
  service_type: string
  franchising: number
  modal: string
  vehicle: string
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  collect_date: string
  collect_hour_start: string
  collect_hour_end: string
  delivery_date: string
  delivery_hour: string
  days_of_week: string[]
  material_type: string
  destination_addresses_input: {
    address_id: string
    quantity: number
  }[]
  driver_address_assign: IAssignDriverInputProps[]
  driver_provider_assign: IAssignDriverProviderInputProps[]
  observation: string
}

const recurrentServiceFormatDataToBack = (values: RecurrentServiceRequest) => {
  const dataDaysOfWeek =
    values.days_of_week.toString().split(',').length > 1
      ? values.days_of_week
      : values.days_of_week.toString().split('  ')
  const sourceAddresses =
    values.source_address_id.toString().split(',').length > 1
      ? values.source_address_id
      : values.source_address_id.toString().split('  ')
  const destinationAddresses =
    values.destination_address_id.toString().split(',').length > 1
      ? values.destination_address_id
      : values.destination_address_id.toString().split('  ')

  const daysOfWeekByNumber: number[] = []

  dataDaysOfWeek.forEach((day) => {
    if (day === 'DOMINGO') {
      daysOfWeekByNumber.push(0)
    }
    if (day === 'SEGUNDA') {
      daysOfWeekByNumber.push(1)
    }
    if (day === 'TERÇA') {
      daysOfWeekByNumber.push(2)
    }
    if (day === 'QUARTA') {
      daysOfWeekByNumber.push(3)
    }
    if (day === 'QUINTA') {
      daysOfWeekByNumber.push(4)
    }
    if (day === 'SEXTA') {
      daysOfWeekByNumber.push(5)
    }
    if (day === 'SÁBADO') {
      daysOfWeekByNumber.push(6)
    }
  })

  const [collectHourStart, collectMinutesStart] =
    values.collect_hour_start.split(':')
  const [collectHourEnd, collectMinutesEnd] = values.collect_hour_end.split(':')
  const [deliveryHour, deliveryMinutes] = values.delivery_hour.split(':')

  const boardHour = values.board_hour ? values.board_hour.split(':') : null
  const boardHourFormated =
    boardHour !== null
      ? format(
          set(new Date(), {
            hours: Number(boardHour[0]),
            minutes: Number(boardHour[1]),
          }),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
        )
      : null

  const hourAvailabilityForecast = values.availability_forecast_time
    ? values.availability_forecast_time.split(':')
    : null
  const hourSettedToAvailabilityForecast =
    hourAvailabilityForecast !== null
      ? format(
          set(new Date(), {
            hours: Number(hourAvailabilityForecast[0]),
            minutes: Number(hourAvailabilityForecast[1]),
          }),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
        )
      : null

  const collectHourStartFormated = format(
    set(new Date(), {
      hours: Number(collectHourStart),
      minutes: Number(collectMinutesStart),
    }),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )

  const collectHourEndFormated = format(
    set(new Date(), {
      hours: Number(collectHourEnd),
      minutes: Number(collectMinutesEnd),
    }),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )

  const deliveryHourFormated = format(
    set(new Date(), {
      hours: Number(deliveryHour),
      minutes: Number(deliveryMinutes),
    }),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )

  const valuesFormated = {
    ...values,
    source_address_id: sourceAddresses,
    days_of_week: daysOfWeekByNumber,
    destination_address_id: destinationAddresses,
    source_collector_id: values.source_collector_id
      ? values.source_collector_id
      : null,
    destination_collector_id: values.destination_collector_id
      ? values.destination_collector_id
      : null,
    source_branch_id: values.source_branch_id ? values.source_branch_id : null,
    destination_branch_id: values.destination_branch_id
      ? values.destination_branch_id
      : null,
    provider_id: values.provider_id ? values.provider_id : null,
    planned_flight: values.planned_flight ?? null,
    collect_hour_end: collectHourEndFormated,
    collect_hour_start: collectHourStartFormated,
    delivery_hour: deliveryHourFormated,
    availability_forecast_deadline:
      values.availability_forecast_deadline !== null
        ? values.availability_forecast_deadline >= 0
          ? values.availability_forecast_deadline
          : null
        : null,
    availability_forecast_day: null,
    availability_forecast_time: hourSettedToAvailabilityForecast,
    board_hour: boardHourFormated,
    destination_addresses_input:
      values.material_type === 'CORRELATOS' ||
      values.material_type === 'CARGA GERAL'
        ? values.destination_addresses_input
        : null,
    board_date:
      values.board_date !== null
        ? values.board_date >= 0
          ? values.board_date
          : null
        : null,
  }

  return valuesFormated
}

export { recurrentServiceFormatDataToBack }
