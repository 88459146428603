import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, IconButton, Link, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { CltDriver } from "api/cltDrivers/_types/CltDriver";
import { format, setDay } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { FaExternalLinkAlt } from "react-icons/fa";
import { captalize } from "utils/captalize";

function booleanStringFieldsMap(fieldValue: 'yes' | 'no' | 'n/a') {
  if (fieldValue === 'yes') {
    return 'Sim'
  }

  if (fieldValue === 'no') {
    return 'Não'
  }

  return 'N/A'
}

interface CltDriverDetailTableProps {
  cltDriver: CltDriver & {
    terminationDocumentsAttachments: {
      id: string
      attachment: Attachment
    }[]
  }
}

export function CltDriverDetailTable({ cltDriver }: CltDriverDetailTableProps) {

  return (
    <Box maxH="600px" overflowY="scroll">
      <Accordion allowMultiple mt="4">
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Informações do Motorista
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Table size="sm" w="full" >
              <Tbody>
                <Tr>
                  <Td>Motorista</Td>
                  <Td isNumeric>{`${cltDriver?.driver?.firstname} ${cltDriver?.driver?.lastname} `}</Td>
                </Tr>
                <Tr>
                  <Td>Nome Completo</Td>
                  <Td isNumeric>{`${cltDriver?.driver?.firstname} ${cltDriver?.driver?.lastname} `}</Td>
                </Tr>
                <Tr>
                  <Td>CPF</Td>
                  <Td isNumeric>{cltDriver?.driver?.cpf}</Td>
                </Tr>
                <Tr>
                  <Td>E-mail do colaborador</Td>
                  <Td isNumeric>{cltDriver?.email}</Td>
                </Tr>
                <Tr>
                  <Td>RG</Td>
                  <Td isNumeric>{cltDriver?.rg}</Td>
                </Tr>
                <Tr>
                  <Td>Celular</Td>
                  <Td isNumeric>{cltDriver?.cellphone}</Td>
                </Tr>
                <Tr>
                  <Td>Data de nascimento</Td>
                  <Td isNumeric>{format(new Date(cltDriver?.birth_date), "dd/MM/yyyy")}</Td>
                </Tr>
                <Tr>
                  <Td>Endereço completo</Td>
                  <Td isNumeric>{cltDriver?.address}</Td>
                </Tr>
                <Tr>
                  <Td>Dias de trabalho</Td>
                  <Td isNumeric>
                    {cltDriver.days_of_work.map((weekDay) => {
                      return (
                        <Text>{captalize(format(setDay(new Date(), Number(weekDay)), 'eeee', {
                          locale: ptBR
                        }))}</Text>
                      )
                    })}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Horário inicial de trabalho</Td>
                  <Td isNumeric>{cltDriver?.start_work_hour}</Td>
                </Tr>
                <Tr>
                  <Td>Horário final de trabalho</Td>
                  <Td isNumeric>{cltDriver?.end_work_hour}</Td>
                </Tr>
                <Tr>
                  <Td>Horário inicial de intervalo</Td>
                  <Td isNumeric>{cltDriver?.start_interval_hour}</Td>
                </Tr>
                <Tr>
                  <Td>Horário final de intervalo</Td>
                  <Td isNumeric>{cltDriver?.end_interval_hour}</Td>
                </Tr>
                {cltDriver?.start_weekend_work_hour && (
                  <Tr>
                    <Td>Horário inicial de trabalho final de semana</Td>
                    <Td isNumeric>{cltDriver?.start_weekend_work_hour}</Td>
                  </Tr>
                )}
                {cltDriver?.end_weekend_work_hour && (
                  <Tr>
                    <Td>Horário final de trabalho final de semana</Td>
                    <Td isNumeric>{cltDriver?.end_weekend_work_hour}</Td>
                  </Tr>
                )}
                {cltDriver?.salary && (
                  <Tr>
                    <Td>Salário</Td>
                    <Td isNumeric>{new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(cltDriver?.salary)}</Td>
                  </Tr>
                )}
                {cltDriver?.family_salary && (
                  <Tr>
                    <Td>Salário família</Td>
                    <Td isNumeric>{cltDriver?.family_salary ? 'SIM' : 'NÃO'}</Td>
                  </Tr>
                )}
                {cltDriver?.admission_date && (
                  <Tr>
                    <Td>Data de admissão</Td>
                    <Td isNumeric>{format(new Date(cltDriver?.admission_date), "dd/MM/yyyy")}</Td>
                  </Tr>
                )}
                {cltDriver?.regional && (
                  <Tr>
                    <Td>Regional</Td>
                    <Td isNumeric>{cltDriver?.regional}</Td>
                  </Tr>
                )}
                {cltDriver?.office && (
                  <Tr>
                    <Td>Cargo</Td>
                    <Td isNumeric>{cltDriver?.office}</Td>
                  </Tr>
                )}
                {cltDriver?.cost_center && (
                  <Tr>
                    <Td>Centro de custo</Td>
                    <Td isNumeric>{cltDriver?.cost_center}</Td>
                  </Tr>
                )}
                {cltDriver?.benefits && (
                  <Tr>
                    <Td>Benefícios</Td>
                    <Td isNumeric>{cltDriver?.benefits.map(benefit => benefit)?.join(', ')}</Td>
                  </Tr>
                )}

                {cltDriver?.vr_value && (
                  <Tr>
                    <Td>Valor diário (Vale refeição)</Td>
                    <Td isNumeric>{new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(cltDriver?.vr_value / 100)}</Td>
                  </Tr>
                )}
                {cltDriver?.va_payment_type && (
                  <Tr>
                    <Td>Tipo de pagamento (VA)</Td>
                    <Td isNumeric>{cltDriver?.va_payment_type === 'daily' ? 'DIÁRIO' : 'MENSAL'}</Td>
                  </Tr>
                )}

                {cltDriver?.va_value && (
                  <Tr>
                    <Td>{cltDriver?.va_payment_type === 'daily' ? 'Valor Diário(VA)' : 'Valor mensal(VA)'}</Td>
                    <Td isNumeric>{new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(cltDriver?.va_value / 100)}</Td>
                  </Tr>
                )}
                {cltDriver?.motorcycle_location_value && (
                  <Tr>
                    <Td>Valor mensal (Aluguel de moto)</Td>
                    <Td isNumeric>{new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(cltDriver?.motorcycle_location_value / 100)}</Td>
                  </Tr>
                )}
                {cltDriver?.home_work_monthly_value_in_cents && (
                  <Tr>
                    <Td>Valor mensal (casa-trabalho)</Td>
                    <Td isNumeric>{new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(cltDriver?.home_work_monthly_value_in_cents / 100)}</Td>
                  </Tr>
                )}
                {cltDriver?.business_days_daily_km_in_meters && (
                  <Tr>
                    <Td>Km diário (Seg-Sex ou 12x36)</Td>
                    <Td isNumeric>{new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(cltDriver?.business_days_daily_km_in_meters / 100)}</Td>
                  </Tr>
                )}

                {cltDriver?.business_days_fuel_price_in_cents && (
                  <Tr>
                    <Td>Combustível operacional diário (Seg-Sex ou 12x36)</Td>
                    <Td isNumeric>{new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(cltDriver?.business_days_fuel_price_in_cents / 100)}</Td>
                  </Tr>
                )}

                {cltDriver?.cellphone_cost_help && (
                  <Tr>
                    <Td>Valor mensal (ajuda de custo celular)</Td>
                    <Td isNumeric>{new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(cltDriver?.cellphone_cost_help / 100)}</Td>
                  </Tr>
                )}

                {cltDriver?.toll_value && (
                  <Tr>
                    <Td>Valor diário (Pedágio)</Td>
                    <Td isNumeric>{new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(cltDriver?.toll_value / 100)}</Td>
                  </Tr>
                )}
                {cltDriver?.vt_value && (
                  <Tr>
                    <Td>Valor diário (VT)</Td>
                    <Td isNumeric>{new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(cltDriver?.vt_value / 100)}</Td>
                  </Tr>
                )}
                {cltDriver?.fuel_aid_value && (
                  <Tr>
                    <Td>Valor mensal (Auxílio combustível)</Td>
                    <Td isNumeric>{new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(cltDriver?.fuel_aid_value / 100)}</Td>
                  </Tr>
                )}

              </Tbody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Informações da Contratação
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Table size="sm" w="full" >
              <Tbody>
                <Tr>
                  <Td>Documentação</Td>
                  <Td isNumeric>{cltDriver?.hiringDocumentationChecklist?.map((documentation) => documentation)?.join(", ")}</Td>
                </Tr>
                <Tr>
                  <Td>VA/VR Solicitado ?</Td>
                  <Td isNumeric>{cltDriver?.hasRequestedMealFoodVoucher ? 'SIM' : 'NÃO'}</Td>
                </Tr>
                <Tr>
                  <Td>Ticket Log Solicitado ?</Td>
                  <Td isNumeric>{cltDriver?.hasRequestedTicketLog ? 'SIM' : 'NÃO'}</Td>
                </Tr>
              </Tbody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Informações da Moto
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Table size="sm" w="full" >
              <Tbody>
                <Tr>
                  <Td>Marca da moto</Td>
                  <Td isNumeric>{cltDriver?.motorcycleBrand}</Td>
                </Tr>
                <Tr>
                  <Td>Modelo da moto</Td>
                  <Td isNumeric>{cltDriver?.motorcycleModel}</Td>
                </Tr>
                <Tr>
                  <Td>Ano da moto</Td>
                  <Td isNumeric>{cltDriver?.motorcycleYear}</Td>
                </Tr>
                <Tr>
                  <Td>Cor</Td>
                  <Td isNumeric>{cltDriver?.motorcycleColor}</Td>
                </Tr>
                <Tr>
                  <Td>Placa</Td>
                  <Td isNumeric>{cltDriver?.motorcycleLicensePlate}</Td>
                </Tr>
                <Tr>
                  <Td>Ano do documento do veículo</Td>
                  <Td isNumeric>{cltDriver?.vehicleDocumentRegisterYear}</Td>
                </Tr>
              </Tbody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Informações do Cadastro Scalabrini
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Table size="sm" w="full" >
              <Tbody>
                <Tr>
                  <Td>Cadastrado no sistema da Scalabrini ?</Td>
                  <Td isNumeric>{cltDriver?.doesRegisteredInScalabriniSystem ? 'SIM' : 'NÃO'}</Td>
                </Tr>
              </Tbody>
            </Table>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Informações do Cadastro iFractal
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Table size="sm" w="full" >
              <Tbody>
                <Tr>
                  <Td>Cadastrado no iFractal ?</Td>
                  <Td isNumeric>{cltDriver?.hasRegisteredOnPontoMais ? 'SIM' : 'NÃO'}</Td>
                </Tr>
                <Tr>
                  <Td>E-mail cadastrado ?</Td>
                  <Td isNumeric>{cltDriver?.hasRegisteredEmail ? 'SIM' : 'NÃO'}</Td>
                </Tr>
                <Tr>
                  <Td>Foto Cadastrada ?</Td>
                  <Td isNumeric>{cltDriver?.hasRegisteredPhoto ? 'SIM' : 'NÃO'}</Td>
                </Tr>
              </Tbody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Informações da Assinatura de Documentos
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Table size="sm" w="full" >
              <Tbody>
                <Tr>
                  <Td>Funcionário desistiu da vaga ?</Td>
                  <Td isNumeric>{cltDriver?.doesEmployeeWithdrewThePosition ? 'SIM' : 'NÃO'}</Td>
                </Tr>
                <Tr>
                  <Td>Documentação</Td>
                  <Td isNumeric>{cltDriver?.signingDocumentsChecklist?.map(document => document)?.join(', ')}</Td>
                </Tr>
                <Tr>
                  <Td>VA/VR Entregue ao colaborador ?</Td>
                  <Td isNumeric>{cltDriver?.doesFoodMeanVoucherDelivered ? 'SIM' : 'NÃO'}</Td>
                </Tr>
              </Tbody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Informações Bancárias
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Table size="sm" w="full" >
              <Tbody>
                <Tr>
                  <Td>Banco</Td>
                  <Td isNumeric>{cltDriver?.bank}</Td>
                </Tr>
                <Tr>
                  <Td>Agência</Td>
                  <Td isNumeric>{cltDriver?.agency}</Td>
                </Tr>
                <Tr>
                  <Td>Conta</Td>
                  <Td isNumeric>{cltDriver?.account}</Td>
                </Tr>
              </Tbody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
        {cltDriver.status === 'moved' && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Informações de afastamento
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Table size="sm" w="full" >
                <Tbody>
                  <Tr>
                    <Td>Data do acidente</Td>
                    <Td isNumeric>{format(new Date(cltDriver?.accidentDate), 'dd/MM/yyyy')}</Td>
                  </Tr>
                  <Tr>
                    <Td>Data de início do INSS</Td>
                    <Td isNumeric>{format(new Date(cltDriver?.inssBenefitStartDate), 'dd/MM/yyyy')}</Td>
                  </Tr>
                  <Tr>
                    <Td>Data de término do INSS</Td>
                    <Td isNumeric>{format(new Date(cltDriver?.inssBenefitFinalDate), 'dd/MM/yyyy')}</Td>
                  </Tr>
                  <Tr>
                    <Td>Tipo de afastamento</Td>
                    <Td isNumeric>{cltDriver?.moveTypeCode}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        )}

        {['dismissing-comunication', 'dismissing-aso-and-notice', 'dismissing-fulfilling-notice', 'dismissing-documentation', 'dismissing-trct-signature', 'dismissing-termination', 'dismissed', 'dismissing-approvation', 'dismissing-legal-prevention'].includes(cltDriver.status) && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Demissão - Prevenção jurídica
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Table size="sm" w="full" >
                <Tbody>
                  <Tr>
                    <Td>Documentação assinada</Td>
                    <Td isNumeric>{cltDriver?.legalPreventionDocumentsChecklist?.join(', ')}</Td>
                  </Tr>
                  <Tr>
                    <Td>Realizou conferência de registros de ponto?</Td>
                    <Td isNumeric>{cltDriver?.hasConferredPointRegisters ? 'Sim' : 'Não'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Realizou conferência se funcionário tem alguma pendência de documentação com a empresa?</Td>
                    <Td isNumeric>{cltDriver?.hasConferredEmployeeHasPendingDocumentsWithCompany ? 'Sim' : 'Não'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Líder da operação ciente para realizar demissão?</Td>
                    <Td isNumeric>{cltDriver?.doesOperationLeaderKnownAboutDismiss ? 'Sim' : 'Não'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Data da demissão?</Td>
                    <Td isNumeric>{format(new Date(cltDriver?.dismissDate), 'dd/MM/yyyy')}</Td>
                  </Tr>
                  <Tr>
                    <Td>Colaborador não está com estabilidade de emprego?</Td>
                    <Td isNumeric>{cltDriver?.doesEmployeeHaveNoStabilityOfJob}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        )}


        {['dismissing-comunication', 'dismissing-aso-and-notice', 'dismissing-fulfilling-notice', 'dismissing-documentation', 'dismissing-trct-signature', 'dismissing-termination', 'dismissed', 'dismissing-approvation'].includes(cltDriver.status) && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Demissão - Aprovação
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Table size="sm" w="full" >
                <Tbody>
                  <Tr>
                    <Td>Risco jurídico controlado?</Td>
                    <Td isNumeric>{cltDriver?.isLegalRiskControlled ? 'Sim' : 'Não'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Tipo de demissão</Td>
                    <Td isNumeric>{cltDriver?.dismissionType}</Td>
                  </Tr>
                  <Tr>
                    <Td>Tipo de aviso prévio</Td>
                    <Td isNumeric>{cltDriver?.noticeType}</Td>
                  </Tr>
                  <Tr>
                    <Td>Demissão aprovada?</Td>
                    <Td isNumeric>{cltDriver?.isApprovedDismission ? 'Sim' : 'Não'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Data da demissão?</Td>
                    <Td isNumeric>{format(new Date(cltDriver?.dismissDate), 'dd/MM/yyyy')}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        )}

        {['dismissing-aso-and-notice', 'dismissing-fulfilling-notice', 'dismissing-documentation', 'dismissing-trct-signature', 'dismissing-termination', 'dismissed', 'dismissing-comunication'].includes(cltDriver.status) && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Demissão - Comunicação
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Table size="sm" w="full" >
                <Tbody>
                  <Tr>
                    <Td>Demissão informada a Scalabrini?</Td>
                    <Td isNumeric>{cltDriver?.doesRegisteredInScalabriniSystem ? 'Sim' : 'Não'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Último dia de trabalho</Td>
                    <Td isNumeric>{format(new Date(cltDriver?.lastWorkDate), 'dd/MM/yyyy')}</Td>
                  </Tr>
                  <Tr>
                    <Td>Realizou conferência de registros de ponto?</Td>
                    <Td isNumeric>{cltDriver?.hasConferredPointRegisters ? 'Sim' : 'Não'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Foram devolvidos todos os patrimônios em posse do funcionário?</Td>
                    <Td isNumeric>{cltDriver?.doesEmployeeReturnedAllPatrimonies ? 'Sim' : 'Não'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Aviso prévio assinado?</Td>
                    <Td isNumeric>{cltDriver?.hasSignedNotice ? 'Sim' : 'Não'}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        )}

        {['dismissing-fulfilling-notice', 'dismissing-documentation', 'dismissing-trct-signature', 'dismissing-termination', 'dismissed', 'dismissing-aso-and-notice'].includes(cltDriver.status) && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Demissão - Aso e aviso prévio
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Table size="sm" w="full" >
                <Tbody>
                  <Tr>
                    <Td>Aviso de demissão assinado?</Td>
                    <Td isNumeric>{cltDriver?.hasSignedDemissionNotice ? 'Sim' : 'Não'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Ticket log bloqueado?</Td>
                    <Td isNumeric>{cltDriver?.isTicketLogBlocked ? 'Sim' : 'Não'}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        )}

        {['dismissing-documentation', 'dismissing-trct-signature', 'dismissing-termination', 'dismissed', 'dismissing-fulfilling-notice'].includes(cltDriver.status) && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Demissão - Cumprindo aviso
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Table size="sm" w="full" >
                <Tbody>
                  <Tr>
                    <Td>Patrimônios devolvidos?</Td>
                    <Td isNumeric>{cltDriver?.doesInNoticeReturnedPatrimonies ? 'Sim' : 'Não'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Descontou VA/VR/Combustível?</Td>
                    <Td isNumeric>{cltDriver?.hasDiscountedMeetAndFoodAndFuelVouchers ? 'Sim' : 'Não'}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        )}

        {['dismissing-trct-signature', 'dismissing-termination', 'dismissed', 'dismissing-documentation'].includes(cltDriver.status) && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Demissão - Documentação
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Table size="sm" w="full" >
                <Tbody>
                  <Tr>
                    <Td>Documentos de demissão recebidos da contabilidade?</Td>
                    <Td isNumeric>{cltDriver?.doesReceivedDismissionDocumentsFromAccounting ? 'Sim' : 'Não'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Apuração de saldo de combustível e aluguel do período?</Td>
                    <Td isNumeric>{cltDriver?.hasCheckedPeriodFuelAndRentalBalance ? 'Sim' : 'Não'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Realizou conferência de GRFF?</Td>
                    <Td isNumeric>{cltDriver?.hasCheckedGrrf ? 'Sim' : 'Não'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Líder da operação ciente para realizar a demissão?</Td>
                    <Td isNumeric>{cltDriver?.doesOperationLeaderKnownAboutDismiss ? 'Sim' : 'Não'}</Td>
                  </Tr>
                  {cltDriver?.terminationDocumentsAttachments?.map((terminationDocument) => {
                    return (
                      <Tr
                        key={terminationDocument.id}
                      >
                        <Td>{terminationDocument.attachment.title}</Td>
                        <Td isNumeric>
                          <IconButton
                            aria-label=""
                            as={Link}
                            href={terminationDocument.attachment.link}
                            icon={<FaExternalLinkAlt />}
                            size="sm"
                            variant="ghost"
                            isExternal
                          />
                        </Td>
                      </Tr>
                    )
                  })}

                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        )}

        {['dismissing-termination', 'dismissed'].includes(cltDriver.status) && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Demissão - Assinatura TRCT
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Table size="sm" w="full" >
                <Tbody>
                  <Tr>
                    <Td>TRCT assinado?</Td>
                    <Td isNumeric>{cltDriver?.doesSignedTrct ? 'Sim' : 'Não'}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        )}

        {['dismissed', 'dismissing-termination'].includes(cltDriver.status) && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Demissão - Rescisão
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Table size="sm" w="full" >
                <Tbody>
                  <Tr>
                    <Td>Rescisão paga?</Td>
                    <Td isNumeric>{cltDriver?.doesPayedTermination ? 'Sim' : 'Não'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Excluiu o funcionário da plataforma de Medicina Ocupacional?</Td>
                    <Td isNumeric>{booleanStringFieldsMap(cltDriver?.hasDeletedEmployeeFromOccupationalMedicalPlatform)}</Td>
                  </Tr>
                  <Tr>
                    <Td>Data real da demissão?</Td>
                    <Td isNumeric>{format(new Date(cltDriver?.dismissionRealDate), 'dd/MM/yyyy')}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        )}
      </Accordion>
    </Box>
  )
}
