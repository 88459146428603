import { useEffect } from "react"
import { useHistory, useParams, Link } from "react-router-dom"
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { Button, Flex } from "@chakra-ui/react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAuth } from "hooks/auth/useAuth"
import { useAggregateFunctions } from "hooks/aggregate/useAggregateFunctions";
import { isCnpjValid } from "utils/cpfCnpjValidateFunctions";
import { useAggregate } from "hooks/aggregate/useAggregate";
import { useSwal } from "hooks/swal/useSwal";
import { AggregateForm, AggregateFormInputs } from './components/AggregateForm'
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";
import { formatDate } from "utils/DateFunctions/formatDate";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { useIbgeState } from "hooks/ibge/useIbgeState";
import { statesWithUfList } from "utils/CustomLists/statesWithUfList";
import { useIbgeCitiesByUf } from "hooks/ibge/useIbgeCitiesByUf";
import { useDriver } from "hooks/driver/useDriver";
import { useCity } from "hooks/city/useCity";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";


interface QueryParams {
  slug: string
  id: string
}

const editAggregateForm = yup.object().shape({
  company_name: yup.string().required("Campo obrigatório"),
  trading_name: yup.string().required("Campo obrigatório"),
  cnpj: yup.string().transform(isCnpjValid).required('CNPJ inválido'),
  rg: yup.string().required("Campo obrigatório"),
  cellphone: yup.string().required("Campo obrigatório"),
  driver_id: yup.string().required("Campo obrigatório"),
  birth_date: yup.string().required("Campo obrigatório"),
  cep: yup.string().required("Campo obrigatório"),
  state: yup.string().required("Campo obrigatório"),
  city: yup.string().required("Campo obrigatório"),
  street: yup.string().required("Campo obrigatório"),
  number: yup.string().required("Campo obrigatório"),
  neighborhood: yup.string().required("Campo obrigatório"),
  complement: yup.string(),
  contract_start_date: yup.string().required("Campo obrigatório"),
  payment_type: yup.string().required("Campo obrigatório"),
  due_date: yup.string().required("Campo obrigatório"),
  days_of_work: yup.array().of(yup.object({
    day: yup.number().typeError('Campo obrigatório'),
    price: yup.string().required('Campo obrigatório')
  })).min(1, 'Campo obrigatório'),
  rider_backup: yup.string().required("Campo obrigatório"),
  bank: yup.string().required('Campo obrigatório'),
  type: yup.string().required('Campo obrigatório'),
  cpf: yup.mixed().when('type', {
    is: 'cpf',
    then: yup.string().required('Campo obrigatório')
  }),
  account_type: yup.string().required('Campo obrigatório'),
  agency: yup.string().required('Campo obrigatório'),
  account: yup.string().required('Campo obrigatório'),
  cities: yup.array().of(yup.object({
    cityId: yup.string().required('Campo obrigatório'),
    carValueInCents: yup.number().required().transform((value, originalValue) => Math.ceil(transformStringToNumber(originalValue) * 100)),
    motorcycleValueInCents: yup.number().required().transform((value, originalValue) => Math.ceil(transformStringToNumber(originalValue) * 100)),
  })),
  observation: yup.string()
})

export function EditViewAggregatePage() {
  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  const { confirmMessage, standardMessage } = useSwal()


  const { slug, id } = useParams<QueryParams>()

  const formMethods = useForm<AggregateFormInputs>({
    resolver: yupResolver(editAggregateForm)
  })

  const {
    control,
    handleSubmit,
    setValue,
    formState: {
      isSubmitting
    }
  } = formMethods

  const { data: aggregate, isFetching: isFetchingAggregate } = useAggregate(id)

  const {
    cities: { data: cities, isLoading: isCitiesLoading },
  } = useCity(null, true)

  const {
    updateAggregate: { mutateAsync: updateAggregate },
    updateAggregateAttachments: { mutateAsync: updateAggregateAttachments },
  } = useAggregateFunctions()

  const userHasPermissionEditAggregations =
    userLogged?.permissions.includes("edit-aggregate");

  useEffect(() => {
    if (!userHasPermissionEditAggregations && slug !== 'visualizar') {
      redirect('/')
    }
  }, [redirect, slug, userHasPermissionEditAggregations])


  useEffect(() => {
    if (aggregate !== undefined) {
      setValue('company_name', aggregate.company_name)
      setValue('trading_name', aggregate.trading_name)
      setValue('cnpj', aggregate.cnpj)
      setValue('rg', aggregate.rg)
      setValue('cellphone', aggregate.cellphone)
      setValue('birth_date', formatDate.handle(aggregate.birth_date, 'DateWithoutHourToInput'))
      setValue('cep', aggregate.cep)
      setValue('street', aggregate.street)
      setValue('number', aggregate.number)
      setValue('neighborhood', aggregate.neighborhood)
      setValue('complement', aggregate.complement ? aggregate.complement : '')
      setValue('contract_start_date', formatDate.handle(aggregate.contract_start_date, 'DateWithoutHourToInput'))
      setValue('payment_type', aggregate.payment_type)
      setValue('days_of_work', JSON.parse(aggregate?.days_of_work))
      setValue('due_date', aggregate.due_date)
      setValue('rider_backup', aggregate.rider_backup ? 'yes' : 'no')
      setValue('observation', aggregate.observation)
      setValue('bank', aggregate.bank ?? '')
      setValue('type', aggregate.cpf ? 'cpf' : 'cnpj')
      setValue('cpf', aggregate.cpf ?? '')

      if (aggregate?.cities.length && Boolean(cities)) {
        const aggregateCities = aggregate?.cities?.map(aggregateCity => {
          const cityState = cities?.find(city => city.id === aggregateCity.cityId).state

          let uf = ''

          for (const state in statesWithUfList) {
            if (state.toLowerCase() === cityState.toLowerCase()) {
              uf = state
            }
          }

          return {
            cityId: aggregateCity.cityId,
            uf,
            motorcycleValueInCents: String(aggregateCity.motorcycleValueInCents / 100),
            carValueInCents: String(aggregateCity.carValueInCents / 100)
          }
        })

        setValue('cities', aggregateCities)
      }

      if (aggregate.account_type) {
        setValue('account_type', aggregate.account_type)

      }
      setValue('agency', aggregate.agency ?? '')
      setValue('account', aggregate.account ?? '')
    }
  }, [aggregate, cities, setValue])

  const aggregateAttachments = aggregate?.attachments

  const {
    drivers: { data: drivers, isLoading: isDriversLoading },
  } = useDriver(null, true)

  useEffect(() => {
    if (!isDriversLoading && aggregate !== undefined) {
      setValue('driver_id', aggregate.driver_id)
    }
  }, [setValue, aggregate, isDriversLoading])


  const {
    ibgeStates: { data: ibgeStates, isFetching: isFetchingIbgeStates }
  } = useIbgeState()



  useEffect(() => {
    if (!isFetchingIbgeStates && aggregate) {
      setValue('state', aggregate.state)
    }
  }, [setValue, isFetchingIbgeStates, aggregate])

  const selectedState = useWatch({
    control,
    name: 'state'
  })

  const selectedStateUf = Object.entries(statesWithUfList).find(([key, value]) => {
    return key.toLocaleUpperCase() === selectedState
  })?.[1].uf

  const { data: ibgeCities, isFetching: isFetchingIbgeCitiesByUf } =
    useIbgeCitiesByUf({
      uf: selectedStateUf,
      queryOptions: { enabled: !!selectedState }
    })

  useEffect(() => {
    if (!isFetchingIbgeCitiesByUf && aggregate) {
      setValue('city', aggregate.city)
    }
  }, [setValue, isFetchingIbgeCitiesByUf, aggregate])

  const isViewPage = slug === "visualizar"
  const formTitle = isViewPage ? "Visualizar agregado" : "Editar agregado"

  async function handleEditAggregate({ ...values }: AggregateFormInputs) {
    const hasEditAggregate = await confirmMessage({ title: "Deseja editar um agregado?" })

    if (hasEditAggregate) {
      await updateAggregate({ aggregate_id: id!, input: values }, {
        onSuccess: async () => {

          if (values.attachments) {
            const formData = new FormData()

            Object.entries(values.attachments).forEach(([key, file]) => {
              formData.append('attachments', file)
            })

            await updateAggregateAttachments({
              aggregateId: aggregate?.id,
              input: formData
            })
          }
          redirect('/agregados')
        }
      })

    } else {
      standardMessage('Ação cancelada com êxito!')
    }

  }

  if (isFetchingAggregate || isCitiesLoading) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm title={formTitle} onSubmit={handleSubmit(handleEditAggregate)}>
      <FormProvider {...formMethods}>
        <AggregateForm
          ibgeStates={ibgeStates}
          ibgeCities={ibgeCities}
          slug={slug}
          driversState={{
            drivers,
            isLoading: isDriversLoading
          }}
          aggregateId={aggregate?.id}
          aggregateAttachments={aggregateAttachments}
          defaultDaysOfWork={JSON.parse(aggregate?.days_of_work)}
          cities={cities}
        />
      </FormProvider>

      <Flex
        mt={6}
        gap={4}
        direction={["column", "column", "row"]}
        align="center"
        justify={["center", "center", "flex-end"]}
      >
        <Button
          as={Link}
          colorScheme='gray'
          w={['full', 'full', 'min']}
          isLoading={isSubmitting}
          to='/agregados'
        >
          Lista de agregados
        </Button>

        {isViewPage ? (
          <Button
            as={Link}
            to={`/agregado/editar/${id}`}
            type="button"
            colorScheme="blue"
            w={["full", "full", "min"]}
            isLoading={isFetchingAggregate}
          >
            Editar
          </Button>
        ) : (
          <Button
            type="submit"
            colorScheme="blue"
            w={["full", "full", "min"]}
            isLoading={isFetchingAggregate || isSubmitting}
          >
            Salvar
          </Button>
        )}

      </Flex>
    </StandardBackgroundForm>
  )
}
