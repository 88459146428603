import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import { useState, useEffect, useContext } from 'react'
import { Pagination } from '../../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../../utils/searchBoxFilter'
import { serviceFormatDateToFrontTable } from '../../../../utils/ServiceFunctions/serviceFormatDateToFrontTable'
import { ReportContext, ReportProps } from '../../../../contexts/ReportContext'
import { ReportLoading } from '../../../Loading/ReportLoading'

export function SlaTable() {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [isLoading, setIsLoading] = useState(true)

  const [searchedSlaReport, setSearchedSlaReport] = useState<ReportProps[]>([])
  const [slicedSlaReports, setSlicedSlaReports] = useState<ReportProps[]>([])

  const [protocolFiltered, setProtocolFiltered] = useState('')
  const [customerFiltered, setCustomerFiltered] = useState('')
  const [sourceCityFiltered, setCityFiltered] = useState('')
  const [destinationCityFiltered, setDestinationCityFiltered] = useState('')
  const [collectDateFiltered, setCollectDateFiltered] = useState('')
  const [deliveryDateFiltered, setDeliveryDateFiltered] = useState('')
  const [stepFiltered, setStepFiltered] = useState('')

  const { slaReport, isSlaReportLoaded } = useContext(ReportContext)

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedSlaReport?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function run() {
      if (isSlaReportLoaded) {
        setIsLoading(false)
      }
    }
    run()
  }, [isSlaReportLoaded])

  useEffect(() => {
    function run() {
      setSlicedSlaReports(slaReport)
    }
    run()
  }, [itemLimit, currentPage, offset, slaReport])

  useEffect(() => {
    function run() {
      const newSlicedBillingReport = slicedSlaReports?.filter((slaReports) => {
        const protocolFilter = searchBoxFilter(
          slaReports.protocol.toString(),
          protocolFiltered,
        )
        const customerFilter = searchBoxFilter(
          slaReports.customer,
          customerFiltered,
        )
        const sourceCityFilter = searchBoxFilter(
          slaReports.sourceCity,
          sourceCityFiltered,
        )
        const destinationCityFilter = searchBoxFilter(
          slaReports.destinationCity,
          destinationCityFiltered,
        )
        const collectDateFilter = searchBoxFilter(
          serviceFormatDateToFrontTable(slaReports.expectedCollectTime),
          collectDateFiltered,
        )
        const deliveryDateFilter = searchBoxFilter(
          serviceFormatDateToFrontTable(slaReports.expectedDeliveryTime),
          deliveryDateFiltered,
        )
        const stepFilter = searchBoxFilter(slaReports.step, stepFiltered)

        if (
          customerFiltered === '' &&
          protocolFiltered === '' &&
          sourceCityFiltered === '' &&
          destinationCityFiltered === '' &&
          collectDateFiltered === '' &&
          deliveryDateFiltered === '' &&
          stepFiltered === ''
        ) {
          return slaReports
        }
        return (
          protocolFilter &&
          customerFilter &&
          sourceCityFilter &&
          destinationCityFilter &&
          collectDateFilter &&
          deliveryDateFilter &&
          stepFilter
        )
      })

      setSearchedSlaReport(newSlicedBillingReport)
    }
    run()
  }, [
    protocolFiltered,
    customerFiltered,
    sourceCityFiltered,
    destinationCityFiltered,
    collectDateFiltered,
    deliveryDateFiltered,
    stepFiltered,
    slicedSlaReports,
  ])

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  if (isLoading) {
    return <ReportLoading />
  }

  return (
    <>
      {slicedSlaReports.length <= 0 ? (
        <Alert
          status="info"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Oops!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Não há dados para mostrar aqui! Tente ajustar os filtros e tente
            novamente 😀!
          </AlertDescription>
        </Alert>
      ) : (
        <>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Protocolo..."
                        handleSearch={(e) =>
                          setProtocolFiltered(e.target.value)
                        }
                      />
                      <Text>PROTOCOLO</Text>
                    </Stack>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Cliente..."
                        handleSearch={(e) =>
                          setCustomerFiltered(e.target.value)
                        }
                      />
                      <Text>CLIENTE</Text>
                    </Stack>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Cidade Origem..."
                        handleSearch={(e) => setCityFiltered(e.target.value)}
                      />
                      <Text>CIDADE DE ORIGEM</Text>
                    </Stack>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Cidade Destino..."
                        handleSearch={(e) =>
                          setDestinationCityFiltered(e.target.value)
                        }
                      />
                      <Text>CIDADE DESTINO</Text>
                    </Stack>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Data Coleta..."
                        handleSearch={(e) =>
                          setCollectDateFiltered(e.target.value)
                        }
                      />
                      <Text>DATA DA COLETA</Text>
                    </Stack>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Data Entrega..."
                        handleSearch={(e) =>
                          setDeliveryDateFiltered(e.target.value)
                        }
                      />
                      <Text>DATA DA ENTREGA</Text>
                    </Stack>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Stack>
                      <SearchBox
                        placeholder="Buscar Etapa..."
                        handleSearch={(e) => setStepFiltered(e.target.value)}
                      />
                      <Text>ETAPA</Text>
                    </Stack>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchedSlaReport
                  .slice(offset, offset + Number(itemLimit))
                  .map((slaReport, index) => {
                    return (
                      <Tr key={index}>
                        <Td>{slaReport.protocol}</Td>
                        <Td>{slaReport.customer.toUpperCase()}</Td>
                        <Td>{slaReport.sourceCity.toUpperCase()}</Td>
                        <Td>{slaReport.destinationCity.toUpperCase()}</Td>
                        <Td>
                          {serviceFormatDateToFrontTable(
                            slaReport.expectedCollectTime,
                          )}
                        </Td>
                        <Td>
                          {serviceFormatDateToFrontTable(
                            slaReport.expectedDeliveryTime,
                          )}
                        </Td>
                        <Td>{slaReport.step.toUpperCase()}</Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </Box>
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}
