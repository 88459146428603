import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CompleteMaintenanceAuditBody {
  realizedDate: string
  nonCompliance?: string
  reportAttachmentId: string
  status: string
}

interface CompleteMaintenanceAuditParams {
  body: CompleteMaintenanceAuditBody
  auditId: string
}

export async function completeMaintenanceAudit({
  body,
  auditId
}: CompleteMaintenanceAuditParams) {
  try {
    await api.put(`/audits/${auditId}/maintenance`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
