import { Button, Flex, Input, Select } from "@chakra-ui/react";
import { AsoStatus } from "api/asos/aso";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface AsosTableFiltersSchema {
  collaborator: string
  status: AsoStatus | 'all'
}

export function AsosTableFilters() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const collaborator = searchParams.get('collaborator')
  const status = searchParams.get('status') ?? 'all'

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<AsosTableFiltersSchema>({
    defaultValues: {
      collaborator: collaborator ?? '',
      status: status as AsoStatus | 'all',
    }
  })

  async function handleFilterAsos(data: AsosTableFiltersSchema) {
    if (data.collaborator) {
      searchParams.set('collaborator', data.collaborator)
    } else {
      searchParams.delete('collaborator')
    }
    if (
      data.status) {
      searchParams.set('status', data.status)
    } else {
      searchParams.delete('status')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleClearFilters() {
    searchParams.delete('collaborator')
    searchParams.delete('hub')
    searchParams.delete('status')

    searchParams.set('page', '1')

    reset({
      collaborator: '',
      status: 'all'
    })

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterAsos)}
    >
      <Input
        {...register("collaborator")}
        placeholder="Colaborador"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />

      <Select
        {...register("status")}
        defaultValue="all"
        size="sm"
        rounded="md"
        w={["full", "full", "200px"]}
      >
        <option value="all">Todos status</option>
        <option value="pending">Pendente</option>
        <option value="active">Ativo</option>
        <option value="inactive">Inativo</option>
      </Select>

      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={["full", "full", "min"]}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        w={["full", "full", "min"]}
        onClick={handleClearFilters}
      >
        Remover filtros
      </Button>

    </Flex>
  )
}
