import { Box, Flex, Text } from "@chakra-ui/react"
import { BillingStatus as BillingStatusTypes } from "api/billings/billing"

interface BillingStatusProps {
  status: BillingStatusTypes | 'all'
}

const billingStatusMap: Record<BillingStatusTypes | 'all', string> = {
  "awaiting-billing": 'Em faturamento',
  "awaiting-receive": 'Recebendo',
  all: 'Todos status',
  billed: 'Faturado',
  done: 'Finalizado'
}

export function BillingStatus({ status }: BillingStatusProps) {
  return (
    <Flex
      align="center"
      gap="2"
    >
      {['awaiting-billing', 'awaiting-receive'].includes(status) && (
        <Box w="2" h="2" rounded="full" bgColor="orange.300" />
      )}

      {status === 'billed' && (
        <Box w="2" h="2" rounded="full" bgColor="blue.300" />
      )}

      {status === 'done' && (
        <Box w="2" h="2" rounded="full" bgColor="green.300" />
      )}
      <Text>{billingStatusMap[status]}</Text>
    </Flex>
  )
}
