import { Box, Flex } from "@chakra-ui/react";
import { StandardizationStatus as StandardizationStatusTypes } from "api/standardizations/standardization";

interface StandardizationStatusProps {
  status: StandardizationStatusTypes;
}

export const standardizationStatusMap: Record<StandardizationStatusTypes, string> = {
  approved: 'Aprovada',
  reproved: 'Reprovada',
  pending: 'Pendente'
}


export function StandardizationStatus({ status }: StandardizationStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {status === 'pending' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'approved' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'reproved' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {standardizationStatusMap[status]}
    </Flex>
  )
}
