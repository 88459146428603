import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { startDimissCltDriver } from "api/cltDrivers/startDimissCltDriver";
import { useToastify } from "hooks/toastify/useToastify";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { MoveCltDriver } from "./MoveCltDriver";

interface CltDriverActionPanelProps {
  cltDriverId: string
}

export function CltDriverActionPanel({ cltDriverId }: CltDriverActionPanelProps) {
  const [isSuspendFormVisible, setSuspendFormVisible] = useState(false);
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const handleSuspendDriver = () => {
    setSuspendFormVisible(!isSuspendFormVisible);
  };
  const { mutateAsync: startDimissCltDriverFn } = useMutation({
    mutationFn: startDimissCltDriver,
    onSuccess(_data, { cltDriverId }) {
      queryClient.invalidateQueries({ queryKey: 'clts-drivers' })
      queryClient.invalidateQueries({ queryKey: 'dismissing-legal-prevention' })
      queryClient.invalidateQueries({ queryKey: 'active' })
      queryClient.invalidateQueries({ queryKey: 'moved' })
      queryClient.invalidateQueries({ queryKey: ['clts-drivers', cltDriverId] })
    }
  })

  async function handleStartDimissCltDriver() {
    await promiseMessage(startDimissCltDriverFn({
      cltDriverId
    }), 'Processo de demissão iniciado!')
  }

  return (
    <Box
      w="full"
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'>Painel de ações - Motorista</Heading>
      <Flex mt={3} gap={4}>

        <Button
          size="md"
          colorScheme='red'
          variant='outline'
          onClick={handleStartDimissCltDriver}
        >
          Demitir
        </Button>
        <Button
          size="md"
          colorScheme='blue'
          onClick={handleSuspendDriver}
        >
          Afastar
        </Button>
      </Flex>

      {isSuspendFormVisible && (
        <MoveCltDriver
          cltDriverId={cltDriverId}
        />
      )}

    </Box>
  )
}
