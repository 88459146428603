import * as yup from "yup"
import { GridItem, Flex, FormLabel, Icon, Button, Link, TableContainer, Table, Thead, Tr, Th, TableCaption, Tbody, Td } from "@chakra-ui/react"
import { Input } from "components/Inputs/Input"
import { useForm, useWatch } from "react-hook-form"
import { FaExternalLinkAlt, FaFile } from "react-icons/fa"
import { yupResolver } from "@hookform/resolvers/yup"
import { validateHasFile } from "utils/fileValidation"
import { RncAlignments } from "utils/RequestFunctions/Rnc/requestRncFunctions"
import { useRncFunctions } from "hooks/rnc/useRncFunctions"
import { useQueryClient } from "react-query"
import { useToastify } from "hooks/toastify/useToastify"
import { handleChangeUrl } from "utils/handleChangeUrl"

interface SendRncAlignmentAttachmentsFormInputs {
  alignments: {
    attachments: FileList
  }[]
}

interface AlignmentAttachment {
  key: string
  filename: string
}

const sendRncAlignmentAttachmentsFormSchema = yup.object().shape({
  alignments: yup.array().min(1, 'Campo obrigatório').of(yup.object({
    attachments: yup.mixed().test('hasFile', 'Campo obrigatório.', value => validateHasFile(value))
  }))
})

interface SendRncAlignmentAttachmentsFormProps {
  index: number
  alignment: RncAlignments
}

export function SendRncAlignmentAttachmentsForm({
  index,
  alignment
}: SendRncAlignmentAttachmentsFormProps) {

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<SendRncAlignmentAttachmentsFormInputs>({
    resolver: yupResolver(sendRncAlignmentAttachmentsFormSchema)
  })

  const inputedAttachments = useWatch({
    control,
    name: `alignments.${index}.attachments`
  })

  const {
    sendInternRncAlignmentAttachments
  } = useRncFunctions()

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  async function handleSendRncAlignmentAttachments(values: SendRncAlignmentAttachmentsFormInputs) {
    const formData = new FormData()

    const existentAlignmentAttachments = values.alignments.filter(alignment => alignment.attachments)

    Object.entries(existentAlignmentAttachments[0].attachments).forEach(
      ([key, file]) => formData.append('attachments', file)
    )

    await promiseMessage(sendInternRncAlignmentAttachments.mutateAsync({
      internRncAlignmentId: alignment.id,
      input: formData
    }, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['rnc'])
      }
    }), 'Anexos salvos com sucesso!')
  }

  const alignmentAttachments: AlignmentAttachment[] = alignment.attachments
    ? typeof alignment.attachments === 'string'
      ? JSON.parse(alignment.attachments)
      : alignment.attachments
    : null

  return (
    <GridItem
      as="form"
      onSubmit={handleSubmit(handleSendRncAlignmentAttachments)}
    >
      <Flex direction="column">
        <Input
          name="alignedBy"
          defaultValue={`${alignment?.alignedBy?.firstname} ${alignment?.alignedBy?.lastname}`}
          label="Responsável pelo alinhamento"
          isDisabled
        />

        {alignmentAttachments && (
          <TableContainer>
            <Table size="sm" variant="simple">
              <TableCaption>Arquivos anexados</TableCaption>
              <Thead>
                <Tr>
                  <Th>Nome</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {alignmentAttachments.map((attachment) => {
                  return (
                    <Tr key={attachment.key}>
                      <Td>{attachment.filename}</Td>
                      <Td>
                        <Button
                          as={Link}
                          href={handleChangeUrl(attachment.key)}
                          variant="outline"
                        >
                          <Icon as={FaExternalLinkAlt} />
                        </Button>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        {!alignmentAttachments && (
          <>
            {inputedAttachments && (
              <TableContainer>
                <Table size="sm" variant="simple">
                  <TableCaption>Arquivos selecionados</TableCaption>
                  <Thead>
                    <Tr>
                      <Th>Nome</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {Object.entries(inputedAttachments).map(([key, file]) => {
                      return (
                        <Tr key={file.name}>
                          <Td>{file.name}</Td>
                          <Td>
                            <Button
                              as={Link}
                              href={URL.createObjectURL(file)}
                              variant="outline"
                            >
                              <Icon as={FaExternalLinkAlt} />
                            </Button>
                          </Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
            <FormLabel
              mt="2"
              border="1px solid"
              borderColor="blue.50"
              rounded="md"
              htmlFor={`alignments.${index}.attachments`}
              cursor="pointer"
              display="flex"
              alignItems="center"
              w="full"
              gap={2}
              justifyContent="center"
              _hover={{
                bg: 'blue.100'
              }}
            >
              <Icon w="4" h="4" as={FaFile} />
              Selecione um arquivo
            </FormLabel>

            <Input
              {...register(`alignments.${index}.attachments`)}
              name={`alignments.${index}.attachments`}
              type="file"
              hidden
              error={
                errors.alignments
                  ? errors?.alignments[index]?.attachments
                  : undefined
              }
            />
            <Button
              alignSelf="flex-end"
              colorScheme="blue"
              type="submit"
            >
              Salvar
            </Button>
          </>
        )}
      </Flex>
    </GridItem>
  )
}
