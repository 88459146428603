import { Td, Tr } from "@chakra-ui/react";
import { GetAuditResponse } from "api/audits/getAudit";

interface AuditRhLlmTableRowProps {
  audit: GetAuditResponse
}

export function AuditRhLlmTableRow({
  audit
}: AuditRhLlmTableRowProps) {
  return (
    <>
      <Tr>
        <Td>Cartão de ponto batido corretamente?</Td>
        <Td isNumeric>{audit?.audit?.llmHumanResourcesAudit?.isTimeCardRecordedCorrectly ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Onboarding finalizado?</Td>
        <Td isNumeric>{audit?.audit?.llmHumanResourcesAudit?.isFinishedOnboarding ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>ASO vigente?</Td>
        <Td isNumeric>{audit?.audit?.llmHumanResourcesAudit?.isWithAso ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Conta salário aberta?</Td>
        <Td isNumeric>{audit?.audit?.llmHumanResourcesAudit?.isOpenedSalaryAccount ? 'SIM' : 'NÃO'}</Td>
      </Tr>
    </>
  )
}
