import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

export interface GetServicesValidatedWithSameModalResponse {
  services: {
    id: string
    protocol: number
    customer: {
      id: string
      tradingFirstname: string
    },
    boardDate: string
    boardHour: string
    sourceBranch: {
      id: string
      nickname: string
    },
    destinationBranch: {
      id: string
      nickname: string
    },
    shipping: {
      id: string
      tradingName: string
    }
  }[]
}

interface GetServicesValidatedWithSameModalParams {
  collectorId: string
  destinationHubId: string
  modal: string
  sourceHubId: string
}


export async function getServicesValidatedWithSameModal({
  collectorId,
  destinationHubId,
  modal,
  sourceHubId
}: GetServicesValidatedWithSameModalParams) {
  try {
    const { data } = await api.get<GetServicesValidatedWithSameModalResponse>(`services/validated/with-same-modal`, {
      params: {
        collectorId,
        destinationHubId,
        modal,
        sourceHubId
      }
    })



    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
