import { Box, Button, Flex, Spinner, Stack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  addDays,
  differenceInMinutes,
  format,
  isDate,
  set,
  toDate,
} from 'date-fns'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert'

import * as yup from 'yup'
import { Input } from '../../../components/Inputs/Input'
import { Select } from '../../../components/Inputs/SelectInput'
import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'


import { RecurrentServiceTable } from '../../../components/Tables/Service/Recurrent/RecurrentTable'
import { AuthContext } from '../../../contexts/AuthContext'
import { RequestedServiceContext } from '../../../contexts/ServiceContext'
import { useCustomer } from '../../../hooks/customer/useCustomer'
import { useRecurrentServiceByDayOfWeek } from '../../../hooks/recurrentService/useRecurrentServiceByDayOfWeek'
import { useAddresses } from '../../../hooks/address/useAddresses'
import { days_of_week } from '../../../utils/customLists'

interface FormInputProps {
  day_of_week: number
  date_filter: string
}

function formatCollectDateToValidate(value: any, originalValue: string) {
  const formatedDate = isDate(originalValue)
    ? originalValue
    : toDate(addDays(new Date(originalValue), 1))
  return formatedDate
}

const today = new Date()

const executeSchema = yup.object().shape({
  day_of_week: yup
    .number()
    .typeError('Campo Obrigatório')
    .required('Campo Obrigatório'),
  date_filter: yup
    .date()
    .transform(formatCollectDateToValidate)
    .min(
      today,
      `A data de coleta deve ser dia ${format(
        new Date(today),
        'dd/MM/yyyy',
      )} ou após esta data`,
    ),
})

export function ExecuteRecurrentService() {
  const [dayOfWeek, setDayOfWeek] = useState<number | null>(null)
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isDataLoading, setIsDataLoading] = useState(false)

  const dateNow = format(new Date(), 'yyyy-MM-dd')

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    resolver: yupResolver(executeSchema),
  })

  const dateInput = watch('date_filter')

  const history = useHistory()
  const { userLogged } = useContext(AuthContext)
  const { createRecurrentServiceByDayOfWeek } = useContext(
    RequestedServiceContext,
  )

  const {
    recurrentServiceByDayOfWeek: {
      data: recurrentServiceByDayOfWeek,
      isLoading: isRecurrentServiceByDayOfWeekLoading,
      refetch,
    },
  } = useRecurrentServiceByDayOfWeek(dayOfWeek, false)
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()

  const {
    customers: { data: customers, isLoading: isCustomerLoading },
  } = useCustomer(null, true, false)

  useEffect(() => {
    function run() {
      if (!isAddressesLoading && !isCustomerLoading) {
        setIsPageLoading(false)
      }
    }
    run()
  }, [isAddressesLoading, isCustomerLoading])

  useEffect(() => {
    function run() {
      if (isRecurrentServiceByDayOfWeekLoading) {
        setIsDataLoading(true)
      } else {
        setIsDataLoading(false)
      }
    }
    run()
  }, [isRecurrentServiceByDayOfWeekLoading])

  function handleGetValue() {
    const daySelected = getValues('day_of_week')
    setDayOfWeek(daySelected)
  }

  function handleClearValuesOnChange() {
    setValue('date_filter', '')
  }

  async function handleCreateRecurrentServicesByDayOfWeek() {
    const dayOfWeekSelected = days_of_week
      .filter((day) => day.value === Number(dayOfWeek))
      .map((day) => day.name)
      .toString()
    const dateSelected = getValues('date_filter')
    const dateSelectedFormated = format(
      addDays(new Date(dateSelected), 1),
      'dd/MM/yyyy',
    )
    const dateSelectedToBack = format(
      addDays(new Date(dateSelected), 1),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
    )

    await swal({
      title: `Deseja criar serviços recorrentes de ${dayOfWeekSelected} para o dia ${dateSelectedFormated}?`,
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willDelete) => {
      if (willDelete && !!recurrentServiceByDayOfWeek && !!userLogged) {
        const response = await createRecurrentServiceByDayOfWeek(
          Number(dayOfWeek),
          dateSelectedToBack,
          userLogged.id,
        )
        if (response) {
          refetch()
          setTimeout(() => {
            history.push('/servicos')
          }, 1500)
        }
      } else {
        swal('Ação cancelada com êxito!')
      }
    })
  }

  if (isPageLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Box
        as="form"
        flex="1"
        borderRadius="8px"
        bg="white"
        p={['6', '8']}
        overflowX="auto"
        onSubmit={handleSubmit(handleGetValue)}
        noValidate
      >
        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Select
            {...register('day_of_week')}
            isDisabled={false}
            days_of_week={days_of_week}
            placeholder="Selecione uma opção..."
            onChange={handleClearValuesOnChange}
            error={errors.day_of_week}
            name="day_of_week"
            label="Dia da Semana"
            required
          />

          <Input
            {...register('date_filter')}
            isDisabled={false}
            min={dateNow}
            name="date_filter"
            type="date"
            label="Data da Coleta"
            error={errors.date_filter}
            required
          />
        </Stack>

        {isDataLoading && (
          <Flex justify="center" mt="8">
            <Spinner />
          </Flex>
        )}
        <Box mt="8">
          {!!recurrentServiceByDayOfWeek && !!addresses && !!customers && (
            <RecurrentServiceTable
              recurrentServices={recurrentServiceByDayOfWeek}
              refetch={refetch}
              addresses={addresses}
            />
          )}
        </Box>

        <Flex mt="8" justify="flex-end">
          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Button type="submit" colorScheme="gray" isLoading={isSubmitting}>
              Filtrar Serviços
            </Button>

            {!!recurrentServiceByDayOfWeek &&
              watch('date_filter') !== '' &&
              differenceInMinutes(
                set(new Date(), { hours: 12, minutes: 0 }),
                set(addDays(new Date(dateInput), 1), { hours: 12, minutes: 0 }),
              ) <= 0 && (
                <Button
                  colorScheme="blue"
                  onClick={handleCreateRecurrentServicesByDayOfWeek}
                >
                  Criar Serviços
                </Button>
              )}
          </Stack>
        </Flex>
      </Box>

  )
}
