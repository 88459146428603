import { Button, Flex, Input } from "@chakra-ui/react";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface PatrimonyTypesTableFiltersSchema {
  type: string
}

export function PatrimonyTypesTableFilters() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const type = searchParams.get('type')

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<PatrimonyTypesTableFiltersSchema>({
    defaultValues: {
      type: type ?? '',
    }
  })

  async function handleFilterOnboardings(data: PatrimonyTypesTableFiltersSchema) {
    if (data.type) {
      searchParams.set('type', data.type)
    } else {
      searchParams.delete('type')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    reset()
    searchParams.delete('type')
    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }


  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterOnboardings)}
    >

      <Input
        {...register("type")}
        placeholder="Tipo"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />
      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={["full", "full", "min"]}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleRemoveFilters}
        w={["full", "full", "min"]}
      >
        Remover Filtros
      </Button>

    </Flex>
  )
}
