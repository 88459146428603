import { Box, BoxProps, useRadio, UseRadioProps } from "@chakra-ui/react";
import { captalize } from "utils/captalize";

interface CollaboratorRadioItemProps {
  props: UseRadioProps
  radio: {
    styles: BoxProps,
    value: string,
  }
  onClosePop: () => void
  onSetCollaboratorName: (name: string) => void
}

export function CollaboratorRadioItem({ props, radio, onClosePop, onSetCollaboratorName }: CollaboratorRadioItemProps) {

  const { getInputProps, getRadioProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Box
      as="label"
      onClick={onClosePop}
    >
      <input {...input} onChange={e => onSetCollaboratorName(e.target.value)}/>
      <Box
        {...checkbox}
        cursor='pointer'
        display="flex"
        alignItems="center"
        px="2"
        _checked={{
          bg: '#38C3FA',
          color: 'white',
          borderColor: 'teal.600',
        }}
        _focus={{
          boxShadow: '0 0 0 2px #38c3fa'
        }}
        {...radio.styles}
      >
        {captalize(radio.value)}
      </Box>
    </Box>
  )
}
