import { Box, Card, CardBody, CardHeader, Flex, Grid, GridItem, Heading, IconButton, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import { getCustomerDeliveries } from "api/service/getCustomerDeliveries"
import { Empty } from "components/Empty"

import { useAuth } from "hooks/auth/useAuth"
import { FaArrowLeft } from "react-icons/fa"
import { useQuery } from "react-query"
import { Link, useHistory } from "react-router-dom"
import { captalize } from "utils/captalize"
import { serviceHandleStep } from "utils/ServiceFunctions/serviceDict"
import { InfoCardsLoading } from "../components/InfoCardsLoading"

export function Deliveries() {
  const { userLogged } = useAuth()

  const {
    data: result,
    isError,
    isLoading: isLoadingDeliverieservicesWithDelayOccurrence
  } = useQuery({
    queryKey: ['customer-deliveries'],
    queryFn: () => getCustomerDeliveries({ customerId: userLogged?.customer_id })
  })

  const handleReloadPage = () => window.location.reload()

  const history = useHistory()

  const handleReturnToDashboard = () => history.goBack()

  if (isError) {
    return (
      <Empty.Root>
        <Empty.ActionButton onClick={handleReloadPage}>Recarregar a página</Empty.ActionButton>
      </Empty.Root>
    )
  }

  return (

      <Box
        p="6"
        rounded="md"
        bg="white"
        display="flex"
        flexDirection="column"
        gap="6"
      >
        <Flex
          align="center"
          gap="2"
        >
          <IconButton
            aria-label="Voltar ao dashboard"
            icon={<FaArrowLeft />}
            variant="ghost"
            size="sm"
            onClick={handleReturnToDashboard}
          />
          <Heading letterSpacing="tight">Serviços em Entrega</Heading>
        </Flex>

        <Grid
          gap="6"
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }}
        >
          {isLoadingDeliverieservicesWithDelayOccurrence && (
            <InfoCardsLoading />
          )}
          {result?.services?.map((service) => {
            return (
              <GridItem
                key={service.id}
              >
                <Card
                  rounded="xl"
                  transition="all 0.2s"
                  _hover={{
                    transform: 'translateY(-0.5rem)'
                  }}
                  h="full"
                >
                  <CardHeader
                    as={Link}
                    display="flex"
                    alignItems="center"
                    to={`/servico/detalhes/${service.id}`}
                    h="40px"
                    bg="#38c3fa"
                    borderTopRadius="xl"
                  >
                    <Text fontWeight="bold" color="white">{service.protocol}</Text>
                  </CardHeader>

                  <CardBody>
                    <Table size="sm">
                      <Tbody>
                        <Tr>
                          <Td>Status</Td>
                          <Td isNumeric>{captalize(serviceHandleStep(service.step))}</Td>
                        </Tr>
                        <Tr>
                          <Td>Cliente</Td>
                          <Td isNumeric>{captalize(service.customer)}</Td>
                        </Tr>
                        <Tr>
                          <Td>Hub de Origem</Td>
                          <Td isNumeric>{captalize(service.source_hub)}</Td>
                        </Tr>
                        <Tr>
                          <Td>Hub de Destino</Td>
                          <Td isNumeric>{captalize(service.destination_hub)}</Td>
                        </Tr>
                        <Tr>
                          <Td>Transportadora</Td>
                          <Td isNumeric>{service.shipping ? captalize(service.shipping) : '-'}</Td>
                        </Tr>
                        <Tr>
                          <Td>Fuso Origem</Td>
                          <Td isNumeric>{service.source_tz ?? '-'}</Td>
                        </Tr>
                        <Tr>
                          <Td>Fuso Destino</Td>
                          <Td isNumeric>{service.destination_tz ?? '-'}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </CardBody>
                </Card>
              </GridItem>
            )
          })}
        </Grid>
      </Box>

  )
}
