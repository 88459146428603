import axios from "axios"
import { useAuth } from "hooks/auth/useAuth"
import { useMutation, useQueryClient } from "react-query"
import { api } from "services/api"
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum"
import { RequestError } from "utils/errors/RequestErrors"
import { AdministrativeRegionConditional, PeriodConditional, DayConditional } from "./dtos/CollectorCost"

interface CreateCollectorCostInput {
  collector_id: string
  city_id: string
  cost_motorcycle: number
  additional_cost_motorcycle: number
  cost_car: number
  additional_cost_car: number
  cost_truck: number
  additional_cost_truck: number
  observation?: string
  conditionals: {
    admRegionConditionals: AdministrativeRegionConditional[]
    periodConditionals: PeriodConditional[]
    dayConditionals: DayConditional[]
  }
  is_primary_collector: boolean
}

interface UpdateCollectorCostReqProps {
  id: string
  input: CreateCollectorCostInput
}



async function createCollectorCostReqFunction(input: CreateCollectorCostInput) {
  try {
    const { data } = await api.post(`/cost/collector/`, input)
    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

async function updateCollectorCostReqFunction({
  id,
  input
}: UpdateCollectorCostReqProps) {
  try {
    await api.put(`/cost/collector/${id}`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

async function deleteCollectorCostReqFunction(id: string) {
  try {
    await api.delete(`/cost/collector/${id}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

interface UpdateCollectorCostAttachmentsReqProps {
  collectorCostId: string
  input: FormData
}

async function updateCollectorCostAttachmentReqFunction({
  collectorCostId,
  input
}: UpdateCollectorCostAttachmentsReqProps) {
  try {
    await api.patch(`/collectors/cost/${collectorCostId}/attachments`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado")
    }
  }
}

interface DeleteCollectorCostAttachmentsReqProps {
  collectorCostId: string
  fileKey: string
}

async function deleteCollectorCostAttachmentReqFunction({
  collectorCostId,
  fileKey
}: DeleteCollectorCostAttachmentsReqProps) {
  try {
    await api.delete(`/collectors/cost/${collectorCostId}/attachments/${fileKey}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado")
    }
  }
}

export function useCollectorCostFunctions() {

  const { handleLogout } = useAuth()

  const queryClient = useQueryClient()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  const onRequestSuccess = async () => {
    await queryClient.invalidateQueries(['collectorCosts'])
  }

  const createCollectorCost = useMutation(
    'createCollectorCost', createCollectorCostReqFunction, {
    onError: onRequestError,
    onSuccess: (data) => {
      onRequestSuccess()
      return data
    }
  })

  const updateCollectorCost = useMutation(
    'updateCollectorCost', updateCollectorCostReqFunction, {
    onError: onRequestError,
    onSuccess: onRequestSuccess
  })

  const deleteCollectorCost = useMutation(
    'deleteCollectorCost', deleteCollectorCostReqFunction, {
    onError: onRequestError,
    onSuccess: onRequestSuccess
  })

  const updateCollectorCostAttachments = useMutation(
    'updateCollectorCostAttachments', updateCollectorCostAttachmentReqFunction, {
    onError: onRequestError,
    onSuccess: onRequestSuccess
  })

  const deleteCollectorCostAttachment = useMutation(
    'deleteCollectorCostAttachment', deleteCollectorCostAttachmentReqFunction, {
    onError: onRequestError,
    onSuccess: onRequestSuccess
  })

  return {
    createCollectorCost,
    updateCollectorCost,
    deleteCollectorCost,
    updateCollectorCostAttachments,
    deleteCollectorCostAttachment
  }
}
