import { Box, Button, Divider, Flex, Heading, Icon, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react"

import { Link, useHistory } from "react-router-dom"
import { usePagination } from "@ajna/pagination"
import { useEffect, useState } from "react"
import { Pagination } from "components/Pagination/Pagination"
import { FaEye, FaFileArchive } from "react-icons/fa"
import { useAuth } from "hooks/auth/useAuth"
import { useInternRncsByStatus } from "hooks/rnc/useInternRncsByStatus"
import { format, parseISO } from "date-fns"
import { FinishedRncModal } from "components/Modal/Rnc/FinishedRncModal"

export function AwaitingAttachmentRncsList() {
  const [selectedRncId, setSelectedRncId] = useState<string | null>(null)

  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()

  const { push: redirect, location } = useHistory()

  const userHasPermissionToSendAlignmentAttachment =
    userLogged?.permissions.includes('send-intern-rnc-alignment-attachments')

  useEffect(() => {
    if (!userHasPermissionToSendAlignmentAttachment) {
      redirect('/')
    }
  }, [userHasPermissionToSendAlignmentAttachment, redirect])

  const currentPageFilter = location.search.match(`[?&]page=([^&]+)`)

  const {
    data: internRncsData,
    isFetching: isFetchingInternRncsData
  } = useInternRncsByStatus({
    queryParams: {
      pageSize: Number(rowsPerPage),
      currentPage: currentPageFilter ? Number(currentPageFilter[1]) : 1
    },
    status: 'AWAITING ATTACHMENT'
  })

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: internRncsData?.totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  const handleChangePage = (page: number) => setCurrentPage(page)

  useEffect(() => {
    if (userHasPermissionToSendAlignmentAttachment) {
      redirect({
        pathname: '/rncs/pendente-anexo',
        search: `?page=${currentPage}`
      })
    }
  }, [currentPage, redirect, userHasPermissionToSendAlignmentAttachment])

  const {
    isOpen: isRncModalOpen,
    onOpen: onOpenRncModal,
    onClose: onCloseRncModal
  } = useDisclosure()

  function handleOpenRncModal(rncId: string) {
    setSelectedRncId(rncId)
    onOpenRncModal()
  }

  return (

      <Box
        p={4}
        bg='white'
        rounded='lg'
      >

        <FinishedRncModal
          rncID={selectedRncId}
          isOpen={isRncModalOpen}
          onClose={onCloseRncModal}
        />

        <Flex
          gap={4}
          direction='column'
        >
          <Heading size='lg'>RNCs Aguardando Anexo</Heading>

          <Divider />

          {isFetchingInternRncsData ? (
            <Spinner />
          ) : (
            <TableContainer>
              <Table size='sm' variant='striped'>
                <Thead>
                  <Tr>
                    <Th>PROTOCOLO</Th>
                    <Th>DIRETORIA</Th>
                    <Th>DATAS DE ALINHAMENTO</Th>
                    <Th>RESPONSÁVEIS PELOS ALINHAMENTOS</Th>
                    <Th>REUNIÃO DE TRATATIVA</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {internRncsData?.rncs?.map((rnc) => {
                    const alignmentsResponsibles = rnc?.alignments?.map(
                      (alignment) => `${alignment.alignedBy.firstname} ${alignment.alignedBy.lastname}`
                    )

                    const alignmentsDates = rnc?.alignments?.map(
                      (alignment) => `${format(parseISO(alignment.date), 'dd/MM/yyyy')}`
                    )

                    return (
                      <Tr key={rnc.id}>
                        <Td>{rnc.protocol}</Td>
                        <Td>{rnc.direction}</Td>
                        <Td>{alignmentsDates.join(', ')}</Td>
                        <Td>{alignmentsResponsibles.join(', ')}</Td>
                        <Td>{rnc.business_meeting}</Td>
                        <Td isNumeric>
                          <Button
                            as={Link}
                            variant="ghost"
                            to={`/rnc/${rnc.id}/anexar`}
                          >
                            <Icon as={FaFileArchive} />
                          </Button>
                          <Button
                            variant="ghost"
                            onClick={() => handleOpenRncModal(rnc.id)}
                          >
                            <Icon as={FaEye}/>
                          </Button>
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          <Pagination
            currentPage={currentPage}
            pages={pages}
            pagesQuantity={pagesCount}
            handlePageChange={handleChangePage}
          />
        </Flex>
      </Box>

  )
}
