import { Box, Flex } from "@chakra-ui/react";
import { AdvertenceStatus as AdvertenceStatusTypes } from "api/advertences/types/Advertence";
import { advertenceStatusMap } from "utils/advertenceMapppers";

interface AdvertenceStatusProps {
  status: AdvertenceStatusTypes;
}

export function AdvertenceStatus({ status }: AdvertenceStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {(status === 'awaiting-application' || status === 'parent-verification') && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'done' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'cancelled' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}


      {advertenceStatusMap[status]}
    </Flex>
  )
}
