import * as yup from 'yup'

const schema = yup.object().shape({
  user_type: yup.string().required('Campo Obrigatório'),
  loglife_employee: yup.string().required('Campo Obrigatório'),
  firstname: yup.string().required('Campo Obrigatório'),
  lastname: yup.string().required('Campo Obrigatório'),
  customers: yup.mixed().when('user_type', {
    is: 'CLIENTE',
    then: yup.array().min(1, 'Campo obrigatório').required('Campo Obrigatório'),
  }),
  collector_id: yup.string().when('user_type', {
    is: (value: string) => value === 'MOTORISTA',
    then: yup.string().required('Campo Obrigatório'),
  }),
  driver_id: yup.string().when('user_type', {
    is: 'MOTORISTA',
    then: yup.string().required('Campo Obrigatório'),
  }),
  email: yup
    .string()
    .email('Formato de e-mail inválido')
    .required('Campo Obrigatório'),
  permissions: yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
      case 'string':
        return yup.string().required('Campo Obrigatório')
      default:
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
    }
  }),
})

export { schema }
