import * as yup from "yup"
import { Box, Button, Icon, InputRightElement, Text, useDisclosure } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "hooks/auth/useAuth";
import { LoginCustomerSelectorModal } from "./LoginCustomerSelectorModal";
import { CustomerProps } from "contexts/CustomerContext";
import { LoginCollectorSelectorModal } from "./LoginCollectorSelectorModal";

interface LoginFormInputs {
  email: string
  password: string
  passwordConfirmation: string
}

interface Collector {
  id: string
  trading_name: string
}

const loginFormSchema = yup.object().shape({
  email: yup.string().required('Email obrigatório'),
  password: yup.string().required('Senha obrigatória'),
})

export function LoginForm() {
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [userCustomers, setUserCustomers] = useState<CustomerProps[]>([])
  const [userCollectors, setUserCollectors] = useState<Collector[]>([])

  const handleSetIsShowPassword = () => setIsShowPassword(!isShowPassword)

  const { push: redirect } = useHistory()

  const { handleLogin: login } = useAuth()

  const {
    onOpen: onOpenLoginCustomerSelectorModal,
    isOpen: isLoginCustomerSelectorModalOpen,
    onClose: onCloseLoginCustomerSelectorModal,
  } = useDisclosure()

  const {
    onOpen: onOpenLoginCollectorSelectorModal,
    isOpen: isLoginCollectorSelectorModalOpen,
    onClose: onCloseLoginCollectorSelectorModal,
  } = useDisclosure()

  async function handleLogin(values: LoginFormInputs) {
    const data = await login({
      email: values.email,
      password: values.password
    })

    if (data) {
      if (data.user.user_type === 'CLIENTE' || data.user.user_type === 'REMETENTE') {
        onOpenLoginCustomerSelectorModal()
        setUserCustomers(data.user.customers)
      } else if (data.user.user_type === 'COLETADOR') {
        onOpenLoginCollectorSelectorModal()
        setUserCollectors(data.user.collectors.map(collector => {
          return {
            id: collector.collectorId,
            trading_name: collector.collector.trading_name
          }
        }))
      } else {
        redirect('/')
      }
    }

  }

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(loginFormSchema)
  })

  return (
    <>
      <LoginCustomerSelectorModal
        isOpen={isLoginCustomerSelectorModalOpen}
        onClose={onCloseLoginCustomerSelectorModal}
        userCustomers={userCustomers}
      />

      <LoginCollectorSelectorModal
        isOpen={isLoginCollectorSelectorModalOpen}
        onClose={onCloseLoginCollectorSelectorModal}
        userCollectors={userCollectors}
      />
      <Box
        as="form"
        display="flex"
        flexDirection="column"
        gap="8"
        w="full"
        onSubmit={handleSubmit(handleLogin)}
        noValidate
      >

        <Input
          {...register("email")}
          name="email"
          type="email"
          label="E-mail"
          error={errors.email}
          required
        />
        <Input
          {...register("password")}
          name="password"
          label="Senha"
          type={isShowPassword ? "text" : "password"}
          display="flex"
          error={errors.password}
          alignItems="center"
          justifyContent="center"
          required
        >
          <InputRightElement
            as={Button}
            variant="unstiled"
            onClick={handleSetIsShowPassword}
            display="flex"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            {isShowPassword ? (
              <Icon fontSize="xl">
                <FaEye />
              </Icon>
            ) : (
              <Icon fontSize="xl">
                <FaEyeSlash />
              </Icon>
            )}
          </InputRightElement>
        </Input>

        <Text
          as={Link}
          to="/recuperar-senha"
          alignSelf="flex-end"
          fontSize="sm"
          fontWeight="bold"
          transition="all 0.2s"
          _hover={{
            opacity: 0.9
          }}
        >
          Esqueceu a senha?
        </Text>

        <Button
          w={["full", "full", "150px"]}
          boxShadow="md"
          colorScheme="blue"
          type="submit"
          isLoading={isSubmitting}
        >
          Login
        </Button>
      </Box>
    </>
  )
}
