import { Box, FormControl, FormLabel, Input, Select, Stack, Text, Textarea } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext, } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";


export function PaymentRequestForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      suggestedPaymentDate: 'Data de pagamento sugerida',
      costCenter: 'Centro de custo',
      isPaymentReceiptNeeded: 'Precisa do comprovante de pagamento?',
      paymentDescription: 'Descrição do pagamento',
    };


    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };


  return (
    <Box w='full'>

      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.suggestedPaymentDate} >
            <FormLabel fontSize='sm'>
              Data de pagamento sugerida
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.suggestedPaymentDate.value')}
              name='formData.suggestedPaymentDate.value'
              type="date"
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('suggestedPaymentDate')}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.costCenter}>
            <FormLabel fontSize='sm'>
              Centro de custo
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.costCenter.value')}
              name='formData.costCenter.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('costCenter')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <FormControl isInvalid={!!errors.formData?.isPaymentReceiptNeeded} mt={3}>
        <FormLabel fontSize="sm">
          Precisa do comprovante de pagamento?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register("formData.isPaymentReceiptNeeded.value")}
          name="formData.isPaymentReceiptNeeded.value"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('isPaymentReceiptNeeded')}
        >

          <option value='Sim'>Sim</option>
          <option value='Não'>Não</option>

        </Select>
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.paymentDescription} mt={3}>
        <FormLabel fontSize="sm">
          Descrição do pagamento
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register('formData.paymentDescription.value')}
          name='formData.paymentDescription.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('paymentDescription')}
        />
      </FormControl>
    </Box>
  )
}
