import { usePagination } from "@ajna/pagination"
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Spinner, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react"
import { getInvoices } from "api/invoices/getInvoices"
import { InvoiceStatus } from "api/invoices/_types/Invoice"
import { Pagination } from "components/Pagination/Pagination"
import { useAuth } from "hooks/auth/useAuth"
import { useSearchParams } from "hooks/useSearchParams"
import { useEffect } from "react"
import { FaExchangeAlt, FaPlus } from "react-icons/fa"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { CreateInvoice } from "./components/CreateInvoice"
import { InvoicesTableFilters } from "./components/InvoicesTableFilters"
import { InvoiceTableRow } from "./components/InvoiceTableRow"


export function Invoices() {

  const { push: redirect, replace } = useHistory()
  const searchParams = useSearchParams()
  const { userLogged } = useAuth()

  const userCanViewInvoices = userLogged?.permissions?.includes(
    'view-invoices'
  )

  const userCanCreateInvoice = userLogged?.permissions?.includes(
    'create-invoice'
  )

  useEffect(() => {
    if (!userCanViewInvoices) redirect('/')
  }, [redirect, userCanViewInvoices])


  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status') ?? 'all'

  const {
    data: invoicesResult,
    isLoading: isLoadingInvoicesResult
  } = useQuery({
    queryKey: [
      'invoices',
      page,
      status,

    ],
    queryFn: () => getInvoices({
      currentPage: Number(page),
      pageSize: 10,
      status: status as InvoiceStatus | 'all'
    }),
    keepPreviousData: true
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: invoicesResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: invoicesResult?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreateInvoiceOpen,
    onOpen: onOpenCreateInvoice,
    onClose: onCloseCreateInvoice
  } = useDisclosure()

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Controle de fatura de coletador e agregado</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar kanban"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => redirect('/controle/fatura/coletadores-e-agregados/kanban')}
          />
          {userCanCreateInvoice && (
            <IconButton
              aria-label="Criar fatura"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateInvoice}
            />
          )}
        </Flex>
        <Modal
          isOpen={isCreateInvoiceOpen}
          onClose={onCloseCreateInvoice}
          isCentered
          size="4xl"
        >
          <ModalOverlay />
          <CreateInvoice
            onCloseModal={onCloseCreateInvoice}
          />
        </Modal>
      </Flex>
      <InvoicesTableFilters />
      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Nome Fantasia</Th>
              <Th color="white">Razão social</Th>
              <Th color="white">Status</Th>
              <Th color="white">Data</Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoadingInvoicesResult && <Spinner />}
            {invoicesResult?.invoices?.map((invoice) => {
              return (
                <InvoiceTableRow
                  key={invoice.id}
                  invoice={invoice}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
