import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { RecurrentServiceEditDetailForm } from '../../../components/Form/Service/Recurrent/RecurrentServiceEditDetailForm'
import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { useAuth } from '../../../hooks/auth/useAuth'
import { useRecurrentServiceFunctions } from '../../../hooks/recurrentService/useRecurrentServiceFunctions'
import { useSwal } from '../../../hooks/swal/useSwal'
import { recurrentServiceFormatDataToBack } from '../../../utils/ServiceFunctions/Recurrent/recurrentServiceFormatDataToBack'

interface IAssignDriverInputProps {
  driver_id: string
  address_id: string
}

interface IAssignDriverProviderInputProps {
  driver_id: string
  provider_id: string
}

interface IRecurrentServiceEditProps {
  customer_id: string
  situation: string
  budget_id: string
  owner: string
  source_address_id: string[]
  destination_address_id: string[]
  source_collector_id: string
  destination_collector_id: string
  source_branch_id: string
  destination_branch_id: string
  provider_id: string
  availability_forecast_deadline: number | null
  availability_forecast_day: string
  availability_forecast_time: string
  board_hour: string
  board_date: number | null
  planned_flight: string
  deadline: number
  service_type: string
  franchising: number
  modal: string
  vehicle: string
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  collect_date: string
  collect_hour_start: string
  collect_hour_end: string
  delivery_date: string
  delivery_hour: string
  days_of_week: string[]
  material_type: string
  destination_addresses_input: {
    address_id: string
    quantity: number
  }[]
  driver_address_assign: IAssignDriverInputProps[]
  driver_provider_assign: IAssignDriverProviderInputProps[]
  observation: string
}

interface IQueryParams {
  slug: string
  id: string
}

export function RecurrentServiceEditDetailPage() {
  const {
    editRecurrentService: {
      mutateAsync: editRecurrentService,
      isSuccess,
      isLoading,
    },
  } = useRecurrentServiceFunctions()

  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useAuth()

  const { push: redirectTo } = useHistory()
  const { slug, id }: IQueryParams = useParams()

  useEffect(() => {
    if (slug === 'visualizar') {
      if (!userLogged?.permissions.includes('view-recurrent-service')) {
        redirectTo('/')
      }
    }

    if (slug === 'editar') {
      if (!userLogged?.permissions.includes('edit-recurrent-service')) {
        redirectTo('/')
      }
    }
  }, [slug, redirectTo, userLogged])

  useEffect(() => {
    if (isSuccess) {
      redirectTo('/servicos/recorrentes')
    }
  }, [isSuccess, redirectTo])

  const handleEditRecurrentService = async (
    values: IRecurrentServiceEditProps,
  ) => {
    const hasEditRecurrentService = await confirmMessage({
      title: 'Deseja editar um serviço recorrente?',
    })

    if (hasEditRecurrentService) {
      const recurrentServiceFormatedValuesToBack =
        recurrentServiceFormatDataToBack(values)
      const recurrentServiceRequestObj = {
        recurrentServiceID: id,
        recurrentServiceInput: recurrentServiceFormatedValuesToBack,
      }
      await editRecurrentService({ ...recurrentServiceRequestObj })
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (
    <>
      {slug === 'editar' && (
        <RecurrentServiceEditDetailForm
          slug={slug}
          id={id}
          action="Salvar"
          href=""
          submit={handleEditRecurrentService}
          title="Editar Serviço Recorrente"
        />
      )}

      {slug === 'visualizar' && (
        <RecurrentServiceEditDetailForm
          slug={slug}
          id={id}
          isDisabled={true}
          action="Editar"
          href={`/servico/recorrente/editar/${id}`}
          submit={async () => {}}
          title="Editar Serviço Recorrente"
        />
      )}
    </>
  )
}
