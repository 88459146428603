import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface GenerateMotorcycleRentalBody {
  address: string
  cpf: string
  name: string
  rg: string
  brand: string
  color: string
  model: string
  plate: string
  year: number
  valueInCents: number
}

interface GenerateMotorcycleRentalParams {
  body: GenerateMotorcycleRentalBody
}

export async function generateMotorcycleRental({
  body
}: GenerateMotorcycleRentalParams) {
  try {
    const { data } = await api.post('/contracts/motorcycle-rental', body)

    return data

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

