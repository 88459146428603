import { Flex, Icon, InputGroup, InputLeftElement, Text, Input } from "@chakra-ui/react";
import { useLoadRatioServicesListContext } from "hooks/loadRatio/useLoadRatioServicesListContext";
import { FaSearch } from "react-icons/fa";
import { FilterOptions } from "../LoadRatioServices";
import { Select } from "components/Inputs/SelectInput";

interface LoadRatioServicesFilterOptionsProps {
  filterOptions: FilterOptions[]
}

const serviceStepFilterOptions = [
  { key: 'toAllocateService', value: 'toAllocateService', showOption: 'EMBARCADO (ALOCAÇÃO)', },
  { key: 'toAvailableService', value: 'toAvailableService', showOption: 'ALOCADO', },
  { key: 'availableService', value: 'availableService', showOption: 'AGUARDANDO DISPONIBILIZAÇÃO', },
  { key: 'toMaintenanceService', value: 'toMaintenanceService', showOption: 'EM MANUTENÇÃO', },
  { key: 'ongoingMaintenanceService', value: 'ongoingMaintenanceService', showOption: 'MANUTENÇÃO EM ANDAMENTO', },
  { key: 'toLandingService', value: 'toLandingService', showOption: 'DISPONÍVEL' },
  { key: 'landingService', value: 'landingService', showOption: 'DESEMBARCANDO SERVIÇO' },
]

export function LoadRatioServicesFilterOptions({ filterOptions }: LoadRatioServicesFilterOptionsProps) {

  const { filters, handleSetInputFilters, handleSetSelectFilters } = useLoadRatioServicesListContext()

  return (
    <Flex
      mt="4"
      gap="4"
      p="2"
      overflow="auto"
    >
      {filterOptions.filter(option => option.selected === true).map((filterOption) => {
        return (
          <Flex key={filterOption.value} >
            {filterOption.value === 'collectDate' || filterOption.value === 'deliveryDatetime' ? (

              <Flex
                direction="column"
              >
                <Text fontSize="sm">{filterOption.value === 'collectDate' ? 'Data Coleta' : 'Data Entrega'}</Text>
                <Input
                  name={`${filterOption.value}`}
                  type={filterOption.value === 'collectDate' ? 'date' : 'datetime-local'}
                  value={filters[filterOption.value] || ''}
                  onChange={(event) => handleSetInputFilters(event)}
                  size="md"
                  rounded="md"
                  boxShadow="base"
                />
              </Flex>
            ) : (
              filterOption.value === 'step' ? (
                <Select
                  label="Etapa"
                  name={`${filterOption.value}`}
                  options={serviceStepFilterOptions}
                  placeholder={`${filterOption.showValue}...`}
                  onChange={(event) => handleSetSelectFilters(event)}
                />
              ) : (
                <InputGroup
                  w={["full", "full", "min"]}
                >
                  <InputLeftElement
                    pointerEvents="none"
                  >
                    <Icon
                      as={FaSearch}
                    />
                  </InputLeftElement>
                  <Input
                    name={`${filterOption.value}`}
                    placeholder={`${filterOption.showValue}...`}
                    size="md"
                    value={filters[filterOption.value] || ''}
                    onChange={(event) => handleSetInputFilters(event)}
                    w={["full", "full", "min"]}
                    rounded="xl"
                    boxShadow="base"
                    pl={8}
                  />
                </InputGroup>
              )

            )}
          </Flex>
        )
      })}
    </Flex>
  )
}
