import { Text, TextProps } from "@chakra-ui/react";

interface NotificationMessageProps extends TextProps {
  message: string
}

export function NotificationMessage({
  message,
  ...rest
}: NotificationMessageProps) {
  return (
    <Text fontSize="xs" {...rest}>{message}</Text>
  )
}
