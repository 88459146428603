import { Options } from "components/Inputs/SelectInput";

export const daysOfWeekOptions: Options[] = [
  { key: '0', value: 0, showOption: 'Domingo' },
  { key: '1', value: 1, showOption: 'Segunda-feira' },
  { key: '2', value: 2, showOption: 'Terça-feira' },
  { key: '3', value: 3, showOption: 'Quarta-feira' },
  { key: '4', value: 4, showOption: 'Quinta-feira' },
  { key: '5', value: 5, showOption: 'Sexta-feira' },
  { key: '6', value: 6, showOption: 'Sábado' },
]
