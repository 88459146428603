import request from "axios"
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import { useAuth } from "hooks/auth/useAuth";
import { RequestError } from "utils/errors/RequestErrors";
import { useToastify } from "hooks/toastify/useToastify";
import { DispatchStockProposal } from "api/dispachStocks/dispatchStockProposal";

interface CollectorDispatchStockProposalsResponse {
  dispatchStockProposals: DispatchStockProposal[]
}

interface GetCollectorDispatchStockProposalsProps {
  collectorId: string
}

async function getCollectorDispatchStockProposals({
  collectorId
}: GetCollectorDispatchStockProposalsProps) {
  try {
    const { data } = await api.get(`/collectors/${collectorId}/proposals`)

    return data
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

type QueryOptions = UseQueryOptions<CollectorDispatchStockProposalsResponse, unknown, CollectorDispatchStockProposalsResponse, QueryKey>

interface UseCollectorDispatchStockProposalsOptions {
  collectorId: string
  queryKey?: QueryKey
  queryOptions?: QueryOptions
}

export function useCollectorDispatchStockProposals(
  options?: UseCollectorDispatchStockProposalsOptions
) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([
    options?.queryKey ?? 'collectorDispatchStockProposals',
    options.collectorId,
  ], () => getCollectorDispatchStockProposals({ collectorId: options.collectorId }), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
