import { Button, Flex, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner } from "@chakra-ui/react";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { PriceForm, PriceSchemaInputs } from "./PriceForm";
import { FormProvider, useForm } from "react-hook-form";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQueryClient } from "react-query";
import { useHub } from "hooks/hub/useHub";
import { useCity } from "hooks/city/useCity";
import { useShipping } from "hooks/shipping/useShipping";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import { createPrice } from "api/prices/createPrice";

interface CreatePriceProps {
  onClose: () => void
}

function checkIfValueIsBiggerThenZero(value: string, originalValue: string) {
  return transformStringToNumber(value) > 0 ? value : ''
}


export const priceSchema = yup.object().shape({
  sourceCityUf: yup.string().required(),
  destinationCityUf: yup.string().required(),
  sourceCityId: yup.string().required(),
  sourceHubId: yup.string().required(),
  destinationCityId: yup.string().required(),
  destinationHubId: yup.string().required(),
  category: yup.string().required(),
  routeType: yup.string().required(),
  serviceType: yup.string().required(),
  airMinimumPrice: yup.string().when('routeType', {
    is: (value: string) => value === 'AMBOS' || value === 'AÉREO',
    then: yup
      .string()
      .transform(checkIfValueIsBiggerThenZero)
      .required(),
  }),
  airExtraKg: yup.string().when('routeType', {
    is: (value: string) => value === 'AMBOS' || value === 'AÉREO',
    then: yup
      .string()
      .transform(checkIfValueIsBiggerThenZero)
      .required(),
  }),
  airDeadline: yup
    .number()
    .nullable()
    .when('routeType', {
      is: (value: string) => value === 'AMBOS' || value === 'AÉREO',
      then: yup
        .number()
        .nullable()
        .required(),
    }),
  highwayMinimumPrice: yup.string().when('routeType', {
    is: (value: string) => value === 'AMBOS' || value === 'RODOVIÁRIO',
    then: yup
      .string()
      .transform(checkIfValueIsBiggerThenZero)
      .required(),
  }),
  highwayExtraKg: yup.string().when('routeType', {
    is: (value: string) => value === 'AMBOS' || value === 'RODOVIÁRIO',
    then: yup
      .string()
      .transform(checkIfValueIsBiggerThenZero)
      .required(),
  }),
  highwayDeadline: yup
    .number()
    .nullable()
    .when('routeType', {
      is: (value: string) => value === 'AMBOS' || value === 'RODOVIÁRIO',
      then: yup
        .number()
        .nullable()
        .required(),
    }),
  additionalCollectPrice: yup
    .string()
    .transform(checkIfValueIsBiggerThenZero)
    .required(),
  additionalDeliveryPrice: yup
    .string()
    .transform(checkIfValueIsBiggerThenZero)
    .required(),
    shippingsIds: yup.mixed().when('serviceType', {
    is: 'FRACIONADO',
    then: yup.array().min(1,).required(),
  }),
})

export function CreatePrice({ onClose }: CreatePriceProps) {
  const formMethods = useForm<PriceSchemaInputs>({
    resolver: yupResolver(priceSchema)
  })

  const { formState: { isSubmitting }, handleSubmit } = formMethods

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const {
    hubs: { data: hubs, isLoading: isHubsLoading },
  } = useHub(null, true)

  const {
    cities: { data: cities, isLoading: isCitiesLoading },
  } = useCity(null, true)

  const {
    shipping: { data: shippings, isLoading: isShippingLoading },
  } = useShipping(null, true, false)

  const { mutateAsync: createPriceFn } = useMutation({
    mutationFn: createPrice,
    onSuccess(_data, _variables, _context) {

      queryClient.invalidateQueries({ queryKey: 'prices' })
      onClose()
    }
  })

  async function handleCreatePrice(values: PriceSchemaInputs) {
    await promiseMessage(createPriceFn({
      body: {
        ...values,
        additionalCollectPrice: transformStringToNumber(values.additionalCollectPrice),
        additionalDeliveryPrice: transformStringToNumber(values.additionalDeliveryPrice),
        airExtraKg: transformStringToNumber(values.airExtraKg),
        airMinimumPrice: transformStringToNumber(values.airMinimumPrice),
        highwayExtraKg: transformStringToNumber(values.highwayExtraKg),
        highwayMinimumPrice: transformStringToNumber(values.highwayMinimumPrice)


      }
    }), 'Preço criado!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Criar Preço
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        maxH='600px'
        overflowY='scroll'
        onSubmit={handleSubmit(handleCreatePrice)}
      >
        {(isCitiesLoading || isHubsLoading || isShippingLoading) ? (
          <Spinner />
        ) : (
          <FormProvider {...formMethods}>

            <PriceForm
              slug="create"
              cities={cities}
              hubs={hubs}
              shippings={shippings}
            />
          </FormProvider>
        )}
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
