import { Box, FormControl, FormLabel, Input, Text, Textarea } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";


export function KPIChangeForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()


  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      kpiToBeReplaced: 'Qual KPI será substituído?',
      kpiName: 'Nome do KPI',
      kpiTarget: 'Meta do KPI',
      howItWillBeMeasured: 'Como será apurado?',
    };


    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };
  return (
    <Box w='full'>
      <FormControl isInvalid={!!errors.formData?.kpiToBeReplaced} mt={3}>
        <FormLabel fontSize='sm'>
          Qual KPI será substituído?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.kpiToBeReplaced.value')}
          name='formData.kpiToBeReplaced.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('kpiToBeReplaced')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.kpiName} mt={3}>
        <FormLabel fontSize='sm'>
          Nome do KPI
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.kpiName.value')}
          name='formData.kpiName.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('kpiName')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.kpiTarget} mt={3}>
        <FormLabel fontSize='sm'>
          Meta do KPI
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.kpiTarget.value')}
          name='formData.kpiTarget.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('kpiTarget')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.howItWillBeMeasured} mt={3}>
        <FormLabel fontSize="sm">
          Como será apurado?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register('formData.howItWillBeMeasured.value')}
          name='formData.howItWillBeMeasured.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('howItWillBeMeasured')} />
      </FormControl>

    </Box>
  )
}
