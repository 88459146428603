import { Box, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { Refund } from "api/refunds/_types/Refund";
import { format, formatDistanceToNowStrict, isFuture } from "date-fns";
import { FaCalendarAlt, FaDollarSign, FaExclamationCircle } from "react-icons/fa";
import { FaPeopleCarryBox } from "react-icons/fa6";
import { RefundDetail } from "./RefundDetail";
import { ptBR } from "date-fns/locale";

interface KanbanRefundCardProps {
  refund: Refund
}

export function KanbanRefundCard({ refund }: KanbanRefundCardProps) {

  const {
    isOpen: isRefundDetailModalOpen,
    onToggle: onToggleRefundDetailModal,
  } = useDisclosure()

  const refundDate = new Date(refund?.date);
  const formattedRefundDate = format(refundDate, "dd MMM", { locale: ptBR });

  const relativeDate = formatDistanceToNowStrict(refundDate, { locale: ptBR });
  const isDateInFuture = isFuture(refundDate);
  const relativeDateText = isDateInFuture ? `em ${relativeDate}` : `${relativeDate} atrás`;

  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleRefundDetailModal}
      cursor='pointer'
    >
      <Text fontWeight="bold" mb={2}>{refund.protocol}</Text>

      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA DA REEMBOLSO</Text>
          </HStack>
          <Text ml={5}>{format(new Date(refund?.date),  "dd/MM/yyyy '-' HH:mm")}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaDollarSign} />
            <Text fontSize="xs" fontWeight="bold">VALOR DO REEMBOLSO</Text>
          </HStack>
          <Text ml={5}>{String(new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          }).format(Number(refund?.valueInCents / 100)))}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaPeopleCarryBox} />
            <Text fontSize="xs" fontWeight="bold">TIPO REEMBOLSO</Text>
          </HStack>
          <Text ml={5}>{refund?.type.toUpperCase()}</Text>
        </Box>
        <HStack spacing={2}>
          {isDateInFuture ? (
            <>
              <Icon as={FaCalendarAlt} />
              <Text fontWeight="bold">{`Venc ${formattedRefundDate}`}</Text>
            </>
          ) : (
            <>
              <Icon as={FaExclamationCircle} color="red.500" />
              <Text color="red.500" fontWeight="bold">{`Venc ${formattedRefundDate}`}</Text>
            </>
          )}
          <Text>{relativeDateText}</Text>
        </HStack>
      </VStack>

      <Modal
        isOpen={isRefundDetailModalOpen}
        onClose={onToggleRefundDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <RefundDetail
          refundId={refund.id}
        />
      </Modal>
    </Box>
  )
}
