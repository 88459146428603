import { Box, Flex, Spinner } from "@chakra-ui/react"
import { FaUser, FaPlaneDeparture, FaPlaneArrival, FaClock, FaAddressCard } from "react-icons/fa"
import { getTimezoneNameByValue } from "utils/getTimezoneNameByValue"
import { IHubsProps } from "utils/RequestFunctions/Hubs/requestHubFunctions"
import { EmptyContentTableAlert } from "../../../../../components/Alerts/EmptyContentTableAlert"
import { SearchBox } from "../../../../../components/SearchBox/SearchBox"
import { BudgetProps } from "../../../../../contexts/BudgetContext"
import { ServiceProps } from "../../../../../contexts/ServiceContext"
import { useKanbanSearchBox } from "../../../../../hooks/useKanbanSearchBox"
import { CitiesProps } from "../../../../../services/getFunctions/city/getCity"
import { formatDate } from "../../../../../utils/DateFunctions/formatDate"
import { searchBoxFilter } from "../../../../../utils/searchBoxFilter"
import { sortByDate } from "../../../../../utils/SortFunctions/sortByDate"
import { sortByHour } from "../../../../../utils/SortFunctions/sortByHour"
import { KanbanCard } from "../KanbanCard/KanbanCard"
import { KanbanCardHeader } from "../KanbanCardHeader/KanbanCardHeader"
import { KanbanCardItem } from "../KanbanCardItem/KanbanCardItem"

interface IKanbanToDeliveryAndDeliveringServicesProps {
  services?: ServiceProps[]
  budgets?: BudgetProps[]
  cities?: CitiesProps[]
  hubs: IHubsProps[]
}

export function KanbanToDeliveryAndDeliveringServices({ services, budgets, cities, hubs }: IKanbanToDeliveryAndDeliveringServicesProps) {
  const { handleSearchServices, isLoadingSearch, searchedWords } = useKanbanSearchBox()

  const filteredSteps = ["toDeliveryService", "deliveringService"]

  const toDeliveryAndDeliveringServices = services
    ?.filter(service => filteredSteps.includes(service.step)) || []

  const toDeliveryAndDeliveringServicesOrdered =
    toDeliveryAndDeliveringServices.sort((a, b) =>
      sortByDate(a.serviceIDRequested.delivery_date, b.serviceIDRequested.delivery_date) ||
      sortByHour(a.serviceIDRequested.delivery_date, b.serviceIDRequested.delivery_hour) ||
      0
    )

  const servicesFilteredByUser = toDeliveryAndDeliveringServicesOrdered.filter(service => {
    const budgetFiltered = budgets
      ?.find(budget => budget.id === service.serviceIDRequested.budget_id)

    const sourceCities = cities
      ?.filter(city => budgetFiltered?.source_cities.includes(city.id))
      .map(city => city.name)
      .join(', ')

    const destinationCities = cities
      ?.filter(city => budgetFiltered?.destination_cities.includes(city.id))
      .map(city => city.name)
      .join(', ')

    const collectDate = formatDate.handle(service.serviceIDRequested.collect_date, "DateWithoutHourToShow")
    const collectHourStart = formatDate.handle(service.serviceIDRequested.collect_hour_start, "DateOnlyWithHourMinute")
    const collectHourEnd = formatDate.handle(service.serviceIDRequested.collect_hour_end, "DateOnlyWithHourMinute")

    const deliveryDate = formatDate.handle(service.serviceIDRequested.delivery_date, "DateWithoutHourToShow")
    const deliveryHour = formatDate.handle(service.serviceIDRequested.delivery_hour, "DateOnlyWithHourMinute")

    const receiverQuantity = service.serviceIDRequested.destination_address_id.length

    const filter = searchBoxFilter(
      `${service.customerIDService.trading_firstname} ${sourceCities} ${destinationCities} ${collectDate} ${collectHourStart} ${collectHourEnd} ${deliveryDate} ${deliveryHour} ${receiverQuantity}`,
      searchedWords
    )

    if (searchedWords === '') return service

    return filter
  })

  return (
    <Flex direction="column">
      <SearchBox
        placeholder="Filtrar palavras..."
        handleSearch={(event) => handleSearchServices(event)}
        width="full"
      />
      <Flex
        overflowY="auto"
        maxHeight={1000}
        flexDirection="column"
        gap="4"
        sx={{
          '&::-webkit-scrollbar': {
            width: '10px',
          },
          '&::-webkit-scrollbar-track': {
            width: '10px',
            bg: 'gray.100',
          },
          '&::-webkit-scrollbar-thumb': {
            bg: 'gray.300',
            width: '4px',
            borderRadius: '24px',
          },
        }}
      >
        {isLoadingSearch ? (
          <Spinner />
        ) : (
          <>
            {!servicesFilteredByUser.length ? (
              <EmptyContentTableAlert
                title="Oops"
                description="Não foram encontrados resultados para o filtro. Tente novamente!"
              />
            ) : (
              servicesFilteredByUser.map(service => {
                const budgetFiltered = budgets
                  ?.find(budget => budget.id === service.serviceIDRequested.budget_id)

                const sourceCities = cities
                  ?.filter(city => budgetFiltered?.source_cities.includes(city.id))
                  .map(city => city.name)
                  .join(', ')

                const destinationCities = cities
                  ?.filter(city => budgetFiltered?.destination_cities.includes(city.id))
                  .map(city => city.name)
                  .join(', ')

                const collectDate = formatDate.handle(service.serviceIDRequested.collect_date, "DateWithoutHourToShow")
                const collectHourStart = formatDate.handle(service.serviceIDRequested.collect_hour_start, "DateOnlyWithHourMinute")
                const collectHourEnd = formatDate.handle(service.serviceIDRequested.collect_hour_end, "DateOnlyWithHourMinute")

                const deliveryDate = formatDate.handle(service.serviceIDRequested.delivery_date, "DateWithoutHourToShow")
                const deliveryHour = formatDate.handle(service.serviceIDRequested.delivery_hour, "DateOnlyWithHourMinute")

                const receiverQuantity = service.serviceIDRequested.destination_address_id.length

                const sourceHub = hubs?.find(hub => hub.id === service?.serviceIDRequested?.budgetIDService.source_hub_id)

                const destinationHub = hubs?.find(hub => hub.id === service.serviceIDRequested?.budgetIDService.destination_hub_id)

                const crossdockingHub = hubs?.find(hub => hub.id === service?.serviceIDRequested?.budgetIDService.crossdocking_hub_id)

                const timezoneSourceHub = getTimezoneNameByValue(sourceHub?.timezone)
                const timezoneDestinationHub = getTimezoneNameByValue(destinationHub?.timezone)
                const timezoneCrossdockingHub = getTimezoneNameByValue(crossdockingHub?.timezone)


                return (
                  <KanbanCard key={service.id}>
                    <KanbanCardHeader serviceId={service.id} protocol={service.protocol} />
                    <Box p={4}>
                      <KanbanCardItem
                        icon={FaUser}
                        content={service.customerIDService.trading_firstname}
                      />
                      <KanbanCardItem
                        icon={FaPlaneDeparture}
                        content={sourceCities ?? '-'}
                      />
                      <KanbanCardItem
                        icon={FaPlaneArrival}
                        content={destinationCities ?? '-'}
                      />
                      <KanbanCardItem
                        icon={FaClock}
                        content={`${collectDate} - ${collectHourStart} -> ${collectHourEnd}`}
                      />
                      <KanbanCardItem
                        icon={FaClock}
                        content={`${deliveryDate} - ${deliveryHour}`}
                      />
                      {timezoneSourceHub && (
                        <KanbanCardItem
                          icon={FaClock}
                          content={`Fuso Origem: ${timezoneSourceHub}`}
                        />
                      )}
                      {timezoneDestinationHub && (
                        <KanbanCardItem
                          icon={FaClock}
                          content={`Fuso Destino: ${timezoneDestinationHub}`}
                        />
                      )}
                      {timezoneCrossdockingHub && (
                        <KanbanCardItem
                          icon={FaClock}
                          content={`Fuso Crossdocking: ${timezoneCrossdockingHub}`}
                        />
                      )}
                      <KanbanCardItem
                        icon={FaAddressCard}
                        content={`${receiverQuantity}`}
                        isLink
                        url={`/servicos/entregas/${service.id}`}
                      />
                    </Box>
                  </KanbanCard>
                )
              })
            )}
          </>
        )}
      </Flex>
    </Flex>
  )
}
