import { Tr, Td, IconButton, Modal, ModalOverlay, useDisclosure, Icon, Flex, Button } from "@chakra-ui/react";
import { approveDispatchStock } from "api/dispachStocks/approveDispatchStock";
import { deleteDispatchStock } from "api/dispachStocks/deleteDispatchStock";
import { DispatchStock } from "api/dispachStocks/dispatchStock";
import { GetDispatchStocksResponse } from "api/dispachStocks/getDispatchStocks";
import { DispatchConfirmProps } from "contexts/StockContext";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaArchive, FaArrowRight, FaPen, FaSearch, FaTrash } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { captalize } from "utils/captalize";
import { CollectorConfirmDispatchStock } from "./CollectorConfirmDispatchStock";
import { DispatchStockDetail } from "./DispatchStockDetail";
import { DispatchStockMaterial } from "./DispatchStockMaterial";
import { DispatchStockStep } from "./DispatchStockStep";
import { HeadConfirmDispatchStock } from "./HeadConfirmDispatchStock";


interface DispatchStockTableRowProps {
  dispatchStock: DispatchStock & {
    confirmation: DispatchConfirmProps
  }
}

export function DispatchStocksTableRow({ dispatchStock }: DispatchStockTableRowProps) {
  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  const userCanEditDispatchStock = userLogged?.permissions.includes('edit-dispatch-stock')
  const userCanApproveDispatchStock = userLogged?.permissions.includes('approve-dispatch-stock')
  const userCanHeadConfirmDispatchStock = userLogged?.permissions.includes('head-confirm-dispatch-stock')
  const userCanCollectorConfirmDispatchStock = userLogged?.permissions.includes('collector-confirm-dispatch-stock')

  const {
    isOpen: isDetailModalOpen,
    onOpen: onOpenDetailModal,
    onClose: onCloseDetailModal,
  } = useDisclosure()

  const {
    isOpen: isDispatchStockMaterialModalOpen,
    onOpen: onOpenDispatchStockMaterialModal,
    onClose: onCloseDispatchStockMaterialModal,
  } = useDisclosure()

  const {
    isOpen: isHeadConfirmDispatchStockModalOpen,
    onOpen: onOpenHeadConfirmDispatchStockModal,
    onClose: onCloseHeadConfirmDispatchStockModal
  } = useDisclosure()

  const {
    isOpen: isCollectorConfirmDispatchStockModalOpen,
    onOpen: onOpenCollectorConfirmDispatchStockModal,
    onClose: onCloseCollectorConfirmDispatchStockModal
  } = useDisclosure()

  const queryClient = useQueryClient()

  const { mutateAsync: approveDispatchStockFn } = useMutation({
    mutationFn: approveDispatchStock,
    onSuccess: (_data, { dispatchStockId }) => {
      const cached = queryClient.getQueriesData<GetDispatchStocksResponse>({
        queryKey: ['dispatch-stocks']
      })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) {
          return
        }

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          dispatchStocks: cachedValue.dispatchStocks.map((dispatchStock) => {
            if (dispatchStock.id === dispatchStockId) {
              return { ...dispatchStock, step: 'head-confirming' }
            }

            return dispatchStock
          })
        })
      })
    }
  })

  const { mutateAsync: deleteDispatchStockFn } = useMutation({
    mutationFn: deleteDispatchStock,
    onSuccess: (_data, { dispatchStockId }) => {
      const cached = queryClient.getQueriesData<GetDispatchStocksResponse>({
        queryKey: ['dispatch-stocks']
      })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) {
          return
        }

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          dispatchStocks: cachedValue.dispatchStocks.filter((dispatchStock) => {
            return dispatchStock.id !== dispatchStockId
          })
        })
      })
    }
  })

  const { promiseMessage } = useToastify()

  async function handleApproveDispatchStock() {
    await promiseMessage(approveDispatchStockFn({
      dispatchStockId: dispatchStock.id,
    }), 'Despacho aprovado!')
  }

  async function handleDeleteDispatchStock() {
    await promiseMessage(deleteDispatchStockFn({
      dispatchStockId: dispatchStock.id,
    }), 'Despacho excluído! ❌' )
  }

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Despacho de Estoque"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onOpenDetailModal}
        />
        <Modal
          isOpen={isDetailModalOpen}
          onClose={onCloseDetailModal}
          isCentered
          size="2xl"
        >
          <ModalOverlay />
          <DispatchStockDetail dispatchStockId={dispatchStock?.id} />
        </Modal>
      </Td>
      <Td>
        {userCanEditDispatchStock && dispatchStock.step === 'pending' && (
          <IconButton
            aria-label="Editar despacho de estoque"
            icon={<Icon as={FaPen} />}
            size="sm"
            variant="ghost"
            onClick={() => redirect(`/despachos/editar/${dispatchStock.id}`)}
          />
        )}
      </Td>
      <Td>{dispatchStock.protocol}</Td>
      <Td>{captalize(dispatchStock?.sourceCollector?.trading_name)}</Td>
      <Td>{dispatchStock?.destination_collector_id ? captalize(dispatchStock.destinationCollector.trading_name) : '-'}</Td>
      <Td>{captalize(dispatchStock?.shipping.trading_name)}</Td>
      <Td>{captalize(dispatchStock?.branch.nickname)}</Td>
      <Td>{format(new Date(dispatchStock.board_date), 'dd/MM/yyyy')}</Td>
      <Td>{format(new Date(dispatchStock.arrival_forecast), 'dd/MM/yyyy')}</Td>
      <Td>{dispatchStock?.confirmation?.dispatch_invoice ?? '-'}</Td>
      <Td>{dispatchStock?.confirmation?.dispatch_cte ?? '-'}</Td>
      <Td>
        <DispatchStockStep step={dispatchStock.step} />
      </Td>
      <Td>
        <Flex align="center" gap="2" w="full" justify="space-between">
          {dispatchStock.step === 'pending' && userCanApproveDispatchStock && (
            <>
              <IconButton
                aria-label="Adicionar materiais"
                icon={<Icon as={FaArchive} />}
                variant="ghost"
                size="sm"
                onClick={onOpenDispatchStockMaterialModal}
              />

              <Button
                size="xs"
                lineHeight="1"
                variant="ghost"
                leftIcon={<FaArrowRight />}
                onClick={handleApproveDispatchStock}
              >
                Aprovar
              </Button>


              <Modal
                isOpen={isDispatchStockMaterialModalOpen}
                onClose={onCloseDispatchStockMaterialModal}
                isCentered
                size="lg"
              >
                <ModalOverlay />
                <DispatchStockMaterial
                  dispatchStockId={dispatchStock.id}
                />
              </Modal>
            </>
          )}

          {dispatchStock.step === 'head-confirming' && userCanHeadConfirmDispatchStock && (
            <>
              <Button
                size="xs"
                lineHeight="1"
                variant="ghost"
                leftIcon={<FaArrowRight />}
                onClick={onOpenHeadConfirmDispatchStockModal}
              >
                Confirmar (Matriz)
              </Button>

              <Modal
                isOpen={isHeadConfirmDispatchStockModalOpen}
                onClose={onCloseHeadConfirmDispatchStockModal}
                isCentered
              >
                <ModalOverlay />

                <HeadConfirmDispatchStock dispatchStockId={dispatchStock.id} />
              </Modal>
            </>
          )}

          {dispatchStock.step === 'collector-confirming' && userCanCollectorConfirmDispatchStock && (
            <>
              <Button
                size="xs"
                lineHeight="1"
                variant="ghost"
                leftIcon={<FaArrowRight />}
                onClick={onOpenCollectorConfirmDispatchStockModal}
              >
                Confirmar (Coletador)
              </Button>

              <Modal
                isOpen={isCollectorConfirmDispatchStockModalOpen}
                onClose={onCloseCollectorConfirmDispatchStockModal}
                isCentered
              >
                <ModalOverlay />

                <CollectorConfirmDispatchStock dispatchStockId={dispatchStock.id} />
              </Modal>
            </>
          )}

          <Button
            size="xs"
            lineHeight="1"
            leftIcon={<FaTrash />}
            variant="outline"
            colorScheme="red"
            onClick={handleDeleteDispatchStock}
          >
            Excluir
          </Button>
        </Flex>
      </Td>
    </Tr>
  )
}
