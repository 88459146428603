import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { InternClt } from "./_types/internClt";

export interface GetInternCltResponse {
  internClt: InternClt & {
    terminationDocumentsAttachments: {
      attachment: Attachment
    }[]
  }
}

interface GetInternCltProps {
  routeParams: {
    internCltId: string
  }
}

export async function getInternClt({
  routeParams
}: GetInternCltProps) {
  try {
    const response = await api.get<GetInternCltResponse>(`/intern-clts/${routeParams.internCltId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
