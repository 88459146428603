import axios from "axios";
import { DispatchConfirmProps } from "contexts/StockContext";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { DispatchStock } from "./dispatchStock";

interface GetDispatchStocksParams {
  pageSize: string
  currentPage: string
  step: string
  protocol?: string
  branch?: string
  dispatchInvoice?: string
  destinationCollector?: string
}

export interface GetDispatchStocksResponse {
  dispatchStocks: Array<DispatchStock & {
    confirmation: DispatchConfirmProps
  }>
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
  }
}

export async function getDispatchStocks({
  pageSize,
  currentPage,
  step,
  protocol,
  branch,
  dispatchInvoice,
  destinationCollector
}: GetDispatchStocksParams) {
  try {
    const { data } = await api.get<GetDispatchStocksResponse>('/dispatch-stocks', {
      params: {
        pageSize,
        currentPage,
        step,
        protocol,
        branch,
        dispatchInvoice,
        destinationCollector
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

