import * as yup from "yup"
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { Select } from "components/Inputs/SelectInput"
import { TextArea } from "components/Inputs/TextInput"
import { ThirdPartyRnc } from "hooks/rnc/dtos/ThirdPartyRnc"
import { useForm, useWatch } from "react-hook-form"
import { ViewThirdPartyRncInfoModal } from "./ViewThirdPartyRncInfoModal"
import { yupResolver } from "@hookform/resolvers/yup"
import { useThirdPartyRncFunctions } from "hooks/rnc/useThirdPartyRncFunctions"
import { useToastify } from "hooks/toastify/useToastify"
import { useQueryClient } from "react-query"

interface AnswerThirdPartyRncInputs {
  proceed: 'yes' | 'no'
  why_not_proceed: string
  cause: string
  corrective: string
  preventive: string
}

interface AnswerThirdPartyRncModalProps {
  isOpen: boolean
  onClose: () => void
  thirdPartyRnc: ThirdPartyRnc
}

const proceedOptions = [
  { key: '0', value: 'yes', showOption: 'Sim' },
  { key: '1', value: 'no', showOption: 'Não' },
]

const answerThirdPartyFormSchema = yup.object().shape({
  proceed: yup.string().required('Campo obrigatório.'),
  why_not_proceed: yup.string().when('proceed', {
    is: 'no',
    then: yup.string().required('Campo obrigatório.')
  }),
  cause: yup.string().when('proceed', {
    is: 'yes',
    then: yup.string().required('Campo obrigatório.')
  }),
  corrective: yup.string().when('proceed', {
    is: 'yes',
    then: yup.string().required('Campo obrigatório.')
  }),
  preventive: yup.string().when('proceed', {
    is: 'yes',
    then: yup.string().required('Campo obrigatório.')
  }),
})

export function AnswerThirdPartyRncModal({
  isOpen,
  onClose,
  thirdPartyRnc
}: AnswerThirdPartyRncModalProps) {

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<AnswerThirdPartyRncInputs>({
    resolver: yupResolver(answerThirdPartyFormSchema)
  })

  const proceed = useWatch({
    control,
    name: 'proceed'
  })

  const {
    isOpen: isViewThirdPartyRncInfoModalOpen,
    onOpen: onOpenThirdPartyRncInfoModalOpen,
    onClose: onCloseThirdPartyRncInfoModalOpen,
  } = useDisclosure()

  const handleOpenThirdPartyRncInfoModal = () => onOpenThirdPartyRncInfoModalOpen()

  const {
    answerThirdPartyRnc: { mutateAsync: answerThirdPartyRnc }
  } = useThirdPartyRncFunctions()

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  async function handleAnswerThirdPartyRnc(values: AnswerThirdPartyRncInputs) {
    await promiseMessage(answerThirdPartyRnc({
      id: thirdPartyRnc.id,
      input: values
    }, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('thirdPartyRncs')
        onClose()
      }
    }), 'Rnc respondido com sucesso!')
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        as='form'
        onSubmit={handleSubmit(handleAnswerThirdPartyRnc)}
        noValidate
      >
        <ModalHeader>Responder rnc</ModalHeader>
        <ModalCloseButton />

        <ViewThirdPartyRncInfoModal
          isOpen={isViewThirdPartyRncInfoModalOpen}
          onClose={onCloseThirdPartyRncInfoModalOpen}
          thirdPartyRnc={thirdPartyRnc}
        />

        <ModalBody>
          <Flex direction='column' gap={2}>
            <Button
              size='sm'
              variant='outline'
              onClick={handleOpenThirdPartyRncInfoModal}
            >
              Informações da rnc
            </Button>
            <Select
              {...register('proceed')}
              name='proceed'
              options={proceedOptions}
              error={errors.proceed}
              label='Procede?'
              placeholder='Selecione uma opção...'
              required
            />

            {proceed === 'no' && (
              <TextArea
                {...register('why_not_proceed')}
                name='why_not_proceed'
                error={errors.why_not_proceed}
                label='Por que não procede?'
                required
              />
            )}

            {proceed === 'yes' && (
              <>
                <TextArea
                  {...register('cause')}
                  name='cause'
                  error={errors.cause}
                  label='Causa raiz'
                  required
                />
                <TextArea
                  {...register('corrective')}
                  name='corrective'
                  error={errors.corrective}
                  label='Ação corretiva'
                  required
                />
                <TextArea
                  {...register('preventive')}
                  name='preventive'
                  error={errors.preventive}
                  label='Ação preventiva'
                  required
                />
              </>
            )}

          </Flex>
        </ModalBody>

        <ModalFooter>
          <Flex gap={4} w='full' direction={['column', 'column', 'row']}>
            <Button
              w='full'
              variant='ghost'
              onClick={onClose}
              isLoading={isSubmitting}
            >
              Fechar
            </Button>
            <Button
              w='full'
              colorScheme='blue'
              type='submit'
              isLoading={isSubmitting}
            >
              Responder
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>

    </Modal>
  )
}
