import { Box, Button, IconButtonProps, Text, Tooltip } from "@chakra-ui/react";
import { BiTimeFive } from "react-icons/bi";

interface ServicesInDelayTriggerButtonProps extends IconButtonProps {
  label?: string
  delayedServicesCount: number
}

export function ServicesInDelayTriggerButton({
  label = 'Serviços em atraso!',
  delayedServicesCount = 0,
  ...rest
}: ServicesInDelayTriggerButtonProps) {

  return (
    <Tooltip
      hasArrow
      label={label}
      placement="left"
      fontSize="sm"
      rounded="lg"
      boxShadow="md"
      position="relative"
    >
      <Button
        aria-label="Serviços em atraso"
        position="fixed"
        bottom="8"
        right="2"
        size="md"
        colorScheme="blue"
        boxShadow="md"
        rounded="lg"
        {...rest}
      >
        <Text
          position="absolute"
          top={-2}
          left={-2}
          bg="red.500"
          rounded="full"
          display="flex"
          justifyContent="center"
          alignItems="center"
          w={5}
          h={5}
          fontSize="xs"
        >
          {delayedServicesCount}
        </Text>

        <Box
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <BiTimeFive size="24" />
        </Box>

      </Button>
    </Tooltip >
  )
}
