import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { CltDriver } from "./_types/CltDriver";


interface GetCltDriverProps {
  routeParams: {
    cltDriverId: string
  }
}

type GetCltDriverResponse = CltDriver & {
  terminationDocumentsAttachments: {
    id: string
    attachment: Attachment
  }[]
}

export async function getCltDriver({
  routeParams
}: GetCltDriverProps) {
  try {
    const response = await api.get<GetCltDriverResponse>(`/clt-drivers/${routeParams.cltDriverId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
