import { usePagination } from "@ajna/pagination"
import { Box, Button, Divider, Flex, Heading, Icon, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";

import { Pagination } from "components/Pagination/Pagination"
import { Link, useHistory } from "react-router-dom";

import { useEffect, useReducer, useState } from "react";
import { useAuth } from "hooks/auth/useAuth";
import { formatDate } from "utils/DateFunctions/formatDate";
import { FaCheck, FaEye, FaTrash } from "react-icons/fa";
import { useAwaitingArrivalPurchaseOrders } from "hooks/purchaseOrder/useAwaitingArrivalPurchaseOrders";
import { useToastify } from "hooks/toastify/useToastify";
import { useQueryClient } from "react-query";
import { usePurchaseOrderFunctions } from "hooks/purchaseOrder/usePurchaseOrderFunctions";
import { PurchaseOrder } from "hooks/purchaseOrder/dtos/PurchaseOrder";
import { ViewPurchaseOrderModal } from "./components/ViewPurchaseOrderModal";

interface ActionPayload {
  currentPage?: number
}

interface Action {
  type: 'set-awaiting-arrival-purchase-order-current-page'
  payload: ActionPayload
}

function reducer(state: ActionPayload, action: Action) {
  if (action.type === 'set-awaiting-arrival-purchase-order-current-page') {
    return {
      currentPage: action.payload.currentPage
    }
  }
  return {
    ...state,
    ...action.payload
  }
}

export function PurchaseOrdersAwaitingArrival() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  const { promiseMessage } = useToastify()

  const queryClient = useQueryClient()

  const [purchaseOrder, setPurchaseOrder] = useState({} as PurchaseOrder)

  const [listAwaitingArrivalDataState, dispatch] = useReducer(
    reducer,
    {} as ActionPayload
  )

  const userHasPermissionToViewPurchaseOrdersAwaitingArrival =
    userLogged.permissions.includes('view-purchases-arrived')

  const userHasPermissionToValidatePurchase =
    userLogged.permissions.includes('validate-purchase')

  const userHasPermissionToDeletePurchaseOrdersAwaitingArrival =
    userLogged.permissions.includes('delete-purchase-order')

  useEffect(() => {
    if (!userHasPermissionToViewPurchaseOrdersAwaitingArrival) {
      redirect('/')
    }
  }, [userHasPermissionToViewPurchaseOrdersAwaitingArrival, redirect])



  const {
    data: purchaseOrdersAwaitingArrivalResponseData,
    isFetching: isFetchingPurchaseOrdersAwaitingArrivalData
  } = useAwaitingArrivalPurchaseOrders({
    queryParams: {
      currentPage: listAwaitingArrivalDataState.currentPage,
      pageSize: Number(rowsPerPage)
    },
    queryOptions: {
      enabled: !!listAwaitingArrivalDataState.currentPage
    }
  })

  const { deletePurchaseOrder: { mutateAsync: deletePurchaseOrder } } = usePurchaseOrderFunctions()

  async function handleDeletePurchaseOrder(purchaseOrderId: string) {
    await promiseMessage(deletePurchaseOrder(purchaseOrderId, {
      onSuccess: () => {
        queryClient.invalidateQueries(['purchaseOrdersAwaitingArrival'])
      }
    }), 'Pedido de compra excluído com sucesso!')
  }


  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: purchaseOrdersAwaitingArrivalResponseData?.totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  useEffect(() => {
    dispatch({
      type: 'set-awaiting-arrival-purchase-order-current-page',
      payload: {
        currentPage
      }
    })
  }, [currentPage])

  const {
    isOpen: isViewPurchaseOrderModalOpen,
    onOpen: onOpenViewPurchaseOrderModal,
    onClose: onCloseViewPurchaseOrderModal,
  } = useDisclosure()

  const handleOpenViewPurchaseOrderModal = (purchaseOrder: PurchaseOrder) => {
    setPurchaseOrder(purchaseOrder)
    onOpenViewPurchaseOrderModal()
  }

  const handleChangePage = (page: number) => setCurrentPage(page)


  return (
    <>
      <ViewPurchaseOrderModal
        onClose={onCloseViewPurchaseOrderModal}
        isOpen={isViewPurchaseOrderModalOpen}
        purchaseOrder={purchaseOrder}
      />
      <Box
        borderRadius='8px'
        p={4}
        bg='white'
      >
        {isFetchingPurchaseOrdersAwaitingArrivalData ? <Spinner /> :
          <Flex
            w="full"
            direction="column"
            gap={6}
          >
            <Heading fontSize="xl">Compras Aguardando Chegada</Heading>
            <Divider />
            <TableContainer>
              <Table variant="striped" size="sm">
                <Thead>
                  <Tr>
                    <Th>PROTOCOLO</Th>
                    <Th>SOLICITANTE</Th>
                    <Th>DATA SOLICITAÇÃO</Th>
                    <Th>TIPO</Th>
                    <Th>TIPO DE COTAÇÃO</Th>
                    <Th>DATA LIMITE</Th>
                    <Th>FORNECEDOR APROVADO</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {purchaseOrdersAwaitingArrivalResponseData?.purchaseOrders.map(purchaseOrder => {
                    const isPmcPurchaseOrder = purchaseOrder.purchase_description === 'PMC'
                    const isPurchaseOrderEnableToValidate = isPmcPurchaseOrder
                      ? purchaseOrder?.pmc?.received_at !== null
                        ? true
                        : false
                      : true

                    return (
                      <Tr key={purchaseOrder.id}>
                        <Td>{purchaseOrder.protocol}</Td>
                        <Td>{`${purchaseOrder.owner.firstname} ${purchaseOrder.owner.lastname}`}</Td>
                        <Td>{formatDate.handle(purchaseOrder.created_at, 'DateWithoutHourToShow')}</Td>
                        <Td>{purchaseOrder.type}</Td>
                        <Td>{purchaseOrder.quote_type}</Td>
                        <Td>{formatDate.handle(purchaseOrder.arrival_limit_date, 'DateWithoutHourToShow')}</Td>
                        <Td>{purchaseOrder.approved_provider}</Td>
                        <Td isNumeric>
                          <Button
                            onClick={() => handleOpenViewPurchaseOrderModal(purchaseOrder)}
                            variant="ghost"
                          >
                            <Icon
                              cursor="pointer"
                              as={FaEye}
                              transition="all 0.2s"
                              _hover={{ opacity: 0.8 }}
                            />
                          </Button>
                          {userHasPermissionToValidatePurchase && isPurchaseOrderEnableToValidate && (
                            <Button
                              as={Link}
                              to={`/compra/${purchaseOrder.id}/validar`}
                              variant="ghost"
                            >
                              <Icon
                                cursor="pointer"
                                as={FaCheck}
                                transition="all 0.2s"
                                _hover={{ opacity: 0.8 }}
                              />
                            </Button>
                          )}
                          {userHasPermissionToDeletePurchaseOrdersAwaitingArrival && (
                            <Button
                              onClick={() => handleDeletePurchaseOrder(purchaseOrder.id)}
                              variant="ghost"
                            >
                              <Icon
                                cursor="pointer"
                                as={FaTrash}
                                transition="all 0.2s"
                                _hover={{ opacity: 0.8 }}
                              />
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <Pagination
              currentPage={currentPage}
              pages={pages}
              pagesQuantity={pagesCount}
              handlePageChange={handleChangePage}
            />
          </Flex>
        }
      </Box>
    </>
  )
}
