import axios from "axios"
import { useQuery, UseQueryOptions, QueryKey } from "react-query";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import {  CrmReportProps } from "./dtos/CrmReport"

export interface CrmReportResponse {
  reportData: CrmReportProps[]
}

interface CrmReportQueryParams{
  crmType?: string
  startDate: string
  endDate: string
}
type QueryOptions<T = CrmReportResponse> = UseQueryOptions<CrmReportResponse, unknown, T, QueryKey>

interface UseCrmReportOptions<T> {
  queryKey?: string
  queryOptions?: QueryOptions<T>
  queryParams?: CrmReportQueryParams
}

async function generateCrmReportReqFunction({crmType, startDate, endDate}:CrmReportQueryParams){
  try {
    const {data} = await api.get(`report/crms`,{
      params:{
        crmType,
        startDate,
        endDate
      }
    })
    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError(error.response.data.message)
    }
  }
}

export function useCrmReport<T>(options?: UseCrmReportOptions<T>){
  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    errorMessage(message)

    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) await handleLogout()
  }

  return useQuery([
    options?.queryKey ?? 'crmReport',
    {...options?.queryParams}
  ], () => generateCrmReportReqFunction(options?.queryParams),{
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )
}


