import { Box, Flex } from "@chakra-ui/react";
import { CustomerSituation as CustomerSituationType } from "api/customers/_types/Customer";
import { captalize } from "utils/captalize";

interface CustomerSituationProps {
  situation: CustomerSituationType;
}

export function CustomerSituation({ situation }: CustomerSituationProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {situation === 'ATIVO' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {situation === 'INATIVO' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {captalize(situation)}
    </Flex>
  )
}
