import { differenceInMinutes } from "date-fns";
import { formatDate } from "../DateFunctions/formatDate";
import { setDate } from "../DateFunctions/setDate";

export function sortByHour(currentHour: Date | string, nextHour: Date | string) {
  const [currentHours, currentMinutes] = formatDate.handle(currentHour, "DateOnlyWithHourMinute").split(":")
  const [nextHours, nextMinutes] = formatDate.handle(nextHour, "DateOnlyWithHourMinute").split(":")

  const currentHourFormatted = setDate.handleSetHourWithoutFormat(Number(currentHours), Number(currentMinutes))
  const nextHourFormatted = setDate.handleSetHourWithoutFormat(Number(nextHours), Number(nextMinutes))

  const difference = differenceInMinutes(
    currentHourFormatted,
    nextHourFormatted
  )

  if (difference > 0) {
    return 1
  }

  if (difference < 0) {
    return -1
  }

  return 0
}
