import { Flex } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { useFormContext } from "react-hook-form";

export interface FinancialClassificationFormInputs {
  type: string
}

export function FinancialClassificationForm() {

  const {
    register,
    formState: {
      errors
    }
  } = useFormContext<FinancialClassificationFormInputs>()

  return (
    <Flex align="center" gap={6}>
      <Input
        {...register("type")}
        name="type"
        placeholder="Digite o nome da classificação financeira..."
        label="Nome"
        error={errors.type}
        required
      />
    </Flex>
  )
}
