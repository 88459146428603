export class SwitchRequestedServiceMateryalTypes {
  handle() {
    const materialTypes = [
      { id: 0, value: 'BIOLÓGICO UN3373' },
      { id: 1, value: 'BIOLÓGICO RISCO MÍNIMO/ISENTO' },
      { id: 2, value: 'CORRELATOS' },
      { id: 3, value: 'CARGA GERAL' },
      { id: 4, value: 'INFECTANTE UN2814/UN2900' },
    ]

    return materialTypes
  }
}

const switchRequestedServiceMateryalTypes =
  new SwitchRequestedServiceMateryalTypes()

export { switchRequestedServiceMateryalTypes }
