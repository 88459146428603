import { Address } from 'hooks/address/dtos/Address';
import { useToastify } from 'hooks/toastify/useToastify';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query'
import axios from 'axios';
import { useAuth } from 'hooks/auth/useAuth';
import { api } from 'services/api'

import { RequestError } from "utils/errors/RequestErrors"

interface ServiceNewRequestedAddressesResponse {
  newRequestedCollectAddresses: Address[]
  newRequestedDeliveryAddresses: Address[]
}

type QueryOptions = UseQueryOptions<ServiceNewRequestedAddressesResponse, unknown, ServiceNewRequestedAddressesResponse, QueryKey>

interface useServiceNewRequestedAddressesProps {
  serviceId: string
  queryKey?: QueryKey
  queryOptions?: QueryOptions
}

async function getNewRequestedAddresses(serviceId: string) {
  try {
    const { data } = await api.get(`/services/${serviceId}/addresses/requested`)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

export function useServiceNewRequestedAddresses(options?: useServiceNewRequestedAddressesProps) {
  const { handleLogout } = useAuth()
  const { errorMessage } = useToastify()

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([options?.queryKey || 'newServiceRequestedAddresses'],
    () => getNewRequestedAddresses(options.serviceId), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )
}
