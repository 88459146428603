import { api } from '../../services/api'

interface BranchProps {
  id: string
  nickname: string
  shipping_id: string
  email: string
  hub_id: string
  cellphone: string
  telephone: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  business_open: string
  business_close: string
  saturday_open: string
  saturday_close: string
  sunday_open: string
  sunday_close: string
  open_on_holidays: boolean
  holiday_open: string | null
  holiday_close: string | null
  observation: string
  shippingId: {
    company_name: string
    trading_name: string
  }
  hubId: {
    name: string
  }
  situation: string
}

export async function getBranchById(id?: string): Promise<BranchProps> {
  const response = await api.get<BranchProps>(`branch/${id}`)
  const data = response.data

  return data
}
