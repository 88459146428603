import { Box, Button, Flex, FormControl, FormLabel, Heading, Select, Text, Textarea } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup"
import { realizePassiveFeedback } from "api/feedbacks/realizePassiveFeedback";
import { useToastify } from "hooks/toastify/useToastify";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup"

interface RealizeRecurrentInternCltPassiveFeedbackProps {
  passiveFeedbackId: string
}

interface RealizeRecurrentInternCltPassiveFeedbackSchema {
  selfPerformanceLast6Months: number
  goalAchievementFeedback: string
  improvementFeedback: string
  challengesFeedback: string
  goalsFeedback: string
  teamEnvironmentRate: number
  improvementSugestion: string
  marketingFeedback: string
  leadershipRate: number
  supportFeedback: string
  companyImprovementFeedback: string
  loglifeReferralScore: number
  observations?: string
}

const realizeRecurrentInternCltPassiveFeedbackSchema = yup.object({
  selfPerformanceLast6Months: yup.number().required(),
  goalAchievementFeedback: yup.string().required(),
  improvementFeedback: yup.string().required(),
  challengesFeedback: yup.string().required(),
  goalsFeedback: yup.string().required(),
  teamEnvironmentRate: yup.number().required(),
  improvementSugestion: yup.string().required(),
  marketingFeedback: yup.string().required(),
  leadershipRate: yup.number().required(),
  supportFeedback: yup.string().required(),
  companyImprovementFeedback: yup.string().required(),
  loglifeReferralScore: yup.number().required(),
  observations: yup.string()
})

const formSelectOptions = Array.from({ length: 10 }, (_, i) => (i + 1).toString());

export function RealizeRecurrentInternCltPassiveFeedback({
  passiveFeedbackId
}: RealizeRecurrentInternCltPassiveFeedbackProps) {
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<RealizeRecurrentInternCltPassiveFeedbackSchema>({
    resolver: yupResolver(realizeRecurrentInternCltPassiveFeedbackSchema)
  })

  const { mutateAsync: realizeRecurrentInternCltPassiveFeedbackFn } = useMutation({
    mutationFn: realizePassiveFeedback,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'passives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'scheduled-passives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'done-passives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: ['passive-feedback', passiveFeedbackId] })
    }
  })

  async function handleRealizeRecurrentInternCltPassiveFeedback(values: RealizeRecurrentInternCltPassiveFeedbackSchema) {
    await promiseMessage(realizeRecurrentInternCltPassiveFeedbackFn({
      body: {
        ...values,
      },
      passiveFeedbackId
    }), 'Feedback realizado')
  }

  return (
    <Box
      w="full"
      as="form"
      maxH='500px'
      overflowY='scroll'
      onSubmit={handleSubmit(handleRealizeRecurrentInternCltPassiveFeedback)}
    >
      <Heading letterSpacing="tight" size='sm'>Formulário Recorrente</Heading>
      <FormControl isInvalid={!!errors.selfPerformanceLast6Months} mt={3}>
        <FormLabel fontSize="sm">
          Como você avalia o seu desempenho nos últimos 6 meses?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register('selfPerformanceLast6Months')}
          name="selfPerformanceLast6Months"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
        >
          {Object.entries(formSelectOptions).map(([key, value]) => (
            <option key={key} value={value}>{value}</option>
          ))}

        </Select>
      </FormControl>
      <FormControl isInvalid={!!errors.goalAchievementFeedback} mt={3}>
        <FormLabel fontSize="sm">
          Você sente que atingiu suas metas e objetivos?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("goalAchievementFeedback")}
          name="goalAchievementFeedback"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.improvementFeedback} mt={3}>
        <FormLabel fontSize="sm">
          O que precisa melhorar? Há algum projeto ou tarefa que gostaria de ter feito melhor?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("improvementFeedback")}
          name="improvementFeedback"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.challengesFeedback} mt={3}>
        <FormLabel fontSize="sm">
          Quais foram os maiores desafios que você enfrentou nos últimos 6 meses? O que aprendeu com eles?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("challengesFeedback")}
          name="challengesFeedback"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.goalsFeedback} mt={3}>
        <FormLabel fontSize="sm">
          Quais são suas metas para o próximo semestre? Como acha que a empresa pode contribuir?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("goalsFeedback")}
          name="goalsFeedback"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.teamEnvironmentRate} mt={3}>
        <FormLabel fontSize="sm">
          Como você avalia o ambiente de trabalho da sua equipe? (1 a 10)
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register('teamEnvironmentRate')}
          name="teamEnvironmentRate"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
        >
          {Object.entries(formSelectOptions).map(([key, value]) => (
            <option key={key} value={value}>{value}</option>
          ))}

        </Select>
      </FormControl>
      <FormControl isInvalid={!!errors.improvementSugestion} mt={3}>
        <FormLabel fontSize="sm">
          Qual sugestão para melhoria?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("improvementSugestion")}
          name="improvementSugestion"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.leadershipRate} mt={3}>
        <FormLabel fontSize="sm">
          Nota para liderança? (1 a 10)
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register('leadershipRate')}
          name="leadershipRate"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
        >
          {Object.entries(formSelectOptions).map(([key, value]) => (
            <option key={key} value={value}>{value}</option>
          ))}

        </Select>
      </FormControl>
      <FormControl isInvalid={!!errors.supportFeedback} mt={3}>
        <FormLabel fontSize="sm">
          Você se sente apoiado? O que poderia ser melhorado?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("supportFeedback")}
          name="supportFeedback"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.marketingFeedback} mt={3}>
        <FormLabel fontSize="sm">
          O que acha das ações de endomarketing? Sugere alguma melhoria?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("marketingFeedback")}
          name="marketingFeedback"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.companyImprovementFeedback} mt={3}>
        <FormLabel fontSize="sm">
          Existe algo que gostaria de mudar ou melhorar na empresa?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("companyImprovementFeedback")}
          name="companyImprovementFeedback"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.loglifeReferralScore} mt={3}>
        <FormLabel fontSize="sm">
          Qual a possibilidade de indicar a Loglife para alguém trabalhar? (0 a 10)
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register('loglifeReferralScore')}
          name="loglifeReferralScore"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
        >
          {Object.entries(formSelectOptions).map(([key, value]) => (
            <option key={key} value={value}>{value}</option>
          ))}

        </Select>
      </FormControl>
      <FormControl isInvalid={!!errors.companyImprovementFeedback} mt={3}>
        <FormLabel fontSize="sm">
          Observações:

        </FormLabel>
        <Textarea
          {...register("observations")}
          name="observations"
          size="sm"
          rounded="md"
        />
      </FormControl>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
