import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface ConfirmDestinationBranchBody {
  hasConfirm: string
}

interface ConfirmDestinationBranchParams {
  serviceId: string
  body: ConfirmDestinationBranchBody
}

export async function confirmDestinationBranch({
  serviceId,
  body
}: ConfirmDestinationBranchParams) {
  try {
    await api.patch(`/services/${serviceId}/board/confirm-destination-branch`, body)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
