import axios from "axios"
import { useAuth } from "hooks/auth/useAuth"
import { useMutation, useQueryClient } from "react-query"
import { api } from "services/api"
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum"
import { RequestError } from "utils/errors/RequestErrors"


export interface CreateShippingCostInput {
  sourceBranchId: string
  destinationBranchId: string
  weights: {
    kg: number
    priceInCents: string
  }[]
  extraKgPriceInCents: string
}

interface CreateShippingCostInputReqFunctionProps {
  shippingId: string
  input: CreateShippingCostInput
}

async function createShippingCostReqFunction({
  shippingId,
  input
}: CreateShippingCostInputReqFunctionProps) {
  try {
    const { data } = await api.post(`/shippings/${shippingId}/costs`, input)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

type EditShippingCostInput = CreateShippingCostInput & {
  shippingCostId: string
  shippingId: string
}

async function updateShippingCostReqFunction(input: EditShippingCostInput) {
  try {
    const { data } = await api.put(`/shippings/${input.shippingId}/costs/${input.shippingCostId}`, input)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

async function deleteShippingCostReqFunction(shippingCostId: string) {
  try {
    await api.delete(`/shippings/costs/${shippingCostId}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    }
    throw new RequestError('Erro inesperado!')
  }
}

export function useShippingCostFunctions() {
  const queryClient = useQueryClient()
  const { handleLogout, } = useAuth()

  const onRequestSuccess = async () => {
    await queryClient.invalidateQueries("shippingCosts")
  }

  const onRequestError = async (error: RequestError) => {
    if (error.httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  const createShippingCost = useMutation('createPurchaseOrder', createShippingCostReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  const updateShippingCost = useMutation('updateShippingCost', updateShippingCostReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  const deleteShippingCost = useMutation('deleteShippingCost', deleteShippingCostReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })


  return {
    createShippingCost,
    updateShippingCost,
    deleteShippingCost
  }
}
