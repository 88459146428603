import { format } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { DateFormats, dateFormatsMoreUsed } from './dateFormats'
import { TimezonesFormat, timezonesUsed } from './timezones'

type TimezoneFormats = TimezonesFormat | null | string;

export class FormatDate {
  handle(date: string | Date | null, dateFormat: DateFormats, timezone?: TimezoneFormats) {
    if (date) {
      if (timezone) {
        if (date instanceof Date) {
          return formatInTimeZone(date, timezonesUsed[timezone as TimezonesFormat].timezone, dateFormatsMoreUsed[dateFormat].format)
        }
        return formatInTimeZone(new Date(date), timezonesUsed[timezone as TimezonesFormat].timezone, dateFormatsMoreUsed[dateFormat].format)
      } else {
        if (date instanceof Date) {
          return format(date, dateFormatsMoreUsed[dateFormat].format)
        }
        return format(new Date(date), dateFormatsMoreUsed[dateFormat].format)
      }
    }

    return '-'
  }
}

const formatDate = new FormatDate()
export { formatDate }
