import { Button, Flex, Icon, Spinner } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import * as yup from "yup"
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { FaList } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import { ExtrasAndDiscountsForm, ExtrasAndDiscountsFormInputs } from "./components/ExtrasAndDiscountsForm";
import { useExtrasAndDiscountsFunctions } from "hooks/extrasAndDiscounts/useExtrasAndDiscountsFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { useBusinessBudgetRoutes } from "hooks/businessBudget/useBusinessBudgetsRoutes";
import { useNonInactiveCustomers } from "hooks/customer/useNonInactiveCustomers";
import { useExtraAndDiscount } from "hooks/extrasAndDiscounts/useExtraAndDiscount";

const updateExtrasAndDiscountsFormSchema = yup.object().shape({
  customer_id: yup.string().required('Campo obrigatório.'),
  type: yup.string().required('Campo obrigatório.'),
  service_type: yup.string().required('Campo obrigatório.'),
  protocol: yup.mixed().when('service_type', {
    is: 'EXPRESS',
    then: yup.string().required('Campo obrigatório.')
  }),
  route: yup.mixed().when('service_type', {
    is: 'BUSINESS',
    then: yup.string().required('Campo obrigatório.')
  }),
  occurrence_date: yup.string().when("service_type", {
    is: 'BUSINESS',
    then: yup.string().required('Campo obrigatório.')
  }),
  description: yup.string().required('Campo obrigatório.'),
  value: yup.string().required('Campo obrigatório.'),
})

interface Params {
  extraAndDiscountId: string
}

export function UpdateExtrasAndDiscounts() {

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  const formMethods = useForm<ExtrasAndDiscountsFormInputs>({
    resolver: yupResolver(updateExtrasAndDiscountsFormSchema)
  })

  const { setValue, control, handleSubmit } = formMethods

  const userLoggedHasPermissionToUpdateExtrasAndDiscounts =
    userLogged?.permissions.includes('update-extra-and-discount')

  useEffect(() => {
    if (!userLoggedHasPermissionToUpdateExtrasAndDiscounts) {
      redirect('/')
    }
  }, [userLoggedHasPermissionToUpdateExtrasAndDiscounts, redirect])

  const {
    data: customersData,
    isFetching: isCustomersDataFetching
  } = useNonInactiveCustomers()

  const [customerSelected, serviceType] = useWatch({
    control,
    name: ['customer_id', 'service_type']
  })

  const isBusinessServiceType = serviceType === 'BUSINESS'

  const {
    data: businessBudgetRoutesData,
    isFetching: isFetchingBusinessBudgetRoutesData
  } = useBusinessBudgetRoutes({
    customer_id: customerSelected,
    queryOptions: {
      enabled: !!customerSelected && isBusinessServiceType
    }
  })

  const businessBudgetRoutesOptions = businessBudgetRoutesData?.routes.map(
    (route) => {
      return {
        key: route.id,
        value: route.route_nickname,
        showOption: route.route_nickname
      }
    }
  )

  const { promiseMessage } = useToastify()

  const {
    updateCustomerExtraAndDiscount
  } = useExtrasAndDiscountsFunctions()

  async function handleUpdateExtrasAndDiscounts(
    formValues: ExtrasAndDiscountsFormInputs
  ) {
    const successMessage = formValues.type === 'DESCONTO'
      ? 'Desconto criado com sucesso!'
      : 'Extra criado com sucesso!'

    const formData = new FormData()

    formData.append('type', formValues.type)
    formData.append('service_type', formValues.service_type)
    if (formValues.protocol) {
      formData.append('protocol', formValues.protocol.toString())
    }
    if (formValues.route) {
      formData.append('route', formValues.route)
    }
    formData.append('description', formValues.description)
    formData.append('value', formValues.value)
    formData.append('attachment', formValues.attachment[0])

    if (formValues.occurrence_date) {
      formData.append("occurrence_date", formValues.occurrence_date)
    }

    await promiseMessage(updateCustomerExtraAndDiscount.mutateAsync({
      extraAndDiscountId,
      customerId: formValues.customer_id,
      input: formData
    }, {
      onSuccess: () => {
        redirect('/extras-e-descontos')
      }
    }), successMessage)
  }

  const customersOptions = customersData?.customers.map((customer) => {
    return {
      key: customer.id,
      value: customer.id,
      showOption: customer.trading_firstname
    }
  }).sort((a, b) => a.showOption.localeCompare(b.showOption))

  const { extraAndDiscountId } = useParams<Params>()

  const {
    data: extraAndDiscountData,
    isFetching: isFetchingExtraAndDiscountData
  } = useExtraAndDiscount({
    extraAndDiscountId,
    queryOptions: { enabled: !!extraAndDiscountId }
  })

  useEffect(() => {
    if (extraAndDiscountData) {
      setValue('type', extraAndDiscountData?.extraAndDiscount.type)
      setValue('service_type', extraAndDiscountData?.extraAndDiscount.service_type)
      setValue('description', extraAndDiscountData?.extraAndDiscount.description)
      setValue('value', String(extraAndDiscountData?.extraAndDiscount.value / 100))
      setValue('protocol', extraAndDiscountData?.extraAndDiscount.protocol)
      if (extraAndDiscountData?.extraAndDiscount?.occurrence_date) {
        setValue('occurrence_date', extraAndDiscountData?.extraAndDiscount?.occurrence_date)
      }
    }
  }, [extraAndDiscountData, setValue])

  useEffect(() => {
    if (customersData && extraAndDiscountData) {
      if (extraAndDiscountData.extraAndDiscount.customer) {
        setValue('customer_id', extraAndDiscountData?.extraAndDiscount?.customer.id)

        if (businessBudgetRoutesData) {
          setValue('route', extraAndDiscountData?.extraAndDiscount?.route)
        }
      }
    }
  }, [setValue, customersData, extraAndDiscountData, businessBudgetRoutesData])


  return (
    <StandardBackgroundForm
      onSubmit={handleSubmit(handleUpdateExtrasAndDiscounts)}
      title='Atualizar extra e desconto'
    >

      {(isCustomersDataFetching || isFetchingExtraAndDiscountData) ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <ExtrasAndDiscountsForm
            slug="update"
            attachmentPreview={extraAndDiscountData?.extraAndDiscount?.attachment}
            customerOptions={customersOptions}
            businessBudgetRoutesOptions={businessBudgetRoutesOptions}
            isFetchingBusinessBudgetRoutesData={isFetchingBusinessBudgetRoutesData}
          />
        </FormProvider>
      )}

      <Flex w='full' mt={4} gap={2} justify='flex-end'>
        <Button
          type='submit'
          colorScheme='blue'
        >
          Salvar
        </Button>
        <Button
          as={Link}
          to='/extras-e-descontos'
          leftIcon={<Icon as={FaList} />}
        >
          Extras e descontos
        </Button>
      </Flex>

    </StandardBackgroundForm>
  )
}
