import { Box, Flex } from "@chakra-ui/react";
import { SupportStatus as SupportStatusTypes } from "api/supports/_types/Support";
import { supportStatusMap } from "utils/supportMappers";

interface SupportStatusProps {
  status: SupportStatusTypes;
}

export function SupportStatus({ status }: SupportStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {['analyzing','supporting','waiting-return','verifying'].includes(status)  && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'done' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'canceled' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {supportStatusMap[status]}
    </Flex>
  )
}
