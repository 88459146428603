import { useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { Collector } from "./dtos/Collector";

interface CollectorsQueryParams {
  hub_id?: string
  hubs?: string[]
  situation?: 'ATIVO' | 'INATIVO'
}

type QueryOptions = UseQueryOptions<Collector[], unknown, Collector[], "collectors">

interface UseCollectorsOptions {
  queryOptions?: QueryOptions
  queryParams?: CollectorsQueryParams
}

export function useCollectors(options?: UseCollectorsOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getCollectors(queryParams?: CollectorsQueryParams) {
    try {
      const { data } = await api.get(`/collector`, {
        params: {
          ...queryParams,
          hubs: queryParams?.hubs?.join(",")
        }
      })

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new Error("Erro inesperado!")
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery("collectors", () => getCollectors(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
