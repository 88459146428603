import { BudgetProps } from '../../../contexts/BudgetContext'
import { CustomerProps } from '../../../contexts/CustomerContext'
import { api } from '../../api'

export interface RecurrentServiceProps {
  id: string
  customer_id: string
  situation: string
  budget_id: string
  owner: string
  sourceCollectorIDRecurrent: {
    trading_name: string
  }
  destinationCollectorIDRecurrent: {
    trading_name: string
  }
  sourceBranchIDRecurrent: {
    nickname: string
  }
  destinationBranchIDRecurrent: {
    nickname: string
  }
  source_address_id: string[]
  destination_address_id: string[]
  source_collector_id: string | null
  destination_collector_id: string | null
  source_branch_id: string | null
  destination_branch_id: string | null
  provider_id: string | null
  availability_forecast_deadline: number | null
  availability_forecast_day: string | null
  availability_forecast_time: string | null
  board_hour: string | null
  board_date: number | null
  planned_flight: string | null
  deadline: number
  service_type: string
  franchising: number
  modal: string
  vehicle: string
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  collect_date: string | null
  collect_hour_start: string
  collect_hour_end: string
  delivery_date: string | null
  delivery_hour: string
  days_of_week: number[]
  material_type: string
  driver_address_assign: string
  driver_provider_assign: string
  destination_addresses_input:
    | {
        address_id: string
        quantity: number
      }[]
    | null
  observation: string
  created_at: string
  customerIDRecurrent: CustomerProps
  budgetIDRecurrent: BudgetProps
}

export const getAllRecurrentServices = () =>
  api.get<RecurrentServiceProps[]>('/recurrent-service').then((res) => res.data)
export const getOneRecurrentService = (recurrentServiceId: string) =>
  api
    .get<RecurrentServiceProps>(`/recurrent-service/${recurrentServiceId}`)
    .then((res) => res.data)
export const getRecurrentServiceByDayOfWeek = (dayOfWeek: number | null) =>
  api
    .get<RecurrentServiceProps[]>(`/recurrent-service/?dayOfWeek=${dayOfWeek}`)
    .then((res) => res.data)
