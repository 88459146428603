import { createContext, ReactNode, useEffect, useState } from 'react'
import { api } from '../services/api'

import 'react-toastify/dist/ReactToastify.css'
import swal from 'sweetalert'

interface ClientsProviderProps {
  children: ReactNode
  pathname: string
}

export interface ReportProps {
  id: string
  customer_id: string
  collector_id: string
  serviceType: string
  serviceID: string
  deliveryDate: string
  collectDates: string[]
  collectHours: string[]
  deliveryDates: string[]
  deliveryHours: string[]
  sourceCity: string
  sourceState: string
  destinationCity: string
  destinationState: string
  customer: string
  collectDate: string
  destinationBranch: string
  destinationCollector: string
  endFilter: string
  estimatedTimeAvailable: string
  expectedCollectTime: string
  expectedDeliveryTime: string
  protocol: number
  realTimeAvailable: string
  estimatedDateAvailable: string
  realDateAvailable: string
  slaTransfer: string
  slaDelivery: string
  slaCollect: string
  availabilityForecastDay: string
  availabilityForecastTime: string
  sourceBranch: string
  sourceCollector: string
  startFilter: string
  step: string
}

export interface StockReportProps {
  serviceAdesivoDryIce: number
  serviceAlmofadaAbsorvente: number
  collectDate: string
  deliveryDate: string
  customer: string
  destinationCollectorCity: string
  destinationCollectorName: string
  sourceCollectorName: string
  sourceCollectorCity: string
  serviceEmbalagemSecundaria: number
  serviceEmbalagemSecundariaPote1l: number
  endFilter: string
  serviceGeloSeco: string
  serviceFitaTransparente: number
  serviceGelox: number
  serviceIsopor3l: number
  serviceIsopor7l: number
  serviceLacre: number
  protocol: number
  serviceID: string
  serviceType: string
  startFilter: string
  sourceCity: string
  destinationCity: string
  step: string
  serviceTerciaria3l: number
  serviceTerciaria8l: number
  budgetCaixaTermica: number
  budgetEmbalagemSecundaria: number
  budgetEmbalagemSecundariaPote1l: number
  budgetGeloSeco: number
  budgetGelox: number
  budgetIsopor3l: number
  budgetIsopor7l: number
  budgetTerciaria3l: number
  budgetTerciaria8l: number
  additionalMaterials: string
  modal: string
}

export interface BillingReportProps {
  addCollectPrice: string
  addDeliveryPrice: string
  collectSample: string
  observation: string
  deliveryHour: string
  deliveryDate: string
  collectHourEnd: string
  boardVolume: number
  budgetDestinationAddressQty: number
  budgetPrice: string
  budgetSourceAddressQty: number
  collectDate: string
  sourceCity: string
  destinationCity: string
  collectVolume: string
  deliveryVolume: string
  collectDiffTime: number
  cteLoglife: string
  cteLoglifeEmissionDate: string
  customer: string
  deliveryDiffTime: number
  endFilter: string
  extraKGPrice: string
  extraKgQuantity: number
  extraTimePrice: string
  infoExtraMaterials: string
  totalCostExtraMaterials: string
  franchising: number
  landingVolume: number
  protocol: number
  realWeight: number
  totalExtraKGPrice: number
  totalAdditionalCollects: number
  totalAdditionalDeliveries: number
  vehicle: string
  serviceDestinationAddressQty: number
  serviceID: string
  serviceSourceAddressQty: number
  serviceType: string
  startFilter: string
  step: string
  sourceStates: string
  destinationStates: string
  sourceAddress: string
  destinationAddress: string
  taxedWeight: number
  totalPrice: string
  awaitingTimeCollect: number
  awaitingCostCollect: number
  awaitingTimeDelivery: number
  awaitingCostDelivery: number
  unsuccessCount: number
  otherCharges: string
  otherChargesValue: number
  unsuccessPrice: number
  complementaryCte: string
  cteComplementaryEmissionDate: string
  additionalChargesTotalValue: string
}

export interface CargoReportProps {
  boardDateTime: string
  boardVolume: number
  collectDate: string
  shipping: string
  vehicle: string
  sourceAddress: string
  sourceCollector: string
  sourceCity: string
  destinationCollector: string
  destinationCity: string
  destinationAddress: string
  customer: string
  deliveryDate: string
  deliveryHour: string
  destinationBranch: string
  endFilter: string
  landingDateTime: string
  landingVolume: number
  hasCrossdocking: string
  tracker: string
  crossdockingTracker: string
  sourceCrossdockingBranch: string
  destinationCrossdockingBranch: string
  cte: string
  whichBoard: string
  crossdockingCTE: string
  crossdockingShipping: string
  protocol: number
  serviceID: string
  serviceType: string
  sourceBranch: string
  startFilter: string
  geloSeco: number
  step: string
}

export interface CteReportProps {
  serviceID: string
  protocol: string
  customer: string
  step: string
  startFilter: string
  endFilter: string
  serviceType: string
  collectDate: string
  collectHourStart: string
  collectHourEnd: string
  franchising: string
  taxedWeight: number
  transportPrice: string
  kgExtraPrice: string
  sourceCity: string
  destinationCity: string
  sourceCollectorName: string
  destinationCollectorName: string
  sourceAddress: string
  sourceCnpjCpf: string
  destinationAddress: string
  destinationCnpjCpf: string
  deliveryDate: string
  deliveryHour: string
}

export interface StockControlReportProps {
  collector: string
  collectorHubs: string[]
  input: string
  minimumStock: string
  standardStock: string
  currentStock: string
}

export interface OccurrenceReportProps {
  occurrenceProtocol: string
  serviceProtocol: string
  customer: string
  customer_id: string
  sourceCollector: string
  destinationCollector: string
  collector: string
  shipping: string
  type: string
  sourceCities: string
  destinationCities: string
  intercurrence: string
  occurrenceDetail: string
  occurrenceDate: string
  occurrenceHour: string
  proceed: string
  cause: string
  corrective: string
  preventive: string
  reason: string
  owner: string
  owner_id: string
  createdAt: string
  updatedAt: string
  serviceStep: string
  step: string
  user: string
  photo: string | null
  movedToValidationDate: string
  observation: string
}

export type RncReportProps = {
  protocol: string
  direction: string
  date: string
  intercurrence_description: string
  loss_value: number
  opened_by: string
  intercurrence_responsible: string
  why_not_proceed: string
  cause: string
  corrective: string
  preventive: string
  risk_rating: string
  rnc_type: string
  alignment_date: string
  alignment_observation: string
  business_meeting: string
  status: string
}

export type CustomerManagmentReportProps = {
  protocol: number
  customer: string
  step: string
  collectDate: string
  sourceCities: string
  sourceAddressQuantity: number
  recipientName: string
  realCollectHours: string
  deliveryDate: string
  destinationCities: string
  destinationAddressQuantity: number
  receiverName: string
  realDeliveryHours: string
  unsuccessCollectsCount: number
  unsuccessCollectsAddresses: string
}

export type SpotReportProps = {
  protocol: number
  successCollectedAddresses: string
  unsuccessCollectedAddresses: string
}

interface IInputProps {
  is_driver: boolean
  shipping_id: string | null
  customer_id: string | null
  collector_id: string | null
  startFilter: string
  endFilter: string
  is_customer: boolean
}

interface ReportContextProps {
  slaReport: ReportProps[]
  isSlaReportLoaded: boolean
  stockReport: StockReportProps[]
  isStockReportLoaded: boolean
  billingReport: BillingReportProps[]
  isBillingReportLoaded: boolean
  cargoReport: CargoReportProps[]
  isCargoReportLoaded: boolean
  cteReport: CteReportProps[]
  isCteReportLoaded: boolean
  stockControllerReport: StockControlReportProps[]
  isStockControllerReportLoaded: boolean
  occurrenceReport: OccurrenceReportProps[]
  isOccurrenceReportLoaded: boolean
  rncReport: RncReportProps[]
  isRncReportLoaded: boolean
  customerManagmentReport: CustomerManagmentReportProps[]
  isCustomerManagmentReportLoaded: boolean
  sendSlaDate: (slaInput: IInputProps) => Promise<void>
  sendStockData: (stockInput: IInputProps) => Promise<void>
  sendBillingData: (billingInput: IInputProps) => Promise<void>
  sendCargoData: (cargoInput: IInputProps) => Promise<void>
  sendCteData: (cteInput: IInputProps) => Promise<void>
  sendStockControllerData: (stockControllerInput: IInputProps) => Promise<void>
  sendOccurrenceData: (occurrenceInput: IInputProps) => Promise<void>
  sendRncData: (rncInput: IInputProps) => Promise<void>
  sendCustomerManagmentReportData: (input: IInputProps) => Promise<void>
}

export const ReportContext = createContext<ReportContextProps>(
  {} as ReportContextProps,
)

export function ReportProvider({ children, pathname }: ClientsProviderProps) {
  const [slaReport, setSlaReport] = useState<ReportProps[]>([])
  const [isSlaReportLoaded, setIsSlaReportLoaded] = useState(false)

  const [stockReport, setStockReport] = useState<StockReportProps[]>([])
  const [isStockReportLoaded, setIsStockReportLoaded] = useState(false)

  const [billingReport, setBillingReport] = useState<BillingReportProps[]>([])
  const [isBillingReportLoaded, setIsBillingReportLoaded] = useState(false)

  const [cargoReport, setCargoReport] = useState<CargoReportProps[]>([])
  const [isCargoReportLoaded, setIsCargoReportLoaded] = useState(false)

  const [cteReport, setCteReport] = useState<CteReportProps[]>([])

  const [isCteReportLoaded, setIsCteReportLoaded] = useState(false)

  const [stockControllerReport, setStockControllerReport] = useState<
    StockControlReportProps[]
  >([])
  const [isStockControllerReportLoaded, setIsStockControllerReportLoaded] =
    useState(false)

  const [occurrenceReport, setOccurrenceReport] = useState<
    OccurrenceReportProps[]
  >([])
  const [isOccurrenceReportLoaded, setIsOccurrenceReportLoaded] =
    useState(false)

  const [rncReport, setRncReport] = useState<RncReportProps[]>([])
  const [isRncReportLoaded, setIsRncReportLoaded] = useState(false)

  const [customerManagmentReport, setCustomerManagmentReport] = useState<
    CustomerManagmentReportProps[]
  >([])
  const [isCustomerManagmentReportLoaded, setIsCustomerManagmentReportLoaded] =
    useState(false)

  useEffect(() => {
    function run() {
      if (!pathname?.includes('sla')) {
        setSlaReport([])
      }
      if (!pathname?.includes('estoque')) {
        setStockReport([])
      }
      if (!pathname?.includes('faturamento')) {
        setBillingReport([])
      }
      if (!pathname?.includes('cargas')) {
        setCargoReport([])
      }
      if (!pathname?.includes('cte')) {
        setCteReport([])
      }
      if (!pathname?.includes('controle-estoque')) {
        setStockControllerReport([])
      }
      if (!pathname?.includes('ocorrencias')) {
        setOccurrenceReport([])
      }
      if (!pathname?.includes('rnc')) {
        setRncReport([])
      }
      if (!pathname?.includes('gerencial-cliente')) {
        setCustomerManagmentReport([])
      }
    }
    run()
  }, [pathname])

  async function sendSlaDate(slaInput: IInputProps) {
    try {
      const response = await api.post('/report/sla', slaInput)
      const data = response.data

      if (response) {
        setIsSlaReportLoaded(true)
      }

      setSlaReport(data)
      swal('Poof! O Sla retornou informações com sucesso!', {
        icon: 'success',
      })
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
    }
  }

  async function sendStockData(stockInput: IInputProps) {
    try {
      const response = await api.post('/report/stock', stockInput)
      const data = response.data

      if (response) {
        setIsStockReportLoaded(true)
      }

      setStockReport(data)
      swal('Poof! O Stock retornou informações com sucesso!', {
        icon: 'success',
      })
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
    }
  }

  async function sendBillingData(billingInput: IInputProps) {
    try {
      const response = await api.post('/report/billing', billingInput)
      const data = response.data

      if (response) {
        setIsBillingReportLoaded(true)
      }

      setBillingReport(data)
      swal(
        'Poof! As informações de faturamento foram retornadas com sucesso!',
        {
          icon: 'success',
        },
      )
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
    }
  }

  async function sendCargoData(cargoInput: IInputProps) {
    try {
      const response = await api.post('/report/cargo', cargoInput)
      const data = response.data

      if (response) {
        setIsCargoReportLoaded(true)
      }

      setCargoReport(data)
      swal(
        'Poof! As informações de carga em trânsito foram retornadas com sucesso!',
        {
          icon: 'success',
        },
      )
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
    }
  }

  async function sendCteData(costInput: IInputProps) {
    try {
      const response = await api.post('/report/cte', costInput)
      const data = response.data

      if (response) {
        setIsCteReportLoaded(true)
      }

      setCteReport(data)
      swal(
        'Poof! As informações do relatório de CTE foram retornadas com sucesso!',
        {
          icon: 'success',
        },
      )
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
    }
  }

  async function sendStockControllerData(stockControllerInput: IInputProps) {
    try {
      const response = await api.post(
        '/report/stock-control',
        stockControllerInput,
      )
      const data = response.data

      if (response) {
        setIsStockControllerReportLoaded(true)
      }

      setStockControllerReport(data)
      swal(
        'Poof! As informações de controle de estoque foram retornadas com sucesso!',
        {
          icon: 'success',
        },
      )
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
    }
  }

  async function sendOccurrenceData(occurrenceInput: IInputProps) {
    try {
      const response = await api.post('/report/occurrence', occurrenceInput)
      const data = response.data

      if (response) {
        setIsOccurrenceReportLoaded(true)
      }

      setOccurrenceReport(data)
      swal('Poof! As informações de ocorrência foram retornadas com sucesso!', {
        icon: 'success',
      })
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
    }
  }

  async function sendRncData(rncInput: IInputProps) {
    try {
      const { data } = await api.post('/report/rnc', rncInput)

      setIsRncReportLoaded(true)
      setRncReport(data)
      swal('Poof! As informações RNC foram retornadas com sucesso!', {
        icon: 'success',
      })
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
    }
  }

  async function sendCustomerManagmentReportData(input: IInputProps) {
    try {
      const { data } = await api.post('/report/customer-managment', input)

      setIsCustomerManagmentReportLoaded(true)
      setCustomerManagmentReport(data)
      swal(
        'Poof! As informações do relatório gerencial de cliente foram retornadas com sucesso!',
        {
          icon: 'success',
        },
      )
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
    }
  }

  return (
    <ReportContext.Provider
      value={{
        slaReport,
        isSlaReportLoaded,
        stockReport,
        isStockReportLoaded,
        billingReport,
        isBillingReportLoaded,
        cargoReport,
        isCargoReportLoaded,
        cteReport,
        isCteReportLoaded,
        stockControllerReport,
        isStockControllerReportLoaded,
        occurrenceReport,
        isOccurrenceReportLoaded,
        rncReport,
        isRncReportLoaded,
        customerManagmentReport,
        isCustomerManagmentReportLoaded,
        sendSlaDate,
        sendStockData,
        sendBillingData,
        sendCargoData,
        sendCteData,
        sendStockControllerData,
        sendOccurrenceData,
        sendRncData,
        sendCustomerManagmentReportData,
      }}
    >
      {children}
    </ReportContext.Provider>
  )
}
