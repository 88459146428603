import { Stack } from "@chakra-ui/react";
import { Options, Select } from "components/Inputs/SelectInput";
import { useFormContext } from "react-hook-form";

interface ConnectionBranchesInputsGroupProps {
  isAirModal: boolean
  isCrossdockingAirModal: boolean
  isCrossdockingService: boolean
  hasConnection: boolean
  hasCrossdockingConnection: boolean
  connectionBranchesOptions: Options[]
  crossdockingConnectionBranchesOptions: Options[]
  isBranchesFetching: boolean
  isDisabled?: boolean
}

export function ConnectionBranchesInputsGroup({
  isAirModal,
  isDisabled = false,
  isCrossdockingAirModal,
  isCrossdockingService,
  hasConnection,
  hasCrossdockingConnection,
  connectionBranchesOptions,
  crossdockingConnectionBranchesOptions
}: ConnectionBranchesInputsGroupProps) {

  const { register, formState: { errors } } = useFormContext();

  const connectionBranchLabel = isCrossdockingService
    ? 'Base conexão (segundo embarque)'
    : 'Base conexão'

  return (
    <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
      {isCrossdockingAirModal && hasCrossdockingConnection && (
        <Select
          {...register("crossdocking_connection_branch_id")}
          name="crossdocking_connection_branch_id"
          label="Base conexão crossdocking (primeiro embarque)"
          error={errors.crossdocking_connection_branch_id}
          placeholder="Selecione uma opção..."
          options={crossdockingConnectionBranchesOptions}
          isDisabled={isDisabled}
          required
        />
      )}
      {isAirModal && hasConnection && (
        <Select
          {...register("connection_branch_id")}
          name="connection_branch_id"
          label={connectionBranchLabel}
          error={errors.connection_branch_id}
          placeholder="Selecione uma opção..."
          options={connectionBranchesOptions}
          isDisabled={isDisabled}
          required
        />
      )}
    </Stack>
  )
}
