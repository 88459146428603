import { Alert, AlertDescription, AlertIcon, Button, FormControl, FormLabel, Input, InputGroup, InputLeftAddon, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Radio, RadioGroup, Select, Stack, Table, TableContainer, Tbody, Td, Text, Textarea, Th, Thead, Tr } from "@chakra-ui/react";
import { getMultiBoardServices } from "api/service/getMultiBoardServices";
import axios from "axios";
import { useEffect, useState } from "react";
import { useCurrency } from "react-hook-currency";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { XMLParser } from 'fast-xml-parser'
import { format, set } from "date-fns";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import { MultiValidationServicesProps } from "../ValidadteServiceTable";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { bulkValidateBoards } from "api/service/bulkValidateBoards";
import { useToastify } from "hooks/toastify/useToastify";
import { captalize } from "utils/captalize";

interface IXmlProps {
  cteProc: {
    CTe: {
      infCte: {
        infCTeNorm: {
          infModal: {
            aereo: {
              nOCA: number
            }
            rodoviario: {
              nOCA: number
            }
          }
          infCarga: {
            infQ: {
              cUnid: number
              tpMed: string
              qCarga: number
            }[]
          }
        }
        ide: {
          nCT: number
          dhEmi: string
        }
        compl: {
          fluxo: {
            xOrig: string
            xDest: string
          }
        }
        vPrest: {
          vTPrest: number
        }
      }
    }
  }
}

interface MultiBoardValidationsSchema {
  xml: FileList
  boardVolume: number
  boardWeight: string
  cte: string
  cteTransferCost: string
  operationalNumber: string
  realWeight: string
  taxedWeight: string
  realBoardDate: string
  realBoardHour: string
  openTicketEmail: 'yes' | 'no'
  hasTrackerLink: 'yes' | 'no'
  trackerLink: string
  isLoglifePayer: 'yes' | 'no'
  isCteDestinationBranchEqualToPlanned: 'yes' | 'no'
  validateObservation: string
  ratings: {
    boardVolume: number
    shareExtraKg: 'yes' | 'no'
    protocol: number
    franchising: number
    taxedWeight: string
    extraKGPrice: number | null
    transferBudget: number
    cteTransferCost: number
  }[]
}

const multiBoardValidationsSchema = yup.object({
  xml: yup.mixed(),
  boardVolume: yup.number().typeError('Campo obrigatório!'),
  boardWeight: yup
    .string()
    .test({
      message: 'O valor precisa ser maior que zero!',
      test(value) {
        return transformStringToNumber(value) > 0
      }
    })
    .required('Campo obrigatório!'),
  cte: yup
    .string()
    .required('Campo Obrigatório')
    .matches(/^[A-Za-z0-9]+$/, 'Insira somente letras ou números'),
  cteTransferCost: yup.string().required('Campo obrigatório!'),
  operationalNumber: yup
    .string()
    .matches(/^[A-Za-z0-9]+$/, 'Insira somente letras ou números')
    .required('Campo Obrigatório'),
  realWeight: yup
    .string()
    .test({
      message: 'O valor precisa ser maior que zero!',
      test(value) {
        return transformStringToNumber(value) > 0
      }
    })
    .required('Campo obrigatório!'),
  taxedWeight: yup
    .string()
    .test({
      message: 'O valor precisa ser maior que zero!',
      test(value) {
        return transformStringToNumber(value) > 0
      }
    })
    .required('Campo obrigatório!'),
  realBoardDate: yup.string().required('Campo obrigatório!'),
  realBoardHour: yup.string().required('Campo obrigatório!'),
  openTicketEmail: yup.mixed().when('$isPriorityBudget', {
    is: true,
    then: yup.string().required('Campo obrigatório')
  }),
  hasTrackerLink: yup.string().required('Campo obrigatório'),
  trackerLink: yup.mixed().when('hasTrackerLink', {
    is: 'yes',
    then: yup.string().required('Campo obrigatório')
  }),
  isLoglifePayer: yup.string().required('Campo obrigatório'),
  isCteDestinationBranchEqualToPlanned: yup.string().required('Campo obrigatório'),
  validateObservation: yup.string(),
})

interface MultiBoardValidationsProps {
  multiValidationInfo: MultiValidationServicesProps
  onClose: () => void
  onResetMultiValidationServices: () => void
}

const switchTotalWeight = (name: string) => {
  switch (name) {
    case 'PESO BASE DE CÁLCULO':
      return true
    case 'PESO BASE DE CALCULO':
      return true
    case 'PESO TAXADO':
      return true
    case 'Peso taxado':
      return true
    case 'PESO_BASE_CALCULO':
      return true
    case 'PESO':
      return true
    default:
      return false
  }
}

const switchRealWeight = (name: string) => {
  switch (name) {
    case 'PESO AFERIDO(KG)':
      return true
    case 'PESO CUBADO':
      return true
    case 'Peso cubado':
      return true
    case 'M3':
      return true
    case 'PESO':
      return true
    default:
      return false
  }
}

const switchTaxedWeight = (name: string) => {
  switch (name) {
    case 'PESO BRUTO(KG)':
      return true
    case 'PESO REAL':
      return true
    case 'Peso real':
      return true
    case 'PESO':
      return true
    default:
      return false
  }
}

const switchVolumes = (name: string) => {
  switch (name) {
    case 'QTDE DE VOLUMES':
      return true
    case 'QTDE VOLUMES':
      return true
    case 'VOLUMES':
      return true
    case 'Volumes':
      return true
    case 'UNIDADE':
      return true
    default:
      return false
  }
}

export function MultiBoardValidations({
  multiValidationInfo,
  onClose,
  onResetMultiValidationServices,
}: MultiBoardValidationsProps) {
  const [isShippingWithTrackerLink, setIsShippingWithTrackerLink] = useState(false)
  const [parsedXml, setParsedXml] = useState<IXmlProps | null>(null)

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: {
      isSubmitting,
      errors
    }
  } = useForm<MultiBoardValidationsSchema>({
    defaultValues: {
      hasTrackerLink: 'no'
    },
    resolver: yupResolver(multiBoardValidationsSchema)
  })

  const hasTrackerLink = useWatch({
    control,
    name: 'hasTrackerLink'
  })

  const {
    format: currencyFormat,
    onChange: onCurrencyChange
  } = useCurrency({
    style: 'decimal'
  })

  const {
    data: result,
  } = useQuery({
    queryFn: () => getMultiBoardServices({
      queryParams: {
        servicesIds: multiValidationInfo.services.map(service => service.id)
      }
    }),
    refetchOnWindowFocus: false
  })

  const queryClient = useQueryClient()

  const { mutateAsync: bulkValidateBoardsFn } = useMutation({
    mutationFn: bulkValidateBoards,
    onSuccess: () => {
      onResetMultiValidationServices()
      onClose()
      queryClient.invalidateQueries({ queryKey: 'servicesBySteps' })
    }
  })

  const { promiseMessage } = useToastify()

  async function handleBulkValidateBoards(data: MultiBoardValidationsSchema) {
    const [year, month, day] = data.realBoardDate.split('-').map(Number)
    const [hours, minutes] = data.realBoardHour.split(':').map(Number)


    const realBoardTimestamp = set(new Date(), {
      date: day,
      month: month - 1,
      year,
      hours,
      minutes
    })

    await promiseMessage(bulkValidateBoardsFn({
      body: {
        boardWeight: transformStringToNumber(data.boardWeight),
        cte: data.cte,
        operationalNumber: data.operationalNumber,
        realBoardDate: realBoardTimestamp,
        realBoardHour: realBoardTimestamp,
        realWeight: transformStringToNumber(data.realWeight),
        taxedWeight: transformStringToNumber(data.taxedWeight),
        trackerLink: data.trackerLink ?? null,
        validateObservation: data.validateObservation ?? null,
        modal: multiValidationInfo.modal,
        ratings: data.ratings.map((rating) => {
          const board = result?.boards.find(board => board.protocol === rating.protocol)

          return {
            boardServiceId: board.board_service_id,
            boardVolume: rating.boardVolume,
            cteTransferCost: rating.cteTransferCost,
            taxedWeight: transformStringToNumber(rating.taxedWeight),
            transferBudget: rating.transferBudget,
            protocol: rating.protocol,
          }
        })
      }
    }), 'Embarques validados com sucesso!')
  }

  const {
    fields,
    update
  } = useFieldArray({
    control,
    name: 'ratings'
  })

  useEffect(() => {
    if (result) {
      result.boards.forEach((board, index) => {
        update(index, {
          boardVolume: board.board_volume,
          franchising: board.franchising,
          protocol: board.protocol,
          extraKGPrice: board.price_kg_extra,
          shareExtraKg: board.does_share_extra_kg ? 'yes' : 'no',
          transferBudget: board.transfer_budget,
        })
      })
    }
  }, [result, update])

  const ratings = useWatch({
    control,
    name: 'ratings'
  })

  const isAllOperationalNumbersEqual = result?.boards?.every(board => board.operational_number === multiValidationInfo.operationalNumber)

  useEffect(() => {
    if (isAllOperationalNumbersEqual) {
      setValue('operationalNumber', multiValidationInfo.operationalNumber)
    }
  }, [isAllOperationalNumbersEqual, setValue, multiValidationInfo.operationalNumber])

  const totalCteTransferCost = useWatch({
    control,
    name: 'cteTransferCost'
  })

  const xml = useWatch({ control, name: 'xml' })

  const previewXmlUrl = xml ? URL.createObjectURL(xml[0]) : null

  const xmlName = xml ? xml[0].name : ''

  const { data: xmlData } = useQuery({
    queryKey: ['xml', xmlName],
    queryFn: async () => {
      const response = await axios.get(previewXmlUrl, {
        headers: {
          'Content-Type': 'application/xml; charset=utf-8',
        },
      })
      return response.data
    },
    refetchOnWindowFocus: false,
    enabled: !!previewXmlUrl
  })

  useEffect(() => {
    if (xmlData) {
      const parser = new XMLParser()
      const jsonXml: IXmlProps = parser.parse(xmlData)

      setParsedXml(jsonXml)

      const infoCargo = jsonXml.cteProc.CTe.infCte.infCTeNorm.infCarga.infQ
      const infoOperationalNumberAir =
        jsonXml.cteProc.CTe.infCte.infCTeNorm.infModal.aereo?.nOCA
      const infoOperationalNumberHighway =
        jsonXml.cteProc.CTe.infCte.infCTeNorm.infModal.rodoviario?.nOCA
      const infoCteNumber = jsonXml.cteProc.CTe.infCte.ide.nCT
      const infoTotalValue = jsonXml.cteProc.CTe.infCte.vPrest.vTPrest
      const realBoardDate = jsonXml.cteProc.CTe.infCte.ide.dhEmi

      setValue('realBoardDate', format(new Date(realBoardDate), 'yyyy-MM-dd'))
      setValue('realBoardHour', format(new Date(realBoardDate), 'HH:mm'))
      setValue('cte', infoCteNumber.toString())
      setValue('cteTransferCost', infoTotalValue.toString())

      let taxedWeight = 0;
      let realWeight = 0;
      let boardWeight = 0;
      let boardVolume = 0;

      infoCargo.forEach((cargo) => {

        if (switchTotalWeight(cargo.tpMed)) {
          boardWeight = cargo.qCarga;
        }
        if (switchRealWeight(cargo.tpMed)) {
          realWeight = cargo.qCarga;
        }
        if (switchTaxedWeight(cargo.tpMed)) {
          taxedWeight = cargo.qCarga;
        }
        if (switchVolumes(cargo.tpMed)) {
          boardVolume = cargo.qCarga;
        }
      })

      if (realWeight === 0) {
        realWeight = taxedWeight;
      }

      let totalWeight = Math.max(taxedWeight, realWeight, boardWeight);

      if (multiValidationInfo.modal === 'AÉREO' && infoOperationalNumberAir) {
        setValue('operationalNumber', String(infoOperationalNumberAir))
      }

      if (multiValidationInfo.modal === 'RODOVIÁRIO' && infoOperationalNumberHighway) {
        setValue('operationalNumber', String(infoOperationalNumberHighway))
      }

      setValue('taxedWeight', totalWeight.toString())
      setValue('realWeight', realWeight.toString())
      setValue('boardWeight', totalWeight.toString())
      setValue('boardVolume', boardVolume)
    }
  }, [xmlData, setValue, multiValidationInfo])

  const totalFranchising = ratings?.reduce((acc, curr) => acc + curr.franchising, 0)

  const ratingsWithShareExtraKg = ratings?.filter(
    (rating) => rating.shareExtraKg === 'yes'
  )

  const ratingsWithShareExtraKgTotalFranchising = ratingsWithShareExtraKg?.reduce(
    (acc, curr) => acc + curr.franchising, 0
  )


  const ratingsWithoutShareExtraKgTotalFranchising = ratings
    ?.filter(
      (rating) => rating.shareExtraKg === 'no'
    )
    ?.reduce((acc, curr) => acc + curr.franchising, 0)

  const operationalNumber = useWatch({
    control,
    name: 'operationalNumber'
  })

  useEffect(() => {
    if ((
      multiValidationInfo.shipping.includes('LATAM') ||
      multiValidationInfo.shipping.includes('AZUL') ||
      multiValidationInfo.shipping.includes('GOLLOG')
    ) && operationalNumber
    ) {
      setValue('hasTrackerLink', 'yes')
      setIsShippingWithTrackerLink(true)

      let trackerLink = null

      if (multiValidationInfo.shipping.includes('GOLLOG')) {
        trackerLink = `https://transportebiologico.com.br/rastreios/gollog/${operationalNumber}`
      }

      if (multiValidationInfo.shipping.includes('LATAM')) {
        trackerLink = `https://www.latamcargo.com/en/trackshipment?docNumber=${operationalNumber.substring(3)}&docPrefix=957&soType=SO`
      }

      if (multiValidationInfo.shipping.includes('AZUL') || multiValidationInfo.shipping.includes('DEV')) {
        trackerLink = `https://azulcargoexpress.smartkargo.com/FrmAWBTracking.aspx?AWBPrefix=577&AWBno=${operationalNumber.substring(3)}`
      }

      if (trackerLink) setValue('trackerLink', trackerLink)
    }
  }, [multiValidationInfo, setValue, operationalNumber])

  const totalTaxedWeight = useWatch({
    control,
    name: 'taxedWeight'
  })

  const totalBoardVolume = useWatch({
    control,
    name: 'boardVolume'
  })

  useEffect(() => {
    fields.forEach((field, index) => {

      let taxedWeight = '0'

      if (totalTaxedWeight) {
        const totalTaxedWeightAsNumber = transformStringToNumber(totalTaxedWeight)

        if (totalTaxedWeightAsNumber <= totalFranchising) {

          taxedWeight = ((field.franchising / totalFranchising) * totalTaxedWeightAsNumber).toString()
        } else {

          if (ratings[index]?.shareExtraKg === 'yes') {

            taxedWeight = ((field.franchising / ratingsWithShareExtraKgTotalFranchising) * (totalTaxedWeightAsNumber - ratingsWithoutShareExtraKgTotalFranchising)).toString()
          } else {
            taxedWeight = (field.franchising).toString()
          }
        }

        if (ratings && ratings[index].taxedWeight !== taxedWeight) {
          setValue(`ratings.${index}.taxedWeight`, taxedWeight)
        }
      }
    })

  }, [fields, setValue, ratings, totalTaxedWeight, totalFranchising, ratingsWithoutShareExtraKgTotalFranchising, ratingsWithShareExtraKgTotalFranchising])

  useEffect(() => {
    fields.forEach((field, index) => {

      let cteTransferCost = 0

      if (totalCteTransferCost && totalTaxedWeight) {
        const totalTaxedWeightAsNumber = transformStringToNumber(totalTaxedWeight)
        const totalCteTransferCostAsNumber = transformStringToNumber(totalCteTransferCost)

        if (totalTaxedWeightAsNumber <= totalFranchising) {

          cteTransferCost = ((field.franchising / totalFranchising) * totalCteTransferCostAsNumber)
        } else {
          const cteTransferCostsWithoutShareExtraKg = ratings?.filter(
            (rating) => rating.shareExtraKg === 'no'
          )?.reduce((acc, curr) => acc + curr.cteTransferCost, 0)


          if (ratings[index]?.shareExtraKg === 'yes') {

            cteTransferCost = (field.franchising / ratingsWithShareExtraKgTotalFranchising) * (totalCteTransferCostAsNumber - cteTransferCostsWithoutShareExtraKg)
          } else {
            cteTransferCost = (field.franchising / totalTaxedWeightAsNumber) * totalCteTransferCostAsNumber
          }
        }

        if (ratings && ratings[index].cteTransferCost !== cteTransferCost) {
          setValue(`ratings.${index}.cteTransferCost`, cteTransferCost)
        }
      }
    })

  }, [fields, setValue, ratings, totalCteTransferCost, totalTaxedWeight, totalFranchising, ratingsWithoutShareExtraKgTotalFranchising, ratingsWithShareExtraKgTotalFranchising])

  const isTotalFranchisingLessThanTotalTaxedWeightWithoutKgShare = totalTaxedWeight && ratingsWithShareExtraKg
    ? totalFranchising < transformStringToNumber(totalTaxedWeight) && ratingsWithShareExtraKg?.length === 0
    : false

  const ratingsTotalVolumes = ratings?.reduce((acc, curr) => acc + Number(curr.boardVolume), 0)

  const isRatingsTotalVolumesDifferentThanTotalBoardVolumes = totalBoardVolume && ratingsTotalVolumes
    ? Number(ratingsTotalVolumes) !== Number(totalBoardVolume)
    : false

  return (
    <ModalContent>
      <ModalHeader>
        Validação múltipla de embarques
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody
        display="flex"
        flexDirection="column"
        gap="3"
        as="form"
        onSubmit={handleSubmit(handleBulkValidateBoards)}
      >
        <FormControl>
          <Button
            as={FormLabel}
            htmlFor="xml"
            cursor="pointer"
          >
            Xml
          </Button>

          <Input
            {...register('xml')}
            id="xml"
            name="xml"
            hidden
            type="file"
          />
        </FormControl>

        {parsedXml && (

          <Stack
            w="full"
            spacing="3"
            direction={{ base: 'column', lg: 'row' }}
          >
            <FormControl>
              <FormLabel fontSize="sm">
                Base origem (XML)
                <Text textColor="red.400" as="sup">*</Text>
              </FormLabel>
              <Input
                name="sourceXmlBranch"
                defaultValue={parsedXml.cteProc.CTe.infCte.compl.fluxo?.xOrig}
                disabled
              />
            </FormControl>

            <FormControl>
              <FormLabel fontSize="sm">
                Base destino (XML)
                <Text textColor="red.400" as="sup">*</Text>
              </FormLabel>
              <Input
                name="destinationXmlBranch"
                defaultValue={parsedXml.cteProc.CTe.infCte.compl.fluxo?.xDest}
                disabled
              />
            </FormControl>
          </Stack>
        )}

        <Stack
          w="full"
          spacing="3"
          direction={{ base: 'column', lg: 'row' }}
        >
          <FormControl>
            <FormLabel fontSize="sm">
              Base origem
              <Text textColor="red.400" as="sup">*</Text>
            </FormLabel>
            <Input
              name="sourceBranch"
              defaultValue={captalize(multiValidationInfo.sourceBranch)}
              disabled
            />
          </FormControl>

          <FormControl>
            <FormLabel fontSize="sm">
              Base destino
              <Text textColor="red.400" as="sup">*</Text>
            </FormLabel>
            <Input
              name="destinationBranch"
              defaultValue={captalize(multiValidationInfo.destinationBranch)}
              disabled
            />
          </FormControl>
        </Stack>

        <Stack
          spacing="3"
          direction={{ base: 'column', lg: 'row' }}
        >
          <Stack
            w="full"
            spacing="3"
            direction={{ base: 'column', lg: 'row' }}
          >
            <FormControl isInvalid={!!errors.boardVolume}>
              <FormLabel fontSize="sm">
                Volume total embarcado
                <Text textColor="red.400" as="sup">*</Text>
              </FormLabel>
              <InputGroup>
                <InputLeftAddon>UN</InputLeftAddon>
                <Input
                  {...register('boardVolume')}
                  type="number"
                />
              </InputGroup>
            </FormControl>
          </Stack>

          <Stack
            w="full"
            spacing="3"
            direction={{ base: 'column', lg: 'row' }}
          >
            <FormControl>
              <FormLabel fontSize="sm">
                Peso total do CTE (sem rateio)
                <Text textColor="red.400" as="sup">*</Text>
              </FormLabel>
              <InputGroup>
                <InputLeftAddon>KG</InputLeftAddon>
                <Controller
                  name='boardWeight'
                  control={control}
                  render={({ field }) => (
                    <Input
                      value={field.value}
                      defaultValue={currencyFormat('000')}
                      onChange={field.onChange}
                      onChangeCapture={onCurrencyChange}
                    />
                  )}
                />
              </InputGroup>

            </FormControl>

            <FormControl>
              <FormLabel fontSize="sm">
                CTE
                <Text textColor="red.400" as="sup">*</Text>
              </FormLabel>
              <Input
                {...register('cte')}
              />
            </FormControl>
          </Stack>
        </Stack>

        <Stack
          spacing="3"
          direction={{ base: 'column', lg: 'row' }}
        >
          <Stack
            w="full"
            spacing="3"
            direction={{ base: 'column', lg: 'row' }}
          >
            <FormControl>
              <FormLabel fontSize="sm">
                Valor CTE transferência
                <Text textColor="red.400" as="sup">*</Text>
              </FormLabel>
              <InputGroup>
                <InputLeftAddon>R$</InputLeftAddon>
                <Controller
                  name='cteTransferCost'
                  control={control}
                  render={({ field }) => (
                    <Input
                      value={field.value}
                      defaultValue={currencyFormat('000')}
                      onChange={field.onChange}
                      onChangeCapture={onCurrencyChange}
                    />
                  )}
                />
              </InputGroup>
            </FormControl>
          </Stack>

          <Stack
            w="full"
            spacing="3"
            direction={{ base: 'column', lg: 'row' }}
          >
            <FormControl>
              <FormLabel fontSize="sm">
                Rastreador
                <Text textColor="red.400" as="sup">*</Text>
              </FormLabel>
              <Input
                {...register('operationalNumber')}
                type="number"
              />
            </FormControl>
          </Stack>
        </Stack>

        <Stack
          spacing="3"
          direction={{ base: 'column', lg: 'row' }}
        >
          <Stack
            w="full"
            spacing="3"
            direction={{ base: 'column', lg: 'row' }}
          >
            <FormControl>
              <FormLabel fontSize="sm">
                Peso bruto para cobrança (rateado)
                <Text textColor="red.400" as="sup">*</Text>
              </FormLabel>
              <InputGroup>
                <InputLeftAddon>KG</InputLeftAddon>
                <Controller
                  name='realWeight'
                  control={control}
                  render={({ field }) => (
                    <Input
                      value={field.value}
                      defaultValue={currencyFormat('000')}
                      onChange={field.onChange}
                      onChangeCapture={onCurrencyChange}
                    />
                  )}
                />
              </InputGroup>
            </FormControl>
          </Stack>

          <Stack
            w="full"
            spacing="3"
            direction={{ base: 'column', lg: 'row' }}
          >
            <FormControl>
              <FormLabel fontSize="sm">
                Peso taxado para cobrança (rateado)
                <Text textColor="red.400" as="sup">*</Text>
              </FormLabel>
              <InputGroup>
                <InputLeftAddon>KG</InputLeftAddon>
                <Controller
                  name='taxedWeight'
                  control={control}
                  render={({ field }) => (
                    <Input
                      value={field.value}
                      defaultValue={currencyFormat('000')}
                      onChange={field.onChange}
                      onChangeCapture={onCurrencyChange}
                    />
                  )}
                />
              </InputGroup>
            </FormControl>
          </Stack>
        </Stack>

        <Stack
          spacing="3"
          direction={{ base: 'column', lg: 'row' }}
        >
          <Stack
            w="full"
            spacing="3"
            direction={{ base: 'column', lg: 'row' }}
          >
            <FormControl>
              <FormLabel fontSize="sm">
                Data real embarque
                <Text textColor="red.400" as="sup">*</Text>
              </FormLabel>
              <Input
                {...register('realBoardDate')}
                type="date"
              />
            </FormControl>

            <FormControl>
              <FormLabel fontSize="sm">
                Horário real embarque
                <Text textColor="red.400" as="sup">*</Text>
              </FormLabel>
              <Input
                {...register('realBoardHour')}
                type="time"
              />
            </FormControl>

          </Stack>
        </Stack>

        <Stack
          spacing="3"
          direction={{ base: 'column', lg: 'row' }}
        >
          <Stack
            w="full"
            spacing="3"
            direction={{ base: 'column', lg: 'row' }}
          >
            <FormControl>
              <FormLabel fontSize="sm">
                Possui link de rastreio?
                <Text textColor="red.400" as="sup">*</Text>
              </FormLabel>
              <Controller
                name="hasTrackerLink"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    value={field.value}
                    onChange={field.onChange}
                  >
                    <Stack spacing="3">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>

            {hasTrackerLink === 'yes' && (
              <FormControl>
                <FormLabel fontSize="sm">
                  Link de rastreio
                  <Text textColor="red.400" as="sup">*</Text>
                </FormLabel>
                <Input
                  {...register('trackerLink')}
                  type="text"
                  isDisabled={isShippingWithTrackerLink}
                />
              </FormControl>
            )}
          </Stack>
        </Stack>

        <Stack
          spacing="3"
          direction={{ base: 'column', lg: 'row' }}
        >
          <Stack
            w="full"
            spacing="3"
            direction={{ base: 'column', lg: 'row' }}
          >
            <FormControl>
              <FormLabel fontSize="sm">
                Tomador do serviço no CTE é Loglife?
                <Text textColor="red.400" as="sup">*</Text>
              </FormLabel>
              <Select
                {...register('isLoglifePayer')}
                placeholder="Selecione uma opção..."
              >
                <option value="yes">Sim</option>
                <option value="no">Não</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel fontSize="sm">
                Base destino do CTE é igual ao planejado?
                <Text textColor="red.400" as="sup">*</Text>
              </FormLabel>
              <Select
                {...register('isCteDestinationBranchEqualToPlanned')}
                placeholder="Selecione uma opção..."
              >
                <option value="yes">Sim</option>
                <option value="no">Não</option>
              </Select>
            </FormControl>

          </Stack>
        </Stack>

        <Stack
          spacing="3"
          direction={{ base: 'column', lg: 'row' }}
        >
          <Stack
            w="full"
            spacing="3"
            direction={{ base: 'column', lg: 'row' }}
          >
            <FormControl>
              <FormLabel fontSize="sm">
                Observações de validação
                <Text textColor="red.400" as="sup">*</Text>
              </FormLabel>
              <Textarea
                {...register('validateObservation')}
              />
            </FormControl>
          </Stack>
        </Stack>

        <TableContainer>
          <Table
            size="sm"
          >
            <Thead>
              <Tr>
                <Th>Protocolo</Th>
                <Th>Cliente</Th>
                <Th>Valor CTE rateado</Th>
                <Th>Franquia</Th>
                <Th>Volume embarcado</Th>
                <Th>Compartilhar KG Extra</Th>
                <Th>Budget de transferência</Th>
                <Th>Peso taxado</Th>
              </Tr>
            </Thead>
            <Tbody>
              {fields.map((field, index) => {
                const rating = ratings?.find(rate => rate.protocol === field.protocol)

                let transferBudget = 0

                if (rating) {
                  transferBudget = rating?.transferBudget

                  if (rating.taxedWeight) {
                    if (
                      transformStringToNumber(rating.taxedWeight) >
                      rating.franchising
                    ) {
                      transferBudget = transferBudget +
                        (transformStringToNumber(rating.taxedWeight) - rating.franchising) *
                        (rating.extraKGPrice * 0.8275)
                    }
                  }
                }

                const currentBoard = result?.boards.find(
                  (board) => board.protocol === field.protocol
                )

                return (
                  <Tr
                    key={field.id}
                  >
                    <Td>{field.protocol}</Td>
                    <Td>{currentBoard?.customer}</Td>
                    <Td>
                      <InputGroup>
                        <InputLeftAddon>R$</InputLeftAddon>
                        <Input
                          {...register(`ratings.${index}.cteTransferCost`)}
                          disabled
                          w="fit-content"
                        />
                      </InputGroup>
                    </Td>
                    <Td>{field.franchising}</Td>
                    <Td>
                      <Input
                        {...register(`ratings.${index}.boardVolume`)}
                        type="number"
                        w="fit-content"
                      />
                    </Td>
                    <Td>
                      <Select
                        {...register(`ratings.${index}.shareExtraKg`)}
                        outlineColor={isTotalFranchisingLessThanTotalTaxedWeightWithoutKgShare ? "red.500" : ''}
                      >
                        <option value="yes">Sim</option>
                        <option value="no">Não</option>
                      </Select>
                    </Td>
                    <Td>{transferBudget.toFixed(2)}</Td>
                    <Td>
                      <Input
                        {...register(`ratings.${index}.taxedWeight`)}
                        isDisabled
                      />
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>

          </Table>
        </TableContainer>

        {isTotalFranchisingLessThanTotalTaxedWeightWithoutKgShare && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>A soma das franquias é menor que o peso taxado para cobrança!</AlertDescription>
          </Alert>
        )}

        {isRatingsTotalVolumesDifferentThanTotalBoardVolumes && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>A soma dos volumes rateados é diferente do total informado!</AlertDescription>
          </Alert>
        )}

        <Button
          isLoading={isSubmitting}
          type="submit"
          alignSelf="end"
          w="min"
          colorScheme="blue"
          isDisabled={isTotalFranchisingLessThanTotalTaxedWeightWithoutKgShare || isRatingsTotalVolumesDifferentThanTotalBoardVolumes}
        >
          Validar
        </Button>
      </ModalBody>
    </ModalContent>
  )
}
