import axios from "axios"
import { useMutation } from "react-query"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface CreateEmployeeCostInput {
  remuneration: string
  fapAliquot: number
  thirdyPartyEmployerInss: number
  satEmployerInss: number
  fgts: number
  vacation: number
  oneThirdOfVacation: number
  employerInssVacation: number
  vacationFgts: number
  thirteenthSalary: number
  fgtsThirteenthSalary: number
  employerInsuranceThirteenthSalary: number
  costForecastWithIndemnifiedPriorNotice: number
  fgtsPriorNotice: number
  fgtsFineUponDismissalWithoutJustCause: number
  transportationVoucher: string
  foodVoucher: string
  mealTicket: string
  uniform: string
  healthInsurance: string
  dentalPlan: string
  lifeInsurance: string
  monetaryAllowance: string
  motorbykeCarRental: string
  workMaterials: string
  fuel: string
  cellphone: string
  tracker: string
  homeWork: string
  backup: string
  fairTrader: string
  regionId: string
  vehicle: string
}

async function createEmployeeCostReqFunction(input: CreateEmployeeCostInput) {
  try {
    const { data } = await api.post(`regions/${input.regionId}/employee-costs`, input)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    }
    throw new RequestError('Erro inesperado!')
  }
}

async function deleteEmployeeCostReqFunction(employeeCostId: string) {
  try {
    await api.delete(`employee-costs/${employeeCostId}/`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    }
    throw new RequestError('Erro inesperado!')
  }
}

type EditEmployeeCostInput = CreateEmployeeCostInput & {
  employeeCostId: string
}

async function editEmployeeCostReqFunction(input: EditEmployeeCostInput) {
  try {
    const { data } = await api.put(`regions/${input.regionId}/employee-costs/${input.employeeCostId}`, input)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    }
    throw new RequestError('Erro inesperado!')
  }
}

interface GenerateEmployeeCostStatementInput {
  weekHoursAmount: number
  fuelPrice: string
  monthlyDaysUp6WorkHours: number
  monthlyKm: number
}

interface GenerateEmployeeCostStatementProps {
  employeeCostId: string
  input: GenerateEmployeeCostStatementInput
}

async function generateEmployeeCostStatementReqFunction(
  props: GenerateEmployeeCostStatementProps
) {
  try {
    const { data } = await api.post(`employee-costs/${props.employeeCostId}/monthly-cost/`, props.input, {
      responseType: 'arraybuffer'
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    }
    throw new RequestError('Erro inesperado!')
  }
}

export function useEmployeeCostsFunctions() {

  const createEmployeeCost = useMutation(createEmployeeCostReqFunction)
  const deleteEmployeeCost = useMutation(deleteEmployeeCostReqFunction)
  const editEmployeeCost = useMutation(editEmployeeCostReqFunction)
  const generateEmployeeCostStatement = useMutation(
    generateEmployeeCostStatementReqFunction
  )

  return {
    createEmployeeCost,
    deleteEmployeeCost,
    editEmployeeCost,
    generateEmployeeCostStatement
  }

}
