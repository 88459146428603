import { useHistory } from "react-router-dom";
import { FiFile } from "react-icons/fi";
import { useForm, useWatch, useFieldArray } from "react-hook-form";
import { Button, Divider, Flex, FormLabel, Icon, Stack, Table, TableContainer, Tbody, Th, Thead, Tr, Link as ChakraLink } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { AttachOccurrenceEvidencesFormInputs } from "../AttachOccurrenceEvidences";
import * as yup from "yup"
import { validateHasFile } from "utils/imageValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useOccurrenceFunctions } from "hooks/occurrences/useOccurrenceFunctions";
import { useQueryClient } from "react-query";
import { FaEye, FaTimes } from "react-icons/fa";
import { handleChangeUrl } from "utils/handleChangeUrl";

const supportedFileFormats = [
  "image/jpeg",
  "image/pjpeg",
  "image/jpg",
  "image/png",
  "text/csv",
  "text/plain",
  "application/pdf",
  "application/eml",
]


interface preventiveResponsibleAttachment {
  filename: string
  key: string
}
interface AttachOccurrenceEvidencesFormProps {
  indexOccurrence: number
  occurrence_id: string
  occurrenceAlignmentId: string
  preventiveResponsibleId: string
  preventiveResponsibleName: string
  preventiveResponsibleAttachment: preventiveResponsibleAttachment[] | null
}

const attachOccurrenceEvidencesSchema = yup.object().shape({
  alignments: yup.array().of(yup.object({
    attachments: yup.mixed()
      .test('minLength', 'Campo Obrigatório!', value => {
        return validateHasFile(value)
      })
  }))
})

export function AttachOccurrenceEvidencesForm({
  indexOccurrence,
  occurrence_id,
  occurrenceAlignmentId,
  preventiveResponsibleId,
  preventiveResponsibleName,
  preventiveResponsibleAttachment,
}: AttachOccurrenceEvidencesFormProps) {

  const { push: redirect } = useHistory()

  const {
    attachOccurrenceEvidences: {
      mutateAsync: attachOccurrenceEvidences
    }
  } = useOccurrenceFunctions()

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<AttachOccurrenceEvidencesFormInputs>({
    resolver: yupResolver(attachOccurrenceEvidencesSchema)
  })

  const aligmentAttachments = useWatch({
    control,
    name: `alignments.${indexOccurrence}.attachments`
  })

  const {
    fields: aligmentAttachmentFields,
    append: appendAligmentAttachmentField,
    remove: removeAligmentAttachmentField
  } = useFieldArray({
    control,
    name: `alignments.${indexOccurrence}.attachments`
  })

  function handleRemoveappendAligmentAttachmentFieldField(index: number) {
    removeAligmentAttachmentField(index)
  }

  function handleAppendappendAligmentAttachmentFieldField() {
    appendAligmentAttachmentField({ attachment: undefined })
  }


  const queryClient = useQueryClient()

  async function handleAttachOccurrenceEvidences({
    alignments
  }: AttachOccurrenceEvidencesFormInputs) {

    const formData = new FormData()
    if (alignments[indexOccurrence].attachments.length !== 0) {
      Object.entries(alignments[indexOccurrence].attachments).forEach(([key, file]) => {
        formData.append("attachments", file.attachment[0])
      })
    }
    await attachOccurrenceEvidences({
      occurrenceAlignmentId,
      occurrenceId: occurrence_id,
      input: formData
    }, {
      onSuccess: () => {
        redirect('/ocorrencias/pendentes/anexar-evidencias')
      }
    })
    await queryClient.invalidateQueries('userTasks')
  }

  return (
    <Flex
      direction='column'
      gap={4}
      as='form'
      onSubmit={handleSubmit(handleAttachOccurrenceEvidences)}
    >
      <Input
        {...register(`alignments.${indexOccurrence}.preventive_responsible`)}
        name={`alignments.${indexOccurrence}.preventive_responsible`}
        defaultValue={preventiveResponsibleName}
        label='Responsável pela preventiva'
        isDisabled
      />

      {aligmentAttachmentFields.map((field, index) => {
        return (
          <Stack key={field.id} w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Input
              {...register(`alignments.${indexOccurrence}.attachments.${index}.attachment`)}
              name={`alignments.${indexOccurrence}.attachments.${index}.attachment`}
              label="Anexo"
              type="file"
              accept={supportedFileFormats.join(", ")}
              error={errors.alignments ? errors?.alignments[indexOccurrence]?.attachments[index].attachment : undefined}
              hidden
            >
              <Stack
                w="full"
                spacing="24px"
                mt="4"
                justifyContent='space-between'
                direction={['column', 'column', 'row']}
              >
                <Button
                  as={FormLabel}
                  htmlFor={`alignments.${indexOccurrence}.attachments.${index}.attachment`}
                  _hover={{
                    cursor: 'pointer'
                  }}
                  mr="2"
                  leftIcon={<Icon as={FiFile} />}
                >
                  Upload
                </Button>
                {aligmentAttachments && aligmentAttachments[index]?.attachment?.length > 0 && (
                  <ChakraLink
                    href={URL.createObjectURL(aligmentAttachments[index].attachment[0])}
                  >
                    {aligmentAttachments[index].attachment[0].name} (Visualizar)
                  </ChakraLink>
                )}
                <Button
                  leftIcon={<Icon as={FaTimes} />}
                  variant="ghost"
                  onClick={() => handleRemoveappendAligmentAttachmentFieldField(index)}
                  h="48px"
                >
                  Remover
                </Button>
              </Stack>
            </Input>
          </Stack>
        )
      })}

      <Stack
        w="full"
        spacing="24px"
        mt="4"
        direction={['column', 'column', 'row']}
      >
        <Button
          _hover={{
            cursor: 'pointer'
          }}
          mr="2"
          leftIcon={<Icon as={FiFile} />}
          onClick={handleAppendappendAligmentAttachmentFieldField}
        >
          Adicionar anexo
        </Button>
      </Stack>
      {preventiveResponsibleAttachment?.length > 0 && (
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Anexo existente</Th>
              </Tr>
            </Thead>
            <Tbody>
              {preventiveResponsibleAttachment?.map((attachment) => {
                return (
                  <Tr key={attachment.key}>
                    <Th>{attachment?.filename}</Th>
                    <Th isNumeric>
                      <Button
                        as={ChakraLink}
                        href={handleChangeUrl(attachment?.key)}
                        variant='ghost'
                        isExternal
                      >
                        <Icon as={FaEye} />
                      </Button>

                    </Th>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      <Flex
        justify={['center', 'center', 'flex-end']}
        direction={['column', 'column', 'row']}
        gap={4}
      >

        <Button
          w={['full', 'full', 'min']}
          colorScheme='blue'
          isLoading={isSubmitting}
          type='submit'
        >
          Salvar
        </Button>
      </Flex>

      <Divider />
    </Flex>
  )
}
