import { TableContainer, Table, Thead, Tr, Th, Tbody } from "@chakra-ui/react";
import { GetDelayedDeliveriesResponse } from "api/service/getDelayedDeliveries";

import { DelayedDeliveryTableRow } from "./DelayedDeliveryTableRow";

interface DelayedDeliveriesTableProps {
  delayedDeliveriesResult: GetDelayedDeliveriesResponse
}

export function DelayedDeliveriesTable({ delayedDeliveriesResult }: DelayedDeliveriesTableProps) {
  return (
    <TableContainer
      rounded="md"
      mt="6"
    >
      <Table size="sm">
        <Thead>
          <Tr
            h="40px"
            bg="#38c3fa"
          >
            <Th></Th>
            <Th color="white">Protocolo</Th>
            <Th color="white">Cliente</Th>
            <Th color="white">Origem</Th>
            <Th color="white">Destino</Th>
            <Th color="white">Status</Th>
            <Th color="white" isNumeric>Atraso</Th>
          </Tr>
        </Thead>
        <Tbody>
          {delayedDeliveriesResult?.services?.map(service => {
            return (
              <DelayedDeliveryTableRow
                key={service.id}
                service={service}
              />
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
