import { Box, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import { MaterialRequest } from "hooks/materialRequests/dtos/MaterialRequest";

interface MaterialRequestInfoProps {
  materialRequest: MaterialRequest
}

export function MaterialRequestInfo({
  materialRequest
}: MaterialRequestInfoProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="4"
    >
      <Heading size="sm">Informações da RSM</Heading>

      <Table size="sm">
        <Tbody>
          <Tr>
            <Td>Solicitado por</Td>
            <Td textAlign="right">{`${materialRequest.requestedBy.firstname} ${materialRequest.requestedBy.lastname}`}</Td>
          </Tr>
          <Tr>
            <Td>Almoxarifado</Td>
            <Td textAlign="right">{materialRequest.warehouse.name}</Td>
          </Tr>
          <Tr>
            <Td>Data limite de entrega</Td>
            <Td textAlign="right">{format(parseISO(materialRequest.delivery_limit_date), 'dd/MM/yyyy')}</Td>
          </Tr>
          <Tr>
            <Td>Observações iniciais</Td>
            <Td textAlign="right" maxW="150px">{materialRequest.observations ?? '-'}</Td>
          </Tr>
          {materialRequest?.approved_at && (
            <>
              <Tr>
                <Td>Aprovado em</Td>
                <Td textAlign="right">{format(new Date(materialRequest.approved_at), "dd/MM/yyyy '-' HH:mm")}</Td>
              </Tr>
              <Tr>
                <Td>Obervações de aprovação</Td>
                <Td textAlign="right" maxW="150px">{materialRequest.approved_observations ?? '-'}</Td>
              </Tr>
            </>
          )}
          {materialRequest?.reproved_at && (
            <Tr>
              <Td>Reprovado em</Td>
              <Td textAlign="right">{format(new Date(materialRequest.reproved_at), "dd/MM/yyyy '-' HH:mm")}</Td>
            </Tr>
          )}
          {materialRequest?.delivered_at && (
            <>
              <Tr>
                <Td>Entregue em</Td>
                <Td textAlign="right">{format(new Date(materialRequest.delivered_at), "dd/MM/yyyy '-' HH:mm")}</Td>
              </Tr>
              <Tr>
                <Td>Observações de entrega</Td>
                <Td textAlign="right" maxW="150px">{materialRequest.delivered_observations ?? '-'}</Td>
              </Tr>
            </>
          )}
        </Tbody>
      </Table>

      <TableContainer>
        <Heading mb="2" size="sm" letterSpacing="tight">Produtos</Heading>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Produto</Th>
              <Th>Quantidade</Th>
              <Th>Quantidade aprovada</Th>
              <Th>Quantidade entregue</Th>
            </Tr>
          </Thead>
          <Tbody>
            {materialRequest?.products?.map((product) => {
              return (
                <Tr key={product.id}>
                  <Td>{product.product.material.material_name}</Td>
                  <Td>{product.quantity}</Td>
                  <Td>{product.approved_quantity ?? '-'}</Td>
                  <Td>{product.delivered_quantity ?? '-'}</Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>

    </Box>
    // <Box
    //   display="flex"
    //   flexDirection="column"
    //   gap="4"
    //   maxH="400px"
    //   overflow="auto"
    // >
    //
    //   <Input
    //     name="requestedBy"
    //     defaultValue={`${materialRequest.requestedBy.firstname} ${materialRequest.requestedBy.lastname}`}
    //     label="Solicitado por"
    //     size="sm"
    //     isDisabled
    //   />

    //   <Stack
    //     spacing="4"
    //     direction={["column", "column", "row"]}
    //   >
    //     <Input
    //       name="warehouse"
    //       defaultValue={`${materialRequest.warehouse.name}`}
    //       label="Almoxarifado"
    //       size="sm"
    //       isDisabled
    //     />
    //     <Input
    //       name="product"
    //       defaultValue={`${materialRequest.product.material.material_name}`}
    //       label="Produto"
    //       size="sm"
    //       isDisabled
    //     />
    //   </Stack>
    //   <Stack
    //     spacing="4"
    //     direction={["column", "column", "row"]}
    //   >
    //     <Input
    //       name="quantity"
    //       defaultValue={`${materialRequest.quantity}`}
    //       label="Quantidade solicitada"
    //       size="sm"
    //       isDisabled
    //     />
    //     <Input
    //       name="deliveryLimitDate"
    //       defaultValue={`${materialRequest.delivery_limit_date}`}
    //       type="date"
    //       size="sm"
    //       isDisabled
    //       label="Data limite de entrega"
    //     />
    //   </Stack>
    //   <TextArea
    //     name="observations"
    //     defaultValue={materialRequest.observations ?? '-'}
    //     label="Observações de solicitações"
    //     isDisabled
    //   />
    //   {materialRequest.approved_at && (
    //     <>
    //       <Stack
    //         spacing="4"
    //         direction={["column", "column", "row"]}
    //       >
    //         <Input
    //           name="approvedQuantity"
    //           defaultValue={`${materialRequest.approved_quantity}`}
    //           label="Quantidade aprovada"
    //           size="sm"
    //           isDisabled
    //         />
    //         <Input
    //           name="approvedAt"
    //           defaultValue={format(new Date(materialRequest.approved_at), 'yyyy-MM-dd')}
    //           type="date"
    //           label="Data da aprovação"
    //           size="sm"
    //           isDisabled
    //         />
    //       </Stack>
    //       <TextArea
    //         name="approvedObservations"
    //         defaultValue={materialRequest.approved_observations ?? '-'}
    //         label="Observações de aprovação"
    //         size="sm"
    //         isDisabled
    //       />
    //     </>
    //   )}
    //   {materialRequest.reproved_at && (
    //     <Input
    //       name="reprovedAt"
    //       defaultValue={format(new Date(materialRequest.reproved_at), 'yyyy-MM-dd')}
    //       type="date"
    //       label="Data da reprovação"
    //       size="sm"
    //       isDisabled
    //     />
    //   )}
    //   {materialRequest.delivered_at && (
    //     <>
    //       <Stack
    //         spacing="4"
    //         direction={["column", "column", "row"]}
    //       >
    //         <Input
    //           name="deliveredQuantity"
    //           defaultValue={`${materialRequest.delivered_quantity}`}
    //           label="Quantidade entregue"
    //           size="sm"
    //           isDisabled
    //         />
    //         <Input
    //           name="deliveredAt"
    //           defaultValue={format(new Date(materialRequest.delivered_at), 'yyyy-MM-dd')}
    //           type="date"
    //           label="Data da entrega"
    //           size="sm"
    //           isDisabled
    //         />
    //       </Stack>
    //       <TextArea
    //         name="deliveredObservations"
    //         defaultValue={materialRequest.delivered_observations ?? '-'}
    //         label="Observações de entrega"
    //         size="sm"
    //         isDisabled
    //       />
    //     </>
    //   )}
    // </Box>
  )
}
