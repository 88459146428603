import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface DeleteDispatchStockParams {
  dispatchStockId: string
}

export async function deleteDispatchStock({
  dispatchStockId,

}: DeleteDispatchStockParams) {
  try {
    await api.delete(`/dispatch-stocks/${dispatchStockId}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

