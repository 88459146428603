import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { useRequestServiceFunctions } from "hooks/services/request/useRequestServiceFunctions";
import { useSwal } from "hooks/swal/useSwal";

interface ValidateAddressesRequestedToCancelModalProps {
  isOpen: boolean;
  onClose: () => void;
  protocol: number;
  serviceId: string;
  addressId: string;
  addressInfo: string;
}

export function ValidateAddressesRequestedToCancelModal({
  isOpen,
  onClose,
  serviceId,
  protocol,
  addressId,
  addressInfo,
}: ValidateAddressesRequestedToCancelModalProps) {

  const {
    validateCancelAddressToCollectService: {
      mutateAsync: validateCancelAddressToCollectService,
    },
  } = useRequestServiceFunctions();
  const { confirmMessage, standardMessage } = useSwal();

  async function handleValidateCancelAddressToCollectService(
    action: "validar" | "invalidar"
  ) {
    const hasValidatedCancelAddress = await confirmMessage({
      title: `Deseja ${action} o cancelamento do endereço?`,
    });

    if (hasValidatedCancelAddress) {
      await validateCancelAddressToCollectService({
        service_id: serviceId,
        address_id: addressId,
        is_validated: action === "validar" ? true : false,
      });
      onClose();
    } else {
      standardMessage("Ação cancelada com êxito!");
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Validar Cancelamento de Endereço</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack mt="4" w="full" direction="column" spacing="24px">
              <Input
                name="protocol"
                label="Protocolo"
                defaultValue={protocol}
                isDisabled={true}
              />
              <Input
                name="address_id"
                label="Endereço"
                defaultValue={addressInfo}
                isDisabled={true}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() =>
                handleValidateCancelAddressToCollectService("validar")
              }
            >
              Validar
            </Button>
            <Button
              variant="ghost"
              onClick={() =>
                handleValidateCancelAddressToCollectService("invalidar")
              }
            >
              Invalidar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
