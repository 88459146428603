import { Box, Flex } from "@chakra-ui/react";
import { InternCltStatus as InternCltStatusTypes } from "api/internClts/_types/internClt";
import { internCltStatusMap } from "utils/internCltMappers";

interface InternCltStatusProps {
  status: InternCltStatusTypes;
}

export function InternCltStatus({ status }: InternCltStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >

      {['hiring', 'pontomais-registering', 'sending-bank-details', 'dismissal-docs', 'in-notice','dismissal-comunication','dismissal-payment','dismissal-financial'].includes(status) && (
          <Box
            w="2"
            h="2"
            rounded="full"
            bgColor="orange.300"
          />
        )}
      {status === 'active' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {(status === 'inactive' || status === 'dismissed') && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {internCltStatusMap[status]}
    </Flex>
  )
}
