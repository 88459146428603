import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CreateRncForm } from '../../components/Form/Rnc/CreateRncForm'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useAuth } from '../../hooks/auth/useAuth'
import { useRncFunctions } from '../../hooks/rnc/useRncFunctions'
import { useSwal } from '../../hooks/swal/useSwal'
import { transformStringToNumber } from '../../utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber'

interface IFormInputProps {
  direction: string
  date: string
  is_date_full: boolean
  intercurrence_description: string
  loss_value: number | null
  opened_by: string
  intercurrence_responsible: string
}

export function CreateRncPage() {
  const [isLoading, setIsLoading] = useState(false)

  const {
    postRnc: { mutateAsync: createRnc },
  } = useRncFunctions()
  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useAuth()

  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('add-rnc')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  const handleCreateRnc = async (values: IFormInputProps) => {
    const formData = new FormData()

    const newValues = {
      ...values,
      loss_value: values.loss_value
        ? transformStringToNumber(String(values.loss_value))
        : '',
    }

    Object.entries(newValues).forEach(([key, value]) => {
      formData.append(key, value as string | Blob)
    })

    const hasCreateRnc = await confirmMessage({ title: 'Deseja criar um RNC?' })

    if (hasCreateRnc) {
      setIsLoading(true)
      try {
        const createRncResponse = await createRnc(formData)
        if (createRncResponse) redirectTo('/rnc/pendentes-resposta')
      } catch {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return <CreateRncForm title="Criar Rnc" submit={handleCreateRnc} />
}
