import { Box, Card, CardBody, CardHeader, Flex, Grid, GridItem, Heading, Icon, IconButton, Table, Tbody, Td, Text, Tooltip, Tr } from "@chakra-ui/react"
import { getUnsuccessCollectsPendingValidation } from "api/service/getUnsuccessCollectsPendingValidation"
import { Empty } from "components/Empty"

import { format } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { FaArrowLeft, FaInfoCircle } from "react-icons/fa"
import { useQuery } from "react-query"
import { Link, useHistory } from "react-router-dom"
import { captalize } from "utils/captalize"
import { timezonesUsed } from "utils/DateFunctions/timezones"
import { InfoCardsLoading } from "../components/InfoCardsLoading"

export function UnsuccessCollectsPendingValidation() {
  const {
    data: result,
    isError,
    isLoading: isLoadingUnsuccessCollectsPendingValidationResult
  } = useQuery({
    queryKey: ['unsuccess-collects-pending-validation'],
    queryFn: getUnsuccessCollectsPendingValidation
  })

  const handleReloadPage = () => window.location.reload()

  const history = useHistory()

  const handleReturnToDashboard = () => history.goBack()

  if (isError) {
    return (
      <Empty.Root>
        <Empty.ActionButton onClick={handleReloadPage}>Recarregar a página</Empty.ActionButton>
      </Empty.Root>
    )
  }

  return (

    <Box
      p="6"
      rounded="md"
      bg="white"
      display="flex"
      flexDirection="column"
      gap="6"
    >
      <Flex
        align="center"
        gap="2"
      >
        <IconButton
          aria-label="Voltar ao dashboard"
          icon={<FaArrowLeft />}
          variant="ghost"
          size="sm"
          onClick={handleReturnToDashboard}
        />
        <Heading letterSpacing="tight">Coletas Sem Sucesso Pendente Validação</Heading>
      </Flex>

      <Grid
        gap="6"
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }}
      >
        {isLoadingUnsuccessCollectsPendingValidationResult && (
          <InfoCardsLoading />
        )}
        {result?.collects?.map((collect) => {
          return (
            <GridItem
              key={collect.id}
              h="full"
            >
              <Card
                rounded="xl"
                transition="all 0.2s"
                _hover={{
                  transform: 'translateY(-0.5rem)'
                }}
                h="full"
              >
                  <CardHeader
                    as={Link}
                    display="flex"
                    alignItems="center"
                    to={`/servico/detalhes/${collect.service_id}`}
                    h="40px"
                    bg="#38c3fa"
                    borderTopRadius="xl"
                  >
                    <Text fontWeight="bold" color="white">{collect.protocol}</Text>
                  </CardHeader>

                <CardBody>
                  <Table size="sm">
                    <Tbody>
                      <Tr>
                        <Td fontSize='md'>Cliente</Td>
                        <Td isNumeric fontSize='md'>{captalize(collect.customer)}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize='md'>Hub de Origem
                          {Number(collect.source_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
                            <Tooltip label={collect.source_tz}>
                              <Box as="span" ml="2">
                                <Icon as={FaInfoCircle} />
                              </Box>
                            </Tooltip>
                          )}
                        </Td>
                        <Td isNumeric fontSize='md'>{captalize(collect.source_hub)}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize='md'>Hub de Destino
                          {Number(collect.destination_hub.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
                            <Tooltip label={collect.destination_tz}>
                              <Box as="span" ml="2">
                                <Icon as={FaInfoCircle} />
                              </Box>
                            </Tooltip>
                          )}
                        </Td>
                        <Td isNumeric fontSize='md'>{captalize(collect.destination_hub)}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize='md'>Transportadora</Td>
                        <Td isNumeric fontSize='md'>{collect.shipping ? captalize(collect.shipping) : '-'}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize='md'>Endereço</Td>
                        <Td isNumeric fontSize='md'>{captalize(`${collect.street}, ${collect.number}, ${collect.neighborhood} - ${collect.cep}`)}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize='md'>Data da coleta</Td>
                        <Td isNumeric fontSize='md'>{collect.source_tz
                          ? formatInTimeZone(new Date(collect.collect_date), timezonesUsed[collect.source_tz].timezone, 'dd/MM/yyyy')
                          : format(new Date(collect.collect_date), 'dd/MM/yyyy')
                        }</Td>
                      </Tr>

                    </Tbody>
                  </Table>
                </CardBody>
              </Card>
            </GridItem>
          )
        })}
      </Grid>
    </Box>

  )
}
