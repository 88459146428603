import { Button, FormControl, FormLabel, Input, InputGroup, InputRightAddon, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Text, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { editSupportSubject } from "api/supports/editSupportSubject";
import { useToastify } from "hooks/toastify/useToastify";
import { getUsers } from "hooks/user/useUsers";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { SupportType, SUPPORT_TYPE_CATEGORIES } from "utils/supportTypes";
import * as yup from "yup";
import AsyncSelect from "react-select/async"
import { captalize } from "utils/captalize";
import { getSupportSubject } from "api/supports/getSupportSubject";
import { useEffect } from "react";

interface EditSupportSubjectSchema {
  name: string
  category: string
  supportType: SupportType
  deadlineHours: number
  responsibles: { value: string, label: string }[]
}

const editSupportSubjectSchema = yup.object({
  supportType: yup.string().required(),
  name: yup.string().required(),
  category: yup.mixed().when('supportType', {
    is: (supportType: SupportType) => Boolean(SUPPORT_TYPE_CATEGORIES[supportType]),
    then: yup.string().required()
  }),
  deadlineHours: yup.number().required(),
  responsibles: yup.array().min(1).required(),
})

interface EditSupportSubjectModalProps {
  supportSubjectId: string
  onCloseModal: () => void
}

export function EditSupportSubjectModal({
  onCloseModal,
  supportSubjectId
}: EditSupportSubjectModalProps) {

  const {
    data: supportSubjectResult,
    isLoading: isLoadingSupportSubjectResult
  } = useQuery({
    queryFn: () => getSupportSubject({ supportSubjectId }),
    queryKey: ['support-subject', supportSubjectId],
    refetchOnWindowFocus: false
  })

  const {
    control,
    register,
    setValue,
    formState: {
      errors,
      isSubmitting,
    },
    handleSubmit
  } = useForm<EditSupportSubjectSchema>({
    resolver: yupResolver(editSupportSubjectSchema)
  })

  useEffect(() => {
    if (supportSubjectResult) {
      setValue('category', supportSubjectResult.supportSubject.category)
      setValue('name', supportSubjectResult.supportSubject.name)
      setValue('supportType', supportSubjectResult.supportSubject.supportType)
      setValue('deadlineHours', supportSubjectResult.supportSubject.deadlineInMinutes / 60)
      setValue('responsibles', supportSubjectResult.supportSubject.responsibles.map(
        (responsible) => {
          return {
            value: responsible.user.id,
            label: captalize(`${responsible.user.firstname} ${responsible.user.lastname}`)
          }
        }
      ))
    }
  }, [supportSubjectResult, setValue])

  const [selectedSupportType] = useWatch({
    control,
    name: ['supportType']
  })

  const doesSelectedSupportTypeHasCategories = Boolean(SUPPORT_TYPE_CATEGORIES[selectedSupportType])

  const queryClient = useQueryClient()

  const { mutateAsync: editSupportSubjectFn } = useMutation({
    mutationFn: editSupportSubject,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['supports-subjects'] })
      queryClient.invalidateQueries({
        queryKey: ['supports-subject', supportSubjectId]
      })

      onCloseModal()
    }
  })

  const { promiseMessage } = useToastify()

  async function handleEditSupportSubjectModal(values: EditSupportSubjectSchema) {
    await promiseMessage(editSupportSubjectFn({
      supportSubjectId,
      body: {
        ...values,
        deadlineInMinutes: values.deadlineHours * 60,
        responsiblesIds: values.responsibles.map(responsible => responsible.value)
      }
    }), 'Assunto de suporte editado!')
  }

  async function usersPromiseOptions(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const users = await getUsers({ situation: 'ATIVO' })

    return users.filter(user => `${user.firstname} ${user.lastname}`.normalize('NFC').toLowerCase().includes(inputValue.normalize('NFC').toLowerCase())).map(user => {
      return { value: user.id, label: captalize(`${user.firstname} ${user.lastname}`) }
    })
  }

  return (

    <ModalContent>
      <ModalHeader>
        Criar assunto de atendimento
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleEditSupportSubjectModal)}
      >
        <VStack
          spacing={3}
        >
          <FormControl isInvalid={Boolean(errors.supportType)}>
            <FormLabel>
              Tipo de suporte
              <Text color="red.500" as="sup">*</Text>
            </FormLabel>
            <Select
              {...register('supportType')}
              name="supportType"
              placeholder="Selecione um tipo de suporte..."
            >
              {Object.keys(SupportType).filter((key) => isNaN(Number(key))).map((key) => {
                return <option value={key} key={key}>{key}</option>
              })}
            </Select>
          </FormControl>

          {doesSelectedSupportTypeHasCategories && (
            <FormControl isInvalid={Boolean(errors.category)}>
              <FormLabel>
                Categoria
                <Text color="red.500" as="sup">*</Text>
              </FormLabel>
              <Select
                {...register('category')}
                name="category"
                placeholder="Selecione uma categoria..."
              >
                {SUPPORT_TYPE_CATEGORIES[selectedSupportType].map((category) => {
                  return <option value={category} key={category}>{category}</option>
                })}
              </Select>
            </FormControl>
          )}

          <FormControl isInvalid={Boolean(errors.name)}>
            <FormLabel>
              Assunto
              <Text color="red.500" as="sup">*</Text>
            </FormLabel>
            <Input
              {...register('name')}
              name="name"
            />
          </FormControl>

          <FormControl isInvalid={Boolean(errors.deadlineHours)}>
            <FormLabel>
              Prazo (horas)
              <Text color="red.500" as="sup">*</Text>
            </FormLabel>
            <InputGroup>
              <Input
                {...register('deadlineHours')}
                name="deadlineHours"
                type="number"
                placeholder="Apenas numeros inteiros"

              />
              <InputRightAddon>Hrs</InputRightAddon>
            </InputGroup>
          </FormControl>

          <FormControl isInvalid={Boolean(errors.deadlineHours)}>
            <FormLabel>
              Responsáveis
              <Text color="red.500" as="sup">*</Text>
            </FormLabel>
            <Controller
              control={control}
              name="responsibles"
              render={({ field }) => (
                <AsyncSelect
                  {...register('responsibles')}
                  name={field.name}
                  onChange={field.onChange}
                  value={field.value}
                  cacheOptions
                  defaultOptions
                  isMulti
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: '1px',
                      borderRadius: '6px',
                      border: state.isFocused ? 'none' : '',
                      boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                    })
                  }}
                  noOptionsMessage={() => 'Não há colaboradores cadastrados ou encontrados!'}
                  placeholder="Selecione um colaborator..."
                  loadOptions={usersPromiseOptions}
                  isClearable={true}
                />
              )}
            />
          </FormControl>


          <Button
            type="submit"
            colorScheme="blue"
            alignSelf="end"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Salvar
          </Button>
        </VStack>

      </ModalBody>

    </ModalContent>
  )
}
