import request from "axios"
import { useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import { CltDriver, CltDriversReqProps } from "./dtos/CltDriver";
import { useAuth } from "hooks/auth/useAuth";
import { RequestError } from "utils/errors/RequestErrors";
import { useToastify } from "hooks/toastify/useToastify";

interface CltDriversQueryParams {
  search?: string
  pageSize?: number
  currentPage?: number
}

type QueryOptions = UseQueryOptions<CltDriversReqProps, unknown, CltDriversReqProps, ["cltDrivers"]>

interface UseCltDriversOptions {
  queryParams?: CltDriversQueryParams
  queryOptions?: QueryOptions
}

export async function getCltDrivers(queryParams?: CltDriversQueryParams) {
  try {
    const { data } = await api.get<{ cltDrivers: CltDriver[], totalPages: number }>(`/clt-drivers`, {
      params: {
        ...queryParams
      }
    })

    return data
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}

export function useCltDrivers(options?: UseCltDriversOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([
    "cltDrivers",
    options?.queryParams.currentPage,
    options?.queryParams.pageSize,
    options?.queryParams.search,
  ], () => getCltDrivers(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
