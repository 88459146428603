import { Box, Divider, Flex, Grid, GridItem, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { getCltDriver } from "api/cltDrivers/getCltDriver";
import { useAuth } from "hooks/auth/useAuth";
import { useQuery } from "react-query";
import { CltDriverActionPanel } from "./CltDriverActionPanel";
import { CltDriverDetailTable } from "./CltDriverDetailTable";
import { ContractsCltDriver } from "./ContractsCltDriver";

import { HireCltDriver } from "./HireCltDriver";
import { SendBankDetails } from "./SendBankDetails";
import { SendCltDriverApproveDismiss } from "./SendCltDriverApproveDismiss";
import { SendCltDriverAsoAndNoticeDetails } from "./SendCltDriverAsoAndNoticeDetails";
import { SendCltDriverDismissComunication } from "./SendCltDriverDismissComunication";
import { SendCltDriverDismissDocumentation } from "./SendCltDriverDismissDocumentation";
import { SendCltDriverDismissLegalPreventionDetails } from "./SendCltDriverDismissLegalPreventionDetails";
import { SendCltDriverDismissTerminationDetails } from "./SendCltDriverDismissTerminationDetails";
import { SendCltDriverDismissTRCTSignatureDetails } from "./SendCltDriverDismissTRCTSignatureDetails";
import { SendCltDriverInProgressNoticeDetails } from "./SendCltDriverInProgressNoticeDetails";
import { SendCltDriverMotorcycleDetails } from "./SendCltDriverMotorcycleDetails";
import { SendCltDriverScalabriniRegister } from "./SendCltDriverScalabriniRegister";
import { SendPontoMaisRegister } from "./SendPontoMaisRegister";
import { SendSigningDocument } from "./SendSigningDocument";

interface CltDriverDetailProps {
  cltDriverId: string
  onClose: () => void
}

export function CltDriverDetail({ cltDriverId, onClose }: CltDriverDetailProps) {
  const { userLogged } = useAuth()

  const {
    data: cltDriver,
    isLoading: isLoadingCltDriver
  } = useQuery({
    queryKey: ['clt-driver', cltDriverId],
    queryFn: () => getCltDriver({ routeParams: { cltDriverId } })
  })

  const doesHasHireCltDriverPermission = userLogged?.permissions.includes('hire-clt-driver')
  const doesHasDismissCltDriverPermission = userLogged?.permissions.includes('dismiss-clt-driver')

  const doesCltDriverInHireStep = cltDriver?.status?.includes('hiring')
  const doesCltDriverInDismissStep = cltDriver?.status?.includes('dismissing')

  return (
    <ModalContent>
      <ModalHeader>
        <Flex align="center" justify="space-between">
          <Text fontSize="lg" fontWeight="bold">
            Detalhes do Motorista CLT
          </Text>
          <Flex align="center" gap={4}>
            {!["hiring"].includes(cltDriver?.status) && (
              <ContractsCltDriver cltDriver={cltDriver} />
            )}
            <ModalCloseButton position="static" />
          </Flex>
        </Flex>


      </ModalHeader>
      <ModalBody>
        {isLoadingCltDriver ? (
          <Table size="sm">
            <Tbody>
              {Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr>
                    <Td>
                      <Skeleton w="full" h="16px" />
                    </Td>
                    <Td isNumeric>
                      <Skeleton w="full" h="16px" />
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        ) : (
          <Grid
            templateColumns={((doesCltDriverInHireStep && doesHasHireCltDriverPermission) || ((doesCltDriverInDismissStep || cltDriver?.status === 'active') && doesHasDismissCltDriverPermission))
              ? "1fr auto 1fr"
              : "1fr"
            }
            gap={6}
          >
            <GridItem>
              <CltDriverDetailTable
                cltDriver={cltDriver}
              />
            </GridItem>
            <Box>
              <Divider orientation="vertical" />
            </Box>

            <GridItem>
              {cltDriver?.status === 'hiring' && doesHasHireCltDriverPermission && (
                <HireCltDriver
                  cltDriver={cltDriver}
                />
              )}

              {cltDriver?.status === 'hiring-motorcyle-details' && doesHasHireCltDriverPermission && (
                <SendCltDriverMotorcycleDetails
                  cltDriverId={cltDriverId}
                />
              )}

              {cltDriver?.status === 'hiring-scalabrini-register' && doesHasHireCltDriverPermission && (
                <SendCltDriverScalabriniRegister
                  cltDriverId={cltDriverId}
                />
              )}

              {cltDriver?.status === 'hiring-pontomais-register' && doesHasHireCltDriverPermission && (
                <SendPontoMaisRegister
                  cltDriver={cltDriver}
                />
              )}

              {cltDriver?.status === 'hiring-signing-document' && doesHasHireCltDriverPermission && (
                <SendSigningDocument
                  cltDriver={cltDriver}
                />
              )}

              {cltDriver?.status === 'hiring-bank-details' && doesHasHireCltDriverPermission && (
                <SendBankDetails
                  cltDriverId={cltDriverId}
                />
              )}
              {cltDriver?.status === 'active' && (
                <CltDriverActionPanel
                  cltDriverId={cltDriverId}
                />
              )}
              {cltDriver?.status === 'dismissing-legal-prevention' && doesHasDismissCltDriverPermission && (
                <SendCltDriverDismissLegalPreventionDetails
                  cltDriver={cltDriver}
                />
              )}
              {cltDriver?.status === 'dismissing-approvation' && doesHasDismissCltDriverPermission && (
                <SendCltDriverApproveDismiss
                  cltDriver={cltDriver}
                />
              )}
              {cltDriver?.status === 'dismissing-comunication' && (
                <SendCltDriverDismissComunication
                  cltDriverId={cltDriverId}
                />
              )}
              {cltDriver?.status === 'dismissing-aso-and-notice' && doesHasDismissCltDriverPermission && (
                <SendCltDriverAsoAndNoticeDetails
                  cltDriver={cltDriver}
                />
              )}
              {cltDriver?.status === 'dismissing-fulfilling-notice' && doesHasDismissCltDriverPermission && (
                <SendCltDriverInProgressNoticeDetails
                  cltDriver={cltDriver}
                />
              )}
              {cltDriver?.status === 'dismissing-documentation' && doesHasDismissCltDriverPermission && (
                <SendCltDriverDismissDocumentation
                  cltDriver={cltDriver}
                />
              )}
              {cltDriver?.status === 'dismissing-trct-signature' && doesHasDismissCltDriverPermission && (
                <SendCltDriverDismissTRCTSignatureDetails
                  cltDriverId={cltDriverId}
                />
              )}
              {cltDriver?.status === 'dismissing-termination' && doesHasDismissCltDriverPermission && (
                <SendCltDriverDismissTerminationDetails
                  cltDriver={cltDriver}
                />
              )}
            </GridItem>
          </Grid>
        )}
      </ModalBody>
    </ModalContent>
  )
}
