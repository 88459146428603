import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  HStack,
  Spinner,
  Stack,
} from '@chakra-ui/react'
import { memo, useEffect, useState } from 'react'

import { RequestedServiceProps } from '../../../../contexts/ServiceContext'
import { BranchProps } from '../../../../contexts/BranchContext'

import { useShipping } from '../../../../hooks/shipping/useShipping'

import { TextArea } from '../../../Inputs/TextInput'
import { Input } from '../../../Inputs/Input'
import { BoardServiceProps } from '../../../../services/getFunctions/getBoardServiceFunctions'

interface BranchAccordionDeliveryProps {
  serviceProtocol: number
  sourceBranchID: string
  destinationBranchID: string
  boardServiceFilteredById: BoardServiceProps[]
  requestedServiceFilteredById: RequestedServiceProps[]
  branches?: BranchProps[]
}

function BoardValidateAccordion({
  serviceProtocol,
  boardServiceFilteredById,
  sourceBranchID,
  destinationBranchID,
  requestedServiceFilteredById,
  branches,
}: BranchAccordionDeliveryProps) {
  const [isLoading, setIsLoading] = useState(true)

  const [sourceBranchFiltered, setSourceBranchFiltered] = useState<
    BranchProps[]
  >([])
  const [destinationBranchFiltered, setDestinationBranchFiltered] = useState<
    BranchProps[]
  >([])
  const [shippingFiltered, setShippingFiltered] = useState('')

  const {
    shipping: { data: shipping, isLoading: isShippingLoading },
  } = useShipping(null, true, false)

  useEffect(() => {
    function run() {
      if (!isShippingLoading) {
        setIsLoading(false)
      }
    }
    run()
  }, [isShippingLoading])

  useEffect(() => {
    function run() {
      if (!!branches && !!shipping) {
        // const sourceBranchOfReqService = requestedServiceFilteredById.map(reqService => reqService.source_branch_id).toString()
        const sourceBranchFiltered = branches.filter(
          (branch) => branch.id === sourceBranchID,
        )
        setSourceBranchFiltered(sourceBranchFiltered)

        // const destinationBranchOfReqService = requestedServiceFilteredById.map(reqService => reqService.destination_branch_id).toString()
        const destinationBranchFiltered = branches.filter(
          (branch) => branch.id === destinationBranchID,
        )
        setDestinationBranchFiltered(destinationBranchFiltered)

        const shippingOfBranches = sourceBranchFiltered
          .map((branch) => branch.shipping_id)
          .toString()
        const shippingFiltered = shipping
          .filter((ship) => ship.id === shippingOfBranches)
          .map((ship) => ship.company_name)
          .toString()
        setShippingFiltered(shippingFiltered)
      }
    }
    run()
  }, [sourceBranchID, destinationBranchID, branches, shipping])

  if (isLoading) {
    ;<Spinner />
  }

  return (
    <>
      {requestedServiceFilteredById.map((service) => (
        <Accordion key={service.id} allowMultiple>
          {service.service_type === 'FRACIONADO' && (
            <AccordionItem>
              <h2>
                <AccordionButton p="4">
                  <Box flex="1" textAlign="left">
                    Informações do serviço
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Stack
                  spacing="24px"
                  mt="4"
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    name="protocol"
                    value={serviceProtocol ?? ''}
                    label="Protocolo"
                    isDisabled={true}
                  />
                </Stack>
                <Stack
                  spacing="24px"
                  mt="4"
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    name="shipping"
                    value={shippingFiltered}
                    label="Transportadora"
                    isDisabled={true}
                  />

                  <HStack w="100%" spacing="24px">
                    {sourceBranchFiltered.map((branch) => (
                      <Input
                        key={branch.id}
                        name="start_branch"
                        value={branch.nickname}
                        label="Base Origem"
                        isDisabled={true}
                      />
                    ))}
                    {destinationBranchFiltered.map((branch) => (
                      <Input
                        key={branch.id}
                        name="final_branch"
                        value={branch.nickname}
                        label="Base Destino"
                        isDisabled={true}
                      />
                    ))}
                  </HStack>
                </Stack>
                <Stack>
                  {boardServiceFilteredById.map((boardService) => (
                    <TextArea
                      key={boardService.id}
                      name="board_observation"
                      value={
                        boardService.board_observation !== null
                          ? boardService.board_observation
                          : ''
                      }
                      label="Observações do Embarque"
                      isDisabled={true}
                    />
                  ))}
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          )}
          <Divider my="6" borderColor="gray.700" />
        </Accordion>
      ))}
    </>
  )
}

export default memo(BoardValidateAccordion)
