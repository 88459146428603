import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { api } from 'services/api'
import { RequestError } from 'utils/errors/RequestErrors'
import {
  BusinessBudgetInputProps,
  createBusinessBudgetReqFunction,
  editBusinessBudgetReqFunction,
  EditBusinessBudgetReqProps,
  removeBusinessBudgetReqFunction,
} from '../../utils/RequestFunctions/BusinessBudget/requestBusinessBudgetFunctions'
import { useSwal } from '../swal/useSwal'

async function deleteBusinessBudgetReqFunction(businessBudgetId: string) {
  try {
    await api.delete(`/business-budget/${businessBudgetId}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

export const useBusinessBudgetFunctions = () => {
  const queryClient = useQueryClient()
  const { successMessage, errorMessage } = useSwal()

  const createBusinessBudget = useMutation(
    'createBusinessBudget',
    (input: BusinessBudgetInputProps) => createBusinessBudgetReqFunction(input),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['businessBudgets'])
        await queryClient.invalidateQueries(['businessBudget'])
        successMessage('Orçamento business criado com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  const editBusinessBudget = useMutation(
    ({ id, input }: EditBusinessBudgetReqProps) =>
      editBusinessBudgetReqFunction({ id, input }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['businessBudgets'])
        await queryClient.invalidateQueries(['businessBudget'])
        successMessage('Orçamento business editado com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  const removeBusinessBudget = useMutation(
    (id: string) => removeBusinessBudgetReqFunction(id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['businessBudgets'])
        successMessage('Orçamento business excluído com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  const deleteBusinessBudget = useMutation(
    "deleteBusinessBudget", deleteBusinessBudgetReqFunction,
  )

  return {
    createBusinessBudget,
    editBusinessBudget,
    removeBusinessBudget,
    deleteBusinessBudget
  }
}
