import { useQuery, UseQueryOptions } from "react-query";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { MaintenanceService } from "./dtos/MaintenanceService";
import { api } from "services/api";

interface MaintenanceServicesQueryParams {
  maintenance_service?: string
}

type QueryOptions = UseQueryOptions<MaintenanceService[], unknown, MaintenanceService[], "maintenanceServices">

interface UseMaintenanceServicesOptions {
  queryParams?: MaintenanceServicesQueryParams,
  queryOptions?: QueryOptions
}

async function getMaintenanceServicesReqFunction(
  queryParams?: MaintenanceServicesQueryParams
) {

  try {
    const { data } = await api.get(`/maintenance-service`, {
      params: queryParams && {
        ...queryParams,
      }
    })

    return data
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new Error("Erro inesperado!")
    }
  }
}

export function useMaintenanceServices(options?: UseMaintenanceServicesOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery("maintenanceServices",
    () => getMaintenanceServicesReqFunction(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => await onRequestError(error)
  })
}
