import { Box, Button, Flex, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { FaCalendarAlt, FaTimes } from "react-icons/fa";

import { AuditDetail } from "./AuditDetail";

import { useAuth } from "hooks/auth/useAuth";
import { Audit } from "api/audits/_types/Audit";
import { format } from "date-fns";
import { captalize } from "utils/captalize";
import { FaUser } from "react-icons/fa6";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { cancelAudit } from "api/audits/cancelAudit";



interface AuditsKanbanCardProps {
  audit: Audit
}

export function AuditsKanbanCard({ audit }: AuditsKanbanCardProps) {
  const { userLogged } = useAuth()

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const userCanCancelAudit = userLogged?.permissions?.includes('cancel-audit')

  const { mutateAsync: cancelAuditFn } = useMutation({
    mutationFn: cancelAudit,
    onSuccess: () => {
      queryClient.invalidateQueries(['audits'])
      queryClient.invalidateQueries({ queryKey: 'pending-audits' })
      queryClient.invalidateQueries({ queryKey: 'reproved-audits' })
      queryClient.invalidateQueries({ queryKey: ['audit', audit?.id] })

    }
  })

  async function handleCancelAudit() {
    await promiseMessage(
      cancelAuditFn({ auditId: audit?.id }),
      'Auditoria cancelada com sucesso!'
    )
  }

  const {
    isOpen: isAuditDetailModalOpen,
    onToggle: onToggleAuditDetailModal,
  } = useDisclosure()



  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleAuditDetailModal}
      cursor='pointer'
    >
      <HStack justify="space-between" w="full">
        <Text fontWeight="bold" mb={2}>
          {audit?.type}
        </Text>
      </HStack>
      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">
        <Box>
          <HStack spacing={2}>
            <Icon as={FaUser} />
            <Text fontSize="xs" fontWeight="bold">COLABORADOR</Text>
          </HStack>
          <Text ml={5}>{audit?.collaborator ? captalize(audit?.collaborator) : 'N/A'}</Text>
        </Box>

        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA PROGRAMADA</Text>
          </HStack>
          <Text ml={5}>{audit?.scheduled_date ? format(new Date(audit?.scheduled_date), 'dd/MM/yyyy') : '-'}</Text>
        </Box>

      </VStack>
      <Flex align="center" gap="2" alignItems="flex-end" justifyContent="flex-end">
        {userCanCancelAudit && audit?.status === 'pending' && (
          <Button
            size="xs"
            lineHeight="1"
            leftIcon={<FaTimes />}
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation()
              handleCancelAudit()
            }}

          >
            Cancelar
          </Button>
        )}
      </Flex>
      <Modal
        isOpen={isAuditDetailModalOpen}
        onClose={onToggleAuditDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <AuditDetail
          auditId={audit.id}
        />
      </Modal>
    </Box>
  )
}
