import { Attachment } from 'hooks/attachment/dtos/Attachment';
import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Invoice } from "./_types/Invoice";
import { CitiesProps } from 'services/getFunctions/city/getCity';
import { Regional } from 'api/regionals/_types/Regional';
import { User } from 'hooks/user/dtos/User';

export interface GetInvoiceResponse {
  invoice: Invoice & {
    serviceReportAttachment: Attachment | null
    approvedReportAttachment: Attachment | null
    receiptAttachment: Attachment | null
    approvedStepAttachments: {
      id: string
      type: "billet" | "receipt",
      createdAt: string
      updatedAt: string
      invoiceId: string
      attachmentId: string
      attachment: Attachment
    }[] | null
    city: CitiesProps
    regional: Regional
    responsible: User | null
  }
}

interface GetInvoiceParams {
  invoiceId: string
}

export async function getInvoice({
  invoiceId
}: GetInvoiceParams) {
  try {
    const response = await api.get<GetInvoiceResponse>(`/invoices/${invoiceId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
