import { Button, Flex, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Stack, Text, Textarea } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createAdvertence } from "api/advertences/createAdvertence";
import { AdvertenceType, RegionalType, UserType } from "api/advertences/types/Advertence";
import { set } from "date-fns";
import { useToastify } from "hooks/toastify/useToastify";
import { useUsers } from "hooks/user/useUsers";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { advertenceTypeMap, regionalTypeMap, userTypeMap } from "utils/advertenceMapppers";
import { captalize } from "utils/captalize";
import * as yup from "yup"

interface CreateAdvertenceProps {
  onCloseModal: () => void
}

interface CreateAdvertenceSchema {
  userType: UserType
  userId: string
  advertenceType: AdvertenceType
  regional: RegionalType
  description: string
  date: string
}

const createAdvertenceSchema = yup.object({
  userType: yup.string().required(),
  userId: yup.string().required(),
  advertenceType: yup.string().required(),
  regional: yup.string().required(),
  description: yup.string().required(),
  date: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  })
})

export function CreateAdvertence({ onCloseModal }: CreateAdvertenceProps) {

  const { data: users } = useUsers({
    queryParms: {
      situation: 'ATIVO'
    }
  })
  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const { mutateAsync: createAdvertenceFn } = useMutation({
    mutationFn: createAdvertence,
    onSuccess: () => {
      queryClient.invalidateQueries(['advertences'])
      queryClient.invalidateQueries({ queryKey: 'awaiting-application-advertences' })
      onCloseModal()
    }
  })

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CreateAdvertenceSchema>({
    resolver: yupResolver(createAdvertenceSchema)
  })

  async function handleCreateAdvertence(values: CreateAdvertenceSchema) {
    await promiseMessage(createAdvertenceFn({
      body: values
    }), 'Advertência criada!')
  }
  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Criar Advertência</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleCreateAdvertence)}
        maxH='600px'
        overflowY='scroll'
      >
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Tipo de usuário
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.userType}>
              <Select
                {...register('userType')}
                name="userType"
                placeholder="Selecione..."
                size="sm"
                rounded="md"
              >
                {Object.entries(userTypeMap).map(([key, value]) => {
                  return (
                    <option key={key} value={key}>{value}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Colaborador
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.userId}>
              <Select
                {...register('userId')}
                name="userId"
                placeholder="Selecione..."
                size="sm"
                rounded="md"
              >
                {users?.map((user) => {
                  return (
                    <option key={user.id} value={user.id}>{captalize(`${user.firstname} ${user.lastname}`)}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Motivo da advertência
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.advertenceType}>
              <Select
                {...register('advertenceType')}
                name="advertenceType"
                placeholder="Selecione..."
                size="sm"
                rounded="md"
              >
                {Object.entries(advertenceTypeMap).map(([key, value]) => {
                  return (
                    <option key={key} value={key}>{value}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Regional
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.regional}>
              <Select
                {...register('regional')}
                name="regional"
                placeholder="Selecione..."
                size="sm"
                rounded="md"
              >
                {Object.entries(regionalTypeMap).map(([key, value]) => {
                  return (
                    <option key={key} value={key}>{value}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors?.description}>
              <FormLabel fontSize="sm">
                Descreva o motivo da advertência
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Textarea
                {...register('description')}
                name="description"
                size="sm"
                rounded="md"
              />
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          direction="column"
          w="full"
          mt="3"
        >
          <FormControl isInvalid={!!errors?.date}>
            <FormLabel fontSize="sm">
              Data
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('date')}
              name="date"
              type="date"
              size="sm"
              rounded="md"
            />
          </FormControl>
        </Stack>
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
