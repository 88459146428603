import { Button, Flex, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Stack, Text, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { GetDispatchStocksResponse } from "api/dispachStocks/getDispatchStocks";
import { collectorConfirmDispatchStock } from "api/dispachStocks/collectorConfirmDispatchStock";
import { useToastify } from "hooks/toastify/useToastify";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { set } from "date-fns";

interface CollectorConfirmDispatchStockSchema {
  arrivalDate: string
}

const collectorConfirmDispatchStockSchema = yup.object({
  arrivalDate: yup.string().required(),
})

interface CollectorConfirmDispatchStockProps {
  dispatchStockId: string
}

export function CollectorConfirmDispatchStock({
  dispatchStockId
}: CollectorConfirmDispatchStockProps) {

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<CollectorConfirmDispatchStockSchema>({
    resolver: yupResolver(collectorConfirmDispatchStockSchema)
  })

  const queryClient = useQueryClient()

  const { mutateAsync: collectorConfirmDispatchStockFn } = useMutation({
    mutationFn: collectorConfirmDispatchStock,
    onSuccess: (_data, { routeParams }) => {
      const cached = queryClient.getQueriesData<GetDispatchStocksResponse>({
        queryKey: ['dispatch-stocks']
      })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) {
          return
        }

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          dispatchStocks: cachedValue.dispatchStocks.map((dispatchStock) => {
            if (dispatchStock.id === routeParams.dispatchStockId) {
              return { ...dispatchStock, step: 'dispatched' }
            }

            return dispatchStock
          })
        })
      })
    }
  })

  const { promiseMessage } = useToastify()

  async function handleCollectorConfirmDispatchStock(
    values: CollectorConfirmDispatchStockSchema
  ) {
    const [year, month, day] = values.arrivalDate.split('-').map(Number)

    const arrivalDate = set(new Date(), {
      date: day,
      month: month - 1,
      year,
      hours: 12,
      minutes: 0,
      seconds: 0
    })

    await promiseMessage(collectorConfirmDispatchStockFn({
      body: { arrivalDate },
      routeParams: {
        dispatchStockId
      }
    }), 'Despacho confirmado e finalizado! ✔️')

  }

  return (
    <ModalContent>
      <ModalHeader>Confirmar despacho (Coletador)</ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <Flex
          direction="column"
          gap="6"
          as="form"
          onSubmit={handleSubmit(handleCollectorConfirmDispatchStock)}
        >
          <Stack
            w="full"
            direction={{ base: 'column', md: 'row' }}
            spacing="3"
          >
            <VStack w="full">
              <FormControl isInvalid={!!errors.arrivalDate}>
                <FormLabel fontSize="sm">
                  Data do recebimento
                  <Text color="red.500" as="sup">*</Text>
                </FormLabel>

                <Input
                  {...register('arrivalDate')}
                  rounded="md"
                  type="date"
                  size="sm"
                />
              </FormControl>
            </VStack>
          </Stack>

          <Button
            colorScheme="blue"
            type="submit"
            size="sm"
            rounded="md"
            w="min"
            alignSelf="flex-end"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Confirmar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
