import { Button, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { GetOnboardingsResponse } from "api/onboardings/getOnboardings";
import { inactivateOnboarding } from "api/onboardings/inactivateOnboarding";
import { inviteToOnboarding } from "api/onboardings/inviteToOnboarding";
import { Onboarding } from "api/onboardings/onboarding";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaArrowRight, FaSearch, FaTimes } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { OnboardingDetail } from "./OnboardingDetail";
import { OnboardingStatus } from "./OnboardingStatus";

interface OnboardingTableRowProps {
  onboarding: Onboarding
}
export function OnboardingTableRow({ onboarding }: OnboardingTableRowProps) {
  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const userCanInviteToOnboarding = userLogged?.permissions.includes('invite-to-onboarding')
  const userHasInactivateOnboardingPermission = userLogged?.permissions.includes('inactivate-onboarding')

  const { mutateAsync: inactivateOnboardingFn } = useMutation({
    mutationFn: inactivateOnboarding,
    onSuccess(_data, { onboardingId }) {
      const cachedOnboardings = queryClient.getQueriesData<GetOnboardingsResponse>({
        queryKey: ['onboardings']
      })
      queryClient.invalidateQueries(['onboardings'])
      queryClient.invalidateQueries({ queryKey: 'inviting-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'training-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'testing-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'finished-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'inactive-onboardings' })
      queryClient.invalidateQueries({ queryKey: ['onboarding', onboardingId]})
      cachedOnboardings.forEach(([cachedKey, cachedData]) => {

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          onboardings: cachedData.onboardings.map((onboarding) => {
            if (onboarding.id === onboardingId) {
              return {
                ...onboarding,
                status: 'inactivate',
                inactivated_at: new Date().toISOString(),
              }
            }

            return onboarding
          })
        })
      })
    },
  })

  const { mutateAsync: inviteToOnboardingFn } = useMutation({
    mutationFn: inviteToOnboarding,
    onSuccess(_data, { onboardingId }) {
      const cachedOnboardings = queryClient.getQueriesData<GetOnboardingsResponse>({
        queryKey: ['onboardings']
      })
      queryClient.invalidateQueries(['onboardings'])
      queryClient.invalidateQueries({ queryKey: 'inviting-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'training-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'testing-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'finished-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'inactive-onboardings' })
      queryClient.invalidateQueries({ queryKey: ['onboarding', onboardingId]})
      cachedOnboardings.forEach(([cachedKey, cachedData]) => {

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          onboardings: cachedData.onboardings.map((onboarding) => {
            if (onboarding.id === onboardingId) {
              return {
                ...onboarding,
                status: 'inviting',
              }
            }

            return onboarding
          })
        })
      })
    },
  })


  const {
    isOpen: isDetailModalOpen,
    onOpen: onOpenDetailModal,
    onClose: onCloseDetailModal,
  } = useDisclosure()


  async function handleInactivateOnboarding() {
    await promiseMessage(inactivateOnboardingFn({
      onboardingId: onboarding.id
    }), 'Onboarding inativado!')
  }

  async function handleInviteOnboarding(onboardingId: string) {
    await promiseMessage(inviteToOnboardingFn({
      onboardingId: onboardingId
    }), 'Convite onboarding enviado!')
  }

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Onboarding"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onOpenDetailModal}
        />

        <Modal
          isOpen={isDetailModalOpen}
          onClose={onCloseDetailModal}
          isCentered
          size='6xl'
        >
          <ModalOverlay />

          <OnboardingDetail onboardingId={onboarding.id} />

        </Modal>
      </Td>
      <Td>{onboarding?.name}</Td>
      <Td>{onboarding?.training_type}</Td>
      <Td>{onboarding?.cpf}</Td>
      <Td>{onboarding?.email}</Td>
      <Td>
        <OnboardingStatus status={onboarding.status} />
      </Td>
      <Td>
        {onboarding?.status === 'inviting' && userCanInviteToOnboarding && !onboarding.training_type.includes('LLM') && (
           <Button
           lineHeight="1"
           leftIcon={<FaArrowRight />}
           size="xs"
           variant="outline"
           onClick={() => handleInviteOnboarding(onboarding?.id)}
         >
           Convidar
         </Button>
        )}

      </Td>

      <Td>
        {(onboarding.status !== 'inactive' && userHasInactivateOnboardingPermission) && (
          <Button
            leftIcon={<FaTimes />}
            lineHeight="1"
            size="xs"
            variant="ghost"
            onClick={handleInactivateOnboarding}
          >
            Inativar
          </Button>
        )}
      </Td>
    </Tr >
  )
}
