import { differenceInMinutes, format, set } from 'date-fns'

export function serviceSortByDate(dateA: string | Date, dateB: string | Date) {
  const dateAFormatedWithGmt = format(
    new Date(dateA),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )
  const dateBFormatedWithGmt = format(
    new Date(dateB),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )

  const difference = differenceInMinutes(
    set(new Date(dateAFormatedWithGmt), { hours: 12, minutes: 0, seconds: 0 }),
    set(new Date(dateBFormatedWithGmt), { hours: 12, minutes: 0, seconds: 0 }),
  )

  if (difference < 0) {
    return -1
  }

  if (difference > 0) {
    return 1
  }

  return 0
}
