import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { CltIntercurrence } from "./types/CltIntercurrence";

interface GetCltIntercurrenceResponse {
  cltIntercurrence: CltIntercurrence
}

interface GetCltIntercurrenceProps {
  routeParams: {
    cltIntercurrenceId: string
  }
}

export async function getCltIntercurrence({
  routeParams
}: GetCltIntercurrenceProps) {
  try {
    const response = await api.get<GetCltIntercurrenceResponse>(`/clt-intercurrences/${routeParams.cltIntercurrenceId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
