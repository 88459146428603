import { Flex, IconButton, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner } from "@chakra-ui/react";
import { getDispatchStock } from "api/dispachStocks/getDispatchStock";
import { useInput } from "hooks/input/useInput";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FaPlus } from "react-icons/fa";
import { deleteDispatchStockMaterial } from "api/dispachStocks/deleteDispatchStockMaterial";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect, useState } from "react";
import { SaveDispatchStockMaterialForm, SaveDispatchStockMaterialFormSchema } from "./SaveDispatchStockMaterialForm";
import { saveDispatchStockMaterial } from "api/dispachStocks/saveDispatchStockMaterial";

interface DispatchStockMaterialProps {
  dispatchStockId: string
}

export interface Material {
  id: string
  inputId: string
  quantity: number
}


export function DispatchStockMaterial({
  dispatchStockId,
}: DispatchStockMaterialProps) {
  const [materialFields, setMaterialFields] = useState<Material[]>([])

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const {
    inputs: { data: inputs, isLoading: isInputLoading },
  } = useInput(null, true, false)

  const { data: dispatchStockData, isFetching: isDispatchStockDataLoading } = useQuery({
    queryKey: ['dispatch-stock', dispatchStockId],
    queryFn: () => getDispatchStock({ dispatchStockId })
  })

  useEffect(() => {
    if (dispatchStockData && dispatchStockData?.dispatchStock.materials?.length) {
      dispatchStockData.dispatchStock.materials.forEach((material, index) => {
        setMaterialFields(prevFields => [...prevFields, {
          id: index.toString(),
          inputId: material.input_id,
          quantity: material.quantity
        }])
      })
    }
  }, [dispatchStockData])

  const { mutateAsync: saveDispatchStockMaterialFn } = useMutation({
    mutationFn: saveDispatchStockMaterial,
    onSuccess: () => {
      setMaterialFields([])
      queryClient.invalidateQueries({
        queryKey: ['dispatch-stock', dispatchStockId]
      })
    }
  })

  const { mutateAsync: deleteDispatchStockMaterialFn } = useMutation({
    mutationFn: deleteDispatchStockMaterial,
    onSuccess: () => {
      setMaterialFields([])
      queryClient.invalidateQueries({
        queryKey: ['dispatch-stock', dispatchStockId]
      })
    }
  })


  async function handleSaveDispatchStockMaterial(values: SaveDispatchStockMaterialFormSchema) {
    await promiseMessage(saveDispatchStockMaterialFn({
      body: {
        quantity: values.quantity
      },
      dispatchStockId: dispatchStockId,
      inputId: values.inputId
    }), 'Material salvo!')
  }

  async function handleRemoveDispatchStockMaterial({ inputId, id }: { inputId: string, id: string }) {

    const materialAlreadySavedOnDispatchStock = dispatchStockData?.dispatchStock.materials?.find(material => material.input_id === inputId)

    if (materialAlreadySavedOnDispatchStock) {
      await promiseMessage(deleteDispatchStockMaterialFn({
        dispatchStockId: dispatchStockId,
        materialId: materialAlreadySavedOnDispatchStock.id
      }), 'Material removido!')
    }

    setMaterialFields(prevFields => prevFields.filter((material) => material.id !== id))

  }

  const alreadyChoosedMaterials = materialFields.map((material) => material.inputId)

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Adicionar Materiais</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        maxH='600px'
        overflowY='scroll'
      >
        {(isInputLoading || isDispatchStockDataLoading) && (
          <Spinner />
        )}

        <Flex direction='column' alignItems='center' gap="4">
          {materialFields?.length > 0 && (
            materialFields.map((material, index) => {
              return (
                <SaveDispatchStockMaterialForm
                  alreadyChoosedMaterials={alreadyChoosedMaterials}
                  key={material.id}
                  defaultMaterialValue={material}
                  onSaveDispatchStockMaterial={handleSaveDispatchStockMaterial}
                  onRemoveDispatchStockMaterial={() => handleRemoveDispatchStockMaterial({
                    id: material.id,
                    inputId: material.inputId
                  })}
                  inputs={inputs}
                />
              )
            })
          )}
          <IconButton
            aria-label="Adicionar Material"
            alignSelf='end'
            type="button"
            onClick={() => setMaterialFields(prevFields => {
              const last = prevFields[prevFields.length - 1]

              return [...prevFields, {
                id: last ? (Number(last.id) + 1).toString() : '0',
                inputId: '',
                quantity: 0
              }]
            })}
            icon={<FaPlus />}
            colorScheme='blue'
            size='sm'
          />

        </Flex>

      </ModalBody>
    </ModalContent>
  )
}
