import { Box, Flex, Icon, ListIcon, Text, Link as ChakraLink } from "@chakra-ui/react"
import { ReactNode } from "react"
import { IconType } from "react-icons"
import { FaAngleDown } from "react-icons/fa6"
import { Link } from "react-router-dom"

interface SubmenuContentProps {
  type?: string
  href?: string
  icon?: IconType
  isOpen: boolean
  isExpanded: boolean
  name: string
  hasSubmenu: boolean
  children?: ReactNode
}


export function SubmenuContent({
  type,
  href,
  icon,
  isOpen,
  isExpanded,
  name,
  hasSubmenu,
  children,
}: SubmenuContentProps) {
  if (type === 'external') {
    return (
      <Box
        as={ChakraLink}
        href={href}
        w="full"
        isExternal
      >
        <Flex align="center">
          {icon && (
            <ListIcon
              color={isOpen ? 'blackAlpha.900' : 'blackAlpha.400'}
              fontSize="lg"
              as={icon}
              transition="all 0.2s"
              _groupHover={{
                color: 'blackAlpha.900'
              }}
            />
          )}
          {isExpanded && (
            <Text
              fontWeight={isOpen ? 'bold' : 'normal'}
              fontSize="0.765rem"
              letterSpacing="tighter"
              transition="all 0.2s"
              textAlign="left"
              _groupHover={{
                fontWeight: 'bold'
              }}
            >
              {name}
            </Text>
          )}
        </Flex>
        {hasSubmenu && isExpanded && (
          <Icon transition="all 0.3s" as={FaAngleDown} transform={isOpen ? 'rotate(180deg)' : 'rotate(0)'} />
        )}
        {children}
      </Box>
    )
  }

  if (type === 'internal') {
    return (
      <Box
        as={Link}
        to={href}
        w="full"
      >
        <Flex align="center">
          {icon && (
            <ListIcon
              color={isOpen ? 'blackAlpha.900' : 'blackAlpha.400'}
              fontSize="lg"
              as={icon}
              transition="all 0.2s"
              _groupHover={{
                color: 'blackAlpha.900'
              }}
            />
          )}
          {isExpanded && (
            <Text
              fontWeight={isOpen ? 'bold' : 'normal'}
              fontSize="0.765rem"
              letterSpacing="tighter"
              transition="all 0.2s"
              textAlign="left"
              _groupHover={{
                fontWeight: 'bold'
              }}
            >
              {name}
            </Text>
          )}
        </Flex>
        {hasSubmenu && isExpanded && (
          <Icon transition="all 0.3s" as={FaAngleDown} transform={isOpen ? 'rotate(180deg)' : 'rotate(0)'} />
        )}
        {children}
      </Box>
    )
  }

  return (
    <>
      <Flex align="center">
        {icon && (
          <ListIcon
            color={isOpen ? 'blackAlpha.900' : 'blackAlpha.400'}
            fontSize="lg"
            as={icon}
            transition="all 0.2s"
            _groupHover={{
              color: 'blackAlpha.900'
            }}
          />
        )}
        {isExpanded && (
          <Text
            fontWeight={isOpen ? 'bold' : 'normal'}
            fontSize="0.765rem"
            letterSpacing="tighter"
            transition="all 0.2s"
            textAlign="left"
            _groupHover={{
              fontWeight: 'bold'
            }}
          >
            {name}
          </Text>
        )}
      </Flex>
      {hasSubmenu && isExpanded && (
        <Icon transition="all 0.3s" as={FaAngleDown} transform={isOpen ? 'rotate(180deg)' : 'rotate(0)'} />
      )}
      {children}
    </>
  )
}
