import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface CreateDispatchStockBody {
  collectorId: string
  destinationCollectorId: string
  shippingId: string
  proposalId: string
  sender: string
  branchId: string
  boardDate: string
  arrivalForecast: string
  withdrawalResponsible: string
  vehicle: string
  invoiceTotalPriceInCents: number
}

interface CreateDispatchStockParams {
  body: CreateDispatchStockBody
}

export async function createDispatchStock({
  body
}: CreateDispatchStockParams) {
  try {
    await api.post('/dispatch-stocks', body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

