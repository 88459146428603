import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface EditPatrimonyTypeBody {
  type: string
}


interface EditPatrimonyTypeParams {
  body: EditPatrimonyTypeBody,
  patrimonyTypeId: string
}

export async function editPatrimonyType({
  body,
  patrimonyTypeId
}: EditPatrimonyTypeParams) {
  try {
    await api.put(`patrimony-types/${patrimonyTypeId}`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

