import { Button, Flex, FormLabel, Icon, Spinner, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { FaFile, FaList, FaSave } from "react-icons/fa";
import { useCurrency } from "react-hook-currency";
import { useFormContext, useWatch } from "react-hook-form";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { useCollectors } from "hooks/collector/useCollectors";
import { ThirdPartyRncType } from "hooks/rnc/dtos/ThirdPartyRnc";
import { TextArea } from "components/Inputs/TextInput";
import { useGeneralProviders } from "hooks/generalProvider/useGeneralProviders";


export interface CreateThirdPartyRncInputs {
  type: ThirdPartyRncType
  collector_id: string
  provider_id: string
  occurred_date: string
  loss_value: string
  description: string
  attachment: FileList
}

const thirdPartyRncTypeOptions = [
  { key: '0', value: 'COLETADOR', showOption: 'Coletador' },
  { key: '1', value: 'FORNECEDOR', showOption: 'Fornecedor' },
]

export function CreateThirdPartyRncForm() {

  const {
    format: currencyFormat,
    onChange: onCurrencyChange
  } = useCurrency({
    style: 'decimal'
  })

  const {
    control,
    register,
    formState: {
      errors,
    }
  } = useFormContext<CreateThirdPartyRncInputs>()

  const [rncType, attachment] = useWatch({
    control,
    name: ['type', 'attachment']
  })

  const attachmentPreview = attachment
    ? Object.entries(attachment).map(([key, value]) => value)[0]
    : null


  const {
    data: generalProvidersData,
    isFetching: isFetchingGeneralProvidersData
  } = useGeneralProviders({
    queryOptions: { enabled: rncType === 'FORNECEDOR' }
  })

  const generalProvidersSelectOptions = generalProvidersData?.generalProviders?.map(provider => {
    return {
      key: provider.id,
      value: provider.id,
      showOption: provider.trading_name
    }
  })

  const {
    data: collectors,
    isFetching: isFetchingCollectors
  } = useCollectors({
    queryOptions: { enabled: rncType === 'COLETADOR' }
  })

  const collectorsSelectOptions = collectors?.map(collector => {
    return {
      key: collector.id,
      value: collector.id,
      showOption: collector.trading_name
    }
  })

  return (
    <Flex
      direction='column'
      gap={4}
      w='full'
    >
      <Select
        {...register('type')}
        name='type'
        label='Tipo de rnc'
        placeholder='Selecione uma opção...'
        options={thirdPartyRncTypeOptions}
        error={errors.type}
        required
      />

      {rncType === 'FORNECEDOR' && (
        <>
          {isFetchingGeneralProvidersData
            ?
            (
              <Spinner />
            )
            : (
              <Select
                {...register(
                  'provider_id')}
                name='provider_id'
                label='Fornecedor'

                placeholder='Selecione uma opção...'
                options={generalProvidersSelectOptions}
                error={errors.provider_id}
                required
              />
            )}
        </>
      )}

      {rncType === 'COLETADOR' && (
        <>
          {isFetchingCollectors ? (
            <Spinner />
          ) : (
            <Select
              {...register('collector_id')}
              name='collector_id'
              label='Coletador'
              placeholder='Selecione uma opção...'
              options={collectorsSelectOptions}
              error={errors.collector_id}
              required
            />
          )}
        </>
      )}

      <Stack spacing={4} direction={['column', 'column', 'row']}>
        <Input
          {...register('occurred_date')}
          name='occurred_date'
          label='Data'
          type='date'
          error={errors.occurred_date}
          required
        />
        <Input
          {...register('loss_value')}
          name='loss_value'
          label='Prejuízo Gerado'
          onChange={onCurrencyChange}
          defaultValue={currencyFormat('000')}
          addChildren={'R$'}
          error={errors.loss_value}
          required
        />
      </Stack>

      <TextArea
        {...register('description')}
        name='description'
        label='descrição'
        error={errors.description}
        required
      />


      <Input
        {...register('attachment')}
        name='attachment'
        type='file'
        label='Anexo (opcional)'
        error={errors.attachment}
        hidden
      />

      <Button
        as={FormLabel}
        w='full'
        htmlFor='attachment'
        leftIcon={<Icon as={FaFile} />}
        cursor='pointer'
      >
        Escolha um anexo
      </Button>

      {attachmentPreview && (
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>
                  Arquivo
                </Th>
                <Th>
                  Tamanho (Mb)
                </Th>
                <Th>
                  Tipo
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{attachmentPreview.name}</Td>
                <Td>{(attachmentPreview.size / 1024 / 1024).toFixed(2) /** Mb */}</Td>
                <Td>{attachmentPreview.type}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      )}

      <Flex
        align='center'
        justify='flex-end'
        gap={4}
        direction={['column', 'column', 'row']}
      >
        <Button
          w={['full', 'full', 'min']}
          leftIcon={<Icon as={FaList} />}
        >
          Rncs aguardando resposta
        </Button>
        <Button
          w={['full', 'full', 'min']}
          colorScheme='blue'
          type='submit'
          leftIcon={<Icon as={FaSave} />}
        >
          Salvar
        </Button>
      </Flex>
    </Flex>

  )
}
