import { Flex, IconButton, Input } from "@chakra-ui/react";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface InterComunicationsFiltersSchema {
  sourceHub: string
  destinationHub: string
  date: string
  hour: string
  customer: string
  responsible: string
  serviceProtocol: string
  comunicationType: string
  comunicationProtocol: string
}

export function InternComunicationsFilters() {
  const searchParams = useSearchParams()

  const customer = searchParams.get('customer')
  const responsible = searchParams.get('responsible')
  const serviceProtocol = searchParams.get('serviceProtocol')
  const comunicationProtocol = searchParams.get('comunicationProtocol')
  const date = searchParams.get('date')
  const hour = searchParams.get('hour')
  const sourceHub = searchParams.get('sourceHub')
  const destinationHub = searchParams.get('destinationHub')
  const comunicationType = searchParams.get('comunicationType')

  const {
    reset,
    register,
    handleSubmit,
  } = useForm<InterComunicationsFiltersSchema>({
    defaultValues: {
      customer,
      responsible,
      serviceProtocol,
      date,
      hour,
      sourceHub,
      destinationHub,
      comunicationType,
      comunicationProtocol
    }
  })

  const history = useHistory()

  function handleFilterInternComunications(values: InterComunicationsFiltersSchema) {
    if (values.customer) {
      searchParams.set('customer', values.customer)
    } else {
      searchParams.delete('customer')
    }

    if (values.responsible) {
      searchParams.set('responsible', values.responsible)
    } else {
      searchParams.delete('responsible')
    }

    if (values.serviceProtocol) {
      searchParams.set('serviceProtocol', values.serviceProtocol)
    } else {
      searchParams.delete('serviceProtocol')
    }

    if (values.date) {
      searchParams.set('date', values.date)
    } else {
      searchParams.delete('date')
    }

    if (values.hour) {
      searchParams.set('hour', values.hour)
    } else {
      searchParams.delete('hour')
    }

    if (values.sourceHub) {
      searchParams.set('sourceHub', values.sourceHub)
    } else {
      searchParams.delete('sourceHub')
    }

    if (values.destinationHub) {
      searchParams.set('destinationHub', values.destinationHub)
    } else {
      searchParams.delete('destinationHub')
    }

    if (values.comunicationType) {
      searchParams.set('comunicationType', values.comunicationType)
    } else {
      searchParams.delete('comunicationType')
    }

    if (values.comunicationProtocol) {
      searchParams.set('comunicationProtocol', values.comunicationProtocol)
    } else {
      searchParams.delete('comunicationProtocol')
    }

    history.replace({ search: searchParams.toString() })
  }

  function handleClearFilters() {
    searchParams.delete('customer')
    searchParams.delete('responsible')
    searchParams.delete('serviceProtocol')
    searchParams.delete('date')
    searchParams.delete('hour')
    searchParams.delete('sourceHub')
    searchParams.delete('destinationHub')
    searchParams.delete('comunicationType')
    searchParams.delete('comunicationProtocol')

    reset()

    history.replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={{ base: 'column', lg: 'row' }}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterInternComunications)}
    >
      <Flex
        flexWrap="wrap"
        gap="2"
      >
        <Input
          {...register("comunicationProtocol")}
          placeholder="Protocolo da comunicação"
          size="sm"
          rounded="md"
          w={{ base: 'full', lg: 'fit-content' }}
        />

        <Input
          {...register("customer")}
          placeholder="Cliente"
          size="sm"
          rounded="md"
          w={{ base: 'full', lg: 'fit-content' }}
        />

        <Input
          {...register("responsible")}
          placeholder="Responsável pela comunicação"
          size="sm"
          rounded="md"
          w={{ base: 'full', lg: 'fit-content' }}
        />

        <Input
          {...register("serviceProtocol")}
          placeholder="Protocolo do serviço"
          size="sm"
          rounded="md"
          w={{ base: 'full', lg: 'fit-content' }}
        />

        <Input
          {...register("sourceHub")}
          placeholder="Nome do hub de origem"
          size="sm"
          rounded="md"
          w={{ base: 'full', lg: 'fit-content' }}
        />

        <Input
          {...register("destinationHub")}
          placeholder="Nome do hub de destino"
          size="sm"
          rounded="md"
          w={{ base: 'full', lg: 'fit-content' }}
        />

        <Input
          {...register("comunicationType")}
          placeholder="Tipo de comunicação"
          size="sm"
          rounded="md"
          w={{ base: 'full', lg: 'fit-content' }}
        />

        <Input
          {...register("date")}
          type="date"
          size="sm"
          rounded="md"
          w={{ base: 'full', lg: 'fit-content' }}
        />

        <Input
          {...register("hour")}
          type="time"
          size="sm"
          rounded="md"
          w={{ base: 'full', lg: 'fit-content' }}
        />
      </Flex>
      <IconButton
        aria-label="Filtrar resultados"
        size="sm"
        icon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={{ base: 'full', lg: 'fit-content' }}
      />

      <IconButton
        aria-label="Remover filtros"
        size="sm"
        icon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleClearFilters}
        w={{ base: 'full', lg: 'fit-content' }}
      />


    </Flex>
  )
}
