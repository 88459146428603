import { Button, Flex, FormControl, FormLabel, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Stack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { updatePricesByCsv } from "api/prices/updatePricesByCsv";
import { useForm, useWatch } from "react-hook-form";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaFileImport } from "react-icons/fa6";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { useToastify } from "hooks/toastify/useToastify";

interface UpdatePricesByCsvSchema {
  csv: FileList
}

const updatePricesByCsvSchema = yup.object({
  csv: yup.mixed().test((file: FileList) => file.length > 0),
})

export function UpdatePricesByCsvModal() {

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<UpdatePricesByCsvSchema>({
    resolver: yupResolver(updatePricesByCsvSchema)
  })

  const [csvAttachment] = useWatch({
    control,
    name: ['csv']
  })

  const attachmentCsvPreview = csvAttachment
    ? Object.entries(csvAttachment).map(([key, value]) => URL.createObjectURL(value))[0]
    : null

  const queryClient = useQueryClient()

  const { mutateAsync: updatePricesByCsvFn } = useMutation({
    mutationFn: updatePricesByCsv,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['prices'] })
    }
  })

  const { promiseMessage } = useToastify()

  async function handleUpdatePricesByCsv(values: UpdatePricesByCsvSchema) {
    const formData = new FormData()

    formData.append('csv', values.csv[0])

    await promiseMessage(updatePricesByCsvFn({ body: formData }), 'Preços atualizados com sucesso!')
  }



  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Atualizar preços por CSV
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody
        as='form'
        onSubmit={handleSubmit(handleUpdatePricesByCsv)}
      >

        <Stack
          direction="column"
          w="full"
          mt="3"
        >
          <FormControl isInvalid={!!errors?.csv}>
            <FormLabel fontSize="sm">
              Arquivo csv
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('csv')}
              name="csv"
              id="csv"
              type="file"
              hidden
              accept=".csv"

            />
          </FormControl>
          <Button
            as={FormLabel}
            htmlFor="csv"
            lineHeight="1"
            leftIcon={<FaFileImport />}
            size="sm"
            w="min"
            cursor="pointer"
            border={!!errors?.csv && '2px solid'}
            borderColor={!!errors?.csv && 'red.500'}
          >
            Anexar csv
          </Button>
          {attachmentCsvPreview && (
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Td fontSize="xs" maxW="100px">{csvAttachment[0].name}</Td>

                  <Td fontSize="xs" isNumeric>

                    <IconButton
                      aria-label="Visualizar anexo"
                      as={Link}
                      size="sm"
                      icon={<FaExternalLinkAlt />}
                      href={URL.createObjectURL(csvAttachment[0])}
                      isExternal
                    />

                  </Td>
                </Tr>
              </Tbody>
            </Table>
          )}
        </Stack>


        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Atualizar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
