import {
  BillingReportProps,
  CargoReportProps,
  CteReportProps,
  OccurrenceReportProps,
  ReportProps,
  StockReportProps,
} from '../../contexts/ReportContext'
import { occurrenceStatusDict } from '../OccurrenceFunctions/occurrenceDict'
import { reportFormatDateToFront } from './reportFormatDateToFront'
import { reportFormatHourToFront } from './reportFormatHourToFront'
import { serviceHandleStep } from '../ServiceFunctions/serviceDict'
import { rncDict } from '../RncFunctions/rncDict'
import { handleChangeUrl } from '../handleChangeUrl'
import { formatTextToExcel } from 'utils/formatTextToExcel'
import { RncResponse } from 'hooks/rnc/useRncFunctions'
import { formatDate } from 'utils/DateFunctions/formatDate'
import { parseISO } from 'date-fns'

export function reportSlaFormatDataToFront(data: ReportProps[]) {
  const slaFormatedToFront = data.flatMap((data) => {
    return {
      id: data.id,
      serviceType: data.serviceType,
      serviceID: data.serviceID,
      collectDate: reportFormatDateToFront(data.collectDate),
      collectDates: data.collectDates,
      collectHours: data.collectHours,
      deliveryDate: reportFormatDateToFront(data.deliveryDate),
      deliveryDates: data.deliveryDates,
      deliveryHours: data.deliveryHours,
      sourceCity: data.sourceCity,
      sourceState: data.sourceState,
      destinationCity: data.destinationCity,
      destinationState: data.destinationState,
      customer: data.customer,
      destinationBranch: data.destinationBranch,
      destinationCollector: data.destinationCollector,
      endFilter: reportFormatDateToFront(data.endFilter),
      realDateAvailable:
        data.realDateAvailable === '-' || !data.realDateAvailable
          ? '-'
          : data.realDateAvailable,
      expectedCollectTime: reportFormatHourToFront(data.expectedCollectTime),
      expectedDeliveryTime: reportFormatHourToFront(data.expectedDeliveryTime),
      protocol: data.protocol,
      realTimeAvailable:
        data.realTimeAvailable === '-' || !data.realTimeAvailable
          ? '-'
          : data.realTimeAvailable,
      slaTransfer: data.slaTransfer,
      slaDelivery: data.slaDelivery,
      slaCollect: data.slaCollect,
      sourceBranch: data.sourceBranch,
      sourceCollector: data.sourceCollector,
      availabilityForecastDay: data.availabilityForecastDay
        ? data.availabilityForecastDay
        : '-',
      availabilityForecastTime: data.availabilityForecastTime
        ? data.availabilityForecastTime
        : '-',
      startFilter: reportFormatDateToFront(data.startFilter),
      step: data.step,
    }
  })

  return slaFormatedToFront
}

export function reportStockFormatDataToFront(dataStock: StockReportProps[]) {
  const stockFormatedToFront = dataStock.map((data) => {
    return {
      serviceAdesivoDryIce: data.serviceAdesivoDryIce,
      serviceAlmofadaAbsorvente: data.serviceAlmofadaAbsorvente,
      collectDate: reportFormatDateToFront(data.collectDate),
      customer: data.customer,
      destinationCollectorCity: data.destinationCollectorCity,
      destinationCollectorName: data.destinationCollectorName,
      sourceCollectorName: data.sourceCollectorName,
      sourceCollectorCity: data.sourceCollectorCity,
      endFilter: reportFormatDateToFront(data.endFilter),
      serviceGeloSeco: data.serviceGeloSeco,
      serviceFitaTransparente: data.serviceFitaTransparente,
      serviceGelox: data.serviceGelox,
      serviceIsopor3l: data.serviceIsopor3l,
      serviceIsopor7l: data.serviceIsopor7l,
      serviceLacre: data.serviceLacre,
      serviceEmbalagemSecundaria: data.serviceEmbalagemSecundaria,
      serviceEmbalagemSecundariaPote1l: data.serviceEmbalagemSecundariaPote1l,
      protocol: data.protocol,
      serviceID: data.serviceID,
      serviceType: data.serviceType,
      startFilter: reportFormatDateToFront(data.startFilter),
      step: data.step,
      serviceTerciaria3l: data.serviceTerciaria3l,
      serviceTerciaria8l: data.serviceTerciaria8l,
      budgetCaixaTermica: data.budgetCaixaTermica,
      budgetEmbalagemSecundaria: data.budgetEmbalagemSecundaria,
      budgetEmbalagemSecundariaPote1l: data.budgetEmbalagemSecundariaPote1l,
      budgetGeloSeco: data.budgetGeloSeco,
      budgetGelox: data.budgetGelox,
      budgetIsopor3l: data.budgetIsopor3l,
      budgetIsopor7l: data.budgetIsopor7l,
      budgetTerciaria3l: data.budgetTerciaria3l,
      budgetTerciaria8l: data.budgetTerciaria8l,
      additionalMaterials: data.additionalMaterials,
      modal: data.modal,
    }
  })

  return stockFormatedToFront
}

export function reportBillingFormatDataToFront(
  dataBilling: BillingReportProps[],
) {
  const billingFormatedData = dataBilling.map((data) => {
    return {
      addCollectPrice: data.addCollectPrice,
      addDeliveryPrice: data.addDeliveryPrice,
      sourceAddress: data.sourceAddress,
      collectSample: data.collectSample,
      observation: data.observation,
      deliveryHour: reportFormatHourToFront(data.deliveryHour),
      deliveryDate: reportFormatDateToFront(data.deliveryDate),
      collectHourEnd: reportFormatHourToFront(data.collectHourEnd),
      sourceStates: data.sourceStates,
      destinationStates: data.destinationStates,
      destinationAddress: data.destinationAddress,
      boardVolume: data.boardVolume,
      collectVolume: data.collectVolume,
      deliveryVolume: data.deliveryVolume,
      budgetDestinationAddressQty: data.budgetDestinationAddressQty,
      unsuccessCount: data.unsuccessCount,
      unsuccessPrice: data.unsuccessPrice,
      budgetPrice: data.budgetPrice,
      budgetSourceAddressQty: data.budgetSourceAddressQty,
      collectDate: reportFormatDateToFront(data.collectDate),
      collectDiffTime: data.collectDiffTime,
      sourceCity: data.sourceCity,
      destinationCity: data.destinationCity,
      cteLoglife: data.cteLoglife,
      cteLoglifeEmissionDate: data.cteLoglifeEmissionDate,
      customer: data.customer,
      deliveryDiffTime: data.deliveryDiffTime,
      extraKGPrice: data.extraKGPrice,
      extraKgQuantity: data.extraKgQuantity,
      extraTimePrice: data.extraTimePrice,
      infoExtraMaterials: data.infoExtraMaterials,
      totalCostExtraMaterials: data.totalCostExtraMaterials,
      franchising: data.franchising,
      landingVolume: data.landingVolume,
      protocol: data.protocol,
      realWeight: data.realWeight,
      totalExtraKGPrice: data.totalExtraKGPrice,
      totalAdditionalCollects: data.totalAdditionalCollects,
      totalAdditionalDeliveries: data.totalAdditionalDeliveries,
      vehicle: data.vehicle,
      serviceDestinationAddressQty: data.serviceDestinationAddressQty,
      serviceID: data.serviceID,
      serviceSourceAddressQty: data.serviceSourceAddressQty,
      serviceType: data.serviceType,
      awaitingTimeCollect: data.awaitingTimeCollect,
      awaitingCostCollect: data.awaitingCostCollect,
      awaitingTimeDelivery: data.awaitingTimeDelivery,
      awaitingCostDelivery: data.awaitingCostDelivery,
      step: data.step,
      taxedWeight: data.taxedWeight,
      otherCharges: data.otherCharges,
      otherChargesValue: data.otherChargesValue,
      totalPrice: data.totalPrice,
      complementaryCte: data.complementaryCte,
      cteComplementaryEmissionDate: data.cteComplementaryEmissionDate,
      additionalChargesTotalValue: data.additionalChargesTotalValue,
    }
  })

  return billingFormatedData
}

export function reportCargoFormatDataToFront(dataCargo: CargoReportProps[]) {
  const cargoFormatedData = dataCargo.map((data) => {
    return {
      // boardDateTime: `${reportFormatDateToFront(data.boardDateTime)} - ${reportFormatHourToFront(data.boardDateTime)}`,
      boardVolume: data.boardVolume,
      collectDate: reportFormatDateToFront(data.collectDate),
      vehicle: data.vehicle,
      customer: data.customer,
      sourceAddress: data.sourceAddress,
      sourceCollector: data.sourceCollector,
      sourceCity: data.sourceCity,
      destinationCollector: data.destinationCollector,
      destinationCity: data.destinationCity,
      destinationAddress: data.destinationAddress,
      deliveryDate: reportFormatDateToFront(data.deliveryDate),
      deliveryHour: reportFormatHourToFront(data.deliveryHour),
      destinationBranch: data.destinationBranch,
      shipping: data.shipping,
      // endFilter: reportFormatDateToFront(data.endFilter),
      landingDateTime: data.landingDateTime,
      landingVolume: data.landingVolume,
      hasCrossdocking: data.hasCrossdocking,
      tracker: data.tracker,
      crossdockingTracker: data.crossdockingTracker,
      sourceCrossdockingBranch: data.sourceCrossdockingBranch,
      destinationCrossdockingBranch: data.destinationCrossdockingBranch,
      cte: data.cte,
      crossdockingCTE: data.crossdockingCTE,
      crossdockingShipping: data.crossdockingShipping,
      whichBoard: data.whichBoard,
      protocol: data.protocol,
      serviceID: data.serviceID,
      serviceType: data.serviceType,
      sourceBranch: data.sourceBranch,
      geloSeco: data.geloSeco,
      // startFilter: reportFormatDateToFront(data.startFilter),
      step: data.step,
    }
  })

  return cargoFormatedData
}

export function reportCteFormatDataToFront(dataCte: CteReportProps[]) {
  const cteFormatedData = dataCte.map((data) => {
    return {
      protocol: data.protocol,
      customer: data.customer,
      step: data.step,
      collectDate: reportFormatDateToFront(data.collectDate),
      franchising: data.franchising,
      taxedWeight: data.taxedWeight,
      transportPrice: data.transportPrice,
      kgExtraPrice: data.kgExtraPrice,
      sourceCity: data.sourceCity,
      sourceAddress: data.sourceAddress,
      sourceCnpjCpf: data.sourceCnpjCpf,
      destinationCity: data.destinationCity,
      sourceCollectorName: data.sourceCollectorName,
      destinationCollectorName: data.destinationCollectorName,
      destinationAddress: data.destinationAddress,
      destinationCnpjCpf: data.destinationCnpjCpf,
    }
  })

  return cteFormatedData
}

export function reportOccurrenceFormatDataToFront(
  dataOccurrence: OccurrenceReportProps[],
) {
  const occurrenceFormatedData = dataOccurrence?.map((data) => ({
    ...data,
    occurrenceProtocol: data.occurrenceProtocol,
    serviceProtocol: data.serviceProtocol,
    customer: data.customer,
    sourceCollector: data.sourceCollector,
    destinationCollector: data.destinationCollector,
    collector: data.collector,
    shipping: data.shipping,
    sourceCities: data.sourceCities,
    destinationCities: data.destinationCities,
    type: data.type,
    intercurrence: data.intercurrence,
    occurrenceDetail: formatTextToExcel(data.occurrenceDetail),
    occurrenceDate:
      data.occurrenceDate !== '-'
        ? reportFormatDateToFront(data.occurrenceDate)
        : data.occurrenceDate,
    occurrenceHour:
      data.occurrenceHour !== '-'
        ? reportFormatHourToFront(data.occurrenceHour)
        : data.occurrenceHour,
    proceed: data.proceed,
    cause: formatTextToExcel(data.cause),
    corrective: formatTextToExcel(data.corrective),
    preventive: formatTextToExcel(data.preventive),
    reason: data.reason,
    owner: data.owner,
    createdAt: reportFormatDateToFront(data.createdAt),
    updatedAt: data.updatedAt,
    serviceStep: serviceHandleStep(data.serviceStep),
    step: occurrenceStatusDict(data.step),
    user: data.user,
    photo: data.photo ? handleChangeUrl(data.photo) : '-',
    observation: data.observation,
  }))

  return occurrenceFormatedData
}

export function reportRncFormatDataToFront(rncReportData: RncResponse) {
  const data = rncReportData?.rncs.map((rncData) => ({
    protocol: rncData.protocol,
    direction: rncData.direction,
    date: formatDate.handle(rncData.date, 'DateWithoutHourToShow'),
    intercurrence_description: formatTextToExcel(rncData.intercurrence_description ? rncData.intercurrence_description : '-'),
    loss_value: rncData.loss_value,
    opened_by: rncData.opened_by,
    intercurrence_responsible: rncData.intercurrence_responsible,
    why_not_proceed: formatTextToExcel(rncData.why_not_proceed ? rncData.why_not_proceed : '-'),
    cause: formatTextToExcel(rncData.cause ? rncData.cause : '-'),
    corrective: formatTextToExcel(rncData.corrective ? rncData.corrective : '-'),
    preventive: formatTextToExcel(rncData.preventive ? rncData.preventive : '-'),
    risk_rating: rncData.risk_rating ? rncData.risk_rating : '-',
    rnc_type: rncData.rnc_type ? rncData.rnc_type : '-',
    alignment_date: rncData.alignments.length > 0
      ? rncData.alignments.map(alignment => alignment.date)
      : rncData.alignment_date,
    alignment_observation: rncData.alignment_observation ? rncData.alignment_observation : '-',
    business_meeting: rncData.business_meeting ? rncData.business_meeting : '-',
    aligned_by: rncData?.alignments.length !== 0 ?
      rncData?.alignments.map(alignment => {
        return `${alignment?.alignedBy.firstname} ${alignment?.alignedBy.lastname}`
      }).join('\n')
      : "-",
    created_at: rncData?.alignments.length !== 0 ?
      rncData?.alignments.map(alignment => {
        return formatDate.handle(parseISO(alignment?.date), "DateWithoutHourToShow")
      }).join('\n')
      : "-",
    description: rncData?.alignments.length !== 0 ?
      rncData?.alignments.map(alignment => {
        return alignment?.description
      }).join('\n')
      : "-",
    attachments: rncData?.alignments.length !== 0 ?
      rncData?.alignments.map(alignment => {
        return alignment.attachments
          ? typeof alignment.attachments === 'string'
            ? JSON.parse(alignment.attachments).map((attachment) => {
              return handleChangeUrl(attachment.key)
            }).join('\n')
            : alignment.attachments.map((attachment) => {
              return handleChangeUrl(attachment.key)
            }).join('\n')
          : '-'
      }).join('\n')
      : "-",
    status: rncDict(rncData.status),
  }))

  return data
}
