import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Radio, RadioGroup, Select, Stack, Text } from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { sendCltDriverDismissTerminationDetails } from "api/cltDrivers/sendCltDriverDismissTerminationDetails";
import { CltDriver } from "api/cltDrivers/_types/CltDriver";
import { useEffect } from "react";
import { format, parseISO } from "date-fns";

interface SendCltDriverDismissTerminationDetailsProps {
  cltDriver: CltDriver
}

export interface SendCltDriverDismissTerminationDetailsSchema {
  doesPayedTermination: string
  hasDeletedEmployeeFromOccupationalMedicalPlatform: 'yes' | 'no' | 'n/a'
  dismissionRealDate: string
  shouldAdvanceStatus: string
}

const sendCltDriverDismissTerminationDetailsSchema = yup.object({
  doesPayedTermination: yup.string(),
  hasDeletedEmployeeFromOccupationalMedicalPlatform: yup.string(),
  dismissionRealDate: yup.string(),
})

export function SendCltDriverDismissTerminationDetails({ cltDriver }: SendCltDriverDismissTerminationDetailsProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<SendCltDriverDismissTerminationDetailsSchema>({
    resolver: yupResolver(sendCltDriverDismissTerminationDetailsSchema)
  })

  useEffect(() => {
    if (cltDriver) {
      setValue("doesPayedTermination", cltDriver.doesPayedTermination ? 'yes' : 'no')
      setValue("hasDeletedEmployeeFromOccupationalMedicalPlatform", cltDriver.hasDeletedEmployeeFromOccupationalMedicalPlatform || 'yes')
      setValue("dismissionRealDate", cltDriver?.dismissionRealDate ? format(parseISO(cltDriver?.dismissionRealDate), 'yyyy-MM-dd') : '')
    }
  }, [cltDriver, setValue])

  const { mutateAsync: sendCltDriverDismissTerminationDetailsFn } = useMutation({
    mutationFn: sendCltDriverDismissTerminationDetails,
    onSuccess(_data, { body, cltDriverId }) {
      queryClient.invalidateQueries({ queryKey: 'clts-drivers' })
      queryClient.invalidateQueries({ queryKey: 'dismissing-termination' })
      queryClient.invalidateQueries({ queryKey: 'dismissed' })
      queryClient.invalidateQueries({ queryKey: ['clt-driver', cltDriverId] })
    }
  })


  async function handleSendCltDriverDismissTerminationDetails(values: SendCltDriverDismissTerminationDetailsSchema) {
    await promiseMessage(sendCltDriverDismissTerminationDetailsFn({
      body: {
        doesPayedTermination: values.doesPayedTermination === 'yes',
        hasDeletedEmployeeFromOccupationalMedicalPlatform: values.hasDeletedEmployeeFromOccupationalMedicalPlatform,
        dismissionRealDate: new Date(`${values.dismissionRealDate} 12:00`),
        shouldAdvanceStatus: values.shouldAdvanceStatus ? values.shouldAdvanceStatus === 'yes' : false
      }, cltDriverId: cltDriver?.id
    }), 'Informações de rescisão enviadas com sucesso!')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleSendCltDriverDismissTerminationDetails)}
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'>Demissão - Rescisão</Heading>
      <FormControl isInvalid={!!errors.doesPayedTermination} mt={3}>
        <FormLabel>
          Rescisão paga?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="doesPayedTermination"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl isInvalid={!!errors.hasDeletedEmployeeFromOccupationalMedicalPlatform} mt={3}>
        <FormLabel>
          Excluiu o funcionário da plataforma de Medicina Ocupacional?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasDeletedEmployeeFromOccupationalMedicalPlatform"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
                <Radio size='sm' rounded='md' value="n/a">N/A</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl isInvalid={!!errors?.dismissionRealDate} mt={3}>
        <FormLabel>
          Data real da demissão
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('dismissionRealDate')}
          name="dismissionRealDate"
          type="date"
          rounded="md"
          size='sm'
        />
      </FormControl>
      <FormControl isInvalid={!!errors.shouldAdvanceStatus} mt={3}>
        <FormLabel fontSize="sm">
          Passar para o próximo status?
        </FormLabel>
        <Select
          {...register('shouldAdvanceStatus')}
          name="shouldAdvanceStatus"
          placeholder="Selecione..."
          size="sm"
          rounded="md"
        >
          <option value='yes'>Sim</option>
          <option value='no'>Não</option>
        </Select>
      </FormControl>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
