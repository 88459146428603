import { useQuery } from 'react-query'
import { apiIbge } from '../../services/ibgeApiservice/ibgeApi'

const useIbgeState = () => {
  const ibgeStates = useQuery(
    'ibgeStates',
    async () => {
      const response = await apiIbge.getStates('estados')

      return response
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  return { ibgeStates }
}

export { useIbgeState }
