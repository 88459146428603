import { PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";
import { BudgetProps } from "contexts/BudgetContext";

interface BillingServiceBudgetDetailProps {
  budget: BudgetProps
}

export function BillingServiceBudgetDetail({
  budget
}: BillingServiceBudgetDetailProps) {
  return (
    <PopoverContent textAlign="left">
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverHeader>Informações do orçamento</PopoverHeader>
      <PopoverBody>
        <TableContainer>
          <Table size="sm">
            <Tbody>
              <Tr>
                <Td>Origem</Td>
                <Td isNumeric>{budget.sourceHubIDBudget.name}</Td>
              </Tr>
              <Tr>
                <Td>Destino</Td>
                <Td isNumeric>{budget.destinationHubIDBudget.name}</Td>
              </Tr>
              <Tr>
                <Td>Veículo</Td>
                <Td isNumeric>{budget.vehicle}</Td>
              </Tr>
              <Tr>
                <Td>Gelox</Td>
                <Td isNumeric>{budget.gelox}</Td>
              </Tr>
              <Tr>
                <Td>Gelo seco</Td>
                <Td isNumeric>{budget.gelo_seco}</Td>
              </Tr>
              <Tr>
                <Td>Modal</Td>
                <Td isNumeric>{budget.modal}</Td>
              </Tr>
              <Tr>
                <Td>Preço</Td>
                <Td isNumeric>{budget.price.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </PopoverBody>
    </PopoverContent>
  )
}
