import { Button, ButtonProps, Icon, useMediaQuery } from '@chakra-ui/react'
import { FaTimes } from 'react-icons/fa'

interface ICancelButtonProps extends ButtonProps {
  action: string
  isSubmitting?: boolean
  type?: 'button' | 'reset' | 'submit'
}

export function CancelButton({
  action,
  type,
  isSubmitting,
  ...rest
}: ICancelButtonProps) {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  return (
    <Button
      type={type ?? 'button'}
      colorScheme="red"
      isLoading={isSubmitting}
      loadingText={isWideVersion ? 'Carregando' : ''}
      {...rest}
    >
      {isWideVersion ? action : <Icon as={FaTimes} />}
    </Button>
  )
}
