import { useMemo } from "react";
import { formatDate } from "utils/DateFunctions/formatDate";
import { RouteNetwork } from "./useRouteNetwork/dtos/RouteNetwork";
import { ServiceRouteNetworks } from "./useRouteNetwork/dtos/ServiceRouteNetwork";

interface UsePlannedRoutesProps {
  routeNetworks: ServiceRouteNetworks[]
  isFractionedService: boolean
  isCrossdockingService: boolean
  hasConnection: boolean
  hasCrossdockingConnection: boolean
  sourceBranchSelected: string
  destinationBranchSelected: string
  sourceCrossdockingBranchSelected: string
  destinationCrossdockingBranchSelected: string
  connectionBranchSelected: string
  crossdockingConnectionBranchSelected: string
}

export interface UsePlannedNetworkFormattedData {
  id: string
  value_filter: string
  truck_flight: string
  arrival_date: string
  arrival_hour: string
  departure_hour: string
  departure_date: string
}

function formatRouteNetworkData(routeNetwork: RouteNetwork) {
  const plannedRouteInfo = `${routeNetwork.truck_flight} - ${formatDate.handle(
    routeNetwork.departure_date, "DateWithoutHourToShow"
  )} - ${formatDate.handle(
    routeNetwork.departure_hour, "DateOnlyWithHourMinute"
  )} / ${formatDate.handle(
    routeNetwork.arrival_date, "DateWithoutHourToShow"
  )} - ${formatDate.handle(
    routeNetwork.arrival_hour, "DateOnlyWithHourMinute"
  )}`

  return {
    id: routeNetwork.id,
    value_filter: plannedRouteInfo,
    truck_flight: routeNetwork.truck_flight,
    arrival_date: routeNetwork.arrival_date,
    arrival_hour: routeNetwork.arrival_hour,
    departure_hour: routeNetwork.departure_hour,
    departure_date: routeNetwork.departure_date,
  }
}

export function usePlannedRoutes({
  routeNetworks,
  isFractionedService,
  isCrossdockingService,
  hasConnection,
  hasCrossdockingConnection,
  sourceBranchSelected,
  destinationBranchSelected,
  sourceCrossdockingBranchSelected,
  destinationCrossdockingBranchSelected,
  connectionBranchSelected,
  crossdockingConnectionBranchSelected,
}: UsePlannedRoutesProps) {

  const plannedRoutesWithoutCrossdockingAndConnection = useMemo(() => {
    if (isFractionedService && !isCrossdockingService && !hasConnection && routeNetworks && sourceBranchSelected && destinationBranchSelected)
      return routeNetworks?.filter(routeNetwork => {
        const sourceBranchFilter = routeNetwork.source_branch_id === sourceBranchSelected
        const destinationBranchFilter = routeNetwork.destination_branch_id === destinationBranchSelected

        if (sourceBranchFilter && destinationBranchFilter) {
          return true
        }

        return false
      }).map(formatRouteNetworkData)

    return []
  }, [
    destinationBranchSelected,
    hasConnection,
    isCrossdockingService,
    isFractionedService,
    routeNetworks,
    sourceBranchSelected
  ])

  const plannedRoutesFromSourceBranchToConnectionBranch = useMemo(() => {
    if (isFractionedService && !isCrossdockingService && hasConnection && routeNetworks && sourceBranchSelected && connectionBranchSelected) {
      return routeNetworks.filter(routeNetwork => {
        const sourceBranchFilter = routeNetwork.source_branch_id === sourceBranchSelected
        const destinationBranchFilter = routeNetwork.destination_branch_id === connectionBranchSelected

        if (sourceBranchFilter && destinationBranchFilter) {
          return true
        }

        return false
      }).map(formatRouteNetworkData)
    }
    return []
  }, [
    isFractionedService,
    isCrossdockingService,
    hasConnection,
    routeNetworks,
    sourceBranchSelected,
    connectionBranchSelected,
  ])

  const plannedRoutesFromSourceBranchToDestinationCrossdockingBranch = useMemo(() => {
    if (isFractionedService && isCrossdockingService && !hasConnection && !hasCrossdockingConnection && routeNetworks && sourceBranchSelected && destinationCrossdockingBranchSelected) {
      return routeNetworks.filter(routeNetwork => {
        const sourceBranchFilter = routeNetwork.source_branch_id === sourceBranchSelected
        const destinationBranchFilter = routeNetwork.destination_branch_id === destinationCrossdockingBranchSelected

        if (sourceBranchFilter && destinationBranchFilter) {
          return true
        }

        return false
      }).map(formatRouteNetworkData)
    }
    return []
  }, [
    isFractionedService,
    isCrossdockingService,
    hasConnection,
    routeNetworks,
    sourceBranchSelected,
    destinationCrossdockingBranchSelected,
    hasCrossdockingConnection,
  ])

  const plannedRoutesFromSourceCrossdockingBranchToDestinationBranch = useMemo(() => {
    if (isFractionedService && isCrossdockingService && !hasConnection && routeNetworks && sourceCrossdockingBranchSelected && destinationBranchSelected) {
      return routeNetworks.filter(routeNetwork => {
        const sourceBranchFilter = routeNetwork.source_branch_id === sourceCrossdockingBranchSelected
        const destinationBranchFilter = routeNetwork.destination_branch_id === destinationBranchSelected

        if (sourceBranchFilter && destinationBranchFilter) {
          return true
        }

        return false
      }).map(formatRouteNetworkData)
    }
    return []
  }, [
    isFractionedService,
    isCrossdockingService,
    hasConnection,
    routeNetworks,
    sourceCrossdockingBranchSelected,
    destinationBranchSelected,
  ])

  const plannedRoutesFromSourceBranchToConnectionCrossdockingBranch = useMemo(() => {
    if (isFractionedService && isCrossdockingService && hasCrossdockingConnection && routeNetworks && sourceBranchSelected && crossdockingConnectionBranchSelected) {

      return routeNetworks.filter(routeNetwork => {
        const sourceBranchFilter = routeNetwork.source_branch_id === sourceBranchSelected
        const destinationBranchFilter = routeNetwork.destination_branch_id === crossdockingConnectionBranchSelected

        if (sourceBranchFilter && destinationBranchFilter) {
          return true
        }

        return false
      }).map(formatRouteNetworkData)
    }

    return []
  }, [
    isFractionedService,
    isCrossdockingService,
    routeNetworks,
    sourceBranchSelected,
    crossdockingConnectionBranchSelected,
    hasCrossdockingConnection,
  ])

  const plannedRoutesFromConnectionCrossdockingBranchToDestinationCrossdockingBranch = useMemo(() => {
    if (isFractionedService && isCrossdockingService && !hasConnection && hasCrossdockingConnection && routeNetworks && crossdockingConnectionBranchSelected && destinationCrossdockingBranchSelected) {
      return routeNetworks.filter(routeNetwork => {
        const sourceBranchFilter = routeNetwork.source_branch_id === crossdockingConnectionBranchSelected
        const destinationBranchFilter = routeNetwork.destination_branch_id === destinationCrossdockingBranchSelected

        if (sourceBranchFilter && destinationBranchFilter) {
          return true
        }

        return false
      }).map(formatRouteNetworkData)
    }
    return []
  }, [
    isFractionedService,
    isCrossdockingService,
    hasConnection,
    routeNetworks,
    crossdockingConnectionBranchSelected,
    destinationCrossdockingBranchSelected,
    hasCrossdockingConnection,
  ])

  const plannedRoutesFromSourceCrossdockingBranchToConnectionBranch = useMemo(() => {
    if (isFractionedService && isCrossdockingService && hasConnection && routeNetworks && sourceCrossdockingBranchSelected && connectionBranchSelected) {
      return routeNetworks.filter(routeNetwork => {
        const sourceBranchFilter = routeNetwork.source_branch_id === sourceCrossdockingBranchSelected
        const destinationBranchFilter = routeNetwork.destination_branch_id === connectionBranchSelected

        if (sourceBranchFilter && destinationBranchFilter) {
          return true
        }

        return false
      }).map(formatRouteNetworkData)
    }

    return []
  }, [
    isFractionedService,
    isCrossdockingService,
    hasConnection,
    routeNetworks,
    sourceCrossdockingBranchSelected,
    connectionBranchSelected,
  ])

  const plannedRoutesFromConnectionBranchToDestinationBranch = useMemo(() => {
    if (isFractionedService && hasConnection && routeNetworks && connectionBranchSelected && destinationBranchSelected) {
      return routeNetworks.filter(routeNetwork => {
        const sourceBranchFilter = routeNetwork.source_branch_id === connectionBranchSelected
        const destinationBranchFilter = routeNetwork.destination_branch_id === destinationBranchSelected

        if (sourceBranchFilter && destinationBranchFilter) {
          return true
        }

        return false
      }).map(formatRouteNetworkData)
    }

    return []
  }, [
    isFractionedService,
    hasConnection,
    routeNetworks,
    connectionBranchSelected,
    destinationBranchSelected,
  ])


  return {
    plannedRoutesWithoutCrossdockingAndConnection,
    plannedRoutesFromSourceBranchToConnectionBranch,
    plannedRoutesFromConnectionBranchToDestinationBranch,
    plannedRoutesFromSourceBranchToDestinationCrossdockingBranch,
    plannedRoutesFromSourceCrossdockingBranchToDestinationBranch,
    plannedRoutesFromSourceBranchToConnectionCrossdockingBranch,
    plannedRoutesFromConnectionCrossdockingBranchToDestinationCrossdockingBranch,
    plannedRoutesFromSourceCrossdockingBranchToConnectionBranch,
  }
}
