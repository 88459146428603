import { Attachment } from 'hooks/attachment/dtos/Attachment';
import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Contingency } from './_types/Contingency';
import { User } from 'hooks/user/dtos/User';

export interface GetContingencyResponse {
  contingency: Contingency & {
    attachments: {
      id: string
      attachmentId: string
      createdByUser: User
      attachment: Attachment
      createdAt: string
    }[]
  }
}

interface GetContingencyParams {
  contingencyId: string
}

export async function getContingency({
  contingencyId
}: GetContingencyParams) {
  try {
    const response = await api.get<GetContingencyResponse>(`/contingencies/${contingencyId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
