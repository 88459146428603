import { Box, Button, Flex, Icon, Menu, MenuButton, Text } from "@chakra-ui/react";
import { ReactNode } from "react";
import { IconType } from "react-icons";

interface LoadRatioServicesCheckboxFilterButtonRootProps {
  title: string
  checkedOptionsCount?: number
  children: ReactNode
  icon: IconType
}

export function LoadRatioServicesCheckboxFilterButtonRoot
  ({
    title,
    checkedOptionsCount,
    children,
    icon
  }: LoadRatioServicesCheckboxFilterButtonRootProps) {
  return (
    <Box>
      <Menu
        closeOnSelect={false}
      >
        <MenuButton
          as={Button}
          variant="outline"
          rounded="lg"
          fontWeight="normal"
          boxShadow="base"
          display="flex"
          w="full"
          alignItems="center"
          rightIcon={
            <Icon as={icon} />
          }
          _focus={{
            outlineOffset: 0,
            outlineColor: "#38c3fa",
            outlineWidth: 2,
          }}
        >
          <Flex
            gap="2"
            align="center"
            justify="space-between"
            w="full"
          >
            <Text
              w="full"
              fontSize="sm"
            >
              {title}
            </Text>
            {(!!checkedOptionsCount && checkedOptionsCount > 0) && (
              <Text
                border="1px solid"
                borderColor="gray.300"
                rounded="md"
                bg="blue.50"
                fontSize="xs"
                fontWeight="bold"
                minW="5"
                minH="5"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {checkedOptionsCount}
              </Text>
            )}
          </Flex>
        </MenuButton>
        {children}

      </Menu>
    </Box>
  )
}
