import { Flex, Heading } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ValidateNewServiceAddressContentProps {
  children: ReactNode
  title: string
}

export function ValidateNewServiceAddressContent({
  title,
  children
}: ValidateNewServiceAddressContentProps) {
  return (
    <Flex
      gap="4"
      direction="column"
    >
      <Heading size="md">{title}</Heading>
      {children}
    </Flex>
  )
}
