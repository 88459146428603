import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Button,
  Icon,
  Image,
  Stack,
  Alert,
  AlertIcon,
  Link as ChakraLink,
  Text,
  AlertDescription,
  AlertTitle,
} from '@chakra-ui/react'
import { useEffect, useState, useRef, useMemo } from 'react'
import { XMLParser } from 'fast-xml-parser'

import { Link, useLocation } from 'react-router-dom'
import { useHistory, useParams } from 'react-router'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { FiExternalLink, FiFile, FiPlus } from 'react-icons/fi'

import { negative_positive } from '../../../utils/customLists'
import { handleChangeUrl } from '../../../utils/handleChangeUrl'

import {
  RequestedServiceProps,
  ServiceProps,
} from '../../../contexts/ServiceContext'
import { BranchProps } from '../../../contexts/BranchContext'

import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { TextArea } from '../../../components/Inputs/TextInput'
import { Select } from '../../../components/Inputs/SelectInput'
import { Input } from '../../../components/Inputs/Input'

import BoardValidateAccordion from '../../../components/Accordions/Service/BoardValidateAccordion/BoardValidateAccordion'


import { useService } from '../../../hooks/services/service'
import { useBranch } from '../../../hooks/branch/useBranch'
import { useDriver } from '../../../hooks/driver/useDriver'

import { useCurrency } from 'react-hook-currency'
import {
  boardValidateFormatDataToBack,
  boardValidateFormatDataToBackWithoutPhoto,
} from '../../../utils/ServiceFunctions/BoardValidate/boardValidateFormatDataToBack'
import { useSwal } from '../../../hooks/swal/useSwal'
import axios from 'axios'
import { useBoardServiceFunctions } from '../../../hooks/services/board/useBoardServiceFunctions'
import { useAuth } from '../../../hooks/auth/useAuth'
import { BoardServiceProps } from '../../../services/getFunctions/getBoardServiceFunctions'
import { IDriverProps } from '../../../services/getFunctions/driver/getDrivers'
import { transformStringToNumber } from '../../../utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber'
import { useBudget } from '../../../hooks/budget/useBudget'
import { formatDate } from '../../../utils/DateFunctions/formatDate'
import { FieldError } from 'react-hook-form'
import { Radio } from 'components/Inputs/RadioInput'
import { positiveNegativeOptions } from 'utils/CustomLists/positiveNegativeOptions'
import { useShipping } from 'hooks/shipping/useShipping'

interface FormInputProps {
  branch_id: string
  destination_branch_id: string
  service_id: string
  driver_id: string
  collector_id: string
  arrival_latitude: string
  arrival_longitude: string
  arrival_timestamp: string
  operational_number: string
  cte: string | null
  transfer_budget: string
  franchising: string
  extra_kg_price: string
  board_volume: number | null
  board_weight: string | null
  cte_photo: {} | null
  xml_file: {} | null
  cte_photo_change: string
  real_weight: string | null
  taxed_weight: string | null
  cte_transfer_cost: string | null
  departure_latitude: string | null
  departure_longitude: string | null
  departure_timestamp: string | null
  real_board_date: string
  real_board_hour: string
  wait_link: string
  is_loglife_payer: string
  is_cte_destination_branch_equal_to_planned: string
  board_observation: string | null
  validate_observation: string | null
  sourceBranch: string
  destinationBranch: string
  open_ticket_email: 'yes' | 'no'
  has_tracker_link: 'yes' | 'no'
  tracker_link: string
  volume_quantity: number
}

interface IXmlProps {
  cteProc: {
    CTe: {
      infCte: {
        infCTeNorm: {
          infModal: {
            aereo: {
              nOCA: number
            }
            rodoviario: {
              nOCA: number
            }
          }
          infCarga: {
            infQ: {
              cUnid: number
              tpMed: string
              qCarga: number
            }[]
          }
        }
        ide: {
          nCT: number
          dhEmi: string
        }
        compl: {
          fluxo: {
            xOrig: string
            xDest: string
          }
        }
        vPrest: {
          vTPrest: number
        }
      }
    }
  }
}

interface IQueryParams {
  service_id: string
  slug: string
}

interface ILocationProps {
  state: {
    collector_id: string
  }
}

interface DriverFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
}

const switchTotalWeight = (name: string) => {
  switch (name) {
    case 'PESO BASE DE CÁLCULO':
      return true
    case 'PESO BASE DE CALCULO':
      return true
    case 'PESO TAXADO':
      return true
    case 'Peso taxado':
      return true
    case 'PESO_BASE_CALCULO':
      return true
    case 'PESO':
      return true
    default:
      return false
  }
}

const switchRealWeight = (name: string) => {
  switch (name) {
    case 'PESO AFERIDO(KG)':
      return true
    case 'PESO CUBADO':
      return true
    case 'Peso cubado':
      return true
    case 'M3':
      return true
    case 'PESO':
      return true
    default:
      return false
  }
}

const switchTaxedWeight = (name: string) => {
  switch (name) {
    case 'PESO BRUTO(KG)':
      return true
    case 'PESO REAL':
      return true
    case 'Peso real':
      return true
    case 'PESO':
      return true
    default:
      return false
  }
}

const switchVolumes = (name: string) => {
  switch (name) {
    case 'QTDE DE VOLUMES':
      return true
    case 'QTDE VOLUMES':
      return true
    case 'VOLUMES':
      return true
    case 'Volumes':
      return true
    case 'UNIDADE':
      return true
    default:
      return false
  }
}


const validateValueIsBiggerThanZero = (
  value: string,
  originalValue: string,
) => {
  return transformStringToNumber(value) > 0 ? originalValue : ''
}

const validateSchema = yup.object().shape({
  driver_id: yup.string().when('$requiredDriver', {
    is: true,
    then: yup.string().required('Campo obrigatório!')
  }),
  cte_loglife: yup
    .string()
    .matches(/^[A-Za-z0-9]+$/, 'Insira somente letras ou números'),
  cte: yup
    .string()
    .required('Campo Obrigatório')
    .matches(/^[A-Za-z0-9]+$/, 'Insira somente letras ou números'),
  board_volume: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório!'),
  board_weight: yup
    .string()
    .transform(validateValueIsBiggerThanZero)
    .required('Campo Obrigatório'),
  operational_number: yup
    .string()
    .matches(/^[A-Za-z0-9]+$/, 'Insira somente letras ou números')
    .required('Campo Obrigatório'),
  real_weight: yup
    .string()
    .transform(validateValueIsBiggerThanZero)
    .required('Campo Obrigatório'),
  taxed_weight: yup
    .string()
    .transform(validateValueIsBiggerThanZero)
    .required('Campo Obrigatório'),
  cte_transfer_cost: yup
    .string()
    .transform(validateValueIsBiggerThanZero)
    .required('Campo Obrigatório'),
  is_cte_destination_branch_equal_to_planned: yup.string().required("Campo Obrigatório!"),
  is_loglife_payer: yup.string().required("Campo Obrigatório!"),
  open_ticket_email: yup.string().when('$isPriorityBudget', {
    is: true,
    then: yup.string().required('Campo obrigatório!')
  }),
  validate_observation: yup.string(),
  has_tracker_link: yup.string().required('Campo obrigatório!'),
  tracker_link: yup.mixed().when('has_tracker_link', {
    is: 'yes',
    then: yup.string().required('Campo Obrigatório')
  })
})

const validateCurrentSchema = yup.object().shape({
  branch_id: yup.string().required('Campo Obrigatório'),
  board_volume: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  operational_number: yup
    .string()
    .matches(/^[A-Za-z0-9]+$/, 'Insira somente letras ou números')
    .required('Campo Obrigatório'),
  driver_id: yup.string().required('Campo Obrigatório'),
  cte: yup
    .string()
    .required('Campo Obrigatório')
    .matches(/^[A-Za-z0-9]+$/, 'Insira somente letras ou números'),
  board_weight: yup.string().required('Campo Obrigatório'),
  real_weight: yup.string().required('Campo Obrigatório'),
  taxed_weight: yup.string().required('Campo Obrigatório'),
  cte_photo_change: yup.string().required('Campo Obrigatório'),
  cte_photo: yup.mixed().required('Foto Obrigatória'),
  cte_transfer_cost: yup.string().required('Campo Obrigatório'),
  real_board_date: yup.string().required('Campo Obrigatório'),
  real_board_hour: yup.string().required('Campo Obrigatório'),
  validate_observation: yup.string(),
  board_observation: yup.string(),
  has_tracker_link: yup.string().required('Campo obrigatório!'),
  tracker_link: yup.mixed().when('has_tracker_link', {
    is: 'yes',
    then: yup.string().required('Campo Obrigatório')
  })
})

export function ValidateService({
  id,
  isDisabled = false,
  href,
  title,
  action,
}: DriverFormProps) {
  const [isCalculatingTranferBudget, setIsCalculatingTranferBudget] =
    useState(false)
  const [transferBudgetNewValue, setTransferBudgetNewValue] = useState('')
  const [taxedWeightValue, setTaxedWeightValue] = useState('')

  const [selectedFileCtePhoto, setSelectedFileCtePhoto] = useState<any>()
  const [previewCtePhoto, setPreviewCtePhoto] = useState<string>()

  const [selectedXmlFile, setSelectedXmlFile] = useState<any>()
  const [previewXmlFile, setPreviewXmlFile] = useState<string>()

  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const [sourceBranches, setSourceBranches] = useState<BranchProps[]>()

  const [boardServiceFilteredById, setBoardServiceFilteredById] = useState<
    BoardServiceProps[]
  >([])
  const [requestedServiceFilteredById, setRequestedServiceFilteredById] =
    useState<RequestedServiceProps[]>([])
  const [serviceFilteredById, setServiceFilteredById] = useState<
    ServiceProps[]
  >([])
  const [serviceProtocol, setServiceProtocol] = useState<number>()

  const [isShippingWithTrackerLinkDefault, setIsShippingWithTrackerLinkDefault] = useState(false)

  const {
    validateBoardService: { mutateAsync: validateBoardService },
    updateBoardService: { mutateAsync: updateBoardService },
  } = useBoardServiceFunctions()
  const { userLogged } = useAuth()

  const { service_id, slug }: IQueryParams = useParams()
  const { state }: ILocationProps = useLocation()
  const { confirmMessage, standardMessage } = useSwal()
  const history = useHistory()

  const location = useLocation()
  const resState = location.state as { path?: string }
  const hasPathRedirect = resState?.path !== undefined && resState?.path !== null

  const {
    service: { data: serviceById, isLoading: isServiceLoading },
  } = useService(service_id, false, false)
  const {
    branches: { data: branches, isLoading: isBranchLoading },
  } = useBranch(null, true, false)
  const {
    drivers: { data: drivers, isLoading: isDriverLoading },
  } = useDriver(null, true, false)
  const {
    budgets: { data: budgets, isLoading: isBudgetsLoading },
  } = useBudget(null, true, false)

  const {
    shipping: { data: shipping, isLoading: isShippingLoading },
  } = useShipping(null, true, false)

  const priorityBudget = serviceById?.serviceIDRequested?.budgetIDService?.priority_budget
  const isServiceRoad = serviceById?.serviceIDRequested.modal === 'RODOVIÁRIO'

  const isFinishedService = serviceById?.step === 'finishedService'

  const userHasPermissionEditFinishedService =
    userLogged?.permissions.includes("edit-finished-service");

  const editFinishedService = isFinishedService ?
    userHasPermissionEditFinishedService ?
      false :
      true :
    false

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    resolver: slug
      ? yupResolver(validateCurrentSchema)
      : yupResolver(validateSchema),
    context: {
      requiredDriver:
        !!slug,
      isPriorityBudget: priorityBudget
    }
  })

  const { onChange, format: currencyFormat } = useCurrency({
    style: 'decimal',
  })

  const changeCtePhoto = watch('cte_photo_change')
  const isDriverSelected = watch('driver_id')
  const collectorId = watch('collector_id')
  const branchID = watch('branch_id')
  const destinationBranchID = watch('destination_branch_id')
  const franchising = watch('franchising')
  const transferBudget = watch('transfer_budget')
  const extraKgPrice = watch('extra_kg_price')
  const sourceBranchXmlBoarding = watch("sourceBranch")
  const destinationBranchXmlBoarding = watch('destinationBranch')

  const trackerLink = watch('has_tracker_link')
  const hasTrackerLink = trackerLink === 'yes'

  const operationalNumber = watch("operational_number")
  const boardVolume = watch("board_volume")
  const volumeQuantity = watch("volume_quantity")

  const boardVolumeIsEqualsVolumeQuantity = Number(boardVolume) === Number(volumeQuantity)
  const enableBoardValidation = boardVolumeIsEqualsVolumeQuantity || volumeQuantity === undefined

  const sourceBranchIsSameXml = branches?.find(branch => {
    return branch.id === branchID && branch.nickname.toUpperCase() === sourceBranchXmlBoarding
  })

  const destinationBranchIsSameXml = branches?.find(branch => {
    return branch.id === destinationBranchID && branch.nickname.toUpperCase() === destinationBranchXmlBoarding
  })

  const isCrossdockingService = serviceById?.serviceIDRequested.crossdocking_collector_id !== null

  const isCrossdockingBoard = isCrossdockingService
    ? !serviceById?.serviceIDBoard?.find(
      (board) => board.collector_id === serviceById?.serviceIDRequested.source_collector_id
    )
    : false

  const isValidateBoardingEnabled = true
  // !slug
  //   ? isCrossdockingBoard
  //     ? serviceById?.serviceIDRequested.crossdocking_modal === 'AÉREO'
  //       ? sourceBranchIsSameXml && destinationBranchIsSameXml
  //       : true
  //     : serviceById?.serviceIDRequested.modal === 'AÉREO'
  //       ? sourceBranchIsSameXml && destinationBranchIsSameXml
  //       : true
  //   : true


  const boardServiceByIdFilteredByCollectorId = useMemo(() => {
    const boardServiceCollectorIdValue = boardServiceFilteredById.map(
      (boardService) => boardService.collector_id,
    )
    if (boardServiceCollectorIdValue[0] === null) {
      return boardServiceFilteredById.filter(
        (boardService) => boardService.collector_id === null,
      )
    } else {
      return boardServiceFilteredById.filter(
        (boardService) => boardService.collector_id === collectorId,
      )
    }
  }, [collectorId, boardServiceFilteredById])

  useEffect(() => {
    function run() {
      if (serviceById !== undefined) {
        const serviceFiltered = []
        serviceFiltered.push(serviceById)
        setServiceFilteredById(serviceFiltered)
        setServiceProtocol(serviceById.protocol)

        if (serviceById.serviceIDBoard !== null) {
          setBoardServiceFilteredById(serviceById.serviceIDBoard)
        }

        if (serviceById.serviceIDRequested !== null) {
          const requestedServiceFiltered = []
          requestedServiceFiltered.push(serviceById.serviceIDRequested)
          setRequestedServiceFilteredById(requestedServiceFiltered)
        }
      }
    }
    run()
  }, [serviceById, collectorId])

  useEffect(() => {
    function run() {
      if (
        !isDriverLoading &&
        !isServiceLoading &&
        !isBranchLoading &&
        !isBudgetsLoading &&
        !isShippingLoading
      ) {
        setIsLoadingPage(false)
      }
    }
    run()
  }, [isDriverLoading, isServiceLoading, isBranchLoading, isBudgetsLoading, isShippingLoading])

  useEffect(() => {
    function run() {
      if (!!branches && !!shipping && !!serviceById) {
        const sourceBranchFiltered = branches.filter(
          (branch) => branch.id === branchID,
        )
        const shippingOfBranches = sourceBranchFiltered
          .map((branch) => branch.shipping_id)
          .toString()

        const shippingFiltered = shipping
          .filter((ship) => ship.id === shippingOfBranches)
          .map((ship) => ship.company_name)
          .toString()

        if (!isServiceRoad) {
          const hasTrackerLinkDefault = shippingFiltered.includes('LATAM') || shippingFiltered.includes('AZUL') || shippingFiltered.includes('GOLLOG')

          setIsShippingWithTrackerLinkDefault(hasTrackerLinkDefault)

          if (shippingFiltered.includes('GOLLOG')) {
            const trackerLink = `https://transportebiologico.com.br/rastreios/gollog/${operationalNumber}`

            setValue('tracker_link', trackerLink)
            setValue('has_tracker_link', 'yes')
          }

          if (shippingFiltered.includes('LATAM')) {
            const trackerLink = `https://www.latamcargo.com/en/trackshipment?docNumber=${operationalNumber.substring(3)}&docPrefix=957&soType=SO`

            setValue('tracker_link', trackerLink)
            setValue('has_tracker_link', 'yes')
          }

          if (shippingFiltered.includes('AZUL')) {
            const trackerLink = `https://azulcargoexpress.smartkargo.com/FrmAWBTracking.aspx?AWBPrefix=577&AWBno=${operationalNumber.substring(3)}`

            setValue('tracker_link', trackerLink)
            setValue('has_tracker_link', 'yes')
          }
        }
      }
    }
    run()
  }, [branches, shipping, branchID, operationalNumber, setValue, hasTrackerLink, serviceById, isServiceRoad])

  useEffect(() => {
    if (!selectedFileCtePhoto) {
      setPreviewCtePhoto(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFileCtePhoto)
    setPreviewCtePhoto(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFileCtePhoto])

  useEffect(() => {
    if (!selectedXmlFile) {
      setPreviewXmlFile(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedXmlFile)
    setPreviewXmlFile(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedXmlFile])

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (!permissions?.includes('valid-boarding-service')) {
            history.push('/')
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [history, userLogged])

  useEffect(() => {
    const operationalNumber = String(
      boardServiceByIdFilteredByCollectorId.map(
        (service) => service.operational_number,
      ),
    )
    const boardVolume = Number(
      boardServiceByIdFilteredByCollectorId.map(
        (service) => service.board_volume,
      ),
    )
    const branchID = String(
      boardServiceByIdFilteredByCollectorId.map((service) => service.branch_id),
    )

    const crossdockingCollectorID = String(
      serviceFilteredById.map(
        (service) => service.serviceIDRequested.crossdocking_collector_id,
      ),
    )
    if (crossdockingCollectorID) {
      if (collectorId === crossdockingCollectorID) {
        const sourceBranchID = String(
          serviceFilteredById.map(
            (service) => service.serviceIDRequested.source_branch_id,
          ),
        )
        const destinationCrossdockingBranchID = String(
          serviceFilteredById.map(
            (service) =>
              service.serviceIDRequested.destination_crossdocking_branch_id,
          ),
        )
        setValue('branch_id', sourceBranchID)
        setValue('destination_branch_id', destinationCrossdockingBranchID)
      } else {
        const sourceCrossdockingBranchID = String(
          serviceFilteredById.map(
            (service) =>
              service.serviceIDRequested.source_crossdocking_branch_id,
          ),
        )
        const destinationBranchID = String(
          serviceFilteredById.map(
            (service) => service.serviceIDRequested.destination_branch_id,
          ),
        )
        setValue('branch_id', sourceCrossdockingBranchID)
        setValue('destination_branch_id', destinationBranchID)
      }
    } else {
      const sourceCrossdockingBranchID = String(
        serviceFilteredById.map(
          (service) => service.serviceIDRequested.source_crossdocking_branch_id,
        ),
      )
      const destinationBranchID = String(
        serviceFilteredById.map(
          (service) => service.serviceIDRequested.destination_branch_id,
        ),
      )
      setValue('branch_id', sourceCrossdockingBranchID)
      setValue('destination_branch_id', destinationBranchID)
    }

    setValue('service_id', service_id)
    setValue('operational_number', operationalNumber)
    setValue('board_volume', boardVolume)
    setValue('branch_id', branchID)

    if (slug) {
      boardServiceByIdFilteredByCollectorId.map((boardService) => {

        return (
          setValue('branch_id', boardService.branch_id),
          setValue('service_id', boardService.service_id),
          setValue('arrival_latitude', boardService.arrival_latitude),
          setValue('arrival_longitude', boardService.arrival_longitude),
          setValue('arrival_timestamp', boardService.arrival_timestamp),
          setValue('operational_number', boardService.operational_number),
          setValue('cte', boardService.cte),
          setValue('board_volume', boardService.board_volume),
          setValue('board_weight', String(boardService.board_weight)),
          setValue('cte_photo', boardService.cte_photo),
          setValue('real_weight', String(boardService.real_weight)),
          setValue('taxed_weight', String(boardService.taxed_weight)),
          setValue('cte_transfer_cost', String(boardService.cte_transfer_cost)),
          setValue('departure_latitude', boardService.departure_latitude),
          setValue('departure_longitude', boardService.departure_longitude),
          setValue('departure_timestamp', boardService.departure_timestamp),
          setValue(
            'real_board_date',
            boardService.real_board_date
              ? formatDate.handle(
                boardService.real_board_date,
                'DateWithoutHourToInput',
              )
              : '',
          ),
          setValue(
            'real_board_hour',
            boardService.real_board_date
              ? formatDate.handle(
                boardService.real_board_date,
                'DateOnlyWithHourMinute',
              )
              : '',
          ),
          setValue('board_observation', boardService.board_observation),
          setValue('validate_observation', boardService.validate_observation),
          setValue('tracker_link', boardService.tracker_link),
          setValue('has_tracker_link', boardService.tracker_link ? 'yes' : 'no')
        )
      })
    } else {
      setValue('cte_photo_change', ' ')
    }
  }, [
    setValue,
    slug,
    collectorId,
    serviceFilteredById,
    boardServiceByIdFilteredByCollectorId,
    service_id,
  ])

  const sourceShippingHasBoardAutoFill = serviceById?.serviceIDRequested?.sourceBranchIDService?.shippingIDBranch?.has_board_autofill

  const sourceCrossdockingShippingHasBoardAutoFill = serviceById?.serviceIDRequested?.sourceCrossdockingBranchIDService?.shippingIDBranch?.has_board_autofill

  useEffect(() => {
    const crossdockingCollectorId = String(
      serviceFilteredById.map(
        (service) => service.serviceIDRequested.crossdocking_collector_id,
      ),
    )

    if (!crossdockingCollectorId) {
      if (sourceShippingHasBoardAutoFill) {
        setValue('cte_transfer_cost', String(serviceById?.serviceIDRequested?.sourceBranchIDService?.shippingIDBranch?.cte_transfer_cost_in_cents / 100))
        setValue('board_weight', String(serviceById?.serviceIDRequested?.sourceBranchIDService?.shippingIDBranch?.board_weight))
        setValue('taxed_weight', String(serviceById?.serviceIDRequested?.sourceBranchIDService?.shippingIDBranch?.taxed_weight))
        setValue('real_weight', String(serviceById?.serviceIDRequested?.sourceBranchIDService?.shippingIDBranch?.real_weight))
        setValue('is_loglife_payer', serviceById?.serviceIDRequested?.sourceBranchIDService?.shippingIDBranch?.is_loglife_payer ? 'SIM' : 'NÃO')
        setValue('is_cte_destination_branch_equal_to_planned', serviceById?.serviceIDRequested?.sourceBranchIDService?.shippingIDBranch?.is_cte_destination_branch_equal_to_planned ? 'SIM' : 'NÃO')
        setValue('has_tracker_link', 'no')
      }
    } else {
      const isCrossdockingBoard = !boardServiceFilteredById.find(board => board.collector_id === crossdockingCollectorId)
      if (!isCrossdockingBoard) {
        if (sourceCrossdockingShippingHasBoardAutoFill) {
          setValue('cte_transfer_cost', String(serviceById?.serviceIDRequested?.sourceCrossdockingBranchIDService?.shippingIDBranch?.cte_transfer_cost_in_cents / 100))
          setValue('board_weight', String(serviceById?.serviceIDRequested?.sourceCrossdockingBranchIDService?.shippingIDBranch?.board_weight))
          setValue('taxed_weight', String(serviceById?.serviceIDRequested?.sourceCrossdockingBranchIDService?.shippingIDBranch?.taxed_weight))
          setValue('real_weight', String(serviceById?.serviceIDRequested?.sourceCrossdockingBranchIDService?.shippingIDBranch?.real_weight))
          setValue('is_loglife_payer', serviceById?.serviceIDRequested?.sourceCrossdockingBranchIDService?.shippingIDBranch?.is_loglife_payer ? 'SIM' : 'NÃO')
          setValue('is_cte_destination_branch_equal_to_planned', serviceById?.serviceIDRequested?.sourceCrossdockingBranchIDService?.shippingIDBranch?.is_cte_destination_branch_equal_to_planned ? 'SIM' : 'NÃO')
          setValue('has_tracker_link', 'no')
        }
      } else {
        if (sourceShippingHasBoardAutoFill) {
          setValue('cte_transfer_cost', String(serviceById?.serviceIDRequested?.sourceBranchIDService?.shippingIDBranch?.cte_transfer_cost_in_cents / 100))
          setValue('board_weight', String(serviceById?.serviceIDRequested?.sourceBranchIDService?.shippingIDBranch?.board_weight))
          setValue('taxed_weight', String(serviceById?.serviceIDRequested?.sourceBranchIDService?.shippingIDBranch?.taxed_weight))
          setValue('real_weight', String(serviceById?.serviceIDRequested?.sourceBranchIDService?.shippingIDBranch?.real_weight))
          setValue('is_loglife_payer', serviceById?.serviceIDRequested?.sourceBranchIDService?.shippingIDBranch?.is_loglife_payer ? 'SIM' : 'NÃO')
          setValue('is_cte_destination_branch_equal_to_planned', serviceById?.serviceIDRequested?.sourceBranchIDService?.shippingIDBranch?.is_cte_destination_branch_equal_to_planned ? 'SIM' : 'NÃO')
          setValue('has_tracker_link', 'no')
        }
      }
    }

  }, [boardServiceFilteredById, serviceById, serviceFilteredById, setValue, sourceCrossdockingShippingHasBoardAutoFill, sourceShippingHasBoardAutoFill])

  useEffect(() => {
    if (serviceById) {
      const crossdockingCollectorId = serviceById.serviceIDRequested.crossdocking_collector_id


      if (state?.collector_id) {
        return setValue('collector_id', state?.collector_id)
      }

      if (!crossdockingCollectorId) {
        setValue('collector_id', serviceById.serviceIDRequested.source_collector_id)
      } else {
        const isCrossdockingBoard = !serviceById.serviceIDLanding.find(board => board.collector_id === crossdockingCollectorId)

        if (isCrossdockingBoard) {
          setValue('collector_id', crossdockingCollectorId)

        } else {
          setValue('collector_id', serviceById.serviceIDRequested.source_collector_id)

        }
      }
    }
  }, [boardServiceFilteredById, state?.collector_id, setValue, serviceById])

  useEffect(() => {
    if (budgets) {
      const budgetId = serviceFilteredById.map(
        (service) => service.serviceIDRequested.budget_id,
      )[0]
      const budgetFilteredById = budgets.find(
        (budget) => budget.id === budgetId,
      )

      if (budgetFilteredById) {
        setValue('franchising', String(budgetFilteredById.franchising))
        setValue('extra_kg_price', String(budgetFilteredById.price_kg_extra))
        if (transferBudgetNewValue !== '') {
          setValue('transfer_budget', transferBudgetNewValue)
          return
        }
        setValue('transfer_budget', String(budgetFilteredById.transfer_budget))
      }
    }
  }, [serviceFilteredById, transferBudgetNewValue, budgets, setValue])

  useEffect(() => {
    if (taxedWeightValue && isCalculatingTranferBudget) {
      if (
        transformStringToNumber(taxedWeightValue) >
        transformStringToNumber(franchising)
      ) {
        const transformedFranchising = transformStringToNumber(franchising)
        const transformedTransferBudget =
          transformStringToNumber(transferBudget)
        const transformedTaxedWeight = transformStringToNumber(taxedWeightValue)
        const transformedExtraKgPrice = transformStringToNumber(extraKgPrice)
        const budget =
          transformedTransferBudget +
          (transformedTaxedWeight - transformedFranchising) *
          (transformedExtraKgPrice * 0.8275)
        setTransferBudgetNewValue(String(budget.toFixed(2)))
        setIsCalculatingTranferBudget(false)
      } else {
        setTransferBudgetNewValue('')
        setIsCalculatingTranferBudget(false)
      }
    }
  }, [
    taxedWeightValue,
    isCalculatingTranferBudget,
    franchising,
    transferBudget,
    extraKgPrice,
    setValue,
  ])

  useEffect(() => {
    const driverBySelectedService = String(
      boardServiceByIdFilteredByCollectorId.map((service) => service.driver_id),
    )

    if (slug) {
      const driverSelected = drivers?.filter(
        (driver) => driver.collector_id === collectorId,
      )
      const driver = String(
        driverSelected
          ?.filter((driver) => driver.id === driverBySelectedService)
          .map((driver) => driver.id),
      )
      if (isDriverSelected === '') {
        setValue('driver_id', driver)
      }
      return
    }

    const driverFiltered = String(
      drivers
        ?.filter((driver) => driver.id === driverBySelectedService)
        .map((driver) => driver.id),
    )
    setValue('driver_id', driverFiltered)
  }, [
    drivers,
    isDriverSelected,
    setValue,
    slug,
    boardServiceByIdFilteredByCollectorId,
    collectorId,
  ])

  const driversCollectorId = slug
    ? serviceById?.serviceIDRequested.crossdocking_collector_id
      ? state?.collector_id === serviceById?.serviceIDRequested.crossdocking_collector_id
        ? serviceById?.serviceIDRequested.source_collector_id
        : serviceById?.serviceIDRequested.crossdocking_collector_id
      : !!serviceById?.serviceIDLanding.find(landing => landing.collector_id === serviceById?.serviceIDRequested.crossdocking_collector_id)
        ? serviceById?.serviceIDRequested.crossdocking_collector_id
        : serviceById?.serviceIDRequested.source_collector_id
    : serviceById?.serviceIDRequested.source_collector_id

  const sourceDrivers = drivers?.filter(
    (driver) => driver.collector_id === driversCollectorId
  )


  useEffect(() => {
    if (userLogged) {
      if (userLogged.user_type === 'MOTORISTA') {
        setValue('driver_id', userLogged?.driver_id)
      }
    }
  }, [collectorId, setValue, userLogged])

  useEffect(() => {
    function run() {
      if (branches) {
        const sourceBranch = branches.filter((branch) => branch.id === branchID)
        setSourceBranches(sourceBranch)
      }
    }

    run()
  }, [setValue, branches, branchID])

  async function handleSetXmlValues() {
    if (previewXmlFile) {
      const { data } = await axios.get(previewXmlFile, {
        headers: {
          'Content-Type': 'application/xml; charset=utf-8',
        },
      })

      const parser = new XMLParser()
      const jsonXml: IXmlProps = parser.parse(data)

      let serviceModal = null

      if (serviceById?.serviceIDRequested.crossdocking_collector_id) {
        if (serviceById?.serviceIDLanding.find(landing => landing.collector_id === serviceById?.serviceIDRequested.crossdocking_collector_id)?.step === "DONE") {
          serviceModal = serviceById.serviceIDRequested.modal
        } else {
          serviceModal = serviceById?.serviceIDRequested.crossdocking_modal
        }
      } else {
        serviceModal = serviceById?.serviceIDRequested.modal
      }

      const infoCargo = jsonXml.cteProc.CTe.infCte.infCTeNorm.infCarga.infQ
      const infoOperationalNumberAir =
        jsonXml.cteProc.CTe.infCte.infCTeNorm.infModal.aereo?.nOCA
      const infoOperationalNumberHighway =
        jsonXml.cteProc.CTe.infCte.infCTeNorm.infModal.rodoviario?.nOCA
      const infoCteNumber = jsonXml.cteProc.CTe.infCte.ide.nCT
      const infoTotalValue = jsonXml.cteProc.CTe.infCte.vPrest.vTPrest
      const realBoardDate = jsonXml.cteProc.CTe.infCte.ide.dhEmi

      if (isCrossdockingBoard) {
        if (serviceById.serviceIDRequested.crossdocking_modal === 'AÉREO') {
          const sourceBranch = jsonXml.cteProc.CTe.infCte.compl.fluxo?.xOrig
          const destinationBranch = jsonXml.cteProc.CTe.infCte.compl.fluxo?.xDest

          setValue('sourceBranch', sourceBranch)
          setValue('destinationBranch', destinationBranch)
        }
      } else {
        if (serviceById.serviceIDRequested.modal === 'AÉREO') {
          const sourceBranch = jsonXml.cteProc.CTe.infCte.compl.fluxo?.xOrig
          const destinationBranch = jsonXml.cteProc.CTe.infCte.compl.fluxo?.xDest

          setValue('sourceBranch', sourceBranch)
          setValue('destinationBranch', destinationBranch)
        }
      }


      setValue(
        'real_board_date',
        formatDate.handle(realBoardDate, 'DateWithoutHourToInput'),
      )
      setValue(
        'real_board_hour',
        formatDate.handle(realBoardDate, 'DateOnlyWithHourMinute'),
      )
      setValue('cte', String(infoCteNumber))
      setValue('cte_transfer_cost', String(infoTotalValue))

      if (serviceModal === 'RODOVIÁRIO' && infoOperationalNumberHighway) {
        setValue('operational_number', String(infoOperationalNumberHighway))
      }

      if (serviceModal === 'AÉREO' && infoOperationalNumberAir) {
        setValue('operational_number', String(infoOperationalNumberAir))
      }

      let taxedWeight = 0;
      let realWeight = 0;
      let boardWeight = 0;
      let boardVolume = 0;

      infoCargo.forEach((cargo) => {

        if (switchTotalWeight(cargo.tpMed)) {
          boardWeight = cargo.qCarga;
        }
        if (switchRealWeight(cargo.tpMed)) {
          realWeight = cargo.qCarga;
        }
        if (switchTaxedWeight(cargo.tpMed)) {
          taxedWeight = cargo.qCarga;
        }
        if (switchVolumes(cargo.tpMed)) {
          boardVolume = cargo.qCarga;
        }

      })

      if (realWeight === 0) {
        realWeight = taxedWeight;
      }

      let totalWeight = Math.max(taxedWeight, realWeight, boardWeight);

      setValue('taxed_weight', String(totalWeight))
      setValue('real_weight', String(realWeight))
      setValue('board_weight', String(totalWeight))
      setValue('volume_quantity', boardVolume)
    }
  }

  function handleSelectFileCtePhoto(e: { target: HTMLInputElement }) {
    if (!e.target.files || e.target.files.length === 0) {
      setValue('cte_photo', null)
      setSelectedFileCtePhoto(undefined)
      return
    }

    // I've kept this example simple by using the first image instead of multiple
    setValue('cte_photo', e.target.files[0])
    setSelectedFileCtePhoto(e.target.files[0])
  }

  function handleSelectXmlFile(e: { target: HTMLInputElement }) {
    if (!e.target.files || e.target.files.length === 0) {
      setValue('xml_file', null)
      setSelectedXmlFile(undefined)
      return
    }

    // I've kept this example simple by using the first image instead of multiple
    setValue('xml_file', e.target.files[0])
    setSelectedXmlFile(e.target.files[0])
  }

  async function handleValidateBoardService(values: FormInputProps) {
    let serviceModal = ''

    if (serviceById?.serviceIDRequested.crossdocking_collector_id) {
      if (serviceById?.serviceIDLanding.find(landing => landing.collector_id === serviceById?.serviceIDRequested.crossdocking_collector_id)?.step === "DONE") {
        serviceModal = serviceById.serviceIDRequested.modal!
      } else {
        serviceModal = serviceById?.serviceIDRequested.crossdocking_modal!
      }
    } else {
      serviceModal = serviceById?.serviceIDRequested.modal!
    }

    const hasDivergenceBetweenWeights = transformStringToNumber(values.real_weight) / 2 >= transformStringToNumber(values.taxed_weight) ||
      transformStringToNumber(values.taxed_weight) / 2 >= transformStringToNumber(values.real_weight)

    let hasConfirmDivergenceBetweenWeights = true

    if (hasDivergenceBetweenWeights) {
      hasConfirmDivergenceBetweenWeights = await confirmMessage({
        title: 'Os pesos real e taxado tem uma diferença maior ou igual a 100%.',
      })
    }

    if (hasConfirmDivergenceBetweenWeights) {
      const hasValidateBoardService = await confirmMessage({
        title: 'Deseja validar um embarque?',
      })

      if (hasValidateBoardService) {
        setIsLoading(true)
        try {
          const formatedData = boardValidateFormatDataToBackWithoutPhoto(values)
          if (Number(formatedData.cte) === 0) {
            setIsLoading(false)
            return standardMessage('O cte não pode ser igual a 0!')
          }
          const validateBoardServiceReqObj = {
            serviceID: service_id,
            serviceModal,
            validateBoardServiceInput: formatedData as any,
          }
          const validateBoardServiceReponse = await validateBoardService({
            ...validateBoardServiceReqObj,
          })
          if (validateBoardServiceReponse && userLogged) {
            if (hasPathRedirect) {
              history.push(resState?.path)
            } else {
              history.push('/servicos/embarques/validar')
            }
          }
        } catch (err) {
          setIsLoading(false)
        }
      } else {
        standardMessage('Ação cancelada com êxito!')
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const handleUpdateBoardService = async (values: FormInputProps) => {
    if (changeCtePhoto === 'NÃO') {
      setValue('cte_photo', {})
    }

    const hasEditCurrentBoardService = await confirmMessage({
      title: 'Deseja atualizar o embarque?',
    })

    if (hasEditCurrentBoardService) {
      setIsLoading(true)
      try {
        const formData = boardValidateFormatDataToBack(values)
        const updateBoardServiceReqObj = {
          serviceID: service_id,
          updateBoardServiceInput: formData as any,
        }
        const editCurrentResponse = await updateBoardService({
          ...updateBoardServiceReqObj,
        })
        if (editCurrentResponse && userLogged) {
          history.push(`/servico/editar/${values.service_id}`)
        }
      } catch (err) {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const inputRefCtePhoto = useRef<HTMLInputElement | null>(null)
  const handleInputRefCtePhoto = () => inputRefCtePhoto.current?.click()
  const inputRefXmlFile = useRef<HTMLInputElement | null>(null)
  const handleInputRefXmlFile = () => inputRefXmlFile.current?.click()

  if (isLoading || isLoadingPage) {
    return <GeneralContentLoading />
  }

  return (

    <Flex>
      <Box
        as="form"
        flex="1"
        borderRadius="8px"
        bg="white"
        p={['6', '8']}
        onSubmit={handleSubmit(
          slug ? handleUpdateBoardService : handleValidateBoardService,
        )}
        noValidate
      >
        <Flex
          align="center"
          mb="4"
          justify="space-between"
          direction={['column', 'column', 'row']}
        >
          <Heading size="lg" fontFamily="poppins">
            Validar Embarque
          </Heading>
          <Link
            to={{
              pathname: '/ocorrencia/criar',
              state: {
                service_id,
              },
            }}
          >
            <Button
              colorScheme="gray"
              rightIcon={<Icon as={FiPlus} />}
              size="sm"
              mt={['2', '2', '0']}
            >
              Adicionar Ocorrência
            </Button>
          </Link>
        </Flex>

        <BoardValidateAccordion
          sourceBranchID={branchID}
          destinationBranchID={destinationBranchID}
          boardServiceFilteredById={boardServiceFilteredById}
          requestedServiceFilteredById={requestedServiceFilteredById}
          serviceProtocol={serviceProtocol || 0}
          branches={branches}
        />

        <Alert status="info">
          <AlertIcon />
          Informações do serviço!
        </Alert>

        <Divider my="6" borderColor="gray.700" />
        <Stack spacing="24px" direction="row">
          <Input
            {...register('xml_file')}
            name="xml_file"
            label="Xml do Embarque"
            type="file"
            hidden
            accept="text/xml"
            ref={inputRefXmlFile}
            onChange={handleSelectXmlFile}
          >
            <Flex w="full" align="center" justify="flex-start">
              <Flex align="center">
                <Button
                  mr="2"
                  onClick={handleInputRefXmlFile}
                  leftIcon={<Icon as={FiFile} />}
                >
                  Upload Xml
                </Button>
                {!!selectedXmlFile && <Text>{selectedXmlFile.name}</Text>}
              </Flex>
            </Flex>
          </Input>
        </Stack>

        <Button
          mt="4"
          colorScheme="facebook"
          w="full"
          onClick={handleSetXmlValues}
        >
          Converter Xml
        </Button>

        {!!slug && (
          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            {sourceBranches?.map((branch) => (
              <Input
                key={branch.id}
                defaultValue={branch.nickname.toUpperCase()}
                isDisabled={true}
                name="firstname"
                label="Base de Origem"
              />
            ))}

            <Select
              {...register('driver_id')}
              name="driver_id"
              label="Motorista Responsável"
              error={errors.driver_id}
              drivers={sourceDrivers}
              isDisabled={isDisabled}
              placeholder="Selecione uma opção..."
              required
            />
          </Stack>
        )}

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Stack
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('board_volume')}
              addChildren="UN"
              isDisabled={isDisabled}
              name="board_volume"
              type="number"
              error={errors.board_volume}
              label="Volume Embarcado"
              required
            />
            <Input
              {...register('volume_quantity')}
              addChildren="UN"
              isDisabled={true}
              name="volume_quantity"
              type="number"
              error={errors.volume_quantity}
              label="Quantidade de Volumes"
              required
            />

          </Stack>
          <Stack
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('board_weight')}
              addChildren="KG"
              name="board_weight"
              error={errors.board_weight}
              onChange={onChange}
              defaultValue={currencyFormat('000')}
              label="Peso Total do CTE (Sem rateio)"
              required
              isDisabled={editFinishedService || sourceShippingHasBoardAutoFill || sourceCrossdockingShippingHasBoardAutoFill}
            />
            <Input
              {...register('cte')}
              name="cte"
              label="CTE"
              error={errors.cte}
              isDisabled={isDisabled}
              required
            />
          </Stack>


        </Stack>
        {!boardVolumeIsEqualsVolumeQuantity && volumeQuantity && (
          <Alert status='error' my='4'>
            <AlertIcon />
            <AlertTitle>Validação bloqueada!</AlertTitle>
            <AlertDescription>A quantidade de volumes embarcada e a quantidade de volumes na emissão estão divergentes</AlertDescription>
          </Alert>
        )}
        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Stack
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('cte_transfer_cost')}
              name="cte_transfer_cost"
              addChildren="R$"
              label="Valor CTE Transferência"
              error={errors.cte_transfer_cost}
              onChange={onChange}
              defaultValue={currencyFormat('000')}
              isDisabled={isDisabled || sourceShippingHasBoardAutoFill || sourceCrossdockingShippingHasBoardAutoFill}
              required
            />
          </Stack>


          <Stack
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('transfer_budget')}
              name="transfer_budget"
              label="Budget Transferência"
              onChange={onChange}
              defaultValue={currencyFormat('000')}
              isDisabled={true}
              required
            />

            <Input
              {...register('operational_number')}
              name="operational_number"
              label="Rastreador"
              error={errors.operational_number}
              isDisabled={isDisabled}
              required
            />
          </Stack>
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Input
            {...register('real_weight')}
            name="real_weight"
            label="Peso Bruto para Cobrança (Rateado)"
            error={errors.real_weight}
            addChildren="KG"
            onChange={onChange}
            defaultValue={currencyFormat('000')}
            isDisabled={editFinishedService || sourceShippingHasBoardAutoFill || sourceCrossdockingShippingHasBoardAutoFill}
            required
          />

          <Stack
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('taxed_weight')}
              name="taxed_weight"
              label="Peso Taxado para Cobrança (Rateado)"
              addChildren="KG"
              error={errors.taxed_weight}
              onChange={(e) => {
                onChange(e)
                setTaxedWeightValue(e.target.value)
                setIsCalculatingTranferBudget(true)
              }}
              defaultValue={currencyFormat('000')}
              isDisabled={editFinishedService || sourceShippingHasBoardAutoFill || sourceCrossdockingShippingHasBoardAutoFill}
              required
            />

            <Input
              {...register('franchising')}
              name="franchising"
              label="Franquia"
              onChange={onChange}
              defaultValue={currencyFormat('000')}
              isDisabled={true}
              required
            />
          </Stack>
        </Stack>

        <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
          <Input
            {...register('real_board_date')}
            name="real_board_date"
            label="Data Real Embarque"
            type="date"
            error={errors.real_board_date}
            isDisabled={isDisabled}
            required
          />
          <Input
            {...register('real_board_hour')}
            name="real_board_hour"
            label="Horário Real Embarque"
            type="time"
            error={errors.real_board_hour}
            isDisabled={isDisabled}
            required
          />
        </Stack>

        {!!slug && (
          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Select
              {...register('cte_photo_change')}
              negative_positive={negative_positive}
              placeholder="Selecione uma opção..."
              name="cte_photo_change"
              label="Alterar Foto do CTE?"
              error={errors.cte_photo_change}
              isDisabled={isDisabled}
              required
            />
          </Stack>
        )}
        {boardServiceByIdFilteredByCollectorId.map((boardService) => (
          <Stack
            key={boardService.id}
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Input name="link_cte_photo" hidden>
              <ChakraLink
                fontSize="lg"
                href={handleChangeUrl(boardService.cte_photo as string)}
                isExternal
              >
                Foto do CTE <Icon as={FiExternalLink} mx="2px" />
              </ChakraLink>
            </Input>
            {boardService.box_photo && (
              <Input name="link_cte_photo" hidden>
                <ChakraLink
                  fontSize="lg"
                  href={handleChangeUrl(boardService.box_photo as string)}
                  isExternal
                >
                  Foto das caixas <Icon as={FiExternalLink} mx="2px" />
                </ChakraLink>
              </Input>
            )}
          </Stack>
        ))}
        {!!slug && (
          <>

            {changeCtePhoto === 'SIM' && (
              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Input
                  {...register('cte_photo')}
                  name="cte_photo"
                  label="Foto do CTE"
                  type="file"
                  hidden
                  accept="image/*"
                  error={errors.cte_photo as FieldError}
                  ref={inputRefCtePhoto}
                  onChange={handleSelectFileCtePhoto}
                  required
                >
                  <Button
                    onClick={handleInputRefCtePhoto}
                    leftIcon={<Icon as={FiFile} />}
                  >
                    Upload
                  </Button>
                </Input>
                {!!previewCtePhoto && (
                  <Image
                    boxSize="100px"
                    objectFit="cover"
                    src={previewCtePhoto}
                  />
                )}
              </Stack>
            )}
          </>
        )}

        {(!slug && serviceById?.serviceIDRequested?.budgetIDService?.priority_budget) && (
          <Stack mt="4" direction={['column', 'column', 'row']} spacing="24px">
            <Radio
              {...register('open_ticket_email')}
              name='open_ticket_email'
              radioOptions={positiveNegativeOptions}
              label='Abriu ticket/email?'
              error={errors.open_ticket_email}
              required
              isDisabled={isDisabled}
            />
          </Stack>
        )}
        <Stack mt="4" direction={['column', 'column', 'row']} spacing="24px">
          <Radio
            {...register('has_tracker_link')}
            name='has_tracker_link'
            radioOptions={positiveNegativeOptions}
            defaultCheckedOption={isServiceRoad && !isShippingWithTrackerLinkDefault ? 'no' : 'yes'}
            label='Possui Link de Rastreio?'
            error={errors.has_tracker_link}
            required
            isDisabled={!slug && isShippingWithTrackerLinkDefault ? true : isDisabled}
          />
          {hasTrackerLink && (
            <Input
              {...register('tracker_link')}
              name="tracker_link"
              label="Link de Rastreio"
              isDisabled={!slug && isShippingWithTrackerLinkDefault ? true : isDisabled}
              required
              error={errors.tracker_link}
            />
          )}
        </Stack>
        {!slug && (
          <Stack mt="4" direction={['column', 'column', 'row']} spacing="24px">
            <Select
              {...register("is_loglife_payer")}
              name="is_loglife_payer"
              label="Tomador do serviço no CTE é Loglife?"
              negative_positive={negative_positive}
              error={errors.is_loglife_payer}
              placeholder="Selecione uma opção..."
              required
              isDisabled={isDisabled || sourceShippingHasBoardAutoFill || sourceCrossdockingShippingHasBoardAutoFill}
            />
            <Select
              {...register("is_cte_destination_branch_equal_to_planned")}
              name="is_cte_destination_branch_equal_to_planned"
              label="Base destino do CTE é igual ao planejado?"
              error={errors.is_cte_destination_branch_equal_to_planned}
              negative_positive={negative_positive}
              placeholder="Selecione uma opção..."
              required
              isDisabled={isDisabled || sourceShippingHasBoardAutoFill || sourceCrossdockingShippingHasBoardAutoFill}
            />

          </Stack>
        )}
        {sourceBranchXmlBoarding && destinationBranchXmlBoarding && (
          <Stack
            mt="4"
            direction={["column", "column", "row"]}
            spacing="24px"
          >
            <Input
              name="sourceBranch"
              label="Base de Origem"
              error={errors.sourceBranch}
              defaultValue={sourceBranchXmlBoarding}
              isDisabled
              required
            />
            <Input
              name="destinationBranch"
              label="Base de Destino"
              error={errors.destinationBranch}
              defaultValue={destinationBranchXmlBoarding}
              isDisabled
              required
            />
          </Stack>

        )}
        {sourceBranchXmlBoarding && destinationBranchXmlBoarding && !isValidateBoardingEnabled && (
          <Alert status='error' my='4'>
            <AlertIcon />
            <AlertTitle>Validação bloqueada!</AlertTitle>
            <AlertDescription>O planejamento de bases diverge com as informações passadas no xml.</AlertDescription>
          </Alert>


        )}

        <TextArea
          {...register('validate_observation')}
          name="validate_observation"
          label={slug ? 'Obervações da Validação do Embarque' : 'Observações'}
          isDisabled={isDisabled}
        />

        {!!slug && (
          <TextArea
            {...register('board_observation')}
            name="board_observation"
            label="Observações do Embarque"
            isDisabled={isDisabled}
          />
        )}

        <Flex mt="8" justify="flex-end">
          <HStack>
            <Button
              type="submit"
              colorScheme="blue"
              isLoading={isSubmitting}
              loadingText="Carregando"
              disabled={!isValidateBoardingEnabled}
              isDisabled={!isValidateBoardingEnabled || !enableBoardValidation}
            >
              {slug ? 'Atualizar Embarque' : 'Validar Embarque'}
            </Button>

            <Link to="/servicos/embarques/validar">
              <Button type="button" colorScheme="gray">
                Lista de Serviços à Validar
              </Button>
            </Link>
          </HStack>
        </Flex>
      </Box>
    </Flex>

  )
}
