import { createContext, ReactNode } from 'react'
import { api } from '../services/api'

import swal from 'sweetalert'

import 'react-toastify/dist/ReactToastify.css'

interface ClientsProviderProps {
  children: ReactNode
  pathname?: string
}

export interface LogProps {
  id: string
  user_id: string
  userIDLogs: {
    id: string
    loglife_employee: boolean
    permissions: string[]
    user_type: string
    email: string
    password: string
    firstname: string
    lastname: string
    situation: string
    createdAt: string
    updatedAt: string
    collector_id: string | null
    customer_id: string | null
    driver_id: string | null
  }
  action: string
  updated_at: string
  updatedAt: string
  createdAt: string
}

type InputLogProps = Omit<LogProps, 'id' | 'userIDLogs' | 'updated_at' | 'updatedAt' |'createdAt'>

interface LogContextProps {
  createLog: (inputLog: InputLogProps) => Promise<boolean>
}

export const LogContext = createContext<LogContextProps>({} as LogContextProps)

export function LogProvider({ children, pathname }: ClientsProviderProps) {
  async function createLog(inputLog: InputLogProps) {
    try {
      await api.post('/logs', inputLog)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  return (
    <LogContext.Provider value={{ createLog }}>{children}</LogContext.Provider>
  )
}
