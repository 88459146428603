import { differenceInMinutes } from "date-fns";
import { setDate } from "../DateFunctions/setDate";

export function sortByDate(currentDate: Date | string, nextDate: Date | string) {
  const currentDateFormatted = setDate.handleWithDefaultHourWithoutFormat(currentDate)
  const nextDateFormatted = setDate.handleWithDefaultHourWithoutFormat(nextDate)

  const difference = differenceInMinutes(
    currentDateFormatted,
    nextDateFormatted
  )

  if (difference > 0) {
    return 1
  }

  if (difference < 0) {
    return -1
  }

  return 0
}
