import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, Icon, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { EmptyContentTableAlert } from "components/Alerts/EmptyContentTableAlert";
import { TableFilterButton } from "components/Filters/TableFilterButton";
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";
import { Pagination } from "components/Pagination/Pagination";
import { SearchBox } from "components/SearchBox/SearchBox";

import { useAuth } from "hooks/auth/useAuth";
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions";
import { useServicesWithNewRequestedAddresses } from "hooks/services/useServicesWithNewRequestedAddresses";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { FiCheckCircle } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { formatDate } from "utils/DateFunctions/formatDate";


interface FilterOptionSearchInputs {
  query: string;
}

const servicesWithNewAddressesList = [
  { key: 'query', value: 'Busca Geral', checked: false },
]

export function ServicesWithNewAddressesList() {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE
  const {
    filterOptions,
    onLoadSetFilterOptions,
  } = useFilterOptions()

  useEffect(() => {
    onLoadSetFilterOptions(servicesWithNewAddressesList)
  }, [onLoadSetFilterOptions])


  const { userLogged } = useAuth()
  const { push: redirectTo, location } = useHistory()

  const userHasPermissionToValidateNewAddressesToCollect =
    userLogged?.permissions.includes("validate-new-collect-service-addresses")

  const userHasPermissionToValidateNewAddressesToDelivery =
  !userLogged?.permissions.includes("validate-new-delivery-service-addresses")

  useEffect(() => {
    if (!userHasPermissionToValidateNewAddressesToCollect && !userHasPermissionToValidateNewAddressesToDelivery) {
      redirectTo("/")
    }
  }, [redirectTo, userHasPermissionToValidateNewAddressesToCollect, userHasPermissionToValidateNewAddressesToDelivery])

  const currentPageFilter = location.search.match(`[?&]page=([^&]+)`)
  const queryPageFilter = location.search.match(`[?&]query=([^&]+)`)


  const { register, control } = useForm<FilterOptionSearchInputs>()

  const querySearched = useWatch({
    control,
    name: ['query']
  })

  const {
    data: servicesWithNewAddressesRequestedData,
    isFetching: isFetchingServicesWithNewAddressesRequestedData } =
    useServicesWithNewRequestedAddresses({
      queryParams: {
        query: queryPageFilter ? String(queryPageFilter[1]) : null,
        pageSize: Number(itemLimit),
        currentPage: currentPageFilter ? Number(currentPageFilter[1]) : 1,
      },
    })

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: servicesWithNewAddressesRequestedData?.services?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: currentPageFilter ? Number(currentPageFilter[1]) : 1,
      },
    })

  useEffect(() => {
    if (userHasPermissionToValidateNewAddressesToCollect) {

      const queryParams = new URLSearchParams(location.search);
      if (currentPage) queryParams.set('page', String(currentPage))
      if (querySearched) queryParams.set('query', String(querySearched))


      redirectTo({
        pathname: '/servicos/novos-enderecos',
        search: queryParams.toString()
      })
    }
  }, [currentPage, location.search, querySearched, redirectTo, userHasPermissionToValidateNewAddressesToCollect])



  if (isFetchingServicesWithNewAddressesRequestedData) {
    return <GeneralContentLoading />
  }

  return (

      <Box
        bg="white"
        padding={[2, 4]}
        borderRadius="8px"
        flex="1"
      >
        <Flex
          gap={2}
          direction="column"
        >
          <Heading
            size="lg"
            fontFamily="poppins"
            textAlign={["center", "center", "justify"]}
          >
            Serviços com novos endereços para validação
          </Heading>
          {!servicesWithNewAddressesRequestedData.services?.length ? (
            <EmptyContentTableAlert
              title="Oops!"
              description="Não há serviços com endereços pendentes para validação!"
            />
          ) : (
            <>
              <Flex
                justify="space-between"
                direction={["column", "column", "row"]}
                w="full"
                gap={2}
              >
                <Flex>
                  <TableFilterButton />
                </Flex>

                <Flex direction="column" gap="4">
                  {filterOptions
                    .filter(option => option.checked === true)
                    .map((option) => {
                      return (
                        <SearchBox
                          {...register(option.key as keyof FilterOptionSearchInputs)}
                          key={option.key}
                          size="sm"
                          placeholder={'Buscar '}
                          handleSearch={() => { }}
                        />
                      )
                    })}
                </Flex>
              </Flex>
              <TableContainer w="full">
                <Table w="full" size="sm" variant="striped">
                  <Thead>
                    <Tr>
                      <Th></Th>
                      <Th>PROTOCOLO</Th>
                      <Th>CLIENTE</Th>
                      <Th>DATA DA COLETA</Th>
                      <Th>COLETADOR</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {servicesWithNewAddressesRequestedData.services
                      ?.slice(offset, offset + Number(itemLimit))
                      ?.map(service => {
                        return (
                          <Tr key={service.id}>
                            <Td>
                              <Link to={`/servico/${service.id}/validar-novos-enderecos`}>
                                <Icon
                                  cursor="pointer"
                                  as={FiCheckCircle}
                                  fontSize="20"
                                  mr="2"
                                  mt={['2', '2', '0']}
                                />
                              </Link>
                            </Td>
                            <Td>{service.protocol}</Td>
                            <Td>{service.customerIDService?.trading_firstname}</Td>
                            <Td>{formatDate.handle(service.serviceIDRequested.collect_date, "DateWithoutHourToShow")}</Td>
                            <Td>{service.serviceIDRequested?.sourceCollectorIDService?.trading_name}</Td>
                          </Tr>
                        )
                      })}
                  </Tbody>
                </Table>
              </TableContainer>
            </>
          )}
        </Flex>

        <Pagination
          currentPage={currentPage}
          pagesQuantity={pagesCount}
          pages={pages}
          handlePageChange={setCurrentPage}
        />
      </Box>

  )
}
