import { ModalBody, ModalCloseButton, ModalContent, ModalHeader, Table, Tbody, Td, Tr } from "@chakra-ui/react"
import { ExtraDiscount, ExtraDiscountStatus } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts";
import { captalize } from "utils/captalize";
import { CollectorExtraDiscountStatus } from "./CollectorExtraDiscountStatus";


interface CollectorExtraDiscountDetailProps {
  extraDiscount: ExtraDiscount
}

export function getStatus({
  approved_at,
  effective_at,
  rejected_at,
}: { approved_at?: string | null; effective_at?: string | null; rejected_at?: string | null }): ExtraDiscountStatus {
  return rejected_at ? "rejected" :
    effective_at ? "effectivated" :
      approved_at ? "approved" :
        "requested";
}

export function CollectorExtraDiscountDetails({
  extraDiscount,
}: CollectorExtraDiscountDetailProps) {

  const [serviceYear, serviceMonth, serviceDay] = extraDiscount.service_date.split('-')

  const status = getStatus({
    approved_at: extraDiscount?.approved_at,
    effective_at: extraDiscount?.effective_at,
    rejected_at: extraDiscount?.rejected_at
  })

  return (
    <ModalContent maxW='750px'>
      <ModalHeader>
        Detalhes do Extra/Desconto
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Table size="sm">
          <Tbody>

            <Tr>
              <Td>Status</Td>
              <Td display="flex" justifyContent="flex-end">
                <CollectorExtraDiscountStatus status={status} />
              </Td>
            </Tr>
            <Tr>
              <Td>Coletador</Td>
              <Td isNumeric>
                {captalize(`${extraDiscount?.collector?.trading_name}`)}
              </Td>
            </Tr>
            <Tr>
              <Td>Tipo</Td>
              <Td isNumeric>
                {captalize(extraDiscount?.type)}
              </Td>
            </Tr>
            <Tr>
              <Td>Data do serviço</Td>
              <Td isNumeric>{`${serviceDay}/${serviceMonth}/${serviceYear}`}</Td>
            </Tr>
            <Tr>
              <Td>Valor</Td>
              <Td isNumeric>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(extraDiscount?.value / 100)}
              </Td>
            </Tr>
            {extraDiscount?.service_route && (
              <Tr>
                <Td>Rota</Td>
                <Td isNumeric>
                  {extraDiscount?.service_route}
                </Td>
              </Tr>

            )}
            {extraDiscount?.service_protocol && (
              <Tr>
                <Td>Protocolo(s)</Td>
                <Td isNumeric>
                  {extraDiscount?.services?.map((service) => service?.service?.protocol).join(', ')}
                </Td>

              </Tr>
            )}
            <Tr>
              <Td>Descrição</Td>
              <Td isNumeric>
                {extraDiscount?.description}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </ModalBody>

    </ModalContent>
  )
}
