import { api } from '../../../../services/api'

export type GenerateContentDeclarationPdfInput = {
  source_address_id: string
  destination_address_id: string
}

async function generateContentDeclarationPdfReqFunction(
  input: GenerateContentDeclarationPdfInput,
) {
  try {
    const { data } = await api.post('/contentDeclaration-pdf', input, {
      responseType: 'arraybuffer',
    })

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

export { generateContentDeclarationPdfReqFunction }
