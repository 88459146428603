import axios from "axios";
import { RequestError } from "utils/errors/RequestErrors";
import { ShippingCost } from "../shippings/_types/ShippingCost";

interface GetShippingsCostsReportProps {
  queryParams: {
    startDate: string
    finalDate: string
  }
}


export async function getShippingsCostsReport({
  queryParams
}: GetShippingsCostsReportProps): Promise<ShippingCost[]> {
  try {

    const userInfo = localStorage.getItem('userInfo')

    const response = await fetch(`${process.env.REACT_APP_API_BACKEND}/reports/shippings/costs?startDate=${queryParams.startDate}&finalDate=${queryParams.finalDate}`, {
      headers: {
        'Authorization': `${JSON.parse(userInfo).token}`
      }
    });

    if (!response.ok) {
      throw new RequestError('Erro ao inesperado ao gerar o relatório!');
    }

    // Verifica se a resposta está sendo entregue como stream
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let buffer = '';

    let teste = []

    while (true) {
      // Lê um chunk da stream
      const { done, value } = await reader.read();

      if (done) {
        break;
      }

      // Decodifica o chunk recebido
      buffer += decoder.decode(value, { stream: true });

      // Processa o chunk de dados como necessário
      let boundary = 0;
      while ((boundary = buffer.indexOf('\n')) !== -1) {
        const chunk = buffer.slice(0, boundary);  // Pega uma linha (um pedaço de JSON)
        buffer = buffer.slice(boundary + 1);  // Remove essa linha do buffer

        if (chunk.trim()) {
          try {
            const parsedChunk = JSON.parse(chunk);
            teste = teste.concat(JSON.parse(chunk))
            // Parse o pedaço de JSON
            console.log('Chunk JSON recebido:', parsedChunk);
          } catch (error) {
            console.error('Erro ao parsear o chunk:', error);
          }
        }
      }
    }


    return teste
  } catch (error) {

    console.error('Error', error)

    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

