import { createContext, ReactNode } from 'react'
import { api } from '../services/api'

import swal from 'sweetalert'

import 'react-toastify/dist/ReactToastify.css'
import { Collector } from 'hooks/collector/dtos/Collector'

interface ClientsProviderProps {
  children: ReactNode
  pathname?: string
}

export interface StockProps {
  id: string
  collector_id: string
  input_id: string
  minimum_stock: number
  standard_stock: number
  current_stock: number
  collectorIDStock: Collector
  inputIDStock: {
    name: string
  }
  observation: string
}

export interface DispatchStockProps {
  id: string
  collector_id: string
  destination_collector_id: string | null
  proposal_id: string | null
  shipping_id: string
  branch_id: string
  sender: string
  board_date: string
  arrival_forecast: string
  collectorIDDispatch: Collector
  destinationCollectorIDDispatch: Collector
  shippingIDDispatch: {
    id: string
    company_name: string
  }
  branchIDDispatch: {
    id: string
    nickname: string
  }
  dispachIDConfirmation: DispatchConfirmProps
  step: string
}

export interface DispatchMaterialProps {
  id: string
  dispatch_id: string
  input_id: string
  quantity: string
  dispatchIDMaterials: {
    id: string
  }
  inputIDMaterials: {
    id: string
    name: string
  }
}

export interface DispatchConfirmProps {
  id: string
  dispatch_id: string
  step: string
  dispatch_cost: number
  dispatch_cte: string
  dispatch_tracker: string
  dispatch_invoice: number
}

export interface DispatchConfirmRecipeProps {
  id: string
  arrival_date: string
  dispatch_id: string
}

type StockStock = Omit<
  StockProps,
  'id' | 'current_stock' | 'collectorIDStock' | 'inputIDStock'
>
type DispatchStockInputProps = Omit<
  DispatchStockProps,
  | 'id'
  | 'collectorIDDispatch'
  | 'destinationCollectorIDDispatch'
  | 'shippingIDDispatch'
  | 'branchIDDispatch'
  | 'dispachIDConfirmation'
  | 'step'
>
type DispatchMaterialInputProps = {
  materials: {
    dispatch_id: string
    input_id: string
    quantity: string
  }[]
}
type DispatchMaterialInputEditProps = Omit<
  DispatchMaterialProps,
  'id' | 'dispatchIDMaterials' | 'inputIDMaterials'
>
type DispatchMaterialValidateInputProps = Omit<
  DispatchConfirmProps,
  'id' | 'step'
>
type DispatchConfirmInputRecipeProps = Omit<
  DispatchConfirmRecipeProps,
  'id' | 'dispatch_id'
>

interface StockContextProps {
  createStock: (stockStock: StockStock) => Promise<boolean>
  deleteStock: (stockId: string) => Promise<boolean | undefined>
  editStock: (
    stockId: string,
    stockStock: StockStock,
  ) => Promise<boolean | undefined>
  createDispatchStock: (
    dispatchStockInput: DispatchStockInputProps,
  ) => Promise<boolean | undefined>
  deleteDispatchStock: (dispatchStockId: string) => Promise<boolean | undefined>
  editDispatchStock: (
    dispatchStockId: string,
    dispatchStockInput: DispatchStockInputProps,
  ) => Promise<boolean | undefined>
  sendDispatchStock: (dispatchStockId: string) => Promise<boolean | undefined>
  createDispatchMaterials: (
    dispatchMaterialInput: DispatchMaterialInputProps,
  ) => Promise<boolean | undefined>
  deleteDispatchMaterial: (
    dispatchMaterialId: string,
  ) => Promise<boolean | undefined>
  editDispatchMaterial: (
    dispatchMaterialId: string,
    dispatchMaterialInput: DispatchMaterialInputEditProps,
  ) => Promise<boolean | undefined>
  createDispatchStockValidate: (
    dispatchStockValidateInput: DispatchMaterialValidateInputProps,
  ) => Promise<boolean | undefined>
  editDispatchStockValidate: (
    dispatchStockId: string,
    dispatchStockConfirmRecipeInput: DispatchConfirmInputRecipeProps,
  ) => Promise<boolean | undefined>
}

export const StockContext = createContext<StockContextProps>(
  {} as StockContextProps,
)

export function StockProvider({ children, pathname }: ClientsProviderProps) {
  async function createStock(stockStock: StockStock) {
    try {
      await api.post('/stock', stockStock)
      setTimeout(() => {
        swal('Poof! O Estoque foi criado com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function deleteStock(stockId: string) {
    try {
      await api.delete(`/stock/${stockId}`)

      swal('Poof! O Estoque foi excluído com suceso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro!', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function editStock(stockId: string, stockStock: StockStock) {
    try {
      await api.put(`/stock/${stockId}`, stockStock)
      setTimeout(() => {
        swal('Poof! O Estoque foi atualizado com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function createDispatchStock(
    dispatchStockInput: DispatchStockInputProps,
  ) {
    try {
      await api.post('/dispatch-stock', dispatchStockInput)
      setTimeout(() => {
        swal('Poof! O Despacho de Estoque foi criado com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function deleteDispatchStock(dispatchStockId: string) {
    try {
      await api.delete(`/dispatch-stock/${dispatchStockId}`)

      swal('Poof! O Despacho de Estoque foi excluído com sucesso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro!', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function editDispatchStock(
    dispatchStockId: string,
    dispatchStockInput: DispatchStockInputProps,
  ) {
    try {
      await api.put(`/dispatch-stock/${dispatchStockId}`, dispatchStockInput)
      setTimeout(() => {
        swal('Poof! O Despacho de Estoque foi atualizado com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function sendDispatchStock(dispatchStockId: string) {
    try {
      await api.put(`/dispatch-stock/${dispatchStockId}`, {
        toDispatchConfirm: true,
      })
      setTimeout(() => {
        swal('Poof! O Estoque foi despachado com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function createDispatchMaterials(
    dispatchMaterialInput: DispatchMaterialInputProps,
  ) {
    try {
      await api.post('/dispatch-materials', dispatchMaterialInput)
      setTimeout(() => {
        swal('Poof! Os materiais foram adicionados com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function deleteDispatchMaterial(dispatchMaterialId: string) {
    try {
      await api.delete(`/dispatch-materials/${dispatchMaterialId}`)

      swal('Poof! O Material foi excluído com sucesso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro!', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function editDispatchMaterial(
    dispatchMaterialId: string,
    dispatchMaterialInput: DispatchMaterialInputEditProps,
  ) {
    try {
      await api.put(
        `/dispatch-materials/${dispatchMaterialId}`,
        dispatchMaterialInput,
      )
      swal('Poof! O Material foi atualizado com sucesso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function createDispatchStockValidate(
    dispatchStockValidateInput: DispatchMaterialValidateInputProps,
  ) {
    try {
      await api.post('/dispatch-confirmation', dispatchStockValidateInput)
      setTimeout(() => {
        swal('Poof! O Despacho foi confirmado com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function editDispatchStockValidate(
    dispatchStockId: string,
    dispatchStockConfirmRecipeInput: DispatchConfirmInputRecipeProps,
  ) {
    try {
      await api.put(
        `/dispatch-confirmation/${dispatchStockId}`,
        dispatchStockConfirmRecipeInput,
      )
      swal('Poof! O recebimento do despacho foi confirmado com sucesso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  return (
    <StockContext.Provider
      value={{
        createStock,
        deleteStock,
        editStock,
        createDispatchStock,
        deleteDispatchStock,
        editDispatchStock,
        createDispatchMaterials,
        deleteDispatchMaterial,
        editDispatchMaterial,
        sendDispatchStock,
        createDispatchStockValidate,
        editDispatchStockValidate,
      }}
    >
      {children}
    </StockContext.Provider>
  )
}
