import { ReactNode } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

interface PmcCardPurchaseOrderProps {
  title: string
  children: ReactNode
}

export function PmcCardPurchaseOrder({ title, children }: PmcCardPurchaseOrderProps) {
  return (
    <Box
      bgColor={'white'}
      maxH='400px'
      h='full'
      minW='300px'
      w='full'
      rounded="md"
      transition="2"
      cursor="pointer"
      boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
      overflow='auto'
    >
      <Flex
        bg="blue.300"
        justifyContent="center"
        borderRadius="8px 8px 0 0"
        p={1}
        position='sticky'
      >
        <Text fontSize="sm" fontWeight="bold" color="white" noOfLines={1}>
          {title}
        </Text>
      </Flex>

      {children}
    </Box>
  )
}
