import { Button, ButtonProps, Icon, useMediaQuery } from '@chakra-ui/react'
import { BaseSyntheticEvent } from 'react'
import { IconType } from 'react-icons'
import { FaSave } from 'react-icons/fa'

interface ISaveButtonProps extends ButtonProps {
  action: string
  isSubmitting: boolean
  icon?: IconType
  onClick?: (e?: BaseSyntheticEvent<object, any, any> | undefined) => void
}

export function SubmitButton({
  action,
  isSubmitting,
  icon,
  onClick,
  ...rest
}: ISaveButtonProps) {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  return (
    <Button
      type="submit"
      colorScheme="blue"
      isLoading={isSubmitting}
      loadingText={isWideVersion ? 'Carregando' : ''}
      onClick={onClick}
      {...rest}
    >
      {isWideVersion ? action : <Icon as={icon ?? FaSave} />}
    </Button>
  )
}
