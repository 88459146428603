import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  CheckboxGroup,
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckBoxProps,
  Text,
  Stack,
  Box,
  Flex,
} from '@chakra-ui/react'
import { SearchBox } from 'components/SearchBox/SearchBox'
import { forwardRef, ForwardRefRenderFunction, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { searchBoxFilter } from 'utils/searchBoxFilter'

interface CitySourceProps {
  id: string
  name: string
}

type CityDestinationProps = CitySourceProps

interface InputProps extends ChakraCheckBoxProps {
  slug?: string
  id?: string
  defaultSourceOption?: string[]
  defaultDestinationOption?: string[]
  citySource?: CitySourceProps[]
  cityDestination?: CityDestinationProps[]
  name: string
  label?: string
  required?: boolean
  error?: FieldError
}

const CheckboxBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    slug,
    citySource,
    cityDestination,
    id,
    defaultSourceOption,
    defaultDestinationOption,
    required = false,
    error = null,
    ...rest
  },
  ref,
) => {

  const [cityFiltered, setCityFiltered] = useState('')

  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <Flex flex="1" justify="space-between" >
          <FormLabel htmlFor={name} noOfLines={1}>
            <Flex h="full" w="full" gap="1" align="center">
              <Text fontSize="sm" color="gray.700" w="full">
                {label}
              </Text>
              {required && (
                <Text display="block" mt="2" color="red" fontWeight="bold" fontSize="sm" as="sup">
                  {' '}
                  *
                </Text>
              )}
            </Flex>
          </FormLabel>
        </Flex>
      )}

      <SearchBox
        isCheckbox={true}
        size="sm"
        placeholder="Buscar Cidade..."
        handleSearch={(e) =>
          setCityFiltered(e.target.value)
        }
      />
      <Box
        overflowY="auto"
        height="100px"
        borderWidth="1px"
        borderRadius="lg"
        p="2"
      >
        {!!citySource && (
          <Stack spacing="10px" justify="flex-start">
            {slug !== 'adicionar' && (
              <CheckboxGroup
                colorScheme="blue"
                defaultValue={defaultSourceOption}
              >
                <Stack direction="column">
                  {
                    citySource?.filter((city) => {
                      const cityFilter = searchBoxFilter(`${city.name}`, cityFiltered)
                      return cityFilter
                    })?.map((city) => (
                      <ChakraCheckbox
                        key={city.id}
                        name={name}
                        label={label}
                        value={city.id}
                        error={error}
                        ref={ref}
                        {...rest}
                      >
                        {city.name.toUpperCase()}
                      </ChakraCheckbox>
                    ))}
                </Stack>
              </CheckboxGroup>
            )}
          </Stack>
        )}
        {!!cityDestination && (
          <Stack spacing="10px" justify="flex-start">
            {slug !== 'adicionar' && (
              <CheckboxGroup
                colorScheme="blue"
                defaultValue={defaultDestinationOption}
              >
                <Stack direction="column">
                  {cityDestination?.filter((city) => {
                    const cityFilter = searchBoxFilter(`${city.name}`, cityFiltered)
                    return cityFilter
                  })?.map((city) => (
                    <ChakraCheckbox
                      key={city.id}
                      name={name}
                      label={label}
                      value={city.id}
                      error={error}
                      ref={ref}
                      {...rest}
                    >
                      {city.name.toUpperCase()}
                    </ChakraCheckbox>
                  ))}
                </Stack>
              </CheckboxGroup>
            )}
          </Stack>
        )}
      </Box>

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const CheckboxBudget = forwardRef(CheckboxBase)
