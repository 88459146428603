import { InvoiceStatus } from "api/invoices/_types/Invoice";


export const invoiceStatusMap: Record<InvoiceStatus, string> = {
  analyzing: 'Aguardando análise',
  "checking-invoice": 'Conferência de fatura',
  "operational-inconsistency": 'Inconsistência operacional',
  "collector-inconsistency": 'Inconsistência do coletador',
  "approved-invoice": 'Fatura aprovada',
  "checking-values": 'Conferência de valores',
  "checking-cp-value": 'Conferência de valor (Cp)',
  approved: 'Aprovado',
  repproved: 'Reprovado'
}
