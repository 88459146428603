import axios from "axios"
import { StockProps } from "contexts/StockContext"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetStocksUnderMinimumResponse {
  stocks: Array<StockProps & {
    inputIDStock: {
      name: string
      last_period_consume: number
    }
    hubs: string[]
  }>
}

interface GetStocksUnserMinimumProps {
  queryParams?: {
    collectorId?: string
  }
}

export async function getStocksUnderMinimum({
  queryParams
}: GetStocksUnserMinimumProps) {
  try {
    const { data } = await api.get<GetStocksUnderMinimumResponse>('/stocks/dashboard/under-minimum', {
      params: { ...queryParams }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
