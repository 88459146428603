import { Button, Flex, FormLabel, Icon, Image, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { useAuth } from "hooks/auth/useAuth";
import { useMaintenanceServiceFunctions } from "hooks/services/maintenance/useMaintenanceServiceFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect } from "react";
import { FieldError, useForm, useWatch } from "react-hook-form";
import { FiFile } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { maintenanceTypeOptions } from "utils/CustomLists/maintenanceTypeOptions";
import { supportedFileFormats, validateFileSize, validateFileType, validateHasFile } from "utils/imageValidation";
import * as yup from "yup";

interface StartMaintenanceFormInputs {
  maintenance_type: 'GELO SECO' | 'GELOX'
  maintenance_arrival_temperature: number
  maintenance_arrival_photo: File[]
}

interface StartMaintenanceFormProps {
  serviceId: string
}

const startMaintenanceFormSchema = yup.object().shape({
  maintenance_type: yup.string().required('Campo obrigatório'),
  maintenance_arrival_temperature: yup.string().matches(/^-?\d+(\.\d+)?$/, 'Apenas números (positivos ou negativos) com ponto (.) são permitidos').required('Campo obrigatório'),
  maintenance_arrival_photo: yup.mixed()
    .test("length", "Campo obrigatório", value => validateHasFile(value))
    .test("type", "São suportados apenas os formatos de imagem: .jpeg, .pjpeg, jpg, png", value => validateFileType(value))
    .test("fileSize", "É suportado o upload de imagem somente até 10Mb", value => validateFileSize(value)),
})

export function StartMaintenanceForm({
  serviceId
}: StartMaintenanceFormProps) {

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('create-maintenance-service')) {
      redirectTo('/')
    }
  }, [redirectTo, userLogged])

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<StartMaintenanceFormInputs>({
    resolver: yupResolver(startMaintenanceFormSchema)
  })

  const { promiseMessage } = useToastify()

  const { createMaintenance: {
    mutateAsync: createMaintenance
  } } = useMaintenanceServiceFunctions()

  const maintenanceArrivalPhoto = useWatch({
    control,
    name: 'maintenance_arrival_photo'
  })

  const maintenanceArrivalPhotoPreview = maintenanceArrivalPhoto
    ? Object.entries(maintenanceArrivalPhoto).map(([key, value]) => URL.createObjectURL(value))[0]
    : null

  async function handleStartMaintenance({
    maintenance_arrival_photo,
    maintenance_arrival_temperature,
    maintenance_type
  }: StartMaintenanceFormInputs) {
    const formData = new FormData()
    const maintenanceArrivalTemperature =
      String(maintenance_arrival_temperature).replace(',', '.')

    formData.append('maintenance_type', maintenance_type)
    formData.append(
      'maintenance_arrival_temperature', maintenanceArrivalTemperature
    )
    formData.append('maintenance_arrival_photo', maintenance_arrival_photo[0])
    formData.append('maintenance_service', serviceId)

    await promiseMessage(
      createMaintenance(formData), 'Manutenção iniciada com sucesso!'
    )

    reset()
  }

  return (
    <Flex
      w="full"
      as="form"
      gap={4}
      direction="column"
      onSubmit={handleSubmit(handleStartMaintenance)}
      noValidate
    >
      <Stack
        direction={['column', 'column', 'row']}
        spacing={4}
        w="full"
      >
        <Select
          {...register('maintenance_type')}
          name='maintenance_type'
          placeholder="Selecione uma opção..."
          label='Tipo de manutenção'
          error={errors.maintenance_type}
          options={maintenanceTypeOptions}
          required
        />

        <Stack
          direction={['column', 'column', 'row']}
          spacing={4}
          w='full'
        >
          <Input
            {...register('maintenance_arrival_temperature')}
            name='maintenance_arrival_temperature'
            label='Temperatura de chegada'
            error={errors.maintenance_arrival_temperature}
            required
          />
        </Stack>
      </Stack>

      <Stack
        direction={['column', 'column', 'row']}
        spacing={4}
      >


        <Input
          {...register("maintenance_arrival_photo")}
          name="maintenance_arrival_photo"
          type="file"
          accept={supportedFileFormats.join(", ")}
          hidden
          label="Foto antes da manutenção"
          error={errors.maintenance_arrival_photo as FieldError}
          required
          multiple={false}
        >
          <FormLabel
            display="flex"
            alignItems="center"
            gap="2"
            justifyContent="center"
            border="1px solid"
            borderColor="gray.300"
            borderRadius="md"
            position="relative"
            w="350px"
            h="250px"
            htmlFor="maintenance_arrival_photo"
            _hover={{
              cursor: "pointer",
            }}
          >
            {maintenanceArrivalPhotoPreview ? (
              <Image
                position="absolute"
                w="full"
                h="full"
                objectFit="fill"
                src={maintenanceArrivalPhotoPreview}
              />
            ) : (
              <>
                <Icon as={FiFile} />
                Escolha uma imagem
              </>
            )}

          </FormLabel>
        </Input>
      </Stack>

      <Flex alignSelf='flex-end'>
        <Button
          type='submit'
          colorScheme='blue'
        >
          Iniciar manutenção
        </Button>
      </Flex>
    </Flex>
  )
}
