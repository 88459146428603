import { Box, Flex, Heading, IconButton } from "@chakra-ui/react"
import { getVacations } from "api/vacations/getVacations"
import { useAuth } from "hooks/auth/useAuth"
import { useEffect } from "react"
import { FaExchangeAlt } from "react-icons/fa"
import { useInfiniteQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { VacationsKanbanColumn } from "./components/VacationsKanbanColumn"

export function VacationsKanban() {
  const { userLogged } = useAuth()
  const history = useHistory()

  const userCanViewVacations = userLogged?.permissions.includes(
    'view-vacations'
  )

  useEffect(() => {
    if (!userCanViewVacations) history.push('/')
  }, [userCanViewVacations, history])


  const {
    data: activeVacationsResult,
    isLoading: isActiveVacationsResultLoading,
    hasNextPage: hasNextPageActiveVacations,
    fetchNextPage: fetchNextPageActiveVacations,
    isFetchingNextPage: isFetchingNextPageActiveVacations,
  } = useInfiniteQuery({
    queryKey: ['active-vacations'],
    queryFn: ({ pageParam = 1 }) => getVacations({
      currentPage: pageParam,
      pageSize: 10,
      status: 'active',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })


  const {
    data: planningVacationsResult,
    isLoading: isPlanningVacationsResultLoading,
    hasNextPage: hasNextPagePlanningVacations,
    fetchNextPage: fetchNextPagePlanningVacations,
    isFetchingNextPage: isFetchingNextPagePlanningVacations,
  } = useInfiniteQuery({
    queryKey: ['planning-vacations'],
    queryFn: ({ pageParam = 1 }) => getVacations({
      currentPage: pageParam,
      pageSize: 10,
      status: 'planning',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })
  const {
    data: plannedVacationsResult,
    isLoading: isPlannedVacationsResultLoading,
    hasNextPage: hasNextPagePlannedVacations,
    fetchNextPage: fetchNextPagePlannedVacations,
    isFetchingNextPage: isFetchingNextPagePlannedVacations,
  } = useInfiniteQuery({
    queryKey: ['planned-vacations'],
    queryFn: ({ pageParam = 1 }) => getVacations({
      currentPage: pageParam,
      pageSize: 10,
      status: 'planned',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })
  const {
    data: payingVacationsResult,
    isLoading: isPayingVacationsResultLoading,
    hasNextPage: hasNextPagePayingVacations,
    fetchNextPage: fetchNextPagePayingVacations,
    isFetchingNextPage: isFetchingNextPagePayingVacations,
  } = useInfiniteQuery({
    queryKey: ['paying-vacations'],
    queryFn: ({ pageParam = 1 }) => getVacations({
      currentPage: pageParam,
      pageSize: 10,
      status: 'paying',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: waitingVacationsResult,
    isLoading: isWaitingVacationsResultLoading,
    hasNextPage: hasNextPageWaitingVacations,
    fetchNextPage: fetchNextPageWaitingVacations,
    isFetchingNextPage: isFetchingNextPageWaitingVacations,
  } = useInfiniteQuery({
    queryKey: ['waiting-vacations'],
    queryFn: ({ pageParam = 1 }) => getVacations({
      currentPage: pageParam,
      pageSize: 10,
      status: 'waiting',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: onVacationsResult,
    isLoading: isOnVacationsResultLoading,
    hasNextPage: hasNextPageOnVacations,
    fetchNextPage: fetchNextPageOnVacations,
    isFetchingNextPage: isFetchingNextPageOnVacations,
  } = useInfiniteQuery({
    queryKey: ['on-vacations'],
    queryFn: ({ pageParam = 1 }) => getVacations({
      currentPage: pageParam,
      pageSize: 10,
      status: 'on-vacation',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: doneVacationsResult,
    isLoading: isDoneVacationsResultLoading,
    hasNextPage: hasNextPageDoneVacations,
    fetchNextPage: fetchNextPageDoneVacations,
    isFetchingNextPage: isFetchingNextPageDoneVacations,
  } = useInfiniteQuery({
    queryKey: ['done-vacations'],
    queryFn: ({ pageParam = 1 }) => getVacations({
      currentPage: pageParam,
      pageSize: 10,
      status: 'done',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: dismissedVacationsResult,
    isLoading: isDismissedVacationsResultLoading,
    hasNextPage: hasNextPageDismissedVacations,
    fetchNextPage: fetchNextPageDismissedVacations,
    isFetchingNextPage: isFetchingNextPageDismissedVacations,
  } = useInfiniteQuery({
    queryKey: ['dismissed-vacations'],
    queryFn: ({ pageParam = 1 }) => getVacations({
      currentPage: pageParam,
      pageSize: 10,
      status: 'dismissed',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })


  const columnsVacations = [
    {
      title: 'Ativas',
      vacations: activeVacationsResult?.pages.flatMap((page) => page.vacations) || [],
      vacationsCount: activeVacationsResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageActiveVacations,
      fetchNextPage: fetchNextPageActiveVacations,
      isFetchingNextPage: isFetchingNextPageActiveVacations,
      isLoading: isActiveVacationsResultLoading,
    },
    {
      title: 'Aguardando planejamento',
      vacations: planningVacationsResult?.pages.flatMap((page) => page.vacations) || [],
      vacationsCount: planningVacationsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPagePlanningVacations,
      fetchNextPage: fetchNextPagePlanningVacations,
      isFetchingNextPage: isFetchingNextPagePlanningVacations,
      isLoading: isPlanningVacationsResultLoading,
    },
    {
      title: 'Planejadas',
      vacations: plannedVacationsResult?.pages.flatMap((page) => page.vacations) || [],
      vacationsCount: plannedVacationsResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPagePlannedVacations,
      fetchNextPage: fetchNextPagePlannedVacations,
      isFetchingNextPage: isFetchingNextPagePlannedVacations,
      isLoading: isPlannedVacationsResultLoading,
    },
    {
      title: 'Em Pagamento',
      vacations: payingVacationsResult?.pages.flatMap((page) => page.vacations) || [],
      vacationsCount: payingVacationsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPagePayingVacations,
      fetchNextPage: fetchNextPagePayingVacations,
      isFetchingNextPage: isFetchingNextPagePayingVacations,
      isLoading: isPayingVacationsResultLoading,
    },
    {
      title: 'Aguardando férias',
      vacations: waitingVacationsResult?.pages.flatMap((page) => page.vacations) || [],
      vacationsCount: waitingVacationsResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageWaitingVacations,
      fetchNextPage: fetchNextPageWaitingVacations,
      isFetchingNextPage: isFetchingNextPageWaitingVacations,
      isLoading: isWaitingVacationsResultLoading,
    },
    {
      title: 'Aguardando férias',
      vacations: onVacationsResult?.pages.flatMap((page) => page.vacations) || [],
      vacationsCount: onVacationsResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageOnVacations,
      fetchNextPage: fetchNextPageOnVacations,
      isFetchingNextPage: isFetchingNextPageOnVacations,
      isLoading: isOnVacationsResultLoading,
    },
    {
      title: 'Finalizadas',
      vacations: doneVacationsResult?.pages.flatMap((page) => page.vacations) || [],
      vacationsCount: doneVacationsResult?.pages[0]?.meta?.count,
      color: 'blue.300',
      hasNextPage: hasNextPageDoneVacations,
      fetchNextPage: fetchNextPageDoneVacations,
      isFetchingNextPage: isFetchingNextPageDoneVacations,
      isLoading: isDoneVacationsResultLoading,
    },
    {
      title: 'Funcionário Demitido',
      vacations: dismissedVacationsResult?.pages.flatMap((page) => page.vacations) || [],
      vacationsCount: dismissedVacationsResult?.pages[0]?.meta?.count,
      color: 'red.300',
      hasNextPage: hasNextPageDismissedVacations,
      fetchNextPage: fetchNextPageDismissedVacations,
      isFetchingNextPage: isFetchingNextPageDismissedVacations,
      isLoading: isDismissedVacationsResultLoading,
    },
  ]

  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Férias</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar tabela"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => history.push('/ferias')}
          />

        </Flex>

      </Flex>
      <Flex gap={4} overflowX="auto" mt={4}>
        {columnsVacations?.map((vacation, index) => {
          return (
            <VacationsKanbanColumn
              key={index}
              vacation={vacation}
            />
          )
        })}
      </Flex>
    </Box>
  )
}
