import axios from "axios";
import { User } from "hooks/user/dtos/User";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { IHubsProps } from "utils/RequestFunctions/Hubs/requestHubFunctions";
import { Standardization, StandardizationStatus } from "./standardization";

interface GetStandardizationsParams {
  pageSize: string
  currentPage: string
  status?: StandardizationStatus | 'all' | null
  hub?: string | null
}

export interface GetStandardizationsResponse {
  standardizations: Array<Standardization & { collaborator: User, hub: IHubsProps }>
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
    count: number
  }
}

export async function getStandardizations({
  pageSize,
  currentPage,
  status,
  hub,

}: GetStandardizationsParams) {
  try {
    const { data } = await api.get<GetStandardizationsResponse>('/standardizations', {
      params: {
        pageSize,
        currentPage,
        status,
        hub,
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

