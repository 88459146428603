import { Tr, Td, IconButton, Icon, Button, useDisclosure, Modal, ModalOverlay } from "@chakra-ui/react";
import { deletePmc } from "api/pmcs/deletePmc";
import { GetPmcsResponse } from "api/pmcs/getPmcs";
import { Pmc } from "api/pmcs/pmc";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { Warehouse } from "hooks/warehouse/dtos/Warehouse";
import { useEffect } from "react";
import { FaSearch, FaArrowRight, FaTimes } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ApprovePmc } from "./ApprovePmc";
import { PmcDetail } from "./PmcDetail";
import { PmcStatus } from "./PmcStatus";
import { ReceivePmc } from "./ReceivePmc";

interface PmcType extends Pmc {
  warehouse: Pick<Warehouse, 'name'>
}

interface PmcsTableRowProps {
  pmc: PmcType
}

export function PmcsTableRow({ pmc }: PmcsTableRowProps) {

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  const userHasViewPmcPermission = userLogged?.permissions.includes('view-pmc')

  useEffect(() => {
    if (!userHasViewPmcPermission) {
      redirect('/')
    }
  }, [userHasViewPmcPermission, redirect])

  const userHasApprovePmcPermission = userLogged?.permissions.includes('approve-pmc')
  const userHasReceivePmcPermission = userLogged?.permissions.includes('receive-pmc')
  const userHasDeletePmcPermission = userLogged?.permissions.includes('delete-pmc')

  const {
    isOpen: isApprovePmcModalOpen,
    onOpen: onOpenApprovePmcModal,
    onClose: onCloseApprovePmcModal
  } = useDisclosure()

  const {
    isOpen: isReceivePmcModalOpen,
    onOpen: onOpenReceivePmcModal,
    onClose: onCloseReceivePmcModal
  } = useDisclosure()

  const {
    isOpen: isPmcDetailModalOpen,
    onOpen: onOpenPmcDetailModal,
    onClose: onClosePmcDetailModal
  } = useDisclosure()


  const pmcStatus = pmc.approved_at === null
    ? 'approving'
    : pmc.received_at === null
      ? 'receiving'
      : 'received'

  const queryClient = useQueryClient()

  const { mutateAsync: deletePmcFn } = useMutation({
    mutationFn: deletePmc,
    onSuccess(_data, { pmcId }) {
      const cachedPmcs = queryClient.getQueriesData<GetPmcsResponse>({
        queryKey: ['pmcs']
      })

      cachedPmcs.forEach(([cachedKey, cachedData]) => {
        if (!cachedData) {
          return
        }

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          pmcs: cachedData.pmcs.filter(pmc => pmc.id !== pmcId)
        })
      })

      toast.success('Pmc excluída!')
    },
    onError(error: any) {
      toast.error(error.message)
    },
  })

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar pmc"
          icon={<Icon as={FaSearch} />}
          size="sm"
          variant="ghost"
          onClick={onOpenPmcDetailModal}
        />
        <Modal
          isOpen={isPmcDetailModalOpen}
          onClose={onClosePmcDetailModal}
          isCentered
        >
          <PmcDetail pmcId={pmc.id} />

        </Modal>

      </Td>
      <Td>{pmc.protocol}</Td>
      <Td textAlign="left">
        <PmcStatus status={pmcStatus} />
      </Td>
      <Td>{pmc.warehouse.name}</Td>
      <Td>{format(new Date(pmc.created_at), 'dd/MM/yyyy')}</Td>
      <Td>
        {(pmcStatus === 'approving' && userHasApprovePmcPermission) && (
          <>
            <Button
              size="xs"
              leftIcon={<Icon h={2.5} w={2.5} as={FaArrowRight} />}
              variant="outline"
              onClick={onOpenApprovePmcModal}
            >
              Aprovar
            </Button>

            <Modal
              size="6xl"
              isOpen={isApprovePmcModalOpen}
              onClose={onCloseApprovePmcModal}
              isCentered
            >

              <ModalOverlay />

              <ApprovePmc pmcId={pmc.id} onCloseModal={onCloseApprovePmcModal} />
            </Modal>
          </>
        )}


        {(pmcStatus === 'receiving' && userHasReceivePmcPermission) && (
          <>
            <Button
              size="xs"
              leftIcon={<Icon h={2.5} w={2.5} as={FaArrowRight} />}
              variant="outline"
              onClick={onOpenReceivePmcModal}
            >
              Receber
            </Button>

            <Modal
              size="4xl"
              isOpen={isReceivePmcModalOpen}
              onClose={onCloseReceivePmcModal}
              isCentered
            >

              <ModalOverlay />

              <ReceivePmc pmcId={pmc.id} onCloseModal={onCloseReceivePmcModal} />
            </Modal>
          </>
        )}


      </Td>
      <Td>
        {(pmcStatus !== 'received' && userHasDeletePmcPermission) && (
          <Button
            size="xs"
            leftIcon={<Icon as={FaTimes} />}
            variant="ghost"
            onClick={() => deletePmcFn({ pmcId: pmc.id })}
          >
            Reprovar
          </Button>
        )}
      </Td>
    </Tr>

  )
}
