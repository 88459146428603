import { useEffect } from "react";
import { usePagination } from "@ajna/pagination";
import { Box, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Pagination } from "components/Pagination/Pagination";
import { UseExcludedServicesLogsReponse } from "hooks/log/useExcludedServicesLogs";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils/DateFunctions/formatDate";

interface ExcludedServicesLogsProps {
  rowsPerPage: string
  logs: UseExcludedServicesLogsReponse
}

export function ExcludedServicesLogs({ logs, rowsPerPage }: ExcludedServicesLogsProps) {
  const { push: redirect } = useHistory()

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: logs.totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  const handleChangePage = (page: number) => setCurrentPage(page)

  useEffect(() => {
    redirect({
      pathname: '/logs/filtrar',
      search: `?page=${currentPage}`
    })
  }, [currentPage, redirect])

  return (
    <Box>
      <TableContainer>
        <Table size='sm' variant='striped'>
          <Thead>
            <Tr>
              <Th>EXCLUÍDO POR</Th>
              <Th>EMAIL</Th>
              <Th>AÇÃO</Th>
              <Th>DATA DA EXCLUSÃO</Th>
            </Tr>
          </Thead>

          <Tbody>
            {logs?.logs.length > 0 && logs?.logs.map((log) => {
              return (
                <Tr key={log.id}>
                  <Td>{log?.userIDLogs.firstname} {log?.userIDLogs.lastname}</Td>
                  <Td>{log?.userIDLogs.email}</Td>
                  <Td>{log.action}</Td>
                  <Td>{formatDate.handle(log.createdAt, 'DateWithDateAndHourMinute')}</Td>
                </Tr>
              )
            })}
          </Tbody>

        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
