import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Radio, RadioGroup, Stack, Text, Textarea } from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { set } from "date-fns";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { completeRhAudit } from "api/audits/completeRhAudit";

interface AuditRhProps {
  auditId: string
}

interface AuditRhSchema {
  realizedDate: string
  isCustomerPopTrainingRegistered: string
  isCleaningDesinfectionTrainingRegistered: string
  isBiosecurityTrainingRegistered: string
  isLastMonthCleaningRegistered: string
  isWithVaccineCard: string
  isWithEarCnh: string
  isWithMotorcycleFreightAnttCourse: string
  isWithCrlvLocationCategory: string
  isWithAso: string
  isEpiKitDelivered: string
  isTimeCardRecordedCorrectly: string
  isOpenedSalaryAccount: string
  isWithBadge: string
  isWithBadgeCord: string
  isDeliveredMotorcycleLabels: string
  isDeliveredThermalBox: string
  nonCompliance: string
}

const auditRhSchema = yup.object({
  realizedDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  isCustomerPopTrainingRegistered: yup.string().required(),
  isCleaningDesinfectionTrainingRegistered: yup.string().required(),
  isBiosecurityTrainingRegistered: yup.string().required(),
  isLastMonthCleaningRegistered: yup.string().required(),
  isWithVaccineCard: yup.string().required(),
  isWithEarCnh: yup.string().required(),
  isWithMotorcycleFreightAnttCourse: yup.string().required(),
  isWithCrlvLocationCategory: yup.string().required(),
  isWithAso: yup.string().required(),
  isEpiKitDelivered: yup.string().required(),
  isTimeCardRecordedCorrectly: yup.string().required(),
  isOpenedSalaryAccount: yup.string().required(),
  isWithBadge: yup.string().required(),
  isWithBadgeCord: yup.string().required(),
  isDeliveredMotorcycleLabels: yup.string().required(),
  isDeliveredThermalBox: yup.string().required(),
  nonCompliance: yup.string(),
})

export function AuditRh({
  auditId
}: AuditRhProps) {

  const { promiseMessage } = useToastify()

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<AuditRhSchema>({
    resolver: yupResolver(auditRhSchema)
  })

  const queryClient = useQueryClient()

  const { mutateAsync: completeRhAuditFn } = useMutation({
    mutationFn: completeRhAudit,
    onSuccess() {
      queryClient.invalidateQueries(['audits'])
      queryClient.invalidateQueries({ queryKey: 'pending-audits' })
      queryClient.invalidateQueries({ queryKey: 'approved-audits' })
      queryClient.invalidateQueries({ queryKey: 'reproved-audits' })
      queryClient.invalidateQueries({ queryKey: ['audit', auditId] })
    }
  })

  async function handleAuditRh(values: AuditRhSchema) {
    await promiseMessage(completeRhAuditFn({
      body: {
        ...values,
        isCustomerPopTrainingRegistered: values.isCustomerPopTrainingRegistered === 'yes',
        isCleaningDesinfectionTrainingRegistered: values.isCleaningDesinfectionTrainingRegistered === 'yes',
        isBiosecurityTrainingRegistered: values.isBiosecurityTrainingRegistered === 'yes',
        isLastMonthCleaningRegistered: values.isLastMonthCleaningRegistered === 'yes',
        isWithVaccineCard: values.isWithVaccineCard === 'yes',
        isWithEarCnh: values.isWithEarCnh === 'yes',
        isWithMotorcycleFreightAnttCourse: values.isWithMotorcycleFreightAnttCourse === 'yes',
        isWithCrlvLocationCategory: values.isWithCrlvLocationCategory === 'yes',
        isWithAso: values.isWithAso === 'yes',
        isEpiKitDelivered: values.isEpiKitDelivered === 'yes',
        isTimeCardRecordedCorrectly: values.isTimeCardRecordedCorrectly === 'yes',
        isWithBadge: values.isWithBadge === 'yes',
        isWithBadgeCord: values.isWithBadgeCord === 'yes'
      },
      auditId
    }), 'Auditoria realizada!')
  }

  return (
    <Box
      w="full"
      as="form"
      maxH='500px'
      overflowY='scroll'
      onSubmit={handleSubmit(handleAuditRh)}
    >
      <Heading letterSpacing="tight" size='sm'> Auditoria RH</Heading>

      <FormControl isInvalid={!!errors?.realizedDate} mt={3}>
        <FormLabel fontSize="sm">
          Data realizada
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('realizedDate')}
          name="realizedDate"
          type="date"
          size="sm"
          rounded="md"
          w={["full", "full", "sm"]}
        />
      </FormControl>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isCustomerPopTrainingRegistered}>
            <FormLabel>
              Registro de treinamento POP do cliente?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isCustomerPopTrainingRegistered"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isCleaningDesinfectionTrainingRegistered}>
            <FormLabel>
              Registro de treinamento limpeza e desinfecção?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isCleaningDesinfectionTrainingRegistered"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isBiosecurityTrainingRegistered}>
            <FormLabel>
              Registro de treinamento de biossegurança?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isBiosecurityTrainingRegistered"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isLastMonthCleaningRegistered}>
            <FormLabel>
              Registro de limpeza do mês anterior?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isLastMonthCleaningRegistered"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isWithVaccineCard}>
            <FormLabel>
              Cartão de vacina?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isWithVaccineCard"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isWithEarCnh}>
            <FormLabel>
              CNH com EAR?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isWithEarCnh"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isWithMotorcycleFreightAnttCourse}>
            <FormLabel>
              Curso de motofrete/ANTT?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isWithMotorcycleFreightAnttCourse"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isWithCrlvLocationCategory}>
            <FormLabel>
              CRLV categoria aluguel?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isWithCrlvLocationCategory"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isWithAso}>
            <FormLabel>
              Aso?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isWithAso"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isEpiKitDelivered}>
            <FormLabel>
              KIT EPI entregue?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isEpiKitDelivered"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isTimeCardRecordedCorrectly}>
            <FormLabel>
              Cartão de ponto batido corretamente?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isTimeCardRecordedCorrectly"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isOpenedSalaryAccount}>
            <FormLabel>
              Conta salário aberta?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isOpenedSalaryAccount"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                    <Radio value="n.a">N.A</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isWithBadge}>
            <FormLabel>
              Crachá?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isWithBadge"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isWithBadgeCord}>
            <FormLabel>
              Cordão crachá?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isWithBadgeCord"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isDeliveredMotorcycleLabels}>
            <FormLabel>
              Adesivos moto entregue?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isDeliveredMotorcycleLabels"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                    <Radio value="n.a">N.A</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.isDeliveredThermalBox}>
            <FormLabel>
              Caixa térmica entregue?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="isDeliveredThermalBox"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                    <Radio value="n.a">N.A</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <FormControl isInvalid={!!errors.nonCompliance} mt={3}>
        <FormLabel>
          Não conformidade
        </FormLabel>
        <Textarea
          {...register('nonCompliance')}
          placeholder="Descreva a não conformidade..."
          size='sm'
          rounded='md'
        />
      </FormControl>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Auditar
        </Button>
      </Flex>
    </Box>
  )
}
