import { Box, Flex } from "@chakra-ui/react";
import { AsoStatus as AsoStatusTypes } from "api/asos/aso";
import { asoStatusMap } from "utils/asoMappers";

interface AsoStatusProps {
  status: AsoStatusTypes;
}

export function AsoStatus({ status }: AsoStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {status === 'pending' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'active' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'inactive' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {asoStatusMap[status]}
    </Flex>
  )
}
