import { Button, Flex, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { Input } from "components/Inputs/Input";
import { add, format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { InternComunicationReport as InternComunicationReportProps } from "hooks/report/dtos/InternComunicationReport";
import { useInternComunicationReport } from "hooks/report/useInternComunicationReport";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils/DateFunctions/formatDate";
import { handleChangeUrl } from "utils/handleChangeUrl";
import { internComunicationStatusDict } from "utils/internComunication/statusDict";
import * as yup from "yup"

interface InternComunicationReportInputs {
  start_filter: string
  end_filter: string
}

const internComunicationReportHeaders = [
  { key: 'protocol', label: 'PROTOCOLO' },
  { key: 'owner', label: 'SOLICITANTE' },
  { key: 'attachment', label: 'ANEXO DE ABERTURA' },
  { key: 'responsible', label: 'RESPONSÁVEL' },
  { key: 'comunication_type', label: 'TIPO DE COMUNICAÇÃO' },
  { key: 'comunication_questions', label: 'SOLICITAÇÕES/PERGUNTAS' },
  { key: 'comunication_answers', label: 'RESPOSTAS' },
  { key: 'last_comunication_answer', label: 'DATA E HORA DA ÚLTIMA RESPOSTA' },
  { key: 'comunication_deadline', label: 'PRAZO DE RESPOSA' },
  { key: 'created_at', label: 'DATA DA CRIAÇÃO' },
  { key: 'updated_at', label: 'DATA DA FINALIZAÇÃO' },
  { key: 'comunication_status', label: 'STATUS' },
]

const schema = yup.object().shape({
  start_filter: yup.string().required('Campo obrigatório!'),
  end_filter: yup.string().required('Campo obrigatório!')
})

export function InternComunicationReport() {
  const [
    enableInternComunicationReportRequest,
    setEnableInternComunicationReportRequest
  ] = useState(false)

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-intern-comunication-report')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<InternComunicationReportInputs>({
    resolver: yupResolver(schema),
    shouldUnregister: true
  })

  const [
    startFilter,
    endFilter
  ] = useWatch({
    control,
    name: ['start_filter', 'end_filter']
  })

  const isValidStartDate = new Date(startFilter) instanceof Date &&
    !isNaN(new Date(startFilter).getMilliseconds())

  const maxEndFilter = isValidStartDate
    ? formatDate.handle(add(new Date(startFilter), {
      days: 31
    }), 'DateWithoutHourToInput')
    : ''

  const {
    data: internComunicationReportData,
    isFetching: isInternComunincationReportFetching,

  } = useInternComunicationReport({
    queryOptions: {
      enabled: enableInternComunicationReportRequest,
      select: (internComunications: InternComunicationReportProps[]) => {

        return internComunications.map(internComunication => {
          const comunicationAnswers = internComunication.comunication_answers.map(answer => {
            return `
            Identificador: ${answer.id}
            Descrição: ${answer?.description}
            Data da resposta: ${formatDate.handle(answer?.answer_date, "DateWithDateAndHourMinute")}
            Anexos: ${(Boolean(answer?.attachments) && Boolean(answer?.attachments.length)) ? answer.attachments.map((attachment) => handleChangeUrl(attachment)).join('\n') : '-'}
            `
          }).join('\n')
          const comunicationQuestions = internComunication.comunication_questions.map(question => {
            return `
            Identificador: ${question.id}
            Descrição: ${question?.description?.replace(/<\/?[^>]+(>|$)/g, '').replace(/strong/g, '').trim().split("\"").join("'")}
            Data da solicitação: ${formatDate.handle(question.question_date, "DateWithDateAndHourMinute")}
            `
          }).join('\n')
          return {
            protocol: internComunication.protocol,
            owner: internComunication.owner,
            attachment: internComunication.attachment ? handleChangeUrl(internComunication.attachment) : '-',
            responsible: internComunication.responsible,
            comunication_type: internComunication.comunication_type,
            comunication_answers: comunicationAnswers,
            last_comunication_answer: internComunication.last_comunication_answer
              ? format(new Date(internComunication.last_comunication_answer), 'yyyy-MM-dd HH:mm:ss')
              : '-',
            comunication_questions: comunicationQuestions,
            comunication_deadline: format(new Date(internComunication.comunication_deadline), 'yyyy-MM-dd HH:mm:ss'),
            created_at: format(new Date(internComunication.created_at), 'yyyy-MM-dd HH:mm:ss'),
            updated_at: internComunication.comunication_status === 'DONE'
              ? format(new Date(internComunication.updated_at), 'yyyy-MM-dd HH:mm:ss')
              : '-',
            comunication_status: internComunicationStatusDict(internComunication.comunication_status)
          }
        })
      }
    },
    queryParams: { start_filter: startFilter, end_filter: endFilter }
  })

  const handleGenerateReport = () => {
    setEnableInternComunicationReportRequest(true)
    setTimeout(() => {
      setEnableInternComunicationReportRequest(false)
    }, 500)
  }

  const csvReportProps = {
    data: internComunicationReportData ?? [],
    headers: internComunicationReportHeaders,
    filename: `relatorio-comunicacoes-internas-${startFilter}-${endFilter}.csv`
  }

  return (
    <StandardBackgroundForm
      title='Relatório de comunicações internas'
      onSubmit={handleSubmit(handleGenerateReport)}
    >
      <Flex
        gap={4}
        direction='column'
      >
        <Stack
          w='full'
          spacing={4}
          direction={['column', 'column', 'row']}
        >
          <Input
            {...register('start_filter')}
            name='start_filter'
            label='Data inicial do filtro'
            type='date'
            error={errors.start_filter}
            required
          />

          <Input
            {...register('end_filter')}
            name='end_filter'
            label='Data final do filtro'
            type='date'
            error={errors.end_filter}
            max={maxEndFilter}
            required
          />
        </Stack>

        <Flex gap={2} justify={['center', 'center', 'flex-end']}>
          <Button
            isLoading={isInternComunincationReportFetching}
            type='submit'
            w={['full', 'full', 'min']}
            colorScheme='blue'
          >
            Gerar relatório
          </Button>
          {internComunicationReportData && (
            <GenerateExcelReportButton mt={-4} csvReportProps={csvReportProps} />
          )}
        </Flex>
      </Flex>


    </StandardBackgroundForm>
  )
}
