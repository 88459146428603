import { api } from '../../../services/api'

async function updateStockFromCsvReqFunction(input: FormData) {
  try {
    const { data } = await api.post('/stock-csv', input)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

export { updateStockFromCsvReqFunction }
