import { Flex, Stack } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { ShippingProps } from "contexts/ShippingContext";
import { useBranchesByShipping } from "hooks/shippingCost/useBranchesByShipping";
import { useCurrency } from "react-hook-currency";
import { useFormContext, useFieldArray, useWatch } from "react-hook-form";

interface ShippingCostFormProps {
  slug?: string
  shippings: ShippingProps[]
}

export interface ShippingCostFormInputs {
  shippingId: string
  sourceBranchId: string
  destinationBranchId: string
  weights: {
    kg: number
    priceInCents: string
  }[]
  extraKgPriceInCents: string
}

export function ShippingCostForm({
  shippings,
  slug
}: ShippingCostFormProps) {

  const isViewPage = slug === "visualizar"

  const {
    register,
    control,
    formState: {
      errors,
    }
  } = useFormContext<ShippingCostFormInputs>()

  const [shippingSelected] = useWatch({
    control,
    name: ['shippingId']
  })

  const { data: branches, isFetching: isFetchingBranches } = useBranchesByShipping({ shippingId: shippingSelected, queryOptions: { enabled: !!shippingSelected } })

  const {
    fields: weightsFields,
  } = useFieldArray({
    control,
    name: 'weights'
  })

  const { onChange, format: currencyFormat } = useCurrency({ style: 'decimal' })

  const branchesOptions = branches?.branches.map((branch) => {
    return {
      key: branch.id,
      value: branch.id,
      showOption: `${branch.nickname} (${branch.hubIDBranch.name})`
    }
  })

  return (
    <Flex direction="column" gap={6} align="center">
      <Stack
        w='full'
        spacing={6}
        direction={['column', 'column', 'row']}
      >
        <Select
          {...register("shippingId")}
          name="shippingId"
          label="Transportadora"
          error={errors.shippingId}
          placeholder="Selecione uma opção..."
          shipping={shippings}
          required
          isDisabled={isViewPage}
        />
      </Stack>
      {(!isFetchingBranches && shippingSelected) && (
        <>
          <Stack
            w='full'
            spacing={6}
            direction={['column', 'column', 'row']}
          >
            <Select
              {...register("sourceBranchId")}
              name="sourceBranchId"
              label="Base de Origem"
              error={errors.sourceBranchId}
              placeholder="Selecione uma opção..."
              options={branchesOptions}
              isDisabled={isViewPage}
              required
            />
            <Select
              {...register("destinationBranchId")}
              name="destinationBranchId"
              label="Base de Destino"
              error={errors.destinationBranchId}
              placeholder="Selecione uma opção..."
              options={branchesOptions}
              isDisabled={isViewPage}
              required
            />
          </Stack>
          <Flex flexDirection='column' align='center' gap={6} w='full'>
            {weightsFields?.map((field, index) => {
              return (
                <Stack
                  w='full'
                  spacing={6}
                  direction={['column', 'column', 'row']}
                  key={field.id}
                >
                  <Input
                    {...register(`weights.${index}.kg`)}
                    name={`weights.${index}.kg`}
                    label="KG"
                    error={errors?.weights
                      ? errors?.weights[index]?.kg
                      : undefined}
                    required
                    isDisabled
                  />
                  <Input
                    {...register(`weights.${index}.priceInCents`)}
                    name={`weights.${index}.priceInCents`}
                    label="Preço"
                    onChange={onChange}
                    defaultValue={currencyFormat('000')}
                    addChildren='R$'
                    error={errors?.weights
                      ? errors?.weights[index]?.priceInCents
                      : undefined}
                    isDisabled={isViewPage}
                    required
                  />

                </Stack>
              );
            })}
          </Flex>

          <Stack
            w='full'
            spacing={6}
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register("extraKgPriceInCents")}
              name="extraKgPriceInCents"
              label="Preço KG Extra"
              onChange={onChange}
              defaultValue={currencyFormat('000')}
              error={errors.extraKgPriceInCents}
              isDisabled={isViewPage}
              required
            />
          </Stack>
        </>
      )}
    </Flex>
  )
}
