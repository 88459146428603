import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Stack,
  useMediaQuery,
  Button,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye, FiTrash2 } from 'react-icons/fi'
import { useState, useEffect, useCallback } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { EmptyContentTableAlert } from '../../Alerts/EmptyContentTableAlert'
import { useSwal } from '../../../hooks/swal/useSwal'
import { useAuth } from '../../../hooks/auth/useAuth'
import { ProfileProps } from '../../../utils/RequestFunctions/Profile/requestProfileFunctions'
import { formatDate } from '../../../utils/DateFunctions/formatDate'
import { useProfileFunctions } from '../../../hooks/profile/useProfileFunctions'
import { PermissionProps } from '../../../contexts/PermissionContext'
import { CSVLink } from 'react-csv'

interface IProfileTableProps {
  profiles: ProfileProps[]
  permissions: PermissionProps[]
}

interface IReportProps {
  permission: string
  hasPermission0: string
  hasPermission1: string
  hasPermission2: string
  hasPermission3: string
  hasPermission4: string
  hasPermission5: string
  hasPermission6: string
  hasPermission7: string
  hasPermission8: string
  hasPermission9: string
  hasPermission10: string
  hasPermission11: string
  hasPermission12: string
  hasPermission13: string
  hasPermission14: string
  hasPermission15: string
  hasPermission16: string
  hasPermission17: string
  hasPermission18: string
  hasPermission19: string
  hasPermission20: string
}

const profileOptions = [
  { key: 'userType', value: 'Tipo de Perfil', checked: false },
  { key: 'createdAt', value: 'Data da Criação', checked: false },
  { key: 'updatedAt', value: 'Última Atualização', checked: false },
]

const profileOrderOptions = [{ key: 'userType', value: 'Tipo de Usuário' }]

// const headers = [
//   { label: "TIPO DE USUÁRIO", key: "user_type" },
//   { label: "PERMISSÕES", key: "permissions" }
// ]

export function ProfileTable({ profiles, permissions }: IProfileTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [searchedProfiles, setSearchedProfiles] = useState<ProfileProps[]>([])
  const [slicedProfiles, setSlicedProfiles] = useState<ProfileProps[]>([])
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  // SEARCHBOX FILTERS
  const [userTypeFiltered, setUserTypeFiltered] = useState('')
  const [createdAtFiltered, setCreatedAtFiltered] = useState('')
  const [updatedAtFiltered, setUpdatedAtFiltered] = useState('')
  // ---------------------------------------------------------------------------- //

  // HOOKS
  const {
    deleteProfile: { mutateAsync: deleteProfile },
  } = useProfileFunctions()
  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useAuth()
  // ---------------------------------------------------------------------------- //

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedProfiles?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  function formatValuesToReport(
    profiles: ProfileProps[],
    permissions: PermissionProps[],
  ): IReportProps[] {
    const profilesReportData = permissions.map((permission) => {
      return {
        permission: permission.key,
        hasPermission0: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[1]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',
        hasPermission1: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[2]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',
        hasPermission2: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[3]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',
        hasPermission3: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[4]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',
        hasPermission4: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[5]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',
        hasPermission5: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[6]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',
        hasPermission6: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[7]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',
        hasPermission7: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[8]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',
        hasPermission8: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[9]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',
        hasPermission9: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[10]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',
        hasPermission10: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[11]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',
        hasPermission11: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[12]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',
        hasPermission12: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[13]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',
        hasPermission13: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[14]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',
        hasPermission14: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[15]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',

        hasPermission15: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[16]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',

        hasPermission16: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[15]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',

        hasPermission17: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[16]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',

        hasPermission18: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[17]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',

        hasPermission19: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[18]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',

        hasPermission20: profiles
          .find(
            (profile) =>
              profile.user_type === formatReportHeaders(profiles)[19]?.label ||
              '',
          )
          ?.permissions.includes(permission.value)
          ? 'SIM'
          : 'NÃO',
      }
    })

    return profilesReportData
  }

  const formatReportHeaders = useCallback((profiles: ProfileProps[]) => {
    const permissionHeader = { label: 'PERMISSÕES', key: 'permission' }

    const headers = profiles.map((profile, index) => {
      return {
        label: profile.user_type,
        key: `hasPermission${index}`,
      }
    })

    headers.unshift(permissionHeader)

    return headers
  }, [])

  const csvReportProps = {
    data: formatValuesToReport(profiles, permissions),
    headers: formatReportHeaders(profiles),
    filename: `relatório-acessos`,
  }

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(profileOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(profileOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      setSlicedProfiles(profiles)
    }
    run()
  }, [profiles, currentPage])

  useEffect(() => {
    function run() {
      const newSlicedProfiles = slicedProfiles.filter((profile) => {
        const userTypeFilter = searchBoxFilter(
          profile.user_type,
          userTypeFiltered,
        )
        const createdAtFilter = searchBoxFilter(
          formatDate.handle(profile.createdAt, 'DateWithoutHourToShow'),
          createdAtFiltered,
        )
        const updatedAtFilter = searchBoxFilter(
          formatDate.handle(profile.updatedAt, 'DateWithoutHourToShow'),
          updatedAtFiltered,
        )

        if (
          userTypeFiltered === '' &&
          createdAtFiltered === '' &&
          updatedAtFiltered === ''
        ) {
          return profile
        }
        return userTypeFilter && createdAtFilter && updatedAtFilter
      })

      if (orderOptionSelected.length > 0) {
        newSlicedProfiles.sort(
          (a, b) =>
            (orderOptionSelected.includes('name') &&
              a.user_type.localeCompare(b.user_type)) ||
            0,
        )
        setSearchedProfiles(newSlicedProfiles)
      } else {
        setSearchedProfiles(newSlicedProfiles)
      }
    }
    run()
  }, [
    userTypeFiltered,
    createdAtFiltered,
    updatedAtFiltered,
    slicedProfiles,
    orderOptionSelected,
  ])

  const handlePageChange = (page: number) => {
    return setCurrentPage(page)
  }

  const handleChangeSetter = (key: string, value: string) => {
    switch (key) {
      case 'name':
        return setUserTypeFiltered(value)
      case 'date':
        return setCreatedAtFiltered(value)
      case 'city':
        return setUpdatedAtFiltered(value)
    }
  }

  const handleDeleteProfile = async (id: string) => {
    const hasConfirmed = await confirmMessage({
      title: 'Deseja excluir um perfil?',
    })

    if (hasConfirmed) {
      await deleteProfile(id)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (slicedProfiles.length <= 0) {
    return (
      <EmptyContentTableAlert
        title="Oops!"
        description="Não há dados para mostrar aqui!"
      />
    )
  }

  return (
    <>
      <Flex
        mb="2"
        wrap="wrap"
        justify={isWideVersion ? 'space-between' : 'flex-start'}
      >
        <Box>
          <TableFilterButton />
        </Box>
        <Box w={isWideVersion ? '' : 'full'}>
          <Stack mt={isWideVersion ? '0' : '2'} direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Flex key={option.key}>
                  <SearchBox
                    placeholder={`Buscar ${option.value}...`}
                    handleSearch={(e) =>
                      handleChangeSetter(option.key, e.target.value)
                    }
                  />
                </Flex>
              ))}
          </Stack>
        </Box>
      </Flex>
      <Box overflowX="auto" w="full">
        <Table w="full" colorScheme="gray" variant="striped" size="sm">
          <Thead>
            <Tr>
              {profileOptions.map((option) => (
                <Th key={option.key} fontSize="12" color="blue.900">
                  {option.value.toUpperCase()}
                </Th>
              ))}
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {searchedProfiles
              .slice(offset, offset + Number(itemLimit))
              .map((profile, index) => (
                <Tr key={profile.id}>
                  <Td fontSize="12">{profile.user_type.toUpperCase()}</Td>
                  <Td fontSize="12">
                    {formatDate.handle(
                      profile.createdAt,
                      'DateWithoutHourToShow',
                    )}
                  </Td>
                  <Td fontSize="12">
                    {formatDate.handle(
                      profile.updatedAt,
                      'DateWithoutHourToShow',
                    )}
                  </Td>
                  <Td isNumeric>
                    {userLogged !== null && (
                      <>
                        {userLogged?.permissions.includes('view-profile') && (
                          <Link to={`/perfil/visualizar/${profile.id}`}>
                            <Icon cursor="pointer" as={FiEye} fontSize="20" />
                          </Link>
                        )}
                        {userLogged?.permissions.includes('edit-profile') && (
                          <Link to={`/perfil/editar/${profile.id}`}>
                            <Icon
                              cursor="pointer"
                              as={FiEdit3}
                              fontSize="20"
                              ml="4"
                              mt={['2', '2', '0']}
                            />
                          </Link>
                        )}
                        {userLogged?.permissions.includes('remove-profile') && (
                          <Icon
                            cursor="pointer"
                            as={FiTrash2}
                            fontSize="20"
                            ml="4"
                            mt={['2', '2', '0']}
                            onClick={async () =>
                              handleDeleteProfile(profile.id)
                            }
                          />
                        )}
                      </>
                    )}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>
      <Flex justifyContent="flex-end" mt="4">
        <CSVLink
          {...csvReportProps}
          separator={';'}
          style={{
            flex: isWideVersion ? 0 : 1,
            width: isWideVersion ? '' : '100%',
          }}
        >
          <Button type="button" colorScheme="green" w={['full', 'full', '']}>
            Exportar CSV
          </Button>
        </CSVLink>
      </Flex>
      <Pagination
        handlePageChange={handlePageChange}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </>
  )
}
