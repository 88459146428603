import { Flex, Box, Heading, Button, Icon } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useAuth } from '../../hooks/auth/useAuth'
import { useProfile } from '../../hooks/profile/useProfile'
import { ProfileTable } from '../../components/Tables/Profile/ProfileTable'

import { usePermission } from '../../hooks/permission/usePermission'

export function ProfileList() {
  const {
    profiles: { data: profiles, isLoading: isProfilesLoading },
  } = useProfile(null, true)
  const {
    permissions: { data: permissions, isLoading: isPermissionsLoading },
  } = usePermission(null, true)

  const { userLogged } = useAuth()

  if (isProfilesLoading || isPermissionsLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontFamily="poppins">
              Lista de Perfis
            </Heading>
            <Link to="/perfil/adicionar">
              {userLogged?.permissions.includes('add-profile') && (
                <Button
                  colorScheme="gray"
                  rightIcon={<Icon as={FiPlus} />}
                  size="sm"
                >
                  Adicionar Perfil
                </Button>
              )}
            </Link>
          </Flex>
          {profiles && permissions && (
            <ProfileTable profiles={profiles} permissions={permissions} />
          )}
        </Box>
      </Flex>

  )
}
