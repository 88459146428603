import * as yup from 'yup'
import { isCnpjValid, isCpfValid } from '../utils/cpfCnpjValidateFunctions'

const transformYesNoToBoolean = (value: string) => {
  return value.toLowerCase() === 'yes';
};


const customerSchema = yup.object().shape({
  type: yup.string().required('Campo Obrigatório'),
  situation: yup.string().required('Campo Obrigatório'),
  trading_firstname: yup.string().required('Campo Obrigatório'),
  company_lastname: yup
    .string()
    .when('type', {
      is: 'PESSOA FÍSICA',
      then: yup.string().required('Campo Obrigatório'),
    })
    .when('type', {
      is: 'PESSOA JURÍDICA',
      then: yup.string().required('Campo Obrigatório'),
    }),
  cnpj_cpf: yup
    .string()
    .when('type', {
      is: 'PESSOA FÍSICA',
      then: yup.string().transform(isCpfValid).required('CPF inválido'),
    })
    .when('type', {
      is: 'PESSOA JURÍDICA',
      then: yup.string().transform(isCnpjValid).required('CNPJ inválido'),
    }),
  attendance_responsible: yup.string().required('Campo Obrigatório'),
  cost_center: yup.string().required('Campo obrigatório'),
  deadline_delivery: yup.string().required('Campo Obrigatório'),
  operational_email: yup
    .string()
    .email('Formato de e-mail inválido')
    .required('Campo Obrigatório'),
  financial_email: yup
    .string()
    .email('Formato de e-mail inválido')
    .required('Campo Obrigatório'),
  cellphone: yup.string().required('Campo Obrigatório'),
  telephone: yup.string().required('Campo Obrigatório'),
  cep: yup.string().required('Campo Obrigatório'),
  street: yup.string().required('Campo Obrigatório'),
  number: yup.string().required('Campo Obrigatório'),
  complement: yup.string().notRequired(),
  neighborhood: yup.string().required('Campo Obrigatório'),
  state: yup.string().required('Campo Obrigatório'),
  city: yup.string().required('Campo Obrigatório'),

  has_municipal_register: yup.string().required('Campo Obrigatório'),
  municipal_register: yup.string().when('has_municipal_register', {
    is: 'CONTRIBUINTE',
    then: yup.string().required('Campo Obrigatório'),
  }),

  has_state_register: yup.string().required('Campo Obrigatório'),
  state_register: yup.string().when('has_state_register', {
    is: 'CONTRIBUINTE',
    then: yup.string().required('Campo Obrigatório'),
  }),

  icms: yup.string().required('Campo Obrigatório'),
  iss: yup.string().required('Campo Obrigatório'),

  payment_conditional: yup.string().required('Campo Obrigatório'),
  day_expiration_1: yup.string().when('payment_conditional', {
    is: 'FATURADO MENSAL',
    then: yup.string().required('Campo Obrigatório'),
  }),
  day_expiration_2: yup.string().when('payment_conditional', {
    is: 'FATURADO QUINZENAL',
    then: yup.string().required('Campo Obrigatório'),
  }),

  payment_type: yup.string().required('Campo Obrigatório'),
  emission_type: yup.string().required('Campo Obrigatório'),
  billing_type:  yup.string().required('Campo Obrigatório'),
  custom_billing_day: yup.mixed().when('billing_type', {
    is: 'PERSONALIZADO',
    then: yup.number().typeError('Campo Obrigatório')
  }),
  lifebox_payment_term:  yup.string().required('Campo Obrigatório'),
  contacts: yup.array().min(1, 'Campo obrigatório').of(yup.object().shape({
    name: yup.string().required('Campo obrigatório'),
    email: yup.string().required('Campo obrigatório'),
    phone: yup.string(),
    description: yup.string(),
    type: yup.array().min(1, 'Campo obrigatório')
  })),
  can_receive_service_extras_email: yup.string().required('Campo obrigatório'),
  anual_adjustment_date: yup.string().required('Campo obrigatório'),
  has_maintenance: yup.mixed().required('Campo obrigatório')
  .transform(transformYesNoToBoolean),
  observation: yup.string(),
  has_temp_check: yup.string().required('Campo obrigatório'),
})

export { customerSchema }
