import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Spinner,
  Stack,
  Link as ChakraLink,
  Icon,
  VStack,
} from '@chakra-ui/react'
import { FiExternalLink } from 'react-icons/fi'
import { handleChangeUrl } from 'utils/handleChangeUrl'
import { useRnc } from '../../../hooks/rnc/useRnc'
import { formatDateToFrontInput } from '../../../utils/GeneralFunctions/DateFunctions/formatDateToFrontInput'
import { Input } from '../../Inputs/Input'
import { TextArea } from '../../Inputs/TextInput'

interface IRncAnswerAccordionProps {
  rncID: string
}

export function RncValidateAccordion({ rncID }: IRncAnswerAccordionProps) {
  const {
    rnc: { data: rnc, isLoading: isRncLoading },
  } = useRnc(rncID, false)

  if (isRncLoading) {
    return <Spinner />
  }

  return (
    <Accordion allowMultiple mt="4">
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Informações de Abertura do RNC
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Stack>
            <Input
              label="Protocolo"
              name="protocol"
              value={rnc?.protocol}
              isDisabled
            />
          </Stack>
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <Input
              label="Diretoria"
              name="direction"
              value={rnc?.direction}
              isDisabled={true}
            />

            <Input
              label="Data"
              name="date"
              type="date"
              value={rnc ? formatDateToFrontInput(rnc?.date) : ''}
              isDisabled={true}
            />
          </Stack>
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <Input
              label="Prejuízo"
              name="loss_value"
              value={rnc ? rnc.loss_value?.toFixed(2) : ''}
              isDisabled={true}
            />
          </Stack>
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <Input
              label="Aberto Por"
              name="open_by"
              value={`${rnc?.UserOpenedIDRNC.firstname} ${rnc?.UserOpenedIDRNC.lastname}`}
              isDisabled={true}
            />
            <Input
              label="Responsável pela Intercorrência"
              name="intercurrence_responsible"
              value={`${rnc?.UserResponsibleIDRNC.firstname} ${rnc?.UserResponsibleIDRNC.lastname}`}
              isDisabled={true}
            />
          </Stack>
          <Stack mt='4'>
            {rnc?.attachment_photo && (
              <ChakraLink
                fontSize="lg"
                href={handleChangeUrl(rnc?.attachment_photo)}
                isExternal
              >
                Anexo<Icon as={FiExternalLink} mx="2px" />
              </ChakraLink>
            )}
          </Stack>
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <TextArea
              label="Descrição da Intercorrência"
              name="intercurrence_description"
              value={rnc?.intercurrence_description}
              height="200px"
              isDisabled={true}
            />
          </Stack>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Informações de Resposta do RNC
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          {rnc?.reasons_why && (
            <VStack
              spacing={3}
              w="full"
            >
              {rnc.reasons_why.map((reason, index) => {
                return <TextArea
                  label={`Porque ${index + 1}`}
                  name="cause"
                  key={index.toString()}
                  value={reason.content}
                  isDisabled={true}
                />
              })}
            </VStack>
          )}
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <TextArea
              label="Causa Raiz"
              name="cause"
              value={rnc?.cause ?? '-'}
              isDisabled={true}
            />
          </Stack>
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <TextArea
              label="Preventiva"
              name="preventive"
              value={rnc?.preventive ?? '-'}
              isDisabled={true}
            />
          </Stack>
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <TextArea
              label="Corretiva"
              name="corrective"
              value={rnc?.corrective ?? '-'}
              isDisabled={true}
            />
          </Stack>
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <TextArea
              label="Por que não procede?"
              name="why_not_proceed"
              value={rnc?.why_not_proceed ?? '-'}
              isDisabled={true}
            />
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
