import { Box, Button, Flex, Icon, IconButton, Td, Text, Tooltip, Tr } from "@chakra-ui/react"
import { differenceInDays, format, isToday, set } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { FaArrowRight, FaCheckCircle, FaInfoCircle, FaSearch } from "react-icons/fa"
import { Link } from "react-router-dom"
import { formatDate } from "utils/DateFunctions/formatDate"
import { formatTimeByTimezoneUser } from "utils/formatTimeByTimezoneUser"
import { serviceDelay } from "utils/serviceDelay"

interface CollectsTableRowProps {
  service: {
    id: string
    protocol: number,
    customer: string
    vehicle: string
    source_addresses: {
      id: string
      address: string
    }[]
    going_collect_addresses: {
      id: string
      address: string
    }[] | null
    collected_addresses: string[] | null,
    destination_addresses: string[],
    collect_date: string
    collect_hour_start: string
    collect_hour_end: string
    board_hour: string
    source_collector_id: string
    source_collector: string
    destination_collector: string
    shipping: string
    source_branch: string
    destination_branch: string
    delivery_date: string
    delivery_hour: string
    caixa_termica: number
    embalagem_secundaria: number
    gelo_seco: number
    gelox: number
    isopor3l: number
    isopor7l: number
    terciaria3l: number
    terciaria8l: number
    service_type: string
    observations: string
    source_tz: string
    destination_tz: string
    has_occurrence: boolean
    has_unsuccess_collects_validation: boolean
    has_new_addresses_to_validate: boolean
  }
}

function compareCollectDateWithDateNow(collectDate: Date) {
  const differenceToNow = differenceInDays(
    set(new Date(), { hours: 11, minutes: 0, seconds: 0 }),
    set(new Date(collectDate), { hours: 12, minutes: 0, seconds: 0 })
  )

  return differenceToNow >= 0
}

export function CollectsTableRow({ service }: CollectsTableRowProps) {
  const { userLogged } = useAuth()

  const userCanCollectService =
    userLogged?.permissions.includes('add-collect-service')

  const userCanValidateUnsuccessCollectService =
    userLogged?.permissions.includes('validate-unsuccess-collect-service')

  const userCanValidateNewCollectAddresses =
    userLogged?.permissions.includes('validate-new-collect-service-addresses')

  const userCanValidateNewDeliveryAddresses =
    userLogged?.permissions.includes('validate-new-delivery-service-addresses')

  const serviceInDelay = serviceDelay({
    timezoneOffset: service.source_tz,
    serviceDate: service.collect_date,
    serviceHour: service.collect_hour_end
  })

  const isCollectAvailableToStart = compareCollectDateWithDateNow(
    formatTimeByTimezoneUser({ serviceDateTime: new Date(service.collect_date), timezoneOffset: service.source_tz })
  )

  const pathRedirect = '/relacao/servicos?tab=collects&page=1'

  return (
    <Tr
      key={service.protocol}
      color={
        service.has_occurrence
          ? 'yellow.400'
          : serviceInDelay
            ? 'red.400'
            : service.has_unsuccess_collects_validation
              ? 'blue.400'
              : ''
      }
    >
      <Td>
        <Flex align="center" gap="3">
          <IconButton
            as={Link}
            to={`/servico/detalhes/${service.id}`}
            aria-label="Detalhes do serviço"
            size="sm"
            icon={<FaSearch />}
            variant="ghost"
          />

          {userCanCollectService && isCollectAvailableToStart && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              rounded="md"
              variant="ghost"
              to={{
                pathname: `/servicos/coletas/${service.id}`,
                state: {
                  path: pathRedirect
                }
              }}
            >
              Coletar
            </Button>
          )}

          {service.has_unsuccess_collects_validation && userCanValidateUnsuccessCollectService && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaCheckCircle />}
              rounded="md"
              variant="ghost"
              to={{
                pathname: `/servicos/coletas-sem-sucesso/${service.id}`,
                state: {
                  path: pathRedirect
                }
              }}
            >
              Validar Coletas Sem Sucesso
            </Button>
          )}
          {service.has_new_addresses_to_validate && (userCanValidateNewCollectAddresses || userCanValidateNewDeliveryAddresses) && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaCheckCircle />}
              rounded="md"
              variant="ghost"
              to={{
                pathname:
                  `/servico/${service.id}/validar-novos-enderecos`,
                state: {
                  path: pathRedirect
                }
              }}
            >
              Validar Novos Endereços
            </Button>
          )}
        </Flex>
      </Td>
      <Td>{service.protocol}</Td>
      <Td>
        <Text maxW="300px" whiteSpace="pre-line">
          {service.customer}
        </Text>
      </Td>
      <Td>{service.vehicle}</Td>
      <Td>
        {service?.source_addresses?.map((address, index) => {
          if (
            service.collected_addresses &&
            service.collected_addresses.includes(address.address)
          ) {
            return (
              <Text as="del" w="300px" key={index} whiteSpace="pre-line">
                {service.source_addresses.length > 1 && index + 1 + '.'} {address.address}
                <br />
              </Text>
            )
          }

          if (
            service.going_collect_addresses &&
            service.going_collect_addresses.find(goingAddress => goingAddress.id === address.id)
          ) {
            return (
              <Text w="300px" key={index} whiteSpace="pre-line" _hover={{
                opacity: 0.7
              }}>
                <Link
                  to={{
                    pathname: `/servico/${service.id}/finalizar/coleta/endereco/${address.id}`,
                    state: {
                      path: pathRedirect
                    }
                  }}
                >

                  {service.source_addresses.length > 1 && index + 1 + '.'} {address.address}
                </Link>
              </Text>
            )
          }

          return (
            <Text w="300px" key={index} whiteSpace="pre-line" _hover={{
              opacity: 0.7
            }}>
              <Link to={{
                pathname: `/servico/${service.id}/coleta/endereco/${address.id}`,
                state: {
                  path: pathRedirect
                }
              }}>
                {service.source_addresses.length > 1 && index + 1 + '.'} {address.address}
              </Link>
            </Text>
          )
        }
        )}
      </Td>
      <Td>
        {service.source_tz ? (
          <Flex align="center">
            {format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.collect_date), timezoneOffset: service.source_tz }), 'dd/MM/yyyy')}
            {Number(service.source_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
              <Tooltip label={service.source_tz}>
                <Box as="span" ml="2">
                  <Icon as={FaInfoCircle} />
                </Box>
              </Tooltip>
            )}
          </Flex>
        ) : (
          formatDate.handle(service.collect_date, "DateWithoutHourToShow")
        )}
      </Td>
      <Td>
        {service.source_tz ? (
          <Flex align="center">
            {`${format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.collect_hour_start), timezoneOffset: service.source_tz }), 'HH:mm')} às ${format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.collect_hour_end), timezoneOffset: service.source_tz }), 'HH:mm')}`}
            {Number(service.source_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
              <Tooltip label={service.source_tz}>
                <Box as="span" ml="2">
                  <Icon as={FaInfoCircle} />
                </Box>
              </Tooltip>
            )}
          </Flex>
        ) : (
          `${formatDate.handle(service.collect_hour_start, "DateOnlyWithHourMinute")}  às   ${formatDate.handle(service.collect_hour_end, "DateOnlyWithHourMinute")}`
        )}
      </Td>
      <Td>
        {service.source_tz ? (
          <>
            {service.service_type === 'FRACIONADO' ? (
              <Flex align="center">
                <Flex flexDirection='column'>
                  <Text>Embarque {service.shipping}</Text>
                  <Text>{`${service.source_branch} -> ${service.destination_branch}`}</Text>
                  <Text>{`até ${format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.board_hour), timezoneOffset: service.source_tz }), 'HH:mm')}`}</Text>
                </Flex>
                {Number(service.source_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
                  <Tooltip label={service.source_tz}>
                    <Box as="span" ml="2">
                      <Icon as={FaInfoCircle} />
                    </Box>
                  </Tooltip>
                )}
              </Flex>
            ) : (
              service.service_type === 'DEDICADO' && (
                <Flex align="center">
                  <Flex flexDirection='column'>
                    {isToday(new Date(service.delivery_date)) ? (
                      <Text>Entrega até {format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.delivery_hour), timezoneOffset: service.source_tz }), 'HH:mm')} </Text>
                    ) : (
                      <>
                        <Text>Entrega até {format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.delivery_hour), timezoneOffset: service.source_tz }), 'HH:mm')}</Text>
                        <Text>do dia {format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.delivery_date), timezoneOffset: service.source_tz }), 'dd/MM/yyyy')} </Text>
                      </>
                    )}
                  </Flex>
                  {Number(service.source_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
                    <Tooltip label={service.source_tz}>
                      <Box as="span" ml="2">
                        <Icon as={FaInfoCircle} />
                      </Box>
                    </Tooltip>
                  )}
                </Flex>
              )
            )
            }
          </>
        ) : (
          <>
            {service.service_type === 'FRACIONADO' ? (
              <Flex flexDirection='column'>
                <Text>Embarque {service.shipping}</Text>
                <Text>{`${service.source_branch} -> ${service.destination_branch}`}</Text>
                <Text>{`até  ${formatDate.handle(service.board_hour, "DateOnlyWithHourMinute")}`}</Text>
              </Flex>
            ) : (
              service.service_type === 'DEDICADO' && (
                <Flex flexDirection='column'>
                  {isToday(new Date(service.delivery_date)) ? (
                    <Text>Entrega até {formatDate.handle(service.delivery_hour, "DateOnlyWithHourMinute")}</Text>
                  ) : (
                    <>
                      <Text>Entrega até {formatDate.handle(service.delivery_hour, "DateOnlyWithHourMinute")}</Text>
                      <Text>do dia {formatDate.handle(service.delivery_date, "DateWithoutHourToShow")}</Text>
                    </>
                  )}
                </Flex>
              )
            )
            }
          </>
        )}

      </Td>
      <Td>
        {
          <Flex flexDirection='column'>
            {service.caixa_termica > 0 && (
              <Text>{service.caixa_termica} Caixa térmica</Text>
            )}
            {service.embalagem_secundaria > 0 && (
              <Text>{service.embalagem_secundaria} Embalagem secundária ziplock</Text>
            )}
            {service.gelo_seco > 0 && (
              <Text>{service.gelo_seco} KG Gelo seco</Text>
            )}
            {service.gelox > 0 && (
              <Text>{service.gelox} Gelox</Text>
            )}
            {service.isopor3l > 0 && (
              <Text>{service.isopor3l} Isopor 3l</Text>
            )}
            {service.isopor7l > 0 && (
              <Text>{service.isopor7l} Isopor 7l</Text>
            )}
            {service.terciaria3l > 0 && (
              <Text>{service.terciaria3l} Terciária 3l</Text>
            )}
            {service.terciaria8l > 0 && (
              <Text>{service.terciaria8l} Terciária 8l</Text>
            )}
          </Flex>
        }
      </Td>
      <Td>
        {service?.destination_addresses?.map((address, index) => (
          <Text w="300px" key={index} whiteSpace="pre-line">
            {service.destination_addresses.length > 1 && index + 1 + '.'} {address}
          </Text>
        ))}
      </Td>
      <Td>
        <Text w="500px" overflow="hidden" whiteSpace="pre-line">
          {service.observations !== '' ? service.observations : '-'}
        </Text>
      </Td>
      <Td>{service.source_collector}</Td>
      <Td>{service.destination_collector}</Td>
    </Tr >
  )
}
