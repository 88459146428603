import { Box, Flex, Icon } from '@chakra-ui/react'
import {
  PaginationNext,
  PaginationPageGroup,
  Pagination as AjnaPagination,
  PaginationPrevious,
  PaginationPage,
} from '@ajna/pagination'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

interface PaginationProps {
  pagesQuantity: number
  pages: number[]
  currentPage: number
  handlePageChange: (page: number) => void
}

export function Pagination({
  pagesQuantity,
  handlePageChange,
  pages,
  currentPage,
}: PaginationProps) {

  return (
    <Box overflowX="auto">
      <Flex justify="center" align="center" p="2">
        <AjnaPagination
          onPageChange={handlePageChange}
          pagesCount={pagesQuantity}
          currentPage={currentPage ?? 0}
        >
          <Flex align="center" justify="space-around" p="4">
            <PaginationPrevious>
              <Icon as={FiChevronLeft} />
            </PaginationPrevious>
            <PaginationPageGroup ml="2" mr="2" isInline align="center">
              {pages.map((page: number) => (
                <PaginationPage
                  w="8"
                  bg="blue.50"
                  fontSize="lg"
                  _hover={{
                    bg: 'blue.900',
                    color: 'blue.50',
                  }}
                  _current={{
                    w: '8',
                    bg: 'blue.900',
                    color: 'blue.50',
                    fontSize: 'sm',
                    _hover: {
                      bg: 'blue.700',
                    },
                  }}
                  key={`pagination_page_${page}`}
                  page={page}
                />
              ))}
            </PaginationPageGroup>
            <PaginationNext>
              <Icon as={FiChevronRight} />
            </PaginationNext>
          </Flex>
        </AjnaPagination>
      </Flex>
    </Box>
  )
}
