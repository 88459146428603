import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Select } from "components/Inputs/SelectInput";
import { useHubFunctions } from "hooks/hub/useHubFunctions";
import { useSwal } from "hooks/swal/useSwal";
import { useUsers } from "hooks/user/useUsers";
import { useForm } from "react-hook-form";
import * as yup from "yup"

interface UpdateHubResponsibleInputs {
  regional: 1 | 2 | 3 | 4 | 5
  hub_responsible: string
}

interface UpdateHubResponsibleModalProps {
  isUpdateHubResponsibleModalOpen: boolean
  onCloseUpdateHubResponsibleModal: () => void
}

const regionalOptions = [
  { key: "one", value: 1, showOption: "REGIONAL 1" },
  { key: "two", value: 2, showOption: "REGIONAL 2" },
  { key: "three", value: 3, showOption: "REGIONAL 3" },
  { key: "four", value: 4, showOption: "REGIONAL 4" },
  { key: "five", value: 5, showOption: "REGIONAL 5" },
]

const updateHubResponsibleSchema = yup.object().shape({
  regional: yup.number().required().typeError("Campo obrigatório"),
  hub_responsible: yup.string().required("Campo obrigatório")
})

export function UpdateHubResponsibleModal({
  isUpdateHubResponsibleModalOpen,
  onCloseUpdateHubResponsibleModal
}: UpdateHubResponsibleModalProps) {

  const {
    register,
    handleSubmit,
    reset,
    formState: {
      isSubmitting,
      errors
    }
  } = useForm<UpdateHubResponsibleInputs>({
    resolver: yupResolver(updateHubResponsibleSchema)
  })

  const { data: users, isFetching: isUsersFetching } = useUsers()

  const usersOptions = users
    ?.filter(user => user.situation === "ATIVO")
    .map(user => {
      return {
        key: user.id,
        value: user.id,
        showOption: `${user.firstname} ${user.lastname}`
      }
    })

  const { confirmMessage, standardMessage } = useSwal()

  const {
    updateHubResponsible: { mutateAsync: updateHubResponsible }
  } = useHubFunctions()

  async function handleUpdateHubResponsibleFromRegional(
    values: UpdateHubResponsibleInputs
  ) {
    const hasUpdateHubResponsibleFromRegional = await confirmMessage({
      title: "Deseja atualizar o responsável dos hubs com a regional selecionada?"
    })

    if (hasUpdateHubResponsibleFromRegional) {
      await updateHubResponsible(values, {
        onSuccess: () => {
          reset()
          onCloseUpdateHubResponsibleModal()
        }
      })
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  return (
    <Modal
      onClose={onCloseUpdateHubResponsibleModal}
      isOpen={isUpdateHubResponsibleModalOpen}
    >
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={handleSubmit(handleUpdateHubResponsibleFromRegional)}
        noValidate
      >
        <ModalHeader>Atualizar responsável por regional</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Stack spacing={6}>
            <Select
              {...register("regional")}
              name="regional"
              placeholder="Selecione uma opção..."
              error={errors.regional}
              label="Regional"
              options={regionalOptions}
              required
            />

            <Select
              {...register("hub_responsible")}
              name="hub_responsible"
              placeholder="Selecione uma opção..."
              error={errors.hub_responsible}
              label="Responsável"
              options={usersOptions}
              isDisabled={isUsersFetching}
              required
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Flex>
            <Button variant='ghost' mr={3} onClick={onCloseUpdateHubResponsibleModal}>
              Fechar
            </Button>
            <Button colorScheme='blue' isLoading={isSubmitting} type="submit">Salvar</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
