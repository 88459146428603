import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CompleteVisaQualityAuditBody {
  realizedDate: string
  hasTrainingSchedule: boolean
  hasAuditsSchedule: boolean
  hasUpdatedDocumentThatStandardizesCriticalDocuments: boolean
  hasUpdatedOrganizationalChart: boolean
  hasCargoAndFunctionsDetail: boolean
  hasAuditProcessDocument: boolean
  hasTrainingProcessDocument: boolean
  hasCleaningDesinfectionDocument: boolean
  hasBiossecurityRulesDocument: boolean
  hasCustomerPopPatternDocument: boolean
  nonCompliance: string
}

interface CompleteVisaQualityAuditParams {
  body: CompleteVisaQualityAuditBody
  auditId: string
}

export async function completeVisaQualityAudit({
  body,
  auditId
}: CompleteVisaQualityAuditParams) {
  try {
    await api.put(`/audits/${auditId}/visa-quality`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
