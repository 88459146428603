import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Stack,
  useMediaQuery,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye, FiTrash2 } from 'react-icons/fi'
import { useState, useEffect, Fragment } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { EmptyContentTableAlert } from '../../Alerts/EmptyContentTableAlert'
import { useSwal } from '../../../hooks/swal/useSwal'
import { useAuth } from '../../../hooks/auth/useAuth'
import { HolidayProps } from '../../../services/getFunctions/holiday/getHoliday'
import { useHolidayFunctions } from '../../../hooks/holiday/useHolidayFunctions'
import { formatDateToFrontWithDayAndMonth } from '../../../utils/GeneralFunctions/DateFunctions/formatDateToFrontWithDayAndMonth'

interface IHolidayTableProps {
  holidays: HolidayProps[]
}

const holidayOptions = [
  { key: 'name', value: 'Nome', checked: false },
  { key: 'date', value: 'Data', checked: false },
  { key: 'national', value: 'Nacional', checked: false },
  { key: 'city', value: 'Cidade', checked: false },
]

const holidayOrderOptions = [{ key: 'name', value: 'Nome' }]

export function HolidayTable({ holidays }: IHolidayTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [searchedHolidays, setSearchedHolidays] = useState<HolidayProps[]>([])
  const [slicedHolidays, setSlicedHolidays] = useState<HolidayProps[]>([])
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  // SEARCHBOX FILTERS
  const [nameFiltered, setNameFiltered] = useState('')
  const [dateFiltered, setDateFiltered] = useState('')
  const [cityFiltered, setCityFiltered] = useState('')
  const [nationalFiltered, setNationalFiltered] = useState('')
  // ---------------------------------------------------------------------------- //

  // HOOKS
  const {
    deleteHoliday: { mutateAsync: deleteHoliday },
  } = useHolidayFunctions()
  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useAuth()
  // ---------------------------------------------------------------------------- //

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedHolidays?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(holidayOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(holidayOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      setSlicedHolidays(holidays)
    }
    run()
  }, [holidays, currentPage])

  useEffect(() => {
    function run() {
      const newSlicedHubs = slicedHolidays.filter((holiday) => {
        const nameFilter = searchBoxFilter(holiday.name, nameFiltered)
        const dateFilter = searchBoxFilter(
          formatDateToFrontWithDayAndMonth(holiday.date),
          dateFiltered,
        )
        const cityFilter = searchBoxFilter(
          holiday.city_id ? holiday.cityIDHoliday.name : '-',
          cityFiltered,
        )
        const nationalFilter = searchBoxFilter(
          holiday.national === true ? 'SIM' : 'NÃO',
          nationalFiltered,
        )

        if (
          nameFiltered === '' &&
          dateFiltered === '' &&
          cityFiltered === '' &&
          nationalFiltered === ''
        ) {
          return holiday
        }
        return nameFilter && dateFilter && cityFilter && nationalFilter
      })

      if (orderOptionSelected.length > 0) {
        newSlicedHubs.sort(
          (a, b) =>
            (orderOptionSelected.includes('name') &&
              a.name.localeCompare(b.name)) ||
            0,
        )
        setSearchedHolidays(newSlicedHubs)
      } else {
        setSearchedHolidays(newSlicedHubs)
      }
    }
    run()
  }, [
    nameFiltered,
    dateFiltered,
    cityFiltered,
    nationalFiltered,
    slicedHolidays,
    orderOptionSelected,
  ])

  const handlePageChange = (page: number) => {
    return setCurrentPage(page)
  }

  const handleChangeSetter = (key: string, value: string) => {
    switch (key) {
      case 'name':
        return setNameFiltered(value)
      case 'date':
        return setDateFiltered(value)
      case 'city':
        return setCityFiltered(value)
      case 'national':
        return setNationalFiltered(value)
    }
  }

  const handleDeleteHoliday = async (holidayId: string) => {
    const hasConfirmed = await confirmMessage({
      title: 'Deseja excluir um feriado?',
    })

    if (hasConfirmed) {
      await deleteHoliday(holidayId)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (slicedHolidays.length <= 0) {
    return (
      <EmptyContentTableAlert
        title="Oops!"
        description="Não há dados para mostrar aqui!"
      />
    )
  }

  return (
    <>
      <Flex
        mb="2"
        wrap="wrap"
        justify={isWideVersion ? 'space-between' : 'flex-start'}
      >
        <Box>
          <TableFilterButton />
        </Box>
        <Box w={isWideVersion ? '' : 'full'}>
          <Stack mt={isWideVersion ? '0' : '2'} direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Flex key={option.key}>
                  <SearchBox
                    placeholder={`Buscar ${option.value}...`}
                    handleSearch={(e) =>
                      handleChangeSetter(option.key, e.target.value)
                    }
                  />
                </Flex>
              ))}
          </Stack>
        </Box>
      </Flex>
      <Box overflowX="auto" w="full">
        <Table w="full" colorScheme="gray" variant="striped" size="sm">
          <Thead>
            <Tr>
              {holidayOptions.map((option) => (
                <Th key={option.key} fontSize="12" color="blue.900">
                  {option.value.toUpperCase()}
                </Th>
              ))}
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {searchedHolidays
              .slice(offset, offset + Number(itemLimit))
              .map((holiday, index) => (
                <Tr key={index}>
                  <Td fontSize="12">{holiday.name.toUpperCase()}</Td>
                  <Td fontSize="12">
                    {formatDateToFrontWithDayAndMonth(
                      holiday.date,
                    ).toUpperCase()}
                  </Td>
                  <Td fontSize="12">
                    {holiday.national === true ? 'SIM' : 'NÃO'}
                  </Td>
                  <Td fontSize="12">
                    {holiday.city_id
                      ? holiday.cityIDHoliday.name.toUpperCase()
                      : '-'}
                  </Td>
                  <Td isNumeric>
                    {userLogged !== null && (
                      <>
                        {userLogged?.permissions.includes('view-holiday') && (
                          <Link to={`/feriado/visualizar/${holiday.id}`}>
                            <Icon cursor="pointer" as={FiEye} fontSize="20" />
                          </Link>
                        )}
                        {userLogged?.permissions.includes('edit-holiday') && (
                          <Link to={`/feriado/editar/${holiday.id}`}>
                            <Icon
                              cursor="pointer"
                              as={FiEdit3}
                              fontSize="20"
                              ml="4"
                              mt={['2', '2', '0']}
                            />
                          </Link>
                        )}
                        {userLogged?.permissions.includes('remove-holiday') && (
                          <Icon
                            cursor="pointer"
                            as={FiTrash2}
                            fontSize="20"
                            ml="4"
                            mt={['2', '2', '0']}
                            onClick={() => handleDeleteHoliday(holiday.id)}
                          />
                        )}
                      </>
                    )}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>
      <Pagination
        handlePageChange={handlePageChange}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </>
  )
}
