
import axios from "axios"
import { useAuth } from "hooks/auth/useAuth"
import { QueryKey, useQuery, UseQueryOptions } from "react-query"
import { api } from "services/api"
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum"
import { RequestError } from "utils/errors/RequestErrors"
import { BusinessServiceData } from "./dtos/BusinessService"

interface BusinessServicesQueryParams {
  pageSize?: number
  currentPage?: number
  customer?: string
  protocol?: string
  sourceCities?: string
  destinationCities?: string

}

type QueryOptions = UseQueryOptions<BusinessServiceData, unknown, BusinessServiceData, QueryKey>

interface UseBusinessServicesOptions {
  queryKey?: string
  queryParams?: BusinessServicesQueryParams,
  queryOptions?: QueryOptions
}

async function getBusinessServicesReqFunction(queryParams?: BusinessServicesQueryParams) {
  try {
    const { data } = await api.get('/services/business', {
      params: {
        ...queryParams
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

export function useBusinessServices(options?: UseBusinessServicesOptions) {

  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  return useQuery([
    options?.queryKey || 'businessServices',
    { ...options?.queryParams }
  ],
    () => getBusinessServicesReqFunction(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )

}
