import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Stack,
  Icon,
  useMediaQuery,
  useDisclosure,
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import { formatDateToFrontTable } from '../../../utils/GeneralFunctions/DateFunctions/formatDateToFrontTable'
import { Link } from 'react-router-dom'
import { FiCheckCircle, FiEdit3, FiTrash2 } from 'react-icons/fi'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { useAuth } from '../../../hooks/auth/useAuth'
import { EmptyContentTableAlert } from '../../Alerts/EmptyContentTableAlert'
import { RncProps } from '../../../utils/RequestFunctions/Rnc/requestRncFunctions'
import { useSwal } from '../../../hooks/swal/useSwal'
import { useRncFunctions } from '../../../hooks/rnc/useRncFunctions'
import { rncDict } from '../../../utils/RncFunctions/rncDict'
import { FaEye } from 'react-icons/fa'
import { FinishedRncModal } from '../../Modal/Rnc/FinishedRncModal'

interface IRncTableProps {
  rnc: RncProps[]
  isAlignment?: boolean
}

const rncOptionsSetter = (isAlignment?: boolean) => {
  if (isAlignment) {
    const rncOptions = [
      { key: 'protocol', value: 'Protocolo', checked: false },
      { key: 'direction', value: 'Diretoria', checked: false },
      { key: 'date', value: 'Data', checked: false },
      { key: 'opened_by', value: 'Aberta Por', checked: false },
      {
        key: 'intercurrence_responsible',
        value: 'Responsável',
        checked: false,
      },
      {
        key: 'business_meeting',
        value: 'Reunião de Tratativa',
        checked: false,
      },
      { key: 'status', value: 'Status', checked: false },
    ]

    return rncOptions
  }

  const rncOptions = [
    { key: 'protocol', value: 'Protocolo', checked: false },
    { key: 'direction', value: 'Diretoria', checked: false },
    { key: 'date', value: 'Data', checked: false },
    { key: 'opened_by', value: 'Aberta Por', checked: false },
    { key: 'intercurrence_responsible', value: 'Responsável', checked: false },
    { key: 'status', value: 'Status', checked: false },
  ]

  return rncOptions
}

const rncOrderOptions = [{ key: 'protocol', value: 'Protocolo' }]

export function RncTable({ rnc, isAlignment }: IRncTableProps) {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [searchedRnc, setSearchedRnc] = useState<RncProps[]>([])

  const [slicedRnc, setSlicedRnc] = useState<RncProps[]>([])

  const [rncID, setRncID] = useState('')

  const [protocolFiltered, setProtocolFiltered] = useState('')
  const [directionFiltered, setDirectionFiltered] = useState('')
  const [dateFiltered, setDateFiltered] = useState('')
  const [openedByFiltered, setOpenedByFiltered] = useState('')
  const [
    intercurrenceResponsibleFiltered,
    setIntercurrenceResponsibleFiltered,
  ] = useState('')
  const [businessMeetingFiltered, setBusinessMeetingFiltered] = useState('')
  const [statusFiltered, setStatusFiltered] = useState('')

  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { confirmMessage, standardMessage } = useSwal()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { userLogged } = useAuth()

  const {
    deleteRnc: { mutateAsync: deleteRnc },
  } = useRncFunctions()

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedRnc?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(rncOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(rncOptionsSetter(isAlignment))
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions, isAlignment])

  useEffect(() => {
    function run() {
      setSlicedRnc(rnc)
    }
    run()
  }, [itemLimit, currentPage, offset, rnc])

  useEffect(() => {
    function run() {
      const newSlicedRnc = slicedRnc?.filter((rnc) => {
        const protocolFilter = searchBoxFilter(
          String(rnc.protocol),
          protocolFiltered,
        )
        const directionFilter = searchBoxFilter(
          rnc.direction,
          directionFiltered,
        )
        const dateFilter = searchBoxFilter(
          `${formatDateToFrontTable(rnc.date)}`,
          dateFiltered,
        )
        const openedByFilter = searchBoxFilter(
          `${rnc.UserOpenedIDRNC.firstname} ${rnc.UserOpenedIDRNC.lastname}`,
          openedByFiltered,
        )
        const intercurrenceResponsibleFilter = searchBoxFilter(
          `${rnc.UserResponsibleIDRNC.firstname} ${rnc.UserResponsibleIDRNC.lastname}`,
          intercurrenceResponsibleFiltered,
        )
        const businessMeetingFilter = searchBoxFilter(
          rnc.business_meeting ? rnc.business_meeting : '-',
          businessMeetingFiltered,
        )
        const statusFilter = searchBoxFilter(
          rncDict(rnc.status),
          statusFiltered,
        )

        if (
          dateFiltered === '' &&
          protocolFiltered === '' &&
          directionFiltered === '' &&
          openedByFiltered === '' &&
          intercurrenceResponsibleFiltered === '' &&
          statusFiltered === '' &&
          businessMeetingFiltered === ''
        )
          return rnc

        return (
          dateFilter &&
          protocolFilter &&
          directionFilter &&
          openedByFilter &&
          intercurrenceResponsibleFilter &&
          businessMeetingFilter &&
          statusFilter
        )
      })

      if (orderOptionSelected.length > 0) {
        const slicedRncByOrder = newSlicedRnc.sort(
          (a, b) =>
            (orderOptionSelected.includes('protocol') &&
              Number(a.protocol) - Number(b.protocol)) ||
            0,
        )
        setSearchedRnc(slicedRncByOrder)
      } else {
        setSearchedRnc(newSlicedRnc)
      }
    }
    run()
  }, [
    dateFiltered,
    protocolFiltered,
    directionFiltered,
    statusFiltered,
    openedByFiltered,
    intercurrenceResponsibleFiltered,
    businessMeetingFiltered,
    slicedRnc,
    orderOptionSelected,
  ])

  const handleSetRncIdToOpenModal = (rncID: string) => {
    return setRncID(rncID)
  }

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  const handleChangeSetter = (key: string, value: string) => {
    switch (key) {
      case 'protocol':
        return setProtocolFiltered(value)
      case 'date':
        return setDateFiltered(value)
      case 'direction':
        return setDirectionFiltered(value)
      case 'opened_by':
        return setOpenedByFiltered(value)
      case 'intercurrence_responsible':
        return setIntercurrenceResponsibleFiltered(value)
      case 'business_meeting':
        return setBusinessMeetingFiltered(value)
      case 'status':
        return setStatusFiltered(value)
    }
  }
  async function handleDeleteRnc(rncID: string) {
    const hasDeleteRnc = await confirmMessage({
      title: 'Deseja excluir um RNC?',
    })

    if (hasDeleteRnc) {
      await deleteRnc(rncID)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  return (
    <>
      {slicedRnc?.length <= 0 ? (
        <EmptyContentTableAlert
          title="Oops!"
          description="Não há dados para mostrar aqui."
        />
      ) : (
        <>
          <Flex
            mb="2"
            wrap="wrap"
            justify={isWideVersion ? 'space-between' : 'flex-start'}
          >
            <Box>
              <TableFilterButton />
            </Box>
            <Box w={isWideVersion ? '' : 'full'}>
              <Stack mt="4" direction="column">
                {filterOptions
                  .filter((option) => option.checked === true)
                  .map((option) => (
                    <Flex key={option.key}>
                      <SearchBox
                        placeholder={`Buscar ${option.value}...`}
                        handleSearch={(e) =>
                          handleChangeSetter(option.key, e.target.value)
                        }
                      />
                    </Flex>
                  ))}
              </Stack>
            </Box>
          </Flex>
          <FinishedRncModal onClose={onClose} isOpen={isOpen} rncID={rncID} />
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  {rncOptionsSetter(isAlignment).map((option) => (
                    <Th key={option.key} fontSize="12" color="blue.900">
                      {option.value.toUpperCase()}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {searchedRnc
                  .slice(offset, offset + Number(itemLimit))
                  .map((rnc, index) => {
                    return (
                      <Tr key={index}>
                        <Td fontSize="12">{rnc.protocol}</Td>
                        <Td fontSize="12">{rnc.direction}</Td>
                        <Td fontSize="12">
                          {formatDateToFrontTable(rnc.date)}
                        </Td>
                        <Td fontSize="12">
                          {`${rnc.UserOpenedIDRNC.firstname} ${rnc.UserOpenedIDRNC.lastname}`.toUpperCase()}
                        </Td>
                        <Td fontSize="12">
                          {`${rnc.UserResponsibleIDRNC.firstname} ${rnc.UserResponsibleIDRNC.lastname}`.toUpperCase()}
                        </Td>
                        {rnc.status === 'AWAITING ALIGNMENT' && (
                          <Td fontSize="12">
                            {`${rnc.business_meeting}`.toUpperCase()}
                          </Td>
                        )}
                        <Td fontSize="12">{rncDict(rnc.status)}</Td>
                        <Td isNumeric>
                          {userLogged !== null && (
                            <>
                              {rnc.status === 'AWAITING RESPONSE' && (
                                <>
                                  {userLogged.permissions.includes('answer-rnc') && (
                                      <Link to={`/rnc/resposta/${rnc.id}`}>
                                        <Icon
                                          cursor="pointer"
                                          as={FiEdit3}
                                          fontSize="20"
                                          ml="4"
                                          mt={['2', '2', '0']}
                                        />
                                      </Link>
                                    )}
                                </>
                              )}
                              {rnc.status === 'AWAITING VALIDATION' && (
                                <>
                                  {userLogged.permissions.includes("validate-rnc") && (
                                      <Link to={`/rnc/validar/${rnc.id}`}>
                                        <Icon
                                          cursor="pointer"
                                          as={FiCheckCircle}
                                          fontSize="20"
                                          ml="4"
                                          mt={['2', '2', '0']}
                                        />
                                      </Link>
                                    )}
                                </>
                              )}
                              {rnc.status === 'AWAITING ALIGNMENT' && (
                                <>
                                  {userLogged.permissions.includes('align-rnc') && (
                                      <Link to={`/rnc/${rnc.id}/alinhar`}>
                                        <Icon
                                          cursor="pointer"
                                          as={FiCheckCircle}
                                          fontSize="20"
                                          ml="4"
                                          mt={['2', '2', '0']}
                                        />
                                      </Link>
                                    )}
                                </>
                              )}
                              {rnc.status === 'DONE' && (
                                <>
                                  {userLogged.permissions.includes('view-rnc-details') && (
                                      <Icon
                                        cursor="pointer"
                                        as={FaEye}
                                        fontSize="20"
                                        ml="4"
                                        mt={['2', '2', '0']}
                                        onClick={() => {
                                          handleSetRncIdToOpenModal(rnc.id)
                                          onOpen()
                                        }}
                                      />
                                    )}
                                </>
                              )}
                              {userLogged.permissions.includes('remove-rnc') && (
                                <Icon
                                  cursor="pointer"
                                  as={FiTrash2}
                                  fontSize="20"
                                  ml="4"
                                  mt={['2', '2', '0']}
                                  onClick={() => handleDeleteRnc(rnc.id)}
                                />
                              )}
                            </>
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </Box>
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}
