import { Button, Flex, ModalBody, ModalCloseButton, ModalContent, ModalHeader } from "@chakra-ui/react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm, } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useToastify } from "hooks/toastify/useToastify"
import { RequestPatimonyForm, RequestPatrimonySchema } from "./RequestPatimonyForm"
import { getPatrimonyRequest } from "api/patrimonies/getPatrimonyRequest"
import { useEffect } from "react"
import { addHours, format } from "date-fns"
import { editPatrimonyRequest } from "api/patrimonies/editPatrimonyRequest"


interface EditRequestPatrimonyProps {
  onCloseModal: () => void
  patrimonyRequestId: string
  patrimonyId: string
}

const editRequestPatrimonySchema = yup.object({
  limitDate: yup.string().required(),
  regional: yup.string().required(),
  sector: yup.string().required(),
  patrimonyTypes: yup.array().of(yup.object({
    patrimonyTypeId: yup.string().required(),
    quantity: yup.number().required(),
  }))

})


export function EditRequestPatrimony({
  onCloseModal,
  patrimonyRequestId,
  patrimonyId
}: EditRequestPatrimonyProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()


  const formMethods = useForm<RequestPatrimonySchema>({
    resolver: yupResolver(editRequestPatrimonySchema),
    defaultValues: {
      patrimonyTypes: [{
        patrimonyTypeId: '',
        quantity: 1
      }]
    },
  })

  const { formState: { isSubmitting }, handleSubmit, setValue } = formMethods

  const userTimezone = new Date().getTimezoneOffset() / 60

  const { data: patrimonyRequestResult } = useQuery({
    queryKey: ['patrimonyRequest', patrimonyRequestId],
    queryFn: () => getPatrimonyRequest({ patrimonyRequestId })
  })



  const patrimonyRequest = patrimonyRequestResult?.patrimonyRequest?.patrimonies.find(patrimonyRequest => patrimonyRequest.patrimony_id === patrimonyId)



  useEffect(() => {
    if (patrimonyRequestResult) {
      const formattedPatrimonyTypes = patrimonyRequestResult?.patrimonyRequest?.patrimonies?.map((patrimony) => ({
        patrimonyTypeId: patrimony.patrimonyType.id,
        quantity: patrimony.quantity,
      }));

      setValue('regional', patrimonyRequestResult?.patrimonyRequest?.regional)
      setValue('sector', patrimonyRequestResult?.patrimonyRequest?.sector)
      setValue('limitDate', format(addHours(new Date(patrimonyRequestResult?.patrimonyRequest.limit_date), userTimezone), 'yyyy-MM-dd'))
      setValue('patrimonyTypes', formattedPatrimonyTypes)
      setValue('responsible', patrimonyRequestResult?.patrimonyRequest?.responsible_name)

    }
  }, [patrimonyRequestResult, setValue, userTimezone])



  const { mutateAsync: editPatrimonyRequestFn } = useMutation({
    mutationFn: editPatrimonyRequest,
    onSuccess() {
      queryClient.invalidateQueries(['requestsPatrimony'])
      onCloseModal()
    }
  })

  async function handleRequestPatrimony({
    limitDate,
    regional,
    sector,
    patrimonyTypes,
    responsibleName,
    responsible
  }: RequestPatrimonySchema) {

    await promiseMessage(editPatrimonyRequestFn({
      body: {
        limitDate,
        regional,
        sector,
        patrimonyTypes,
        responsibleName: Boolean(responsibleName?.value) ? responsibleName.value : responsible
      },
      patrimonyRequestId: patrimonyRequestId
    }), 'Solicitação de patrimônio atualizada! 🎉')
  }


  return (
    <ModalContent>
      <ModalHeader>Editar solicitação de patrimônio</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleRequestPatrimony)}
        maxH='600px'
        overflowY='scroll'
      >
        <FormProvider {...formMethods}>
          <RequestPatimonyForm
            slug='edit'
            status={patrimonyRequest?.status}
          />
        </FormProvider>

        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Editar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent >
  )
}
