import { FormProvider, useForm, useWatch } from "react-hook-form";
import { StandardBackgroundForm } from "../../../../components/Form/StandardBackgroundForm";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { useService } from "../../../../hooks/services/service";
import { useHistory, useParams } from "react-router-dom";
import { useDriver } from "../../../../hooks/driver/useDriver";
import { useEffect } from "react";
import { Accordion, Flex } from "@chakra-ui/react";
import { GeneralContentLoading } from "../../../../components/Loading/GeneralContentLoading";
import { RequestedServiceInfoAccordion } from "../../../../components/Accordions/Service/RequestedServiceInfoAccordion";
import { useCollectServiceFunctions } from "../../../../hooks/collectService/useCollectServiceFunctions";
import { useSwal } from "../../../../hooks/swal/useSwal";
import { toast } from "react-toastify";
import { useGeolocation } from "../../../../hooks/useGeolocation";
import { EditCollectServiceForm } from "../components/EditCollectServiceForm";
import { useAddresses } from "../../../../hooks/address/useAddresses";
import { useProviders } from "hooks/provider/useProviders";
import { isAfter, isSameDay, set } from "date-fns";

interface IFormProps {
  step: string
  unsuccess_reason: string
  provider_id: string
  driver_id: string
  responsible_name: string
  collect_volume: number
  collect_sample: number
  has_edit_box_photo: string
  box_photos: {
    file: FileList
  }[]
  has_edit_content_declaration: string
  content_declarations: {
    file: FileList
  }[]
  has_edit_open_box_photo: string
  open_box_photo: FileList | string
  observation: string
  collects_total_volumes: number
}

interface IParams {
  service_id: string
  provider_id: string
}

const supportedFileFormats = [
  "image/jpeg",
  "image/pjpeg",
  "image/jpg",
  "image/png"
]
function validateHasFile(file: FileList) {
  if (!file?.length) return false

  return true
}

function validateFileType(file: FileList) {
  if (file?.length) {
    return supportedFileFormats.includes(file[0].type)
  }

  return true
}

function validateFileSize(file: FileList) {
  if (file?.length) {
    return file[0].size / 1024 / 1024 <= 15
  }

  return true
}

interface ValidateDateInputs {
  real_arrival_date: string,
  real_arrival_hour: string,
  deliveryDate: Date
}

function validateDate({
  real_arrival_date,
  real_arrival_hour,
  deliveryDate
}: ValidateDateInputs): boolean {

  const [year, month, date] = real_arrival_date.split('-')?.map(value => Number(value))
  const [hours, minutes] = real_arrival_hour.split(':')?.map(value => Number(value))

  const realDepartureTimestamp = set(new Date(), {
    date,
    month: month - 1,
    year: year,
    hours: hours,
    minutes: minutes
  })

  const currentDate = new Date();
  const isRealDepartureDateSameNow = isSameDay(realDepartureTimestamp, currentDate)

  if (deliveryDate && isAfter(realDepartureTimestamp, deliveryDate)) {
    throw new yup.ValidationError(
      'A nova data de coleta não pode ser maior que a data de entrega',
      real_arrival_date,
      'real_arrival_date'
    );
  }

  if (!isRealDepartureDateSameNow && isAfter(realDepartureTimestamp, currentDate)) {
    throw new yup.ValidationError(
      'A data não pode ser maior que a data atual',
      real_arrival_date,
      'real_arrival_date'
    );

  }
  return true;
}

async function alertGeolocationError(error: GeolocationPositionError) {
  if (error.code === 1) {
    toast.error("Para finalizar a coleta, é necessário que a localização esteja habilitada!")
  }
  if (error.code === 2) {
    toast.error("Localização não está disponível! Tente recarregar a página ou verifique as configurações do dispositivo.")
  }
}

const schema = yup.object().shape({
  step: yup.string().required("Campo Obrigatório"),
  provider_id: yup.string().required("Campo Obrigatório"),
  driver_id: yup.string().required("Campo Obrigatório"),
  unsuccess_reason: yup.mixed().nullable().when('step', {
    is: 'UNSUCCESS',
    then: yup.string().required("Campo Obrigatório"),
  }),
  has_edit_box_photo: yup.mixed().when('step', {
    is: 'DONE',
    then: yup.string().required("Campo Obrigatório")
  }),
  has_edit_content_declaration: yup.string().required("Campo Obrigatório"),
  has_edit_open_box_photo: yup.mixed().when('step', {
    is: 'DONE',
    then: yup.string().required("Campo Obrigatório")
  }),
  responsible_name: yup.string().required("Campo Obrigatório"),
  collect_volume: yup.mixed().when('step', {
    is: 'DONE',
    then: yup.number().typeError("Campo Obrigatório").required("Campo Obrigatório"),
  }),
  collect_sample: yup.mixed().when('step', {
    is: 'DONE',
    then: yup.number().typeError("Campo Obrigatório").required("Campo Obrigatório"),
  }),
  box_photo: yup.mixed().when("has_edit_box_photo", {
    is: 'SIM',
    then: yup.mixed()
      .test("length", "Campo obrigatório", value => validateHasFile(value))
      .test("type", "São suportados apenas os formatos de imagem: .jpeg, .pjpeg, jpg, png", value => validateFileType(value))
      .test("fileSize", "É suportado o upload de imagem somente até 15Mb", value => validateFileSize(value)),
  }),
  box_photos: yup.mixed().when("has_edit_box_photo", {
    is: 'SIM',
    then: yup.array().min(1, 'Campo obrigatório').of(yup.object({
      file: yup.mixed()
        .test("length", "Campo obrigatório", value => validateHasFile(value))
        .test("type", "São suportados apenas os formatos de imagem: .jpeg, .pjpeg, jpg, png", value => validateFileType(value))
        .test("fileSize", "É suportado o upload de imagem somente até 15Mb", value => validateFileSize(value)),
    }))
  }),
  content_declarations: yup.mixed().when("has_edit_content_declaration", {
    is: 'SIM',
    then: yup.array().min(1, 'Campo obrigatório').of(yup.object({
      file: yup.mixed()
        .test("length", "Campo obrigatório", value => validateHasFile(value))
        .test("type", "São suportados apenas os formatos de imagem: .jpeg, .pjpeg, jpg, png", value => validateFileType(value))
        .test("fileSize", "É suportado o upload de imagem somente até 15Mb", value => validateFileSize(value)),
    }))
  }),
  real_arrival_date: yup.string().when('$isEditRealArrivalTime', {
    is: true,
    then: yup.string().required('Campo obrigatório')
      .test('real_arrival_date', 'A nova data de coleta não pode ser menor que a data de entrega', async function (value) {
        return validateDate({
          real_arrival_date: value,
          real_arrival_hour: this.parent.real_arrival_hour,
          deliveryDate: new Date(this.options.context.deliveryDate)
        });
      })
  }),
  real_arrival_hour: yup.string().when('$isEditRealArrivalTime', {
    is: true,
    then: yup.string().required('Campo Obrigatório')
      .test('real_arrival_hour', 'O horário não pode ser maior que o horário atual', function (value) {
        return validateDate({
          real_arrival_hour: value,
          real_arrival_date: this.parent.real_arrival_date,
          deliveryDate: new Date(this.options.context.deliveryDate)
        });
      })
  }),
  collects_total_volumes: yup.number().typeError('Campo obrigatório'),
  observation: yup.string()
})

export function EditProviderCollectService() {
  const { service_id, provider_id } = useParams<IParams>()
  const { push: redirectTo } = useHistory()

  const {
    service: { data: service, isLoading: isServiceLoading }
  } = useService(service_id)
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()
  const {
    drivers: { data: drivers, isLoading: isDriversLoading }
  } = useDriver(null, true)
  const { data: providers, isLoading: isProvidersLoading } = useProviders();

  const {
    editCollectService: {
      mutateAsync: editCollectService, isLoading: isEditCollectServiceLoading, isSuccess: isEditCollectServiceSuccess
    }
  } = useCollectServiceFunctions()

  const { error: geolocationError, position } = useGeolocation()
  const { confirmMessage, standardMessage } = useSwal()

  const collectServiceFiltered = service?.serviceIDCollect
    .find(collect => collect.provider_id === provider_id)

  const formMethods = useForm<IFormProps>({
    resolver: yupResolver(schema),
    context: {
      deliveryDate: service?.serviceIDRequested.delivery_date,
      isEditRealArrivalTime: collectServiceFiltered.real_arrival_timestamp && collectServiceFiltered.real_arrival_timestamp_validated
    }
  })

  const { control, setValue, handleSubmit } = formMethods



  const stepSelected = useWatch({
    control,
    name: 'step'
  })

  useEffect(() => {
    if (stepSelected === 'UNSUCCESS' && collectServiceFiltered.step === 'DONE') {
      setValue('has_edit_content_declaration', 'SIM')
    }

    if (stepSelected === 'DONE' && collectServiceFiltered.step === 'UNSUCCESS') {
      setValue('has_edit_box_photo', 'SIM')
    }
  }, [setValue, stepSelected, collectServiceFiltered])

  useEffect(() => {
    if (collectServiceFiltered) {
      setValue("step", collectServiceFiltered.step)
      if (collectServiceFiltered.step === 'DONE') {
        setValue("provider_id", collectServiceFiltered?.provider_id)
        setValue("driver_id", collectServiceFiltered?.driver_id)
        setValue("responsible_name", collectServiceFiltered?.responsible_name!)
        setValue("collect_volume", collectServiceFiltered?.collect_volume!)
        setValue("collect_sample", collectServiceFiltered?.collect_sample!)
        setValue("observation", collectServiceFiltered?.observation!)
        setValue("collects_total_volumes", service?.collects_total_volumes)
      } else {
        setValue("provider_id", collectServiceFiltered?.address_id)
        setValue("driver_id", collectServiceFiltered?.driver_id)
        setValue("unsuccess_reason", collectServiceFiltered?.unsuccess_reason)
        setValue("responsible_name", collectServiceFiltered?.responsible_name!)
        setValue("observation", collectServiceFiltered?.observation!)
        setValue("collects_total_volumes", service?.collects_total_volumes)
      }
    }
  }, [setValue, collectServiceFiltered, service])

  useEffect(() => {
    if (isEditCollectServiceSuccess) {
      redirectTo(`/servicos/coletas/${service_id}`)
    }
  }, [redirectTo, service_id, isEditCollectServiceSuccess])

  async function handleEditCollectService(values: IFormProps) {
    const formData = new FormData()

    Object.entries(values).forEach(([key, value]) => {
      if (["box_photo", "content_declaration", "open_box_photo"].includes(key)) {
        if (value.length) {
          formData.append(key, value[0])
        }
      } else {
        if (key === "has_edit_box_photo") {
          formData.append("boxPhotoChoose", value)
        }
        if (key === "has_edit_content_declaration") {
          formData.append("contentDeclarationChoose", value)
        }
        if (key === "has_edit_open_box_photo") {
          formData.append("openBoxPhotoChoose", value)
        }
        formData.append(key, value)
      }
    })


    if (geolocationError) return await alertGeolocationError(geolocationError)

    if (values.content_declarations) {
      values.content_declarations.forEach((contentDeclaration) => {
        formData.append("content_declarations", contentDeclaration.file[0])
      })
    }

    if (values.box_photos) {
      values.box_photos.forEach((boxPhoto) => {
        formData.append("box_photos", boxPhoto.file[0])
      })
    }
    if (!values.open_box_photo) {
      if (collectServiceFiltered?.open_box_photo) {
        formData.append("open_box_photo", collectServiceFiltered.open_box_photo as string)
      }
    }
    formData.append("departure_latitude", String(position?.coords.latitude))
    formData.append("departure_longitude", String(position?.coords.longitude))
    formData.append("arrival_latitude", collectServiceFiltered?.arrival_latitude!)
    formData.append("arrival_longitude", collectServiceFiltered?.arrival_longitude!)
    formData.append("arrival_timestamp", collectServiceFiltered?.arrival_timestamp!)
    formData.append("departure_timestamp", String(new Date()))
    formData.append("service_id", service_id)
    formData.delete("address_id")

    const hasEditProviderCollect = await confirmMessage({ title: "Deseja editar uma coleta?" })

    if (hasEditProviderCollect) {
      await editCollectService({ input: formData, service_id })
    } else {
      await standardMessage("Ação cancelada!")
    }

  }

  if (isServiceLoading || isAddressesLoading || isDriversLoading || isEditCollectServiceLoading || isProvidersLoading) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm
      onSubmit={handleSubmit(handleEditCollectService)}
      title="Editar coleta"
    >
      <Flex direction="column" gap="4">
        <Accordion allowMultiple>
          <RequestedServiceInfoAccordion
            requestedServiceId={service?.serviceIDRequested.id!}
            addresses={addresses!}
          />
        </Accordion>
        <FormProvider {...formMethods}>
          <EditCollectServiceForm
            isProviderForm
            collectService={collectServiceFiltered!}
            providers={providers}
            drivers={drivers!}
          />
        </FormProvider>
      </Flex>
    </StandardBackgroundForm>
  )
}
