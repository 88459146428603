import { Box, Card, CardBody, CardHeader, Flex, Icon, IconButton, Modal, ModalOverlay, Table, Tbody, Td, Text, Tooltip, Tr, useDisclosure } from "@chakra-ui/react"
import { Interactions } from "components/Interactions/Interactions"
import { FaInfoCircle, FaPeopleArrows } from "react-icons/fa"

import { Link } from "react-router-dom"
import { captalize } from "utils/captalize"
import { serviceHandleStep } from "utils/ServiceFunctions/serviceDict"
import { interactionStatusColors } from "./DelayedCollects"

interface NextToDelayCollectCardProps {
  service: {
    id: string
    protocol: number
    step: string
    customer: string
    source_hub: string
    source_tz: string
    destination_hub: string
    destination_tz: string
    last_interaction_status: string
  }
}
export function NextToDelayCollectCard({ service }: NextToDelayCollectCardProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Card
      rounded="xl"
      transition="all 0.2s"
      _hover={{
        transform: 'translateY(-0.5rem)'
      }}
      h="full"
    >
      <CardHeader
        as={Link}
        display="flex"
        alignItems="center"
        to={`/servico/detalhes/${service.id}`}
        h="40px"
        bg="#38c3fa"
        borderTopRadius="xl"
      >
        <Text fontWeight="bold" color="white">{service.protocol}</Text>
      </CardHeader>

      <CardBody
        as={Flex}
        gap="6"
        direction="column"
      >
        <Table size="sm">
          <Tbody>
            <Tr>
              <Td fontSize='lg'>Status</Td>
              <Td isNumeric fontSize='lg'>{captalize(serviceHandleStep(service.step))}</Td>
            </Tr>
            <Tr>
              <Td fontSize='lg'>Cliente</Td>
              <Td isNumeric fontSize='lg'>{captalize(service.customer)}</Td>
            </Tr>
            <Tr>
              <Td fontSize='lg'>
                <Flex>
                  Hub de Origem
                  {Number(service.source_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
                    <Tooltip label={service.source_tz}>
                      <Box as="span" ml="2">
                        <Icon as={FaInfoCircle} />
                      </Box>
                    </Tooltip>
                  )}
                </Flex>
              </Td>
              <Td isNumeric fontSize='lg'>{captalize(service.source_hub)}</Td>
            </Tr>
            <Tr>
              <Td fontSize='lg'>
                <Flex>
                  Hub de Destino
                  {Number(service.destination_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
                    <Tooltip label={service.destination_tz}>
                      <Box as="span" ml="2">
                        <Icon as={FaInfoCircle} />
                      </Box>
                    </Tooltip>
                  )}
                </Flex>
              </Td>
              <Td isNumeric fontSize='lg'>{captalize(service.destination_hub)}</Td>
            </Tr>
          </Tbody>
        </Table>

        <Flex h="full" justify="flex-end" align="flex-end">

          <IconButton
            aria-label="Abrir Interações"
            icon={<FaPeopleArrows />}
            colorScheme={interactionStatusColors[service.last_interaction_status] || interactionStatusColors.default}
            size="sm"
            onClick={onOpen}

          />
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />

            <Interactions isOpen={isOpen} serviceId={service.id} />
          </Modal>

        </Flex>
      </CardBody>
    </Card>
  )
}
