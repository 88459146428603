import { api } from '../../../services/api'

export type ProfileProps = {
  id: string
  user_type: string
  permissions: string[]
  createdAt: string
  updatedAt: string
}

export type CreateProfileInputProps = {
  user_type: string
  permissions: string[]
}

type EditProfileInputProps = CreateProfileInputProps

export type EditProfileReqProps = {
  id: string
  input: EditProfileInputProps
}

async function getAllProfilesReqFunction() {
  try {
    const { data } = await api.get<ProfileProps[]>('/profile')

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function getOneProfileReqFunction(id: string) {
  try {
    const { data } = await api.get<ProfileProps>(`/profile/${id}`)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function createProfileReqFunction(input: CreateProfileInputProps) {
  try {
    const { data } = await api.post('/profile', input)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function editProfileReqFunction({ input, id }: EditProfileReqProps) {
  try {
    const { data } = await api.put(`/profile/${id}`, input)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function deleteProfileReqFunction(id: string) {
  try {
    const { data } = await api.delete(`/profile/${id}`)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

export {
  createProfileReqFunction,
  getAllProfilesReqFunction,
  getOneProfileReqFunction,
  editProfileReqFunction,
  deleteProfileReqFunction,
}
