import { MenuList, Flex, Avatar, IconButton, Spinner, Text } from "@chakra-ui/react"
import { Input } from "components/Inputs/Input"
import { User } from "hooks/user/dtos/User"
import { useState } from "react"
import { FaTimes } from "react-icons/fa"
import { searchBoxFilter } from "utils/searchBoxFilter"

type AssignUserToBugProps = {
  bugId: string
  userId: string
}

type RemoveAssignedUserFromBugProps = AssignUserToBugProps

type SearchAssignedUsersProps = {
  users: User[]
  isFetchingUsersData: boolean
  assignedUsers: Pick<User, 'id' | 'firstname' | 'lastname'>[]
  onRemoveAssignedUserFromBug: (input: RemoveAssignedUserFromBugProps, status: 'open' | 'validated') => Promise<void>
  onAssignUserToBug: (input: AssignUserToBugProps, status: 'open' | 'validated') => Promise<void>
  status: 'open' | 'validated'
  bugId: string
}

export function SearchAssignedUsers({
  users,
  isFetchingUsersData,
  assignedUsers,
  onRemoveAssignedUserFromBug,
  onAssignUserToBug,
  status,
  bugId,
}: SearchAssignedUsersProps) {
  const [userFiltered, setUserFiltered] = useState('')

  const filteredUsers = users?.filter((user) => {
    const userFilter = userFiltered
      ? searchBoxFilter(`${user.firstname} ${user.lastname}}`, userFiltered)
      : true

    return userFilter
  })

  return (
    <MenuList
      p="4"
      minW="300"
      maxW="200"
    >
      <Input
        name="user"
        size="sm"
        placeholder="Digite um nome"
        value={userFiltered}
        onChange={(event) => setUserFiltered(event.target.value)}
      />
      <Flex
        maxH="200px"
        direction="column"
        overflowY="auto"
        gap="2"
        mt="4"
        p="1"
      >
        <Text fontWeight="bold" fontSize="sm" color="black">Atribuídos</Text>
        {assignedUsers?.map((assignedUser) => {
          return (
            <Flex
              w="full"
              key={assignedUser.id}
              gap="1"
              align="center"
              _hover={{
                bg: "gray.100"
              }}
              p="2"
              rounded="md"
              cursor="pointer"
              justify="space-between"
              onClick={() => {
                onRemoveAssignedUserFromBug(
                  {
                    bugId: bugId,
                    userId: assignedUser.id
                  },
                  status
                )
              }}
            >
              <Flex
                gap="1"
                align="center"
              >
                <Avatar
                  size="sm"
                  name={`${assignedUser.firstname} ${assignedUser.lastname}`}
                />

                <Text fontSize="xs">{`${assignedUser.firstname} ${assignedUser.lastname}`}</Text>
              </Flex>
              <IconButton
                aria-label="Remover atribuíção"
                icon={<FaTimes />}
                size="sm"
                justifySelf="flex-end"
                variant="unstyled"
              />
            </Flex>
          )
        })}
      </Flex>


      {isFetchingUsersData ? (
        <Spinner mt="4" />
      ) : (
        <Flex
          maxH="200px"
          direction="column"
          overflowY="auto"
          gap="2"
          mt="4"
          p="1"
        >

          {filteredUsers?.map((user) => {
            return (
              <Flex
                key={user.id}
                gap="1"
                align="center"
                _hover={{
                  bg: "gray.100"
                }}
                p="2"
                rounded="md"
                cursor="pointer"
                onClick={() => {
                  onAssignUserToBug(
                    {
                      bugId: bugId,
                      userId: user.id
                    },
                    status
                  )
                }}
              >
                <Avatar
                  size="sm"
                  name={`${user.firstname} ${user.lastname}`}
                />

                <Text fontSize="xs">{`${user.firstname} ${user.lastname}`}</Text>
              </Flex>
            )
          })}
        </Flex>
      )}



    </MenuList>
  )
}
