import axios from "axios";
import { Collector } from "hooks/collector/dtos/Collector";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

export interface GetSpecialBudgetCostsResponse {
  budgetSpecialCosts: {
    id: string
    valueInCents: number
    collectors: {
      collector: Collector
    }[]
  }[]
}

interface GetSpecialBudgetCostsParams {
  budgetId: string
}

export async function getSpecialBudgetCosts({
  budgetId
}: GetSpecialBudgetCostsParams) {
  try {
    const response = await api.get<GetSpecialBudgetCostsResponse>(`/budgets/${budgetId}/special-costs`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
