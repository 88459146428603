import { differenceInMinutes } from 'date-fns'

export function recurrentServiceSortByDate(dateA: string, dateB: string) {
  const difference = differenceInMinutes(new Date(dateA), new Date(dateB))

  if (difference < 0) {
    return -1
  }

  if (difference > 0) {
    return 1
  }

  return 0
}
