import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { TextArea } from "components/Inputs/TextInput";
import { useAuth } from "hooks/auth/useAuth";
import { ComunicationTypes } from "hooks/internComunication/dtos/InternComunication";
import { useInternComunicationsFunctions } from "hooks/internComunication/useInternComunicationsFunctions";
import { useInternComunicationSocket } from "hooks/socket/useInternComunicationSocket";
import { useSwal } from "hooks/swal/useSwal";
import { useToastify } from "hooks/toastify/useToastify";
import { useUsers } from "hooks/user/useUsers";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { compareComunicationDeadlineWithDateNow } from "utils/internComunications/compareComunicationDeadlineWithDateNow";
import * as yup from "yup"
import { AttachInternComunicationAttachmentModal } from "../AttachInternComunicationPhotoModal";

interface StandardInternComunicationInputs {
  comunication_type: ComunicationTypes
  comunication_responsible: string
  comunication_question: string
  comunication_deadline: string
}

interface StandardInternComunicationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const comunicationTypes = [
  { key: 'logisticsDirectionLastMile', value: 'DIRETORIA DE LOGÍSTICA - LAST MILE', showOption: 'DIRETORIA DE LOGÍSTICA - LAST MILE' },
  { key: 'logisticsDirectionPlanning', value: 'DIRETORIA DE LOGÍSTICA - PLANEJAMENTO', showOption: 'DIRETORIA DE LOGÍSTICA - PLANEJAMENTO' },
  { key: "commercialDirection", value: 'DIRETORIA COMERCIAL', showOption: 'DIRETORIA COMERCIAL' },
  { key: "financialDirection", value: 'DIRETORIA FINANCEIRA', showOption: 'DIRETORIA FINANCEIRA' },
  { key: "qualityAndRegulationDirection", value: 'DIRETORIA DE REGULAÇÃO E QUALIDADE', showOption: 'DIRETORIA DE REGULAÇÃO E QUALIDADE' },
  { key: "techDirection", value: 'DIRETORIA DE TECNOLOGIA', showOption: 'DIRETORIA DE TECNOLOGIA' },
  { key: "peopleDirection", value: 'DIRETORIA DE GENTE', showOption: 'DIRETORIA DE GENTE' },
]

const standardInternComunicationFormSchema = yup.object().shape({
  comunication_type: yup.string().required("Campo obrigatório"),
  comunication_responsible: yup.string().required("Campo obrigatório"),
  comunication_question: yup.string().required("Campo obrigatório"),
  comunication_deadline: yup.string().test({
    name: 'compare_deadline_with_now',
    test: compareComunicationDeadlineWithDateNow,
    message: 'O prazo deve ser de no mínimo 15 minutos e no máximo 7 dias após a abertura'
  }).required("Campo obrigatório"),
})

export function StandardInternComunicationModal({
  isOpen,
  onClose,
}: StandardInternComunicationModalProps) {
  const { userLogged } = useAuth()
  const [internComunicationId, setInternComunicationId] = useState()

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<StandardInternComunicationInputs>({
    resolver: yupResolver(standardInternComunicationFormSchema)
  })

  const [comunicationTypeSelected, comunicationResponsibleSelected] = watch([
    "comunication_type",
    "comunication_responsible"
  ])

  const isComunicationTypeSelected = !!comunicationTypeSelected
  const isComunicationResponsibleSelected = !!comunicationResponsibleSelected

  const { data: users, isFetching: isFetchingUsers } = useUsers({
    queryParms: { situation: 'ATIVO' }
  })

const usersFilteredByComunicationTypeOptions = users?.filter(user => {

    if (comunicationTypeSelected === 'DIRETORIA COMERCIAL') {
      const comercialUserTypes = [
        "SUCESSO DO CLIENTE - COMERCIAL",
        "COMERCIAL - DIRETORIA",
      ]

      return comercialUserTypes.includes(user.user_type)
    }

    if (comunicationTypeSelected === 'DIRETORIA FINANCEIRA') {
      const financeDirectionUserTypes = [
        "ADMINISTRATIVO FINANCEIRO - DIRETORIA",
        "FINANCEIRO - GERAL",
        "ADMINISTRATIVO FINANCEIRO - GERÊNCIA",
        "ADMINISTRATIVO FINANCEIRO - JOVEM APRENDIZ",
        "ADMINISTRATIVO FINANCEIRO - ALMOXARIFADO",
        "ADMINISTRATIVO FINANCEIRO - COMPRAS",
        "ADMINISTRATIVO FINANCEIRO - FINANCEIRO",
      ]

      return financeDirectionUserTypes.includes(user.user_type)
    }

    if (comunicationTypeSelected === 'DIRETORIA DE REGULAÇÃO E QUALIDADE') {
      const qualityAndRegulationDirectionUserTypes = [
        "REGULAÇÃO E QUALIDADE - ANALISTA",
        "REGULAÇÃO E QUALIDADE - DIRETORIA",
        "REGULAÇÃO E QUALIDADE - JOVEM APRENDIZ",
      ]

      return qualityAndRegulationDirectionUserTypes.includes(user.user_type)
    }

    if (comunicationTypeSelected === 'DIRETORIA DE LOGÍSTICA - PLANEJAMENTO') {
      const operationUserTypes = [
        "SUCESSO DO CLIENTE - TORRE DE CONTROLE",
        "OPERAÇÃO - GERÊNCIA",
        "SUCESSO DO CLIENTE - DIRETORIA",
        "SUCESSO DO CLIENTE - TORRE DE CONTROLE",
        "SUCESSO DO CLIENTE - GERÊNCIA",
        "SUCESSO DO CLIENTE - SUPERVISÃO",
        "SUCESSO DO CLIENTE - JOVEM APRENDIZ",
      ]

      return operationUserTypes.includes(user.user_type)
    }

    if (comunicationTypeSelected === "DIRETORIA DE TECNOLOGIA") {
      return user.user_type === "ADMIN"
    }

    if (comunicationTypeSelected === "DIRETORIA DE LOGÍSTICA - LAST MILE") {
      return user.user_type === 'SUCESSO DO CLIENTE - LAST MILE'
    }

    if (comunicationTypeSelected === "DIRETORIA DE GENTE") {
      return user.user_type.includes('GENTE')
    }

    return user.user_type.includes(comunicationTypeSelected)
  })
    .map(user => {
      return {
        key: user.id,
        value: user.id,
        showOption: `${user.firstname} ${user.lastname}`
      }
    })

  const {
    createInternComunication: { mutateAsync: createInternComunication }
  } = useInternComunicationsFunctions()

  const { internComunicationSocketConnection } = useInternComunicationSocket()

  const {
    isOpen: isAttachInternComunicationAttachmentModalOpen,
    onClose: onCloseAttachInternComunicationAttachmentModal,
    onOpen: onOpenAttachInternComunicationPhotoModal
  } = useDisclosure()

  const { confirmMessage } = useSwal()
  const { promiseMessage } = useToastify()

  const queryClient = useQueryClient()

  async function handleCreateNewInternComunication(
    values: StandardInternComunicationInputs
  ) {
    await promiseMessage(createInternComunication({
      ...values,
      comunication_deadline: new Date(values.comunication_deadline),
    }, {
      onSuccess: async (data) => {
        internComunicationSocketConnection?.emit("internComunicationCreated", {
          comunication_responsible: values.comunication_responsible
        })
        setInternComunicationId(() => data.id)
        reset()
        onClose()
        const hasAttachInternComunicationPhoto = await confirmMessage({
          title: 'Deseja adicionar um arquivo à comunicação interna?',
          text: '',
          buttons: ['Não', 'Sim']
        })

        if (hasAttachInternComunicationPhoto) {
          onOpenAttachInternComunicationPhotoModal()
        } else {
          queryClient.invalidateQueries({
            queryKey: ['owner-intern-comunications', userLogged?.id]
          })
        }

      }
    }), 'Comunicação interna criada com sucesso!')
  }

  return (
    <>
      <AttachInternComunicationAttachmentModal
        isAttachInternComunicationAttachmentModalOpen={isAttachInternComunicationAttachmentModalOpen}
        onCloseAttachInternComunicationAttachmentModal={onCloseAttachInternComunicationAttachmentModal}
        internComunicationId={internComunicationId ?? ''}
      />
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nova comunicação interna</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            as="form"
            onSubmit={handleSubmit(handleCreateNewInternComunication)}
            noValidate
          >
            <Flex gap={6} direction="column" p={4}>
              <Select
                {...register("comunication_type")}
                name="comunication_type"
                options={comunicationTypes}
                label="Tipo de comunicação"
                placeholder="Selecione uma opção..."
                error={errors.comunication_type}
                required
              />

              {isComunicationTypeSelected && (
                <Select
                  {...register("comunication_responsible")}
                  name="comunication_responsible"
                  options={usersFilteredByComunicationTypeOptions}
                  label="Responsável"
                  placeholder={isFetchingUsers ? "Carregando..." : "Selecione uma opção..."}
                  isDisabled={isFetchingUsers}
                  error={errors.comunication_responsible}
                  required
                />
              )}

              {isComunicationTypeSelected && isComunicationResponsibleSelected && (
                <>
                  <Input
                    {...register("comunication_deadline")}
                    name="comunication_deadline"
                    label="Prazo para resposta"
                    type="datetime-local"
                    error={errors.comunication_deadline}
                    required
                  />

                  <TextArea
                    {...register("comunication_question")}
                    name="comunication_question"
                    label="Descrição"
                    error={errors.comunication_question}
                    required
                  />
                </>
              )}
            </Flex>
            <ModalFooter gap={4}>
              <Button colorScheme="blue" isLoading={isSubmitting} type="submit">
                Enviar
              </Button>
              <Button onClick={onClose} variant="ghost">Fechar</Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
