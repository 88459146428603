import { addDays, format, set } from 'date-fns'
import { GeloSecoAddressesProps } from 'utils/RequestFunctions/Service/Request/requestRequestServiceFunctions'
import { splitPlannedRouteValue } from './splitPlannedRouteValue'

interface IAttachments {
  attachment: FileList
}

type ValidateEditServiceRequest = {
  customer_id: string
  budget_id: string
  owner: string
  source_address_id: string[]
  destination_address_id: string[]
  destination_addresses_input:
  | {
    address_id: string
    quantity: number
  }[]
  | null
  gelo_seco_addresses: GeloSecoAddressesProps[]

  is_edit_page: boolean
  has_crossdocking: string
  shipping_id: string

  crossdocking_collector_id: string | null
  source_crossdocking_branch_id: string | null
  destination_crossdocking_branch_id: string | null
  source_collector_id: string | null
  destination_collector_id: string | null
  source_branch_id: string | null
  destination_branch_id: string | null
  provider_id: string | null
  deadline: number

  crossdocking_planned_flight: string | null
  planned_flight: string
  crossdocking_board_date: string | null
  crossdocking_board_hour: string | null
  board_date: string
  board_hour: string

  crossdocking_availability_forecast_day: string | null
  crossdocking_availability_forecast_time: string | null

  planned_connection_flight: string | null
  crossdocking_planned_connection_flight: string | null

  availability_forecast_day: string
  availability_forecast_time: string
  service_type: string
  franchising: number
  modal: string
  crossdocking_modal: string
  vehicle: string
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  collect_date: string
  collect_hour_start: string
  collect_hour_end: string
  delivery_date: string
  delivery_hour: string
  material_type: string
  observation: string
  attachments?: IAttachments[]
}

export function serviceFormatDataToBackEdit(
  values: ValidateEditServiceRequest,
) {
  const sourceAddresses =
    values.source_address_id.toString().split(',').length > 1
      ? values.source_address_id
      : values.source_address_id.toString().split('  ')
  const destinationAddresses =
    values.destination_address_id.toString().split(',').length > 1
      ? values.destination_address_id
      : values.destination_address_id.toString().split('  ')

  const [collectHourStart, collectMinuteStart] =
    values.collect_hour_start.split(':')
  const [collectHourEnd, collectMinuteEnd] = values.collect_hour_end.split(':')
  const [deliveryHour, deliveryMinute] = values.delivery_hour.split(':')

  const boardHour = values.board_hour ? values.board_hour.split(':') : null
  const crossdockingBoardHour = values.crossdocking_board_hour
    ? values.crossdocking_board_hour.split(':')
    : null
  const dateAvailabilityForecast = values.availability_forecast_day
    ? values.availability_forecast_day.split('-')
    : null
  const hourAvailabilityForecast = values.availability_forecast_time
    ? values.availability_forecast_time.split(':')
    : null
  const crossdockingDateAvailabilityForecast =
    values.crossdocking_availability_forecast_day
      ? values.crossdocking_availability_forecast_day.split('-')
      : null
  const crossdockingHourAvailabilityForecast =
    values.crossdocking_availability_forecast_time
      ? values.crossdocking_availability_forecast_time.split(':')
      : null

  const formatedDateCollectStart = format(
    set(addDays(new Date(values.collect_date), 1), {
      hours: Number(collectHourStart),
      minutes: Number(collectMinuteStart),
    }),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )

  const formatedDateCollectEnd = format(
    set(addDays(new Date(values.collect_date), 1), {
      hours: Number(collectHourEnd),
      minutes: Number(collectMinuteEnd),
    }),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )

  const formatedDateDelivery = format(
    set(addDays(new Date(values.delivery_date), 1), {
      hours: Number(deliveryHour),
      minutes: Number(deliveryMinute),
    }),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )

  const dateToBoard =
    boardHour !== null
      ? set(new Date(), {
        hours: Number(boardHour[0]),
        minutes: Number(boardHour[1]),
      })
      : null

  const crossdockingDateToBoard =
    crossdockingBoardHour !== null
      ? set(new Date(), {
        hours: Number(crossdockingBoardHour[0]),
        minutes: Number(crossdockingBoardHour[1]),
      })
      : null

  const dateSettedAvailabilityForecast =
    dateAvailabilityForecast !== null
      ? set(new Date(), {
        date: Number(dateAvailabilityForecast[2]),
        month: Number(dateAvailabilityForecast[1]) - 1,
        year: Number(dateAvailabilityForecast[0]),
        hours: 12,
        minutes: 0,
      })
      : null

  const hourSettedToAvailabilityForecast =
    hourAvailabilityForecast !== null
      ? set(new Date(), {
        hours: Number(hourAvailabilityForecast[0]),
        minutes: Number(hourAvailabilityForecast[1]),
      })
      : null

  const dateSettedCrossdockingAvailabilityForecast =
    crossdockingDateAvailabilityForecast !== null
      ? set(new Date(), {
        date: Number(crossdockingDateAvailabilityForecast[2]),
        month: Number(crossdockingDateAvailabilityForecast[1]) - 1,
        year: Number(crossdockingDateAvailabilityForecast[0]),
        hours: 12,
        minutes: 0,
      })
      : null

  const hourSettedCrossdockingAvailabilityForecast =
    crossdockingHourAvailabilityForecast !== null
      ? set(new Date(), {
        hours: Number(crossdockingHourAvailabilityForecast[0]),
        minutes: Number(crossdockingHourAvailabilityForecast[1]),
      })
      : null

  const formatedDateBoard =
    dateToBoard !== null
      ? format(dateToBoard, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
      : null
  const formatedCrossdockingDateBoard =
    crossdockingDateToBoard !== null
      ? format(crossdockingDateToBoard, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
      : null
  const formatedDateAvailabilityForecast =
    dateSettedAvailabilityForecast !== null
      ? format(dateSettedAvailabilityForecast, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
      : null
  const formatedHourAvailabilityForecast =
    hourSettedToAvailabilityForecast !== null
      ? format(hourSettedToAvailabilityForecast, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
      : null
  const formatedCrossdockingDateAvailabilityForecast =
    dateSettedCrossdockingAvailabilityForecast !== null
      ? format(
        dateSettedCrossdockingAvailabilityForecast,
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
      )
      : null
  const formatedCrossdockingHourAvailabilityForecast =
    hourSettedCrossdockingAvailabilityForecast !== null
      ? format(
        hourSettedCrossdockingAvailabilityForecast,
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
      )
      : null

  const serviceFormatedDataToBackEdit = {
    ...values,
    source_address_id: sourceAddresses,
    destination_address_id: destinationAddresses,
    destination_addresses_input:
      values.material_type === 'CORRELATOS' ||
        values.material_type === 'CARGA GERAL'
        ? values.destination_addresses_input
        : null,
    source_collector_id: values.source_collector_id
      ? values.source_collector_id
      : null,
    crossdocking_collector_id: values.crossdocking_collector_id
      ? values.crossdocking_collector_id
      : null,
    destination_collector_id: values.destination_collector_id
      ? values.destination_collector_id
      : null,
    source_branch_id: values.source_branch_id ? values.source_branch_id : null,
    source_crossdocking_branch_id: values.source_crossdocking_branch_id
      ? values.source_crossdocking_branch_id
      : null,
    destination_crossdocking_branch_id:
      values.destination_crossdocking_branch_id
        ? values.destination_crossdocking_branch_id
        : null,
    destination_branch_id: values.destination_branch_id
      ? values.destination_branch_id
      : null,
    provider_id: values.provider_id ? values.provider_id : null,
    crossdocking_availability_forecast_day:
      formatedCrossdockingDateAvailabilityForecast,
    crossdocking_availability_forecast_time:
      formatedCrossdockingHourAvailabilityForecast,
    availability_forecast_day: formatedDateAvailabilityForecast,
    availability_forecast_time: formatedHourAvailabilityForecast,
    collect_date: formatedDateCollectStart,
    collect_hour_start: formatedDateCollectStart,
    collect_hour_end: formatedDateCollectEnd,
    delivery_date: formatedDateDelivery,
    delivery_hour: formatedDateDelivery,
    planned_flight: values.planned_connection_flight
      ? `${splitPlannedRouteValue(values.planned_flight)} => ${splitPlannedRouteValue(values.planned_connection_flight)}`
      : splitPlannedRouteValue(values.planned_flight),
    crossdocking_planned_flight: values.crossdocking_planned_flight
      ? values.crossdocking_planned_connection_flight
        ? `${splitPlannedRouteValue(values.crossdocking_planned_connection_flight)} => ${splitPlannedRouteValue(values.crossdocking_planned_flight)}`
        : splitPlannedRouteValue(values.crossdocking_planned_flight)
      : null,
    crossdocking_board_date: values.crossdocking_board_date
      ? values.crossdocking_board_date
      : null,
    crossdocking_board_hour: formatedCrossdockingDateBoard,
    crossdocking_modal: values.crossdocking_modal
      ? values.crossdocking_modal
      : null,
    board_date: values.board_date ? values.board_date : null,
    board_hour: formatedDateBoard,
    gelo_seco_addresses: values.gelo_seco_addresses.length ? values.gelo_seco_addresses : null
  }

  return serviceFormatedDataToBackEdit
}
