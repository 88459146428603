import { Button, Flex, FormLabel, Icon, Image, Stack, Text, Link as ChakraLink } from "@chakra-ui/react";
import { Address } from "hooks/address/dtos/Address";
import { useCallback, useEffect } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { FaFile, FaPlus, FaTimes } from "react-icons/fa";
import { FiFile } from "react-icons/fi";
import { Link } from "react-router-dom";
import { unsuccessReasonOptions } from "utils/CustomLists/unsuccessCollectReasonOptions";
import { Input } from "../../../../components/Inputs/Input";
import { Select } from "../../../../components/Inputs/SelectInput";
import { TextArea } from "../../../../components/Inputs/TextInput";
import { ProviderProps } from "../../../../contexts/ProviderContext";
import { IDriverProps } from "../../../../services/getFunctions/driver/getDrivers";
import { negative_positive } from "../../../../utils/customLists";

interface Attachments {
  attachment: FileList
}

interface IFormProps {
  address_id?: string
  provider_id?: string
  driver_id: string
  responsible_name: string
  collect_volume?: number
  collect_sample?: number
  gelo_seco_box_quantity?: number
  is_unsuccess: string
  box_photo?: FileList
  box_photos?: Attachments[] | null
  content_declaration?: FileList
  content_declarations?: Attachments[] | null
  open_box_photo?: FileList
  unsuccess_reason?: 'RESPONSÁVEL NÃO LOCALIZADO' | 'LOCAL FECHADO' | 'PACIENTE NÃO COMPARECEU, SEM AVISO.' | 'CANCELAMENTO FORA DO PRAZO' | 'SEM MATERIAL PARA ENVIO' | 'DOCUMENTAÇÃO INCORRETA OU FALTANTE'
  real_arrival_date: string
  real_arrival_hour: string
  observation: string
  temp: number
  materialPackedWithDryIce: string
}

interface IFinishCollectServiceForm {
  isProviderForm?: boolean;
  addressHasGeloSeco?: boolean
  providers?: ProviderProps[]
  addresses?: Address[]
  drivers: IDriverProps[]
  deliveryDate?: string
  hasTempCheck?: boolean
}

const supportedFileFormats = [
  "image/jpeg",
  "image/pjpeg",
  "image/jpg",
  "image/png"
]


export function FinishCollectServiceForm({
  isProviderForm = false,
  addressHasGeloSeco = false,
  providers,
  addresses,
  drivers,
  deliveryDate,
  hasTempCheck = false
}: IFinishCollectServiceForm) {

  const { register, watch, setValue, control, formState: { errors } } = useFormContext<IFormProps>()

  const openBoxPhoto = watch("open_box_photo")
  const isUnsucessCollect = watch("is_unsuccess")


  const openBoxPhotoPreview = openBoxPhoto
    ? Object.entries(openBoxPhoto).map(([key, value]) => URL.createObjectURL(value))[0]
    : null

  const {
    fields: boxPhotosFields,
    append: appendBoxPhotoField,
    remove: removeBoxPhotoField
  } = useFieldArray({
    control,
    name: 'box_photos'
  })

  const boxPhotos = useWatch({
    control,
    name: 'box_photos'
  })

  function handleRemoveBoxPhotoField(index: number) {
    removeBoxPhotoField(index)
  }

  const handleAppendBoxPhotoField = useCallback(() => {
    appendBoxPhotoField({ attachment: undefined });
  }, [appendBoxPhotoField]);

  useEffect(() => {
    handleAppendBoxPhotoField();
  }, [handleAppendBoxPhotoField]);


  const {
    fields: contentDeclarationsFields,
    append: appendContentDeclarationField,
    remove: removeContentDeclarationField
  } = useFieldArray({
    control,
    name: 'content_declarations'
  })

  const contentDeclarations = useWatch({
    control,
    name: 'content_declarations'
  })

  function handleRemoveContentDeclarationAttachmentField(index: number) {
    removeContentDeclarationField(index)
  }

  const handleAppendContentDeclarationAttachmentField = useCallback(() => {
    appendContentDeclarationField({ attachment: undefined });
  }, [appendContentDeclarationField]);

  useEffect(() => {
    handleAppendContentDeclarationAttachmentField();
  }, [handleAppendContentDeclarationAttachmentField]);


  function handleResetFieldsBasedOnCollectStatus() {
    setValue("responsible_name", '')
    setValue("collect_sample", undefined)
    setValue("collect_volume", undefined)
    setValue("box_photo", undefined)
    setValue("content_declaration", undefined)
    setValue("open_box_photo", undefined)
  }

  return (
    <Flex direction="column" gap="4">
      <Stack direction={['column', 'column', 'row']} spacing="24px">
        {isProviderForm ? (
          <Select
            {...register("provider_id")}
            name="provider_id"
            label="Endereço remetente"
            providerAddress={providers}
            error={errors.provider_id}
            isDisabled
            required
          />
        ) : (
          <Select
            {...register("address_id")}
            name="address_id"
            label="Endereço remetente"
            addresses={addresses}
            error={errors.address_id}
            isDisabled
            required
          />
        )}
        <Select
          {...register("driver_id")}
          name="driver_id"
          label="Pertence ao motorista"
          drivers={drivers}
          error={errors.driver_id}
          isDisabled
          required
        />
      </Stack>
      <Stack direction={["column", "column", "row"]} spacing={6}>
        <Input
          {...register("real_arrival_date")}
          name="real_arrival_date"
          label="Data real da coleta"
          error={errors.real_arrival_date}
          type="date"
          max={deliveryDate}
          required
        />
        <Input
          {...register("real_arrival_hour")}
          name="real_arrival_hour"
          label="Horário real da coleta"
          error={errors.real_arrival_hour}
          type="time"
          required
        />
      </Stack>
      <Select
        {...register('is_unsuccess')}
        name="is_unsuccess"
        placeholder="Selecione uma opção..."
        label="Coleta sem sucesso?"
        onChangeCapture={handleResetFieldsBasedOnCollectStatus}
        negative_positive={negative_positive}
        required
      />

      {isUnsucessCollect === "NÃO" && (
        <>
          {hasTempCheck && (
            <Stack
              direction={['column', 'column', 'row']}
              spacing={4}
              w='full'
            >
              <Input
                {...register('temp')}
                name='temp'
                label='Temperatura'
                error={errors.temp}
                required
              />
            </Stack>
          )}
          <Input
            {...register("responsible_name")}
            name="responsible_name"
            label="Nome do responsável"
            error={errors.responsible_name}
            required
          />
          <Stack direction={['column', 'column', 'row']} spacing="24px">
            <Input
              {...register("collect_volume")}
              name="collect_volume"
              label="Volume"
              error={errors.collect_volume}
              required
            />
            {isProviderForm ? (
              <Input
                {...register("collect_sample")}
                name="collect_sample"
                label="Quantidade de gelo seco"
                error={errors.collect_sample}
                required
              />
            ) : (
              <Stack
                w="full"
                direction={["column", "column", "row"]}
                spacing={6}
              >
                <Input
                  {...register("collect_sample")}
                  name="collect_sample"
                  label="Quantidade de amostras"
                  error={errors.collect_sample}
                  required
                />
                {addressHasGeloSeco && !isProviderForm && (
                  <Input
                    {...register("gelo_seco_box_quantity")}
                    name="gelo_seco_box_quantity"
                    label="Quantidade de gelo seco na caixa (kg)"
                    error={errors.gelo_seco_box_quantity}
                    required
                  />
                )}
              </Stack>
            )}
          </Stack>
          {addressHasGeloSeco && !isProviderForm && (
            <Select
              {...register('materialPackedWithDryIce')}
              name="materialPackedWithDryIce"
              placeholder="Selecione uma opção..."
              label="Material acondicionado com gelo seco?"
              negative_positive={negative_positive}
              required
            />
          )
          }

          {boxPhotosFields.map((field, index) => {
            return (
              <Stack key={field.id} w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
                <Input
                  {...register(`box_photos.${index}.attachment`)}
                  name={`box_photos.${index}.attachment`}
                  label="Foto da caixa"
                  type="file"
                  accept={supportedFileFormats.join(", ")}
                  error={errors.box_photos ? errors?.box_photos[index]?.attachment : undefined}
                  hidden
                  required
                >
                  <Stack
                    w="full"
                    spacing="24px"
                    mt="4"
                    justifyContent='space-between'
                    direction={['column', 'column', 'row']}
                  >
                    <Button
                      as={FormLabel}
                      htmlFor={`box_photos.${index}.attachment`}
                      _hover={{
                        cursor: 'pointer'
                      }}
                      mr="2"
                      leftIcon={<Icon as={FaFile} />}
                    >
                      Upload
                    </Button>
                    {boxPhotos && boxPhotos[index]?.attachment?.length > 0 && (
                      <ChakraLink
                        href={URL.createObjectURL(boxPhotos[index].attachment[0])}
                      >
                        {boxPhotos[index].attachment[0].name} (Visualizar)
                      </ChakraLink>
                    )}
                    <Button
                      leftIcon={<Icon as={FaTimes} />}
                      variant="ghost"
                      onClick={() => handleRemoveBoxPhotoField(index)}
                      h="48px"
                    >
                      Remover
                    </Button>
                  </Stack>
                </Input>
              </Stack>
            )
          })}

          <Stack
            w="full"
            mt="4"
          >
            <Button
              _hover={{
                cursor: 'pointer'
              }}
              mr="2"
              leftIcon={<Icon as={FaPlus} />}
              onClick={handleAppendBoxPhotoField}
            >
              Foto da caixa
            </Button>
            <Text
              fontSize="sm"
              color="red.500"
            >
              {errors?.box_photos?.message}
            </Text>
          </Stack>
          {contentDeclarationsFields.map((field, index) => {
            return (
              <Stack key={field.id} w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
                <Input
                  {...register(`content_declarations.${index}.attachment`)}
                  name={`content_declarations.${index}.attachment`}
                  label="Declaração de Conteúdo"
                  type="file"
                  accept={supportedFileFormats.join(", ")}
                  error={errors.content_declarations ? errors?.content_declarations[index]?.attachment : undefined}
                  hidden
                  required
                >
                  <Stack
                    w="full"
                    spacing="24px"
                    mt="4"
                    justifyContent='space-between'
                    direction={['column', 'column', 'row']}
                  >
                    <Button
                      as={FormLabel}
                      htmlFor={`content_declarations.${index}.attachment`}
                      _hover={{
                        cursor: 'pointer'
                      }}
                      mr="2"
                      leftIcon={<Icon as={FaFile} />}
                    >
                      Upload
                    </Button>
                    {contentDeclarations && contentDeclarations[index]?.attachment?.length > 0 && (
                      <ChakraLink
                        href={URL.createObjectURL(contentDeclarations[index].attachment[0])}
                      >
                        {contentDeclarations[index].attachment[0].name} (Visualizar)
                      </ChakraLink>
                    )}
                    <Button
                      leftIcon={<Icon as={FaTimes} />}
                      variant="ghost"
                      onClick={() => handleRemoveContentDeclarationAttachmentField(index)}
                      h="48px"
                    >
                      Remover
                    </Button>
                  </Stack>
                </Input>
              </Stack>
            )
          })}

          <Stack
            w="full"
            mt="4"
          >
            <Button
              _hover={{
                cursor: 'pointer'
              }}
              mr="2"
              leftIcon={<Icon as={FaPlus} />}
              onClick={handleAppendContentDeclarationAttachmentField}
            >
              Declaração de Conteúdo
            </Button>
            <Text
              fontSize="sm"
              color="red.500"
            >
              {errors?.content_declarations?.message}
            </Text>
          </Stack>
          <Stack>

            <Input
              {...register("open_box_photo")}
              name="open_box_photo"
              type="file"
              accept={supportedFileFormats.join(", ")}
              hidden
              label="Foto da caixa aberta"
              error={errors.open_box_photo}
              required
            >
              <Button
                leftIcon={<Icon as={FiFile} />}
                as={FormLabel}
                htmlFor="open_box_photo"
                _hover={{
                  cursor: "pointer"
                }}
              >Escolha uma imagem</Button>
            </Input>
            {openBoxPhotoPreview && (
              <Image
                objectFit="contain"
                width="300px"
                src={openBoxPhotoPreview}
              />
            )}
          </Stack>
        </>
      )}
      {isUnsucessCollect === "SIM" && (
        <>
          <Input
            {...register("responsible_name")}
            name="responsible_name"
            label="Nome do responsável"
            error={errors.responsible_name}
            required
          />
          <Select
            {...register('unsuccess_reason')}
            name='unsuccess_reason'
            label='Motivo'
            placeholder='Selecione uma opção...'
            options={unsuccessReasonOptions}
            error={errors.unsuccess_reason}
            required
          />
          {contentDeclarationsFields.map((field, index) => {
            return (
              <Stack key={field.id} w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
                <Input
                  {...register(`content_declarations.${index}.attachment`)}
                  name={`content_declarations.${index}.attachment`}
                  label="Declaração de Conteúdo"
                  type="file"
                  accept={supportedFileFormats.join(", ")}
                  error={errors.content_declarations ? errors?.content_declarations[index]?.attachment : undefined}
                  hidden
                  required
                >
                  <Stack
                    w="full"
                    spacing="24px"
                    mt="4"
                    justifyContent='space-between'
                    direction={['column', 'column', 'row']}
                  >
                    <Button
                      as={FormLabel}
                      htmlFor={`content_declarations.${index}.attachment`}
                      _hover={{
                        cursor: 'pointer'
                      }}
                      mr="2"
                      leftIcon={<Icon as={FaFile} />}
                    >
                      Upload
                    </Button>
                    {contentDeclarations && contentDeclarations[index]?.attachment?.length > 0 && (
                      <ChakraLink
                        href={URL.createObjectURL(contentDeclarations[index].attachment[0])}
                      >
                        {contentDeclarations[index].attachment[0].name} (Visualizar)
                      </ChakraLink>
                    )}
                    <Button
                      leftIcon={<Icon as={FaTimes} />}
                      variant="ghost"
                      onClick={() => handleRemoveContentDeclarationAttachmentField(index)}
                      h="48px"
                    >
                      Remover
                    </Button>
                  </Stack>
                </Input>
              </Stack>
            )
          })}

          <Stack
            w="full"
            mt="4"
          >
            <Button
              _hover={{
                cursor: 'pointer'
              }}
              mr="2"
              leftIcon={<Icon as={FaPlus} />}
              onClick={handleAppendContentDeclarationAttachmentField}
            >
              Declaração de Conteúdo
            </Button>
            <Text
              fontSize="sm"
              color="red.500"
            >
              {errors?.content_declarations?.message}
            </Text>
          </Stack>
        </>
      )}
      <TextArea
        {...register("observation")}
        name="observation"
        label="Observações"
      />
      <Stack w={["full", "full", "min", "min"]} alignSelf={["center", "center", "flex-end"]} direction={['column', 'column', 'row']}>
        {isUnsucessCollect === "SIM" ? (
          <Button colorScheme="red" type="submit">Sem sucesso</Button>
        ) : (
          <Button colorScheme="blue" type="submit">Finalizar</Button>
        )}
        <Button as={Link} to="/servicos/coletas" type="submit">Coletas</Button>
      </Stack>
    </Flex>
  )
}
