import { Button, Flex, ModalBody, ModalCloseButton, ModalContent, ModalHeader } from "@chakra-ui/react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm, } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import { useToastify } from "hooks/toastify/useToastify"
import { createPatrimonyRequest } from "api/patrimonies/createPatrimonyRequest"
import { useSearchParams } from "hooks/useSearchParams"
import { useHistory } from "react-router-dom"
import { RequestPatimonyForm, RequestPatrimonySchema } from "./RequestPatimonyForm"


interface CreateRequestPatrimonyProps {
  onCloseModal: () => void
  required?: boolean
}

interface CreateRequestPatrimonySchema {
  limitDate: string
  receiptAttachmentId: FileList
  regional: string
  responsibleName: {
    label: string
    value: string
  }
  sector: string
  collaboratorType: string
  patrimonyTypes: {
    patrimonyTypeId: string
    quantity: number
  }[]
}

const createRequestPatrimonySchema = yup.object({
  limitDate: yup.string().required(),
  regional: yup.string().required(),
  collaboratorType: yup.string().required(),
  responsibleName: yup.object().required(),
  sector: yup.string().required(),
  patrimonyTypes: yup.array().of(yup.object({
    patrimonyTypeId: yup.string().required(),
    quantity: yup.number().required(),
  }))

})


export function CreateRequestPatrimony({
  onCloseModal,
  required = false
}: CreateRequestPatrimonyProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const formMethods = useForm<RequestPatrimonySchema>({
    resolver: yupResolver(createRequestPatrimonySchema),
    defaultValues: {
      patrimonyTypes: [{
        patrimonyTypeId: '',
        quantity: 1
      }]
    },
  })

  const { formState: { isSubmitting }, handleSubmit } = formMethods


  const { mutateAsync: createPatrimonyRequestFn } = useMutation({
    mutationFn: createPatrimonyRequest,
    onSuccess() {
      queryClient.invalidateQueries(['requestsPatrimony'])

      if (required) {
        searchParams.set('patrimonyRequested', 'true')
        replace({ search: searchParams.toString() })
      }
      onCloseModal()
    }
  })

  async function handleRequestPatrimony({
    limitDate,
    regional,
    responsibleName,
    sector,
    patrimonyTypes
  }: CreateRequestPatrimonySchema) {

    await promiseMessage(createPatrimonyRequestFn({
      body: {
        limitDate,
        regional,
        responsibleName: responsibleName?.value,
        sector,
        patrimonyTypes
      }
    }), 'Solicitação de patrimônio criada! 🎉')
  }


  return (
    <ModalContent>
      <ModalHeader>Solicitar Patrimônio</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleRequestPatrimony)}
        maxH='600px'
        overflowY='scroll'
      >
        <FormProvider {...formMethods}>
          <RequestPatimonyForm />
        </FormProvider>

        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent >
  )
}
