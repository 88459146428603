import { ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react"
import { getStock } from "api/stocks/getStock"
import { useQuery } from "react-query"

interface StockDetailProps {
  stockId: string
}
export function StockDetail({ stockId }: StockDetailProps) {

  const {
    data: stockResult,
    isLoading: isLoadingStockResult
  } = useQuery({
    queryKey: ['stock', stockId],
    queryFn: () => getStock({ routeParams: { stockId } })
  })

  if (isLoadingStockResult) {
    return (
      <ModalContent>
        <ModalHeader>
          <Skeleton w="150px" />
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
        </ModalBody>
      </ModalContent>
    )
  }

  return (
    <ModalContent maxW='550px'>
      <ModalHeader>
        Detalhes da Insumo
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <TableContainer>
          <Table size="sm">
            <Tbody>
              <Tr>
                <Td>Coletador</Td>
                <Td isNumeric>{stockResult?.collectorIDStock?.trading_name}</Td>
              </Tr>
              <Tr>
                <Td>Insumo</Td>
                <Td isNumeric>{stockResult?.inputIDStock?.name}</Td>
              </Tr>
              <Tr>
                <Td>Estoque Mínimo</Td>
                <Td isNumeric>{stockResult?.minimum_stock}</Td>
              </Tr>
              <Tr>
                <Td>Estoque Padrão</Td>
                <Td isNumeric>{stockResult?.standard_stock}</Td>
              </Tr>
              <Tr>
                <Td>Estoque Atual</Td>
                <Td isNumeric>{stockResult?.current_stock}</Td>
              </Tr>
              <Tr>
                <Td>Observações</Td>
                <Td isNumeric>{stockResult?.observation}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </ModalBody>
    </ModalContent>
  )
}
