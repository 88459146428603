import { Box, Flex } from "@chakra-ui/react";
import { VacationStatus as VacationStatusType } from "api/vacations/_types/Vacation";
import { vacationStatusMap } from "utils/vacationMappers";

interface VacationStatusProps {
  status: VacationStatusType;
}

export function VacationStatus({ status }: VacationStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {['planning', 'paying'].includes(status) && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {['active', 'on-vacation', 'planned', 'waiting'].includes(status) && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'done' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="blue.300"
        />
      )}

      {status === 'dismissed' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {vacationStatusMap[status]}
    </Flex>
  )
}
