import { Box, Button, Flex, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "components/Inputs/Input";
import { useEmployeeCostsFunctions } from "hooks/employeeCosts/useEmployeeCostsFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { useCurrency } from "react-hook-currency";
import { useForm } from "react-hook-form";
import { RequestError } from "utils/errors/RequestErrors";
import * as yup from "yup"

interface GenerateEmployeeCostStatementFormInputs {
  weekHoursAmount: number
  fuelPrice: string
  monthlyDaysUp6WorkHours: number
  monthlyKm: number
}

interface GenerateEmployeeCostStatementModalProps {
  isOpen: boolean
  onClose: () => void
  employeeCostId: string
}

const generateEmployeeCostStatementFormSchema = yup.object().shape({
  weekHoursAmount: yup.number().required('Campo obrigatório').typeError('Campo obrigatório'),
  fuelPrice: yup.string().required('Campo obrigatório'),
  monthlyDaysUp6WorkHours: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  monthlyKm: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
})

export function GenerateEmployeeCostStatementModal({
  isOpen,
  onClose,
  employeeCostId,
}: GenerateEmployeeCostStatementModalProps) {
  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    }
  } = useForm<GenerateEmployeeCostStatementFormInputs>({
    resolver: yupResolver(generateEmployeeCostStatementFormSchema)
  })

  const { onClick, onChange, format } = useCurrency({
    style: 'decimal',
  })

  const {
    generateEmployeeCostStatement
  } = useEmployeeCostsFunctions()

  const { errorMessage } = useToastify()

  async function handleGenerateEmployeeCostStatement(
    values: GenerateEmployeeCostStatementFormInputs
  ) {
    await generateEmployeeCostStatement.mutateAsync({
      employeeCostId,
      input: { ...values }
    }, {
      onError: (error: RequestError) => {
        errorMessage(error.message)
      }
    })
  }

  const pdfData = generateEmployeeCostStatement.data ? new Uint8Array(generateEmployeeCostStatement.data) : null

  const link = pdfData ? URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' })) : null

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Text
            mb="6"
            fontWeight="bold"
            fontSize="xl"
          >
            Demonstrativo de custo mensal de empregado
          </Text>
          <Box
            as="form"
            display="flex"
            flexDirection="column"
            gap="4"
            onSubmit={handleSubmit(handleGenerateEmployeeCostStatement)}
            noValidate
          >

            <Input
              {...register("weekHoursAmount")}
              name="weekHoursAmount"
              label="Quantidade de horas semanais"
              error={errors.weekHoursAmount}
              required
            />

            <Input
              {...register("fuelPrice")}
              name="fuelPrice"
              label="Preço da gasolina"
              addChildren="R$"
              onClick={onClick}
              onChange={onChange}
              defaultValue={format('000')}
              error={errors.fuelPrice}
              required
            />

            <Input
              {...register("monthlyDaysUp6WorkHours")}
              name="monthlyDaysUp6WorkHours"
              label="Dias de trabalho com carga horária acima de 6 horas"
              type="number"
              error={errors.monthlyDaysUp6WorkHours}
              required
            />

            <Input
              {...register("monthlyKm")}
              name="monthlyKm"
              label="Km mensal"
              type="number"
              addChildren="Km"
              error={errors.monthlyKm}
              required
            />

            <Flex
              w="full"
              align="center"
              gap="2"
              justify="flex-end"
            >
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
              >
                Gerar
              </Button>

              {link && (
                <Button
                  as={Link}
                  download
                  href={link}
                >
                  Download
                </Button>
              )}

            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>

    </Modal>
  )
}
