import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { ReactNode } from "react";

interface MaterialRequestModalRootProps {
  isOpen: boolean
  onClose: () => void
  title: string
  children: ReactNode
}

export function MaterialRequestModalRoot({
  isOpen,
  onClose,
  title,
  children
}: MaterialRequestModalRootProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalCloseButton />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        {children}
      </ModalContent>
    </Modal>
  )
}
