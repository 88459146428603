
import axios from "axios";
import { User } from "hooks/user/dtos/User";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Regional } from "./_types/Regional";

interface GetRegionalResponse {
  regional: Regional & {
    responsible: User | null
  }
}

interface GetRegionalParams {
  regionalId: string
}

export async function getRegional({ regionalId }: GetRegionalParams) {
  try {
    const response = await api.get<GetRegionalResponse>(`/regionals/${regionalId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
