import { Button, ButtonProps, Icon, useMediaQuery } from '@chakra-ui/react'
import { CSSProperties } from 'react'
import { FaList } from 'react-icons/fa'
import { Link } from 'react-router-dom'

interface IListButtonProps extends ButtonProps {
  href: string
  name: string
  customStyle?: CSSProperties
}

export function ListButton({ href, name, customStyle }: IListButtonProps) {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  return (
    <Link to={href} style={customStyle}>
      <Button type="button" colorScheme="gray" w={customStyle ? 'full' : ''}>
        {isWideVersion ? name : <Icon as={FaList} />}
      </Button>
    </Link>
  )
}
