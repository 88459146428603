import { Button, Flex, IconButton, Input, useMediaQuery } from "@chakra-ui/react";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface ShippingCostsTableFiltersSchema {
  shipping: string
  sourceBranch: string
  destinationBranch: string
}

export function ShippingCostsTableFilters() {
  const searchParams = useSearchParams()
  const history = useHistory()

  const shipping = searchParams.get('shipping')
  const sourceBranch = searchParams.get('sourceBranch')
  const destinationBranch = searchParams.get('destinationBranch')

  const [isLargerThen768, isLessThen1200] = useMediaQuery(['(min-width: 768px)', '(max-width: 1200px)'])

  const {
    register,
    reset,
    handleSubmit,
  } = useForm<ShippingCostsTableFiltersSchema>({
    defaultValues: {
      shipping,
      sourceBranch,
      destinationBranch
    }
  })

  function handleSearchShippingCosts(values: ShippingCostsTableFiltersSchema) {
    if (values.shipping) {
      searchParams.set('shipping', values.shipping)
    } else {
      searchParams.delete('shipping')
    }

    if (values.sourceBranch) {
      searchParams.set('sourceBranch', values.sourceBranch)
    } else {
      searchParams.delete('sourceBranch')
    }

    if (values.destinationBranch) {
      searchParams.set('destinationBranch', values.destinationBranch)
    } else {
      searchParams.delete('destinationBranch')
    }

    searchParams.set('page', '1')

    history.replace({ search: searchParams.toString() })
  }

  function handleClearFilters() {
    searchParams.delete('shipping')
    searchParams.delete('sourceBranch')
    searchParams.delete('destinationBranch')

    searchParams.set('page', '1')

    history.replace({ search: searchParams.toString() })

    reset({
      destinationBranch: '',
      shipping: '',
      sourceBranch: '',
    })
  }

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      gap="2"
      as="form"
      onSubmit={handleSubmit(handleSearchShippingCosts)}
    >
      <Input
        {...register('shipping')}
        size="sm"
        rounded="md"
        w={{ base: "full", lg: "250px" }}
        placeholder="Nome fantasia"
      />
      <Input
        {...register('sourceBranch')}
        size="sm"
        rounded="md"
        w={{ base: "full", lg: "250px" }}
        placeholder="Apelido base de origem"
      />
      <Input
        {...register('destinationBranch')}
        size="sm"
        rounded="md"
        w={{ base: "full", lg: "250px" }}
        placeholder="Apelido base de destino"
      />

      {isLargerThen768 && isLessThen1200 ? (
        <IconButton
          aria-label="Buscar no formulário"
          size="sm"
          icon={<FaSearch />}
          type="submit"
        />
      ) : (

        <Button
          size="sm"
          leftIcon={<FaSearch />}
          type="submit"
          overflow="hidden"
        >
          Filtrar resultados

        </Button>
      )}

      {isLargerThen768 && isLessThen1200 ? (
        <IconButton
          aria-label="Remover filtros"
          size="sm"
          icon={<FaTimes />}
          type="submit"
        />
      ) : (

        <Button
          size="sm"
          leftIcon={<FaTimes />}
          type="button"
          onClick={handleClearFilters}
          variant="ghost"
          overflow="hidden"
        >
          Remover filtros
        </Button>
      )}


    </Flex>
  )
}
