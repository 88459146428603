
import { Box, Button, Divider, Flex, Heading, Icon, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { usePagination } from "@ajna/pagination";
import { Link, useHistory } from "react-router-dom";
import { FiEdit3, FiEye, FiPlus, FiTrash2 } from "react-icons/fi";
import { useGeneralProviders } from "hooks/generalProvider/useGeneralProviders";
import { useGeneralProviderFunctions } from "hooks/generalProvider/useGeneralProviderFunctions";
import { useAuth } from "hooks/auth/useAuth";
import { useSwal } from "hooks/swal/useSwal";
import { useToastify } from "hooks/toastify/useToastify";
import { Fragment, useEffect, useMemo, useReducer } from "react";
import { Pagination } from "components/Pagination/Pagination";
import { useForm, useWatch } from "react-hook-form";
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { situation } from "utils/customLists";
import { TableFilterButton } from "components/Filters/TableFilterButton";
import { FaUndoAlt } from "react-icons/fa";
import { useQueryClient } from "react-query";

interface IGeneralProvidersFilterInputs {
  q: string
  situation: string
}

const generalProvidersListFilterOptions = [
  { key: 'q', value: 'q', checked: false },
  { key: 'situation', value: 'Situação', checked: false },
]

interface ActionPayload {
  generalProviderData?: IGeneralProvidersFilterInputs
  currentPage?: number
}

interface Action {
  type: 'set-general-provider-current-data' | 'set-general-provider-current-page'
  payload: ActionPayload
}

function reducer(state: ActionPayload, action: Action) {
  if (action.type === 'set-general-provider-current-page') {
    return {
      generalProviderData: state.generalProviderData,
      currentPage: action.payload.currentPage
    }
  }

  return {
    ...state,
    ...action.payload
  }
}

export function GeneralProviderList() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  const userLoggedHasPermissionToViewGeneralProvider =  userLogged?.permissions.includes('view-general-provider')

  const { confirmMessage, standardMessage } = useSwal()
  const { promiseMessage } = useToastify()

  const { register, control} = useForm<IGeneralProvidersFilterInputs>()

  const [listGeneralProviderDataState, dispatch] = useReducer(
    reducer,
    {} as ActionPayload
  )

  const { deleteGeneralProvider: { mutateAsync: deleteGeneralProvider } } = useGeneralProviderFunctions()

  useEffect(() => {
    if (!userLoggedHasPermissionToViewGeneralProvider) {
      redirectTo('/')
    }
  }, [userLoggedHasPermissionToViewGeneralProvider, redirectTo])


  const [
    querySearched,
    situationSearched,
  ] = useWatch({
    control,
    name: [
      'q',
      'situation',
    ]
  })

  const filterOptionsWithValuesRenamed = useMemo(() => {
    return generalProvidersListFilterOptions.map((option) => {
      if (option.key === 'q') {
        option.value = 'Razão Social | Nome Fantasia'
      }
      return option
    })
  }, [])

  const {
    filterOptions,
    onLoadSetFilterOptions
  } = useFilterOptions()

  useEffect(() => {
    onLoadSetFilterOptions(filterOptionsWithValuesRenamed)
  }, [filterOptionsWithValuesRenamed, onLoadSetFilterOptions])

  const { data: generalProviders, isFetching: isFetchingGeneralProviders } = useGeneralProviders({
    queryParams: {
      ...listGeneralProviderDataState.generalProviderData,
      current_page: listGeneralProviderDataState.currentPage,
      page_size: Number(rowsPerPage)
    },
    queryOptions: { enabled: !!listGeneralProviderDataState }
  })

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },

      pagesCount: generalProviders?.totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });



  useEffect(() => {
    dispatch({
      type: 'set-general-provider-current-page',
      payload: {
        currentPage: currentPage
      }
    })
  }, [currentPage])

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      dispatch({
        type: 'set-general-provider-current-data',
        payload: {
          generalProviderData: {
            q: querySearched,
            situation: situationSearched,
          }
        }
      })

      setCurrentPage(1)
    }, 1000)

    return () => clearTimeout(debounceTimeout)
  }, [
    querySearched,
    situationSearched,
    dispatch,
    setCurrentPage
  ])



  async function handleDeleteGeneralProvider(general_provider_id: string) {
    const hasDeleteGeneralProvider = await confirmMessage({ title: "Deseja excluir o fornecedor geral?" })

    if (hasDeleteGeneralProvider) {
      await promiseMessage(deleteGeneralProvider(general_provider_id), "Fornecedor Geral excluído com sucesso!")
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  const handleChangePage = (page: number) => setCurrentPage(page)

  const queryClient = useQueryClient()
  const handleRefetchTableData = async () => {
    await queryClient.invalidateQueries(['generalProviders'])
  }

  const filterOptionsChecked = filterOptions.filter((option) => option.checked)
  return (

      <Box
        borderRadius='8px'
        p={4}
        bg='white'
      >
        <Flex
          w="full"
          direction="column"
          gap={6}
        >
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontFamily="poppins">
              Fornecedores Gerais
            </Heading>
            <Link to="/fornecedor-geral/criar">
              <Button
                colorScheme="gray"
                rightIcon={<Icon as={FiPlus} />}
                size="sm"
              >
                Adicionar Fornecedor Geral
              </Button>

            </Link>
          </Flex>
          <Divider />
          <Flex gap={2}>
            <TableFilterButton />
            <Button colorScheme="blue" onClick={handleRefetchTableData}>
              <Icon as={FaUndoAlt} />{' '}
            </Button>
          </Flex>
          {filterOptionsChecked.map((option) => {
            return (
              <Fragment key={option.key}>
                {option.key === 'situation' ? (
                  <Select
                    {...register('situation')}
                    name='situation'
                    label='Situação'
                    situations={situation}
                    placeholder='Selecione uma opção...'
                  />
                ) : (
                  <Input
                    {...register('q')}
                    name='q'
                    label='Buscar por razão social ou nome fantasia'
                  />
                )}
              </Fragment>
            )
          })}
          {isFetchingGeneralProviders ? (<Spinner/>): ( <TableContainer w="full">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th>SITUAÇÃO</Th>
                  <Th>RAZÃO SOCIAL</Th>
                  <Th>NOME FANTASIA</Th>
                  <Th>CONDIÇÃO DE PAGAMENTO</Th>
                  <Th>TIPO DE PAGAMENTO</Th>
                  <Th>CIDADE</Th>
                  <Th>ESTADO</Th>
                </Tr>
              </Thead>
              <Tbody>
                {generalProviders?.generalProviders.map((generalProvider) => {
                  return (
                    <Tr key={generalProvider.id}>
                      <Td>{generalProvider.situation}</Td>
                      <Td>{generalProvider.company_name ?? '-'}</Td>
                      <Td>{generalProvider.trading_name}</Td>
                      <Td>{generalProvider.payment_conditional}</Td>
                      <Td>{generalProvider.payment_type}</Td>
                      <Td>{generalProvider.city}</Td>
                      <Td>{generalProvider.state}</Td>
                      <Td isNumeric>
                        {userLogged !== null && (
                          <>
                            {
                              userLogged.permissions.includes('view-general-provider') && (
                                <Link
                                  to={`/fornecedor-geral/visualizar/${generalProvider.id}`}
                                >
                                  <Icon
                                    cursor="pointer"
                                    as={FiEye}
                                    fontSize="20"
                                    ml="4"
                                    mt={['2', '2', '0']}
                                  />
                                </Link>
                              )
                            }
                            {userLogged.permissions.includes('edit-general-provider') && (
                              <Link
                                to={`/fornecedor-geral/editar/${generalProvider.id}`}
                              >
                                <Icon
                                  cursor="pointer"
                                  as={FiEdit3}
                                  fontSize="20"
                                  ml="4"
                                  mt={['2', '2', '0']}
                                />
                              </Link>
                            )}

                            {userLogged.permissions.includes('delete-general-provider') && (
                              <Icon
                                cursor="pointer"
                                as={FiTrash2}
                                fontSize="20"
                                ml="4"
                                mt={['2', '2', '0']}
                                onClick={() =>
                                  handleDeleteGeneralProvider(generalProvider.id)
                                }
                              />
                            )}


                          </>
                        )}
                      </Td>
                    </Tr>
                  )

                })}

              </Tbody>
            </Table>
          </TableContainer>)}

          <Pagination
            currentPage={currentPage}
            pagesQuantity={pagesCount}
            pages={pages}
            handlePageChange={handleChangePage}
          />

        </Flex>
      </Box>

  )
}
