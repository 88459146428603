import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CreateCarrierBody {
  name: string
  cpf: string
  email: string
  collectorId: string
  dtVaccineDoseAttachmentId: string
  hepatiteBVaccineDoseAttachmentId: string
  tripliceViralVaccineDoseAttachmentId: string
  cnhAttachmentId: string
  crlvAttachmentId: string
  anttMotorFreightAttachmentId: string
  vehicleSidePhotoAttachmentId: string
  vehicleInternPhotoAttachmentId: string
  vehicleRearPhotoAttachment: string
}

interface CreateCarrierProps {
  body: CreateCarrierBody
}

export async function createCarrier({
  body
}: CreateCarrierProps) {
  try {
    const response = await api.post(`/carriers`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
