import { Button, HStack, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { GetCarriersResponse } from "api/carriers/getCarriers";
import { inactivateCarrier } from "api/carriers/inactivateCarrier";
import { Carrier } from "api/carriers/_types/Carrier";
import { useAuth } from "hooks/auth/useAuth";
import { Collector } from "hooks/collector/dtos/Collector";
import { useToastify } from "hooks/toastify/useToastify";
import { FaSearch, FaTimes } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";
import { useMutation, useQueryClient } from "react-query";
import { captalize } from "utils/captalize";
import { CarrierDetail } from "./CarrierDetail";
import { CarrierStatus } from "./CarrierStatus";
import { EvaluateCarrier } from "./EvaluateCarrier";

interface CarriersTableRowProps {
  carrier: Carrier & {
    collector: Collector
  }
}


export function CarriersTableRow({ carrier }: CarriersTableRowProps) {
  const { userLogged } = useAuth()

  const userCanEvaluateCarrier = userLogged?.permissions?.includes(
    'evaluate-carrier'
  )

  const userCanInactivateCarrier = userLogged?.permissions?.includes(
    'inactivate-carrier'
  )

  const {
    isOpen: isCarrierDetailOpen,
    onOpen: onOpenCarrierDetail,
    onClose: onCloseCarrierDetail,
  } = useDisclosure()

  const {
    isOpen: isEvaluateCarrierOpen,
    onOpen: onOpenEvaluateCarrier,
    onClose: onCloseEvaluateCarrier,
  } = useDisclosure()

  const queryClient = useQueryClient()

  const { mutateAsync: inactivateCarrierFn } = useMutation({
    mutationFn: inactivateCarrier,
    onSuccess(_data, variables) {
      const cached = queryClient.getQueriesData<GetCarriersResponse>({
        queryKey: ['carriers']
      })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          carriers: cachedValue.carriers.map((carrier) => {

            if (carrier.id === variables.carrierId) {
              return { ...carrier, status: 'inactive' }
            }

            return carrier
          })
        })
      })
    },
  })

  const { promiseMessage } = useToastify()

  async function handleInactivateCarrier(carrierId: string) {
    await promiseMessage(inactivateCarrierFn({
      carrierId
    }), 'Portador inativado com sucesso!')
  }

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label=""
          icon={<FaSearch />}
          variant="ghost"
          size="sm"
          onClick={onOpenCarrierDetail}
        />

        <Modal
          isOpen={isCarrierDetailOpen}
          onClose={onCloseCarrierDetail}
          size="2xl"
        >
          <ModalOverlay />

          <CarrierDetail carrierId={carrier.id} />
        </Modal>
      </Td>
      <Td>{carrier.name}</Td>
      <Td>{captalize(carrier.collector.trading_name)}</Td>
      <Td>{carrier.cpf}</Td>
      <Td>{carrier.email}</Td>
      <Td>
        <CarrierStatus status={carrier.status} />
      </Td>
      <Td>
        <HStack spacing={3}>
          {['evaluating', 'pending'].includes(carrier.status) && userCanEvaluateCarrier && (
            <>
              <Button
                rightIcon={<FaArrowRight />}
                size="xs"
                lineHeight="short"
                onClick={onOpenEvaluateCarrier}
              >
                Avaliar
              </Button>

              <Modal
                isOpen={isEvaluateCarrierOpen}
                onClose={onCloseEvaluateCarrier}
                size="2xl"
              >
                <ModalOverlay />

                <EvaluateCarrier
                  carrierId={carrier.id}
                  onClose={onCloseEvaluateCarrier}
                />
              </Modal>
            </>
          )}

          {carrier.status !== 'inactive' && userCanInactivateCarrier && (
            <Button
              rightIcon={<FaTimes />}
              size="xs"
              lineHeight="short"
              onClick={() => handleInactivateCarrier(carrier.id)}
            >
              Inativar
            </Button>
          )}
        </HStack>
      </Td>
    </Tr>
  )
}
