import { format, set } from "date-fns";
import { useMutation } from "react-query";
import { ServiceProps } from "../../contexts/ServiceContext";
import { api } from "../../services/api";
import { useSwal } from "../swal/useSwal";

interface IConsultServicesProps {
  customer_id?: string;
  startFilter?: Date;
  endFilter?: Date;
  protocol?: number;
  is_business?: boolean;
  collector_id?: string;
  budget_id?: string;
  sourceCity?: string;
  destinationCity?: string;
}


async function consultServicesReqFunction(queryParams: IConsultServicesProps): Promise<ServiceProps[]> {
  try {
    let startFilterFormatted: string | undefined = undefined

    if (queryParams.startFilter) {
      startFilterFormatted = format(set(new Date(queryParams.startFilter), {
        hours: 0
      }), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
    }

    let endFilterFormatted: string | undefined = undefined

    if (queryParams.endFilter) {
      endFilterFormatted = format(set(new Date(queryParams.endFilter), {
        hours: 23,
        minutes: 59
      }), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
    }

    const queryParamsFormatted = {
      ...queryParams,
      startFilter: startFilterFormatted,
      endFilter: endFilterFormatted
    }

    const { data } = await api.get("/consult/service", {
      params: { ...queryParamsFormatted }
    })

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}


export function useServiceByQueryParams() {
  const { successMessage, alertMessage, errorMessage } = useSwal()

  const consultServices = useMutation('consultServices', consultServicesReqFunction, {
    onSuccess: (data) => {
      if (!data.length) {
        return alertMessage("A consulta foi realizada com sucesso, \n mas não foram encontrados serviços com as informações passadas!")
      }
      return successMessage("Consulta realizada com sucesso!")
    },
    onError: (error: any) => {
      errorMessage(error.message)
    }
  })

  return { consultServices }
}
