import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ValidateRncForm } from '../../components/Form/Rnc/ValidateRncForm'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useAuth } from '../../hooks/auth/useAuth'
import { useRncFunctions } from '../../hooks/rnc/useRncFunctions'
import { useSwal } from '../../hooks/swal/useSwal'

interface IFormInputProps {
  risk_rating: string
  rnc_type: string | null
  alignment_observation: string | null
  business_meeting: string | null
}

interface IQueryParams {
  rncID: string
}

export function ValidateRncPage() {
  const [isLoading, setIsLoading] = useState(false)

  const {
    validateRnc: { mutateAsync: validateRnc },
  } = useRncFunctions()
  const { confirmMessage, standardMessage } = useSwal()
  const { rncID }: IQueryParams = useParams()
  const { userLogged } = useAuth()

  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('validate-rnc')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  const handleValidateRnc = async (values: IFormInputProps) => {
    const newValues = {
      rncId: rncID,
      hasAlignment: !!values.alignment_observation,
      validateRncInput: {
        ...values,
        rnc_type: values.rnc_type ?? null,
        alignment_observation: values.alignment_observation ?? null,
        business_meeting: values.business_meeting ?? null,
      },
    }

    const hasValidateRnc = await confirmMessage({
      title: 'Deseja validar um RNC?',
    })

    if (hasValidateRnc) {
      setIsLoading(true)
      try {
        const validateRncResponse = await validateRnc(newValues)
        if (validateRncResponse) {
          redirectTo('/rnc/pendentes-validacao')
        }
      } catch {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (
    <ValidateRncForm
      title="Validar Registro de Não Conformidade"
      rncID={rncID}
      submit={handleValidateRnc}
    />
  )
}
