import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Link,
  Spinner,
  Stack,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { handleChangeUrl } from 'utils/handleChangeUrl'
import { InputProps } from '../../../contexts/InputContext'
import { OccurrenceProps } from '../../../contexts/OccurrenceContext'
import { formatDate } from '../../../utils/DateFunctions/formatDate'
import { formatDateToFrontInput } from '../../../utils/GeneralFunctions/DateFunctions/formatDateToFrontInput'
import { formatHourToFrontInput } from '../../../utils/GeneralFunctions/HourFunctions/formatHourToFrontInput'
import { Input } from '../../Inputs/Input'
import { TextArea } from '../../Inputs/TextInput'

interface OccurrenceAccordionProps {
  inputs: InputProps[]
  isInputsLoading: boolean
  isOccurrenceLoading: boolean
  occurrenceById?: OccurrenceProps
}

interface IInputsToShowProps {
  input_id: string
  inputName: string
  quantity: number
}

interface OldInputsProps {
  input_id: string
  name: string
  quantity: number
  prev_quantity?: number
}

export function OccurrenceAccordion({
  inputs,
  occurrenceById,
  isInputsLoading,
  isOccurrenceLoading,
}: OccurrenceAccordionProps) {
  const [inputsToShow, setInputsToShow] = useState<IInputsToShowProps[] | null>(
    null,
  )

  const oldRegisteredInputs: OldInputsProps[] = occurrenceById?.prev_materials ? JSON.parse(occurrenceById?.prev_materials) : []


  useEffect(() => {
    if (
      occurrenceById?.intercurrence === 'ALTERAÇÃO DE INSUMOS UTILIZADOS' ||
      occurrenceById?.intercurrence === 'REPOSIÇÃO DE INSUMOS'
    ) {
      const inputsToShow = occurrenceById?.materials?.reduce((acc, curr) => {
        if (curr.input_id === 'GELO SECO') {
          acc.push({ input_id: curr.input_id, inputName: curr.input_id, quantity: curr.quantity })
        }

        inputs.forEach((input) => {
          if (input.id === curr.input_id) {
            acc.push({ input_id: curr.input_id, inputName: input.name, quantity: curr.quantity })
          }
        })
        return acc
      }, [] as IInputsToShowProps[])

      setInputsToShow(inputsToShow)
    }
  }, [occurrenceById, inputs])

  const combineRegisteredInputsAndOccurrenceMaterials = (
    registeredInputs: OldInputsProps[],
    materials: IInputsToShowProps[]
  ): OldInputsProps[] => {
    const newMaterialsArray: OldInputsProps[] = [];

    registeredInputs.forEach(input => {
      const matchingMaterial = materials?.find(
        material => input.input_id === material.input_id
      );

      if (matchingMaterial) {
        newMaterialsArray.push({
          input_id: input.input_id,
          name: input.name || matchingMaterial.inputName,
          prev_quantity: input.quantity,
          quantity: matchingMaterial.quantity,
        });
      }
    });

    return newMaterialsArray;
  };

  const updatedRegisteredInputs: OldInputsProps[] = combineRegisteredInputsAndOccurrenceMaterials(
    oldRegisteredInputs,
    inputsToShow
  );

  if (isOccurrenceLoading || isInputsLoading) {
    return <Spinner />
  }

  return (
    <Accordion allowMultiple mt="4">
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Informações da Ocorrência
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Stack w="full" spacing="24px" direction="column">
            <Stack
              w="full"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Input
                label="Ocorrência"
                name="occurrence_type"
                value={occurrenceById?.type}
                isDisabled={true}
              />

              <Input
                label="Intercorrência"
                name="intercurrence_type"
                value={occurrenceById?.intercurrence}
                isDisabled={true}
              />
            </Stack>
            {occurrenceById?.shipping_ticket_number !== null && (
              <Stack
                w="full"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  label="Número do chamado/ticket"
                  name="shipping_ticket_number"
                  value={occurrenceById?.shipping_ticket_number}
                  isDisabled={true}
                />
              </Stack>
            )}
            <Stack
              w="full"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Input
                label="Protocolo Serviço"
                name="service_protocol"
                value={occurrenceById?.occurrenceIDService.protocol}
                isDisabled={true}
              />

              <Input
                label="Cliente"
                name="customer"
                value={occurrenceById?.occurrenceIDCustomer.trading_firstname}
                isDisabled={true}
              />
            </Stack>
          </Stack>
          {occurrenceById?.intercurrence === 'RECLAMAÇÃO DE CLIENTE' && (
            <Stack
              w="full"
              mt="4"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <TextArea
                label="Reclamação"
                name="occurrence_detail"
                value={
                  occurrenceById?.occurrence_detail !== null
                    ? occurrenceById?.occurrence_detail
                    : ''
                }
                isDisabled={true}
              />
            </Stack>
          )}
           {occurrenceById?.email_to_reply !== null && (
              <Stack
                w="full"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  label="E-mail para resposta"
                  name="email_to_reply"
                  value={occurrenceById?.email_to_reply}
                  isDisabled={true}
                />
              </Stack>
            )}

          <Stack mt="4">
            <TextArea
              label="Observações"
              name="observation"
              value={
                occurrenceById?.observation !== null
                  ? occurrenceById?.observation
                  : ''
              }
              isDisabled={true}
            />
          </Stack>

          {occurrenceById?.proceed && (
            <Input
              name='proceed'
              label="Procede"
              defaultValue={occurrenceById?.proceed}
              isDisabled={true}
            />
          )}

          {occurrenceById?.cause && (
            <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
              <TextArea
                name="cause"
                label="Causa Raiz"
                value={occurrenceById?.cause}
                isDisabled={true}
              />
            </Stack>
          )}

          {occurrenceById?.corrective && (
            <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
              <TextArea
                name="corrective"
                label="Ação Corretiva"
                isDisabled={true}
                value={occurrenceById?.corrective}
              />
            </Stack>
          )}
          {occurrenceById?.preventive && (
            <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
              <TextArea
                name="preventive"
                label="Ação Preventiva"
                isDisabled={true}
                value={occurrenceById?.preventive}
              />
            </Stack>
          )}

          {occurrenceById?.attachment_photo && (
            <Stack
              mt="4"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Button
                as={Link}
                href={handleChangeUrl(occurrenceById?.attachment_photo)}
                isExternal
              >
                Visualizar anexo da ocorrência
              </Button>
            </Stack>
          )}

          {(occurrenceById?.intercurrence === 'ATRASO NA COLETA' ||
            occurrenceById?.intercurrence === 'ATRASO NA ENTREGA') && (
              <Stack
                w="full"
                mt="4"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Stack
                  w="full"
                  spacing="24px"
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    label="Previsão de Coleta/Entrega (Data)"
                    name="occurrence_date"
                    value={
                      occurrenceById?.occurrence_date !== null
                        ? formatDateToFrontInput(occurrenceById?.occurrence_date)
                        : ''
                    }
                    type="date"
                    isDisabled={true}
                  />
                  <Input
                    label="Previsão de Coleta/Entrega (Horário)"
                    name="occurrence_hour"
                    value={
                      occurrenceById?.occurrence_hour !== null
                        ? formatHourToFrontInput(occurrenceById?.occurrence_hour)
                        : ''
                    }
                    type="time"
                    isDisabled={true}
                  />
                </Stack>

                <Input
                  label="Motivo do Atraso"
                  name="occurrence_detail"
                  value={
                    occurrenceById?.occurrence_detail !== null
                      ? occurrenceById?.occurrence_detail
                      : ''
                  }
                  isDisabled={true}
                />
              </Stack>
            )}

          {occurrenceById?.intercurrence === 'TENTATIVA DE ENTREGA SEM SUCESSO' && (
            <Stack
              w="full"
              mt="4"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Input
                name='unsuccess_delivery_address_id'
                value={`${occurrenceById?.unsuccessDeliveryAddress.trading_name} | ${occurrenceById?.unsuccessDeliveryAddress.branch} | ${occurrenceById?.unsuccessDeliveryAddress.street} | ${occurrenceById?.unsuccessDeliveryAddress.number} | ${occurrenceById?.unsuccessDeliveryAddress.neighborhood} | ${occurrenceById?.unsuccessDeliveryAddress.cityIDAddress.name} | ${occurrenceById?.unsuccessDeliveryAddress.cep}
            `.toUpperCase()}
                label="Endereço"
                isDisabled={true}
              />
            </Stack>
          )}

          {occurrenceById?.intercurrence === 'SERVIÇO EXTRA' && (
            <Stack
              w="full"
              mt="4"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Input
                label="Valor Lançado"
                name="occurrence_detail"
                value={
                  occurrenceById?.occurrence_detail !== null
                    ? occurrenceById?.occurrence_detail
                    : ''
                }
                isDisabled={true}
              />
            </Stack>
          )}

          {occurrenceById?.intercurrence ===
            'DESVIO DE BUDGET DE TRANSFERÊNCIA' && (
              <Stack
                w="full"
                mt="4"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  label="Valor"
                  name="occurrence_detail"
                  value={
                    occurrenceById?.occurrence_detail !== null
                      ? occurrenceById?.occurrence_detail
                      : ''
                  }
                  isDisabled={true}
                />
              </Stack>
            )}

          {occurrenceById?.intercurrence === 'ESPERA ACIMA DE 10 MIN' && (
            <Stack
              w="full"
              mt="4"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Input
                label="Tempo"
                name="occurrence_detail"
                value={
                  occurrenceById?.occurrence_detail !== null
                    ? occurrenceById?.occurrence_detail
                    : ''
                }
                isDisabled={true}
              />
            </Stack>
          )}


          {occurrenceById?.intercurrence === 'INSUMOS ERRADOS' && (
            <Stack
              w="full"
              mt="4"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <TextArea
                label="Descrição"
                name="occurrence_detail"
                value={
                  occurrenceById?.occurrence_detail !== null
                    ? occurrenceById?.occurrence_detail
                    : ''
                }
                isDisabled={true}
              />
            </Stack>
          )}

          {occurrenceById?.intercurrence === 'OUTRAS COBRANÇAS' && (
            <Stack
              w="full"
              mt="4"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Input
                label="Valor"
                name="occurrence_detail"
                value={
                  occurrenceById?.occurrence_detail !== null
                    ? occurrenceById?.occurrence_detail
                    : ''
                }
                isDisabled={true}
              />
            </Stack>
          )}

          {(occurrenceById?.intercurrence === 'CANCELAMENTO DE VOO' ||
            occurrenceById?.intercurrence === 'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)') && (
              <Stack
                w="full"
                mt="4"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  label="Motivo do atraso"
                  name="occurrence_detail"
                  value={occurrenceById!.occurrence_detail ?? ''}
                  isDisabled={true}
                />
                <Stack w="full" direction={['column', 'column', 'row']} spacing="24px">
                  <Input
                    label="Nova data prevista"
                    name="occurrence_date"
                    value={formatDate.handle(occurrenceById!.occurrence_date, "DateWithoutHourToShow")}
                    isDisabled={true}
                  />
                  <Input
                    label="Novo horário previsto"
                    name="occurrence_hour"
                    value={formatDate.handle(occurrenceById!.occurrence_hour, "DateOnlyWithHourMinute")}
                    isDisabled={true}
                  />
                </Stack>
              </Stack>
            )}

          {(inputsToShow && occurrenceById?.prev_materials) &&
            updatedRegisteredInputs.map((input) => (
              <Stack
                mt="4"
                key={input.input_id}
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  name={`input`}
                  label="Insumo"
                  defaultValue={input.name}
                  isDisabled={true}
                />
                <Input
                  name={`quantity`}
                  label="Quantidade"
                  defaultValue={input.quantity}
                  isDisabled={true}
                />
                <Input
                  name={`prev_quantity`}
                  label="Quantidade anterior"
                  defaultValue={input.prev_quantity}
                  isDisabled={true}
                />
              </Stack>
            ))}
          <Divider mt={4} />
          {occurrenceById?.reason && (
            <Input
              name='reason'
              label="Motivo"
              defaultValue={occurrenceById?.reason}
              isDisabled={true}
            />
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
