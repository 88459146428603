import { useEffect, useRef } from "react";
import * as yup from "yup";
import { set } from "date-fns";
import { useForm, useWatch } from "react-hook-form";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Flex, Stack, Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { useSwal } from "hooks/swal/useSwal";
import { useFiscalRetentionFunctions } from "hooks/services/fiscalRetention/useFiscalRetentionFunctions";
import { Input } from "components/Inputs/Input";
import { SubmitButton } from "components/Buttons/SubmitButton";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { useToastify } from "hooks/toastify/useToastify";
import { useAuth } from "hooks/auth/useAuth";

interface ValidateFiscalRetentionFormInputs {
  release_date: string
  release_time: string
  attachment_documentation: FileList

}
interface Params {
  service_id: string
}

function validateHasMaxFileQuantity(files: FileList) {
  if (files?.length > 3) return false

  return true
}

function validateHasFile(file: FileList) {
  if (!file?.length) return false

  return true
}

const schema = yup.object().shape({
  release_date: yup.string().typeError("Campo Obrigatório!").required("Campo Obrigatório!"),
  release_time: yup.string().typeError("Campo Obrigatório!").required("Campo Obrigatório!"),
  attachment_documentation: yup.mixed()
    .test('minLength', 'Campo Obrigatório!', value => {
      return validateHasFile(value)
    })
    .test('maxLength', 'Podem ser enviados no máximo 3 arquivos', value => {
      return validateHasMaxFileQuantity(value)
    })
})

export function ValidateFiscalRetention() {

  const { service_id }: Params = useParams()
  const { push: redirectTo } = useHistory()
  const { userLogged } = useAuth()
  const location = useLocation()
  const state = location.state as { path?: string }
  const hasPathRedirect = state?.path !== undefined && state?.path !== null

  const userHasPermissionToValidateServiceWithFiscalRetention =
    userLogged?.permissions.includes("validate-fiscal-retention");

  useEffect(() => {
    if (!userHasPermissionToValidateServiceWithFiscalRetention) {
      redirectTo('/')
    }
  }, [redirectTo, userHasPermissionToValidateServiceWithFiscalRetention])

  const {
    validateFiscalRetention: { mutateAsync: validateFiscalRetention }
  } = useFiscalRetentionFunctions()
  const { confirmMessage, standardMessage } = useSwal();
  const { promiseMessage } = useToastify()
  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<ValidateFiscalRetentionFormInputs>({
    resolver: yupResolver(schema)
  })

  const selectedPdfFilesWatch = useWatch({
    control,
    name: 'attachment_documentation'
  })

  function handleSelectPdfFiles(e: { target: HTMLInputElement }) {
    const files = e.target.files || e.target.files

    setValue('attachment_documentation', files)
  }

  const inputRefPdfFiles = useRef<HTMLInputElement | null>(null)
  const handleInputRefPdfFiles = () => inputRefPdfFiles.current?.click()

  async function handleValidateFiscalRedention(data: ValidateFiscalRetentionFormInputs) {
    const formData = new FormData()
    const [year, month, day] = data.release_date
      .split('-')
      .map(date => Number(date))

    const [hours, minutes] = data.release_time
      .split(':')
      .map(time => Number(time))

    const releaseDatetime = set(new Date(), {
      hours,
      minutes,
      date: day,
      year,
      month: month - 1
    }).toISOString()

    formData.append('release_datetime', releaseDatetime);

    Object.entries(selectedPdfFilesWatch).forEach(([key, file]) => {
      formData.append("attachment_documentation", file)
    })

    formData.append('service_id', service_id)

    const hasValidatedFiscalRetention = await confirmMessage({
      title: `Deseja validar a retenção fiscal?`,
    });

    if (hasValidatedFiscalRetention) {
      await promiseMessage(validateFiscalRetention(formData, {
        onSuccess: () => {
          if (hasPathRedirect) {
            redirectTo(state?.path)
          } else {
            redirectTo('/servicos/retencoes-fiscais')
          }
        }
      }), 'Retenção fiscal validada com sucesso!')

    } else {
      standardMessage("Ação cancelada com êxito!");
    }
  }
  return (
    <StandardBackgroundForm
      title="Validar retenção fiscal"
      onSubmit={handleSubmit(handleValidateFiscalRedention)}
    >
      <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
        <Input
          {...register("release_date")}
          name="release_date"
          label="Data da liberação"
          error={errors.release_date}
          type="date"
          required
        />
        <Input
          {...register("release_time")}
          name="release_time"
          label="Horário da liberação"
          error={errors.release_time}
          type="time"
          required
        />
      </Stack>
      <Stack w="full" direction={["column", "column", "row"]} spacing={6} mt={6}>
        <Input
          {...register("attachment_documentation")}
          name="attachment_documentation"
          accept="application/pdf"
          type="file"
          ref={inputRefPdfFiles}
          error={errors.attachment_documentation}
          hidden
          onChange={handleSelectPdfFiles}
          multiple
        >
          <Button w="full" onClick={handleInputRefPdfFiles}>Escolher arquivos</Button>
        </Input>

      </Stack>
      <Stack direction={["column", "column", "row"]} spacing={6}>
        <Table mt="4">
          <Thead>
            <Tr>
              <Th>Arquivos</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.entries(selectedPdfFilesWatch ?? {}).map(([key, file]) => {
              return (
                <Tr key={key}>
                  <Th>{file.name}</Th>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </Stack>
      <Flex
        mt="8"
        gap={2}
        direction={["column", "column", "row"]}
        justify={["center", "center", "flex-end"]}
      >
        <SubmitButton
          action="Validar"
          isSubmitting={isSubmitting}
        />

        <Button
          as={Link}
          to='/servicos/retencoes-fiscais'
          colorScheme='gray'
        >
          Retenções fiscais
        </Button>
      </Flex>
    </StandardBackgroundForm>

  )
}
