import { Td, Tr } from "@chakra-ui/react";
import { GetAuditResponse } from "api/audits/getAudit";

interface AuditQualityVisaTableRowProps {
  audit: GetAuditResponse
}

export function AuditQualityVisaTableRow({
  audit
}: AuditQualityVisaTableRowProps) {
  return (
    <>
      <Tr>
        <Td>Existe cronograma de treinamentos?</Td>
        <Td isNumeric>{audit?.audit?.visaQualityAudit?.hasTrainingSchedule ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe cronograma de auditorias?</Td>
        <Td isNumeric>{audit?.audit?.visaQualityAudit?.hasAuditsSchedule ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe documento atualizado que padronize os documentos críticos?</Td>
        <Td isNumeric>{audit?.audit?.visaQualityAudit?.hasUpdatedDocumentThatStandardizesCriticalDocuments ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe organograma atualizado?</Td>
        <Td isNumeric>{audit?.audit?.visaQualityAudit?.hasUpdatedOrganizationalChart ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe descrição de cargos e funções?</Td>
        <Td isNumeric>{audit?.audit?.visaQualityAudit?.hasCargoAndFunctionsDetail ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe documento que defina o processo de auditoria?</Td>
        <Td isNumeric>{audit?.audit?.visaQualityAudit?.hasAuditProcessDocument ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe documento que defina o processo de treinamento?</Td>
        <Td isNumeric>{audit?.audit?.visaQualityAudit?.hasTrainingProcessDocument ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe documento que descreve a limpeza e desinfecção?</Td>
        <Td isNumeric>{audit?.audit?.visaQualityAudit?.hasCleaningDesinfectionDocument ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe documento que defina as regras de biossegurança?</Td>
        <Td isNumeric>{audit?.audit?.visaQualityAudit?.hasBiossecurityRulesDocument ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe documento padrão de POP do Cliente?</Td>
        <Td isNumeric>{audit?.audit?.visaQualityAudit?.hasCustomerPopPatternDocument ? 'SIM' : 'NÃO'}</Td>
      </Tr>
    </>
  )
}
