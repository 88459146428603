import { useFormContext } from "react-hook-form";
import { Select } from "../../../components/Inputs/SelectInput";

const uploadTypes = {
  'cte_loglife': {
    type: 'CTE LOGLIFE'
  },
  'cte_complementary': {
    type: 'CTE COMPLEMENTAR'
  },
  'minuta': {
    type: 'MINUTA'
  },
}

export function SelectPdfTypeInput() {
  const { register, formState: { errors } } = useFormContext()

  return (
    <Select
      {...register("type")}
      name="type"
      placeholder="Selecione uma opção..."
      error={errors.type}
      required
    >
      {Object.entries(uploadTypes).map(([key, value]) => {
        return (
          <option key={key} value={value.type}>
            {value.type}
          </option>
        )
      })}
    </Select>
  )
}
