import * as yup from 'yup'

const holidaySchema = yup.object().shape({
  name: yup.string().required('Campo Obrigatório'),
  day: yup.number().typeError('Campo Obrigatório'),
  month: yup.number().typeError('Campo Obrigatório'),
  national: yup.string().required('Campo Obrigatório'),
  city_id: yup.string().when('national', {
    is: 'NÃO',
    then: yup.string().required('Campo Obrigatório'),
  }),
})

export { holidaySchema }
