import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Button,
  Stack,
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  negative_positive,
  payment_conditions,
  payment_types,
  material_list,
  situation,
} from '../../utils/customLists'


import { Input } from '../../components/Inputs/Input'
import { Select } from '../../components/Inputs/SelectInput'
import { TextArea } from '../../components/Inputs/TextInput'
import { apiCep } from '../../services/cepApiService/cepApi'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { toast } from 'react-toastify'
import { InputMaskCustom } from '../../components/Inputs/InputMask'
import { handleSwitchMask } from '../../utils/handleChangeMask'
import { useHub } from '../../hooks/hub/useHub'
import { useProvider } from '../../hooks/provider/useProvider'
import { isCnpjValid } from '../../utils/cpfCnpjValidateFunctions'
import { useCurrency } from 'react-hook-currency'
import { apiIbge } from '../../services/ibgeApiservice/ibgeApi'
import { formatDate } from '../../utils/DateFunctions/formatDate'
import { providerDeadlineOptions } from 'utils/CustomLists/providerDeadlineOptions'
import { positiveNegativeOptions } from 'utils/CustomLists/positiveNegativeOptions'
import { useQuery } from 'react-query'
import { statesWithUfList } from 'utils/CustomLists/statesWithUfList'

interface FormInputProps {
  company_name: string
  trading_name: string
  hub_id: string
  cnpj: string
  email: string
  material: string
  unit_cost: number
  payment_conditional: string
  day_expiration_1: string
  day_expiration_2: string
  payment_type: string
  cellphone: string
  telephone: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  business_open: string
  business_close: string
  saturday_open: string | null
  saturday_close: string | null
  sunday_open: string | null
  sunday_close: string | null
  situation: string
  material_price: string
  observation: string
  deadline: string
  is_primary_provider: string
}

interface CustomerFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
  submit: (values: FormInputProps) => Promise<void>
}

const schema = yup.object().shape({
  company_name: yup.string().required('Nome inválido'),
  trading_name: yup.string().required('Razão Social Inválida'),
  hub_id: yup.string().required('Hub inválido'),
  cnpj: yup.string().transform(isCnpjValid).required('CNPJ inválido'),
  email: yup
    .string()
    .email('Formato de e-mail inválido')
    .required('E-mail inválido'),
  material: yup.string().required('Material inválido'),
  unit_cost: yup.string().required('Custo inválido'),
  payment_conditional: yup.string().required('Condição de pagamento inválida'),
  day_expiration_1: yup.string().required('Dia de expiração inválido'),
  day_expiration_2: yup.string().required('Dia de expiração inválido'),
  payment_type: yup.string().required('Tipo de pagamento inválido'),
  cellphone: yup.string().required('Celular inválido'),
  telephone: yup.string().required('Telefone inválido'),
  cep: yup.string().required('CEP inválido'),
  state: yup.string().required('Selecione um Estado'),
  city: yup.string().required('Selecione uma Cidade'),
  street: yup.string().required('Rua inválida'),
  number: yup.string().required('Número inválido'),
  complement: yup.string().notRequired(),
  neighborhood: yup.string().required('Bairro inválido'),
  business_open: yup.string().required('Horário inválido'),
  business_close: yup.string().required('Horário inválido'),
  saturday_open: yup.string().nullable(),
  saturday_close: yup.string().nullable(),
  sunday_open: yup.string().nullable(),
  sunday_close: yup.string().nullable(),
  situation: yup.string().required(),
  material_price: yup.string().required("Campo Obrigatório"),
  is_primary_provider: yup.string().required('Campo obrigatório'),
  observation: yup.string(),
})

export function ProviderForm({
  slug,
  id,
  isDisabled = false,
  href,
  title,
  action,
  submit,
}: CustomerFormProps) {
  const [hasOperationSaturday, setHasOperationSaturday] = useState('')
  const [hasOperationSunday, setHasOperationSunday] = useState('')

  const {
    hubs: { data: hubs, isLoading: isHubLoading },
  } = useHub(null, true, false)
  const {
    provider: { data: provider, isLoading: isProviderLoading },
  } = useProvider(id || null, false, false)

  const { onChange, format } = useCurrency({
    style: 'decimal',
  })

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    resolver: yupResolver(schema),
  })

  const addressCep = watch('cep')
  const addressState = watch('state')
  const paymentCondition = watch('payment_conditional')

  const {
    data: cepData
  } = useQuery({
    queryKey: ['cep', addressCep],
    queryFn: () => apiCep.get(addressCep + '/json'),
    enabled: Boolean(addressCep) && addressCep.length === 9 && !addressCep.includes('_'),
    refetchOnWindowFocus: false
  })

  const {
    data: statesData
  } = useQuery({
    queryKey: ['ibgeStates'],
    queryFn: () => apiIbge.getStates('estados'),
    refetchOnWindowFocus: false
  })

  const stateUf = Object.entries(statesWithUfList).find(([key, value]) => {
    return key.toLowerCase() === addressState?.toLowerCase()
  })?.[1]?.uf

  const {
    data: citiesData
  } = useQuery({
    queryKey: ['ibgeCities', stateUf],
    queryFn: () => apiIbge.getCities(`/estados/${stateUf}/municipios`),
    enabled: !!stateUf,
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (cepData) {
      if (cepData?.erro) {
        toast('O cep inserido não foi encontrado!', {
          type: 'warning'
        })

      } else {

        const state = Object.entries(statesWithUfList).find(([key, value]) => {
          return value.uf === cepData.uf
        })[0]

        if (Boolean(addressCep) && addressCep.length === 9 && !addressCep.includes('_')) {
          setValue('street', cepData.logradouro)
          setValue('neighborhood', cepData.bairro)
          setValue('complement', cepData.complemento)
        }

        if (citiesData) setValue('city', cepData.localidade.toUpperCase())
        if (statesData) setValue('state', state.toUpperCase())
      }

    }
  }, [setValue, cepData, statesData, citiesData, addressCep])

  useEffect(() => {
    function run() {
      if (hasOperationSaturday === 'NÃO') {
        setValue('saturday_open', null)
        setValue('saturday_close', null)
      }

      if (hasOperationSunday === 'NÃO') {
        setValue('sunday_open', null)
        setValue('sunday_close', null)
      }

      if (paymentCondition === 'À VISTA') {
        setValue('day_expiration_1', ' ')
        setValue('day_expiration_2', ' ')
      }

      if (paymentCondition === 'FATURADO MENSAL') {
        setValue('day_expiration_2', ' ')
      }
    }
    run()
  }, [setValue, hasOperationSaturday, hasOperationSunday, paymentCondition])

  // useEffect(() => {
  //   async function run() {
  //     const cepSplited = cepValue?.split('-').join('')
  //     if (
  //       !cepSplited?.includes('_') &&
  //       cepSplited !== undefined &&
  //       cepSplited !== '' &&
  //       (slug)
  //     ) {
  //       try {
  //         const cep = await apiCep.get(`/${cepSplited}/json/`)
  //         const cityFilteredByCep = cep.localidade

  //         const statesListByCep = await apiIbge.getStates('estados')
  //         setStatesList(statesListByCep)

  //         const filteredState = statesListByCep
  //           .find(state => state.sigla === cep.uf)?.nome ?? ''

  //         setValue('state', filteredState.toUpperCase())

  //         const citiesFilteredByUf = await apiIbge.getCities(`/estados/${cep.uf}/municipios`)
  //         setCitiesList(citiesFilteredByUf)

  //         if (slug === 'adicionar') {
  //           setValue('street', cep.logradouro)
  //           setValue('neighborhood', cep.bairro)
  //           setValue('complement', cep.complemento)
  //           setValue('city', cityFilteredByCep.toUpperCase())
  //           if (citiesFilteredByUf.length) {
  //             setValue('city', cityFilteredByCep.toUpperCase())
  //           }
  //         }


  //         if (slug === 'editar' && provider && provider.cep !== cepValue) {
  //           setValue('street', cep.logradouro)
  //           setValue('neighborhood', cep.bairro)
  //           setValue('complement', cep.complemento)
  //           if (citiesFilteredByUf.length) {
  //             setValue('city', cityFilteredByCep.toUpperCase())
  //           }
  //         }

  //       } catch {
  //         toast.error('Erro ao consultar cep!')
  //       }
  //     }
  //   }

  //   run()
  // }, [setValue, cepValue, slug, provider])


  // useEffect(() => {
  //   if (provider && provider.cep === cepValue) {
  //     if (citiesList.length) {
  //       setValue('city', provider.city)
  //     }
  //   }
  // }, [setValue, cepValue, citiesList, provider])

  useEffect(() => {
    function run() {
      if (slug === 'adicionar') {
        setValue('situation', 'ATIVO')
      }

      if (slug === 'editar' || slug === 'visualizar') {
        if (provider) {
          if (provider.saturday_open === null) {
            setHasOperationSaturday('NÃO')
          } else {
            setHasOperationSaturday('SIM')
          }

          if (provider.sunday_open === null) {
            setHasOperationSunday('NÃO')
          } else {
            setHasOperationSunday('SIM')
          }

          const timeOpenWeek = formatDate
            .handle(provider.business_open, "DateOnlyWithHourMinute")
          const timeCloseWeek = formatDate
            .handle(provider.business_close, "DateOnlyWithHourMinute")
          const timeOpenSaturday = formatDate
            .handle(provider.saturday_open, "DateOnlyWithHourMinute")
          const timeCloseSaturday = formatDate
            .handle(provider.saturday_close, "DateOnlyWithHourMinute")
          const timeOpenSunday = formatDate
            .handle(provider.sunday_open, "DateOnlyWithHourMinute")
          const timeCloseSunday = formatDate
            .handle(provider.sunday_close, "DateOnlyWithHourMinute")

          setValue('company_name', provider.company_name)
          setValue('trading_name', provider.trading_name)
          setValue('hub_id', provider.hub_id)
          setValue('cnpj', provider.cnpj)
          setValue('email', provider.email)
          setValue('material', provider.material)
          setValue('unit_cost', provider.unit_cost)
          setValue('payment_conditional', provider.payment_conditional)
          setValue('day_expiration_1', provider.day_expiration_1)
          setValue('day_expiration_2', provider.day_expiration_2)
          setValue('payment_type', provider.payment_type)
          setValue('cellphone', provider.cellphone)
          setValue('telephone', provider.telephone)
          setValue('cep', provider.cep)
          setValue('street', provider.street)
          setValue('number', provider.number)
          setValue('complement', provider.complement)
          setValue('neighborhood', provider.neighborhood)
          setValue('state', provider.state)
          // setValue('city', provider.city)
          setValue('business_open', timeOpenWeek)
          setValue('business_close', timeCloseWeek)
          setValue('saturday_open', timeOpenSaturday)
          setValue('saturday_close', timeCloseSaturday)
          setValue('sunday_open', timeOpenSunday)
          setValue('sunday_close', timeCloseSunday)
          setValue('situation', provider.situation)
          setValue('material_price', String(provider.material_price))
          setValue('observation', provider.observation)
          setValue('deadline', String(provider.deadline))
          setValue('is_primary_provider', provider.is_primary_provider ? 'yes' : 'no')
        }
      }
    }

    run()
  }, [setValue, slug, provider])

  if (isHubLoading && isProviderLoading && slug !== 'adicionar') {
    return <GeneralContentLoading />
  }

  return (

    <Flex>
      <Box
        as="form"
        flex="1"
        borderRadius="8px"
        bg="white"
        p={['6', '8']}
        onSubmit={handleSubmit(submit)}
        noValidate
      >
        <Heading size="lg" fontFamily="poppins">
          {title}
        </Heading>

        <Divider my="6" borderColor="gray.700" />

        <Stack>
          <Select
            {...register('situation')}
            name="situation"
            label="Situação"
            error={errors.situation}
            isDisabled={slug === 'adicionar' ? true : isDisabled}
            situations={situation}
            placeholder="Selecione uma opção..."
            required
          />
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Input
            {...register('company_name')}
            isDisabled={isDisabled}
            name="company_name"
            label="Nome Fantasia"
            error={errors.company_name}
            required
          />

          <Input
            {...register('trading_name')}
            isDisabled={isDisabled}
            name="trading_name"
            label="Razão Social"
            error={errors.trading_name}
            required
          />
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Select
            {...register('hub_id')}
            name="hub_id"
            label="Pertence ao HUB"
            error={errors.hub_id}
            isDisabled={isDisabled}
            hubs={hubs}
            placeholder="Selecione o HUB"
            required
          />

          <Controller
            control={control}
            name="cnpj"
            render={({ field: { onChange, value } }) => (
              <InputMaskCustom
                {...register('cnpj')}
                isDisabled={isDisabled}
                name="cnpj"
                label="CNPJ"
                mask={handleSwitchMask('cnpj')}
                defaultValue={value}
                onChange={onChange}
                error={errors.cnpj}
                required
              />
            )}
          />
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Input
            {...register('email')}
            name="email"
            label="E-mail"
            error={errors.email}
            isDisabled={isDisabled}
            required
          />
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Select
            {...register('material')}
            name="material"
            material_list={material_list}
            label="Material"
            placeholder="Selecione uma opção..."
            error={errors.material}
            isDisabled={isDisabled}
            required
          />
          <Stack
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('material_price')}
              name="material_price"
              label="Preço do material"
              onChange={onChange}
              defaultValue={format('000')}
              error={errors.material_price}
              isDisabled={isDisabled}
              addChildren="R$"
              required
            />

            <Input
              {...register('unit_cost')}
              name="unit_cost"
              addChildren="R$"
              label="Custo por Unidade"
              onChange={onChange}
              defaultValue={format('000')}
              error={errors.unit_cost}
              isDisabled={isDisabled}
              required
            />
          </Stack>
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Select
            {...register('payment_conditional')}
            name="payment_conditional"
            label="Condição de Pagamento"
            payment_conditions={payment_conditions}
            placeholder="Selecione uma opção..."
            error={errors.payment_conditional}
            isDisabled={isDisabled}
            required
          />

          {paymentCondition === 'FATURADO MENSAL' ? (
            <Input
              {...register('day_expiration_1')}
              name="day_expiration_1"
              label="Dia de Vencimento"
              error={errors.day_expiration_1}
              isDisabled={isDisabled}
              required
            />
          ) : paymentCondition === 'FATURADO QUINZENAL' ? (
            <HStack w="full" spacing="24px">
              <Input
                {...register('day_expiration_1')}
                name="day_expiration_1"
                label="1º Dia de Vencimento"
                error={errors.day_expiration_1}
                isDisabled={isDisabled}
                required
              />
              <Input
                {...register('day_expiration_2')}
                name="day_expiration_2"
                label="2º Dia de Vencimento"
                error={errors.day_expiration_2}
                isDisabled={isDisabled}
                required
              />
            </HStack>
          ) : null}
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Select
            {...register('payment_type')}
            name="payment_type"
            label="Tipo de Pagamento"
            placeholder="Selecione uma opção..."
            error={errors.payment_type}
            payment_types={payment_types}
            isDisabled={isDisabled}
            required
          />
          <HStack w="full" spacing="24px">
            <Controller
              control={control}
              name="cellphone"
              render={({ field: { onChange, value } }) => (
                <InputMaskCustom
                  {...register('cellphone')}
                  mask={handleSwitchMask('cellphone')}
                  onChange={onChange}
                  defaultValue={value}
                  name="cellphone"
                  label="Celular"
                  error={errors.cellphone}
                  isDisabled={isDisabled}
                  required
                />
              )}
            />

            <Controller
              control={control}
              name="telephone"
              render={({ field: { onChange, value } }) => (
                <InputMaskCustom
                  {...register('telephone')}
                  name="telephone"
                  defaultValue={value}
                  onChange={onChange}
                  mask={handleSwitchMask('phone')}
                  label="Telefone"
                  error={errors.telephone}
                  isDisabled={isDisabled}
                  required
                />
              )}
            />
          </HStack>
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Controller
            control={control}
            name="cep"
            render={({ field: { onChange, value } }) => (
              <InputMaskCustom
                {...register('cep')}
                name="cep"
                label="CEP"
                error={errors.cep}
                isDisabled={isDisabled}
                defaultValue={value}
                onChange={onChange}
                mask={handleSwitchMask('cep')}
                required
              />
            )}
          />
          <HStack w="full" spacing="24px">
            <Input
              {...register('street')}
              name="street"
              label="Rua"
              error={errors.street}
              isDisabled={isDisabled}
              required
            />

            <Input
              {...register('number')}
              name="number"
              label="Número"
              error={errors.number}
              isDisabled={isDisabled}
              required
            />
          </HStack>
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Input
            {...register('complement')}
            name="complement"
            label="Complemento"
            error={errors.complement}
            isDisabled={isDisabled}
          />
          <Input
            {...register('neighborhood')}
            name="neighborhood"
            label="Bairro"
            error={errors.neighborhood}
            isDisabled={isDisabled}
            required
          />

          <Select
            {...register('state')}
            name="state"
            label="Estado"
            error={errors.state}
            options={statesData?.map((state) => {
              return {
                key: state.id.toString(),
                value: state.nome.toUpperCase(),
                showOption: state.nome,
              }
            })}
            placeholder="Selecione um estado"
            isDisabled={isDisabled}
            required
          />
          <Select
            {...register('city')}
            name="city"
            label="Cidade"
            error={errors.city}
            options={citiesData?.map((city) => {
              return {
                key: city.id.toString(),
                showOption: city.nome,
                value: city.nome.toUpperCase()
              }
            })}
            placeholder="Selecione uma Cidade"
            isDisabled={isDisabled}
            required
          />
        </Stack>

        <Stack
          mt='4'
        >
          <Select
            {...register('deadline')}
            name='deadline'
            label='Prazo de solicitação'
            placeholder='Selecione uma opção...'
            options={providerDeadlineOptions}
            required
          />

        </Stack>
        <Stack
          spacing="6"
          mt="4"
          direction={['column', 'column', 'row']}
        >
          <Select
            {...register("is_primary_provider")}
            name="is_primary_provider"
            placeholder="Selecione uma opção..."
            options={positiveNegativeOptions}
            label="É o fornecedor principal?"
            error={errors.is_primary_provider}
            isDisabled={isDisabled}
            required
          />
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Input
            value="SIM"
            name="week_select"
            label="Funcionamento de segunda a sexta?"
            error={undefined}
            isDisabled={true}
            required
          />
          <HStack w="full" spacing="24px">
            <Input
              {...register('business_open')}
              name="business_open"
              label="Horário de abertura"
              type="time"
              error={errors.business_open}
              isDisabled={isDisabled}
              required
            />
            <Input
              {...register('business_close')}
              name="business_close"
              label="Horário de fechamento"
              type="time"
              error={errors.business_close}
              isDisabled={isDisabled}
              required
            />
          </HStack>
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Select
            onChange={(e) => setHasOperationSaturday(e.target.value)}
            value={hasOperationSaturday}
            negative_positive={negative_positive}
            placeholder="Selecione uma opção..."
            name="weekend_select"
            label="Funcionamento aos sábados?"
            error={undefined}
            isDisabled={isDisabled}
            required
          />
          <HStack w="full" spacing="24px">
            <Input
              {...register('saturday_open')}
              name="saturday_open"
              label="Horário de abertura"
              type="time"
              error={
                hasOperationSaturday === 'SIM'
                  ? errors.saturday_open
                  : undefined
              }
              isDisabled={
                !(slug !== 'visualizar' && hasOperationSaturday === 'SIM')
              }
              required
            />
            <Input
              {...register('saturday_close')}
              name="saturday_close"
              label="Horário de fechamento"
              type="time"
              error={
                hasOperationSaturday === 'SIM'
                  ? errors.saturday_close
                  : undefined
              }
              isDisabled={
                !(slug !== 'visualizar' && hasOperationSaturday === 'SIM')
              }
              required
            />
          </HStack>
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Select
            onChange={(e) => setHasOperationSunday(e.target.value)}
            value={hasOperationSunday}
            negative_positive={negative_positive}
            placeholder="Selecione uma opção..."
            name="weekend_select"
            label="Funcionamento aos domingos?"
            error={undefined}
            isDisabled={isDisabled}
            required
          />
          <HStack w="full" spacing="24px">
            <Input
              {...register('sunday_open')}
              name="sunday_open"
              label="Horário de abertura"
              type="time"
              error={
                hasOperationSunday === 'SIM' ? errors.sunday_open : undefined
              }
              isDisabled={
                !(slug !== 'visualizar' && hasOperationSunday === 'SIM')
              }
              required
            />
            <Input
              {...register('sunday_close')}
              name="sunday_close"
              label="Horário de fechamento"
              type="time"
              error={
                hasOperationSunday === 'SIM' ? errors.sunday_close : undefined
              }
              isDisabled={
                !(slug !== 'visualizar' && hasOperationSunday === 'SIM')
              }
              required
            />
          </HStack>
        </Stack>
        <TextArea
          {...register('observation')}
          name="observation"
          label="Observações"
          isDisabled={isDisabled}
        />
        <Flex mt="8" justify="flex-end">
          <HStack>
            {slug === 'visualizar' ? (
              <Link to={href}>
                <Button
                  type="button"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Carregando"
                >
                  {action}
                </Button>
              </Link>
            ) : (
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
                loadingText="Carregando"
              >
                {action}
              </Button>
            )}

            <Link to="/fornecedores">
              <Button type="button" colorScheme="gray">
                Lista de Fornecedores
              </Button>
            </Link>
          </HStack>
        </Flex>
      </Box>
    </Flex>

  )
}
