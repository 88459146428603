import { Box, Button, Flex, FormControl, FormLabel, Heading, IconButton, Modal, ModalOverlay, Select, Text, useDisclosure, VStack } from "@chakra-ui/react";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { useSearchParams } from "hooks/useSearchParams";
import { useHistory } from "react-router-dom";
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form";
import { approveInvoice } from "api/invoices/approveInvoice";
import { FormEvent, useReducer } from "react";
import { AttachmentsTableRowField } from "./AttachmentsTableRowField";
import { CreateSupport } from "pages/Support/components/CreateSupport";
import { FaPlus } from "react-icons/fa";

interface ApproveInvoiceProps {
  invoiceId: string
}

export interface ApproveInvoiceSchema {
  isWithoutReturn: string
  attachments: {
    file: FileList
    type: "billet" | "receipt"
  }[]
}

interface SetAttachmentIdActionPayload {
  attachment: {
    _id: string
    type: "billet" | "receipt"
  }
}

export interface SetAttachmentIdAction {
  type: 'ADD' | 'DELETE'
  payload: SetAttachmentIdActionPayload
}

function reducer(state: { _id: string; type: "billet" | "receipt" }[], action: SetAttachmentIdAction) {
  if (action.type === 'ADD') {
    return [...state, action.payload.attachment]
  }

  if (action.type === "DELETE") {
    return state.filter((attachment) => attachment._id !== action.payload.attachment._id);
  }

  return state
}



export const approveInvoiceSchema = yup.object({
  isWithoutReturn: yup.string().required(),

})

export function ApproveInvoice({ invoiceId }: ApproveInvoiceProps) {


  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const [attachmentsIds, setAttachmentId] = useReducer(reducer, [])

  const formMethods = useForm<ApproveInvoiceSchema>({
    resolver: yupResolver(approveInvoiceSchema),
    defaultValues: {
      attachments: [{ file: undefined, type: undefined }]
    }
  })

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = formMethods

  const {
    fields: attachmentsFields,
    append: appendAttachmentField,
    remove: removeAttachmentField,
  } = useFieldArray({
    control,
    name: 'attachments'
  })

  const [attachments, isWithoutReturn] = useWatch({
    control,
    name: ['attachments', 'isWithoutReturn']
  })

  const isOpenSupport = isWithoutReturn === 'no'
  const supportRequested = searchParams.get('supportRequested')
  const hasSupportRequested = supportRequested === 'true'

  const {
    isOpen: isCreateSupportOpen,
    onOpen: onOpenCreateSupport,
    onClose: onCloseCreateSupport
  } = useDisclosure()

  const { mutateAsync: approveInvoiceFn } = useMutation({
    mutationFn: approveInvoice,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'invoices' })
      queryClient.invalidateQueries({ queryKey: ['invoice', invoiceId] })

      searchParams.delete('supportRequested')
      replace({ search: searchParams.toString() })
      reset()
    }
  })

  async function handleApproveInvoice(values: ApproveInvoiceSchema, event: FormEvent) {
    event.preventDefault()
    event.stopPropagation()

    await promiseMessage(approveInvoiceFn({
      body: {
        isWithoutReturn: values.isWithoutReturn === 'yes',
        ...(values.isWithoutReturn === 'no' && {
          attachments: attachmentsIds?.filter((attachment, index, self) =>
            index === self.findIndex((t) => t.type === attachment.type)
          )
        }),
      },
      invoiceId
    }), 'Fatura aprovada')
  }
  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleApproveInvoice)}
    >
      <Heading letterSpacing="tight" size='sm'>Aprovar fatura</Heading>
      <FormControl isInvalid={!!errors.isWithoutReturn} mt={3}>
        <FormLabel fontSize="sm">
          Aprovada sem retorno:
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register('isWithoutReturn')}
          name="isWithoutReturn"
          placeholder="Selecione..."
          size="sm"
          rounded="md"
        >
          <option value='yes'>Sim</option>
          <option value='no'>Não</option>
        </Select>
      </FormControl>
      {isWithoutReturn === 'no' && (
        <>
          <FormLabel fontSize='sm' mt={3}>
            Anexar boleto e nota fiscal
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <VStack spacing={3}>
            {attachmentsFields.map((field, index) => {

              const isFilledField = Boolean(attachments[index]?.file?.length);

              return (

                <FormProvider {...formMethods}>
                  <AttachmentsTableRowField
                    key={field.id}
                    index={index}
                    onSetAttachmentId={setAttachmentId}
                    isFilledField={isFilledField}
                    onAppendField={() => appendAttachmentField({})}
                    onRemoveField={() => removeAttachmentField(index)} />
                </FormProvider>
              );
            })}
          </VStack>
        </>
      )}
      {isOpenSupport && (
        <Flex mt={3} alignItems="baseline" justifyContent="space-between">
          <FormLabel fontSize='sm'>
            Abrir atendimento
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <IconButton
            aria-label="Abrir atendimento"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onOpenCreateSupport} />

        </Flex>
      )}
      <Modal
        isOpen={isCreateSupportOpen}
        onClose={onCloseCreateSupport}
        isCentered
        size="2xl"
      >
        <ModalOverlay />
        <CreateSupport
          onClose={onCloseCreateSupport}
          required={true} />
      </Modal>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting || (isOpenSupport && !hasSupportRequested)}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
