import { MenuList, Flex, MenuItem, Checkbox, MenuDivider, Text } from "@chakra-ui/react"
import { Filters } from "contexts/LoadRatioServicesListContext"
import { useLoadRatioServicesListContext } from "hooks/loadRatio/useLoadRatioServicesListContext"
import React from "react"
import { FilterOptions } from "../../LoadRatioServices"


interface LoadRatioServicesCheckboxFilterButtonContentProps {
  filterOptions: FilterOptions[]
  onSetFilterOption: (option: string) => void
}

export function LoadRatioServicesCheckboxFilterButtonContent({
  filterOptions,
  onSetFilterOption,
}: LoadRatioServicesCheckboxFilterButtonContentProps) {
  const {  handleRemoveFilters } = useLoadRatioServicesListContext()

  function setFilterOptions(event: React.ChangeEvent<HTMLInputElement>) {
    handleRemoveFilters(event.target.name as keyof Filters)
    onSetFilterOption(event.target.value)
  }

  return (
    <MenuList
      rounded="xl"
      boxShadow="md"
      w="full"
    >
      <Flex maxH="300px" overflowY="auto" w="full" direction="column">

        {filterOptions.map((filterOption) => {
          return (
            <MenuItem key={filterOption.value} w="full">
              <Checkbox
                name={filterOption.value}
                onChange={setFilterOptions}
                w="full"
                value={filterOption.value}
                checked={filterOption.selected}
              >
                <Text w="full" fontSize="xs">
                  {filterOption.showValue}
                </Text>
              </Checkbox>
            </MenuItem>
          )
        })}
      </Flex>
      <MenuDivider />
    </MenuList>
  )
}
