import { Box, Flex } from "@chakra-ui/react";

interface CollectStatusProps {
  status: 'DONE' | 'UNSUCCESS' | 'GOING' | 'VALIDATEUNSUCCESS';
}

const map = {
  DONE: 'Finalizada',
  UNSUCCESS: 'Sem sucesso',
  GOING: 'Em andamento',
  VALIDATEUNSUCCESS: 'Sem sucesso (em validação)'
}

export function CollectStatus({ status }: CollectStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {['GOING', 'VALIDATEUNSUCCESS'].includes(status) && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'DONE' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'UNSUCCESS' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {map[status]}
    </Flex>
  )
}
