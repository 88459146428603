import { Box, FormControl, FormLabel, Textarea } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { getSupportSubject } from "api/supports/getSupportSubject";
import { FormEvent } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useQuery } from "react-query";
import { CreateSupportSchema } from "../CreateSupport";
import { KPIChangeForm } from "./KPIChangeForm";


export function CreateQualitySupport() {

  const { register, control, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const [supportSubjectId] = useWatch({
    control,
    name: ['subject']
  })

  const {
    data: supportSubjectResult
  } = useQuery({
    queryFn: () => getSupportSubject({ supportSubjectId }),
    queryKey: ['support-subject', supportSubjectId],
    enabled: !!supportSubjectId
  })


  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value
    const labelMap: { [key in keyof FormDataItem]: string } = {
      description: 'Descreva o que precisa'
    };

    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };

  const hasDescriptioInSubject = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Solicitação de treinamento'.toLowerCase()
  const subjectIsKPIChange = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Alteração de KPI'.toLowerCase()

  return (
    <Box w='full'>

      {hasDescriptioInSubject && (
        <FormControl isInvalid={!!errors.formData?.description} mt={3}>
          <FormLabel fontSize="sm">
            Descreva o que precisa
          </FormLabel>
          <Textarea
            {...register('formData.description.value')}
            name='formData.description.value'
            size='sm'
            rounded='md'
            onChangeCapture={handleInputChange('description')}
          />
        </FormControl>
      )}

      {subjectIsKPIChange && (
        <KPIChangeForm />
      )}

    </Box>
  )
}
