import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  Button,
  useMediaQuery,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye, FiTrash2 } from 'react-icons/fi'
import { useState, useEffect, Fragment } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { IHubsProps } from '../../../utils/RequestFunctions/Hubs/requestHubFunctions'
import { CSVLink } from 'react-csv'
import { formatDate } from '../../../utils/DateFunctions/formatDate'
import { useCollectorsFunctions } from 'hooks/collector/useCollectorsFunctions'
import { useAuth } from 'hooks/auth/useAuth'
import { useSwal } from 'hooks/swal/useSwal'
import { Collector } from 'hooks/collector/dtos/Collector'


interface CollectorTableProps {
  hubs: IHubsProps[]
  collectors: Collector[]
}

const collectorOptions = [
  { key: 'tradingName', value: 'Nome Fantasia', checked: false },
  { key: 'companyName', value: 'Razão Social', checked: false },
  { key: 'hub', value: 'Hub', checked: false },
  { key: 'situation', value: 'Situação', checked: false },
]

const collectorOrderOptions = [
  { key: 'tradingName', value: 'Nome Fantasia' },
  { key: 'companyName', value: 'Razão Social' },
]

const headers = [
  { label: 'SITUAÇÃO', key: 'situation' },
  { label: 'RAZÃO SOCIAL', key: 'company_name' },
  { label: 'NOME FANTASIA', key: 'trading_name' },
  { label: 'LISTA DE HUBS', key: 'hub_list' },
  { label: 'CNPJ', key: 'cnpj' },
  { label: 'EMAIL OPERACIONAL', key: 'operational_email' },
  { label: 'EMAIL FINANCEIRO', key: 'financial_email' },
  { label: 'CUSTO POR ATRASOS', key: 'delay_cost' },
  { label: 'CELULAR', key: 'cellphone' },
  { label: 'TELEFONE', key: 'telephone' },
  { label: 'CEP', key: 'cep' },
  { label: 'ESTADO', key: 'state' },
  { label: 'CIDADE', key: 'city' },
  { label: 'RUA', key: 'street' },
  { label: 'NÚMERO', key: 'number' },
  { label: 'BAIRRO', key: 'neighborhood' },
  { label: 'COMPLEMENTO', key: 'complement' },
  { label: 'REGISTRO MUNICIPAL', key: 'municipal_register' },
  { label: 'TIPO DE PAGAMENTO', key: 'payment_type' },
  { label: 'DATA DE VENCIMENTO', key: 'day_expiration' },
  { label: 'HORÁRIO ABERTURA DE SEG. A SEXTA', key: 'business_open' },
  { label: 'HORÁRIO FECHAMENTO DE SEG. A SEXTA', key: 'business_close' },
  { label: 'HORÁRIO ABERTURA SÁBADOS', key: 'saturday_open' },
  { label: 'HORÁRIO FECHAMENTO SÁBADOS', key: 'saturday_close' },
  { label: 'HORÁRIO ABERTURA DOMINGO', key: 'sunday_open' },
  { label: 'HORÁRIO FECHAMENTO DOMINGO', key: 'sunday_close' },
  { label: 'OBSERVAÇÕES', key: 'observation' },
]

function formatValuesToReport(
  collectorsData: Collector[],
  hubs: IHubsProps[],
) {
  const collectorsDataFormated = collectorsData.map((collector) => {
    return {
      ...collector,
      complement: collector.complement ? collector.complement : '-',
      hub_list: hubs
        .filter((hub) => collector.hub_list.includes(hub.id))
        .map((hub) => hub.name)
        .join(', '),
      business_open: formatDate.handle(
        collector.business_open,
        'DateOnlyWithHourMinute',
      ),
      business_close: formatDate.handle(
        collector.business_close,
        'DateOnlyWithHourMinute',
      ),
      saturday_open: collector.saturday_open
        ? formatDate.handle(collector.saturday_open, 'DateOnlyWithHourMinute')
        : '-',
      saturday_close: collector.saturday_close
        ? formatDate.handle(collector.saturday_close, 'DateOnlyWithHourMinute')
        : '-',
      sunday_open: collector.sunday_open
        ? formatDate.handle(collector.sunday_open, 'DateOnlyWithHourMinute')
        : '-',
      sunday_close: collector.sunday_close
        ? formatDate.handle(collector.sunday_close, 'DateOnlyWithHourMinute')
        : '-',
      observation: collector.observation !== '' ? collector.observation : '-',
    }
  })

  return collectorsDataFormated
}

export function CollectorTable({ hubs, collectors }: CollectorTableProps) {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE
  const [searchedCollectors, setSearchedCollectors] = useState<
    Collector[]
  >([])
  const [slicedCollectors, setSlicedCollectors] = useState<Collector[]>([])

  const [collectorFiltered, setCollectorFiltered] = useState('')
  const [collectorSocialFiltered, setCollectorSocialFiltered] = useState('')
  const [hubsFiltered, setHubsFiltered] = useState('')
  const [situationFiltered, setSituationFiltered] = useState('')

  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const {
    deleteCollector: { mutateAsync: deleteCollector },
  } = useCollectorsFunctions()
  const { userLogged } = useAuth()
  const { confirmMessage, standardMessage } = useSwal()

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedCollectors?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  const csvReportProps = {
    data: formatValuesToReport(slicedCollectors, hubs),
    headers,
    filename: `relatório-cadastro-coletadores`,
  }

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(collectorOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(collectorOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      setSlicedCollectors(collectors)
    }

    run()
  }, [itemLimit, currentPage, offset, collectors])

  useEffect(() => {
    function run() {
      const newSlicedCollectors = slicedCollectors?.filter((collectors) => {
        const collectorsHub = hubs
          .filter((hub) => collectors.hub_list.includes(hub.id))
          .map((hub) => hub.name)

        const collectorFilter = searchBoxFilter(
          collectors.trading_name,
          collectorFiltered,
        )
        const collectorSocialFilter = searchBoxFilter(
          collectors.company_name,
          collectorSocialFiltered,
        )
        const hubFilter = searchBoxFilter(
          collectorsHub.join(', '),
          hubsFiltered,
        )
        const situationFilter = searchBoxFilter(
          collectors.situation,
          situationFiltered,
        )

        if (
          collectorFiltered === '' &&
          collectorSocialFiltered === '' &&
          hubsFiltered === '' &&
          situationFiltered === ''
        )
          return collectors

        return (
          collectorFilter &&
          collectorSocialFilter &&
          hubFilter &&
          situationFilter
        )
      })

      if (orderOptionSelected.length > 0) {
        const slicedCollectorsByOrder = newSlicedCollectors.sort(
          (a, b) =>
            (orderOptionSelected.includes('tradingName') &&
              a.trading_name.localeCompare(b.trading_name)) ||
            (orderOptionSelected.includes('companyName') &&
              a.company_name.localeCompare(b.company_name)) ||
            0,
        )

        setSearchedCollectors(slicedCollectorsByOrder)
      } else {
        setSearchedCollectors(newSlicedCollectors)
      }
    }
    run()
  }, [
    collectorFiltered,
    collectorSocialFiltered,
    hubsFiltered,
    situationFiltered,
    slicedCollectors,
    orderOptionSelected,
    hubs,
  ])

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  async function handleDeleteCollector(collectorId: string) {
    const hasDeleteCollector = await confirmMessage({ title: "Deseja excluir um coletador?" })

    if (hasDeleteCollector) {
      await deleteCollector(collectorId)
    } else {
      await standardMessage('Ação cancelada com êxito!')
    }
  }

  return (
    <>
      {slicedCollectors.length <= 0 ? (
        <Alert
          status="info"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Oops!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Não há dados para mostrar aqui!
          </AlertDescription>
        </Alert>
      ) : (
        <>
          <TableFilterButton />
          <Stack mt="4" direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Fragment key={option.key}>
                  <Flex>
                    {option.key === 'tradingName' && (
                      <SearchBox
                        placeholder="Buscar Nome Fantasia..."
                        handleSearch={(e) =>
                          setCollectorFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'companyName' && (
                      <SearchBox
                        placeholder="Buscar Razão Social..."
                        handleSearch={(e) =>
                          setCollectorSocialFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'hub' && (
                      <SearchBox
                        placeholder="Buscar Hub..."
                        handleSearch={(e) => setHubsFiltered(e.target.value)}
                      />
                    )}
                    {option.key === 'situation' && (
                      <SearchBox
                        placeholder="Buscar Situação..."
                        handleSearch={(e) =>
                          setSituationFiltered(e.target.value)
                        }
                      />
                    )}
                  </Flex>
                </Fragment>
              ))}
          </Stack>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  {collectorOptions.map((option) => (
                    <Th key={option.key} fontSize="12" color="blue.900">
                      {option.value.toUpperCase()}
                    </Th>
                  ))}
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchedCollectors
                  .slice(offset, offset + Number(itemLimit))
                  .map((collector, index) => {
                    const collectorsHub = hubs
                      .filter((hub) => collector.hub_list.includes(hub.id))
                      .map((hub) => hub.name)
                    return (
                      <Tr key={index}>
                        <Td fontSize="12">
                          {collector.trading_name.toUpperCase()}
                        </Td>
                        <Td fontSize="12">
                          {collector.company_name.toUpperCase()}
                        </Td>
                        <Td fontSize="12">
                          {collectorsHub.join(', ').toUpperCase()}
                        </Td>
                        <Td fontSize="12">
                          {collector.situation.toUpperCase()}
                        </Td>
                        <Td isNumeric>
                          {userLogged !== null && (
                            <>
                              {userLogged.permissions.includes(
                                'view-collector',
                              ) && (
                                  <Link
                                    to={`/coletador/visualizar/${collector.id}`}
                                  >
                                    <Icon
                                      cursor="pointer"
                                      as={FiEye}
                                      fontSize="20"
                                      ml="4"
                                      mt={['2', '2', '0']}
                                    />
                                  </Link>
                                )}
                              {userLogged.permissions.includes(
                                'edit-collector',
                              ) && (
                                  <Link to={`/coletador/editar/${collector.id}`}>
                                    <Icon
                                      cursor="pointer"
                                      as={FiEdit3}
                                      fontSize="20"
                                      ml="4"
                                      mt={['2', '2', '0']}
                                    />
                                  </Link>
                                )}
                              {userLogged.permissions.includes(
                                'remove-collector',
                              ) && (
                                  <Icon
                                    cursor="pointer"
                                    as={FiTrash2}
                                    fontSize="20"
                                    ml="4"
                                    mt={['2', '2', '0']}
                                    onClick={() =>
                                      handleDeleteCollector(collector.id)
                                    }
                                  />
                                )}
                            </>
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </Box>
          <Flex justifyContent="flex-end" mt="4">
            <CSVLink
              {...csvReportProps}
              separator={';'}
              style={{
                flex: isWideVersion ? 0 : 1,
                width: isWideVersion ? '' : '100%',
              }}
            >
              <Button
                type="button"
                colorScheme="green"
                w={['full', 'full', '']}
              >
                Exportar CSV
              </Button>
            </CSVLink>
          </Flex>

          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )
      }
    </>
  )
}
