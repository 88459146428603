import { Button, Flex, Icon, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { useAggregateFunctions } from "hooks/aggregate/useAggregateFunctions";
import { useAuth } from "hooks/auth/useAuth";
import { ExtraDiscount } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts";
import { useToastify } from "hooks/toastify/useToastify";
import { FaArrowRight, FaClone, FaPen, FaSearch, FaTimes } from "react-icons/fa";
import { useQueryClient } from "react-query";
import { AggregateExtraDiscountDetails, getStatus } from "./AggregateExtraDiscountDetail";
import { AggregateExtraDiscountStatus } from "./AggregateExtraDiscountStatus";

interface AggregateExtraDiscountTableRowProps {
  extraDiscount: ExtraDiscount
}

export function AggregateExtraDiscountTableRow({ extraDiscount }: AggregateExtraDiscountTableRowProps) {

  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const userCanDeleteAggregateExtraDiscount = userLogged?.permissions?.includes(
    'delete-aggregate-extra-discount'
  )
  const userCanApproveAggregateExtraDiscount = userLogged?.permissions?.includes(
    'approve-aggregate-extra-discount'
  )
  const userCanRejectAggregateExtraDiscount = userLogged?.permissions?.includes(
    'reject-aggregate-extra-discount'
  )
  const userCanEffectiveAggregateExtraDiscount = userLogged?.permissions?.includes(
    'effective-aggregate-extra-discount'
  )

  const userCanEditAggregateExtraDiscount = userLogged?.permissions?.includes(
    'update-aggregate-extra-discount'
  )


  const status = getStatus({
    approved_at: extraDiscount?.approved_at,
    effective_at: extraDiscount?.effective_at,
    rejected_at: extraDiscount?.rejected_at
  })

  const {
    isOpen: isAggregateExtraDiscountDetailModalOpen,
    onToggle: onToggleAggregateExtraDiscountDetailModal,
  } = useDisclosure()

  const [serviceYear, serviceMonth, serviceDay] = extraDiscount.service_date.split('-')

  const {
    approveAggregateExtraDiscount: {
      mutateAsync: approveAggregateExtraDiscount
    },
    rejectAggregateExtraDiscount: {
      mutateAsync: rejectAggregateExtraDiscount
    },
    deleteAggregateExtraDiscount: {
      mutateAsync: deleteAggregateExtraDiscount
    },
    effectiveAggregateExtraDiscount,
  } = useAggregateFunctions()

  async function handleApproveAggregateExtraDiscount() {
    await promiseMessage(approveAggregateExtraDiscount(
      extraDiscount?.id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['aggregates-extras-discounts'])
      }
    }
    ), 'Extra/Desconto de agregado aprovado com sucesso!')
  }

  async function handleEffectiveAggregateExtraDiscount() {
    await promiseMessage(effectiveAggregateExtraDiscount.mutateAsync(
      extraDiscount?.id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('aggregates-extras-discounts')
      }
    }
    ), 'Extra/Desconto de agregado efetivado com sucesso!')
  }

  async function handleRejectAggregateExtraDiscount() {
    await promiseMessage(rejectAggregateExtraDiscount(
      extraDiscount?.id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['aggregates-extras-discounts'])
      }
    }
    ), 'Extra/Desconto de agregado recusado com sucesso!')
  }

  async function handleDeleteAggregateExtraDiscount() {
    await promiseMessage(deleteAggregateExtraDiscount(
      extraDiscount?.id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['aggregates-extras-discounts'])
      }
    }
    ), 'Extra/Desconto de agregado excluido com sucesso!')
  }

  function handleUpdateAggregateExtraDiscount() {
    window.open(`/extra-desconto/${extraDiscount?.id}/agregado`, '_blank')
  }


  return (
    <Tr>
      <Td>
        <Flex align="center" gap={3}>
          <IconButton
            aria-label="Visualizar extra e desconto"
            icon={<FaSearch />}
            size="sm"
            variant="ghost"
            onClick={onToggleAggregateExtraDiscountDetailModal}
          />
          {userCanEditAggregateExtraDiscount && (
            <IconButton
              aria-label="Editar extra/desconto"
              icon={<FaPen />}
              variant="ghost"
              size="sm"
              onClick={handleUpdateAggregateExtraDiscount} />
          )}
          {extraDiscount.is_duplicated && (
            <Icon as={FaClone} mx="2px" title="Extra/Desconto duplicado"/>
          )}
        </Flex>
        <Modal
          isOpen={isAggregateExtraDiscountDetailModalOpen}
          onClose={onToggleAggregateExtraDiscountDetailModal}
          size='6xl'
        >
          <ModalOverlay />
          <AggregateExtraDiscountDetails
            extraDiscount={extraDiscount}
          />
        </Modal>
      </Td>
      <Td>{`${extraDiscount?.aggregate?.driver?.firstname} ${extraDiscount?.aggregate?.driver?.lastname} `}</Td>
      <Td>{extraDiscount.type}</Td>
      <Td>{`${serviceDay}/${serviceMonth}/${serviceYear}`}</Td>
      <Td>{
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(extraDiscount.value / 100)
      }</Td>
      <Td>{extraDiscount.service_route ?? '-'}</Td>
      <Td>{extraDiscount.services ? extraDiscount?.services?.map((service) => service?.service?.protocol).join(', ') : '-'}</Td>
      <Td>{extraDiscount?.createdBy ? `${extraDiscount?.createdBy.firstname} ${extraDiscount?.createdBy.lastname}` : '-'}</Td>

      <Td>
        <AggregateExtraDiscountStatus
          status={status}
        />
      </Td>
      <Td>
        {status === 'requested' && userCanApproveAggregateExtraDiscount && (
          <Button
            size="xs"
            leftIcon={<Icon as={FaArrowRight} />}
            variant="ghost"
            lineHeight="1"
            onClick={handleApproveAggregateExtraDiscount}
          >
            Aprovar
          </Button>
        )}
        {status === 'requested' && userCanRejectAggregateExtraDiscount && (
          <Button
            size="xs"
            leftIcon={<Icon as={FaArrowRight} />}
            variant="ghost"
            lineHeight="1"
            onClick={handleRejectAggregateExtraDiscount}
          >
            Recusar
          </Button>
        )}
        {status === 'approved' && userCanEffectiveAggregateExtraDiscount && (
          <Button
            size="xs"
            leftIcon={<Icon as={FaArrowRight} />}
            variant="ghost"
            lineHeight="1"
            onClick={handleEffectiveAggregateExtraDiscount}
          >
            Efetivar
          </Button>
        )}
        {userCanDeleteAggregateExtraDiscount && (
          <Button
            size="xs"
            leftIcon={<Icon as={FaTimes} />}
            variant="ghost"
            lineHeight="1"
            onClick={handleDeleteAggregateExtraDiscount}
          >
            Excluir
          </Button>
        )}

      </Td>

    </Tr>
  )
}
