import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Button,
  Stack,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useEffect, useContext, useState } from 'react'

import { Input } from '../../../../components/Inputs/Input'
import { ServiceProps } from '../../../../contexts/ServiceContext'
import { useHistory, useParams } from 'react-router'
import { CollectedServiceProps } from '../../../../contexts/CollectedServiceContext'
import { AuthContext } from '../../../../contexts/AuthContext'
import { useService } from '../../../../hooks/services/service'
import { GeneralContentLoading } from '../../../../components/Loading/GeneralContentLoading'
import { useAddresses } from '../../../../hooks/address/useAddresses'
import { Address } from 'hooks/address/dtos/Address'
import { useProviders } from 'hooks/provider/useProviders'

interface QueryParams {
  service_id: string
  address_id: string
}

interface DriverFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
}

interface ProviderProps {
  id: string
  company_name: string
  trading_name: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
}

export function CollectedAddresses({
  slug,
  id,
  isDisabled = false,
  href,
  title,
  action,
}: DriverFormProps) {
  const [isLoading, setIsLoading] = useState(true)

  const [sourceAddresses, setSourceAddresses] = useState<Address[]>([])
  const [stepsOfCollectedService, setStepsOfCollectedService] = useState<
    string[]
  >([])
  const [providerSourceAddress, setProviderSourceAddress] = useState<
    ProviderProps[]
  >([])

  const [serviceFilteredById, setServiceFilteredById] = useState<
    ServiceProps[]
  >([])
  const [collectedServiceFilteredById, setCollectedServiceFilteredById] =
    useState<CollectedServiceProps[]>([])

  const { userLogged } = useContext(AuthContext)

  const { service_id }: QueryParams = useParams()
  const history = useHistory()

  const { data: providers, isLoading: isProviderLoading } = useProviders();
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()
  const {
    service: { data: serviceById, isLoading: isServiceLoading },
  } = useService(service_id, false, false)

  useEffect(() => {
    function run() {
      if (serviceById !== undefined) {
        const serviceFiltered = []
        serviceFiltered.push(serviceById)
        setServiceFilteredById(serviceFiltered)
      }
    }
    run()
  }, [serviceById])

  useEffect(() => {
    function run() {
      if (serviceById?.serviceIDCollect !== undefined) {
        setCollectedServiceFilteredById(serviceById?.serviceIDCollect)
      }
    }
    run()
  }, [serviceById])

  useEffect(() => {
    function run() {
      if (!isAddressesLoading && !isServiceLoading && !isProviderLoading) {
        setIsLoading(false)
      }
    }
    run()
  }, [isAddressesLoading, isServiceLoading, isProviderLoading])

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (!permissions?.includes('add-collect-service')) {
            history.push('/')
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [slug, history, userLogged])

  useEffect(() => {
    function run() {
      if (!collectedServiceFilteredById.length) {
        setStepsOfCollectedService([])
        return
      }
      const stepsOfCollectedService = collectedServiceFilteredById.map(
        (colService) => colService.step,
      )
      setStepsOfCollectedService(stepsOfCollectedService)
    }

    run()
  }, [collectedServiceFilteredById])

  useEffect(() => {
    function run() {
      const hasProvider = serviceFilteredById.findIndex(
        (service) => service.serviceIDRequested.provider_id,
      )
      const providerAlreadyInUse = collectedServiceFilteredById.filter(
        (colService) => colService.provider_id,
      )
      const stepOfProviderSearch = providerAlreadyInUse.findIndex(
        (colService) =>
          colService.step === 'DONE' || colService.step === 'UNSUCCESS',
      )
      if (hasProvider >= 0 && stepOfProviderSearch > 0 && !!providers) {
        const providerIdOfService = serviceFilteredById.map(
          (service) => service.serviceIDRequested.provider_id,
        )
        const providerSourceAddressFiltered = providers.filter(
          (provider) => provider.id === providerIdOfService.toString(),
        )
        setProviderSourceAddress(providerSourceAddressFiltered)
      }

      if (addresses) {
        const sourceAddressOfService = serviceFilteredById.flatMap(
          (service) => service.serviceIDRequested.source_address_id,
        )
        const sourceAddress = addresses.filter((address) =>
          sourceAddressOfService.includes(address.id),
        )
        setSourceAddresses(sourceAddress)
      }
    }

    run()
  }, [
    service_id,
    serviceFilteredById,
    addresses,
    providers,
    collectedServiceFilteredById,
    stepsOfCollectedService,
  ])

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex>
        <Box flex="1" borderRadius="8px" bg="white" p={['6', '8']}>
          <Heading size="lg" fontFamily="poppins">
            {title}
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          {sourceAddresses.map((address) => (
            <Stack
              key={address.id}
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'column']}
            >
              <Input
                defaultValue={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep} | ${address.complement}`.toUpperCase()}
                isDisabled={isDisabled}
                name="firstname"
                label="Endereço Remetente"
              />

              <Link
                to={`/servico/${service_id}/editar/coleta/endereco/${address.id}`}
              >
                <Button type="button" colorScheme="blue" w="100%">
                  Editar Coleta
                </Button>
              </Link>
              <Divider my="6" borderColor="gray.700" />
            </Stack>
          ))}

          {providerSourceAddress.map((provider) => (
            <Stack
              key={provider.id}
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'column']}
            >
              <Input
                defaultValue={`| ${provider.trading_name} | ${provider.street} | ${provider.number} | ${provider.neighborhood} | ${provider.city} | ${provider.cep} | ${provider.complement}`.toUpperCase()}
                isDisabled={isDisabled}
                name="firstname"
                label="Endereço do Fornecedor de Gelo Seco"
              />

              <Link
                to={`/servico/${service_id}/editar/coleta/fornecedor/${provider.id}`}
              >
                <Button type="button" colorScheme="blue" w="100%">
                  Editar Coleta
                </Button>
              </Link>
              <Divider my="6" borderColor="gray.700" />
            </Stack>
          ))}

          <Flex mt="8" justify="flex-end">
            <HStack>
              <Link to="/servicos/coletas">
                <Button type="button" colorScheme="gray">
                  Lista de Serviços à Coletar
                </Button>
              </Link>
            </HStack>
          </Flex>
        </Box>
      </Flex>

  )
}
