import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { getRefunds } from "api/refunds/getRefunds";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useInfiniteQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreateRefund } from "./components/CreateRefund";
import { KanbanRefundColumn } from "./components/KanbanRefundColumn";

export function Refunds() {

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanViewRefunds = userLogged?.permissions?.includes(
    'view-refunds'
  )

  const userCanCreateRefund = userLogged?.permissions?.includes(
    'create-refund'
  )

  useEffect(() => {
    if (!userCanViewRefunds) history.push('/')
  }, [history, userCanViewRefunds])

  const {
    data: pendingRefundsResult,
    isLoading: isPendingRefundsResultLoading,
    hasNextPage: hasNextPagePendingRefunds,
    fetchNextPage: fetchNextPagePendingRefunds,
    isFetchingNextPage: isFetchingNextPagePedingRefunds,
  } = useInfiniteQuery({
    queryKey: ['pending-refunds'],
    queryFn: ({ pageParam = 1 }) => getRefunds({
      currentPage: pageParam,
      pageSize: 10,
      status: 'refunding'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: approvedRefundsResult,
    isLoading: isApprovedRefundsResultLoading,
    hasNextPage: hasNextPageApprovedRefunds,
    fetchNextPage: fetchNextPageApprovedRefunds,
    isFetchingNextPage: isFetchingNextPageApprovedRefunds,
  } = useInfiniteQuery({
    queryKey: ['approved-refunds'],
    queryFn: ({ pageParam = 1 }) => getRefunds({
      currentPage: pageParam,
      pageSize: 10,
      status: 'approved'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: reprovedRefundsResult,
    isLoading: isReprovedRefundsResultLoading,
    hasNextPage: hasNextPageReprovedRefunds,
    fetchNextPage: fetchNextPageReprovedRefunds,
    isFetchingNextPage: isFetchingNextPageReprovedRefunds,
  } = useInfiniteQuery({
    queryKey: ['reproved-refunds'],
    queryFn: ({ pageParam = 1 }) => getRefunds({
      currentPage: pageParam,
      pageSize: 10,
      status: 'reproved'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })


  const {
    isOpen: isCreateRefundOpen,
    onOpen: onOpenCreateRefund,
    onClose: onCloseCreateRefund
  } = useDisclosure()

  const columnsRefunds = [
    {
      title: 'Aguardando Reembolso',
      refunds: pendingRefundsResult?.pages.flatMap((page) => page.refunds) || [],
      refundsCount: pendingRefundsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPagePendingRefunds,
      fetchNextPage: fetchNextPagePendingRefunds,
      isFetchingNextPage: isFetchingNextPagePedingRefunds,
      isLoading: isPendingRefundsResultLoading
    },
    {
      title: 'Aprovados',
      refunds: approvedRefundsResult?.pages.flatMap((page) => page.refunds) || [],
      refundsCount: approvedRefundsResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageApprovedRefunds,
      fetchNextPage: fetchNextPageApprovedRefunds,
      isFetchingNextPage: isFetchingNextPageApprovedRefunds,
      isLoading: isApprovedRefundsResultLoading
    },
    {
      title: 'Reprovados',
      refunds: reprovedRefundsResult?.pages.flatMap((page) => page.refunds) || [],
      refundsCount: reprovedRefundsResult?.pages[0]?.meta?.count,
      color: 'red.300',
      hasNextPage: hasNextPageReprovedRefunds,
      fetchNextPage: fetchNextPageReprovedRefunds,
      isFetchingNextPage: isFetchingNextPageReprovedRefunds,
      isLoading: isReprovedRefundsResultLoading
    },
  ];


  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Reembolsos</Heading>
        {userCanCreateRefund && (
          <IconButton
            aria-label="Criar reembolso"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onOpenCreateRefund}
          />
        )}

        <Modal
          isOpen={isCreateRefundOpen}
          onClose={onCloseCreateRefund}
          isCentered
          size="2xl"
        >
          <ModalOverlay />
          <CreateRefund onClose={onCloseCreateRefund} />
        </Modal>

      </Flex>
      <Flex gap={4} overflowX="auto" mt={4}>
        {columnsRefunds.map((refund, index) => (
          <KanbanRefundColumn
            key={index}
            refund={refund}
          />
        ))}
      </Flex>
    </Box>
  )
}
