import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { SupportSubject } from './_types/SupportSubject';
import { User } from 'hooks/user/dtos/User';

export interface GetSupportSubjectResponse {
  supportSubject: SupportSubject & {
    responsibles: {
      id: string
      userId: string
      user: User
    }[]
  }
}

interface GetSupportSubjectParams {
  supportSubjectId: string
}

export async function getSupportSubject({
  supportSubjectId
}: GetSupportSubjectParams) {
  try {
    const response = await api.get<GetSupportSubjectResponse>(`/supports/subjects/${supportSubjectId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
