import { useReducer } from "react"
import {
  Button,
  Grid,
  GridItem,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure
} from "@chakra-ui/react"
import { FaClock, FaHistory, FaTags, FaUserAlt, FaUserTag } from "react-icons/fa"
import { formatDate } from "utils/DateFunctions/formatDate"
import { useInternComunications } from "hooks/internComunication/useInternComunications"
import { ComunicationAnswer, ComunicationQuestion } from "hooks/internComunication/dtos/InternComunication"
import { InternComunicationCardItem } from "pages/InternComunications/components/InternComunicationCardItem"
import { InternComunicationHistoryModal } from "pages/InternComunications/components/InternComunicationHistoryModal"

interface ServiceOpenedInternComunicationsModalProps {
  serviceId: string
  isOpen: boolean
  onClose: () => void
}

interface InternComnicationReducerState {
  internComunicationAnswers: ComunicationAnswer[]
  internComunicationQuestions: ComunicationQuestion[]
}

interface Action {
  payload: InternComnicationReducerState
}

function reducer(state: InternComnicationReducerState, action: Action) {
  return {
    ...action.payload
  }
}

export function ServiceOpenedInternComunicationsModal({
  isOpen,
  onClose,
  serviceId
}: ServiceOpenedInternComunicationsModalProps) {
  const [internComunicationState, dispatch] = useReducer(reducer, {
    internComunicationAnswers: [],
    internComunicationQuestions: []
  })

  const {
    isOpen: isInternComunicationHistoryModal,
    onClose: onCloseInternComunicationHistoryModal,
    onOpen: onOpenInternComunicationHistoryModal
  } = useDisclosure()

  const {
    data: internComunications,
    isFetching: isFetchingInternComunications
  } = useInternComunications({
    queryParams: {
      comunication_service: serviceId,
    }
  })

  function handleOpenInternComunicationHistoryModal(
    internComunicationAnswers: ComunicationAnswer[],
    internComunicationQuestions: ComunicationQuestion[],
  ) {
    dispatch({
      payload: {
        internComunicationAnswers,
        internComunicationQuestions
      }
    })

    onOpenInternComunicationHistoryModal()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <InternComunicationHistoryModal
        isInternComunicationHistoryModalOpen={isInternComunicationHistoryModal}
        onCloseInternComunicationHistoryModal={onCloseInternComunicationHistoryModal}
        comunicationAnswers={internComunicationState.internComunicationAnswers}
        comunicationQuestions={internComunicationState.internComunicationQuestions}
      />
      <ModalOverlay />

      <ModalContent maxH='500px' overflow='auto'>
        <ModalHeader>Comunicações internas do serviço</ModalHeader>
        <ModalCloseButton></ModalCloseButton>

        <ModalBody as={Grid} gridTemplateColumns='repeat(1, 1fr)' gap={4}>
          {isFetchingInternComunications ? (
            <Spinner />
          ) : (
            internComunications?.map(internComunication => {
              return (
                <GridItem boxShadow='md' borderRadius='8px' p={4}>
                  {internComunication.comunication_owner && (
                    <InternComunicationCardItem
                      icon={FaUserAlt}
                      itemName={internComunication.ownerIDComunication.firstname}
                    />
                  )}
                  {internComunication.comunication_responsible && (
                    <InternComunicationCardItem
                      icon={FaUserTag}
                      itemName={internComunication.responsibleIDComunication.firstname}
                    />
                  )}
                  <InternComunicationCardItem
                    icon={FaTags}
                    itemName={internComunication.comunication_type}
                  />
                  <InternComunicationCardItem
                    icon={FaClock}
                    itemName={formatDate.handle(internComunication.comunication_deadline, "DateWithDateAndHourMinute")}
                  />
                  <Button
                    w='full'
                    size='sm'
                    leftIcon={<Icon as={FaHistory} />}
                    onClick={() => {
                      handleOpenInternComunicationHistoryModal(
                        internComunication.comunication_answers as ComunicationAnswer[],
                        internComunication.comunication_questions as ComunicationQuestion[],
                      )
                    }}
                  >
                    Histórico
                  </Button>
                </GridItem>
              )
            })
          )}

        </ModalBody>
      </ModalContent>


    </Modal>
  )
}
