import { usePagination } from "@ajna/pagination";
import { Box, Button, Divider, Flex, Heading, Icon, Spinner, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { TableFilterButton } from "components/Filters/TableFilterButton";
import { Pagination } from "components/Pagination/Pagination";
import { SearchBox } from "components/SearchBox/SearchBox";

import { useAuth } from "hooks/auth/useAuth";
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions";
import { useNotificationTypeFunctions } from "hooks/notification/useNotificationTypeFunctions";
import { useNotificationTypes } from "hooks/notification/useNotificationTypes";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { FaPen, FaPlus, FaTrash } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { searchBoxFilter } from "utils/searchBoxFilter";

interface NotificationTypeFilterOptionsInputs {
  name: string
  profiles: string
}

const notificationTypeFilterOptions = [
  { key: 'name', value: 'Nome', checked: false },
  { key: 'profiles', value: 'Perfis', checked: false }
]

export function NotificationTypesList() {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()
  const { promiseMessage } = useToastify()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-notification-type')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  const { control, register } = useForm()

  const {
    data: notificationTypes,
    isFetching: isNotificationTypesFetching
  } = useNotificationTypes()

  const { filterOptions, onLoadSetFilterOptions } = useFilterOptions()

  useEffect(() => {
    onLoadSetFilterOptions(notificationTypeFilterOptions)
  }, [onLoadSetFilterOptions])

  const {
    deleteNotificationType: { mutateAsync: deleteNotificationType }
  } = useNotificationTypeFunctions()

  async function handleDeleteNotificationType(id: string) {
    await promiseMessage(
      deleteNotificationType(id),
      'Tipo de notificação excluída com sucesso!'
    )
  }

  const [nameFiltered, profilesFiltered] = useWatch({
    control,
    name: ['name', 'profiles']
  })

  const notificationTypesFiltered = notificationTypes?.filter(notificationType => {
    const nameFilter = nameFiltered
      ? searchBoxFilter(notificationType.name, nameFiltered)
      : notificationType.name

    const profilesFilter = profilesFiltered
      ? searchBoxFilter(notificationType.profiles.join(','), profilesFiltered)
      : notificationType.profiles

    return nameFilter && profilesFilter
  })

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: notificationTypesFiltered?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setCurrentPage(1)
    }, 500)

    return () => clearTimeout(debounceTimeout)
  }, [nameFiltered, profilesFiltered, setCurrentPage])

  const handlePageChange = (page: number) => setCurrentPage(page)

  return (

      <Box
        borderRadius='8px'
        p={['2', '4']}
        bg='white'
      >
        <Flex
          gap={4}
          direction='column'
        >
          <Flex justify="space-between">
            <Heading size='lg'>Tipos de notificação</Heading>

            {userLogged?.permissions.includes('create-notification-type') && (
              <Button
                size='sm'
                leftIcon={<Icon as={FaPlus} />}
                colorScheme='gray'
              >
                Novo tipo
              </Button>
            )}
          </Flex>

          <Divider />

          <Flex
            w='full'
            direction={['column', 'column', 'row']}
            gap={2}
            justify='space-between'
          >
            <Flex>
              <TableFilterButton />
            </Flex>
            <Stack direction='column'>
              {
                filterOptions
                  .filter(option => option.checked)
                  .map(option => {
                    return (
                      <SearchBox
                        {...register(option.key as keyof NotificationTypeFilterOptionsInputs)}
                        key={option.key}
                        size="sm"
                        placeholder={'Buscar ' + option.value}
                        handleSearch={() => { }}
                      />
                    )
                  })
              }
            </Stack>
          </Flex>

          {isNotificationTypesFetching ? (
            <Spinner />
          ) : (
            <TableContainer>
              <Table size='sm' variant='striped'>
                <Thead>
                  <Tr>
                    {notificationTypeFilterOptions.map(option => {
                      return (
                        <Th key={option.key}>{option.value.toLocaleUpperCase()}</Th>
                      )
                    })}
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {notificationTypesFiltered.slice(offset, offset + Number(itemLimit)).map(notificationType => {
                    return (
                      <Tr>
                        <Td>{notificationType.name}</Td>
                        <Td>{notificationType.profiles.join(', \n')}</Td>
                        <Td isNumeric>
                          {userLogged?.permissions.includes('edit-notification-type') && (
                            <Button
                              as={Link}
                              to={`/notificacao/tipo/${notificationType.id}/editar`}
                              variant='ghost'

                            >
                              <Icon fontSize='xl' as={FaPen} />
                            </Button>
                          )}
                          {userLogged?.permissions.includes('delete-notification-type') && (
                            <Button
                              onClick={() => handleDeleteNotificationType(notificationType.id)}
                              variant='ghost'
                            >
                              <Icon fontSize='xl' as={FaTrash} />
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          )}

          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </Flex>
      </Box>

  )
}
