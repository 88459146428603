import { Box, Card, CardBody, CardHeader, Flex, Grid, GridItem, Heading, IconButton, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import { getStocksUnderMinimum } from "api/stocks/getStocksUnderMinumum"
import { Empty } from "components/Empty"

import { useAuth } from "hooks/auth/useAuth"
import { FaArrowLeft } from "react-icons/fa"
import { useQuery } from "react-query"
import { Link, useHistory } from "react-router-dom"
import { captalize } from "utils/captalize"
import { InfoCardsLoading } from "../components/InfoCardsLoading"

export function StocksUnderMinimum() {
  const { userLogged } = useAuth()

  const {
    data: result,
    isError,
    isLoading: isLoadingStocksUnderMinimum
  } = useQuery({
    queryKey: ['stocks-under-minimum'],
    queryFn: () => getStocksUnderMinimum({ queryParams: { collectorId: userLogged?.collector_id }})
  })

  const handleReloadPage = () => window.location.reload()

  const history = useHistory()

  const handleReturnToDashboard = () => history.goBack()

  if (isError) {
    return (
      <Empty.Root>
        <Empty.ActionButton onClick={handleReloadPage}>Recarregar a página</Empty.ActionButton>
      </Empty.Root>
    )
  }

  return (

      <Box
        p="6"
        rounded="md"
        bg="white"
        display="flex"
        flexDirection="column"
        gap="6"
      >
        <Flex
          align="center"
          gap="2"
        >
          <IconButton
            aria-label="Voltar ao dashboard"
            icon={<FaArrowLeft />}
            variant="ghost"
            size="sm"
            onClick={handleReturnToDashboard}
          />
          <Heading letterSpacing="tight">Estoques Mínimos ou Abaixo</Heading>
        </Flex>

        <Grid
          gap="6"
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }}
        >
          {isLoadingStocksUnderMinimum && (
            <InfoCardsLoading />
          )}
          {result?.stocks?.map((stock) => {
            return (
              <GridItem
                key={stock.id}
              >
                <Card
                  rounded="xl"
                  transition="all 0.2s"
                  _hover={{
                    transform: 'translateY(-0.5rem)'
                  }}
                  h="full"
                >
                  <CardHeader
                    as={Link}
                    display="flex"
                    alignItems="center"
                    to={`/estoque/visualizar/${stock.id}`}
                    h="40px"
                    bg="#38c3fa"
                    borderTopRadius="xl"
                  >
                    <Text fontWeight="bold" color="white">{stock.inputIDStock.name}</Text>
                  </CardHeader>

                  <CardBody>
                    <Table size="sm">
                      <Tbody>
                        <Tr>
                          <Td fontSize='md'>Coletador</Td>
                          <Td isNumeric fontSize='md'>{captalize(stock.collectorIDStock.trading_name)}</Td>
                        </Tr>
                        <Tr>
                          <Td fontSize='md'>Estoque padrão</Td>
                          <Td isNumeric fontSize='md'>{stock.standard_stock}</Td>
                        </Tr>
                        <Tr>
                          <Td fontSize='md'>Estoque Mínimo</Td>
                          <Td isNumeric fontSize='md'>{stock.minimum_stock}</Td>
                        </Tr>
                        <Tr>
                          <Td fontSize='md'>Estoque Atual</Td>
                          <Td isNumeric fontSize='md'>{stock.current_stock}</Td>
                        </Tr>
                        <Tr>
                          <Td fontSize='md'>Cidade</Td>
                          <Td isNumeric fontSize='md'>{captalize(stock.collectorIDStock.city)}</Td>
                        </Tr>
                        <Tr>
                          <Td fontSize='md'>Consumo no Último Período (30 dias)</Td>
                          <Td isNumeric fontSize='md'>{stock.inputIDStock.last_period_consume}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </CardBody>
                </Card>
              </GridItem>
            )
          })}
        </Grid>
      </Box>

  )
}
