import { useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";


interface GenerateBoardServiceAuthorizationQueryParams {
  driver_id: string
  shipping_id: string
  current_date: Date
}

type QueryOptions = UseQueryOptions<ArrayBuffer, unknown, ArrayBuffer, "boardServiceAuthorization">

interface UseBoardServiceAuthorizationOptions {
  service_id: string
  queryOptions?: QueryOptions
  queryParams?: GenerateBoardServiceAuthorizationQueryParams
}

interface GenerateBoardServiceAuthorizationReqProps {
  service_id: string
  queryParams?: GenerateBoardServiceAuthorizationQueryParams
}

export function useBoardServiceAuthorization(options?: UseBoardServiceAuthorizationOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function generateBoardServiceAuthorization({
    service_id,
    queryParams
  }: GenerateBoardServiceAuthorizationReqProps) {
    try {
      const { data } = await api.get(`/board-service/authorization/${service_id}`, {
        params: {
          ...queryParams,
        },
        responseType: 'arraybuffer',
      })

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new RequestError("Erro inesperado!")
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery("boardServiceAuthorization", () => generateBoardServiceAuthorization({
    service_id: options?.service_id,
    queryParams: options?.queryParams
  }), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
