import { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { cityFormatDataToBack } from '../../utils/CityFunctions/cityFormatDataToBack'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { CityForm } from '../../components/Form/City/CityForm'
import { useAuth } from '../../hooks/auth/useAuth'
import { useCityFunctions } from '../../hooks/city/useCityFunctions'
import { useSwal } from '../../hooks/swal/useSwal'
import { useQueryClient } from 'react-query'

export interface IFormInputs {
  name: string
  state: string
  schedule_deadline: string
  opening_deadline: number
  start_attendance_block: string
  final_attendance_block: string
  has_saturday_attendance: string
  saturday_start_attendance_block: string
  saturday_final_attendance_block: string
  has_sunday_attendance: string
  sunday_start_attendance_block: string
  sunday_final_attendance_block: string
  timezone: string
  days_service: number[]
  situation: string
  observation: string
  hub_id: string
}

interface QueryParams {
  slug: string
  id: string
}

export function CityAddDetailEdit() {
  const { slug, id }: QueryParams = useParams()
  const { confirmMessage, standardMessage } = useSwal()
  const {
    createCity: { mutateAsync: createCity },
    editCity: { mutateAsync: editCity, isLoading: isEditCityLoading },
  } = useCityFunctions()

  const [isLoading, setIsLoading] = useState(false)
  const { userLogged } = useAuth()

  const history = useHistory()


  const queryClient = useQueryClient()

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (slug === 'adicionar') {
            if (!permissions?.includes('add-city')) {
              history.push('/')
            }
          }
          if (slug === 'editar') {
            if (!permissions?.includes('edit-city')) {
              history.push('/')
            }
          }
          if (slug === 'visualizar') {
            if (
              !permissions?.includes('view-city') &&
              userLogged?.user_type !== 'CLIENTE'
            ) {
              history.push('/')
            }
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [slug, history, userLogged])

  async function handleCreateCity(values: IFormInputs) {
    const hasCreateCity = await confirmMessage({
      title: 'Deseja adicionar uma cidade?',
    })

    if (hasCreateCity) {
      setIsLoading(true)
      try {
        const formatedCityFormValues = cityFormatDataToBack(values)
        const createCityResponse = await createCity(formatedCityFormValues)

        if (createCityResponse) {
          history.push('/cidades')
        }
      } catch {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  async function handleEditCity(values: IFormInputs) {
    const hasEditCity = await confirmMessage({
      title: 'Deseja editar uma cidade?',
    })

    if (hasEditCity) {
      const formatedCityFormValues = cityFormatDataToBack(values)
      const editCityReqObj = {
        cityId: id,
        editCityInput: formatedCityFormValues,
      }

      await editCity({ ...editCityReqObj }, {
        onSuccess: async () => {
          await queryClient.invalidateQueries('city')
          history.push('/cidades')
        }
      })
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (isLoading || isEditCityLoading) {
    return <GeneralContentLoading />
  }

  if (slug === 'adicionar') {
    return (
      <CityForm
        slug={slug}
        href=""
        submit={handleCreateCity}
        title="Adicionar cidade"
        action="Salvar"
      />
    )
  } else if (slug === 'editar') {
    return (
      <CityForm
        slug={slug}
        href=""
        submit={handleEditCity}
        title="Editar cidade"
        action="Salvar"
        id={id}
      />
    )
  } else {
    return (
      <CityForm
        slug={slug}
        href={`/cidade/editar/${id}`}
        submit={async () => { }}
        title="Visualizar cidade"
        isDisabled={true}
        action="Editar"
        id={id}
      />
    )
  }
}
