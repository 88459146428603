import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { getSupports } from "api/supports/getSupports";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useInfiniteQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreateSupport } from "./components/CreateSupport";
import { KanbanSupportColumn } from "./components/KanbanSupportColumn";


export function Supports() {

  const { userLogged } = useAuth()
  const history = useHistory()

  const userCanViewSupports = userLogged?.permissions?.includes(
    'view-supports'
  )

  const userCanCreateSupport = userLogged?.permissions?.includes(
    'create-support'
  )

  useEffect(() => {
    if (!userCanViewSupports) history.push('/')
  }, [history, userCanViewSupports])

  const {
    isOpen: isCreateSupportOpen,
    onOpen: onOpenCreateSupport,
    onClose: onCloseCreateSupport
  } = useDisclosure()

  const {
    data: analyzingSupportsResult,
    isLoading: isAnalyzingSupportsResultLoading,
    hasNextPage: hasNextPageAnalyzingSupports,
    fetchNextPage: fetchNextPageAnalyzingSupports,
    isFetchingNextPage: isFetchingNextPageAnalyzingSupports,
  } = useInfiniteQuery({
    queryKey: ['analyzing-supports'],
    queryFn: ({ pageParam = 1 }) => getSupports({
      currentPage: pageParam,
      pageSize: 10,
      status: 'analyzing'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })
  const {
    data: supportingSupportsResult,
    isLoading: isSupportingSupportsResultLoading,
    hasNextPage: hasNextPageSupportingSupports,
    fetchNextPage: fetchNextPageSupportingSupports,
    isFetchingNextPage: isFetchingNextPageSupportingSupports,
  } = useInfiniteQuery({
    queryKey: ['supporting-supports'],
    queryFn: ({ pageParam = 1 }) => getSupports({
      currentPage: pageParam,
      pageSize: 10,
      status: 'supporting'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: waitingReturnSupportsResult,
    isLoading: isWaitingReturnSupportsResultLoading,
    hasNextPage: hasNextPageWaitingReturnSupports,
    fetchNextPage: fetchNextPageWaitingReturnSupports,
    isFetchingNextPage: isFetchingNextPageWaitingReturnSupports,
  } = useInfiniteQuery({
    queryKey: ['waiting-return-supports'],
    queryFn: ({ pageParam = 1 }) => getSupports({
      currentPage: pageParam,
      pageSize: 10,
      status: 'waiting-return'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage;
    },
    keepPreviousData: true,
  });

  const {
    data: verifyingSupportsResult,
    isLoading: isVerifyingSupportsResultLoading,
    hasNextPage: hasNextPageVerifyingSupports,
    fetchNextPage: fetchNextPageVerifyingSupports,
    isFetchingNextPage: isFetchingNextPageVerifyingSupports,
  } = useInfiniteQuery({
    queryKey: ['verifying-supports'],
    queryFn: ({ pageParam = 1 }) => getSupports({
      currentPage: pageParam,
      pageSize: 10,
      status: 'verifying'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage;
    },
    keepPreviousData: true,
  });

  const {
    data: doneSupportsResult,
    isLoading: isDoneSupportsResultLoading,
    hasNextPage: hasNextPageDoneSupports,
    fetchNextPage: fetchNextPageDoneSupports,
    isFetchingNextPage: isFetchingNextPageDoneSupports,
  } = useInfiniteQuery({
    queryKey: ['done-supports'],
    queryFn: ({ pageParam = 1 }) => getSupports({
      currentPage: pageParam,
      pageSize: 10,
      status: 'done'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage;
    },
    keepPreviousData: true,
  });

  const {
    data: cancelledSupportsResult,
    isLoading: isCancelledSupportsResultLoading,
    hasNextPage: hasNextPageCancelledSupports,
    fetchNextPage: fetchNextPageCancelledSupports,
    isFetchingNextPage: isFetchingNextPageCancelledSupports,
  } = useInfiniteQuery({
    queryKey: ['cancelled-supports'],
    queryFn: ({ pageParam = 1 }) => getSupports({
      currentPage: pageParam,
      pageSize: 10,
      status: 'canceled'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage;
    },
    keepPreviousData: true,
  });

  const columnsSupports = [
    {
      title: 'Aguardando Análise',
      supports: analyzingSupportsResult?.pages.flatMap((page) => page.supports) || [],
      supportsCount: analyzingSupportsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageAnalyzingSupports,
      fetchNextPage: fetchNextPageAnalyzingSupports,
      isFetchingNextPage: isFetchingNextPageAnalyzingSupports,
      isLoading: isAnalyzingSupportsResultLoading,
    },
    {
      title: 'Em Atendimento',
      supports: supportingSupportsResult?.pages.flatMap((page) => page.supports) || [],
      supportsCount: supportingSupportsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageSupportingSupports,
      fetchNextPage: fetchNextPageSupportingSupports,
      isFetchingNextPage: isFetchingNextPageSupportingSupports,
      isLoading: isSupportingSupportsResultLoading,
    },
    {
      title: 'Aguardando Retorno',
      supports: waitingReturnSupportsResult?.pages.flatMap((page) => page.supports) || [],
      supportsCount: waitingReturnSupportsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageWaitingReturnSupports,
      fetchNextPage: fetchNextPageWaitingReturnSupports,
      isFetchingNextPage: isFetchingNextPageWaitingReturnSupports,
      isLoading: isWaitingReturnSupportsResultLoading,
    },
    {
      title: 'Verificação',
      supports: verifyingSupportsResult?.pages.flatMap((page) => page.supports) || [],
      supportsCount: verifyingSupportsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageVerifyingSupports,
      fetchNextPage: fetchNextPageVerifyingSupports,
      isFetchingNextPage: isFetchingNextPageVerifyingSupports,
      isLoading: isVerifyingSupportsResultLoading,
    },
    {
      title: 'Concluído',
      supports: doneSupportsResult?.pages.flatMap((page) => page.supports) || [],
      supportsCount: doneSupportsResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageDoneSupports,
      fetchNextPage: fetchNextPageDoneSupports,
      isFetchingNextPage: isFetchingNextPageDoneSupports,
      isLoading: isDoneSupportsResultLoading,
    },
    {
      title: 'Cancelado',
      supports: cancelledSupportsResult?.pages.flatMap((page) => page.supports) || [],
      supportsCount: cancelledSupportsResult?.pages[0]?.meta?.count,
      color: 'red.300',
      hasNextPage: hasNextPageCancelledSupports,
      fetchNextPage: fetchNextPageCancelledSupports,
      isFetchingNextPage: isFetchingNextPageCancelledSupports,
      isLoading: isCancelledSupportsResultLoading,
    }
  ];


  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >

      <Flex justify="space-between">
        <Heading letterSpacing="tight">Atendimentos</Heading>
        {userCanCreateSupport && (
          <IconButton
            aria-label="Criar atendimento"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onOpenCreateSupport}
          />
        )}

        <Modal
          isOpen={isCreateSupportOpen}
          onClose={onCloseCreateSupport}
          isCentered
          size="2xl"
        >
          <ModalOverlay />
          <CreateSupport
            onClose={onCloseCreateSupport}
          />
        </Modal>
      </Flex>
      <Flex
        w="full"
        gap={4}
        overflowX="auto"
        mt={4}
        pb={4}
        whiteSpace="nowrap"
      >
        {columnsSupports.map((support) => {
          return (
            <KanbanSupportColumn
              key={support.title}
              support={support}
            />
          )
        })}
      </Flex>

    </Box>
  )
}
