import * as yup from "yup"
import { Button, Divider, Flex, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { useAddresses } from "hooks/address/useAddresses";
import { useServices } from "hooks/services/useServices";
import { Fragment, useEffect, useState } from "react";
import { NestedValue, useForm, useWatch } from "react-hook-form";
import { formatDate } from "utils/DateFunctions/formatDate";
import { yupResolver } from "@hookform/resolvers/yup";
import { LabelInfo, useGenerateLabelFunctions } from "hooks/services/label/useGenerateLabelFunctions";
import { usePagination } from "@ajna/pagination";
import { Pagination } from "components/Pagination/Pagination";
import { useToastify } from "hooks/toastify/useToastify";
import { useAuth } from "hooks/auth/useAuth";
import { useCollectors } from "hooks/collector/useCollectors";


interface GenerateServicesLabelsModalInputs {
  date: string;
  sourceCollectorId: string
  labelsInfo: NestedValue<LabelInfo[]>
}

interface GenerateServicesLabelsModalProps {
  isOpen: boolean
  onClose: () => void
}

const generateServicesLabelsSchema = yup.object().shape({
  date: yup.string().required('Campo obrigatório!'),
  sourceCollectorId: yup.string().required('Campo obrigatório!'),
  labelsInfo: yup.array().of(yup.object({
    source_address_id: yup.string().required('Campo obrigatório!'),
    destination_address_id: yup.string().required('Campo obrigatório!'),
    quantity: yup.number().typeError('Insira apenas números!').required('Campo obrigatório!'),
  }))
})

export function GenerateServicesLabelsModal({
  isOpen,
  onClose
}: GenerateServicesLabelsModalProps) {
  const [labelsInfo, setLabelsInfo] = useState<LabelInfo[]>([])
  const [selectedDateDebounced, setSelectedDateDebounced] = useState('')

  const { userLogged } = useAuth()

  const {
    control,
    register,
    handleSubmit,
  } = useForm<GenerateServicesLabelsModalInputs>({
    resolver: yupResolver(generateServicesLabelsSchema),
    defaultValues: {
      date: formatDate.handle(new Date(), 'DateWithoutHourToInput')
    }
  })

  const [selectedDate, selectedCollector] = useWatch({
    control,
    name: ['date', 'sourceCollectorId']
  })

  const isValidSelectedDate = new Date(selectedDate) instanceof Date && !isNaN(new Date(selectedDate).getMilliseconds())

  const {
    generateServicesLabels: {
      data: servicesLabelsData,
      reset: resetServicesLabelsData,
      mutateAsync: generateServicesLabels,
      isLoading: isGenerateServicesLabelsLoading
    },
  } = useGenerateLabelFunctions()

  useEffect(() => {
    if (isValidSelectedDate) {
      resetServicesLabelsData()

      const debounceTimeout = setTimeout(async () => {
        setSelectedDateDebounced(selectedDate)
      }, 500)

      return () => clearTimeout(debounceTimeout)
    }
  }, [
    selectedDate,
    isValidSelectedDate,
    resetServicesLabelsData,
  ])

  const isValidSelectedDateDebouced = new Date(selectedDateDebounced) instanceof Date && !isNaN(new Date(selectedDateDebounced).getMilliseconds())

  const {
    data: services,
    isFetching: isFetchingServices,
  } = useServices({
    queryParams: {
      collect_range_date: selectedDateDebounced,
      source_collector_id: selectedCollector,
      steps: ['toCollectService', 'collectingService', 'toBoardService', 'boardingService']
    },
    queryOptions: {
      enabled: isValidSelectedDateDebouced && isOpen
    }
  })

  const { data: addresses, isFetching: isFetchingAddresses } = useAddresses()

  const addressesSelectOptions = addresses?.map(address => {
    return {
      key: address.id,
      value: address.id,
      showOption: `${address.trading_name ?? '-'} - ${address.branch} ${address.street}, ${address.number}, ${address.neighborhood
        }, ${address.cityIDAddress.name}`
    }
  })

  useEffect(() => {
    if (services) {
      const servicesAddressInfos = services.reduce((acc, service) => {
        service.serviceIDRequested.source_address_id.forEach((address, index) => {
          acc.push({
            id: `${service.id}-${index}`,
            service_id: service.id,
            source_address_id: address,
            destination_address_id: service.serviceIDRequested.destination_address_id[0],
            quantity: 1
          })
        })

        return acc
      }, [])

      setLabelsInfo(() => [...servicesAddressInfos])
    }
  }, [services])


  const bytes = servicesLabelsData
    ? new Uint8Array(servicesLabelsData)
    : null

  const url = bytes
    ? URL.createObjectURL(
      new Blob([bytes], { type: 'application/pdf' }),
    )
    : null

  const { promiseMessage } = useToastify()

  async function handleGenerateServicesLabels() {
    await promiseMessage(generateServicesLabels({
      labelsInfo: labelsInfo
    }), '🚀 Etiquetas geradas com sucesso!')
  }

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: labelsInfo?.length,
      initialState: {
        pageSize: Number(5),
        isDisabled: false,
        currentPage: 1,
      },
    })

  const handlePageChange = (page: number) => setCurrentPage(page)

  function handleChangeLabelQuantity(quantity: string, index: number) {
    const newLabelsInfo = [...labelsInfo]

    newLabelsInfo[index].quantity = Number(quantity)

    setLabelsInfo(() => [...newLabelsInfo])
  }

  const {
    data: collectors,
    isLoading: isCollectorsLoading
  } = useCollectors({
    queryParams: {
      situation: 'ATIVO'
    }
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />

      <ModalContent
        as='form'
        onSubmit={handleSubmit(handleGenerateServicesLabels)}
        noValidate
      >
        <ModalHeader as={Flex} gap={2} flexDirection='column'>
          Gerar etiquetas para serviços

          <Divider />
          <Input
            {...register('date')}
            name='date'
            type='date'
            label='Data'
            required
          />
          <Select
            {...register('sourceCollectorId')}
            name='sourceCollectorId'
            label='Coletador de origem'
            placeholder="Selecione uma opção..."
            isDisabled={isCollectorsLoading || ['COLETADOR', 'MOTORISTA'].includes(userLogged?.user_type)}
            required
            options={collectors?.map(collector => {
              return {
                key: collector.id,
                showOption: collector.trading_name,
                value: collector.id
              }
            })}
          />
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody
          as={Flex}
          flexDirection='column'
          gap={2}
          overflow='auto'
          maxH='400px'
        >

          {(isFetchingServices || isFetchingAddresses) ? (
            <Spinner />
          ) : (
            <>
              {labelsInfo?.slice(offset, offset + 5)?.map((labelField, index) => {

                const currentService = services?.find(service => {
                  return service.id === labelField.service_id
                })

                return (
                  <Fragment key={labelField.id}>
                    <Divider />

                    <Text as='b'>{currentService?.protocol} - {currentService?.customerIDService.trading_firstname}</Text>

                    <Select
                      name={`labelsInfo.${index}.source_address_id`}
                      label="Remetente"
                      value={labelField.source_address_id}
                      options={addressesSelectOptions}
                      placeholder="Selecione uma opção..."
                      isDisabled
                      required
                    />
                    <Select
                      name={`labelsInfo.${index}.destination_address_id`}
                      label="Destinatário"
                      value={labelField.destination_address_id}
                      options={addressesSelectOptions}
                      placeholder="Selecione uma opção..."
                      isDisabled
                      required
                    />

                    <Input
                      name={`labelsInfo.${index}.quantity`}
                      label='Quantidade (volume)'
                      onChange={(event) => {
                        handleChangeLabelQuantity(event.target.value, index)
                      }}
                      defaultValue={labelField.quantity}
                      required
                      type='number'
                    />
                  </Fragment>
                )
              })}
            </>
          )}
        </ModalBody>

        <ModalFooter as={Flex} flexDirection='column' gap={2}>

          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
          <Button
            w='full'
            type='submit'
            colorScheme='blue'
            isLoading={isFetchingServices || isGenerateServicesLabelsLoading}
          >
            Gerar Etiquetas
          </Button>
          {url && (
            <Button
              as={Link}
              w='full'
              href={url}
              download={`etiquetas-${selectedDate}`}
            >
              Download
            </Button>
          )}
        </ModalFooter>
      </ModalContent>

    </Modal>
  )
}
