import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";

interface ExternalComunicationsResponse {
  openExternalComunicationsCount: number
}

type QueryOptions = UseQueryOptions<ExternalComunicationsResponse, unknown, ExternalComunicationsResponse, QueryKey>

interface ExternalComunicationsQueryParams {
  status?: 'open' | 'closed'
  pageSize?: number
  currentPage?: number
}

interface UseExternalComunicationsMetricsOptions {
  queryKey?: string
  queryOptions?: QueryOptions
  queryParams?: ExternalComunicationsQueryParams
}

export function useExternalComunicationsMetrics(options?: UseExternalComunicationsMetricsOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  async function getExternalComunicationsMetrics() {
    try {
      const { data } = await api.get('/comunications/external/metrics')


      return data

    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new Error("Erro inesperado!")
      }
    }
  }

  return useQuery(
    [options?.queryKey || 'externalComunicationsMetrics'],
    getExternalComunicationsMetrics,
    {
      ...options?.queryOptions,
      refetchOnWindowFocus: false,
      onError: async (error) => onRequestError(error)
    })
}
