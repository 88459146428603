import { Button, Icon, Modal, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { ServicesValidationsRealTimes } from "api/service/validateRealTime/getServicesValidationsRealTimes";
import { FaCheckCircle } from "react-icons/fa";
import { ValidateRealTimeModal } from "./ValidateRealTimeModal";

interface ValidateRealTimeRowProps {
  service: ServicesValidationsRealTimes
}

export function ValidateRealTimeRow({
  service
}: ValidateRealTimeRowProps) {

  const {
    isOpen: isValidateRealTimeModalOpen,
    onOpen: onOpenValidateRealTimeModal,
    onClose: onCloseValidateRealTimeModal
  } = useDisclosure()

  return (
    <Tr>
      <Modal
        isOpen={isValidateRealTimeModalOpen}
        onClose={onCloseValidateRealTimeModal}
        isCentered
      >
        <ValidateRealTimeModal service={service}/>

      </Modal>
      <Td>{service.protocol}</Td>
      <Td>{service.type === 'collect' ? 'Coleta' : 'Entrega'}</Td>
      <Td>{service.responsible}</Td>
      <Td>{`${service.branch} | ${service.street} | ${service.number} | ${service.neighborhood} | ${service.city} | ${service.cep}`}</Td>

      <Td>
        <Button
          size="xs"
          leftIcon={<Icon h={2.5} w={2.5} as={FaCheckCircle} />}
          variant="outline"
          onClick={onOpenValidateRealTimeModal}
        >
          Validar
        </Button>

      </Td>
    </Tr>
  )
}
