import axios from "axios"
import { User } from "hooks/user/dtos/User"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

export interface RequestedService {
  sourceCollectorId: string
  destinationCollectorId: string
  sourceBranchId: string
  destinationBranchId: string
  providerId: string
  deadline: number
  serviceType: 'FRACIONADO' | 'DEDICADO'
  franchising: number
  modal: 'AÉREO' | 'RODOVIÁRIO'
  vehicle: 'CARRO' | 'MOTO' | 'CAMINHÃO'
  caixaTermica: number
  embalagemSecundaria: number
  geloSeco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  collectDate: string
  collectHourStart: string
  collectHourEnd: string
  deliveryDate: string
  deliveryHour: string
  plannedFlight: string
  boardHour: string
  availabilityForecastDay: string
  availabilityForecastTime: string
  crossdockingCollectorId: string
  sourceCrossdockingBranchId: string
  destinationCrossdockingBranchId: string
  crossdockingPlannedFlight: string
  crossdockingBoardHour: string
  crossdockingAvailabilityForecastDay: string
  crossdockingAvailabilityForecastTime: string
  crossdockingModal: string
  materialType: string
  boardDate: string
  crossdockingBoardDate: string
}
interface GetRequestedServiceLogsParams {
  requestedServiceId: string
  pageSize: number
  currentPage: number
}

type LogDetail = {
  [Property in keyof RequestedService]: {
    prev: RequestedService[Property]
    new: RequestedService[Property]
  }
}

export interface RequestedServiceLogs {
  id: string
  logDetail: LogDetail
  updatedBy: Pick<User, 'id' | 'firstname' | 'lastname'>
  createdAt: string
}

export interface GetRequestedServiceLogsResponse {
  logs: RequestedServiceLogs[]
  meta: {
    count: number
    totalPages: number
  }
}

export async function getRequestedServiceLogs({
  requestedServiceId,
  currentPage,
  pageSize
}: GetRequestedServiceLogsParams) {
  try {
    const { data } = await api.get<GetRequestedServiceLogsResponse>(
      `/services/requested/${requestedServiceId}/logs`, {
      params: {
        pageSize,
        currentPage
      }
    }
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
