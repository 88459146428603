import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Button, Flex, FormControl, FormLabel, Heading, Select, Text } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "react-query"
import { useToastify } from "hooks/toastify/useToastify"
import { checkInvoiceCPValue } from "api/invoices/checkInvoiceCPValue"
import { FormEvent } from "react"


interface CheckInvoiceCPValueProps {
  invoiceId: string
}

export interface CheckInvoiceCPValueSchema {
  isCorrectValue: string
}

const checkInvoiceSchema = yup.object({
  isCorrectValue: yup.string().required(),
})

export function CheckInvoiceCPValue({ invoiceId }: CheckInvoiceCPValueProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const formMethods = useForm<CheckInvoiceCPValueSchema>({
    resolver: yupResolver(checkInvoiceSchema),
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = formMethods



  const { mutateAsync: checkInvoiceCPValueFn } = useMutation({
    mutationFn: checkInvoiceCPValue,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'invoices' })
      queryClient.invalidateQueries({ queryKey: ['invoice', invoiceId] })
      reset()
    }
  })

  async function handlecheckInvoiceCPValue(values: CheckInvoiceCPValueSchema, event: FormEvent) {
    event.preventDefault()
    event.stopPropagation()
    await promiseMessage(checkInvoiceCPValueFn({
      body: {
        isCorrectValue: values.isCorrectValue === 'yes',

      },
      invoiceId
    }), 'Conferência de valores (CP) realizada!')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handlecheckInvoiceCPValue)}
    >
      <Heading letterSpacing="tight" size='sm'>Conferência de Valor (CP)</Heading>

      <FormControl isInvalid={!!errors.isCorrectValue} mt={3}>
        <FormLabel fontSize="sm">
          O valor está correto?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register('isCorrectValue')}
          name="isCorrectValue"
          placeholder="Selecione..."
          size="sm"
          rounded="md"
        >
          <option value='yes'>Sim</option>
          <option value='no'>Não</option>
        </Select>
      </FormControl>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
