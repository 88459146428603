import { Flex, Box, Heading, Divider, Stack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCollectors } from 'hooks/collector/useCollectors'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Input } from '../../components/Inputs/Input'
import { Select } from '../../components/Inputs/SelectInput'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'

import { useInput } from '../../hooks/input/useInput'
import { useShipping } from '../../hooks/shipping/useShipping'
import { useStock } from '../../hooks/stock/useStock'

interface FormInputProps {
  collector_id: string
  input_id: string
  input_qty: string
  shipping_id: string
}

const schema = yup.object().shape({
  collector_id: yup.string().required('Campo Obrigatório'),
  input_id: yup.string().required('Campo Obrigatório'),
  input_qty: yup.string().required('Campo Obrigatório'),
  shipping_id: yup.string().required('Campo Obrigatório'),
})

export function StockAddSent() {
  const [isPageLoading, setIsPageLoading] = useState(true)

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormInputProps>({
    resolver: yupResolver(schema),
  })

  const collectorSelected = watch('collector_id')
  const inputSelected = watch('input_id')

  const {
    data: collectors, isLoading: isCollectorsLoading,
  } = useCollectors()
  const {
    stocks: { data: stock, isLoading: isStockLoading },
  } = useStock(null, true, false)
  const {
    inputs: { data: inputs, isLoading: isInputLoading },
  } = useInput(null, true, false)
  const {
    shipping: { data: shipping, isLoading: isShippingLoading },
  } = useShipping(null, true, false)

  const inputsFilteredByCollector = useMemo(() => {
    if (!!inputs && !!stock) {
      const inputIdByStockByCollectorId = stock
        .filter((stock) => stock.collector_id === collectorSelected)
        .map((stock) => stock.input_id)
        .toString()
      return inputs.filter((input) => input.id === inputIdByStockByCollectorId)
    }
  }, [collectorSelected, inputs, stock])

  useEffect(() => {
    function run() {
      if (
        !isCollectorsLoading &&
        !isStockLoading &&
        !isInputLoading &&
        !isShippingLoading
      ) {
        setIsPageLoading(false)
      }
    }
    run()
  }, [isCollectorsLoading, isStockLoading, isInputLoading, isShippingLoading])

  if (isPageLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(() => {})}
          noValidate
        >
          <Heading size="lg" fontFamily="poppins">
            Adicionar Envio de Estoque
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Select
              {...register('collector_id')}
              name="collector_id"
              label="Coletador"
              error={errors.collector_id}
              isDisabled={false}
              collectors={collectors}
              placeholder="Selecione uma opção..."
              required
            />
          </Stack>
          {collectorSelected !== '' && (
            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              <Select
                {...register('input_id')}
                name="input_id"
                label="Insumo"
                error={errors.input_id}
                isDisabled={false}
                inputs={inputsFilteredByCollector}
                placeholder="Selecione uma opção..."
                required
              />
              <Input
                {...register('input_qty')}
                isDisabled={false}
                name="input_qty"
                label="Quantidade Material"
                error={errors.input_qty}
                required
              />
            </Stack>
          )}

          {inputSelected !== '' && (
            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              <Select
                {...register('shipping_id')}
                name="shipping_id"
                label="Transportadora para Envio"
                error={errors.shipping_id}
                isDisabled={false}
                shipping={shipping}
                placeholder="Selecione uma opção..."
                required
              />
            </Stack>
          )}
        </Box>
      </Flex>

  )
}
