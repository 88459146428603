import { Flex, Box, Heading, Button, Icon } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { ProviderTable } from '../../components/Tables/Provider/ProviderTable'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useProviders } from 'hooks/provider/useProviders'

export function ProviderList() {
  const [isLoading, setIsLoading] = useState(true)

  const { data: providers, isLoading: isProviderLoading, refetch } = useProviders()
  const { userLogged } = useContext(AuthContext)
  const permissions = userLogged?.permissions

  useEffect(() => {
    function run() {
      if (!isProviderLoading) {
        setIsLoading(false)
      }
    }
    run()
  }, [isProviderLoading])

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontFamily="poppins">
              Lista de Fornecedores
            </Heading>
            {permissions !== undefined && (
              <Link to="/fornecedor/adicionar">
                {userLogged?.permissions.includes('add-provider') && (
                  <Button
                    colorScheme="gray"
                    rightIcon={<Icon as={FiPlus} />}
                    size="sm"
                  >
                    Adicionar fornecedor
                  </Button>
                )}
              </Link>
            )}
          </Flex>
          {!!providers && (
            <ProviderTable providers={providers} refetch={refetch} />
          )}
        </Box>
      </Flex>

  )
}
