import { UserProps } from 'contexts/UserContext'
import { api } from '../../../services/api'

export interface IHubsProps {
  id: string
  name: string
  state: string
  situation: string
  regional: number
  hub_responsible: string
  responsibleIDHub: UserProps
  timezone: string | null
  observation: string
}

export interface HubInput extends Omit<IHubsProps, 'id' | 'responsibleIDHub'> {
  timezone: string
}

export interface IReqEditProps {
  hubId: string
  hubInput: HubInput
}

export interface UpdateHubResponsibleReqProps {
  regional: 1 | 2 | 3 | 4 | 5
  hub_responsible: string
}

const createHubReqFunction = (hubInput: HubInput) =>
  api
    .post('/hub', hubInput)
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })
const editHubReqFunction = ({ hubId, hubInput }: IReqEditProps) =>
  api
    .put(`/hub/${hubId}`, hubInput)
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })
const deleteHubReqFunction = (hubId: string) =>
  api
    .delete(`/hub/${hubId}`)
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })

const updateHubResponsibleReqFunction = async ({
  regional,
  hub_responsible
}: UpdateHubResponsibleReqProps) => {
  try {
    await api.patch(`/hub/responsible/${regional}`, {
      hub_responsible
    })
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

export { createHubReqFunction, editHubReqFunction, deleteHubReqFunction, updateHubResponsibleReqFunction }
