import { Box, Flex, Text } from "@chakra-ui/react"
import { PurchaseOrderStatus as PurchaseOrderStatusTypes } from "api/purchaseOrders/purchaseOrder"

interface PurchaseOrderStatusProps {
  status: PurchaseOrderStatusTypes | 'all'
}

const purchaseorderStatusMap: Record<PurchaseOrderStatusTypes | 'all', string> = {
  analyzing: 'Análise',
  quoting: 'Aguardando cotação',
  approving: 'Aguardando aprovação',
  purchasing: 'Aguardando compra',
  "awaiting-invoice": 'Aguardando envio de NF',
  arriving: 'Aguardando chegada',
  finished: 'Finalizada',
  refused: 'Recusada',
  all: 'Todos'
}

export function PurchaseOrderStatus({ status }: PurchaseOrderStatusProps) {
  return (
    <Flex
      align="center"
      gap="2"
    >
      {['quoting', 'approving', 'purchasing', 'arriving', 'awaiting-invoice', 'analyzing'].includes(status) && (
        <Box w="2" h="2" rounded="full" bgColor="orange.300" />
      )}

      {status === 'refused' && (
        <Box w="2" h="2" rounded="full" bgColor="red.300" />
      )}

      {status === 'finished' && (
        <Box w="2" h="2" rounded="full" bgColor="green.300" />
      )}
      <Text>{purchaseorderStatusMap[status]}</Text>
    </Flex>
  )
}
