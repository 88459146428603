import { Button, Flex, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { PurchaseOrder } from "api/purchaseOrders/purchaseOrder";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { usePurchaseOrderFunctions } from "hooks/purchaseOrder/usePurchaseOrderFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { FaArrowRight, FaSearch, FaTimes, FaTrash } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { QuotePurchaseOrder } from "./QuotePurchaseOrder";
import { ApprovePurchaseOrder } from "./ApprovePurchaseOrder";
import { InvoicePurchaseOrderModal } from "./InvoicePurchaseOrderModal";
import { PurchaseOrderDetail } from "./PurchaseOrderDetail";
import { PurchaseOrderStatus } from "./PurchaseOrderStatus";
import { RefusePurchaseOrderModal } from "./RefusePurchaseOrderModal";
import { ValidatePurchaseOrder } from "./ValidatePurchaseOrder";
import { BuyPurchaseOrder } from "./BuyPurchaseOrder";
import { acceptPurchaseOrder } from "api/purchaseOrders/acceptPurchaseOrder";

interface PurchaseOrderTableRowProps {
  purchaseOrder: PurchaseOrder
}

export function PurchaseOrdersTableRow({ purchaseOrder }: PurchaseOrderTableRowProps) {
  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()

  const userHasRefusedPurchaseOrderPermission = userLogged?.permissions.includes("refuse-purchase-order")

  const userHasDeletePurchaseOrderPermission = userLogged?.permissions.includes("delete-purchase-order")

  const userHasPermissionToApprovePurchaseOrderQuotes =
    userLogged.permissions.includes('approve-purchase-order-quotes')


  const userHasPermissionAddInvoicePurchaseOrder =
    userLogged.permissions.includes('add-invoice-purchase-order')

  const userHasPermissionToBuyPurchaseOrder =
    userLogged.permissions.includes('buy-purchase-order')

  const userHasPermissionToValidatePurchase =
    userLogged.permissions.includes('validate-purchase')

  const userHasPermissionToAcceptPurchase =
    userLogged.permissions.includes('accept-purchase')

  const userHasPermissionToQuotePurchaseOrder = userLogged.permissions.includes('quote-purchase-order')

  const {
    isOpen: isDetailModalOpen,
    onOpen: onOpenDetailModal,
    onClose: onCloseDetailModal,
  } = useDisclosure()

  const {
    isOpen: isRefusePurchaseOrderModalOpen,
    onOpen: onOpenRefusePurchaseOrderModal,
    onClose: onCloseRefusePurchaseOrderModal,
  } = useDisclosure()

  const {
    isOpen: isInvoicePurchaseOrderModalOpen,
    onOpen: onOpenInvoicePurchaseOrderModal,
    onClose: onCloseInvoicePurchaseOrderModal
  } = useDisclosure()

  const {
    isOpen: isValidatePurchaseOrderModalOpen,
    onOpen: onOpenValidatePurchaseOrderModal,
    onClose: onCloseValidatePurchaseOrderModal
  } = useDisclosure()

  const {
    isOpen: isApprovePurchaseOrderModalOpen,
    onOpen: onOpenApprovePurchaseOrderModal,
    onClose: onCloseApprovePurchaseOrderModal
  } = useDisclosure()

  const {
    isOpen: isQuotePurchaseOrderModalOpen,
    onOpen: onOpenQuotePurchaseOrderModal,
    onClose: onCloseQuotePurchaseOrderModal
  } = useDisclosure()

  const {
    isOpen: isBuyPurchaseOrderModalOpen,
    onOpen: onOpenBuyPurchaseOrderModal,
    onClose: onCloseBuyPurchaseOrderModal
  } = useDisclosure()

  const { deletePurchaseOrder: { mutateAsync: deletePurchaseOrder } } = usePurchaseOrderFunctions()

  const queryClient = useQueryClient()

  const { mutateAsync: acceptPurchaseOrderFn } = useMutation({
    mutationFn: acceptPurchaseOrder,
    onSuccess() {
      queryClient.invalidateQueries(['purchaseOrders'])
    },
  })


  async function handleDeletePurchaseOrder(purchaseOrder: PurchaseOrder) {
    await promiseMessage(deletePurchaseOrder(purchaseOrder.id, {
      onSuccess: () => {
        queryClient.invalidateQueries(['purchaseOrders'])
      }
    }), 'Pedido de compra excluído com sucesso!')
  }
  async function handleAcceptPurchaseOrder(purchaseOrderId: string) {
    await promiseMessage(acceptPurchaseOrderFn({purchaseOrderId}, {
      onSuccess: () => {
        queryClient.invalidateQueries(['purchaseOrders'])
      }
    }), 'Pedido de compra aceito com sucesso!')
  }

  const purchaseOrderActionsOptions = [
    {
      condition: purchaseOrder.status === 'quoting' && userHasPermissionToQuotePurchaseOrder,
      icon: <FaArrowRight />,
      label: 'Cotar',
      onClick: onOpenQuotePurchaseOrderModal
    },
    {
      condition: purchaseOrder.status === 'approving' && userHasPermissionToApprovePurchaseOrderQuotes,
      icon: <FaArrowRight />,
      label: 'Aprovar',
      onClick: onOpenApprovePurchaseOrderModal
    },
    {
      condition: purchaseOrder.status === 'awaiting-invoice' && userHasPermissionAddInvoicePurchaseOrder,
      icon: <FaArrowRight />,
      label: 'Nota Fiscal',
      onClick: onOpenInvoicePurchaseOrderModal
    },
    {
      condition: purchaseOrder.status === 'purchasing' && userHasPermissionToBuyPurchaseOrder,
      icon: <FaArrowRight />,
      label: 'Comprar',
      onClick: onOpenBuyPurchaseOrderModal
    },
    {
      condition: purchaseOrder.status === 'arriving' && userHasPermissionToValidatePurchase,
      icon: <FaArrowRight />,
      label: 'Validar Compra',
      onClick: onOpenValidatePurchaseOrderModal
    }
  ];

  const enabledRefusedPurchaseOrder = userHasRefusedPurchaseOrderPermission && (purchaseOrder.status === 'quoting' || purchaseOrder.status === 'analyzing')

  return (
    <Tr>
      <RefusePurchaseOrderModal
        onClose={onCloseRefusePurchaseOrderModal}
        isOpen={isRefusePurchaseOrderModalOpen}
        purchaseOrderId={purchaseOrder.id}
      />
      <InvoicePurchaseOrderModal
        isInvoiceModalOpen={isInvoicePurchaseOrderModalOpen}
        onCloseInvoiceModal={() => {
          onCloseInvoicePurchaseOrderModal();
        }}
        purchaseOrderId={purchaseOrder.id} />

      <Td>
        <IconButton
          aria-label="Visualizar Pedido de Compra"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onOpenDetailModal}
        />

        <Modal
          isOpen={isDetailModalOpen}
          onClose={onCloseDetailModal}
          isCentered
        >
          <ModalOverlay />
          <PurchaseOrderDetail purchaseOrderId={purchaseOrder.id} />
        </Modal>

      </Td>
      <Td>{purchaseOrder.protocol}</Td>
      <Td>{purchaseOrder?.owner.firstname} {purchaseOrder?.owner.lastname}</Td>
      <Td>{format(new Date(purchaseOrder.created_at), 'dd/MM/yyyy')}</Td>
      <Td>{purchaseOrder.type}</Td>
      <Td>{purchaseOrder.quote_type}</Td>
      <Td>
        <PurchaseOrderStatus status={purchaseOrder.status} />
      </Td>
      <Td>{format(new Date(purchaseOrder.arrival_limit_date), 'dd/MM/yyyy')}</Td>
      <Td>
        <Modal
          isOpen={isValidatePurchaseOrderModalOpen}
          onClose={onCloseValidatePurchaseOrderModal}
          isCentered
        >
          <ModalOverlay />
          <ValidatePurchaseOrder
            isProductForm={purchaseOrder?.type === 'PRODUTO'}
            purchaseOrderId={purchaseOrder.id}
            onCloseModal={onOpenValidatePurchaseOrderModal}
          />
        </Modal>
        <Modal
          isOpen={isApprovePurchaseOrderModalOpen}
          onClose={onCloseApprovePurchaseOrderModal}
          isCentered
        >
          <ModalOverlay />
          <ApprovePurchaseOrder
            purchaseOrderId={purchaseOrder.id}
            onCloseModal={onOpenApprovePurchaseOrderModal}

          />
        </Modal>
        <Modal
          isOpen={isQuotePurchaseOrderModalOpen}
          onClose={onCloseQuotePurchaseOrderModal}
          isCentered
        >
          <ModalOverlay />
          <QuotePurchaseOrder
            purchaseOrderId={purchaseOrder.id}
            onCloseModal={onOpenQuotePurchaseOrderModal}

          />
        </Modal>
        <Modal
          isOpen={isBuyPurchaseOrderModalOpen}
          onClose={onCloseBuyPurchaseOrderModal}
          isCentered
        >
          <ModalOverlay />
          <BuyPurchaseOrder
            purchaseOrderId={purchaseOrder.id}
            onCloseModal={onOpenBuyPurchaseOrderModal}
          />
        </Modal>
        <Flex
          align="center"
          gap="2"
        >
          {purchaseOrderActionsOptions.map((config, index) => (
            config.condition && (
              <Button
                key={index}
                leftIcon={config.icon}
                lineHeight="1"
                size="xs"
                onClick={config.onClick}
              >
                {config.label}
              </Button>
            )
          ))}
           {userHasPermissionToAcceptPurchase && purchaseOrder.status === 'analyzing' && (
            <Button
              leftIcon={<FaArrowRight />}
              lineHeight="1"
              size="xs"
              variant="ghost"
              onClick={() => handleAcceptPurchaseOrder(purchaseOrder.id)}
            >
              Aceitar
            </Button>
          )}
          {enabledRefusedPurchaseOrder && (
            <Button
              leftIcon={<FaTimes />}
              lineHeight="1"
              size="xs"
              variant="ghost"
              onClick={onOpenRefusePurchaseOrderModal}
            >
              Recusar
            </Button>
          )}
          {userHasDeletePurchaseOrderPermission && (
            <Button
              leftIcon={<FaTrash />}
              lineHeight="1"
              size="xs"
              variant="ghost"
              onClick={() => handleDeletePurchaseOrder(purchaseOrder)}
            >
              Excluir
            </Button>
          )}
        </Flex>
      </Td>
    </Tr>
  )
}
