import { IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { Invoice } from "api/invoices/_types/Invoice";
import { format } from "date-fns";
import { FaSearch } from "react-icons/fa";
import { captalize } from "utils/captalize";
import { InvoiceDetails } from "./InvoiceDetails";

import { InvoiceStatus } from "./InvoiceStatus";

interface InvoiceTableRowProps {
  invoice: Invoice
}

export function InvoiceTableRow({
  invoice }: InvoiceTableRowProps) {

  const {
    isOpen: isInvoiceDetailModalOpen,
    onToggle: onToggleInvoiceDetailModal,
  } = useDisclosure()

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Fatura"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onToggleInvoiceDetailModal}
        />
        <Modal
          isOpen={isInvoiceDetailModalOpen}
          onClose={onToggleInvoiceDetailModal}
          size='6xl'
        >
          <ModalOverlay />
          <InvoiceDetails
            invoiceId={invoice.id}
          />
        </Modal>
      </Td>

      <Td>{invoice?.collectorId ? captalize(invoice?.collector?.trading_name) : captalize(invoice.aggregate?.trading_name)}</Td>
      <Td>{invoice?.collectorId ? captalize(invoice?.collector?.company_name) : captalize(invoice.aggregate?.company_name)}</Td>
      <Td>
        <InvoiceStatus
          status={invoice?.status}
        />
      </Td>
      <Td >{invoice?.reportStartDate ? format(new Date(invoice?.reportStartDate), 'dd/MM/yyyy') : '-'}</Td>

    </Tr>
  )
}
