import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import {
  getAllBudgets,
  getOneBudget,
} from '../../services/getFunctions/budget/getBudget'
import { useAuth } from '../auth/useAuth'

export function useBudget(
  budgetId: string | null,
  isEnable?: boolean,
  refetchOnFocus?: boolean,
  customer_id?: string
) {
  const { handleLogout, userLogged } = useAuth()

  const budgets = useQuery('budgets', () => getAllBudgets({ customer_id }), {
    enabled: !!isEnable && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (error: any) => {
      toast.error('Erro ao consultar informações! Realize o login novamente.')
      handleLogout()
    },
  })
  const budget = useQuery(
    ['budget', budgetId],
    () => getOneBudget(budgetId !== null ? budgetId : ''),
    {
      enabled: !!budgetId && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )

  return {
    budgets,
    budget,
  }
}
