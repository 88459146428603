import { useEffect } from "react"
import * as yup from "yup"
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm"

import { useToastify } from "hooks/toastify/useToastify"
import { FormProvider, useForm } from "react-hook-form"
import { Link, useHistory, useParams } from "react-router-dom"

import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Flex, Spinner } from "@chakra-ui/react"

import { useAuth } from "hooks/auth/useAuth"
import { usePurchaseOrderFunctions } from "hooks/purchaseOrder/usePurchaseOrderFunctions"
import { useQueryClient } from "react-query"
import { ValidatePurchaseOrderForm, ValidatePurchaseOrderFormInputs } from "./components/ValidatePurchaseOrderForm"
import { usePurchaseOrder } from "hooks/purchaseOrder/usePurchaseOrder"

interface ParamsProps {
  purchaseOrderId: string
}

const validatePurchaseOrderSchema = yup.object().shape({
  isFinishedCorrectly: yup.mixed().required('Campo obrigatório'),
  hasRequestedFinancialPayment: yup.mixed().required('Campo obrigatório'),
  hasConferredEquity: yup.mixed().required('Campo obrigatório'),
})

export function ValidatePurchaseOrder() {
  const { purchaseOrderId } = useParams<ParamsProps>()

  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()

  const { push: redirect } = useHistory()

  const userHasPermissionToValidatePurchase = userLogged.permissions.includes('validate-purchase')

  useEffect(() => {
    if (!userHasPermissionToValidatePurchase) {
      redirect('/')
    }
  }, [userHasPermissionToValidatePurchase, redirect])

  const formMethods = useForm<ValidatePurchaseOrderFormInputs>({
    resolver: yupResolver(validatePurchaseOrderSchema),
  })

  const { handleSubmit, formState: { isSubmitting } } = formMethods

  const {
    data: purchaseOrderData,
    isFetching: isFetchingPurchaseOrderData
  } = usePurchaseOrder({
    purchaseOrderId
  })

  const {
    validatePurchaseOrder: {
      mutateAsync: validatePurchaseOrder
    }
  } = usePurchaseOrderFunctions()

  const queryClient = useQueryClient()

  async function handleCreatePurchaseOrder(values: ValidatePurchaseOrderFormInputs) {
    const successMessage = "Cotação realizada com sucesso!"

    await promiseMessage(validatePurchaseOrder({
      purchaseOrderId,
      input: values
    }, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['purchaseOrdersAwaitingQuote'])
        redirect("/compras/aguardando-chegada")
      }
    }), successMessage)
  }
  return (
    <StandardBackgroundForm
      title='Validar Compra'
      onSubmit={handleSubmit(handleCreatePurchaseOrder)}
    >
      {isFetchingPurchaseOrderData ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <ValidatePurchaseOrderForm
            isProductForm={purchaseOrderData?.purchaseOrder.type === 'PRODUTO'}
          />
        </FormProvider>
      )}

      <Flex justify='flex-end' mt={4} gap={4}>
        <Button
          as={Link}
          colorScheme='gray'
          w={['full', 'full', 'min']}
          isLoading={isSubmitting}
          to='/compras/aguardando-chegada'
        >
          Pedidos Aguardando Cotação
        </Button>
        <Button
          type='submit'
          colorScheme='blue'
        >
          Salvar
        </Button>
      </Flex>

    </StandardBackgroundForm>
  )
}
