import { ModalBody } from "@chakra-ui/react";
import { ReactNode } from "react";

interface MaterialRequestModalContentProps {
  children: ReactNode
}

export function MaterialRequestModalContent({
  children
}: MaterialRequestModalContentProps) {
  return (
    <ModalBody
      p="6"
      gap="4"
      display="flex"
      flexDirection="column"
      maxH="500px"
      overflowY="scroll"
    >
      {children}
    </ModalBody>
  )
}
