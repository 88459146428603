import request from "axios"
import { useQuery, UseQueryOptions } from "react-query";
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { IIbgeApiCityProps } from "services/ibgeApiservice/IIbgeApi";
import { apiIbge } from "services/ibgeApiservice/ibgeApi";

type QueryOptions = UseQueryOptions<IIbgeApiCityProps[], unknown, IIbgeApiCityProps[], ['ibgeCitiesByUf']>

interface UseIbgeCitiesByUfOptions {
  queryOptions?: QueryOptions
  uf: string
}

async function getIbgeCitiesByUf(uf: string) {
  try {
    const data = await apiIbge.getCities(`/estados/${uf}/municipios`)

    return data
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

export function useIbgeCitiesByUf(options?: UseIbgeCitiesByUfOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()


  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery(['ibgeCitiesByUf', options.uf], () => getIbgeCitiesByUf(options?.uf), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
