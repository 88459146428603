import { useMutation, useQueryClient } from 'react-query'
import {
  CreateDriverInputProps,
  createDriverReqFunction,
  deleteDriverReqFunction,
  editDriverReqFunction,
  EditDriverReqProps,
} from '../../utils/RequestFunctions/Driver/requestDriverFunctions'
import { useSwal } from '../swal/useSwal'

export const useDriverFunctions = () => {
  const queryClient = useQueryClient()
  const { successMessage, errorMessage } = useSwal()

  const createDriver = useMutation(
    'createDriver',
    (driverInput: CreateDriverInputProps) =>
      createDriverReqFunction(driverInput),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['drivers'])
        successMessage('Motorista criado com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  const editDriver = useMutation(
    ({ driverId, editDriverInput }: EditDriverReqProps) =>
      editDriverReqFunction({ driverId, editDriverInput }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['drivers'])
        successMessage('Motorista editado com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )
  const deleteDriver = useMutation(
    (driverId: string) => deleteDriverReqFunction(driverId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['drivers'])
        successMessage('Motorista excluído com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  return {
    createDriver,
    editDriver,
    deleteDriver,
  }
}
