import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Flex, Stack } from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { useAuth } from "hooks/auth/useAuth";
import { Input } from "components/Inputs/Input";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { formatDate } from "utils/DateFunctions/formatDate";
import { add } from "date-fns";
import { useToastify } from "hooks/toastify/useToastify";
import { useExtrasAndDiscountsFunctions } from "hooks/extrasAndDiscounts/useExtrasAndDiscountsFunctions";
import { useNonInactiveCustomers } from "hooks/customer/useNonInactiveCustomers";
import { Select } from "components/Inputs/SelectInput";
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";

interface ExtrasAndDiscountsReportInputs {
  initialDate: string
  finalDate: string
  customerId: string
  find_by: 'GERAL' | 'CLIENTE'
}

const ExtrasAndDiscountsReportHeaders = [
  { key: 'customer', label: 'CLIENTE' },
  { key: 'occurrence_date', label: 'DATA DA OCORRÊNCIA' },
  { key: 'route', label: 'ROTA' },
  { key: 'value', label: 'VALOR' },
  { key: 'description', label: 'DESCRIÇÃO' },
]

const findBySelectOptions = [
  { key: '0', value: 'GERAL', showOption: 'GERAL' },
  { key: '1', value: 'CLIENTE', showOption: 'CLIENTE' },
]


const schema = yup.object().shape({
  find_by: yup.string().required('Campo Obrigatório'),
  customerId: yup.string().when('find_by', {
    is: 'CLIENTE',
    then: yup.string().required('Campo Obrigatório'),
  }),
  initialDate: yup.string().required('Campo obrigatório!'),
  finalDate: yup.string().required('Campo obrigatório!')
})

export function ExtrasAndDiscountsReport() {

  const { promiseMessage } = useToastify()

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<ExtrasAndDiscountsReportInputs>({
    resolver: yupResolver(schema),
  })

  const [
    initialDateFilter,
    finalDateFilter,
    findBySelected
  ] = useWatch({
    control,
    name: ['initialDate', 'finalDate', 'find_by']
  })

  const isFindByAggregate = findBySelected === 'CLIENTE'

  const isValidStartDate = new Date(initialDateFilter) instanceof Date &&
    !isNaN(new Date(initialDateFilter).getMilliseconds())

  const maxEndFilter = isValidStartDate
    ? formatDate.handle(add(new Date(initialDateFilter), {
      days: 31
    }), 'DateWithoutHourToInput')
    : ''

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-extras-discounts-report')) {
      redirect('/')
    }
  }, [userLogged, redirect])

  const {
    data: customersData,
    isFetching: isCustomersDataFetching
  } = useNonInactiveCustomers()


  const { generateExtrasAndDiscountsReport: {
    mutateAsync: generateExtrasAndDiscountsReport,
    isLoading: isGenerateExtrasAndDiscountsReportDataLoading,
    data: extrasAndDiscountsData
  } } = useExtrasAndDiscountsFunctions()

  async function handleGenerateExtrasAndDiscountsReport({ initialDate, finalDate, customerId }: ExtrasAndDiscountsReportInputs) {
    await promiseMessage(
      generateExtrasAndDiscountsReport({
        initialDate,
        finalDate,
        customerId
      }), "Relatório gerado com sucesso!"
    )
  }

  const extrasAndDiscountsReportDataFormatted = extrasAndDiscountsData !== undefined ?
    extrasAndDiscountsData?.extrasDiscounts
      ?.map(extraAndDiscount => {
        return {
          ...extraAndDiscount,
          customer: extraAndDiscount?.customer?.trading_firstname,
          occurrence_date: formatDate.handle(extraAndDiscount?.occurrence_date, 'DateWithoutHourToShow'),
          route: extraAndDiscount?.route ? extraAndDiscount?.route : '-',
          value: extraAndDiscount.value / 100
        }
      })
    : []


  const csvReportFilename = `relatorio-extras-e-descontos-${initialDateFilter}-${finalDateFilter}`

  const csvReportProps = {
    data: extrasAndDiscountsReportDataFormatted,
    headers: ExtrasAndDiscountsReportHeaders,
    filename: csvReportFilename,
  }

  if (isCustomersDataFetching) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm
      title='Relatório de Extras e Descontos Finalizados'
      onSubmit={handleSubmit(handleGenerateExtrasAndDiscountsReport)}
    >
      <Flex
        gap={4}
        direction='column'
      >
        <Stack
          w='full'
          spacing={4}
          direction={['column', 'column', 'row']}
        >
          <Select
            {...register('find_by')}
            name='find_by'
            options={findBySelectOptions}
            label='Pesquisar por'
            placeholder='Selecione uma opção...'
            error={errors.find_by}
            required
          />
          {isFindByAggregate && (
            <Select
              {...register("customerId")}
              name="customerId"
              label="Cliente"
              placeholder="Selecione uma opção..."
              customers={customersData?.customers}
              error={errors.customerId}
              required
            />
          )}
        </Stack>
        <Stack
          w='full'
          spacing={4}
          direction={['column', 'column', 'row']}
        >
          <Input
            {...register('initialDate')}
            name='initialDate'
            label='Data inicial do filtro'
            type='date'
            error={errors.initialDate}
            required
          />

          <Input
            {...register('finalDate')}
            name='finalDate'
            label='Data final do filtro'
            type='date'
            error={errors.finalDate}
            max={maxEndFilter}
            required
          />
        </Stack>

        <Flex gap={2} justify={['center', 'center', 'flex-end']}>
          <Button
            isLoading={isGenerateExtrasAndDiscountsReportDataLoading}
            type='submit'
            w={['full', 'full', 'min']}
            colorScheme='blue'
          >
            Gerar relatório
          </Button>
          {extrasAndDiscountsData !== undefined && (
            <GenerateExcelReportButton

              mt={-4}
              csvReportProps={csvReportProps}
              isLoading={isGenerateExtrasAndDiscountsReportDataLoading} />
          )}
        </Flex>
      </Flex>
    </StandardBackgroundForm>
  )
}
