import { Button, Flex, Icon, IconButton, Link as ChakraLink } from "@chakra-ui/react"
import { useState } from "react"
import { FaArrowLeft, FaArrowRight, FaLink } from "react-icons/fa"
import { handleChangeUrl } from "utils/handleChangeUrl"

interface PreviewFilesCarouselProps {
  files: {
    key: string
    filename: string
  }[]
}

export function PreviewFilesCarousel({
  files
}: PreviewFilesCarouselProps) {
  const [selectedFileIndex, setSelectedFileIndex] = useState(0)

  const selectedFile = files[selectedFileIndex]

  const handleNextFile = () => {
    const isLastFile = selectedFileIndex === files.length - 1

    if (isLastFile) {
      setSelectedFileIndex(0)
    } else {
      setSelectedFileIndex(prevSelectedFileIndex => prevSelectedFileIndex + 1)
    }
  }

  const handlePrevFile = () => {
    const isFirstFile = selectedFileIndex === 0

    if (isFirstFile) {
      setSelectedFileIndex(files.length - 1)
    } else {
      setSelectedFileIndex(prevSelectedFileIndex => prevSelectedFileIndex - 1)
    }
  }

  return (
    <Flex
      justify="between"
      align="center"
      w="full"
      gap="2"
    >
      <IconButton
        aria-label="Item anterior"
        icon={<FaArrowLeft />}
        onClick={handlePrevFile}
      />
      <Button
        w="full"
        as={ChakraLink}
        href={handleChangeUrl(selectedFile.key)}
        leftIcon={<Icon as={FaLink} />}
        variant="outline"
        isExternal
      >
        {selectedFile.filename}
      </Button>
      <IconButton
        aria-label="Próximo item"
        icon={<FaArrowRight />}
        onClick={handleNextFile}
      />
    </Flex>
  )
}
