import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Product } from "./_types/Product";

export interface GetProductResponse {
  product: Product
}

interface GetProductParams {
  productId: string
}

export async function getProduct({
  productId
}: GetProductParams) {
  try {
    const response = await api.get<GetProductResponse>(`/products/${productId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
