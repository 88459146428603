import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";


interface CreateCustomerExtraDiscountProps {
  input: FormData
  customerId: string
}

export async function createCustomerExtraDiscount({
  input,
  customerId
}: CreateCustomerExtraDiscountProps) {
  try {
    const response = await api.post(`/customer/${customerId}/extras-and-discounts`, input)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
