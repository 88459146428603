import { Flex, Input, Select, Button, Icon, Text } from "@chakra-ui/react";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { PmcStatusTypes } from "./PmcStatus";

interface PmcTableFilterSchema {
  protocol: string
  status: PmcStatusTypes
}

export function PmcsTableFilter() {
  const { replace } = useHistory()
  const searchParams = useSearchParams()

  const protocol = searchParams.get('protocol')
  const status = searchParams.get('status') ?? 'all'

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<PmcTableFilterSchema>({
    defaultValues: {
      protocol: protocol ?? '',
      status: status as PmcStatusTypes
    }
  })

  function handleSearchPmcs({ protocol, status }: PmcTableFilterSchema) {
    if (protocol) {
      searchParams.set('protocol', protocol)
    } else {
      searchParams.delete('protocol')
    }

    if (status) {
      searchParams.set('status', status)
    } else {
      searchParams.delete('status')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    searchParams.delete('protocol')
    searchParams.delete('status')

    reset({ protocol: '', status: 'all' })

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      as="form"
      align="center"
      direction={["column", "column", "row"]}
      gap="2"
      onSubmit={handleSubmit(handleSearchPmcs)}
    >
      <Text alignSelf={["flex-start", "flex-start", "center"]} fontSize="sm">
        Filtros:
      </Text>

      <Input
        {...register('protocol')}
        name="protocol"
        placeholder="Protocolo..."
        size="sm"
        borderRadius="md"
        w={["full", "full", "min"]}
      />

      <Select
        {...register('status')}
        defaultValue="all"
        size="sm"
        borderRadius="md"
        w={["full", "full", "200px"]}
      >
        <option value="all">Todos status</option>
        <option value="approving">Em aprovação</option>
        <option value="receiving">Em recebimento</option>
        <option value="received">Recebido</option>
      </Select>

      <Button
        type="submit"
        size="sm"
        leftIcon={<Icon as={FaSearch} />}
        w={['full', 'full', 'min']}
      >
        Filtrar resultados
      </Button>

      <Button
        type="submit"
        size="sm"
        variant="outline"
        leftIcon={<Icon as={FaTimes} />}
        w={['full', 'full', 'min']}
        onClick={handleRemoveFilters}
      >
        Remover filtros
      </Button>


    </Flex>
  )
}
