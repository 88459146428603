import axios from "axios";
import { DispatchConfirmProps } from "contexts/StockContext";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { DispatchStock } from "./dispatchStock";

interface GetDispatchStocksReportProps {
  queryParams: {
    startDate: string
    endDate: string
    timezoneOffset: number,
    step: string
  }
}

export interface GetDispatchStocksReportResponse {
  dispatchStocks: Array<DispatchStock & {
    confirmation: DispatchConfirmProps & {
      arrival_date: string
    }
  }>
}

export async function getDispatchStocksReport({
  queryParams
}: GetDispatchStocksReportProps) {
  try {
    const { data } = await api.get<GetDispatchStocksReportResponse>('/dispatch-stocks/report', {
      params: { ...queryParams }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

