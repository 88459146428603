import {
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Icon,
  VStack,
} from "@chakra-ui/react";
import { FaFilePdf } from "react-icons/fa";
import { generateSalaryAccountAuthorizationLetter } from "api/contracts/generateSalaryAccountAuthorizationLetter";
import { useMutation, } from "react-query";
import { GetInternCltResponse } from "api/internClts/getInternClt";


interface ContractsInternCltProps {
  internCltData: GetInternCltResponse
}

export function ContractsInternClt({ internCltData }: ContractsInternCltProps) {

  const { mutateAsync: generateSalaryAccountAuthorizationLetterFn } = useMutation({
    mutationFn: generateSalaryAccountAuthorizationLetter,
  })

  async function handleGenerateSalaryAccountAuthorizationLetter() {
    const generateSalaryAccountAuthorizationLetterResponse = await generateSalaryAccountAuthorizationLetterFn({
      body: {
        address: `
      ${internCltData?.internClt.address_street}, ${' '}
      ${internCltData?.internClt.address_number}, ${internCltData?.internClt.address_neighborhood} - ${internCltData?.internClt.address_cep}`,
        admissionDate: internCltData?.internClt?.admission_date,
        cpf: internCltData?.internClt?.cpf,
        name: internCltData?.internClt?.name,
        rg: internCltData?.internClt?.rg
      }
    })

    const bytes = new Uint8Array(generateSalaryAccountAuthorizationLetterResponse.pdfDocData.data)

    const url = URL.createObjectURL(
      new Blob([bytes], { type: 'application/pdf' }),
    )

    const link = document.createElement("a");
    link.href = url;
    link.download = "Carta_Autorização_Conta_Salario.pdf";
    document.body.appendChild(link);
    link.click();


    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 0);

  }
  return (
    <Box>
      <Popover>
        <PopoverTrigger>
          <VStack

            spacing={1}
            cursor="pointer"
            _hover={{
              transform: 'translateY(-5px)'
            }}
            transition="ease-in-out 0.2s"
          >
            <Icon
              as={FaFilePdf}
              color="blue.500"
              fontSize="lg"
            />
          </VStack>

        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader fontSize='sm'>Documentos</PopoverHeader>
          <PopoverBody>
            <VStack spacing={2} align="stretch">
              <Button
                width="100%"
                variant="ghost"
                justifyContent="flex-start"
                onClick={handleGenerateSalaryAccountAuthorizationLetter}
                size="sm"
                fontWeight="normal"
                leftIcon={<Icon as={FaFilePdf} />}
              >
                Carta de autorização para conta salário
              </Button>

            </VStack>

          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};


