import { Button, Flex, Icon, IconButton, Stack } from "@chakra-ui/react"
import { Input } from "components/Inputs/Input"
import { Select } from "components/Inputs/SelectInput"
import { DayConditional } from "hooks/cost/dtos/CollectorCost"
import { useEffect } from "react"
import { useCurrency } from "react-hook-currency"
import { Control, FieldErrors, useFieldArray, UseFormRegister } from "react-hook-form"
import { FaPlus, FaTrash } from "react-icons/fa"
import { daysOfWeekOptions } from "utils/CustomLists/daysOfWeekOptions"
import { FormInputProps } from "../CollectorCostForm"

interface CostDayConditionalsFieldProps<T> {
  control: Control<T>
  register: UseFormRegister<T>
  errors: FieldErrors<T>
  dayConditionals: DayConditional[]
}

export function CostDayConditionalsField({
  control,
  register,
  errors,
  dayConditionals,
}: CostDayConditionalsFieldProps<FormInputProps>) {

  const {
    fields,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: 'dayConditionals'
  })

  useEffect(() => {
    if (dayConditionals) {
      dayConditionals.forEach((conditional, index) => {
        update(index, {
          day: conditional.day,
          cost_motorcycle: String(conditional.cost_motorcycle as number / 100),
          additional_cost_motorcycle: String(conditional.additional_cost_motorcycle as number / 100),
          cost_car: String(conditional.cost_car as number / 100),
          additional_cost_car: String(conditional.additional_cost_car as number / 100),

        })
      })
    }
  }, [update, dayConditionals])

  const { format: currencyFormat, onChange: onCurrencyChange } = useCurrency({
    style: 'decimal'
  })

  const handleAppendField = () => append({
    day: undefined,
    cost_motorcycle: '',
    additional_cost_motorcycle: '',
    cost_car: '',
    additional_cost_car: '',
  })

  const handleRemoveField = (index: number) => remove(index)

  return (
    <Flex
      direction="column"
      gap="6"
      mt="6"
    >
      {fields.map((field, index) => {
        return (
          <Flex
            key={field.id}
            border="1px solid"
            borderColor="gray.100"
            borderRadius="base"
            p="4"
            direction="column"
            gap="6"
          >
            <Stack
              spacing="6"
              direction={["column", "column", "row"]}
            >
              <Select
                {...register(`dayConditionals.${index}.day`)}
                name={`dayConditionals.${index}.day`}
                options={daysOfWeekOptions}
                error={
                  errors?.dayConditionals
                    ? errors?.dayConditionals[index]?.day
                    : undefined
                }
                placeholder="Selecione uma opção..."
                label="Dia"
                required
              />
            </Stack>

            <Stack
              spacing="6"
              direction={["column", "column", "row"]}
            >
              <Input
                {...register(`dayConditionals.${index}.cost_motorcycle`)}
                name={`dayConditionals.${index}.cost_motorcycle`}
                error={
                  errors?.dayConditionals
                    ? errors?.dayConditionals[index]?.cost_motorcycle
                    : undefined
                }
                addChildren="R$"
                onChange={onCurrencyChange}
                defaultValue={currencyFormat('000')}
                label="Custo para motos"
                required
              />

              <Input
                {...register(`dayConditionals.${index}.additional_cost_motorcycle`)}
                name={`dayConditionals.${index}.additional_cost_motorcycle`}
                error={
                  errors?.dayConditionals
                    ? errors?.dayConditionals[index]?.additional_cost_motorcycle
                    : undefined
                }
                addChildren="R$"
                onChange={onCurrencyChange}
                defaultValue={currencyFormat('000')}
                label="Custo adicional para motos"
                required
              />
            </Stack>

            <Stack
              spacing="6"
              direction={["column", "column", "row"]}
            >
              <Input
                {...register(`dayConditionals.${index}.cost_car`)}
                name={`dayConditionals.${index}.cost_car`}
                error={
                  errors?.dayConditionals
                    ? errors?.dayConditionals[index]?.cost_car
                    : undefined
                }
                addChildren="R$"
                onChange={onCurrencyChange}
                defaultValue={currencyFormat('000')}
                label="Custo para carros"
                required
              />

              <Input
                {...register(`dayConditionals.${index}.additional_cost_car`)}
                name={`dayConditionals.${index}.additional_cost_car`}
                error={
                  errors?.dayConditionals
                    ? errors?.dayConditionals[index]?.additional_cost_car
                    : undefined
                }
                addChildren="R$"
                onChange={onCurrencyChange}
                defaultValue={currencyFormat('000')}
                label="Custo adicional para carros"
                required
              />
            </Stack>

            <Flex
              w="full"
              justify="flex-end"
            >
              <Button
                as={IconButton}
                icon={<Icon as={FaTrash} />}
                colorScheme="red"
                mt="8"
                onClick={() => handleRemoveField(index)}
              />
            </Flex>
          </Flex>
        )
      })}

      <Button
        leftIcon={<Icon as={FaPlus} />}
        colorScheme="blue"
        onClick={handleAppendField}
        alignSelf="flex-end"
        outline={
          errors?.dayConditionals?.message
            ? '2px solid'
            : ''
        }
        outlineColor={
          errors?.dayConditionals?.message
            ? 'red.500'
            : 'none'
        }
      >
        Novo dia
      </Button>
    </Flex>

  )
}
