import { Box, BoxProps, Icon, useRadio, UseRadioProps } from "@chakra-ui/react";
import { IconType } from "react-icons";

interface RadioItemProps {
  props: UseRadioProps;
  radio: {
    styles: BoxProps;
    icon: IconType;
  };
}

export function RadioItem({ props, radio }: RadioItemProps) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const inputProps = getInputProps();
  const radioProps = getRadioProps();

  return (
    <Box as="label">
      <input {...inputProps} />
      <Box
        {...radioProps}
        cursor="pointer"
        borderWidth={"1px"}
        borderRadius="full"
        boxSize="1.5rem"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderColor={props.isChecked ? "blue.500" : "gray.300"}
      >
        {props.isChecked && (
          <Icon as={radio.icon} color="blue.500" />
        )}
      </Box>
    </Box>
  );
}
