import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface GetServicesWithStepChangesParams {
  pageSize: string
  currentPage: string
}

export interface ServiceWithStepChanges {
  id: string
  protocol: number
  step: string
  cte_loglife: null | string
  cte_loglife_emission_date: null | string
  cte_complementary_emission_date: null | string
  cte_complementary: null | string
  collect_date: string
  identifiers: null
  assigned_pdf: null | string
  is_business: boolean
  has_gelo_seco_maintenance: boolean
  pending_gelo_seco_maintenance: boolean
  pending_crossdocking_gelo_seco_maintenance: boolean
  step_before_cancel_request: null | string
  cte_loglife_pdf_associated: null | string
  cte_complementary_pdf_associated: null | string
  landing_downtim: null | string
  pending_maintenance: boolean
  pending_crossdocking_maintenance: boolean
  cancel_reason: null | string
  cancel_observation: null | string
  audited_at: null | boolean
  is_destination_board_branch_confirmed: null | boolean
  is_crossdocking_destination_board_branch_confirmed: null | boolean
  has_qr_code: null | boolean
  collects_total_volumes: null | number
  step_change_requested: string
  step_before_step_change_requested: string
  createdAt: string
  updatedAt: string
  customer_id: string
}

export interface GetServicesWithStepChangesResponse {
  services: ServiceWithStepChanges[]
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
  }
}

export async function getServicesWithStepChanges({
  pageSize,
  currentPage,
}: GetServicesWithStepChangesParams) {
  try {
    const { data } = await api.get<GetServicesWithStepChangesResponse>('/services/with-step-change', {
      params: {
        pageSize,
        currentPage,
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

