import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface EditAsoFieldBody {
  value: string
}

interface EditAsoFieldParams {
  body: EditAsoFieldBody
  asoId: string
  fieldName: string
}

export async function editAsoField({
  body,
  asoId,
  fieldName
}: EditAsoFieldParams) {
  try {
    await api.patch(`asos/${asoId}/field/${fieldName}`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
