import { useMutation } from 'react-query'
import { sendFileToAssociatePdfToServiceReqFunction, sendPdfFilesReqFunction } from '../../utils/RequestFunctions/Pdf/requestPdfFunctions'
import { useSwal } from '../swal/useSwal'

export const usePdfFunctions = () => {
  const { successMessage, errorMessage } = useSwal()

  const sendPdfFiles = useMutation(
    'sendPdfFiles',
    (input: FormData) => sendPdfFilesReqFunction(input),
    {
      onSuccess: () => {
        successMessage('Arquivo(s) enviado com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  const sendFileToAssociatePdfToService = useMutation(
    'associatePdfToService',
    (input: FormData) => sendFileToAssociatePdfToServiceReqFunction(input),
    {
      onSuccess: () => {
        successMessage('Arquivo(s) enviado com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  return {
    sendPdfFiles,
    sendFileToAssociatePdfToService
  }
}
