import { Flex, Box, Heading, Button, Icon } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { DriverTable } from '../../components/Tables/Driver/DriverTable'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useDriver } from '../../hooks/driver/useDriver'

export function DriverList() {
  const [isLoading, setIsLoading] = useState(true)

  const {
    drivers: { data: drivers, isLoading: isDriverLoading, refetch },
  } = useDriver(null, true, false)

  const { userLogged } = useContext(AuthContext)

  const permissions = userLogged?.permissions

  useEffect(() => {
    const setLoadingState = () => {
      if (!isDriverLoading) setIsLoading(false)
    }
    setLoadingState()
  }, [isDriverLoading])

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontFamily="poppins">
              Lista de Motoristas
            </Heading>
            {permissions !== undefined && (
              <Link to="/motorista/adicionar">
                {userLogged?.permissions.includes('add-driver') && (
                  <Button
                    colorScheme="gray"
                    rightIcon={<Icon as={FiPlus} />}
                    size="sm"
                  >
                    Adicionar Motorista
                  </Button>
                )}
              </Link>
            )}
          </Flex>
          {!!drivers && <DriverTable drivers={drivers} refetch={refetch} />}
        </Box>
      </Flex>

  )
}
