import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text } from "@chakra-ui/react";
import { TextArea } from "components/Inputs/TextInput";
import { useMutation, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useSwal } from "hooks/swal/useSwal";
import { GetPurchaseOrdersResponse } from "api/purchaseOrders/getPurchaseOrders";
import { refusePurchaseOrder } from "api/purchaseOrders/refusePurchaseOrder";

interface RefusePurchaseOrderModalInputs {
  refuseDescription: string
}

interface RefusePurchaseOrderModalProps {
  isOpen: boolean
  onClose: () => void
  purchaseOrderId: string
}

const refuseModalSchema = yup.object().shape({
  refuseDescription: yup.string().required('Campo obrigatório'),
})

export function RefusePurchaseOrderModal({
  isOpen,
  onClose,
  purchaseOrderId,
}: RefusePurchaseOrderModalProps) {

  const history = useHistory()
  const queryClient = useQueryClient()
  const { standardMessage, confirmMessage } = useSwal()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting }
  } = useForm<RefusePurchaseOrderModalInputs>({
    resolver: yupResolver(refuseModalSchema)
  })

  const { mutateAsync: refusePurchaseOrderFn } = useMutation({
    mutationFn: refusePurchaseOrder,
    onSuccess(_data, { input, purchaseOrderId }) {
      const cachedPurchaseOrders = queryClient.getQueriesData<GetPurchaseOrdersResponse>({
        queryKey: ['purchaseOrders']
      })
      queryClient.invalidateQueries(['purchaseOrders'])
      cachedPurchaseOrders.forEach(([cachedKey, cachedData]) => {
        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          purchaseOrders: cachedData.purchaseOrders.map((purchaseOrder) => {
            if (purchaseOrder.id === purchaseOrderId) {
              return {
                ...purchaseOrder,
              }
            }

            return purchaseOrder
          })
        })
      })
    }
  })

  async function handleRefusePurchaseOrder(
    values: RefusePurchaseOrderModalInputs
  ) {
    const hasRefuseInPurchaseOrder = await confirmMessage({ title: "Deseja recusar o perdido de compre?" })

    if (hasRefuseInPurchaseOrder) {

      await refusePurchaseOrderFn({ purchaseOrderId: purchaseOrderId, input: values }, {
        onSuccess: async () => {
          reset()
          history.replace({ state: undefined })
          await queryClient.invalidateQueries(['purchaseOrdersAwaitingQuote'])
          onClose()
        }
      })
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  function handleCloseModal() {
    history.replace({ state: undefined })
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent as="form" noValidate onSubmit={handleSubmit(handleRefusePurchaseOrder)}>
        <ModalHeader>
          <Text>Recusar Pedido de compra</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody
          as={Flex}
          direction="column"
          gap={4}
        >
          <Stack
            direction={["column", "column", "row"]}
            spacing="4"
          >
            <TextArea
              {...register("refuseDescription")}
              name="refuseDescription"
              size="sm"
              label="Motivo da Recusa de Compra"
              error={errors.refuseDescription}
            />
          </Stack>
          <ModalFooter>
            <Flex gap={2}>
              <Button variant='ghost' onClick={handleCloseModal}>
                Fechar
              </Button>
              <Button isLoading={isSubmitting} type="submit" colorScheme='blue'>
                Enviar
              </Button>
            </Flex>

          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
