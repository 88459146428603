import { Regional } from "api/regionals/_types/Regional";
import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Aso, AsoStatus } from "./aso";

interface GetAsosParams {
  pageSize: string
  currentPage: string
  status?: AsoStatus | 'all' | null
  collaborator?: string | null
}

export interface GetAsosResponse {
  asos: Array<Aso & { regional: Regional | null }>
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
    count: number
  }
}

export async function getAsos({
  pageSize,
  currentPage,
  status,
  collaborator,
}: GetAsosParams) {
  try {
    const response = await api.get<GetAsosResponse>('/asos', {
      params: {
        pageSize,
        currentPage,
        status,
        collaborator,
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
