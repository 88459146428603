import * as yup from 'yup'

const citySchema = yup.object().shape({
  name: yup.string().required('Campo Obrigatório'),
  situation: yup.string().required('Campo Obrigatório'),
  state: yup.string().required('Campo Obrigatório'),
  hub_id: yup.string().required('Campo Obrigatório'),
  schedule_deadline: yup.string().required('Campo Obrigatório'),
  opening_deadline: yup.number().typeError('Campo Obrigatório').required('Campo Obrigatório'),
  start_attendance_block: yup.string().required('Campo Obrigatório'),
  final_attendance_block: yup.string().required('Campo Obrigatório'),
  has_saturday_attendance: yup.string().required('Campo Obrigatório'),
  saturday_start_attendance_block: yup.string().when("has_saturday_attendance", {
    is: "SIM",
    then: yup.string().required('Campo Obrigatório'),
  }),
  saturday_final_attendance_block: yup.string().when("has_saturday_attendance", {
    is: "SIM",
    then: yup.string().required('Campo Obrigatório'),
  }),
  has_sunday_attendance: yup.string().required('Campo Obrigatório'),
  sunday_start_attendance_block: yup.string().when("has_sunday_attendance", {
    is: "SIM",
    then: yup.string().required('Campo Obrigatório'),
  }),
  sunday_final_attendance_block: yup.string().when("has_sunday_attendance", {
    is: "SIM",
    then: yup.string().required('Campo Obrigatório'),
  }),
  days_service: yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
      case 'string':
        return yup.string().nullable().required('Campo Obrigatório')
      default:
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
    }
  }),
  observation: yup.string(),
})

export { citySchema }
