import {
  getAllPermissions,
  getOnePermission,
} from '../../services/getFunctions/permission/getPermission'
import { useQuery } from 'react-query'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'

export function usePermission(
  permissionId: string | null,
  isEnable?: boolean,
  refetchOnFocus?: boolean,
) {
  const { handleLogout, userLogged } = useContext(AuthContext)

  const permissions = useQuery('permissions', getAllPermissions, {
    enabled: !!isEnable && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (error: any) => {
      toast.error('Erro ao consultar informações! Realize o login novamente.')
      handleLogout()
    },
  })
  const permission = useQuery(
    ['permission', permissionId],
    () => getOnePermission(permissionId !== null ? permissionId : ''),
    {
      enabled: !!permissionId && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )

  return {
    permissions,
    permission,
  }
}
