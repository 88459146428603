import { Button, Flex, IconButton, Input, Select, useMediaQuery } from "@chakra-ui/react";
import { VaccineStatus } from "api/vaccines/vaccine";
import { useSearchParams } from "hooks/useSearchParams";
import { CSVLink } from "react-csv";
import { useForm } from "react-hook-form";
import { FaFileExport, FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface VaccinesReportFiltersSchema {
  collaborator: string
  status: VaccineStatus | 'all'
}


interface VaccinesReportFiltersProps {
  csvProps: {
    headers: {
      label: string
      key: string
    }[]
    data: unknown[]
    filename: string
  }
}

export function VaccinesReportFilters({
  csvProps
}: VaccinesReportFiltersProps) {
  const searchParams = useSearchParams()
  const [isLargerThen768, isSmallerThen1200] = useMediaQuery(['(min-width: 768px)', '(max-width: 1200px)'])

  const { replace } = useHistory()

  const status = searchParams.get('status')
  const collaborator = searchParams.get('collaborator')

  const {
    register,
    handleSubmit,
  } = useForm<VaccinesReportFiltersSchema>({
    defaultValues: {
      collaborator,
      status: status as VaccineStatus | 'all'
    }
  })

  function handleSearchVaccinesReport(values: VaccinesReportFiltersSchema) {

    if (values.collaborator) {
      searchParams.set('collaborator', values.collaborator)
    } else {
      searchParams.delete('collaborator')
    }

    if (values.status) {
      searchParams.set('status', values.status)
    } else {
      searchParams.delete('status')
    }

    replace({ search: searchParams.toString() })

  }

  return (
    <Flex
      justify={{
        base:
          'center',
        md:
          'space-between'
      }}
      direction={{
        base: 'column',
        md: 'row'
      }}
      align="center"
      mt="6"
      as="form"
      gap="2"
      w='full'
      onSubmit={handleSubmit(handleSearchVaccinesReport)}
    >
      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>

        <Input
          {...register("collaborator")}
          name="collaborator"
          placeholder="Ex: John Doe"
          size="sm"
          rounded="md"
          w={{ base: 'full', md: '250px' }}
        />


        <Select
          {...register("status")}
          name="status"
          size="sm"
          rounded="md"
          placeholder="Selecione uma opção..."
          w={["full", "full", "200px"]}
        >
          <option value="all">Todos status</option>
          <option value="pending">Pendente</option>
          <option value="active">Ativo</option>
          <option value="inactive">Inativo</option>
        </Select>
      </Flex>

      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        {isLargerThen768 && isSmallerThen1200 ? (
          <IconButton
            aria-label="Exportar"
            icon={<FaFileExport />}
            size="sm"
            type="button"
            colorScheme="green"
            as={CSVLink}
            separator={';'}
            {...csvProps}
            w={{ base: 'full', md: 'min' }}
          />
        ) : (
          <Button
            leftIcon={<FaFileExport />}
            size="sm"
            type="button"
            colorScheme="green"
            as={CSVLink}
            separator={';'}
            {...csvProps}
            w={{ base: 'full', md: 'min' }}
          >
            Exportar
          </Button>
        )}

        {isLargerThen768 && isSmallerThen1200 ? (
          <IconButton
            aria-label="Filtrar"
            icon={<FaSearch />}
            size="sm"
            type="submit"
            w={{ base: 'full', md: 'min' }}
          />
        ) : (
          <Button
            leftIcon={<FaSearch />}
            size="sm"
            type="submit"
            w={{ base: 'full', md: 'min' }}
          >
            Gerar relatório
          </Button>
        )}

      </Flex>
    </Flex>
  )
}
