import axios from "axios"
import { BranchProps } from "contexts/BranchContext"
import { api } from "services/api"
import { BoardServiceProps } from "services/getFunctions/getBoardServiceFunctions"
import { RequestError } from "utils/errors/RequestErrors"

export interface GetServiceBoardsResponse {
  boards: Array<BoardServiceProps & {
    branchIDBoard: BranchProps
  }
  >
}

interface GetServiceBoardsParams {
  protocol: string
}

export async function getServiceBoards({
  protocol
}: GetServiceBoardsParams) {
  try {
    const response = await api.get<GetServiceBoardsResponse>(`/services/protocol/${protocol}/boards`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
