import { Button, FormControl, FormLabel, IconButton, Input, Link, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, Table, Tbody, Td, Text, Textarea, Tr } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useReportBugsFunctions } from "hooks/reportBugs/useReportBugsFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { useForm, useWatch } from "react-hook-form";
import { RiExternalLinkFill } from "react-icons/ri";
import { validateHasFile } from "utils/fileValidation";
import * as yup from "yup"

interface CreateBugSchema {
  subject: string
  description: string
  attachments: FileList
}

const createBugSchema = yup.object({
  subject: yup.string().required(),
  description: yup.string().required(),
  attachments: yup.mixed().test(value => validateHasFile(value))
})

export function CreateBug() {
  const { createReportBug } = useReportBugsFunctions()

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CreateBugSchema>({
    resolver: yupResolver(createBugSchema)
  })

  const attachments = useWatch({ control, name: 'attachments' })

  const attachmentUrl = attachments
    ? URL.createObjectURL(attachments[0])
    : null

  const { promiseMessage } = useToastify()

  async function handleReportBug(values: CreateBugSchema) {
    const formData = new FormData()

    formData.append('subject', values.subject)
    formData.append('description', values.description)

    if (values.attachments.length !== 0) {
      formData.append("attachments", values.attachments[0])
    }

    await promiseMessage(createReportBug.mutateAsync({
      input: formData
    }, {
      onSuccess: () => reset()
    }), 'Bug reportado com sucesso! ✔️')
  }

  return (
    <PopoverContent zIndex="tooltip" minW="400px">
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverHeader letterSpacing="tight" fontWeight="bold" border="0">Reportar Bug</PopoverHeader>
      <PopoverBody
        as="form"
        display="flex"
        flexDirection="column"
        gap="3"
        onSubmit={handleSubmit(handleReportBug)}
      >
        <FormControl isInvalid={!!errors?.subject}>
          <FormLabel>
            Assunto
            <Text color="red" as="sup">*</Text>
          </FormLabel>
          <Input {...register('subject')} size="sm" rounded="lg" />
        </FormControl>

        <FormControl isInvalid={!!errors?.subject}>
          <FormLabel>
            Descrição
            <Text color="red" as="sup">*</Text>
          </FormLabel>
          <Textarea {...register('description')} size="sm" rounded="lg" />
        </FormControl>

        <FormControl isInvalid={!!errors?.attachments}>
          <Button border={!!errors?.attachments ? '2px solid red' : 'none'} w="full" cursor="pointer" as={FormLabel} htmlFor="attachments">
            Anexar
          </Button>
          <Input
            {...register('attachments')}
            id="attachments"
            hidden
            type="file"
            size="sm"
            rounded="lg"
          />
        </FormControl>

        {attachmentUrl && (
          <Table size="sm" w="full">
            <Tbody>
              <Tr>
                <Td>{attachments[0].name}</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={attachmentUrl}
                    icon={<RiExternalLinkFill />}
                    size="sm"
                    isExternal
                  />
                </Td>
              </Tr>
            </Tbody>
          </Table>
        )}

        <Button isDisabled={isSubmitting} w="min" alignSelf="flex-end" size="sm"colorScheme="blue" rounded="lg" type="submit">Reportar</Button>
      </PopoverBody>
    </PopoverContent>
  )
}
