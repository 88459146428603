import { differenceInMinutes, set } from "date-fns"

export function comparePlannedRouteDepartureHourIsLessThanSourceBranchCloseHour(
  departureHour: string,
  branchCloseHour: string,
) {
  const [departureHours, departureMinutes] = departureHour.split(":").map(time => Number(time))
  const [branchCloseHours, branchOpenMinutes] = branchCloseHour.split(":").map(time => Number(time))

  const difference = differenceInMinutes(
    set(new Date(), { hours: departureHours, minutes: departureMinutes }),
    set(new Date(), { hours: branchCloseHours, minutes: branchOpenMinutes }),
  )

  if (difference < 0) return true

  return false
}
