import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Button,
  Stack,
  Icon,
  Image,
  Alert,
  AlertIcon,
  Link as ChakraLink,
  useMediaQuery,
  FormLabel,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useContext, useState, useRef, useMemo, useCallback } from 'react'
import { useHistory, useParams, useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { FiExternalLink, FiPlus } from 'react-icons/fi'

import { handleChangeUrl } from '../../../utils/handleChangeUrl'
import { negative_positive } from '../../../utils/customLists'
import { format, isAfter, isSameDay, set } from 'date-fns'

import {
  DeliveryServiceContext,
  DeliveryServiceProps,
} from '../../../contexts/DeliveryServiceContext'
import { ServiceProps } from '../../../contexts/ServiceContext'

import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { TextArea } from '../../../components/Inputs/TextInput'
import { Select } from '../../../components/Inputs/SelectInput'
import { Input } from '../../../components/Inputs/Input'

import DeliveryAccordion from '../../../components/Accordions/Service/DeliveryAccordion/DeliveryAccordion'


import { useService } from '../../../hooks/services/service'
import { useDriver } from '../../../hooks/driver/useDriver'
import { FaFile, FaList, FaPlus, FaSave, FaTimes } from 'react-icons/fa'
import { LandingServiceProps } from '../../../services/getFunctions/getLandingServiceFunctions'
import { useDeliveryServiceFunctions } from '../../../hooks/services/delivery/useDeliveryServiceFunctions'
import { useSwal } from '../../../hooks/swal/useSwal'
import { useAuth } from '../../../hooks/auth/useAuth'
import { useBranch } from '../../../hooks/branch/useBranch'
import { useAddress } from 'hooks/address/useAddress'
import { Address } from 'hooks/address/dtos/Address'
import { validateHasFile } from 'utils/fileValidation'
import { ExternalComunications } from 'components/ExternalComunications'
import { formatDate } from 'utils/DateFunctions/formatDate'
import { validateHaveDuplicateFiles } from 'utils/validateHaveDuplicateFiles'
import { Empty } from 'components/Empty'
import { useGeolocation } from 'hooks/useGeolocation'
import { toast } from 'react-toastify'

const supportedFileFormats = [
  "image/jpeg",
  "image/pjpeg",
  "image/jpg",
  "image/png",
  "text/plain",
  "application/pdf",
]

interface IDestinationAddressesInputProps {
  address_id: string
  quantity: number
}

interface Attachments {
  attachment: FileList
}


interface FormInputProps {
  address_id: string
  service_id: string
  driver_id: string
  destination_addresses_input: number
  arrival_latitude: string
  arrival_longitude: string
  arrival_timestamp: string
  responsible_name: string | null
  responsible_lastname: string | null
  landing_volume: number
  current_delivery_volume: number
  delivery_volume: number | null
  box_photo: any | null
  departure_latitude: string | null
  departure_longitude: string | null
  departure_timestamp: string | null
  unsuccess_latitude: string | null
  unsuccess_longitude: string | null
  box_photo_change: string | null
  content_photo_change: string | null
  unsuccess_times: string | null
  real_arrival_date: string
  real_arrival_hour: string
  observation: string | null
  content_declaration_attachments: Attachments[] | null
}

interface DriverProps {
  id: string
  situation: string
  collector_id: string
  collectorIDDriver: {
    company_name: string
  }
  firstname: string
  lastname: string
  cpf: string
  email: string
  observation: string
}

interface QueryParams {
  service_id: string
  address_id: string
  slug: string
}

interface DriverFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
}

interface ValidateDateInputs {
  real_arrival_date: string,
  real_arrival_hour: string,
  collectDate: Date
}

function validateDate({
  real_arrival_date,
  real_arrival_hour,
  collectDate
}: ValidateDateInputs): boolean {
  const [year, month, date] = real_arrival_date.split('-')?.map(value => Number(value))
  const [hours, minutes] = real_arrival_hour.split(':')?.map(value => Number(value))

  const realArrivalTimestamp = set(new Date(), {
    date,
    month: month - 1,
    year,
    hours,
    minutes
  })

  const currentDate = new Date();
  const isRealArrivalDateSameNow = isSameDay(realArrivalTimestamp, currentDate)

  if (!isSameDay(realArrivalTimestamp, collectDate) && isAfter(collectDate, realArrivalTimestamp)) {
    throw new yup.ValidationError(
      'A nova data real de entrega não pode ser menor que a data esperada para coleta',
      real_arrival_date,
      'real_arrival_date'
    );
  }

  if (!isRealArrivalDateSameNow && isAfter(realArrivalTimestamp, currentDate)) {
    throw new yup.ValidationError(
      'A nova data real de entrega não pode ser maior que a data atual',
      real_arrival_date,
      'real_arrival_date'
    );
  }

  if (isRealArrivalDateSameNow && isAfter(realArrivalTimestamp, currentDate)) {
    throw new yup.ValidationError(
      'O novo horário real de entrega não pode ser maior que a data atual',
      real_arrival_hour,
      'real_arrival_hour'
    );
  }
  return true;
}

const deliverySchema = yup.object().shape({
  driver_id: yup.string().required('Motorista inválido'),
  delivery_volume: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
})

const deliveryCurrentSchema = yup.object().shape({
  driver_id: yup.string().required('Motorista inválido'),
  responsible_name: yup.string().required('Campo Obrigatório!'),
  responsible_lastname: yup.string().required('Campo Obrigatório!'),
  delivery_volume: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  box_photo: yup.mixed().test("duplicateFiles", "Não é permitido envio de arquivos iguais", async function (value) {
    const filesToCheck = [];

    if (value !== undefined && value instanceof File) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(value);
      const boxPhoto: FileList = dataTransfer.files;

      filesToCheck.push(Object.assign({}, { attachment: boxPhoto }));

    }

    if (this.parent.content_declaration_attachments !== undefined) {
      filesToCheck.push(Object.assign({}, { attachment: this.parent.content_declaration_attachments[0].attachment }));
    }
    return !validateHaveDuplicateFiles(...filesToCheck)
  }).required('Foto Obrigatória!'),
  box_photo_change: yup.string().required('Campo Obrigatório!'),
  content_photo_change: yup.string().required('Campo Obrigatório!'),
  content_declaration_attachments: yup.mixed().when('$isEditPage', {
    is: true,
    then: yup.mixed().when('content_photo_change', {
      is: 'SIM',
      then: yup.array().min(1, 'Campo obrigatório').of(yup.object({
        attachment: yup.mixed().test('hasFile', 'Campo obrigatório.', value => validateHasFile(value))
      })).required('Campo Obrigatório'),
    }),
    otherwise: yup.array().min(1, 'Campo obrigatório').of(yup.object({
      attachment: yup.mixed().test('hasFile', 'Campo obrigatório.', value => validateHasFile(value))
    })).test("duplicateFiles", "Não é permitido envio de arquivos iguais", async function (value) {

      const filesToCheck = [];

      if (value !== undefined) {
        filesToCheck.push(...value)
      }

      if (this.parent.box_photo !== undefined) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(this.parent.box_photo);
        const boxPhoto: FileList = dataTransfer.files;

        filesToCheck.push(Object.assign({}, { attachment: boxPhoto }));
      }

      return !validateHaveDuplicateFiles(...filesToCheck)
    }).required('Campo Obrigatório'),
  }),
  real_arrival_date: yup.mixed().when('$isEditPage', {
    is: true,
    then: yup.mixed().when('$isEditRealArrivalTime', {
      is: true,
      then: yup.string().required('Campo obrigatório')
        .test('real_arrival_date', 'A nova data de coleta não pode ser menor que a data de entrega', async function (value) {
          return validateDate({
            real_arrival_date: value,
            real_arrival_hour: this.parent.real_arrival_hour,
            collectDate: new Date(this.options.context.collectDate)
          });
        }),
    }),
    otherwise: yup.string().required('Campo obrigatório')
      .test('real_arrival_date', 'A nova data de coleta não pode ser menor que a data de entrega', async function (value) {
        return validateDate({
          real_arrival_date: value,
          real_arrival_hour: this.parent.real_arrival_hour,
          collectDate: new Date(this.options.context.collectDate)
        });
      }),
  }),
  real_arrival_hour: yup.mixed().when('$isEditPage', {
    is: true,
    then: yup.mixed().when('$isEditRealArrivalTime', {
      is: true,
      then: yup.string().required('Campo Obrigatório')
        .test('real_arrival_hour', 'O horário não pode ser maior que o horário atual', function (value) {
          return validateDate({
            real_arrival_hour: value,
            real_arrival_date: this.parent.real_arrival_date,
            collectDate: new Date(this.options.context.collectDate)
          });
        })
    }),
    otherwise: yup.string().required('Campo Obrigatório')
      .test('real_arrival_hour', 'O horário não pode ser maior que o horário atual', function (value) {
        return validateDate({
          real_arrival_hour: value,
          real_arrival_date: this.parent.real_arrival_date,
          collectDate: new Date(this.options.context.collectDate)
        });
      })
  }),
  observation: yup.string(),
})

async function alertGeolocationError(error: GeolocationPositionError) {
  if (error.code === 1) {
    toast.error("Para iniciar/finalizar a entrega, é necessário que a localização esteja habilitada!")
  }
  if (error.code === 2) {
    toast.error("Localização não está disponível! Tente recarregar a página ou verifique as configurações do dispositivo.")
  }
}


export function DeliveryServiceAddressSelected({
  id,
  isDisabled = false,
  href,
  title,
  action,
}: DriverFormProps) {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  const { service_id, address_id, slug }: QueryParams = useParams()

  const [sourceAddresses, setSourceAddresses] = useState<Address[]>([])
  const [addressCollected, setAddressCollected] = useState<string[]>([])
  const [sourceDrivers, setSourceDrivers] = useState<DriverProps[]>([])
  const [stepOfService, setStepOfService] = useState('')

  const [selectedFileBox, setSelectedFileBox] = useState<any>()


  const [serviceFilteredById, setServiceFilteredById] = useState<
    ServiceProps[]
  >([])
  const [deliveryServiceFilteredById, setDeliveryServiceFilteredById] =
    useState<DeliveryServiceProps[]>([])
  const [landingServiceFilteredById, setLandingServiceFilteredById] = useState<
    LandingServiceProps[]
  >([])

  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const [previewBox, setPreviewBox] = useState<string>()

  const { deleteDeliveryService } = useContext(DeliveryServiceContext)
  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useAuth()
  const { position, error: geolocationError } = useGeolocation()

  const {
    createDeliveryService: { mutateAsync: createDeliveryService },
    finishDeliveryService: { mutateAsync: finishDeliveryService },
    updateDeliveryService: { mutateAsync: updateDeliveryService },
  } = useDeliveryServiceFunctions()

  const {
    service: { data: serviceById, isLoading: isServiceLoading },
  } = useService(service_id, false, false)
  const { data: address, isLoading: isAddressLoading } = useAddress(address_id)
  const {
    drivers: { data: drivers, isLoading: isDriverLoading },
  } = useDriver(null, true, false)
  const {
    branches: { data: branches, isLoading: isBranchesLoading },
  } = useBranch(null, true, false)

  const destinationAddressesInput = useMemo<
    IDestinationAddressesInputProps[] | null
  >(() => {
    if (serviceById) {
      const destinationAddressesInputFormated = serviceById.serviceIDRequested
        .destination_addresses_input
        ? serviceById.serviceIDRequested.destination_addresses_input
          .map<IDestinationAddressesInputProps>((address) =>
            JSON.parse(address as string),
          )
          .filter((address) => address.address_id === address_id)
        : null

      return destinationAddressesInputFormated
    }
    return null
  }, [serviceById, address_id])



  useEffect(() => {
    function run() {
      if (serviceById !== undefined) {
        const serviceFiltered = []
        serviceFiltered.push(serviceById)
        setServiceFilteredById(serviceFiltered)

        if (serviceById.serviceIDDelivery !== null) {
          setDeliveryServiceFilteredById(serviceById.serviceIDDelivery)
        }
        if (serviceById.serviceIDLanding !== null) {
          setLandingServiceFilteredById(serviceById.serviceIDLanding)
        }
      }
    }
    run()
  }, [serviceById])

  useEffect(() => {
    if (address) {
      const sourceAddress = []
      sourceAddress.push(address)
      setSourceAddresses(sourceAddress)
    }
  }, [address])

  const history = useHistory()
  const location = useLocation()
  const state = location.state as { path?: string }
  const hasPathRedirect = state?.path !== undefined && state?.path !== null

  const deliveryAlreadyStarted = serviceById?.serviceIDDelivery?.find(delivery => {
    return delivery.address_id === address_id
  })


  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    resolver: !addressCollected.includes(address_id)
      ? yupResolver(deliverySchema)
      : yupResolver(deliveryCurrentSchema),
    context: {
      isEditPage: !!slug,
      collectDate: serviceById?.serviceIDRequested.collect_date,
      isEditRealArrivalTime: deliveryAlreadyStarted?.real_arrival_timestamp && deliveryAlreadyStarted?.real_arrival_timestamp_validated
    }
  })

  const {
    fields: contentDeclarationAttachmentsFields,
    append: appendContentDeclarationAttachmentField,
    remove: removeContentDeclarationAttachmentField
  } = useFieldArray({
    control,
    name: 'content_declaration_attachments'
  })

  const contentDeclarationAttachments = useWatch({
    control,
    name: 'content_declaration_attachments'
  })

  function handleRemoveContentDeclarationAttachmentField(index: number) {
    removeContentDeclarationAttachmentField(index)
  }

  const handleAppendContentDeclarationAttachmentField = useCallback(() => {
    appendContentDeclarationAttachmentField({ attachment: undefined });
  }, [appendContentDeclarationAttachmentField]);

  useEffect(() => {
    handleAppendContentDeclarationAttachmentField();
  }, [handleAppendContentDeclarationAttachmentField]);

  useEffect(() => {
    if (serviceById && sourceDrivers?.length) {
      const deliveryAlreadyStarted = serviceById.serviceIDDelivery?.find(delivery => {
        return delivery.address_id === address_id
      })

      if (!deliveryAlreadyStarted && userLogged?.user_type === 'MOTORISTA') {
        const deliveryDriverAssignedInfo = serviceById.deliveryDriversAssigned.find(deliveryDriverAssigned => {
          return deliveryDriverAssigned.address_id === address_id && userLogged?.driver_id === deliveryDriverAssigned.driver_id
        })

        if (deliveryDriverAssignedInfo) {
          setValue('driver_id', deliveryDriverAssignedInfo.driver_id)
          if (serviceById.serviceIDRequested.service_type === 'DEDICADO') {
            setValue('delivery_volume', deliveryDriverAssignedInfo.volume)
          }
        }
      }
    }
  }, [serviceById, setValue, address_id, sourceDrivers, userLogged])

  const serviceType = serviceFilteredById
    .map((service) => service.serviceIDRequested.service_type)
    .toString()
  const isDriverSelected = watch('driver_id')
  const changeBoxPhoto = watch('box_photo_change')

  useEffect(() => {
    let landingVolume: number = 0;

    if (serviceType === "FRACIONADO") {
      const mostRecentlyLandingService = landingServiceFilteredById?.reduce(
        (acc, curr) => acc.arrival_timestamp > curr.arrival_timestamp
          ? acc
          : curr,
        {} as LandingServiceProps
        // landingServiceFilteredById[0]
      )

      landingVolume = mostRecentlyLandingService?.landing_volume
    }

    setValue('landing_volume', landingVolume)
  }, [setValue, serviceType, landingServiceFilteredById])

  useEffect(() => {
    if (
      !isServiceLoading &&
      !isAddressLoading &&
      !isDriverLoading &&
      !isBranchesLoading
    ) {
      setIsLoadingPage(false)
    }
  }, [isServiceLoading, isAddressLoading, isDriverLoading, isBranchesLoading])

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (!permissions?.includes('add-delivery-service')) {
            history.push('/')
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [slug, history, userLogged])

  useEffect(() => {
    if (!selectedFileBox) {
      setPreviewBox(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFileBox)
    setPreviewBox(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFileBox])



  useEffect(() => {
    function run() {
      const addressId = deliveryServiceFilteredById.map(
        (service) => service.address_id,
      )
      setAddressCollected(addressId)

      if (addressId.includes(address_id) && !slug && !!drivers) {
        const filteredDeliveryServiceByAddressId =
          deliveryServiceFilteredById.filter(
            (service) => service.address_id === address_id,
          )
        filteredDeliveryServiceByAddressId.map((deliveryService) => {
          setValue('address_id', address_id)
          setValue('service_id', service_id)
          if (sourceDrivers.length > 0) {
            setValue('driver_id', deliveryService.driver_id)
          }
          setValue(
            'departure_timestamp',
            format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
          )
          if (geolocationError) {
            return alertGeolocationError(geolocationError)
          }
          if (position) {
            setValue('departure_latitude', position?.coords.latitude.toString())
            setValue(
              'departure_longitude',
              position?.coords.longitude.toString(),
            )
          }

          return (
            setValue('arrival_latitude', deliveryService.arrival_latitude),
            setValue('arrival_longitude', deliveryService.arrival_longitude),
            setValue('arrival_timestamp', deliveryService.arrival_timestamp),
            setValue(
              "real_arrival_date",
              deliveryService.arrival_timestamp
                ? formatDate.handle(
                  deliveryService.arrival_timestamp,
                  'DateWithoutHourToInput',
                )
                : '',
            ), setValue(
              'real_arrival_hour',
              deliveryService.arrival_timestamp
                ? formatDate.handle(
                  deliveryService.arrival_timestamp,
                  'DateOnlyWithHourMinute',
                )
                : '',
            )
          )
        })
      }

      if (!addressId.includes(address_id) && !slug) {
        setValue(
          'arrival_timestamp',
          format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        )
        setValue('address_id', address_id)
        setValue('service_id', service_id)
        if (geolocationError) {
          return alertGeolocationError(geolocationError)
        }
        if (position) {
          setValue('arrival_latitude', position?.coords.latitude.toString())
          setValue('arrival_longitude', position?.coords.longitude.toString())
        }

      }
    }

    run()
  }, [
    setValue,
    drivers,
    slug,
    service_id,
    deliveryServiceFilteredById,
    sourceDrivers,
    address_id,
    position,
    geolocationError
  ])

  useEffect(() => {
    function run() {
      if (drivers) {
        const stepOfServiceSelected = serviceFilteredById
          .map((service) => service.step)
          .toString()
        const destinationCollectorId = serviceFilteredById
          .map((service) => service.serviceIDRequested.destination_collector_id)
          .toString()

        if (userLogged !== null) {
          if (userLogged.user_type === 'MOTORISTA') {
            const driverSelected = drivers
              .filter(
                (driver) => driver.collector_id === destinationCollectorId,
              )
              .filter((driver) => driver.id === userLogged.driver_id)
            setSourceDrivers(driverSelected)
          } else {
            const driverSelected = drivers.filter(
              (driver) => driver.collector_id === destinationCollectorId,
            )
            setSourceDrivers(driverSelected)
          }
        }
        setStepOfService(stepOfServiceSelected)
      }
    }

    run()
  }, [setValue, serviceFilteredById, userLogged, drivers])

  useEffect(() => {
    function run() {
      if (slug) {
        deliveryServiceFilteredById
          .filter((delService) => delService.address_id === address_id)
          .map((delService) => {
            const completeNameSplited = delService.responsible_name.split(' ')
            const responsibleFirstname = completeNameSplited[0]
            const responsibleLastname = completeNameSplited.slice(1).join(' ')


            return (
              setValue('address_id', delService.address_id),
              setValue('service_id', delService.service_id),
              setValue('driver_id', delService.driver_id),
              setValue('arrival_latitude', delService.arrival_latitude),
              setValue('arrival_longitude', delService.arrival_longitude),
              setValue('arrival_timestamp', delService.arrival_timestamp),
              setValue('responsible_name', responsibleFirstname),
              setValue('responsible_lastname', responsibleLastname),
              setValue('delivery_volume', delService.delivery_volume),
              setValue('box_photo', delService.box_photo),
              setValue('departure_latitude', delService.departure_latitude),
              setValue('departure_longitude', delService.departure_longitude),
              setValue('departure_timestamp', delService.departure_timestamp),
              setValue('observation', delService.observation),
              setValue(
                "real_arrival_date",
                delService.real_arrival_timestamp
                  ? formatDate.handle(
                    delService.real_arrival_timestamp,
                    'DateWithoutHourToInput',
                  )
                  : '',
              ), setValue(
                'real_arrival_hour',
                delService.real_arrival_timestamp
                  ? formatDate.handle(
                    delService.real_arrival_timestamp,
                    'DateOnlyWithHourMinute',
                  )
                  : '',
              )
            )
          })
      } else {
        deliveryServiceFilteredById
          .filter((delService) => delService.address_id === address_id)
          .map((delService) => {
            return setValue('delivery_volume', delService.delivery_volume)
          })
        setValue('box_photo_change', ' ')
        setValue('content_photo_change', ' ')
      }
    }

    run()
  }, [setValue, slug, deliveryServiceFilteredById, address_id])

  function handleSelectFileBox(e: { target: HTMLInputElement }) {
    if (!e.target.files || e.target.files.length === 0) {
      setValue('box_photo', null)
      setSelectedFileBox(undefined)
      return
    }

    // I've kept this example simple by using the first image instead of multiple
    setValue('box_photo', e.target.files[0])
    setSelectedFileBox(e.target.files[0])
  }


  async function handleCreateDeliveryService(values: FormInputProps) {
    if (geolocationError) {
      return alertGeolocationError(geolocationError)
    }
    const deliveryServiceVolumes =
      serviceById?.serviceIDDelivery !== null &&
        serviceById?.serviceIDDelivery.length! > 0
        ? serviceById?.serviceIDDelivery
          .map((delivery) => delivery.delivery_volume)
          .reduce((a, b) => a! + b!)! + Number(values.delivery_volume)
        : 0 + Number(values.delivery_volume)

    if (serviceType === 'FRACIONADO') {
      if (Number(deliveryServiceVolumes) > Number(values.landing_volume)) {
        return standardMessage(
          'A somatória dos volumes de entrega excedem o volume desembarcado!',
        )
      }
    }

    const hasCreateDeliveryService = await confirmMessage({
      title: 'Deseja iniciar uma entrega',
    })

    if (hasCreateDeliveryService) {
      setIsLoading(true)
      try {
        const createDeliveryServiceResponse = await createDeliveryService(
          values,
        )
        if (createDeliveryServiceResponse && userLogged) {
          setIsLoading(false)
        }
      } catch {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const handleFinishDeliveryService = async (values: FormInputProps) => {
    if (geolocationError) {
      return alertGeolocationError(geolocationError)
    }
    const hasFinishDeliveryService = await confirmMessage({
      title: 'Deseja finalizar a entrega?',
    })

    const [year, month, date] = values.real_arrival_date.split('-').map(Number)
    const [hours, minutes] = values.real_arrival_hour.split(':').map(Number)

    const realArrivalTimestamp = set(new Date(), {
      date,
      month: month - 1,
      year,
      hours,
      minutes
    })

    const formData = new FormData()
    formData.append('address_id', values.address_id)
    formData.append('service_id', values.service_id)
    formData.append('driver_id', values.driver_id)
    formData.append('arrival_latitude', values.arrival_latitude)
    formData.append('arrival_longitude', values.arrival_longitude)
    formData.append('arrival_timestamp', values.arrival_timestamp)
    formData.append('responsible_name', `${values.responsible_name} ${values.responsible_lastname}` as any)
    formData.append('delivery_volume', values.delivery_volume as any)
    formData.append('box_photo', values.box_photo as any)
    formData.append('departure_latitude', values.departure_latitude as any)
    formData.append('departure_longitude', values.departure_longitude as any)
    formData.append('departure_timestamp', values.departure_timestamp as any)
    formData.append('real_arrival_timestamp', String(realArrivalTimestamp))
    formData.append('observation', values.observation as any)

    Object.entries(values.content_declaration_attachments).forEach(([key, attachment]) => {
      formData.append('content_declaration_attachments', attachment?.attachment[0])
    })


    const serviceIdFiltered = deliveryServiceFilteredById
      .filter((deliveryService) => deliveryService.address_id === address_id)
      .map((deliveryService) => deliveryService.service_id)

    if (hasFinishDeliveryService) {
      setIsLoading(true)
      try {
        if (serviceIdFiltered.length > 0) {
          if (serviceIdFiltered.length > 1) {
            const deliveryServiceById = deliveryServiceFilteredById
              .filter(
                (deliveryService) => deliveryService.address_id === address_id,
              )
              .map((deliveryService) => deliveryService.id)
            const isAllDuplicatedServicesRemoved = await Promise.all(
              deliveryServiceById.map(async (id, index) => {
                if (index > 0) {
                  deleteDeliveryService(id)
                }
              }),
            )
            if (isAllDuplicatedServicesRemoved) {
              const finishDeliveryServiceReqObj = {
                serviceID: serviceIdFiltered[0],
                finishDeliveryServiceInput: formData as any,
              }
              const finishDeliveryServiceResponse = await finishDeliveryService(
                { ...finishDeliveryServiceReqObj },
              )
              if (finishDeliveryServiceResponse && userLogged) {
                setIsLoading(true)
                if (hasPathRedirect) {
                  history.push(state?.path)
                } else {
                  history.push(`/servicos/entregas/${service_id}/`)
                }
              }
            }
            return
          }
        }
        const finishDeliveryServiceReqObj = {
          serviceID: service_id,
          finishDeliveryServiceInput: formData as any,
        }
        const finishDeliveryServiceResponse = await finishDeliveryService({
          ...finishDeliveryServiceReqObj,
        })
        if (finishDeliveryServiceResponse && userLogged) {
          if (hasPathRedirect) {
            history.push(state?.path)
          } else {
            history.push(`/servicos/entregas/${service_id}/`)
          }
        }
      } catch {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const handleUpdateDeliveryService = async (values: FormInputProps) => {
    if (geolocationError) {
      return alertGeolocationError(geolocationError)
    }
    const formData = new FormData()

    const [year, month, date] = values.real_arrival_date.split('-').map(Number)
    const [hours, minutes] = values.real_arrival_hour.split(':').map(Number)

    const realArrivalTimestamp = set(new Date(), {
      date,
      month: month - 1,
      year,
      hours,
      minutes
    })

    formData.append('address_id', values.address_id)
    formData.append('service_id', values.service_id)
    formData.append('driver_id', values.driver_id)
    formData.append('responsible_name', `${values.responsible_name} ${values.responsible_lastname}` as any)
    formData.append('delivery_volume', values.delivery_volume as any)
    formData.append('box_photo', values.box_photo as any)
    formData.append('observation', values.observation as any)
    formData.append('real_arrival_timestamp', String(realArrivalTimestamp))

    if (values.box_photo_change === 'SIM') {
      formData.append('boxPhotoChoose', 'SIM')
    } else {
      setValue('box_photo', {})
    }
    if (values.content_photo_change === 'SIM') {
      formData.append('contentDeclarationChoose', 'SIM')

      Object.entries(values.content_declaration_attachments).forEach(([key, attachment]) => {
        formData.append('content_declaration_attachments', attachment?.attachment[0])
      })
    } else {
      setValue('content_declaration_attachments', [])
    }

    const hasUpdateDeliveryService = await confirmMessage({
      title: 'Deseja atualizar uma entrega?',
    })
    const deliveryServiceReqObj = {
      serviceID: service_id,
      updateDeliveryServiceInput: formData as any,
    }

    if (hasUpdateDeliveryService) {
      setIsLoading(true)
      try {
        const updateDeliveryServiceResponse = await updateDeliveryService({
          ...deliveryServiceReqObj,
        })
        if (updateDeliveryServiceResponse) {
          history.push(`/servico/entregas/andamento/${service_id}/`)
        }
      } catch {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const inputRefBox = useRef<HTMLInputElement | null>(null)
  const handleInputRefBox = () => inputRefBox.current?.click()

  const {
    isOpen: isExternalComunicationModalOpen,
    onOpen: onOpenExternalComunicationModal,
    onClose: onCloseExternalComunicationModal,
  } = useDisclosure()

  const destinationCollectorHasDriverAssign = serviceById?.serviceIDRequested?.destinationCollectorIDService?.has_driver_assign
  const hasAssignedDrivers = serviceById?.deliveryDriversAssigned?.length > 0

  if (isLoading || isLoadingPage) {
    return <GeneralContentLoading />
  }

  if (destinationCollectorHasDriverAssign && !hasAssignedDrivers && !slug) {
    return (
      <Empty.Root
        message='Aguarde até que a atribuição de motoristas dos endereços do serviço sejam finalizadas.'
      >
        <Empty.ActionButton onClick={history.goBack}>Voltar</Empty.ActionButton>
      </Empty.Root>
    )
  }

  if (!addressCollected.includes(address_id)) {
    return (
      <>
        {(userLogged?.user_type === 'COLETADOR' || userLogged?.user_type === 'MOTORISTA') && (
          <ExternalComunications.TriggerButton
            aria-label="help-me"
            onClick={onOpenExternalComunicationModal}
          />
        )}

        <ExternalComunications.Root
          isOpen={isExternalComunicationModalOpen}
          onClose={onCloseExternalComunicationModal}
        >
          <ExternalComunications.Content
            serviceId={service_id}
            onClose={onCloseExternalComunicationModal}
          />
        </ExternalComunications.Root>

        <Flex>
          <Box
            as="form"
            flex="1"
            borderRadius="8px"
            bg="white"
            p={['6', '8']}
            onSubmit={handleSubmit(handleCreateDeliveryService)}
            noValidate
          >
            <Heading size="lg" fontFamily="poppins">
              {title}
            </Heading>

            <Divider my="6" borderColor="gray.700" />

            {serviceById && branches && (
              <DeliveryAccordion
                serviceByID={serviceById}
                branches={branches}
              />
            )}

            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'column']}
            >
              {sourceAddresses.map((address) => (
                <Input
                  key={address.id}
                  defaultValue={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep}`.toUpperCase()}
                  isDisabled={true}
                  name="firstname"
                  label="Endereço Destinatário"
                />
              ))}

              <Select
                {...register('driver_id')}
                name="driver_id"
                label="Pertence ao Motorista"
                error={errors.driver_id}
                drivers={sourceDrivers}
                isDisabled={isDisabled || (userLogged?.user_type === 'MOTORISTA' && destinationCollectorHasDriverAssign)}
                placeholder="Selecione uma opção..."
                required
              />

              <Input
                {...register('current_delivery_volume')}
                name="current_delivery_volume"
                label="Volumes Entregues e/ou em Entrega"
                defaultValue={
                  deliveryServiceFilteredById.length > 0
                    ? deliveryServiceFilteredById
                      .map((delivery) => delivery.delivery_volume)
                      .reduce((a, b) => a! + b!)!
                    : 0
                }
                isDisabled={true}
              />

              <Stack
                w="full"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                {serviceType !== 'DEDICADO' && (
                  <Input
                    {...register('landing_volume')}
                    name="landing_volume"
                    label="Volume Desembarcado"
                    isDisabled={true}
                  />
                )}

                <Input
                  {...register('delivery_volume')}
                  name="delivery_volume"
                  label="Volume"
                  error={errors.delivery_volume}
                  isDisabled={isDisabled}
                  required
                />
              </Stack>

              {destinationAddressesInput && (
                <Stack>
                  <Input
                    {...register('destination_addresses_input')}
                    name="destination_addresses_input"
                    defaultValue={
                      destinationAddressesInput.map(
                        (address) => address.quantity,
                      )[0]
                    }
                    label="Quantidade de insumos para o endereço"
                    isDisabled={true}
                  />
                </Stack>
              )}

              {isDriverSelected && (
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                >
                  Iniciar Entrega
                </Button>
              )}
              <Divider my="6" borderColor="gray.700" />
            </Stack>
            <Flex mt="8" justify="flex-end">
              <HStack>
                <Link to="/servicos/entregas">
                  <Button type="button" colorScheme="gray">
                    Lista de Serviços à Entregar
                  </Button>
                </Link>
              </HStack>
            </Flex>
          </Box>
        </Flex>
      </>
    )
  } else {
    return (
      <>

        {(userLogged?.user_type === 'COLETADOR' || userLogged?.user_type === 'MOTORISTA') && (
          <ExternalComunications.TriggerButton
            aria-label="help-me"
            onClick={onOpenExternalComunicationModal}
          />
        )}

        <ExternalComunications.Root
          isOpen={isExternalComunicationModalOpen}
          onClose={onCloseExternalComunicationModal}
        >
          <ExternalComunications.Content
            serviceId={service_id}
            onClose={onCloseExternalComunicationModal}
          />
        </ExternalComunications.Root>

        <Flex>
          <Box
            as="form"
            flex="1"
            borderRadius="8px"
            bg="white"
            p={['6', '8']}
            onSubmit={handleSubmit(
              slug ? handleUpdateDeliveryService : handleFinishDeliveryService,
            )}
            noValidate
          >
            <Heading size="lg" fontFamily="poppins">
              {title}
            </Heading>
            {serviceById && branches && (
              <DeliveryAccordion
                serviceByID={serviceById}
                branches={branches}
              />
            )}

            <Alert status="info">
              <AlertIcon />
              {slug ? 'Atualizar Entrega' : 'Entrega Iniciada'}
            </Alert>

            <Divider my="6" borderColor="gray.700" />

            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              {sourceAddresses.map((address) => (
                <Input
                  key={address.id}
                  defaultValue={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep}`.toUpperCase()}
                  isDisabled={true}
                  name="firstname"
                  label="Endereço Destinatário"
                />
              ))}

              <Select
                {...register('driver_id')}
                name="driver_id"
                label="Pertence ao Motorista"
                error={errors.driver_id}
                drivers={sourceDrivers}
                isDisabled={
                  stepOfService === 'deliveringService' || !!slug
                    ? isDisabled
                    : true
                }
                placeholder="Selecione uma opção..."
                required
              />
            </Stack>

            <Stack mt="4" direction={["column", "column", "row"]} spacing={6}>
              <Input
                {...register("real_arrival_date")}
                name="real_arrival_date"
                label="Data real da entrega"
                error={errors.real_arrival_date}
                type="date"
                required
              />
              <Input
                {...register("real_arrival_hour")}
                name="real_arrival_hour"
                label="Horário real da entrega"
                error={errors.real_arrival_hour}
                type="time"
                required
              />
            </Stack>

            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              <Input
                {...register('responsible_name')}
                name="responsible_name"
                label="Nome do Responsável"
                error={errors.responsible_name}
                isDisabled={isDisabled}
                required
              />
              <Input
                {...register('responsible_lastname')}
                name="responsible_lastname"
                label="Sobrenome"
                error={errors.responsible_lastname}
                isDisabled={isDisabled}
                required
              />
            </Stack>

            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              <Stack
                w="100%"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                {serviceType !== 'DEDICADO' && (
                  <Input
                    {...register('landing_volume')}
                    name="landing_volume"
                    label="Volume Desembarcado"
                    isDisabled={true}
                  />
                )}
                <Input
                  {...register('delivery_volume')}
                  name="delivery_volume"
                  label="Volume"
                  error={errors.delivery_volume}
                  isDisabled={!slug === true}
                  required
                />
              </Stack>
            </Stack>

            {!!slug && (
              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Select
                  {...register('box_photo_change')}
                  negative_positive={negative_positive}
                  placeholder="Selecione uma opção..."
                  name="box_photo_change"
                  label="Alterar Foto da Caixa?"
                  error={errors.box_photo_change}
                  isDisabled={isDisabled}
                  required
                />

                <Select
                  {...register('content_photo_change')}
                  negative_positive={negative_positive}
                  placeholder="Selecione uma opção..."
                  name="content_photo_change"
                  label="Alterar Foto do Conteúdo?"
                  error={errors.content_photo_change}
                  isDisabled={isDisabled}
                  required
                />
              </Stack>
            )}

            {slug ? (
              <>
                {deliveryServiceFilteredById
                  .filter((delService) => delService.address_id === address_id)
                  .map((delService) => (
                    <Stack
                      key={delService.id}
                      spacing="24px"
                      mt="4"
                      direction={['column', 'column', 'row']}
                    >
                      <Input name="link_box" hidden>
                        <ChakraLink
                          fontSize="lg"
                          href={handleChangeUrl(delService.box_photo as any)}
                          isExternal
                        >
                          Foto da caixa <Icon as={FiExternalLink} mx="2px" />
                        </ChakraLink>
                      </Input>

                      {delService.content_declaration_attachments !== null && (
                        delService.content_declaration_attachments.map((contentDeclaration) => {
                          return (
                            <Input name="link_content" hidden>
                              <ChakraLink
                                fontSize="lg"
                                href={handleChangeUrl(contentDeclaration.key)}
                                isExternal
                              >
                                {contentDeclaration.filename}{' '}
                                <Icon as={FiExternalLink} mx="2px" />
                              </ChakraLink>
                            </Input>
                          )
                        })
                      )}
                    </Stack>
                  ))}
                <Stack
                  spacing="24px"
                  mt="4"
                  direction={['column', 'column', 'row']}
                >
                  {changeBoxPhoto === 'SIM' && (
                    <>
                      <Input
                        {...register('box_photo')}
                        name="box_photo"
                        label="Foto da Caixa"
                        type="file"
                        accept="image/*"
                        ref={inputRefBox}
                        error={errors.box_photo}
                        hidden
                        onChange={handleSelectFileBox}
                        required
                      >
                        <Button
                          size="md"
                          onClick={handleInputRefBox}
                          leftIcon={<Icon as={FaFile} />}
                        >
                          Upload
                        </Button>
                      </Input>
                      {!!previewBox && (
                        <Image
                          boxSize="100px"
                          objectFit="cover"
                          src={previewBox}
                        />
                      )}
                    </>
                  )}
                </Stack>
              </>
            ) : (
              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Input
                  {...register('box_photo')}
                  name="box_photo"
                  label="Foto da Caixa"
                  type="file"
                  accept="image/*"
                  ref={inputRefBox}
                  error={errors.box_photo}
                  hidden
                  onChange={handleSelectFileBox}
                  required
                >
                  <Button
                    size="md"
                    onClick={handleInputRefBox}
                    leftIcon={<Icon as={FaFile} />}
                  >
                    Upload
                  </Button>
                </Input>
                {!!previewBox && (
                  <Image boxSize="100px" objectFit="cover" src={previewBox} />
                )}
              </Stack>
            )}

            {contentDeclarationAttachmentsFields.map((field, index) => {
              return (
                <Stack key={field.id} w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
                  <Input
                    {...register(`content_declaration_attachments.${index}.attachment`)}
                    name={`content_declaration_attachments.${index}.attachment`}
                    label="Declaração de Conteúdo"
                    type="file"
                    accept={supportedFileFormats.join(", ")}
                    error={errors.content_declaration_attachments ? errors?.content_declaration_attachments[index]?.attachment : undefined}
                    hidden
                    required
                  >
                    <Stack
                      w="full"
                      spacing="24px"
                      mt="4"
                      justifyContent='space-between'
                      direction={['column', 'column', 'row']}
                    >
                      <Button
                        as={FormLabel}
                        htmlFor={`content_declaration_attachments.${index}.attachment`}
                        _hover={{
                          cursor: 'pointer'
                        }}
                        mr="2"
                        leftIcon={<Icon as={FaFile} />}
                      >
                        Upload
                      </Button>
                      {contentDeclarationAttachments && contentDeclarationAttachments[index]?.attachment?.length > 0 && (
                        <ChakraLink
                          href={URL.createObjectURL(contentDeclarationAttachments[index].attachment[0])}
                        >
                          {contentDeclarationAttachments[index].attachment[0].name} (Visualizar)
                        </ChakraLink>
                      )}
                      <Button
                        leftIcon={<Icon as={FaTimes} />}
                        variant="ghost"
                        onClick={() => handleRemoveContentDeclarationAttachmentField(index)}
                        h="48px"
                      >
                        Remover
                      </Button>
                    </Stack>
                  </Input>
                </Stack>
              )
            })}

            <Stack
              w="full"
              mt="4"
            >
              <Button
                _hover={{
                  cursor: 'pointer'
                }}
                mr="2"
                leftIcon={<Icon as={FaPlus} />}
                onClick={handleAppendContentDeclarationAttachmentField}
              >
                Declaração de Conteúdo
              </Button>
              <Text
                fontSize="sm"
                color="red.500"
              >
                {errors?.content_declaration_attachments?.message}
              </Text>
            </Stack>

            <Stack
              w="full"
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              <TextArea
                {...register('observation')}
                name="observation"
                label="Observações"
                isDisabled={isDisabled}
              />
            </Stack>

            <Flex w="full" mt="8" justify="flex-end">
              <Flex w="full" justifySelf="center">
                {/* {userLogged?.permissions.includes("add-occurrence") && ( */}
                <Link
                  to={{
                    pathname: '/ocorrencia/criar',
                    state: {
                      service_id,
                    },
                  }}
                >
                  <Button colorScheme="gray" rightIcon={<Icon as={FiPlus} />}>
                    {isWideVersion ? 'Adicionar Ocorrência' : 'Ocorrência'}
                  </Button>
                </Link>
                {/* )} */}
              </Flex>

              <Stack spacing="12px" direction="row">
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText={isWideVersion ? 'Carregando' : ' '}
                >
                  {!isWideVersion ? (
                    <Icon as={FaSave} />
                  ) : slug ? (
                    'Atualizar entrega'
                  ) : (
                    'Finalizar entrega'
                  )}
                </Button>

                <Link to="/servicos/entregas">
                  <Button type="button" colorScheme="gray">
                    {isWideVersion ? (
                      'Lista de Serviços à Entregar'
                    ) : (
                      <Icon as={FaList} />
                    )}
                  </Button>
                </Link>
              </Stack>
            </Flex>
          </Box>
        </Flex>
      </>
    )
  }
}
