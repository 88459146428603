import * as yup from 'yup'

const schema = yup.object().shape({
  situation: yup.string().when('$is_edit', {
    is: true,
    then: yup.string().required('Campo obrigatório!')
  }),
  customer_id: yup.string().required('Campo Obrigatório'),
  source_hub_id: yup.string().required('Campo Obrigatório'),
  destination_hub_id: yup.string().required('Campo Obrigatório'),
  source_collector_id: yup.string().required('Campo Obrigatório'),
  source_cities: yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
      case 'string':
        return yup.string().nullable().required('Campo Obrigatório')
      default:
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
    }
  }),
  destination_cities: yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
      case 'string':
        return yup.string().nullable().required('Campo Obrigatório')
      default:
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
    }
  }),
  route_nickname: yup.string().required('Campo Obrigatório'),
  vehicle: yup.string().required('Campo Obrigatório'),
  start_date: yup.string().required('Campo Obrigatório'),
  end_date: yup.string().when('situation', {
    is: 'INATIVO',
    then: yup.string().required('Campo Obrigatório'),
  }),
  caixa_termica: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0)
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  embalagem_secundaria: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0)
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  gelo_seco: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0)
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  gelox: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0)
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  isopor3l: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0)
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  isopor7l: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0)
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  terciaria3l: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0)
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  terciaria8l: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .positive('Insira um valor positivo!')
    .min(0)
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  monthly_price: yup.string().required('Campo Obrigatório'),
  monthly_km_franchising: yup.string().required('Campo Obrigatório'),
  week_time_franchising: yup.string().required('Campo Obrigatório'),
  km_extra_price: yup.string().required('Campo Obrigatório'),
  extra_hour_price: yup.string().required('Campo Obrigatório'),
  weekday_daily_budget_backup: yup.string().required('Campo Obrigatório'),
  weekend_daily_budget_backup: yup.string().required('Campo Obrigatório'),
  drivers: yup.array().min(1).of(
    yup.object().shape({
      driver: yup.string().required('Campo Obrigatório'),
      attendance_days: yup.array().min(1).required(),
      start_hour: yup.string().required('Campo Obrigatório'),
      end_hour: yup.string().required('Campo Obrigatório'),
    }),
  ).required("Campo Obrigatório"),
  last_readjustment: yup.string().required('Campo Obrigatório'),
  observation: yup.string(),
})

export { schema }
