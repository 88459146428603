import { Options } from "components/Inputs/SelectInput";

export const rncValidationCauseOptions: Options[] = [
  { key: '0', value: 'INSUMOS NÃO CONFORMES', showOption: 'Insumos não conformes' },
  { key: '1', value: 'CONDUTA', showOption: 'Conduta' },
  { key: '2', value: 'AGENDAMENTO INCORRETO', showOption: 'Agendamento incorreto' },
  { key: '3', value: 'FALTA DE MONITORAMENTO', showOption: 'Falta de monitoramento' },
  { key: '4', value: 'PREENCHIMENTO INCORRETO', showOption: 'Preenchimento incorreto' },
  { key: '5', value: 'CAPACITAÇÃO', showOption: 'Capacitação' },
  { key: '6', value: 'NÃO CONFORMIDADE DE INDICADORES', showOption: 'Não conformidade de indicadores' },
  { key: '7', value: 'DECLARAÇÃO DE CONTEÚDO NÃO CONFORME', showOption: 'Declaração de conteúdo não conforme' },
  { key: '8', value: 'DESVIO DE FLUXO INTERNO', showOption: 'Desvio de fluxo interno' },
  { key: '9', value: 'COMUNICAÇÃO', showOption: 'Comunicação' },
  { key: '10', value: 'MANUTENÇÃO', showOption: 'Manutenção' },
  { key: '11', value: 'SEM FLUXO DEFINIDO', showOption: 'Sem fluxo definido' },
  { key: '12', value: 'FALTA DE LANÇAMENTO', showOption: 'Falta de lançamento' },
  { key: '13', value: 'LANÇAMENTO INCORRETO', showOption: 'Lançamento incorreto' },
  { key: '14', value: 'ABERTURA DE SERVIÇO INCORRETO', showOption: 'Abertura de serviço incorreto' },
  { key: '15', value: 'CAIXA NÃO CONFORME', showOption: 'Caixa não conforme' },
  { key: '16', value: 'EMBARQUE INCORRETO', showOption: 'Embarque incorreto' },
].sort((a, b) => a.value.localeCompare(b.value))
