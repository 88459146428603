import { IconButton, Link, Td, Tr } from "@chakra-ui/react";
import { GetAuditResponse } from "api/audits/getAudit";
import { format } from "date-fns";
import { FaExternalLinkAlt } from "react-icons/fa";

interface AuditFieldTableRowProps {
  audit: GetAuditResponse
}

export function AuditFieldTableRow({
  audit
}: AuditFieldTableRowProps) {
  return (
    <>
      <Tr>
        <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">Foto do colaborador</Td>
        <Td isNumeric>
          <IconButton
            aria-label="Abrir anexo"
            as={Link}
            href={audit?.audit?.fieldAudit?.collaboratorPhotoAttachment?.link}
            icon={<FaExternalLinkAlt />}
            size="sm"
            isExternal
          />
        </Td>
      </Tr>
      <Tr>
        <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">Foto da traseira da moto com placa</Td>
        <Td isNumeric>
          <IconButton
            aria-label="Abrir anexo"
            as={Link}
            href={audit?.audit?.fieldAudit?.vehicleRearPhotoAttachment?.link}
            icon={<FaExternalLinkAlt />}
            size="sm"
            isExternal
          />
        </Td>
      </Tr>
      <Tr>
        <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">Foto da embalagem terciária</Td>
        <Td isNumeric>
          <IconButton
            aria-label="Abrir anexo"
            as={Link}
            href={audit?.audit?.fieldAudit?.tertiaryPackingPhotoAttachment?.link}
            icon={<FaExternalLinkAlt />}
            size="sm"
            isExternal
          />
        </Td>
      </Tr>
      <Tr>
        <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">Foto interna do baú</Td>
        <Td isNumeric>
          <IconButton
            aria-label="Abrir anexo"
            as={Link}
            href={audit?.audit?.fieldAudit?.internChestPhotoAttachment?.link}
            icon={<FaExternalLinkAlt />}
            size="sm"
            isExternal
          />
        </Td>
      </Tr>
      <Tr>
        <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">Foto da CNH</Td>
        <Td isNumeric>
          <IconButton
            aria-label="Abrir anexo"
            as={Link}
            href={audit?.audit?.fieldAudit?.cnhPhotoAttachment?.link}
            icon={<FaExternalLinkAlt />}
            size="sm"
            isExternal
          />
        </Td>
      </Tr>
      <Tr>
        <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">Foto da CRLV</Td>
        <Td isNumeric>
          <IconButton
            aria-label="Abrir anexo"
            as={Link}
            href={audit?.audit?.fieldAudit?.crlvPhotoAttachment?.link}
            icon={<FaExternalLinkAlt />}
            size="sm"
            isExternal
          />
        </Td>
      </Tr>
      <Tr>
        <Td>Última data da troca de óleo</Td>
        <Td isNumeric>{format(new Date(audit?.audit?.fieldAudit.lastOilChangeDate), 'dd/MM/yyyy')}</Td>
      </Tr>
      <Tr>
        <Td>Sistema elétrico</Td>
        <Td isNumeric>{audit?.audit?.fieldAudit.eletricSystem}</Td>
      </Tr>
      <Tr>
        <Td>Número de patrimônio do baú</Td>
        <Td isNumeric>{audit?.audit?.fieldAudit.chestPatrimonyNumber}</Td>
      </Tr>
      <Tr>
        <Td>Número de patrimônio das caixas térmicas</Td>
        <Td isNumeric>{audit?.audit?.fieldAudit.thermicBoxesPatrimonyNumber}</Td>
      </Tr>
    </>
  )
}
