export function sortByStep(stepA: string, stepB: string) {
  const StepAChanged = switchSteps(stepA)
  const StepBChanged = switchSteps(stepB)

  if (StepAChanged < StepBChanged) {
    return -1
  }

  if (StepAChanged > StepBChanged) {
    return 1
  }

  return 0
}

export const switchSteps = (step: string) => {
  switch (step) {
    case 'toValidateRequestedService':
      return 0
    case 'toCollectService':
      return 1
    case 'collectingService':
      return 2
    case 'toValidateCollectAddresses':
      return 3
    case 'toBoardService':
      return 4
    case 'boardingService':
      return 5
    case 'toBoardValidate':
      return 6
    case 'toAllocateService':
      return 7
    case 'toAvailableService':
      return 8
    case 'toLandingService':
      return 9
    case 'landingService':
      return 10
    case 'toDeliveryService':
      return 11
    case 'deliveringService':
      return 12
    case 'finishedService':
      return 13
    case 'unsuccessService':
      return 14
    case 'cancelledService':
      return 15
    default:
      return 16
  }
}
