import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface StockBody {
  collector_id: string
  input_id: string
  minimum_stock: number
  standard_stock: number
  observation: string
}

interface EditStockProps {
  stockId: string
  body: StockBody
}

export async function editStock({
  body,
  stockId
}: EditStockProps) {
  try {
    const response = await api.put(`/stock/${stockId}`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
