import axios from "axios"
import { set } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { useQuery, UseQueryOptions } from "react-query"
import { api } from "services/api"
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum"
import { RequestError } from "utils/errors/RequestErrors"
import { InLandingServiceData } from "./dtos/InLandingService"

interface InLandingServicesQueryParams {
  page_size?: number
  current_page?: number
  protocol?: string
  delivery_date?: string
  delivery_hour?: string
  shipping?: string
  board_tracker?: string
  board_volume?: string
  source_branch?: string
  destination_branch?: string
  customer?: string
  source_city?: string
  destination_city?: string
  collector?: string
  collector_id?: string
}

type QueryOptions = UseQueryOptions<InLandingServiceData, unknown, InLandingServiceData, ["inLandingServices"]>

interface UseInLandingServicesOptions {
  queryParams?: InLandingServicesQueryParams,
  queryOptions?: QueryOptions
}

async function getInLandingServicesReqFunction(queryParams?: InLandingServicesQueryParams) {
  try {
    const { data } = await api.get('/service/list/in-landing', {
      params: {
        ...queryParams,
        delivery_hour: queryParams?.delivery_hour
          ? set(new Date(), {
            hours: Number(queryParams?.delivery_hour.split(':')[0]),
            minutes: Number(queryParams?.delivery_hour.split(':')[1])
          })
          : undefined,
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

export function useInLandingServices(options?: UseInLandingServicesOptions) {

  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  return useQuery([
    'inLandingServices',
    { ...options?.queryParams }
  ],
    () => getInLandingServicesReqFunction(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )

}
