import { api } from '../../../services/api'

async function generateMinutaReqFunction(service_id: string) {
  try {
    const { data } = await api.get(`/minuta/${service_id}`, {
      responseType: 'arraybuffer',
    })

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

export { generateMinutaReqFunction }
