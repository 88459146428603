import { Flex } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { useFormContext } from "react-hook-form";

interface CrmNonPayerFormInputs {
  invoiceNumber: number
  originalInvoiceExpirationDate: string
  paymentForecastDate: string
}

interface CrmNonPayerFormProps {
  isViewPage: boolean
}

export function CrmNonPayerForm({
  isViewPage
}: CrmNonPayerFormProps) {

  const {
    register,
    formState: { errors }
  } = useFormContext<CrmNonPayerFormInputs>()

  return (
    <Flex
      gap={4}
      direction='column'
    >
      <Input
        {...register('invoiceNumber')}
        name='invoiceNumber'
        label='Número da fatura'
        error={errors.invoiceNumber}
        isDisabled={isViewPage}
        required
      />

      <Input
        {...register('originalInvoiceExpirationDate')}
        name='originalInvoiceExpirationDate'
        type='date'
        label='Vencimento original'
        error={errors.originalInvoiceExpirationDate}
        isDisabled={isViewPage}
        required
      />

      <Input
        {...register('paymentForecastDate')}
        name='paymentForecastDate'
        type='date'
        label='Previsão de pagamento'
        error={errors.paymentForecastDate}
        isDisabled={isViewPage}
        required
      />
    </Flex>
  )
}
