import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface EditDeliveryAddressesAssignedDriversBody {
  deliveries: {
    addressId: string
    driversIds: string[]
    volume?: number
  }[]
}

interface EditDeliveryAddressesAssignedDriversParams {
  serviceId: string
  body: EditDeliveryAddressesAssignedDriversBody
}

export async function editDeliveryAddressesAssignedDrivers({
  serviceId,
  body
}: EditDeliveryAddressesAssignedDriversParams) {
  try {
    const { data } = await api.put(
      `/services/${serviceId}/deliveries/assigned-drivers`,
      body
    )

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
