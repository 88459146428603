import { Box, FormControl, FormLabel, Textarea } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";


export function DocumentSubmissionForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value
    const labelMap: { [key in keyof FormDataItem]: string } = {
      medicalNoteOrDocumentObservation: 'Observação sobre o atestado médico/documento',
    };

    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };

  return (
    <Box w='full'>
      <FormControl isInvalid={!!errors.formData?.medicalNoteOrDocumentObservation} mt={3}>
        <FormLabel fontSize="sm">
          Observação sobre o atestado médico/documento
        </FormLabel>
        <Textarea
          {...register('formData.medicalNoteOrDocumentObservation.value')}
          name='formData.medicalNoteOrDocumentObservation.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('medicalNoteOrDocumentObservation')}
        />
      </FormControl>
    </Box>
  )

}
