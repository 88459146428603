export enum SupportType {
  Externo = 'Externo',
  Interno = 'Interno',
  Parceiro = 'Parceiro'
}


export const SUPPORT_TYPE_CATEGORIES: Record<SupportType, string[] | null> = {
  [SupportType.Parceiro]: null,
  [SupportType.Externo]: null,
  [SupportType.Interno]: ['Compras e Almoxarifado', 'Contas a pagar', 'Contas a receber', 'TI', 'Gente', 'Jurídico', 'Qualidade']
}
