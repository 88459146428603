import { Box, BoxProps, Icon, useRadio, UseRadioProps } from "@chakra-ui/react";
import { IconType } from "react-icons";

interface RadioItemProps {
  props: UseRadioProps
  radio: {
    styles: BoxProps,
    icon: IconType,
  }
}

export function RadioItem({ props, radio }: RadioItemProps) {

  const { getInputProps, getRadioProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Box
      as="label"
    >
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        rounded="full"
        p="3"
        display="flex"
        alignItems="center"
        justifyContent="center"
        _checked={{
          bg: '#38C3FA',
          color: 'white',
          borderColor: 'teal.600',
        }}
        _focus={{
          boxShadow: '0 0 0 2px #38c3fa'
        }}
        {...radio.styles}
      >
        <Icon as={radio.icon} />
      </Box>
    </Box>
  )
}
