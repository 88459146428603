import { useHistory, useParams } from 'react-router'
import { useState, useContext, useEffect } from 'react'
import swal from 'sweetalert'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { ProviderForm } from './ProviderForm'
import { providerFormatDataToBack } from '../../utils/Provider/providerFormatDataToBack'
import { ProviderContext } from '../../contexts/ProviderContext'
import { AuthContext } from '../../contexts/AuthContext'
import { getPrimaryProviderByHub } from 'api/providers/getPrimaryProviderByHub'
import { useSwal } from 'hooks/swal/useSwal'

interface QueryParams {
  id: string
  slug: string
}

interface FormInputs {
  company_name: string
  trading_name: string
  hub_id: string
  cnpj: string
  email: string
  material: string
  unit_cost: number
  payment_conditional: string
  day_expiration_1: string
  day_expiration_2: string
  payment_type: string
  cellphone: string
  telephone: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  business_open: string
  business_close: string
  saturday_open: string | null
  saturday_close: string | null
  sunday_open: string | null
  sunday_close: string | null
  situation: string
  material_price: string
  observation: string
  deadline: string
  is_primary_provider: string
}

export function ProviderAddDetailEdit() {
  const [isLoading, setIsLoading] = useState(false)
  const { createProvider, editProvider } = useContext(ProviderContext)
  const { userLogged } = useContext(AuthContext)
  const { confirmMessage } = useSwal()

  const { id, slug }: QueryParams = useParams()

  const history = useHistory()

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (slug === 'adicionar') {
            if (!permissions?.includes('add-provider')) {
              history.push('/')
            }
          }
          if (slug === 'editar') {
            if (!permissions?.includes('edit-provider')) {
              history.push('/')
            }
          }
          if (slug === 'visualizar') {
            if (!permissions?.includes('view-provider')) {
              history.push('/')
            }
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [slug, history, userLogged])

  async function handleCreateProvider(values: FormInputs) {
    const { provider } = await getPrimaryProviderByHub({ hubId: values.hub_id })

    let hasOpenConfirmMessage = false

    if (provider && values.is_primary_provider === 'yes') {
      hasOpenConfirmMessage = true
    }

    let hasConfirmMessage = true

    if (hasOpenConfirmMessage) {
      hasConfirmMessage = await confirmMessage({
        title: 'Deseja alterar o fornecedor principal?',
        text: `O atual fornecedor principal do hub é o ${provider?.trading_name} - ${provider?.company_name}`,
        buttons: ['Não', 'Sim']
      })
    }

    if (hasConfirmMessage) {
      await swal({
        title: 'Deseja adicionar um fornecedor?',
        text: 'Essa ação não poderá ser desfeita!',
        icon: 'warning',
        buttons: ['Cancelar', 'Confirmar'],
        dangerMode: false,
      }).then(async (willCreate) => {
        if (willCreate) {
          const formattedData = providerFormatDataToBack(values)
          const response = await createProvider(formattedData)
          if (response) {
            setIsLoading(true)
            setTimeout(() => {
              history.push('/fornecedores')
            }, 1500)
          }
        } else {
          return swal('Ação cancelada com êxito!')
        }
      })
    }


  }

  async function handleEditProvider(values: FormInputs) {
    const { provider } = await getPrimaryProviderByHub({ hubId: values.hub_id })

    let hasOpenConfirmMessage = false

    if (provider && values.is_primary_provider === 'yes') {
      hasOpenConfirmMessage = true
    }

    let hasConfirmMessage = true

    if (hasOpenConfirmMessage) {
      hasConfirmMessage = await confirmMessage({
        title: 'Deseja alterar o fornecedor principal?',
        text: `O atual fornecedor principal do hub é o ${provider?.trading_name} - ${provider?.company_name}`,
        buttons: ['Não', 'Sim']
      })
    }

    if (hasConfirmMessage) {
      await swal({
        title: 'Deseja editar um fornecedor?',
        text: 'Essa ação não poderá ser desfeita!',
        icon: 'warning',
        buttons: ['Cancelar', 'Confirmar'],
        dangerMode: false,
      }).then(async (willEdit) => {
        if (willEdit) {
          const formattedData = providerFormatDataToBack(values)
          const response = await editProvider(id, formattedData)
          if (response) {
            setIsLoading(true)
            setTimeout(() => {
              history.push('/fornecedores')
            }, 1500)
          }
        } else {
          return swal('Ação cancelada com êxito!')
        }
      })
    }
  }

  if (isLoading) {
    return <GeneralContentLoading />
  }

  if (slug === 'adicionar') {
    return (
      <ProviderForm
        slug={slug}
        id={id}
        href=""
        submit={handleCreateProvider}
        title="Adicionar fornecedor"
        action="Salvar"
      />
    )
  } else if (slug === 'editar') {
    return (
      <ProviderForm
        slug={slug}
        id={id}
        href=""
        submit={handleEditProvider}
        title="Editar fornecedor"
        action="Salvar"
      />
    )
  } else {
    return (
      <ProviderForm
        slug={slug}
        id={id}
        href={`/fornecedor/editar/${id}`}
        submit={handleEditProvider}
        title="Visualizar fornecedor"
        action="Editar"
        isDisabled={true}
      />
    )
  }
}
