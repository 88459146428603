import { convertTimeStringInMinutes } from "utils/convertTimeStringInMinutes"

interface DataProps {
  situation: string
  collector_id: string
  firstname: string
  lastname: string
  cpf: string
  email: string
  observation: string
  has_interval: 'yes' | 'no'
  intervals: {
    startTime: string
    endTime: string
    weekDay: number
    enabled: boolean
  }[]
}

const driverFormatDataToBack = async (data: DataProps) => {

  return {
    ...data,
    intervals: data.has_interval === 'yes'
      ? data.intervals.filter(interval => interval.enabled).map((interval) => {

        return {
          timeStartInMinutes: convertTimeStringInMinutes(interval.startTime),
          timeEndInMinutes: convertTimeStringInMinutes(interval.endTime),
          weekDay: interval.weekDay
        }
      })
      : null
  }
}

export { driverFormatDataToBack }
