import { FormEvent } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { CreateSupportSchema } from "../CreateSupport"
import { FormDataItem } from "api/supports/createSupport"
import { Box, FormControl, FormLabel, Input, Select, Stack, Text, Textarea } from "@chakra-ui/react"
import ReactInputMask from "react-input-mask"
import { handleSwitchMask } from "utils/handleChangeMask"
import { getCustomersCostCenters } from "api/customers/getCostCenters"
import { captalize } from "utils/captalize"
import AsyncSelect from "react-select/async"


export function RpaRequestForm() {
  const { register, setValue, formState: { errors }, control } = useFormContext<CreateSupportSchema>()

  async function promiseOptions(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getCustomersCostCenters({ currentPage: 1, pageSize: 5, costCenter: Boolean(inputValue) ? inputValue : null })

    return response?.costCenters?.map(costCenter => {
      return { value: costCenter.costCenter, label: captalize(costCenter.costCenter) }
    })
  }


  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      recurringPayment: 'Pagamento Recorrente ?',
      applicantName: 'Nome do Solicitante',
      occurrenceDate: 'Data da Ocorrência',
      paymentDate: 'Data de Pagamento',
      providerFullName: 'Nome Completo do Prestador do Serviço',
      birthDate: 'Data de Nascimento',
      birthCity: 'Naturalidade (Cidade de Nascimento)',
      rg: 'RG',
      cpf: 'CPF',
      pis: 'PIS',
      address: 'Endereço',
      cep: 'CEP',
      motherName: 'Nome da Mãe',
      serviceProvided: 'Serviço Prestado',
      costCenter: 'Centro de Custo',
      inssDeduction: 'Será descontado INSS (16%)',
      bankDetails: 'Dados Bancários',
      observations: 'Observações',
      serviceCityState: 'Cidade e UF de Prestação de Serviço',
    };


    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };

  return (
    <Box w='full'>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.recurringPayment}>
            <FormLabel fontSize="sm">
              Pagamento Recorrente ?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register("formData.recurringPayment.value")}
              name="formData.recurringPayment.value"
              placeholder="Selecione uma opção..."
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('recurringPayment')}
            >
              <option value='Sim, é fixo todo mês'>Sim, é fixo todo mês</option>
              <option value='Não, é pontual'>Não, é pontual</option>

            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors?.formData?.applicantName}>
            <FormLabel fontSize="sm">
              Nome do Solicitante
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.applicantName.value')}
              name="formData.applicantName.value"
              size="sm"
              rounded="md"
              onChangeCapture={handleInputChange('applicantName')}
            />
          </FormControl>

        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.occurrenceDate} >
            <FormLabel fontSize='sm'>
              Data da Ocorrência
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.occurrenceDate.value')}
              name='formData.occurrenceDate.value'
              type="date"
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('occurrenceDate')}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.paymentDate} >
            <FormLabel fontSize='sm'>
              Data de Pagamento
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.paymentDate.value')}
              name='formData.paymentDate.value'
              type="date"
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('paymentDate')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors?.formData?.providerFullName}>
            <FormLabel fontSize="sm">
              Nome Completo do Prestador do Serviço
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.providerFullName.value')}
              name="formData.providerFullName.value"
              size="sm"
              rounded="md"
              onChangeCapture={handleInputChange('providerFullName')}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.birthDate} >
            <FormLabel fontSize='sm'>
              Data de Nascimento
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.birthDate.value')}
              name='formData.birthDate.value'
              type="date"
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('birthDate')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors?.formData?.birthCity}>
            <FormLabel fontSize="sm">
              Naturalidade (Cidade de Nascimento)
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.birthCity.value')}
              name="formData.birthCity.value"
              size="sm"
              rounded="md"
              onChangeCapture={handleInputChange('birthCity')}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors?.formData?.rg}>
            <FormLabel fontSize="sm">
              RG
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.rg.value')}
              name="formData.rg.value"
              size="sm"
              rounded="md"
              onChangeCapture={handleInputChange('rg')}
            />
          </FormControl>
        </Stack>

      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors?.formData?.cpf}>
            <FormLabel fontSize="sm">
              CPF
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.cpf.value')}
              name="formData.cpf.value"
              as={ReactInputMask as any}
              mask={handleSwitchMask('cpf')}
              size="sm"
              rounded="md"
              onChangeCapture={handleInputChange('cpf')}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors?.formData?.pis}>
            <FormLabel fontSize="sm">
              PIS
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.pis.value')}
              name="formData.pis.value"
              size="sm"
              rounded="md"
              onChangeCapture={handleInputChange('pis')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <FormControl isInvalid={!!errors?.formData?.address}>
          <FormLabel fontSize="sm">
            Endereço
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('formData.address.value')}
            name="formData.address.value"
            size="sm"
            rounded="md"
            onChangeCapture={handleInputChange('address')}
          />
        </FormControl>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors?.formData?.cep}>
            <FormLabel fontSize="sm">
              CEP
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.cep.value')}
              name="formData.cep.value"
              as={ReactInputMask as any}
              mask={handleSwitchMask('cep')}
              size="sm"
              rounded="md"
              onChangeCapture={handleInputChange('cep')}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors?.formData?.motherName}>
            <FormLabel fontSize="sm">
              Nome da Mãe
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.motherName.value')}
              name="formData.motherName.value"
              size="sm"
              rounded="md"
              onChangeCapture={handleInputChange('motherName')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <FormControl isInvalid={!!errors.formData?.serviceProvided} >
          <FormLabel fontSize="sm">
            Serviço Prestado
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Textarea
            {...register('formData.serviceProvided.value')}
            name='formData.serviceProvided.value'
            size='sm'
            rounded='md'
            onChangeCapture={handleInputChange('serviceProvided')}
          />
        </FormControl>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.costCenter}>
            <FormLabel fontSize="sm">
              Centro de Custo
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              control={control}
              name="formData.costCenter.value"
              render={({ field }) => (
                <AsyncSelect
                  name={field.name}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption?.value || '');
                    setValue(`formData.costCenter.label`, 'Centro de Custo')
                    setValue(`formData.costCenter.type`, 'select');
                  }}
                  cacheOptions
                  defaultOptions
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: '0px',
                      minHeight: '20px',
                      height: '32px',
                      borderRadius: '6px',
                      border: state.isFocused ? 'none' : '',
                      borderColor: 'rgba(0, 0, 0, 0.09)',
                      fontSize: '14px',
                      fontWeight: 'normal',
                      boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : '',
                    }),
                    valueContainer: (baseStyles) => ({
                      ...baseStyles,
                      padding: '0px 8px'
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      margin: '0px',
                      padding: '0px',
                      height: '100%',
                    }),
                    multiValue: (baseStyles) => ({
                      ...baseStyles,
                      margin: '2px'
                    }),
                    dropdownIndicator: (baseStyles) => ({
                      ...baseStyles,
                      padding: '4px'
                    }),
                    clearIndicator: (baseStyles) => ({
                      ...baseStyles,
                      padding: '4px'
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      color: 'black'
                    })
                  }}
                  placeholder="Centro de custos..."
                  loadOptions={promiseOptions}
                  isClearable={true}
                />
              )}
            />


          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.inssDeduction}>
            <FormLabel fontSize="sm">
              Será descontado INSS (16%)
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register("formData.inssDeduction.value")}
              name="formData.inssDeduction.value"
              placeholder="Selecione uma opção..."
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('inssDeduction')}
            >
              <option value='Sim'>Sim</option>
              <option value='Não'>Não</option>

            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <FormControl isInvalid={!!errors.formData?.bankDetails} >
          <FormLabel fontSize="sm">
            Dados Bancários
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Textarea
            {...register('formData.bankDetails.value')}
            name='formData.bankDetails.value'
            size='sm'
            rounded='md'
            onChangeCapture={handleInputChange('bankDetails')}
          />
        </FormControl>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <FormControl isInvalid={!!errors.formData?.observations} >
          <FormLabel fontSize="sm">
            Observações
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Textarea
            {...register('formData.observations.value')}
            name='formData.observations.value'
            size='sm'
            rounded='md'
            onChangeCapture={handleInputChange('observations')}
          />
        </FormControl>
      </Stack>
      <FormControl isInvalid={!!errors?.formData?.serviceCityState} mt={3}>
        <FormLabel fontSize="sm">
          Cidade e UF de Prestação de Serviço
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.serviceCityState.value')}
          name="formData.serviceCityState.value"
          size="sm"
          rounded="md"
          onChangeCapture={handleInputChange('serviceCityState')}
        />
      </FormControl>

    </Box>
  )
}
