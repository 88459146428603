import { Stack } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { useFormContext } from "react-hook-form";

interface FractionedServiceHoursInputsGroupProps {
  isDisabled?: boolean;
}

export function FractionedServiceHoursInputsGroup({
  isDisabled = false
}: FractionedServiceHoursInputsGroupProps) {

  const { register, formState: { errors } } = useFormContext()

  return (
    <Stack w="full" spacing={6}>
      <Stack direction={["column", "column", "row"]} spacing={6}>
        <Input
          {...register('board_date')}
          name="board_date"
          type="date"
          label="Data limite para embarque"
          error={errors.board_date}
          isDisabled={isDisabled}
          required
        />
        <Input
          {...register('board_hour')}
          name="board_hour"
          type="time"
          label="Horário limite para embarque"
          error={errors.board_hour}
          isDisabled={isDisabled}
          required
        />
      </Stack>

      <Stack direction={["column", "column", "row"]} spacing={6}>
        <Input
          {...register('availability_forecast_day')}
          name="availability_forecast_day"
          type="date"
          label="Data previsão de disponibilidade"
          error={errors.availability_forecast_day}
          isDisabled={isDisabled}
          required
        />
        <Input
          {...register('availability_forecast_time')}
          name="availability_forecast_time"
          type="time"
          label="Hora previsão de disponibilidade"
          error={errors.availability_forecast_time}
          isDisabled={isDisabled}
          required
        />
      </Stack>
    </Stack>
  )
}
