import { AvatarGroup, Box, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { Support } from "api/supports/_types/Support";
import { SupportSubject } from "api/supports/_types/SupportSubject";
import { format, formatDistanceToNowStrict, isFuture } from "date-fns";
import { ptBR } from "date-fns/locale";
import { User } from "hooks/user/dtos/User";

import { FaCalendarAlt, FaExclamationCircle, FaHeadphonesAlt, FaUser } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";

import { SupportDetail } from "./SupportDetail";
import { TooltipAvatar } from "./TooltipAvatar";


interface KanbanSupportCardProps {
  support: Support & {
    subject: SupportSubject & {
      responsibles: {
        user: User
      }[]
    }
  }
}

export function KanbanSupportCard({ support }: KanbanSupportCardProps) {

  const {
    isOpen: isSupportDetailModalOpen,
    onToggle: onToggleSupportDetailModal,
  } = useDisclosure()

  const supportDeadline = new Date(support?.deadline);
  const formattedSupportDate = format(supportDeadline, "dd MMM", { locale: ptBR });

  const relativeDate = formatDistanceToNowStrict(supportDeadline, { locale: ptBR });
  const isDateInFuture = isFuture(supportDeadline);
  const relativeDateText = isDateInFuture ? `em ${relativeDate}` : `${relativeDate} atrás`;

  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleSupportDetailModal}
      cursor='pointer'
    >
      <Text fontWeight="bold" mb={2}>{support.protocol}</Text>

      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">

        <Box>
          <HStack spacing={2}>
            <Icon as={FaHeadphonesAlt} />
            <Text fontSize="sm" fontWeight="bold">Tipo de atendimento</Text>
          </HStack>
          <Text ml={5}>{support?.type.toUpperCase()}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="sm" fontWeight="bold">Data da solicitação</Text>
          </HStack>
          <Text ml={5}>{format(new Date(support?.createdAt), "dd/MM/yyyy '-' HH:mm")}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaUser} />
            <Text fontSize="sm" fontWeight="bold">Solicitado por</Text>
          </HStack>
          <Text ml={5}>{`${support?.createdByUser?.firstname} ${support?.createdByUser?.lastname}`}</Text>
        </Box>

        <Box>
          <HStack spacing={2}>
            <Icon as={FaPeopleGroup} />
            <Text fontSize="sm" fontWeight="bold">Responsáveis</Text>
          </HStack>
          <AvatarGroup >
            {support?.subject?.responsibles.map((responsible) => {
              return <TooltipAvatar size="sm" key={responsible.user.id} name={`${responsible.user.firstname} ${responsible.user.lastname}`} />
            })}

          </AvatarGroup>
        </Box>
        {!['canceled','done'].includes(support?.status) && (
           <HStack spacing={2}>
           {isDateInFuture ? (
             <>
               <Icon as={FaCalendarAlt} />
               <Text fontWeight="bold">{`Venc ${formattedSupportDate}`}</Text>
             </>
           ) : (
             <>
               <Icon as={FaExclamationCircle} color="red.500" />
               <Text color="red.500" fontWeight="bold">{`Venc ${formattedSupportDate}`}</Text>
             </>
           )}
           <Text>{relativeDateText}</Text>
         </HStack>
        )}

      </VStack>

      <Modal
        isOpen={isSupportDetailModalOpen}
        onClose={onToggleSupportDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <SupportDetail
          supportId={support.id}
          onClose={onToggleSupportDetailModal}
        />
      </Modal>
    </Box>
  )
}
