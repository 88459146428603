import { IconButton, Link, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { GetInvoiceResponse } from "api/invoices/getInvoice";
import { format } from "date-fns";
import { FaExternalLinkAlt } from "react-icons/fa";
import { captalize } from "utils/captalize";
import { InvoiceStatus } from "./InvoiceStatus";

interface InvoiceDetailTableProps {
  invoiceResult: GetInvoiceResponse
}


export function InvoiceDetailTable({ invoiceResult }: InvoiceDetailTableProps) {
  return (
    <Table size="sm">
      <Tbody>
        <Tr>
          <Td>Status</Td>
          <Td display='flex' justifyContent='flex-end'>
            <InvoiceStatus status={invoiceResult?.invoice.status} />
          </Td>
        </Tr>
        <Tr>
          <Td>E-mail para faturamento</Td>
          <Td isNumeric>{invoiceResult?.invoice.billingEmail}</Td>
        </Tr>
        {invoiceResult?.invoice?.collectorId && (
          <>
            <Tr>
              <Td>Nome fantasia</Td>
              <Td isNumeric>{invoiceResult?.invoice?.collector?.trading_name}</Td>
            </Tr>
            <Tr>
              <Td>Razão Social</Td>
              <Td isNumeric>{invoiceResult?.invoice?.collector?.company_name}</Td>
            </Tr>
          </>
        )}
        {invoiceResult?.invoice?.aggregateId && (
          <>
            <Tr>
              <Td>Nome fantasia</Td>
              <Td isNumeric>{captalize(invoiceResult?.invoice?.aggregate?.trading_name)}</Td>
            </Tr>
            <Tr>
              <Td>Razão Social</Td>
              <Td isNumeric>{captalize(invoiceResult?.invoice?.aggregate?.company_name)}</Td>
            </Tr>
          </>
        )}
        <Tr>
          <Td>Cidade</Td>
          <Td isNumeric>{captalize(invoiceResult?.invoice?.city?.name)}</Td>
        </Tr>
        <Tr>
          <Td>Regional</Td>
          <Td isNumeric>{captalize(invoiceResult?.invoice?.regional?.name)}</Td>
        </Tr>
        <Tr>
          <Td>Relatório contendo serviços do dia</Td>
          <Td isNumeric>{invoiceResult?.invoice?.reportStartDate ? format(new Date(invoiceResult?.invoice?.reportStartDate), 'dd/MM/yyyy') : '-'}</Td>
        </Tr>
        <Tr>
          <Td>Até o dia</Td>
          <Td isNumeric>{invoiceResult?.invoice?.reportFinalDate ? format(new Date(invoiceResult?.invoice?.reportFinalDate), 'dd/MM/yyyy') : '-'}</Td>
        </Tr>
        {invoiceResult?.invoice?.approvedReportAttachment && (
          <Tr>
            <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">
              Relatório de serviços
            </Td>
            <Td isNumeric>
              <IconButton
                aria-label="Abrir anexo"
                as={Link}
                href={invoiceResult?.invoice?.approvedReportAttachment?.link}
                icon={<FaExternalLinkAlt />}
                size="sm"
                isExternal
              />
            </Td>
          </Tr>
        )}
        {invoiceResult?.invoice?.responsible && (
          <Tr>
            <Td>Responsável</Td>
            <Td isNumeric>{captalize(`${invoiceResult?.invoice?.responsible?.firstname} ${invoiceResult?.invoice?.responsible?.lastname}`)}</Td>
          </Tr>
        )}
        {invoiceResult?.invoice?.isApproved !== null && (
          <Tr>
            <Td>Aprovado</Td>
            <Td isNumeric>{invoiceResult?.invoice?.isApproved ? 'Sim' : 'Não'}</Td>
          </Tr>
        )}
        {invoiceResult?.invoice?.divergences && (
          <Tr>
            <Td>Responsável</Td>
            <Td isNumeric>{captalize(invoiceResult?.invoice?.divergences)}</Td>
          </Tr>
        )}
        {invoiceResult?.invoice?.approvedValueInCents && (
          <Tr>
            <Td>Valor aprovado</Td>
            <Td isNumeric>{new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            }).format(invoiceResult?.invoice?.approvedValueInCents / 100)}</Td>
          </Tr>
        )}
        {invoiceResult?.invoice?.hasContractAdditive !== null && (
          <Tr>
            <Td>Existe alguma cobrança fora do previsto que vá gerar aditivo contratual?</Td>
            <Td isNumeric>{invoiceResult?.invoice?.hasContractAdditive ? 'Sim' : 'Não'}</Td>
          </Tr>
        )}
        {invoiceResult?.invoice?.hasUnforeseenCharge !== null && (
          <Tr>
            <Td>Existe alguma cobrança fora de previsto que precisa informar ao comercial?</Td>
            <Td isNumeric>{invoiceResult?.invoice?.hasUnforeseenCharge ? 'Sim' : 'Não'}</Td>
          </Tr>
        )}
        {invoiceResult?.invoice?.unforeseenChargeChecklist && (
          <Tr>
            <Td>Comunicação</Td>
            <Td isNumeric>{invoiceResult?.invoice?.unforeseenChargeChecklist?.map((list) => list)}</Td>
          </Tr>
        )}
        {invoiceResult?.invoice?.isWithoutReturn !== null && (
          <Tr>
            <Td>Aprovada sem retorno</Td>
            <Td isNumeric>{invoiceResult?.invoice?.isWithoutReturn ? 'Sim' : 'Não'}</Td>
          </Tr>
        )}
        {invoiceResult?.invoice?.approvedStepAttachments && invoiceResult?.invoice?.approvedStepAttachments.map((attachment) => {
          return (

            <Tr key={attachment.id}>
              <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">
                {attachment.type === 'billet' ? 'Boleto' : 'Nota fiscal'} - Fatura Aprovada
              </Td>
              <Td isNumeric>
                <IconButton
                  aria-label="Abrir anexo"
                  as={Link}
                  href={attachment?.attachment.link}
                  icon={<FaExternalLinkAlt />}
                  size="sm"
                  isExternal
                />
              </Td>
            </Tr>
          )
        })}
        {invoiceResult?.invoice?.receiptAttachment && (
          <Tr>
            <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">
              Nota Fiscal - Conferência de Valores
            </Td>
            <Td isNumeric>
              <IconButton
                aria-label="Abrir anexo"
                as={Link}
                href={invoiceResult?.invoice?.approvedReportAttachment?.link}
                icon={<FaExternalLinkAlt />}
                size="sm"
                isExternal
              />
            </Td>
          </Tr>
        )}

        {invoiceResult?.invoice?.isValueSameOfApprovedInvoice !== null && (
          <Tr>
            <Td>Confere com o valor da fatura aprovada?</Td>
            <Td isNumeric>{invoiceResult?.invoice?.isValueSameOfApprovedInvoice ? 'Sim' : 'Não'}</Td>
          </Tr>
        )}
        {invoiceResult?.invoice?.isReceiptCnpjSameOfRegistered !== null && (
          <Tr>
            <Td>CNPJ da NF confere com o cadastrado?</Td>
            <Td isNumeric>{invoiceResult?.invoice?.isReceiptCnpjSameOfRegistered ? 'Sim' : 'Não'}</Td>
          </Tr>
        )}
        {invoiceResult?.invoice?.isCorrectValue !== null && (
          <Tr>
            <Td>O valor está correto?</Td>
            <Td isNumeric>{invoiceResult?.invoice?.isCorrectValue ? 'Sim' : 'Não'}</Td>
          </Tr>
        )}

      </Tbody>
    </Table>
  )
}
