import { usePagination } from "@ajna/pagination";
import { Box, Heading, Skeleton, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { getStandardizationsReport } from "api/standardizations/getStandardizationsReport";
import { Empty } from "components/Empty";
import { Pagination } from "components/Pagination/Pagination";

import { format } from "date-fns";
import { useSearchParams } from "hooks/useSearchParams";
import { useQuery } from "react-query";
import { standardizationStatusMapper } from "utils/standardizations/standardizationMappers";
import { StandardizationsReportFilters } from "./components/StandardizationsReportFilters";
import { StandardizationStatus } from "./components/StandardizationStatus";

const headers = [
  { label: 'Hub', key: 'hub.name' },
  { label: 'Data de envio', key: 'name' },
  { label: 'Tipo de veículo', key: 'sended_at' },
  { label: 'Veículo', key: 'vehicle' },
  { label: 'Status', key: 'status' },
  { label: 'CNH atual', key: 'cnh_photo' },
  { label: 'CRLV atual', key: 'crlv_photo' },
  { label: 'Curso de motofrete/ANTT', key: 'antt_photo' },
  { label: 'Registro(s) de limpeza', key: 'cleaning_register_photos' },
  { label: 'Foto frontal com uniforme e crachá', key: 'uniform_and_badge_photo' },
  { label: 'Foto do veículo (frente)', key: 'vehicle_front_photo' },
  { label: 'Foto do veículo (lateral esquerda)', key: 'vehicle_left_photo' },
  { label: 'Foto do veículo (lateral direita)', key: 'vehicle_right_photo' },
  { label: 'Foto do veículo (traseira com placa)', key: 'vehicle_rear_photo' },
  { label: 'Foto do veículo (interna se carro, baú se moto - com KIT EPI)', key: 'vehicle_intern_photo' },
  { label: 'Foto da tampa do baú ou carro com ficha de emergência', key: 'trunk_lid_or_car_with_emergency_form_photo' },
  { label: 'Foto dos pneus', key: 'tires_photos' },
  { label: 'Status CNH', key: 'cnh_status' },
  { label: 'Status CRLV', key: 'crlv_status' },
  { label: 'Status certificado motofrete', key: 'motor_freight_certificate_status' },
  { label: 'Registro de limpeza', key: 'cleaning_register_status' },
  { label: 'Vacinas', key: 'vaccines_status' },
  { label: 'Treinamentos', key: 'trainings_status' },
  { label: 'Conta salário', key: 'salary_account_status' },
  { label: 'Uniforme e crachá', key: 'uniform_and_badge_status' },
  { label: 'Adesivos', key: 'stickers_status' },
  { label: 'Faixa refletiva', key: 'reflective_strip_status' },
  { label: 'Placa vermelha', key: 'red_plate_status' },
  { label: 'Limpeza visual do veículo', key: 'vehicle_visual_cleaning_status' },
  { label: 'KIT EPI no veículo', key: 'vehicle_epi_kit_status' },
  { label: 'Condição dos pneus', key: 'tires_condition_status' },
]

export function StandardizationsReport() {
  const searchParams = useSearchParams()

  const status = searchParams.get('status')
  const sendedAtStartDate = searchParams.get('sendedAtStartDate')
  const sendedAtEndDate = searchParams.get('sendedAtEndDate')


  const {
    data: standardizationsReportResult,
    isLoading: isLoadingStandardizationsReportResult,
    isError: isStandardizationsReportResultError
  } = useQuery({
    queryKey: ['standardizations-report', status, sendedAtStartDate, sendedAtEndDate],
    queryFn: () => getStandardizationsReport({
      status: status as 'approved' | 'reproved' | 'pending',
      sendedAtStartDate,
      sendedAtEndDate
    }),
    keepPreviousData: true,
    enabled: !!status
  })

  const csvProps = {
    data: standardizationsReportResult ? standardizationsReportResult?.standardizations.map(standardization => {
      return {
        ...standardization,
        sended_at: format(new Date(standardization.sended_at), 'dd/MM/yyyy'),
        status: standardizationStatusMapper[standardization.status],
        cnh_photo: standardization.attachments
          .find(attachment => attachment.type === 'cnh').attachment.link,
        crlv_photo: standardization.attachments
          .find(attachment => attachment.type === 'crlv').attachment.link,
        antt_photo: standardization.attachments
          .find(attachment => attachment.type === 'antt').attachment.link,
        cleaning_register_photos: standardization.attachments
          .filter(attachment => attachment.type === 'cleaning-register')
          .map(attachment => attachment.attachment.link)
          .join('\n'),
        uniform_and_badge_photo: standardization.attachments
          .find(attachment => attachment.type === 'uniform-and-badge').attachment.link,
        vehicle_front_photo: standardization.attachments
          .find(attachment => attachment.type === 'vehicle-front').attachment.link,
        vehicle_left_photo: standardization.attachments
          .find(attachment => attachment.type === 'vehicle-left').attachment.link,
        vehicle_right_photo: standardization.attachments
          .find(attachment => attachment.type === 'vehicle-right').attachment.link,
        vehicle_rear_photo: standardization.attachments
          .find(attachment => attachment.type === 'vehicle-rear').attachment.link,
        vehicle_intern_photo: standardization.attachments
          .find(attachment => attachment.type === 'vehicle-intern').attachment.link,
        trunk_lid_or_car_with_emergency_form_photo: standardization.attachments
          .find(attachment => attachment.type === 'vehicle-trunk-lid-or-car-with-emergency-form').attachment.link,
        tires_photos: standardization.attachments
          .filter(attachment => attachment.type === 'vehicle-tire')
          .map(attachment => attachment.attachment.link)
          .join('\n'),
        cnh_status: standardizationStatusMapper[standardization.cnh_status],
        crlv_status: standardizationStatusMapper[standardization.crlv_status],
        motor_freight_certificate_status: standardizationStatusMapper[standardization.motor_freight_certificate_status],
        cleaning_register_status: standardizationStatusMapper[standardization.cleaning_register_status],
        vaccines_status: standardizationStatusMapper[standardization.vaccines_status],
        trainings_status: standardizationStatusMapper[standardization.trainings_status],
        salary_account_status: standardizationStatusMapper[standardization.salary_account_status],
        uniform_and_badge_status: standardizationStatusMapper[standardization.uniform_and_badge_status],
        stickers_status: standardizationStatusMapper[standardization.stickers_status],
        reflective_strip_status: standardizationStatusMapper[standardization.reflective_strip_status],
        red_plate_status: standardizationStatusMapper[standardization.red_plate_status],
        vehicle_visual_cleaning_status: standardizationStatusMapper[standardization.vehicle_visual_cleaning_status],
        vehicle_epi_kit_status: standardizationStatusMapper[standardization.vehicle_epi_kit_status],
        tires_condition_status: standardizationStatusMapper[standardization.tires_condition_status],
      }
    })
      : [],
    headers,
    filename: `relatório-padronizações-${new Date().toISOString()}.csv`,
  }

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
    offset,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  })

  const handleChangePage = (page: number) => setCurrentPage(page)


  const handleReloadPage = () => window.location.reload()

  if (isStandardizationsReportResultError) {
    return (
      <Empty.Root>
        <Empty.ActionButton
          onClick={handleReloadPage}
        >
          Recarregar página
        </Empty.ActionButton>
      </Empty.Root>
    )
  }

  return (

      <Box
        p="6"
        bg="white"
        rounded="md"
      >
        <Heading letterSpacing="tight">Relatório de Padronizações</Heading>

        <StandardizationsReportFilters csvProps={csvProps} />

        {isLoadingStandardizationsReportResult ? (
          <Skeleton mt="6" h="400px" rounded="md" w="full" />
        ) : (
          <TableContainer
          border="1px solid"
          borderColor="gray.200"
          rounded="md"
          mt="6"
        >
          <Table size="sm">
            <Thead>
              <Tr
                h="40px"
                bg="#38c3fa"
              >
                <Th textColor="white">Colaborador</Th>
                <Th textColor="white">Hub</Th>
                <Th textColor="white">Veículo</Th>
                <Th textColor="white">Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {standardizationsReportResult?.standardizations?.slice(offset, offset + 10)?.map((standardization) => {
                return (
                  <Tr key={standardization.id}>
                    <Td>{standardization.name}</Td>
                    <Td>{standardization.hub.name}</Td>
                    <Td>{standardization.vehicle}</Td>
                    <Td>
                      <StandardizationStatus status={standardization.status} />
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
        )}


        <Pagination
          handlePageChange={handleChangePage}
          pagesQuantity={pagesCount}
          pages={pages}
          currentPage={currentPage}
        />
      </Box>

  )
}
