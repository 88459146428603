import { Text, TextProps } from "@chakra-ui/react";

interface ExternalComunicationsMessageContentHeaderProps extends TextProps {
  content: string
}

export function ExternalComunicationsMessageContentHeader({
  content,
  ...rest
}: ExternalComunicationsMessageContentHeaderProps) {
  return (
    <Text
      fontSize="x-small"
      {...rest}
    >
      {content}
    </Text>
  )
}
