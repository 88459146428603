import { Box, Button, Flex, FormControl, FormLabel, Radio, RadioGroup, Stack, Text, Textarea } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup";
import { approveDirectLeaderActiveFeedback } from "api/feedbacks/approveDirectLeaderActiveFeedback";
import { useToastify } from "hooks/toastify/useToastify";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup"


interface DirectLeaderApprovalFormProps {
  activeFeedbackId: string
}

interface DirectLeaderApprovalFormSchema {
  responsability: string
  learnEveryDay: string
  loveMonday: string
  teamResult: string
  doesNotExistsSuccess: string
  catchesAndDoes: string
  noLies: string
  doesKeepCollaborator: string
  doesCollaboratorHasPromotion: string
  nextPeriodMissions: string
}

const directLeaderApprovalFormSchema = yup.object({
  responsability: yup.string().required(),
  learnEveryDay: yup.string().required(),
  loveMonday: yup.string().required(),
  teamResult: yup.string().required(),
  doesNotExistsSuccess: yup.string().required(),
  catchesAndDoes: yup.string().required(),
  noLies: yup.string().required(),
  doesKeepCollaborator: yup.string().required(),
  doesCollaboratorHasPromotion: yup.string().required(),
  nextPeriodMissions: yup.string().required(),
})

const formRadioOptions = [
  'Acima da expectativa',
  'Na média',
  'Abaixo da expectativa'
]

export function DirectLeaderApprovalForm({ activeFeedbackId }: DirectLeaderApprovalFormProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<DirectLeaderApprovalFormSchema>({
    resolver: yupResolver(directLeaderApprovalFormSchema)
  })

  const { mutateAsync: approveDirectLeaderActiveFeedbackFn } = useMutation({
    mutationFn: approveDirectLeaderActiveFeedback,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'actives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'scheduled-actives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'direct-leader-approving-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'executive-leader-approving-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'awaiting-leader-actives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'done-actives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: ['active-feedback', activeFeedbackId] })
    }
  })

  async function handleDirectLeaderApproval(values: DirectLeaderApprovalFormSchema) {
    await promiseMessage(approveDirectLeaderActiveFeedbackFn({
      body: {
        ...values,
        doesKeepCollaborator: values.doesKeepCollaborator === 'yes',
        doesCollaboratorHasPromotion: values.doesCollaboratorHasPromotion === 'yes'
      },
      activeFeedbackId
    }), 'Feedback aprovado!')
  }
  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleDirectLeaderApproval)}
      maxH='500px'
      overflowY='scroll'
    >
      <FormControl isInvalid={!!errors.responsability} mt={3}>
        <FormLabel fontSize='sm'>
          Responsabilidade
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="responsability"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                {Object.entries(formRadioOptions).map(([key, value]) => (
                  <Radio
                    key={key}
                    size='sm'
                    rounded='md'
                    value={value}

                  >{value}</Radio>
                ))}

              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.learnEveryDay} mt={3}>
        <FormLabel fontSize='sm'>
          Aprenda todo dia
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="learnEveryDay"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                {Object.entries(formRadioOptions).map(([key, value]) => (
                  <Radio
                    key={key}
                    size='sm'
                    rounded='md'
                    value={value}

                  >{value}</Radio>
                ))}

              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.loveMonday} mt={3}>
        <FormLabel fontSize='sm'>
          Ame a 2ª feira
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="loveMonday"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                {Object.entries(formRadioOptions).map(([key, value]) => (
                  <Radio
                    key={key}
                    size='sm'
                    rounded='md'
                    value={value}

                  >{value}</Radio>
                ))}

              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.teamResult} mt={3}>
        <FormLabel fontSize='sm'>
          Resultado em equipe
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="teamResult"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                {Object.entries(formRadioOptions).map(([key, value]) => (
                  <Radio
                    key={key}
                    size='sm'
                    rounded='md'
                    value={value}

                  >{value}</Radio>
                ))}

              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.doesNotExistsSuccess} mt={3}>
        <FormLabel fontSize='sm'>
          Não existe sucesso
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="doesNotExistsSuccess"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                {Object.entries(formRadioOptions).map(([key, value]) => (
                  <Radio
                    key={key}
                    size='sm'
                    rounded='md'
                    value={value}

                  >{value}</Radio>
                ))}

              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.catchesAndDoes} mt={3}>
        <FormLabel fontSize='sm'>
          Pega e faz
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="catchesAndDoes"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                {Object.entries(formRadioOptions).map(([key, value]) => (
                  <Radio
                    key={key}
                    size='sm'
                    rounded='md'
                    value={value}

                  >{value}</Radio>
                ))}

              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.noLies} mt={3}>
        <FormLabel fontSize='sm'>
          Sem fingimento
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="noLies"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                {Object.entries(formRadioOptions).map(([key, value]) => (
                  <Radio
                    key={key}
                    size='sm'
                    rounded='md'
                    value={value}

                  >{value}</Radio>
                ))}

              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.doesKeepCollaborator} mt={3}>
        <FormLabel fontSize='sm'>
          Sugere manter colaborador (a)?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="doesKeepCollaborator"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field} >
              <Radio size='sm' mr={2} rounded='md' value="yes">Sim</Radio>
              <Radio size='sm' rounded='md' value="no">Não</Radio>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.doesCollaboratorHasPromotion} mt={3}>
        <FormLabel fontSize='sm'>
          Colaborador (a) terá promoção?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="doesCollaboratorHasPromotion"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Radio size='sm' mr={2} rounded='md' value="yes">Sim</Radio>
              <Radio size='sm' rounded='md' value="no">Não</Radio>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.nextPeriodMissions} mt={3}>
        <FormLabel fontSize="sm">
          Missão (observações) passadas para o próximo semestre
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("nextPeriodMissions")}
          name="nextPeriodMissions"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
