import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Product } from "./_types/Product";


export interface GetProductsParams {
  q?: string
  situation?: string
  page_size?: number
  current_page?: number
}

export interface GetProductsResponse {
  products: Product[]
  totalPages: number
}

export async function getProducts({
  current_page,
  page_size,
  situation,
  q
}: GetProductsParams) {
  try {
    const response = await api.get<GetProductsResponse>('/products', {
      params: {
        current_page,
        page_size,
        situation,
        q
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
