export const inProgressServiceSelectOptions = [
  { key: '0', value: 'toCollectService', showOption: 'Agendado'},
  { key: '1', value: 'collectingService', showOption: 'Coletando serviço'},
  { key: '2', value: 'toValidateCollectAddresses', showOption: 'Pendente validação de endereços para coleta'},
  { key: '3', value: 'toBoardService', showOption: 'Embarcar serviço'},
  { key: '4', value: 'boardingService', showOption: 'Em rota de embarque'},
  { key: '5', value: 'toBoardValidate', showOption: 'Embarcado (validação)'},
  { key: '6', value: 'toAllocateService', showOption: 'Embarcado (alocação)'},
  { key: '7', value: 'toAvailableService', showOption: 'Alocado'},
  { key: '8', value: 'availableService', showOption: 'Aguardando disponibilização'},
  { key: '9', value: 'toLandingService', showOption: 'Disponível'},
  { key: '10', value: 'landingService', showOption: 'Desembarcando serviço'},
  { key: '11', value: 'toDeliveryService', showOption: 'Em rota de entrega'},
  { key: '12', value: 'deliveringService', showOption: 'Entregando serviço'},
  { key: '13', value: 'toValidateAdditionalDeliveryAddresses', showOption: 'Pendente validação de endereços para entrega'},
  { key: '14', value: 'toMaintenanceService', showOption: 'Em manutenção'},
  { key: '15', value: 'ongoingMaintenanceService', showOption: 'Manutenção em andamento'},

]
