import axios from "axios";
import { User } from "hooks/user/dtos/User";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { SupportType } from "utils/supportTypes";
import { SupportSubject } from "./_types/SupportSubject";

export interface GetSupportSubjectsByCategoryParams {
  type?: SupportType | 'all'
  category?: string
}

export interface GetSupportSubjectsByCategoryResponse {
  supportsSubjects: Array<SupportSubject & {
    responsibles: {
      user: User
    }[]
  }>
}

export async function getSupportSubjectsByCategory({
  category,
  type
}: GetSupportSubjectsByCategoryParams) {
  try {
    const response = await api.get<GetSupportSubjectsByCategoryResponse>(`/supports/types/${type}/subjects`, {
      params: {
        category
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
