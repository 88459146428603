import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import { FieldError } from 'react-hook-form'

interface TextAreaProps extends ChakraTextareaProps {
  name: string
  label?: string
  placeholder?: string
  required?: boolean
  error?: FieldError
}

const TextAreaBase: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextAreaProps
> = (
  { name, label, placeholder, required = false, error = null, ...rest },
  ref,
  ) => {
    return (
      <FormControl isInvalid={!!error}>
        {label && (
          <Flex flex="1" justify="space-between" >
            <FormLabel htmlFor={name} noOfLines={1}>
              <Flex h="full" w="full" gap="1" align="center">
                <Text fontSize="sm" color="gray.700" w="full">
                  {label}
                </Text>
                {required && (
                  <Text display="block" mt="2" color="red" fontWeight="bold" fontSize="sm" as="sup">
                    {' '}
                    *
                  </Text>
                )}
              </Flex>
            </FormLabel>
          </Flex>
        )}

        <ChakraTextarea
          name={name}
          placeholder={placeholder}
          bgColor="gray.100"
          variant="filled"
          _hover={{
            bgcolor: 'gray.900',
          }}
          ref={ref}
          {...rest}
        />

        {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      </FormControl>
    )
  }

export const TextArea = forwardRef(TextAreaBase)
