import axios from "axios"
import { useQuery, UseQueryOptions, QueryKey } from "react-query";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { ExtraDiscount } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts";
import { CollectorReportProps } from "./dtos/CollectorReportProps";

interface businessBudgetsProps{
  customer: string
  route_nickname: string
  collector: string
  driver: string
  vehicle: string
  budget: number
}

export interface CollectorReportResponse {
  reportData: CollectorReportProps[]
  businessExtrasDiscounts: Pick<ExtraDiscount, 'id' | 'value' | 'description' | 'type' | 'created_at'>[]
  businessBudgets: businessBudgetsProps[]
}

interface CollectorReportQueryParams {
  startDate: string
  endDate: string
  collectorId?: string
}

type QueryOptions<T> = UseQueryOptions<CollectorReportResponse, unknown, T, QueryKey>

interface UseCrmReportOptions<T> {
  queryKey?: string
  queryOptions?: QueryOptions<T>
  queryParams: CollectorReportQueryParams
}

interface GenerateCollectorReportReqFunctionProps {
  queryParams: CollectorReportQueryParams
}

async function generateCollectorReportReqFunction({
  queryParams
}: GenerateCollectorReportReqFunctionProps) {
  try {
    const { data } = await api.get<CollectorReportResponse>('report/collector', {
      params: {
        ...queryParams
      }
    })
    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError(error.response.data.message)
    }
  }
}

export function useCollectorReport<T = CollectorReportResponse>(options?: UseCrmReportOptions<T>) {
  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    errorMessage(message)

    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) await handleLogout()
  }

  return useQuery([
    options?.queryKey ?? 'collectorReport',
    { ...options.queryParams}
  ], () => generateCollectorReportReqFunction({
    queryParams: options.queryParams
  }), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )
}


