import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { Regional } from "api/regionals/_types/Regional";
import { getTrainings } from "api/trainings/getTrainings";
import { Training, TrainingStatus } from "api/trainings/training";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { Pagination } from "components/Pagination/Pagination";
import { format } from "date-fns";

import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { FaExchangeAlt, FaFileDownload, FaFileExport, FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { trainingCollaboratorTypeMap, trainingStatusMap, trainingTypesMap } from "utils/trainingMappers";
import { CreateTraining } from "./components/CreateTraining";
import { TrainingsTableFilter } from "./components/TrainingsTableFilter";
import { TrainingsTableRow } from "./components/TrainingsTableRow";

const headers = [
  { label: 'COLABORADOR', key: 'collaborator_name' },
  { label: 'TIPO DE COLABORADOR', key: 'collaborator_type' },
  { label: 'REGIONAL', key: 'regional' },
  { label: 'STATUS', key: 'status' },
  { label: 'TREINAMENTO', key: 'type' },
  { label: 'DATA DE VENCIMENTO', key: 'due_date' },
  { label: 'DATA DE REALIZAÇÃO', key: 'realization_date' },

]

function formatValuesToReport(values: Array<Training & { regional: Regional }>) {
  return values?.map((training) => ({
    ...training,
    collaborator_type: trainingCollaboratorTypeMap[training.collaborator_type],
    regional: training?.regional ? training?.regional.code.toUpperCase() : '-',

    due_date: training?.due_date ? format(new Date(training.due_date), 'dd/MM/yyyy') : '-',
    realization_date: training.realization_date ? format(new Date(training.realization_date), 'dd/MM/yyyy') : '-',
    type: trainingTypesMap[training.type],
    status: trainingStatusMap[training?.status]
  }))
}


export function Trainings() {
  const { replace, push: redirect } = useHistory()
  const searchParams = useSearchParams()

  const { userLogged } = useAuth()

  const userHasViewTrainingsPermission = userLogged?.permissions.includes('view-trainings')

  const userHasCreateTrainingPermission = userLogged?.permissions.includes('create-training')

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status') ?? 'all'
  const collaborator = searchParams.get('collaborator')

  const { data: trainingsData } = useQuery({
    queryKey: [
      'trainings',
      page,
      status,
      collaborator,
    ],
    queryFn: () => getTrainings({
      currentPage: page,
      pageSize: '10',
      collaborator,
      status: status as TrainingStatus | 'all'
    }),
    keepPreviousData: true
  })

  useEffect(() => {
    if (!userHasViewTrainingsPermission) {
      redirect('/')
    }
  }, [userHasViewTrainingsPermission, redirect])


  const {
    isOpen: isCreateTrainingModalOpen,
    onOpen: onOpenCreateTrainingModal,
    onClose: onCloseCreateTrainingModal
  } = useDisclosure()

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: trainingsData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: trainingsData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }

  const {
    data: trainingsDataReportResult,
    isLoading: isLoadingTrainingsDataReportResult
  } = useQuery({
    queryKey: [
      'vaccines-report',
      status,
      collaborator,
      trainingsData?.meta?.count,
    ],
    queryFn: () => getTrainings({
      currentPage: '1',
      pageSize: String(trainingsData?.meta?.count),
      status: ["pending", "active", "inactive"].includes(status) ? status as TrainingStatus : 'all',
      collaborator,
    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })

  const csvReportProps = {
    data: trainingsDataReportResult ? formatValuesToReport(trainingsDataReportResult?.trainings) : [],
    headers,
    filename: `treinamentos.csv`,
  }

  return (

    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Treinamentos</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar kanban"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => redirect('/controle/treinamentos/kanban')}
          />
          {userHasCreateTrainingPermission && (
            <IconButton
              aria-label="Criar Treinamento"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateTrainingModal}
            />
          )}
        </Flex>
        <Modal
          isOpen={isCreateTrainingModalOpen}
          onClose={onCloseCreateTrainingModal}
          size="2xl"
          isCentered
        >
          <ModalOverlay />
          <CreateTraining onCloseModal={onCloseCreateTrainingModal} />
        </Modal>
      </Flex>
      <TrainingsTableFilter />
      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Colaborador</Th>
              <Th color="white">Tipo Colaborador</Th>
              <Th color="white">Regional</Th>
              <Th color="white">Treinamento</Th>
              <Th color="white">Data de Vencimento</Th>
              <Th color="white">Data da Realização</Th>
              <Th color="white">Status</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>

            {trainingsData?.trainings?.map((training) => {
              return (
                <TrainingsTableRow training={training} />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {!trainingsDataReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingTrainingsDataReportResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>



  )
}
