import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { ServiceProps } from "contexts/ServiceContext";

interface ServiceStatus extends Pick<ServiceProps, 'id' | 'step' | 'protocol'> {
  service_type: 'DEDICADO' | 'FRACIONADO'
  delivery_date: string
}

interface ServiceStatusResponse {
  service: ServiceStatus
}

type QueryOptions = UseQueryOptions<ServiceStatusResponse, unknown, ServiceStatusResponse, QueryKey>

interface UseServiceStatusOptions {
  serviceId: string
  queryKey?: string
  queryOptions?: QueryOptions
}

export function useServiceStatus(options?: UseServiceStatusOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  async function getServiceStatus() {
    try {
      const { data } = await api.get(`/services/${options.serviceId}/status`)


      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new RequestError("Erro inesperado!")
      }
    }
  }

  return useQuery(options?.queryKey || 'serviceStatus', getServiceStatus, {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
