import { Flex, HStack, Stack } from '@chakra-ui/react'

import { useEffect } from 'react'
import { FaPen } from 'react-icons/fa'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'

import { modal_types } from '../../utils/customLists'
import { handleSwitchMask } from '../../utils/handleChangeMask'

import { shippingSchema } from '../../validations/shippingSchema'

import { useShipping } from '../../hooks/shipping/useShipping'

import { Input } from '../../components/Inputs/Input'
import { Select } from '../../components/Inputs/SelectInput'
import { TextArea } from '../../components/Inputs/TextInput'
import { ListButton } from '../../components/Buttons/ListButton'
import { InputMaskCustom } from '../../components/Inputs/InputMask'
import { SubmitButton } from '../../components/Buttons/SubmitButton'
import { FormActionButton } from '../../components/Buttons/FormActionButton'
import { StandardBackgroundForm } from '../../components/Form/StandardBackgroundForm'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { positiveNegativeOptions } from 'utils/CustomLists/positiveNegativeOptions'
import { useCurrency } from 'react-hook-currency'

interface IFormInputProps {
  company_name: string
  trading_name: string
  cnpj: string
  email: string
  modal: string
  cellphone: string
  telephone: string
  state_register: string | null
  has_board_autofill: 'yes' | 'no'
  cte_transfer_cost: string
  board_weight: string
  real_weight: string
  taxed_weight: string
  is_loglife_payer: string
  is_cte_destination_branch_equal_to_planned: string
  observation: string
}

interface IShippingFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
  submit: (values: IFormInputProps) => Promise<void>
}

export function ShippingForm({
  slug,
  id,
  isDisabled = false,
  href,
  title,
  action,
  submit,
}: IShippingFormProps) {

  const {
    shipp: { data: shipp, isLoading: isShippingLoading },
  } = useShipping(id || null, false, false)

  const { onChange, format } = useCurrency({
    style: 'decimal',
  })

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(shippingSchema),
  })
  const hasBoardAutofill = watch('has_board_autofill') === 'yes'

  useEffect(() => {
    if (slug !== 'adicionar') {
      if (shipp) {
        setValue('company_name', shipp.company_name)
        setValue('trading_name', shipp.trading_name)
        setValue('cnpj', shipp.cnpj)
        setValue('email', shipp.email)
        setValue('modal', shipp.modal)
        setValue('cellphone', shipp.cellphone)
        setValue('telephone', shipp.telephone)
        setValue('state_register', shipp.state_register ?? '')
        setValue('observation', shipp.observation)

        setValue('has_board_autofill', shipp.has_board_autofill ? 'yes' : 'no')

        if (shipp.has_board_autofill) {
          setValue('cte_transfer_cost', String(shipp.cte_transfer_cost_in_cents / 100))
          setValue('board_weight', String(shipp.board_weight))
          setValue('real_weight', String(shipp.real_weight))
          setValue('taxed_weight', String(shipp.taxed_weight))
          setValue('is_loglife_payer', shipp.is_loglife_payer ? 'yes' : 'no')
          setValue('is_cte_destination_branch_equal_to_planned', shipp.is_cte_destination_branch_equal_to_planned ? 'yes' : 'no')
        }
      }
    }
  }, [setValue, slug, shipp])

  if (isShippingLoading) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm onSubmit={handleSubmit(submit)} title={title}>
      <Stack spacing="24px" direction={['column', 'column', 'row']}>
        <Input
          {...register('company_name')}
          isDisabled={isDisabled}
          name="company_name"
          label="Nome Fantasia"
          error={errors.company_name}
          required
        />

        <Input
          {...register('trading_name')}
          name="trading_name"
          label="Razão Social"
          error={errors.trading_name}
          isDisabled={isDisabled}
          required
        />
      </Stack>

      <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Controller
          control={control}
          name="cnpj"
          render={({ field: { onChange, value } }) => (
            <InputMaskCustom
              {...register('cnpj')}
              isDisabled={isDisabled}
              name="cnpj"
              label="CNPJ"
              mask={handleSwitchMask('cnpj')}
              defaultValue={value}
              onChange={onChange}
              error={errors.cnpj}
              required
            />
          )}
        />

        <Input
          {...register('email')}
          isDisabled={isDisabled}
          name="email"
          label="E-mail"
          error={errors.email}
          required
        />
      </Stack>

      <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Select
          {...register('modal')}
          name="modal"
          label="Modal"
          error={errors.modal}
          modal_types={modal_types}
          placeholder="Selecione uma opção..."
          isDisabled={isDisabled}
          required
        />

        <Stack w="full" spacing="24px" direction={['column', 'column', 'row']}>
          <Controller
            control={control}
            name="cellphone"
            render={({ field: { onChange, value } }) => (
              <InputMaskCustom
                {...register('cellphone')}
                mask={handleSwitchMask('cellphone')}
                onChange={onChange}
                defaultValue={value}
                name="cellphone"
                label="Celular"
                error={errors.cellphone}
                isDisabled={isDisabled}
                required
              />
            )}
          />

          <Controller
            control={control}
            name="telephone"
            render={({ field: { onChange, value } }) => (
              <InputMaskCustom
                {...register('telephone')}
                name="telephone"
                defaultValue={value}
                onChange={onChange}
                mask={handleSwitchMask('phone')}
                label="Telefone"
                error={errors.telephone}
                isDisabled={isDisabled}
                required
              />
            )}
          />
        </Stack>
      </Stack>

      <Stack mt="4">
        <Input
          {...register("state_register")}
          name="state_register"
          label="Registro Estadual"
          error={errors.state_register}
          required
        />
      </Stack>
      <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Select
          {...register('has_board_autofill')}
          name='has_board_autofill'
          options={positiveNegativeOptions}
          label='Possui preenchimento automático de validação de embarque?'
          placeholder='Selecione uma opção...'
          error={errors.has_board_autofill}
          required
        />
      </Stack>
      {hasBoardAutofill && (
          <>
            <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
              <Input
                {...register('cte_transfer_cost')}
                name="cte_transfer_cost"
                addChildren="R$"
                label="Valor CTE transferência"
                error={errors.cte_transfer_cost}
                onChange={onChange}
                defaultValue={format('000')}
                isDisabled={isDisabled}
                required
              />
              <Input
                {...register('board_weight')}
                name="board_weight"
                label="Peso total do CTE (sem rateio)"
                onChange={onChange}
                addChildren="KG"
                defaultValue={format('000')}
                isDisabled={isDisabled}
                required
              />
            </Stack>

            <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
              <Input
                {...register('real_weight')}
                name="real_weight"
                addChildren="R$"
                label="Peso bruto para cobrança (rateado)"
                error={errors.real_weight}
                onChange={onChange}
                defaultValue={format('000')}
                isDisabled={isDisabled}
                required
              />
              <Input
                {...register('taxed_weight')}
                name="taxed_weight"
                label="Peso taxado para cobrança (rateado)"
                onChange={onChange}
                addChildren="KG"
                defaultValue={format('000')}
                error={errors.taxed_weight}
                isDisabled={isDisabled}
                required
              />
            </Stack>

            <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
              <Select
                {...register('is_loglife_payer')}
                name='is_loglife_payer'
                options={positiveNegativeOptions}
                label='Tomador do serviço no CTE é Loglife?'
                placeholder='Selecione uma opção...'
                error={errors.is_loglife_payer}
                required
              />
              <Select
                {...register('is_cte_destination_branch_equal_to_planned')}
                name='is_cte_destination_branch_equal_to_planned'
                options={positiveNegativeOptions}
                label='Base destino do CTE é igual ao planejado?'
                placeholder='Selecione uma opção...'
                error={errors.is_cte_destination_branch_equal_to_planned}
                required
              />
            </Stack>
          </>
        )}

      <TextArea
        {...register('observation')}
        name="observation"
        label="Observações"
        isDisabled={isDisabled}
      />

      <Flex mt="8" justify="flex-end">
        <HStack>
          {slug === 'visualizar' ? (
            <FormActionButton href={href} action={action} icon={FaPen} />
          ) : (
            <SubmitButton action={action} isSubmitting={isSubmitting} />
          )}
          <ListButton href="/transportadoras" name="Transportadoras" />
        </HStack>
      </Flex>
    </StandardBackgroundForm>
  )
}
