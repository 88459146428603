import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Divider, Grid, GridItem, HStack, IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, Tbody, Td, Text, Tooltip, Tr, VStack, } from "@chakra-ui/react";
import { getRefund } from "api/refunds/getRefund";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { FaExternalLinkAlt } from "react-icons/fa";

import { useQuery } from "react-query";
import { captalize } from "utils/captalize";
import { AddActivityRefund } from "./AddActivityRefund";
import { CompletRefund } from "./CompletRefund";
import { RefundDetailTable } from "./RefundDetailTable";

interface RefundDetailProps {
  refundId: string
}

export function RefundDetail({ refundId }: RefundDetailProps) {
  const { userLogged } = useAuth()

  const userCanCompleteRefund = userLogged?.permissions?.includes(
    'complete-refund'
  )

  const userCanAddActivityRefund = userLogged?.permissions?.includes(
    'add-refund-activity'
  )

  const {
    data: refundResult,
    isLoading: isLoadingRefundResult
  } = useQuery({
    queryKey: ['refund', refundId],
    queryFn: () => getRefund({ refundId })
  })

  return (
    <ModalContent >
      <ModalHeader>
        Detalhes Reembolso
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        maxH='570px'
        overflowY='scroll'
      >
        {isLoadingRefundResult ? (
          <Table size="sm">
            <Tbody>
              {Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr>
                    <Td>
                      <Skeleton w="full" h="16px" />
                    </Td>
                    <Td isNumeric>
                      <Skeleton w="full" h="16px" />
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>

        ) : (
          <Grid
            templateColumns={
              ['approved', 'reproved'].includes(refundResult?.refund.status) ? "1fr" :
                refundResult?.refund.status === 'refunding'
                  && userCanAddActivityRefund && userCanCompleteRefund
                  ? "1.5fr auto 1fr auto 1fr"
                  : userCanAddActivityRefund || userCanCompleteRefund ?
                    "1fr auto 1fr"
                    : "1fr"
            }
            gap={6}
          >
            <GridItem>
              <RefundDetailTable
                refundResult={refundResult}
              />
              {refundResult?.refund?.activities.length !== 0 && (
                <Accordion allowToggle w="full">
                  <AccordionItem>
                    <AccordionButton>
                      <Box flex="1" textAlign="left" fontSize="sm">
                        Tratativas
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel pb={4} maxH="400px" overflowY="scroll">
                      {refundResult?.refund?.activities.map((activity) => (
                        <Box
                          key={activity.id}
                          borderWidth="1px"
                          borderRadius="lg"
                          p={4}
                          mb={4}
                          bg="gray.50"
                          shadow="md"
                        >
                          <VStack align="flex-start" spacing={3}>
                            <Text fontSize="sm" color="gray.800">
                              {activity.observations}
                            </Text>

                            {activity.attachments.length > 0 && (
                              <Box mt={2}>
                                <Text fontWeight="bold" fontSize="sm" mb={2}>Anexos:</Text>
                                <HStack spacing={4} wrap="wrap">
                                  {activity.attachments.map((attachment) => (
                                    <HStack key={attachment.id} spacing={2}>
                                      <Tooltip label={attachment.attachment.title} fontSize="sm">
                                        <IconButton
                                          aria-label="Abrir anexo"
                                          as={Link}
                                          href={attachment.attachment.link}
                                          icon={<FaExternalLinkAlt />}
                                          size="sm"
                                          isExternal
                                          colorScheme="teal"
                                          variant="outline"
                                          _hover={{ bg: "teal.100" }}
                                        />
                                      </Tooltip>
                                      <Text fontSize="sm" color="gray.700" noOfLines={1} maxW="150px">
                                        {attachment.attachment.title}
                                      </Text>
                                    </HStack>
                                  ))}
                                </HStack>
                              </Box>
                            )}

                            <HStack justifyContent="space-between" w="full" mt={2}>
                              <Text fontSize="sm" color="gray.600">
                                {captalize(`${activity.createdByUser.firstname} ${activity.createdByUser.lastname}`)}

                              </Text>
                              <Text fontSize="xs" color="gray.500">
                                {format(new Date(activity.createdAt), 'dd/MM/yyyy HH:mm')}
                              </Text>
                            </HStack>
                          </VStack>
                        </Box>
                      ))}

                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              )}
            </GridItem>

            {userCanAddActivityRefund && refundResult?.refund.status === 'refunding' && (
              <>
                <Box>
                  <Divider orientation="vertical" />
                </Box>
                <GridItem>
                  <AddActivityRefund
                    refundId={refundId}
                  />
                </GridItem>

              </>
            )}

            {userCanCompleteRefund && refundResult?.refund.status === 'refunding' && (
              <>
                <Box>
                  <Divider orientation="vertical" />
                </Box>

                <GridItem>
                  <CompletRefund
                    refundId={refundId} />
                </GridItem>
              </>
            )}
          </Grid>
        )}
      </ModalBody>
    </ModalContent>
  )
}
