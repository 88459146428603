import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface FormField {
  label: string
  value: string
  type: string
}

export interface FormDataItem {
  [key: string]: FormField
}

interface CreateSupportBody {
  type?: 'Externo' | 'Parceiro' | 'Interno'
  description?: string
  subjectId: string
  requestAttachmentId?: string | null
  externalSupport?: {
    bank?: string
    agency?: number
    accountNumber?: number
    accountOwner?: string
    cpf?: string
    accountType?: string
  }
  partnerSupport?: {
    accountNumber?: number
    bank?: string
    agency?: number
    collectorId?: string
  }
  internSupport?: {
    category?: string
    formData?: FormDataItem
  }
}

interface CreateSupportProps {
  body: CreateSupportBody

}

export async function createSupport({
  body
}: CreateSupportProps) {
  try {
    const response = await api.post(`/supports`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
