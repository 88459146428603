import { Box, Flex, Stack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { SubmitButton } from "../../components/Buttons/SubmitButton";
import { Input } from "../../components/Inputs/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { useHistory, useParams } from "react-router-dom";

interface IResetPasswordFormProps {
  email: string
  has_token: boolean
  new_password: string
  new_password_repeated: string
}

interface IQueryParams {
  token?: string
}

const schema = yup.object().shape({
  has_token: yup.boolean(),
  email: yup.string().when("has_token", {
    is: false,
    then: yup.string().email("Formato de e-mail inválido!").required('Campo Obrigatório')
  }),
  new_password: yup.string().when("has_token", {
    is: true,
    then: yup.string().required()
  }),
  new_password_repeated: yup.string().when("has_token", {
    is: true,
    then: yup.string().oneOf([yup.ref('new_password')], 'Senhas precisam ser iguais!')
  })
})

export function RecoverPasswordPage() {
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordRepeated, setShowPasswordRepeated] = useState(false)

  const { recoverUserPassword, sendForgotUserPassword } = useContext(UserContext)

  const { push: redirectTo } = useHistory()
  const { token }: IQueryParams = useParams()

  const {
    register, handleSubmit, setValue, formState: { isSubmitting, errors }
  } = useForm<IResetPasswordFormProps>({
    resolver: yupResolver(schema)
  })

  async function handleResetPassword(data: IResetPasswordFormProps) {
    const response = await recoverUserPassword({
      new_password: data.new_password,
      token: token!
    })

    if (response) {
      redirectTo('/login')
    }
  }

  async function handleSendRecoverPasswordMail(data: IResetPasswordFormProps) {
    const response = await sendForgotUserPassword(data)

    if (response) {
      redirectTo('/login')
    }
  }

  useEffect(() => {
    if (token) {
      setValue("has_token", true)
    } else {
      setValue("has_token", false)
    }
  })

  return (
    <Flex p={240} justify="center" align="center">
      <Box
        as="form"
        bg="white"
        maxW="600px"
        p="8"
        borderRadius="10"
        onSubmit={handleSubmit(token ? handleResetPassword : handleSendRecoverPasswordMail)}
        noValidate
      >
        <Stack spacing={6}>
          {!token ? (
            <>
              <Input
                {...register("email")}
                name="email"
                label="Digite seu email"
                error={errors.email}
                required
              />
            </>
          ) : (
            <>
              <Input
                {...register("new_password")}
                name="new_password"
                label="Nova Senha"
                minW="300px"
                type={showPassword ? "text" : "password"}
                show
                showPassword={showPassword}
                setShow={setShowPassword}
                error={errors.new_password}
                required
              />
              <Input
                {...register("new_password_repeated")}
                name="new_password_repeated"
                label="Repita a senha"
                minW="300px"
                type={showPasswordRepeated ? "text" : "password"}
                show
                showPassword={showPasswordRepeated}
                setShow={setShowPasswordRepeated}
                error={errors.new_password_repeated}
                required
              />
            </>
          )}


          <SubmitButton
            action="Enviar"
            isSubmitting={isSubmitting}
          />
        </Stack>
      </Box>
    </Flex >
  )
}
