import { Refund } from './_types/Refund';
import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface EditRefundFieldParams {
  refundId: string
  fieldName: keyof Refund
  body: {
    value: unknown
  }
}

export async function editRefundField({
  body,
  refundId,
  fieldName
}: EditRefundFieldParams) {
  try {
    await api.patch(`/refunds/${refundId}/field/${fieldName}`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
