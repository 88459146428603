import { Box, Button, ButtonGroup, Flex, FormControl, FormLabel, HStack, Input, Skeleton, SkeletonCircle, Stack, Text, VStack } from "@chakra-ui/react"
import { getDeliveriesAssignedDriversByServiceId } from "api/service/getDeliveriesAssignedDriversByServiceId"
import { Address } from "hooks/address/dtos/Address"
import { useEffect, useMemo } from "react"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { captalize } from "utils/captalize"
import AsyncSelect from "react-select/async"
import { getAllDrivers, IDriverProps } from "services/getFunctions/driver/getDrivers"
import { editDeliveryAddressesAssignedDrivers } from "api/service/editDeliveryAddressesAssignedDrivers"
import { useToastify } from "hooks/toastify/useToastify"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

interface AssignDriversSchema {
  deliveries: {
    addressId: string
    driversIds: string[]
    volume?: number
  }[]
}

const assignDriversSchema = yup.object().shape({
  deliveries: yup.array().of(yup.object({
    addressId: yup.string().required(),
    driversIds: yup.array().of(yup.string()).min(1),
    volume: yup.mixed().when('$isDedicatedService', {
      is: true,
      then: yup.number().required(),
    })
  }))
})

interface AssignDriversStepProps {
  serviceId: string
  destinationCollectorId: string
}

interface Delivery {
  [key: string]: {
    address: Address
    drivers: IDriverProps[]
    volume?: number
  }
}

export function AssignDriversStep({
  serviceId,
  destinationCollectorId
}: AssignDriversStepProps) {

  const {
    data: deliveriesAssignedDriversResult,
    isLoading: isDeliveriesAssignedDriversResultLoading
  } = useQuery({
    queryKey: ['delivery-addresses-assigned-drivers', serviceId],
    queryFn: () => getDeliveriesAssignedDriversByServiceId({ serviceId }),
  })

  const queryClient = useQueryClient()

  const { mutateAsync: editDeliveryAddressesAssignedDriversFn } = useMutation({
    mutationFn: editDeliveryAddressesAssignedDrivers,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['service', serviceId]
      })

      queryClient.invalidateQueries({
        queryKey: ['delivery-addresses-assigned-drivers', serviceId]
      })
    },
  })

  const { promiseMessage } = useToastify()

  async function handleEditDeliveryAddressesAssignedDrivers(
    values: AssignDriversSchema
  ) {
    await promiseMessage(editDeliveryAddressesAssignedDriversFn({
      body: {
        deliveries: values.deliveries
      },
      serviceId,
    }), 'Motoristas atribuídos editados com sucesso!')
  }

  const isDedicatedService = deliveriesAssignedDriversResult?.deliveriesAssignedDrivers[0]?.service.type === 'DEDICADO'

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    }
  } = useForm<AssignDriversSchema>({
    resolver: yupResolver(assignDriversSchema),
    context: {
      isDedicatedService
    }
  })

  const {
    fields,
    update,
  } = useFieldArray({
    control,
    name: 'deliveries'
  })

  const deliveryDriversByAddress = useMemo(() => {
    return deliveriesAssignedDriversResult?.deliveriesAssignedDrivers?.reduce<Delivery>((deliveries, curr) => {
      if (!deliveries[curr.address.id]) {
        deliveries[curr.address.id] = {
          address: curr.address,
          drivers: [],
        }
      }

      deliveries[curr.address.id].drivers.push(curr.driver)

      if (curr.volume) deliveries[curr.address.id].volume = curr.volume

      return deliveries
    }, {})

  }, [deliveriesAssignedDriversResult])

  useEffect(() => {
    if (deliveryDriversByAddress) {
      Object.entries(deliveryDriversByAddress).forEach(([key, value], index) => {
        update(index, { addressId: key, driversIds: value.drivers.map(driver => driver.id), volume: value.volume })
      })
    }
  }, [deliveryDriversByAddress, update])

  async function promiseOptions(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getAllDrivers()

    return response.filter(driver => {
      const collectorFilter = driver.collector_id === destinationCollectorId
      const nameFilter = (`${driver.firstname} ${driver.lastname}`.toLowerCase()).includes(inputValue.toLowerCase())
      const situationFilter = driver.situation === 'ATIVO'

      return collectorFilter && nameFilter && situationFilter
    }).map(driver => {
      return { value: driver.id, label: captalize(`${driver.firstname} ${driver.lastname}`) }
    })
  }

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(handleEditDeliveryAddressesAssignedDrivers)}
    >

      <Flex
        align="center"
        gap={3}
      >
        <Text
          fontWeight="semibold"
          fontSize="2xl"
          letterSpacing="tight"
        >
          Motoristas atribuídos
        </Text>
      </Flex>

      {isDeliveriesAssignedDriversResultLoading ? (
        Array.from({ length: 3 }).map((_, index) => {
          return (
            <Box
              p={4}
              border="1px"
              rounded="md"
              borderColor="gray.200"
              _notFirst={{ mt: 3 }}
              position="relative"
              key={index.toString()}
            >
              <SkeletonCircle
                position="absolute"
                top={-3}
                left={-3}
              />

              <Skeleton w="100px" h="15px" />
              <Skeleton w="full" h="40px" mt={3} />
              <HStack mt={3} spacing={3}>
                <VStack w="full" align="start">
                  <Skeleton w="100px" h="15px" />
                  <Skeleton w="full" h="40px" />
                </VStack>
                <VStack w="full" align="start">
                  <Skeleton w="100px" h="15px" />
                  <Skeleton w="full" h="40px" />
                </VStack>
              </HStack>
            </Box>
          )
        })
      ) : (
        <>

          <Box mt={3}>
            {fields.map((field, index) => {

              const fieldAddressInfo = deliveryDriversByAddress[field.addressId]

              return (
                <Stack
                  key={field.id}
                  border="1px"
                  rounded="lg"
                  p={3}
                  borderColor="gray.200"
                  position="relative"
                >
                  <Text
                    rounded="full"
                    w={6}
                    h={6}
                    position="absolute"
                    bg="blue.500"
                    color="white"
                    left={-2}
                    outline="4px solid"
                    outlineColor="white"
                    top={-2}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="sm"
                  >
                    {index + 1}
                  </Text>
                  <FormControl>
                    <FormLabel fontSize="sm">Endereço</FormLabel>
                    <Input
                      defaultValue={captalize(
                        `${fieldAddressInfo.address.street}, ${fieldAddressInfo.address.number}, ${fieldAddressInfo.address.neighborhood} ${' '}`
                      )}
                      disabled
                    />
                  </FormControl>

                  <Stack direction={{ base: 'column', lg: 'row' }} spacing={3}>
                    <FormControl isInvalid={errors.deliveries && !!errors.deliveries[index].driversIds}>
                      <FormLabel fontSize="sm">Motorista(s)</FormLabel>
                      <Controller
                        control={control}
                        name={`deliveries.${index}.driversIds`}
                        render={({ field }) => (
                          <AsyncSelect
                            name={field.name}
                            // value={field.value}
                            onChange={(arr) => field.onChange(arr?.map(v => v.value))}
                            cacheOptions
                            defaultOptions
                            defaultValue={fieldAddressInfo?.drivers?.map(driver => {
                              return {
                                value: driver.id,
                                label: captalize(`${driver.firstname} ${driver.lastname}`)
                              }
                            })}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                padding: '1px',
                                borderRadius: '6px',
                                border: state.isFocused ? 'none' : '',
                                boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                              })
                            }}
                            placeholder="Motorista(s)..."
                            loadOptions={promiseOptions}
                            isClearable={true}
                            isMulti
                          />
                        )}
                      />
                    </FormControl>

                    {isDedicatedService && (
                      <FormControl isInvalid={errors.deliveries && !!errors.deliveries[index].volume}>
                        <FormLabel fontSize="sm">Quantidade de volumes e amostras/bags</FormLabel>
                        <Input
                          {...register(`deliveries.${index}.volume`)}
                          name={`deliveries.${index}.volume`}
                          type="number"
                        />
                      </FormControl>
                    )}
                  </Stack>
                </Stack>
              )
            })}

          </Box>
          <ButtonGroup
            mt={3}
            w="full"
            justifyContent="end"
          >
            <Button
              type="submit"
              colorScheme="blue"
              isLoading={isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </>
      )}

    </Box >
  )
}
