import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Divider, Flex, Grid, GridItem, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { formatDistance } from "date-fns";
import ptBR from "date-fns/locale/pt-BR"
import { ComunicationAnswer, ComunicationQuestion } from "hooks/internComunication/dtos/InternComunication";
import { Fragment } from "react";
import { FaRegClock } from "react-icons/fa";

interface InternComunicationHistory {
  id: number
  question: string
  question_date: Date
  answer?: string
  answer_date?: Date
  is_question_answered: boolean
}

interface InternComunicationHistoryModalProps {
  onCloseInternComunicationHistoryModal: () => void
  isInternComunicationHistoryModalOpen: boolean
  comunicationQuestions: ComunicationQuestion[]
  comunicationAnswers: ComunicationAnswer[]
}

export function InternComunicationHistoryModal({
  isInternComunicationHistoryModalOpen,
  onCloseInternComunicationHistoryModal,
  comunicationQuestions,
  comunicationAnswers
}: InternComunicationHistoryModalProps) {


  const internComunicationsHistory = comunicationQuestions
  ? comunicationQuestions?.reduce(
    (acc, comunicationQuestion) => {
      const existsQuestionAnswer = comunicationAnswers?.find(
        answer => answer.id === comunicationQuestion.id
      )

      acc.push({
        id: comunicationQuestion.id,
        question: comunicationQuestion.description,
        question_date: comunicationQuestion.question_date,
        answer: existsQuestionAnswer?.description,
        answer_date: existsQuestionAnswer?.answer_date,
        is_question_answered: existsQuestionAnswer ? true : false
      })

      return acc
    }, [] as InternComunicationHistory[])
  : []

  return (
    <Modal
      isOpen={isInternComunicationHistoryModalOpen}
      onClose={onCloseInternComunicationHistoryModal}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Histórico da comunicação interna</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Accordion allowMultiple>
            <AccordionItem>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Histórico
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                {internComunicationsHistory.map(internComunicationHistory => {
                  return (
                    <Fragment key={internComunicationHistory.id}>
                      <Grid
                        p={2}
                        templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]}
                        gap={6}
                      >
                        <GridItem w="100%">
                          <Flex w="full" direction="column" gap={2}>
                            <Text as="i" fontWeight="bold">Solicitação</Text>
                            <Flex direction="row" align="center" gap={1}>
                              <Icon alignSelf="flex-start" fontSize="sm" as={FaRegClock} opacity={0.8} />
                              <Text fontSize="sm" opacity={0.8}>
                                {formatDistance(new Date(internComunicationHistory.question_date), new Date(), { addSuffix: true, locale: ptBR })}
                              </Text>
                            </Flex>
                            <Text maxW='200px' dangerouslySetInnerHTML={{ __html: internComunicationHistory.question?.replace(/\n/g, "<br>")}} />
                          </Flex>
                        </GridItem>
                        <GridItem w="100%">
                          {internComunicationHistory.is_question_answered ? (
                            <Flex direction="column" gap={2}>
                              <Text as="i" fontWeight="bold">Resposta</Text>
                              <Flex direction="row" align="center" gap={1}>
                                <Icon alignSelf="flex-start" fontSize="sm" as={FaRegClock} opacity={0.8} />
                                <Text fontSize="sm" opacity={0.8}>
                                  {formatDistance(new Date(internComunicationHistory.answer_date as Date), new Date(), { addSuffix: true, locale: ptBR })}
                                </Text>
                              </Flex>
                              <Text>{internComunicationHistory.answer}</Text>
                            </Flex>
                          ) : (
                            <Flex direction="column" gap={2}>
                              <Text as="i" fontWeight="bold">Resposta</Text>
                              <Text fontSize="sm">Aguardando resposta</Text>
                            </Flex>
                          )}
                        </GridItem>
                      </Grid>
                      <Divider />
                    </Fragment>
                  )
                })}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
