import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Flex, ModalFooter, Button, Link, FormLabel, Icon, Text, TableContainer, Table, Tbody, Tr, Td, Spinner } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { Input } from "components/Inputs/Input";
import { useSwal } from "hooks/swal/useSwal"
import { useForm, useWatch } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import * as yup from "yup"
import { FiFile } from "react-icons/fi";
import { validateHasFile } from "utils/fileValidation";
import { positiveNegativeOptions } from "utils/CustomLists/positiveNegativeOptions";
import { Radio } from "components/Inputs/RadioInput";
import { getPurchaseOrder } from "api/purchaseOrders/getPurchaseOrder";
import { updateInvoicePurchaseOrder } from "api/purchaseOrders/updateInvoicePurchaseOrder";
import { GetPurchaseOrdersResponse } from "api/purchaseOrders/getPurchaseOrders";


const supportedFileFormats = [
  "image/jpeg",
  "image/pjpeg",
  "image/jpg",
  "image/png",
  "text/csv",
  "text/plain",
  "application/pdf",
  "application/zip",
  "application/gzip",
  "application/x-zip-compressed",
  "application/x-rar-compressed",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/xml",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation"
]

interface InvoicePurchaseOrderModalInputs {
  invoice: FileList
  hasCfop: 'yes' | 'no'
}

interface InvoicePurchaseOrderModalProps {
  purchaseOrderId: string
  isInvoiceModalOpen: boolean
  onCloseInvoiceModal: () => void
}

const invoiceModalSchema = yup.object().shape({
  invoice: yup.mixed()
    .test('validateHasFile', 'Campo obrigatório', (value: FileList) => validateHasFile([value]))
  ,
  hasCfop: yup.mixed().required('Campo obrigatório'),
})

export function InvoicePurchaseOrderModal({
  isInvoiceModalOpen,
  onCloseInvoiceModal,
  purchaseOrderId
}: InvoicePurchaseOrderModalProps) {

  const history = useHistory()
  const { data: purchaseOrderData, isLoading: isPurchaseOrderDataLoading } = useQuery({
    queryKey: ['purchaseOrder', purchaseOrderId],
    queryFn: () => getPurchaseOrder({ purchaseOrderId }),
    enabled: isInvoiceModalOpen,
  })

  const purchaseOrder = purchaseOrderData?.purchaseOrder
  const isPurchaseOrderTypePmc = purchaseOrder?.purchase_description === 'PMC'

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting }
  } = useForm<InvoicePurchaseOrderModalInputs>({
    resolver: yupResolver(invoiceModalSchema)
  })

  const invoiceAttachment = useWatch({
    control,
    name: 'invoice'
  })

  const { standardMessage, confirmMessage } = useSwal()


  const { mutateAsync: updateInvoicePurchaseOrderFn } = useMutation({
    mutationFn: updateInvoicePurchaseOrder,
    onSuccess(_data, { input, purchaseOrderId }) {
      const cachedPurchaseOrders = queryClient.getQueriesData<GetPurchaseOrdersResponse>({
        queryKey: ['purchaseOrders']
      })
      queryClient.invalidateQueries(['purchaseOrders'])
      cachedPurchaseOrders.forEach(([cachedKey, cachedData]) => {
        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          purchaseOrders: cachedData.purchaseOrders.map((purchaseOrder) => {
            if (purchaseOrder.id === purchaseOrderId) {
              return {
                ...purchaseOrder,
              }
            }

            return purchaseOrder
          })
        })
      })

      onCloseInvoiceModal()
    }
  })

  const queryClient = useQueryClient()

  async function handleUpdateInvoicePurchaseOrder(
    values: InvoicePurchaseOrderModalInputs
  ) {
    const hasInvoiceInPurchaseOrder = await confirmMessage({ title: "Deseja anexar a nota fiscal?" })

    if (hasInvoiceInPurchaseOrder) {
      const formData = new FormData()
      formData.append('invoice', values.invoice[0])
      formData.append('hasCfop', values.hasCfop)
      await updateInvoicePurchaseOrderFn({ input: formData, purchaseOrderId: purchaseOrderId }, {
        onSuccess: async () => {
          reset()
          history.replace({ state: undefined })
          onCloseInvoiceModal()
        }
      })
      await queryClient.invalidateQueries('purchaseOrdersAwaitingInvoice')
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  function handleCloseModal() {
    history.replace({ state: undefined })
    onCloseInvoiceModal()
  }

  const attachmentPhotoPreview = invoiceAttachment
    ? Object.entries(invoiceAttachment).map(([key, value]) => URL.createObjectURL(value))[0]
    : null

  return (
    <Modal
      isOpen={isInvoiceModalOpen}
      onClose={handleCloseModal}
    >
      <ModalOverlay />
      <ModalContent
        as="form"
        noValidate
        onSubmit={handleSubmit(handleUpdateInvoicePurchaseOrder)}
      >
        <ModalHeader letterSpacing="tight">Adicionar Nota Fiscal</ModalHeader>
        <ModalCloseButton />
        <ModalBody
        >
          {isPurchaseOrderDataLoading ? (
            <Spinner/>
          ): (
          <TableContainer>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Td>Protocolo</Td>
                  <Td isNumeric>{purchaseOrder?.protocol}</Td>
                </Tr>
                <Tr>
                  <Td>Tipo</Td>
                  <Td isNumeric>{purchaseOrder?.type}</Td>
                </Tr>
                <Tr>
                  <Td>Descrição da compra</Td>
                  <Td isNumeric>{purchaseOrder?.purchase_description}</Td>
                </Tr>

                {!isPurchaseOrderTypePmc && (
                  <Tr>
                    <Td>Motivo da compra</Td>
                    <Td isNumeric alignSelf='flex-end' display="flex" justifyContent="flex-end">
                      <Text w='220px' whiteSpace="pre-line">{purchaseOrder?.purchase_reason}</Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
          )}
          <Flex direction="column" gap={6} p={4}>
            <Radio
              {...register('hasCfop')}
              name='hasCfop'
              error={errors.hasCfop}
              radioOptions={positiveNegativeOptions}
              label='Conferiu se a nota está com CFOP de venda a contribuinte do ICMS ? Não pode ser CFOPs 6.107/6.108'
              placeholder='Selecione uma opção...'
              required
            />
            <Input
              {...register("invoice")}
              name="invoice"
              type="file"
              accept={supportedFileFormats.join(", ")}
              hidden
              label="Nota Fiscal"
              error={errors.invoice}
              required
            >
              <Button
                leftIcon={<Icon as={FiFile} />}
                as={FormLabel}
                htmlFor="invoice"
                _hover={{
                  cursor: "pointer"
                }}
              >Escolha um arquivo</Button>
            </Input>
            {attachmentPhotoPreview && (
              <Link
                href={URL.createObjectURL(invoiceAttachment[0])}
                isExternal
              >
                <Text as='b'>{invoiceAttachment[0].name} (Visualizar)</Text>
              </Link>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex gap={2}>
            <Button variant='ghost' onClick={handleCloseModal}>
              Fechar
            </Button>
            <Button isLoading={isSubmitting} type="submit" colorScheme='blue'>
              Enviar
            </Button>
          </Flex>

        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
