import { User } from 'hooks/user/dtos/User'
import { api } from '../../api'

type LogDetail = {
  [Property in keyof CitiesProps]: {
    prev_value: CitiesProps[Property]
    new_value: CitiesProps[Property]
  }
}

export interface CitiesLogs {
  id: string
  log_detail: LogDetail
  created_at: string
  updated_at: string
  createdAt: string
  updatedAt: string
  city_id: string
  updated_by: string
  updatedBy: Pick<User, 'id' | 'firstname' | 'lastname'>
}

export type CitiesProps = {
  id: string
  name: string
  state: string
  hub_id: string
  schedule_deadline: string
  opening_deadline: number
  start_attendance_block: string
  final_attendance_block: string
  saturday_start_attendance_block: Date
  saturday_final_attendance_block: Date
  sunday_start_attendance_block: Date
  sunday_final_attendance_block: Date
  days_service: number[]
  situation: string
  observation: string
  hubIDCity: {
    name: string
  }
  logs: CitiesLogs[]
  createdBy: User
}

const getAllCities = (state?: string) =>
  api
    .get<CitiesProps[]>('/city', {
      params: {
        state,
      }
    })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err)
    })

const getOneCity = (cityId: string) =>
  api
    .get<CitiesProps>(`/city/${cityId}`)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err)
    })

export { getAllCities, getOneCity }
