import { Box, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { format } from "date-fns";
import { FaCalendarAlt, FaUserTag } from "react-icons/fa";

import { Training } from "api/trainings/training";
import { TrainingDetail } from "./TrainingDetail";
import { trainingCollaboratorTypeMap, trainingTypesMap } from "utils/trainingMappers";


interface TrainingsKanbanCardProps {
  training: Training
}

export function TrainingsKanbanCard({ training }: TrainingsKanbanCardProps) {

  const {
    isOpen: isTrainingDetailModalOpen,
    onToggle: onToggleTrainingDetailModal,
  } = useDisclosure()


  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleTrainingDetailModal}
      cursor='pointer'
    >

      <Text fontWeight="bold" mb={2}>
        {training?.collaborator_name}
      </Text>

      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">

        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA SOLICITAÇÃO</Text>
          </HStack>
          <Text ml={5}>{format(new Date(training?.created_at), "dd/MM/yyyy")}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaUserTag} />
            <Text fontSize="xs" fontWeight="bold">TIPO COLABORADOR</Text>
          </HStack>
          <Text ml={5}>{trainingCollaboratorTypeMap[training.collaborator_type]}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaUserTag} />
            <Text fontSize="xs" fontWeight="bold">TREINAMENTO</Text>
          </HStack>
          <Text ml={5}>{trainingTypesMap[training.type]}</Text>
        </Box>

      </VStack>
      <Modal
        isOpen={isTrainingDetailModalOpen}
        onClose={onToggleTrainingDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <TrainingDetail
          trainingId={training.id}
          onClose={onToggleTrainingDetailModal}
        />
      </Modal>
    </Box>
  )
}
