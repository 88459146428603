import { Button, Flex, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { getPmc } from "api/pmcs/getPmc";
import { useQuery } from "react-query";
import { ReceivePmcProductForm } from "./ReceivePmcForm";

interface ReceivePmcProps {
  pmcId: string
  onCloseModal: () => void

}

export function ReceivePmc({ pmcId, onCloseModal }: ReceivePmcProps) {

  const { data: pmcData } = useQuery({
    queryKey: ['pmc', pmcId],
    queryFn: () => getPmc({ pmcId })
  })

  return (
    <ModalContent>
      <ModalHeader>Pmc: {pmcData?.pmc.protocol}</ModalHeader>
      <ModalCloseButton />

      <ModalBody
        maxHeight="600px"
        overflowY="scroll"
      >
        <TableContainer>
          <Table size="sm">
            <Thead
              h="40px"
              bg="#38c3fa"
            >
              <Tr>
                <Th textColor="white">Produto</Th>
                <Th textColor="white">Fornecedor</Th>
                <Th textColor="white">Medida</Th>
                <Th textColor="white">Custo unitário</Th>
                <Th textColor="white">Quantidade</Th>
                <Th textColor="white">Custo estimado</Th>
                <Th textColor="white">Quantidade entregue</Th>
              </Tr>
            </Thead>
            <Tbody>
              {pmcData?.pmc?.pmcProducts.filter((pmcProduct) => pmcProduct.delivered_at === null).map((pmcProduct) => {
                return (
                  <Tr key={pmcProduct.product_id}>
                    <Td>{pmcProduct.product.material.material_name}</Td>
                    <Td>{pmcProduct.product.generalProvider.trading_name}</Td>
                    <Td>{pmcProduct.product.measure}</Td>
                    <Td>{(pmcProduct.product.unit_cost / 100).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })}</Td>
                    <Td>{pmcProduct.approved_quantity}</Td>
                    <Td>{((pmcProduct.product.unit_cost / 100) * pmcProduct.approved_quantity).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })}</Td>
                    <Td>
                      <ReceivePmcProductForm
                        pmcId={pmcData?.pmc.id}
                        productId={pmcProduct.product_id}
                        onCloseModal={onCloseModal}
                      />
                    </Td>
                  </Tr>
                )
              })}

            </Tbody>
          </Table>
        </TableContainer>

        <Flex
          mt="2"
          gap="2"
          justifyContent="flex-end"
        >
          <Button
            type="button"
            size="sm"
            variant="outline"
            onClick={onCloseModal}
          >
            Cancelar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
