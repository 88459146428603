import axios from "axios"
import { set } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { useQuery, UseQueryOptions } from "react-query"
import { api } from "services/api"
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum"
import { RequestError } from "utils/errors/RequestErrors"
import { InBoardServiceData } from "./dtos/InBoardService"

interface InBoardServicesQueryParams {
  page_size?: number
  current_page?: number
  protocol?: string
  collect_date?: string
  board_date?: string
  board_hour?: string
  shipping?: string
  source_branch?: string
  destination_branch?: string
  customer?: string
  source_city?: string
  destination_city?: string
  delivery_date?: string
  collector?: string
  collector_id?: string
}

type QueryOptions = UseQueryOptions<InBoardServiceData, unknown, InBoardServiceData, ["inBoardServices"]>

interface UseInBoardServicesOptions {
  queryParams?: InBoardServicesQueryParams,
  queryOptions?: QueryOptions
}

async function getInBoardServicesReqFunction(queryParams?: InBoardServicesQueryParams) {
  try {
    const { data } = await api.get('/service/list/in-board', {
      params: {
        ...queryParams,
        board_hour: queryParams?.board_hour
          ? set(new Date(), {
            hours: Number(queryParams?.board_hour.split(':')[0]),
            minutes: Number(queryParams?.board_hour.split(':')[1])
          })
          : undefined,
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

export function useInBoardServices(options?: UseInBoardServicesOptions) {

  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  return useQuery([
    'inBoardServices',
    { ...options?.queryParams }
  ],
    () => getInBoardServicesReqFunction(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )

}
