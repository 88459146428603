import { AdvertenceStatus, AdvertenceType, RegionalType, UserType } from "api/advertences/types/Advertence";

export const userTypeMap: Record<UserType, string> = {
  llm: 'LLM',
  collector: 'Coletador',
  bearer: 'Portador',
  motorcyclist: 'Motociclista',
  driver: 'Motorista',
  aggregate: 'Agregado'
}

export const advertenceTypeMap: Record<AdvertenceType, string> = {
  foul: 'Falta',
  point: 'Ponto',
  other: 'Outro'
}

export const regionalTypeMap: Record<RegionalType, string> = {
  sp: 'SP',
  mg: 'MG',
  rj: 'RJ',
  df: 'DF',
  other: 'Outro'
}

export const advertenceStatusMap: Record<AdvertenceStatus, string> = {
  "awaiting-application": 'Aguardando aplicação',
  "parent-verification": 'Aguardando verificação da matriz',
  cancelled: 'Cancelado',
  done: 'Concluído'
}
