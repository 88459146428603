import { Box, Flex, Spinner } from "@chakra-ui/react"
import { FaUser, FaPlaneDeparture, FaPlaneArrival, FaClock, FaShippingFast } from "react-icons/fa"
import { getTimezoneNameByValue } from "utils/getTimezoneNameByValue"
import { IHubsProps } from "utils/RequestFunctions/Hubs/requestHubFunctions"
import { EmptyContentTableAlert } from "../../../../../components/Alerts/EmptyContentTableAlert"
import { SearchBox } from "../../../../../components/SearchBox/SearchBox"
import { BranchProps } from "../../../../../contexts/BranchContext"
import { BudgetProps } from "../../../../../contexts/BudgetContext"
import { ServiceProps } from "../../../../../contexts/ServiceContext"
import { useKanbanSearchBox } from "../../../../../hooks/useKanbanSearchBox"
import { CitiesProps } from "../../../../../services/getFunctions/city/getCity"
import { formatDate } from "../../../../../utils/DateFunctions/formatDate"
import { searchBoxFilter } from "../../../../../utils/searchBoxFilter"
import { sortByDate } from "../../../../../utils/SortFunctions/sortByDate"
import { sortByHour } from "../../../../../utils/SortFunctions/sortByHour"
import { KanbanCard } from "../KanbanCard/KanbanCard"
import { KanbanCardHeader } from "../KanbanCardHeader/KanbanCardHeader"
import { KanbanCardItem } from "../KanbanCardItem/KanbanCardItem"

interface IKanbanToBoardAndBoardingServices {
  services?: ServiceProps[]
  budgets?: BudgetProps[]
  cities?: CitiesProps[]
  branches?: BranchProps[]
  hubs: IHubsProps[]
}

export function KanbanToBoardAndBoardingServices({ services, budgets, cities, branches, hubs }: IKanbanToBoardAndBoardingServices) {
  const { handleSearchServices, isLoadingSearch, searchedWords } = useKanbanSearchBox()

  const filteredSteps = ["toBoardService", "boardingService"]

  const toBoardAndBoardingServices = services
    ?.filter(service => filteredSteps.includes(service.step)) || []

  const orderedToBoardAndBoardingServices = toBoardAndBoardingServices
    .sort((a, b) => {
      const currentIsCrossdockingService = a.serviceIDRequested.crossdocking_collector_id !== null
      const nextIsCrossdockingService = b.serviceIDRequested.crossdocking_collector_id !== null

      let currentBoardDateToCompare: Date | string
      let currentBoardHourToCompare: Date | string
      let nextBoardDateToCompare: Date | string
      let nextBoardHourToCompare: Date | string

      if (currentIsCrossdockingService) {
        const currentCrossdockingCollectorId = a.serviceIDRequested.crossdocking_collector_id

        const isCrossdockingBoardFinished =
          a.serviceIDBoard.find(board => board.collector_id === currentCrossdockingCollectorId) !== undefined &&
          a.serviceIDBoard.find(board => board.collector_id === currentCrossdockingCollectorId)?.step === "DONE"

        if (isCrossdockingBoardFinished) {
          currentBoardDateToCompare = a.serviceIDRequested.board_date!
          currentBoardHourToCompare = a.serviceIDRequested.board_hour!
        } else {
          currentBoardDateToCompare = a.serviceIDRequested.crossdocking_board_date!
          currentBoardHourToCompare = a.serviceIDRequested.crossdocking_board_hour!
        }
      } else {
        currentBoardDateToCompare = a.serviceIDRequested.board_date!
        currentBoardHourToCompare = a.serviceIDRequested.board_hour!
      }

      if (nextIsCrossdockingService) {
        const nextCrossdockingCollectorId = b.serviceIDRequested.crossdocking_collector_id

        const isCrossdockingBoardFinished =
          b.serviceIDBoard.find(board => board.collector_id === nextCrossdockingCollectorId) !== undefined &&
          b.serviceIDBoard.find(board => board.collector_id === nextCrossdockingCollectorId)?.step === "DONE"

        if (isCrossdockingBoardFinished) {
          nextBoardDateToCompare = b.serviceIDRequested.board_date!
          nextBoardHourToCompare = b.serviceIDRequested.board_hour!
        } else {
          nextBoardDateToCompare = b.serviceIDRequested.crossdocking_board_date!
          nextBoardHourToCompare = b.serviceIDRequested.crossdocking_board_hour!
        }
      } else {
        nextBoardDateToCompare = b.serviceIDRequested.board_date!
        nextBoardHourToCompare = b.serviceIDRequested.board_hour!
      }

      return sortByDate(currentBoardDateToCompare, nextBoardDateToCompare) ||
        sortByHour(currentBoardHourToCompare, nextBoardHourToCompare) ||
        0
    })

  const servicesFilteredByUser = orderedToBoardAndBoardingServices.filter(service => {
    const budgetFiltered = budgets
      ?.find(budget => budget.id === service.serviceIDRequested.budget_id)

    const sourceCities = cities
      ?.filter(city => budgetFiltered?.source_cities.includes(city.id))
      .map(city => city.name)
      .join(', ')

    const destinationCities = cities
      ?.filter(city => budgetFiltered?.destination_cities.includes(city.id))
      .map(city => city.name)
      .join(', ')

    const collectDate = formatDate.handle(service.serviceIDRequested.collect_date, "DateWithoutHourToShow")
    const collectHourStart = formatDate.handle(service.serviceIDRequested.collect_hour_start, "DateOnlyWithHourMinute")
    const collectHourEnd = formatDate.handle(service.serviceIDRequested.collect_hour_end, "DateOnlyWithHourMinute")

    const crossdockingCollectorId = service.serviceIDRequested.crossdocking_collector_id
    const hasCrossdocking = crossdockingCollectorId !== null


    const boardDate = hasCrossdocking
      ? service.serviceIDBoard.find(board => board.collector_id === crossdockingCollectorId) !== undefined
        ? service.serviceIDBoard.find(board => board.collector_id === crossdockingCollectorId)?.step === "DONE"
          ? formatDate.handle(service.serviceIDRequested.board_date, "DateWithoutHourToShow")
          : formatDate.handle(service.serviceIDRequested.crossdocking_board_date, "DateWithoutHourToShow")
        : formatDate.handle(service.serviceIDRequested.crossdocking_board_date, "DateWithoutHourToShow")
      : formatDate.handle(service.serviceIDRequested.board_date, "DateWithoutHourToShow")

    const boardHour = hasCrossdocking
      ? service.serviceIDBoard.find(board => board.collector_id === crossdockingCollectorId) !== undefined
        ? service.serviceIDBoard.find(board => board.collector_id === crossdockingCollectorId)?.step === "DONE"
          ? formatDate.handle(service.serviceIDRequested.board_hour, "DateOnlyWithHourMinute")
          : formatDate.handle(service.serviceIDRequested.crossdocking_board_hour, "DateOnlyWithHourMinute")
        : formatDate.handle(service.serviceIDRequested.crossdocking_board_hour, "DateOnlyWithHourMinute")
      : formatDate.handle(service.serviceIDRequested.board_hour, "DateOnlyWithHourMinute")

    const shipping = hasCrossdocking
      ? service.serviceIDBoard.find(board => board.collector_id === crossdockingCollectorId) !== undefined
        ? service.serviceIDBoard.find(board => board.collector_id === crossdockingCollectorId)?.step === "DONE"
          ? branches?.find(branch => branch.id === service.serviceIDRequested.source_crossdocking_branch_id)?.shippingIDBranch.trading_name
          : branches?.find(branch => branch.id === service.serviceIDRequested.source_branch_id)?.shippingIDBranch.trading_name
        : branches?.find(branch => branch.id === service.serviceIDRequested.source_branch_id)?.shippingIDBranch.trading_name
      : branches?.find(branch => branch.id === service.serviceIDRequested.source_branch_id)?.shippingIDBranch.trading_name

    const deliveryDate = formatDate.handle(service.serviceIDRequested.delivery_date, "DateWithoutHourToShow")
    const deliveryHour = formatDate.handle(service.serviceIDRequested.delivery_hour, "DateOnlyWithHourMinute")

    const filter = searchBoxFilter(
      `${service.customerIDService.trading_firstname} ${sourceCities} ${destinationCities} ${collectDate} ${collectHourStart} ${collectHourEnd} ${boardDate} ${boardHour} ${shipping} ${deliveryDate} ${deliveryHour}`,
      searchedWords
    )

    if (searchedWords === '') return service

    return filter

  })

  return (
    <Flex direction="column">
      <SearchBox
        placeholder="Filtrar palavras..."
        handleSearch={(event) => handleSearchServices(event)}
        width="full"
      />
      <Flex
        overflowY="auto"
        maxHeight={1000}
        flexDirection="column"
        gap="4"
        sx={{
          '&::-webkit-scrollbar': {
            width: '10px',
          },
          '&::-webkit-scrollbar-track': {
            width: '10px',
            bg: 'gray.100',
          },
          '&::-webkit-scrollbar-thumb': {
            bg: 'gray.300',
            width: '4px',
            borderRadius: '24px',
          },
        }}
      >
        {isLoadingSearch ? (
          <Spinner />
        ) : (
          <>
            {!servicesFilteredByUser.length ? (
              <EmptyContentTableAlert
                title="Oops"
                description="Não foram encontrados resultados para o filtro. Tente novamente!"
              />
            ) : (
              servicesFilteredByUser.map(service => {
                const budgetFiltered = budgets
                  ?.find(budget => budget.id === service.serviceIDRequested.budget_id)

                const sourceCities = cities
                  ?.filter(city => budgetFiltered?.source_cities.includes(city.id))
                  .map(city => city.name)
                  .join(', ')

                const destinationCities = cities
                  ?.filter(city => budgetFiltered?.destination_cities.includes(city.id))
                  .map(city => city.name)
                  .join(', ')

                const collectDate = formatDate.handle(service.serviceIDRequested.collect_date, "DateWithoutHourToShow")
                const collectHourStart = formatDate.handle(service.serviceIDRequested.collect_hour_start, "DateOnlyWithHourMinute")
                const collectHourEnd = formatDate.handle(service.serviceIDRequested.collect_hour_end, "DateOnlyWithHourMinute")

                const crossdockingCollectorId = service.serviceIDRequested.crossdocking_collector_id
                const hasCrossdocking = crossdockingCollectorId !== null


                const boardDate = hasCrossdocking
                  ? service.serviceIDBoard.find(board => board.collector_id === crossdockingCollectorId) !== undefined
                    ? service.serviceIDBoard.find(board => board.collector_id === crossdockingCollectorId)?.step === "DONE"
                      ? formatDate.handle(service.serviceIDRequested.board_date, "DateWithoutHourToShow")
                      : formatDate.handle(service.serviceIDRequested.crossdocking_board_date, "DateWithoutHourToShow")
                    : formatDate.handle(service.serviceIDRequested.crossdocking_board_date, "DateWithoutHourToShow")
                  : formatDate.handle(service.serviceIDRequested.board_date, "DateWithoutHourToShow")

                const boardHour = hasCrossdocking
                  ? service.serviceIDBoard.find(board => board.collector_id === crossdockingCollectorId) !== undefined
                    ? service.serviceIDBoard.find(board => board.collector_id === crossdockingCollectorId)?.step === "DONE"
                      ? formatDate.handle(service.serviceIDRequested.board_hour, "DateOnlyWithHourMinute")
                      : formatDate.handle(service.serviceIDRequested.crossdocking_board_hour, "DateOnlyWithHourMinute")
                    : formatDate.handle(service.serviceIDRequested.crossdocking_board_hour, "DateOnlyWithHourMinute")
                  : formatDate.handle(service.serviceIDRequested.board_hour, "DateOnlyWithHourMinute")

                const shipping = hasCrossdocking
                  ? service.serviceIDBoard.find(board => board.collector_id === crossdockingCollectorId) !== undefined
                    ? service.serviceIDBoard.find(board => board.collector_id === crossdockingCollectorId)?.step === "DONE"
                      ? branches?.find(branch => branch.id === service.serviceIDRequested.source_crossdocking_branch_id)?.shippingIDBranch.trading_name
                      : branches?.find(branch => branch.id === service.serviceIDRequested.source_branch_id)?.shippingIDBranch.trading_name
                    : branches?.find(branch => branch.id === service.serviceIDRequested.source_branch_id)?.shippingIDBranch.trading_name
                  : branches?.find(branch => branch.id === service.serviceIDRequested.source_branch_id)?.shippingIDBranch.trading_name

                const deliveryDate = formatDate.handle(service.serviceIDRequested.delivery_date, "DateWithoutHourToShow")
                const deliveryHour = formatDate.handle(service.serviceIDRequested.delivery_hour, "DateOnlyWithHourMinute")

                const sourceHub = hubs?.find(hub => hub.id === service?.serviceIDRequested?.budgetIDService.source_hub_id)

                const destinationHub = hubs?.find(hub => hub.id === service.serviceIDRequested?.budgetIDService.destination_hub_id)

                const crossdockingHub = hubs?.find(hub => hub.id === service?.serviceIDRequested?.budgetIDService.crossdocking_hub_id)

                const timezoneSourceHub = getTimezoneNameByValue(sourceHub?.timezone)
                const timezoneDestinationHub = getTimezoneNameByValue(destinationHub?.timezone)
                const timezoneCrossdockingHub = getTimezoneNameByValue(crossdockingHub?.timezone)


                return (
                  <KanbanCard key={service.id}>
                    <KanbanCardHeader serviceId={service.id} protocol={service.protocol} />
                    <Box p={4}>
                      <KanbanCardItem
                        icon={FaUser}
                        content={service.customerIDService.trading_firstname}
                      />
                      <KanbanCardItem
                        icon={FaPlaneDeparture}
                        content={sourceCities ?? '-'}
                      />
                      <KanbanCardItem
                        icon={FaPlaneArrival}
                        content={destinationCities ?? '-'}
                      />
                      <KanbanCardItem
                        icon={FaClock}
                        content={`${collectDate} - ${collectHourStart} -> ${collectHourEnd}`}
                      />
                      <KanbanCardItem
                        icon={FaClock}
                        content={`${boardDate} - ${boardHour}`}
                      />
                      <KanbanCardItem
                        icon={FaClock}
                        content={`${deliveryDate} - ${deliveryHour}`}
                      />
                     {timezoneSourceHub && (
                        <KanbanCardItem
                          icon={FaClock}
                          content={`Fuso Origem: ${timezoneSourceHub}`}
                        />
                      )}
                      {timezoneDestinationHub && (
                        <KanbanCardItem
                          icon={FaClock}
                          content={`Fuso Destino: ${timezoneDestinationHub}`}
                        />
                      )}
                      {timezoneCrossdockingHub && (
                        <KanbanCardItem
                          icon={FaClock}
                          content={`Fuso Crossdocking: ${timezoneCrossdockingHub}`}
                        />
                      )}
                      <KanbanCardItem
                        icon={FaShippingFast}
                        content={`${shipping}`}
                        isLink
                        url={`/servicos/embarques/${service.id}`}
                      />
                    </Box>
                  </KanbanCard>
                )
              })
            )}
          </>
        )}


      </Flex>
    </Flex>
  )
}
