import { useState } from 'react'
import { useQuery } from 'react-query'
import { getLogsByDate } from '../../services/getFunctions/log/getLog'
import { useAuth } from '../auth/useAuth'
import { useToastify } from '../toastify/useToastify'

const useLogByDate = () => {
  const [date, setDate] = useState('')
  const { handleLogout, userLogged } = useAuth()
  const { errorMessage } = useToastify()

  const handleSetDateValue = (dateValue: string) => setDate(dateValue)

  const logByDate = useQuery('logByDate', () => getLogsByDate(date), {
    enabled: !!date && !!userLogged,
    refetchOnWindowFocus: false,
    onError: (error: any) => {
      errorMessage(error.message)
      handleLogout()
    },
  })
  return { logByDate, handleSetDateValue }
}

export { useLogByDate }
