import { Flex, IconButton, Text } from "@chakra-ui/react";
import { FaTimes } from "react-icons/fa";

interface ExternalComunicationsChatHeaderProps {
  title: string
  onClose: () => void
}

export function ExternalComunicationsChatHeader({
  title,
  onClose
}: ExternalComunicationsChatHeaderProps) {
  return (
    <Flex
      w="full"
      bgColor="white"
      align="center"
      justify="space-between"
      gap="2"
      p="2"
      borderBottom="4px solid"
      borderColor="gray.50"
    >

      <Text
        maxW={["200px", "200px", "400px"]}
        fontSize="sm"
        fontWeight="bold"
        whiteSpace="pre-wrap"
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {title}
      </Text>

      <IconButton
        aria-label="close modal"
        icon={<FaTimes />}
        onClick={onClose}
        variant="ghost"
      />
    </Flex>
  )
}
