import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface ApproveDirectLeaderActiveFeedbackBody {
  responsability: string
  learnEveryDay: string
  loveMonday: string
  teamResult: string
  doesNotExistsSuccess: string
  catchesAndDoes: string
  noLies: string
  doesKeepCollaborator: boolean
  doesCollaboratorHasPromotion: boolean
  nextPeriodMissions: string
}

interface ApproveDirectLeaderActiveFeedbackProps {
  body: ApproveDirectLeaderActiveFeedbackBody
  activeFeedbackId: string

}
export async function approveDirectLeaderActiveFeedback({
  body,
  activeFeedbackId
}: ApproveDirectLeaderActiveFeedbackProps) {
  try {
    const response = await api.put(`/feedbacks/${activeFeedbackId}/actives/direct-leader-approve`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
