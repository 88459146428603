import { useEffect } from "react"
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Flex, Spinner } from "@chakra-ui/react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

import { useAuth } from "hooks/auth/useAuth"
import { Link, useHistory } from "react-router-dom"
import { isCnpjValid, isCpfValid } from "utils/cpfCnpjValidateFunctions";

import { useCep } from "hooks/useCep";
import { useToastify } from "hooks/toastify/useToastify";
import { statesWithUfList } from "utils/CustomLists/statesWithUfList";
import { useIbgeCitiesByUf } from "hooks/ibge/useIbgeCitiesByUf";
import { useIbgeState } from "hooks/ibge/useIbgeState";
import { GeneralProviderForm, GeneralProviderFormInputs } from "./components/GeneralProviderForm";
import { useGeneralProviderFunctions } from "hooks/generalProvider/useGeneralProviderFunctions";

import { useMaterials } from "hooks/material/useMaterials";
import { useFinancialClassifications } from "hooks/financialClassification/useFinancialClassifications";

const generalProviderFormSchema = yup.object().shape({
  situation: yup.string().required("Campo Obrigatório"),
  company_name: yup.mixed().when('provider_person', {
    is: 'PESSOA JURÍDICA',
    then: yup.string().required("Campo obrigatório")
  }),
  trading_name: yup.string().required("Campo obrigatório"),
  state_registration: yup.string().nullable(),
  cnpj: yup.mixed().when('provider_person', {
    is: 'PESSOA JURÍDICA',
    then: yup.string().transform(isCnpjValid).required('CNPJ inválido')
  }),
  email: yup.string().required("Campo obrigatório"),
  payment_conditional: yup.string().required("Campo obrigatório"),
  payment_type: yup.string().required("Campo obrigatório"),
  cellphone: yup.string().required("Campo obrigatório"),
  telephone: yup.string().required("Campo obrigatório"),
  cep: yup.string().required("Campo obrigatório"),
  state: yup.string().required("Campo obrigatório"),
  city: yup.string().required("Campo obrigatório"),
  street: yup.string().required("Campo obrigatório"),
  number: yup.string().required("Campo obrigatório"),
  neighborhood: yup.string().required("Campo obrigatório"),
  complement: yup.string(),
  type: yup.string().required("Campo obrigatório"),
  materials: yup.array().when('type', {
    is: 'PRODUTO',
    then: yup.array().of(yup.object().shape({
      _id: yup.string().required("Campo obrigatório")
    }))
  }),
  financial_classifications: yup.array().of(yup.object().shape({
    _id: yup.string().required("Campo obrigatório")
  })),
  cost_centers: yup.array().of(yup.object().shape({
    name: yup.string().required("Campo obrigatório")
  })),
  observation: yup.string().nullable(),
  provider_person: yup.string().required("Campo obrigatório"),
  cpf: yup.mixed().when('provider_person', {
    is: 'PESSOA FÍSICA',
    then: yup.string().transform(isCpfValid).required('CPF inválido'),
  })
})

export function CreateGeneralProviderPage() {
  const formMethods = useForm<GeneralProviderFormInputs>({
    resolver: yupResolver(generalProviderFormSchema)
  })

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()
  const { warnMessage, promiseMessage } = useToastify()
  const { watch, setValue, handleSubmit, formState: { isSubmitting } } = formMethods

  const cepValue = watch("cep")
  const isValidCep = cepValue ? !cepValue.includes("_") : false

  const cepFormatted = isValidCep ? cepValue.split("-").join("") : null

  const {
    data: cepData, isFetching: isFetchingCepData, refetch: refetchCep
  } = useCep({ cep: cepFormatted, useQueryOptions: { enabled: isValidCep } })

  useEffect(() => {
    if (!userLogged.permissions.includes('create-general-provider')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])


  useEffect(() => {
    if (isValidCep) refetchCep()
  }, [refetchCep, isValidCep, cepValue])

  const isErrorOnConsultCep = cepData?.erro

  useEffect(() => {
    if (isErrorOnConsultCep && !isFetchingCepData) {
      warnMessage("Ocorreu um erro ao consultar o cep informado!")
    }
  }, [isErrorOnConsultCep, warnMessage, isFetchingCepData])

  const {
    ibgeStates: { data: ibgeStates, isFetching: isFetchingIbgeStates }
  } = useIbgeState()

  const { data: ibgeCities, isFetching: isFetchingIbgeCitiesByUf } =
    useIbgeCitiesByUf({
      uf: cepData?.uf,
      queryOptions: { enabled: !isErrorOnConsultCep && !isFetchingCepData }
    })

  const { data: materials, isFetching: isFetchingMaterials } = useMaterials()
  const { data: financialClassifications, isFetching: isFetchingFinancialClassifications } = useFinancialClassifications()

  const { createGeneralProvider: { mutateAsync: createGeneralProvider } } = useGeneralProviderFunctions()

  const stateFromCepUf = Object.entries(statesWithUfList).find(([key, value]) => value.uf === cepData?.uf)?.[0]

  const cityFilteredByCep = ibgeCities?.find(city => {
    return city.nome === cepData?.localidade
  })

  useEffect(() => {

    if (
      cepData &&
      !isErrorOnConsultCep &&
      !isFetchingCepData &&
      !isFetchingIbgeCitiesByUf &&
      !isFetchingIbgeStates
    ) {
      setValue('street', cepData.logradouro)
      setValue('complement', cepData.complemento)
      setValue('neighborhood', cepData.bairro)

      if (stateFromCepUf) setValue('state', stateFromCepUf?.toLocaleUpperCase())
      if (cityFilteredByCep) setValue('city', cityFilteredByCep?.nome.toLocaleUpperCase())

    }
  }, [
    cepData,
    stateFromCepUf,
    setValue,
    cityFilteredByCep,
    isErrorOnConsultCep,
    isFetchingCepData,
    isFetchingIbgeCitiesByUf,
    isFetchingIbgeStates
  ])

  async function handleCreateGeneralProvider({ ...values }: GeneralProviderFormInputs) {
    const successMessage = "Fornecedor criado com sucesso!"

    await promiseMessage(createGeneralProvider(values, {
      onSuccess: () => redirectTo("/fornecedor-geral")
    }), successMessage)
  }
  return (
    <StandardBackgroundForm title="Criar Fornecedor Geral" onSubmit={handleSubmit(handleCreateGeneralProvider)}>
      {(isFetchingFinancialClassifications && isFetchingMaterials) ? (<Spinner />) : (<FormProvider {...formMethods}>
        <GeneralProviderForm slug="criar" ibgeStates={ibgeStates}
          ibgeCities={ibgeCities} materials={materials} financialClassifications={financialClassifications} />
      </FormProvider>)}

      <Flex
        mt={6}
        gap={4}
        direction={["column", "column", "row"]}
        align="center"
        justify={["center", "center", "flex-end"]}
      >
        <Button
          as={Link}
          colorScheme='gray'
          w={['full', 'full', 'min']}
          isLoading={isSubmitting}
          to='/fornecedor-geral'
        >
          Lista de fornecedores gerais
        </Button>
        <Button
          w={["full", "full", "min"]}
          colorScheme="blue"
          type="submit"
          isLoading={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}
