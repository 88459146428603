import { setDate } from '../../DateFunctions/setDate'
import { transformStringToNumber } from '../../GeneralFunctions/FormatValuesFuntions/transformStringToNumber'

type DataProps = {
  branch_id: string
  service_id: string
  driver_id: string
  collector_id: string
  arrival_latitude: string
  arrival_longitude: string
  arrival_timestamp: string
  operational_number: string
  cte: string | null
  board_volume: number | null
  board_weight: string | null
  cte_photo: {} | null
  cte_photo_change: string
  real_weight: string | null
  taxed_weight: string | null
  cte_transfer_cost: string | null
  departure_latitude: string | null
  departure_longitude: string | null
  departure_timestamp: string | null
  real_board_hour: string
  real_board_date: string
  wait_link: string
  board_observation: string | null
  validate_observation: string | null
  open_ticket_email: 'yes' | 'no'
  tracker_link: string | null
}

export function boardValidateFormatDataToBack(data: DataProps) {
  const [realBoardYear, realBoardMonth, realBoardDay] =
    data.real_board_date.split('-')
  const [realBoardHour, realBoardMinute] = data.real_board_hour.split(':')

  const realBoardDate = setDate.handleSetDateAndHourWithFormat(
    Number(realBoardDay),
    Number(realBoardMonth),
    Number(realBoardYear),
    Number(realBoardHour) + 3,
    Number(realBoardMinute),
    'FullDate',
  )

  const formData = new FormData()
  formData.append('branch_id', data.branch_id as any)
  formData.append('service_id', data.service_id as any)
  formData.append('driver_id', data.driver_id as any)
  formData.append('collector_id', data.collector_id as any)
  formData.append('arrival_latitude', data.arrival_latitude as any)
  formData.append('arrival_longitude', data.arrival_longitude as any)
  formData.append('arrival_timestamp', data.arrival_timestamp as any)
  formData.append('operational_number', data.operational_number as any)
  formData.append('cte', data.cte as any)
  formData.append('board_volume', data.board_volume as any)
  formData.append(
    'board_weight',
    data.board_weight !== null
      ? (transformStringToNumber(String(data.board_weight)) as any)
      : null,
  )
  formData.append('cte_photo', data.cte_photo as any)
  formData.append(
    'real_weight',
    data.real_weight !== null
      ? (transformStringToNumber(String(data.real_weight)) as any)
      : null,
  )
  formData.append(
    'taxed_weight',
    data.taxed_weight !== null
      ? (transformStringToNumber(String(data.taxed_weight)) as any)
      : null,
  )
  formData.append(
    'cte_transfer_cost',
    data.cte_transfer_cost
      ? (transformStringToNumber(String(data.cte_transfer_cost)) as any)
      : null,
  )
  formData.append('departure_latitude', data.departure_latitude as any)
  formData.append('departure_longitude', data.departure_longitude as any)
  formData.append('departure_timestamp', data.departure_timestamp as any)
  formData.append('real_board_date', realBoardDate as any)
  formData.append('wait_link', data.wait_link as any)
  formData.append('board_observation', data.board_observation as any)
  formData.append('validate_observation', data.validate_observation as any)
  formData.append('tracker_link', data.tracker_link)

  if (data.cte_photo_change === 'SIM') {
    formData.append('ctePhotoChoose', 'SIM')
  }

  return formData
}

export function boardValidateFormatDataToBackWithoutPhoto(data: DataProps) {
  const [realBoardYear, realBoardMonth, realBoardDay] =
    data.real_board_date.split('-')
  const [realBoardHour, realBoardMinute] = data.real_board_hour.split(':')

  const realBoardDate = setDate.handleSetDateAndHourWithFormat(
    Number(realBoardDay),
    Number(realBoardMonth),
    Number(realBoardYear),
    Number(realBoardHour),
    Number(realBoardMinute),
    'FullDate',
  )

  const formatedData = {
    ...data,
    board_weight:
      data.board_weight !== null
        ? transformStringToNumber(data.board_weight)
        : null,
    real_weight:
      data.real_weight !== null
        ? (transformStringToNumber(data.real_weight) as any)
        : null,
    taxed_weight:
      data.taxed_weight !== null
        ? (transformStringToNumber(data.taxed_weight) as any)
        : null,
    real_board_date: realBoardDate,
    cte_transfer_cost: data.cte_transfer_cost
      ? transformStringToNumber(data.cte_transfer_cost)
      : null,
  }

  return formatedData
}
