import { TrainingCollaboratorTypes, TrainingStatus, TrainingTypes } from './../api/trainings/training';

export const trainingCollaboratorTypeMap: Record<TrainingCollaboratorTypes, string> = {
  "clt-driver": 'Motorista CLT',
  llm: 'Llm',
  collector: 'Coletador',
  "aggregate-driver": 'Agregado',
  "carrier": "Portador"
}

export const trainingTypesMap: Record<TrainingTypes, string> = {
  "initial": "Básico Introdutório",
  "recycle": "Reciclagem de Treinamentos Obrigatórios",
}

export const trainingStatusMap: Record<TrainingStatus, string> = {
  active: 'Ativo',
  inactive: 'Inativo',
  pending: 'Pendente'
}




