import { Th, Tr } from "@chakra-ui/react";


export function CollectsRelationsTableHead() {

  return (
    <Tr
      h="40px"
      bg="#38c3fa"
    >
      <Th></Th>
      <Th color="white">Protocolo</Th>
      <Th color="white">Cliente</Th>
      <Th color="white">Veículo</Th>
      <Th color="white">Remetentes</Th>
      <Th color="white">Data da coleta</Th>
      <Th color="white">Horário da coleta</Th>
      <Th color="white">Embarque/Entrega</Th>
      <Th color="white">Insumos</Th>
      <Th color="white">Destinatários</Th>
      <Th color="white">Observações</Th>
      <Th color="white">Coletador de origem</Th>
      <Th color="white">Coletador de destino</Th>
    </Tr >
  )
}
