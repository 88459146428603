import { Box, Divider, Grid, GridItem, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { getSupport } from "api/supports/getSupport";
import { useAuth } from "hooks/auth/useAuth";
import { useQuery } from "react-query";
import { AnalyzeSupport } from "./AnalyzeSupport";
import { SendSupportResolutionReturn } from "./SendSupportResolutionReturn";
import { SolveSupport } from "./SolveSupport";
import { SupportDetailTable } from "./SupportDetailTable";

interface SupportDetailProps {
  supportId: string
  onClose: () => void
}

interface ChangeGridTemplateColumnsParams {
  supportStatus: string
  permissions: string[]
  userLoggedId: string
  supportCreatedByUserId: string
  supportResponsiblesIds: string[]
}

function changeIsEnabledDetailWithForm({
  permissions,
  supportStatus,
  userLoggedId,
  supportCreatedByUserId,
  supportResponsiblesIds,
}: ChangeGridTemplateColumnsParams) {
  if (supportStatus === 'analyzing' && permissions.includes('analyze-support') && supportResponsiblesIds.includes(userLoggedId)) {
    return true
  }

  if (supportStatus === 'supporting' && permissions.includes('solve-support') && supportResponsiblesIds.includes(userLoggedId)) {
    return true
  }

  if (supportStatus === 'waiting-return' && permissions.includes('validate-support-resolution') && userLoggedId === supportCreatedByUserId) {
    return true
  }

  return false
}


export function SupportDetail({ supportId, onClose }: SupportDetailProps) {

  const { userLogged } = useAuth()

  const doesUserCanAnalyzeSupport = userLogged?.permissions.includes('analyze-support')
  const doesUserCanSolveSupport = userLogged?.permissions.includes('solve-support')
  const doesUserCanValidateSupportResolution = userLogged?.permissions.includes('validate-support-resolution')

  const {
    data: supportResult,
    isLoading: isLoadingSupportResult
  } = useQuery({
    queryKey: ['support', supportId],
    queryFn: () => getSupport({ supportId })
  })

  const lastResolution = supportResult?.support?.resolutions.at(-1)


  const isEnabledCurrentStatusForm = changeIsEnabledDetailWithForm({
    supportStatus: supportResult?.support.status,
    permissions: userLogged?.permissions,
    supportCreatedByUserId: supportResult?.support.createdByUserId,
    supportResponsiblesIds: supportResult?.support?.subject?.responsibles.map(responsible => responsible.user.id),
    userLoggedId: userLogged?.id
  })

  return (
    <ModalContent >
      <ModalHeader>
        Detalhes Atendimento
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        {isLoadingSupportResult ? (
          <Table size="sm">
            <Tbody>
              {Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr key={index}>
                    <Td>
                      <Skeleton w="full" h="16px" />
                    </Td>
                    <Td isNumeric>
                      <Skeleton w="full" h="16px" />
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        ) : (
          <Grid
            templateColumns={isEnabledCurrentStatusForm ? '1fr auto 1fr' : '1fr'}
            gap={6}
          >
            <GridItem>
              <SupportDetailTable
                supportResult={supportResult}
              />
            </GridItem>
            <>
              <Box>
                <Divider orientation="vertical" />
              </Box>
              <GridItem>
                {supportResult?.support.status === 'analyzing' && doesUserCanAnalyzeSupport && isEnabledCurrentStatusForm && (
                  <AnalyzeSupport
                    supportId={supportId}
                  />
                )}

                {supportResult?.support.status === 'supporting' && doesUserCanSolveSupport && isEnabledCurrentStatusForm && (
                  <SolveSupport
                    supportId={supportId}
                    onClose={onClose}
                  />
                )}

                {supportResult?.support.status === 'waiting-return' && doesUserCanValidateSupportResolution && isEnabledCurrentStatusForm && (
                  <SendSupportResolutionReturn
                    supportId={supportId}
                    supportResolutionReplyId={lastResolution.id}
                  />
                )}
              </GridItem>
            </>
          </Grid>
        )}
      </ModalBody>
    </ModalContent>
  )
}
