import { Flex, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { BusinessCost } from "api/businessCosts/businessCost";
import { deleteBusinessCost } from "api/businessCosts/deleteBusinessCost";
import { GetBusinessCostsResponse } from "api/businessCosts/getBusinessCosts";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { FaPen, FaSearch, FaTrash } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { captalize } from "utils/captalize";
import { BusinessBudgetProps } from "utils/RequestFunctions/BusinessBudget/requestBusinessBudgetFunctions";
import { BusinessCostDetail } from "./BusinessCostDetail";
import { BusinessCostSituation } from "./BusinessCostSituation";

interface BusinessCostsTableRowProps {
  businessCost: BusinessCost & {
    businessBudget: BusinessBudgetProps
  }
}

export function BusinessCostsTableRow({
  businessCost
}: BusinessCostsTableRowProps) {
  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanEditBusinessCost = userLogged?.permissions.includes('edit-business-cost')
  const userCanDeleteBusinessCost = userLogged?.permissions.includes('delete-business-cost')

  const {
    isOpen: isBusinessCostDetailModalOpen,
    onOpen: onOpenBusinessCostDetailModal,
    onClose: onCloseBusinessCostDetailModal,
  } = useDisclosure()

  const queryClient = useQueryClient()

  const { mutateAsync: deleteBusinessCostFn } = useMutation({
    mutationFn: deleteBusinessCost,
    onSuccess: (_data, { businessCostId }) => {
      const cachedBusinessCosts = queryClient.getQueriesData<GetBusinessCostsResponse>({
        queryKey: ['business-costs']
      })

      cachedBusinessCosts.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) {
          return
        }

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          businessCosts: cachedValue.businessCosts.filter(
            (businessCost) => businessCost.id !== businessCostId
          )
        })

      })

      toast.success('Custo business excluído!')
    }
  })


  async function handleDeleteBusinessCost(businessCostId: string) {
    await deleteBusinessCostFn({ businessCostId })
  }


  return (
    <Tr>
      <Td>
        <Modal
          isOpen={isBusinessCostDetailModalOpen}
          onClose={onCloseBusinessCostDetailModal}
        >
          <ModalOverlay />

          <BusinessCostDetail businessCostId={businessCost.id} />
        </Modal>

        <IconButton
          icon={<FaSearch />}
          aria-label="Abrir detalhes de custo business"
          size="sm"
          variant="ghost"
          onClick={onOpenBusinessCostDetailModal}
        />
      </Td>
      <Td>
        {captalize(businessCost.businessBudget.customerIDBusinessBudget.trading_firstname)}
      </Td>
      <Td>{businessCost.businessBudget.route_nickname}</Td>
      <Td>{format(new Date(businessCost.start_date), 'dd/MM/yyyy')}</Td>
      <Td>{businessCost.end_date ? format(new Date(businessCost.end_date), 'dd/MM/yyyy') : '-'}</Td>
      <Td>
        <BusinessCostSituation situation={businessCost.situation} />
      </Td>
      <Td w="150px">
        <Flex
          align="center"
          gap="4"
          w="min"
        >
          <IconButton
            icon={<FaPen />}
            aria-label="Abrir detalhes de custo business"
            size="sm"
            isDisabled={!userCanEditBusinessCost}
            variant="ghost"
            _disabled={{
              cursor: 'not-allowed',
              opacity: '0.5'
            }}
            onClick={() => history.push(`/custo/business/${businessCost.id}/editar`)}
          />

          <IconButton
            icon={<FaTrash />}
            aria-label="Excluir custo business"
            size="sm"
            isDisabled={!userCanDeleteBusinessCost}
            variant="outline"
            _disabled={{
              cursor: 'not-allowed',
              opacity: '0.5'
            }}
            onClick={() => handleDeleteBusinessCost(businessCost.id)}
          />

        </Flex>
      </Td>
    </Tr>
  )
}
