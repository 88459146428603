import { Box, Button, Flex, FormControl, FormLabel, Heading, IconButton, Input, Link, Progress, Radio, RadioGroup, Select, Stack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { dismissalPaymentInternClt } from "api/internClts/dismissalPaymentInternClt";
import { format, parseISO, set } from "date-fns";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { useEffect, useState } from "react";
import { FaExternalLinkAlt, FaFileImport, FaPlus, FaTrash } from "react-icons/fa";
import { InternClt } from "api/internClts/_types/internClt";


interface DismissalPaymentInternCltProps {
  internClt: InternClt
}

interface AttachmentUpload {
  id: string
  link: string
  title: string
  progress: number
}

interface Attachments {
  attachment: FileList
}

export interface DismissalPaymentInternCltSchema {
  isConferredGRRF: string
  terminationDocumentsIds: Attachments[]
  paymentDate: string
  shouldAdvanceStatus: string
}

const dismissalPaymentInternCltSchema = yup.object({
  isConferredGRRF: yup.string(),
  terminationDocumentsIds: yup.array(),
})


export function DismissalPaymentInternClt({ internClt }: DismissalPaymentInternCltProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const { mutation: uploadTerminationDocumentsFn, uploadProggress: uploadProggressTerminationDocuments } = useAttachmentMutation()

  const [attachments, setAttachments] = useState<AttachmentUpload[]>([]);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<DismissalPaymentInternCltSchema>({
    resolver: yupResolver(dismissalPaymentInternCltSchema),
    defaultValues: {
      terminationDocumentsIds: [{ attachment: null }]
    }
  })

  useEffect(()=> {
    if(internClt){
      setValue("isConferredGRRF", Boolean(internClt?.is_conferred_grrf) ? internClt?.is_conferred_grrf: '')
      setValue("paymentDate", internClt?.payment_date ? format(parseISO(internClt?.payment_date), 'yyyy-MM-dd') : '')
    }
  },[internClt, setValue])

  const {
    fields: terminationDocumentsIdsFields,
    append: appendTerminationDocumentsIdField,
    remove: removeTerminationDocumentsIdField,
  } = useFieldArray({
    control,
    name: 'terminationDocumentsIds'
  })

  const handleUploadAttachment = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = Number(event.target.name.match(/\d+/)?.[0]);

    const formData = new FormData();
    formData.append('attachment', event.target.files[0]);


    const result = await uploadTerminationDocumentsFn.mutateAsync(formData);

    const newAttachment = {
      id: result.attachment?.id || '',
      link: result.attachment?.link || '',
      title: result?.attachment?.title || '',
      progress: uploadProggressTerminationDocuments,
    };

    setAttachments(prev => {
      const updatedAttachments = [...prev];
      updatedAttachments[index] = newAttachment;
      return updatedAttachments;
    });
  };

  const handleRemoveAttachment = (index: number) => {
    removeTerminationDocumentsIdField(index);
    setAttachments(prev => prev.filter((_, i) => i !== index));
  };



  const { mutateAsync: dismissalPaymentInternCltFn } = useMutation({
    mutationFn: dismissalPaymentInternClt,
    onSuccess(_data, { body, internCltId }) {
      queryClient.invalidateQueries({ queryKey: 'intern-clts' })
      queryClient.invalidateQueries({ queryKey: 'dismissal-payment-intern-clts' })
      queryClient.invalidateQueries({ queryKey: 'dismissal-financial-intern-clts' })
      queryClient.invalidateQueries({ queryKey: ['intern-clt', internCltId] })
    }
  })

  async function handleDismissalPaymentInternClt(values: DismissalPaymentInternCltSchema) {
    const terminationDocumentsIds = attachments.map((attachment) => attachment.id)
    let paymentDate = null
    if(Boolean(values?.paymentDate)){
      const [year, month, day] = values?.paymentDate?.split('-').map(Number)
       paymentDate = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()
    }

    await promiseMessage(dismissalPaymentInternCltFn({
      body: {
        ...values,
        terminationDocumentsIds,
        shouldAdvanceStatus: values.shouldAdvanceStatus ? values.shouldAdvanceStatus === 'yes' : false,
        paymentDate: paymentDate
      },
      internCltId: internClt?.id
    }), 'Pagamento enviado!')
  }


  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleDismissalPaymentInternClt)}

    >
      <Heading letterSpacing="tight" size='sm'>Demissão pagamento</Heading>
      <FormControl isInvalid={!!errors.isConferredGRRF} mt={3}>
        <FormLabel>
          Realizadou conferência GRRF ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isConferredGRRF"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="Sim">Sim</Radio>
                <Radio size='sm' rounded='md' value="Não">Não</Radio>
                <Radio size='sm' rounded='md' value="N/A">N/A</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <Flex
        border="1px solid"
        borderColor="gray.200"
        p="4"
        rounded="lg"
        direction="column"
      >
        <Heading
          fontSize="md"
          letterSpacing="tight"
        >
          Anexo de documentos rescisórios:
        </Heading>

        <Box
          maxH="400px"
          overflowY="scroll"
        >
          {terminationDocumentsIdsFields.map((field, index) => {
            const attachment = attachments[index];
            return (
              <FormControl mt="3" key={field.id} isInvalid={errors?.terminationDocumentsIds ? !!errors?.terminationDocumentsIds[index]?.attachment : undefined}>
                <Flex gap="3">
                  <Stack
                    direction="column"
                    w="full"
                    mt="3"
                  >
                    <Button
                      as={FormLabel}
                      htmlFor={`terminationDocumentsIds.${index}.attachment`}
                      lineHeight="1"
                      leftIcon={<FaFileImport />}
                      size="sm"
                      w="min"
                      cursor="pointer"
                      border={!!errors?.terminationDocumentsIds && '2px solid'}
                      borderColor={(!!errors?.terminationDocumentsIds) && 'red.500'}
                    >
                      Documento rescisório
                    </Button>
                    <FormControl isInvalid={!!errors?.terminationDocumentsIds}>
                      <Input
                        {...register(`terminationDocumentsIds.${index}.attachment`)}
                        name={`terminationDocumentsIds.${index}.attachment`}
                        id={`terminationDocumentsIds.${index}.attachment`}
                        type="file"
                        hidden
                        onChangeCapture={handleUploadAttachment}
                      />
                    </FormControl>

                  </Stack>
                  {terminationDocumentsIdsFields?.length > 1 && (
                    <IconButton
                      aria-label="Remover anexo"
                      icon={<FaTrash />}
                      size="sm"
                      variant="ghost"
                      onClick={() => handleRemoveAttachment(index)}
                    />
                  )}
                </Flex>
                {attachment !== undefined && (
                  <Table size="sm">
                    <Tbody>
                      <Tr>

                        <Td fontSize="xs" maxW="100px">{attachment?.title}</Td>
                        <>
                          <Td fontSize="xs" w="200px">
                            <Progress size="sm" rounded="md" value={attachment?.progress || 100} />
                          </Td>
                          <Td fontSize="xs" isNumeric>
                            <IconButton
                              aria-label="Visualizar anexo"
                              as={Link}
                              size="sm"
                              icon={<FaExternalLinkAlt />}
                              href={attachment.link || ''}
                              isExternal
                            />
                          </Td>
                        </>
                      </Tr>
                    </Tbody>
                  </Table>
                )}
              </FormControl>
            )
          })
          }
        </Box>
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            leftIcon={<FaPlus />}
            mt="4"
            size="sm"
            colorScheme="blue"
            onClick={() => appendTerminationDocumentsIdField({ attachment: null })}
          >
            Adicionar anexos
          </Button>
        </Flex>

      </Flex>

      <FormControl isInvalid={!!errors?.paymentDate} mt={3}>
        <FormLabel fontSize="sm">
          Data de pagamento:
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('paymentDate')}
          name="paymentDate"
          type="date"
          rounded="md"
          size='sm'
        />
      </FormControl>
      <FormControl isInvalid={!!errors.shouldAdvanceStatus} mt={3}>
        <FormLabel fontSize="sm">
          Passar para o próximo status?
        </FormLabel>
        <Select
          {...register('shouldAdvanceStatus')}
          name="shouldAdvanceStatus"
          placeholder="Selecione..."
          size="sm"
          rounded="md"
        >
          <option value='yes'>Sim</option>
          <option value='no'>Não</option>
        </Select>
      </FormControl>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
